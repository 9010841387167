import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames/bind';

import { withDefaultChartSettings } from '@/hoc/withDefaultChartSettings';

import { gatesSelectors } from '@/store/slices/gates';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { chartDataSelectors } from '@/store/slices/chartData';
import { experimentSelectors } from '@/store/slices/experiment';

import usePlotProxy from '@/hooks/usePlotProxy';
import { useChartRangeHandling, useDebounce, usePlotSettings } from '@/hooks';
import { useHeatmapPlotSettings } from '@/hooks/plotSettings/useHeatmapPlotSettings';

import { findLaneInScanList } from '@/helpers/scans';
import { handlePlotlyResize } from '@/helpers/plotly';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import { CONFIG } from '@/pages/Dataset/constants';
import { EAxesScaleType, EModebarTools, EPageWithChartType } from '@/types/charts';
import NoDataFound from '@/components/common/NoDataFound';
import ChartSettingsButton from '@/components/charts/ChartSettingsButton';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import type { TDatasetChart } from '@/components/charts/SingleChartWithGates/types';
import AxisXSelect from '@/components/charts/AxisXSelect';
import '@/styles/gates.scss';

import usePrimaryAxisOptionListForMultiDatasets from '@/hooks/charts/usePrimaryAxisOptionListForMultiDatasets';
import RangeResetButton from '@/components/charts/RangeResetButton';
import { getOption } from '@/components/common/Select/helpers';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { getCoordinatesByAxesAndGate, prepareDataConfig } from '@/helpers/charts/chartsData';
import { getUniqueCageIds, prepareHeatmapAxesLayout, prepareHeatmapData } from './helpers';

import styles from './Heatmap.module.scss';

const cn = classnames.bind(styles);

type THeatmapProps = TDatasetChart;

const Heatmap: FC<THeatmapProps> = ({
  isTransitionEnd,
  chartDataList,
  currentAppDatasetList = [],
  isError,
  scatterPlotAxesOptions = [],
  scanList,
  entityLevelGateList,
}) => {
  const { openCageInspector } = useExperimentModalsContext();
  const chartId = usePlotChartIdContext();

  const selectedTool = EModebarTools.zoom;
  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const debouncedTransitionEnd = useDebounce(isTransitionEnd, 100);

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));

  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);

  const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(chartId));
  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const customHistogramBinsAmount = useSelector(histogramSettingsSelectors.selectCustomHistogramBinsAmount(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const kernelBinsAmountMultiplier = useSelector(histogramSettingsSelectors.selectKernelBinsAmountMultiplier(chartId));

  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isPlotLoaded, setIsPlotLoaded] = useState(false);

  const primaryAxisOptionList = usePrimaryAxisOptionListForMultiDatasets({
    scanList,
    chartDataList,
  });

  const xAxisLabel = useMemo(() => {
    if (!Array.isArray(scatterPlotAxesOptions)) return '';

    return getOption(scatterPlotAxesOptions, xAxis)?.label ?? '';
  }, [scatterPlotAxesOptions, xAxis]);

  const multiChartEntityListData = useSelector(chartDataSelectors.selectMultiChartEntityListData);

  const multiChartEntityList = useMemo(() => {
    const datasetPathList = chartDataList.map((chartData) => chartData.dataset.path);
    return datasetPathList.map((datasetPath) => multiChartEntityListData[datasetPath]?.data ?? []);
  }, [chartDataList, multiChartEntityListData]);

  const filteredMultiChartEntityList = useMemo(
    (): TEntity[][] =>
      multiChartEntityList.map((entityList: TEntity[]) => {
        if (selectedGate) {
          const data = getCoordinatesByAxesAndGate({
            entityList,
            xAxis,
            yAxis: xAxis,
            gate: selectedGate,
            entityLevelGateList,
          });

          return data.cagesDataByCoordinates;
        }

        return entityList;
      }),
    [selectedGate, multiChartEntityList, entityLevelGateList, xAxis]
  );

  const noDataMessage = useMemo(() => {
    const isNoDataForGate = Boolean(selectedGate) && filteredMultiChartEntityList.every((list) => list.length === 0);
    return isEmptyData && isNoDataForGate
      ? 'No data matching the current settings. \n Please select a different gate or change the axes'
      : 'The are no cages with the Global cage ID \n  Please select different datasets to analyse';
  }, [isEmptyData, selectedGate]);

  const isAllPlotDataLoaded = useMemo(
    () => Boolean(isPlotLoaded && graphRef.current && debouncedTransitionEnd),
    [isPlotLoaded, graphRef.current, debouncedTransitionEnd]
  );

  const fileName = useMemo(() => {
    if (!graphRef?.current?.layout?.xaxis?.ticktext) return `${experimentName}-heatmap`;
    const { ticktext } = graphRef.current.layout.xaxis;
    const labels = ticktext.join(', ');

    return `${experimentName} (${labels}) heatmap`;
  }, [experimentName, xAxis, graphRef?.current?.layout]);

  usePlotSettings({ graphRef });

  const {
    isSortByDatasetData,
    toggleIsSortByDatasetData,
    setOptions,
    sortByOptions,
    sortBySelectedOption,
    changeSortBySelectedOption,
  } = useHeatmapPlotSettings(graphRef, filteredMultiChartEntityList, isPlotLoaded);

  const handlePointClick = useCallback(
    ({ points }: TPlotMouseEvent) => {
      if (!graphRef?.current?.data?.[0]) return;
      const { x, customdata, z } = points[0];
      const chartEntityList = filteredMultiChartEntityList[x];
      const dataset = chartDataList[x];
      const pointCage = chartEntityList.find(
        (entity: TEntity) => entity[xAxis] === z && entity.globalCageIdMatched === customdata
      );

      if (pointCage) {
        const lane = findLaneInScanList(scanList, dataset.scanId, dataset.laneId);
        openCageInspector({ entity: pointCage, lane });
      }
    },
    [scanList, chartDataList, filteredMultiChartEntityList, xAxis, openCageInspector]
  );

  const handleRangeResetClick = () => {
    resetRangeToDefault();
  };

  const { isCustomRangeUsed, resetRangeToDefault, handlePlotZoomed } = useChartRangeHandling({
    openedAxisSettingsName: null,
    selectedTool,
    graphRef,
  });

  useEffect(() => {
    if (!graphRef.current?.data?.length) {
      return;
    }

    const graphDiv = graphRef.current;

    graphRef.current.on('plotly_relayouting', (eventData: TPlotRelayoutEvent) =>
      handlePlotZoomed(eventData, 'plotly_relayouting')
    ); // event for zoom via scroll
    graphRef.current.on('plotly_relayout', (eventData: TPlotRelayoutEvent) =>
      handlePlotZoomed(eventData, 'plotly_relayout')
    ); // event for zoom via selection
    graphRef.current.on('plotly_doubleclick', () => {
      graphRef?.current?.removeAllListeners('plotly_click');
      resetRangeToDefault(() => graphRef?.current?.on('plotly_click', handlePointClick));
    });

    return () => {
      if (!graphDiv.removeAllListeners) return;
      graphDiv.removeAllListeners('plotly_relayouting');
      graphDiv.removeAllListeners('plotly_relayout');
      graphDiv.removeAllListeners('plotly_doubleclick');
    };
  }, [graphRef.current?.data?.length, selectedGate, handlePlotZoomed]);

  // create plot when all data received. Do not add other dependencies to this hook. This can cause Plotly configurations to overlap and the chart to display incorrectly.
  useEffect(() => {
    if (!graphRef.current) {
      return;
    }

    const uniqueCageIds = getUniqueCageIds(filteredMultiChartEntityList);
    if (!uniqueCageIds.size) {
      setIsEmptyData(true);
      if (!selectedGate || isEmptyData) return;
    } else {
      setIsEmptyData(false);
    }

    const heatmapData = prepareHeatmapData(
      uniqueCageIds,
      filteredMultiChartEntityList,
      xAxis,
      isSortByDatasetData,
      sortBySelectedOption
    );
    const { dataConfig } = prepareDataConfig({
      coordinates: { x: [], y: [] },
      xAxisScaleType: EAxesScaleType.linear,
      yAxisScaleType: EAxesScaleType.linear,
      currentChartType,
      currentColorScale,
      customdata: [],
      dataName: '',
      isChartFillEnabled,
      isStackedAndFilledEnabled,
      customHistogramBinsAmount,
      currentHistogramDataGroupType,
      kernelBinsAmountMultiplier,
      selectedGate,
    });

    const data = {
      ...dataConfig,
      ...heatmapData,
    };

    const axesLayoutConfig = prepareHeatmapAxesLayout(chartDataList, currentAppDatasetList, xAxis);
    const layout = {
      margin: {
        l: 60,
        r: 0,
        t: 30,
        b: currentAppDatasetList.length > 4 ? 200 : 60,
        pad: 0,
      },
      ...(uniqueCageIds.size && { ...axesLayoutConfig }),
    };

    plotlyProxy.forceReact(
      [data],
      layout,
      {
        ...CONFIG(),
        scrollZoom: EModebarTools.zoom === selectedTool,
      },
      (plot) => {
        const options =
          plot?.layout?.xaxis?.ticktext?.map((item: string, index: number) => ({ label: item, value: index })) ?? [];
        setOptions(options);
        const currentOption = options[options.length - 1]?.value;
        if (currentOption) {
          changeSortBySelectedOption(currentOption);
        }
        setIsPlotLoaded(true);
      }
    );
  }, [filteredMultiChartEntityList, currentAppDatasetList, xAxis, plotlyProxy.id]);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
    };
  }, []);

  useEffect(() => {
    const graphDiv = graphRef.current;
    if (!isPlotLoaded || !graphRef.current) return;

    graphRef.current.on('plotly_click', handlePointClick);

    return () => {
      if (!graphDiv?.removeAllListeners) return;

      graphDiv.removeAllListeners('plotly_click');
    };
  }, [isPlotLoaded, xAxis, filteredMultiChartEntityList]);

  return (
    <div
      className={cn('chart')}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {!isError
        ? !isAllPlotDataLoaded && !isEmptyData && <Skeleton className={cn('skeleton')} />
        : !isAllPlotDataLoaded && <NoDataFound className={cn('chart__no-data')} alignment="center" size="normal" />}
      <div className={cn('chart__controls')}>
        {isAllPlotDataLoaded && (
          <div className={cn('modebar')}>
            <ChartSettingsButton
              pageType={EPageWithChartType.heatmap}
              isSortByDatasetData={isSortByDatasetData}
              toggleIsSortByDatasetData={toggleIsSortByDatasetData}
              heatmapSortByOptions={sortByOptions}
              heatmapSortBySelectedOption={sortBySelectedOption}
              heatmapSetSortBySelectedOption={changeSortBySelectedOption}
            />
            <DownloadChartButton graphRef={graphRef} fileName={fileName} xAxisLabel={xAxisLabel} />
            <RangeResetButton onClick={handleRangeResetClick} disabled={!isCustomRangeUsed} />
          </div>
        )}
      </div>

      <div className={cn('plot')}>
        <div className={cn('plot__container')}>
          <div ref={graphRef} id="plot-chart" className={cn('plot-chart')}>
            {isEmptyData && (
              <NoDataFound className={cn('chart__no-data')} alignment="center" size="small" textData={noDataMessage} />
            )}
          </div>
        </div>
        {isAllPlotDataLoaded && (
          <AxisXSelect scatterPlotAxesOptions={scatterPlotAxesOptions} primaryOptionList={primaryAxisOptionList} />
        )}
      </div>
    </div>
  );
};

export default memo(withDefaultChartSettings(Heatmap, EPageWithChartType.heatmap));
