import { FC, MouseEventHandler } from 'react';

import icons from '@/components/common/icons';
import ChartModebarButton from '@/components/charts/ChartModebarButton';

type RangeResetButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
};

const RangeResetButton: FC<RangeResetButtonProps> = ({ onClick, disabled }) => (
  <ChartModebarButton
    IconComponent={icons.ReloadIcon}
    onClick={onClick}
    disabled={disabled}
    tooltip="Reset range to default"
  />
);

export default RangeResetButton;
