import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

type TChartSettingsContext = {
  toggleMainPopoverBlocked: (newValue?: boolean) => void;
  hidePopoverOnOutsideClick: boolean;
};

const ChartSettingsContext = createContext<TChartSettingsContext>({} as TChartSettingsContext);

export const useChartSettingsContext = (): TChartSettingsContext => useContext(ChartSettingsContext);

type TChartSettingsContextProviderProps = {
  children: ReactNode;
};

export const ChartSettingsContextProvider: FC<TChartSettingsContextProviderProps> = ({ children }) => {
  const [hidePopoverOnOutsideClick, setHidePopoverOnOutsideClick] = useState(true);

  const toggleMainPopoverBlocked = useCallback(
    (newValue?: boolean) => {
      setHidePopoverOnOutsideClick((prev) => newValue ?? !prev);
    },
    [setHidePopoverOnOutsideClick]
  );

  const contoursContextData = useMemo<TChartSettingsContext>(
    () => ({
      toggleMainPopoverBlocked,
      hidePopoverOnOutsideClick,
    }),
    [toggleMainPopoverBlocked, hidePopoverOnOutsideClick]
  );

  return <ChartSettingsContext.Provider value={contoursContextData}>{children}</ChartSettingsContext.Provider>;
};
