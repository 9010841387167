import { SVGProps } from 'react';

const ResetGateToGlobalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx="8"
      cy="12"
      r="7"
      transform="rotate(-180 8 12)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.3201 5.67987L10.7591 5.18205L10.7359 5.20822L10.7152 5.23644L11.3201 5.67987ZM11.2449 5.76746L10.7145 5.23713L11.2449 5.76746ZM8.54632 8.46601L9.07665 8.99634L9.07665 8.99634L8.54632 8.46601ZM7.48566 8.46601L6.95533 8.99634L6.95533 8.99634L7.48566 8.46601ZM7.48566 7.40535L6.95533 6.87502L6.95533 6.87502L7.48566 7.40535ZM9.7032 5.1878L10.2335 5.71813L10.7639 5.1878L10.2335 4.65747L9.7032 5.1878ZM7.52975 3.01435L6.99942 3.54468L7.52975 3.01435ZM10.7152 5.23644L10.7145 5.23713L11.7752 6.29779C11.83 6.24299 11.88 6.18463 11.9249 6.12329L10.7152 5.23644ZM10.7145 5.23713L8.01599 7.93568L9.07665 8.99634L11.7752 6.29779L10.7145 5.23713ZM8.01599 7.93568L8.01599 7.93568L6.95533 8.99634C7.54112 9.58212 8.49086 9.58212 9.07665 8.99634L8.01599 7.93568ZM8.01599 7.93568L8.01599 7.93568L6.95533 6.87502C6.36954 7.4608 6.36954 8.41055 6.95533 8.99634L8.01599 7.93568ZM8.01599 7.93568L10.2335 5.71813L9.17287 4.65747L6.95533 6.87502L8.01599 7.93568ZM10.2335 4.65747L8.06008 2.48402L6.99942 3.54468L9.17287 5.71813L10.2335 4.65747ZM8.06008 2.48402L6.99942 1.42336C6.41364 2.00915 6.41364 2.9589 6.99942 3.54468L8.06008 2.48402ZM8.06008 2.48402L9.12074 1.42336C8.53496 0.837577 7.58521 0.837577 6.99942 1.42336L8.06008 2.48402ZM8.06008 2.48402L10.7586 5.18255L11.8193 4.12189L9.12074 1.42336L8.06008 2.48402ZM10.7586 5.18255L10.7591 5.18205L11.881 6.17769C12.4042 5.58809 12.3837 4.68634 11.8193 4.12189L10.7586 5.18255Z"
      fill="currentColor"
    />
  </svg>
);

export default ResetGateToGlobalIcon;
