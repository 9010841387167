import { FC, forwardRef, memo, MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';
import { useParamsExperimentId, useRerender } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { LAST_EDIT } from '@/helpers';
import clamp from '@/helpers/clamp';

import styles from './LaneController.module.scss';

const cn = classnames.bind(styles);

const Thumb = forwardRef<HTMLDivElement>((_, forwardedRef) => (
  <div className={cn('lane__thumb')} ref={forwardedRef}>
    <svg
      viewBox="0 0 4 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('lane__thumb-bracket', 'lane__thumb-bracket_left')}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M4 1C 2 1 1 2 1 4 V 12 M 1 12 C 1 15 2 15 4 15"
      />
    </svg>
    <svg
      viewBox="0 0 4 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('lane__thumb-bracket', 'lane__thumb-bracket_right')}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M4 1C 2 1 1 2 1 4 V 12 M 1 12 C 1 15 2 15 4 15"
      />
    </svg>
  </div>
));
Thumb.displayName = 'Thumb';

export type TLaneControllerProps = {
  laneId: string;
  viewportWidth: number;
  xNormal: number;
  widthNormal: number;
  label: string | number;
  isActive: boolean;
};

const LaneController: FC<TLaneControllerProps> = ({ laneId, viewportWidth, xNormal, widthNormal, label, isActive }) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);

  const trackRef = useRef<Nullable<HTMLDivElement>>(null);
  const dragOffset = useRef(0);
  const thumbRef = useRef<Nullable<HTMLDivElement>>(null);
  const isHandled = useRef(false);
  const rerender = useRerender();

  // todo: add resize
  const { x: trackPosition, width: trackWidth } = useMemo(() => {
    if (!trackRef.current) {
      return { x: 0, width: 0 };
    }

    return trackRef.current.getBoundingClientRect();
  }, [trackRef.current]);

  const calculateThumbPosition = useCallback(
    (newXNormal: number) => {
      const { left, right } = getThumbProps(newXNormal);
      thumbRef.current?.style.setProperty('--left', String(left));
      thumbRef.current?.style.setProperty('--right', String(right));
    },
    [widthNormal, trackRef.current]
  );

  const setNavigatorLanePosition = useCallback(
    (newXNormal: number) => {
      appDispatch(
        navigatorActions.setPosition({
          experimentId,
          laneId,
          options: {
            x: newXNormal * viewportWidth,
            lastEdit: LAST_EDIT.LANE,
          },
        })
      );
    },
    [experimentId, laneId, viewportWidth]
  );

  const handleLaneClick = useCallback(() => {
    if (laneId === currentLaneId) {
      return;
    }
    appDispatch(navigatorActions.setCurrentLaneId({ experimentId, laneId }));
  }, [laneId, currentLaneId]);

  useEffect(() => {
    calculateThumbPosition(xNormal);
  }, [xNormal, trackWidth, widthNormal]);

  useEffect(() => {
    // for rerender after settings closing
    rerender();
  }, []);

  const getThumbProps = useCallback(
    (newXNormal: number) => {
      const minThumbWidth = 16;
      const thumbLeft = Math.floor((newXNormal - widthNormal / 2) * trackWidth) ?? 0;
      const thumbRight = Math.floor((1 - newXNormal - widthNormal / 2) * trackWidth) ?? 0;
      const boundedLeft = clamp(0, thumbLeft, trackWidth - minThumbWidth);
      const boundedRight = clamp(0, thumbRight, trackWidth - boundedLeft - minThumbWidth);
      return {
        left: boundedLeft,
        right: boundedRight,
      };
    },
    [widthNormal, trackRef.current, trackWidth]
  );

  const handleCarriageDrag = useCallback(
    (ev: MouseEvent<HTMLDivElement>) => {
      isHandled.current = true;
      if (!trackPosition) {
        return;
      }

      const mouseUpHandler = () => {
        handleCarriageDrop();
      };

      document.addEventListener('mouseup', mouseUpHandler, { once: true });
      dragOffset.current = ev.clientX - trackPosition - xNormal * trackWidth;
    },
    [xNormal, trackPosition]
  );

  const handleCarriageDrop = useCallback(() => {
    isHandled.current = false;
  }, [trackWidth]);

  const handleCarriageMove = (ev: MouseEvent<HTMLButtonElement>) => {
    if ((widthNormal === 1 && xNormal - 0.5 < 0.01) || Number.isNaN(xNormal)) {
      return;
    }

    if (!isHandled.current) {
      return;
    }
    const newX = Math.floor(ev.clientX - trackPosition - dragOffset.current);
    calculateThumbPosition(newX / trackWidth);
    setNavigatorLanePosition(newX / trackWidth);
  };

  return (
    <button
      className={cn('lane', { lane_active: isActive })}
      onMouseMove={handleCarriageMove}
      onMouseUp={handleCarriageDrop}
      onClick={handleLaneClick}
    >
      <div className={cn('lane__wrap')}>
        <div className={cn('lane__label')}>{label}</div>
        <div className={cn('lane__track-wrap')}>
          <div
            className={cn('lane__track', { lane__track_hidden: !isActive })}
            ref={trackRef}
            onMouseDown={handleCarriageDrag}
            role="presentation"
          >
            <Thumb ref={thumbRef} />
          </div>
        </div>
      </div>
    </button>
  );
};

export default memo(LaneController);
