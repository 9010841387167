import { FC } from 'react';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';
import { ECheckedState, TCheckedState } from '@/components/common/CheckboxInput/types';
import styles from '../CheckboxInput.module.scss';
import type { TCheckboxInputProps, TCheckboxProps } from '../index';

const cn = classnames.bind(styles);

type TCheckboxBodyProps = { checkedState: TCheckedState } & Partial<
  Pick<TCheckboxInputProps, 'label' | 'disabled' | 'multiline'>
> &
  Partial<Pick<TCheckboxProps, 'isLabelAtLeft' | 'children'>>;

const CheckboxBody: FC<TCheckboxBodyProps> = ({
  checkedState,
  label,
  isLabelAtLeft,
  disabled,
  children,
  multiline,
}) => (
  <>
    {checkedState === ECheckedState.checked && <icons.CheckboxCheckedIcon className={cn('checkbox__icon')} />}
    {checkedState === ECheckedState.indeterminate && <icons.CheckboxDashIcon className={cn('checkbox__icon')} />}
    {!!label && (
      <span
        className={cn('checkbox__label', {
          checkbox__label_left: isLabelAtLeft,
          checkbox__label_checked: checkedState === ECheckedState.indeterminate,
          checkbox__label_disabled: disabled,
          checkbox__label_multiline: multiline,
        })}
      >
        {label}
      </span>
    )}
    {children}
  </>
);
export default CheckboxBody;
