import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { useDatasetsOptionsInit } from '@/hooks/datasetList/useDatasetsOptionsInit';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import useParamsExperimentId from '@/hooks/useParamsExperimentId';

import { appAPI } from '@/store/services/app';
import { datasetsSelectors, datasetsActions } from '@/store/slices/datasets';
import { experimentSelectors } from '@/store/slices/experiment';

import Panel from '@/components/common/Panel';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import IconsAssaySet from '@/components/common/IconsAssaySet';
import SwitchInput from '@/components/common/SwitchInput';
import DatasetsFilters from '@/components/common/DatasetsFilters';

import styles from './DatasetsOptions.module.scss';

const cn = classnames.bind(styles);

const DatasetsOptions: FC = () => {
  const appDispatch = useAppDispatch();

  const experimentId = useParamsExperimentId();
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);

  const isDatasetsView = useSelector(datasetsSelectors.selectIsDatasetsView);
  const channelDetailsList = useSelector(datasetsSelectors.selectChannelDetailsList(experimentId));
  const includedChannels = useSelector(datasetsSelectors.selectIncludedChannels);
  const includedTimePoints = useSelector(datasetsSelectors.selectIncludedTimePoints);
  const hasOpenLaneDetails = useSelector(datasetsSelectors.selectHasOpenLaneDetails(experimentId));
  const filterAssayId = useSelector(datasetsSelectors.selectFilterAssayId);

  const { uniqueChannelNameList } = useDatasetsOptionsInit();

  const timePointOptionList = useMemo(() => {
    const timePointList = channelDetailsList.map((channelDetails) => channelDetails.timePoint);
    return scanList.map((scan) => ({
      time: scan.time,
      timeNode: scan.timeNode,
      disabled: !timePointList.includes(scan.time),
    }));
  }, [channelDetailsList, scanList, includedTimePoints]);
  const isPreprocessing = useSelector(datasetsSelectors.selectIsPreprocessingFilteredAssay(experimentId));

  const { data: assayList = [] } = appAPI.useFetchAssayListQuery();

  const filterAssay = useMemo(() => {
    const assay = assayList.find((assayItem) => assayItem.id === filterAssayId) ?? null;
    if (!assay) {
      return assay;
    }

    const assayWithPreprocessing: TAssay = {
      ...assay,
      properties: {
        ...assay.properties,
        isPreprocessing,
      },
    };
    return assayWithPreprocessing;
  }, [assayList, filterAssayId, isPreprocessing]);

  const toggleIsDatasetsView = () => {
    appDispatch(datasetsActions.toggleIsDatasetsView());
  };

  const toggleTimePoint = (timePoint: string) => {
    appDispatch(datasetsActions.toggleIncludedTimePoint(timePoint));
  };

  const handleBackClick = () => {
    appDispatch(datasetsActions.setIsDatasetsView(false));
    appDispatch(datasetsActions.setFilterAssayId(null));
  };

  return (
    <Panel theme={themeOptions.light} className={cn('datasets-options')}>
      <Panel.Content className={cn('datasets-options__paner-wrapper')}>
        {!!filterAssay && (
          <div className={cn('datasets-options__panel', 'datasets-options__assay')}>
            <Button onClick={handleBackClick} color="white" className={cn('datasets-options__button')}>
              <icons.ArrowBackIcon />
            </Button>
            <IconsAssaySet assayList={[filterAssay]} color="yellow" withPreprocessingIcon />
            <span className={cn('datasets-options__assay-name')}>Assay: {filterAssay.name}</span>
          </div>
        )}
        {!filterAssay && (
          <div className={cn('datasets-options__panel', 'datasets-options__viewas', 'viewas')}>
            <span className={cn('viewas__label')}>View as:</span>
            <SwitchInput
              checked={!isDatasetsView}
              onChange={toggleIsDatasetsView}
              offLabel="Samples"
              onLabel="Assays"
            />
          </div>
        )}
        {isDatasetsView && hasOpenLaneDetails && (
          <DatasetsFilters
            channelNameList={uniqueChannelNameList}
            timePointOptionList={timePointOptionList}
            includedChannels={includedChannels}
            includedTimePoints={includedTimePoints}
            toggleTimePoint={toggleTimePoint}
            className={cn('datasets-options__filters')}
          />
        )}
      </Panel.Content>
    </Panel>
  );
};

export default DatasetsOptions;
