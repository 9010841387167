import { FC, useCallback } from 'react';
import classnames from 'classnames/bind';

import Modal from '@/components/common/Modal';

import PreviewImageModalContent, { TPreviewImageModalContentProps } from './PreviewImageModalContent';
import styles from './PreviewImage.module.scss';

const cn = classnames.bind(styles);

const PreviewImage: FC<TPreviewImageModalContentProps> = ({ isOpen, setIsOpen, ...props }) => {
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className={cn('preview-image')} animationType="splash-animation">
      <Modal.Content className={cn('preview-image__wrapper')}>
        <PreviewImageModalContent isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
      </Modal.Content>
    </Modal>
  );
};

export default PreviewImage;
