import { FC } from 'react';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import FilterInputWithSuggestionFromData from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/FilterInputWithSuggestionFromData';
import FilterInput from '../FilterInput';

type TReagentFilterBlockProps = {
  filterState: Record<string, string>;
  updateFactory: (field: string) => (value: string) => void;
  data?: TReturnTransformedTypes[];
};

const ReagentFilterBlock: FC<TReagentFilterBlockProps> = ({ filterState, updateFactory, data }) => (
  <>
    <FilterInputWithSuggestionFromData
      value={filterState.vendor}
      placeholder="Vendor"
      onChange={updateFactory('vendor')}
      data={data}
      field="vendor"
    />
    <FilterInput value={filterState.catalogIndex} placeholder="Catalog #" onChange={updateFactory('catalogIndex')} />
    {/* <ExInput value={filterState.ex} onChange={updateEx} /> */}
    {/* <EmInput value={filterState.em} onChange={updateEm} /> */}
  </>
);

export default ReagentFilterBlock;
