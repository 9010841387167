import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import styles from './VisibleInView.scss';

const cn = classnames.bind(styles);

const VisibleInView = ({
  children,
  placeholderClassName,
  className,
}: {
  children: React.ReactNode;
  placeholderClassName?: string;
  className?: string;
}) => {
  const [isLoaded, setIsLoaded] = useState(false); // Track when content is fully loaded
  const placeholderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => setIsLoaded(true), 50);
          } else {
            setIsLoaded(false);
          }
        });
      },
      { threshold: 0.1, rootMargin: '50px' }
    );

    const currentElement = placeholderRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <div ref={placeholderRef} className={cn('in-view')}>
      {isLoaded ? (
        <div className={cn('in-view__content', className)}>{children}</div>
      ) : (
        <div className={cn('in-view__placeholder', placeholderClassName)} />
      )}
    </div>
  );
};

export default VisibleInView;
