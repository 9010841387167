import { FC } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import packageJson from '~/package.json';
import styles from './HomeHeader.module.scss';
import headerBgImage from '../../images/header.png';
import dotsImage from '../../images/dots.png';

const cn = classNames.bind(styles);

const HomeHeader: FC = () => (
  <div className={cn('header')}>
    <img className={cn('header__bg-image')} src={headerBgImage} alt="abstract tubes" role="presentation" />
    <img className={cn('header__front-image')} src={dotsImage} alt="abstract dots" role="presentation" />
    <div className={cn('header__column', 'header__column_wide')}>
      <h1 className={cn('header__title')}>Cellanome Cloud</h1>
      <div className={cn('header__text', 'header__version')}>{packageJson.version}</div>
    </div>
    <div className={cn('header__column')}>
      <div className={cn('header__text', 'header__question')}>
        Want to learn more
        <br />
        about Cellanome Technology?
      </div>
      <Link
        to={process.env.REACT_APP_WIKI_URL ?? ''}
        color="light"
        className={cn('header__learn-link')}
        target="_blank"
      >
        Learn more
      </Link>
    </div>
  </div>
);

export default HomeHeader;
