import { TRenderOption } from '@/store/slices/navigator/types';
import { parseJSON } from '@/helpers';
import { getChannelName, getChannelShaderColor } from '@/helpers/channels';

export const CHANNEL_OPTIONS_STORAGE_KEY = 'render-options';

export const saveRenderOptionsInLocalStorage = (
  newRenderOptionsData: {
    experimentId: string;
    channelId: string;
    options: Partial<TRenderOption>;
  },
  currentRenderoptions: Record<string, TRenderOption>
) => {
  const { channelId, experimentId, options } = newRenderOptionsData;
  let newOptions: Record<string, Record<string, TRenderOption>> = parseJSON(
    localStorage.getItem(CHANNEL_OPTIONS_STORAGE_KEY) ?? ''
  ) ?? null;

  if (!newOptions) {
    newOptions = {
      [experimentId]: {
        [channelId]: { ...currentRenderoptions[channelId], ...options },
      },
    };
  }

  newOptions[experimentId] = {
    ...(newOptions[experimentId] && { ...newOptions[experimentId] }),
    [channelId]: {
      ...currentRenderoptions[channelId],
      ...options,
    },
  };

  localStorage.setItem(CHANNEL_OPTIONS_STORAGE_KEY, JSON.stringify(newOptions));
};

export const getInitialRenderOptionsByChannel = (channelId: string): TRenderOption => {
  const channelName = channelId.toLowerCase() === 'white.caging' ? channelId : getChannelName(channelId, false);
  return {
    label: channelName,
    color: getChannelShaderColor(channelId),
    range: [0, 255],
    isActive: true,
    isUseLut: false,
    lutType: '',
  };
};
