import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import styles from './NavigatorSettings.module.scss';

const cn = classnames.bind(styles);

type TSettingsProps = {
  children: ReactNode;
  className?: string;
};

type TSettingsRowProps = {
  children: ReactNode;
  className?: string;
};

const SettingsRow: FC<TSettingsRowProps> = ({ children, className }) => (
  <div className={cn('navigator-settings__row', className)}>{children}</div>
);

type TSettingsExtensions = {
  Row: typeof SettingsRow;
};

const NavigatorSettings: FC<TSettingsProps> & TSettingsExtensions = ({ children, className }) => (
  <div className={cn('navigator-settings', className)}>
    <div className={cn('navigator-settings__wrap')}>{children}</div>
  </div>
);

NavigatorSettings.Row = SettingsRow;

export default NavigatorSettings;
