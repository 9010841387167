import hexToRgb from '@/hooks/useWebgl/helpers/hexToRgb';
import changeLut from '@/hooks/useWebgl/helpers/changeLut';

export function render(
  gl: WebGLRenderingContext,
  uniforms: Record<string, Nullable<WebGLUniformLocation>>,
  settings: TRenderOptions,
  lutTextureList: Record<string, Nullable<WebGLTexture>>
) {
  if (!gl || !uniforms || !settings.min?.length) {
    return;
  }
  gl.uniform1iv(uniforms.min, settings.min);
  gl.uniform1iv(uniforms.max, settings.max);
  gl.uniform1iv(uniforms.isActive, settings.isActive);

  const colors = settings.color.reduce((acc: [number, number, number][], color: string) => {
    acc.push(hexToRgb(color));

    return acc;
  }, []);

  gl.uniform3fv(uniforms.rgb, colors.flat());
  gl.uniform1iv(uniforms.isUseLut, settings.isUseLut);
  changeLut(gl, settings.lutTypeList, lutTextureList);

  gl.drawArrays(gl.TRIANGLES, 0, 6);
}
