import type { TBaseHistogramSettings, TInitialHistogramSettingsState } from './types';

export function getStateObject(state: TInitialHistogramSettingsState, fullScreenChartDataId?: string) {
  return state.specificDatasetOptionMap?.[fullScreenChartDataId ?? ''] ?? state;
}

export function getStateValue<T extends TInitialHistogramSettingsState, K extends keyof TBaseHistogramSettings>(
  state: T,
  key: K,
  fullScreenChartDataId?: Nullable<string>
): TBaseHistogramSettings[K] {
  const currentState = fullScreenChartDataId ? state.specificDatasetOptionMap?.[fullScreenChartDataId] : state;
  return currentState?.[key] ?? state?.[key];
}
