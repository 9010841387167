import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors, TRunDesignOpticsSettingsItem } from '@/store/slices/experimentRunDesign';

import { OpticsSettingsItem } from '@/graphql/API';
import { removeDuplicates } from '@/helpers';
import { isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';
import {
  DEFAULT_EXPOSURE,
  DEFAULT_INTENSITY,
  DEFAULT_ZOFFSET,
} from '@/pages/experiment-run-design/OpticalSettings/constants';
import { getDefaultWavesConfig } from '@/helpers/waveLengths';

export const useGlobalOpticalSettings = () => {
  const globalOpticalSettings = useSelector(experimentRunDesignSelectors.selectGlobalOpticalSettings);
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);
  const prevSettingsRef = useRef<Nullable<TRunDesignOpticsSettingsItem[]>>(null);

  const [opticalSettings, setOpticalSettings] = useState<TRunDesignOpticsSettingsItem[]>(globalOpticalSettings ?? []);

  useEffect(() => {
    if (!opticalMatrixSettings?.length) {
      return;
    }

    const globalSettingsFromAPI = globalOpticalSettings ?? [];
    const defaultExLabel = getDefaultWavesConfig().ex.label;
    const defaultEmLabel = getDefaultWavesConfig().em.label;

    const globalSettingsAPIByKeyMap = globalSettingsFromAPI?.reduce<Record<string, OpticsSettingsItem>>((acc, item) => {
      const key = `${item.opticalDisplay?.detection?.symbol ?? defaultExLabel}_${
        item.opticalDisplay?.excitation?.symbol ?? defaultEmLabel
      }`;
      acc[key] = item;
      return acc;
    }, {});

    const globalSettings: OpticsSettingsItem[] = removeDuplicates(opticalMatrixSettings, 'reagentId').map((setting) => {
      const reagentInfo = setting.consumable ?? null;
      const isConsumableWithWaves = isReagentConsumable(reagentInfo) || isStainConsumable(reagentInfo);
      const excitationWavelength = isConsumableWithWaves ? reagentInfo?.excitationWavelength ?? 0 : 0;
      const detectionWavelength = isConsumableWithWaves ? reagentInfo?.detectionWavelength ?? 0 : 0;
      const opticalDisplay = isConsumableWithWaves ? reagentInfo?.opticalDisplay : null;

      let settingsFromAPIKey = '';

      settingsFromAPIKey = `${setting.opticalDisplay?.detection?.symbol ?? defaultExLabel}_${
        setting.opticalDisplay?.excitation?.symbol ?? defaultEmLabel
      }`;

      return {
        __typename: 'OpticsSettingsItem',
        detectionWavelength,
        excitationWavelength,
        exposure: globalSettingsAPIByKeyMap[settingsFromAPIKey]?.exposure ?? DEFAULT_EXPOSURE,
        intensity: globalSettingsAPIByKeyMap[settingsFromAPIKey]?.intensity ?? DEFAULT_INTENSITY,
        zOffset: globalSettingsAPIByKeyMap[settingsFromAPIKey]?.zOffset ?? DEFAULT_ZOFFSET,
        opticalDisplay,
      };
    });

    const filteredSettings = [
      ...new Map(
        globalSettings.map((item) => {
          const itemKey = `${item.opticalDisplay?.detection?.symbol}_${item.opticalDisplay?.excitation?.symbol}`;
          return [itemKey, item];
        })
      ).values(),
    ];

    setOpticalSettings(filteredSettings);
    prevSettingsRef.current = filteredSettings;
  }, [globalOpticalSettings, opticalMatrixSettings]);

  useEffect(
    () => () => {
      prevSettingsRef.current = null;
    },
    []
  );

  return useMemo(
    () => ({
      opticalSettings,
      setOpticalSettings,
      prevGlobalSettingsRef: prevSettingsRef,
    }),
    [opticalSettings, setOpticalSettings, prevSettingsRef]
  );
};
