import { isIncubationComponent } from '@/helpers/runDesigns/typeGuards';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import copyState from './helpers/copyState';
import { EMediaActionList, TMediaReducerAction } from './types';

const updateIncubationHasTreatment = (state: TRunDesignComponent[], placementIndex: number, payload: any) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isIncubationComponent(currentComponent)) {
    return newState;
  }

  currentComponent.hasTreatment = payload.hasTreatment;

  return newState;
};

const mediaReducer = (state: TRunDesignComponent[], action: TMediaReducerAction) => {
  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EMediaActionList.updateIncubationHasTreatment) {
    return updateIncubationHasTreatment(state, placementIndex, action.payload);
  }

  throw new Error('Unknown edit incubation action.');
};

export default mediaReducer;
