import { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import CheckboxInput from '@/components/common/CheckboxInput';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import styles from '../MainBlock.module.scss';

const cn = classnames.bind(styles);

type TIncubationBlockProps = {
  component: TRunDesignComponent;
  onChange: (step: string, isChecked: boolean) => void;
  checkedByDefault: boolean;
  disabled?: boolean;
};

const StainStepsSwitch: FC<TIncubationBlockProps> = ({ component, onChange, checkedByDefault, disabled }) => {
  const [isChecked, setIsChecked] = useState(checkedByDefault);

  const onSwitchChange = () => {
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    // only call onChange if the component has been interacted with
    if (isChecked !== checkedByDefault) {
      onChange(component.id, isChecked);
    }
  }, [isChecked, onChange, component.id]);

  return (
    <div key={component.id} className={cn('main-block__toggle-box')}>
      <CheckboxInput
        checked={isChecked}
        isSwitch
        theme="run-design"
        onChange={onSwitchChange}
        disabled={disabled}
        label={component.name}
        labelClassName={cn('main-block__checkbox-label')}
      />
    </div>
  );
};

export default StainStepsSwitch;
