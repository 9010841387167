import { SVGProps } from 'react';

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M16 5H5a4 4 0 0 0-4 4v6h0M16 5l-3.536-3.536M16 5l-3.536 3.535" />
    </g>
  </svg>
);

export default ArrowRightIcon;
