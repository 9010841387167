import { SVGProps } from 'react';

const ViewerStretchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22" height="14" viewBox="0 0 22 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.846 1h12.308c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v4.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 0 1-1.135 1.134c-.489.262-.974.401-2.31.401H4.844c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 0 1 1.4 11.464C1.139 10.976 1 10.491 1 9.155v-4.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 0 1 2.536 1.4C3.024 1.139 3.509 1 4.845 1z"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default ViewerStretchIcon;
