import { MouseEvent, FC } from 'react';
import classnames from 'classnames/bind';

import { TPopoverContentProps } from './types';

import styles from './Popover.module.scss';

const cn = classnames.bind(styles);

const PopoverContent: FC<TPopoverContentProps> = ({
  options,
  closePopover,
  optionClassName,
  contentClassName,
  closeOnMouseLeave,
  isClosing,
  theme,
  contentId,
  useBackdrop = true,
}) => (
  <div className={cn('popover', { popover_closing: isClosing, popover_light: theme === 'light' })} id={contentId}>
    {useBackdrop && (
      <div className={cn('popover__backdrop')} id="popover__backdrop" role="presentation" onClick={closePopover} />
    )}
    <div onMouseLeave={() => closeOnMouseLeave && closePopover()} className={cn('popover__wrap', contentClassName)}>
      {options.map((option, index) => {
        const handleOptionClick = (e: MouseEvent<HTMLDivElement>) => {
          e?.stopPropagation();

          if (option.onClick) {
            option.onClick(option, index, e);
          }
          closePopover();
        };

        const handleMouseEnter = () => {
          if (option.onMouseEnter) {
            option.onMouseEnter(option, index);
          }
        };

        const handleMouseLeave = () => {
          if (option.onMouseLeave) {
            option.onMouseLeave(option, index);
          }
        };

        return (
          !option.hidden && (
            <div
              className={cn('popover__option', optionClassName, {
                popover__option_disabled: option.disabled,
                popover__option_inactive: option.inactive,
              })}
              role="presentation"
              onClick={handleOptionClick}
              onMouseEnter={handleMouseEnter}
              onFocus={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onBlur={handleMouseLeave}
              key={option.id}
            >
              <div
                className={cn('popover__text', {
                  popover__text_inactive: option.inactive,
                })}
              >
                {option.content ? option.content : option.title}
              </div>
              {option.icon && <div className={cn('popover__icon')}>{option.icon}</div>}
            </div>
          )
        );
      })}
    </div>
  </div>
);

export default PopoverContent;
