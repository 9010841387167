/**
 * Trimmed and modified version of the plugin react-file-icon
 * See: https://github.com/corygibbons/react-file-icon/blob/master/src/FileIcon.js
 */

import { v4 as uuidv4 } from 'uuid';
import { addTooltip } from '@/helpers';

import glyphs from './glyphs';

const VIEWBOX = {
  WIDTH: 40,
  HEIGHT: 48,
};

const ICON = {
  WIDTH: VIEWBOX.WIDTH,
  HEIGHT: VIEWBOX.HEIGHT,
  X_OFFSET: 0,
};

const FOLD = {
  HEIGHT: 12,
};

const LABEL_HEIGHT = 14;

interface IExtensionByMimeType {
  [key: string]: string;
}

const EXTENSION_BY_MIME_TYPE: IExtensionByMimeType = {
  'application/r-data-serialized': 'rds',
  'application/zip': 'zip',
  'text/csv': 'csv',
  'text/tab-separated-values': 'tsv',
  mtx: 'mtx',
};

interface IGlyphTypeByExtension {
  [key: string]: string;
}

const GLYPH_TYPE_BY_EXTENSION: IGlyphTypeByExtension = {
  zip: 'compressed',
  csv: 'spreadsheet',
  tsv: 'spreadsheet',
  rds: 'spreadsheet',
  mtx: 'spreadsheet',
};

const FileIcon = ({
  color = 'whitesmoke',
  foldColor = '#dcdcdc',
  labelColor = '#a9a9a9',
  labelTextColor = '#fff',
  glyphColor = '#cfcfcf',
  radius = 4,
  fileType = '',
  className = '',
}) => {
  const UNIQUE_ID = uuidv4();

  const extension = EXTENSION_BY_MIME_TYPE[fileType] ?? fileType.split('/')[1];
  const type = GLYPH_TYPE_BY_EXTENSION[extension] ?? '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${VIEWBOX.WIDTH} ${VIEWBOX.HEIGHT}`}
      width="100%"
      className={className}
      {...addTooltip(fileType)}
    >
      <defs>
        <clipPath id={`pageRadius${UNIQUE_ID}`}>
          <rect x={ICON.X_OFFSET} y="0" rx={radius} ry={radius} width={ICON.WIDTH} height={ICON.HEIGHT} />
        </clipPath>

        <clipPath id={`foldCrop${UNIQUE_ID}`}>
          <rect width={ICON.WIDTH} height={FOLD.HEIGHT} transform={`rotate(-45 0 ${FOLD.HEIGHT})`} />
        </clipPath>
      </defs>

      <g clipPath={`url(#pageRadius${UNIQUE_ID})`}>
        <path
          d={`M${ICON.X_OFFSET} 0 h ${ICON.WIDTH - FOLD.HEIGHT} L ${ICON.WIDTH + ICON.X_OFFSET} ${FOLD.HEIGHT} v ${
            ICON.HEIGHT - FOLD.HEIGHT
          } H ${ICON.X_OFFSET} Z`}
          fill={color}
        />

        <g transform={`translate(28 ${FOLD.HEIGHT}) rotate(-90)`}>
          <rect
            width={ICON.WIDTH}
            height={ICON.HEIGHT}
            fill={foldColor}
            rx={radius}
            ry={radius}
            clipPath={`url(#foldCrop${UNIQUE_ID})`}
          />
        </g>
      </g>

      {extension && (
        <>
          <g id={`label${UNIQUE_ID}`}>
            <rect
              fill={labelColor}
              x={ICON.X_OFFSET}
              y={ICON.HEIGHT - LABEL_HEIGHT}
              width={ICON.WIDTH}
              height={LABEL_HEIGHT}
              clipPath={`url(#pageRadius${UNIQUE_ID})`}
            />
          </g>
          <g id={`labelText${UNIQUE_ID}`} transform={`translate(${ICON.X_OFFSET} 34)`}>
            <text
              x={ICON.WIDTH / 2}
              y="10"
              fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              fontSize="9"
              fill={labelTextColor}
              textAnchor="middle"
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                pointerEvents: 'none',
                textTransform: 'uppercase',
                userSelect: 'none',
              }}
            >
              {extension}
            </text>
          </g>
        </>
      )}

      {type && (
        <g transform={`translate(-4 ${!extension ? 6 : 0})`} fill={glyphColor}>
          {/* @ts-ignore */}
          {glyphs[type]}
        </g>
      )}
    </svg>
  );
};

export default FileIcon;
