import { useMemo } from 'react';
import type {
  TGeneralChartProps,
  TDatasetChartHeading,
  TDatasetChart,
  TGateList,
} from '../components/charts/SingleChartWithGates/types';
import { useUpdateExperimentGate } from './gates/useUpdateGates';

type TUseChartWithGatesProps = TGeneralChartProps;

export type TExcludedProps = 'isTransitionEnd' | 'scatterPlotAxesOptions' | 'isExpandMode' | 'isAsideOpen' | 'pageType';

export const useChartWithGatesProps = ({
  cageEntitiesByLanesAndGates,
  changeSelectedChartData,
  channelName,
  chartDataList,
  currentAppLane,
  entitiesByLanesAndGates,
  entitiesLoadedLaneList,
  entityLevelGateList,
  experimentId,
  isError,
  isGateListLoading,
  laneId,
  scanId,
  scanList,
  selectedChartData,
  currentAppDatasetList,
  withoutSearchParams,
  fullGateList = [],
  createGate,
  updateGate,
  deleteGate,
  deleteGateChildren,
  isCreateGatesInProgress,
  isUpdateGatesInProgress,
  isDeleteGatesInProgress,
  isDeleteGatesChildrenInProgress,
  onlyShowAxisValue,
  plotId,
  needResetRange,
  xAxisSpecific,
  yAxisSpecific,
  handleSwapChannels,
  plotRangeName,
}: TUseChartWithGatesProps) => {
  const { createExperimentGate, deleteExperimentGate, deleteExperimentGateChildren, updateExperimentGate } =
    useUpdateExperimentGate({
      experimentId,
      fullGateList,
      laneId,
      scanId,
      createGate,
      deleteGate,
      deleteGateChildren,
      updateGate,
    });

  const headingProps = useMemo<Omit<TDatasetChartHeading, 'isExpandMode' | 'isAsideOpen'>>(
    () => ({
      channelName,
      laneId,
      scanId,
      scanList,
      createGate: createExperimentGate,
      updateGate: updateExperimentGate,
      deleteGate: deleteExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      withoutSearchParams,
    }),
    [
      channelName,
      laneId,
      scanId,
      scanList,
      createGate,
      deleteGate,
      deleteGateChildren,
      updateGate,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      withoutSearchParams,
    ]
  );

  const chartProps = useMemo<Omit<TDatasetChart, TExcludedProps>>(
    () => ({
      channelName,
      currentAppLane,
      entitiesByLanesAndGates,
      entitiesLoadedLaneList,
      entityLevelGateList,
      experimentId,
      isCreateGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      laneId,
      scanId,
      scanList,
      chartDataList,
      currentAppDatasetList,
      withoutSearchParams,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      fullGateList,
      onlyShowAxisValue,
      plotId,
      needResetRange,
      xAxisSpecific,
      yAxisSpecific,
      plotRangeName,
      createGate: createExperimentGate,
      updateGate: updateExperimentGate,
      deleteGate: deleteExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      handleSwapChannels,
    }),
    [
      createGate,
      currentAppLane,
      deleteGate,
      deleteGateChildren,
      entitiesByLanesAndGates,
      entitiesLoadedLaneList,
      entityLevelGateList,
      experimentId,
      isCreateGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      laneId,
      scanId,
      scanList,
      updateGate,
      chartDataList,
      currentAppDatasetList,
      withoutSearchParams,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      fullGateList,
      channelName,
      onlyShowAxisValue,
      plotId,
      needResetRange,
      xAxisSpecific,
      yAxisSpecific,
      plotRangeName,
      handleSwapChannels,
    ]
  );

  const gatesProps = useMemo<Omit<TGateList, TExcludedProps>>(
    () => ({
      cageEntitiesByLanesAndGates,
      changeSelectedChartData,
      chartDataList,
      currentAppLane,
      entitiesByLanesAndGates,
      entityLevelGateList,
      experimentId,
      isGateListLoading,
      isUpdateGatesInProgress,
      scanList,
      selectedChartData,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      createGate: createExperimentGate,
      updateGate: updateExperimentGate,
      deleteGate: deleteExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      channelName,
      plotRangeName,
    }),
    [
      cageEntitiesByLanesAndGates,
      changeSelectedChartData,
      chartDataList,
      currentAppLane,
      deleteGate,
      deleteGateChildren,
      entitiesByLanesAndGates,
      entityLevelGateList,
      experimentId,
      isGateListLoading,
      isUpdateGatesInProgress,
      scanList,
      selectedChartData,
      updateGate,
      createGate,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      channelName,
      plotRangeName,
    ]
  );

  const generalProps = useMemo(
    () => ({
      scanList,
      scanId,
      laneId,
      chartDataList,
    }),
    [scanList, scanId, laneId, chartDataList]
  );

  return {
    headingProps,
    chartProps,
    gatesProps,
    generalProps,
    allProps: { ...headingProps, ...chartProps, ...gatesProps, ...generalProps },
  };
};
