import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useCellChannelTypes from '@/hooks/preprocessing/useCellChannelTypes';

import { preprocessingSelectors, preprocessingActions } from '@/store/slices/preprocessing';
import { EStepName } from '@/store/slices/preprocessing/types';

import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import { scatterplotsActions } from '@/store/slices/scatterplots';
import { gatesActions } from '@/store/slices/gates';
import { getCorrectDimension } from '@/helpers/charts/chartsData';
import useSelectedGateForSpecificDataset from '@/hooks/preprocessing/useSelectedGateForSpecificDataset';

import PreprocessingDatasetChart from '../../components/PreprocessingDatasetChart';
import StepActions, { TAction, TActionGroup } from '../../components/StepActions';

import styles from '../../PreprocessingSteps.module.scss';
import PreprocessingHeader from '../../components/PreprocessingHeader';
import PreprocessingStepHeader from '../../components/PreprocessingStepHeader';
import Gates from '../Gates';
import InstructionItem from '../../components/InstructionItem';
import CreateGatePopover from '../CreateGatePopover';
import { CELL_TYPES_FOR_PUPULATION } from '../constants';

const cn = classnames.bind(styles);

type TStepCellKillingDefineCells = {
  className?: string;
};

const title = 'STEP 3. Define target/effector and live/dead cells populations';
const DEFAULT_DATASET_INDEX = 0;

const StepCellKillingDefineCellsTarget: FC<TStepCellKillingDefineCells> = ({ className }) => {
  const appDispatch = useAppDispatch();

  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const isNextStepEnabled = useSelector(preprocessingSelectors.selectNextStepEnabled);
  const defaultDataset = useSelector(preprocessingSelectors.selectCurrentDataset);
  const cellXChannel = useSelector(preprocessingSelectors.selectCellXChannel);
  const cellYChannel = useSelector(preprocessingSelectors.selectCellYChannel);
  const selectedGate = useSelectedGateForSpecificDataset();
  const shapes = useSelector(preprocessingSelectors.selectGeneralCellShapes);
  const cellTypes = useSelector(preprocessingSelectors.selectCellTypes);
  const channelOptionMap = useSelector(preprocessingSelectors.selectChannelOptionMap);
  const axesOptionMap = useSelector(preprocessingSelectors.selectAxesOptionMap);

  useCellChannelTypes();

  const datasetDetails = useMemo(() => {
    const dataset = datasets[DEFAULT_DATASET_INDEX];
    return dataset;
  }, [datasets]);

  const getAxisByChannel = useCallback(
    (channelName: string) => {
      const datasetData = datasetDetails ?? defaultDataset;

      const axisByChannel = getCorrectDimension(channelName, datasetData.dataset.name, channelOptionMap);
      const axis = Object.keys(axesOptionMap).find(
        (key) => axesOptionMap[key]?.[datasetData.dataset.name] === axisByChannel
      );

      return axis;
    },
    [datasetDetails, axesOptionMap, channelOptionMap]
  );

  const onChangeChannel = useCallback(
    (channelName: string, axisName: 'x' | 'y') => {
      const currentChannelByType = {
        x: cellXChannel,
        y: cellYChannel,
      };

      if (selectedGate && channelName !== currentChannelByType[axisName]) {
        appDispatch(preprocessingActions.clearGeneralCellShapeNodes(selectedGate.id));
      }

      if (axisName === 'x') {
        appDispatch(preprocessingActions.setCellXChannel(channelName));
      } else {
        appDispatch(preprocessingActions.setCellYChannel(channelName));
      }

      let newAxis = getAxisByChannel(channelName);
      if (!newAxis) {
        newAxis = channelName ? `${channelName}_mean` : '';
      }
      appDispatch(
        scatterplotsActions.setAxes({
          newAxes: { [axisName]: newAxis },
        })
      );
    },
    [selectedGate, getAxisByChannel, cellXChannel, cellYChannel]
  );

  const instructions = useMemo(
    () => [
      {
        id: 1,
        text: 'Select target cells (Y-axis) channel:',
        onChange: (channelName: string) => {
          onChangeChannel(channelName, 'y');
        },
        value: cellYChannel,
      },
      {
        id: 2,
        text: 'Select dead cells (X-axis) channel:',
        onChange: (channelName: string) => {
          onChangeChannel(channelName, 'x');
        },
        value: cellXChannel,
      },
    ],
    [cellYChannel, cellXChannel, onChangeChannel]
  );

  const InstructionElements = useMemo(() => {
    const instructionList = instructions.map(({ id, value, text, onChange }) => (
      <InstructionItem key={id} currentValue={value} text={text} onChange={onChange} />
    ));
    const item = (
      <span className={cn('custom-instruction', { 'custom-instruction_disabled': !(cellXChannel && cellYChannel) })}>
        Use click-and-drag functionality on the graph to set up 4 gates: target cells live, target cells dead, effector
        cells live, and effector cells dead
      </span>
    );
    return [...instructionList, item];
  }, [instructions]);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          // TODO: add handler to the separate method
          if (selectedGate) {
            appDispatch(gatesActions.setSelectedGate(null));
          }
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCellKillingReviewCells));
          appDispatch(preprocessingActions.setCellTypes(CELL_TYPES_FOR_PUPULATION));
        },
      },
      {
        label: 'Next',
        disabled: !isNextStepEnabled,
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCellKillingReviewCellsTarget));
        },
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isNextStepEnabled]);

  const createGate: TGeneralChartProps['createGate'] = useCallback(
    (gate) => {
      if (!datasetDetails) {
        return;
      }

      const { gateNodes, level, shape, parentId, properties, xDimension, yDimension } = gate.newGate;
      appDispatch(
        preprocessingActions.addGeneralCellShape({
          gateNodes,
          level,
          shape,
          parentId,
          properties,
          xDimension,
          yDimension,
        })
      );
    },
    [appDispatch]
  );

  const updateGate: TGeneralChartProps['updateGate'] = useCallback(
    (data) => {
      const { gateId, updatedGateList } = data;
      const updatedGate = updatedGateList.find((el) => el.id === gateId);
      if (!updatedGate || !selectedGate) {
        return;
      }

      const { level, parentId, shape, properties, name, xDimension, yDimension } = updatedGate;

      appDispatch(
        preprocessingActions.updateGeneralCellShape({
          cellShapeId: gateId,
          parentId: selectedGate.id,
          data: { level, parentId, shape, properties, name, xDimension, yDimension },
        })
      );
    },
    [appDispatch]
  );

  const deleteGate: TGeneralChartProps['deleteGate'] = useCallback(
    (data) => {
      if (!selectedGate) return;
      const { gateId } = data;

      appDispatch(
        preprocessingActions.deleteGeneralCellShape({ parentId: selectedGate ? selectedGate.id : '', id: gateId })
      );
    },
    [appDispatch, selectedGate]
  );

  const deleteGateChildren: TGeneralChartProps['deleteGateChildren'] = useCallback(() => null, []);

  const handleSwapChannels = useCallback(() => {
    appDispatch(preprocessingActions.setCellXChannel(cellYChannel));
    appDispatch(preprocessingActions.setCellYChannel(cellXChannel));
  }, [cellXChannel, cellYChannel]);

  useEffect(() => {
    const isBlockDraw = cellTypes.length === Object.keys(shapes).length;
    appDispatch(gatesActions.setIsBlockDraw(isBlockDraw));
  }, [cellTypes.length, shapes]);

  useEffect(() => {
    appDispatch(preprocessingActions.setCurrentDatasetIndex(DEFAULT_DATASET_INDEX));

    return () => {
      appDispatch(preprocessingActions.setCurrentDatasetIndex(null));
    };
  }, []);

  return (
    <div className={cn('preprocessing-step', className)}>
      <div className={cn('preprocessing-step__header')}>
        <PreprocessingHeader customInfo="Gating for target/effector and live/dead cell populations" />
        <PreprocessingStepHeader title={title} instructions={InstructionElements} withoutBorder />
      </div>
      <div className={cn('preprocessing-step__content')}>
        <PreprocessingDatasetChart
          createGate={createGate}
          deleteGate={deleteGate}
          updateGate={updateGate}
          deleteGateChildren={deleteGateChildren}
          onlyShowAxisValue
          datasetDetails={datasetDetails ?? defaultDataset}
          GatesComponent={Gates}
          disabled={!(cellXChannel && cellYChannel)}
          CustomCreateGateComponent={CreateGatePopover}
          handleSwapChannels={handleSwapChannels}
        />
        <StepActions actionGroups={actionGroups} />
      </div>
    </div>
  );
};

export default memo(StepCellKillingDefineCellsTarget);
