import { FC } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { navigatorSelectors } from '@/store/slices/navigator';
import styles from './Coords.module.scss';

const cn = classnames.bind(styles);

type TCoordsProps = {
  laneId: string;
};

const Coords: FC<TCoordsProps> = ({ laneId }) => {
  const { x, y } = useSelector(navigatorSelectors.selectPosition(laneId));
  const formattedX = Math.floor(x);
  const formattedY = Math.floor(y);

  return (
    <div className={cn('coords')}>
      <div className={cn('coords__coordinate')}>X {formattedX}</div>
      <div className={cn('coords__coordinate')}>Y {formattedY}</div>
    </div>
  );
};

export default Coords;
