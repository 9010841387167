import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import 'react-loading-skeleton/dist/skeleton.css';

import { findLaneInScanList, findScanInScanList } from '@/helpers/scans';
import { getEntityObjectTypeOptionList } from '@/helpers/cages';
import { MDASH } from '@/helpers';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { selectAxesPresetOptionListFromScanList } from '@/store/services/app/selectors';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { themeOptions } from '@/types/theme';
import { datasetsSelectors } from '@/store/slices/datasets';
import { chartSettingsSelectors, chartSettingsActions, EEntityObjectType } from '@/store/slices/chartSettings';
import { chartDataSelectors } from '@/store/slices/chartData';

import Select from '@/components/common/Select';

import styles from './DatasetChartHeading.module.scss';
import { TDatasetChartHeading } from '../SingleChartWithGates/types';

const cn = classnames.bind(styles);

export type TDatasetChartHeadingProps = TDatasetChartHeading & {
  children?: ReactNode;
};

const DatasetChartHeading: FC<TDatasetChartHeadingProps> = ({
  isExpandMode,
  scanId,
  laneId,
  channelName,
  scanList,
  withoutSearchParams = false,
  children,
  specificChangeScanHandler,
  specificChangeChannelHandler,
}) => {
  const appDispatch = useAppDispatch();
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const chartId = usePlotChartIdContext();

  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);
  const laneDetailsList = useSelector(datasetsSelectors.selectLaneDetailsList);

  const [selectedAxesPreset, setSelectedAxesPreset] = useState<Nullable<string>>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const currentScan = useMemo(() => findScanInScanList(scanList, scanId), [scanList, scanId]);
  const currentLane = useMemo(() => findLaneInScanList(scanList, scanId, laneId), [scanList, scanId, laneId]);

  const axesPresetOptionList = useMemo(
    () => selectAxesPresetOptionListFromScanList(scanList, scanId, laneId, isObjectEntityEnabled),
    [scanList, scanId, laneId, isObjectEntityEnabled]
  );

  const chartData = useMemo(() => fullScreenChartData ?? currentChartData, [fullScreenChartData, currentChartData]);

  const laneOptionList = useMemo(
    () =>
      laneDetailsList.map((laneDetails) => ({
        label: laneDetails.sampleFriendlyName,
        value: laneDetails.id,
      })),
    [laneDetailsList]
  );

  const scanListOptions = useMemo<TOption[]>(() => {
    if (fullScreenChartData) {
      return (
        scanList
          // this filtering return only scans, which contain this lane id and channel id
          .filter((scanItem) =>
            scanItem.lanes.some(
              (lane) =>
                lane.id === chartData?.laneId && lane.channels.some((channelId) => channelId === chartData?.channelId)
            )
          )
          .map((scanItem) => ({ label: scanItem.timeNode, value: scanItem.id }))
      );
    }

    return scanList.map((scan) => ({ label: scan.timeNode, value: scan.id }));
  }, [scanList, chartData]);

  const entityObjectTypeOptions = useMemo<TOption[]>(() => getEntityObjectTypeOptionList(), []);

  const handleEntityObjectTypeChange = useCallback(
    (newObjectType: EEntityObjectType) => {
      appDispatch(chartSettingsActions.setObjectType(newObjectType));
    },
    [objectType, appDispatch]
  );

  const handleLaneChange = (newLaneId: string) => {
    if (newLaneId === laneId) {
      return;
    }

    if (withoutSearchParams) {
      return;
    }

    searchParams.set('laneId', `${newLaneId}`);
    setSearchParams(searchParams);
  };

  const changeScan = (newScanId: string) => {
    if (newScanId === scanId) {
      return;
    }

    if (specificChangeScanHandler) {
      specificChangeScanHandler(newScanId);
      return;
    }

    if (withoutSearchParams) {
      return;
    }

    searchParams.set('scanId', `${newScanId}`);
    setSearchParams(searchParams);
  };

  const handleAxesPresetChange = (newChannelName: string) => {
    if (channelName === newChannelName) {
      return;
    }

    if (specificChangeChannelHandler) {
      specificChangeChannelHandler(newChannelName);
      setSelectedAxesPreset(newChannelName);
      return;
    }

    if (withoutSearchParams) {
      return;
    }

    setSelectedAxesPreset(newChannelName);
    searchParams.set('channelName', newChannelName);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const axesPresetOption =
      axesPresetOptionList.find((option) => option.axes.xAxis === xAxis && option.axes.yAxis === yAxis)?.value ??
      channelName ??
      null;
    setSelectedAxesPreset(axesPresetOption);
  }, [xAxis, yAxis, axesPresetOptionList, channelName]);

  // set the marker name in the search params if it is present in the displayed dataset after changing the scan
  useEffect(() => {
    if (withoutSearchParams || fullScreenChartData) return;

    if (!currentChartData?.marker?.name) {
      searchParams.delete('markerName');
    } else {
      searchParams.set('markerName', currentChartData.marker.name);
    }

    setSearchParams(searchParams);
  }, [currentChartData]);

  return (
    <div className={cn('chart-heading')}>
      <h3 className={cn('chart-heading__name')}>
        {isPreprocessingView ? (
          currentLane?.sampleFriendlyName ?? ''
        ) : (
          <Select
            value={currentLane?.id}
            placeholder=""
            className={cn('chart-heading__select', { 'chart-heading__select_time': !!currentLane?.id })}
            theme={themeOptions.light}
            defaultValue={currentLane?.id}
            onChange={handleLaneChange}
            options={laneOptionList}
          />
        )}
      </h3>
      {!isPreprocessingView && (
        <>
          <span>{chartData?.friendlyName ?? MDASH}</span>
          <Select
            value={currentScan?.id}
            placeholder=""
            className={cn('chart-heading__select', { 'chart-heading__select_time': !!currentScan?.id })}
            theme={themeOptions.light}
            defaultValue={currentScan?.id}
            onChange={changeScan}
            options={scanListOptions}
          />
          <Select
            value={selectedAxesPreset}
            placeholder="Select axes preset..."
            className={cn('chart-heading__select')}
            theme={themeOptions.light}
            onChange={handleAxesPresetChange}
            options={axesPresetOptionList}
          />
        </>
      )}
      {((isExpandMode && isObjectEntityEnabled) || isPreprocessingView) && (
        <Select
          value={objectType}
          placeholder=""
          className={cn('chart-heading__select', 'select__object-type')}
          theme={themeOptions.light}
          options={entityObjectTypeOptions}
          defaultValue={objectType}
          onChange={handleEntityObjectTypeChange}
        />
      )}
      {children}
    </div>
  );
};

export default DatasetChartHeading;
