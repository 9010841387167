import { useCallback, useRef, useState } from 'react';
import { API as awsAPI } from 'aws-amplify';
import { Id, toast } from 'react-toastify';

import { ZippingStatus } from '@/graphql/API';
import * as mutations from '@/graphql/mutations';

import { downloadByUrl, TOAST_DELAY } from '@/helpers';
import { getErrorMessage } from '@/helpers/errors';
import graphqlEvents, { EOnExperimentEventType } from '@/helpers/graphqlEvents';

const ZIPPING_TIMER_MINUTES = 10;

export function useDownloadExperiment(experimentId: string, experimentData?: TExperiment) {
  const downloadUrl = useRef<Nullable<string>>(null);
  const zippingSubscription = useRef<string | undefined>(undefined);
  const unsubscribeTimer = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);
  const toastId = useRef<Nullable<Id>>(null);

  const [isExperimentZippingInProgress, setIsExperimentZippingInProgress] = useState(false);

  const showZippingProgressMessage = () => {
    toastId.current = toast.loading(
      <span>
        Experiment zipping started.
        <br />
        We will notify you when zip is ready to be downloaded.
      </span>,
      {
        className: 'toast_max-content',
      }
    );
  };

  const showDownloadStartedMessage = () => {
    if (toastId.current) {
      toast.update(toastId.current, {
        render: `Download the experiment "${experimentData?.experimentName}" has started`,
        type: 'success',
        isLoading: false,
        autoClose: TOAST_DELAY,
      });
    }
  };

  const showDownloadErrorMessage = (error?: unknown) => {
    if (toastId.current) {
      toast.update(toastId.current, {
        render: getErrorMessage(error, `Failed to download the experiment "${experimentData?.experimentName}"`),
        type: 'error',
        isLoading: false,
        autoClose: TOAST_DELAY,
      });
    }
  };

  const downloadExperiment = () => {
    if (downloadUrl.current) {
      setIsExperimentZippingInProgress(false);
      showDownloadStartedMessage();
      downloadByUrl(downloadUrl.current, `${experimentData?.experimentName}.zip`);
    }
  };

  const unsubscribeZippingTimer = () => {
    if (unsubscribeTimer.current) {
      clearTimeout(unsubscribeTimer.current);
    }
  };

  const unsubscribeZippingEvents = () => {
    if (zippingSubscription.current) {
      graphqlEvents.unsubscribe(zippingSubscription.current);
      zippingSubscription.current = undefined;
    }
  };

  const unsubscribeAll = () => {
    unsubscribeZippingTimer();
    unsubscribeZippingEvents();
  };

  const subscribeZippingEvents = async () => {
    graphqlEvents
      .subscribe(experimentId, experimentData?.organizationId ?? '', {
        eventType: EOnExperimentEventType.zipped,
        success: () => {
          downloadExperiment();
          unsubscribeAll();
        },
        error: (error: unknown) => {
          setIsExperimentZippingInProgress(false);
          unsubscribeAll();
          showDownloadErrorMessage(error);
        },
      })
      .then((callBackUuid) => {
        zippingSubscription.current = callBackUuid;
      });
  };

  const requestExperimentDownloadUrl = async () => {
    try {
      const response = await awsAPI.graphql({
        query: mutations.zipExperimentDataset,
        variables: {
          experimentId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      // @ts-ignore
      if (response?.data?.zipExperimentDataset) {
        // @ts-ignore
        const { status, url } = response.data.zipExperimentDataset;
        downloadUrl.current = url ?? null;
        if (status === ZippingStatus.READY) {
          downloadExperiment();
        } else {
          subscribeZippingEvents();
        }
      }
    } catch (error) {
      setIsExperimentZippingInProgress(false);
      showDownloadErrorMessage(error);
    }
  };

  const startExperimentDownload = useCallback(() => {
    setIsExperimentZippingInProgress(true);
    showZippingProgressMessage();

    unsubscribeZippingTimer();
    unsubscribeTimer.current = setTimeout(() => {
      if (zippingSubscription.current) {
        setIsExperimentZippingInProgress(false);
        unsubscribeZippingEvents();
        showDownloadErrorMessage();
      }
    }, ZIPPING_TIMER_MINUTES * 60000);

    requestExperimentDownloadUrl();
  }, [experimentData?.experimentName]);

  return {
    startExperimentDownload,
    isExperimentZippingInProgress,
  };
}
