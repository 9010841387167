import { FC } from 'react';
import classnames from 'classnames/bind';

import { useOpenModal } from '@/hooks/useOpenModal';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import DetailsModal from './DetailsModal';

import styles from './DetailsButton.module.scss';

const cn = classnames.bind(styles);

type TDetailsButtonProps = {
  data: TAuditLogData;
};
const DetailsButton: FC<TDetailsButtonProps> = ({ data }) => {
  const { details, description } = data;

  if (!details) {
    return null;
  }

  const { isOpen: isDetailsModalOpen, setIsOpen: setIsDetailsModalOpen, onClose: onDetailsModalClose } = useOpenModal();

  const handleButtonClick = () => {
    setIsDetailsModalOpen(true);
  };

  return (
    <>
      <Button tooltip="Details" color="light" className={cn('details-button')} onClick={handleButtonClick}>
        <icons.DetailsIcon />
      </Button>

      <DetailsModal
        isOpen={isDetailsModalOpen}
        handleCloseModal={onDetailsModalClose}
        title={description}
        details={details}
      />
    </>
  );
};
export default DetailsButton;
