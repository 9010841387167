import { EAxesScaleType, EHistogramDataGroupTypes, EPageWithChartType, EChartType, TColorscale } from '@/types/charts';

export type TCustomRange = {
  label: string;
  rangeName: string;
  isEditable?: boolean;
  range: Nullable<TPopulationRange>;
  xAxis?: string;
  yAxis?: string;
  currentChartType?: EChartType;
  axesScaleTypes?: {
    x: EAxesScaleType;
    y: EAxesScaleType;
  };
  isObjectEntityEnabled?: boolean;
  currentHistogramDataGroupType?: EHistogramDataGroupTypes;
};

export enum EEntityObjectType {
  all = 'all',
  isCell = 'cells',
  isBead = 'beads',
}

export enum EPlotRangeType {
  general = 'general',
}

export type ChartPresetSettingsСompletionStatusMap = Record<EPageWithChartType, boolean>;

export type TBaseChartSettings = {
  currentChartType: EChartType;
  axesScaleTypes: {
    x: EAxesScaleType;
    y: EAxesScaleType;
  };
  currentColorScale: TColorscale;
  isObjectEntityEnabled: boolean;
  isCageLvlForced: boolean;
  customRangesMapName: string;
  customRangesMap: Record<string, TCustomRange>;
  customPlotRange: Nullable<TPopulationRange>;
  plotRangesMap: Record<string, Nullable<TPopulationRange>>;
  plotRangesMapName: string;
  lastPlotRangeName: string;
  objectType: EEntityObjectType;
  isTickLabelsVisible: boolean;
  contourBandWidth: number;
};

export type SpecificDataset = Partial<TBaseChartSettings>;

export type TInitialChartSettingsState = TBaseChartSettings & {
  cageLevelAxesOptionListByLanes: Record<string, TOption[]>;
  objectLevelAxesOptionListByLanes: Record<string, TOption[]>;
  fullScreenChartData: Nullable<TDatasetDetails>;
  specificDatasetOptionMap: Record<string, SpecificDataset>;
  сhartPresetSettingsСompletionStatusMap: ChartPresetSettingsСompletionStatusMap;
};
