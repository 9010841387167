import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import TextInput from '@/components/common/TextInput';
import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './SearchInput.module.scss';

const cn = classnames.bind(styles);

type TSearchInputProps = {
  value: string;
  onChange: (_ev: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  disabled?: boolean;
  autoComplete?: string;
  className?: string;
  isAlwaysOpen?: boolean;
  placeholder?: string;
};

const SearchInput: FC<TSearchInputProps> = ({
  value,
  onChange = () => null,
  onReset = () => null,
  disabled = false,
  autoComplete,
  className,
  isAlwaysOpen = false,
  placeholder = '',
}) => {
  const [isOpen, setIsOpen] = useState(isAlwaysOpen);
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const isCloseIconShown = useMemo(() => {
    if (isAlwaysOpen) {
      return value !== '';
    }
    return isOpen;
  }, [isAlwaysOpen, value, isOpen]);

  const handleCloseButtonClick = () => {
    onReset();
    if (!isAlwaysOpen) {
      setIsOpen(false);
    }
  };

  const handleSearchButtonClick = () => {
    setIsOpen(true);
    inputRef.current?.focus();
  };

  return (
    <div
      className={cn(
        'search-input',
        {
          'search-input_open': isOpen,
        },
        className
      )}
    >
      <TextInput
        value={value}
        onChange={onChange}
        className={cn('search-input__input', { 'search-input__input_open': isOpen })}
        tabIndex={isOpen ? 0 : -1}
        disabled={disabled}
        ref={inputRef}
        theme="white"
        autoComplete={autoComplete}
        placeholder={placeholder}
      />

      {isCloseIconShown ? (
        <Button
          tooltip={isAlwaysOpen ? 'Clear search' : 'Close search'}
          className={cn('search-input__button')}
          onClick={handleCloseButtonClick}
          color="white"
          disabled={disabled}
        >
          <icons.CloseIcon />
        </Button>
      ) : (
        <Button
          tooltip="Search"
          className={cn('search-input__button')}
          onClick={handleSearchButtonClick}
          color="white"
          disabled={disabled}
        >
          <icons.SearchIcon />
        </Button>
      )}
    </div>
  );
};

export default SearchInput;
