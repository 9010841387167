const changeLut = (
  gl: WebGLRenderingContext,
  lutIdList: string[],
  lutTextureList: Record<string, Nullable<WebGLTexture>>
) => {
  if (!gl) {
    return;
  }

  lutIdList.forEach((lutId, index) => {
    gl.activeTexture(gl.TEXTURE10 + index);
    gl.bindTexture(gl.TEXTURE_2D, lutTextureList[lutId]);
  });
};

export default changeLut;
