import { FC, useEffect } from 'react';
import { headerActions } from '@/store/slices/header';

import ExperimentList from '@/components/common/ExperimentList';
import { useAppDispatch } from '@/hooks/useAppDispatch';

const ExperimentsDashboard: FC = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());
  }, []);

  return <ExperimentList />;
};

export default ExperimentsDashboard;
