import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isNumber } from '@/helpers';
import { gatesSelectors } from '@/store/slices/gates';
import { datasetsSelectors } from '@/store/slices/datasets';

import { preprocessingSelectors } from '@/store/slices/preprocessing';

// use this hook for geting the gate points (selectedGate.specificDatasets) specific to current dataset from preprocessing
const useSelectedGateForSpecificDataset = (datasetId = '') => {
  const currentDatasets = useSelector(preprocessingSelectors.selectDatasets);
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);

  const specificSelectedGate = useMemo(() => {
    if (selectedGate && isPreprocessingView && currentDatasets.length) {
      let currentDatasetId = null;

      if (datasetId) {
        currentDatasetId = datasetId;
      } else if (isNumber(currentDatasetIndex)) {
        currentDatasetId = currentDatasets?.[currentDatasetIndex]?.id ?? null;
      }

      if (!currentDatasetId) {
        return selectedGate;
      }
      const shapeDataForCurrentDataset = selectedGate?.specificDatasets?.[currentDatasetId]?.shape ?? null;

      const gateForSpecificDataset = {
        ...selectedGate,
        shape: shapeDataForCurrentDataset ?? selectedGate.shape,
      };
      return gateForSpecificDataset;
    }
    return selectedGate;
  }, [selectedGate, isPreprocessingView, currentDatasets, currentDatasetIndex]);

  return specificSelectedGate;
};

export default useSelectedGateForSpecificDataset;
