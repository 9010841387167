import { FC, useId, useMemo, ReactNode, ReactElement, ChangeEventHandler } from 'react';
import classnames from 'classnames/bind';

import { CSSProperty } from '@/helpers/interfaces';

import { ECheckedState } from './types';
import CheckboxBody from './components/CheckboxBody';
import SwitchBody from './components/SwitchBody';
import styles from './CheckboxInput.module.scss';

const cn = classnames.bind(styles);

export type TCheckboxBaseProps = {
  checked: boolean | 'indeterminate';
  label?: string | ReactElement;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  labelClassName?: string;
  id?: string;
  style?: CSSProperty;
  multiline?: boolean;
};

export type TCheckboxProps = {
  isLabelAtLeft?: boolean;
  children?: ReactNode;
  theme?: 'dark' | 'light';
};

export type TSwitchProps = {
  isSwitch: true;
  switchClassName?: string;
  theme?: 'dark' | 'light' | 'run-design' | 'run-design-table';
};

const isSwitchProps = (props: Record<string, unknown>): props is TSwitchProps => 'isSwitch' in props;
const isCheckboxProps = (props: Record<string, unknown>): props is TCheckboxProps => !isSwitchProps(props);

export type TCheckboxInputProps = TCheckboxBaseProps & (TCheckboxProps | TSwitchProps);

const CheckboxInput: FC<TCheckboxInputProps> = ({
  checked = false,
  label = '',
  onChange,
  disabled = false,
  readOnly = false,
  className = '',
  labelClassName = '',
  id,
  style,
  multiline = false,
  ...props
}) => {
  const uId = useId();
  const currentUd = id ?? uId;
  const checkboxId = useMemo(() => `checkbox__${currentUd}`, [currentUd]);
  const dataTestId = useMemo(() => `checkbox__${currentUd}`, [currentUd]);
  const { theme = 'dark' } = props;

  const checkedState = useMemo(() => {
    if (checked === true) {
      return ECheckedState.checked;
    }

    if (!checked) {
      return ECheckedState.unchecked;
    }

    return ECheckedState.indeterminate;
  }, [checked]);
  const isSwitch = isSwitchProps(props);

  let bodyProps;
  if (isSwitchProps(props)) {
    const { switchClassName } = props;

    bodyProps = {
      checkedState,
      label,
      switchClassName,
      labelClassName,
    };
  } else {
    const { isLabelAtLeft, children } = props;

    bodyProps = {
      checkedState,
      disabled,
      label,
      isLabelAtLeft,
      children,
      multiline,
    };
  }

  return (
    <label
      style={style}
      className={cn(
        'checkbox',
        {
          [theme]: true,
          checkbox_switch: isSwitch,
          'checkbox_left-label': isCheckboxProps(props) && bodyProps.isLabelAtLeft,
        },
        className
      )}
    >
      <input
        type="checkbox"
        className={cn('checkbox__input', {
          checkbox__input_indeterminate: checkedState === ECheckedState.indeterminate,
          checkbox__slider_input: isSwitch,
        })}
        checked={checkedState === ECheckedState.checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        id={checkboxId}
        data-test-id={dataTestId}
      />
      {!isSwitch && <CheckboxBody {...bodyProps} />}
      {isSwitch && <SwitchBody {...bodyProps} />}
    </label>
  );
};

export default CheckboxInput;
