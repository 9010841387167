import { isEqualAxes } from './channels';
import { arrToMapByKeys } from './common';
import {
  PREPROCESSING_ASSAYS_BY_ASSAY,
  EPreprocessingAssayType,
  PREPROCESSING_ASSAYS,
  CELL_KILLING_PROCESSED_DATASET_XAXIS,
  PREPROCESSING_ASSAY_TYPE_LIST,
  SURFACE_RECEPTOR_ASSAY,
  AXES_DEFAULT_PRESET,
} from './constants';

export const getAssayNameByPreprocessingType = (assayType: EPreprocessingAssayType) =>
  PREPROCESSING_ASSAYS.find((preprocessingAssay) => preprocessingAssay.idByProcessType === assayType)?.name ?? '';

export const getAssayByLane = (lane: Nullable<TLane>, channelName: string) => {
  const channelProperties = lane?.channelsProperties?.[channelName];
  return channelProperties?.assay ?? null;
};

// https://cellanome.atlassian.net/browse/CD-687
export const isLaneProcessed = (lane: TLane, assay: Nullable<TAssay>) => {
  const { classes = [] } = lane;
  if (assay) {
    return classes.some(
      (classItem) =>
        assay?.name === getAssayNameByPreprocessingType(classItem.processType as EPreprocessingAssayType) ||
        SURFACE_RECEPTOR_ASSAY.idByProcessType === assay.name
    );
  }
  // for cases where the data has been pre-processed, but there is currently no assay
  return hasLanePreprocessedData(lane);
};

export const getUniquePreprocessingAssays = (list: TDatasetDetails[]): TAssay[] => {
  const assaysMap = new Map<string, TAssay>();

  list.forEach((data) => {
    if (!data.assay) {
      return;
    }
    assaysMap.set(data.assay.name, data.assay);
  });

  return [...assaysMap.values()];
};

export const isDatasetDetailsNeedPreprocessing = (
  datasetDetails: TDatasetDetails,
  preprocessingAssayType?: EPreprocessingAssayType
) => {
  const preprocessingAssays = PREPROCESSING_ASSAYS.filter(
    (preprocessingAssayItem) =>
      preprocessingAssayItem.name === datasetDetails.assay?.name ||
      datasetDetails.assayList.some((assay) => assay.name === preprocessingAssayItem.name)
  );

  if (
    preprocessingAssayType &&
    preprocessingAssays.every((assay) => preprocessingAssayType !== assay.idByProcessType)
  ) {
    return false;
  }

  return !!preprocessingAssays.length;
};

export const getIsPreprocessingAssay = (list: TDatasetDetails[] = [], assayId: Nullable<string> = ''): boolean =>
  list
    .filter((el) => el.assay?.id === assayId)
    .some((el) => el.assay?.name && PREPROCESSING_ASSAYS_BY_ASSAY[el.assay.name]);

export const getChannelNames = (list: TDatasetDetails[]): string[] => {
  const withoutDublicates = new Set(list.map((el, i) => el.channelName ?? `Channel ${i}`));
  return [...withoutDublicates.values()];
};

export const getCorrectNameOfAxes = (
  optionMap: Record<string, Record<string, string>>,
  datasetName: string,
  xAxis: string,
  yAxis: string
) => {
  const optionKeys = Object.keys(optionMap);
  const xDefault = optionKeys.find((key) => isEqualAxes(key, xAxis));
  const yDefault = optionKeys.find((key) => isEqualAxes(key, yAxis));
  const newXAxis = optionMap[xDefault ?? '']?.[datasetName];
  const newYAxis = optionMap[yDefault ?? '']?.[datasetName];
  return {
    correctXAxis: newXAxis ?? xAxis,
    correctYAxis: newYAxis ?? yAxis,
    xAxisFromMap: xDefault ?? xAxis,
    yAxisFromMap: yDefault ?? yAxis,
  };
};

export type TPreprocessingAssay = { idByProcessType: EPreprocessingAssayType; name: string };

type TPrepareXAxisForCytokineProcessedDataset = {
  channel: string;
  marker?: string;
  channels?: string[];
  scatterPlotAxesOptions: TBasicOption[];
};
export const prepareXAxisForCytokineProcessedDataset = ({
  channel,
  marker,
  channels,
  scatterPlotAxesOptions,
}: TPrepareXAxisForCytokineProcessedDataset): Nullable<string> => {
  const correctChannel = channels?.find((channelItem) => channelItem.includes(channel));
  let axisName = '';
  if (!correctChannel) return null;

  if (!marker) {
    axisName = `cell_${correctChannel}_mean_sub`;
  } else {
    axisName = `${marker}_${correctChannel}_mean_sub`;
  }

  const axisFromOptionList = scatterPlotAxesOptions.find((option) => option?.value === axisName);

  return axisFromOptionList?.value ? axisFromOptionList?.value.toString() : null;
};

export const prepareXAxisForCellKillingProcessedDataset = ({
  channel,
  channels,
  scatterPlotAxesOptions,
}: Omit<TPrepareXAxisForCytokineProcessedDataset, 'marker'>) => {
  const defaultAxis = scatterPlotAxesOptions.find((option) => option?.value === CELL_KILLING_PROCESSED_DATASET_XAXIS);

  if (defaultAxis?.value) {
    return defaultAxis.value.toString();
  }

  const correctChannel = channels?.find((channelItem) => channelItem.includes(channel));

  if (correctChannel) {
    const axisName = `cell_${correctChannel}_mean_sub`;
    const axisInOptions = scatterPlotAxesOptions.find((option) => option?.value === axisName);

    if (axisInOptions?.value) return axisInOptions.value.toString();
  }

  return scatterPlotAxesOptions?.[0]?.value.toString() ?? '';
};

export const hasLanePreprocessedData = (lane: Nullable<TLane>) => {
  const classList = lane?.classes ?? [];

  if (classList.length === 0) {
    return false;
  }

  return classList.some((classItem) =>
    [...PREPROCESSING_ASSAY_TYPE_LIST, SURFACE_RECEPTOR_ASSAY.idByProcessType].includes(classItem.processType)
  );
};

export const getValidatedNotPreprocessedAxes = (
  channel: string,
  scatterPlotAxesOptions: TBasicOption[],
  isObjectEntityEnabled: boolean
) => {
  const axesOptionsMapByValue = arrToMapByKeys(scatterPlotAxesOptions, 'value');
  let xAxis = isObjectEntityEnabled && channel === 'White' ? AXES_DEFAULT_PRESET.xAxis : `${channel}_mean_sub`;
  let yAxis = channel === 'White' ? AXES_DEFAULT_PRESET.yAxis : 'object_area_px';

  if (!isObjectEntityEnabled) {
    yAxis = `${channel}_bg_mean`;
  }

  if (!axesOptionsMapByValue?.[xAxis]) {
    xAxis = `${channel}_mean`;
  }

  return {
    validatedXAxis: axesOptionsMapByValue?.[xAxis]?.value
      ? String(axesOptionsMapByValue[xAxis].value)
      : String(scatterPlotAxesOptions[0]?.value),
    validatedYAxis: axesOptionsMapByValue?.[yAxis]?.value
      ? String(axesOptionsMapByValue[yAxis].value)
      : String(scatterPlotAxesOptions[1]?.value),
  };
};
