import isEqual from 'lodash.isequal';

import { isKeyOf } from '@/helpers';

export const checkIsGlobalSettingsChanged = ({
  fullScreenChartDataId,
  globalSettings,
  specificSettings,
  specificSettingKeysToExlude,
}: {
  fullScreenChartDataId?: string;
  globalSettings: Record<string, unknown>;
  specificSettings: Record<string, unknown>;
  specificSettingKeysToExlude?: string[];
}) => {
  const specificSettingsForDataset = specificSettings?.[fullScreenChartDataId ?? ''] ?? {};

  const isSettingsChanged = Object.keys(specificSettingsForDataset).length
    ? Object.keys(specificSettingsForDataset).some((key) => {
        if (specificSettingKeysToExlude?.length && specificSettingKeysToExlude.includes(key)) return false;

        if (!isKeyOf(key, specificSettingsForDataset)) return false;

        const specificSettingValue = specificSettingsForDataset[key];
        const globalSettingValue = globalSettings?.[key];

        return !isEqual(specificSettingValue, globalSettingValue);
      })
    : false;

  return isSettingsChanged;
};
