import { FC } from 'react';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

type TFullScreen = {
  isSquareScreen?: boolean;
  className?: string;
  changeRatio: () => void;
};

const RatioToggle: FC<TFullScreen> = ({ className, isSquareScreen, changeRatio }) => (
  <Button
    tooltip={isSquareScreen ? 'Default view' : 'Square view'}
    id="ratio-toggle"
    color="gray"
    className={className}
    onClick={changeRatio}
  >
    {isSquareScreen ? <icons.ViewerStretchIcon /> : <icons.ViewerShrinkIcon />}
  </Button>
);

export default RatioToggle;
