import { SVGProps } from 'react';

const MaxNumberOfControlCagesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="22.5078"
      y="22.5"
      width="19"
      height="19"
      rx="9.5"
      transform="rotate(180 22.5078 22.5)"
      stroke="#57D4FB"
      strokeWidth="7"
    />
    <rect
      x="22.5078"
      y="50.5"
      width="19"
      height="19"
      rx="9.5"
      transform="rotate(180 22.5078 50.5)"
      stroke="#EBEBEA"
      strokeWidth="7"
    />
    <rect
      x="50.5078"
      y="22.5"
      width="19"
      height="19"
      rx="9.5"
      transform="rotate(180 50.5078 22.5)"
      stroke="#EBEBEA"
      strokeWidth="7"
    />
    <rect
      x="50.5078"
      y="50.5"
      width="19"
      height="19"
      rx="9.5"
      transform="rotate(180 50.5078 50.5)"
      stroke="#EBEBEA"
      strokeWidth="7"
    />
  </svg>
);

export default MaxNumberOfControlCagesIcon;
