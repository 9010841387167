import { SVGProps } from 'react';

const CellKillingStainIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="50" height="55" viewBox="0 0 50 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M42 39L42 55" stroke="currentColor" strokeWidth="4" />
    <path d="M50 47L34 47" stroke="currentColor" strokeWidth="4" />
    <path
      d="M39 25.5C39 35.7173 30.7173 44 20.5 44C10.2827 44 2 35.7173 2 25.5C2 15.2827 10.2827 7 20.5 7C22.3679 7 24.1712 7.27683 25.871 7.7917"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      d="M42 14.2167C42 17.963 39.0899 21 35.5 21C31.9101 21 29 17.963 29 14.2167C29 10.4705 32.4048 7.64286 35.5 4C38.5952 7.64286 42 10.4705 42 14.2167Z"
      stroke="currentColor"
      strokeWidth="4"
    />
  </svg>
);

export default CellKillingStainIcon;
