import { FC, memo } from 'react';

import Portal from '@/components/common/Portal';
import Preprocessing from '@/components/experiment/Preprocessing';

const PortalPreprocessing: FC<{ isPreprocessingView: boolean }> = ({ isPreprocessingView }) => {
  if (!isPreprocessingView) {
    return null;
  }
  return (
    <Portal>
      <Preprocessing />
    </Portal>
  );
};

export default memo(PortalPreprocessing);
