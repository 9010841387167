import { FC } from 'react';
import classnames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
import Aside from '@/components/Layout/Aside';
import Header from '@/components/Layout/Header';
import Datetime from './Header/Datetime';
import styles from './Layout.module.scss';
import stylesOverride from './LayoutV2Override.module.scss';

const cn = classnames.bind({ ...styles, ...stylesOverride });

const LayoutV2: FC = () => (
  <div className={cn('layout', 'layout-v2')}>
    <Aside className={cn('layout__aside')} />

    <div className={cn('layout__main', 'layout-v2__main')}>
      <div id="layout-page-header">
        <Header className={cn('layout__header')} leftSlot={<Datetime />} />
        {/*  Used as Portal */}
      </div>

      <div className={cn('layout__content')}>
        <Outlet />
      </div>
    </div>
  </div>
);

export default LayoutV2;
