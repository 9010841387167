import { useEffect } from 'react';
import { PlotlyProxy } from '../helpers';

const plotlyProxyMap = new Map<string, PlotlyProxy>();

const usePlotProxy = (id: string, debounce = 300) => {
  const proxy = plotlyProxyMap.get(id) ?? new PlotlyProxy(id, debounce);

  if (!plotlyProxyMap.has(proxy.id)) {
    plotlyProxyMap.set(id, proxy);
  }

  useEffect(() => {
    const graphDiv = document.querySelector(`#${proxy.id || 'plot-id'}`) as IPlotlyHTMLDivElement;

    return () => {
      proxy.purge(graphDiv ?? undefined);
      plotlyProxyMap.delete(proxy.id);
    };
  }, [proxy.id]);

  return proxy;
};
export default usePlotProxy;
