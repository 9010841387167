import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useExperimentContext } from '@/hooks';

import { prepareSummaryValue } from '@/helpers/experiment/summary';

import { experimentSelectors } from '@/store/slices/experiment';

import InfoButton from '@/components/common/InfoButton';
import NoDataFound from '@/components/common/NoDataFound';

import styles from '../../Summary.module.scss';

const cn = classnames.bind(styles);

const getLaneStatistics = (statisticsData?: TStatisticsData) => ({
  'Number of cells detected': statisticsData?.numberOfCellsDetected ?? 0,
  'Number of cages': statisticsData?.numberOfCages ?? 0,
  'Number of cells caged': statisticsData?.numberOfCellsCaged ?? 0,
  'Percentage of cells caged': statisticsData?.percentageOfCellsCaged ?? 0,
  'Number of cells outside a cage': statisticsData?.numberOfCellsOutsideCage ?? 0,
  'Percentage of cells outside a cage': statisticsData?.percentageOfCellsOutsideCage ?? 0,
});

const StatisticsSummary: FC = () => {
  const { isAllLanesDataShow } = useExperimentContext();
  const cagingScan = useSelector(experimentSelectors.selectCagingScan);
  const currentScanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const cagingLaneList = useSelector(experimentSelectors.selectAllCagingLaneList);

  const { summaryStatistics, infoText } = useMemo(() => {
    const cagingLane = cagingLaneList.find((lane) => lane.id === currentAppLane?.id);
    const isShowScanStatistics = isAllLanesDataShow || !cagingLane;

    const scanByLane = currentScanList.find((scan) => scan.id === cagingLane?.dataset.scanId);
    const statisticData = isShowScanStatistics ? scanByLane?.summary : cagingLane?.summary;

    const comingInfo = isShowScanStatistics ? `` : `from <strong>lane ${cagingLane.letterName}</strong>`;

    const info = `Statistic is coming ${comingInfo} from <strong>the caging scan ${scanByLane?.time}</strong>`;

    return { summaryStatistics: getLaneStatistics(statisticData), infoText: info };
  }, [currentAppLane, cagingScan?.summary, isAllLanesDataShow, cagingLaneList, currentScanList]);

  return (
    <>
      <div className={cn('statistics-headline', 'headline')}>
        <h3 className={cn('headline__title')}>Summary</h3>
        <InfoButton tooltip={infoText} />
      </div>
      {cagingScan?.summary ? (
        <div className={cn('chart-statistics', 'chart-statistics__summary')}>
          {Object.entries(summaryStatistics).map((statisticsItem) => (
            <div key={statisticsItem[0]} className={cn('chart-statistics__row')}>
              <div className={cn('chart-statistics__cell', 'chart-statistics__cell_row_name')}>{statisticsItem[0]}</div>
              <div className={cn('chart-statistics__cell', 'chart-statistics__cell_value')}>
                {prepareSummaryValue(statisticsItem[0], statisticsItem[1])}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoDataFound alignment="left" />
      )}
    </>
  );
};

export default StatisticsSummary;
