import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { EChartType } from '@/types/charts';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { scatterplotsActions, EAxesGroupName, TAxes, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { prepareAxesPresetOptionList } from '@/store/services/app/selectHelpers';
import { experimentSelectors } from '@/store/slices/experiment';
import { findLaneInScanList } from '@/helpers/scans';
import { chartDataSelectors } from '@/store/slices/chartData';
import { getAssayByLane, isLaneProcessed } from '@/helpers/preprocessing';

import { getPlotAxesOptionList } from '../../helpers';
import { getBasicAxes, getPreprocessedXAxis } from './helpers';

export function useSingleChartSettingsPreset(chartDataList: TDatasetDetails[]) {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const [searchParams] = useSearchParams();
  const channelName = searchParams.get('channelName') ?? '';

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const cageLevelAxesOptionListByLanes = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionListByLanes);
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);
  const currentLane = findLaneInScanList(scanList, currentChartData?.scanId ?? '', currentChartData?.laneId ?? '');
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const generalAxes = useSelector(scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.general)) as Record<
    string,
    TAxes
  >;
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const [plotType, setChartType] = useState(EChartType.dotDensity);
  const [isReady, setIsReady] = useState(false);

  const chartData = useMemo(() => chartDataList[0] ?? null, [chartDataList]);

  const hasGeneralAxes = useMemo(
    () => generalAxes?.[chartData?.id]?.isObjectEntityEnabled === isObjectEntityEnabled,
    [generalAxes, chartData, isObjectEntityEnabled]
  );
  const plotAxesOptionList = useMemo(
    () => (chartData ? getPlotAxesOptionList(chartData, isObjectEntityEnabled, cageLevelAxesOptionListByLanes) : []),
    [hasGeneralAxes, chartData, isObjectEntityEnabled, cageLevelAxesOptionListByLanes]
  );

  const isReadyToPrepareSettings = useMemo(() => {
    if (hasGeneralAxes || isObjectEntityEnabled) {
      return true;
    }
    return plotAxesOptionList.length > 0;
  }, [hasGeneralAxes, isObjectEntityEnabled, plotAxesOptionList]);

  useEffect(() => {
    setChartType(currentChartType);
  }, []);

  useEffect(() => {
    if (!isReady || plotAxesOptionList.length === 0) {
      return;
    }
    const axesPresetOptionList = prepareAxesPresetOptionList(chartData.channelList, isObjectEntityEnabled);
    const newAxes = getBasicAxes(axesPresetOptionList, plotAxesOptionList, isObjectEntityEnabled, channelName);
    appDispatch(
      scatterplotsActions.setAxes({
        axesGroupName: EAxesGroupName.singleChart,
        newAxes: { ...newAxes, isObjectEntityEnabled },
      })
    );
  }, [channelName, isObjectEntityEnabled]);

  useEffect(() => {
    if (!isReady || plotAxesOptionList.length === 0) {
      return;
    }
    if (
      !plotAxesOptionList.find((axisOption) => axisOption.value === xAxis) ||
      !plotAxesOptionList.find((axisOption) => axisOption.value === yAxis)
    ) {
      const axesPresetOptionList = prepareAxesPresetOptionList(chartData.channelList, isObjectEntityEnabled);
      const newAxes = getBasicAxes(axesPresetOptionList, plotAxesOptionList, isObjectEntityEnabled, channelName);

      appDispatch(
        scatterplotsActions.setAxes({
          axesGroupName: EAxesGroupName.singleChart,
          newAxes: { ...newAxes, isObjectEntityEnabled },
        })
      );
    }
  }, [chartData, isObjectEntityEnabled, plotAxesOptionList]);

  useEffect(() => {
    if (!chartData || !isReadyToPrepareSettings || isReady) {
      if (isReadyToPrepareSettings) {
        setIsReady(true);
      }
      return;
    }

    if (hasGeneralAxes) {
      appDispatch(
        scatterplotsActions.setAxes({
          axesGroupName: EAxesGroupName.singleChart,
          chartDataId: chartData?.id,
          newAxes: generalAxes[chartData?.id],
        })
      );

      setIsReady(true);
      return;
    }
    const axesPresetOptionList = prepareAxesPresetOptionList(chartData.channelList, isObjectEntityEnabled);
    const newAxes = getBasicAxes(axesPresetOptionList, plotAxesOptionList, isObjectEntityEnabled, channelName);
    const preprocessedXAxis = getPreprocessedXAxis(
      scanList,
      chartData,
      plotAxesOptionList,
      channelName,
      searchParams.get('markerName') ?? ''
    );

    const assay = getAssayByLane(currentLane, currentChartData?.channelName ?? '');

    if (currentLane && isLaneProcessed(currentLane, assay)) {
      setChartType(EChartType.lineHistogram);
    }

    if (preprocessedXAxis) {
      newAxes.x = preprocessedXAxis;
    }

    appDispatch(
      scatterplotsActions.setAxes({
        axesGroupName: EAxesGroupName.singleChart,
        newAxes: { ...newAxes, isObjectEntityEnabled },
      })
    );
    setIsReady(true);
  }, [chartData, isReadyToPrepareSettings, isReady, isObjectEntityEnabled]);

  return useMemo(() => ({ isReady, plotType }), [plotType, isReady]);
}
