import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { isBasicOption } from '@/components/common/Select/helpers';
import { removeDuplicates } from '@/helpers';
import { getPrimeAxisList } from '@/pages/Dataset/components/DatasetChart/helpers/datasetChart';
import { selectAxesOptionListFromScanList } from '@/store/services/app/selectors';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

const usePrimaryAxisOptionListForMultiDatasets = ({
  scanList,
  chartDataList,
}: {
  scanList: TScan[];
  chartDataList: TDatasetDetails[];
}) => {
  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));

  return useMemo(() => {
    if (isObjectEntityEnabled) {
      const optionLists = chartDataList.map((chartData) =>
        selectAxesOptionListFromScanList(scanList, chartData.scanId, chartData.laneId, isObjectEntityEnabled)
      );
      const optionList = removeDuplicates(optionLists.flat(), 'value');

      return optionList.map((option) => (isBasicOption(option) ? option.value : ''));
    }

    const markerList: TClass[][] = [];
    const currentDatasetsChannelsMap = new Map<string, string>();

    chartDataList.forEach((chartData) => {
      const markers = chartData?.markerList ?? [];
      markerList.push(markers);

      const key = `channel-${chartData.channelName}`;

      currentDatasetsChannelsMap.set(key, chartData?.channelName ?? '');
    });

    const markerListWithoutDublicates = removeDuplicates<TClass>(markerList.flat(), 'id');

    const channelsList = Array.from(currentDatasetsChannelsMap.values());

    const optionsList = channelsList
      .map((channel: string, index) => {
        const isTheLastElement = index === channelsList.length - 1;
        const withChannelIndependentAxes = isTheLastElement;

        return getPrimeAxisList(channel, markerListWithoutDublicates, withChannelIndependentAxes);
      })
      .flat();

    return optionsList;
  }, [scanList, isObjectEntityEnabled, chartDataList]);
};

export default usePrimaryAxisOptionListForMultiDatasets;
