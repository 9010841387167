import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCorrectNameOfAxes } from '@/helpers/preprocessing';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';

const useCytokineAxes = () => {
  const axesOptionMap = useSelector(preprocessingSelectors.selectAxesOptionMap);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());

  const getAxes = useCallback(
    (datasetName: string) => {
      const { correctXAxis, correctYAxis } = getCorrectNameOfAxes(axesOptionMap, datasetName, xAxis, yAxis);
      const axes: Record<'xAxis' | 'yAxis', string> = {
        xAxis: correctXAxis,
        yAxis: correctYAxis,
      };

      return axes;
    },
    [axesOptionMap, xAxis, yAxis]
  );

  return useMemo(() => ({ getAxes }), [getAxes]);
};

export default useCytokineAxes;
