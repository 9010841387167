import { useCallback, useMemo, useState } from 'react';

export type TUseOpenModal = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  open: () => void;
  onClose: () => void;
};

export function useOpenModal(): TUseOpenModal {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      open,
      onClose,
    }),
    [isOpen, setIsOpen, open, onClose]
  );
}
