import lut from '../lut';

function makeTexture(gl: WebGLRenderingContext, image: HTMLImageElement) {
  const tex = gl.createTexture();

  gl.bindTexture(gl.TEXTURE_2D, tex);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

  return tex;
}

async function addLut(gl: WebGLRenderingContext) {
  const lutImageList = await lut;

  if (!lutImageList) {
    return;
  }

  return Object.entries(lutImageList).reduce((acc, [key, image]) => {
    acc[key] = makeTexture(gl, image);

    return acc;
  }, {} as Record<string, Nullable<WebGLTexture>>);
}

export default addLut;
