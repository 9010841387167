import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import type { TModalColor } from '@/helpers/interfaces';

import type { TModal } from '..';
import styles from './ModalContent.module.scss';

const cn = classnames.bind(styles);

type TModalContent = {
  children: ReactNode;
  className?: string;
  modalColor?: TModalColor; // ?? this props from modal component
  color?: TModalColor;
} & Pick<TModal, 'onRequestClose' | 'sidebar' | 'id'>;

const ModalContent: FC<TModalContent> = ({ children, className, sidebar, modalColor, color }) => (
  <div className={cn('modal-content', { 'modal-content__sidebar': sidebar, modalColor, color }, className)}>
    {children}
  </div>
);

export default ModalContent;
