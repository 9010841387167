import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TExperimentsState } from './types';

const initialState: TExperimentsState = {
  currentExperimentId: '',
  currentScanId: '',
  currentLaneId: '',
  noSequencingLaneDataList: [],
};

const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    setCurrentExperimentId: (state, action) => {
      if (state.currentExperimentId === action.payload) {
        return;
      }
      state.currentExperimentId = action.payload;
      state.noSequencingLaneDataList = [];
    },

    setCurrentScanId: (state, action) => {
      state.currentScanId = action.payload ?? '';
    },

    setCurrentLaneId: (state, action) => {
      state.currentLaneId = action.payload ?? '';
    },

    setNoSequencingDataLaneId: (
      state,
      action: PayloadAction<{ laneData: { id: string; name: string }; isProcessed: boolean }>
    ) => {
      const { laneData, isProcessed } = action.payload;
      if (isProcessed) {
        state.noSequencingLaneDataList = state.noSequencingLaneDataList.filter(
          (notProcessedLaneData) => notProcessedLaneData.id !== laneData.id
        );
      } else if (
        !state.noSequencingLaneDataList.find((notProcessedLaneData) => notProcessedLaneData.id === laneData.id)
      ) {
        state.noSequencingLaneDataList = [...state.noSequencingLaneDataList, laneData];
      }
    },
  },
});

export default experimentsSlice;
