import { SVGProps } from 'react';

const SecretionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" viewBox="0 0 49 45" fill="none" {...props}>
    <path
      d="M41 13.226A20.624 20.624 0 0 0 22.612 2C11.23 2 2 11.178 2 22.5S11.229 43 22.612 43a20.6 20.6 0 0 0 13.908-5.369"
      stroke="currentColor"
      strokeWidth="4"
    />
    <g fill="currentColor">
      <circle cx="28" cy="21" r="3" />
      <circle cx="37" cy="21" r="3" />
      <circle cx="46" cy="21" r="3" />
      <circle cx="41" cy="29" r="3" />
      <circle cx="32" cy="29" r="3" />
    </g>
  </svg>
);

export default SecretionIcon;
