import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import CheckboxInput from '@/components/common/CheckboxInput';
import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './AxesControls.module.scss';

const cn = classnames.bind(styles);

type TAxesControlsProps = {
  className?: string;

  isGateRangeControlsHidden?: boolean;
  isRangeChangedByPlotly?: boolean;
  isCustomGateRangeActive?: boolean;

  toggleCustomRangeCheckbox?: () => void;
  deleteCustomGateRange?: () => void;
  saveGateCustomRange?: () => void;
};

const AxesControls: FC<TAxesControlsProps> = ({
  className,

  isGateRangeControlsHidden = true,
  isRangeChangedByPlotly = false,
  isCustomGateRangeActive = false,

  toggleCustomRangeCheckbox,
  deleteCustomGateRange,
  saveGateCustomRange,
}) => {
  const visibleState = useMemo(
    () => ({
      isApplyCustomRangeCheckbox: !isGateRangeControlsHidden,
      isDeleteCustomRangeButton: !isGateRangeControlsHidden,
      isSaveCustomRangeButton: isRangeChangedByPlotly,
    }),
    [isGateRangeControlsHidden, isRangeChangedByPlotly]
  );
  return (
    <div className={cn('controls', className)}>
      {visibleState.isApplyCustomRangeCheckbox && (
        <div className={cn('controls__checkbox-container')}>
          <CheckboxInput
            checked={isCustomGateRangeActive}
            onChange={toggleCustomRangeCheckbox}
            className={cn('select__checkbox')}
            label="Apply custom range"
            theme="light"
          />
        </div>
      )}
      {visibleState.isDeleteCustomRangeButton && (
        <Button
          color="white"
          tooltip="Delete custom range"
          className={cn('control__item')}
          onClick={deleteCustomGateRange}
        >
          <icons.DeleteIcon className={cn('control__icon')} />
        </Button>
      )}
      {visibleState.isSaveCustomRangeButton && (
        <Button
          color="white"
          tooltip="Save custom range"
          className={cn('control__item')}
          onClick={saveGateCustomRange}
          disabled={isCustomGateRangeActive}
        >
          <icons.SaveIcon className={cn('control__icon')} />
        </Button>
      )}
    </div>
  );
};

export default AxesControls;
