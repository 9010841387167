import { FC } from 'react';
import classnames from 'classnames/bind';

import FileIcon from '@/components/common/FileIcon';

import styles from './DownloadsTab.module.scss';

const cn = classnames.bind(styles);

type TFileTypeProps = {
  data: TDownloadFile;
};
const FileType: FC<TFileTypeProps> = ({ data }) => (
  <FileIcon className={cn('downloads__file-icon')} fileType={data.fileType} />
);

export default FileType;
