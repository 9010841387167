import { FC, useCallback, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import {
  experimentRunDesignSelectors,
  TMatrixSettingItem,
  TRunDesignOpticsSettingsItem,
} from '@/store/slices/experimentRunDesign';
import { isSpecificGlobalSettingChanged } from '@/store/slices/experimentRunDesign/helpers';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import NoDataFound from '@/components/common/NoDataFound';

import styles from './GlobalView.module.scss';
import GlobalSettingRow from './GlobalSettingRow';

const cn = classnames.bind(styles);

type TGlobalView = {
  isEditMode: boolean;
  opticalSettings: TRunDesignOpticsSettingsItem[];
  setOpticalSettings: Dispatch<SetStateAction<TRunDesignOpticsSettingsItem[]>>;
  lanesMatrixSettings: TMatrixSettingItem[];
  setLanesMatrixSettings: Dispatch<SetStateAction<TMatrixSettingItem[]>>;
};

const GlobalView: FC<TGlobalView> = ({
  isEditMode,
  opticalSettings,
  setOpticalSettings,
  lanesMatrixSettings,
  setLanesMatrixSettings,
}) => {
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);

  const handleChangeGlobalSetting = useCallback(
    (index: number) => (updatedSettings: TRunDesignOpticsSettingsItem) => {
      const copyOpticalSettings = [...opticalSettings];
      const overriddenMatrixSettings = lanesMatrixSettings.map((matrixSetting) => {
        if (
          matrixSetting.opticalDisplay?.detection?.hex !== updatedSettings.opticalDisplay?.detection?.hex ||
          matrixSetting.opticalDisplay?.excitation?.hex !== updatedSettings.opticalDisplay?.excitation?.hex
        ) {
          return matrixSetting;
        }

        // Update matrix settings for the corresponding channels to the changed parameters in a global view
        return {
          ...matrixSetting,
          ...(isSpecificGlobalSettingChanged(copyOpticalSettings[index], updatedSettings, 'intensity') && {
            intensity: updatedSettings.intensity,
          }),
          ...(isSpecificGlobalSettingChanged(copyOpticalSettings[index], updatedSettings, 'exposure') && {
            exposure: updatedSettings.exposure,
          }),
          ...(isSpecificGlobalSettingChanged(copyOpticalSettings[index], updatedSettings, 'zOffset') && {
            zOffset: updatedSettings.zOffset,
          }),
        };
      });
      copyOpticalSettings[index] = { ...updatedSettings };

      setLanesMatrixSettings(overriddenMatrixSettings);
      setOpticalSettings(copyOpticalSettings);
    },
    [opticalSettings]
  );

  if (!opticalMatrixSettings.length) {
    return <NoDataFound size="big" textData="There are no optic settings" className="center-block" />;
  }

  return (
    <div className={cn('global-view__wrapper')}>
      <div className={cn('global-view__table-wrapper')}>
        <RunDesignTable
          className={cn('global-view__table', 'table-scroll')}
          bodyClassName={cn('global-view__body')}
          tableData={[]}
          header={
            <RunDesignTable.Row className={cn('global-view__header')}>
              <RunDesignTable.Column>EX. CHANNEL</RunDesignTable.Column>
              <RunDesignTable.Column>EM. FILTER</RunDesignTable.Column>
              <RunDesignTable.Column>
                <div className={cn('global-view__header-title')}>INTENSITY</div>
                <div className={cn('global-view__header-range')}>Range: 1 - 100</div>
              </RunDesignTable.Column>
              <RunDesignTable.Column>
                <div className={cn('global-view__header-title')}>EXPOSURE (ms)</div>
                <div className={cn('global-view__header-range')}>Range: 1 - 5000</div>
              </RunDesignTable.Column>
              <RunDesignTable.Column>
                <div className={cn('global-view__header-title')}>Z-Offset</div>
                <div className={cn('global-view__header-range')}>Range: 0.1 - 100</div>
              </RunDesignTable.Column>
            </RunDesignTable.Row>
          }
        >
          {opticalSettings.map((globalSetting, globalSettingIndex) => {
            const key = globalSettingIndex;
            return (
              <GlobalSettingRow
                updateSettings={handleChangeGlobalSetting(globalSettingIndex)}
                isEditMode={isEditMode}
                settings={globalSetting}
                // index={globalSettingIndex}
                key={key}
              />
            );
          })}
        </RunDesignTable>
      </div>
    </div>
  );
};

export default GlobalView;
