import { getMinMax } from '@/helpers/arrays';

import { getAxisGap } from '../helpers';

import { getScaleLinearArcSinh } from './helpers';
import ArcSinhTicksCreator from './arcSinhTicksCreator';

export default {
  getPlotLayoutTypePart() {
    return {
      type: 'linear',
    };
  },

  getPlotLayoutTicksPart(plotAxisRange: number[], axisOrigDataRange?: number[]) {
    return new ArcSinhTicksCreator(plotAxisRange, axisOrigDataRange).getPlotLayoutTicksPart();
  },

  preparePlotData(data: number[], axisOrigDataRange?: number[]) {
    let minVal;
    let maxVal;
    if (axisOrigDataRange) {
      [minVal, maxVal] = axisOrigDataRange;
    } else {
      const { min, max } = getMinMax(data);
      minVal = min;
      maxVal = max;
    }
    const scaleLinearArcSinh = getScaleLinearArcSinh(minVal, maxVal);
    return data.map((value) => scaleLinearArcSinh(Math.asinh(value)));
  },

  getPlotAxisRange(data: number[], withGap: boolean) {
    const { min: minVal, max: maxVal } = getMinMax(data);
    const valDiff = maxVal - minVal;
    const gap = withGap ? getAxisGap(valDiff) : 0;
    return [minVal - gap, maxVal + gap];
  },

  // The values in the arcsin range and in the linear range are the same. No need for conversion
  convertToLinearPlotRange(asinhRange: Nullable<[number, number]>) {
    return asinhRange;
  },

  getScaledGatePoint({
    point,
    axisLinearRange: _,
    axisOrigDataRange,
  }: {
    point: number;
    axisLinearRange: Nullable<[number, number]>;
    axisOrigDataRange?: number[];
  }) {
    if (!axisOrigDataRange) {
      return point;
    }
    const scaleLinearArcSinh = getScaleLinearArcSinh(axisOrigDataRange[0], axisOrigDataRange[1]);
    return scaleLinearArcSinh(Math.asinh(point));
  },

  getRealGatePoint({
    point,
    axisLinearRange: _,
    axisOrigDataRange,
  }: {
    point: number;
    axisLinearRange: Nullable<[number, number]>;
    axisOrigDataRange?: number[];
  }) {
    if (!axisOrigDataRange) {
      return point;
    }
    // ArcSinh passes to Plotly converted data, so we need to invert it
    const scaleLinearArcSinh = getScaleLinearArcSinh(axisOrigDataRange[0], axisOrigDataRange[1]);
    return Math.sinh(scaleLinearArcSinh.invert(point));
  },

  convertPlotValueToReal(axisValue: number, axisOrigDataRange?: number[]) {
    if (!axisOrigDataRange) {
      return axisValue;
    }
    // ArcSinh passes to Plotly converted data, so we need to invert it
    const scaleLinearArcSinh = getScaleLinearArcSinh(axisOrigDataRange[0], axisOrigDataRange[1]);
    return Math.sinh(scaleLinearArcSinh.invert(axisValue));
  },

  // todo: allow to draw circle gates in arcSinh scale
  isCircleGateAllowed() {
    return false;
  },

  isNegativeValuesSupported() {
    return true;
  },
};
