import { SVGProps } from 'react';

const ArrowTimeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M5.867 8.87 1.978 4.981M1.981 4.911 5.87 1.022" />
    </g>
  </svg>
);

export default ArrowTimeIcon;
