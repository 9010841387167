import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { clearCdnDataCache, useParamsExperimentId } from '@/hooks';

import { experimentActions, experimentSelectors } from '@/store/slices/experiment';
import { chartDataActions } from '@/store/slices/chartData';

import Header from '@/components/Layout/Header';
import Aside from '@/components/Layout/Aside';
import Footer from '@/components/Layout/Footer';

import styles from './Layout.module.scss';

const cn = classnames.bind(styles);

const Layout = () => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const storedExperimentId = useSelector(experimentSelectors.selectCurrentExperimentId);

  useLayoutEffect(() => {
    appDispatch(experimentActions.setCurrentExperimentId(experimentId));
    if (!experimentId) {
      clearCdnDataCache();
      appDispatch(chartDataActions.resetEntityListData());
    }
  }, [experimentId]);

  if (experimentId !== storedExperimentId) {
    return null;
  }

  return (
    <div className={cn('layout')}>
      <Aside className={cn('layout__aside')} />

      <div className={cn('layout__main')}>
        <div id="layout-page-header">
          <Header className={cn('layout__header')} />
          {/*  Used as Portal */}
        </div>

        <div className={cn('layout__content')}>
          <Outlet />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
