import { CSSProperties, FC, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import { isDefined } from '~/src/helpers/typeGuards';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import styles from './FilterBlock.module.scss';

const cn = classNames.bind(styles);

type TFilterBlockProps = {
  className?: string;
  isApplied: boolean;
  onFilter?: (newFilterList: Record<string, string>) => void;
  data?: TReturnTransformedTypes[];
};

const FilterBlock: FC<TFilterBlockProps> = ({ className, isApplied, onFilter, data }) => {
  const { config } = useReagentModalContext();

  if (!config.filter) {
    return null;
  }

  const { filterState, updateFactory, cleanFilters, cssGridLayout } = config.filter.hook();
  const FilterList = config.filter.renderer;
  const isFilterButtonVisible = useMemo(() => !Object.values(filterState).every((filter) => !filter), [filterState]);

  const handleFilterButtonClick = () => {
    if (isApplied) {
      cleanFilters();
      onFilter?.({});
      return;
    }

    onFilter?.(filterState);
  };

  useEffect(() => {
    if (isApplied) {
      onFilter?.(filterState);
    }
  }, [filterState, isApplied]);

  return (
    <div className={cn('filter-block', className)} style={{ '--css-grid-layout': cssGridLayout } as CSSProperties}>
      {isDefined(FilterList) && <FilterList filterState={filterState} updateFactory={updateFactory} data={data} />}
      <Button
        className={cn('filter-block__trigger', { 'filter-block__trigger_cleanup': isApplied })}
        onClick={handleFilterButtonClick}
        aria-label="filter"
        disabled={!isFilterButtonVisible}
      >
        <icons.FilterIcon className={cn('filter-block__trigger-icon')} />
      </Button>
    </div>
  );
};

export default FilterBlock;
