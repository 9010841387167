export enum EPageWithChartType {
  heatmap = 'heatmap',
  matrixView = 'matrixView', // Scatterplot
  multiHistogram = 'multiHistogram',
  preprocessing = 'preprocessing',
  singleChart = 'singleChart', // Dataset
  violin = 'violin',
  knee = 'knee',
}

export enum EModebarTools {
  rectangle = 'rectangle',
  polygon = 'polygon',
  ellipse = 'ellipse',
  polar = 'polar',
  splitGate = 'splitGate',
  range = 'range',
  zoom = 'zoom',
  gateInteractions = 'gateInteractions',
}

export enum EPlotlyChartType {
  bar = 'bar',
  box = 'box',
  scatter = 'scatter',
  scattergl = 'scattergl',
  violin = 'violin',
  histogram2d = 'histogram2d',
  histogram = 'histogram',
  histogram2dcontour = 'histogram2dcontour',
  pie = 'pie',
  heatmap = 'heatmap',
  heatmapgl = 'heatmapgl',
  image = 'image',
  contour = 'contour',
  table = 'table',
}

export enum EChartType {
  dot = 'dot',
  dotDensity = 'dotDensity',
  histogram2d = 'histogram2d',
  histogram2dcontour = 'histogram2dcontour',
  histogram = 'histogram',
  lineHistogram = 'lineHistogram',
  heatmap = 'heatmap',
  violin = 'violin',
  knee = 'knee',
}

export type TChartTypesForSelect = Exclude<EChartType, EChartType.heatmap | EChartType.violin | EChartType.knee>;

export enum EAxesScaleType {
  linear = 'linear',
  log = 'log',
  arcSinh = 'arcSinh',
}

export type TColorscale =
  | 'inferno'
  | 'turbo'
  | 'cividis'
  | 'ylOrRd'
  | 'ylGnBu'
  | 'rdBu'
  | 'rdYlBu'
  | 'purples'
  | 'ylGn'
  | 'schemeSet3'
  | 'schemeTableau10'
  | 'schemeSet2'
  | 'schemePaired'
  | 'schemeCategory10'
  | 'schemeSet1'
  | 'plasma'
  | 'oranges';

export type TColorscaleScheme = Record<string, string[][]>;

export enum EHistogramDataGroupTypes {
  thresholdFreedmanDiaconis = 'thresholdFreedmanDiaconis',
  thresholdScott = 'thresholdScott',
  thresholdSturges = 'thresholdSturges',
  custom = 'custom',
  kernelEstimation = 'kernelEstimation',
}

export enum EMarkerSizes {
  default = 4,
  highlighted = 12,
}

export enum ECanvasWebglContextType {
  virtualWebgl = 'virtual-webgl',
  defaultWebgl = 'webgl',
}
