import { useEffect, useState } from 'react';

const useNowDate = (interval = 30) => {
  const [now, setNow] = useState<string>(new Date().toISOString());

  // update now date every `interval` seconds
  useEffect(() => {
    const updateNow = () => {
      setNow(new Date().toISOString());
    };

    const intervalId = setInterval(updateNow, interval * 1000);
    updateNow();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return now;
};

export default useNowDate;
