export const LoadStatusType = {
  loading: 'loading',
  empty: 'empty',
  error: 'error',
  loaded: 'loaded',
} as const;

export type TLoadStatusType = keyof typeof LoadStatusType;

export type TViolinData = {
  id: string;
  laneId: string;
  geneName: string;
  experimentCloudPath: string;
  data?: number[];
  loadStatus: TLoadStatusType;
};

export type TAddViolinData = Pick<TViolinData, 'laneId' | 'experimentCloudPath' | 'geneName' | 'data' | 'loadStatus'>;

export type TUpdateViolinData = { id: string; updated: Pick<TViolinData, 'laneId' | 'geneName'> };

export type TViolinState = {
  violinMap: Record<string, TViolinData>;
  laneIdList: string[];
};
