import { TMatrixSettingItem } from '@/store/slices/experimentRunDesign';

export const defineIsTheFirstSettingElement = (
  currentSettingData: TMatrixSettingItem,
  allMatrixSettings: TMatrixSettingItem[],
  settingIndex: number,
  activeLaneId: Nullable<string>
) => {
  if (!activeLaneId) return false;

  const currentLaneId = currentSettingData.lane.laneId;
  const currentScanId = currentSettingData.scanConfig?.scanIds?.[0];

  const prevSettingData = allMatrixSettings?.[settingIndex - 1] ?? null;
  const prevLaneId = prevSettingData?.lane?.laneId;
  const prevScanId = prevSettingData?.scanConfig?.scanIds?.[0];

  const isCurrentLaneDisplayed = currentLaneId === activeLaneId;

  if (!isCurrentLaneDisplayed) return false;

  return prevLaneId !== currentLaneId || currentScanId !== prevScanId;
};

export const defineIsTheLastSettingElement = (
  currentSettingData: TMatrixSettingItem,
  allMatrixSettings: TMatrixSettingItem[],
  settingIndex: number,
  activeLaneId: Nullable<string>
) => {
  if (!activeLaneId) return false;

  const currentLaneId = currentSettingData.lane.laneId;
  const currentScanId = currentSettingData.scanConfig?.scanIds?.[0];

  const nextSettingData = allMatrixSettings?.[settingIndex + 1] ?? null;
  const nextLaneId = nextSettingData?.lane?.laneId;
  const nextScanId = nextSettingData?.scanConfig?.scanIds?.[0];

  const isCurrentLaneDisplayed = currentLaneId === activeLaneId;

  if (!isCurrentLaneDisplayed) return false;

  return nextLaneId !== currentLaneId || currentScanId !== nextScanId;
};
