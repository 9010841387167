import { FC, memo, useCallback, useMemo, MouseEvent, TouchEvent, MutableRefObject } from 'react';
import classnames from 'classnames/bind';
import styles from './GridMinimap.module.scss';

const cn = classnames.bind(styles);

type TCircleData = {
  row: number;
  column: number;
};

type TGridCircles = {
  hoverCircleData: TCircleData;
  activeCircleData: TCircleData;
  startRow: number;
  startColumn: number;
  startGridRow?: number;
  startGridColumn?: number;
  endGridRow?: number;
  endGridColumn?: number;
  rows: number;
  columns: number;
  disabled?: boolean;
  onLeaveGrid?: () => void;
  onCircleClick?: (_rowIndex: number, _columnIndex: number) => void;
  onCircleMouseEnter?: (_rowIndex: number, _columnIndex: number, _e: MouseEvent<HTMLSpanElement>) => void;
  onCircleTouch?: (_rowIndex: number, _columnIndex: number, _e: TouchEvent<HTMLSpanElement>) => void;
  onCircleTouchEnd?: () => void;
  className?: string;
  withRowNumber?: boolean;
  innerRef: MutableRefObject<Nullable<HTMLDivElement>>;
};

const GridCircles: FC<TGridCircles> = ({
  rows,
  columns,
  disabled,
  onLeaveGrid,
  onCircleClick,
  onCircleMouseEnter,
  onCircleTouch,
  onCircleTouchEnd,
  startRow,
  startColumn,
  startGridRow = 0,
  startGridColumn = 0,
  endGridRow = 0,
  endGridColumn = 0,
  hoverCircleData,
  activeCircleData,
  className,
  withRowNumber,
  innerRef,
}) => {
  const rowsArr = useMemo(() => Array.from({ length: rows }, (_, i) => i), [rows]);
  const columnsArr = useMemo(() => Array.from({ length: columns }, (_, i) => i), [columns]);

  const isActive = useCallback(
    (rowIndex: number, columnIndex: number): boolean => {
      const rowCond = rowIndex === activeCircleData.row - startRow;
      const colCond = columnIndex === activeCircleData.column - startColumn;
      return rowCond && colCond;
    },
    [activeCircleData.row, activeCircleData.column, startRow, startColumn]
  );

  const isHovered = useCallback(
    (rowIndex: number, columnIndex: number): boolean => {
      const rowCond = rowIndex === hoverCircleData.row - startRow;
      const colCond = columnIndex === hoverCircleData.column - startColumn;

      return rowCond && colCond;
    },
    [hoverCircleData.row, hoverCircleData.column, startRow, startColumn]
  );

  const isHidden = useCallback(
    (rowIndex: number, columnIndex: number): boolean => {
      const colCond = startColumn + columnIndex < startGridColumn || startColumn + columnIndex > endGridColumn;
      const rowCond = startRow + rowIndex < startGridRow || startRow + rowIndex > endGridRow;
      return colCond || rowCond;
    },
    [startRow, startColumn, startGridRow, startGridColumn, endGridRow, endGridColumn]
  );

  return (
    <div
      className={cn('grid-minimap__rows-wrapper', { 'grid-minimap__disabled': disabled }, className)}
      onMouseLeave={onLeaveGrid}
      ref={innerRef}
    >
      {rowsArr.map((rowNumber, rowIndex) => (
        <div key={rowIndex} className={cn('grid-minimap__grid-row-wrapper')}>
          {withRowNumber && <span className={cn('grid-minimap__grid-row-number')}>{rowNumber + 1 + startRow}</span>}
          <div className={cn('grid-minimap__columns-wrapper')}>
            {columnsArr.map((_, columnIndex) => (
              <span
                key={columnIndex}
                className={cn('grid-minimap__circle-wrapper', { 'grid-minimap__circle_disabled': disabled })}
                role="presentation"
                onClick={() => !disabled && onCircleClick && onCircleClick(rowIndex, columnIndex)}
                onMouseEnter={(e) => !disabled && onCircleMouseEnter && onCircleMouseEnter(rowIndex, columnIndex, e)}
                onTouchMove={(e) => !disabled && onCircleTouch && onCircleTouch(rowIndex, columnIndex, e)}
                onTouchStart={(e) => !disabled && onCircleTouch && onCircleTouch(rowIndex, columnIndex, e)}
                onTouchEnd={() => !disabled && onCircleTouchEnd && onCircleTouchEnd()}
              >
                <span
                  className={cn('grid-minimap__circle', {
                    'grid-minimap__circle_10': !((columnIndex + 1 + startColumn) % 10),
                    'grid-minimap__circle_active': isActive(rowIndex, columnIndex),
                    'grid-minimap__circle_hover': isHovered(rowIndex, columnIndex) && !disabled,
                    'grid-minimap__circle_disabled': disabled,
                    'grid-minimap__circle_hidden': !withRowNumber && isHidden(rowIndex, columnIndex),
                  })}
                />
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(GridCircles);
