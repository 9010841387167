import { CSSProperties, ReactElement, ReactNode, useRef, useState, MouseEvent, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';

import styles from './Accordion.module.scss';

const cn = classnames.bind(styles);

type TAccordionProps = {
  children: ReactNode | ReactNode[];
  heading?: string | ReactElement;
  onHeadingClick?: () => void;
  defaultActive?: boolean;
  theme?: 'dark' | 'light';
  wrapperClassName?: string;
  className?: string;
  style?: CSSProperties;
  handleOpened?: (isOpened: boolean) => void;
};

const Accordion = ({
  children,
  heading,
  onHeadingClick,
  defaultActive = false,
  theme = 'dark',
  wrapperClassName,
  className,
  style,
  handleOpened,
}: TAccordionProps) => {
  const [isOpened, setIsOpened] = useState(defaultActive);
  const nodeRef = useRef<Nullable<HTMLDivElement>>(null);

  const toggleIsOpened = () => {
    setIsOpened((prev) => !prev);
  };

  const handleHeadingClick = () => {
    const hasHeadingClick = typeof onHeadingClick === 'function';
    if (hasHeadingClick) {
      onHeadingClick();
    } else {
      toggleIsOpened();
    }
  };

  const handleToggleClick = (event: MouseEvent) => {
    event.stopPropagation();
    toggleIsOpened();
  };

  useEffect(() => {
    if (handleOpened) {
      handleOpened(isOpened);
    }
  }, [handleOpened, isOpened]);

  return (
    <div role="presentation" className={cn('accordion', `accordion_${theme}`, wrapperClassName)}>
      <div
        className={cn('accordion__button', className)}
        style={style}
        onClick={handleHeadingClick}
        role="presentation"
      >
        <div className={cn('accordion__head')} role="presentation">
          {heading}
        </div>
        <div role="presentation" onClick={handleToggleClick}>
          <icons.ExpandMore className={cn('accordion__icon', { icon_opened: isOpened })} />
        </div>
      </div>
      <CSSTransition
        in={isOpened}
        timeout={300}
        classNames={{
          enter: cn('accordion__enter'),
          enterActive: cn('accordion__enter_active'),
          exit: cn('accordion__exit'),
          exitActive: cn('accordion__exit_active'),
        }}
        nodeRef={nodeRef}
        unmountOnExit
      >
        <div ref={nodeRef} className={cn('accordion__content-wrapper')}>
          <div className={cn('accordion__content')}>{children}</div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Accordion;
