type TGenListData = {
  genes: string[];
};

type TGenOptionListData = {
  genes: TBasicOption[];
};

const genListData: TGenOptionListData = {
  genes: [],
};

export const getGenes = async (limit = 0, offset = 0, searchStr = '') => {
  try {
    if (!genListData.genes.length) {
      const genList = (await (await fetch('/gene-list.json')).json()) as TGenListData;
      genListData.genes = genList.genes.map((el) => ({
        label: el,
        value: el,
      }));
    }

    const filtered = searchStr
      ? genListData.genes.filter((gene) => gene.label.toLowerCase().includes(searchStr.toLowerCase()))
      : genListData.genes;

    const sliced = offset ? filtered.slice(offset) : filtered;

    if (limit) {
      sliced.length = limit;
    }

    return sliced;
  } catch (err) {
    return [];
  }
};

export const clearGenes = () => {
  genListData.genes = [];
};
