import { createSelector } from '@reduxjs/toolkit';

import { removeDuplicates } from '@/helpers';
import { findDefaultScanInScanList, findLaneInScanList, findScanInScanList } from '@/helpers/scans';

import type { RootState } from '@/store';
import { appAPI } from '@/store/services/app';

export const selectCurrentExperimentId = (state: RootState) => state.experiment.currentExperimentId;

export const selectCurrentExperiment = createSelector(
  [(state) => state, selectCurrentExperimentId],
  (state, experimentId) => appAPI.endpoints.fetchExperimentById.select(experimentId)(state)?.data
);

export const selectCurrentExperimentName = createSelector(
  [(state) => state, selectCurrentExperimentId],
  (state, experimentId) => appAPI.endpoints.fetchExperimentById.select(experimentId)(state)?.data?.name
);

export const selectCurrentScanList = createSelector(
  [(state) => state, selectCurrentExperimentId],
  (state, experimentId) => appAPI.endpoints.fetchExperimentScans.select(experimentId)(state)?.data ?? []
);

export const selectCurrentScanId = (state: RootState) => state.experiment.currentScanId;

export const selectCurrentScan = createSelector(
  [selectCurrentScanList, selectCurrentScanId],
  (scanList, currentScanId) => findScanInScanList(scanList, currentScanId)
);

export const selectCagingScanList = createSelector([selectCurrentScanList], (scanList) => {
  if (!scanList?.length) {
    return [];
  }
  return scanList.filter((scan) => scan.isCaging);
});

export const selectCagingScan = createSelector([selectCurrentScanList], (scanList) => {
  if (!scanList?.length) {
    return null;
  }
  return scanList.find((scan) => scan.isCaging) ?? scanList[scanList.length - 1];
});

export const selectDefaultScan = createSelector([selectCurrentScanList], (scanList) =>
  findDefaultScanInScanList(scanList)
);

export const selectScan = (scanId: string) =>
  createSelector([selectCurrentScanList], (scanList) => findScanInScanList(scanList, scanId));

export const selectExperimentLaneList = createSelector([selectCurrentScanList], (scanList) =>
  scanList.flatMap((scan) => scan.lanes)
);

export const selectCurrentLaneList = createSelector([selectCurrentScan], (currentScan) => currentScan?.lanes ?? []);

export const selectAllLaneList = createSelector([selectCurrentScanList], (currentScanList) => {
  const lanes = currentScanList.reduce(
    (acc: TLane[], scan) => removeDuplicates([...acc, ...(scan.lanes ?? [])], 'id'),
    []
  );
  return lanes;
});

export const selectAllCagingLaneList = createSelector(
  [selectCagingScanList, selectCurrentScanList],
  (cagingScanList, scanList) => {
    const cagingLanes = cagingScanList.reduce(
      (acc: TLane[], scan) => removeDuplicates([...acc, ...(scan.lanes ?? [])], 'id'),
      []
    );
    const lanes = scanList.reduce((acc: TLane[], scan) => removeDuplicates([...acc, ...(scan.lanes ?? [])], 'id'), []);
    return removeDuplicates([...lanes, ...cagingLanes], 'id');
  }
);

export const selectCurrentLaneId = (state: RootState) => state.experiment.currentLaneId;

export const selectCurrentLane = createSelector(
  [selectCurrentLaneList, selectCurrentLaneId],
  (laneList, currentLaneId) => laneList.find((lane) => lane.id === currentLaneId) ?? null
);

export const selectLane = (scanId: string, laneId: string) =>
  createSelector([selectCurrentScanList], (scanList) => findLaneInScanList(scanList, scanId, laneId));

export const selectCurrentDataset = createSelector([selectCurrentLane], (currentLane) => currentLane?.dataset ?? null);

export const selectAllLaneMap = createSelector([selectCurrentScanList], (scanList) => {
  const laneMap: Record<string, TLane> = scanList.reduce((acc: Record<string, TLane>, scan) => {
    scan.lanes.forEach((lane) => {
      acc[lane.id] = lane;
    });

    return acc;
  }, {});
  return laneMap;
});

export const selectNoSequencingLaneDataList = (state: RootState) => state.experiment.noSequencingLaneDataList;
