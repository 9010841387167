import { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TCdnObjectType } from '@/types/cdnData';

import { getErrorMessage } from '@/helpers/errors';
import { getCdnTableDataFileName } from '@/helpers/cdnData/fetch';

import { viewerSelectors } from '@/store/slices/viewer';

export type TUseCdnTablesData = {
  objectList: Array<TCdnObject>;
  isLoading: boolean;
  loadingError: Nullable<string>;
  isDisabled: boolean;
};

const cdnDataMap = new Map();

export const clearCdnDataCache = () => {
  cdnDataMap.clear();
};

export function useCdnTablesData(lane: Nullable<TLane>, type: TCdnObjectType): TUseCdnTablesData {
  const useOptimizedData = useSelector(viewerSelectors.selectUseOptimizedData);
  const savedUseOptimizedData = useRef(useOptimizedData);

  const cdnDataMapKey = useMemo(() => JSON.stringify({ type, path: lane?.path }), [type, lane]);

  const [objectList, setObjectList] = useState<Array<TCdnObject>>(
    cdnDataMap.has(cdnDataMapKey) ? cdnDataMap.get(cdnDataMapKey) : []
  );
  const [isFileNameSpecified, setIsFileNameSpecified] = useState(false);
  const [isLoading, setIsLoading] = useState(!cdnDataMap.has(cdnDataMapKey));
  const [loadingError, setLoadingError] = useState<Nullable<string>>(null);

  const loadingPathList = useRef<string[]>([]);

  const handleWorkerError = (error: unknown) => {
    loadingPathList.current = loadingPathList.current.filter((path) => path !== lane?.path);
    setLoadingError(getErrorMessage(error));
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setLoadingError(null);
    setObjectList([]);
    setIsFileNameSpecified(false);

    if (!lane?.path) {
      handleWorkerError(`Fetching ${type} objects requires a lane path`);
      return;
    }

    const { fileName } = getCdnTableDataFileName({ lane, type, useOptimizedData });
    if (!fileName) {
      handleWorkerError(`No filename specified for ${type}`);
      return;
    }
    setIsFileNameSpecified(true);

    if (useOptimizedData !== savedUseOptimizedData.current) {
      cdnDataMap.clear();
      loadingPathList.current = [];
      savedUseOptimizedData.current = useOptimizedData;
    }
    if (cdnDataMap.has(cdnDataMapKey)) {
      setIsLoading(false);
      setObjectList(cdnDataMap.get(cdnDataMapKey));
      return;
    }

    if (loadingPathList.current.includes(lane.path)) {
      return;
    }
    loadingPathList.current.push(lane.path);

    const fetchDataWorker = new Worker(new URL('@/webWorkers/fetchCdnDataWorker', import.meta.url));
    const resultObjectList: TCdnObject[] = [];
    fetchDataWorker.onmessage = (event) => {
      const { objectListChunk, isLastChunk, error } = event.data;
      if (error) {
        handleWorkerError(error);
        fetchDataWorker.terminate();
        return;
      }
      const len = objectListChunk.length;
      for (let i = 0; i < len; i++) {
        resultObjectList.push(objectListChunk[i]);
      }
      setObjectList(resultObjectList);
      if (isLastChunk) {
        cdnDataMap.set(cdnDataMapKey, resultObjectList);
        setIsLoading(false);
        loadingPathList.current = loadingPathList.current.filter((path) => path !== lane.path);
        fetchDataWorker.terminate();
      }
    };
    fetchDataWorker.onerror = (error) => {
      handleWorkerError(error);
      fetchDataWorker.terminate();
    };
    fetchDataWorker.postMessage({ lane, type, useOptimizedData });

    return () => {
      setIsLoading(false);
      loadingPathList.current = loadingPathList.current.filter((path) => path !== lane.path);
      fetchDataWorker.terminate();
    };
  }, [lane?.path, type, useOptimizedData]);

  return {
    objectList,
    isLoading,
    loadingError,
    isDisabled: !isFileNameSpecified,
  };
}
