import { SVGProps } from 'react';

const DatasetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M16 11c-1.218-.015-2.55-.023-3.997-.022-.833.001-2.167.008-4.003.022.003-3.323.003-5.99 0-8a8 8 0 1 0 8 8z"
        fill="currentColor"
      />
      <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" d="M12 1h6M12 4h6M12 7h6" />
    </g>
  </svg>
);

export default DatasetIcon;
