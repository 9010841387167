import { SVGProps } from 'react';

const MaxCellSizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="19.5" y="26.4199" width="10" height="10" rx="5" fill="#F9EF7D" />
    <rect x="1.5" y="26.4199" width="10" height="10" rx="5" fill="#F9EF7D" />
    <rect
      x="8.67188"
      y="48.8379"
      width="10"
      height="10"
      rx="5"
      transform="rotate(-60 8.67188 48.8379)"
      fill="#F9EF7D"
    />
    <rect x="17.3281" width="20" height="20" rx="10" transform="rotate(60 17.3281 0)" fill="#57D4FB" />
    <rect x="37.5" y="26.4199" width="10" height="10" rx="5" fill="#F9EF7D" />
    <rect
      x="26.6719"
      y="17.6621"
      width="10"
      height="10"
      rx="5"
      transform="rotate(-60 26.6719 17.6621)"
      fill="#F9EF7D"
    />
    <rect x="35.3359" y="40.1777" width="10" height="10" rx="5" transform="rotate(60 35.3359 40.1777)" fill="#F9EF7D" />
  </svg>
);

export default MaxCellSizeIcon;
