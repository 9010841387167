import { SVGProps } from 'react';

const SquareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x=".75"
      y=".75"
      width="14.5"
      height="14.5"
      rx="1"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SquareIcon;
