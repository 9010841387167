import { SVGProps } from 'react';

const AddMediaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M42 14.8177C42 18.7844 38.866 22 35 22C31.134 22 28 18.7844 28 14.8177C28 10.8511 31.6667 7.85714 35 4C38.3333 7.85714 42 10.8511 42 14.8177Z"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      d="M16 14.8177C16 18.7844 12.866 22 9 22C5.13401 22 2 18.7844 2 14.8177C2 10.8511 5.66667 7.85714 9 4C12.3333 7.85714 16 10.8511 16 14.8177Z"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      d="M29 35.8177C29 39.7844 25.866 43 22 43C18.134 43 15 39.7844 15 35.8177C15 31.8511 18.6667 28.8571 22 25C25.3333 28.8571 29 31.8511 29 35.8177Z"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path d="M42 35L42 51" stroke="currentColor" strokeWidth="4" />
    <path d="M50 43L34 43" stroke="currentColor" strokeWidth="4" />
  </svg>
);

export default AddMediaIcon;
