import { SVGProps } from 'react';

const ExpandScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.5 1.5L9.55025 6.44975" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6.5 9.5L1.55025 14.4497" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14.5 1.5H9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14.5 1.5V6.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6.5 14.5H1.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1.5 9.5V14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ExpandScreenIcon;
