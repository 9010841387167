import { EPlotlyChartType } from '@/types/charts';

export const LAYOUT_CONFIG = () => ({
  margin: {
    l: 10,
    r: 10,
    t: 10,
    b: 0,
    pad: 0,
  },
  xaxis: {
    automargin: true,
    color: '#000',
    linecolor: '#000',
    showgrid: false,
    zeroline: false,
    title: null,
    titlefont: {
      family: 'Inter Display, Arial, sans-serif',
      size: 13,
      color: '#989898',
    },
  },
  yaxis: {
    color: '#989898',
    linecolor: '#ffffff',
    gridcolor: '#e6e6e6',
    zeroline: false,
    showticklabels: true,
    automargin: true,
    title: null,
    titlefont: {
      family: 'Inter Display, Arial, sans-serif',
      size: 13,
      color: '#989898',
    },
  },
  autosize: true,
  plot_bgcolor: 'transparent',
  paper_bgcolor: 'transparent',
});

export const CONFIG = () => ({
  displaylogo: false,
  staticPlot: true,
});

export const BASE_DATA_CONFIG = () => ({
  mode: 'markers' as const,
  showlegend: false,
  // temporary solution for the potly optimizing. Causes webGL context limit error
  type: EPlotlyChartType.scattergl,
  marker: {
    color: '#1f1f1f',
    size: 2,
    line: {
      color: '#000000',
      width: 2,
    },
  },
  hoverlabel: {
    bgcolor: '#ffffff',
    bordercolor: '#cccccc',
    font: {
      color: '#000000',
    },
  },
});
