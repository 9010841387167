import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { MDASH, addTooltip } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { prepareSummaryValue } from '@/helpers/experiment/summary';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useParamsExperimentId from '@/hooks/useParamsExperimentId';

import { datasetsActions, datasetsSelectors } from '@/store/slices/datasets';
import { appAPI } from '@/store/services/app';
import { experimentSelectors } from '@/store/slices/experiment';

import DetailsButton from '@/components/common/DetailsButton';
import Input from '@/components/common/Input';
import SequencingData from '@/pages/Experiment/components/SequencingData';

import DatasetDetailsTable from './DatasetDetailsTable';
import ChannelDetailsTable from './ChannelDetailsTable';
import { ECagesSummary, TCagesSummaryKeys } from '../types';
import styles from './LaneDetailsRow.module.scss';

const cn = classnames.bind(styles);

type TLaneDetailsRowProps = {
  laneDetails: TLaneDetails;
  isMinimalView: boolean;
  handleGenomeSelect: (genome: string) => void;
  genomePresetValue?: string;
  selectedFcConfig: string;
  handleFcConfigChange: (fcConfig: string) => void;
};

const summaryPercentageFields: Record<string, 'percentageOfCellsCaged' | 'percentageOfCellsOutsideCage'> = {
  numberOfCellsCaged: 'percentageOfCellsCaged',
  numberOfCellsOutsideCage: 'percentageOfCellsOutsideCage',
};

const isScanContainLain = (scan: Nullable<TScan>, laneId: string) => scan?.lanes.find((lane) => lane.id === laneId);

const LaneDetailsRow: FC<TLaneDetailsRowProps> = ({
  laneDetails,
  isMinimalView,
  handleGenomeSelect,
  genomePresetValue,
  selectedFcConfig,
  handleFcConfigChange,
}) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const [updateExperimentSampleFriendlyName] = appAPI.useUpdateExperimentSampleFriendlyNameMutation();
  const cagingScanList = useSelector(experimentSelectors.selectCagingScanList);
  const currentScanList = useSelector(experimentSelectors.selectCurrentScanList);
  const cagingScan = useSelector(experimentSelectors.selectCagingScan);

  const { data: sequencingLaneData } = appAPI.useFetchSequencingDataByLanesQuery({
    experimentId,
    laneId: laneDetails?.id,
  });

  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const isEditMode = useSelector(datasetsSelectors.selectEditMode);

  const laneScan = useMemo(() => {
    if (isScanContainLain(cagingScan, laneDetails.id)) {
      return cagingScan;
    }
    const cagingLaneScan = cagingScanList.findLast((scan) => isScanContainLain(scan, laneDetails.id)) ?? null;
    if (cagingLaneScan) {
      return cagingLaneScan;
    }

    const laneScanFromAllScans = currentScanList.findLast((scan) => isScanContainLain(scan, laneDetails.id)) ?? null;
    return laneScanFromAllScans;
  }, [cagingScanList, currentScanList, cagingScan, laneDetails.id]);

  const handleDetailsButtonClick = () => {
    appDispatch(datasetsActions.toggleLaneDetails({ experimentId, laneId: laneDetails.id }));
  };

  const handleSaveValue = (currentValue: string) => {
    const laneId = laneDetails.id;
    const scanWithSpecifiedLaneList = currentScanList.filter((scan) => scan.lanes.find((lane) => lane.id === laneId));
    Promise.all(
      scanWithSpecifiedLaneList.map((scanWithSpecifiedLane) =>
        updateExperimentSampleFriendlyName({
          experimentId,
          scanId: scanWithSpecifiedLane.id,
          laneId,
          friendlyName: currentValue,
        }).unwrap()
      )
    ).catch((error) => {
      showErrorToast(getErrorMessage(error));
    });
  };

  const isChannelsDetailsTableView = useMemo<boolean>(() => laneDetails.isOpen, [laneDetails.isOpen]);

  useEffect(() => {
    appDispatch(
      datasetsActions.toggleLaneDetails({ experimentId, laneId: laneDetails.id, isOpen: !isPreprocessingView })
    );
  }, [isPreprocessingView]);

  return (
    <div className={cn('scan-table', { 'scan-table_minimal': isMinimalView })}>
      <div className={cn('table__row', 'row', { table__row_edit: isEditMode, table__row_minimal: isMinimalView })}>
        {isEditMode ? (
          <Input initialValue={laneDetails.sampleFriendlyName ?? laneDetails.sampleName} handleSave={handleSaveValue} />
        ) : (
          <div
            className={cn('table__col', 'table__col_sample-name')}
            {...addTooltip(laneDetails.sampleFriendlyName ?? laneDetails.sampleName ?? MDASH)}
          >
            {laneDetails.sampleFriendlyName ?? laneDetails.sampleName ?? MDASH}
          </div>
        )}

        <div className={cn('table__col')}>{laneDetails.letterName}</div>
        {!isMinimalView && (
          <>
            {laneScan?.summary && (
              <>
                {Object.keys(ECagesSummary).map((key) => (
                  <div key={key} className={cn('table__col')}>
                    {prepareSummaryValue(key, laneScan.summary[key as TCagesSummaryKeys])}
                    {!!summaryPercentageFields[key] &&
                      ` (${prepareSummaryValue(
                        summaryPercentageFields[key],
                        laneScan.summary[summaryPercentageFields[key]]
                      )})`}
                  </div>
                ))}
              </>
            )}
            <div className={cn('table__col', 'table__col_row', { 'table__col_no-edit': !isEditMode })}>
              <SequencingData
                laneDetails={laneDetails}
                isInEditMode={isEditMode}
                sequencingData={sequencingLaneData?.sequencingData?.[0]}
                flowcellTypeFromServer={sequencingLaneData?.flowcellType}
                genomePresetValue={genomePresetValue}
                handleGenomeSelect={handleGenomeSelect}
                selectedFcConfig={selectedFcConfig}
                handleFcConfigChange={handleFcConfigChange}
              />
            </div>
            <DetailsButton isOpen={!isChannelsDetailsTableView} onClick={handleDetailsButtonClick} />
          </>
        )}
      </div>
      <div className={cn('accordion__content')}>
        {!isChannelsDetailsTableView && <DatasetDetailsTable laneId={laneDetails.id} />}
        {isChannelsDetailsTableView && <ChannelDetailsTable laneId={laneDetails.id} isMinimalView={isMinimalView} />}
      </div>
    </div>
  );
};

export default LaneDetailsRow;
