import { useId, useMemo } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { lexSort } from '@/helpers/arrays';
import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';

import useSorting from '@/hooks/useSorting';

import NoDataFound from '@/components/common/NoDataFound';
import SortedFieldHeading from '@/components/common/SortedFieldHeading';
import Panel from '@/components/common/Panel';

import StandardTableRow from './StandardTableRow';
import type { TAction, TActionsFormat, TStandardField, TStandardValue } from './types';

import styles from './StandardTable.module.scss';

const cn = classnames.bind(styles);

type TStandardTableProps<T> = {
  dataList: T[];
  fieldList: TStandardField<T>[];
  keyFieldName: keyof T;
  searchQuery: string;
  isLoading: boolean;
  errorMessage?: string;
  actions?: TAction<T>[];
  onRowClick?: (data: T) => void;
  actionsFormat?: TActionsFormat;
};

const StandardTable = <T extends object>({
  dataList,
  fieldList,
  keyFieldName,
  searchQuery,
  isLoading,
  errorMessage,
  actions,
  onRowClick,
  actionsFormat = 'popover',
}: TStandardTableProps<T>) => {
  const uid = useId();

  const searchWords = useMemo(() => formatHighlightSearchQuery(searchQuery), [searchQuery]);

  const filteredList = useMemo(() => {
    const searchLowerCase = searchQuery.toLowerCase();
    return dataList.filter((data) =>
      fieldList.some((field) =>
        ((data[field.name] ?? '') as TStandardValue).toString().toLowerCase().includes(searchLowerCase)
      )
    );
  }, [dataList, fieldList, searchQuery]);

  const sortingMethods = useMemo(() => {
    const methods: Record<string, (a: T, b: T) => number> = {};
    fieldList.forEach((field) => {
      if (field.isSortable) {
        methods[field.name as string] = (a: T, b: T) =>
          lexSort((a[field.name] ?? '') as TStandardValue, (b[field.name] ?? '') as TStandardValue);
      }
    });
    return methods;
  }, [fieldList]);

  const { setSortingSettings, sortedData, sortingField, sortingOrder } = useSorting([...filteredList], sortingMethods);

  const handleSortingFactory = (field: string) => () => {
    setSortingSettings(field);
  };

  if (isLoading && !dataList.length) {
    return null;
  }

  if (errorMessage) {
    return <NoDataFound size="normal" textData={errorMessage} />;
  }

  if (!dataList.length) {
    return <NoDataFound size="normal" />;
  }

  return (
    <Panel theme={themeOptions.light} className={cn('standard-table')}>
      <Panel.Content
        className={cn('standard-table__content', { 'standard-table__content_empty': sortedData.length === 0 })}
      >
        {sortedData.length === 0 && <NoDataFound size="small" />}

        {sortedData.length > 0 && (
          <table className={cn('standard-table__table')}>
            <thead>
              <tr className={cn('header-row')}>
                {fieldList.map((field) => (
                  <th key={field.name as string} className={cn(field.className, { 'fit-content': field.isFitContent })}>
                    {field.isSortable && (
                      <SortedFieldHeading
                        id={`${uid}-${field.name as string}`}
                        fieldName={field.name as string}
                        sortingField={sortingField}
                        sortingOrder={sortingOrder}
                        onChangeSorting={handleSortingFactory(field.name as string)}
                        arrowPositionMode={field.arrowPositionMode}
                      >
                        {field.label}
                      </SortedFieldHeading>
                    )}
                    {!field.isSortable && field.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((data) => (
                <StandardTableRow
                  key={data[keyFieldName] as TStandardValue}
                  data={data}
                  fieldList={fieldList}
                  keyFieldName={keyFieldName}
                  searchWords={searchWords}
                  actions={actions}
                  onClick={onRowClick}
                  actionsFormat={actionsFormat}
                />
              ))}
            </tbody>
          </table>
        )}
      </Panel.Content>
    </Panel>
  );
};

export default StandardTable;
