import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type TPortal = {
  portalId?: string;
  children?: ReactNode;
};

const Portal = ({ children, portalId }: TPortal) => {
  const portalRoot = (portalId && document.getElementById(portalId)) || document.body;
  return createPortal(children, portalRoot);
};

export default Portal;
