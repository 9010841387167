import { useCallback, useRef, useState } from 'react';
import { select as d3Select, selectAll as d3SelectAll } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';

import { getGateById } from '@/helpers/gates';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { getMinMax } from '@/helpers/arrays';

import { updateLabelPosition } from './helpers/common';
import { HANDLERS_OFFSET } from './constants';
import {
  MIN_RECT_WIDTH,
  drawRectangleGate,
  getRectangleGatePointsFromChartScale,
  updateGateDataByControlType,
  updateLabelOnRectangleGate,
} from './helpers/rectangleGates';
import {
  TSelectionRectElement,
  TD3DragEvent,
  EGateDragType,
  TSelectionGroupElement,
  TUseGatesOnPlotPayload,
} from './types';

type TRectGateData = {
  rectData: TGatePolygons;
  rectangleElement: TSelectionRectElement;
  bottomLeftElement: TSelectionRectElement;
  topLeftElement: TSelectionRectElement;
  topRightElement: TSelectionRectElement;
  bottomRightElement: TSelectionRectElement;
  gateContainer: TSelectionGroupElement;
};

export function useRectangleGates({
  chartContainerParameters,
  plotId,
  plotRange,
  isStatic,
  openGateCreationModal,
  displayType,
  updateGate,
  entityLevelGateList,
  handleGateDrag,
  xAxisScaleType,
  yAxisScaleType,
  origDataRange,
  isGateMenuHidden,
}: TUseGatesOnPlotPayload) {
  const [newRectangleData, setNewRectangleData] = useState<Nullable<TRectGateData>>(null);
  const [isGateDrawing, setIsGateDrawing] = useState<boolean>(false);
  const isChangedRef = useRef(false);

  const updateRectangleGate = useCallback(
    (currentShapeData?: TRectGateData) => {
      const shapeData = currentShapeData ?? newRectangleData;

      if (!shapeData) return;

      const rect = shapeData.rectangleElement;
      const gateIdAttr = rect.attr('gate-id');
      const gateId = gateIdAttr.split('gate_')[1];
      const { rectData } = shapeData;

      const xStart = Math.min(rectData[0].x, rectData[1].x);
      const xEnd = Math.max(rectData[0].x, rectData[1].x);
      const yBottom = Math.max(rectData[0].y, rectData[1].y); // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
      const yTop = Math.min(rectData[0].y, rectData[1].y); // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper

      rect
        .attr('x', Math.round(xStart))
        .attr('y', Math.round(yTop))
        .attr('width', Math.abs(xEnd - xStart))
        .attr('height', Math.abs(yTop - yBottom));

      const bottomLeftPoint = shapeData.bottomLeftElement;
      bottomLeftPoint.attr('x', xStart - HANDLERS_OFFSET).attr('y', yBottom - HANDLERS_OFFSET);
      const topLeftPoint = shapeData.topLeftElement;
      topLeftPoint.attr('x', xStart - HANDLERS_OFFSET).attr('y', yTop - HANDLERS_OFFSET);

      const topRightPoint = shapeData.topRightElement;
      topRightPoint.attr('x', xEnd - HANDLERS_OFFSET).attr('y', yTop - HANDLERS_OFFSET);
      const bottomRightPoint = shapeData.bottomRightElement;
      bottomRightPoint.attr('x', xEnd - HANDLERS_OFFSET).attr('y', yBottom - HANDLERS_OFFSET);

      updateLabelOnRectangleGate(gateId, xStart, yTop, displayType, plotId);
    },
    [newRectangleData]
  );

  const onRectDragStart = (rect: TSelectionRectElement) => {
    const gateId = rect.attr('gate-id').split('gate_')[1];
    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onRectGateDrag = (rect: TSelectionRectElement, event: TD3DragEvent) => {
    const gateId = rect.attr('gate-id').split('gate_')[1];
    const gateGroupElement = rect.node()?.parentNode ?? null;

    if (!gateGroupElement) return;

    const controls = d3Select(gateGroupElement as Element)
      .selectAll(`[gate-control-id = 'gate-control_${gateId}']`)
      .nodes();

    updateLabelPosition(gateId, [event.dx, event.dy], isGateMenuHidden, plotId);

    handleGateDrag(gateId, EGateDragType.drag);

    controls.forEach((control) => {
      const controlEl = d3Select(control);
      const cx = parseFloat(controlEl.attr('x'));
      const cy = parseFloat(controlEl.attr('y'));

      controlEl.attr('x', cx + event.dx).attr('y', cy + event.dy);
    });
    rect.attr('x', parseFloat(rect.attr('x')) + event.dx).attr('y', parseFloat(rect.attr('y')) + event.dy);
  };

  const onRectShapeDragEnd = useCallback(
    (rect: TSelectionRectElement) => {
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
      const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn

      if (!shapesContainerParameters || !chartContainerParameters || !plotRange) return;
      const rectWidth = parseFloat(rect.attr('width'));
      const rectHeight = parseFloat(rect.attr('height'));
      const rectX = parseFloat(rect.attr('x'));
      const rectY = parseFloat(rect.attr('y'));
      const gateId = rect.attr('gate-id').split('gate_')[1];
      const points = [
        { x: rectX, y: rectY },
        { x: rectX + rectWidth, y: rectY + rectHeight },
      ];

      const updatedPolygons = getRectangleGatePointsFromChartScale(points, shapesContainerParameters, plotRange);
      const gate = getGateById(gateId, entityLevelGateList);

      if (!updatedPolygons || !gate) return;
      handleGateDrag(gateId, EGateDragType.dragEnd, {
        ...gate,
        shape: {
          type: 'polygon',
          model: {
            points: updatedPolygons,
          },
        },
      });

      if (!isChangedRef.current) {
        return;
      }

      updateGate(gate, {
        shape: {
          type: 'polygon',
          model: {
            points: axisScaleHelper.getRealGatePoints({
              xAxisScaleType,
              yAxisScaleType,
              gatePoints: updatedPolygons,
              plotLinearRange: plotRange,
              origDataRange,
            }),
          },
        },
      });
      isChangedRef.current = false;
    },
    [chartContainerParameters, plotRange, entityLevelGateList]
  );

  const handleRectangleDrag = d3Drag<SVGRectElement, unknown>()
    .on('start', function handleDragStart() {
      const rect = d3Select(this);
      onRectDragStart(rect);
    })
    .on('drag', function handleDrag(event) {
      const rect = d3Select(this);
      onRectGateDrag(rect, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const rect = d3Select(this);
      onRectShapeDragEnd(rect);
    });

  const onRectControlDragStart = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onRectControlDrag = (control: TSelectionRectElement, event: TD3DragEvent) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    const controlType = control.attr('control-position');
    const gateContainer: TSelectionGroupElement = d3Select(`[gate-group-id = 'group_${gateId}']`);

    const gateGroupElement = control.node()?.parentNode ?? null;

    if (!gateGroupElement) return;

    const rect: TSelectionRectElement = d3Select(gateGroupElement as Element).select(`[gate-id = 'gate_${gateId}']`);
    const rectWidth = parseFloat(rect.attr('width'));
    const rectHeight = parseFloat(rect.attr('height'));
    const rectX = parseFloat(rect.attr('x'));
    const rectY = parseFloat(rect.attr('y'));
    const rectData = [
      { x: rectX, y: rectY },
      { x: rectX + rectWidth, y: rectY + rectHeight },
    ];

    handleGateDrag(gateId, EGateDragType.drag);

    const data: TRectGateData = {
      rectData: updateGateDataByControlType(rectData, controlType, { x: event.dx, y: event.dy }),
      rectangleElement: rect,
      bottomLeftElement: d3SelectAll(
        `rect[gate-control-id = '${controlGateIdAttr}'][control-position = 'bottom-left']`
      ),
      topLeftElement: d3SelectAll(`rect[gate-control-id = '${controlGateIdAttr}'][control-position = 'top-left']`),
      topRightElement: d3SelectAll(`rect[gate-control-id = '${controlGateIdAttr}'][control-position = 'top-right']`),
      bottomRightElement: d3SelectAll(
        `rect[gate-control-id = '${controlGateIdAttr}'][control-position = 'bottom-right']`
      ),
      gateContainer,
    };

    updateRectangleGate(data);
  };

  const onRectControlDragEnd = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    const gateGroupElement = control.node()?.parentNode ?? null;

    if (!gateGroupElement) return;

    const rect: TSelectionRectElement = d3Select(gateGroupElement as Element).select(`[gate-id = 'gate_${gateId}']`);

    onRectShapeDragEnd(rect);
  };

  const controlDragHandler = d3Drag<SVGRectElement, unknown>()
    .on('start', function handleDragStart() {
      const control = d3Select(this);
      onRectControlDragStart(control);
    })
    .on('drag', function handleDrag(event) {
      const control = d3Select(this);
      onRectControlDrag(control, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const control = d3Select(this);
      onRectControlDragEnd(control);
    });

  const handleRectGateMouseDown = useCallback(
    (eventData: number[]) => {
      if (newRectangleData) return setIsGateDrawing(false);

      const rectData = [
        { x: eventData[0], y: eventData[1] },
        { x: eventData[0] + MIN_RECT_WIDTH, y: eventData[1] + MIN_RECT_WIDTH },
      ];

      const rectPolygons = [
        { x: eventData[0], y: eventData[1] },
        { x: eventData[0], y: eventData[1] + MIN_RECT_WIDTH },
        { x: eventData[0] + MIN_RECT_WIDTH, y: eventData[1] + MIN_RECT_WIDTH },
        { x: eventData[0], y: eventData[1] },
      ];
      const gateId = `${Date.now()}`;
      const drawnElements = drawRectangle({ gateId, gatePolygons: rectPolygons, label: '', isDrawing: true });
      const gateData = { ...drawnElements, rectData };

      setNewRectangleData(gateData);
      updateRectangleGate(gateData);
      setIsGateDrawing(true);
    },
    [entityLevelGateList, newRectangleData]
  );

  const handleRectGateMouseMove = useCallback(
    (eventData: number[]) => {
      if (!newRectangleData || !isGateDrawing) return;

      setNewRectangleData((prevState) => {
        if (!prevState) return null;
        const prevData = { ...prevState };
        prevData.rectData[1] = { x: eventData[0], y: eventData[1] };
        updateRectangleGate(prevData);
        return prevData;
      });
    },
    [newRectangleData, isGateDrawing]
  );

  const handleRectGateMouseUp = useCallback(() => {
    const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
    const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn

    if (!newRectangleData?.rectData || !shapesContainerParameters || !chartContainerParameters || !plotRange) return;
    const xPoints = newRectangleData.rectData.map((data) => data.x);

    const { min: xMin, max: xMax } = getMinMax(xPoints);

    if (xMax - xMin <= MIN_RECT_WIDTH) {
      newRectangleData.gateContainer.remove();
      setNewRectangleData(null);
      setIsGateDrawing(false);
      return;
    }

    const updatedPolygons = getRectangleGatePointsFromChartScale(
      newRectangleData.rectData,
      shapesContainerParameters,
      plotRange
    );

    if (!updatedPolygons) return setNewRectangleData(null);
    const realPolygons = axisScaleHelper.getRealGatePoints({
      xAxisScaleType,
      yAxisScaleType,
      gatePoints: updatedPolygons,
      plotLinearRange: plotRange,
      origDataRange,
    });
    openGateCreationModal({ type: 'rectangle', model: { points: realPolygons } });
    setIsGateDrawing(false);
  }, [plotId, plotRange, chartContainerParameters, newRectangleData]);

  const drawRectangle = ({
    gateId,
    gatePolygons,
    label,
    groupClassName = 'gate-group',
    isDrawing = false,
  }: {
    gateId: string;
    gatePolygons: TGatePolygons;
    label: string;
    groupClassName?: string;
    isDrawing?: boolean;
  }) => {
    const svg = d3Select(`#${plotId} .gates-container`);
    const group = svg
      .append('g')
      .attr('gate-group-id', `group_${gateId}`)
      .attr('class', groupClassName)
      .attr('gate-type', 'rectangle');
    const { rectangleElement, bottomLeftElement, topLeftElement, topRightElement, bottomRightElement } =
      drawRectangleGate({
        gateId,
        gatePolygons,
        label,
        gateContainer: group,
        displayType,
        isGateMenuHidden,
      });

    if (!isStatic) {
      rectangleElement.call(handleRectangleDrag);

      bottomLeftElement.call(controlDragHandler);

      topLeftElement.call(controlDragHandler);
      topRightElement.call(controlDragHandler);
      bottomRightElement.call(controlDragHandler);
    }

    if (isDrawing) {
      bottomLeftElement.style('opacity', 1);
      topLeftElement.style('opacity', 1);
      topRightElement.style('opacity', 1);
      bottomRightElement.style('opacity', 1);
    }

    return {
      rectangleElement,
      bottomLeftElement,
      topLeftElement,
      topRightElement,
      bottomRightElement,
      gateContainer: group,
    };
  };

  return {
    newRectangleData,
    setNewRectangleData,
    drawRectangle,
    updateRectangleGate,
    handleRectGateMouseDown,
    handleRectGateMouseMove,
    handleRectGateMouseUp,
  };
}

export default useRectangleGates;
