import { SVGProps } from 'react';

const ExperimentStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H6.4C4.16 0 3.04 0 2.184.436A4 4 0 0 0 .436 2.184C0 3.04 0 4.16 0 6.4v6.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C3.04 19 4.16 19 6.4 19H11"
      />
    </mask>
    <path
      d="M11-1a1 1 0 1 1 0 2v-2Zm0 19a1 1 0 1 1 0 2v-2ZM.436 16.816l.891-.454-.891.454Zm1.748 1.748.454-.891-.454.891Zm0-18.128.454.891-.454-.891ZM.436 2.184l-.891-.454.891.454ZM11 1H7.462v-2H11v2ZM7.462 1H6.4v-2h1.062v2ZM1 6.4v6.2h-2V6.4h2ZM6.4 18h1.089v2H6.4v-2Zm1.089 0H11v2H7.489v-2ZM1 12.6c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216l-1.782.908c-.302-.592-.428-1.232-.487-1.961C-1.001 14.59-1 13.704-1 12.6h2ZM6.4 20c-1.104 0-1.991 0-2.709-.058-.728-.06-1.369-.185-1.96-.487l.907-1.782c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051v2Zm-5.073-3.638a3 3 0 0 0 1.311 1.311l-.908 1.782A5 5 0 0 1-.455 17.27l1.782-.908ZM6.4 1c-1.137 0-1.929 0-2.546.051-.605.05-.953.142-1.216.276L1.73-.455c.592-.302 1.233-.428 1.961-.487C4.41-1.001 5.296-1 6.4-1v2ZM-1 6.4c0-1.104 0-1.991.058-2.709.06-.728.185-1.369.487-1.96l1.782.907c-.134.263-.226.611-.276 1.216C1.001 4.471 1 5.264 1 6.4h-2Zm3.638-5.073a3 3 0 0 0-1.311 1.311L-.455 1.73A5 5 0 0 1 1.73-.455l.908 1.782Z"
      fill="currentColor"
      mask="url(#a)"
    />
    <path
      d="M4.4 9.35h6.16M4.4 3.35h6.16M4.4 6.35h6.16M4.4 15.35h6.16M4.4 12.35h6.16"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export default ExperimentStatus;
