import { CSSProperties, FC, MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames/bind';

import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { getEntitiesData } from '@/hooks/useExperimentContext/helpers';
import {
  defineGateDeletionConfirmationMessage,
  findParentGate,
  isGateDimensionsMatch,
  isGateForCurrentChartType,
} from '@/helpers/gates';
import type { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import Popover from '@/components/common/Popover';
import UpdateGateNameModal from '@/components/common/UpdateGateNameModal';
import icons from '@/components/common/icons';
import NoDataFound from '@/components/common/NoDataFound';

import {
  autoUpdate,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  safePolygon,
  useDismiss,
  useTransitionStyles,
  offset,
  autoPlacement,
} from '@floating-ui/react';
import { highlightActiveGate } from '@/hooks/gates/helpers/common';
import { isPolarAreaTypeGate, isSplitGatePolarSectorType } from '@/helpers/typeGuards';
import { getOption } from '@/components/common/Select/helpers';
import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import { formatNumber } from '@/helpers';

import Button from '@/components/common/Button';
import UpdateGateChangeColorModal from '@/components/common/UpdateGateChangeColorModal';
import ColorButton from '@/components/common/ColorButton';
import { useHideGate } from '@/hooks/gates/useHideGate';
import { useGatesShapeOverrides } from '@/hooks/gates/useGatesShapeOverrides';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { EPageWithChartType, EChartType } from '@/types/charts';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { EAxesGroupName } from '@/store/slices/scatterplots/types';

import gatesAccordionStyles from './GatesAccordion.module.scss';

const cn = classnames.bind(gatesAccordionStyles);

const plotTypeByGate: Record<TGatePropertyType, EChartType> = {
  circle: EChartType.dotDensity,
  polygon: EChartType.dotDensity,
  rectangle: EChartType.dotDensity,
  polar: EChartType.dotDensity,
  range: EChartType.lineHistogram,
  'split-gate': EChartType.lineHistogram,
};

type TGateItemProps = {
  gate: TGate;
  defaultActive?: boolean;
  headingClassName?: string;
  childrenClassName?: string;
  scatterPlotAxesOptions?: Nullable<TOption[]>;
  currentAppLane: TGeneralChartProps['currentAppLane'];
  entityLevelGateList: TGeneralChartProps['entityLevelGateList'];
  entitiesByLanesAndGates: TGeneralChartProps['entitiesByLanesAndGates'];
  deleteGate: TUseUpdateGateCallbacksType['deleteGate'];
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
  deleteGateChildren: TUseUpdateGateCallbacksType['deleteGateChildren'];
  isUpdateGatesInProgress: TGeneralChartProps['isUpdateGatesInProgress'];
  pageType: EPageWithChartType;
  specificAxesGroupName?: EAxesGroupName;
};

const GateItem: FC<TGateItemProps> = ({
  gate,
  defaultActive = false,
  headingClassName,
  childrenClassName,
  scatterPlotAxesOptions = [],
  currentAppLane,
  entityLevelGateList,
  entitiesByLanesAndGates,
  deleteGate = () => null,
  deleteGateChildren = () => null,
  updateGate,
  isUpdateGatesInProgress,
  pageType,
  specificAxesGroupName,
}) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const gateCagesData = useMemo(
    () => getEntitiesData(entitiesByLanesAndGates, currentAppLane?.path, gate),
    [entitiesByLanesAndGates, currentAppLane, gate]
  );

  const isUngatedSelected = useSelector(gatesSelectors.selectIsUngatedSelected);
  const isGatesDrawn = useSelector(gatesSelectors.selectIsGatesDrawn);
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const activeGate = useSelector(gatesSelectors.selectActiveGate);

  const xAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const onlyGateToDisplay = useSelector(gatesSelectors.selectOnlyGateToDisplay);

  const [isOpened, setIsOpened] = useState(defaultActive);
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [isGateUpdateModalOpened, setIsGateUpdateModalOpened] = useState(false);
  const [isGateChangeColorModalOpened, setIsGateChangeColorModalOpened] = useState(false);

  const nodeRef = useRef<Nullable<HTMLDivElement>>(null);
  const gateNameRef = useRef<Nullable<HTMLDivElement>>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isTooltipOpened,
    onOpenChange: setIsTooltipOpened,
    middleware: [
      offset({ mainAxis: 10, crossAxis: -10 }),
      autoPlacement({ alignment: 'start', allowedPlacements: ['top-start', 'bottom-start'] }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const tooltipRole = useRole(context, { role: 'tooltip' });
  const handleTooltipDismiss = useDismiss(context, {
    ancestorScroll: true,
  });
  const handleTooltipHover = useHover(context, { move: false, handleClose: safePolygon() });
  const { isMounted, styles } = useTransitionStyles(context, {
    duration: {
      open: 300,
      close: 200,
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    handleTooltipHover,
    handleTooltipDismiss,
    tooltipRole,
  ]);

  const confirmationModal = useConfirmationModalContext();

  const { isSettingsChangingConfirmed } = useSettingsNotification();

  const isDimensionsSwitchDisabled = useMemo(() => {
    if (!scatterPlotAxesOptions) return false;
    const isXDimensionExists = !!getOption(scatterPlotAxesOptions, gate.xDimension);
    const isYDimensionExists = !!getOption(scatterPlotAxesOptions, gate.yDimension);

    const gateTypeByProperty = gate.properties.type;

    if (gateTypeByProperty && ['range', 'split-gate'].includes(gateTypeByProperty)) {
      return !isXDimensionExists;
    }

    return !isXDimensionExists || !isYDimensionExists;
  }, [scatterPlotAxesOptions]);

  const isSelectedGate = useMemo(
    () => (isUngatedSelected && isGatesDrawn && gate?.id === '') || selectedGate?.id === gate?.id,
    [isUngatedSelected, isGatesDrawn, gate, selectedGate]
  );

  const { isGateVisible, gateColor, toggleGateVisibility } = useHideGate({
    gate,
    gateList: entityLevelGateList,
    updateGate,
  });

  const isGatesShouldBeVisible = useMemo(() => {
    const isPolarGateNodes = isPolarAreaTypeGate(gate.shape) || isSplitGatePolarSectorType(gate.shape);
    let gateData: Nullable<TGate> = gate;

    if (isPolarGateNodes) {
      gateData = findParentGate(entityLevelGateList, gate.parentId);
    }

    if (onlyGateToDisplay && gateData) {
      return onlyGateToDisplay.id === gateData.id && isGateVisible;
    }
    return isGateVisible;
  }, [onlyGateToDisplay, gate, isGateVisible]);

  const isAllowedGatesControls = useMemo(() => gate && gate.id !== '', [gate]);

  const isShownApplyDimensionsButton = useMemo(
    () => isAllowedGatesControls && !isGateDimensionsMatch(gate, xAxis, yAxis),
    [isAllowedGatesControls, gate, xAxis, yAxis, chartId]
  );

  const isDrawnOnScatterPlotGate = useMemo(() => {
    const isPolarGateNodes = isPolarAreaTypeGate(gate.shape) || isSplitGatePolarSectorType(gate.shape);
    let gateData: Nullable<TGate> = gate;
    const gateTypeByProperty = gate?.properties?.type;

    if (isPolarGateNodes) {
      gateData = findParentGate(entityLevelGateList, gate.parentId);
    }

    if (!gateData || !gateTypeByProperty) return false;

    const isGateForCurrentPlot = isGateForCurrentChartType(gateTypeByProperty, currentChartType);

    if (selectedGate) {
      return (
        selectedGate?.id === gateData.parentId && isGateDimensionsMatch(gateData, xAxis, yAxis) && isGateForCurrentPlot
      );
    }

    return isGatesDrawn && !gateData.parentId && isGateDimensionsMatch(gateData, xAxis, yAxis) && isGateForCurrentPlot;
  }, [isGatesDrawn, gate, selectedGate, xAxis, yAxis, currentChartType]);

  const applyGateDimensions = (event?: MouseEvent) => {
    event?.stopPropagation();
    appDispatch(
      scatterplotsActions.setAxes({
        newAxes: { x: gate.xDimension, y: gate.yDimension },
        axesGroupName: specificAxesGroupName,
        withoutUpdatingGroupName: !!specificAxesGroupName,
      })
    );
    appDispatch(gatesActions.setActiveGate(null));
  };

  const handleToggleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsOpened((prev) => !prev);
  };

  const handleHeadingClick = useCallback(() => {
    if (gate.id === '') {
      appDispatch(gatesActions.toggleIsUngatedSelected());
    } else {
      appDispatch(gatesActions.setSelectedGate(gate));
      appDispatch(gatesActions.setActiveGate(null));
    }
  }, [gate]);

  const handleDeleteGate = () => {
    const idToDelete = isPolarAreaTypeGate(gate.shape) ? gate.parentId : gate.id;
    deleteGate(idToDelete);

    if (isSelectedGate) {
      appDispatch(gatesActions.setSelectedGate(null));
    }

    if (activeGate && activeGate.id === idToDelete) {
      appDispatch(gatesActions.setActiveGate(null));
    }
  };

  const openDeleteGateModal = async () => {
    const result = await confirmationModal.onOpen({
      confirmationText: defineGateDeletionConfirmationMessage(gate, entityLevelGateList),
      approveButtonText: 'Delete',
    });

    if (result) {
      handleDeleteGate();
    }
  };

  const handleDeleteChildren = () => {
    deleteGateChildren(gate.id);
  };

  const handleShowOnlyOneGate = () => {
    if (!isGateDimensionsMatch(gate, xAxis, yAxis)) {
      appDispatch(
        scatterplotsActions.setAxes({
          newAxes: { x: gate.xDimension, y: gate.yDimension },
          axesGroupName: specificAxesGroupName,
          withoutUpdatingGroupName: !!specificAxesGroupName,
        })
      );
    }

    const isPolarGateNodes = isPolarAreaTypeGate(gate.shape) || isSplitGatePolarSectorType(gate.shape);
    const parentGate = gate.parentId ? findParentGate(entityLevelGateList, gate.parentId) : null;
    let gateData: Nullable<TGate> = gate;

    if (isPolarGateNodes) {
      gateData = parentGate;
    } else {
      appDispatch(gatesActions.setSelectedGate(parentGate));
    }

    if (!gateData) return;

    appDispatch(gatesActions.setOnlyGateToDisplay(gateData));
  };

  const handleShowAllGates = (event: MouseEvent) => {
    event.stopPropagation();
    appDispatch(gatesActions.setOnlyGateToDisplay(null));
  };

  const openDeleteGateChildrenModal = async () => {
    const result = await confirmationModal.onOpen({
      confirmationText: `Are you sure you want to delete children gates of gate ${gate.name}?`,
      approveButtonText: 'Delete',
    });

    if (result) {
      handleDeleteChildren();
    }
  };

  const closeGateUpdateModal = () => {
    setIsGateUpdateModalOpened(false);
  };

  const closeGateChangeColorModal = () => {
    setIsGateChangeColorModalOpened(false);
  };

  const handleShowGateOnParentClick = async () => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    if (!entityLevelGateList.length) return;

    if (!isGateDimensionsMatch(gate, xAxis, yAxis)) {
      appDispatch(
        scatterplotsActions.setAxes({
          newAxes: { x: gate.xDimension, y: gate.yDimension },
          axesGroupName: specificAxesGroupName,
          withoutUpdatingGroupName: !!specificAxesGroupName,
        })
      );
    }

    const isPolarSector = isPolarAreaTypeGate(gate.shape);
    const polarSectorParent = isPolarSector ? findParentGate(entityLevelGateList, gate.parentId) : null;
    const parentId = isPolarSector && polarSectorParent ? polarSectorParent.parentId : gate.parentId;
    const parentGate = findParentGate(entityLevelGateList, parentId);

    if (parentGate) {
      appDispatch(gatesActions.setSelectedGate({ ...parentGate }));
    } else {
      appDispatch(gatesActions.setSelectedGate(null));
    }

    const gateToSelect = isPolarSector && polarSectorParent ? polarSectorParent : gate;

    appDispatch(gatesActions.setIsGateDrawn(true));
    appDispatch(gatesActions.setActiveGate(gateToSelect));
    highlightActiveGate(gateToSelect.id);

    if (
      [EPageWithChartType.multiHistogram, EPageWithChartType.heatmap, EPageWithChartType.preprocessing].includes(
        pageType
      )
    )
      return;

    const gateTypeByTool = gateToSelect.properties?.type;
    const plotTypeBySelectedGate =
      gateToSelect.properties?.plotType ?? plotTypeByGate?.[gateTypeByTool as TGatePropertyType];

    if (plotTypeBySelectedGate in EChartType) {
      appDispatch(chartSettingsActions.setCurrentChartType(plotTypeBySelectedGate));
    }
  };

  const openEditGateModal = () => {
    setIsGateUpdateModalOpened(true);
  };

  const openChangeGateColorModal = () => {
    setIsGateChangeColorModalOpened(true);
  };

  const handleOnEyeIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    toggleGateVisibility();
  };

  const {
    applyGateTo,
    resetGateToDefault,
    isApplyGateGloballyHidden,
    isResetToGlobalGateHidden,
    isApplyGateToSelectedHidden,
  } = useGatesShapeOverrides({ updateGate, currentAppLane, gate, pageType });

  const options = [
    {
      id: 'show-on-parent',
      title: 'Show gate on parent',
      icon: <icons.GateOnParentIcon />,
      onClick: handleShowGateOnParentClick,
      hidden: isDimensionsSwitchDisabled,
    },
    {
      id: 'show-only-this-gate',
      title: 'Show only this gate',
      icon: <icons.VisibilityOnIcon />,
      onClick: handleShowOnlyOneGate,
      hidden: onlyGateToDisplay?.id === gate.id,
    },
    {
      id: 'rename-gate',
      title: 'Rename gate',
      icon: <icons.PencilIcon />,
      onClick: openEditGateModal,
      hidden: gate.shape.type === 'polar',
    },
    {
      id: 'change-gate-color',
      title: 'Change gate color',
      icon: <icons.PencilIcon />,
      onClick: openChangeGateColorModal,
    },
    {
      id: 'hide-gate',
      title: isGateVisible ? 'Hide gate' : 'Show gate',
      icon: isGateVisible ? <icons.VisibilityOffIcon /> : <icons.VisibilityOnIcon />,
      onClick: toggleGateVisibility,
    },
    {
      id: 'delete-gate',
      title: 'Delete gate',
      icon: <icons.DeleteIcon />,
      onClick: () => {
        openDeleteGateModal();
      },
    },
    {
      id: 'delete-sub-gates',
      title: 'Delete children',
      icon: <icons.DeleteChildrensIcon />,
      onClick: () => {
        openDeleteGateChildrenModal();
      },
      hidden: !gate.gateNodes.length || gate.shape.type === 'polar',
    },
    {
      id: 'apply-gate-globally',
      title: 'Apply gate globally',
      icon: <icons.ApplyGateToIcon />,
      onClick: () => {
        applyGateTo();
      },
      hidden: isApplyGateGloballyHidden,
    },
    {
      id: 'apply-gate-to-selected',
      title: 'Apply gate to selected datasets in Matrix view',
      icon: <icons.ApplyGateToIcon />,
      onClick: () => {
        applyGateTo(true);
      },
      hidden: isApplyGateToSelectedHidden,
    },
    {
      id: 'reset-gate-to-default',
      title: 'Reset gate to default',
      icon: <icons.ResetGateToGlobalIcon />,
      onClick: () => {
        resetGateToDefault();
      },
      hidden: isResetToGlobalGateHidden,
    },
  ];
  const handlePopoverClick = () => {
    setIsPopoverOpened((prevState) => !prevState);
  };

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsPopoverOpened((prevState) => !prevState);
  };

  const handlePopoverOpen = () =>
    isTooltipOpened && isMounted && gateNameRef.current?.clientWidth !== gateNameRef.current?.scrollWidth;

  const formatGateChildren = () => {
    if (!gate.gateNodes?.length) {
      return null;
    }

    return gate.gateNodes.map((gateChild: TGate) => (
      <li key={gateChild.id} className={cn('gates-accordion__child')}>
        <GateItem
          deleteGate={deleteGate}
          deleteGateChildren={deleteGateChildren}
          isUpdateGatesInProgress={isUpdateGatesInProgress}
          updateGate={updateGate}
          entityLevelGateList={entityLevelGateList}
          currentAppLane={currentAppLane}
          entitiesByLanesAndGates={entitiesByLanesAndGates}
          gate={gateChild}
          defaultActive={defaultActive}
          scatterPlotAxesOptions={scatterPlotAxesOptions}
          pageType={pageType}
          specificAxesGroupName={specificAxesGroupName}
        />
      </li>
    ));
  };

  return (
    <>
      <UpdateGateChangeColorModal
        isModalOpen={isGateChangeColorModalOpened}
        closeModal={closeGateChangeColorModal}
        updateGate={updateGate}
        gate={gate}
        entityLevelGateList={entityLevelGateList}
      />
      <UpdateGateNameModal
        updateGate={updateGate}
        isUpdateGatesInProgress={isUpdateGatesInProgress}
        isModalOpen={isGateUpdateModalOpened}
        closeModal={closeGateUpdateModal}
        gate={gate}
      />
      {gate?.shape?.type === 'polar' && gate?.gateNodes?.length ? (
        gate?.gateNodes?.length &&
        gate.gateNodes.map((guadrantAreaGate) => (
          <GateItem
            key={guadrantAreaGate.id}
            deleteGate={deleteGate}
            deleteGateChildren={deleteGateChildren}
            isUpdateGatesInProgress={isUpdateGatesInProgress}
            updateGate={updateGate}
            entityLevelGateList={entityLevelGateList}
            currentAppLane={currentAppLane}
            entitiesByLanesAndGates={entitiesByLanesAndGates}
            gate={guadrantAreaGate}
            defaultActive={defaultActive}
            scatterPlotAxesOptions={scatterPlotAxesOptions}
            pageType={pageType}
            specificAxesGroupName={specificAxesGroupName}
          />
        ))
      ) : (
        <div
          className={cn('gates-accordion')}
          style={
            {
              '--level': gate.level,
            } as CSSProperties
          }
          role="presentation"
          onContextMenu={handleContextMenu}
        >
          <div
            className={cn(
              'gates-accordion__heading',
              {
                'gates-accordion__heading_selected': isSelectedGate,
                'gates-accordion__heading_drawn': isDrawnOnScatterPlotGate,
                'gates-accordion__heading_hidden': !isGatesShouldBeVisible,
                'gates-accordion__heading_ungated': !gate.id,
              },
              headingClassName
            )}
            onClick={handleHeadingClick}
            role="presentation"
          >
            {gate?.gateNodes.length ? (
              <div role="presentation" onClick={handleToggleClick} className={cn('gates-accordion__icon-wrapper')}>
                <icons.ExpandMore
                  className={cn('gates-accordion__icon', { 'gates-accordion__icon_opened': isOpened })}
                />
              </div>
            ) : (
              ''
            )}
            <div
              className={cn('gates-accordion__container-wrapper', {
                'gates-accordion__container-wrapper_with-left-gap': !gate?.gateNodes.length,
              })}
            >
              <div ref={refs.setReference} {...getReferenceProps()}>
                <div
                  ref={gateNameRef}
                  className={cn('gates-accordion__gate-name', {
                    'gates-accordion__gate-name_highlighted': isDrawnOnScatterPlotGate || isSelectedGate,
                  })}
                >
                  {gate.name}
                  {!gate.name && <NoDataFound />}
                </div>
              </div>

              {handlePopoverOpen() && (
                <div
                  className={cn(
                    'gates-accordion__gate-name',
                    {
                      'gates-accordion__gate-name_highlighted': isDrawnOnScatterPlotGate,
                    },
                    'gates-accordion__gate-name_full-size'
                  )}
                  ref={refs.setFloating}
                  style={{ ...floatingStyles, ...styles }}
                  {...getFloatingProps()}
                >
                  {gate.name}
                </div>
              )}
            </div>
            <div className={cn('gates-accordion__elements')}>
              {isShownApplyDimensionsButton && (
                <Button
                  tooltip="Apply gate dimensions"
                  color="light"
                  disabled={isDimensionsSwitchDisabled}
                  className={cn('gates-accordion__button')}
                  onClick={applyGateDimensions}
                >
                  <icons.ShowAxisIcon />
                </Button>
              )}

              {!!gateColor && (
                <ColorButton
                  color={gateColor}
                  tooltip="Color of gate"
                  onClick={openChangeGateColorModal}
                  disabled={!isGatesShouldBeVisible}
                />
              )}
              {isAllowedGatesControls ? (
                <Button
                  tooltip={isGateVisible ? 'Hide gate' : 'Show gate'}
                  color="light"
                  className={cn('gates-accordion__button', { 'gates-accordion__visibility-icon': isGateVisible })}
                  onClick={handleOnEyeIconClick}
                >
                  {isGateVisible ? (
                    <icons.VisibilityOnIcon className={cn('gates-accordion__eye-icon')} />
                  ) : (
                    <icons.VisibilityOffIcon className={cn('gates-accordion__eye-icon')} />
                  )}
                </Button>
              ) : (
                onlyGateToDisplay && (
                  <Button
                    tooltip="Show all gates"
                    color="light"
                    className={cn('gates-accordion__button')}
                    onClick={handleShowAllGates}
                  >
                    <icons.VisibilityOnIcon className={cn('gates-accordion__eye-icon')} />
                  </Button>
                )
              )}

              <span
                className={cn(
                  'gates-accordion__text',
                  'gates-accordion__text_extra-space',
                  'gates-accordion__text_number-column'
                )}
              >
                {formatNumber(gateCagesData?.cagesCount)}
              </span>

              <span
                className={cn(
                  'gates-accordion__text',
                  'gates-accordion__text_extra-space',
                  'gates-accordion__text_percent-column'
                )}
              >
                {gateCagesData?.cagesPercent}
              </span>

              <div onClick={(e) => e.stopPropagation()} role="presentation">
                {gate.id && gateCagesData?.cagesPercent && (
                  <Popover
                    onClick={handlePopoverClick}
                    isOpen={isPopoverOpened}
                    options={options}
                    setIsOpen={setIsPopoverOpened}
                    contentClassName={cn('gates-accordion__popover')}
                    styles={{
                      top: '-56px',
                      left: '10px',
                      zIndex: '20',
                    }}
                  >
                    <div className={cn('gates-accordion__dot-mask')}>
                      <icons.DotIcon />
                    </div>
                  </Popover>
                )}
              </div>
            </div>
          </div>

          <CSSTransition
            in={isOpened}
            timeout={300}
            classNames={{
              enter: cn('gates-accordion__enter'),
              enterActive: cn('gates-accordion__enter_active'),
              exit: cn('gates-accordion__exit'),
              exitActive: cn('gates-accordion__exit_active'),
            }}
            nodeRef={nodeRef}
            unmountOnExit
          >
            <div ref={nodeRef} className={cn('gates-accordion__content')}>
              <ul className={cn('gates-accordion__children-list', childrenClassName)}>{formatGateChildren()}</ul>
            </div>
          </CSSTransition>
        </div>
      )}
    </>
  );
};

export default GateItem;
