import { ComponentType, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { isNumber } from '@/helpers';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { gatesActions } from '@/store/slices/gates';
import type {
  TDefaultPreprocessingShape,
  TGeneralBeadShape,
  TGeneralCellShape,
} from '@/store/slices/preprocessing/types';
import useCytokineAxes from '@/hooks/preprocessing/useCytokineAxes';

import type { TGateList, TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { scatterplotsActions } from '@/store/slices/scatterplots';

import PreprocessingDatasetChart from '../PreprocessingDatasetChart';

import mainStyles from '../../PreprocessingSteps.module.scss';
import styles from './SelectedDataset.module.scss';

import StepActions, { TAction, TActionGroup } from '../StepActions';

const cnMain = classnames.bind(mainStyles);
const cn = classnames.bind(styles);

export type TSelectedDataset = {
  className?: string;
  needResetRange?: boolean;
  isShown?: boolean;
  GatesComponent: ComponentType<TGateList>;
  handleRemoveSpecificDatasetShape: (datasetDetailsId: string) => void;
  handleAddSpecificDatasetShape: (shapeData: TDefaultPreprocessingShape[]) => void;
  handleCompleteShapeChanges: (shapeData: Record<string, TGeneralBeadShape | TGeneralCellShape>) => void;
  shapes: Record<string, TGeneralCellShape | TGeneralBeadShape>;
  handleUpdatedDataset: (datasetId: string, isUpdated: boolean) => void;
  handleSwapChannels?: () => void;
};

const SelectedDataset: FC<TSelectedDataset> = ({
  className,
  needResetRange,
  isShown,
  GatesComponent,
  handleRemoveSpecificDatasetShape,
  handleAddSpecificDatasetShape,
  handleCompleteShapeChanges,
  shapes,
  handleUpdatedDataset,
  handleSwapChannels,
}) => {
  const appDispatch = useAppDispatch();

  const datasetDetails = useSelector(preprocessingSelectors.selectCurrentDataset);

  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const applyForAllDatasets = useSelector(preprocessingSelectors.selectApplyForAllDatasets);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);

  const { getAxes } = useCytokineAxes();

  const [unsaved, setUnsaved] = useState<Record<string, boolean>>({});
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextIndex, setNextIndex] = useState<Nullable<number>>(null);
  const [prevIndex, setPrevIndex] = useState<Nullable<number>>(null);

  const generalBeadShapesCacheRef = useRef<Record<string, TGeneralCellShape | TGeneralBeadShape>>(shapes);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const noUnsaved = !Object.keys(unsaved).length;
    const possibleReset = Object.values(shapes).some((el) => el.specificDatasets[datasetDetails.id]);

    const actionGroup: TAction[] = [
      {
        label: 'Reset',
        colorBtn: 'white',
        onClick: () => {
          handleRemoveSpecificDatasetShape(datasetDetails.id);
          setUnsaved({});
        },
        disabled: !possibleReset,
      },
      {
        label: 'Save',
        onClick: () => {
          if (applyForAllDatasets && !unsaved.length) {
            const shapesData: TDefaultPreprocessingShape[] = [];
            Object.keys(unsaved).forEach((unsavedBeadId) => {
              const data = shapes[unsavedBeadId].specificDatasets[datasetDetails.id];
              if (!data) {
                return;
              }
              shapesData.push(data);
            });

            handleAddSpecificDatasetShape(shapesData);
          }
          handleUpdatedDataset(datasetDetails.id, true);
          appDispatch(gatesActions.setActiveGate(null));
          setUnsaved({});
        },
        disabled: noUnsaved,
      },
    ];

    res.push(actionGroup);

    const backGroup: TAction[] = [
      {
        label: 'Done',
        colorBtn: 'white',
        disabled: !!Object.keys(unsaved).length,
        onClick: () => {
          if (Object.keys(unsaved).length) {
            handleCompleteShapeChanges(generalBeadShapesCacheRef.current);
          }
          appDispatch(preprocessingActions.setCurrentDatasetIndex(null));
        },
      },
    ];
    res.push(backGroup);

    return res;
  }, [datasetDetails.id, unsaved, shapes, applyForAllDatasets]);

  const updateGate: TGeneralChartProps['updateGate'] = useCallback(
    (data) => {
      const { gateId, updatedGateList } = data;
      const updatedGate = updatedGateList.find((el) => el.id === gateId);

      if (!updatedGate) {
        return;
      }

      setUnsaved((prev) => ({ ...prev, [gateId]: true }));

      const { gateNodes, level, parentId, shape, properties, id, name, xDimension, yDimension } = updatedGate;
      const shapeData = {
        shapeId: gateId,
        datasetId: datasetDetails.id,
        data: {
          gateNodes,
          level,
          parentId,
          shape,
          properties,
          id,
          name,
          xDimension,
          yDimension,
        },
      };
      appDispatch(preprocessingActions.addSpecificDatasetForCellShape(shapeData));
    },
    [appDispatch, preprocessingActions, datasetDetails]
  );

  const handleNextClick = useCallback(() => {
    appDispatch(preprocessingActions.setCurrentDatasetIndex(nextIndex));
  }, [nextIndex]);

  const handlePrevClick = useCallback(() => {
    appDispatch(preprocessingActions.setCurrentDatasetIndex(prevIndex));
  }, [prevIndex]);

  useEffect(() => {
    appDispatch(gatesActions.setActiveGate(null));
    generalBeadShapesCacheRef.current = shapes;
    if (isShown) {
      const { xAxis, yAxis } = getAxes(datasetDetails.dataset.name);
      appDispatch(
        scatterplotsActions.setAxes({
          newAxes: { x: xAxis, y: yAxis },
        })
      );
    }
  }, [isShown, datasetDetails, currentDatasetIndex]);

  // TODO: add a check for the presence of channels in the dataset when the component will be used for cytokine preprocessing
  // calculate next dataset and disable next button if needed
  useEffect(() => {
    if (Object.keys(unsaved).length) {
      setNextDisabled(true);
      return;
    }

    if (!isNumber(currentDatasetIndex)) {
      return;
    }

    const nextDatasetIndex = datasets.findIndex((_, index) => index > (currentDatasetIndex ?? 0));

    const isNextDisabled = nextDatasetIndex < 0;
    setNextDisabled(isNextDisabled);
    setNextIndex(nextDatasetIndex);
  }, [currentDatasetIndex, unsaved]);

  // calculate previous dataset and disable previous button if needed
  useEffect(() => {
    if (Object.keys(unsaved).length) {
      setPrevDisabled(true);
      return;
    }
    if (!isNumber(currentDatasetIndex)) {
      return;
    }

    const prevDatasetIndex = datasets.findLastIndex((_, index) => index < (currentDatasetIndex ?? 0));

    const isPrevDisabled = prevDatasetIndex < 0;
    setPrevDisabled(isPrevDisabled);
    setPrevIndex(prevDatasetIndex);
  }, [currentDatasetIndex, unsaved]);

  return (
    <div className={cn('selected-dataset', className)}>
      <div className={cn('selected-dataset__header', className)}>
        <span className={cn('dataset-name')}>{datasetDetails.friendlyName ?? datasetDetails.name}</span>
        <div className={cn('actions')}>
          <Button
            className={cn('action', 'action__previous')}
            color="white"
            tooltip="Previous"
            onClick={handlePrevClick}
            disabled={prevDisabled}
          >
            <icons.ArrowBackIcon height="10" viewBox="0 0 10 12" />
            <span>Previous</span>
          </Button>
          <Button
            className={cn('action', 'action__next')}
            tooltip="Next dataset"
            onClick={handleNextClick}
            disabled={nextDisabled}
          >
            <span>Next dataset</span>
            <icons.ArrowBackIcon className={cn('next-icon')} height="10" viewBox="0 0 10 12" />
          </Button>
        </div>
      </div>
      <div className={cnMain('preprocessing-step')}>
        <PreprocessingDatasetChart
          createGate={() => null}
          updateGate={updateGate}
          deleteGate={() => null}
          deleteGateChildren={() => null}
          onlyShowAxisValue
          needResetRange={needResetRange}
          datasetDetails={datasetDetails}
          GatesComponent={GatesComponent}
          handleSwapChannels={handleSwapChannels}
        />
      </div>
      <StepActions actionGroups={actionGroups} />
    </div>
  );
};

export default memo(SelectedDataset);
