import { FC, MutableRefObject, useCallback, MouseEvent, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import NoDataFound from '@/components/common/NoDataFound';
import RunDesignCard from '@/components/runDesign/RunDesignCard';
import { useGlobalOpticalSettings } from '@/hooks/runDesign/useGlobalOpticalSettings';
import { useMatrixOpticalSettingsContext } from '@/hooks/runDesign/useMatrixOpticalSettingsContext';
import { parseLanesMatrixSettings } from '@/store/slices/experimentRunDesign/helpers';

import styles from './SettingsCard.module.scss';
import MatrixView from '../MatrixView';
import GlobalView from '../GlobalView';
import { RunDesignContext } from '../../../context';

const cn = classnames.bind(styles);

type TSettingsCard = {
  innerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

const SettingsCard: FC<TSettingsCard> = ({ innerRef }) => {
  const appDispatch = useAppDispatch();
  const currentLanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const isGlobalView = useSelector(experimentRunDesignSelectors.selectIsGlobalSettingsView);
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);

  const { opticalSettings, setOpticalSettings, prevGlobalSettingsRef } = useGlobalOpticalSettings();
  const { lanesMatrixSettings, setLanesMatrixSettings } = useMatrixOpticalSettingsContext();

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  useEffect(() => {
    if (!overrideCustomFooterConfig || !isEditMode) {
      return;
    }

    overrideCustomFooterConfig({
      saveAndContinue: {
        clickHandler: () => {
          // save updated global settings
          appDispatch(experimentRunDesignActions.updateGlobalOpticalSettings(opticalSettings));
          // parse and save updated matrix settings
          const parsedMatrixSettings = parseLanesMatrixSettings(lanesMatrixSettings);
          appDispatch(experimentRunDesignActions.updateMatrixOpticalSettings(parsedMatrixSettings));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
      cancel: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));

          if (prevGlobalSettingsRef.current?.length) {
            setOpticalSettings(prevGlobalSettingsRef.current);
          }

          setLanesMatrixSettings(opticalMatrixSettings.filter((el) => el.scanConfig));
        },
      },
    });
  }, [overrideCustomFooterConfig, opticalSettings, isEditMode, lanesMatrixSettings, opticalMatrixSettings]);

  if (!currentLanes?.length || currentLanes.some((lane) => !lane.cellTypes)) {
    return <NoDataFound size="big" textData="There are no optic settings" className="center-block" />;
  }

  return (
    <RunDesignCard cardRef={innerRef} withoutHeader>
      <RunDesignCard.Content
        className={cn('content', { content_matrix: !isGlobalView })}
        onClick={handleCardContentClick}
      >
        {isGlobalView && (
          <GlobalView
            isEditMode={isEditMode}
            opticalSettings={opticalSettings}
            setOpticalSettings={setOpticalSettings}
            lanesMatrixSettings={lanesMatrixSettings}
            setLanesMatrixSettings={setLanesMatrixSettings}
          />
        )}

        {!isGlobalView && (
          <MatrixView lanesMatrixSettings={lanesMatrixSettings} setLanesMatrixSettings={setLanesMatrixSettings} />
        )}
      </RunDesignCard.Content>
    </RunDesignCard>
  );
};

export default SettingsCard;
