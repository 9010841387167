import { SubCompIncubation, TemperatureUnit } from '@/graphql/API';

const getInitialIncubation = (): SubCompIncubation => ({
  __typename: 'SubCompIncubation',
  duration: 12 * 60 * 60,
  temperature: 38,
  temperatureUnit: TemperatureUnit.C,
  onDevice: true,
});

export default getInitialIncubation;
