import { FC } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';
import Range from '@/components/common/Range';
import ContextPopover from '@/components/common/ContextPopover';

import styles from './AxesSettingsPopover.module.scss';

const cn = classnames.bind(styles);

type TAxesSettingsPopover = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  axesMinValue: number;
  axesMaxValue: number;
  maxLimit: number;
  minLimit: number;
  onRangeChange: (value: number | number[], axesName: 'x' | 'y') => void;
  axisName: 'x' | 'y';
  isControlBntHidden?: boolean;
  handleBtnClick: () => void;
  isLoading?: boolean;
};

const AxesSettingsPopover: FC<TAxesSettingsPopover> = ({
  isOpen,
  onClose = () => null,
  className = '',
  axesMinValue,
  axesMaxValue,
  onRangeChange,
  maxLimit,
  minLimit,
  axisName,
  isControlBntHidden = false,
  handleBtnClick,
  isLoading = false,
}) => {
  const handleRangeChange = (newRangeValue: number | number[]) => {
    onRangeChange(newRangeValue, axisName);
  };

  const popoverTitle = `${axisName.toLocaleUpperCase()}-axis scale (qty)`;

  return (
    <ContextPopover title={popoverTitle} isOpen={isOpen} onClose={onClose} className={cn('popover', className)}>
      <div className={cn('range-container')}>
        <Range
          type="range"
          value={[axesMinValue, axesMaxValue]}
          onChange={handleRangeChange}
          min={minLimit}
          max={maxLimit}
          className={cn('range')}
          infoBlockClassName={cn('range-info')}
          inputClassName={cn('range__input')}
          rangeHeight={4}
        />
      </div>
      {!isControlBntHidden && (
        <Button
          id="new-range__save"
          onClick={handleBtnClick}
          color="yellow"
          className={cn('button')}
          isLoading={isLoading}
        >
          Save
        </Button>
      )}
    </ContextPopover>
  );
};

export default AxesSettingsPopover;
