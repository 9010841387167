import { FC, useState, ChangeEvent, useEffect, useRef } from 'react';
import classnames from 'classnames/bind';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextArea from '@/components/common/Textarea';
import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import type { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';

import styles from './UpdateGateNameModal.module.scss';

const cn = classnames.bind(styles);

export type TUpdateGateNameModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  gate: TGate;
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
  isUpdateGatesInProgress: TGeneralChartProps['isUpdateGatesInProgress'];
};

const UpdateGateNameModal: FC<TUpdateGateNameModalProps> = ({
  isModalOpen,
  closeModal,
  gate,
  updateGate = () => null,
  isUpdateGatesInProgress,
}) => {
  const [newGateName, setNewGateName] = useState('');
  const [previousGateName, setPreviousGateName] = useState('');

  const textareaRef = useRef<Nullable<HTMLTextAreaElement>>(null);

  const onChangeGateName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNewGateName(e.target.value);
  };

  const handleSaveClick = () => {
    setPreviousGateName(gate.name);
    closeModal();
    updateGate(gate, { name: newGateName.trim() });
  };

  useEffect(() => {
    if (isModalOpen) {
      setNewGateName(gate.name);
      setPreviousGateName(gate.name);
    } else {
      setNewGateName('');
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      onFormSubmit={handleSaveClick}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>Rename gate</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__text-wrapper')}>
          Please, add a new name for gate <b className={cn('modal__gate-name')}>{previousGateName}</b>
        </div>
        <div className={cn('modal__row_name')}>
          <TextArea
            value={newGateName}
            onChange={onChangeGateName}
            id="new-population__name-input"
            ref={textareaRef}
            isAutoFocus
            isEnterEnabled={false}
            maxLength={256}
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button id="modal-btn__cancel" onClick={closeModal} color="white" className={cn('modal__button')}>
          Cancel
        </Button>
        <Button
          type="submit"
          id="modal__confirm"
          color="yellow"
          className={cn('modal__button')}
          disabled={!newGateName.trim()}
          isLoading={isUpdateGatesInProgress}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateGateNameModal;
