import { Dispatch, FC, RefObject, SetStateAction, useContext, useEffect } from 'react';
import { PagePanelContext } from '@/components/common/PagePanel/context';

type TPageContextProviderProps = {
  setScrollRefState: Dispatch<SetStateAction<Nullable<RefObject<HTMLDivElement>>>>;
};

/* component provides context value upwards to parent of context setter */
const PageContextProvider: FC<TPageContextProviderProps> = ({ setScrollRefState }) => {
  const scrollRef = useContext(PagePanelContext);

  useEffect(() => {
    setScrollRefState(scrollRef);
  }, [scrollRef]);
  return null;
};

export default PageContextProvider;
