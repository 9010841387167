import { FC, MutableRefObject, ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames/bind';

import styles from './ListWithActiveTab.module.scss';

const cn = classnames.bind(styles);

type TItem = {
  index: number;
  isOpen?: boolean;
  onChangeActive?: (index: number, some?: number) => void;
  children?: ReactNode;
  listRef: MutableRefObject<Nullable<HTMLDivElement>>;
};

const Item: FC<TItem> = ({ index, isOpen, onChangeActive, children, listRef }) => {
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (isOpen) {
        onChangeActive?.(index, itemRef.current?.offsetLeft);
      }
    });
    resizeObserver.observe(listRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isOpen, index, onChangeActive]);

  return (
    <div id={`item-${index}`} ref={itemRef} className={cn('item', { item_open: isOpen })}>
      <div className={cn('item__content')}>{children}</div>
    </div>
  );
};

export default Item;
