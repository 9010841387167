import { TAppEndpointBuilder, TFetchInstrumentsQuery } from '@/store/services/app/types';

export const instrumentsEndpoints = (build: TAppEndpointBuilder) => ({
  fetchInstruments: build.query<Array<TInstrumentFromServer>, TFetchInstrumentsQuery>({
    query: ({ operator, instrument }) => ({
      url: '/instruments',
      method: 'GET',
      params: {
        operator,
        instrumentId: instrument,
      },
    }),
  }),
});
