export const defaultColorList = [
  { value: '#e57373', label: 'Candy Pink' },
  { value: '#ba68c8', label: 'Rich Lilac' },
  { value: '#7986cb', label: 'Ube' },
  { value: '#64b5f6', label: 'Blue Jeans' },
  { value: '#4db6ac', label: 'Verdigris' },
  { value: '#81c784', label: 'Dark Sea Green' },
  { value: '#fff176', label: 'Sunny Yellow' },
  { value: '#ffb74d', label: 'Pastel Orange' },
  { value: '#ff0000', label: 'Red' },
  { value: '#8e24aa', label: 'Grape' },
  { value: '#0000ff', label: 'Blue' },
  { value: '#1e88e5', label: 'Bleu De France' },
  { value: '#00ff00', label: 'Green' },
  { value: '#43a047', label: 'May Green' },
  { value: '#fdd835', label: 'Banana Yellow' },
  { value: '#fb8c00', label: 'Dark Orange' },
  { value: '#000000', label: 'Black' },
  { value: '#222222', label: 'Raisin Black' },
  { value: '#444444', label: 'Outer Space' },
  { value: '#666666', label: 'Granite Gray' },
  { value: '#888888', label: 'Taupe Gray' },
  { value: '#aaaaaa', label: 'Dark Gray' },
  { value: '#cccccc', label: 'Chinese Silver' },
  { value: '#eeeeee', label: 'Bright Gray' },
];
