import { SVGProps } from 'react';

const InstrumentsDashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <rect x="0.75" y="-0.75" width="22.5" height="16" rx="1.5" transform="matrix(1 0 0 -1 0 16)" stroke="currentColor" strokeWidth="1.5"/>
  <rect x="0.75" y="-0.75" width="12.5" height="16" rx="1.5" transform="matrix(1 0 0 -1 0 16)" stroke="currentColor" strokeWidth="1.5"/>
  <path d="M13 8.5H1" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
  
);

export default InstrumentsDashboardIcon;
