import { Viewer as OsdViewer, Point as OsdPoint, MouseTracker, CanvasEvent, CanvasClickEvent } from 'openseadragon';

export class OsdCanvasOverlay {
  onRedraw: () => void;

  onClick: (event: CanvasClickEvent) => void;

  onMove: (event: CanvasEvent) => void;

  _viewer: OsdViewer;

  _containerWidth: number;

  _containerHeight: number;

  _canvas: HTMLCanvasElement;

  constructor(viewer: OsdViewer) {
    this._viewer = viewer;

    this._containerWidth = 0;
    this._containerHeight = 0;

    this._canvas = document.createElement('canvas');
    this._canvas.style.position = 'absolute';
    this._canvas.style.left = '0';
    this._canvas.style.top = '0';
    this._canvas.style.opacity = '1';
    this._viewer.canvas.appendChild(this._canvas);

    this.onRedraw = () => null;
    this.onClick = (_event: CanvasClickEvent) => null;
    this.onMove = (_event: CanvasEvent) => null;
    this._viewer.addHandler('canvas-click', (event) => {
      this.onClick(event);
    });
    new MouseTracker({
      element: this._viewer.canvas,
      moveHandler: (event) => {
        this.onMove(event as unknown as CanvasEvent);
      },
    }).setTracking(true);

    const updateHandler = () => {
      this.resize();
      this.updateCanvas();
    };

    this._viewer.addHandler('update-viewport', updateHandler);

    this._viewer.addHandler('open', updateHandler);
  }

  public canvas() {
    return this._canvas;
  }

  public context2d() {
    return this._canvas.getContext('2d');
  }

  public clear() {
    this.context2d()?.clearRect(0, 0, this._containerWidth, this._containerHeight);
  }

  public resize() {
    if (this._containerWidth !== this._viewer.container.clientWidth) {
      this._containerWidth = this._viewer.container.clientWidth;
      this._canvas.setAttribute('width', this._containerWidth.toString());
    }
    if (this._containerHeight !== this._viewer.container.clientHeight) {
      this._containerHeight = this._viewer.container.clientHeight;
      this._canvas.setAttribute('height', this._containerHeight.toString());
    }
  }

  public updateCanvas() {
    const ctx = this.context2d();
    if (!ctx) {
      return;
    }

    this.clear();

    const p = this._viewer.viewport.pixelFromPoint(new OsdPoint(0, 0), true);
    ctx.translate(p.x, p.y);
    const imageZoom = this._viewer.viewport.viewportToImageZoom(this._viewer.viewport.getZoom(true));
    ctx.scale(imageZoom, imageZoom);
    this.onRedraw();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  public setHandlers(
    redrawHandler: () => void,
    clickHandler: (event: CanvasClickEvent) => void,
    moveHandler: (event: CanvasEvent) => void
  ) {
    this.onRedraw = redrawHandler;
    this.onClick = clickHandler;
    this.onMove = moveHandler;
  }
}

export const getOsdCanvasOverlay = (viewer: OsdViewer) => {
  if (viewer._canvasOverlayInfo) {
    return viewer._canvasOverlayInfo;
  }
  viewer._canvasOverlayInfo = new OsdCanvasOverlay(viewer);
  return viewer._canvasOverlayInfo;
};
