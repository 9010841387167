import { SVGProps } from 'react';

const LaneChoosedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <circle cx="4" cy="4" r="4" />
      <circle cx="4" cy="14" r="4" />
      <circle cx="14" cy="4" r="4" />
      <circle cx="14" cy="14" r="4" />
    </g>
  </svg>
);

export default LaneChoosedIcon;
