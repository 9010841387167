import { FC, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useLinks } from '@/hooks/useLinks';
import { useDatasetsOptionsInit } from '@/hooks/datasetList/useDatasetsOptionsInit';
import { useSelectedDatasets } from '@/hooks/datasetList/useSelectedDatasets';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';

import Datasets from '@/pages/Experiment/components/Data/Datasets';

import styles from './ManageDatasetListModal.module.scss';

const cn = classnames.bind(styles);

export type TManageDatasetListModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ManageDatasetListModal: FC<TManageDatasetListModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const { generateAnalyticsLink } = useLinks();
  const { resetSelectedChannelDetails } = useDatasetsOptionsInit();
  const { selectedDetailsDataList } = useSelectedDatasets();

  const prevSelectedDetailsDataListRef = useRef<Nullable<TDatasetDetailsIdData[]>>(null);

  const isSelectedDatasetsChanged = useMemo(() => {
    const current = selectedDetailsDataList.map((detailsData) => JSON.stringify(detailsData));
    const prev = prevSelectedDetailsDataListRef.current?.map((detailsData) => JSON.stringify(detailsData)) ?? [];
    current.sort();
    prev.sort();
    return current.toString() !== prev.toString();
  }, [selectedDetailsDataList, prevSelectedDetailsDataListRef.current]);

  const isAnalyzeDataDisabled = useMemo(
    () => !isSelectedDatasetsChanged || selectedDetailsDataList.length < 2,
    [selectedDetailsDataList, isSelectedDatasetsChanged]
  );

  const cancelChanges = () => {
    resetSelectedChannelDetails();
    onClose();
  };

  const analyzeData = () => {
    prevSelectedDetailsDataListRef.current = selectedDetailsDataList;
    onClose();
    navigate(generateAnalyticsLink(selectedDetailsDataList));
  };

  useEffect(() => {
    if (isOpen && !prevSelectedDetailsDataListRef.current) {
      prevSelectedDetailsDataListRef.current = selectedDetailsDataList;
    }
  }, [isOpen, selectedDetailsDataList]);

  return (
    <Modal
      sidebar="right"
      onRequestClose={cancelChanges}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      className={cn('dataset-list-modal')}
      animationType="slide-animation"
    >
      <Modal.Header>
        <h2>Select datasets for the matrix view</h2>
      </Modal.Header>
      <Modal.Content className={cn('dataset-list-modal__content')}>
        <Datasets isMinimalView />
      </Modal.Content>
      <Modal.Footer className={cn('dataset-list-modal__footer')}>
        <Button color="white" onClick={cancelChanges} className={cn('dataset-list-modal__button')}>
          Cancel
        </Button>
        <Button
          color="yellow"
          className={cn('dataset-list-modal__button')}
          onClick={analyzeData}
          disabled={isAnalyzeDataDisabled}
        >
          Select datasets
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ManageDatasetListModal;
