import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import styles from './RadioButton.module.scss';

const cn = classnames.bind(styles);

type TRadioButtonProps = {
  isChecked: boolean;
  onChange?: () => void;
  className?: string;
  inputClassName?: string;
  children: string | ReactNode | ReactNode[];
};
const RadioButton: FC<TRadioButtonProps> = ({ isChecked, onChange, className, inputClassName, children }) => (
  <label className={cn('label', className)}>
    <div className={cn('radio', inputClassName)}>
      <input type="radio" className={cn('visually-hidden', 'input')} checked={isChecked} onChange={onChange} />
    </div>
    {children}
  </label>
);

export default RadioButton;
