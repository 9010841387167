import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { isNumber } from '@/helpers';
import { getDatasetChannelNameByChannelName } from '@/helpers/channels';
import useCytokineAxes from '@/hooks/preprocessing/useCytokineAxes';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { gatesActions } from '@/store/slices/gates';
import type { TGeneralBeadShape } from '@/store/slices/preprocessing/types';
import { scatterplotsActions } from '@/store/slices/scatterplots';

import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import PreprocessingDatasetChart from '../../components/PreprocessingDatasetChart';

import styles from './StepCytokineReviewBeads.module.scss';
import StepActions, { TAction, TActionGroup } from '../../components/StepActions';
import Gates from '../Gates';

const cn = classnames.bind(styles);
const emptyFn = () => null;

export type TSelectedDataset = {
  className?: string;
  needResetRange?: boolean;
  isShown?: boolean;
  handleUpdatedDataset?: (datasetId: string, isUpdated: boolean) => void;
};

const SelectedDataset: FC<TSelectedDataset> = ({
  className,
  needResetRange,
  isShown,
  handleUpdatedDataset = () => null,
}) => {
  const appDispatch = useAppDispatch();

  const datasetDetails = useSelector(preprocessingSelectors.selectCurrentDataset);
  const generalBeadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const applyForAllDatasets = useSelector(preprocessingSelectors.selectApplyForAllDatasets);
  const beadChannel = useSelector(preprocessingSelectors.selectBeadChannel);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);

  const [unsaved, setUnsaved] = useState<Record<string, boolean>>({});
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextIndex, setNextIndex] = useState<Nullable<number>>(null);
  const [prevIndex, setPrevIndex] = useState<Nullable<number>>(null);

  const generalBeadShapesCacheRef = useRef<Record<string, TGeneralBeadShape>>(generalBeadShapes);

  const { getAxes } = useCytokineAxes();

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const noUnsaved = !Object.keys(unsaved).length;
    const possibleReset = Object.values(generalBeadShapes).some((el) => el.specificDatasets[datasetDetails.id]);

    const actionGroup: TAction[] = [
      {
        label: 'Reset',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.removeSpecificDatasetShape(datasetDetails.id));
          setUnsaved({});
        },
        disabled: !possibleReset,
      },
      {
        label: 'Save',
        onClick: () => {
          if (applyForAllDatasets && !unsaved.length) {
            const shapesData: Omit<TGeneralBeadShape, 'name' | 'beadId' | 'specificDatasets' | 'marker'>[] = [];
            Object.keys(unsaved).forEach((unsavedBeadId) => {
              const data = generalBeadShapes[unsavedBeadId].specificDatasets[datasetDetails.id];
              if (!data) {
                return;
              }
              shapesData.push(data);
            });
            appDispatch(preprocessingActions.addSpecificDatasetForBeadShapeAllDatasets(shapesData));
          }
          handleUpdatedDataset(datasetDetails.id, true);
          appDispatch(gatesActions.setActiveGate(null));
          setUnsaved({});
        },
        disabled: noUnsaved,
      },
    ];

    res.push(actionGroup);
    const backGroup: TAction[] = [
      {
        label: 'Done',
        colorBtn: 'white',
        disabled: !!Object.keys(unsaved).length,
        onClick: () => {
          if (Object.keys(unsaved).length) {
            appDispatch(preprocessingActions.setGeneralBeadShapes(generalBeadShapesCacheRef.current));
          }
          appDispatch(preprocessingActions.setCurrentDatasetIndex(null));
        },
      },
    ];
    res.push(backGroup);

    return res;
  }, [datasetDetails.id, unsaved, generalBeadShapes, applyForAllDatasets]);

  const updateGate: TGeneralChartProps['updateGate'] = useCallback(
    (data) => {
      const { gateId, updatedGateList } = data;
      const updatedGate = updatedGateList.find((el) => el.id === gateId);

      if (!updatedGate) {
        return;
      }

      setUnsaved((prev) => ({ ...prev, [gateId]: true }));

      const { gateNodes, level, parentId, shape, properties, id, name, xDimension, yDimension } = updatedGate;
      appDispatch(
        preprocessingActions.addSpecificDatasetForBeadShape({
          shapeId: gateId,
          datasetId: datasetDetails.id,
          data: {
            gateNodes,
            level,
            parentId,
            shape,
            properties,
            id,
            name,
            xDimension,
            yDimension,
          },
        })
      );
    },
    [appDispatch, preprocessingActions, datasetDetails]
  );

  const handleNextClick = useCallback(() => {
    appDispatch(preprocessingActions.setCurrentDatasetIndex(nextIndex));
  }, [nextIndex]);

  const handlePrevClick = useCallback(() => {
    appDispatch(preprocessingActions.setCurrentDatasetIndex(prevIndex));
  }, [prevIndex]);

  useEffect(() => {
    appDispatch(gatesActions.setActiveGate(null));
    generalBeadShapesCacheRef.current = generalBeadShapes;
    if (isShown) {
      const { xAxis, yAxis } = getAxes(datasetDetails.dataset.name);
      appDispatch(
        scatterplotsActions.setAxes({
          newAxes: { x: xAxis, y: yAxis },
        })
      );
    }
  }, [isShown, datasetDetails, currentDatasetIndex]);

  // calculate next dataset and disable next button if needed
  useEffect(() => {
    if (Object.keys(unsaved).length) {
      setNextDisabled(true);
      return;
    }
    if (!isNumber(currentDatasetIndex)) {
      return;
    }

    const nextDatasetIndex = datasets.findIndex(
      (dataset, index) =>
        getDatasetChannelNameByChannelName(dataset.channelList, beadChannel) && index > (currentDatasetIndex ?? 0)
    );

    const isNextDisabled = nextDatasetIndex < 0;
    setNextDisabled(isNextDisabled);
    setNextIndex(nextDatasetIndex);
  }, [currentDatasetIndex, unsaved]);

  // calculate previous dataset and disable previous button if needed
  useEffect(() => {
    if (Object.keys(unsaved).length) {
      setPrevDisabled(true);
      return;
    }
    if (!isNumber(currentDatasetIndex)) {
      return;
    }

    const prevDatasetIndex = datasets.findLastIndex(
      (dataset, index) =>
        getDatasetChannelNameByChannelName(dataset.channelList, beadChannel) && index < currentDatasetIndex
    );

    const isPrevDisabled = prevDatasetIndex < 0;
    setPrevDisabled(isPrevDisabled);
    setPrevIndex(prevDatasetIndex);
  }, [currentDatasetIndex, unsaved]);

  return (
    <div className={cn('selected-dataset', className)}>
      <div className={cn('selected-dataset__header', className)}>
        <span className={cn('dataset-name')}>{datasetDetails.friendlyName ?? datasetDetails.name}</span>
        <div className={cn('actions')}>
          <Button
            className={cn('action', 'action__previous')}
            color="white"
            tooltip="Previous"
            onClick={handlePrevClick}
            disabled={prevDisabled}
          >
            <icons.ArrowBackIcon height="10" viewBox="0 0 10 12" />
            <span>Previous</span>
          </Button>
          <Button
            className={cn('action', 'action__next')}
            tooltip="Next dataset"
            onClick={handleNextClick}
            disabled={nextDisabled}
          >
            <span>Next dataset</span>
            <icons.ArrowBackIcon className={cn('next-icon')} height="10" viewBox="0 0 10 12" />
          </Button>
        </div>
      </div>
      <PreprocessingDatasetChart
        createGate={emptyFn}
        updateGate={updateGate}
        deleteGate={emptyFn}
        deleteGateChildren={emptyFn}
        onlyShowAxisValue
        needResetRange={needResetRange}
        datasetDetails={datasetDetails}
        GatesComponent={Gates}
      />
      <StepActions actionGroups={actionGroups} />
    </div>
  );
};

export default memo(SelectedDataset);
