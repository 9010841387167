export const timeFilterOptionList = [
  {
    label: 'All time',
    value: '',
  },
  {
    label: 'last 1 day',
    value: '0-0-1',
  },
  {
    label: 'last 1 week',
    value: '0-1-0',
  },
  {
    label: 'last 1 month',
    value: '1-0-0',
  },
  {
    label: 'last 2 months',
    value: '2-0-0',
  },
  {
    label: 'last 3 months',
    value: '3-0-0',
  },
  {
    label: 'last 4 months',
    value: '4-0-0',
  },
  {
    label: 'last 6 months',
    value: '5-0-0',
  },
];
