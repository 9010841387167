import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { ContentLocation } from 'react-tiny-popover';

import { SELECT_BY_CLICK_CLASS_NAME, SELECTED_CLASS_NAME } from '@/helpers/reactSelectable';

import { checkIfCursorOnEl, handleAllItemAction } from '../helpers';

type TUseContextMenu<T> = {
  tableRef: RefObject<HTMLDivElement>;
  tableData: T;
};

function useContextMenu<T>({ tableRef, tableData }: TUseContextMenu<T>) {
  const [isOpenedPopover, setIsOpenedPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState<ContentLocation>({ left: 0, top: 0 });
  const [cursorPosition, setCursorPosition] = useState<ContentLocation>({ left: 0, top: 0 });

  useEffect(() => {
    const handleContextMenu = (e: PointerEvent) => {
      if (e.button !== 2) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      setIsOpenedPopover(true);
      setPopoverPosition({ left: e.clientX - 150, top: e.clientY });
      setCursorPosition({ left: e.clientX, top: e.clientY });
      handleAllItemAction(tableRef.current, (el) => {
        el.classList.add(SELECT_BY_CLICK_CLASS_NAME);
        if (
          checkIfCursorOnEl(e.clientX, e.clientY, el.getBoundingClientRect()) &&
          !el.classList.contains(SELECTED_CLASS_NAME)
        ) {
          el.classList.add(SELECTED_CLASS_NAME);
        }
      });
    };

    handleAllItemAction(tableRef.current, (el) => {
      if ('oncontextmenu' in el) {
        el.oncontextmenu = handleContextMenu;
      }
      if ('onmousedown' in el) {
        el.onmousedown = handleContextMenu;
      }
    });

    return () => {
      handleAllItemAction(tableRef.current, (el) => {
        if ('oncontextmenu' in el && typeof el.oncontextmenu === 'function') {
          el.oncontextmenu = null;
        }
        if ('onmousedown' in el && typeof el.onmousedown === 'function') {
          el.onmousedown = null;
        }
      });
    };
  }, [tableData]);

  const closeContextMenu = useCallback((e?: MouseEvent) => {
    if (e && e.button !== 0) {
      return;
    }
    setIsOpenedPopover(false);
    handleAllItemAction(tableRef.current, (el) => {
      el.classList.remove(SELECT_BY_CLICK_CLASS_NAME);
    });
  }, []);

  return useMemo(
    () => ({
      popoverPosition,
      isOpenedPopover,
      closeContextMenu,
      cursorPosition,
    }),
    [popoverPosition, isOpenedPopover, cursorPosition, closeContextMenu]
  );
}

export default useContextMenu;
