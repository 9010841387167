import { FC, ReactNode } from 'react';
// Helpers
import classnames from 'classnames/bind';

// Components
import Nav from './Nav';
import Actions from './Actions';

// Styles
import styles from './Header.module.scss';

const cn = classnames.bind(styles);

type THeaderProps = {
  className?: string;
  leftSlot?: ReactNode;
};
const Header: FC<THeaderProps> = ({ className = '', leftSlot = <Nav /> }) => (
  <header className={cn('header', className)}>
    {leftSlot}
    <Actions />
  </header>
);

export default Header;
