import classnames from 'classnames/bind';
import packageJson from '~/package.json';
import { NavLink } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import ApiInfoModal from '@/components/Layout/Footer/ApiInfoModal/ApiInfoModal';
import icons from '@/components/common/icons';
import { intAPI } from '@/store/services/intsvc';
import InfoButton from '@/components/common/InfoButton';
import styles from './Footer.module.scss';

const cn = classnames.bind(styles);
const FOOTER_OFFSET = 20;

const Footer: FC = () => {
  const { data } = intAPI.useFetchApiInfoQuery();
  const feedbackFormPath = process.env.REACT_APP_FEEDBACK_FORM_LINK ?? '';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScrolledBottom, setIsScrolledBottom] = useState(true);

  const handleClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleMutation = (mutationsList: MutationRecord[]) => {
    mutationsList.forEach(() => {
      resetFooterPosition();
    });
  };

  const handleScroll = () => {
    resetFooterPosition();
  };

  const resetFooterPosition = () => {
    const totalPageHeight = document.body.scrollHeight;
    const scrollPoint = window.scrollY + window.innerHeight;

    setIsScrolledBottom(scrollPoint + FOOTER_OFFSET >= totalPageHeight);
  };

  useEffect(() => {
    /**
     * ResizeObserver can't be used as scrollbar-gutter: stable prevent resize shift
     */
    const observer = new MutationObserver(handleMutation);

    observer.observe(document.body, {
      childList: true, // Observe changes to the child nodes
      subtree: true, // Observe changes to descendants as well
    });
    document.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect();
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <footer
        id="footer"
        className={cn('footer', {
          footer_visible: isScrolledBottom,
        })}
      >
        <div className={cn('footer__open-trigger')}>
          <icons.ArrowsUpIcon className={cn('footer__open-trigger-image')} />
        </div>
        <div className={cn('footer__text')}>Cellanome {new Date().getFullYear()}. All Rights Reserved.</div>
        <div className={cn('footer-info')}>
          {feedbackFormPath && (
            <div>
              <NavLink to={feedbackFormPath} target="_blank" className={cn('footer__link')}>
                Report a bug or suggest a feature
              </NavLink>
            </div>
          )}
          <div>Build {packageJson.version} / API</div>
          <InfoButton handleClick={handleClick} tooltip="Service statuses" />
        </div>
      </footer>

      <ApiInfoModal isOpen={isModalOpen} onRequestClose={handleClick} apiInfoData={data} />
    </>
  );
};

export default Footer;
