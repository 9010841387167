import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import NumberInput from '@/components/common/NumberInput';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import styles from '../SettingsPopover.module.scss';

const cn = classnames.bind(styles);

const DotDensitySettings: FC = () => {
  const appDispatch = useAppDispatch();

  const { custom: customBandWidth, default: defaultBandWidth } = useSelector(
    scatterplotsSelectors.selectDensityBandWidth
  );
  const [bandWidth, setBandWidth] = useState(customBandWidth);

  const isApplyBandWidthDisabled = useMemo(
    () => bandWidth.x === customBandWidth.x && bandWidth.y === customBandWidth.y,
    [bandWidth, customBandWidth]
  );

  const isResetBandWidthDisabled = useMemo(
    () => customBandWidth.x === defaultBandWidth.x && customBandWidth.y === defaultBandWidth.y,
    [customBandWidth, defaultBandWidth]
  );

  const applyBandWidth = () => {
    appDispatch(
      scatterplotsActions.setDensityBandWidth({
        custom: bandWidth,
      })
    );
  };

  const resetBandWidth = () => {
    setBandWidth(defaultBandWidth);
    appDispatch(
      scatterplotsActions.setDensityBandWidth({
        custom: defaultBandWidth,
      })
    );
  };

  const handleCustomXBandWidthChange = (x: number) => {
    setBandWidth((prev) => ({ ...prev, x }));
  };

  const handleCustomYBandWidthChange = (y: number) => {
    setBandWidth((prev) => ({ ...prev, y }));
  };

  return (
    <div className={cn('section')}>
      <div className={cn('section__head')}>
        <span>Density Band width</span>
      </div>
      <div className={cn('section__inputs')}>
        <NumberInput
          label="X"
          value={bandWidth.x}
          onChange={handleCustomXBandWidthChange}
          className={cn('section__input')}
        />
        <NumberInput
          label="Y"
          value={bandWidth.y}
          onChange={handleCustomYBandWidthChange}
          className={cn('section__input')}
        />
      </div>
      <div className={cn('section__buttons')}>
        <Button
          color="yellow"
          isBgLight
          className={cn('section__button')}
          onClick={applyBandWidth}
          tooltip="Apply custom band width"
          disabled={isApplyBandWidthDisabled}
        >
          Apply
        </Button>
        <Button
          tooltip="Reset to default"
          color="gray"
          className={cn('section__button', 'section__button_small')}
          onClick={resetBandWidth}
          disabled={isResetBandWidthDisabled}
        >
          <icons.ReloadIcon />
        </Button>
      </div>
    </div>
  );
};

export default DotDensitySettings;
