import { Placement } from '@/graphql/API';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

const fixPlacement = (componentList: TRunDesignComponent[]) => {
  const { defaultFlowList, simultaneousFlowList } = Object.groupBy(componentList, (component) =>
    component.timing.placement === Placement.SIMULTANEOUS ? 'simultaneousFlowList' : 'defaultFlowList'
  );

  const fixedDefaultFlowList = defaultFlowList?.map((component) => {
    const placementIndex = defaultFlowList.findIndex(({ id }) => component.id === id);
    const isFirst = placementIndex === 0;
    const isLast = placementIndex === componentList.length - 1;

    if (placementIndex < 0) {
      throw new Error(`Provided id '${component.id}' not exist in component list`);
    }

    const placementCheckActions = {
      [Placement.START]: () => isFirst,
      [Placement.END]: () => isLast,
      [Placement.BEFORE]: () =>
        !isLast && defaultFlowList[placementIndex].timing.relativeTo === defaultFlowList[placementIndex + 1].id,
      [Placement.AFTER]: () =>
        !isFirst && defaultFlowList[placementIndex].timing.relativeTo === defaultFlowList[placementIndex - 1].id,
      [Placement.SIMULTANEOUS]: () => true,
    };

    if (placementCheckActions[component.timing.placement]()) {
      return component;
    }

    const placementFixActions = {
      [Placement.START]: () => {
        if (isLast) {
          component.timing.placement = Placement.END;
        } else {
          component.timing.placement = Placement.AFTER;
          component.timing.relativeTo = defaultFlowList[placementIndex - 1].id;
        }
      },
      [Placement.END]: () => {
        if (isFirst) {
          component.timing.placement = Placement.START;
        } else {
          component.timing.placement = Placement.AFTER;
          component.timing.relativeTo = defaultFlowList[placementIndex - 1].id;
        }
      },
      [Placement.BEFORE]: () => {
        if (isFirst) {
          component.timing.placement = Placement.START;
          component.timing.relativeTo = null;
        } else if (isLast) {
          component.timing.placement = Placement.END;
          component.timing.relativeTo = null;
        } else {
          component.timing.placement = Placement.AFTER;
          component.timing.relativeTo = defaultFlowList[placementIndex - 1].id;
        }
      },
      [Placement.AFTER]: () => {
        if (isFirst) {
          component.timing.placement = Placement.START;
          component.timing.relativeTo = null;
        } else if (isLast) {
          component.timing.placement = Placement.END;
          component.timing.relativeTo = null;
        } else {
          component.timing.relativeTo = defaultFlowList[placementIndex - 1].id;
        }
      },
      [Placement.SIMULTANEOUS]: () => null,
    };

    placementFixActions[component.timing.placement]();

    return component;
  });

  const fixedSimultaneousFlowList = simultaneousFlowList?.map((component) => {
    if (!defaultFlowList) {
      return component;
    }
    if (!defaultFlowList.find(({ id }) => id === component.timing.relativeTo)) {
      component.timing.relativeTo = defaultFlowList[0].id;
    }

    return component;
  });

  let result: TRunDesignComponent[] = [];
  if (fixedDefaultFlowList) {
    result = fixedDefaultFlowList;
  }
  if (fixedSimultaneousFlowList) {
    result.push(...fixedSimultaneousFlowList);
  }

  return result;
};

export default fixPlacement;
