import { useEffect, useMemo, ComponentType } from 'react';
import { Hub } from 'aws-amplify';
import classnames from 'classnames/bind';

import { ECdnObjectType } from '@/types/cdnData';

import { createChangeJwtTokenListener, parseJSON } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import graphqlEvents, { EOnExperimentEventType } from '@/helpers/graphqlEvents';
import { findLaneInScanList } from '@/helpers/scans';

import { useInitNavigatorData, useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { ExperimentContextProvider } from '@/hooks/useExperimentContext';

import { cdnAPI } from '@/store/services/cdnData';
import { appAPI } from '@/store/services/app';
import { viewerActions } from '@/store/slices/viewer';

import LoaderProgress from '@/components/common/LoaderProgress';

import styles from './withExperimentData.module.scss';

const cn = classnames.bind(styles);

export type TExperimentDataProps = {
  experimentId: string;
};

export function withExperimentData<P>(WrappedComponent: ComponentType<P & TExperimentDataProps>) {
  return function ComponentWithExperimentData(props: P) {
    const appDispatch = useAppDispatch();
    const experimentId = useParamsExperimentId();

    useInitNavigatorData();

    const {
      isLoading: isCookiesLoading,
      isError: isCookiesError,
      error: cookiesErrorMessage,
      refetch: fetchExperimentCookies,
    } = cdnAPI.useFetchExperimentCookiesQuery(experimentId);

    const {
      data: experimentData,
      isLoading: isExperimentLoading,
      isError: isExperimentError,
      error: experimentErrorMessage,
    } = appAPI.useFetchExperimentByIdQuery(experimentId);

    const {
      isLoading: isScansLoading,
      isError: isScansError,
      error: scansErrorMessage,
    } = appAPI.useFetchExperimentScansQuery(experimentId);
    const [fetchExperimentScans] = appAPI.useLazyFetchExperimentScansQuery();

    const isExperimentDataLoading = useMemo(
      () => isCookiesLoading || isExperimentLoading || isScansLoading,
      [isCookiesLoading, isExperimentLoading, isScansLoading]
    );

    useEffect(() => {
      appDispatch(viewerActions.setDisableUseOptimizedData(false));

      const authListener = createChangeJwtTokenListener(() => {
        appDispatch(cdnAPI.util.invalidateTags([{ type: 'Cookies' }]));
        fetchExperimentCookies()
          .unwrap()
          .catch((error) => {
            showErrorToast(getErrorMessage(error));
          });
      });

      const removeAuthListener = Hub.listen('auth', authListener);

      return () => {
        removeAuthListener();
      };
    }, []);

    useEffect(() => {
      if (isCookiesError) {
        showErrorToast(getErrorMessage(cookiesErrorMessage));
      }
    }, [isCookiesError, cookiesErrorMessage]);

    useEffect(() => {
      if (isExperimentError) {
        showErrorToast(getErrorMessage(experimentErrorMessage));
      }
    }, [isExperimentError, experimentErrorMessage]);

    useEffect(() => {
      if (isScansError) {
        showErrorToast(getErrorMessage(scansErrorMessage));
      }
    }, [isScansError, scansErrorMessage]);

    useEffect(() => {
      let eventsSubscriptionUuid: string | undefined;
      graphqlEvents
        .subscribe(experimentId, experimentData?.organizationId ?? '', {
          eventType: EOnExperimentEventType.generated,
          success: (eventData: Record<string, string>) => {
            const payload = parseJSON(eventData.payload);
            fetchExperimentScans(payload.experimentId)
              .unwrap()
              .then((scanList: TScan[]) => {
                const { scanId, laneId } = payload;
                const lane = findLaneInScanList(scanList, scanId, laneId);
                if (lane) {
                  appDispatch(cdnAPI.util.invalidateTags([{ type: ECdnObjectType.cageEntity, id: lane.path }]));
                }
              })
              .catch(() => {
                // Do nothing
              });
          },
        })
        .then((callBackUuid) => {
          eventsSubscriptionUuid = callBackUuid;
        });
      return () => {
        if (eventsSubscriptionUuid) {
          graphqlEvents.unsubscribe(eventsSubscriptionUuid);
        }
      };
    }, [experimentId, experimentData]);

    if (isExperimentDataLoading) {
      return <LoaderProgress isLoading theme="light" wrapperClassName={cn('experiment-data__content-loader')} />;
    }

    if (isExperimentError) {
      return <div className={cn('experiment-data__error')}>{getErrorMessage(experimentErrorMessage)}</div>;
    }

    if (isCookiesError) {
      return <div className={cn('experiment-data__error')}>{getErrorMessage(cookiesErrorMessage)}</div>;
    }

    return (
      <ExperimentContextProvider>
        <WrappedComponent experimentId={experimentId} {...props} />
      </ExperimentContextProvider>
    );
  };
}
