import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import type { TModalColor } from '@/helpers/interfaces';
import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import styles from './ModalHead.module.scss';
import type { TModal } from '..';

const cn = classnames.bind(styles);

type TModalHead = {
  children?: React.ReactNode;
  className?: string;
  modalColor?: TModalColor; // ?? this props from modal component
  color?: TModalColor;
  withDivide?: boolean;
} & Pick<TModal, 'onRequestClose' | 'sidebar' | 'id'>;

const closeIconColorData: Record<TModalColor, string> = {
  blue: '#fff',
  dark: '#fff',
  default: '#3d3d3d',
  light: '#3d3d3d',
  purple: '#fff',
  yellow: '#3d3d3d',
};

const ModalHead: FC<TModalHead> = ({
  onRequestClose,
  children,
  color = 'default',
  className,
  withDivide = true,
  sidebar,
  id = 'modal',
  modalColor = 'default',
}) => {
  const closeIconColor = useMemo(() => closeIconColorData[modalColor], [modalColor]);
  return (
    <div
      data-test-id={`${id}__header_title`}
      className={cn(
        'modal-head',
        { 'modal-head__divide': withDivide, 'modal-head__sidebar': sidebar, color },
        className
      )}
    >
      {children}
      {onRequestClose && (
        <Button
          color="light"
          type="button"
          data-test-id={`${id}__header_close`}
          className={cn('modal-head__close-button')}
          onClick={onRequestClose}
        >
          <icons.CloseIcon color={closeIconColor} />
        </Button>
      )}
    </div>
  );
};
export default ModalHead;
