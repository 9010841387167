export type TVariables = {
  name: string;
  type: string;
  description: string;
  value: string;
};

export type TSetting = {
  category: string;
  variables: Array<TVariables>;
};

export enum EUnselectedOption {
  value = 'unselected',
  label = 'Unselected',
}
