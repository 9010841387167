import { object, string } from 'zod';

export const objectEntityFromServerSchema = object({
  cage_center_dist: string(),
  cage_id: string(),
  eccentricity: string(),
  file_name: string().optional(), // exists in pipeline v4
  cage_crop_file_name: string().optional(), // exists in pipeline v5
  object_area_px: string(),
  perimeter_area_ratio: string(),
  snapshot_id: string(),
  is_cell: string(),
  is_bead: string().optional(), // exists in pipeline v5
});

export const cageEntityFromServerSchema = object({
  cage_id: string(),
  file_name: string().optional(), // exists in pipeline v4
  cage_crop_file_name: string().optional(), // exists in pipeline v5
  // object_count: string(),
  // snapshot_id: string(),
});

export const barcodeFromServerSchema = object({
  barcode_name: string(),
  barcode_sequence: string(),
  x: string(),
  y: string(),
  r: string(),
});

export const cagingSegmentationFromServerSchema = object({
  x: string(),
  y: string(),
  r: string(),
});

export const genFromServerSchema = object({
  'global.cage.id.matched': string().optional(),
  count: string().optional(),
});
