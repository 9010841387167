import { FC } from 'react';
import classnames from 'classnames/bind';

import styles from '../UserList.module.scss';

const cn = classnames.bind(styles);

type TUserStatusBadgeProps = {
  data: TUser;
};

const UserStatusBadge: FC<TUserStatusBadgeProps> = ({ data }) => (
  <div
    className={cn('badge', {
      badge_yellow: data.status !== 'CONFIRMED' && data.status !== 'UNCONFIRMED',
      badge_red: data.status === 'UNCONFIRMED',
      badge_green: data.status === 'CONFIRMED',
    })}
  >
    {data.status}
  </div>
);

export default UserStatusBadge;
