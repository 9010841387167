import { SVGProps } from 'react';

const AxisSwitchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M12.82.5h19.36c4.317 0 5.889.432 7.468 1.277a8.593 8.593 0 0 1 3.575 3.575c.845 1.58 1.277 3.151 1.277 7.467v19.362c0 4.316-.432 5.888-1.277 7.467a8.593 8.593 0 0 1-3.575 3.575c-1.58.845-3.151 1.277-7.467 1.277H12.819c-4.316 0-5.888-.432-7.467-1.277a8.593 8.593 0 0 1-3.575-3.575C.932 38.068.5 36.497.5 32.181V12.819c0-4.316.432-5.888 1.277-7.467a8.593 8.593 0 0 1 3.575-3.575C6.932.932 8.503.5 12.819.5z" />
      <g stroke="currentColor" strokeLinecap="round" strokeWidth="1.5">
        <path d="M29.875 29.492h-7.59c-2.229 0-3.037-.232-3.852-.667a4.543 4.543 0 0 1-1.89-1.89c-.436-.815-.668-1.624-.668-3.852v-7.59h0M27 32.5l3-3M27 26.5l3 3" />
        <g>
          <path d="m18.924 18.406-3-3M12.924 18.406l3-3" />
        </g>
      </g>
    </g>
  </svg>
);

export default AxisSwitchIcon;
