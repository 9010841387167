import { EAxesScaleType, EPageWithChartType, EChartType } from '@/types/charts';

import axisScaleHelper from '@/helpers/axisScaleHelper';
import { PAGE_TYPES_WITH_HIDDEN_Y_TICK_LABELS } from '@/helpers/constants';
import { isHistogramChartType } from '@/helpers/charts/chartsType';

import { TInitialHistogramSettingsState } from '@/store/slices/histogramSettings';

import { LAYOUT_CONFIG } from '@/pages/Dataset/constants';

type TGetChartsLayoutPayload = Pick<
  TInitialHistogramSettingsState,
  'isStackedChartsChecked' | 'isStackedAndFilledEnabled'
> & {
  dataCount: number;
  currentChartType: EChartType;
  isTickLabelsVisible: boolean;
  dragmode: string | boolean;
  range?: Nullable<TPopulationRange>;
  isAutoRange?: boolean;
  withCustomRange?: boolean;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  layoutMarginsPreset?: Partial<TLayoutMargin>;
  pageType?: EPageWithChartType;
};

const getStackedChartsLayoutPart = ({
  dataCount,
  xAxisScaleType,
  yAxisScaleType,
  isAutorange,
  isTickLabelsVisible,
}: {
  dataCount: number;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  isAutorange: boolean;
  isTickLabelsVisible: boolean;
}) => {
  const stackedChartsLayoutPart: Partial<TPlotLayout> = {
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
  };
  // The step formula is chosen so that the graphs overlap slightly
  const domainStep = 0.12 - 0.006 * dataCount;
  for (let i = 0; i < dataCount; i++) {
    const axisName = i === 0 ? 'axis' : `axis${i + 1}`;
    const domain = [i * domainStep, 1 - (dataCount - i - 1) * domainStep];
    const matchesXAxisPart = i === 0 ? {} : { matches: 'x' };
    const matchesYAxisPart = i === 0 ? {} : { matches: 'y' };

    const xAxisScaleTypePart = axisScaleHelper.getPlotLayoutTypePart(xAxisScaleType);
    const yAxisScaleTypePart = axisScaleHelper.getPlotLayoutTypePart(yAxisScaleType);

    stackedChartsLayoutPart[`x${axisName}`] = {
      ...LAYOUT_CONFIG().xaxis,
      autorange: isAutorange,
      showticklabels: isTickLabelsVisible && i === 0,
      showgrid: false,
      ...matchesXAxisPart,
      domain,
      ...xAxisScaleTypePart,
    };

    stackedChartsLayoutPart[`y${axisName}`] = {
      ...LAYOUT_CONFIG().yaxis,
      autorange: isAutorange,
      showticklabels: isTickLabelsVisible && i === 0,
      showgrid: dataCount === 1,
      ...matchesYAxisPart,
      domain,
      ...yAxisScaleTypePart,
    };
  }

  return stackedChartsLayoutPart;
};

export const isAutorangeChartType = (currentChartType: EChartType) => isHistogramChartType(currentChartType);

export const getChartsLayoutConfig = ({
  dataCount,
  currentChartType,
  isTickLabelsVisible,
  dragmode,
  isStackedChartsChecked,
  isStackedAndFilledEnabled,
  range,
  isAutoRange = false,
  xAxisScaleType,
  yAxisScaleType,
  layoutMarginsPreset = {},
  pageType,
}: TGetChartsLayoutPayload) => {
  const withAutoRange = pageType === EPageWithChartType.multiHistogram || (isAutoRange && !range);
  const xAxisScaleTypePart = axisScaleHelper.getPlotLayoutTypePart(xAxisScaleType);
  const yAxisScaleTypePart = axisScaleHelper.getPlotLayoutTypePart(yAxisScaleType);
  const isMultiChart = dataCount > 1;
  const withHoverMode = !isMultiChart || currentChartType === EChartType.histogram;

  const isYTickLabelsHiddenForHistograms =
    pageType &&
    PAGE_TYPES_WITH_HIDDEN_Y_TICK_LABELS.includes(pageType) &&
    currentChartType === EChartType.lineHistogram;

  const changeableLayoutConfigPart: Partial<TPlotLayout> =
    isStackedChartsChecked && !isStackedAndFilledEnabled && isMultiChart
      ? getStackedChartsLayoutPart({
          dataCount,
          xAxisScaleType,
          yAxisScaleType,
          isAutorange: withAutoRange,
          isTickLabelsVisible,
        })
      : {
          xaxis: {
            ...LAYOUT_CONFIG().xaxis,
            autorange: withAutoRange,
            ...(range && { range: [range.xMin, range.xMax] }),
            showticklabels: isTickLabelsVisible,
            ...xAxisScaleTypePart,
          },
          yaxis: {
            ...LAYOUT_CONFIG().yaxis,
            autorange: withAutoRange,
            ...(range && { range: [range.yMin, range.yMax] }),
            rangemode: withAutoRange ? 'tozero' : 'normal',
            showticklabels: isTickLabelsVisible && !isYTickLabelsHiddenForHistograms,
            ...yAxisScaleTypePart,
          },
          hovermode: withHoverMode,
        };

  if (withAutoRange) {
    if (changeableLayoutConfigPart.xaxis) {
      delete changeableLayoutConfigPart.xaxis.range;
    }
    if (changeableLayoutConfigPart.yaxis) {
      delete changeableLayoutConfigPart.yaxis.range;
    }
  }

  if (dataCount > 1) {
    changeableLayoutConfigPart.legend = {
      x: 1,
      xanchor: 'right',
      y: 0.89,
      bgcolor: 'rgba(255,255,255,0.50)',
    };
  }

  return {
    ...LAYOUT_CONFIG(),
    margin: {
      ...LAYOUT_CONFIG().margin,
      ...layoutMarginsPreset,
    },
    ...changeableLayoutConfigPart,
    dragmode,
    barmode: isStackedAndFilledEnabled && isStackedChartsChecked ? 'stack' : null,
  };
};

export const getNewAxesRange = (isAutoRange: boolean, plotRange: Nullable<TPopulationRange>) => {
  if (isAutoRange) {
    return {
      x: [0, 0],
      y: [0, 0],
    };
  }
  if (plotRange) {
    return {
      x: [plotRange.xMin, plotRange.xMax],
      y: [plotRange.yMin, plotRange.yMax],
    };
  }
  return null;
};

export const getUpdatedRangeLayoutConfig = (
  currentLayout: TPlotLayout,
  dataCount: number,
  isAutoRange: boolean,
  newAxesRange: Nullable<{
    x: number[];
    y: number[];
  }>
) => {
  const updatedLayoutConfig: Record<string, boolean | number[]> = {};
  for (let i = 0; i < dataCount; i++) {
    const axisName = i === 0 ? 'axis' : `axis${i + 1}`;
    if (currentLayout[`x${axisName}`]) {
      if (isAutoRange) {
        updatedLayoutConfig[`x${axisName}.autorange`] = true;
        updatedLayoutConfig[`y${axisName}.autorange`] = true;
      } else if (newAxesRange) {
        updatedLayoutConfig[`x${axisName}.range`] = newAxesRange.x;
        updatedLayoutConfig[`y${axisName}.range`] = newAxesRange.y;
      }
    }
  }
  return updatedLayoutConfig;
};
