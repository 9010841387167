import { SVGProps } from 'react';

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-1 -1 18 18" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M14 6v7a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6h0M11 3V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1h0M.5 3.5h15M6 7v5M10 7v5" />
    </g>
  </svg>
);

export default DeleteIcon;
