import { createContext, useContext } from 'react';

export const DatasetSelectingContext = createContext<{
  onSelectMultipleDatasets: ({ event, channelDetails }: { event: any; channelDetails: TDatasetDetails }) => void;
  setSelectionAreaStartId: (id: Nullable<string>) => void;
  selectionAreaStartId: Nullable<string>;
}>({
  onSelectMultipleDatasets: () => null,
  setSelectionAreaStartId: () => null,
  selectionAreaStartId: null,
});

export const useDatasetSelectingContext = () => useContext(DatasetSelectingContext);
