import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Id, toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { ECdnObjectType } from '@/types/cdnData';

import { LAST_EDIT } from '@/helpers';
import { findLaneInScanList } from '@/helpers/scans';
import { contoursOnCanvas } from '@/helpers/objectsOnCanvas';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useContoursContext } from '@/hooks/useContoursContext';

import { cdnAPI } from '@/store/services/cdnData';
import { experimentSelectors } from '@/store/slices/experiment';
import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { viewerActions, viewerSelectors } from '@/store/slices/viewer';

import EntitiesSearch from '@/components/common/EntitiesSearch';

import styles from './EntitiesSearchBlock.module.scss';

const cn = classnames.bind(styles);

const EntitiesSearchBlock: FC<{ openCageInspector: (props: TCageInspectorModalProps) => void }> = ({
  openCageInspector,
}) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const experimentId = useParamsExperimentId();
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const currentLane = useMemo(
    () => findLaneInScanList(scanList, currentScanId, currentLaneId),
    [scanList, currentScanId, currentLaneId]
  );

  const highlightedCage = useSelector(viewerSelectors.selectHighlightedCage);
  const { zoom } = useSelector(navigatorSelectors.selectZoom(currentLaneId));

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const useFetchEntityListQuery = isObjectEntityEnabled
    ? cdnAPI.useFetchObjectEntityListQuery
    : cdnAPI.useFetchCageEntityListQuery;
  const { data: entityList = [], isLoading: isEntityListLoading } = useFetchEntityListQuery(currentLane);

  const {
    cdnData: {
      [ECdnObjectType.cageContour]: { objectList: cageContourList, isLoading: isCageContourListLoading },
    },
  } = useContoursContext();

  const isSearchDisabled = useMemo(
    () => isEntityListLoading || isCageContourListLoading,
    [isEntityListLoading, isCageContourListLoading]
  );

  const toastId = useRef<Nullable<Id>>(null);

  const openImageModal = (entity: TEntity) => {
    openCageInspector({ entity, lane: currentLane });
  };

  const findCageContour = useCallback(
    (entity: TEntity) => {
      if (cageContourList.length === 0) {
        toast.info('The cage cannot be displayed in the navigator because cage locations are not provided');
        return;
      }

      if (!entity.globalCageIdMatched) {
        toast.info(
          'The cage cannot be displayed in the navigator because it does not have a global_cage_id_matched field.'
        );
        return;
      }

      const cageContour = (cageContourList as TCageContour[]).find(
        (contour) => contour.globalCageIdMatched === entity.globalCageIdMatched
      );
      if (!cageContour) {
        toast.info(
          'The cage cannot be displayed in the navigator because its contour was not found by a global_cage_id_matched field.'
        );
      }
      return cageContour;
    },
    [cageContourList]
  );

  const highlightCageInNavigator = useCallback(
    (entity: TEntity, shouldOpenImageIfError = true) => {
      const cageContour = findCageContour(entity);
      if (!cageContour) {
        if (shouldOpenImageIfError) {
          openImageModal(entity);
        }
        return;
      }

      const MIN_ZOOM_TO_HIGHLIGHT_CAGE = 3.5;
      if (zoom < MIN_ZOOM_TO_HIGHLIGHT_CAGE) {
        appDispatch(
          navigatorActions.setZoom({
            experimentId,
            laneId: currentLaneId,
            options: {
              zoom: MIN_ZOOM_TO_HIGHLIGHT_CAGE,
              lastEdit: LAST_EDIT.SEARCH,
            },
          })
        );
      }
      const objBbox = contoursOnCanvas.getObjectBbox(cageContour);
      appDispatch(
        navigatorActions.setPosition({
          experimentId,
          laneId: currentLaneId,
          options: {
            x: objBbox.xMin + (objBbox.xMax - objBbox.xMin) / 2,
            y: objBbox.yMin + (objBbox.yMax - objBbox.yMin) / 2,
            lastEdit: LAST_EDIT.SEARCH,
          },
        })
      );
      appDispatch(
        viewerActions.setHighlightedContourList([
          {
            canvasPoints: [
              [objBbox.xMin, objBbox.yMin],
              [objBbox.xMax, objBbox.yMin],
              [objBbox.xMax, objBbox.yMax],
              [objBbox.xMin, objBbox.yMax],
              [objBbox.xMin, objBbox.yMin],
            ],
          },
        ])
      );
    },
    [findCageContour, zoom, experimentId, currentLaneId]
  );

  const dismissToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
  };

  useEffect(() => {
    if (!highlightedCage) {
      return;
    }

    if (isCageContourListLoading) {
      if (!toastId.current) {
        toastId.current = toast.loading('The cage will be highlighted when cage contours are loaded');
      }
    } else {
      setTimeout(() => {
        dismissToast();
        highlightCageInNavigator(highlightedCage, false);
        appDispatch(viewerActions.setHighlightedCage(null));
      }, 100);
    }
  }, [highlightedCage, isCageContourListLoading, highlightCageInNavigator]);

  useEffect(() => dismissToast, []);

  useEffect(
    () => () => {
      appDispatch(viewerActions.setHighlightedContourList([]));
    },
    [isSearchDisabled]
  );

  return (
    <div className={cn('entities-search-block')}>
      <EntitiesSearch
        entityList={entityList}
        onEntitySelect={highlightCageInNavigator}
        disabled={isSearchDisabled}
        isAlwaysOpen
        placeholder={isObjectEntityEnabled ? 'Search for objects (Object level)' : 'Search for cages (Cage level)'}
        className={cn('entities-search-block__input')}
        popoverPositions={['bottom', 'top']}
        isOnlyGlobalCageId
      />
    </div>
  );
};

export default memo(EntitiesSearchBlock);
