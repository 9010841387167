import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { EChartType } from '@/types/charts';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import Select from '@/components/common/Select';
import SwitchInput from '@/components/common/SwitchInput';

import { TSettingsPopover } from '../types';
import styles from '../SettingsPopover.module.scss';

const cn = classnames.bind(styles);
export type THeatmapSettings = Pick<
  TSettingsPopover,
  | 'isSortByDatasetData'
  | 'toggleIsSortByDatasetData'
  | 'heatmapSortByOptions'
  | 'heatmapSortBySelectedOption'
  | 'heatmapSetSortBySelectedOption'
>;

const HeatmapSettings: FC<THeatmapSettings> = ({
  isSortByDatasetData = false,
  toggleIsSortByDatasetData = () => null,
  heatmapSortByOptions = [],
  heatmapSortBySelectedOption,
  heatmapSetSortBySelectedOption = () => null,
}) => {
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const isSelectVisible = useMemo(
    () =>
      Boolean(isSortByDatasetData && heatmapSortByOptions.length && typeof heatmapSortBySelectedOption === 'number'),
    [isSortByDatasetData, heatmapSortByOptions, heatmapSortBySelectedOption]
  );

  return (
    <>
      {currentChartType === EChartType.heatmap && (
        <div className={cn('section__content')}>
          <div className={cn('section__head', 'section__head_small')}>
            <span>Data grouping</span>
          </div>
          <SwitchInput
            checked={isSortByDatasetData}
            onChange={toggleIsSortByDatasetData}
            offLabel="By cage ID"
            onLabel="By value"
            type="small"
          />
        </div>
      )}
      {isSelectVisible && (
        <div className={cn('section__content')}>
          <div className={cn('section__head', 'section__head_small')}>
            <span>Group by</span>
          </div>
          <Select
            value={heatmapSortBySelectedOption}
            placeholder=""
            className={cn('section__select', 'plot-type-select')}
            controlClassName={cn('section__select')}
            theme={themeOptions.light}
            defaultValue={heatmapSortBySelectedOption}
            onChange={heatmapSetSortBySelectedOption}
            options={heatmapSortByOptions}
          />
        </div>
      )}
    </>
  );
};

export default HeatmapSettings;
