import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { EPreprocessingObjectType, EStepName } from '@/store/slices/preprocessing/types';

import styles from './PreprocessingHeader.module.scss';

const cn = classnames.bind(styles);

type TPreprocessingHeader = {
  hideStepNumber?: boolean;
  customLabel?: string;
  customInfo?: string;
};

const LABEL_BY_SELECTED_PREPROCESSING = {
  [EPreprocessingObjectType.beads]: 'Cytokine Secretion assay data processing',
  [EPreprocessingObjectType.cells]: 'Cell Killing assay data processing',
};

const INFO_BY_SELECTED_PREPROCESSING = {
  [EPreprocessingObjectType.beads]: 'Gating for bead populations',
  [EPreprocessingObjectType.cells]: 'Gating for real cells population',
};

const TOTAL_STEPS = {
  [EPreprocessingObjectType.beads]: 2,
  [EPreprocessingObjectType.cells]: 4,
};

const PreprocessingHeader: FC<TPreprocessingHeader> = ({ hideStepNumber, customLabel, customInfo }) => {
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const preprocessingType = useSelector(preprocessingSelectors.selectPreprocessingType);

  const stepNumber = useMemo(() => {
    const stepNumbersObj: Partial<Record<EStepName, number>> = {
      stepInit: 0,
      stepCytokineDefineBeads: 1,
      stepCytokineReviewBeads: 2,
      stepCytokineSummary: 3,
      stepCellKillingDefineCells: 1,
      stepCellKillingReviewCells: 2,
      stepCellKillingDefineCellsTarget: 3,
      stepCellKillingReviewCellsTarget: 4,
    };

    return stepNumbersObj[currentStep];
  }, [currentStep]);

  return (
    <div className={cn('header')}>
      <span className={cn('header__label')}>{customLabel ?? LABEL_BY_SELECTED_PREPROCESSING[preprocessingType]}</span>
      <div className={cn('step-wrapper')}>
        <span className={cn('header__status-info')}>
          {customInfo ?? INFO_BY_SELECTED_PREPROCESSING[preprocessingType]}
        </span>
        {!hideStepNumber && (
          <span className={cn('header__step-info')}>
            STEP {stepNumber} of {TOTAL_STEPS[preprocessingType]}
          </span>
        )}
      </div>
    </div>
  );
};

export default memo(PreprocessingHeader);
