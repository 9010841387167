import { SVGProps } from 'react';

const SegmentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="40" viewBox="0 0 44 40" fill="none" {...props}>
    <circle cx="7" cy="7" r="5" transform="rotate(-90 7 7)" stroke="black" strokeWidth="4" strokeLinecap="square" />
    <ellipse
      cx="22"
      cy="16"
      rx="5"
      ry="5"
      transform="rotate(-90 22 16)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <circle
      cx="37"
      cy="7"
      r="5"
      transform="rotate(-90 37 7)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <circle
      cx="7"
      cy="24"
      r="5"
      transform="rotate(-90 7 24)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <circle
      cx="22"
      cy="33"
      r="5"
      transform="rotate(-90 22 33)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
    />
    <circle
      cx="37"
      cy="24"
      r="5"
      transform="rotate(-90 37 24)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
    />
  </svg>
);

export default SegmentIcon;
