import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { authAPI } from '@/store/services/auth';

import { themeOptions } from '@/types/theme';
import { MemberRole, TMemberRole } from '@/helpers';
import { addProjectToTeamSchema } from '@/validationSchemas';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import Button from '@/components/common/Button';
import ProjectSelect from '@/components/admin/ProjectSelect';

import styles from './AdminModals.module.scss';
import { TAdminModalProps, TErrorsMapGeneric } from './types';

type TAddProjectToTeamModal = TAdminModalProps & {
  team: TTeam;
};

const cn = classnames.bind(styles);

const validationFields = {
  projectId: 'projectId',
  roles: 'roles',
} as const;

type TErrorsMap = TErrorsMapGeneric<typeof validationFields>;

const AddProjectToTeamModal: FC<TAddProjectToTeamModal> = ({ team, closeModal, isModalOpen }) => {
  const [addProjectToTeam, { isLoading }] = authAPI.useAddProjectToTeamMutation();

  const [selectedProjectId, setSelectedProjectId] = useState<Nullable<string>>(null);
  const [roles, setRoles] = useState<TMemberRole[]>([]);
  const [errors, setErrors] = useState<TErrorsMap>({});

  const isAddProjectInProgress = useMemo(() => isLoading, [isLoading]);

  const roleOptions = useMemo<TOption[]>(() => {
    const result: TOption[] = [];
    result.push({
      label: 'Admin',
      value: MemberRole.admin,
    });
    result.push({
      label: 'Maintainer',
      value: MemberRole.maintainer,
    });
    result.push({
      label: 'Viewer',
      value: MemberRole.viewer,
    });
    return result;
  }, []);

  const handleChangeRole = useCallback((newRoles: TMemberRole[]) => {
    setRoles(newRoles);
  }, []);

  const handleFormSubmit = useCallback(() => {
    if (!team) {
      toast.error('Invalid team');
      return;
    }

    const validate = addProjectToTeamSchema.safeParse({ projectId: selectedProjectId, teamId: team.id, roles });
    if (!validate.success) {
      setErrors(
        validate.error.issues.reduce((acc: TErrorsMap, { message, path }) => {
          acc[path[0].toString() as keyof typeof validationFields] = message;
          return acc;
        }, {})
      );
      return;
    }
    if (!selectedProjectId) {
      return;
    }

    addProjectToTeam({
      teamId: team.id,
      projectId: selectedProjectId,
      allowedRoles: roles,
    })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  }, [team, roles, selectedProjectId]);

  useEffect(() => {
    setSelectedProjectId(null);
    setErrors({});
    setRoles([]);
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedProjectId) {
      setErrors((prev) => {
        delete prev.projectId;
        return prev;
      });
    }
  }, [selectedProjectId]);

  if (!team) {
    return null;
  }
  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick className={cn('modal')}>
      <Modal.Header onRequestClose={closeModal}>
        <h2>Add project to team {team?.name}</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__row')}>
          <span className={cn('select__label')}>
            Select project<span className={cn('required')}>*</span>
          </span>
          <ProjectSelect
            selectedProjectId={selectedProjectId}
            setSelectedProjectId={setSelectedProjectId}
            className={cn('modal__select', 'select__control-container', {
              'select__control-container_error': errors.projectId,
            })}
          />
        </div>

        <div className={cn('modal__row')}>
          <span className={cn('modal__input-label', 'select__label')}>Select role</span>
          <Select
            className={cn('modal__select', 'select__control-container')}
            value={roles}
            multiple
            options={roleOptions}
            theme={themeOptions.light}
            onChange={handleChangeRole}
            id="add-project-to-team__roles-select"
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="add-project-to-team__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="add-project-to-team__begin"
          onClick={handleFormSubmit}
          color="yellow"
          className={cn('modal__button')}
          isLoading={isAddProjectInProgress}
        >
          Add project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(AddProjectToTeamModal);
