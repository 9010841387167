import { SVGProps } from 'react';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeWidth="2">
      <path d="M 0 0 L 16 16 M 0 16 L 16 0" />
    </g>
  </svg>
);

export default CloseIcon;
