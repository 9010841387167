import { SVGProps } from 'react';

const ArrowWhiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M1.654 6.25h8.485M5.896 10.493 10.14 6.25M10.14 6.25 5.895 2.007" />
    </g>
  </svg>
);

export default ArrowWhiteIcon;
