import { useMemo } from 'react';

import { appAPI } from '@/store/services/app';

export function useGetLastExperimentDate() {
  const { data: experimentListData } = appAPI.useFetchExperimentListQuery({ limit: 1 });

  return useMemo(() => {
    const lastExperiment = experimentListData?.list[0];
    if (lastExperiment) {
      return lastExperiment.dateCreated;
    }
  }, [experimentListData]);
}
