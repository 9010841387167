import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { API as awsAPI } from 'aws-amplify';

import graphqlEvents, { EOnExperimentEventType } from '@/helpers/graphqlEvents';
import { parseJSON } from '@/helpers';
import ProgressToast from '@/helpers/progressToast';

import { ProcessCageLevelAnalysisStatus, Gate } from '@/graphql/API';
import * as mutations from '@/graphql/mutations';

import { experimentSelectors } from '@/store/slices/experiment';
import { TProcessingResultPayload } from '@/store/slices/gatesAutoPreprocessing';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { preprocessingResult } from './preprocessingResult';

const preprocessingToast = new ProgressToast(
  (
    <span>
      Data preprocessing started.
      <br />
      We will notify you when data is updated.
    </span>
  ),
  'Data preprocessing is successfully completed.',
  'Preprocessing has failed.'
);

const prepareGateForPreprocessing = (gate: TGate): Gate => ({
  name: gate.name,
  xDimension: gate.xDimension,
  yDimension: gate.yDimension,
  gateNodes: gate.gateNodes ? gate.gateNodes.map((subGate) => prepareGateForPreprocessing(subGate)) : null,
  scanId: gate.scanId ?? null,
  laneId: gate.laneId ?? null,
  properties: gate.properties ? JSON.stringify({ ...gate.properties }) : null,
  shape: { ...gate.shape, model: JSON.stringify(gate.shape.model) },
});

export function useGatesPreprocessing(
  setIsPreprocessingInProgress: (newIsPreprocessingInProgress: boolean) => void,
  savePreprocessingResult: (newPreprocessingResult: TProcessingResultPayload[]) => void
) {
  const experimentId = useSelector(experimentSelectors.selectCurrentExperimentId);
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);
  const objectType = useSelector(chartSettingsSelectors.selectObjectType());

  const associatedEventsSubscription = useRef<string | undefined>(undefined);
  const generatedEventsSubscription = useRef<string | undefined>(undefined);

  const unsubscribeAssociatedEvents = () => {
    if (associatedEventsSubscription.current) {
      graphqlEvents.unsubscribe(associatedEventsSubscription.current);
    }
  };

  const unsubscribeGeneratedEvents = () => {
    if (generatedEventsSubscription.current) {
      graphqlEvents.unsubscribe(generatedEventsSubscription.current);
    }
  };

  const unsubscribeEvents = () => {
    unsubscribeAssociatedEvents();
    unsubscribeGeneratedEvents();
  };

  const subscribeEvents = () => {
    unsubscribeEvents();
    graphqlEvents
      .subscribe(experimentId, experimentData?.organizationId ?? '', {
        eventType: EOnExperimentEventType.associated,
        success: (eventData: Record<string, string>) => {
          const payload = parseJSON(eventData.payload);
          preprocessingResult.clearPayloads(experimentId, payload.datasets ?? []);
          unsubscribeAssociatedEvents();
        },
        experimentChange: endDataPreprocessing,
        error: endDataPreprocessingWithError,
      })
      .then((associatedCallBackUuid) => {
        associatedEventsSubscription.current = associatedCallBackUuid;
      });

    graphqlEvents
      .subscribe(experimentId, experimentData?.organizationId ?? '', {
        eventType: EOnExperimentEventType.generated,
        success: (eventData: Record<string, string>) => {
          preprocessingResult.addPayload(experimentId, parseJSON(eventData.payload));
          if (preprocessingResult.isAllReceived()) {
            endDataPreprocessingWithSuccess();
          }
        },
        experimentChange: endDataPreprocessing,
        error: endDataPreprocessingWithError,
      })
      .then((generatedCallBackUuid) => {
        generatedEventsSubscription.current = generatedCallBackUuid;
      });
  };

  const endDataPreprocessing = () => {
    preprocessingToast.hideMessage();
    setIsPreprocessingInProgress(false);
    unsubscribeEvents();
  };

  const endDataPreprocessingWithSuccess = () => {
    preprocessingToast.showSuccessMessage();
    const result = preprocessingResult.getResultPayloadList();
    savePreprocessingResult(result);
    setIsPreprocessingInProgress(false);
    unsubscribeEvents();
  };

  const endDataPreprocessingWithError = (error?: unknown) => {
    preprocessingToast.showErrorMessage(error);
    setIsPreprocessingInProgress(false);
    unsubscribeEvents();
  };

  const startGatesPreprocessing = async (gateList: TGate[] = []) => {
    preprocessingToast.showLoadingMessage();
    setIsPreprocessingInProgress(true);
    try {
      const response = await awsAPI.graphql({
        query: mutations.processCageLevelAnalysis,
        variables: {
          experimentId,
          objectType,
          gate: gateList.map((gate) => prepareGateForPreprocessing(gate)),
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      // @ts-ignore
      const status = response?.data?.processCageLevelAnalysis?.status;
      if (status === ProcessCageLevelAnalysisStatus.PENDING) {
        subscribeEvents();
      } else {
        endDataPreprocessingWithError();
      }
    } catch (error) {
      endDataPreprocessingWithError(error);
    }
  };

  return useMemo(
    () => ({
      startGatesPreprocessing,
    }),
    [startGatesPreprocessing]
  );
}
