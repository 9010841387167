import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { useUserRole, useFetchNextToken } from '@/hooks';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { useOpenModal } from '@/hooks/useOpenModal';
import { USER_TABLE_FIELD_LIST } from '@/helpers/user/tableFieldList';

import { authAPI } from '@/store/services/auth';

import icons from '@/components/common/icons';
import PageHeader from '@/components/Layout/Page/PageHeader';
import Button from '@/components/common/Button';
import ControlPanel from '@/components/Layout/ControlPanel';
import SearchInput from '@/components/common/SearchInput';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import Modal from '@/components/common/Modal';
import StandardTable from '@/components/common/StandardTable';
import { TAction, TStandardField } from '@/components/common/StandardTable/types';

import { TAdminModalProps } from './types';

import styles from './AdminModals.module.scss';
import InviteUserToTeamModal from './InviteUserToTeamModal';
import AddProjectToTeamModal from './AddProjectToTeamModal';

type TTeamUsersModal = TAdminModalProps & {
  team: TTeam;
};

const cn = classnames.bind(styles);

const PROJECT_TABLE_FIELD_LIST = [
  { name: 'name', label: 'Name', isSearchable: true, isSortable: true },
  {
    name: 'description',
    label: 'Description',
    isSortable: true,
    isSearchable: true,
  },
  {
    name: 'dateModified',
    label: 'Date Modified',
    isSortable: true,
    isSearchable: true,
  },
] as TStandardField<TProject>[];

enum ETab {
  users = 0,
  projects = 1,
}

const TeamUsersModal: FC<TTeamUsersModal> = ({ team, closeModal, isModalOpen }) => {
  const confirmationModal = useConfirmationModalContext();
  const [deleteTeam] = authAPI.useDeleteTeamMutation();
  const [removeUserFromTeam] = authAPI.useRemoveUserFromTeamMutation();
  const [removeProjectFromTeam] = authAPI.useRemoveProjectFromTeamMutation();

  const queryData = useMemo(() => ({ teamId: team.id }), [team.id]);

  const {
    count: userCount,
    error: teamUserError,
    isError: isTeamUserError,
    isListLoading: isTeamUserListLoading,
    isListLoadingDebounced: isTeamUserListLoadingDebounced,
    list: userList,
  } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchTeamUserListQuery,
    select: authAPI.endpoints.fetchTeamUserList.select,
    queryData,
  });

  const {
    count: projectCount,
    error: projectError,
    isError: isProjectError,
    isListLoading: isProjectLoading,
    isListLoadingDebounced: isProjectLoadingDebounced,
    list: projectList,
  } = useFetchNextToken<TProject>({
    useFetch: authAPI.useFetchTeamProjectListQuery,
    select: authAPI.endpoints.fetchTeamProjectList.select,
    queryData,
  });

  const { isTeamUpdateAllowed, isTeamDeleteAllowed } = useUserRole();

  const [searchQuery, setSearchQuery] = useState('');
  const [currentTab, setCurrentTab] = useState(ETab.users);

  const {
    isOpen: isInviteToTeamModalOpen,
    setIsOpen: setIsInviteToTeamModalOpen,
    onClose: handleCloseInviteToTeamModal,
  } = useOpenModal();

  const {
    isOpen: isAddProjectToTeamModalOpen,
    setIsOpen: setIsAddProjectToTeamModalOpen,
    onClose: handleCloseAddProjectToTeamModal,
  } = useOpenModal();

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const handleResetSearch = () => {
    setSearchQuery('');
  };

  const handleDeleteTeam = useCallback(async () => {
    if (!team) {
      return;
    }
    const isConfirmed = await confirmationModal.onOpen({
      confirmationText: `Are you sure you want to delete the team "${team.name}"?`,
      approveButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
    if (!isConfirmed) {
      return;
    }
    deleteTeam({ id: team.id })
      .unwrap()
      .catch((err) => showErrorToast(getErrorMessage(err)));
  }, [isTeamUpdateAllowed, isTeamDeleteAllowed]);

  const handleInviteToTeam = useCallback(() => {
    setIsInviteToTeamModalOpen(true);
  }, []);

  const handleAddProjectToTeam = useCallback(() => {
    setIsAddProjectToTeamModalOpen(true);
  }, []);

  const teamMemberActions = useMemo<TAction<TUser>[]>(() => {
    const actions: TAction<TUser>[] = [];
    if (isTeamUpdateAllowed) {
      actions.push({
        handler: async (user) => {
          const isConfirmed = await confirmationModal.onOpen({
            confirmationText: (
              <>
                Are you sure you want to remove the user <strong>{user.displayName}</strong> from team?
              </>
            ),
            approveButtonText: 'Remove',
          });
          if (!isConfirmed) {
            return;
          }
          removeUserFromTeam({ teamId: team.id, username: user.username });
        },
        className: cn('remove-btn'),
        icon: <icons.DeleteIcon />,
        label: () => 'Remove user',
      });
    }
    return actions;
  }, [isTeamUpdateAllowed, team]);

  const projectActions = useMemo<TAction<TProject>[]>(() => {
    const actions: TAction<TProject>[] = [];
    if (isTeamUpdateAllowed) {
      actions.push({
        handler: async (project) => {
          const isConfirmed = await confirmationModal.onOpen({
            confirmationText: (
              <>
                Are you sure you want to remove the project{' '}
                <strong>
                  {project.name} ({project.name})
                </strong>{' '}
                from team?
              </>
            ),
            approveButtonText: 'Remove',
          });
          if (!isConfirmed) {
            return;
          }
          removeProjectFromTeam({ teamId: team.id, projectId: project.id });
        },
        className: cn('remove-btn'),
        icon: <icons.DeleteIcon />,
        label: () => 'Remove project',
      });
    }
    return actions;
  }, [isTeamUpdateAllowed, team]);

  useEffect(() => {
    handleResetSearch();
  }, [currentTab]);

  const isLoadingControlPanel = useMemo<boolean>(() => {
    const loadingMap: Record<ETab, boolean> = {
      [ETab.users]: isTeamUserListLoadingDebounced,
      [ETab.projects]: isProjectLoadingDebounced,
    };

    return loadingMap[currentTab] ?? false;
  }, [currentTab, isTeamUserListLoadingDebounced, isProjectLoadingDebounced]);

  return (
    <Modal isFullScreen isOpen={isModalOpen} onRequestClose={closeModal} className={cn('list-modal')}>
      <Modal.Header />
      <Modal.Content className={cn('list-modal__content')}>
        <PageHeader>
          <PageHeader.Section>
            <PageHeader.Title as="h2">Team - {team.name}</PageHeader.Title>
          </PageHeader.Section>
          <PageHeader.Section>
            <PageHeader.Title isStatistics>{userCount}</PageHeader.Title>
            <PageHeader.Subtitle>{userCount === 1 ? 'User' : 'Users'}</PageHeader.Subtitle>
          </PageHeader.Section>
          <PageHeader.Section>
            <PageHeader.Title isStatistics>{projectCount}</PageHeader.Title>
            <PageHeader.Subtitle>{projectCount === 1 ? 'Project' : 'Projects'}</PageHeader.Subtitle>
          </PageHeader.Section>
          {(isTeamUpdateAllowed || isTeamDeleteAllowed) && (
            <PageHeader.Section className={cn('header-actions')}>
              {false && isTeamDeleteAllowed && (
                <Button className={cn('header-action')} onClick={handleDeleteTeam} isFitContent>
                  Delete team
                </Button>
              )}
              {isTeamUpdateAllowed && (
                <>
                  <Button className={cn('header-action')} onClick={handleInviteToTeam} isFitContent>
                    Invite to team
                  </Button>
                  <Button className={cn('header-action')} onClick={handleAddProjectToTeam} isFitContent>
                    Add project
                  </Button>
                </>
              )}
            </PageHeader.Section>
          )}
        </PageHeader>

        <Tabs
          selectedTabClassName={cn('tabs__item_selected')}
          className={cn('tabs__container')}
          selectedIndex={currentTab}
          onSelect={setCurrentTab}
        >
          <ControlPanel isLoading={isLoadingControlPanel}>
            <ControlPanel.StickyContent>
              <TabList className={cn('tabs')}>
                <Tab className={cn('tabs__item')} value={ETab.users}>
                  Users
                </Tab>
                <Tab className={cn('tabs__item')} value={ETab.users}>
                  Projects
                </Tab>
              </TabList>
            </ControlPanel.StickyContent>
            <ControlPanel.RightActions>
              <SearchInput
                onChange={handleSearchInputChange}
                onReset={handleResetSearch}
                value={searchQuery}
                disabled={userCount === 0 && projectCount === 0}
              />
            </ControlPanel.RightActions>
          </ControlPanel>

          <TabPanel
            className={cn('tabs__panel', {
              tabs__panel_inactive: currentTab !== ETab.users,
            })}
          >
            <StandardTable<TUser>
              dataList={userList}
              fieldList={USER_TABLE_FIELD_LIST}
              keyFieldName="username"
              searchQuery={searchQuery}
              isLoading={isTeamUserListLoading}
              errorMessage={isTeamUserError ? getErrorMessage(teamUserError) : undefined}
              actions={teamMemberActions}
              actionsFormat="popover"
            />
          </TabPanel>
          <TabPanel
            className={cn('tabs__panel', {
              tabs__panel_inactive: currentTab !== ETab.projects,
            })}
          >
            <StandardTable<TProject>
              dataList={projectList}
              fieldList={PROJECT_TABLE_FIELD_LIST}
              keyFieldName="id"
              searchQuery={searchQuery}
              isLoading={isProjectLoading}
              errorMessage={isProjectError ? getErrorMessage(projectError) : undefined}
              actions={projectActions}
              actionsFormat="popover"
            />
          </TabPanel>
        </Tabs>
        <InviteUserToTeamModal
          closeModal={handleCloseInviteToTeamModal}
          isModalOpen={isInviteToTeamModalOpen}
          team={team}
        />
        <AddProjectToTeamModal
          closeModal={handleCloseAddProjectToTeamModal}
          isModalOpen={isAddProjectToTeamModalOpen}
          team={team}
        />
      </Modal.Content>
    </Modal>
  );
};

export default memo(TeamUsersModal);
