import { CSSProperties, FC } from 'react';
import classnames from 'classnames/bind';

import { EM_WHITE_LUMINANCE, EX_WHITE_LUMINANCE } from '@/helpers/waveLengths';
import { OpticalDisplay } from '@/graphql/API';
import { getColorWithLuminance } from '@/helpers';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TConsumableWaves = {
  className?: string;
  opticalDisplay?: OpticalDisplay | null;
};

const ConsumableWaves: FC<TConsumableWaves> = ({ className, opticalDisplay }) => {
  const whiteColor = getComputedStyle(document.documentElement).getPropertyValue('--wave-white');
  const defaultWaves = {
    em: { color: getColorWithLuminance(whiteColor, EM_WHITE_LUMINANCE), label: 'BF', uiLabel: 'White' },
    ex: { color: getColorWithLuminance(whiteColor, EX_WHITE_LUMINANCE), label: 'BF', uiLabel: 'White' },
  };

  return (
    <div className={cn('waves', className)}>
      <div
        className={cn('waves__wave-item', 'waves__ex')}
        style={{ '--ex-bg': opticalDisplay?.excitation?.hex ?? defaultWaves.ex.color } as CSSProperties}
      >
        {opticalDisplay?.excitation?.symbol ?? defaultWaves.ex.label}
      </div>
      <div
        className={cn('waves__wave-item', 'waves__em')}
        style={{ '--em-bg': opticalDisplay?.detection?.hex ?? defaultWaves.em.color } as CSSProperties}
      >
        {opticalDisplay?.detection?.symbol ?? defaultWaves.em.label}
      </div>
    </div>
  );
};

export default ConsumableWaves;
