import { Viewport as OsdViewport } from 'openseadragon';

export const CAGE_CONTOUR_DRAW_SETTINGS = {
  lineColor: '#6750a4',
  lineWidth: 10,
};

export const CELL_CONTOUR_DRAW_SETTINGS = {
  lineColor: '#e1d274',
  lineWidth: 5,
};

export const CURRENT_OBJECT_DRAW_SETTINGS = {
  lineColor: '#05b2f1',
  lineWidth: 5,
};

export const CURRENT_CAGE_DRAW_SETTINGS = {
  lineColor: '#e85303',
  lineWidth: 20,
};

export const BARCODE_DRAW_SETTINGS = {
  lineColor: '#d323677f',
  fillColor: (opacity: number) => `rgba(211,35,103,${opacity})`,
  lineWidth: 10,
};

export const CAGING_SEGMENTATION_DRAW_SETTINGS = {
  lineColor: '#1f641a7f',
  fillColor: (opacity: number) => `rgba(31,100,26,${opacity})`,
  lineWidth: 10,
};

export const getOsdViewportBbox = (viewport: OsdViewport) => {
  const bounds = viewport.getBounds();
  const imageRect = viewport.viewportToImageRectangle(bounds.x, bounds.y, bounds.width, bounds.height);
  const xMin = imageRect.x;
  const yMin = imageRect.y;
  const xMax = imageRect.x + imageRect.width;
  const yMax = imageRect.y + imageRect.height;
  return { xMin, yMin, xMax, yMax };
};

export const getBboxWithGap = (bbox: TBoundaryBox, gapPercent = 5) => {
  const xGap = (bbox.xMax - bbox.xMin) * (gapPercent / 100);
  const yGap = (bbox.yMax - bbox.yMin) * (gapPercent / 100);
  return {
    xMin: bbox.xMin - xGap,
    xMax: bbox.xMax + xGap,
    yMin: bbox.yMin - yGap,
    yMax: bbox.yMax + yGap,
  };
};
