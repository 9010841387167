import { FC } from 'react';
import classnames from 'classnames/bind';

import type { TModalColor } from '@/helpers/interfaces';

import type { TModal } from '..';
import styles from './ModalFooter.module.scss';

const cn = classnames.bind(styles);

type TModalFooter = {
  children: React.ReactNode;
  className?: string;
  withDivide?: boolean;
  modalColor?: TModalColor; // ?? this props from modal component
  color?: TModalColor;
} & Pick<TModal, 'onRequestClose' | 'sidebar' | 'id'>;

const ModalFooter: FC<TModalFooter> = ({ children, className, withDivide, sidebar, modalColor, color }) => (
  <div
    className={cn(
      'modal-footer',
      { 'modal-footer__divide': withDivide, 'modal-footer__sidebar': sidebar, modalColor, color },
      className
    )}
  >
    {children}
  </div>
);

export default ModalFooter;
