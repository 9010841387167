import { SVGProps } from 'react';

const CellToCageWallMinDistanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="3.5"
      y="-3.5"
      width="47"
      height="47"
      rx="23.5"
      transform="matrix(1 0 0 -1 0.0078125 47)"
      stroke="#EBEBEA"
      strokeWidth="7"
    />
    <rect x="29.0078" y="22" width="10" height="10" rx="5" fill="#57D4FB" />
    <rect x="17.0078" y="15" width="10" height="10" rx="5" fill="#F9EF7D" />
    <rect x="17.0078" y="29" width="10" height="10" rx="5" fill="#F9EF7D" />
    <rect x="40.0078" y="26" width="2" height="2" fill="black" />
    <rect x="44.0078" y="26" width="2" height="2" fill="black" />
  </svg>
);

export default CellToCageWallMinDistanceIcon;
