import { hideGate, highlightActiveGate } from '@/hooks/gates/helpers/common';
import { useCallback, useMemo } from 'react';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { isPolarAreaTypeGate } from '@/helpers/typeGuards';
import { findParentGate } from '@/helpers/gates';
import { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';

type THideGate = {
  gate: Nullable<TGate>;
  gateList: TGate[];
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
};

export function useHideGate({ gate, gateList, updateGate }: THideGate) {
  const appDispatch = useAppDispatch();
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);

  const parentGate = useMemo(() => {
    if (!gate) {
      return null;
    }

    const isPolarSector = isPolarAreaTypeGate(gate.shape);
    const polarSectorParent = isPolarSector ? findParentGate(gateList, gate.parentId) : null;
    return isPolarSector && polarSectorParent ? polarSectorParent : gate;
  }, [gate, gateList, updateGate]);

  const gateProperties: Nullable<TGateProperties> = parentGate?.properties ?? null;
  const isGateVisible = gateProperties?.isVisible ?? typeof gateProperties?.isVisible === 'undefined';
  const gateColor = gateProperties?.color;

  const toggleGateVisibility = useCallback(() => {
    if (!parentGate) {
      return;
    }

    const { properties } = parentGate;

    updateGate(parentGate, {
      properties: {
        ...properties,
        isVisible: typeof properties.isVisible !== 'undefined' ? !properties.isVisible : false,
      },
    });

    highlightActiveGate(parentGate.id);
    appDispatch(gatesActions.setActiveGate(null));

    if (selectedGate) {
      appDispatch(gatesActions.setSelectedGate(null));
    }

    hideGate(parentGate.id, properties.isVisible);
  }, [updateGate, parentGate, selectedGate]);

  return {
    toggleGateVisibility,
    parentGate,
    gateProperties,
    isGateVisible,
    gateColor,
  };
}
