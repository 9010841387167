import { TemperatureUnit, Wash } from '@/graphql/API';

const getInitialWash = (): Wash => ({
  __typename: 'Wash',
  duration: 0,
  temperature: 0,
  temperatureUnit: TemperatureUnit.C,
  numberOfWashes: 0,
});

export default getInitialWash;
