import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { themeOptions } from '@/types/theme';

import Select from '@/components/common/Select';
import { getOption } from '@/components/common/Select/helpers';

import styles from './AxisSelect.module.scss';
import icons from '../../common/icons';
import Button from '../../common/Button';

const cn = classnames.bind(styles);

const DropdownIndicator = () => <icons.SearchIcon />;

type TAxisSelectProps = {
  value: string | number;
  isXAxis: boolean;
  scatterPlotAxesOptions: TOption[];
  onChange: (newAxisValue: string) => void;
  className?: string;
  onlyShowValue?: boolean;
  customLabel?: string;
};

const AxisSelect: FC<TAxisSelectProps> = ({
  value,
  isXAxis,
  scatterPlotAxesOptions,
  onChange,
  className,
  onlyShowValue,
  customLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const selectRef = useRef<Nullable<HTMLDivElement>>(null);

  const tonggleOpen = () => setIsOpen((prev) => !prev);

  const selectContainerId = useMemo(() => (isXAxis ? 'axis-select_x' : 'axis-select_y'), [isXAxis]);

  const handleChange = (option?: TBasicOption) => {
    if (!option) return;

    const newSelectedValue = String(option?.value ?? '');
    setInputValue(newSelectedValue);
    onChange(newSelectedValue);
    setIsOpen(false);
    setInputValue('');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation();

      if (!(event.target as HTMLElement).closest(`#${selectContainerId}`)) {
        setIsOpen(false);
        setInputValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectContainerId]);

  useEffect(() => {
    setInputValue('');
  }, [scatterPlotAxesOptions]);

  return (
    <div
      id={selectContainerId}
      className={cn('axis-select', 'axes-select-container', isXAxis ? 'axis-select_x' : 'axis-select_y', className)}
    >
      {onlyShowValue ? (
        <span className={cn('axis-select', isXAxis ? 'axis-select_x' : 'axis-select_y', className)}>
          {customLabel ?? getOption(scatterPlotAxesOptions, value)?.label}
        </span>
      ) : (
        <>
          <Button className={cn('axis-select__button')} onClick={tonggleOpen} color="light">
            {value ? `${getOption(scatterPlotAxesOptions, value)?.label}` : 'No data'}
            <icons.ArrowDropdownIcon
              width={10}
              height={10}
              className={cn('axis-select__icon', { 'axis-select__icon_reverted': isOpen })}
            />
          </Button>
          {isOpen && (
            <div className={cn('axis-select__main')}>
              <Select
                innerRef={selectRef}
                value={value}
                isEditable
                customComponents={{ DropdownIndicator, IndicatorSeparator: () => null }}
                isClearable={false}
                menuIsOpen
                onChange={handleChange}
                placeholder=""
                options={scatterPlotAxesOptions}
                inputValue={inputValue}
                setInputValue={setInputValue}
                theme={themeOptions.light}
                singleValueClassName={cn('axis-select__single-value')}
                menuClassName={cn('axis-select__menu')}
                menuListClassName={cn('axis-select__menu-list')}
                controlClassName={cn('axis-select__control')}
                valueContainerClassName={cn('axis-select__value-container')}
                menuPlacement="top"
                isPortalable={false}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default memo(AxisSelect);
