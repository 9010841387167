import { SVGProps } from 'react';

const CellLysisIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none" {...props}>
    <path
      d="M22 2C10.9543 2 2 10.9543 2 22C2 33.0457 10.9543 42 22 42C33.0457 42 42 33.0457 42 22"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path
      d="M2 22C2 33.0457 10.9543 42 22 42C33.0457 42 42 33.0457 42 22C42 10.9543 33.0457 2 22 2.00001"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path d="M22.3125 13.1289L33.4753 13.1289" stroke="currentColor" strokeWidth="4" />
    <path d="M31.6133 22.4336L31.6133 11.2708" stroke="currentColor" strokeWidth="4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7919 16.2344C12.7726 16.489 8.78125 20.6394 8.78125 25.722C8.78125 30.9687 13.0345 35.222 18.2812 35.222C23.4964 35.222 27.73 31.0197 27.7808 25.8166H23.7805C23.73 28.8105 21.2872 31.222 18.2812 31.222C15.2437 31.222 12.7812 28.7596 12.7812 25.722C12.7812 22.8493 14.9836 20.491 17.7919 20.2435V16.2344Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9471 23.8342C24.9228 23.1428 25.8334 22.3743 26.679 21.5287C27.8025 20.4052 28.6504 19.3204 29.2229 18.2741C29.7874 17.2199 30.1757 16.2551 30.3876 15.3798L27.3906 12.3828C27.2302 13.2855 26.842 14.2503 26.2258 15.277C25.6139 16.2838 24.7764 17.3188 23.7134 18.3818C22.9644 19.1308 22.1323 19.8208 21.217 20.4517C20.3017 21.0827 19.3546 21.706 18.3757 22.3217C17.3888 22.9294 16.4136 23.573 15.45 24.2523C15.1463 24.4664 14.8479 24.6893 14.5547 24.9211L17.6475 28.014C17.8222 27.8847 17.9998 27.7583 18.1801 27.6347C19.0954 27.0037 20.0465 26.3843 21.0333 25.7766C22.0122 25.1609 22.9835 24.5135 23.9471 23.8342Z"
      fill="currentColor"
    />
  </svg>
);

export default CellLysisIcon;
