import { ReactNode } from 'react';
import { Id, toast } from 'react-toastify';

import { TOAST_DELAY } from '@/helpers';
import { getErrorMessage } from '@/helpers/errors';

export default class ProgressToast {
  private toastId: Id | null = null;

  private readonly loadingMessage: ReactNode;

  private readonly successMessage: ReactNode;

  private readonly errorMessage: ReactNode;

  constructor(loadingMessage: ReactNode, successMessage: ReactNode, errorMessage: ReactNode) {
    this.loadingMessage = loadingMessage;
    this.successMessage = successMessage;
    this.errorMessage = errorMessage;
  }

  public showLoadingMessage() {
    this.toastId = toast.loading(this.loadingMessage, {
      className: 'toast_max-content',
    });
  }

  public showSuccessMessage() {
    if (!this.toastId) {
      return;
    }
    toast.update(this.toastId, {
      render: this.successMessage,
      type: 'success',
      isLoading: false,
      autoClose: TOAST_DELAY,
    });
    this.toastId = null;
  }

  public showErrorMessage(error?: unknown) {
    if (!this.toastId) {
      return;
    }
    toast.update(this.toastId, {
      render: error ? (
        <span>
          {this.errorMessage}
          <br />
          {getErrorMessage(error)}
        </span>
      ) : (
        this.errorMessage
      ),
      type: 'error',
      isLoading: false,
      autoClose: TOAST_DELAY,
    });
    this.toastId = null;
  }

  hideMessage() {
    if (!this.toastId) {
      return;
    }
    toast.dismiss(this.toastId);
  }
}
