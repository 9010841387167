import { FC, useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';

import { TUseCdnTablesData } from '@/hooks';

import CheckboxInput from '@/components/common/CheckboxInput';
import icons from '@/components/common/icons';

import { showErrorToast } from '@/helpers/errors';
import styles from './DisplaySettings.modulde.scss';

const cn = classnames.bind(styles);

type TDrawObjectsCheckboxProps = TUseCdnTablesData & {
  isChecked: boolean;
  onChange: () => void;
  label: string;
  isFillChecked?: boolean;
  onFillChange?: () => void;
  isAllowedObjectsOnCanvas: boolean;
  isVisibilityIconAllowed?: boolean;
};

const DrawObjectsCheckbox: FC<TDrawObjectsCheckboxProps> = ({
  objectList,
  isLoading,
  loadingError,
  isDisabled,
  isChecked,
  onChange,
  label,
  isFillChecked,
  onFillChange,
  isAllowedObjectsOnCanvas,
  isVisibilityIconAllowed = true,
}) => {
  const isCheckboxDisabled = useMemo(() => {
    const isObjectListEmpty = !(Array.isArray(objectList) && objectList.length > 0);
    return isLoading || isObjectListEmpty;
  }, [objectList, isLoading]);

  useEffect(() => {
    if (loadingError && !isDisabled) {
      showErrorToast(loadingError);
    }
  }, [loadingError, isDisabled]);

  if (isDisabled) {
    return null;
  }

  return (
    <div className={cn('display-settings__group-container')}>
      <CheckboxInput checked={isChecked} onChange={onChange} label={label} theme="light" disabled={isCheckboxDisabled}>
        {isLoading && (
          <div className={cn('display-settings__loading-container')}>
            <div className={cn('display-settings__loading')} />
          </div>
        )}
        {isVisibilityIconAllowed && !isLoading && isAllowedObjectsOnCanvas && (
          <icons.VisibilityOnIcon
            className={cn('display-settings__visibility-icon', {
              'display-settings__visibility-icon_disabled': isCheckboxDisabled,
            })}
          />
        )}
        {isVisibilityIconAllowed && !isLoading && !isAllowedObjectsOnCanvas && (
          <icons.VisibilityOffIcon
            className={cn('display-settings__visibility-icon', {
              'display-settings__visibility-icon_disabled': isCheckboxDisabled,
            })}
          />
        )}
      </CheckboxInput>
      {!isLoading && onFillChange !== undefined && isFillChecked !== undefined && (
        <CheckboxInput
          checked={isFillChecked}
          onChange={onFillChange}
          label="Fill"
          theme="light"
          disabled={isDisabled || !isChecked || !isAllowedObjectsOnCanvas}
        />
      )}
    </div>
  );
};

export default DrawObjectsCheckbox;
