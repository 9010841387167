import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useGeneralGates from '@/hooks/preprocessing/useGeneralGates';
import { useObjectEntitiesByLanesAndGates } from '@/hooks/useExperimentContext/useObjectEntitiesByLanesAndGates';
import { useChartWithGatesProps } from '@/hooks/useChartWithGatesProps';
import useCytokineAxes from '@/hooks/preprocessing/useCytokineAxes';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import DataScatterplotChart from '@/pages/Analytics/components/Scatterplot/components/DataScatterplotChart';
import icons from '@/components/common/icons';

import styles from './StepCytokineReviewBeads.module.scss';

const cn = classnames.bind(styles);

type TDatasetScatterPlot = {
  experimentId: string;
  datasetDetails: TDatasetDetails;
  scanList: TScan[];
  onPlotClick: (chartId: string) => void;
  hideGates?: boolean;
  isUpdated?: boolean;
};

const DatasetScatterPlot: FC<TDatasetScatterPlot> = ({
  experimentId,
  datasetDetails,
  onPlotClick,
  scanList,
  hideGates,
  isUpdated,
}) => {
  const appDispatch = useAppDispatch();
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const applyForAllDatasets = useSelector(preprocessingSelectors.selectApplyForAllDatasets);

  const { getAxes } = useCytokineAxes();

  const axes = useMemo(() => getAxes(datasetDetails.dataset.name), [datasetDetails.id, getAxes]);

  const [isEmpty, setIsEmpty] = useState(false);
  const [showUpdatedAnimation, setShowUpdatedAnimation] = useState(false);

  const datasetList = useMemo(() => [datasetDetails.dataset], [datasetDetails]);

  const fullGateList = useGeneralGates(datasetDetails.id);

  const { objectEntitiesByLanesAndGates, objectEntitiesLoadedLaneList } = useObjectEntitiesByLanesAndGates(
    experimentId,
    datasetList,
    null,
    fullGateList
  );

  const { allProps } = useChartWithGatesProps({
    scanId: '',
    laneId: '',
    channelName: '',
    experimentId,
    createGate: () => null,
    updateGate: () => null,
    deleteGate: () => null,
    deleteGateChildren: () => null,
    withoutSearchParams: true,
    scanList,
    entityLevelGateList: fullGateList,
    changeSelectedChartData: () => null,
    chartDataList: datasets,
    currentAppLane: null,
    entitiesByLanesAndGates: objectEntitiesByLanesAndGates,
    entitiesLoadedLaneList: objectEntitiesLoadedLaneList,
    currentAppDatasetList: datasetList,
    cageEntitiesByLanesAndGates: objectEntitiesByLanesAndGates,
    fullGateList,
    plotId: `preprocessing-plot-${datasetDetails.id}`,
    xAxisSpecific: axes.xAxis,
    yAxisSpecific: axes.yAxis,
  });

  const handleIsEmptyData = useCallback((isEmptyData: boolean) => {
    setIsEmpty(isEmptyData);
  }, []);

  const handlePlotClick = useCallback(
    (chartId: string) => {
      if (isEmpty) {
        return;
      }

      onPlotClick(chartId);
    },
    [isEmpty]
  );

  useEffect(() => {
    appDispatch(preprocessingActions.setApplyForAllDatasets(false));
    const timeout = setTimeout(() => {
      setShowUpdatedAnimation(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showUpdatedAnimation]);

  useEffect(() => {
    if ((!isUpdated && !applyForAllDatasets) || hideGates || isEmpty) {
      return;
    }

    setShowUpdatedAnimation(true);
  }, [isUpdated, hideGates]);

  return (
    <DataScatterplotChart
      chartData={datasetDetails}
      chartColumns={3}
      isTransitionEnd
      {...allProps}
      onPlotClickCustom={handlePlotClick}
      withoutGateLabel
      hideGates={hideGates}
      cardClassName={cn('chart-card', { 'chart-card_no-data': isEmpty })}
      noDataContent={<span className={cn('no-data-content')}>No data for this bead channel</span>}
      handleIsEmptyData={handleIsEmptyData}
      customFlipBlockClassName={cn('flip-block', { 'flip-block_updated': showUpdatedAnimation })}
      cardHeaderClassName={cn('chart-card__header')}
      isBlockAxesChanging
      isHideAxesInfo
      customOfTopContent={
        showUpdatedAnimation && (
          <div className={cn('updated-block')}>
            <icons.CheckboxInCircleIcon />
            <span>Gates updated</span>
          </div>
        )
      }
    />
  );
};

export default memo(DatasetScatterPlot);
