import { genomeSchema } from '@/validationSchemas/annotations';

import { TGenome } from '@/store/services/app/types';

export const transformGenomeOptionListResponse = (genomeList: TGenome[]) =>
  genomeList
    .filter((genome) => {
      const checkResult = genomeSchema.safeParse(genome);
      if (!checkResult.success) {
        console.error(checkResult.error);
        return false;
      }
      return true;
    })
    .map((genome) => ({ label: genome.friendlyName, value: genome.name }));
