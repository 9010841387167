import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { experimentSelectors } from '@/store/slices/experiment';

import ImageModal from '@/components/common/ImagesScrollArea/components/ImageModal';

type TCageInspectorModalProps = {
  cageInspectorState: TCageInspectorState;
  setCageInspectorState: Dispatch<SetStateAction<TCageInspectorState>>;
};

const CageInspectorModal: FC<TCageInspectorModalProps> = ({ cageInspectorState, setCageInspectorState }) => {
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);

  const setSelectedEntity = useCallback((entity: TEntity) => {
    setCageInspectorState((prev) => ({ ...prev, entity }));
  }, []);

  const closeCageInspectorModal = useCallback(() => {
    setCageInspectorState({ isOpen: false });
  }, []);

  if (!cageInspectorState.lane || !cageInspectorState.entity) {
    return null;
  }

  return (
    <ImageModal
      selectedEntity={cageInspectorState.entity}
      setSelectedEntity={setSelectedEntity}
      currentLane={cageInspectorState.lane}
      isModalOpen={cageInspectorState.isOpen}
      closeModal={closeCageInspectorModal}
      scanList={scanList}
      chosenGate={cageInspectorState.gateName}
      gateCageList={cageInspectorState.entityList}
    />
  );
};

export default CageInspectorModal;
