import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { EModebarTools, EChartType } from '@/types/charts';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { addTooltip } from '@/helpers';
import axisScaleHelper from '@/helpers/axisScaleHelper';

import { datasetsSelectors } from '@/store/slices/datasets';
import { gatesSelectors } from '@/store/slices/gates';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';

import icons from '@/components/common/icons';

import { isPolarCreationDisabled } from './helpers';

import styles from './Modebar.module.scss';

const cn = classnames.bind(styles);

type TModebar = {
  handleTooltipClick: (key: EModebarTools) => void;
  selectedTool: Nullable<EModebarTools>;
  disabledAll?: boolean;
  controlItemClassName: string;
  controlItemSelectedClassName: string;
  entityLevelGateList: TGeneralChartProps['entityLevelGateList'];
};

const Modebar = ({
  handleTooltipClick,
  selectedTool,
  disabledAll = false,
  controlItemClassName,
  controlItemSelectedClassName,
  entityLevelGateList = [],
}: TModebar) => {
  const chartId = usePlotChartIdContext();

  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const isEllipseGateDisabled = useMemo(
    () => !axisScaleHelper.isCircleGateAllowed(xAxisScaleType) || !axisScaleHelper.isCircleGateAllowed(yAxisScaleType),
    [xAxisScaleType, yAxisScaleType]
  );

  const isHistogramGatesToolVisible = useMemo(
    () => currentChartType === EChartType.lineHistogram || currentChartType === EChartType.histogram,
    [currentChartType]
  );

  const isPolarGateCreationDisabled = useMemo(
    () =>
      isPolarCreationDisabled({
        subType: 'polar',
        selectedGate,
        entityLevelGateList,
        xAxis,
        yAxis,
      }),
    [selectedGate, entityLevelGateList, xAxis, yAxis]
  );
  const isPolarSplitGateCreationDisabled = useMemo(
    () => isPolarCreationDisabled({ subType: 'split-gate', selectedGate, entityLevelGateList, xAxis, yAxis }),
    [selectedGate, entityLevelGateList, xAxis, yAxis]
  );

  const isHistogramGatesDisabled = useMemo(
    () =>
      isPreprocessingView ||
      !(currentChartType === EChartType.lineHistogram || currentChartType === EChartType.histogram),
    [currentChartType, isPreprocessingView]
  );

  useEffect(() => {
    if (isPolarGateCreationDisabled || isPolarSplitGateCreationDisabled) {
      handleTooltipClick(EModebarTools.zoom);
    }
  }, [isPolarGateCreationDisabled, isPolarSplitGateCreationDisabled]);

  return (
    <>
      <div className={cn('main-controllers')}>
        <button
          {...addTooltip('Zoom')}
          className={cn(controlItemClassName, {
            [controlItemSelectedClassName]: selectedTool === EModebarTools.zoom,
          })}
          onClick={() => handleTooltipClick(EModebarTools.zoom)}
          aria-label="Zoom"
        >
          <icons.SearchIcon />
        </button>
        <button
          {...addTooltip('Interactions with dots inside gates')}
          className={cn(controlItemClassName, {
            [controlItemSelectedClassName]: selectedTool === EModebarTools.gateInteractions,
          })}
          onClick={() => handleTooltipClick(EModebarTools.gateInteractions)}
          aria-label="Interactions with dots inside gates"
        >
          <icons.TouchIcon />
        </button>
      </div>
      <div className={cn('main-controllers')}>
        {isHistogramGatesToolVisible ? (
          <>
            <button
              {...addTooltip('Draw range gate')}
              className={cn(controlItemClassName, {
                [controlItemSelectedClassName]: selectedTool === EModebarTools.range,
              })}
              onClick={() => handleTooltipClick(EModebarTools.range)}
              disabled={disabledAll || isHistogramGatesDisabled}
              aria-label="Draw range gate"
            >
              <icons.RangeGateIcon className={cn('control__icon')} />
            </button>
            <button
              {...addTooltip('Draw split gate')}
              className={cn(controlItemClassName, {
                [controlItemSelectedClassName]: selectedTool === EModebarTools.splitGate,
              })}
              onClick={() => handleTooltipClick(EModebarTools.splitGate)}
              disabled={disabledAll || isHistogramGatesDisabled || isPolarSplitGateCreationDisabled}
              aria-label="Draw split gate"
            >
              <icons.SplitGateIcon className={cn('control__icon')} />
            </button>
          </>
        ) : (
          <>
            <button
              {...addTooltip('Draw square gate')}
              className={cn(controlItemClassName, {
                [controlItemSelectedClassName]: selectedTool === EModebarTools.rectangle,
              })}
              onClick={() => handleTooltipClick(EModebarTools.rectangle)}
              disabled={disabledAll}
              aria-label="Draw square gate"
            >
              <icons.SquareIcon className={cn('control__icon')} />
            </button>
            <button
              {...addTooltip('Draw polygon gate')}
              className={cn(controlItemClassName, {
                [controlItemSelectedClassName]: selectedTool === EModebarTools.polygon,
              })}
              onClick={() => handleTooltipClick(EModebarTools.polygon)}
              disabled={disabledAll}
              aria-label="Draw polygon gate"
            >
              <icons.PolygonIcon className={cn('control__icon')} />
            </button>
            <button
              {...addTooltip('Draw ellipse gate')}
              className={cn(controlItemClassName, {
                [controlItemSelectedClassName]: selectedTool === EModebarTools.ellipse,
              })}
              onClick={() => handleTooltipClick(EModebarTools.ellipse)}
              disabled={isEllipseGateDisabled || disabledAll}
              aria-label="Draw ellipse gate"
            >
              <icons.CircleIcon className={cn('control__icon')} />
            </button>
            {!isPreprocessingView && (
              <button
                {...addTooltip('Draw polar gate')}
                className={cn(controlItemClassName, {
                  [controlItemSelectedClassName]: selectedTool === EModebarTools.polar,
                })}
                onClick={() => handleTooltipClick(EModebarTools.polar)}
                disabled={disabledAll || isPolarGateCreationDisabled}
                aria-label="Draw polar gate"
              >
                <icons.PolarIcon className={cn('control__icon')} />
              </button>
            )}
          </>
        )}
        {/* <button className={cn(controlItemClassName, 'gate-import-control')}>
        <icons.ImportGateIcon className={cn('control__icon')} />
      </button> */}
      </div>
    </>
  );
};

export default Modebar;
