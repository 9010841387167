import fire from './fire.jpg';
import spectrum from './spectrum.jpg';
import ice from './ice.jpg';
import amber from './amber.jpg';
import cold from './cold.jpg';
import cool from './cool.jpg';
import pastel from './pastel.jpg';
import vivid from './vivid.jpg';
import rainbow from './rainbow.png';
import jupiter from './jupiter.png';
import combi from './combi.png';
import dawn from './dawn.png';

export const lutImageURIList = {
  fire,
  spectrum,
  ice,
  amber,
  cold,
  cool,
  pastel,
  vivid,
  rainbow,
  jupiter,
  combi,
  dawn,
} as Record<string, string>;

const lutImageComposer = async () => {
  const lutImages = await Promise.all(
    Object.values(lutImageURIList).map(
      (lutImageURI) =>
        new Promise((res, rej) => {
          const image = new Image();
          image.addEventListener('load', () => res(image));
          image.addEventListener('error', rej);
          image.src = lutImageURI;
        })
    )
  );

  if (!lutImages) {
    return;
  }

  return Object.keys(lutImageURIList).reduce((acc, lutImageUri, index) => {
    acc[lutImageUri] = lutImages[index] as HTMLImageElement;

    return acc;
  }, {} as Record<string, HTMLImageElement>);
};

export default lutImageComposer();
