import { SVGProps } from 'react';

const ArrowUnprocessedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="11" viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M12.5 5.5h-11M12.5 5.5l-4-4M12.5 5.5l-4 4" />
    </g>
  </svg>
);

export default ArrowUnprocessedIcon;
