import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';

import styles from './CustomHeaderWithActions.module.scss';
import SwitchInput from '../../common/SwitchInput';

const cn = classnames.bind(styles);

export type TCustomHeaderAction = {
  title: string;
  isActive?: boolean;
  onClick: () => void;
  className?: string;
  isSwitch?: boolean;
  offLabel?: string;
  onLabel?: string;
};

type TCustomHeaderWithActions = {
  actions: TCustomHeaderAction[];
  children: ReactNode;
  className?: string;
};

const CustomHeaderWithActions: FC<TCustomHeaderWithActions> = ({ actions, children, className }) => (
  <div className={cn('header', className)}>
    <div className={cn('header__title-wrapper')}>{children}</div>
    <div className={cn('header__actions')}>
      {actions.map((action) =>
        action.isSwitch ? (
          <SwitchInput
            key={action.title}
            checked={!!action.isActive}
            onChange={action.onClick}
            onLabel={action?.onLabel ?? ''}
            offLabel={action?.offLabel ?? ''}
            className={cn('header__switch')}
          />
        ) : (
          <Button
            key={action.title}
            className={cn('action', { action_active: action.isActive })}
            onClick={action.onClick}
            isOutlined
          >
            {action.title}
          </Button>
        )
      )}
    </div>
  </div>
);
export default CustomHeaderWithActions;
