import { SVGProps } from 'react';

const MinusDashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="23" height="3" viewBox="0 0 23 3" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.5 1.5h-20"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default MinusDashboardIcon;
