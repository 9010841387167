import { ComponentType, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EPageWithChartType } from '@/types/charts';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getAssayByLane, isLaneProcessed } from '@/helpers/preprocessing';

import { useExperimentContext } from '@/hooks';
import useChartEntityList from '@/hooks/charts/useChartEntityList';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { experimentSelectors } from '@/store/slices/experiment';
import { EAxesGroupName, scatterplotsSelectors, TAxes } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { datasetsSelectors } from '@/store/slices/datasets';
import { chartDataSelectors } from '@/store/slices/chartData';

// TODO: MATRIX_SINGLE_CHART this hoc work with all charts(need work only with each chart in matrix view)
export function withChartData<P extends object>(
  WrappedComponent: ComponentType<P>,
  pageWithChartType?: EPageWithChartType
) {
  return function ComponentWithChartData(props: P) {
    const { currentAppDatasetList, isAppDatasetListReady } = useExperimentContext();
    const chartId = usePlotChartIdContext();

    const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
    const preprocessingChartDataList = useSelector(preprocessingSelectors.selectDatasets);
    const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
    const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);
    const chartLane = useSelector(
      experimentSelectors.selectLane(currentChartData?.dataset.scanId ?? '', currentChartData?.dataset.laneId ?? '')
    );
    const isPresetSettingsForPageDone = useSelector(
      chartSettingsSelectors.selectChartPresetSettingsСompletionStatus(pageWithChartType)
    );

    const datasetList = useMemo(() => {
      if (isPreprocessingView) {
        return preprocessingChartDataList.map((chartData) => chartData.dataset);
      }
      return currentAppDatasetList;
    }, [isPreprocessingView, preprocessingChartDataList, currentAppDatasetList]);

    const generalAxes = useSelector(scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.general)) as Record<
      string,
      TAxes
    >;

    const shouldForceCageLevel = useMemo(() => {
      if (!currentChartData) {
        return false;
      }
      const hasGeneralAxes = generalAxes?.[currentChartData?.name]?.isObjectEntityEnabled === isObjectEntityEnabled;
      const assay = getAssayByLane(chartLane, currentChartData?.channelName ?? '');

      return (
        pageWithChartType &&
        [EPageWithChartType.singleChart, EPageWithChartType.matrixView].includes(pageWithChartType) &&
        !hasGeneralAxes &&
        !!chartLane &&
        !!isLaneProcessed(chartLane, assay) &&
        !isPresetSettingsForPageDone
      );
    }, [
      chartLane,
      pageWithChartType,
      generalAxes,
      currentChartData,
      isObjectEntityEnabled,
      isPresetSettingsForPageDone,
    ]);

    useChartEntityList(datasetList, shouldForceCageLevel);

    if (!isPreprocessingView && !isAppDatasetListReady) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}
