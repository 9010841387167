import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { addTooltip, formatDate, MDASH } from '@/helpers';
import { assayIconList } from '@/helpers/runDesigns/constants';

import { useFetchNextToken } from '@/hooks';

import { authAPI } from '@/store/services/auth';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import Modal from '@/components/common/Modal';
import icons from '@/components/common/icons';

import styles from './DesignCompleteModal.module.scss';

const cn = classnames.bind(styles);

type TDesignCompleteModal = {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
};

const DesignCompleteModal: FC<TDesignCompleteModal> = ({ className, isOpen, closeModal }) => {
  const { list: userList } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
  });

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);
  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const assayComponentList = useSelector(experimentRunDesignSelectors.selectAssayComponentList);

  const preparedData = useMemo(
    () => ({
      lanesCount: currentEditFields.schema?.lanes?.length ?? 0,
      assaysCount: assayComponentList.length,
      runName: currentEditFields.name,
      assays: assayComponentList,
      createdBy: userList.find((user) => user.username === currentEditFields.investigatorId)?.displayName,
      createdAt: designData.runDesignDate && formatDate(designData.runDesignDate, 'E, MMM d  h:mm a'),
    }),
    [currentEditFields, assayComponentList, userList, designData]
  );

  return (
    <Modal onRequestClose={closeModal} isOpen={isOpen} className={cn('design-modal', className)}>
      <Modal.Header withDivide className={cn('design-modal__header', 'info-row__title')}>
        Design complete
      </Modal.Header>
      <Modal.Content className={cn('design-modal__content')}>
        <div className={cn('run-info-block')}>
          <div className={cn('info-row')}>
            <span className={cn('info-row__title')}>Run</span>
            <div className={cn('info-row__run-data')}>
              <span className={cn('run-name')} {...addTooltip(preparedData.runName)}>
                {preparedData.runName}
              </span>
              <div className={cn('assays')}>
                {preparedData.assays.map((assay) => (
                  <div key={assay.type} {...addTooltip(assay.name)} className={cn('assay-icon')}>
                    {assayIconList[assay.type]}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={cn('run-info-block__add-info')}>
            <div className={cn('info-row')}>
              <span className={cn('info-row__title')}>Assays</span>
              <span className={cn('info-row__text')}>{preparedData.assaysCount}</span>
            </div>
            <div className={cn('info-row')}>
              <span className={cn('info-row__title')}>Template</span>
              <span className={cn('info-row__text')}>{designData.templateName ?? MDASH}</span>
            </div>
            <div className={cn('info-row')}>
              <span className={cn('info-row__title')}>Created</span>
              <span className={cn('info-row__text')}>{preparedData.createdAt ?? MDASH}</span>
            </div>
            <div className={cn('info-row')}>
              <span className={cn('info-row__title')}>By</span>
              <span className={cn('info-row__text')}>{preparedData.createdBy ?? MDASH}</span>
            </div>
          </div>
        </div>
        <div className={cn('lanes-block')}>
          <span className={cn('info-row__title', 'lanes-block__lanes-count')}>{preparedData.lanesCount}</span>
          <span className={cn('info-row__title', 'lanes-block__lanes-title')}>Lanes total</span>
        </div>
      </Modal.Content>
      <Modal.Footer withDivide className={cn('design-modal__footer', 'actions')}>
        <button
          className={cn('action', 'action_manifest')}
          onClick={() => window.open('/Experiment-Manifest.pdf', '_blank')}
        >
          <span aria-hidden="true">
            <icons.ManifestIcon />
          </span>
          <span>Manifest</span>
        </button>
        <Link to="/home">
          <button className={cn('action', 'action_dashboard')}>
            <span aria-hidden="true">
              <icons.ArrowToIcon />
            </span>
            <span>Dashboard</span>
          </button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default DesignCompleteModal;
