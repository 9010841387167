import { ComponentType } from 'react';
import { ChartSettingsContextProvider } from '@/hooks/useChartSettingsContext';

export function withChartSettingsContext<P extends object>(WrappedComponent: ComponentType<P>) {
  return function ComponentWithChartSettingsContext(props: P) {
    return (
      <ChartSettingsContextProvider>
        <WrappedComponent {...props} />
      </ChartSettingsContextProvider>
    );
  };
}
