import { getGateById } from '@/helpers/gates';

type TIsPolarCreationDisabledPayload = {
  subType: 'polar' | 'split-gate';
  selectedGate: Nullable<TGate>;
  entityLevelGateList: TGate[];
  xAxis: string;
  yAxis: string;
};

export const isPolarCreationDisabled = ({
  subType,
  selectedGate,
  entityLevelGateList,
  xAxis,
  yAxis,
}: TIsPolarCreationDisabledPayload) => {
  const selectedGateNodes = selectedGate ? getGateById(selectedGate.id, entityLevelGateList)?.gateNodes : null;
  const gateListToCheck = selectedGateNodes ?? entityLevelGateList;
  const quadrantGate = gateListToCheck.find(
    (gate: TGate) =>
      gate.shape.type === 'polar' &&
      gate.properties?.type === subType &&
      gate.xDimension === xAxis &&
      gate.yDimension === yAxis
  );

  return Boolean(quadrantGate);
};
