import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './LoaderProgress.module.scss';

const cn = classnames.bind(styles);

type TLoaderProgress = {
  children?: ReactNode;
  isLoading?: boolean;
  className?: string;
  wrapperClassName?: string;
  loaderContent?: ReactNode;
  theme: 'dark' | 'light';
};

const LoaderProgress: FC<TLoaderProgress> = ({
  children,
  isLoading = true,
  className,
  wrapperClassName,
  loaderContent,
  theme,
}) => (
  <>
    {isLoading && (
      <div className={cn('loader-progress__wrapper', wrapperClassName)}>
        {loaderContent}
        <div className={cn('loader-progress', `loader-progress_${theme}`, className)} />
      </div>
    )}
    {!isLoading && children}
  </>
);

export default LoaderProgress;
