import { SVGProps } from 'react';

const PencilIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="13.435" viewBox="0 0 14 13.435" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <path strokeLinecap="round" d="M6.5 12.5h7" />
      <rect transform="rotate(-45 6.718 6.717)" x="-1.282" y="5.218" width="16" height="3" rx="1.5" />
    </g>
  </svg>
);

export default PencilIcon;
