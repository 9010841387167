import { FC, useState, ChangeEvent, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { filterGateListByEntityLevel } from '@/helpers/gates';

import useParamsExperimentId from '@/hooks/useParamsExperimentId';
import type { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { appAPI } from '@/store/services/app';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import Textarea from '@/components/common/Textarea';
import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';

import styles from './PopulationModal.module.scss';

const cn = classnames.bind(styles);

export type TPopulationModalProps = {
  xDimension: string;
  yDimension: string;
  isModalOpen: boolean;
  closeModal: () => void;
  newGateModel: TNewGateModelData;
  createGate: TUseUpdateGateCallbacksType['createGate'];
  isCreateGatesInProgress: TGeneralChartProps['isCreateGatesInProgress'];
  selectedGate: Nullable<TGate>;
};

const getGateLowerCaseNameList = (gateList: TGate[]) => {
  const gateNameList: string[] = [];
  gateList.forEach((gate) => {
    gateNameList.push(gate.name.toLowerCase());
    if (gate.gateNodes.length > 0) {
      gateNameList.push(...getGateLowerCaseNameList(gate.gateNodes));
    }
  });
  return gateNameList;
};

const PopulationModal: FC<TPopulationModalProps> = ({
  xDimension,
  yDimension,
  isModalOpen,
  closeModal,
  newGateModel,
  createGate = () => null,
  isCreateGatesInProgress,
  selectedGate,
}) => {
  const experimentId = useParamsExperimentId();
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));
  const { data: fullGateList = [] } = appAPI.useFetchExperimentGatesQuery(experimentId);
  const entityLevelGateList = useMemo(
    () => filterGateListByEntityLevel(fullGateList, isObjectEntityEnabled && !isCageLvlForced) ?? [],
    [fullGateList, isObjectEntityEnabled, isCageLvlForced]
  );

  const [gateName, setGateName] = useState('');

  const gateLowerCaseNameList = useRef<string[]>([]);

  const isNameEmpty = useMemo(() => gateName.trim().length === 0, [gateName]);

  const isNameAlreadyTaken = useMemo(
    () => gateLowerCaseNameList.current.includes(gateName.toLowerCase()),
    [gateLowerCaseNameList.current, gateName]
  );

  const textareaRef = useRef<Nullable<HTMLTextAreaElement>>(null);

  const onChangePopulationName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setGateName(e.target.value);
  };

  const handleSaveClick = () => {
    createGate({
      gateName: gateName.trim(),
      newGateModel,
      xDimension,
      yDimension,
      successCallback: closeModal,
      parentGate: selectedGate,
      isCageLevel: !isObjectEntityEnabled,
      currentChartType,
    });
  };

  useEffect(() => {
    setGateName('');
    gateLowerCaseNameList.current = getGateLowerCaseNameList(entityLevelGateList);
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('population-modal')}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>New gate</h2>
      </Modal.Header>
      <Modal.Content>
        <div>Please, add name for a new gate:</div>
        <div className={cn('population-modal__row_name')}>
          <Textarea
            value={gateName}
            onChange={onChangePopulationName}
            id="new-population__name-input"
            ref={textareaRef}
            isAutoFocus
            isEnterEnabled={false}
            maxLength={256}
            error={isNameAlreadyTaken ? 'The gate name is already taken' : ''}
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('population-modal__footer')}>
        <Button
          id="new-population__cancel"
          onClick={closeModal}
          color="white"
          className={cn('population-modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="new-population__begin"
          onClick={handleSaveClick}
          color="yellow"
          className={cn('population-modal__button')}
          disabled={isNameEmpty || isNameAlreadyTaken}
          isLoading={isCreateGatesInProgress}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopulationModal;
