import { SVGProps } from 'react';

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.35 2.42C3 2.42 2.705 2.31 2.465 2.09C2.235 1.86 2.12 1.58 2.12 1.25C2.12 0.92 2.235 0.645 2.465 0.424999C2.705 0.204999 3 0.0949991 3.35 0.0949991C3.71 0.0949991 4.005 0.204999 4.235 0.424999C4.475 0.645 4.595 0.92 4.595 1.25C4.595 1.58 4.475 1.86 4.235 2.09C4.005 2.31 3.71 2.42 3.35 2.42ZM0.365 11V9.62H2.705V5.435C2.705 5.105 2.545 4.94 2.225 4.94H0.575V3.56H2.51C3.13 3.56 3.58 3.705 3.86 3.995C4.15 4.275 4.295 4.725 4.295 5.345V9.62H6.635V11H0.365Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoIcon;
