const getTimeUnits = (durationInSeconds: number) => {
  const hours = Math.floor(durationInSeconds / 60 / 60);
  const minutes = Math.floor(durationInSeconds / 60) % 60;
  const seconds = durationInSeconds % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export default getTimeUnits;
