import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import SelectChannel from '../../Cytokine/SelectChannel';
import styles from './InstructionItem.module.scss';

const cn = classnames.bind(styles);

type TInstructionItem = {
  text: string;
  onChange: (value: string) => void;
  currentValue: string;
  disabled?: boolean;
  className?: string;
};

const InstructionItem: FC<TInstructionItem> = ({ text, onChange, currentValue, disabled = false, className }) => {
  const datasets = useSelector(preprocessingSelectors.selectDatasets);

  return (
    <div className={cn('instruction', className)}>
      <span>{text}</span>
      <SelectChannel
        datasets={datasets}
        value={currentValue}
        onChange={onChange}
        disabled={disabled}
        withTitle={false}
      />
    </div>
  );
};

export default InstructionItem;
