import { useState } from 'react';

/** Hook provides function to rerender component
 * @return rerender function
 */
const useRerender = () => {
  const [, setForceRender] = useState(0);

  return () => {
    setForceRender((prev) => prev + 1);
  };
};

export default useRerender;
