import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import type { TGateList } from '@/components/charts/SingleChartWithGates/types';
import { EPreprocessingObjectType, EStepName } from '@/store/slices/preprocessing/types';
import { isNumber } from '@/helpers';

import Gates from '../../components/Gates';

const CytokineGates: FC<Partial<TGateList>> = (props) => {
  const beads = useSelector(preprocessingSelectors.selectBeadTypes);
  const beadsCount = useSelector(preprocessingSelectors.selectBeadsCount);
  const generalBeadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);
  const stepCytokineReviewBeadsCompleted = useSelector(preprocessingSelectors.selectIsStepCytokineReviewBeadsCompleted);
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);

  const withApplyForAllDatasets = useMemo(
    () =>
      stepCytokineReviewBeadsCompleted &&
      currentStep === EStepName.stepCytokineReviewBeads &&
      isNumber(currentDatasetIndex),
    [stepCytokineReviewBeadsCompleted, currentStep, currentDatasetIndex]
  );

  return (
    <Gates
      {...props}
      type={EPreprocessingObjectType.beads}
      shapes={generalBeadShapes}
      count={beadsCount}
      items={beads}
      withApplyForAllDatasets={withApplyForAllDatasets}
    />
  );
};

export default memo(CytokineGates);
