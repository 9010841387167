import { SVGProps } from 'react';

const AssayGuideSeq = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" version="1.1" viewBox="0 0 130 130" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10">
      <path d="m51.5 53.5 5.5 23M80 53.5 74 76" />
      <path d="M52 34h27c1.1 0 2 .9 2 2s-.9 2-2 2H52c-1.1 0-2-.9-2-2s.9-2 2-2zM76 81h27c1.1 0 2 .9 2 2s-.9 2-2 2H76c-1.1 0-2-.9-2-2s.9-2 2-2zM28 81h27c1.1 0 2 .9 2 2s-.9 2-2 2H28c-1.1 0-2-.9-2-2s.9-2 2-2zM52 44h27c1.1 0 2 .9 2 2s-.9 2-2 2H52c-1.1 0-2-.9-2-2s.9-2 2-2zM76 90h27c1.1 0 2 .9 2 2s-.9 2-2 2H76c-1.1 0-2-.9-2-2s.9-2 2-2zM28 90h27c1.1 0 2 .9 2 2s-.9 2-2 2H28c-1.1 0-2-.9-2-2s.9-2 2-2z" />
    </g>
  </svg>
);

export default AssayGuideSeq;
