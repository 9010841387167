import { MouseEvent, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { TButtonColor } from '@/helpers/interfaces';

import icons from '@/components/common/icons';
import Popover, { TPopoverOption } from '@/components/common/Popover';
import Button from '@/components/common/Button';
import { ActionsFormat, TAction, TActionsFormat, TStandardField, TStandardValue } from './types';

import styles from './StandardTable.module.scss';
import StandardTableCell from './StandardTableCell';

const cn = classnames.bind(styles);

type TStandardTableRowProps<T> = {
  searchWords: string[];
  keyFieldName: keyof T;
  fieldList: TStandardField<T>[];
  data: T;
  actions?: TAction<T>[];
  onClick?: (data: T) => void;
  actionsFormat?: TActionsFormat;
};

const StandardTableRow = <T extends object>({
  searchWords,
  keyFieldName,
  data,
  fieldList,
  actions,
  onClick,
  actionsFormat,
}: TStandardTableRowProps<T>) => {
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const handlePopoverClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsPopoverOpened((prev) => !prev);
  };

  const actionOptions = useMemo<
    (TPopoverOption & { onClick: () => void; color?: TButtonColor; className?: string })[]
  >(() => {
    const actionList = actions ?? [];

    return actionList.map((action) => ({
      id: action.label(data),
      title: action.label(data),
      onClick: () => action.handler(data),
      disabled: action.isDisabled,
      icon: action.icon,
      color: action.color,
      className: action.className,
    }));
  }, [actions, data]);

  const handleRowClick = useCallback(() => {
    onClick?.(data);
  }, [data, onClick]);

  return (
    <tr
      key={data[keyFieldName] as TStandardValue}
      onClick={handleRowClick}
      className={cn({ 'row-clickable': !!onClick })}
    >
      {fieldList.map((field) => (
        <td key={field.name as string} aria-label={field.label} className={cn(field.className)}>
          <StandardTableCell data={data} field={field} searchWords={searchWords} />
        </td>
      ))}
      {!!actions?.length && (
        <td aria-label="actions" className={cn('fit-content', 'actions')}>
          {actionsFormat === ActionsFormat.popover ? (
            <Popover
              onClick={handlePopoverClick}
              setIsOpen={setIsPopoverOpened}
              isOpen={isPopoverOpened}
              options={actionOptions}
              positions={['left', 'bottom', 'top', 'right']}
              reposition={false}
              useBackdrop={false}
            >
              <Button isFitContent color="white" className={cn('actions__button')} isActive={isPopoverOpened}>
                <icons.DotIcon />
              </Button>
            </Popover>
          ) : (
            <div className={cn('button-actions')}>
              {actionOptions.map((action) => (
                <Button
                  key={action.id}
                  isFitContent
                  color={action.color ?? 'white'}
                  className={cn('actions__button', action.className)}
                  onClick={action.onClick}
                  tooltip={action.title?.toString()}
                  tooltipPlace="left"
                >
                  {action.icon}
                </Button>
              ))}
            </div>
          )}
        </td>
      )}
    </tr>
  );
};

export default StandardTableRow;
