import { v4 as uuidv4 } from 'uuid';
import { removeTrailingSlash } from '@/helpers/common';

export const prepareDataset = (dataset: TDatasetFromServer, scanId: string, laneId: string): TDataset => ({
  ...dataset,
  id: uuidv4(),
  friendlyName: dataset.friendlyName || dataset.name,
  scanId,
  laneId,
  path: removeTrailingSlash(dataset.path),
});
