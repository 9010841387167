import { arrToMapByKeys, isFiniteNumber, parseJSON } from '@/helpers';
import {
  CLIPBOARD_CLASS_NAME,
  COPIED_CLASS_NAME,
  SELECTABLE_ATTRIBUTE,
  SELECTABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
  TSelectableData,
} from '@/helpers/reactSelectable';
import { DEFAULT_CELL_TYPE } from '@/pages/experiment-run-design/CagingSettings/constants';
import type { TCopyData } from './types';

export const formatData = (dataFromLib: TCopyData[]) => {
  const grouped = Object.groupBy(dataFromLib, (el) => el.rowIndex);
  const values = Object.values(grouped);

  const columnIndexes = values
    .flatMap((el) => el?.map(({ columnIndex }) => Number(columnIndex)))
    .filter(isFiniteNumber);
  const minColumn = Math.min(...columnIndexes);
  const maxColumn = Math.max(...columnIndexes);
  const columnLength = maxColumn - minColumn + 1;

  const rowIndexes = Object.keys(grouped).map(Number);
  const minRow = Math.min(...rowIndexes);
  const maxRow = Math.max(...rowIndexes);
  const length = maxRow - minRow + 1;

  const formattedData = [];
  for (let rowIndex = 0; rowIndex < length; rowIndex++) {
    const rowData = [];
    for (let columnsIndex = 0; columnsIndex < columnLength; columnsIndex++) {
      const mapByColumnIndex = arrToMapByKeys(grouped[rowIndex + minRow] ?? [], 'columnIndex');
      const formattedColumn = mapByColumnIndex[columnsIndex + minColumn] ?? null;
      rowData.push(formattedColumn);
    }

    formattedData.push(rowData ?? null);
  }

  return formattedData;
};

export const handleAllItemAction = (divEl: Nullable<HTMLDivElement>, action: (el: Element) => void) => {
  divEl?.querySelectorAll(`.${SELECTABLE_CLASS_NAME}`).forEach(action);
};

export const getAllItems = (divEl: Nullable<HTMLDivElement>) =>
  divEl?.querySelectorAll(`.${SELECTABLE_CLASS_NAME}`) ?? [];

export const getSelectedItems = (divEl: Nullable<HTMLDivElement>) =>
  [...getAllItems(divEl).values()].filter((el) => el.classList.contains(SELECTED_CLASS_NAME));

export const getCopiedItems = (divEl: Nullable<HTMLDivElement>) =>
  [...getAllItems(divEl).values()].filter((el) => el.classList.contains(COPIED_CLASS_NAME));

export const getClipboardItems = (divEl: Nullable<HTMLDivElement>) =>
  [...getAllItems(divEl).values()].filter((el) => el.classList.contains(CLIPBOARD_CLASS_NAME));

export const getItemsData = (data: Element[]) =>
  formatData(data.map((el) => parseJSON(el.getAttribute(SELECTABLE_ATTRIBUTE)) as TCopyData));

export const checkIfCursorOnEl = (mouseX: number, mouseY: number, clientRect: DOMRect) => {
  const checkX = mouseX > clientRect.x && mouseX < clientRect.width + clientRect.x;
  const checkY = mouseY > clientRect.y && mouseY < clientRect.height + clientRect.y;

  return checkX && checkY;
};

type THandleCellPaste<T> = {
  fromCopy: Nullable<TSelectableData>[];
  isArrayFormatData: boolean;
  newTableData: T;
  tableDataCopy: T;
  callBackOnFindEl?: () => void;
};

export function handleCellPaste<T>({
  fromCopy,
  isArrayFormatData,
  newTableData,
  tableDataCopy,
  callBackOnFindEl,
}: THandleCellPaste<T>) {
  return (cell: Nullable<TSelectableData>, cellIndex: number) => {
    if (!cell) {
      return;
    }
    const correctCellIndex = cellIndex % fromCopy.length;
    const cellFromCopy = isArrayFormatData
      ? fromCopy[correctCellIndex]
      : fromCopy.find((fomCopyEl) => cell.dataType && cell.dataType === fomCopyEl?.dataType);

    if (!cellFromCopy) {
      return;
    }

    if (!Array.isArray(newTableData) || !Array.isArray(tableDataCopy)) {
      return;
    }

    if (Array.isArray(newTableData) && !newTableData?.[cell.rowIndex] && cell.dataType === 'cellIndex') {
      newTableData[cell.rowIndex] = DEFAULT_CELL_TYPE;
    }
    const newTableDataRow = newTableData?.[cell.rowIndex];

    const copyTableDataRow = tableDataCopy?.[cellFromCopy?.rowIndex];

    if (!newTableDataRow || !copyTableDataRow) {
      return;
    }

    if (!isArrayFormatData && newTableDataRow && copyTableDataRow) {
      callBackOnFindEl?.();
      newTableDataRow[cell?.dataType ?? ''] = copyTableDataRow[cell?.dataType ?? ''];

      return;
    }

    if (cellFromCopy.columnIndex === cell.columnIndex) {
      callBackOnFindEl?.();
      newTableDataRow[cell.columnIndex] = copyTableDataRow[cellFromCopy.columnIndex];
      return;
    }

    if (cellFromCopy.dataType && cellFromCopy.dataType === cell.dataType) {
      callBackOnFindEl?.();
      newTableDataRow[cell.columnIndex] = copyTableDataRow[cellFromCopy.columnIndex];
    }
  };
}
