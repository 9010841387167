import { FC } from 'react';
import classnames from 'classnames/bind';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import { TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';
import { CagingSettingsItem } from '@/graphql/API';

import { TLaneCagingSettings } from '../../../types';
import NumberField from './NumberField';
import { prepareValidationConfigForAdvancedSettings } from '../../../helpers';
import styles from '../EditFields.module.scss';

const cn = classnames.bind(styles);

type TAdvancedSettings = {
  laneCagingSettings: TLaneCagingSettings;
  onChangeNumberField: (field: TCagingSettingNumberField) => (n: number) => void;
  globalCagingSettings?: CagingSettingsItem;
  advancedSettingsList: {
    title: string;
    key: keyof Omit<CagingSettingsItem, '__typename'>;
  }[];
};

const AdvancedSettings: FC<TAdvancedSettings> = ({
  laneCagingSettings,
  onChangeNumberField,
  globalCagingSettings,
  advancedSettingsList,
}) => {
  const cageSettingsInputValidationConfig = prepareValidationConfigForAdvancedSettings(laneCagingSettings);

  return (
    <>
      {advancedSettingsList.map(({ key }, index) => (
        <RunDesignTable.Column
          key={key}
          className={cn('number-field', { 'number-field_right-gap': advancedSettingsList.length - 1 === index })}
        >
          <NumberField
            key={key}
            field={key}
            settings={laneCagingSettings?.overrideSettings}
            onChangeNumberField={onChangeNumberField}
            defaultValue={globalCagingSettings?.[key] ?? undefined}
            validationConfig={cageSettingsInputValidationConfig}
            errors={laneCagingSettings.errors}
          />
        </RunDesignTable.Column>
      ))}
    </>
  );
};

export default AdvancedSettings;
