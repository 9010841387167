import { FC, useCallback, useState } from 'react';

import CheckboxInput from '@/components/common/CheckboxInput';

type TAnnotationSwitch = {
  className?: string;
};

const AnnotationSwitch: FC<TAnnotationSwitch> = ({ className }) => {
  const [checked, setChecked] = useState(false);

  const handleChangeStatus = useCallback(() => {
    setChecked((prev) => !prev);
  }, []);

  return (
    <CheckboxInput
      checked={checked}
      onChange={handleChangeStatus}
      isSwitch
      theme="run-design"
      label={checked ? 'Hide annotations' : 'Show annotations'}
      className={className}
    />
  );
};

export default AnnotationSwitch;
