import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { MIN_CELL_TYPES_COUNT } from '@/helpers';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import CounterModule from './CounterModule';

import styles from '../RunDetails.module.scss';

const cn = classnames.bind(styles);

type TCellTypeMaxProps = {
  isChangeAllowed: boolean;
};

const CellTypeMax: FC<TCellTypeMaxProps> = ({ isChangeAllowed }) => {
  const appDispatch = useAppDispatch();

  const cellTypesCount = useSelector(experimentRunDesignSelectors.selectCountOfCellTypes);
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const handleCellTypesCountIncrease = () => {
    appDispatch(experimentRunDesignActions.addNewCellType());
  };

  const handleCellTypesCountDecrease = () => {
    appDispatch(experimentRunDesignActions.removeCellType());
  };

  return (
    <CounterModule
      className={cn('run-details__module')}
      count={cellTypesCount}
      onIncrease={handleCellTypesCountIncrease}
      onDecrease={handleCellTypesCountDecrease}
      showControls={isChangeAllowed}
      description={
        <span>
          <span className={cn('run-details__action')}>Cell type / lane</span>(max)
        </span>
      }
      isLocked={someRunDesignCardIsEdit}
      minCount={MIN_CELL_TYPES_COUNT}
    />
  );
};

export default memo(CellTypeMax);
