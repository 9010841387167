import { MutableRefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { EChartType } from '@/types/charts';
import { EUnselectedOption } from '@/types/settings';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { D3_COLORSCALES_FOR_SCATTER_CHART } from '@/helpers/charts/colorscales';
import { getColorValuesFromEntityList } from '@/helpers/charts/chartsData';
import usePlotProxy from '../usePlotProxy';

type TUseScatterglPlotsSettings = {
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  isPlotLoaded?: boolean;
};

export function useScatterglPlotsSettings({ graphRef, isPlotLoaded = true }: TUseScatterglPlotsSettings) {
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');

  const highlightDotsBy = useSelector(scatterplotsSelectors.selectHighlightDotsBy(chartId));

  useEffect(() => {
    if (!isPlotLoaded || !graphRef.current?.data || currentChartType !== EChartType.dot) return;

    const newColors: Array<number[] | string> = [];
    graphRef.current.data.forEach(({ customdata }) => {
      const colors = getColorValuesFromEntityList(customdata, highlightDotsBy);
      newColors.push(colors);
    });

    const update = {
      'marker.color': highlightDotsBy === EUnselectedOption.value ? '#1f1f1f' : newColors,
      'marker.colorscale': [D3_COLORSCALES_FOR_SCATTER_CHART[currentColorScale]],
    };

    plotlyProxy.forceUpdate(update);
  }, [highlightDotsBy, currentColorScale]);
}

export default useScatterglPlotsSettings;
