import { SVGProps } from 'react';

const SnapshotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path
        d="M5.128 0h9.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v5.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512C.186 13.302 0 12.655 0 10.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 0 1 2.046.534C2.698.186 3.345 0 5.128 0z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <circle stroke="currentColor" strokeWidth="1.5" cx="10" cy="8" r="4" />
      <circle fill="currentColor" cx="16" cy="4" r="1" />
    </g>
  </svg>
);

export default SnapshotIcon;
