import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import formatAxisOptionList from '@/helpers/charts/formatAxisOptionList';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { EAxesGroupName, scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { gatesActions } from '@/store/slices/gates';

import AxisSelect from '@/components/charts/AxisSelect';

type TAxisXSelectProps = {
  primaryOptionList?: TBasicOption['value'][];
  scatterPlotAxesOptions: TOption[];
  onlyShowValue?: boolean;
  customLabel?: string;
  className?: string;
  specificAxesGroupName?: EAxesGroupName;
};

const AxisXSelect: FC<TAxisXSelectProps> = ({
  scatterPlotAxesOptions,
  onlyShowValue,
  primaryOptionList = [],
  customLabel,
  className,
  specificAxesGroupName,
}) => {
  const appDispatch = useAppDispatch();

  const xAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));

  const formattedAxisOptionList = useMemo(
    () => formatAxisOptionList(scatterPlotAxesOptions, primaryOptionList),
    [scatterPlotAxesOptions, primaryOptionList]
  );

  const handleChangeXAxisValue = (newAxisValue: string) => {
    appDispatch(
      scatterplotsActions.setAxes({
        newAxes: { x: newAxisValue },
        axesGroupName: specificAxesGroupName,
        withoutUpdatingGroupName: !!specificAxesGroupName,
      })
    );
    appDispatch(gatesActions.setActiveGate(null));
  };

  return (
    <AxisSelect
      isXAxis
      scatterPlotAxesOptions={formattedAxisOptionList}
      value={xAxis}
      onChange={handleChangeXAxisValue}
      onlyShowValue={onlyShowValue}
      customLabel={customLabel}
      className={className}
    />
  );
};

export default memo(AxisXSelect);
