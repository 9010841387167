import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EHistogramDataGroupTypes, EChartType } from '@/types/charts';
import { TBaseHistogramSettings, TInitialHistogramSettingsState } from './types';
import { chartSettingsActions } from '../chartSettings';
import { getStateObject } from './helpers';

const initialState: TInitialHistogramSettingsState = {
  currentHistogramDataGroupType: EHistogramDataGroupTypes.kernelEstimation,
  customHistogramBinsAmount: 100,
  isStackedChartsChecked: false,
  isStackedAndFilledEnabled: true,
  isChartFillEnabled: true,
  kernelBandwidthCoefficient: 1,
  kernelBinsAmountMultiplier: 9,
  specificDatasetOptionMap: {},
};

const scatterplotsSlice = createSlice({
  name: 'histogramSettings',
  initialState,
  reducers: {
    setCurrentHistogramDataGroupType: (
      sliceState,
      action: PayloadAction<{ type: EHistogramDataGroupTypes; fullScreenChartDataId?: string }>
    ) => {
      const { type, fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.currentHistogramDataGroupType = type;
      state.customHistogramBinsAmount = initialState.customHistogramBinsAmount;
    },
    setCustomHistogramBinsAmount: (
      sliceState,
      action: PayloadAction<{ bins: number; fullScreenChartDataId?: string }>
    ) => {
      const { bins, fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.customHistogramBinsAmount = bins;
    },

    toggleIsStackedChartsChecked: (state) => {
      state.isStackedChartsChecked = !state.isStackedChartsChecked;
    },
    setIsStackedChartsChecked: (state, action: PayloadAction<boolean>) => {
      state.isStackedChartsChecked = action.payload;
    },

    setIsStackedAndFilledEnabled: (state, action: PayloadAction<boolean>) => {
      state.isStackedAndFilledEnabled = action.payload;
    },
    toggleIsStackedAndFilledEnabled: (state) => {
      state.isStackedAndFilledEnabled = !state.isStackedAndFilledEnabled;
    },

    setIsChartFillEnabled: (
      sliceState,
      action: PayloadAction<{ isChecked: boolean; fullScreenChartDataId?: string }>
    ) => {
      const { isChecked, fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.isChartFillEnabled = isChecked;
    },
    toggleIsChartFillEnabled: (sliceState, action: PayloadAction<{ fullScreenChartDataId?: string }>) => {
      const { fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.isChartFillEnabled = !state.isChartFillEnabled;
    },

    setKernelBandwidthCoefficient: (
      sliceState,
      action: PayloadAction<{ coefficient: number; fullScreenChartDataId?: string }>
    ) => {
      const { coefficient, fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.kernelBandwidthCoefficient = coefficient;
    },
    setKernelBinsAmountMultiplier: (
      sliceState,
      action: PayloadAction<{ bins: number; fullScreenChartDataId?: string }>
    ) => {
      const { bins, fullScreenChartDataId } = action.payload;

      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.kernelBinsAmountMultiplier = bins;
    },
    setSpecificChartSettings: (
      state,
      action: PayloadAction<{ chartDataId: string; settings: Partial<TBaseHistogramSettings> }>
    ) => {
      const { chartDataId, settings } = action.payload;
      if (!state.specificDatasetOptionMap[chartDataId]) return;

      state.specificDatasetOptionMap[chartDataId] = {
        ...state.specificDatasetOptionMap[chartDataId],
        ...settings,
      };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(chartSettingsActions.setCurrentChartType, (state, action) => {
        state.kernelBandwidthCoefficient = 1;

        if (action.payload === EChartType.lineHistogram) {
          state.currentHistogramDataGroupType = EHistogramDataGroupTypes.kernelEstimation;
        } else if (action.payload === EChartType.histogram) {
          state.currentHistogramDataGroupType = EHistogramDataGroupTypes.thresholdFreedmanDiaconis;
        }
      })
      .addCase(chartSettingsActions.setAxesScaleTypes, (state) => {
        state.kernelBandwidthCoefficient = 1;
      })
      .addCase(
        chartSettingsActions.setFullScreenChartData,
        (state, action: PayloadAction<Nullable<TDatasetDetails>>) => {
          if (action.payload && !state.specificDatasetOptionMap[action.payload.id]) {
            state.specificDatasetOptionMap[action.payload.id] = {
              isChartFillEnabled: state.isChartFillEnabled,
              currentHistogramDataGroupType: state.currentHistogramDataGroupType,
              customHistogramBinsAmount: state.customHistogramBinsAmount,
              isStackedChartsChecked: state.isStackedChartsChecked,
              isStackedAndFilledEnabled: state.isStackedAndFilledEnabled,
              kernelBandwidthCoefficient: state.kernelBandwidthCoefficient,
              kernelBinsAmountMultiplier: state.kernelBinsAmountMultiplier,
            };
          }
        }
      )
      .addCase(
        chartSettingsActions.clearSpecificDatasetOptionMap,
        (state, action: PayloadAction<{ chartDataId?: string }>) => {
          const { chartDataId } = action.payload;

          if (chartDataId && state.specificDatasetOptionMap[chartDataId]) {
            delete state.specificDatasetOptionMap[chartDataId];
          }
        }
      )
      .addCase(chartSettingsActions.clearAllSpecificChartSettings, (state) => {
        state.specificDatasetOptionMap = {};
      })
      .addCase(
        chartSettingsActions.setSpecificChartSettingsToAllDatasets,
        (state, action: PayloadAction<{ fullScreenChartDataId: string }>) => {
          const { fullScreenChartDataId } = action.payload;

          const specificSettings = state.specificDatasetOptionMap?.[fullScreenChartDataId] ?? {};

          if (!Object.keys(specificSettings)) return;
          state.isChartFillEnabled = specificSettings.isChartFillEnabled ?? state.isChartFillEnabled;
          state.currentHistogramDataGroupType =
            specificSettings.currentHistogramDataGroupType ?? state.currentHistogramDataGroupType;
          state.customHistogramBinsAmount =
            specificSettings.customHistogramBinsAmount ?? state.customHistogramBinsAmount;
          state.isStackedChartsChecked = specificSettings.isStackedChartsChecked ?? state.isStackedChartsChecked;
          state.isStackedAndFilledEnabled =
            specificSettings.isStackedAndFilledEnabled ?? state.isStackedAndFilledEnabled;
          state.kernelBandwidthCoefficient =
            specificSettings.kernelBandwidthCoefficient ?? state.kernelBandwidthCoefficient;
          state.kernelBinsAmountMultiplier =
            specificSettings.kernelBinsAmountMultiplier ?? state.kernelBinsAmountMultiplier;
        }
      );
  },
});

export default scatterplotsSlice;
