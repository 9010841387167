import { memo } from 'react';
import classnames from 'classnames/bind';

import { formatNumber } from '@/helpers';

import { TExperimentBasicInfo } from '@/store/slices/experiment';

import Modal, { TModal } from '@/components/common/Modal';
import NoDataFound from '@/components/common/NoDataFound';

import styles from './DescriptionModal.module.scss';

const cn = classnames.bind(styles);

const SUMMARY_ROWS = [
  { title: 'Operator', key: 'operator' },
  { title: 'Instrument', key: 'instrumentFriendlyName' },
  { title: 'Date', key: 'dateCreated' },
  { title: 'Duration', key: 'duration' },
  { title: 'Scans total', key: 'numberOfScans' },
];

export type TDescriptionModal = {
  experiment: Partial<TExperiment>;
  animationType?: 'slide-animation' | 'splash-animation';
  description?: string; //  remove props when experiment description field is known
  totalCages?: number;
} & Pick<TModal, 'onRequestClose' | 'isOpen'>;

const DescriptionModal = ({
  experiment,
  isOpen,
  onRequestClose,
  animationType,
  description = '',
  totalCages,
}: TDescriptionModal) => (
  <Modal
    sidebar="right"
    onRequestClose={onRequestClose}
    isOpen={isOpen}
    shouldCloseOnOverlayClick
    className={cn('description-modal')}
    animationType={animationType}
  >
    <Modal.Header className={cn('description-modal__header')}>
      <h2 className={cn('header__title')}>Information</h2>
    </Modal.Header>
    <Modal.Content className={cn('description-modal__body')}>
      <div className={cn('body__content')}>
        <div className={cn('body__summary')}>
          <div className={cn('summary__details')}>
            <h3 className={cn('details__title')}>{formatNumber(totalCages)}</h3>
            <span className={cn('title__caption')}>Cages total</span>
          </div>
          <div className={cn('summary__details')}>
            <h3 className={cn('details__title')}>{experiment?.numberOfSamples ?? 0}</h3>
            <span className={cn('title__caption')}>Samples total</span>
          </div>
          <div className={cn('summary__details')}>
            {experiment?.projectName && (
              <>
                <h3 className={cn('details__title', 'title_resized', 'title_extra-top-space')}>
                  {experiment?.projectName}
                </h3>
                <span className={cn('title__caption')}>Project</span>
              </>
            )}
          </div>
          <div className={cn('summary__details', 'details__rows')}>
            {SUMMARY_ROWS.map(({ title, key }) => (
              <div key={key} className={cn('details__row')}>
                <div className={cn('row__title')}>{title} </div>
                <div className={cn('row__data')}>
                  {experiment[key as keyof TExperimentBasicInfo] ? (
                    experiment[key as keyof TExperimentBasicInfo]
                  ) : (
                    <NoDataFound size="small" alignment="center" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={cn('body__description')}>
          {description ? (
            <>
              <h4 className={cn('description__title')}>Abstract</h4>
              <div className={cn('description__info')}>{description}</div>
            </>
          ) : (
            <NoDataFound size="normal" alignment="center" />
          )}
        </div>
      </div>
    </Modal.Content>
  </Modal>
);
export default memo(DescriptionModal);
