import { FC, Fragment, Dispatch, SetStateAction } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import ReadSelect from './ReadSelect';

import styles from '../../SequencingData.module.scss';

const cn = classnames.bind(styles);

type TReadsEditProps = {
  selectedReads: { read1: string; read2: string }[];
  setSelectedReads: Dispatch<SetStateAction<{ read1: string; read2: string }[]>>;
};

const ReadsEdit: FC<TReadsEditProps> = ({ selectedReads, setSelectedReads }) => {
  const setRead1 = (newValue: string, index: number) => {
    setSelectedReads((prev) => {
      const items = [...prev];
      const itemToUpdate = { ...items[index] };

      itemToUpdate.read1 = newValue;
      items[index] = itemToUpdate;

      return items;
    });
  };

  const setRead2 = (newValue: string, index: number) => {
    setSelectedReads((prev) => {
      const items = [...prev];
      const itemToUpdate = { ...items[index] };

      itemToUpdate.read2 = newValue;
      items[index] = itemToUpdate;

      return items;
    });
  };

  const addReads = (index: number) => {
    const arr = [...selectedReads];
    const indexToInsert = index + 1;
    arr.splice(indexToInsert, 0, { read1: '', read2: '' });

    setSelectedReads(arr);
  };

  const deleteReads = (index: number) => {
    if (selectedReads.length === 1) return;

    const arr = [...selectedReads];
    arr.splice(index, 1);
    setSelectedReads(arr);
  };

  return (
    <>
      {selectedReads.map((item, index) => {
        const key = index;
        return (
          <Fragment key={key}>
            <ReadSelect
              selectedValue={item.read1}
              setSelectedValue={setRead1}
              placeholder="Sequencing data R1"
              selectClassName={cn('sequencing-data__column2')}
              valueIndex={index}
              isR1Select
            />
            <ReadSelect
              selectedValue={item.read2}
              setSelectedValue={setRead2}
              placeholder="Sequencing data R2"
              selectClassName={cn('sequencing-data__column3')}
              valueIndex={index}
              isR1Select={false}
            />
            <div className={cn('sequencing-data__buttons', 'sequencing-data__column4')}>
              {selectedReads.length > 1 && (
                <Button
                  color="white"
                  className={cn('sequencing-data__reads-button')}
                  onClick={() => deleteReads(index)}
                  disabled={selectedReads.length === 1}
                >
                  <icons.DeleteIcon width={12} />
                </Button>
              )}
              <Button color="white" className={cn('sequencing-data__reads-button')} onClick={() => addReads(index)}>
                <icons.PlusIcon width={12} />
              </Button>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default ReadsEdit;
