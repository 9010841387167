import {
  transformExperimentDownloadsResponse,
  transformExperimentListResponse,
  transformExperimentResponse,
  transformGateListResponse,
  transformReagentsAutocompleteResponse,
  transformScansResponse,
  transformSequencingDataResponse,
} from '@/store/services/app/dataProvider';
import { shallowEqual } from 'react-redux';

import { getDateBefore, removeDuplicates } from '@/helpers';
import {
  TFetchExperimentResult,
  TFetchExperimentsQuery,
  TAppEndpointBuilder,
  ESequencingDataRequestDataType,
} from '@/store/services/app/types';

const EXPERIMENTS_REQUEST_LIMIT = undefined; // = Number(process.env.REACT_APP_EXPERIMENTS_REQUEST_LIMIT) || 24;

export const experimentEndpoints = (build: TAppEndpointBuilder) => ({
  fetchExperimentList: build.query<TFetchExperimentResult, TFetchExperimentsQuery>({
    query: ({ nextToken, time = '', operator, instrument, projectId, containScans, limit }) => ({
      url: '/experiments',
      params: {
        nextToken,
        projectId,
        containScans,
        experimentDateFrom: getDateBefore(time),
        operator,
        instrumentId: instrument,
        limit: limit ?? EXPERIMENTS_REQUEST_LIMIT,
      },
    }),
    transformResponse: (experimentFromServers: TExperimentFromServer[], meta): TFetchExperimentResult => ({
      nextToken: meta?.response?.headers.get('X-Pagination-Next-Token') ?? undefined,
      list: transformExperimentListResponse(experimentFromServers),
    }),
    // Have cache entry without nextToken because the arg maps without it
    serializeQueryArgs: ({ queryArgs, endpointName }) => {
      const { nextToken: _, ...argsWithoutNextToken } = queryArgs; // do not use nextToken
      return `${endpointName}(${JSON.stringify(argsWithoutNextToken)})`;
    },
    // Always merge incoming data to the cache entry
    merge: (currentCache, newItems) => {
      currentCache.nextToken = newItems.nextToken;
      const mergedList = removeDuplicates(currentCache.list.concat(newItems.list), 'id');
      currentCache.list = mergedList;
    },
    // Refetch when the page arg changes
    forceRefetch: ({ currentArg, previousArg }) => {
      if (!previousArg) {
        // first page
        return true;
      }
      const { nextToken: prevNextToken, ...prevArgsWithoutNextToken } = previousArg;
      const { nextToken: currNextToken, ...currArgsWithoutNextToken } = currentArg ?? {};
      if (!shallowEqual(prevArgsWithoutNextToken, currArgsWithoutNextToken)) {
        // some parameters (except nextToken) have changed
        return true;
      }
      // empty currNextToken and not empty prevNextToken -> the page is after the last one -> do not refetch
      if (!currNextToken && !!prevNextToken) {
        return false;
      }
      // refetch if nextToken has changed
      return prevNextToken !== currNextToken;
    },
  }),

  fetchExperimentById: build.query<TExperiment, string>({
    query: (id = '') => ({
      url: `/experiments/${id}`,
    }),
    transformResponse: transformExperimentResponse,
    providesTags: (result, error, id) => [{ type: 'Experiment', id }],
  }),

  fetchExperimentSummary: build.query({
    query: ({ id, scanId }) => ({
      url: `/experiments/${id}/summary`,
      params: {
        scanId,
      },
    }),
  }),

  fetchExperimentScans: build.query<Array<TScan>, string | undefined>({
    query: (id) => {
      if (!id) {
        throw new Error('An experiment ID is required to fetch scans.');
      }
      return {
        url: `/experiments/${id}/scans`,
      };
    },
    transformResponse: transformScansResponse,
    providesTags: ['Scan'],
  }),

  fetchExperimentDatasets: build.query({
    query: (id) => ({
      url: `/experiments/${id}/datasets`,
    }),
  }),

  fetchExperimentGates: build.query<TGate[], string | undefined>({
    query: (id) => `/experiments/${id}/gates`,
    transformResponse: (response: TGateFromServer[]) => transformGateListResponse(response, null),
    providesTags: ['Gates'],
  }),

  fetchSequencingData: build.query<string[], string>({
    query: (search) => `/sequencingdata/list?sequencingData=${search}`,
  }),

  fetchSequencingDataListV2: build.query<
    TSequencingDataReadListFromServer,
    { searchString: string; dataType: ESequencingDataRequestDataType }
  >({
    query: ({ searchString, dataType }) =>
      searchString
        ? `/directorydata/listv2?dataType=${dataType}&sequencingData=${searchString}`
        : `/directorydata/listv2?dataType=${dataType}`,
  }),

  fetchSequencingDataByLanes: build.query<TParsedSequencingData, { experimentId: string; laneId?: string }>({
    query: ({ experimentId, laneId }) => ({
      url: `/sequencingdata/by-lanes`,
      params: {
        experimentId,
        laneId,
      },
    }),
    transformResponse: transformSequencingDataResponse,
  }),

  fetchExperimentsOperators: build.query<Array<string>, void>({
    query: () => ({
      url: '/experiments/operators',
    }),
  }),

  updateExperimentSequencingData: build.mutation<TGate[], { experimentId: string; sequencingData: string }>({
    query: ({ experimentId, sequencingData }) => ({
      url: `/experiments/${experimentId}`,
      method: 'PATCH',
      body: { sequencingData },
    }),
    invalidatesTags: (result, error, { experimentId }) => [{ type: 'Experiment', id: experimentId }],
  }),

  createExperimentInvitationLink: build.mutation<{ token: string }, unknown>({
    query: ({ experimentId, body }) => ({
      url: `/experiments/${experimentId}/invite`,
      method: 'POST',
      body,
    }),
  }),

  acceptInvitationLink: build.mutation({
    query: ({ experimentId, token }) => ({
      url: `/experiments/${experimentId}/accept-invitation`,
      method: 'POST',
      body: { token },
    }),
  }),

  fetchReagentsAutocomplete: build.query<TReagent[], string>({
    query: (search) => `/reagents/autocomplete?name=${search}`,
    transformResponse: transformReagentsAutocompleteResponse,
  }),

  fetchExperimentDownloads: build.query<TDownloadFile[], string>({
    query: (id) => ({
      url: `/experiments/${id}/downloads`,
    }),
    transformResponse: transformExperimentDownloadsResponse,
  }),

  generateSeuratJob: build.mutation({
    query: ({ experimentId, laneId, sampleId, genome }) => ({
      url: `/experiments/${experimentId}/generate-seurat`,
      method: 'POST',
      body: { laneId, sampleId, genome },
    }),
  }),
});
