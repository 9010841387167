import { SVGProps } from 'react';

const CageToCageDistanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="3.5"
      y="-3.5"
      width="47"
      height="47"
      rx="23.5"
      transform="matrix(1 0 0 -1 0.0078125 47)"
      stroke="#EBEBEA"
      strokeWidth="7"
    />
    <rect x="7.00781" y="26" width="2" height="2" fill="black" />
    <rect x="11.0078" y="26" width="2" height="2" fill="black" />
    <rect x="15.0078" y="26" width="2" height="2" fill="black" />
    <rect x="19.0078" y="26" width="2" height="2" fill="black" />
    <rect x="23.0078" y="26" width="2" height="2" fill="black" />
    <rect x="27.0078" y="26" width="2" height="2" fill="black" />
  </svg>
);

export default CageToCageDistanceIcon;
