import {
  ChangeEvent,
  forwardRef,
  ReactNode,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  FormEventHandler,
  KeyboardEventHandler,
} from 'react';
import classnames from 'classnames/bind';

import { TButtonColor } from '@/helpers/interfaces';
import styles from './TextInput.module.scss';

const cn = classnames.bind(styles);

export type TTextInputProps = {
  id?: string;
  type?: HTMLInputTypeAttribute;
  value?: string | number;
  pattern?: string;
  label?: ReactNode;
  onChange?: VoidFunction | ((_e: ChangeEvent<HTMLInputElement>) => void);
  disabled?: boolean;
  isBgLight?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  title?: string;
  followingText?: string;
  children?: ReactNode;
  tabIndex?: number;
  onBlur?: FocusEventHandler;
  theme?: TButtonColor;
  autoComplete?: string;
  placeholder?: string;
  errorMessage?: Nullable<string>;
  onSubmit?: FormEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
};

const TextInput = forwardRef<HTMLInputElement, TTextInputProps>(
  (
    {
      id,
      type = 'text',
      value,
      theme = 'light',
      pattern,
      label,
      onChange = () => null,
      disabled = false,
      isBgLight = false,
      className,
      title,
      followingText,
      children,
      tabIndex = 0,
      inputClassName,
      labelClassName,
      onBlur,
      autoComplete,
      placeholder,
      errorMessage,
      onSubmit,
      onFocus,
      onKeyDown,
    },
    ref?
  ) => (
    <div className={cn('input', className, { input__disabled: disabled })} data-following-text={followingText}>
      {!!label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        value={value}
        pattern={pattern}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        className={cn(
          'input__input',
          `input_${theme}`,
          {
            input__input_disabled: disabled,
            input__input_following_text: !!followingText,
            'bg-light': isBgLight,
          },
          inputClassName
        )}
        title={title ?? value?.toString()}
        tabIndex={tabIndex}
        ref={ref}
        data-test-id={id ?? 'text-input'}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onSubmit={onSubmit}
        onKeyDown={onKeyDown}
      />
      {!!errorMessage && <span className={cn('input__error')}>{errorMessage}</span>}
      {!!children && <label htmlFor={id}>{children}</label>}
    </div>
  )
);

TextInput.displayName = 'TextInput';

export default TextInput;
