import { useEffect, useRef, useState, useMemo, memo, useCallback, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ECanvasWebglContextType, EMarkerSizes } from '@/types/charts';

import { getScatterPlotObjectLevelAxesOptions, isEqualAxes } from '@/helpers/channels';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { AXES_DEFAULT_PRESET, parseJSON } from '@/helpers';
import { getOrigDataRange } from '@/helpers/origDataRange';
import { isHistogramChartType } from '@/helpers/charts/chartsType';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useScatterPlotDebouncedData from '@/hooks/charts/useScatterPlotDebouncedData';
import { usePlotSettings } from '@/hooks';
import { useGatesOnPlot } from '@/hooks/gates/useGatesOnPlot';
import { useLinks } from '@/hooks/useLinks';
import useFlipBlock from '@/hooks/charts/useFlipBlock';
import useSelectedGateForSpecificDataset from '@/hooks/preprocessing/useSelectedGateForSpecificDataset';
import { useGatesLabelSettings } from '@/hooks/gates/useGatesLabelSettings';
import { useHistogramPlotsSettings } from '@/hooks/plotSettings/useHistogramPlotsSettings';
import usePlotProxy from '@/hooks/usePlotProxy';

import { experimentSelectors } from '@/store/slices/experiment';
import { scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { datasetsSelectors } from '@/store/slices/datasets';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { chartDataSelectors } from '@/store/slices/chartData';

import '@/styles/gates.scss';

import {
  calculateDensity,
  filterCoordinatesDataByGate,
  getCoordinatesByAxesAndGate,
  prepareDataConfig,
} from '@/helpers/charts/chartsData';
import { getOption, isBasicOption } from '@/components/common/Select/helpers';
import NoDataFound from '@/components/common/NoDataFound';
import icons from '@/components/common/icons';
import type { TChartCallBacksData, TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import AxisSelect from '@/components/charts/AxisSelect';
import Button from '@/components/common/Button';
import CheckboxInput from '@/components/common/CheckboxInput';
import { EGateLabelType } from '@/types/gateSettings';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { getAxesTitlesUpdatedLayout, getShapesUpdatedLayout } from './helpers';
import { CONFIG } from './constants';
import styles from './DataScatterplotChart.module.scss';

const cn = classnames.bind(styles);

type TDataScatterplotChart = {
  chartData: TDatasetDetails;
  chartColumns: number;
  isTransitionEnd: boolean;
  disableFullScreen?: boolean;
  withoutGateLabel?: boolean;
  onPlotClickCustom?: (chartId: string) => void;
  hideGates?: boolean;
  cardClassName?: string;
  handleIsEmptyData?: (data: boolean) => void;
  customFlipBlockClassName?: string;
  cardHeaderClassName?: string;
  customOfTopContent?: ReactNode;
  onAxisChange?: (xAxis: string, yAxis: string, plotId: string) => void;
  isBlockAxesChanging?: boolean;
  isHideAxesInfo?: boolean;
  canvasWebglType?: ECanvasWebglContextType;
} & Pick<
  TGeneralChartProps,
  | 'selectedChartData'
  | 'entitiesByLanesAndGates'
  | 'entityLevelGateList'
  | 'fullGateList'
  | 'plotId'
  | 'yAxisSpecific'
  | 'xAxisSpecific'
  | 'noDataContent'
  | 'plotRangeName'
> &
  TChartCallBacksData;

const LIMIT_BY_COLUMN = 3;
const STATIC_CHART_SELECTOR = '.bg';

const DataScatterplotChart = ({
  chartData,
  chartColumns,
  isTransitionEnd,
  selectedChartData,
  entitiesByLanesAndGates,
  entityLevelGateList,
  fullGateList = [],
  updateGate,
  createGate,
  xAxisSpecific,
  yAxisSpecific,
  disableFullScreen = false,
  withoutGateLabel,
  onPlotClickCustom,
  plotId,
  hideGates = false,
  noDataContent,
  cardClassName,
  handleIsEmptyData,
  customFlipBlockClassName,
  customOfTopContent,
  cardHeaderClassName,
  plotRangeName,
  isBlockAxesChanging,
  isHideAxesInfo,
  onAxisChange = () => null,
  canvasWebglType = ECanvasWebglContextType.defaultWebgl,
}: TDataScatterplotChart) => {
  const { Plotly } = window;
  const { generateChartLink } = useLinks();
  const appDispatch = useAppDispatch();
  const contextChartId = usePlotChartIdContext();
  const navigate = useNavigate();

  const scan = useSelector(experimentSelectors.selectScan(chartData.scanId));
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(contextChartId));
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(contextChartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(contextChartId));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(contextChartId)
  );

  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');

  const [localPlotRange, setLocalPlotRange] = useState<Nullable<Record<'x' | 'y', number[]>>>(null);
  const [coordinates, setCoordinates] = useState<Nullable<TScatterPlotCoordinates>>(null);
  const [filteredCoordinates, setFilteredCoordinates] = useState<Nullable<TScatterPlotCoordinates>>(null);
  const [customData, setCustomData] = useState<TEntity[]>([]);
  const [isPlotLoaded, setIsPlotLoaded] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [shouldApplyToAllDatasets, setShouldApplyToAllDatasets] = useState(false);

  const selectedGate = useSelectedGateForSpecificDataset(chartData.id);
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(contextChartId)(plotRangeName));
  const isTickLabelsVisible = useSelector(chartSettingsSelectors.selectIsTickLabelsVisible(contextChartId));

  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);

  const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(contextChartId));
  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const customHistogramBinsAmount = useSelector(
    histogramSettingsSelectors.selectCustomHistogramBinsAmount(contextChartId)
  );
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(contextChartId)
  );
  const kernelBinsAmountMultiplier = useSelector(
    histogramSettingsSelectors.selectKernelBinsAmountMultiplier(contextChartId)
  );
  const kernelBandwidthCoefficient = useSelector(
    histogramSettingsSelectors.selectKernelBandwidthCoefficient(contextChartId)
  );

  const singleChartLink = useMemo(() => generateChartLink(chartData, true), [chartData]);

  const chartLane = useSelector(experimentSelectors.selectLane(chartData.scanId, chartData.laneId));

  const cageLevelAxesOptionList = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionList(chartLane));

  const objectLevelAxesOptionList = useMemo(
    () => getScatterPlotObjectLevelAxesOptions(chartLane?.channels ?? []),
    [chartData, chartLane]
  );

  const scatterPlotAxesOptions = useMemo(
    () => (isObjectEntityEnabled ? objectLevelAxesOptionList : cageLevelAxesOptionList),
    [cageLevelAxesOptionList, objectLevelAxesOptionList, isObjectEntityEnabled]
  );

  const chartId = useMemo(() => `chart_${plotId ?? chartData.id}`, [chartData, plotId]);

  const xAxisStored = useSelector(scatterplotsSelectors.selectXAxis());
  const [xAxis, setXAxis] = useState('');
  const [xAxisSaved, setXAxisSaved] = useState('');
  const xAxisLabel = useMemo(
    () => getOption(scatterPlotAxesOptions, xAxis)?.label ?? '',
    [scatterPlotAxesOptions, xAxis]
  );

  const yAxisStored = useSelector(scatterplotsSelectors.selectYAxis());
  const [yAxis, setYAxis] = useState('');
  const [yAxisSaved, setYAxisSaved] = useState('');
  const yAxisLabel = useMemo(
    () => getOption(scatterPlotAxesOptions, yAxis)?.label ?? '',
    [scatterPlotAxesOptions, yAxis]
  );

  const isAxesChanged = useMemo(
    () => xAxis !== xAxisSaved || yAxis !== yAxisSaved,
    [xAxis, xAxisSaved, yAxis, yAxisSaved]
  );

  const allOrigDataRanges = useSelector(chartDataSelectors.selectAllOrigDataRanges);
  const origDataRange = useMemo(
    () =>
      getOrigDataRange({
        allOrigDataRanges,
        scanId: chartData.scanId,
        laneId: chartData.laneId,
        xAxis,
        yAxis,
      }),
    [allOrigDataRanges, chartData, xAxis, yAxis]
  );

  const { data: chartEntityList, isError: isChartEntityListError } = useSelector(
    chartDataSelectors.selectChartEntityListData(chartLane?.dataset.path)
  );

  const handlePlotlyResize = () => {
    Plotly.Plots.resize(chartId);
  };

  const {
    flipBlockRef,
    flipBlockClassName,
    flipBlockBackgroundClassName,
    toggleFullScreen,
    isExpandMode,
    isTransitionEnd: isFullScreenTransitionEnd,
  } = useFlipBlock(handlePlotlyResize);

  const { filteredCoordinatesDebounce, isAllDataLoaded, plotRangeDebounce } = useScatterPlotDebouncedData({
    filteredCoordinates,
    graphRef,
    isFullScreenTransitionEnd,
    isTransitionEnd,
    plotRange,
  });

  const isChangeAxesAllowed = useMemo(() => isExpandMode && !isHideAxesInfo, [isExpandMode, isHideAxesInfo]);

  const isMinimized = useMemo(() => chartColumns >= LIMIT_BY_COLUMN && !isExpandMode, [chartColumns, isExpandMode]);

  const { getLayoutConfig } = usePlotSettings({
    graphRef,
    isPlotLoaded,
    customXAxis: xAxis,
    customYAxis: yAxis,
    plotRangeName,
  });

  useHistogramPlotsSettings({
    graphRef,
    defaultChartConfigs: { isTickLabelsVisible },
    isPlotLoaded,
    customXAxis: xAxis,
    origDataRange,
  });

  const changeLocalRange = (plot: IPlotlyHTMLDivElement) => {
    if (!plot?.layout) return;

    const { xaxis, yaxis } = plot.layout;

    setLocalPlotRange({
      x: xaxis.range,
      y: yaxis.range,
    });
  };

  const handleXAxisChange = (newXAxis: string) => {
    setXAxis(newXAxis);
  };

  const handleYAxisChange = (newYAxis: string) => {
    setYAxis(newYAxis);
  };

  useEffect(() => {
    if (xAxisSpecific) {
      setXAxis(xAxisSpecific);
    } else if (chartData.channelId) {
      setXAxis(`${chartData.channelId}_mean`);
    } else if (isObjectEntityEnabled || !scatterPlotAxesOptions[0]) {
      setXAxis(AXES_DEFAULT_PRESET.xAxis);
    } else if (isBasicOption(scatterPlotAxesOptions[0])) {
      setXAxis(scatterPlotAxesOptions[0].value as string);
    }
  }, [xAxisSpecific, chartData.channelId, isObjectEntityEnabled, scatterPlotAxesOptions]);

  useEffect(() => {
    if (xAxisSpecific) {
      return;
    }

    // This is triggered when yAxisStored is changed (the setting applies to all datasets)
    const opt = getOption(scatterPlotAxesOptions, xAxisStored, isEqualAxes);
    if (opt && isBasicOption(opt)) {
      setXAxis(opt.value as string);
    }
  }, [xAxisStored, xAxisSpecific, scatterPlotAxesOptions]);

  useEffect(() => {
    if (yAxisSpecific) {
      setYAxis(yAxisSpecific);
    } else if (chartData.channelId) {
      setYAxis(isObjectEntityEnabled ? 'object_area_px' : `${chartData.channelId}_bg_mean`);
    } else if (isObjectEntityEnabled || !scatterPlotAxesOptions[1]) {
      setYAxis(AXES_DEFAULT_PRESET.yAxis);
    } else if (isBasicOption(scatterPlotAxesOptions[1])) {
      setYAxis(scatterPlotAxesOptions[1].value as string);
    }
  }, [yAxisSpecific, chartData.channelId, isObjectEntityEnabled, scatterPlotAxesOptions]);

  useEffect(() => {
    if (yAxisSpecific) {
      return;
    }
    // This is triggered when yAxisStored is changed (the setting applies to all datasets)
    const opt = getOption(scatterPlotAxesOptions, yAxisStored, isEqualAxes);
    if (opt && isBasicOption(opt)) {
      setYAxis(yAxisStored);
    }
  }, [yAxisStored, yAxisSpecific, scatterPlotAxesOptions]);

  useEffect(() => {
    // ?? preprocessing calculate range before rendering plots
    if (isPreprocessingView) {
      return;
    }
    const range = axisScaleHelper.getPlotRange({
      xAxisScaleType,
      yAxisScaleType,
      x: coordinates?.x,
      y: coordinates?.y,
    });
    appDispatch(chartSettingsActions.setPlotRange({ range, rangeName: plotRangeName }));
  }, [coordinates?.x, coordinates?.y]);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
    };
  }, []);

  const entitiesDataByGates = useMemo(
    () => chartLane && entitiesByLanesAndGates[chartLane.path],
    [chartLane, entitiesByLanesAndGates]
  );

  useGatesLabelSettings({ entitiesDataByGates });

  const { defineGatesLayoutParameters } = useGatesOnPlot({
    graphRef,
    scanId: chartData.scanId,
    laneId: chartData.laneId,
    selectedTool: null,
    entitiesDataByGates,
    plotId: chartId,
    isStatic: true,
    chartClassName: STATIC_CHART_SELECTOR,
    isAllPlotDataLoaded: isAllDataLoaded,
    displayType: isMinimized ? EGateLabelType.minimized : EGateLabelType.default,
    entityLevelGateList,
    isObjectEntityEnabled,
    gates: fullGateList,
    updateGate,
    createGate,
    chartXAxis: xAxis,
    chartYAxis: yAxis,
    axesRange: localPlotRange,
    withoutGateLabel,
    hideGates,
    isEmptyData,
    defaultPlotRange: plotRange,
  });

  const isHistogram = useMemo(() => isHistogramChartType(currentChartType), [currentChartType]);

  const getAxisTitleOnPlotly = useCallback(
    (axisLabel: string, isYAxis = false) => {
      if (isYAxis && isHistogram) {
        return null;
      }
      if (!isChangeAxesAllowed && axisLabel) {
        return axisLabel;
      }
      return null;
    },
    [isChangeAxesAllowed, isHistogram]
  );

  const handleCancelClick = () => {
    setXAxis(xAxisSaved);
    setYAxis(yAxisSaved);
    toggleFullScreen();
  };

  const handleSaveClick = () => {
    toggleFullScreen();
    if (!shouldApplyToAllDatasets) {
      return;
    }
    appDispatch(
      scatterplotsActions.setAxes({
        newAxes: { x: xAxis, y: yAxis },
      })
    );
  };

  const toggleShouldApplyToAllDatasets = () => {
    setShouldApplyToAllDatasets((prev) => !prev);
  };

  const handleCardClick = () => {
    if (onPlotClickCustom) {
      onPlotClickCustom(chartData.id);
    } else if (!isExpandMode && !disableFullScreen) {
      setXAxisSaved(xAxis);
      setYAxisSaved(yAxis);
      setShouldApplyToAllDatasets(false);
      toggleFullScreen();
    }
  };

  useEffect(() => {
    if (!chartEntityList || !xAxis || !yAxis) {
      return;
    }
    const {
      coordinates: { x, y },
      cagesDataByCoordinates,
    } = getCoordinatesByAxesAndGate({
      entityList: chartEntityList,
      xAxis,
      yAxis,
    });
    setIsEmptyData(!x.length && !y.length);
    setCustomData(cagesDataByCoordinates);
    setCoordinates({ x, y });
  }, [chartEntityList, xAxis, yAxis]);

  useEffect(() => {
    if (!coordinates) {
      return;
    }
    if (selectedGate) {
      const filterCoordinatesDataPayload = {
        coordinates,
        xAxis,
        yAxis,
        gate: selectedGate,
        gates: entityLevelGateList,
        cagesDataByCoordinates: customData,
        scanId: chartData.scanId,
        laneId: chartData.laneId,
      };
      const { coordinates: filteredByGateCoordinates } = filterCoordinatesDataByGate(filterCoordinatesDataPayload);
      setFilteredCoordinates(filteredByGateCoordinates);
    } else {
      setFilteredCoordinates(coordinates);
    }
  }, [coordinates, selectedGate]);

  useEffect(() => {
    if (!graphRef.current || !filteredCoordinates || isEmptyData) {
      return;
    }
    // plotlyProxy.react has already been executed. It can be executed only once
    if (graphRef.current.data?.length && graphRef.current.layout) {
      return;
    }

    const { dataConfig, densityBandWidth } = prepareDataConfig({
      coordinates: filteredCoordinates,
      xAxisScaleType,
      yAxisScaleType,
      currentChartType,
      currentColorScale,
      customdata: customData,
      isChartFillEnabled,
      isStackedAndFilledEnabled,
      customHistogramBinsAmount,
      currentHistogramDataGroupType,
      kernelBandwidthCoefficient,
      kernelBinsAmountMultiplier,
      selectedGate,
      origDataRange,
    });

    const bandWidthToUpdate = {
      custom: { ...densityBandWidth },
      default: { ...densityBandWidth },
    };

    appDispatch(scatterplotsActions.setDensityBandWidth(bandWidthToUpdate));

    const range =
      plotRange ??
      axisScaleHelper.getPlotRange({
        xAxisScaleType,
        yAxisScaleType,
        x: filteredCoordinates.x,
        y: filteredCoordinates.y,
      });

    const layoutConfig = getLayoutConfig(1, false, range);
    const shapesLayout = getShapesUpdatedLayout(graphRef.current.layout, isMinimized);

    layoutConfig.xaxis.title = getAxisTitleOnPlotly(xAxisLabel) ?? '';
    layoutConfig.yaxis.title = getAxisTitleOnPlotly(yAxisLabel, true) ?? '';
    plotlyProxy.react([dataConfig], { ...layoutConfig, ...shapesLayout }, CONFIG(), (plot) => {
      changeLocalRange(plot);
      defineGatesLayoutParameters();
      setIsPlotLoaded(true);
    });
  }, [filteredCoordinates, plotlyProxy.id]);

  useEffect(() => {
    if (!graphRef.current || !filteredCoordinates) {
      return;
    }

    // plotlyProxy.react has not been executed yet
    if (!graphRef.current.data?.length || !graphRef.current.layout) {
      return;
    }

    const range =
      plotRange ??
      axisScaleHelper.getPlotRange({
        xAxisScaleType,
        yAxisScaleType,
        x: filteredCoordinates.x,
        y: filteredCoordinates.y,
      });

    const dataConfig = graphRef.current.data[0];

    const updatedDataConfig = {
      ...dataConfig,
      x: [filteredCoordinates.x],
      y: [filteredCoordinates.y],
    };

    if (dataConfig.marker && !isHistogram) {
      const { colorsByDensity } = calculateDensity(
        filteredCoordinates.x,
        filteredCoordinates.y,
        dataConfig.marker.colorscale
      );
      updatedDataConfig.marker.color = colorsByDensity;
      updatedDataConfig.marker.size = new Array(filteredCoordinates.x.length).fill(EMarkerSizes.default);
    }

    const layoutConfig = parseJSON(JSON.stringify(graphRef.current.layout));

    const shapesLayout = getShapesUpdatedLayout(graphRef.current.layout, isMinimized);

    if (layoutConfig && range) {
      layoutConfig.xaxis = {
        ...layoutConfig.xaxis,
        range: [range.xMin, range.xMax],
      };

      layoutConfig.yaxis = {
        ...layoutConfig.yaxis,
        range: [range.yMin, range.yMax],
      };
    }

    plotlyProxy.update(updatedDataConfig, { ...layoutConfig, ...shapesLayout }, null, (plot: IPlotlyHTMLDivElement) => {
      changeLocalRange(plot);
      defineGatesLayoutParameters();
    });
  }, [filteredCoordinatesDebounce, plotRangeDebounce, plotlyProxy.id]);

  useEffect(() => {
    const updatedLayout = getShapesUpdatedLayout(graphRef.current?.layout, isMinimized);
    plotlyProxy.relayout(updatedLayout, () => {
      defineGatesLayoutParameters();
    });
  }, [plotlyProxy.id]);

  useEffect(() => {
    const updatedLayoutConfig = getAxesTitlesUpdatedLayout(
      getAxisTitleOnPlotly(xAxisLabel),
      getAxisTitleOnPlotly(yAxisLabel, true)
    );
    plotlyProxy.relayout(updatedLayoutConfig);
  }, [getAxisTitleOnPlotly, xAxisLabel, yAxisLabel]);

  useEffect(() => {
    if (!handleIsEmptyData) {
      return;
    }
    handleIsEmptyData(isEmptyData);
  }, [isEmptyData]);

  useEffect(() => {
    onAxisChange(xAxis.toString(), yAxis.toString(), chartData.id);
  }, [xAxis, yAxis]);

  return (
    <div onClick={handleCardClick} className={cn('card-wrapper')} role="presentation" data-webgl-type={canvasWebglType}>
      <div className={flipBlockBackgroundClassName} />
      <div
        className={cn(
          'card',
          {
            card_selected: selectedChartData?.id === chartData.id,
            card_minimized: isMinimized,
          },
          cardClassName
        )}
      >
        <div ref={flipBlockRef} className={cn('card__flip-block', flipBlockClassName, customFlipBlockClassName)}>
          {customOfTopContent && <div className={cn('card__custom-oftop-content')}>{customOfTopContent}</div>}
          <div className={cn('card__header', cardHeaderClassName)}>
            <h2 className={cn('header__title')}>{chartLane?.sampleFriendlyName ?? ''}</h2>
            <span>{chartData.friendlyName}</span>
            <span>{scan?.timeNode}</span>
            {!isPreprocessingView && (
              <Button
                color="light"
                className={cn('card__small-button')}
                tooltip="View chart"
                onClick={() => navigate(singleChartLink)}
              >
                <icons.ChartViewIcon />
              </Button>
            )}
            {isExpandMode && (
              <div className={cn('card__buttons-container')}>
                {isAxesChanged && (
                  <CheckboxInput
                    checked={shouldApplyToAllDatasets}
                    onChange={toggleShouldApplyToAllDatasets}
                    theme="light"
                    label="Apply this change to other datasets"
                  />
                )}
                <Button color="white" className={cn('card__button')} onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button
                  color="yellow"
                  className={cn('card__button')}
                  onClick={handleSaveClick}
                  disabled={!isAxesChanged}
                >
                  Save
                </Button>
              </div>
            )}
          </div>

          <div className={cn('card__content', { 'card__content_full-screen': isExpandMode })}>
            {isAllDataLoaded && isChangeAxesAllowed && (
              <>
                {!isHistogram && (
                  <AxisSelect
                    scatterPlotAxesOptions={scatterPlotAxesOptions}
                    value={yAxis}
                    isXAxis={false}
                    onChange={handleYAxisChange}
                    className={cn('card__axis-select_y')}
                    onlyShowValue={isBlockAxesChanging}
                  />
                )}
                <AxisSelect
                  scatterPlotAxesOptions={scatterPlotAxesOptions}
                  value={xAxis}
                  isXAxis
                  onChange={handleXAxisChange}
                  className={cn('card__axis-select_x')}
                  onlyShowValue={isBlockAxesChanging}
                />
              </>
            )}
            <div className={cn('card__chart-element')}>
              {!isEmptyData && !isChartEntityListError
                ? !isAllDataLoaded && <Skeleton className={cn('card__skeleton')} />
                : !isEmptyData &&
                  !isAllDataLoaded && <NoDataFound className={cn('card__no-data')} alignment="center" size="normal" />}
              {isEmptyData && (
                <NoDataFound className={cn('card__no-data')} alignment="center" size="small" textData={noDataContent} />
              )}
              <div ref={graphRef} className={cn('card__chart')} id={chartId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DataScatterplotChart);
