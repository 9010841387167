import { FC, memo, useMemo, useCallback, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

import type { TCreateExperimentGatePayload } from '@/hooks/gates/useUpdateGates';

import { EAxesGroupName, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { gatesSelectors } from '@/store/slices/gates';

import UpdateGateNameModal from '@/components/common/UpdateGateNameModal';

import type {
  TCustomCreateGateComponent,
  TCustomUpdateGateComponent,
} from '@/components/charts/SingleChartWithGates/types';

import PopulationModal from '../../PopulationModal';

export type TChartModalsProps = {
  newGateModel: Nullable<TNewGateModelData>;
  handleCloseModal: () => void;
  updateGate: (gate: TGate, updatedGateData: Partial<TGate>, successCallback?: () => void | null) => unknown;
  createGate: (payload: TCreateExperimentGatePayload) => unknown;
  isGateUpdateModalOpened: boolean;
  isModalOpen: boolean;
  isUpdateGatesInProgress: boolean;
  isCreateGatesInProgress: boolean;
  mouseUpEventRef?: MutableRefObject<Nullable<MouseEvent>>;
  mouseDownEventRef?: MutableRefObject<Nullable<MouseEvent>>;
  CustomCreateGateComponent?: TCustomCreateGateComponent;
  CustomUpdateGateComponent?: TCustomUpdateGateComponent;
  specificAxesGroupName?: EAxesGroupName;
};

const ChartModals: FC<TChartModalsProps> = ({
  newGateModel,
  handleCloseModal,
  updateGate,
  createGate,
  isGateUpdateModalOpened,
  isModalOpen,
  isUpdateGatesInProgress,
  isCreateGatesInProgress,
  mouseUpEventRef,
  mouseDownEventRef,
  CustomCreateGateComponent,
  CustomUpdateGateComponent,
  specificAxesGroupName,
}) => {
  const activeGate = useSelector(gatesSelectors.selectActiveGate);
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));

  const closeModal = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const CreateNewGateComponent = useMemo(
    () => CustomCreateGateComponent ?? PopulationModal,
    [CustomCreateGateComponent, PopulationModal]
  );

  const UpdateGateComponent = useMemo(
    () => CustomUpdateGateComponent ?? UpdateGateNameModal,
    [CustomUpdateGateComponent, UpdateGateNameModal]
  );

  return (
    <>
      {activeGate && (
        <UpdateGateComponent
          isModalOpen={isGateUpdateModalOpened}
          closeModal={closeModal}
          gate={activeGate}
          updateGate={updateGate}
          isUpdateGatesInProgress={isUpdateGatesInProgress}
          mouseUpEventRef={mouseUpEventRef}
          mouseDownEventRef={mouseDownEventRef}
        />
      )}
      {newGateModel && (
        <CreateNewGateComponent
          createGate={createGate}
          isCreateGatesInProgress={isCreateGatesInProgress}
          isModalOpen={isModalOpen}
          newGateModel={newGateModel}
          xDimension={xAxis}
          yDimension={yAxis}
          closeModal={closeModal}
          selectedGate={selectedGate}
          mouseUpEventRef={mouseUpEventRef}
          mouseDownEventRef={mouseDownEventRef}
        />
      )}
    </>
  );
};

export default memo(ChartModals);
