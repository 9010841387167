import { FC } from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { addWordBreakAfterUnderscores, formatNumber, formatDate, formatExperimentDuration } from '@/helpers/common';
import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';

import Panel from '@/components/common/Panel';
import IconsAssaySet from '@/components/common/IconsAssaySet';
import NoDataFound from '@/components/common/NoDataFound';
import Metadata from '@/components/common/Metadata';
import StatusesInfo from '@/components/experiment/StatusesInfo';

import { useExperimentAssayList } from '../../hooks/useExperimentAssayList';

import styles from './ExperimentListItemCard.module.scss';

const cn = classnames.bind(styles);

type TExperimentListItem = {
  experiment: TExperiment;
  searchQuery?: string;
};

const ExperimentListItemCard: FC<TExperimentListItem> = ({ experiment, searchQuery = '' }) => {
  const experimentLink = `experiment/${experiment.experimentId}`;

  const { assayListWithActive } = useExperimentAssayList(experiment);

  return (
    <Link to={experimentLink} className={cn('experiment-list-item-card')}>
      <Panel theme={themeOptions.light} className={cn('experiment-list-item-card__panel')}>
        <Panel.Content className={cn('experiment-list-item-card__group_content', 'render-content-animation')}>
          <div className={cn('experiment-list-item-card__header')}>
            <h3 className={cn('experiment-list-item-card__title')}>
              <Highlighter
                textToHighlight={addWordBreakAfterUnderscores(experiment?.experimentName) ?? ''}
                searchWords={formatHighlightSearchQuery(addWordBreakAfterUnderscores(searchQuery))}
              />
            </h3>
            <div className={cn('experiment-list-item-card__icons-container')}>
              <IconsAssaySet assayList={assayListWithActive} border="default" withPreprocessingIcon />

              {experiment && <StatusesInfo experiment={experiment} />}
            </div>
          </div>

          <div className={cn('experiment-list-item-card__total-data')}>
            <div>
              <div>Cages total</div>
              <div className={cn('experiment-list-item-card__number')}>{formatNumber(experiment?.numberOfCages)}</div>
            </div>

            <div>
              <div>Samples total</div>
              <div className={cn('experiment-list-item-card__number')}>{formatNumber(experiment?.numberOfSamples)}</div>
            </div>
          </div>

          <Metadata className={cn('experiment-list-item-card__metadata')}>
            <Metadata.Item
              title="Operator"
              description={
                experiment?.operator ? (
                  <Highlighter
                    textToHighlight={experiment?.operator}
                    searchWords={formatHighlightSearchQuery(searchQuery)}
                  />
                ) : (
                  <NoDataFound alignment="left" />
                )
              }
              titleClassName={cn('text-color-light')}
            />
            <Metadata.Item
              title="Instrument name"
              description={
                experiment?.instrument?.friendlyName ? (
                  <Highlighter
                    textToHighlight={experiment.instrument.friendlyName}
                    searchWords={formatHighlightSearchQuery(searchQuery)}
                  />
                ) : (
                  <NoDataFound alignment="left" />
                )
              }
              titleClassName={cn('text-color-light')}
            />
            <Metadata.Item
              title="Date"
              description={formatDate(experiment?.dateCreated, 'MMMM d, yyyy') ?? <NoDataFound alignment="left" />}
              titleClassName={cn('text-color-light')}
            />
            <Metadata.Item
              title="Duration"
              description={formatExperimentDuration(experiment?.duration)}
              titleClassName={cn('text-color-light')}
            />
            <Metadata.Item
              title="Scans total"
              description={experiment?.numberOfScans ?? 0}
              titleClassName={cn('text-color-light')}
            />
          </Metadata>
        </Panel.Content>
      </Panel>
    </Link>
  );
};

export default ExperimentListItemCard;
