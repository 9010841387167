import { FC, useEffect, useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import { addTooltip, formatDate, NDASH } from '@/helpers';

type TDateDistanceProps = {
  date?: string;
};

const formatDistance = (date?: string) => (date ? formatDistanceToNowStrict(new Date(date), { addSuffix: true }) : '');

const getIntervalDelay = (date?: string) => {
  const diff = date ? new Date().getTime() - new Date(date).getTime() : 0;
  return diff > 60000 ? 60000 : 1000;
};

const DateDistance: FC<TDateDistanceProps> = ({ date }) => {
  const [formattedDistance, setFormattedDistance] = useState(formatDistance(date));
  const [intervalDelay, setIntervalDelay] = useState(getIntervalDelay(date));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedDistance(formatDistance(date));
      setIntervalDelay(getIntervalDelay(date));
    }, intervalDelay);
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalDelay]);

  return (
    <>
      {!!date && (
        <time {...addTooltip(formatDate(date, 'MMMM d, yyyy, p'))} dateTime={formatDate(date, 'MMMM d, yyyy, p')}>
          {formattedDistance}
        </time>
      )}
      {!date && { NDASH }}
    </>
  );
};
export default DateDistance;
