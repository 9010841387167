import { useState, useMemo, FC, useCallback, useEffect, useRef, memo } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useDebounce } from '@/hooks';

import { appAPI } from '@/store/services/app';
import { ESequencingDataRequestDataType } from '@/store/services/app/types';

import Select from '@/components/common/Select';
import { getNoOptionsMessage, isCompoundOption, isOption } from '@/components/common/Select/helpers';

import styles from '../../../SequencingData.module.scss';

const cn = classnames.bind(styles);

type TReadSelectProps = {
  selectedValue: string;
  setSelectedValue: (value: string, valueIndex: number) => void;
  placeholder: string;
  selectClassName?: string;
  valueIndex: number;
  isR1Select: boolean;
};

const ReadSelect: FC<TReadSelectProps> = ({
  selectedValue,
  setSelectedValue,
  placeholder,
  selectClassName = '',
  valueIndex,
  isR1Select,
}) => {
  const selectRef = useRef<Nullable<HTMLDivElement>>(null);

  const [inputValue, setInputValue] = useState(selectedValue);
  const debouncedSequencingData = useDebounce(inputValue, 250);

  const {
    data: sequencingDataV2,
    isFetching: isSequencingDataV2Fetching,
    isError: isSequencingDataV2Error,
    error: sequencingDataV2Error,
  } = appAPI.useFetchSequencingDataListV2Query({
    searchString: debouncedSequencingData,
    dataType: ESequencingDataRequestDataType.fastq,
  });

  const sequencingDataList = useMemo(() => {
    const fullList = sequencingDataV2?.directoryList ?? [];
    const list: string[] = [];
    fullList.forEach((readValue) => {
      const readValueUpperCase = readValue.toUpperCase();
      const isR1 = readValueUpperCase.includes('R1');
      const isR2 = readValueUpperCase.includes('R2');
      if (isR1 && isR1Select) {
        list.push(readValue);
      }
      if (isR2 && !isR1Select) {
        list.push(readValue);
      }
      if (!isR1 && !isR2) {
        list.push(readValue);
      }
    });
    return list;
  }, [sequencingDataV2, isR1Select]);

  const isSelectOptionsLoading = useMemo(
    () => isSequencingDataV2Fetching && !sequencingDataList?.includes(debouncedSequencingData),
    [isSequencingDataV2Fetching, sequencingDataList, debouncedSequencingData]
  );

  const sequencingDataOptionList = useMemo<TOption[]>(
    () => sequencingDataList.map((dataValue: string) => ({ label: dataValue, value: dataValue })),
    [sequencingDataList]
  );

  const noOptionsMessage = (obj: { inputValue: string }) => <>{getNoOptionsMessage(obj)}</>;

  const handleSequencingDataR1Change = useCallback((option: TOption) => {
    if (isOption(option) && isCompoundOption(option)) {
      return;
    }
    const newSelectedValue = isOption(option) ? String(option?.value) : '';
    setSelectedValue(newSelectedValue, valueIndex);
    setInputValue(newSelectedValue);
  }, []);

  useEffect(() => {
    if (isSequencingDataV2Error) {
      showErrorToast(getErrorMessage(sequencingDataV2Error));
    }
  }, [isSequencingDataV2Error, sequencingDataV2Error]);

  useEffect(() => {
    setInputValue(selectedValue);
  }, [selectedValue]);

  return (
    <Select
      placeholder={placeholder}
      isLoading={isSelectOptionsLoading}
      innerRef={selectRef}
      value={selectedValue}
      isEditable
      isMultiLine
      isClearable
      inputValue={inputValue}
      setInputValue={setInputValue}
      className={cn('sequencing-data__selector', selectClassName)}
      menuListClassName={cn('sequencing-data__selector-menu-list')}
      controlClassName={cn('sequencing-data__selector-control')}
      theme={themeOptions.light}
      onChange={handleSequencingDataR1Change}
      options={sequencingDataOptionList}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default memo(ReadSelect);
