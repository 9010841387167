import addTexture from '@/hooks/useWebgl/helpers/addTexture';

export function changeTextures(
  gl: WebGLRenderingContext,
  textures: Nullable<WebGLTexture>[],
  imageDataList: ImageData[] | HTMLImageElement[] | HTMLCanvasElement[]
) {
  if (!gl) {
    return [];
  }
  textures.forEach((texture) => gl.deleteTexture(texture));

  gl.activeTexture(gl.TEXTURE1);
  const newTextures = imageDataList.map((texture) => addTexture(gl, texture));

  for (let i = 0; i < newTextures.length; i++) {
    gl.activeTexture(gl.TEXTURE1 + i);
    gl.bindTexture(gl.TEXTURE_2D, newTextures[i]);
  }

  return newTextures;
}
