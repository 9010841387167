import { FC } from 'react';
import classNames from 'classnames/bind';
import { isKeyOf } from '@/helpers';
import styles from './SearchResults.module.scss';

const cn = classNames.bind(styles);

type TReagentSearchResultsRowProps = {
  result: any;
};

export const CellNameSearchResultsHeading = () => (
  <>
    <div className={cn('search-results__table-cell')}>Cell name</div>
    {/* <div className={cn('search-results__table-cell')}>Disease</div> */}
    <div className={cn('search-results__table-cell')}>Tissue</div>
    <div className={cn('search-results__table-cell')}>Growth Property</div>
    {/* <div className={cn('search-results__table-cell')}>Reporter</div> */}
    {/* <div className={cn('search-results__table-cell')}>Gene Modifier</div> */}
  </>
);

export const CellNameSearchResultsRow: FC<TReagentSearchResultsRowProps> = ({ result }) => (
  <>
    <div className={cn('search-results__table-cell')}>
      <div className={cn('search-results__text')}>{result.name}</div>
    </div>
    {/* <div className={cn('search-results__table-cell')}> */}
    {/*  {'disease' in result && <div className={cn('search-results__text')}>{result.disease}</div>} */}
    {/* </div> */}
    <div className={cn('search-results__table-cell')}>
      {isKeyOf('tissue', result) && <div className={cn('search-results__text')}>{result.tissue}</div>}
    </div>
    <div className={cn('search-results__table-cell')}>
      {isKeyOf('growthProperties', result) && (
        <div className={cn('search-results__text')}>{result.growthProperties}</div>
      )}
    </div>
    {/* <div className={cn('search-results__table-cell')}> */}
    {/*  {'reporter' in result && <div className={cn('search-results__text')}>{result.reporter}</div>} */}
    {/* </div> */}
    {/* <div className={cn('search-results__table-cell')}> */}
    {/*  {'geneModifier' in result && <div className={cn('search-results__text')}>{result.geneModifier}</div>} */}
    {/* </div> */}
  </>
);

// export const cellNameSearchResultsGridLayout = '1fr 104px 180px 97px 88px 75px';
export const cellNameSearchResultsGridLayout = '1fr 180px 150px';

export const cellNameSearchResultInformationList = [
  'cellLineOrPrimary',
  'cellType',
  'morphology',
  'organism',
  'phenotype',
  'type',
  'url',
  'vendor',
];
