import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { downloadFileSchema, experimentSchema } from '@/validationSchemas';

import { removeTrailingSlash } from '@/helpers';

export const transformExperimentListResponse = (experimentFromServers: TExperimentFromServer[]): TExperiment[] =>
  experimentFromServers.filter((experimentFromServer) => {
    if (!('id' in experimentFromServer)) {
      console.warn('The next experiment does not have an ID and will not appear in the list', experimentFromServer);
    }
    return experimentFromServer.id;
  });

export const transformExperimentResponse = (experimentFromServer: TExperimentFromServer): TExperiment => {
  const experimentFromServerChecked = experimentSchema.safeParse(experimentFromServer);

  if (!experimentFromServerChecked.success) {
    console.error(experimentFromServerChecked.error);
  }

  return {
    ...experimentFromServer,
    cloudPath: removeTrailingSlash(experimentFromServer.cloudPath),
    cloudSequencingDataPath: removeTrailingSlash(experimentFromServer.cloudSequencingDataPath),
  };
};

export const transformExperimentDownloadsResponse = (
  downloadFileFromServerList: z.infer<typeof downloadFileSchema>[]
): TDownloadFile[] =>
  downloadFileFromServerList
    .filter((downloadFileFromServer) => {
      const downloadFileCheckResult = downloadFileSchema.safeParse(downloadFileFromServer);
      if (!downloadFileCheckResult.success) {
        console.error(downloadFileCheckResult?.error);
      }
      return downloadFileCheckResult.success;
    })
    .map((downloadFileFromServer) => ({ ...downloadFileFromServer, uuid: uuidv4() }));
