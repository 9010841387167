import { SVGProps } from 'react';

const AssayCytokineSecretion = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" version="1.1" viewBox="0 0 130 130" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round">
      <path d="M95 62c-1.7-18-16.7-32-34.9-32C40.7 30 25 45.9 25 65.5S40.7 101 60.1 101c5.5 0 10.7-1.3 15.4-3.6" />
      <circle cx="72.1" cy="72.9" r="5" />
      <circle cx="87.9" cy="72.9" r="5" />
      <circle cx="103.7" cy="72.9" r="5" />
      <circle cx="95.3" cy="85.9" r="5" />
      <circle cx="79.5" cy="85.9" r="5" />
    </g>
  </svg>
);

export default AssayCytokineSecretion;
