import { ComponentType, FC, MouseEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import VirtualScroll from 'react-dynamic-virtual-scroll';
import { Popover as TinyPopover } from 'react-tiny-popover';
import classnames from 'classnames/bind';

import type { CSSProperty } from '@/helpers/interfaces';

import styles from './VirtualSelect.module.scss';
import SelectInput from './SelectInput';

const cn = classnames.bind(styles);

type TItemProps = {
  option: TBasicOption;
  optionIndex: number;
  isSelected: boolean;
  className?: string;
};

type TVirtualSelect = {
  onSelect: (option: TBasicOption, optionIndex: number) => void;
  options: TBasicOption[];
  minItemWidth?: number;
  itemsToRenderByScroll?: number;
  CustomItemComponent?: ComponentType<TItemProps>;
  defaultValue?: string;
  value?: string;
  inputClassName?: string;
  placeholder?: string;
};

const DefaultItemComponent: ComponentType<TItemProps> = ({ option, optionIndex, isSelected, className }) => (
  <div className={cn('item', { item_selected: isSelected }, className)} data-option-index={optionIndex}>
    <span>{option.label}</span>
  </div>
);

const MemoDefaultItemComponent = memo(DefaultItemComponent);

const VirtualSelect: FC<TVirtualSelect> = ({
  options,
  onSelect,
  minItemWidth = 60,
  itemsToRenderByScroll = 10,
  CustomItemComponent,
  value,
  defaultValue,
  inputClassName,
  placeholder,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [searchString, setSearchString] = useState(defaultValue ?? '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState<Nullable<TBasicOption>>(null);

  const selectInputRef = useRef<HTMLDivElement>(null);

  const totalLength = useMemo(() => filteredOptions.length, [filteredOptions.length]);

  const renderItem = useCallback<(itemIndex: number) => JSX.Element | null>(
    (itemIndex) => {
      const itemToRender = filteredOptions[itemIndex];
      if (!itemToRender) {
        return null;
      }

      const onClickItem = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setSelectedOption(itemToRender);
        setSearchString(itemToRender.label);
        onSelect(itemToRender, itemIndex);
        setIsOpened(false);
      };

      const Component = CustomItemComponent ?? MemoDefaultItemComponent;

      const widthValue = selectInputRef.current?.clientWidth ?? 350;
      const itemStyle: CSSProperty = {
        '--width': `${widthValue}px`,
      };

      return (
        <button onClick={onClickItem} className={cn('item__btn')} aria-label={itemToRender.label} style={itemStyle}>
          <Component
            option={itemToRender}
            optionIndex={itemIndex}
            isSelected={itemToRender.value === selectedOption?.value}
          />
        </button>
      );
    },
    [filteredOptions, onSelect, CustomItemComponent, selectedOption]
  );

  const onChange = useCallback(
    (searchStr: string) => {
      if (!searchStr) {
        setSelectedOption(null);
      }
      setSearchString(searchStr);
    },
    [options]
  );

  useEffect(() => {
    setFilteredOptions(
      options.filter((el) => {
        const label: string = el.label ?? '';
        return label.toLowerCase().includes(searchString.toLowerCase());
      })
    );
  }, [options, searchString]);

  useEffect(() => {
    const option = options.find((el) => el.value === value);
    setSelectedOption(option ?? null);
    setSearchString(option?.label ?? '');
  }, [value]);

  useEffect(() => {
    const handleOutsideClick = () => {
      setIsOpened(false);
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const onBlockClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <TinyPopover
      isOpen={isOpened}
      containerClassName={cn('popover__container')}
      positions={['bottom', 'top']}
      clickOutsideCapture
      content={
        <div className={cn('scroll-area__wrapper')} role="presentation" onClick={onBlockClick}>
          <VirtualScroll
            className={cn('virtual-scroll__component')}
            minItemHeight={minItemWidth}
            totalLength={totalLength}
            length={itemsToRenderByScroll}
            renderItem={renderItem}
          />
        </div>
      }
    >
      <div>
        <SelectInput
          onChange={onChange}
          handleOpened={setIsOpened}
          blockRef={selectInputRef}
          defaultValue={searchString}
          value={selectedOption?.label}
          className={inputClassName}
          isOpened={isOpened}
          placeholder={placeholder}
        />
      </div>
    </TinyPopover>
  );
};

export default memo(VirtualSelect);
