import { createSelector } from '@reduxjs/toolkit';

import { type RootState } from '@/store';
import { EAxesScaleType, EPageWithChartType } from '@/types/charts';
import { isEqualAxes } from '@/helpers/channels';
import { isBasicOption, isCompoundOption } from '@/components/common/Select/helpers';
import { selectCurrentChartData } from '@/store/slices/chartData/selectors';

import {
  defineAllOptionsData,
  getStateValue,
  isLogScaleTypeAllowedForXAxis,
  isLogScaleTypeAllowedForYAxis,
} from './helpers';
import { EPlotRangeType, TCustomRange } from './types';

export const selectCurrentChartType = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'currentChartType', datasetId);

export const selectDatasetsState = (storeState: RootState) => storeState.datasets;

// todo: allow log scale in preprocessing
export const selectIsLogAxesScaleTypeAllowed = (datasetId?: Nullable<string>) =>
  createSelector(
    [selectCurrentChartType(datasetId), selectDatasetsState],
    (currentChartType, datasetsStore) =>
      !datasetsStore.isPreprocessingView &&
      (isLogScaleTypeAllowedForXAxis(currentChartType) || isLogScaleTypeAllowedForYAxis(currentChartType))
  );

export const selectIsLogScaleTypeForXAxisAllowed = (datasetId?: Nullable<string>) =>
  createSelector([selectCurrentChartType(datasetId)], (currentChartType) =>
    isLogScaleTypeAllowedForXAxis(currentChartType)
  );

export const selectIsLogScaleTypeForYAxisAllowed = (datasetId?: Nullable<string>) =>
  createSelector([selectCurrentChartType(datasetId)], (currentChartType) =>
    isLogScaleTypeAllowedForYAxis(currentChartType)
  );

export const selectAxesScaleTypes = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'axesScaleTypes', datasetId);

export const selectCurrentScalesTypeForAxes = (datasetId?: Nullable<string>) =>
  createSelector(
    [selectCurrentChartType(datasetId), selectAxesScaleTypes(datasetId)],
    (currentChartType, axesScaleTypes) => {
      const isLogScaleAllowedForXAxis = isLogScaleTypeAllowedForXAxis(currentChartType);
      const isLogScaleAllowedForYAxis = isLogScaleTypeAllowedForYAxis(currentChartType);

      return {
        xAxisScaleType: isLogScaleAllowedForXAxis ? axesScaleTypes.x : EAxesScaleType.linear,
        yAxisScaleType: isLogScaleAllowedForYAxis ? axesScaleTypes.y : EAxesScaleType.linear,
      };
    }
  );

export const selectCurrentColorScale = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'currentColorScale', datasetId);

export const selectCageLevelAxesOptionListByLanes = (state: RootState) =>
  state.chartSettings.cageLevelAxesOptionListByLanes;

export const selectObjectLevelAxesOptionListByLanes = (state: RootState) =>
  state.chartSettings.objectLevelAxesOptionListByLanes;

export const selectCageLevelAxesOptionList = (lane?: Nullable<TLane>) =>
  createSelector([selectCageLevelAxesOptionListByLanes], (cageLevelAxesOptionListByLanes) =>
    lane ? cageLevelAxesOptionListByLanes[lane.dataset.name] ?? [] : []
  );

export const selectMultiLanesAxesOptionList = (datasetDetailsList: TDatasetDetails[], isObjectLevel = false) =>
  createSelector(
    [selectCageLevelAxesOptionListByLanes, selectObjectLevelAxesOptionListByLanes, selectCurrentChartData],
    (cageLevelAxesOptionListByLanes, objectLevelAxesOptionListByLanes, currentChartData) => {
      const optionList: TOption[] = [];
      const optionMapping: Record<string, Record<string, string>> = {};

      if (datasetDetailsList.length === 0) {
        return { optionList, optionMapping, generalChannelOptionMap: {}, allOptionList: [], allOptionMapping: {} };
      }

      const datasetsOptionLists: Record<string, TOption[]> = {};

      datasetDetailsList.forEach((datasetDetails) => {
        const options = isObjectLevel
          ? objectLevelAxesOptionListByLanes[datasetDetails.dataset.name]
          : cageLevelAxesOptionListByLanes[datasetDetails.dataset.name];

        datasetsOptionLists[datasetDetails.dataset.name] = options;
      });

      if (Object.values(datasetsOptionLists).some((el) => !el)) {
        return { optionList, optionMapping, generalChannelOptionMap: {}, allOptionList: [], allOptionMapping: {} };
      }

      const datasetOptionObj = datasetDetailsList.reduce((acc, datasetDetails) => {
        acc[datasetDetails.dataset.name] = isObjectLevel
          ? objectLevelAxesOptionListByLanes[datasetDetails.dataset.name]
          : cageLevelAxesOptionListByLanes[datasetDetails.dataset.name];
        return acc;
      }, {} as Record<string, TOption[]>);
      const { allOptionList, allOptionMapping, generalChannelOptionMap } = defineAllOptionsData(datasetOptionObj);

      const datasetKey = currentChartData?.dataset.name ?? Object.keys(datasetsOptionLists)[0];
      const currentDatasetOption = datasetsOptionLists[datasetKey];
      currentDatasetOption?.forEach((firstOption) => {
        if (isCompoundOption(firstOption)) {
          return;
        }

        const invalidOptions = Object.values(datasetsOptionLists)?.map((datasetOptionList) =>
          datasetOptionList?.find(
            (datasetOption) =>
              isBasicOption(datasetOption) && isEqualAxes(String(datasetOption.value), String(firstOption.value))
          )
        );

        const sameOptions = invalidOptions.filter((optionData) => optionData) ?? [];

        if (sameOptions.length === Object.keys(datasetsOptionLists).length) {
          const optionMap: Record<string, string> = {};
          sameOptions.forEach((val, index) => {
            const newValue = val && isBasicOption(val) ? val.value : firstOption.value;
            optionMap[datasetDetailsList[index].dataset.name] = String(newValue);
          });
          optionMapping[firstOption.value] = optionMap;
          optionList.push(firstOption);
        }
      });

      return {
        optionList,
        optionMapping,
        generalChannelOptionMap,
        allOptionList,
        allOptionMapping,
      };
    }
  );

export const selectIsObjectEntityEnabled = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'isObjectEntityEnabled', datasetId);

export const selectIsCageLvlForced = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'isCageLvlForced', datasetId);

export const selectSelectedCustomRange =
  (datasetId?: Nullable<string>) =>
  (state: RootState): Nullable<TCustomRange> => {
    const specificDatasetSettings = datasetId ? state.chartSettings.specificDatasetOptionMap?.[datasetId] : null;
    const customRangesMap = specificDatasetSettings?.customRangesMap ?? state.chartSettings.customRangesMap;
    const customRangesMapName = specificDatasetSettings?.customRangesMapName ?? state.chartSettings.customRangesMapName;

    return customRangesMap?.[customRangesMapName] ?? null;
  };

export const selectCustomRangesMap = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'customRangesMap', datasetId);

export const selectCustomRangesMapName = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'customRangesMapName', datasetId);

export const selectPlotRangesMap = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'plotRangesMap', datasetId);

export const selectLastPlotRangeName = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'lastPlotRangeName', datasetId);

export const selectLastPlotRange = (datasetId?: Nullable<string>) =>
  createSelector(
    [selectPlotRangesMap(datasetId), selectLastPlotRangeName(datasetId)],
    (plotRangesMap, lastPlotRangeName) => {
      const rangeName = lastPlotRangeName || EPlotRangeType.general;
      return plotRangesMap[rangeName];
    }
  );

export const selectPlotRangesMapName = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'plotRangesMapName', datasetId);

export const selectPlotRangeFactory =
  (datasetId?: Nullable<string>) =>
  (name = '') =>
    createSelector(
      [selectPlotRangesMap(datasetId), selectPlotRangesMapName(datasetId), selectSelectedCustomRange(datasetId)],
      (plotRangesMap, plotRangesMapName, customPlotRange) => {
        const rangeName = name || plotRangesMapName || EPlotRangeType.general;
        return customPlotRange?.range ?? plotRangesMap[rangeName];
      }
    );

export const selectSpecificDatasetOptionMap = (storeState: RootState) =>
  storeState.chartSettings.specificDatasetOptionMap;

export const selectObjectType = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'objectType', datasetId);

export const selectFullScreenChartData = (storeState: RootState) => storeState.chartSettings.fullScreenChartData;

export const selectChartPresetSettingsСompletionStatusMap = (storeState: RootState) =>
  storeState.chartSettings.сhartPresetSettingsСompletionStatusMap;

export const selectChartPresetSettingsСompletionStatus = (key?: EPageWithChartType) =>
  createSelector([selectChartPresetSettingsСompletionStatusMap], (statuses) => {
    if (!key) return null;
    return statuses[key];
  });

export const selectIsTickLabelsVisible = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'isTickLabelsVisible', datasetId);

export const selectContourBandWidth = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.chartSettings, 'contourBandWidth', datasetId);

export const selectAllGlobalChartSettings = (storeState: RootState) => storeState.chartSettings;
