import { SVGProps } from 'react';

const ReagentsGridIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="13" height="14" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(0 .316)" fill="currentColor" fillRule="evenodd">
      <circle cx="1.5" cy="1.5" r="1.5" />
      <circle cx="1.5" cy="6.5" r="1.5" />
      <circle cx="1.5" cy="11.5" r="1.5" />
      <circle cx="6.5" cy="1.5" r="1.5" />
      <circle cx="6.5" cy="6.5" r="1.5" />
      <circle cx="6.5" cy="11.5" r="1.5" />
      <circle cx="11.5" cy="1.5" r="1.5" />
      <circle cx="11.5" cy="6.5" r="1.5" />
      <circle cx="11.5" cy="11.5" r="1.5" />
    </g>
  </svg>
);

export default ReagentsGridIcon;
