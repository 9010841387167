import { FC } from 'react';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

type TFullScreen = {
  isExpandView?: boolean;
  className?: string;
  changeFullScreen: () => void;
};

const FullScreen: FC<TFullScreen> = ({ className, isExpandView, changeFullScreen }) => {
  const handleChangeFullScreen = () => {
    changeFullScreen();
  };

  return (
    <Button
      tooltip={isExpandView ? 'Default view' : 'Expand view'}
      id="expand-screen"
      color="gray"
      className={className}
      onClick={handleChangeFullScreen}
    >
      {isExpandView ? <icons.CollapseIcon /> : <icons.ExpandScreenIcon />}
    </Button>
  );
};

export default FullScreen;
