export const getOrigDataRange = ({
  allOrigDataRanges,
  scanId,
  laneId,
  xAxis,
  yAxis,
}: {
  allOrigDataRanges: Record<string, number[]>;
  scanId?: string;
  laneId?: string;
  xAxis: string;
  yAxis: string;
}) => {
  const xKey = JSON.stringify({
    scanId,
    laneId,
    axis: xAxis,
  });
  const yKey = JSON.stringify({
    scanId,
    laneId,
    axis: yAxis,
  });
  const xRange = allOrigDataRanges[xKey];
  const yRange = allOrigDataRanges[yKey];
  if (xRange && yRange) {
    return { x: xRange, y: yRange };
  }
};

export const getOrigMultiDataRange = ({
  allOrigDataRanges,
  datasetIdsList,
  xAxis,
  yAxis,
}: {
  allOrigDataRanges: Record<string, number[]>;
  datasetIdsList: { scanId: string; laneId: string }[];
  xAxis: string;
  yAxis: string;
}) => {
  let xUniteRange: number[] | undefined;
  let yUniteRange: number[] | undefined;

  datasetIdsList.forEach(({ scanId, laneId }) => {
    const xKey = JSON.stringify({ scanId, laneId, axis: xAxis });
    const yKey = JSON.stringify({ scanId, laneId, axis: yAxis });
    const xRange = allOrigDataRanges[xKey];
    const yRange = allOrigDataRanges[yKey];

    if (!xUniteRange) {
      xUniteRange = xRange;
    } else if (xRange) {
      xUniteRange = [Math.min(xRange[0], xUniteRange[0]), Math.max(xRange[1], xUniteRange[1])];
    }

    if (!yUniteRange) {
      yUniteRange = yRange;
    } else if (yRange) {
      yUniteRange = [Math.min(yRange[0], yUniteRange[0]), Math.max(yRange[1], yUniteRange[1])];
    }
  });

  if (xUniteRange && yUniteRange) {
    return { x: xUniteRange, y: yUniteRange };
  }
};
