import { FC, Fragment, ReactElement, useMemo } from 'react';
import classNames from 'classnames/bind';
import icons from '@/components/common/icons';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import styles from './TitleBlock.module.scss';

const cn = classNames.bind(styles);

type TTitleBlockProps = {
  title?: string;
  description?: string;
  icon?: ReactElement;
};

const TitleBlock: FC<TTitleBlockProps> = ({ title, description, icon }) => {
  const { config } = useReagentModalContext();
  const titleContent = useMemo(() => {
    const titlePartList = config.title.split('/');

    if (titlePartList.length === 1) {
      return config.title;
    }
    return (
      <>
        {titlePartList.map((titlePart, index) => (
          <Fragment key={titlePart}>
            {!!index && <span className={cn('title-block__slash')} />}
            <span>{titlePart}</span>
          </Fragment>
        ))}
      </>
    );
  }, [config.title]);

  return (
    <div className={cn('title-block')}>
      <div className={cn('title-block__wrap')}>
        <h2 className={cn('title-block__title')}>{title ?? titleContent}</h2>
        <p className={cn('title-block__description')}>{description ?? config.description}</p>
      </div>
      <div className={cn('title-block__icon-wrap')}>
        {icon ?? <icons.ReagentSearchIcon className={cn('title-block__icon')} />}
      </div>
    </div>
  );
};

export default TitleBlock;
