import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { preprocessingSelectors, preprocessingActions } from '@/store/slices/preprocessing';
import { EStepName } from '@/store/slices/preprocessing/types';

import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { prepareNewGateData } from '@/helpers/gates';
import { gatesActions } from '@/store/slices/gates';
import useGatesHandlingOnAxesChange from '@/hooks/preprocessing/useGatesHandlingOnAxesChange';
import usePredefineCellKillingGates from '@/hooks/preprocessing/usePredefineCellKillingGates';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import PreprocessingDatasetChart from '../../components/PreprocessingDatasetChart';
import StepActions, { TAction, TActionGroup } from '../../components/StepActions';

import styles from '../../PreprocessingSteps.module.scss';
import PreprocessingHeader from '../../components/PreprocessingHeader';
import PreprocessingStepHeader from '../../components/PreprocessingStepHeader';
import Gates from '../Gates';
import CreateGatePopover from '../CreateGatePopover';
import { CELL_TYPES_FOR_PUPULATION } from '../constants';

const cn = classnames.bind(styles);

type TStepCellKillingDefineCells = {
  className?: string;
};

const title = 'STEP 1. Define real cells population';
const instructions = [
  'Use click-and-drag functionality on the graph to select actual cells and create a real cell population gate.',
  'Real cell population gate will be applied to all datasets in this processing batch.',
];

const StepCellKillingDefineCells: FC<TStepCellKillingDefineCells> = ({ className }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const isNextStepEnabled = useSelector(preprocessingSelectors.selectNextStepEnabled);
  const defaultDataset = useSelector(preprocessingSelectors.selectCurrentDataset);
  const generalCellShapes = useSelector(preprocessingSelectors.selectGeneralCellShapes);
  const cellTypes = useSelector(preprocessingSelectors.selectCellTypes);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const datasetDetails = useMemo(() => {
    const dataset = datasets[0];
    return dataset;
  }, [datasets]);

  usePredefineCellKillingGates(datasetDetails ?? defaultDataset);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepInit));
        },
      },
      {
        label: 'Next',
        disabled: !isNextStepEnabled,
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCellKillingReviewCells));
        },
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isNextStepEnabled]);

  const createGate: TGeneralChartProps['createGate'] = useCallback(() => null, []);

  const handleGateSelected = (newGateData: Nullable<TNewGateModelData>) => {
    if (!newGateData) {
      return;
    }

    appDispatch(preprocessingActions.setCellForGate(CELL_TYPES_FOR_PUPULATION[0].uuid));
    const gateName = `REAL CELLS GATE`;
    const newGate = prepareNewGateData({
      gateName,
      xDimension: xAxis,
      yDimension: yAxis,
      isCageLevel: false,
      newGateModel: newGateData,
      scanId: '',
      laneId: '',
      currentChartType,
    });

    appDispatch(preprocessingActions.addGeneralCellShape(newGate));
  };

  const updateGate: TGeneralChartProps['updateGate'] = useCallback(
    (data) => {
      const { gateId, updatedGateList } = data;
      const updatedGate = updatedGateList.find((el) => el.id === gateId);
      if (!updatedGate) {
        return;
      }

      const { gateNodes, level, parentId, shape, properties, name, xDimension, yDimension } = updatedGate;

      appDispatch(
        preprocessingActions.updateGeneralCellShape({
          cellShapeId: gateId,
          data: { gateNodes, level, parentId, shape, properties, name, xDimension, yDimension },
        })
      );
    },
    [appDispatch]
  );

  const deleteGate: TGeneralChartProps['deleteGate'] = useCallback(
    (data) => {
      const { gateId } = data;

      appDispatch(preprocessingActions.deleteGeneralCellShape({ id: gateId }));
    },
    [appDispatch]
  );

  const deleteGateChildren: TGeneralChartProps['deleteGateChildren'] = useCallback(() => null, []);

  useEffect(() => {
    appDispatch(preprocessingActions.setCellTypes(CELL_TYPES_FOR_PUPULATION));
  }, []);

  useEffect(() => {
    const isBlockDraw = cellTypes.length === Object.keys(generalCellShapes).length;
    appDispatch(gatesActions.setIsBlockDraw(isBlockDraw));
  }, [cellTypes.length, generalCellShapes]);

  useGatesHandlingOnAxesChange();
  return (
    <div className={cn('preprocessing-step', className)}>
      <div className={cn('preprocessing-step__header')}>
        <PreprocessingHeader />
        <PreprocessingStepHeader title={title} instructions={instructions} />
      </div>
      <div className={cn('preprocessing-step__content')}>
        <PreprocessingDatasetChart
          createGate={createGate}
          deleteGate={deleteGate}
          updateGate={updateGate}
          deleteGateChildren={deleteGateChildren}
          datasetDetails={datasetDetails ?? defaultDataset}
          customPlotlySelectedHandler={handleGateSelected}
          GatesComponent={Gates}
          CustomCreateGateComponent={CreateGatePopover}
          xAxisSpecific={xAxis}
          yAxisSpecific={yAxis}
        />
        <StepActions actionGroups={actionGroups} />
      </div>
    </div>
  );
};

export default memo(StepCellKillingDefineCells);
