import { lazy, object, string, number, boolean } from 'zod';
import { gateFromServerSchema } from './gates';

export const baseBeadFromServerSchema = object({
  id: string(),
  beadId: string(),
  beadSize: number(),
  beadIntensity: number(),
  beadType: string(),
  description: string(),
  catalogNumbers: string().array(),
  manufacturerName: string(),
  name: string(),
  marker: string(),
  organizationId: string(),
  public: boolean(),
  type: string(),
  url: string(),
  validated: boolean(),
  vendor: string(),
});

export const createBeadRequestSchema = object({
  beadId: string().optional(),
  beadName: string().optional(),
  gate: gateFromServerSchema,
});

export const createBeadsRequestSchema = object({
  experimentId: string(),
  scanId: string(),
  laneId: string(),
  body: lazy(() => createBeadRequestSchema.array()),
});
