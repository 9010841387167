import { SVGProps } from 'react';

const ScanningStackedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="44" fill="none" {...props}>
    <path
      d="M31 2h4a7 7 0 0 1 7 7v4M13 2H9a7 7 0 0 0-7 7v4M31 42h4a7 7 0 0 0 7-7v-4M13 42H9a7 7 0 0 1-7-7v-4"
      stroke="currentColor"
      strokeWidth="4"
    />
    <circle
      cx="22"
      cy="22"
      r="10"
      transform="rotate(-90 22 22)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <g transform="translate(46 0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4h1a5 5 0 0 1 5 5V13h4V8.999A9 9 0 0 0 1 0H0v4Z"
        fill="currentColor"
      />
      <path
        transform="translate(0 30)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.223h1a5 5 0 0 0 5-5V.224h4V4.224a9 9 0 0 1-9 9H0v-4Z"
        fill="currentColor"
      />
    </g>
    <g transform="translate(58 0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4h1a5 5 0 0 1 5 5V13h4V8.999A9 9 0 0 0 1 0H0v4Z"
        fill="currentColor"
      />
      <path
        transform="translate(0 30)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.223h1a5 5 0 0 0 5-5V.224h4V4.224a9 9 0 0 1-9 9H0v-4Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ScanningStackedIcon;
