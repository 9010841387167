import { EAxesGroupName, TAxes, TAxesState, TBaseScatterPlotsSettings, TInitialScatterPlotsState } from './types';

const getNewGroupDatasetAxes = (groupAxes: Record<string, TAxes>, chartDataId: string, newDatasetAxes: TAxes) => {
  const datasetGroupAxes = groupAxes[chartDataId] ?? { x: '', y: '' };
  return { ...datasetGroupAxes, ...newDatasetAxes };
};

export const getNewAxesState = (
  prevAllAxes: TAxesState,
  newDatasetAxes: TAxes,
  axesGroupName: EAxesGroupName,
  chartDataId?: string
) => {
  const groupAxes = prevAllAxes[axesGroupName] ?? {};
  if (axesGroupName !== EAxesGroupName.general) {
    return {
      ...prevAllAxes,
      [axesGroupName]: { ...groupAxes, ...newDatasetAxes },
    };
  }

  if (chartDataId) {
    // Change axes for only one dataset in the group
    return {
      ...prevAllAxes,
      [axesGroupName]: {
        ...groupAxes,
        [chartDataId]: getNewGroupDatasetAxes(groupAxes as Record<string, TAxes>, chartDataId, newDatasetAxes),
      },
    };
  }

  // Change axes for all datasets in the group
  const newGroupAxes: Record<string, TAxes> = {};
  Object.keys(groupAxes).forEach((chartDataIdValue) => {
    newGroupAxes[chartDataIdValue] = getNewGroupDatasetAxes(
      groupAxes as Record<string, TAxes>,
      chartDataIdValue,
      newDatasetAxes
    );
  });
  return {
    ...prevAllAxes,
    [axesGroupName]: newGroupAxes,
  };
};

export function getStateObject(state: TInitialScatterPlotsState, fullScreenChartDataId?: string) {
  return state.specificDatasetOptionMap?.[fullScreenChartDataId ?? ''] ?? state;
}

export function getStateValue<T extends TInitialScatterPlotsState, K extends keyof TBaseScatterPlotsSettings>(
  state: T,
  key: K,
  fullScreenChartDataId?: Nullable<string>
): TBaseScatterPlotsSettings[K] {
  const currentState = fullScreenChartDataId ? state.specificDatasetOptionMap?.[fullScreenChartDataId] : state;
  return currentState?.[key] ?? state?.[key];
}
