import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useParamsExperimentId } from '@/hooks';
import { useCytokineSecretionPreprocessing } from '@/hooks/preprocessing/useCytokineSecretionPreprocessing';

import { EStepName } from '@/store/slices/preprocessing/types';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import StepActions, { TAction, TActionGroup } from '../../components/StepActions';
import PreprocessingHeader from '../../components/PreprocessingHeader';

import styles from './StepCytokineSummary.module.scss';

const cn = classnames.bind(styles);

const StepCytokineSummary = () => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const generalBeadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);

  const beads = useSelector(preprocessingSelectors.selectBeadTypes);
  const isStepCytokineReviewBeadsCompleted = useSelector(
    preprocessingSelectors.selectIsStepCytokineReviewBeadsCompleted
  );
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const { startCytokineSecretionPreprocessing } = useCytokineSecretionPreprocessing(experimentId, datasets);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCytokineReviewBeads));
        },
      },
      {
        label: 'Process Data',
        disabled: !isStepCytokineReviewBeadsCompleted,
        onClick: startCytokineSecretionPreprocessing,
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isStepCytokineReviewBeadsCompleted, generalBeadShapes]);

  return (
    <>
      <PreprocessingHeader hideStepNumber />
      <div className={cn('step-cytokine-summary')}>
        <div className={cn('summary')}>
          <span className={cn('summary__title')}>Data processing summary</span>
          <span className={cn('summary__text')}>
            You have successfully configured the gating for the cytokine secretion assay and are now ready to process
            your data.
          </span>
          <span className={cn('summary__text')}>
            With these gates in place, we will calculate <b>Cage-level data</b> for each cytokine secretion dataset for
            each individual bead type, including the following fields:
          </span>
          <div className={cn('summary__beads')}>
            {beads.map((bead) => (
              <span key={bead.uuid} className={cn('summary__bead')}>
                {bead.name}
              </span>
            ))}
          </div>
          <span className={cn('summary__text')}>
            These computed values can be utilized later for generating plots implementing various types of data
            analysis.
          </span>
          <span className={cn('summary__text')}>
            Click on the <b>Process Data</b> button below to initiate the data processing of your datasets.
          </span>
        </div>
        <div className={cn('illustration')}>
          <span className={cn('circles')}>
            Graphic Illustration
            <div className={cn('circle', 'circle_left')} />
            <div className={cn('circle', 'circle_right')} />
          </span>
        </div>
      </div>
      <StepActions actionGroups={actionGroups} className={cn('actions')} />
    </>
  );
};

export default StepCytokineSummary;
