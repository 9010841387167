import { useMemo, useState } from 'react';

import { invertedSort } from '@/helpers/arrays';

const useSorting: <T>(
  data: T[],
  sortingMethods: Record<string, (a: T, b: T) => number>
) => {
  setSortingSettings: (field: string) => void;
  sortedData: T[];
  sortingField: string;
  sortingOrder: 'asc' | 'dec' | 'off';
} = (data, sortingMethods) => {
  const [sortingField, setSortingField] = useState<string>('default');
  const [sortingOrder, setSortingOrder] = useState<'asc' | 'dec' | 'off'>('off');

  const sortedData = useMemo(() => {
    if (sortingOrder === 'off') {
      return data;
    }

    if (Object.keys(sortingMethods).includes(sortingField) && sortingField !== 'default') {
      const sortingMethod =
        sortingOrder === 'asc' ? sortingMethods[sortingField] : invertedSort(sortingMethods[sortingField]);
      return data.sort(sortingMethod);
    }

    console.error(`Data can't be sorted with provided key`);
    return data;
  }, [sortingField, sortingOrder, data]);

  const toggleSortingOrder = () => {
    const orderToggleVariants: Record<'asc' | 'dec' | 'off', 'asc' | 'dec' | 'off'> = {
      asc: 'dec',
      dec: 'off',
      off: 'asc',
    };

    setSortingOrder((prev) => orderToggleVariants[prev]);
  };

  return {
    setSortingSettings: (field) => {
      if (sortingField === field) {
        toggleSortingOrder();
      } else {
        setSortingField(field);
        setSortingOrder('asc');
      }
    },
    sortedData,
    sortingField,
    sortingOrder,
  };
};

export default useSorting;
