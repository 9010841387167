import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TViewerState } from './types';

const initialState: TViewerState = {
  displaySegmentedObjectsLayer: false,
  displayBarcodeCagesLayer: false,
  shouldFillInBarcodes: true,
  displayCagingSegmentationCentersLayer: false,
  shouldFillInCagingSegmentationCenters: true,
  displayCageLocationsLayer: false,
  displayDebugLayer: false,
  useOptimizedData: true,
  disableUseOptimizedData: true,

  allowDrawObjectsOnImageCrop: false,
  displayCurrentObjOnImageCrop: true,
  displayAllObjectsOnImageCrop: false,

  highlightedContourList: [],
  highlightedCage: null,
};

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    toggleDisplaySegmentedObjectsLayer: (state) => {
      state.displaySegmentedObjectsLayer = !state.displaySegmentedObjectsLayer;
    },
    toggleDisplayBarcodeCagesLayer: (state) => {
      state.displayBarcodeCagesLayer = !state.displayBarcodeCagesLayer;
    },
    toggleShouldFillInBarcodes: (state) => {
      state.shouldFillInBarcodes = !state.shouldFillInBarcodes;
    },
    toggleDisplayCagingSegmentationCentersLayer: (state) => {
      state.displayCagingSegmentationCentersLayer = !state.displayCagingSegmentationCentersLayer;
    },
    toggleShouldFillInCagingSegmentationCenters: (state) => {
      state.shouldFillInCagingSegmentationCenters = !state.shouldFillInCagingSegmentationCenters;
    },
    toggleDisplayCageLocationsLayer: (state) => {
      state.displayCageLocationsLayer = !state.displayCageLocationsLayer;
    },
    toggleDisplayDebugLayer: (state) => {
      state.displayDebugLayer = !state.displayDebugLayer;
    },
    setUseOptimizedData: (state, action: PayloadAction<boolean>) => {
      state.useOptimizedData = action.payload;
    },
    setDisableUseOptimizedData: (state, action: PayloadAction<boolean>) => {
      state.disableUseOptimizedData = action.payload;
    },
    setAllowDrawObjectsOnImageCrop: (state, action: PayloadAction<boolean>) => {
      state.allowDrawObjectsOnImageCrop = action.payload;
    },
    toggleDisplayCurrentObjOnImageCrop: (state) => {
      state.displayCurrentObjOnImageCrop = !state.displayCurrentObjOnImageCrop;
    },
    toggleDisplayAllObjectsOnImageCrop: (state) => {
      state.displayAllObjectsOnImageCrop = !state.displayAllObjectsOnImageCrop;
    },
    setHighlightedContourList: (
      state,
      action: PayloadAction<(TCageContour | TCellContour | Pick<TCellContour, 'canvasPoints'>)[]>
    ) => {
      state.highlightedContourList = action.payload;
    },
    setHighlightedCage: (state, action: PayloadAction<Nullable<TEntity>>) => {
      state.highlightedCage = action.payload;
    },
  },
});

export default viewerSlice;
