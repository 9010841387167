import { SVGProps } from 'react';

const NavigatorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
      <path d="M1.205 5.182a4 4 0 0 1 4-4h12" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.205 14.618V9.746c0-.892.093-1.215.268-1.541.174-.326.43-.582.756-.756.326-.174.649-.267 1.54-.267h4.873c.891 0 1.214.093 1.54.267.326.174.582.43.756.756.175.326.267.65.267 1.54v4.873c0 .891-.092 1.215-.267 1.54-.174.327-.43.582-.756.757-.326.174-.649.267-1.54.267H9.769c-.891 0-1.214-.093-1.54-.267a1.817 1.817 0 0 1-.756-.756c-.175-.326-.268-.65-.268-1.541z" />
      <path
        d="M1.205 19.182a4 4 0 0 0 4 4h12M23.205 5.182a4 4 0 0 0-4-4h-2 0M23.205 19.182a4 4 0 0 1-4 4h-2 0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default NavigatorIcon;
