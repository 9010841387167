import { toast } from 'react-toastify';

export const getErrorMessage = (error: unknown, defaultErrorMessage?: string) => {
  if (typeof error === 'string') {
    return error;
  }

  if (typeof error === 'object' && error !== null) {
    if ('message' in error) {
      // Error
      // When Error was thrown in the code
      return error.message as string;
    }
    if ('error' in error) {
      // FetchBaseQueryError (?)
      // When request fails
      return error.error as string;
    }
    if ('errors' in error) {
      // Error from graphql API
      return (error.errors as { message?: string; locations: unknown; path: unknown }[])
        .map((errorItem) => errorItem.message)
        .filter((errorMessage?: string) => errorMessage)
        .join('\n');
    }
    if ('data' in error && typeof error.data === 'object' && error.data !== null && 'message' in error.data) {
      // FetchBaseQueryError (?)
      // When server returns 500
      return error.data.message as string;
    }
    if ('data' in error && typeof error.data === 'string') {
      return error.data as string;
    }
  }

  return defaultErrorMessage ?? 'Something went wrong';
};

export const showErrorToast = (errorMessage: string) => {
  toast.error(errorMessage, {
    toastId: errorMessage, // Prevents showing errors with the same text
  });
};
