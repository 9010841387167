import { useMemo } from 'react';

import { getUngatedName } from '@/helpers/gates';

type TChosenGate = {
  isObjectEntityEnabled: boolean;
  isCageLvlForced: boolean;
  selectedGate: Nullable<TGate>;
  activeGate: Nullable<TGate>;
};

export function useGateLabelForCurrentCageImage({
  isObjectEntityEnabled,
  isCageLvlForced,
  selectedGate,
  activeGate,
}: TChosenGate) {
  const currentGateLabelForCageImage = useMemo(() => {
    let gateText = getUngatedName(isObjectEntityEnabled && !isCageLvlForced);
    if (selectedGate?.name) gateText += `: ${selectedGate.name}`;
    if (activeGate?.name) gateText += `: ${activeGate.name}`;

    return gateText;
  }, [selectedGate, activeGate, isObjectEntityEnabled, isCageLvlForced]);

  return { currentGateLabelForCageImage };
}
