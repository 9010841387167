import { SVGProps } from 'react';

const AddAntibodyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="53" height="51" viewBox="0 0 53 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M45 35L45 51" stroke="currentColor" strokeWidth="4" />
    <path d="M53 43L37 43" stroke="currentColor" strokeWidth="4" />
    <path
      d="M7.53906 3L17.7915 9.82876C20.0187 11.3122 21.3567 13.811 21.3567 16.487V38.5"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M42.4609 3L32.2085 9.82876C29.9813 11.3122 28.6433 13.811 28.6433 16.487V38.5"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4.58799 7.6812L2.90981 6.59321L0.733833 9.94956L2.41201 11.0376L4.58799 7.6812ZM2.41201 11.0376L12.6659 17.6853L14.8419 14.329L4.58799 7.6812L2.41201 11.0376Z"
      fill="currentColor"
    />
    <path
      d="M45.412 7.6812L47.0902 6.59321L49.2662 9.94956L47.588 11.0376L45.412 7.6812ZM47.588 11.0376L37.3341 17.6853L35.1581 14.329L45.412 7.6812L47.588 11.0376Z"
      fill="currentColor"
    />
  </svg>
);

export default AddAntibodyIcon;
