import { SVGProps } from 'react';

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="4.75" y="0.75" width="9.5" height="12.5" rx="2.25" fill="white" stroke="currentColor" strokeWidth="1.5" />
    <rect x="0.75" y="4.75" width="9.5" height="12.5" rx="2.25" fill="white" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default CopyIcon;
