export const getAxesTitlesUpdatedLayout = (
  xAxisTitleOnPlotly: Nullable<string>,
  yAxisTitleOnPlotly: Nullable<string>
) => {
  const fontLayout = {
    family: 'Inter Display, Arial, sans-serif',
    size: 13,
    color: '#989898',
  };

  const xAxisTitle = xAxisTitleOnPlotly
    ? {
        font: fontLayout,
        text: xAxisTitleOnPlotly,
      }
    : null;

  const yAxisTitle = yAxisTitleOnPlotly
    ? {
        font: fontLayout,
        text: yAxisTitleOnPlotly,
      }
    : null;

  return {
    'xaxis.title': xAxisTitle,
    'yaxis.title': yAxisTitle,
  };
};

export const getShapesUpdatedLayout = (prevLayout?: TPlotLayout, isMinimized?: boolean) => {
  const layoutShapes: TPlotShape[] = prevLayout?.shapes ?? [];
  const updatedLayoutShapes = layoutShapes.reduce((acc: Record<string, number>, _cur: TPlotShape, index: number) => {
    acc[`shapes[${index}].label.font.size`] = isMinimized ? 10 : 16;
    return acc;
  }, {});

  const margin = isMinimized ? { l: 50, r: 0, t: 0, b: 40 } : { l: 60, r: 10, t: 10, b: 50 };

  return {
    ...updatedLayoutShapes,
    margin,
  };
};
