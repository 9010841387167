import { isBasicOption } from '@/components/common/Select/helpers';

export const collaboratorFilterProjects = (projectList: TProject[], collaboratorFilterOptionList: TOption[]) => {
  const collaboratorsFilteredArray = new Set<string | number>();

  collaboratorFilterOptionList.forEach((collaborator) => {
    if (isBasicOption(collaborator)) {
      collaboratorsFilteredArray.add(collaborator.value);
    }
  });

  projectList.forEach((project) =>
    project.collaborators.forEach((collaborator) => collaboratorsFilteredArray.add(collaborator))
  );

  const collaboratorsFormattedArray: TOption[] = [];

  collaboratorsFilteredArray.forEach((collaborator) => {
    if (!collaborator) {
      collaboratorsFormattedArray.push({
        value: collaborator,
        label: 'All collaborators',
      });
    } else {
      collaboratorsFormattedArray.push({
        value: collaborator,
        label: collaborator.toString(),
      });
    }
  });

  return { collaborators: collaboratorsFormattedArray };
};

export const ownerFilterProjects = (projectList: TProject[], ownerFilterOptionList: TOption[]) => {
  const ownersFilteredArray = new Set<string | number>();

  ownerFilterOptionList.forEach((owner) => {
    if (isBasicOption(owner)) {
      ownersFilteredArray.add(owner.value);
    }
  });

  projectList.forEach((project) => ownersFilteredArray.add(project.owner));

  const ownersFormattedArray: TOption[] = [];

  ownersFilteredArray.forEach((owner) => {
    if (!owner) {
      ownersFormattedArray.push({
        value: owner,
        label: 'Owner',
      });
    } else {
      ownersFormattedArray.push({
        value: owner,
        label: owner.toString(),
      });
    }
  });
  return {
    owners: ownersFormattedArray,
  };
};
