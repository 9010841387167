import Button from '@/components/common/Button';
import { CSSProperties, FC, MouseEvent } from 'react';
import classnames from 'classnames/bind';
import { TButtonColor } from '@/helpers/interfaces';
import styles from './ColorButton.module.scss';

const cn = classnames.bind(styles);

type TColorButton = {
  color?: string;
  tooltip?: string;
  colorClassName?: string;
  buttonClassName?: string;
  theme?: TButtonColor;
  onClick?: () => void;
  disabled?: boolean;
};
const ColorButton: FC<TColorButton> = ({
  color = '',
  tooltip = '',
  theme = 'light',
  onClick,
  colorClassName = '',
  buttonClassName = '',
  disabled = false,
}) => {
  const handleOnButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      tooltip={tooltip}
      color={theme}
      onClick={handleOnButtonClick}
      className={cn(
        'color-button',
        {
          'color-button_hidden': !color,
        },
        buttonClassName
      )}
      disabled={disabled}
    >
      <div
        style={
          {
            '--color': color,
          } as CSSProperties
        }
        className={cn(
          'color-button__color-circle',
          { 'color-button__color-circle_disabled': disabled },
          colorClassName
        )}
      />
    </Button>
  );
};

export default ColorButton;
