import { formatTime } from '@/helpers';

export const transformProjectListResponse = (projectFromServers: TProject[]): TProject[] =>
  projectFromServers
    .filter((projectFromServer) => {
      if (!('id' in projectFromServer)) {
        // eslint-disable-next-line no-console
        console.log('The next project does not have an ID and will not appear in the list', projectFromServer);
      }
      return projectFromServer.id;
    })
    .map((projectFromServer) => ({
      ...projectFromServer,
      dateModified: formatTime(projectFromServer.dateModified),
      dateCreated: formatTime(projectFromServer.dateCreated),
      experimentsIds: projectFromServer.experimentsIds ?? [],
      experimentsCount: projectFromServer.experimentsIds?.length ?? 0,
    }));
