import JSZip from 'jszip';

import { TCdnObjectType } from '@/types/cdnData';

import { transformWithPapaparse } from './dataProvider';

export const getErrorFromHtml = (htmlError: unknown) => {
  if (typeof htmlError === 'string') {
    const divWithError = document.createElement('div');
    divWithError.innerHTML = htmlError;
    const message = divWithError.getElementsByTagName('message');
    const code = divWithError.getElementsByTagName('code');
    if (message[0] && code[0]) {
      return { message: message[0].innerHTML, code: code[0].innerHTML };
    }
  }
  return htmlError;
};

export const fetchData = (lane: TLane, type: TCdnObjectType, fileName: string, isPreserveCache = true) =>
  new Promise<any>((resolve, reject) => {
    fetch(`${process.env.REACT_APP_CDN_URL}/${lane.path}/${fileName}`, {
      credentials: 'include',
      cache: isPreserveCache ? 'default' : 'no-store',
    })
      .then((response) =>
        response.ok ? response.text() : response.text().then((text) => Promise.reject(getErrorFromHtml(text)))
      )
      .then((data) => {
        transformWithPapaparse(data, lane, type).then(resolve);
      })
      .catch(() => {
        reject(new Error(`File ${fileName} not available. Please contact support.`));
      });
  });

export const fetchDataFromArchive = (lane: TLane, type: TCdnObjectType, archiveName: string, isPreserveCache = true) =>
  new Promise<any>((resolve, reject) => {
    fetch(`${process.env.REACT_APP_CDN_URL}/${lane.path}/${archiveName}`, {
      credentials: 'include',
      cache: isPreserveCache ? 'default' : 'no-store',
    })
      .then((response) =>
        response.ok ? response.blob() : response.text().then((text) => Promise.reject(getErrorFromHtml(text)))
      )
      .then((blob) => {
        JSZip.loadAsync(blob)
          .then(async (zipped) => {
            const csvFile = Object.values(zipped.files)[0];
            if (!csvFile) {
              reject(new Error('Invalid CSV file'));
            }
            csvFile.options = {
              compression: 'DEFLATE',
            };
            csvFile.async('text').then((data) => {
              transformWithPapaparse(data, lane, type).then(resolve);
            });
          })
          .catch(reject);
      })
      .catch(() => {
        reject(new Error(`File ${archiveName} not available. Please contact support.`));
      });
  });

type TWebWorkerEventData = {
  type: TCdnObjectType;
  lane: TLane;
  data: string;
};

export const parseDataWithWorker = (
  webWorkerEventData: TWebWorkerEventData,
  resolve: ({ data }: { data: Array<null> }) => void
) => {
  try {
    const transformDataWorker = new Worker(new URL('./worker', import.meta.url));
    transformDataWorker.onmessage = (event) => {
      resolve({ data: Object.freeze(event.data) });
      transformDataWorker.terminate();
    };
    transformDataWorker.onerror = () => {
      resolve({ data: [] });
      transformDataWorker.terminate();
    };
    transformDataWorker.postMessage(webWorkerEventData);
  } catch (error) {
    resolve({ data: [] });
  }
};
