import { ChangeEventHandler, FC } from 'react';
import classnames from 'classnames/bind';
import TimeInput, { ETimeInputUnit } from '@/components/common/TimeInput';
import { TemperatureUnit } from '@/graphql/API';
import { themeOptions } from '@/types/theme';
import Select from '@/components/common/Select';
import CheckboxInput from '@/components/common/CheckboxInput';
import { CELSIUS, FAHRENHEIT } from '@/helpers';
import styles from '../MainBlock.module.scss';

const cn = classnames.bind(styles);

type TIncubationBlockProps = {
  durationHour: {
    value: number;
    onChange: (value: number) => void;
  };
  durationMinute: {
    value: number;
    onChange: (value: number) => void;
  };
  temperature: {
    value: number;
    onChange: (value: number) => void;
  };
  temperatureUnit: {
    value: TemperatureUnit;
    onChange: (value: TemperatureUnit) => void;
  };
  location: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
};

const IncubationBlock: FC<TIncubationBlockProps> = ({
  durationHour,
  durationMinute,
  temperature,
  temperatureUnit,
  location,
}) => {
  const handleLocationChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget: { checked } }) => {
    location.onChange(checked);
  };

  return (
    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group', 'main-block__group_time')}>
          <div className={cn('main-block__block-title')}>Duration</div>
          <TimeInput
            theme={themeOptions.light}
            onChange={durationHour.onChange}
            value={durationHour.value}
            unit={ETimeInputUnit.hour}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
          <TimeInput
            theme={themeOptions.light}
            onChange={durationMinute.onChange}
            value={durationMinute.value}
            unit={ETimeInputUnit.minute}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
        </div>
      </div>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group', 'main-block__group_time')}>
          <div className={cn('main-block__block-title')}>Temperature</div>
          <Select
            options={Array.from({ length: 100 }, (_, index) => ({
              value: index,
              label: String(index),
            }))}
            value={temperature.value}
            onChange={temperature.onChange}
            theme={themeOptions.light}
            className={cn('main-block__select', 'main-block__select_narrow')}
            controlClassName={cn('main-block__select-control')}
            menuClassName={cn('main-block__select-menu')}
            menuPosition="absolute"
            isPortalable={false}
          />
          <Select
            options={[
              { value: TemperatureUnit.C, label: CELSIUS },
              { value: TemperatureUnit.F, label: FAHRENHEIT },
            ]}
            value={temperatureUnit.value}
            onChange={temperatureUnit.onChange}
            theme={themeOptions.light}
            className={cn('main-block__select', 'main-block__select_narrow')}
            controlClassName={cn('main-block__select-control')}
            menuClassName={cn('main-block__select-menu')}
            menuPosition="absolute"
            isPortalable={false}
          />
        </div>
      </div>

      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title')}>Location</div>
          <div className={cn('main-block__toggle-box')}>
            <CheckboxInput
              checked={location.value}
              isSwitch
              theme="run-design"
              onChange={handleLocationChange}
              label={location.value ? 'On-device' : 'Off-device'}
              labelClassName={cn('main-block__checkbox-label')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncubationBlock;
