import { SVGProps } from 'react';

const JobsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path d="M19.9457 24.8427L10 20.4054V16.5818H18.2353" stroke="currentColor" strokeWidth="1.5" />
    <path d="M19.9457 24.8427L30 20.4054V16.5819H17.0205" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M15.8504 14.5294V13C15.8504 11.8954 16.7459 11 17.8504 11H22.0857C23.1903 11 24.0857 11.8954 24.0857 13V14.5294"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M11.1701 20.9936V27.523C11.1701 28.6276 12.0655 29.523 13.1701 29.523H26.523C27.6276 29.523 28.523 28.6276 28.523 27.523V20.9936"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="18.1316"
      y="22.1047"
      width="3.33333"
      height="3.33333"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default JobsIcon;
