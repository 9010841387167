export const searchExperiments = (searchQuery: string, experimentList: TExperimentFromServer[] | undefined) => {
  if (!searchQuery || !experimentList) {
    return experimentList;
  }

  const searchKeys = [
    'experimentId',
    'experimentName',
    'instrumentName',
    'imagingStatus',
    'sequencingStatus',
    'operator',
  ] as const;

  return experimentList.filter((item: TExperiment) => {
    const preparedData = {
      experimentId: item.id,
      experimentName: item.experimentName,
      instrumentName: item.instrument.friendlyName,
      imagingStatus: item?.imagingStatus ?? '',
      sequencingStatus: item?.sequencingStatus ?? '',
      operator: item?.operator ?? '',
    };

    return searchKeys.some((key) => preparedData[key]?.toLowerCase().includes(searchQuery.toLowerCase()));
  });
};
