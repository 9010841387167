import { SVGProps } from 'react';

const ReceptorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" viewBox="0 0 49 38" fill="none" {...props}>
    <path
      d="M7.038 3 17.29 9.829a8 8 0 0 1 3.565 6.658v19.18M41.962 3 31.71 9.829a8 8 0 0 0-3.565 6.658v19.18"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4.088 7.68 2.41 6.594.234 9.949l1.678 1.088 2.176-3.356Zm-2.176 3.357 10.254 6.648 2.176-3.356L4.088 7.68l-2.176 3.356ZM44.912 7.68l1.678-1.087 2.176 3.356-1.678 1.088-2.176-3.356Zm2.176 3.357-10.254 6.648-2.176-3.356L44.912 7.68l2.176 3.356Z"
      fill="currentColor"
    />
  </svg>
);

export default ReceptorIcon;
