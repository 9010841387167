import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EMarkerSizes, EPlotlyChartType } from '@/types/charts';
import { FULL_D3_COLORSCALES } from '@/helpers/charts/colorscales';
import { arrToMapByKeys } from '@/helpers';

import { violinSelectors, type TViolinData } from '@/store/slices/violin';
import { experimentSelectors } from '@/store/slices/experiment';
import { gatesSelectors } from '@/store/slices/gates';

const colors = FULL_D3_COLORSCALES.schemeCategory10;
const defaultData = [0];

const useViolinPlotData = (entitiesByLanesAndGates: TEntitiesByLanesAndGates) => {
  const violinsDataArr = useSelector(violinSelectors.selectViolinsArr);
  const allLaneMap = useSelector(experimentSelectors.selectAllLaneMap);
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);

  const getViolinPlotConfig = useCallback(
    (plotData: TViolinData, index: number) => {
      const [_, fillcolor] = colors[index % (colors.length - 1)];
      const lineColor = 'white';
      const pointColor = 'black';

      const laneLetter = allLaneMap[plotData.laneId]?.letterName ?? '';
      const laneTitle = laneLetter ? `Lane ${laneLetter}` : '';
      const genTitle = plotData.geneName ? `Gene - ${plotData.geneName}` : '';
      const titles: string[] = [];

      const isEmpty = !plotData.data?.length;
      const data = isEmpty || !plotData.data ? defaultData : plotData.data;

      if (laneTitle) {
        titles.push(laneTitle);
      }
      if (genTitle) {
        titles.push(genTitle);
      }

      let traceTitle = `(${plotData.data?.length ?? 0})`;

      if (titles.length) {
        traceTitle = `${titles.join(', ')}${traceTitle}`;
      }

      const customdata = data.map((globalCageId) => ({
        globalCageId,
        plotIndex: index,
      }));

      return {
        type: EPlotlyChartType.violin,
        y: data,
        points: isEmpty ? false : 'all',
        pointpos: 0,
        box: {
          visible: true,
        },
        boxpoints: true,
        line: {
          color: isEmpty ? fillcolor : lineColor,
        },
        fillcolor,
        opacity: 0.8,
        meanline: {
          visible: true,
        },
        scalemode: 'width',
        x: traceTitle,
        x0: traceTitle,
        name: traceTitle,
        title: traceTitle,
        hoveron: 'points',
        hoverinfo: 'none',
        customdata,
        selectedpoints: [],
        marker: {
          color: pointColor,
          opacity: 1,
        },
        selected: {
          marker: {
            size: EMarkerSizes.highlighted,
          },
        },
      };
    },
    [allLaneMap]
  );

  const plotsData = useMemo(() => {
    const dataArr = violinsDataArr.filter((el) => el.data?.length);
    const entityMap = selectedGate
      ? Object.values(entitiesByLanesAndGates).reduce((acc, el) => {
          const entityList = el[selectedGate?.id ?? ''].cageList ?? [];
          Object.assign(acc, arrToMapByKeys(entityList, 'globalCageIdMatched'));
          return acc;
        }, {})
      : null;

    return dataArr.map((violinData, index) => {
      const plotData = entityMap ? violinData.data?.filter((globalCageId) => entityMap[globalCageId]) : violinData.data;

      return getViolinPlotConfig({ ...violinData, data: plotData }, index);
    });
  }, [getViolinPlotConfig, violinsDataArr, selectedGate, entitiesByLanesAndGates]);

  return plotsData;
};

export default useViolinPlotData;
