import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HSLToHex, getColorWithLuminance, hexToHSL } from '@/helpers';
import { getChannelUiColor } from '@/helpers/channels';

import { CELL_TYPES_FOR_AFFECTED_CELLS } from '@/components/experiment/Preprocessing/CellKilling/constants';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import { useAppDispatch } from '../useAppDispatch';

const useCellChannelTypes = () => {
  const appDispatch = useAppDispatch();
  const cellXChannel = useSelector(preprocessingSelectors.selectCellXChannel);
  const cellYChannel = useSelector(preprocessingSelectors.selectCellYChannel);

  useEffect(() => {
    if (cellXChannel && cellYChannel) {
      const channelForType: Record<string, string> = {
        cellYChannel,
        cellXChannel,
      };

      const updatedCellTypes = CELL_TYPES_FOR_AFFECTED_CELLS.map((typeData, i) => {
        if (typeData?.axisChannel) {
          const channelColor = getChannelUiColor(channelForType[typeData.axisChannel]);

          const bgColor = i % 2 === 0 ? channelColor : getColorWithLuminance(channelColor, -0.1);
          const colorHSL = hexToHSL(bgColor);
          let color = bgColor;

          if (colorHSL) {
            color = HSLToHex({ ...colorHSL, l: 50 });
          }

          return {
            ...typeData,
            color,
            bgColor,
          };
        }
        return typeData;
      });

      appDispatch(preprocessingActions.setCellTypes(updatedCellTypes));
    }
  }, [cellYChannel, cellXChannel]);
};

export default useCellChannelTypes;
