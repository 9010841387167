import z, { array, object, string } from 'zod';

export const sequencingSummarySchema = object({
  'Estimated Number of Cells': string(),
});
export const sequencingSummaryListSchema = array(sequencingSummarySchema);

export const sequencingCellReadsSchema = object({
  CB: string(),
  cbMatch: string(),
  nUMIunique: string(),
});

export const sequencingCellReadsListSchema = array(sequencingCellReadsSchema);

export type TSequencingSummaryListSchema = z.infer<typeof sequencingSummaryListSchema>;

export type TSequencingCellReadsSchema = z.infer<typeof sequencingCellReadsSchema>;

export type TSequencingSummarySchema = z.infer<typeof sequencingSummarySchema>;

export type TSequencingCellReadsListSchema = z.infer<typeof sequencingCellReadsListSchema>;
