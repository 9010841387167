import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';
import { selectCurrentChartData } from '@/store/slices/chartData/selectors';
import { sortByKey } from '@/helpers';

import { EAxesGroupName, TAxes } from './types';
import { getStateValue } from './helpers';

export const selectCurrentAxesGroupName = (state: RootState) => state.scatterplots.currentAxesGroupName;

export const selectAxes = (state: RootState) => state.scatterplots.axes;

export const selectXAxis = (axesGroupName?: EAxesGroupName) =>
  createSelector(
    [selectCurrentChartData, selectCurrentAxesGroupName, selectAxes],
    (currentChartData, currentAxesGroupName, axes) => {
      const groupName = axesGroupName ?? currentAxesGroupName;
      const groupAxes = axes[groupName];

      if (groupAxes?.x) {
        // all not general groups
        return groupAxes.x as string;
      }
      return (currentChartData && (groupAxes as Record<string, TAxes> | undefined)?.[currentChartData.id]?.x) ?? '';
    }
  );

export const selectYAxis = (axesGroupName?: EAxesGroupName) =>
  createSelector(
    [selectCurrentChartData, selectCurrentAxesGroupName, selectAxes],
    (currentChartData, currentAxesGroupName, axes) => {
      const groupName = axesGroupName ?? currentAxesGroupName;
      const groupAxes = axes[groupName];
      if (groupAxes?.y) {
        // all not general groups
        return groupAxes.y as string;
      }
      return (currentChartData && (groupAxes as Record<string, TAxes> | undefined)?.[currentChartData.id]?.y) ?? '';
    }
  );

export const selectXAxisByChartData = (chartDataId: string, axesGroupName?: EAxesGroupName) =>
  createSelector([selectCurrentAxesGroupName, selectAxes], (currentAxesGroupName, axes) => {
    const groupName = axesGroupName ?? currentAxesGroupName;
    const groupAxes = axes[groupName];
    return (groupAxes as Record<string, TAxes> | undefined)?.[chartDataId]?.x ?? '';
  });

export const selectYAxisByChartData = (chartDataName: string, axesGroupName?: EAxesGroupName) =>
  createSelector([selectCurrentAxesGroupName, selectAxes], (currentAxesGroupName, axes) => {
    const groupName = axesGroupName ?? currentAxesGroupName;
    const groupAxes = axes[groupName];
    return (groupAxes as Record<string, TAxes> | undefined)?.[chartDataName]?.y ?? '';
  });

export const selectMatrixPlotInfoObj = (state: RootState) => state.scatterplots.matrixPlotsInfo;

export const selectMatrixPlotInfoSortedArr = createSelector([selectMatrixPlotInfoObj], (matrixPlotInfoObj) =>
  sortByKey(Object.values(matrixPlotInfoObj), 'index')
);

export const selectMatrixPlotInfoArr = createSelector([selectMatrixPlotInfoObj], (matrixPlotInfoObj) =>
  Object.values(matrixPlotInfoObj)
);

export const selectAxesByGroup = (groupName: EAxesGroupName) =>
  createSelector([selectAxes], (axes) => axes[groupName] ?? null);

export const selectActiveCageId = (state: RootState) => state.scatterplots.activeEntityUuid;

export const selectDensityBandWidth = (state: RootState) => state.scatterplots.densityBandWidth;

export const selectHighlightDotsBy = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.scatterplots, 'highlightDotsBy', datasetId);

export const selectSpecificDatasetOptionMap = (storeState: RootState) =>
  storeState.scatterplots.specificDatasetOptionMap;

export const selectAllGlobalScatterPlotSettingsSettings = (storeState: RootState) => storeState.scatterplots;
