import { ComponentType, ReactNode } from 'react';
import type { TButtonColor } from '@/helpers/interfaces';

export type TStandardField<T> = {
  name: keyof T;
  label: string;
  arrowPositionMode?: 'relative' | 'absolute';
  isSortable?: boolean;
  isSearchable?: boolean;
  isFitContent?: boolean;
  isDateDistance?: boolean;
  className?: string;
  isBytes?: boolean;
  dataComponent?: ComponentType<{ data: T }>;
};

export type TAction<T> = {
  icon?: ReactNode;
  label: (data: T) => string;
  color?: TButtonColor;
  handler: (data: T) => Promise<void> | void;
  isDisabled?: boolean;
  className?: string;
};

export type TStandardValue = string | number;

export const ActionsFormat = {
  popover: 'popover',
  list: 'list',
};

export type TActionsFormat = keyof typeof ActionsFormat;
