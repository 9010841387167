import { groupDataByAxesValue } from '@/helpers/charts/chartsData';

export const sortHeatmaqpDataByCageIds = (cageIds: Set<number>, dataList: TEntity[][], selectedXAxis: string) => {
  const groupData: number[][] = [];
  const ids: number[][] = [];
  const groupedCagesByIdList = dataList.map((cageData) => groupDataByAxesValue(cageData, selectedXAxis));
  const sortedCagesIds = cageIds.size ? Array.from(cageIds).sort((a, b) => a - b) : [];

  if (sortedCagesIds.length) {
    sortedCagesIds.forEach((value) => {
      const yTickItem = groupedCagesByIdList.map((cagesList) => cagesList[value] ?? 0);
      const sectorIds = yTickItem.map(() => Number(value));

      ids.push(sectorIds);

      groupData.push(yTickItem);
    });
  }

  const dataConfigToUpdate = {
    z: groupData,
    y: sortedCagesIds,
    customdata: ids,
  };

  return dataConfigToUpdate;
};

export const sortHeatmapDataByAxesValue = (
  cageIds: Set<number>,
  dataList: TEntity[][],
  selectedXAxis: string,
  indexToGroup: number
) => {
  const groupData: Record<number, number[]> = {};

  const groupedCagesByIdList = dataList.map((cageData) => groupDataByAxesValue(cageData, selectedXAxis));
  const cagesIds = cageIds.size ? Array.from(cageIds) : [];

  if (cagesIds.length) {
    cagesIds.forEach((value) => {
      const yTickItem = groupedCagesByIdList.map((cagesList) => cagesList[value] ?? 0);
      groupData[value] = yTickItem;
    });
  }
  const sortedData = Object.entries(groupData).sort(
    ([, a]: [string, number[]], [, b]: [string, number[]]) => a[indexToGroup] - b[indexToGroup]
  );

  const ids: number[][] = [];
  const zData: number[][] = [];

  sortedData.forEach(([key, valuesArr]: [string, number[]]) => {
    const sectorIds = valuesArr.map(() => Number(key));
    ids.push(sectorIds);
    zData.push(valuesArr);
  });

  const dataConfigToUpdate = {
    z: zData,
    y: [],
    customdata: ids,
  };

  return dataConfigToUpdate;
};

export const prepareHeatmapData = (
  cageIds: Set<number>,
  dataList: TEntity[][],
  selectedXAxis: string,
  isSortByDatasetData: boolean,
  indexToGroup?: number
) => {
  let data;
  if (isSortByDatasetData && typeof indexToGroup === 'number') {
    data = sortHeatmapDataByAxesValue(cageIds, dataList, selectedXAxis, indexToGroup);
  } else {
    data = sortHeatmaqpDataByCageIds(cageIds, dataList, selectedXAxis);
  }

  return {
    z: data.z,
    y: isSortByDatasetData ? [] : data.y,
    customdata: data.customdata,
    hovertemplate: ' Global cage ID: %{customdata}   <br> Value: %{z} <extra></extra>',
  };
};

export const prepareHeatmapAxesLayout = (
  datasetDetailsList: TDatasetDetails[],
  datasetList: TDataset[],
  xAxis: string
) => {
  const channel = xAxis.split('_')?.[0] ?? '';

  const xAxisLabels = datasetDetailsList.map((item) => `${item.timePoint} ${item?.laneLetterName ?? ''} ${channel}`);
  const xTickVals = datasetList.map((_, index: number) => index);
  const axesLayout = {
    xaxis: { ticktext: xAxisLabels, tickvals: xTickVals },
  };

  return axesLayout;
};

export const getUniqueCageIds = (dataList: TEntity[][]) => {
  const uniqueCageIds = new Set<number>();

  if (dataList.length) {
    dataList.forEach((list) => {
      list.forEach((cage) => {
        if (!cage?.globalCageIdMatched) return;
        uniqueCageIds.add(cage.globalCageIdMatched);
      });
    });
  }

  return uniqueCageIds;
};
