import { FC } from 'react';
import { schemeTableau10 } from 'd3-scale-chromatic';
import ColorButton from '@/components/common/ColorButton';
import classnames from 'classnames/bind';
import styles from './ColorScheme.module.scss';

const cn = classnames.bind(styles);
type TColorScheme = {
  color?: string;
  onClick: (e: string) => void;
};
const DEFAULT_COLOR = '#000000';
const COLOR_SCHEME = schemeTableau10;

const ColorScheme: FC<TColorScheme> = ({ color = DEFAULT_COLOR, onClick }) => {
  const handleClick = (currentColor: string) => {
    onClick(currentColor);
  };

  return (
    <div className={cn('color-scheme')}>
      {COLOR_SCHEME.map((currentColor) => (
        <ColorButton
          buttonClassName={cn('color-button')}
          colorClassName={cn('color-circle', { 'color-circle_selected': color === currentColor })}
          key={currentColor}
          color={currentColor}
          onClick={() => handleClick(currentColor)}
        />
      ))}
    </div>
  );
};

export default ColorScheme;
