import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import 'react-loading-skeleton/dist/skeleton.css';

import { EPageWithChartType } from '@/types/charts';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSearchParamsDatasets } from '@/hooks';
import { EExperimentTabId, useLinks } from '@/hooks/useLinks';
import { useChartWithGatesDefaultProps } from '@/hooks/useChartWithGatesDefaultProps';
import useParamsProjectId from '@/hooks/useParamsProjectId';

import { headerActions } from '@/store/slices/header';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsActions } from '@/store/slices/chartSettings';

import { withExperimentData } from '@/hoc/withExperimentData';
import { withExperimentToken } from '@/hoc/withExperimentToken';
import { withDefaultChartSettings } from '@/hoc/withDefaultChartSettings';
import { withChartData } from '@/hoc/withChartData';

import { ExperimentModalsProvider } from '@/contexts/ExperimentModalsContext';

import ExperimentPageHeader from '@/components/common/ExperimentPageHeader';
import ExperimentPageDefaultHeaderControls from '@/components/common/ExperimentPageHeader/ExperimentPageDefaultHeaderControls';
import DatasetChartHeading from '@/components/charts/DatasetChartHeading';
import SingleChartWithGates from '@/components/charts/SingleChartWithGates';
import GateList from '@/pages/Analytics/components/Scatterplot/components/GateList';

import styles from './Dataset.module.scss';
import DatasetChart from './components/DatasetChart';

const cn = classnames.bind(styles);

const Dataset = () => {
  const appDispatch = useAppDispatch();
  const { generateExperimentLink, generateAnalyticsLink } = useLinks();
  const datasetsStr = useSearchParamsDatasets();

  const { allProps, ...componentsProps } = useChartWithGatesDefaultProps({});

  const currentAppScan = useSelector(experimentSelectors.selectCurrentScan);

  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);
  const [searchParams] = useSearchParams();
  const projectId = useParamsProjectId();

  const totalCages = useMemo(
    () => experimentData?.numberOfCages ?? currentAppScan?.summary.numberOfCages,
    [currentAppScan?.summary, experimentData?.numberOfCages]
  );

  useEffect(() => {
    const withAnalytics = searchParams.get('withAnalytics') ?? false;
    appDispatch(headerActions.removeAllLinks());

    if (projectId) {
      appDispatch(headerActions.setNewLink({ title: 'projects', link: `/projects` }));
      appDispatch(headerActions.setNewLink({ title: 'project', link: `/project/${projectId}` }));
    } else {
      appDispatch(headerActions.setNewLink({ title: 'dashboard', link: '/' }));
    }

    appDispatch(
      headerActions.setNewLink({
        title: 'experiment',
        link: generateExperimentLink(EExperimentTabId.data),
      })
    );

    if (withAnalytics && datasetsStr) {
      appDispatch(
        headerActions.setNewLink({
          title: 'data',
          link: generateAnalyticsLink(),
        })
      );
    }

    return () => {
      appDispatch(chartSettingsActions.clearRanges());
    };
  }, []);

  return (
    <ExperimentModalsProvider>
      <main className={cn('dataset')}>
        <ExperimentPageHeader
          title={experimentData?.experimentName ?? ''}
          experimentData={experimentData}
          totalCages={totalCages}
          isBasicInfoHidden
          experimentControls={
            <ExperimentPageDefaultHeaderControls
              buttonClassName={cn('dataset__navigator-button')}
              selectClassName={cn('dataset__select')}
            />
          }
        />
        <hr className={cn('dataset__divider')} />
        <SingleChartWithGates
          ChartHeadingComponent={DatasetChartHeading}
          ChartComponent={DatasetChart}
          GatesComponent={GateList}
          pageType={EPageWithChartType.singleChart}
          {...componentsProps}
        />
      </main>
    </ExperimentModalsProvider>
  );
};

export default withExperimentToken(
  withExperimentData(
    withChartData(withDefaultChartSettings(Dataset, EPageWithChartType.singleChart), EPageWithChartType.singleChart)
  )
);
