import { EModebarTools } from '@/types/charts';

export const toolsForDrawingGates = [
  EModebarTools.rectangle,
  EModebarTools.polygon,
  EModebarTools.ellipse,
  EModebarTools.polar,
  EModebarTools.splitGate,
  EModebarTools.range,
];

export const toolsWithMouseUpHandler = [
  EModebarTools.rectangle,
  EModebarTools.ellipse,
  EModebarTools.polar,
  EModebarTools.splitGate,
  EModebarTools.range,
];

export const TITLE_HEIGHT_BY_TYPE = {
  default: 22,
  minimized: 16,
};

export const TITLE_SIZE_BY_TYPE = {
  default: 17,
  minimized: 12,
};

export const TITLE_GAP_BY_TYPE = {
  default: 5,
  minimized: 2,
};

export const TITLE_OFFSET_BY_TYPE = {
  default: 10,
  minimized: 5,
};

export const HANDLERS_OFFSET = 4;
export const HANDLERS_SIZE = 8;

export const HARDCODED_TOP_Y_VALUE = Infinity;

export const PLOT_RANGE_NAME_FOR_LINE_HISTOGRAM = 'line-histogram-general';

export const LABEL_MENU_R = 15;
