import JSZip from 'jszip';

import { downloadByUrl } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

const generateDownloadImagesFileName = (cageList: (TEntity | undefined)[], experimentNamePrefix = '') => {
  const cage = cageList.find((cageItem) => cageItem);
  if (!cage) {
    return `${experimentNamePrefix}_images.zip`;
  }
  const [_, , scanName, laneName] = (cage.cropsPaths[0] ?? '').split('/');
  if (scanName && laneName) {
    return `${experimentNamePrefix}${scanName}_${laneName}_${cage.cageCropFileName}.zip`;
  }

  return `${experimentNamePrefix}${cage.cageCropFileName}.zip`;
};

export const downloadCagesImages = async (
  cageList: (TEntity | undefined)[],
  timelineCompletedTimes: Array<string>,
  globalCageIdMatched?: number,
  experimentName = ''
) => {
  try {
    const zip = new JSZip();
    const imagesZipFolder = zip.folder(`images - ${globalCageIdMatched} global cage id`);
    const experimentNamePrefix = experimentName ? `${experimentName}_` : '';

    if (!imagesZipFolder) {
      showErrorToast('Cannot create an images folder in the archive');
      return;
    }

    cageList.forEach((cage, index) => {
      if (cage) {
        const time = timelineCompletedTimes[index] ?? '';
        const imgCanvas = document.getElementById(`canvas_${time}`) as HTMLCanvasElement;
        imagesZipFolder.file(
          `${experimentNamePrefix}${cage.cageCropFileName}_${time}.png`,
          imgCanvas.toDataURL().split('base64,')[1],
          {
            base64: true,
          }
        );
      }
    });

    const zipData = await zip.generateAsync({
      type: 'blob',
      streamFiles: true,
    });
    const url = window.URL.createObjectURL(zipData);
    downloadByUrl(url, generateDownloadImagesFileName(cageList, experimentNamePrefix));
  } catch (error) {
    showErrorToast(getErrorMessage(error));
  }
};
