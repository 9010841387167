import { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { useUserRole } from '@/hooks';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { authAPI } from '@/store/services/auth';

import CheckboxInput from '@/components/common/CheckboxInput';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';

import styles from '../UserList.module.scss';

const cn = classnames.bind(styles);

type TUserMFARequiredProps = {
  data: TUser;
};

const UserMFARequired: FC<TUserMFARequiredProps> = ({ data: user }) => {
  const confirmationModal = useConfirmationModalContext();
  const { isUserUpdateAllowed } = useUserRole();
  const [changeMFASettings, { isLoading }] = authAPI.useChangeMFAMutation();

  const handleChange = useCallback(async () => {
    const isConfirmed = await confirmationModal.onOpen({
      confirmationText: (
        <>
          Are you sure you want to set MFA as {user.mfa.required ? 'optional' : 'required'} for user <br />
          <strong>{user.displayName}</strong>?
        </>
      ),
      approveButtonText: user.mfa.required ? 'Set as optional' : 'Set as required',
    });
    if (!isConfirmed) {
      return;
    }
    changeMFASettings({ required: !user.mfa.required, username: user.username })
      .then((result) => {
        if ('error' in result) {
          showErrorToast(getErrorMessage(result.error));
        } else {
          toast.success(
            `MFA as required was ${user.mfa.required ? 'disabled' : 'enabled'} successfully for ${user.displayName}`
          );
        }
      })
      .catch((err) => {
        showErrorToast(getErrorMessage(err));
      });
  }, [isUserUpdateAllowed, user]);

  if (!isUserUpdateAllowed) {
    return user.mfa.required ? (
      <div className={cn('badge', 'badge_red')}>Required</div>
    ) : (
      <div className={cn('badge', 'badge_yellow')}>Optional</div>
    );
  }

  return (
    <div className={cn('switch')}>
      <CheckboxInput disabled={isLoading} checked={user.mfa.required} onChange={handleChange} theme="light" isSwitch />
      <small className={cn('small')}>MFA {user.mfa.required ? 'required' : 'optional'}</small>
    </div>
  );
};

export default UserMFARequired;
