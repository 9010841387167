import { object, string, number, record } from 'zod';

const tableFileNamesSchema = object({
  original: string(),
  zip: string().optional(),
  optimized: string().optional(),
  optimizedZip: string().optional(),
});

const datasetFilesSchema = object({
  primaryAnalysis: tableFileNamesSchema,
  cageSummaryTable: tableFileNamesSchema,
  cageContours: tableFileNamesSchema.optional(),
  cellContours: tableFileNamesSchema.optional(),
  barcodeCenters: tableFileNamesSchema.optional(),
  cagingSegmentationCenters: tableFileNamesSchema.optional(),
});

const datasetFoldersSchema = object({
  cageCropsThumbnails: string().optional(),
});

const datasetSchema = object({
  name: string(),
  path: string(),
  datasetFiles: datasetFilesSchema.optional(),
  datasetFolders: datasetFoldersSchema.optional(),
});

const summarySchema = object({
  numberOfCellsDetected: number(),
  numberOfCages: number(),
  numberOfCellsCaged: number(),
  percentageOfCellsCaged: number(),
  numberOfCellsOutsideCage: number(),
  percentageOfCellsOutsideCage: number(),
});

const assaySchema = object({
  id: string(),
  name: string(),
  icon: string(),
  properties: record(string(), string()).optional(),
});

export const laneSchema = object({
  id: string(),
  dataset: datasetSchema,
  summary: summarySchema,
  channels: string().array(),
  channelsProperties: record(string(), assaySchema).optional(),
  cageCropsPath: string(),
  pyramidPath: string(),
  sampleName: string(),
  sampleFriendlyName: string(),
});
