import { array, boolean, discriminatedUnion, literal, number, object, record, string, union } from 'zod';

export const variableItemsSchema = discriminatedUnion('type', [
  object({
    name: string(),
    type: literal('str'),
    description: string().nullable(),
    value: string(),
  }),

  object({
    name: string(),
    type: literal('list'),
    description: string().nullable(),
    value: string(),
  }),

  object({
    name: string(),
    type: literal('range'),
    description: string().nullable(),
    value: string(),
  }),

  object({
    name: string(),
    type: literal('lane'),
    description: string().nullable(),
    value: string(),
  }),

  object({
    name: string(),
    type: literal('locations'),
    description: string().nullable(),
    value: number().nullable(),
  }),

  object({
    name: string(),
    type: literal('bool'),
    description: string().nullable(),
    value: boolean(),
  }),

  object({
    name: string(),
    type: literal('float'),
    description: string().nullable(),
    value: number(),
  }),

  object({
    name: string(),
    type: literal('table'),
    description: string().nullable(),
    value: array(record(string(), union([number(), string()]))),
  }),
]);

export const experimentSettingsSchema = object({
  category: string(),
  variables: variableItemsSchema.array(),
});

export const instrumentSchema = object({
  id: string(),
  name: string(),
  settings: experimentSettingsSchema.array(),
});

const projectSchema = object({
  id: string(),
});

export const experimentSchema = object({
  id: string(),
  experimentId: string(),
  name: string(),
  experimentName: string(),
  createdAt: string(),
  dateCreated: string(),
  updatedAt: string(),
  dateModified: string(),
  startedAt: string(),
  experimentDate: string(),
  localId: string(),
  localPath: string(),
  project: projectSchema,
  instrument: instrumentSchema,
  settings: experimentSettingsSchema.array(),
});

export const downloadFileSchema = object({
  cdnUri: string(),
  description: string(),
  name: string(),
  fileSize: number(),
  fileType: string(),
  lastModified: string().optional(),
});
