import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { navigatorActions, navigatorSelectors, TRenderOption } from '@/store/slices/navigator';
import { throttle } from '@/helpers';

import useParamsExperimentId from '../useParamsExperimentId';
import { getInitialRenderOptionsByChannel, saveRenderOptionsInLocalStorage } from './helpers';
import { useAppDispatch } from '../useAppDispatch';

const useSavedChannelOptions = ({ channelId }: { channelId: string }) => {
  const appDispatch = useAppDispatch();

  const experimentId = useParamsExperimentId();
  const channelsData = useSelector(navigatorSelectors.selectRenderOptions);
  const initialChannelOptions = useMemo(() => getInitialRenderOptionsByChannel(channelId), [channelId]);
  const isResetOptionsDisabled = useMemo(() => {
    if (!channelsData?.[channelId]) return false;
    return isEqual(initialChannelOptions, channelsData[channelId]);
  }, [channelsData?.[channelId], channelId, experimentId]);

  const throttledSaveRenderOptionsInLS = throttle(
    (options: { experimentId: string; channelId: string; options: Partial<TRenderOption> }) => {
      saveRenderOptionsInLocalStorage(options, channelsData);
    },
    300
  );

  const onResetOptionsClick = () => {
    const payload = {
      experimentId,
      channelId,

      options: initialChannelOptions,
    };
    appDispatch(navigatorActions.setRenderOptions(payload));
    throttledSaveRenderOptionsInLS(payload);
  };

  return { isResetOptionsDisabled, onResetOptionsClick, throttledSaveRenderOptionsInLS };
};

export default useSavedChannelOptions;
