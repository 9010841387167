import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { Compound, Reagent, Stain } from '@/graphql/API';

import {
  experimentRunDesignSelectors,
  TRunDesignConsumable,
  TRunDesignConsumableToUse,
} from '@/store/slices/experimentRunDesign';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';

import AddToTableButton from '@/components/runDesign/AddToTableButton';

import { EDesignStep } from '@/pages/experiment-run-design/types';
import { isCompoundConsumable, isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';
import { useSelector } from 'react-redux';
import styles from '@/components/runDesign/ConsumableComponent/Consumable.module.scss';
import PreSelectedConsumable from '@/components/runDesign/ConsumableComponent/PreSelectedConsumable';

import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context';
import useReagentSuggestions from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useReagentSuggestions';
import useReagentSearch from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useReagentSearch';
import useReagentFilter from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/useReagentFilter';
import ReagentFilterBlock from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock';
import {
  reagentSearchResultInformationList,
  reagentSearchResultsGridLayout,
  ReagentSearchResultsHeading,
  ReagentSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/ReagentSearchResults';
import {
  incubationReagentSearchResultsGridLayout,
  IncubationReagentSearchResultsHeading,
  IncubationReagentSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/IncubationReagentSearchResults';

const cn = classnames.bind(styles);

export type TReagentCellClick = (
  consumableToUse: Nullable<TRunDesignConsumableToUse>,
  reagentPos: { laneId: string; componentId: string; reagentIndex: number }
) => void;

export type TReagentCell = {
  usedReagent?: Nullable<TRunDesignConsumableToUse>;
  preselectedUsedReagent?: Nullable<TRunDesignConsumableToUse>;
  usedReagentIndex: number;
  onPreselectedClick: TReagentCellClick;
  onAddNew: TReagentCellClick;
  laneId: string;
  componentId: string;
  isEdit?: boolean;
  withWaves?: boolean;
};

const EComponentType = {
  select: 'select',
  addNew: 'addNew',
  reagent: 'reagent',
  preselected: 'preselected',
} as const;

type TComponentType = keyof typeof EComponentType;

const ReagentCell: FC<TReagentCell> = ({
  usedReagent,
  preselectedUsedReagent,
  usedReagentIndex,
  isEdit,
  laneId,
  componentId,
  onAddNew,
  onPreselectedClick,
  withWaves,
}) => {
  const { openModal, setConfig } = useReagentModalContext();
  const { getSuggestions } = useReagentSuggestions(componentId);
  const { getData } = useReagentSearch(componentId);

  const { currentStep } = useRunDesignLocation();

  const [componentType, setComponentType] = useState<TComponentType>(EComponentType.addNew);
  const component = useSelector(experimentRunDesignSelectors.selectComponentById(componentId));

  const preselectedReagentConsumable = useMemo(
    () =>
      isReagentConsumable(preselectedUsedReagent?.consumable) ||
      isStainConsumable(preselectedUsedReagent?.consumable) ||
      isCompoundConsumable(preselectedUsedReagent?.consumable)
        ? preselectedUsedReagent?.consumable
        : null,
    [preselectedUsedReagent?.consumable]
  );

  const reagentConsumable = useMemo(() => {
    const isValidConsumable =
      isReagentConsumable(usedReagent?.consumable) ||
      isStainConsumable(preselectedUsedReagent?.consumable) ||
      isCompoundConsumable(preselectedUsedReagent?.consumable);
    return (usedReagent?.consumable && isValidConsumable ? usedReagent.consumable : null) as Nullable<
      Reagent | Stain | Compound
    >;
  }, [usedReagent?.consumable]);

  const onSelectChange = useCallback(
    (selectedReagent: Nullable<Reagent | Stain | Compound>) => {
      const newReagent: Nullable<TRunDesignConsumableToUse> = selectedReagent
        ? {
            ...usedReagent,
            consumable:
              selectedReagent ?? ({ id: '', name: '', __typename: 'Reagent', type: 'Reagent' } as TRunDesignConsumable),
            __typename: 'ConsumableToUse',
          }
        : null;
      onAddNew(newReagent, { componentId, laneId, reagentIndex: usedReagentIndex });
    },
    [onAddNew, laneId, componentId, usedReagentIndex]
  );

  const handlePreselectedClick = useCallback(() => {
    if (!preselectedUsedReagent?.consumable?.id) {
      return;
    }
    onPreselectedClick(preselectedUsedReagent, { laneId, componentId, reagentIndex: usedReagentIndex });
  }, [preselectedUsedReagent?.consumable?.id, usedReagentIndex, laneId, componentId]);

  const handleEditClick = useCallback(() => {
    const updatedComponentType = getComponentType();

    setConfig({
      title: `Lane ${laneId} / ${currentStep === EDesignStep.incubation ? 'Treatment' : 'Reagent'} ${
        usedReagentIndex + 1
      }`,
      description: `Search for ${component?.name}${component?.name ? ' ' : ''}${
        currentStep === EDesignStep.incubation ? 'treatment' : 'reagent'
      }. Use filters to narrow down your search.`,
      current: usedReagent?.consumable ?? null,
      search: {
        placeholder: 'Reagent name',
        getSuggestions,
        getData,
        result: {
          headingRenderer:
            currentStep === EDesignStep.incubation
              ? IncubationReagentSearchResultsHeading
              : ReagentSearchResultsHeading,
          rowRenderer:
            currentStep === EDesignStep.incubation ? IncubationReagentSearchResultsRow : ReagentSearchResultsRow,
          cssGridLayout:
            currentStep === EDesignStep.incubation
              ? incubationReagentSearchResultsGridLayout
              : reagentSearchResultsGridLayout,
          informationList: reagentSearchResultInformationList,
        },
      },
      filter:
        currentStep === EDesignStep.incubation
          ? null
          : {
              hook: useReagentFilter,
              renderer: ReagentFilterBlock,
            },
      submitButtonText: 'Add reagent',
      onSelect: (selectedReagent: Nullable<Reagent | Stain>) => {
        onSelectChange(selectedReagent);
        setComponentType(updatedComponentType);
      },
    });
    openModal();
  }, [usedReagent?.consumable.id]);

  const handleAddNewClick = useCallback(() => {
    const updatedComponentType = getComponentType();

    setConfig({
      title: `Lane ${laneId} / ${currentStep === EDesignStep.incubation ? 'Treatment' : 'Reagent'} ${
        usedReagentIndex + 1
      }`,
      description: `Search for ${component?.name}${component?.name ? ' ' : ''}${
        currentStep === EDesignStep.incubation ? 'treatment' : 'reagent'
      }. Use filters to narrow down your search.`,
      search: {
        placeholder: currentStep === EDesignStep.incubation ? 'Treatment name' : 'Reagent name',
        getSuggestions,
        getData,
        result: {
          headingRenderer:
            currentStep === EDesignStep.incubation
              ? IncubationReagentSearchResultsHeading
              : ReagentSearchResultsHeading,
          rowRenderer:
            currentStep === EDesignStep.incubation ? IncubationReagentSearchResultsRow : ReagentSearchResultsRow,
          cssGridLayout:
            currentStep === EDesignStep.incubation
              ? incubationReagentSearchResultsGridLayout
              : reagentSearchResultsGridLayout,
          informationList: reagentSearchResultInformationList,
        },
      },
      filter:
        currentStep === EDesignStep.incubation
          ? null
          : {
              hook: useReagentFilter,
              renderer: ReagentFilterBlock,
            },
      submitButtonText: currentStep === EDesignStep.incubation ? 'Add treatment' : 'Add reagent',
      onSelect: (selectedReagent: Nullable<Reagent | Stain>) => {
        onSelectChange(selectedReagent);
        setComponentType(updatedComponentType);
      },
    });
    openModal();
  }, [openModal, onSelectChange, laneId, usedReagentIndex, setConfig, componentId]);

  const handleDeleteClick = () => {
    onSelectChange(null);
  };

  const getComponentType = useCallback((): TComponentType => {
    if (usedReagent?.consumable.id) {
      return EComponentType.reagent;
    }

    if (preselectedUsedReagent?.consumable.id) {
      return EComponentType.preselected;
    }

    return EComponentType.addNew;
  }, [usedReagent?.consumable.id, preselectedUsedReagent?.consumable.id]);

  useEffect(() => {
    const updatedComponentType = getComponentType();

    setComponentType(updatedComponentType);

    return () => {
      const newUpdatedComponentType = getComponentType();

      setComponentType(newUpdatedComponentType);
    };
  }, [preselectedUsedReagent?.consumable.id, usedReagent?.consumable.id, isEdit, usedReagentIndex]);

  return (
    <div className={cn('consumable-container')}>
      {reagentConsumable && usedReagent?.consumable.id && (
        <ConsumableComponent
          onClick={isEdit ? handleEditClick : null}
          deleteReagent={handleDeleteClick}
          consumable={reagentConsumable}
          withWaves={withWaves}
        />
      )}
      {componentType === EComponentType.preselected && isEdit && preselectedReagentConsumable?.id && (
        <PreSelectedConsumable
          onClick={handlePreselectedClick}
          consumable={preselectedReagentConsumable}
          onPlusClick={handleAddNewClick}
        />
      )}
      {componentType === EComponentType.addNew && isEdit && (
        <AddToTableButton
          onClick={handleAddNewClick}
          text={currentStep === EDesignStep.incubation ? 'Add treatment' : 'Add reagent'}
        />
      )}
      {componentType === EComponentType.preselected && !isEdit && preselectedUsedReagent?.consumable.id && <div />}
      {componentType === EComponentType.addNew && !isEdit && <div />}
    </div>
  );
};

export default ReagentCell;
