import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { isNumber } from '@/helpers';
import { EPreprocessingObjectType, EStepName } from '@/store/slices/preprocessing/types';
import Gates, { TGatesProps } from '../../components/Gates';

const stepsWithApplyForAllDatasets = [EStepName.stepCellKillingReviewCells, EStepName.stepCellKillingReviewCellsTarget];

const CellKillingGates: FC<Partial<TGatesProps>> = (props) => {
  const cells = useSelector(preprocessingSelectors.selectCellTypes);
  const cellShapes = useSelector(preprocessingSelectors.selectGeneralCellShapes);
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);

  const withApplyForAllDatasets = useMemo(
    () => stepsWithApplyForAllDatasets.includes(currentStep) && isNumber(currentDatasetIndex),
    [currentStep, currentDatasetIndex]
  );

  return (
    <Gates
      {...props}
      type={EPreprocessingObjectType.cells}
      shapes={cellShapes}
      count={1}
      items={cells}
      withApplyForAllDatasets={withApplyForAllDatasets}
    />
  );
};

export default memo(CellKillingGates);
