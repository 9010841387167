import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import { LoadStatusType, TLoadStatusType } from '@/store/slices/violin';

import styles from './ViolinPopover.module.scss';

const cn = classnames.bind(styles);

type TLoadStatusProps = {
  status: TLoadStatusType;
};

const StatusTitleMap: Record<TLoadStatusType, string> = {
  empty: 'No data for this gene/lane',
  loaded: 'Success loaded',
  loading: 'Loading',
  error: "File don't exist for this gene/lane",
};

const statusTypeStyleModifiers = Object.keys(LoadStatusType).reduce((acc: Record<string, string>, el) => {
  acc[el] = `_${el}`;
  return acc;
}, {});

const LoadStatus: FC<TLoadStatusProps> = ({ status = LoadStatusType.loading }) => (
  <span className={cn('load-status', statusTypeStyleModifiers[status])} {...addTooltip(StatusTitleMap[status])} />
);

export default memo(LoadStatus);
