import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import Skeleton from 'react-loading-skeleton';

import { EChartType } from '@/types/charts';
import { formatRangeObj } from '@/helpers/charts/ranges';

import { chartSettingsActions } from '@/store/slices/chartSettings';
import { experimentSelectors } from '@/store/slices/experiment';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import usePlotProxy from '@/hooks/usePlotProxy';
import { useChartRangeHandling } from '@/hooks';
import useHighlightMarker from '@/hooks/charts/useHighlightMarker';

import RangeResetButton from '@/components/charts/RangeResetButton';
import NoDataFound from '@/components/common/NoDataFound';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import PointHoverTemplate, { TPointHoverTemplateData } from '@/components/charts/PointHoverTemplate';

import { KNEE_DATA_CONFIG } from '@/pages/Dataset/constants';

import { CELLS_TRACE_NAME } from './hooks/helpers';
import useKneePlotData from './hooks/useKneePlotData';

import styles from './KneePlot.module.scss';

const cn = classnames.bind(styles);

export type TKneePlotProps = {
  laneId: string;
};

const KneePlot: FC<TKneePlotProps> = ({ laneId }) => {
  const appDispatch = useAppDispatch();

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);

  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');

  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isPlotLoaded, setIsPlotLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isHoverInfoOpen, setIsHoverInfoOpen] = useState(false);
  const [hoverInfoPosition, setHoverInfoPosition] = useState<[number, number]>([0, 0]);
  const [hoverData, setHoverData] = useState<TPointHoverTemplateData>(null);

  const { highlightMarker, removeMarkerHighlight } = useHighlightMarker(plotlyProxy);

  const { resetRangeToDefault, isCustomRangeUsed, handlePlotZoomed } = useChartRangeHandling({
    openedAxisSettingsName: null,
    selectedTool: null,
    graphRef,
    customChartType: EChartType.knee,
  });

  const { plotData, summaryInfo, isLoading } = useKneePlotData(laneId);

  const downloadFileName = useMemo(() => `Knee plot ${experimentName} - ${laneId}`, [experimentName, laneId]);

  const handleRangeResetClick = () => {
    resetRangeToDefault();
  };

  const handleHover = useCallback(
    (data: TPlotMouseEvent) => {
      const point = data.points?.[0];
      if (!point?.x || !point?.y) {
        return;
      }
      const { x: rankNumber, y: numberOfUMIs, customdata, pointIndex, curveNumber } = point;
      setIsHoverInfoOpen(true);

      setHoverInfoPosition([data.event.x, data.event.y]);

      highlightMarker(pointIndex, curveNumber);

      const newHoverData: TPointHoverTemplateData = {
        x: {
          label: 'Barcode rank',
          value: rankNumber,
        },
        y: {
          label: 'Number of molecular barcodes',
          value: numberOfUMIs,
        },
        name: {
          label: 'Point type',
          value: point.data.name === CELLS_TRACE_NAME ? 'Genomics-called cell' : 'Background',
        },
      };
      if (customdata.cbName) {
        newHoverData.cbName = {
          label: 'PBC sequence',
          value: customdata.cbName,
        };
      }
      if (summaryInfo) {
        newHoverData.estimatedNumberOfCells = {
          label: 'Number of Genomics-Called Cells',
          value: summaryInfo['Estimated Number of Cells'],
        };
      }
      setHoverData(newHoverData);
    },
    [highlightMarker, summaryInfo]
  );

  const handleUnHover = useCallback(() => {
    setHoverData(null);
    setIsHoverInfoOpen(false);
    removeMarkerHighlight();
  }, [removeMarkerHighlight]);

  useEffect(() => {
    const layout = {
      xaxis: {
        title: 'Barcode rank',
        type: 'log',
      },
      yaxis: {
        zeroline: false,
        title: 'Number of molecular barcodes (log10)',
        type: 'log',
      },
      margin: {
        pad: 0,
        l: 50,
        r: 0,
        t: 0,
        b: 40,
      },
    };

    const isEmpty = plotData.every((trace) => !trace.x.length && !trace.y.length);

    setIsEmptyData(isEmpty);
    if (isEmpty) {
      return;
    }

    plotlyProxy.forceReact(plotData, layout, { ...KNEE_DATA_CONFIG() }, (plot) => {
      const { xaxis, yaxis } = plot.layout;
      if ('range' in xaxis && 'range' in yaxis) {
        appDispatch(
          chartSettingsActions.setPlotRange({
            range: formatRangeObj({
              x: xaxis.range,
              y: yaxis.range,
            }),
          })
        );
      }
      setIsPlotLoaded(true);
    });
  }, [plotlyProxy.id, plotData]);

  useEffect(() => {
    const graphDiv = graphRef.current;

    if (!isPlotLoaded || !graphDiv?.removeAllListeners || !graphDiv?.on) {
      return;
    }

    graphDiv.on('plotly_relayout', (eventData: TPlotRelayoutEvent) => handlePlotZoomed(eventData, 'plotly_relayout')); // event for zoom via selection
    graphDiv.on('plotly_hover', handleHover);
    graphDiv.on('plotly_unhover', handleUnHover);

    return () => {
      graphDiv.removeAllListeners?.('plotly_hover');
      graphDiv.removeAllListeners?.('plotly_relayout');
      graphDiv.removeAllListeners?.('plotly_unhover');
    };
  }, [isPlotLoaded, handleHover, handleUnHover, handlePlotZoomed]);

  useEffect(() => {
    if (!graphRef?.current) {
      return;
    }

    graphRef?.current?.removeAllListeners?.('plotly_doubleclick');
    graphRef.current?.on?.('plotly_doubleclick', () => {
      handleRangeResetClick();
    });

    return () => {
      graphRef?.current?.removeAllListeners?.('plotly_doubleclick');
    };
  }, [plotData, handleRangeResetClick]);

  useEffect(() => {
    setIsReady(false);

    const timeoutId = setTimeout(() => {
      setIsReady(true);
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      className={cn('knee-plot')}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {(!isReady || isLoading) && <Skeleton className={cn('knee-plot__skeleton')} />}
      {!isEmptyData && (
        <div className={cn('knee-plot__controls')}>
          <DownloadChartButton fileName={downloadFileName} graphRef={graphRef} withTime />
          <RangeResetButton onClick={handleRangeResetClick} disabled={!isCustomRangeUsed} />
        </div>
      )}
      <div className={cn('knee-plot__container')}>
        <PointHoverTemplate isOpen={isHoverInfoOpen} data={hoverData} templatePosition={hoverInfoPosition} />
        <div ref={graphRef} id="knee-plot-chart" className={cn('knee-plot__chart')}>
          {isEmptyData && <NoDataFound alignment="center" size="big" textData="Data not found in cloud" />}
        </div>
      </div>
    </div>
  );
};

export default KneePlot;
