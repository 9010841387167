import { useCallback, useState } from 'react';
import { select as d3Select } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getGateById } from '@/helpers/gates';
import { getMin, getMinMax } from '@/helpers/arrays';
import { isNumber } from '@/helpers';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { drawControlElement, drawGateLabel, updateLabelPosition } from './helpers/common';
import { HANDLERS_OFFSET, TITLE_OFFSET_BY_TYPE } from './constants';
import { EGateDragType, TD3DragEvent, TSelectionRectElement, TUseGatesOnPlotPayload } from './types';
import {
  DEFAULT_GAP_BETWEEN_LANES,
  TRangeLineData,
  getPolygonsForRangeGate,
  getRangeGateLinesData,
  getUpdatedLinesData,
  prepareLinesDataList,
} from './helpers/rangeGates';

export function useRangeGate({
  chartContainerParameters,
  plotId,
  plotRange,
  openGateCreationModal,
  displayType,
  handleGateDrag,
  entityLevelGateList,
  isStatic,
  updateGate,
  origDataRange,
  isGateMenuHidden,
}: TUseGatesOnPlotPayload) {
  const chartId = usePlotChartIdContext();
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );

  const [currentRangeGateData, setCurrentRangeGateData] = useState<
    Nullable<{
      group: Nullable<Element>;
      calculatedLanesData: TRangeLineData[];
      x1: number;
      x2: number;
    }>
  >(null);
  const updateControls = (gateId: string, newData: TGatePolygons) => {
    const controls = d3Select(`#${plotId}`)
      .selectAll(`[gate-control-id = 'gate-control_${gateId}'],[control-plot-id = 'control-plot-id_${plotId}']`)
      .nodes() as Nullable<Element>[];

    controls.forEach((controlNode: Nullable<Element>, index: number) => {
      if (!controlNode) return;

      const data = newData[index];

      if (!isNumber(data?.x) || !isNumber(data?.y)) return;

      d3Select(controlNode)
        ?.attr('x', data.x - HANDLERS_OFFSET)
        ?.attr('y', data.y - HANDLERS_OFFSET);
    });
  };

  const updateRangeGate = (
    gateGroup: Element,
    [xPoint, yPoint]: [number, number],
    linesData: TRangeLineData[],
    changedXValue?: number
  ) => {
    const group = d3Select(gateGroup);
    const groupId = group.attr('gate-group-id');
    const gateId = groupId.replace('group_', '');

    const updatedLinesData = getUpdatedLinesData([xPoint, yPoint], linesData, changedXValue);
    const updatedMiddleLineData = updatedLinesData[updatedLinesData.length - 1];
    const newPolygonsForControls = [
      { x: updatedMiddleLineData.x1, y: updatedMiddleLineData.y1 },
      { x: updatedMiddleLineData.x2, y: updatedMiddleLineData.y2 },
    ];

    updateControls(gateId, newPolygonsForControls);

    const lines = group.selectAll(`#${plotId} [gate-id = 'gate_${gateId}']`).nodes() as Element[];

    lines.forEach((line, i) => {
      const lineSelection = d3Select(line);
      lineSelection
        .attr('x1', updatedLinesData[i].x1)
        .attr('x2', updatedLinesData[i].x2)
        .attr('y1', updatedLinesData[i].y1)
        .attr('y2', updatedLinesData[i].y2);
    });

    setCurrentRangeGateData((prevState) => {
      if (!prevState) return null;

      const xPoints = updatedLinesData.map((item) => [item.x1, item.x2]).flat();
      const { min: xMin, max: xMax } = getMinMax(xPoints);

      return {
        ...prevState,
        calculatedLanesData: updatedLinesData,
        x1: xMin,
        x2: xMax,
      };
    });
  };

  const handleRangeMouseDown = useCallback(
    ([xPoint, yPoint]: [number, number]) => {
      if (!chartContainerParameters || currentRangeGateData) return;

      const id = `${Date.now()}`;

      const polygons = [
        { x: xPoint, y: 0 },
        { x: xPoint, y: Infinity },
        { x: xPoint + DEFAULT_GAP_BETWEEN_LANES, y: Infinity },
        { x: xPoint + DEFAULT_GAP_BETWEEN_LANES, y: 0 },
      ];

      const data = drawRangeGate({
        gatePolygons: polygons,
        gateId: id,
        label: '',
        yPoint,
      });

      if (!data?.calculatedLanesData?.length || !data?.group) return;

      setCurrentRangeGateData({
        group: data.group.node(),
        calculatedLanesData: data.calculatedLanesData,
        x1: xPoint,
        x2: xPoint + DEFAULT_GAP_BETWEEN_LANES,
      });
    },
    [chartContainerParameters, plotRange]
  );

  const handleRangeMouseMove = useCallback(
    (eventData: [number, number]) => {
      if (
        !(
          currentRangeGateData?.group &&
          currentRangeGateData.calculatedLanesData?.length &&
          currentRangeGateData.x1 &&
          currentRangeGateData.x2 &&
          chartContainerParameters
        )
      )
        return;

      updateRangeGate(
        currentRangeGateData.group,
        eventData,
        currentRangeGateData.calculatedLanesData,
        currentRangeGateData.x2
      );
    },
    [currentRangeGateData, chartContainerParameters]
  );

  const handleRangeMouseUp = useCallback(() => {
    if (
      !currentRangeGateData?.group ||
      !currentRangeGateData.calculatedLanesData?.length ||
      !plotRange ||
      !chartContainerParameters
    )
      return;

    const values = getPolygonsForRangeGate({
      linesData: currentRangeGateData.calculatedLanesData,
      plotId,
      chartContainerParameters,
      plotRange,
      xAxisScaleType,
      yAxisScaleType,
      origDataRange,
    });

    if (!values) return;

    openGateCreationModal({ type: 'range', model: { x1: values.x1, x2: values.x2, y: values.y } });
    setCurrentRangeGateData(null);
  }, [currentRangeGateData, chartContainerParameters, plotRange]);

  const onRangeGateControlDragStart = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onRangeGateControlDrag = (control: TSelectionRectElement, event: TD3DragEvent) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    const group = control.node()?.parentNode;

    if (!group) return;

    const linesData = getRangeGateLinesData(gateId, plotId);
    const controlX = parseFloat(control.attr('x')) + HANDLERS_OFFSET;
    const controlY = parseFloat(control.attr('y')) + HANDLERS_OFFSET;

    const newX = controlX + event.dx;
    const newY = controlY + event.dy;

    if (!linesData) return;

    handleGateDrag(gateId, EGateDragType.drag);

    const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

    if (!shapesContainer || !group) return;

    const xPoints = linesData.map((item) => [item.x1, item.x2]).flat();
    const xMin = getMin(xPoints);

    const dxForLabel = xMin === controlX ? event.dx : 0;

    if (dxForLabel !== 0 || event.dy !== 0) {
      updateLabelPosition(gateId, [dxForLabel, event.dy], isGateMenuHidden, plotId);
    }

    updateRangeGate(group as Element, [newX, newY], linesData, controlX);
  };

  const onRangeGateControlDragEnd = useCallback(
    (control: TSelectionRectElement) => {
      const controlGateIdAttr = control.attr('gate-control-id');
      const gateId = controlGateIdAttr.split('gate-control_')[1];
      const linesData = getRangeGateLinesData(gateId, plotId);
      const gateGroupElement = control.node()?.parentNode ?? null;

      if (!gateGroupElement) return;
      if (!linesData?.length || !plotRange || !chartContainerParameters) return;

      const values = getPolygonsForRangeGate({
        linesData,
        plotId,
        chartContainerParameters,
        plotRange,
        xAxisScaleType,
        yAxisScaleType,
        origDataRange,
      });

      if (!values) return;

      const gate = getGateById(gateId, entityLevelGateList);

      if (!gate) return;
      const updatedShape = {
        type: 'range',
        model: {
          x1: values.x1,
          x2: values.x2,
          y: values.y,
        },
      } as TGateShape;

      handleGateDrag(gateId, EGateDragType.dragEnd, {
        ...gate,
        shape: updatedShape,
      });
      updateGate(gate, {
        shape: updatedShape,
      });
    },
    [chartContainerParameters, plotRange, entityLevelGateList]
  );

  const controlDragHandler = d3Drag<SVGRectElement, unknown>()
    .on('start', function handleDragStart() {
      const control = d3Select(this);
      onRangeGateControlDragStart(control);
    })
    .on('drag', function handleDrag(event) {
      const control = d3Select(this);
      onRangeGateControlDrag(control, event);
    })
    .on('end', function handleDragEnd() {
      const control = d3Select(this);
      onRangeGateControlDragEnd(control);
    });

  const drawRangeGate = ({
    gatePolygons,
    gateId,
    groupClassName = 'gate-group',
    label = '',
    yPoint,
  }: {
    gateId: string;
    gatePolygons: TGatePolygons;
    cagesData?: TEntitiesByGates;
    gate?: TGate;
    groupClassName?: string;
    label: string;
    yPoint?: number;
  }) => {
    const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
    if (!shapesContainer) return;

    const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn

    const svg = d3Select(`#${plotId} .gates-container`);
    const group = svg
      .append('g')
      .attr('gate-group-id', `group_${gateId}`)
      .attr('class', groupClassName)
      .attr('gate-type', 'range');

    const gateIdAttr = `gate_${gateId}`;

    const lanesData = prepareLinesDataList({ gatePolygons, shapesContainerParameters, yPoint });

    lanesData.forEach((lineData, index) => {
      const line = group
        .append('line')
        .attr('x1', lineData.x1)
        .attr('x2', lineData.x2)
        .attr('y1', lineData.y1)
        .attr('y2', lineData.y2)
        .attr('class', 'gate-element')
        .attr('gate-id', gateIdAttr);

      if (lineData?.isMiddleLine) {
        line.attr('middle-line', true);
      }

      const attributes = [
        {
          attr: 'gate-control-id',
          value: `gate-control_${gateId}`,
        },
      ];

      if (index === lanesData.length - 1) {
        const startLaneControl = drawControlElement({
          parentElement: group,
          x: lineData.x1,
          y: lineData.y1,
          attributes,
        });

        const endLaneControl = drawControlElement({
          parentElement: group,
          x: lineData.x2,
          y: lineData.y2,
          attributes,
        });

        if (!isStatic) {
          startLaneControl.call(controlDragHandler);
          endLaneControl.call(controlDragHandler);
        }
      }
    });

    const labelX = Math.min(lanesData[lanesData.length - 1].x1, lanesData[lanesData.length - 1].x2);
    const labelY = lanesData[lanesData.length - 1].y1;

    drawGateLabel({
      gateContainer: group,
      bgX: labelX + TITLE_OFFSET_BY_TYPE[displayType],
      bgY: labelY,
      textX: labelX + TITLE_OFFSET_BY_TYPE[displayType],
      textY: labelY,
      gateIdAttr,
      label,
      displayType,
      isGateMenuHidden,
    });

    return { group, calculatedLanesData: lanesData };
  };

  return {
    drawRangeGate,
    handleRangeMouseDown,
    handleRangeMouseMove,
    handleRangeMouseUp,
  };
}

export default useRangeGate;
