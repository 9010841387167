import { SVGProps } from 'react';

const PersonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" {...props}>
    <g transform="translate(.933 1.316)" stroke="#1A1A1A" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <circle cx="6.533" cy="3.733" r="3.733" />
      <path d="M13.067 14A6.533 6.533 0 0 0 0 14" strokeLinecap="round" />
    </g>
  </svg>
);

export default PersonIcon;
