import { FC, memo } from 'react';

type TDatasetsToConfirm = {
  datasetDetailsList: TDatasetDetails[];
};

const DatasetsToConfirm: FC<TDatasetsToConfirm> = ({ datasetDetailsList }) => (
  <ul>
    {datasetDetailsList.map((datasetDetails) => (
      <li key={datasetDetails.id}>{datasetDetails.friendlyName ?? datasetDetails.name}</li>
    ))}
  </ul>
);

export default memo(DatasetsToConfirm);
