import { SVGProps } from 'react';

const ArrowsUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#000000"
    {...props}
  >
    <g strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor">
      <path d="M3 20L21 20" />
      <path d="M6 17L6 4M6 4L2 8M6 4L10 8" />
      <path d="M18 17V4M18 4L14 8M18 4L22 8" />
    </g>
  </svg>
);

export default ArrowsUpIcon;
