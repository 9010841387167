import { FC, useCallback, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { EPreprocessingObjectType, EStepName } from '@/store/slices/preprocessing/types';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import type { TCustomCreateGateComponentProps } from '@/components/charts/SingleChartWithGates/types';
import BeadPopover from '../../components/BeadPopover';

const CreateGatePopover: FC<TCustomCreateGateComponentProps> = ({
  mouseUpEventRef,
  mouseDownEventRef,
  closeModal,
  createGate,
  newGateModel,
  xDimension,
  yDimension,
  selectedGate,
  isModalOpen,
}) => {
  const dispatch = useDispatch();
  const chartId = usePlotChartIdContext();

  const cells = useSelector(preprocessingSelectors.selectCellTypes);
  const shapes = useSelector(preprocessingSelectors.selectGeneralCellShapes);
  const cellForGate = useSelector(preprocessingSelectors.selectCellForGate);
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const filteredCells = useMemo(() => {
    const busyCellItems = Object.values(shapes).reduce((acc, shape) => {
      acc[shape.id] = true;
      return acc;
    }, {} as Record<string, boolean>);

    return cells.filter((item) => !busyCellItems[item.uuid]);
  }, [cells, shapes]);

  const handleClose = useCallback(() => {
    dispatch(preprocessingActions.setCellForGate(undefined));
    closeModal();
  }, []);

  const isOpen = useMemo(
    () => isModalOpen && currentStep === EStepName.stepCellKillingDefineCellsTarget,
    [isModalOpen, currentStep]
  );

  const handleSave = useCallback(() => {
    if (!cellForGate) {
      return;
    }

    const gateName = `${cellForGate.name}_gate`;
    createGate({
      gateName,
      newGateModel,
      xDimension,
      yDimension,
      successCallback: closeModal,
      parentGate: selectedGate,
      isCageLevel: false,
      currentChartType,
    });
  }, [newGateModel, xDimension, yDimension, closeModal, selectedGate, cellForGate]);

  const handleSelectBead = useCallback(
    (id: string) => {
      dispatch(preprocessingActions.setCellForGate(id));
    },
    [dispatch]
  );

  return (
    <BeadPopover
      mouseDownEventRef={mouseDownEventRef}
      mouseUpEventRef={mouseUpEventRef}
      items={filteredCells}
      type={EPreprocessingObjectType.cells}
      handleClose={handleClose}
      handleSave={handleSave}
      handleSelectBead={handleSelectBead}
      isOpen={isOpen}
      possibleSave={!!cellForGate}
      selectedBeadId={cellForGate?.uuid ?? ''}
      title="Save gate for cell population"
    />
  );
};

export default memo(CreateGatePopover);
