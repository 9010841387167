import { SVGProps } from 'react';

const PreprocessingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="2 2 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.4062 3.14941V17.1012" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3.43164 10.126L17.382 10.126" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6.91992 4.08496L13.8951 16.1676" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path d="M4.36816 13.6143L16.4495 6.63835" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path d="M4.36523 6.64209L16.4466 13.618" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path d="M6.91895 16.1704L13.8941 4.08779" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path
      d="M15.0099 9.95527C15.0099 12.4974 12.9494 14.558 10.4078 14.558C7.86618 14.558 5.80566 12.4974 5.80566 9.95527C5.80566 7.41317 7.86618 5.35254 10.4078 5.35254C12.9494 5.35254 15.0099 7.41317 15.0099 9.95527Z"
      fill="#66F0FB"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M12.3947 9.95692C12.3947 11.0545 11.505 11.9441 10.4078 11.9441C9.31053 11.9441 8.4209 11.0545 8.4209 9.95692C8.4209 8.85934 9.31053 7.96973 10.4078 7.96973C11.505 7.96973 12.3947 8.85934 12.3947 9.95692Z"
      fill="#none"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default PreprocessingIcon;
