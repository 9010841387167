import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from '@/pages/Experiment/Experiment.module.scss';

import { useGeneratePrimaryAnalysisGates } from '@/components/experiment/GeneratePrimaryAnalysisGatesButton/hooks/useGeneratePrimaryAnalysisGates';

const cn = classnames.bind(styles);

const GeneratePrimaryAnalysisGatesButton: FC = () => {
  const { startGenerating, isGeneratingInProgress } = useGeneratePrimaryAnalysisGates();

  const generatePrimaryAnalysisGates = () => {
    startGenerating();
  };

  return (
    <Button
      tooltip="Generate Primary Analysis Gates"
      onClick={generatePrimaryAnalysisGates}
      color="white"
      className={cn('controls__button')}
      isLoading={isGeneratingInProgress}
    >
      <icons.ImportGateIcon />
    </Button>
  );
};

export default memo(GeneratePrimaryAnalysisGatesButton);
