import { DispatchWithoutAction, Reducer, useReducer } from 'react';
import { isDefined, isObjectKey } from '@/helpers/typeGuards';

const EFilterActionList = {
  setState: 'filter/setState',
  updateStringState: 'filter/updateStringState',
} as const;

const initialFilterState = {
  disease: '',
  tissue: '',
  gender: '',
  growthProperties: '',
  reporter: '',
  geneModification: '',
};

export type TFilterState = Record<keyof typeof initialFilterState, string>;

type TSetState = {
  type: typeof EFilterActionList.setState;
  payload: TFilterState;
};

type TUpdateStringState = {
  type: typeof EFilterActionList.updateStringState;
  payload: {
    field: string;
    value: string;
  };
};

type TFilterStateAction = TSetState | TUpdateStringState;

const filterStateReducer = (state: TFilterState, action: TFilterStateAction) => {
  if (action.type === EFilterActionList.setState) {
    return action.payload;
  }

  if (action.type === EFilterActionList.updateStringState) {
    return {
      ...state,
      [action.payload.field]: action.payload.value,
    };
  }

  return state;
};

const useCellNameFilter = () => {
  const [filterState, dispatchFilterState] = useReducer<Reducer<TFilterState, TFilterStateAction>>(
    filterStateReducer,
    initialFilterState
  );

  const cleanFilters = () => {
    dispatchFilterState({ type: EFilterActionList.setState, payload: initialFilterState });
  };

  return {
    filterState,
    updateFactory: (fieldName: string) => {
      const updateMethodsMap = Object.keys(initialFilterState).reduce<
        Partial<Record<keyof TFilterState, DispatchWithoutAction>>
      >(
        (acc, field) => ({
          ...acc,
          [field]: (value: string) => {
            dispatchFilterState({
              type: EFilterActionList.updateStringState,
              payload: {
                field,
                value,
              },
            });
          },
        }),
        {}
      );

      if (!isObjectKey(fieldName, updateMethodsMap)) {
        return () => null;
      }

      const method = updateMethodsMap[fieldName];

      if (isDefined(method)) {
        return method;
      }

      return () => null;
    },
    cleanFilters,
    // cssGridLayout: '1.5fr 1.5fr 1.1fr 1.1fr 1.3fr 1.4fr',
    cssGridLayout: 'repeat(2, 1fr)',
  };
};

export default useCellNameFilter;
