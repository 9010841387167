import { SVGProps } from 'react';

const ArrowNavigatorFocusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="11" height="12" viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m5.5 1.5-4.5 9h9z"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowNavigatorFocusIcon;
