import { v4 as uuidv4 } from 'uuid';

export const transformReagentsAutocompleteResponse = (reagentFromServerList: TReagent[]) =>
  reagentFromServerList.map((reagentFromServer) => {
    if (!reagentFromServer.id) {
      return {
        ...reagentFromServer,
        id: uuidv4(),
      };
    }
    return reagentFromServer;
  });
