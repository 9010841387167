import { FC } from 'react';

import classnames from 'classnames/bind';

import styles from '../UserList.module.scss';

const cn = classnames.bind(styles);

type TUserEnabledProps = {
  data: TUser;
};

const UserEnabled: FC<TUserEnabledProps> = ({ data }) => {
  if (data.enabled) {
    return <div className={cn('badge', 'badge_green')}>Yes</div>;
  }
  return <div className={cn('badge', 'badge_red')}>No</div>;
};

export default UserEnabled;
