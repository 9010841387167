import { FC, useEffect, memo, useMemo } from 'react';

import { getErrorMessage } from '@/helpers/errors';

import { useParamsExperimentId } from '@/hooks';
import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';

import * as queries from '@/graphql/queries';
import { AuditLogEdge, AuditLogsFilter } from '@/graphql/API';

import { TStandardField } from '@/components/common/StandardTable/types';
import StandardTable from '@/components/common/StandardTable';

import DetailsButton from './Details/DetailsButton';

type TActivityTabProps = {
  searchQuery: string;
  setIsLoading: (value: boolean) => void;
};

const AUDIT_LOG_TABLE_FIELD_LIST = [
  { name: 'actorName', label: 'Author', isSortable: true, isSearchable: true, isFitContent: true },
  { name: 'description', label: 'Description', isSortable: true, isSearchable: true },
  { name: 'occurredAt', label: 'Occurred At', isSortable: true, isFitContent: true, isDateDistance: true },
  { name: 'details', label: '', isFitContent: true, dataComponent: DetailsButton },
] as TStandardField<TAuditLogData>[];

const ActivityTab: FC<TActivityTabProps> = ({ searchQuery, setIsLoading }) => {
  const experimentId = useParamsExperimentId();

  const { edgeList, isLoading, isError, error } = useGraphqlQueryFullList<AuditLogsFilter, AuditLogEdge>({
    query: queries.auditLogs,
    dataName: 'auditLogs',
    input: {
      correlationId: experimentId,
    },
  });

  const auditLogDataList = useMemo(
    () =>
      edgeList.map(({ node }) => ({
        id: node.id,
        actorName: node.actor.name,
        description: node.description,
        details: node.details,
        occurredAt: node.occurredAt,
      })) as TAuditLogData[],
    [edgeList]
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(
    () => () => {
      setIsLoading(false);
    },
    []
  );

  return (
    <StandardTable<TAuditLogData>
      dataList={auditLogDataList}
      fieldList={AUDIT_LOG_TABLE_FIELD_LIST}
      keyFieldName="id"
      searchQuery={searchQuery}
      isLoading={isLoading}
      errorMessage={isError ? getErrorMessage(error) : undefined}
    />
  );
};

export default memo(ActivityTab);
