import { useCallback, useMemo } from 'react';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { EDesignStep } from '@/pages/experiment-run-design/types';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';
import { useSelector } from 'react-redux';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { antnAPI } from '@/store/services/annotation';

const useReagentSuggestions = (componentId = '') => {
  const [fetchAnnotationsSuggests] = antnAPI.useLazyFetchAnnotationsSuggestsQuery();
  const { currentStep } = useRunDesignLocation();
  const component = useSelector(experimentRunDesignSelectors.selectComponentById(componentId));

  const annotationType = useMemo(() => {
    if (currentStep === EDesignStep.incubation) {
      return EAnnotationType.compound;
    }

    return component?.__typename === 'CellKilling' ? EAnnotationType.stain : EAnnotationType.reagent;
  }, [currentStep, component]);

  const getSuggestions = useCallback(
    async (searchText: string) => {
      try {
        const res = await fetchAnnotationsSuggests(
          {
            type: annotationType,
            search: searchText,
          },
          true
        ).unwrap();
        return res ?? [];
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    },
    [annotationType]
  );

  return {
    getSuggestions,
  };
};

export default useReagentSuggestions;
