import { Auth } from 'aws-amplify';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

export const generateJwtToken = async () => {
  try {
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
  } catch (_error) {
    return '';
  }
};

export const getCognitoGroups = async () => {
  try {
    return (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'] ?? [];
  } catch (_error) {
    return [];
  }
};

export const getAwsClientMetadata = () => ({
  sourceUrl: window.location.host,
});

export const refreshSession = async (callBack: () => void) => {
  try {
    const userData = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    userData.refreshSession(session.getRefreshToken(), callBack, getAwsClientMetadata());
  } catch (error) {
    showErrorToast(getErrorMessage(error));
  }
};

export const createChangeJwtTokenListener =
  (tokenChangedHandler: () => void) => (data?: { payload?: { event: string } }) => {
    const event = data?.payload?.event ?? '';
    const changeJwtTokenEvents = ['signIn', 'signUp', 'autoSignIn', 'tokenRefresh'];
    if (changeJwtTokenEvents.includes(event)) {
      tokenChangedHandler();
    }
  };
