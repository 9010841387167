import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { useMatrixOpticalSettingsContext } from '~/src/hooks/runDesign/useMatrixOpticalSettingsContext';

import CustomHeaderWithActions, { TCustomHeaderAction } from '@/components/runDesign/CustomHeaderWithActions';
import icons from '@/components/common/icons';
import { isSomeObjValueDiff } from '@/helpers';

import styles from './SubHeader.scss';
import { defaultSettingsByKeyMap } from '../../constants';

const cn = classnames.bind(styles);

const SubHeader: FC<{ isEditMode: boolean }> = ({ isEditMode }) => {
  const appDispatch = useAppDispatch();
  const isGlobalSettingsView = useSelector(experimentRunDesignSelectors.selectIsGlobalSettingsView);
  const { resetMatrixSettingsToDefault, lanesMatrixSettings } = useMatrixOpticalSettingsContext();

  const actions = useMemo<TCustomHeaderAction[]>(() => {
    const result: TCustomHeaderAction[] = [
      {
        onClick: () => {
          appDispatch(experimentRunDesignActions.setIsGlobalSettingsView(!isGlobalSettingsView));
        },
        onLabel: 'Matrix',
        offLabel: 'Global',
        isSwitch: true,
        isActive: !isGlobalSettingsView,
        title: '',
      },
    ];

    return result;
  }, [isGlobalSettingsView]);

  const subHeaderTitleForGlobalSettings = useMemo(
    () =>
      isEditMode
        ? 'You may edit global settings. Settings will be applied to all lanes and scans.'
        : 'Global settings will be applied to all lanes and scans.',
    [isEditMode]
  );

  const isSomeMatrixSettingsChanged = useMemo(() => {
    const res = lanesMatrixSettings?.map((setting) => {
      const data = {
        exposure: setting.exposure,
        intensity: setting.intensity,
        zOffset: setting.zOffset,
      };
      const isDiff = isSomeObjValueDiff(data ?? {}, defaultSettingsByKeyMap);
      return isDiff;
    });

    return res?.some((isChanged) => isChanged);
  }, [lanesMatrixSettings]);

  const subHeaderTitleForMatrixSettings = useMemo(
    () =>
      isEditMode
        ? 'You may edit optical settings organized by lanes and scans.'
        : 'Review optical settings organized by lanes and scans.',
    [isEditMode]
  );

  return (
    <CustomHeaderWithActions className={cn('sub-header')} actions={actions}>
      <div className={cn('sub-header__title-wrapper')}>
        <span className={cn('sub-header__title')}>
          {isGlobalSettingsView ? subHeaderTitleForGlobalSettings : subHeaderTitleForMatrixSettings}
        </span>
        {isEditMode && isSomeMatrixSettingsChanged && (
          <button type="button" onClick={resetMatrixSettingsToDefault} className={cn('sub-header__button')}>
            <icons.ReloadIcon className={cn('sub-header__button-icon')} />
            <span className={cn('sub-header__button-substring')}>Reset all lanes to defaults</span>
          </button>
        )}
      </div>
    </CustomHeaderWithActions>
  );
};

export default SubHeader;
