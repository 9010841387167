import { SVGProps } from 'react';

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2558 30.5591L21.1231 28.1133H35.5479L33.4152 30.5591H23.2558ZM25.2695 32.8686L28.3355 36.3848L31.4015 32.8686H25.2695ZM37.5616 25.8039H19.1094L17 23.3848L39.671 23.3848L37.5616 25.8039Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterIcon;
