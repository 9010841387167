import { MouseEvent, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames/bind';

import { NDASH, addTooltip } from '@/helpers';

import { useParamsExperimentId } from '@/hooks';
import { useLinks } from '@/hooks/useLinks';

import { experimentSelectors } from '@/store/slices/experiment';
import { appAPI } from '@/store/services/app';
import { navigatorSelectors } from '@/store/slices/navigator';
import { datasetsSelectors } from '@/store/slices/datasets';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import IconsAssaySet from '@/components/common/IconsAssaySet';
import icons from '@/components/common/icons';
import Input from '@/components/common/Input';

import styles from './DatasetDetailsTable.module.scss';

const cn = classnames.bind(styles);

type TDatasetDetailsRowProps = {
  datasetDetails: TDatasetDetails;
};

const DatasetDetailsRow: FC<TDatasetDetailsRowProps> = ({ datasetDetails }) => {
  const { generateChartLink } = useLinks();
  const { openNavigator } = useExperimentModalsContext();

  const experimentId = useParamsExperimentId();

  const scan = useSelector(experimentSelectors.selectScan(datasetDetails.scanId));
  const navigatorLanesSettings = useSelector(navigatorSelectors.selectLanesSettings);
  const isEditMode = useSelector(datasetsSelectors.selectEditMode);

  const [updateExperimentDatasetFriendlyName] = appAPI.useUpdateExperimentDatasetFriendlyNameMutation();

  const isLanesDataExist = useMemo(() => !!Object.values(navigatorLanesSettings).length, [navigatorLanesSettings]);

  const markerNamesValue = useMemo(
    () =>
      datasetDetails.markerList && datasetDetails.markerList.length > 0
        ? datasetDetails.markerList.map(({ marker }) => marker).join(', ')
        : null,
    [datasetDetails.markerList]
  );

  const handleNavigatorButtonClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    openNavigator({ dataset: datasetDetails.dataset });
  };

  const handleSaveValue = (currentValue: string) => {
    updateExperimentDatasetFriendlyName({
      scanId: datasetDetails.scanId,
      laneId: datasetDetails.laneId,
      experimentId,
      friendlyName: currentValue,
    });
  };

  return (
    <tr className={cn('row')}>
      <td className={cn('column', { column_edit: isEditMode })}>
        {isEditMode ? (
          <div onClick={(e) => e.stopPropagation()} role="presentation" className={cn('column__input-wrapper')}>
            <Input initialValue={datasetDetails.friendlyName} handleSave={handleSaveValue} />
          </div>
        ) : (
          datasetDetails.friendlyName
        )}
      </td>
      <td className={cn('column', 'column_center', 'column_time')}>{scan?.timeNode}</td>
      <td className={cn('column')}>
        {datasetDetails.assayList?.length ? (
          <IconsAssaySet className={cn('icons-set')} assayList={datasetDetails.assayList} color="yellow" size={35} />
        ) : (
          <div className={cn('icons-set_empty')}>&ndash;</div>
        )}
      </td>
      <td className={cn('column')}>
        <div className={cn('column__marker-list')} {...addTooltip(markerNamesValue ?? '')}>
          {markerNamesValue ?? NDASH}
        </div>
      </td>
      <td className={cn('column')}>
        <NavLink to={generateChartLink(datasetDetails)}>
          <div {...addTooltip('View chart')} className={cn('control-item')}>
            <icons.ChartViewIcon />
          </div>
        </NavLink>
        <button
          {...addTooltip('Navigator')}
          className={cn('control-item')}
          onClick={handleNavigatorButtonClick}
          disabled={!isLanesDataExist}
          aria-label="Navigator"
        >
          <icons.NavigatorIcon />
        </button>
      </td>
    </tr>
  );
};
export default DatasetDetailsRow;
