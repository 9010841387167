import { getPointsFromChartScale } from './common';

type TEllipseData = {
  rx: number;
  ry: number;
  x: number;
  y: number;
};

type TPayload = {
  shapesContainerParameters: DOMRect;
  plotRange: TPopulationRange;
  newEllipseData: TEllipseData;
};

export const getEllipseDataInChartScale = ({ shapesContainerParameters, plotRange, newEllipseData }: TPayload) => {
  const updatedPolygons = getPointsFromChartScale({
    polygons: [{ x: newEllipseData.x, y: newEllipseData.y }],
    shapesContainerParameters,
    range: plotRange,
  });

  const topYPointInSVG = newEllipseData.y - newEllipseData.ry;
  const topXPointInSVG = newEllipseData.x + newEllipseData.rx;

  const topPoints = getPointsFromChartScale({
    polygons: [{ x: topXPointInSVG, y: topYPointInSVG }],
    shapesContainerParameters,
    range: plotRange,
  });

  const yCenterOnChart = updatedPolygons[0].y;
  const xCenterOnChart = updatedPolygons[0].x;

  const yRadiusInChart = topPoints[0].y - yCenterOnChart;
  const xRadiusInChart = topPoints[0].x - xCenterOnChart;

  if (!updatedPolygons || !yRadiusInChart || !xRadiusInChart) return;

  return {
    x: updatedPolygons[0].x,
    y: updatedPolygons[0].y,
    rx: xRadiusInChart,
    ry: yRadiusInChart,
  };
};
