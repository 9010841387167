import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../CheckboxInput.module.scss';
import type { TCheckboxInputProps, TSwitchProps } from '../index';

const cn = classnames.bind(styles);

type TSwitchBodyProps = Partial<Pick<TCheckboxInputProps, 'label' | 'disabled' | 'labelClassName'>> &
  Partial<Pick<TSwitchProps, 'switchClassName'>>;

const SwitchBody: FC<TSwitchBodyProps> = ({ label, disabled, switchClassName, labelClassName }) => (
  <>
    <span className={cn('checkbox__slider', switchClassName, { checkbox__slider_disabled: disabled })} />
    <span className={cn('checkbox__label_slider', labelClassName)}>{label}</span>
  </>
);
export default SwitchBody;
