import { SVGProps } from 'react';

const MRnaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2 0C2 24.7633 42 13 42 38" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M11 2H33" stroke="black" strokeWidth="4" />
    <path d="M17 10L27 10" stroke="black" strokeWidth="4" />
    <path d="M17 28H27" stroke="black" strokeWidth="4" />
    <path d="M11 36L33 36" stroke="black" strokeWidth="4" />
  </svg>
);

export default MRnaIcon;
