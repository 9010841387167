import { useMemo, FC, memo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { appAPI } from '@/store/services/app';
import { datasetsActions, datasetsSelectors } from '@/store/slices/datasets';

import IconsAssaySet from '@/components/common/IconsAssaySet';
import Select from '@/components/common/Select';

import styles from './ChannelAssay.module.scss';

const cn = classnames.bind(styles);

type TChannelAssayProps = {
  isEditMode: boolean;
  experimentId: string;
  datasetDetails: TDatasetDetails;
};

const ChannelAssay: FC<TChannelAssayProps> = ({ isEditMode, experimentId, datasetDetails }) => {
  const appDispatch = useAppDispatch();

  const filterAssayId = useSelector(datasetsSelectors.selectFilterAssayId);

  const { data: assayList = [] } = appAPI.useFetchAssayListQuery();
  const [updateAssay] = appAPI.useUpdateAssayMutation();
  const [deleteAssay] = appAPI.useDeleteAssayMutation();

  const assay = useMemo(() => datasetDetails?.assay ?? null, [datasetDetails]);

  const assayListOptions = useMemo<TOption[]>(() => {
    const assayOptions = assayList.map((el) => ({
      label: (
        <div className={cn('channel-assay__select-option')}>
          <IconsAssaySet assayList={[el]} color="yellow" size={35} className={cn('channel-assay__icons-set')} />
          <div>{el.name}</div>
        </div>
      ),
      value: el.id,
    })) as TOption[];

    if (!assay?.id) {
      return assayOptions;
    }

    const optionsList: TOption[] = [
      {
        label: (
          <div className={cn('channel-assay__select-option')}>
            <div className={cn('channel-assay__empty-assay')}>&ndash;</div>
            <div>Unselect</div>
          </div>
        ),
        value: `${assay.id}_unselect`,
      },
    ];
    optionsList.push(...assayOptions);

    return optionsList;
  }, [assayList, assay]);

  const isSelectVisible = useMemo<boolean>(
    () => !filterAssayId && !!assayList?.length && isEditMode,
    [filterAssayId, assayList, isEditMode]
  );

  const handleAssaySelectChange = (newAssayId: string) => {
    const isUnselect = newAssayId.includes('_unselect');
    const assayId = newAssayId.replace('_unselect', '');
    const newAssay = assayList.find((assayItem) => assayItem.id === assayId) ?? null;

    if (!newAssay) {
      return;
    }

    const {
      dataset: { scanId, laneId },
      channelId,
    } = datasetDetails;

    if (isUnselect) {
      appDispatch(datasetsActions.changeChannelAssay({ experimentId, channelId, assay: null, scanId, laneId }));
      deleteAssay({
        assayId: newAssay.id,
        experimentId,
        scanId,
        laneId,
        channelId: channelId ?? '',
      });
      return;
    }

    updateAssay({ experimentId, scanId, laneId, channelId, assay: newAssay, isNew: !assay })
      .unwrap()
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  return (
    <>
      {!isSelectVisible && assay && (
        <div className={cn('channel-assay')}>
          <IconsAssaySet assayList={[assay]} color="yellow" size={35} className={cn('channel-assay__icons-set')} />
          {!isSelectVisible && <span>{assay.name}</span>}
        </div>
      )}
      {!isSelectVisible && !assay && <div className={cn('channel-assay__empty-assay')}>&ndash;</div>}
      {isSelectVisible && (
        <Select
          value={assay?.id ?? null}
          theme={themeOptions.light}
          className={cn('channel-assay__select')}
          controlClassName={cn('channel-assay__select-arrow')}
          singleValueClassName={cn('channel-assay__select-single-value')}
          valueContainerClassName={cn('channel-assay__select-value-container')}
          placeholderClassName={cn('channel-assay__select-placeholder')}
          placeholder="Select assay..."
          onChange={handleAssaySelectChange}
          options={assayListOptions}
        />
      )}
    </>
  );
};

export default memo(ChannelAssay);
