import {
  interpolateInferno,
  interpolateTurbo,
  interpolateCividis,
  interpolateYlOrRd,
  interpolateYlGnBu,
  interpolateRdBu,
  interpolateRdYlBu,
  interpolatePurples,
  interpolateYlGn,
  schemeSet3,
  schemeTableau10,
  schemeSet2,
  schemePaired,
  schemeCategory10,
  schemeSet1,
  interpolatePlasma,
  interpolateOranges,
} from 'd3-scale-chromatic';

import { TColorscaleScheme } from '@/types/charts';

const PALLETE_LENGTH = 11;

const schemeToInterpolator = (scheme: readonly string[]) => (index: number) => scheme[index * 10];
const scheme3Interpolator = schemeToInterpolator(schemeSet3);
const schemeTableau10Interpolator = schemeToInterpolator(schemeTableau10);
const schemeSet2Interpolator = schemeToInterpolator(schemeSet2);
const schemePairedInterpolator = schemeToInterpolator(schemePaired);
const schemeCategory10Interpolator = schemeToInterpolator(schemeCategory10);
const schemeSet1Interpolator = schemeToInterpolator(schemeSet1);

export const COLORSCALE_INTERPOLATOR = {
  inferno: interpolateInferno,
  turbo: interpolateTurbo,
  cividis: interpolateCividis,
  ylOrRd: interpolateYlOrRd,
  ylGnBu: interpolateYlGnBu,
  rdBu: interpolateRdBu,
  rdYlBu: interpolateRdYlBu,
  purples: interpolatePurples,
  ylGn: interpolateYlGn,
  schemeSet3: scheme3Interpolator,
  schemeTableau10: schemeTableau10Interpolator,
  schemeSet2: schemeSet2Interpolator,
  schemePaired: schemePairedInterpolator,
  schemeCategory10: schemeCategory10Interpolator,
  schemeSet1: schemeSet1Interpolator,
  plasma: interpolatePlasma,
  oranges: interpolateOranges,
};

const formatColorscaleForPlotly = (interpolator: (t: number) => string) => {
  const colorscale: string[][] = [];

  for (let i = 0; i < PALLETE_LENGTH; i++) {
    const color = interpolator(i / 10) ?? 'rgb(0,0,0)';
    colorscale.push([`${i / 10}`, color]);
  }

  return colorscale;
};

export const DEFAULT_D3_COLORSCALES: TColorscaleScheme = {
  inferno: formatColorscaleForPlotly(interpolateInferno),
  turbo: formatColorscaleForPlotly(interpolateTurbo),
  cividis: formatColorscaleForPlotly(interpolateCividis),
};

export const CONTINUOUS_COLORSCALES: TColorscaleScheme = {
  ...DEFAULT_D3_COLORSCALES,
  ylOrRd: formatColorscaleForPlotly(interpolateYlOrRd),
  ylGnBu: formatColorscaleForPlotly(interpolateYlGnBu),
  rdBu: formatColorscaleForPlotly(interpolateRdBu),
  rdYlBu: formatColorscaleForPlotly(interpolateRdYlBu),
  purples: formatColorscaleForPlotly(interpolatePurples),
  ylGn: formatColorscaleForPlotly(interpolateYlGn),
  plasma: formatColorscaleForPlotly(interpolatePlasma),
};

export const CATEGORICAL_D3_COLORSCALES: TColorscaleScheme = {
  schemeSet3: formatColorscaleForPlotly(scheme3Interpolator),
  schemeTableau10: formatColorscaleForPlotly(schemeTableau10Interpolator),
  schemeSet2: formatColorscaleForPlotly(schemeSet2Interpolator),
  schemePaired: formatColorscaleForPlotly(schemePairedInterpolator),
  schemeCategory10: formatColorscaleForPlotly(schemeCategory10Interpolator),
  schemeSet1: formatColorscaleForPlotly(schemeSet1Interpolator),
};

export const FULL_D3_COLORSCALES: TColorscaleScheme = {
  ...CATEGORICAL_D3_COLORSCALES,
  ...CONTINUOUS_COLORSCALES,
};

export const D3_COLORSCALES_FOR_SCATTER_CHART: TColorscaleScheme = {
  ...DEFAULT_D3_COLORSCALES,
  // defaul Plotly.js sequential colorscale https://plotly.com/javascript/reference/layout/#layout-colorscale-sequential
  oranges: [
    ['0', 'rgb(220,220,220)'],
    ['0.2', 'rgb(245,195,157)'],
    ['0.4', 'rgb(245,160,105)'],
    ['1', 'rgb(178,10,28)'],
  ],
};

export const formatColorscalesForContourCharts = (colorscale: string[][]) => {
  const palette = [...colorscale];
  palette[0] = ['0', 'rgba(0,0,0,0)']; // transparent color is necessary to avoid filling the area with empty data on the contour histogram charts
  return palette;
};
