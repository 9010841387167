import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getUniqueChannelNameList } from '@/helpers/channels';

import { useExperimentContext } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import useParamsExperimentId from '@/hooks/useParamsExperimentId';

import { datasetsActions, datasetsSelectors } from '@/store/slices/datasets';
import { experimentSelectors } from '@/store/slices/experiment';

export function useDatasetsOptionsInit() {
  const appDispatch = useAppDispatch();

  const isOptionsInitialized = useRef(false);

  const experimentId = useParamsExperimentId();
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const channelDetailsList = useSelector(datasetsSelectors.selectChannelDetailsList(experimentId));
  const { chartDataList } = useExperimentContext();

  const uniqueChannelNameList = useMemo(() => {
    const channelIdList = channelDetailsList.map((channelDetails) => channelDetails.channelId) as string[];
    return getUniqueChannelNameList(channelIdList);
  }, [scanList, channelDetailsList]);

  useEffect(() => {
    if (!isOptionsInitialized.current) {
      appDispatch(datasetsActions.setIncludedChannels(uniqueChannelNameList));
      appDispatch(datasetsActions.setIncludedTimePoints(scanList.map((scan) => scan.time)));
      isOptionsInitialized.current = true;
    }
  }, [uniqueChannelNameList, scanList]);

  const resetSelectedChannelDetails = useCallback(() => {
    const detailedChannelIdList = chartDataList
      .map(
        (chartData) =>
          channelDetailsList.find(
            (channelDetails) =>
              channelDetails.scanId === chartData.scanId &&
              channelDetails.laneId === chartData.laneId &&
              channelDetails.channelId === chartData.channelId &&
              channelDetails.marker?.id === chartData.marker?.id
          )?.id
      )
      .filter(Boolean) as string[];
    appDispatch(datasetsActions.setAllChannelDetailsUnchecked());
    appDispatch(datasetsActions.setChannelDetailsChecked({ detailedChannelIdList, isChecked: true }));
  }, [chartDataList]);

  useEffect(() => {
    resetSelectedChannelDetails();
  }, [resetSelectedChannelDetails]);

  return { uniqueChannelNameList, resetSelectedChannelDetails };
}
