import { useEffect, useMemo, useState, useLayoutEffect, ComponentType, FC, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Flip from 'gsap/Flip';
import classnames from 'classnames/bind';
import 'react-loading-skeleton/dist/skeleton.css';

import { EPageWithChartType } from '@/types/charts';

import { handlePlotlyResize } from '@/helpers/plotly';
import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';

import useFlipBlock from '@/hooks/charts/useFlipBlock';
import { useParamsExperimentId } from '@/hooks';

import { selectAxesOptionListForMultiLanes } from '@/store/services/app/selectors';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { datasetsSelectors } from '@/store/slices/datasets';
import { EAxesGroupName } from '@/store/slices/scatterplots';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import type {
  TDatasetChart,
  TDatasetChartHeading,
  TGateList,
  TGeneralComponentProps,
  TCustomCreateGateComponent,
  TCustomUpdateGateComponent,
} from './types';

import styles from './SingleChartWithGates.module.scss';

const cn = classnames.bind(styles);

type TSingleChartWithGatesProps = {
  isMulti?: boolean;
  isHeatmap?: boolean;
  ChartHeadingComponent?: ComponentType<TDatasetChartHeading>;
  ChartComponent: ComponentType<TDatasetChart>;
  GatesComponent?: ComponentType<TGateList>;
  chartProps: Omit<
    TDatasetChart,
    'isTransitionEnd' | 'scatterPlotAxesOptions' | 'isExpandMode' | 'isAsideOpen' | 'pageType'
  >;
  gatesProps: Omit<
    TGateList,
    'isTransitionEnd' | 'scatterPlotAxesOptions' | 'isExpandMode' | 'isAsideOpen' | 'pageType'
  >;
  generalProps: TGeneralComponentProps;
  headingProps: Omit<TDatasetChartHeading, 'isExpandMode' | 'isAsideOpen' | 'pageType'>;
  CustomCreateGateComponent?: TCustomCreateGateComponent;
  CustomUpdateGateComponent?: TCustomUpdateGateComponent;
  className?: string;
  handleAsideMode?: (isAside: boolean) => void;
  handleExpandMode?: (isExpand: boolean) => void;
  chartContainerClassName?: string;
  customScatterAxesOptions?: TOption[];
  headingChildren?: ReactNode;
  customPlotlySelectedHandler?: (newGateData: Nullable<TNewGateModelData>) => void;
  pageType: EPageWithChartType;
  specificAxesGroupName?: EAxesGroupName;
  asideChildren?: ReactNode;
  customGateListClassName?: string;
};

const SingleChartWithGates: FC<TSingleChartWithGatesProps> = ({
  isMulti = false,
  isHeatmap = false,
  ChartHeadingComponent,
  ChartComponent,
  GatesComponent,
  chartProps,
  gatesProps,
  generalProps,
  headingProps,
  CustomCreateGateComponent,
  className,
  handleAsideMode = () => null,
  handleExpandMode = () => null,
  chartContainerClassName,
  customScatterAxesOptions,
  headingChildren,
  customPlotlySelectedHandler,
  pageType,
  specificAxesGroupName,
  asideChildren,
  customGateListClassName,
}) => {
  const chartId = usePlotChartIdContext();

  const experimentId = useParamsExperimentId();
  const [isAsideOpen, setIsAsideOpen] = useState(true);
  const [isWideModeTransition, setIsWideModeTransition] = useState(true);
  const [isCardClicked, setIsCardClicked] = useState(false);

  const channelDetailsList = useSelector(datasetsSelectors.selectChannelDetailsList(experimentId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));

  const {
    flipBlockRef,
    flipBlockClassName,
    flipBlockBackgroundClassName,
    toggleFullScreen,
    isExpandMode,
    isTransitionEnd,
  } = useFlipBlock(handlePlotlyResize, cn('single-chart__content_full-screen'));

  const { scanList, scanId, laneId, chartDataList } = generalProps;

  const uniqueChannelList = useMemo(() => {
    const channelsCollection = new Set<string>();
    channelDetailsList.forEach((channelDetails) => {
      if (channelDetails?.channelId) {
        channelsCollection.add(channelDetails.channelId);
      }
    });

    return Array.from(channelsCollection);
  }, [scanList, channelDetailsList]);

  const showDatasetsSelect = useMemo(
    () => [EPageWithChartType.multiHistogram, EPageWithChartType.matrixView].includes(pageType),
    [pageType]
  );

  const chartLane = useSelector(experimentSelectors.selectLane(scanId, laneId));

  const cageLevelAxesOptionList = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionList(chartLane));

  const { optionList: cageLevelMultiLanesAxesOptionList, optionMapping: dimensionsMapping } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(chartDataList, isObjectEntityEnabled && !isCageLvlForced)
  );

  const objectLevelAxesOptionList = useMemo(
    () => getScatterPlotObjectLevelAxesOptions(uniqueChannelList),
    [uniqueChannelList]
  );

  const scatterPlotAxesOptions = useMemo(() => {
    if (customScatterAxesOptions) {
      return customScatterAxesOptions;
    }

    if (isHeatmap) {
      return cageLevelMultiLanesAxesOptionList;
    }

    if (isMulti) {
      return isObjectEntityEnabled
        ? selectAxesOptionListForMultiLanes(chartDataList, isObjectEntityEnabled)
        : cageLevelMultiLanesAxesOptionList;
    }

    return isObjectEntityEnabled ? objectLevelAxesOptionList : cageLevelAxesOptionList;
  }, [
    customScatterAxesOptions,
    isHeatmap,
    uniqueChannelList,
    isMulti,
    scanList,
    chartDataList,
    cageLevelMultiLanesAxesOptionList,
    isObjectEntityEnabled,
    cageLevelAxesOptionList,
    objectLevelAxesOptionList,
  ]);

  const handleDrawerBtnClick = () => {
    setIsWideModeTransition(false);
  };

  const handleFullScreen = () => {
    toggleFullScreen();
    setIsCardClicked((prev) => !prev);
  };

  const onWideModeAnimationComplete = () => {
    handlePlotlyResize();
    setIsAsideOpen((prevState) => !prevState);
    setIsWideModeTransition(true);
  };

  useLayoutEffect(() => {
    if (!isWideModeTransition) {
      const state = Flip.getState(flipBlockRef.current?.children as HTMLCollection);
      flipBlockRef.current?.classList.toggle(cn('single-chart__content_full-width'));

      Flip.from(state, {
        duration: 0.5,
        ease: 'power1.inOut',
        absolute: true,
        onComplete: onWideModeAnimationComplete,
      });
    }
  }, [isWideModeTransition]);

  useEffect(() => {
    if (!flipBlockRef.current) {
      return;
    }

    if (flipBlockRef.current?.offsetTop < 360) {
      flipBlockRef.current?.style?.setProperty('--container-top-offset', `${flipBlockRef.current?.offsetTop}px`);
    }
  }, [flipBlockRef.current?.offsetTop]);

  useEffect(() => {
    handleAsideMode(isAsideOpen);
  }, [isAsideOpen]);

  useEffect(() => {
    handleExpandMode(isExpandMode);
  }, [isExpandMode, isCardClicked]);

  return (
    <>
      <div onClick={toggleFullScreen} role="presentation" className={flipBlockBackgroundClassName} />
      <div className={cn('single-chart', 'single-chart__wrapper-content', className)}>
        <div
          className={cn('single-chart__content', flipBlockClassName, {
            'single-chart__content_full-screen': isExpandMode,
            'single-chart__content_full-width': !isAsideOpen,
          })}
          ref={flipBlockRef}
        >
          <div className={cn('single-chart__block')}>
            <div className={cn('single-chart__container', chartContainerClassName)}>
              <div className={cn('single-chart__header')}>
                {ChartHeadingComponent ? (
                  <ChartHeadingComponent isAsideOpen={isAsideOpen} isExpandMode={isExpandMode} {...headingProps}>
                    {headingChildren}
                  </ChartHeadingComponent>
                ) : (
                  <div />
                )}
                <Button
                  tooltip="Full screen"
                  color="light"
                  className={cn('single-chart__full-screen-button', {
                    'single-chart__full-screen-button_hide': !(isTransitionEnd && !isExpandMode),
                  })}
                  onClick={handleFullScreen}
                  disabled={isExpandMode}
                >
                  <icons.ExpandScreenIcon />
                </Button>
              </div>

              <ChartComponent
                {...chartProps}
                scatterPlotAxesOptions={scatterPlotAxesOptions}
                dimensionsMapping={dimensionsMapping}
                isTransitionEnd={isTransitionEnd && isWideModeTransition}
                CustomCreateGateComponent={CustomCreateGateComponent}
                isAsideOpen={isAsideOpen}
                isExpandMode={isExpandMode}
                customPlotlySelectedHandler={customPlotlySelectedHandler}
                specificAxesGroupName={specificAxesGroupName}
              />
            </div>

            <button
              type="button"
              onClick={handleDrawerBtnClick}
              className={cn('single-chart__drawer-button')}
              aria-label="toggle chart display"
            >
              <icons.ArrowTimeLeftIcon className={cn('single-chart__drawer-arrow')} />
            </button>
          </div>
          <Button
            color="light"
            className={cn('single-chart__close-modal-button', {
              'single-chart__close-modal-button_visible': isExpandMode && isTransitionEnd,
            })}
            disabled={!isExpandMode}
            onClick={handleFullScreen}
          >
            <icons.CloseIcon />
          </Button>
          <aside className={cn('single-chart__gates')}>
            {GatesComponent && (
              <GatesComponent
                isHoverActionEnabled
                {...gatesProps}
                scatterPlotAxesOptions={scatterPlotAxesOptions}
                isAsideOpen={isAsideOpen}
                isExpandMode={isExpandMode}
                showDatasetsSelect={showDatasetsSelect}
                pageType={pageType}
                specificAxesGroupName={specificAxesGroupName}
                className={customGateListClassName}
              />
            )}
            {asideChildren}
          </aside>
        </div>
      </div>
    </>
  );
};

export default memo(SingleChartWithGates);
