import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TConsumableHeader = {
  children: ReactNode;
  className?: string;
  exName?: string;
  emName?: string;
  withWaves?: boolean;
};

const ConsumableHeader: FC<TConsumableHeader> = ({ children, className, emName, exName, withWaves = true }) => (
  <div className={cn('consumable-header__wrapper', 'consumable-header__text-block', className)}>
    <span>{children}</span>
    <div className={cn('consumable-header__ex-em')}>
      {withWaves && (
        <>
          <span>{exName ?? 'EX'}</span>
          <span>{emName ?? 'EM'}</span>
        </>
      )}
    </div>
  </div>
);

export default ConsumableHeader;
