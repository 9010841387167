import { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import CheckboxInput from '@/components/common/CheckboxInput';
import { isDefined } from '@/helpers/typeGuards';

import styles from './MagnificationSwitch.module.scss';

const cn = classnames.bind(styles);

type TValue = { label: string; value: any };

type TSwitchTwoValue = {
  firstValue: TValue;
  secondValue: TValue;
  selectedValue: 'first' | 'second';
  defaultValue?: 'first' | 'second';
  onChangeValue: (value: TValue) => void;
  disabled?: boolean;
};

const MagnificationSwitch: FC<TSwitchTwoValue> = ({
  onChangeValue,
  firstValue,
  secondValue,
  selectedValue,
  defaultValue,
  disabled,
}) => {
  const [isFirstSelected, setIsFirstSelected] = useState(selectedValue === 'second');

  useEffect(() => {
    setIsFirstSelected(selectedValue === 'second');
  }, [selectedValue]);

  const handleChangeValue = useCallback(() => {
    if (disabled) {
      return;
    }
    onChangeValue(selectedValue === 'second' ? firstValue : secondValue);
  }, [firstValue, secondValue, onChangeValue, disabled]);

  return (
    <div className={cn('switch')}>
      <span className={cn('switch__value', { switch__value_disabled: disabled })}>{firstValue.label}</span>
      <CheckboxInput
        theme="run-design-table"
        onChange={handleChangeValue}
        isSwitch
        checked={isFirstSelected}
        switchClassName={cn('switch-input', {
          'switch-input_changed': isDefined(defaultValue) && defaultValue !== selectedValue,
        })}
      />
      <span className={cn('switch__value', { switch__value_disabled: disabled })}>{secondValue.label}</span>
    </div>
  );
};

export default MagnificationSwitch;
