import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigatorSelectors } from '@/store/slices/navigator';
import { experimentSelectors } from '@/store/slices/experiment';

const useNavigatorCurrentDatasetName = (forcedName: string) => {
  const [datasetName, setDatasetName] = useState('');

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const currentScanData = scanList.find(({ id }) => id === currentScanId);
  const currentLaneData = currentScanData?.lanes.find(({ id }) => id === currentLaneId);
  const currentDatasetName = currentLaneData?.dataset.name ?? '';

  useEffect(() => {
    setDatasetName(forcedName);
  }, [forcedName]);

  useEffect(() => {
    setDatasetName(currentDatasetName);
  }, [currentDatasetName]);

  return datasetName;
};

export default useNavigatorCurrentDatasetName;
