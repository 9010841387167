import { SVGProps } from 'react';

const ImportGateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" viewBox="-1 -1 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M14 10v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h4M11 3h6M14 0v6" />
    </g>
  </svg>
);

export default ImportGateIcon;
