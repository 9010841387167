import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import { TPanelColor } from '@/helpers/interfaces';

import styles from '../Panel.module.scss';

const cn = classnames.bind(styles);

type TPanelDescription = {
  className?: string;
  children?: ReactNode;
  withDivide?: boolean;
  type?: TPanelColor;
};

const PanelDescription: FC<TPanelDescription> = ({ className, children = null, withDivide = true, type }) =>
  children && (
    <div
      className={cn(
        'panel-description',
        { 'panel-description_divide': withDivide, 'panel-description_divide-dark': withDivide && type === 'light' },
        className
      )}
    >
      {children}
    </div>
  );

export default PanelDescription;
