import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { filterGateListByEntityLevel } from '@/helpers/gates';

import { appAPI } from '@/store/services/app';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import type {
  TGeneralChartProps,
  TDatasetChartHeading,
  TDatasetChart,
  TGateList,
} from '../components/charts/SingleChartWithGates/types';
import { useExperimentContext } from './useExperimentContext';
import useParamsExperimentId from './useParamsExperimentId';
import { useUpdateExperimentGate } from './gates/useUpdateGates';
import { usePlotChartIdContext } from '../contexts/PlotChartIdContext';

type TUseChartWithGatesProps = TGeneralChartProps;

export type TExcludedProps = 'isTransitionEnd' | 'scatterPlotAxesOptions' | 'isExpandMode' | 'isAsideOpen' | 'pageType';

export const useChartWithGatesDefaultProps = ({
  plotRangeName,
  specificAppLane,
  specificChangeScanHandler,
  specificChangeChannelHandler,
}: Pick<TUseChartWithGatesProps, 'plotRangeName'> & {
  specificAppLane?: Nullable<TLane>;
  specificChangeScanHandler?: TDatasetChartHeading['specificChangeScanHandler'];
  specificChangeChannelHandler?: TDatasetChartHeading['specificChangeChannelHandler'];
}) => {
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const chartId = usePlotChartIdContext();

  const experimentId = useParamsExperimentId();
  const [searchParams] = useSearchParams();
  const scanId = (fullScreenChartData ? fullScreenChartData.dataset.scanId : searchParams.get('scanId')) ?? '';
  const laneId = (fullScreenChartData ? fullScreenChartData.dataset.laneId : searchParams.get('laneId')) ?? '';
  const channelName = (fullScreenChartData ? fullScreenChartData.channelName : searchParams.get('channelName')) ?? '';
  const [createGate, { isLoading: isCreateGatesInProgress }] = appAPI.useCreateExperimentGatesMutation();
  const [updateGate, { isLoading: isUpdateGatesInProgress }] = appAPI.useUpdateExperimentGatesMutation();
  const [deleteGate, { isLoading: isDeleteGatesInProgress }] = appAPI.useDeleteExperimentGatesMutation();
  const [deleteGateChildren, { isLoading: isDeleteGatesChildrenInProgress }] =
    appAPI.useDeleteExperimentGateChildrensMutation();

  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));
  const { data: fullGateList = [], isLoading: isGateListLoading } = appAPI.useFetchExperimentGatesQuery(experimentId);
  const entityLevelGateList = useMemo(
    () => filterGateListByEntityLevel(fullGateList, isObjectEntityEnabled && !isCageLvlForced) ?? [],
    [fullGateList, isObjectEntityEnabled, isCageLvlForced]
  );

  const {
    entitiesByLanesAndGates,
    entitiesLoadedLaneList,
    isError,
    cageEntitiesByLanesAndGates,
    changeSelectedChartData,
    chartDataList,
    selectedChartData,
    currentAppDatasetList,
  } = useExperimentContext();

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const { createExperimentGate, deleteExperimentGate, deleteExperimentGateChildren, updateExperimentGate } =
    useUpdateExperimentGate({
      experimentId,
      fullGateList,
      laneId,
      scanId,
      createGate,
      deleteGate,
      deleteGateChildren,
      updateGate,
    });

  const headingProps = useMemo<Omit<TDatasetChartHeading, 'isExpandMode' | 'isAsideOpen'>>(
    () => ({
      channelName,
      laneId,
      scanId,
      scanList,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      withoutSearchParams: false,
      createGate: createExperimentGate,
      updateGate: updateExperimentGate,
      deleteGate: deleteExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      specificChangeScanHandler,
      specificChangeChannelHandler,
    }),
    [
      channelName,
      laneId,
      scanId,
      scanList,
      createGate,
      deleteGate,
      deleteGateChildren,
      updateGate,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      specificChangeScanHandler,
      specificChangeChannelHandler,
    ]
  );

  const chartProps = useMemo<Omit<TDatasetChart, TExcludedProps>>(
    () => ({
      currentAppLane: specificAppLane ?? currentAppLane,
      entitiesByLanesAndGates,
      entitiesLoadedLaneList,
      entityLevelGateList,
      experimentId,
      isCreateGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      laneId,
      scanId,
      scanList,
      chartDataList,
      currentAppDatasetList,
      withoutSearchParams: false,
      fullGateList,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      createGate: createExperimentGate,
      updateGate: updateExperimentGate,
      deleteGate: deleteExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      channelName,
      plotRangeName,
    }),
    [
      createGate,
      currentAppLane,
      specificAppLane,
      deleteGate,
      deleteGateChildren,
      entitiesByLanesAndGates,
      entitiesLoadedLaneList,
      entityLevelGateList,
      experimentId,
      isCreateGatesInProgress,
      isError,
      isUpdateGatesInProgress,
      laneId,
      scanId,
      scanList,
      updateGate,
      chartDataList,
      currentAppDatasetList,
      fullGateList,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      channelName,
      plotRangeName,
    ]
  );

  const gatesProps = useMemo<Omit<TGateList, TExcludedProps>>(
    () => ({
      cageEntitiesByLanesAndGates,
      changeSelectedChartData,
      chartDataList,
      currentAppLane: specificAppLane ?? currentAppLane,
      entitiesByLanesAndGates,
      entityLevelGateList,
      experimentId,
      isGateListLoading,
      isUpdateGatesInProgress,
      scanList,
      selectedChartData,
      fullGateList,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      createGate: createExperimentGate,
      updateGate: updateExperimentGate,
      deleteGate: deleteExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      channelName,
      plotRangeName,
    }),
    [
      cageEntitiesByLanesAndGates,
      changeSelectedChartData,
      chartDataList,
      currentAppLane,
      specificAppLane,
      deleteGate,
      deleteGateChildren,
      entitiesByLanesAndGates,
      entityLevelGateList,
      experimentId,
      isGateListLoading,
      isUpdateGatesInProgress,
      scanList,
      selectedChartData,
      updateGate,
      fullGateList,
      createGate,
      isCreateGatesInProgress,
      isDeleteGatesChildrenInProgress,
      isDeleteGatesInProgress,
      isError,
      channelName,
      plotRangeName,
    ]
  );

  const generalProps = useMemo(
    () => ({
      scanList,
      scanId,
      laneId,
      chartDataList,
    }),
    [scanList, scanId, laneId, chartDataList]
  );

  return {
    headingProps,
    chartProps,
    gatesProps,
    generalProps,
    allProps: { ...headingProps, ...chartProps, ...gatesProps, ...generalProps },
  };
};
