import { FC } from 'react';
import classnames from 'classnames/bind';

import { datasetsActions } from '@/store/slices/datasets';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { themeOptions } from '@/types/theme';

import Panel from '@/components/common/Panel';
import IconsAssaySet from '@/components/common/IconsAssaySet';
import icons from '@/components/common/icons';

import styles from './AssayCard.module.scss';

const cn = classnames.bind(styles);

type TAssayCardProps = {
  assay: TAssay;
  className?: string;
};

const AssayCard: FC<TAssayCardProps> = ({ assay, className }) => {
  const appDispatch = useAppDispatch();

  const handlePanelClick = () => {
    appDispatch(datasetsActions.setIsDatasetsView(true));
    appDispatch(datasetsActions.setFilterAssayId(assay.id));
  };

  const { samples, timePoints, channels, markers } = assay.properties ?? {};

  return (
    <Panel theme={themeOptions.light} className={cn('assay-card__panel', className)} onClick={handlePanelClick}>
      <Panel.Content className={cn('assay-card__panel_content')}>
        <div className={cn('assay-card__text', 'assay-card__text_title')}>
          <IconsAssaySet
            className={cn('experiment-list-item-card__icons-container')}
            assayList={[assay]}
            color="yellow"
            withPreprocessingIcon
          />
          <span>{assay.name}</span>
        </div>
        <div className={cn('assay-card__total-samples')}>
          <span className={cn('assay-card__total-samples_title')}>Samples total</span>
          <span className={cn('assay-card__total-samples_count')}>{samples}</span>
        </div>
        <div className={cn('assay-card__time-points')}>
          <span className={cn('assay-card__time-points_title')}>Time points</span>
          <div className={cn('assay-card__time-points_container')}>
            {timePoints?.map((timePoint: string) => (
              <div key={timePoint} className={cn('assay-card__time-point')}>
                <icons.ClockIcon />
                <span className={cn('assay-card__time-point_time')}>{timePoint}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={cn('assay-card__additional-info')}>
          <div className={cn('assay-card__additional-info_titles')}>
            <span className={cn('assay-card__additional-info_title')}>Channels</span>
            <span className={cn('assay-card__additional-info_title')}>Markers</span>
          </div>
          <div className={cn('assay-card__additional-info_values')}>
            <span className={cn('assay-card__additional-info_value')}>
              {channels?.length ? channels?.join(', ') : '-'}
            </span>
            <span className={cn('assay-card__additional-info_value')}>
              {markers?.length ? markers?.join(', \r\n') : '-'}
            </span>
          </div>
        </div>
      </Panel.Content>
    </Panel>
  );
};

export default AssayCard;
