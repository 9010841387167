import { FC, SVGProps } from 'react';

const Arrow: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="13" height="24" viewBox="0 0 13 24" fill="none" {...props}>
    <path
      d="M1 22.9906L12 12.0678L1 1.14485"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrow;
