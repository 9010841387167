import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUniqueCageIds, prepareHeatmapData } from '@/pages/Analytics/Heatmap/helpers';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import usePlotProxy from '../usePlotProxy';

type TUseHeatmapPlotSettingsResponse = {
  isSortByDatasetData: boolean;
  toggleIsSortByDatasetData: () => void;
  setOptions: (options: TOption[]) => void;
  sortByOptions: TOption[];
  sortBySelectedOption: number;
  changeSortBySelectedOption: (option: number) => void;
};

export function useHeatmapPlotSettings(
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>,
  cageDataList: TEntity[][],
  isPlotLoaded = false
): TUseHeatmapPlotSettingsResponse {
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());

  const [isSortByDatasetData, setIsSortByDatasetData] = useState<boolean>(true);
  const [sortByOptions, setSortByOptions] = useState<TOption[]>([]);
  const [sortBySelectedOption, setSortBySelectedOption] = useState(0);

  const toggleIsSortByDatasetData = useCallback(() => setIsSortByDatasetData((prevState) => !prevState), []);
  const setOptions = useCallback((options: TOption[]) => setSortByOptions(options), []);
  const changeSortBySelectedOption = useCallback((option: number) => {
    setSortBySelectedOption(option);
  }, []);

  useEffect(() => {
    if (!isPlotLoaded) return;
    const uniqueCageIds = getUniqueCageIds(cageDataList);
    if (!uniqueCageIds.size) return;

    const { z, y, hovertemplate, customdata } = prepareHeatmapData(
      uniqueCageIds,
      cageDataList,
      xAxis,
      isSortByDatasetData,
      sortBySelectedOption
    );
    const dataToUpdate = {
      z: [z],
      y: [y],
      hovertemplate,
      customdata: [customdata],
    };

    plotlyProxy.forceUpdate(dataToUpdate);
  }, [isSortByDatasetData, sortBySelectedOption]);

  return {
    isSortByDatasetData,
    toggleIsSortByDatasetData,
    setOptions,
    sortByOptions,
    sortBySelectedOption,
    changeSortBySelectedOption,
  };
}

export default useHeatmapPlotSettings;
