import { SVGProps } from 'react';

const InfoRectangularMiniIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.2075 2.27898C3.58875 2.27898 3.11695 1.81492 3.11695 1.19617C3.11695 0.585156 3.58875 0.121094 4.2075 0.121094C4.82625 0.121094 5.29805 0.585156 5.29805 1.19617C5.29805 1.81492 4.82625 2.27898 4.2075 2.27898ZM7.72664 12.7281H0.773438V11.6376H3.65062V5.42688H0.796641V4.33633H4.95V11.6376H7.72664V12.7281Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoRectangularMiniIcon;
