import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isGeneralCellShape } from '@/helpers/typeGuards';
import { getCorrectNameOfAxes } from '@/helpers/preprocessing';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { EPreprocessingObjectType, TGeneralBeadShape, TGeneralCellShape } from '@/store/slices/preprocessing/types';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';

const useGeneralGates = (datasetId = '') => {
  const beads = useSelector(preprocessingSelectors.selectBeadTypes);
  const cells = useSelector(preprocessingSelectors.selectCellTypes);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const generalBeadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);
  const generalCellShapes = useSelector(preprocessingSelectors.selectGeneralCellShapes);
  const preprocessingType = useSelector(preprocessingSelectors.selectPreprocessingType);
  const axesOptionMap = useSelector(preprocessingSelectors.selectAxesOptionMap);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());

  const shapes = useMemo((): Record<string, TGeneralBeadShape | TGeneralCellShape> => {
    if (preprocessingType === EPreprocessingObjectType.cells) {
      return generalCellShapes;
    }
    return generalBeadShapes;
  }, [preprocessingType, generalCellShapes, generalBeadShapes]);

  const defineGateName = useCallback(
    (shapeData: TGeneralBeadShape | TGeneralCellShape) => {
      if (isGeneralCellShape(shapeData, preprocessingType)) {
        return cells[0].name;
      }

      return beads.find((itemData) => itemData.uuid === shapeData?.beadId)?.name ?? '';
    },
    [preprocessingType, cells, beads]
  );

  const prepareGates = (shapesData: Record<string, TGeneralBeadShape | TGeneralCellShape>) => {
    const result: TGate[] = [];
    Object.values(shapesData).forEach((shapeData: TGeneralBeadShape | TGeneralCellShape) => {
      const label = defineGateName(shapeData);
      let typedShapeData: TGeneralBeadShape | TGeneralCellShape = shapeData;

      const isCellShape = isGeneralCellShape(shapeData, preprocessingType);

      if (isCellShape) {
        typedShapeData = shapeData;
      }

      datasets.forEach(({ laneId, scanId, id, dataset }) => {
        if (datasetId && datasetId !== id) {
          return;
        }
        const datasetXDimension = shapeData.specificDatasets[id]?.xDimension ?? typedShapeData.xDimension;
        const datasetYDimension = shapeData.specificDatasets[id]?.yDimension ?? typedShapeData.yDimension;

        const { correctXAxis, correctYAxis } = getCorrectNameOfAxes(
          axesOptionMap,
          dataset.name,
          datasetXDimension,
          datasetYDimension
        );

        const gateData = typedShapeData.specificDatasets[id] ?? typedShapeData;
        const gateNodes =
          isCellShape && Object.values(shapeData.shapeNodes).length ? prepareGates(shapeData.shapeNodes) : [];

        const gate: TGate = {
          laneId,
          name: label,
          scanId,
          ...gateData,
          xDimension: correctXAxis,
          yDimension: correctYAxis,
          ...(!isCellShape && { beadId: shapeData?.beadId ?? '' }),
          gateNodes,
        };

        result.push(gate);
      });
    });
    return result;
  };

  const gates = useMemo<TGate[]>(
    () => prepareGates(shapes),
    [generalBeadShapes, generalCellShapes, datasets, beads, cells, datasetId, axesOptionMap, xAxis, yAxis]
  );

  return gates;
};

export default useGeneralGates;
