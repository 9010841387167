import { SVGProps } from 'react';

const DeleteChildrensIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17.797" viewBox="0 0 16 17.797" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M6 16H5a3 3 0 0 1-3-3V6h0M11 3V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1h0M.5 3.5h15M6 7v4M10 7v1M12.044 11.297v6.5M14.993 14.928l-2.829 2.828M9.142 14.916l2.828 2.829" />
    </g>
  </svg>
);

export default DeleteChildrensIcon;
