import { SVGProps } from 'react';

const CardsViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M17 1.045h-6M17 6.5h-6M17 11.955h-6M7 1.045H1M7 6.5H1M7 11.955H1" />
    </g>
  </svg>
);

export default CardsViewIcon;
