import { FC } from 'react';
import FilterInputWithSuggestionFromData from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/FilterInputWithSuggestionFromData';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';

type TReagentFilterBlockProps = {
  filterState: Record<string, string>;
  updateFactory: (field: string) => (value: string) => void;
  data?: TReturnTransformedTypes[];
};

const CellNameFilterBlock: FC<TReagentFilterBlockProps> = ({ filterState, updateFactory, data }) => (
  <>
    {/* <FilterInput value={filterState.catalogIndex} placeholder="Disease" onChange={updateFactory('disease')} /> */}
    <FilterInputWithSuggestionFromData
      value={filterState.tissue}
      placeholder="Tissue"
      onChange={updateFactory('tissue')}
      data={data}
      field="tissue"
    />
    {/* <FilterInput value={filterState.catalogIndex} placeholder="Gender" onChange={updateFactory('gender')} /> */}
    <FilterInputWithSuggestionFromData
      value={filterState.growthProperties}
      placeholder="Growth property"
      onChange={updateFactory('growthProperties')}
      data={data}
      field="growthProperties"
    />
    {/* <FilterInput value={filterState.catalogIndex} placeholder="Reporter" onChange={updateFactory('reporter')} /> */}
    {/* <FilterInput */}
    {/*  value={filterState.catalogIndex} */}
    {/*  placeholder="Gene modification" */}
    {/*  onChange={updateFactory('geneModification')} */}
    {/* /> */}
  </>
);

export default CellNameFilterBlock;
