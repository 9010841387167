import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TIntEndpointBuilder } from '@/store/services/app/types';
import { infoEndpoints, statusEndpoints } from '@/store/services/intsvc/endpoints';

const prepareEndpoints = (build: TIntEndpointBuilder) => ({
  ...statusEndpoints(build),
  ...infoEndpoints(build),
});

export const intAPI = createApi({
  reducerPath: 'intAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/intsvc`,
  }),
  endpoints: prepareEndpoints,
});
