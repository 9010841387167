import { DragBehavior } from 'd3-drag';

import { isPolarAreaTypeGate } from '@/helpers/typeGuards';
import { EGateLabelType, EGatesLabelFormat } from '@/types/gateSettings';
import { drawControlElement, drawGateLabel } from './common';
import { TSelectionGroupElement } from '../types';

type TDrawPolarLabelsPayload = {
  parentElement: TSelectionGroupElement;
  gateIdAttr: string;
  displayType: EGateLabelType;
  polarSectors: TGate[];
  cagesData: TEntitiesByGates;
  shapesContainerParameters: DOMRect;
  gateLabelFormat: EGatesLabelFormat;
  polarSubType: 'polar' | 'split-gate';
  isGateReverted?: boolean;
  isGateMenuHidden: boolean;
};

export type TLineData = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  offsetAngle?: TPolarOffsetAngles;
};

export const anchorByOffsetAngles: Record<TPolarOffsetAngles, 'start' | 'end'> = {
  0: 'end',
  90: 'end',
  180: 'start',
  270: 'start',
};

export const anchorByOffsetAnglesForRevertedGate: Record<TPolarOffsetAngles, 'start' | 'end'> = {
  0: 'start',
  90: 'end',
  180: 'end',
  270: 'start',
};

const polarControlTypeByOffset: Record<TPolarOffsetAngles, string> = {
  0: 'control_y',
  90: 'control_x',
  180: 'control_y',
  270: 'control_x',
};

const labelGapByX = 20;
const labelGapByY = 40;

const generatePolarLinesData = (center: number[], params: DOMRect): TLineData[] => {
  const linesData: TLineData[] = [
    {
      x1: center[0],
      x2: 0,
      y1: center[1],
      y2: center[1],
      offsetAngle: 180,
    },
    {
      x1: center[0],
      x2: params.width,
      y1: center[1],
      y2: center[1],
      offsetAngle: 0,
    },
    {
      x1: center[0],
      x2: center[0],
      y1: center[1],
      y2: params.height,
      offsetAngle: 270,
    },
    {
      x1: center[0],
      x2: center[0],
      y1: center[1],
      y2: 0,
      offsetAngle: 90,
    },
  ];

  return linesData;
};

const generateSplitGateLinesData = (center: number[], params: DOMRect): TLineData[] => {
  const yCenter = params.height / 2;

  const linesData: TLineData[] = [
    {
      x1: center[0],
      x2: center[0],
      y1: yCenter,
      y2: params.height,
      offsetAngle: 180,
    },
    {
      x1: center[0],
      x2: center[0],
      y1: yCenter,
      y2: 0,
      offsetAngle: 180,
    },
  ];

  return linesData;
};

export const generateLinesList = (
  center: number[],
  params: DOMRect,
  subType: 'polar' | 'split-gate' = 'polar'
): TLineData[] => {
  const linesGeneratorBySubType = {
    polar: () => generatePolarLinesData(center, params),
    'split-gate': () => generateSplitGateLinesData(center, params),
  };

  return linesGeneratorBySubType[subType]();
};

const defineControlType = (lineData: TLineData, subType?: 'polar' | 'split-gate') => {
  if (subType === 'split-gate') return 'control_x';
  const angleType =
    typeof lineData?.offsetAngle === 'number' ? polarControlTypeByOffset[lineData.offsetAngle] : 'control_center';
  return angleType;
};

export const drawPolarControls = ({
  linesData,
  parentElement,
  classes = 'gate-element__control',
  isStatic,
  controlDragHandler,
  gateId,
  center,
  polarGateSubType = 'polar',
}: {
  linesData: TLineData[];
  parentElement: TSelectionGroupElement;
  classes?: string;
  isStatic: boolean;
  controlDragHandler: DragBehavior<SVGRectElement, unknown, unknown>;
  gateId: string;
  center: number[];
  polarGateSubType?: 'polar' | 'split-gate';
}) => {
  const centerControlCoordinates: TLineData[] =
    polarGateSubType === 'polar'
      ? [
          {
            x1: center[0],
            x2: center[0],
            y1: center[1],
            y2: center[1],
          },
        ]
      : [];

  const controlsCoordinates: TLineData[] = [...linesData, ...centerControlCoordinates];

  controlsCoordinates.forEach((lineData: TLineData) => {
    const angleType = defineControlType(lineData, polarGateSubType);

    const attributes = [
      {
        attr: 'gate-control-id',
        value: `gate-control_${gateId}`,
      },
      {
        attr: 'control-type-by-offset-angle',
        value: angleType,
      },
    ];

    const control = drawControlElement({
      parentElement,
      x: lineData.x2,
      y: lineData.y2,
      classes,
      attributes,
    });

    if (!isStatic) {
      control.call(controlDragHandler);
    }
  });
};

const getPolarNameByLabelFormat = (gateLabelFormat: EGatesLabelFormat, gateName: string, cagesPercent = ''): string => {
  const cagesPercentWithDividerStr = cagesPercent.trim() ? `| ${cagesPercent}` : '';

  const lableByFormat = {
    [EGatesLabelFormat.none]: '',
    [EGatesLabelFormat.nameAndPercent]: `${gateName} ${cagesPercentWithDividerStr}`,
    [EGatesLabelFormat.name]: gateName,
    [EGatesLabelFormat.percent]: cagesPercent ?? '',
  };

  return lableByFormat[gateLabelFormat];
};

export const drawPolarLabels = ({
  parentElement,
  displayType,
  polarSectors,
  cagesData,
  shapesContainerParameters,
  gateLabelFormat,
  isGateReverted,
  isGateMenuHidden,
}: TDrawPolarLabelsPayload) => {
  const coordinatesFor90Angle = { x: shapesContainerParameters.width - labelGapByX, y: labelGapByY };
  const coordinatesFor0Angle = {
    x: shapesContainerParameters.width - labelGapByX,
    y: shapesContainerParameters.height,
  };
  const coordinatesFor180Angle = { x: labelGapByX, y: labelGapByY };
  const coordinatesFor270Angle = { x: labelGapByX, y: shapesContainerParameters.height };

  const coordByOffsetAngle: Record<TPolarOffsetAngles, Record<'x' | 'y', number>> = {
    90: coordinatesFor90Angle,
    0: isGateReverted ? coordinatesFor180Angle : coordinatesFor0Angle,
    180: isGateReverted ? coordinatesFor0Angle : coordinatesFor180Angle,
    270: coordinatesFor270Angle,
  };

  polarSectors.forEach((polarSectorGate) => {
    const { id, shape } = polarSectorGate;
    if (isPolarAreaTypeGate(shape)) {
      const gateLabel = getPolarNameByLabelFormat(
        gateLabelFormat,
        polarSectorGate.name,
        cagesData?.[id]?.cagesPercent ?? ''
      );

      const { x, y } = coordByOffsetAngle[shape.model.offsetAngle];

      drawGateLabel({
        gateContainer: parentElement,
        bgX: x,
        bgY: y,
        textX: x,
        textY: y,
        gateIdAttr: `gate_${id}`,
        label: gateLabel,
        displayType,
        aligment: isGateReverted
          ? anchorByOffsetAnglesForRevertedGate[shape.model.offsetAngle]
          : anchorByOffsetAngles[shape.model.offsetAngle],
        isGateMenuHidden,
      });
    }
  });
};

export const drawSplitGatePolarLabels = ({
  parentElement,
  displayType,
  polarSectors,
  cagesData,
  shapesContainerParameters,
  gateLabelFormat,
  isGateMenuHidden,
}: TDrawPolarLabelsPayload) => {
  const coordByOffsetAngle: Record<number, Record<'x' | 'y', number>> = {
    90: { x: shapesContainerParameters.width - labelGapByX, y: labelGapByY },
    270: { x: labelGapByX, y: labelGapByY },
  };

  polarSectors.forEach((polarSectorGate) => {
    const { id, shape } = polarSectorGate;
    if (isPolarAreaTypeGate(shape)) {
      const gateLabel = getPolarNameByLabelFormat(
        gateLabelFormat,
        polarSectorGate.name,
        cagesData?.[id]?.cagesPercent ?? ''
      );

      if (!coordByOffsetAngle?.[shape.model.offsetAngle]) return;

      const { x, y } = coordByOffsetAngle[shape.model.offsetAngle];

      drawGateLabel({
        gateContainer: parentElement,
        bgX: x,
        bgY: y,
        textX: x,
        textY: y,
        gateIdAttr: `gate_${id}`,
        label: gateLabel,
        displayType,
        aligment: anchorByOffsetAngles[shape.model.offsetAngle],
        isGateMenuHidden,
      });
    }
  });
};

export const drawLabels = (payload: TDrawPolarLabelsPayload) => {
  const handlerBySubType = {
    polar: () => drawPolarLabels(payload),
    'split-gate': () => drawSplitGatePolarLabels(payload),
  };

  return handlerBySubType[payload.polarSubType]();
};
