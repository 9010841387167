import { SVGProps } from 'react';

const CheckboxDashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" d="M6 10h7" />
    </g>
  </svg>
);

export default CheckboxDashIcon;
