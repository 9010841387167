import { SVGProps } from 'react';

const IncubationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" fill="none" {...props}>
    <path
      d="M38 34V17.899C38 9.118 30.837 2 22 2 13.164 2 6 9.118 6 17.899V34"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <circle
      cx="22"
      cy="18"
      r="5"
      transform="rotate(-90 22 18)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path
      d="M12.01 25.01s.014 4.457 2.236 6.709c2.221 2.252 6.71 2.236 6.71 2.236s0-4.473-2.237-6.71c-2.237-2.236-6.71-2.236-6.71-2.236ZM31.952 24.995s-.015 4.465-2.24 6.72c-2.225 2.256-6.72 2.24-6.72 2.24s0-4.48 2.24-6.72c2.24-2.24 6.72-2.24 6.72-2.24Z"
      fill="currentColor"
    />
    <path d="M0 41h44" stroke="currentColor" strokeWidth="4" />
  </svg>
);

export default IncubationIcon;
