import { select as d3Select, create as d3Create } from 'd3-selection';

import { preparePlotlySVGSVGElement, downloadSvg } from '@/components/charts/DownloadChartButton/helpers/svgImage';
import { prepareMatrixLayout } from '@/components/charts/DownloadChartButton/prepareLayouts';

import { TOffset } from '@/helpers/screenShotUtils/types';
import { drawGateOnSvg } from '@/helpers/screenShotUtils';

import getCurrentGatesLayout from '@/pages/Dataset/components/DatasetChart/helpers/getCurrentGatesLayout';
import { TGateLayout } from '@/pages/Dataset/components/DatasetChart/types';

import { THeaderInfo } from '@/store/slices/scatterplots';
import { sampleFont, baseFont, plotsPadding, noDataText } from '../constants';
import { TSelectionDivElement } from '../../gates/types';
import { TDownloadMatrixViewHandlerPayload } from './types';

export const drawLabelsOnSVG = (
  chartSVG: SVGSVGElement,
  options: {
    offset: TOffset;
    isEmpty?: boolean;
    headerInfo: THeaderInfo;
    layoutMargin: {
      t: number;
    };
  }
) => {
  const { headerInfo, isEmpty } = options;

  if (isEmpty) {
    d3Select(chartSVG)
      .append('text')
      .text(noDataText)
      .attr('x', '50%')
      .attr('y', '50%')
      .attr('font-size', 17)
      .style('text-align', 'center')
      .style('transform', 'translate(-10%)')
      .style('font', sampleFont);

    return;
  }

  const text = d3Select(chartSVG)
    .append('text')
    .attr('x', 20)
    .attr('y', 50)
    .attr('font-size', 17)
    .style('text-align', 'center');

  text.append('tspan').text(headerInfo.sampleName).style('font-weight', '600').attr('dx', 10).style('font', sampleFont);
  text.append('tspan').text(headerInfo.datasetName).attr('dx', 10).style('font', baseFont);
  text.append('tspan').text(headerInfo.scanTime).attr('dx', 10).style('font', baseFont);
};

export const downloadMatrixViewAsSVG = ({
  plotlyImages,
  imageWidth,
  imageHeight,
  columnsCount,
  plotWidth,
  plotHeight,
  graphBlocks,
  graphInfoObj,
  labelElement,
  fullFileName,
}: TDownloadMatrixViewHandlerPayload) => {
  const potlySVGImages = plotlyImages.map((plotlyImage: string) =>
    preparePlotlySVGSVGElement({ plotlyImage, imageWidth, imageHeight })
  );

  const mainSVG = d3Create('svg').attr('width', imageWidth).attr('height', imageHeight).style('background', 'black');

  potlySVGImages.forEach((image, i) => {
    if (!image) return;
    const paddingX = plotsPadding * Number(i % columnsCount);
    const paddingY = plotsPadding * Number(i / columnsCount >= 1);
    const rowIndex = Math.floor(i / columnsCount);
    const dx = plotWidth * (i % columnsCount) + paddingX;
    const dy = Math.floor(i / columnsCount) * plotHeight + paddingY * rowIndex;

    d3Select(image).attr('x', dx).attr('y', dy);
    mainSVG.node()?.appendChild(image);

    const currentGraphBlock = graphBlocks[i];

    const { xAxisLabel, yAxisLabel, headerInfo } = graphInfoObj[currentGraphBlock.id];
    const { layout } = prepareMatrixLayout(currentGraphBlock, xAxisLabel, yAxisLabel);
    const overflowContainer: TSelectionDivElement = d3Select(`#${currentGraphBlock.id} .overflow-container`);

    const renderPlotHeight = plotHeight - (layout.margin.t + layout.margin.b);
    const renderPlotWidth = plotWidth - layout.margin.l;

    const gatesLayout = getCurrentGatesLayout(overflowContainer);
    const offset: TOffset = {
      sx: layout.margin.l,
      sy: layout.margin.t,
    };

    drawLabelsOnSVG(image, { headerInfo, layoutMargin: layout.margin, offset });

    if (!gatesLayout) {
      return;
    }

    gatesLayout.forEach((gate: Nullable<TGateLayout>) => {
      drawGateOnSvg({
        svg: image,
        gate,
        imageHeight: renderPlotHeight,
        imageWidth: renderPlotWidth,
        offset,
        labelElement,
      });
    });
  });

  const imageSVGSVGElement = mainSVG.node();

  if (!imageSVGSVGElement) return;

  downloadSvg(imageSVGSVGElement, fullFileName);
};
