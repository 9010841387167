import { SVGProps } from 'react';

const FilledClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#F5F5F5" cx="12.5" cy="12.5" r="12.5" />
      <path stroke="#989898" strokeWidth="1.5" strokeLinecap="round" d="M12.5 12.5h5M12.5 12.5v-5" />
    </g>
  </svg>
);

export default FilledClockIcon;
