import { FC, ReactNode } from 'react';

import classnames from 'classnames/bind';

import styles from '../PageHeader.module.scss';

const cn = classnames.bind(styles);

type TPageHeaderSlotProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  children: ReactNode;
  isStatistics?: boolean;
};

const PageHeaderTitle: FC<TPageHeaderSlotProps> = ({ as: Tag = 'h2', className, children, isStatistics }) => (
  <Tag
    className={cn(
      'page-header__title',
      `page-header__title_${Tag}`,
      { 'page-header__title_statistics': isStatistics },
      className
    )}
  >
    {children}
  </Tag>
);
export default PageHeaderTitle;
