import { SVGProps } from 'react';

const AppliedToAllIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <circle cx="2.5" cy="2.5" r="2.5" />
      <circle cx="9.5" cy="2.5" r="2.5" />
      <circle cx="9.5" cy="9.5" r="2.5" />
      <circle cx="2.5" cy="9.5" r="2.5" />
    </g>
  </svg>
);

export default AppliedToAllIcon;
