import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { themeOptions } from '@/types/theme';

import { intAPI } from '@/store/services/intsvc';
import { headerActions } from '@/store/slices/header';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useParamsChangelog from '@/hooks/useParamsChangelog';

import { getErrorMessage } from '@/helpers/errors';
import { fetchUIChangelog, getUiServiceData } from '@/helpers/changelog';

import Markdown from 'react-markdown';
import PageHeader from '@/components/Layout/Page/PageHeader';
import ControlPanel from '@/components/Layout/ControlPanel';
import Nav from '@/components/Layout/Header/Nav';
import NoDataFound from '@/components/common/NoDataFound';
import Panel from '@/components/common/Panel';

const UI_SERVICE_ID = 'ui-service';

const Changelog = () => {
  const appDispatch = useAppDispatch();

  const [changelogText, setChangelogText] = useState('');
  const [uiServiceData, setUiServiceData] = useState<TInfoService>();
  const [error, setError] = useState('');
  const [isChangelogFetching, setIsChangelogFetching] = useState(false);

  const changelogId = useParamsChangelog();

  const { data, isFetching: isServicesFetching } = intAPI.useFetchApiInfoQuery();

  const changelogServiceData: TInfoService | undefined = useMemo(() => {
    if (changelogId === UI_SERVICE_ID) {
      return uiServiceData;
    }
    return data?.services?.find(
      (service: TInfoService) => service.name?.replace(' ', '-').toLowerCase() === changelogId
    );
  }, [data, changelogId]);

  const isDataFetching = useMemo(
    () => isServicesFetching || isChangelogFetching,
    [isServicesFetching, isChangelogFetching]
  );

  const requestChangelog = (changelogLink: string) => {
    setIsChangelogFetching(true);
    setError('');
    setChangelogText('');

    fetch(changelogLink, {
      credentials: 'include',
    })
      .then((response) => response.text())
      .then((text) => {
        setChangelogText(text);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setIsChangelogFetching(false);
      });
  };

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());
    appDispatch(headerActions.setNewLink({ title: 'dashboard', link: '/' }));
  }, []);

  useLayoutEffect(() => {
    if (changelogId === UI_SERVICE_ID) {
      setIsChangelogFetching(true);
      fetchUIChangelog()
        .then((value: unknown) => setChangelogText(value as string))
        .finally(() => setIsChangelogFetching(false));
      return;
    }

    if (!changelogServiceData && changelogId !== UI_SERVICE_ID) {
      return;
    }

    requestChangelog(changelogServiceData?.changelogURL ?? '');
  }, [changelogServiceData, changelogId]);

  useLayoutEffect(() => {
    getUiServiceData().then((uiData) => setUiServiceData(uiData as TInfoService));
  }, []);

  return (
    <div>
      <PageHeader>
        <PageHeader.Section>
          <PageHeader.Title as="h1">{changelogServiceData?.name} Changelog</PageHeader.Title>
        </PageHeader.Section>
      </PageHeader>

      <ControlPanel isLoading={isDataFetching}>
        <ControlPanel.StickyReveal>
          <Nav />
        </ControlPanel.StickyReveal>
      </ControlPanel>

      {!isDataFetching && (
        <Panel theme={themeOptions.light}>
          <Panel.Content>
            {!!error && <NoDataFound alignment="center" size="normal" textData={getErrorMessage(error)} />}
            {!error && (
              <>
                {!!changelogText && <Markdown className="markdown-body">{changelogText}</Markdown>}
                {!changelogText && <NoDataFound alignment="center" size="normal" />}
              </>
            )}
          </Panel.Content>
        </Panel>
      )}
    </div>
  );
};

export default Changelog;
