import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';
import { selectCurrentExperimentId } from '@/store/slices/experiment/selectors';

export const selectIsPreprocessingInProgress = (state: RootState) =>
  state.gatesAutoPreprocessing.isPreprocessingInProgress;

export const selectPreprocessingResultMap = (state: RootState) => state.gatesAutoPreprocessing.preprocessingResultMap;

export const selectPreprocessingResult = createSelector(
  [selectCurrentExperimentId, selectPreprocessingResultMap],
  (experimentId, preprocessingResultMap) => preprocessingResultMap[experimentId] ?? []
);
