import { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { useUserRole } from '@/hooks';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { authAPI } from '@/store/services/auth';

import CheckboxInput from '@/components/common/CheckboxInput';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';

import styles from '../UserList.module.scss';

const cn = classnames.bind(styles);

type TUserMFAStatusProps = {
  data: TUser;
};

const UserMFAStatus: FC<TUserMFAStatusProps> = ({ data: user }) => {
  const confirmationModal = useConfirmationModalContext();
  const { isUserUpdateAllowed } = useUserRole();
  const [changeMFASettings, { isLoading }] = authAPI.useChangeMFAMutation();

  const handleChange = useCallback(async () => {
    const isConfirmed = await confirmationModal.onOpen({
      confirmationText: (
        <>
          Are you sure you want to {user.mfa.status === 'DISABLED' ? 'enable' : 'disable'} MFA for user <br />
          <strong>{user.displayName}</strong>?
        </>
      ),
      approveButtonText: user.mfa.status === 'DISABLED' ? 'Enable' : 'Disable',
    });
    if (!isConfirmed) {
      return;
    }
    changeMFASettings({ status: user.mfa.status === 'DISABLED' ? 'ENABLED' : 'DISABLED', username: user.username })
      .then((result) => {
        if ('error' in result) {
          showErrorToast(getErrorMessage(result.error));
        } else {
          toast.success(
            `MFA was ${user.mfa.status === 'DISABLED' ? 'enabled' : 'disabled'} successfully for ${user.displayName}`
          );
        }
      })
      .catch((err) => {
        showErrorToast(getErrorMessage(err));
      });
  }, [isUserUpdateAllowed, user]);

  if (!isUserUpdateAllowed) {
    return user.mfa.status === 'ENABLED' ? (
      <div className={cn('badge', 'badge_green')}>ENABLED</div>
    ) : (
      <div className={cn('badge', 'badge_gray')}>DISABLED</div>
    );
  }

  return (
    <div className={cn('switch')}>
      <CheckboxInput
        disabled={isLoading}
        checked={user.mfa.status === 'ENABLED'}
        onChange={handleChange}
        theme="light"
        isSwitch
      />
      <small className={cn('small')}>MFA {user.mfa.status.toLowerCase()}</small>
    </div>
  );
};

export default UserMFAStatus;
