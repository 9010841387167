import { FC } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import styles from './AddInformation.module.scss';

const cn = classnames.bind(styles);

type TAddInformation = {
  label?: string;
  className?: string;
};

const AddInformation: FC<TAddInformation> = ({ label = 'Add Information', className }) => (
  <div className={cn('add-information', className)}>
    <icons.BigPlus className={cn('add-information__icon')} />
    <span className={cn('add-information__label')}>{label}</span>
  </div>
);

export default AddInformation;
