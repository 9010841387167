import { FC, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { ECdnObjectType } from '@/types/cdnData';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useContoursContext } from '@/hooks/useContoursContext';

import { viewerActions, viewerSelectors } from '@/store/slices/viewer';
import { navigatorSelectors } from '@/store/slices/navigator';
import { cdnAPI } from '@/store/services/cdnData';

import CheckboxInput from '@/components/common/CheckboxInput';

import DrawObjectsCheckbox from './DrawObjectsCheckbox';

import styles from './DisplaySettings.modulde.scss';

const cn = classnames.bind(styles);

const DisplaySettings: FC = () => {
  const appDispatch = useAppDispatch();

  const displayCageLocations = useSelector(viewerSelectors.selectDisplayCageLocationsLayer);
  const displaySegmentedObjects = useSelector(viewerSelectors.selectDisplaySegmentedObjectsLayer);
  const displayBarcodeCagesLayer = useSelector(viewerSelectors.selectDisplayBarcodeCagesLayer);
  const shouldFillInBarcodes = useSelector(viewerSelectors.selectShouldFillInBarcodes);
  const displayCagingSegmentationCentersLayer = useSelector(
    viewerSelectors.selectDisplayCagingSegmentationCentersLayer
  );
  const shouldFillInCagingSegmentationCenters = useSelector(
    viewerSelectors.selectShouldFillInCagingSegmentationCenters
  );
  const displayDebug = useSelector(viewerSelectors.selectDisplayDebugLayer);
  const useOptimizedData = useSelector(viewerSelectors.selectUseOptimizedData);
  const disableUseOptimizedData = useSelector(viewerSelectors.selectDisableUseOptimizedData);

  const { lane: currentLane, cdnData } = useContoursContext();
  const isCageContourListLoading = useMemo(() => {
    if (!cdnData) {
      return false;
    }
    return cdnData[ECdnObjectType.cageContour].isLoading;
  }, [cdnData]);
  const isCellContourListLoading = useMemo(() => {
    if (!cdnData) {
      return false;
    }
    return cdnData[ECdnObjectType.cellContour].isLoading;
  }, [cdnData]);

  const isAllowedObjectsOnCanvas = useSelector(
    navigatorSelectors.selectIsAllowedObjectsOnCanvas(currentLane?.id ?? '')
  );

  const savedUseOptimizedData = useRef(useOptimizedData);

  const toggleDisplayCageLocations = () => {
    appDispatch(viewerActions.toggleDisplayCageLocationsLayer());
  };

  const toggleDisplaySegmentedObjects = () => {
    appDispatch(viewerActions.toggleDisplaySegmentedObjectsLayer());
  };

  const toggleDisplayBarcodeCagesLayer = () => {
    appDispatch(viewerActions.toggleDisplayBarcodeCagesLayer());
  };

  const toggleFillBarcodes = () => {
    appDispatch(viewerActions.toggleShouldFillInBarcodes());
  };

  const toggleDisplayCagingSegmentationCentersLayer = () => {
    appDispatch(viewerActions.toggleDisplayCagingSegmentationCentersLayer());
  };

  const toggleFillInCagingSegmentationCenters = () => {
    appDispatch(viewerActions.toggleShouldFillInCagingSegmentationCenters());
  };

  const toggleDisplayDebug = () => {
    appDispatch(viewerActions.toggleDisplayDebugLayer());
  };

  const toggleUseOptimizedData = () => {
    appDispatch(viewerActions.setUseOptimizedData(!useOptimizedData));
  };

  useEffect(() => {
    if (savedUseOptimizedData.current !== useOptimizedData) {
      savedUseOptimizedData.current = useOptimizedData;
      appDispatch(
        cdnAPI.util.invalidateTags([{ type: ECdnObjectType.objectEntity }, { type: ECdnObjectType.cageEntity }])
      );
    }
  }, [useOptimizedData]);

  return (
    <div className={cn('display-settings')}>
      <DrawObjectsCheckbox
        {...cdnData[ECdnObjectType.cageContour]}
        label="Cage locations"
        isChecked={displayCageLocations}
        onChange={toggleDisplayCageLocations}
        isAllowedObjectsOnCanvas={isAllowedObjectsOnCanvas}
      />

      <DrawObjectsCheckbox
        {...cdnData[ECdnObjectType.cellContour]}
        label="Cage Segmented objects"
        isChecked={displaySegmentedObjects}
        onChange={toggleDisplaySegmentedObjects}
        isAllowedObjectsOnCanvas={isAllowedObjectsOnCanvas}
      />

      <DrawObjectsCheckbox
        {...cdnData[ECdnObjectType.barcodeCenters]}
        label="Barcodes"
        isChecked={displayBarcodeCagesLayer}
        onChange={toggleDisplayBarcodeCagesLayer}
        isFillChecked={shouldFillInBarcodes}
        onFillChange={toggleFillBarcodes}
        isAllowedObjectsOnCanvas={isAllowedObjectsOnCanvas}
      />

      <DrawObjectsCheckbox
        {...cdnData[ECdnObjectType.cagingSegmentationCenters]}
        label="Caging segmentation"
        isChecked={displayCagingSegmentationCentersLayer}
        onChange={toggleDisplayCagingSegmentationCentersLayer}
        isFillChecked={shouldFillInCagingSegmentationCenters}
        onFillChange={toggleFillInCagingSegmentationCenters}
        isAllowedObjectsOnCanvas={isAllowedObjectsOnCanvas}
      />

      <CheckboxInput checked={displayDebug} onChange={toggleDisplayDebug} label="Grid" theme="light" />
      <CheckboxInput
        checked={useOptimizedData}
        onChange={toggleUseOptimizedData}
        label="Optimize my experience"
        theme="light"
        disabled={disableUseOptimizedData || isCageContourListLoading || isCellContourListLoading}
      />
    </div>
  );
};

export default DisplaySettings;
