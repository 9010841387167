import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import Modal, { TModal } from '@/components/common/Modal';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TCagingSettingNumberField,
} from '@/store/slices/experimentRunDesign';
import { isKeyOf, isSomeObjValueDiff, MDASH } from '@/helpers';
import { CagingSettingsItem } from '@/graphql/API';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import styles from './CageSettingsInfoModal.module.scss';
import { ADVANCED_SETTINGS, DEFAULT_GLOBAL_SETTINGS } from '../../constants';
import NumberField from '../EditSettings/components/EditFields/components/NumberField';
import { prepareValidationConfigForAdvancedSettings, validateCagingSettings } from '../EditSettings/helpers';

const cn = classnames.bind(styles);

type CageSettingsInfoModalProps = {
  isEditMode?: boolean;
  animationType?: 'slide-animation' | 'splash-animation';
  onClose: () => void;
} & Pick<TModal, 'isOpen'>;

const CageSettingsInfoModal: FC<CageSettingsInfoModalProps> = ({ animationType, onClose, isOpen, isEditMode }) => {
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const appDispatch = useAppDispatch();

  const [newGlobalSettings, setNewGlobalSettings] = useState<Nullable<CagingSettingsItem>>(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isAllSettingsValid, setIsAllSettingsValid] = useState({});

  const cageSettingsInputValidationConfig = prepareValidationConfigForAdvancedSettings(undefined, globalCagingSettings);

  const isSaveBtnDisabled = useMemo(() => {
    if (!globalCagingSettings || !newGlobalSettings) return true;
    return !isSomeObjValueDiff(globalCagingSettings, newGlobalSettings);
  }, [newGlobalSettings, globalCagingSettings]);

  const modalTitle = useMemo(() => {
    if (isEditMode) return 'Global settings';
    return 'CellCage charts';
  }, [isEditMode]);

  const settingsList = useMemo(() => {
    if (!isEditMode) {
      return ADVANCED_SETTINGS.filter((setting) => !!setting?.icon).sort((current, next) =>
        current.title[0].localeCompare(next.title[0])
      );
    }

    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings));
  }, [globalCagingSettings, isEditMode]);

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      setNewGlobalSettings((prev) => {
        if (!prev) return null;
        const newSettings = structuredClone(prev);

        newSettings[field] = value;
        validateCagingSettings(undefined, newSettings);
        return newSettings;
      });
    },
    []
  );

  const saveGlobalSettings = useCallback(() => {
    if (!globalCagingSettings) return;

    appDispatch(
      experimentRunDesignActions.updateGlobalCagingSettings({
        ...globalCagingSettings,
        ...newGlobalSettings,
      })
    );
    onClose();
  }, [globalCagingSettings, newGlobalSettings]);

  const resetGlobalSettingsToDefault = useCallback(() => {
    setNewGlobalSettings((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        ...DEFAULT_GLOBAL_SETTINGS,
      };
    });
  }, [globalCagingSettings, newGlobalSettings]);

  useEffect(() => {
    if (!globalCagingSettings) return;

    setNewGlobalSettings(globalCagingSettings);
  }, [globalCagingSettings]);

  useEffect(() => {
    if (!newGlobalSettings) return;
    const { isValid, errors } = validateCagingSettings(undefined, newGlobalSettings);
    setValidationErrors(errors);
    setIsAllSettingsValid(isValid);
  }, [newGlobalSettings]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      sidebar="right"
      animationType={animationType}
    >
      <Modal.Header onRequestClose={onClose} className={cn('modal__header')}>
        <h2 className={cn('modal__subtitle')}>{designData.templateName ?? MDASH}</h2>
      </Modal.Header>
      <Modal.Content className={cn('modal__content', 'content', { content_wide: isEditMode })}>
        <div className={cn('content__top-section')}>
          <div>
            <h1 className={cn('content__title')}>{modalTitle}</h1>
            {isEditMode && (
              <button
                type="button"
                onClick={resetGlobalSettingsToDefault}
                className={cn('modal__button', 'modal__button_reset')}
              >
                <icons.ReloadIcon className={cn('modal__button-icon')} />
                <span className={cn('modal__button-substring')}>Reset to default</span>
              </button>
            )}
          </div>
          <div className={cn('content__top-section-divider')} />
          <icons.CageSettingsIcon className={cn('content__section-img')} />
        </div>
        <div className={cn('content__main-content')}>
          {settingsList.map(({ key, title, icon, customdata = '' }) => (
            <div className={cn('content__row', { content__row_wide: isEditMode })} key={key}>
              <div className={cn('content__row-title')}>
                {title} {customdata}
              </div>
              {isEditMode && (
                <NumberField
                  key={key}
                  field={key}
                  settings={newGlobalSettings}
                  onChangeNumberField={handleChangeNumberField}
                  defaultValue={globalCagingSettings?.[key] ?? undefined}
                  errors={validationErrors}
                  className={cn('content__input')}
                  validationConfig={cageSettingsInputValidationConfig}
                />
              )}
              {icon}
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button type="button" color="light" isOutlined onClick={onClose} className={cn('modal__button')}>
          Close
        </Button>
        {isEditMode && (
          <Button
            disabled={isSaveBtnDisabled || !isAllSettingsValid}
            type="button"
            color="light"
            onClick={saveGlobalSettings}
            className={cn('modal__button', 'modal__button_save')}
          >
            Save settings
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CageSettingsInfoModal);
