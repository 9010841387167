import type { RootState } from '@/store';
import { getStateValue } from './helpers';

export const selectCurrentHistogramDataGroupType = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.histogramSettings, 'currentHistogramDataGroupType', datasetId);

export const selectCustomHistogramBinsAmount = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.histogramSettings, 'customHistogramBinsAmount', datasetId);

export const selectIsStackedChartsChecked = (state: RootState) => state.histogramSettings.isStackedChartsChecked;

export const selectIsStackedAndFilledEnabled = (state: RootState) => state.histogramSettings.isStackedAndFilledEnabled;

export const selectIsChartFillEnabled = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.histogramSettings, 'isChartFillEnabled', datasetId);

export const selectKernelBandwidthCoefficient = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.histogramSettings, 'kernelBandwidthCoefficient', datasetId);

export const selectKernelBinsAmountMultiplier = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.histogramSettings, 'kernelBinsAmountMultiplier', datasetId);

export const selectSpecificDatasetOptionMap = (storeState: RootState) =>
  storeState.histogramSettings.specificDatasetOptionMap;

export const selectAllGlobalHistogramSettings = (storeState: RootState) => storeState.histogramSettings;
