import { TRunDesignComponent } from '@/store/slices/experimentRunDesign/types';

const getCurrentComponentIndex = (state: TRunDesignComponent[], id: string) => {
  const placementIndex = state.findIndex((component) => component.id === id);

  if (placementIndex < 0) {
    throw new Error(`Provided id '${id}' not exist in component list`);
  }
  return placementIndex;
};

export default getCurrentComponentIndex;
