import classnames from 'classnames/bind';
import { TPanelColor } from '@/helpers/interfaces';
import { FC, ReactNode } from 'react';
import styles from '../Panel.module.scss';

const cn = classnames.bind(styles);

type TPanelHeader = {
  className?: string;
  wrapClassName?: string;
  children?: ReactNode;
  withDivide?: boolean;
  type?: TPanelColor;
  onClick?: () => void;
};

const PanelHeader: FC<TPanelHeader> = ({ children, className, withDivide = true, type, wrapClassName, onClick }) => (
  <div
    className={cn(
      'panel-header',
      {
        'panel-header_divide': withDivide && type !== 'light',
        'panel-header_divide-dark': withDivide && type === 'light',
      },
      wrapClassName
    )}
    role="presentation"
    onClick={onClick}
  >
    <h2 className={cn('panel-header__content', className)}>{children}</h2>
  </div>
);

export default PanelHeader;
