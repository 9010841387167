import { FC, RefObject, ReactNode } from 'react';
import classnames from 'classnames/bind';
import styles from '../Panel.module.scss';

const cn = classnames.bind(styles);

type TPanelContent = {
  className?: string;
  children?: ReactNode;
  innerRef?: RefObject<HTMLDivElement>;
};

const PanelContent: FC<TPanelContent> = ({ children, className, innerRef }) => (
  <div ref={innerRef} className={cn('panel-content', className)}>
    {children}
  </div>
);

export default PanelContent;
