import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { EStepName } from '@/store/slices/preprocessing/types';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { useParamsExperimentId } from '@/hooks';
import { useCellKillingPreprocessing } from '@/hooks/preprocessing/useCellKillingPreprocessing';

import StepActions, { TAction, TActionGroup } from '../../components/StepActions';
import PreprocessingHeader from '../../components/PreprocessingHeader';

import styles from './StepCellKillingSummary.module.scss';

const cn = classnames.bind(styles);

// TODO: use one component for cell killing and cytokine preprocessing
const StepCellKillingSummary = () => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const fullCellShapes = useSelector(preprocessingSelectors.selectFullCellShapes);
  const cellTypes = useSelector(preprocessingSelectors.selectCellTypes);
  const isStepCellKillingReviewCellsTargetCompleted = useSelector(
    preprocessingSelectors.selectIsStepCellKillingReviewCellsTargetCompleted
  );
  const datasets = useSelector(preprocessingSelectors.selectDatasets);

  const { startCellKillingPreprocessing } = useCellKillingPreprocessing(experimentId, datasets);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCellKillingReviewCellsTarget));
        },
      },
      {
        label: 'Process Data',
        disabled: !isStepCellKillingReviewCellsTargetCompleted,
        onClick: startCellKillingPreprocessing,
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isStepCellKillingReviewCellsTargetCompleted, fullCellShapes]);

  return (
    <>
      <PreprocessingHeader hideStepNumber customInfo="Gating for target/effector and live/dead cell populations" />
      <div className={cn('summary-step')}>
        <div className={cn('summary')}>
          <span className={cn('summary__title')}>Data processing summary</span>
          <span className={cn('summary__text')}>
            You have successfully configured the gating for the cell killing assay and are now ready to process your
            data.
          </span>
          <span className={cn('summary__text')}>
            With these gates in place, we will calculate Cage-level data for each cell killing dataset, including the
            following fields:
          </span>
          <div className={cn('summary__beads')}>
            {cellTypes.map((cell) => (
              <span key={cell.uuid} className={cn('summary__bead')}>
                {cell.label}
              </span>
            ))}
          </div>
          <span className={cn('summary__text')}>
            These computed values can be utilized later for generating plots implementing various types of data
            analysis.
          </span>
          <span className={cn('summary__text')}>
            Click on the <b>Process Data</b> button below to initiate the data processing of your datasets.
          </span>
        </div>
        <div className={cn('illustration')}>
          <span className={cn('circles')}>
            Graphic Illustration
            <div className={cn('circle', 'circle_left')} />
            <div className={cn('circle', 'circle_right')} />
          </span>
        </div>
      </div>
      <StepActions actionGroups={actionGroups} className={cn('actions')} />
    </>
  );
};

export default StepCellKillingSummary;
