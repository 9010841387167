import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { select as d3Select } from 'd3-selection';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { throttle } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { getNewAxesRange, getUpdatedRangeLayoutConfig } from '@/hooks/datasetAnalysis/helpers';
import { EModebarTools, EChartType } from '@/types/charts';
import usePlotProxy from '../usePlotProxy';

type TUseChartRangeHandling = {
  axesRange: TPlotAxisRange;
  handleAxisRangeChange: (value: number | number[], axisName: 'x' | 'y') => void;
  resetRangeToDefault: (callback?: () => void) => void;
  isCustomRangeUsed: boolean;
  setAxesRange: (value: TPlotAxisRange) => void;
  maxChartRange: number;
  minChartRange: number;
  handlePlotZoomed: (eventData: TPlotRelayoutEvent, eventType?: 'plotly_relayout' | 'plotly_relayouting') => void;
  isRangeChangedByPlotly: boolean;
  isInteractionsEnabled: boolean;
};

type TUseChartRangeHandlingPayload = {
  openedAxisSettingsName: Nullable<'x' | 'y'>;
  selectedTool: Nullable<EModebarTools>;
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  customChartType?: EChartType;
  withAutorange?: boolean;
  rangeName?: string;
};

export function useChartRangeHandling({
  openedAxisSettingsName = null,
  selectedTool = null,
  graphRef,
  customChartType,
  withAutorange,
  rangeName,
}: TUseChartRangeHandlingPayload): TUseChartRangeHandling {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const storeChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const selectedCustomRange = useSelector(chartSettingsSelectors.selectSelectedCustomRange(chartId));
  const currentChartType = useMemo(() => customChartType ?? storeChartType, [customChartType, storeChartType]);
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)(rangeName));
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const isCustomGateRangeActive = useSelector(gatesSelectors.selectIsCustomGateRangeActive);

  const [isRangeChangedByPlotly, setIsRangeChangedByPlotly] = useState(false);
  const [isZoomSelectionStarts, setIsZoomSelectionStarts] = useState(false);
  const [axesRange, setAxesRange] = useState<TPlotAxisRange>({
    x: [0, 0],
    y: [0, 0],
  });
  const [isInteractionsEnabled, setIsInteractionsEnabled] = useState(true);
  const chartDataCount = useMemo(() => graphRef.current?.data?.length ?? 0, [graphRef.current?.data?.length]);

  const isAutoRange = useMemo(
    () => withAutorange || ([EChartType.heatmap, EChartType.violin].includes(currentChartType) && !selectedCustomRange),

    [selectedCustomRange, currentChartType]
  );

  const isCustomRangeUsed = useMemo(() => {
    if (isAutoRange) {
      return axesRange.x[0] !== 0 || axesRange.x[1] !== 0 || axesRange.y[0] !== 0 || axesRange.y[1] !== 0;
    }

    if (!plotRange) return false;

    const isDefaultAxesRange =
      axesRange.x[0] === 0 && axesRange.x[1] === 0 && axesRange.y[0] === 0 && axesRange.y[1] === 0;

    return (
      !isDefaultAxesRange &&
      (axesRange.x[0] !== plotRange.xMin ||
        axesRange.x[1] !== plotRange.xMax ||
        axesRange.y[0] !== plotRange.yMin ||
        axesRange.y[1] !== plotRange.yMax)
    );
  }, [plotRange, axesRange]);

  const isCustomGateRangeEnabled = useMemo(() => {
    if (!selectedGate) return false;
    const { properties } = selectedGate;

    if (!properties?.range) return false;
    const { range } = properties;

    return (
      axesRange.x[0] === range.x[0] ||
      axesRange.x[1] === range.x[1] ||
      axesRange.y[0] === range.y[0] ||
      axesRange.y[1] === range.y[1]
    );
  }, [selectedGate, axesRange]);

  const maxChartRange = useMemo(() => {
    if (openedAxisSettingsName && plotRange) {
      return openedAxisSettingsName === 'x' ? plotRange.xMax : plotRange.yMax;
    }

    return 1;
  }, [openedAxisSettingsName, plotRange]);
  const minChartRange = useMemo(() => {
    if (openedAxisSettingsName && plotRange) {
      return openedAxisSettingsName === 'x' ? plotRange.xMin : plotRange.yMin;
    }

    return 0;
  }, [openedAxisSettingsName, plotRange]);

  const enableInteraction = throttle(() => {
    setIsInteractionsEnabled(true);
  }, 2000);

  const handleMouseWheel = () => {
    setIsInteractionsEnabled(false);
    enableInteraction();
  };

  const handleAxisRangeChange = useCallback(
    (value: number | number[], axisName: 'x' | 'y') => {
      setAxesRange((prevState) => ({
        ...prevState,
        [axisName]: value,
      }));
    },
    [isCustomGateRangeActive]
  );

  const resetRangeToDefault = useCallback(
    (callback?: () => void) => {
      const currentLayout = graphRef.current?.layout;

      if (!currentLayout || chartDataCount === 0) {
        return;
      }

      const newAxesRange = getNewAxesRange(isAutoRange, plotRange);
      if (newAxesRange) {
        setAxesRange(newAxesRange);
      }

      const updatedLayoutConfig = getUpdatedRangeLayoutConfig(currentLayout, chartDataCount, isAutoRange, newAxesRange);

      plotlyProxy.forceRelayout(updatedLayoutConfig, callback);

      setIsRangeChangedByPlotly(false);
      appDispatch(gatesActions.setIsCustomGateRangeActive(false));
    },
    [graphRef.current?.data, isAutoRange, plotRange, chartDataCount]
  );

  const handlePlotZoomed = useCallback(
    (eventData: TPlotRelayoutEvent, eventType = 'plotly_relayouting') => {
      if (isZoomSelectionStarts && eventType === 'plotly_relayouting') return;
      const eventDataKeys = Object.keys(eventData);
      const isRangeEvent = eventDataKeys.length && eventDataKeys.every((key) => key.includes('range'));

      if (!isRangeEvent) return;

      setAxesRange((prevValue) => {
        // axis.range[index] - eventData keys from plotly
        const x0 = eventData['xaxis.range[0]'] ?? prevValue.x[0];
        const x1 = eventData['xaxis.range[1]'] ?? prevValue.x[1];
        const y0 = eventData['yaxis.range[0]'] ?? prevValue.y[0];
        const y1 = eventData['yaxis.range[1]'] ?? prevValue.y[1];

        return {
          x: [x0, x1],
          y: [y0, y1],
        };
      });

      if (selectedGate) {
        setIsRangeChangedByPlotly(true);
      }
    },
    [isZoomSelectionStarts]
  );

  useEffect(() => {
    if (!selectedGate) return;

    appDispatch(gatesActions.setIsCustomGateRangeActive(isCustomGateRangeEnabled));
  }, [isCustomGateRangeEnabled]);

  useEffect(() => {
    document.addEventListener('wheel', handleMouseWheel);

    return () => {
      document.removeEventListener('wheel', handleMouseWheel);
    };
  }, []);

  useEffect(() => {
    resetRangeToDefault();
  }, [selectedGate, selectedCustomRange, currentHistogramDataGroupType]);

  useEffect(() => {
    setAxesRange({
      x: [0, 0],
      y: [0, 0],
    });
  }, [xAxisScaleType, yAxisScaleType]);

  const onSelectionMouseUp = (event: MouseEvent) => {
    const target = event.target as Element;

    const targetSelection = d3Select(target);

    if (!targetSelection.node()) return;
    const targetClass = targetSelection.attr('class')?.split(' ')?.[0] ?? '';

    if (targetClass === 'dragcover') {
      setIsZoomSelectionStarts(false);
    }
  };

  useEffect(() => {
    const layout = d3Select('.nsewdrag.drag');

    if (!layout.node() || (selectedTool && selectedTool !== EModebarTools.zoom)) return;

    layout.on('mousedown', () => {
      setIsZoomSelectionStarts(true);
    });

    document.addEventListener('mouseup', onSelectionMouseUp);

    return () => {
      layout.on('mousedown', null);
      document.removeEventListener('mouseup', onSelectionMouseUp);
    };
  }, [selectedTool, graphRef.current?.data]);

  return {
    handleAxisRangeChange,
    axesRange,
    resetRangeToDefault,
    setAxesRange,
    isCustomRangeUsed,
    maxChartRange,
    minChartRange,
    handlePlotZoomed,
    isRangeChangedByPlotly,
    isInteractionsEnabled,
  };
}

export default useChartRangeHandling;
