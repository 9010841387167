import { FC, memo, ReactNode, useContext, useEffect } from 'react';
import { RunDesignContext } from '@/pages/experiment-run-design/context';

type TTitleProps = {
  children: ReactNode;
};

const Title: FC<TTitleProps> = ({ children }) => {
  const { setTitle } = useContext(RunDesignContext);

  useEffect(() => {
    setTitle?.(children);
  }, [setTitle, children]);

  return null;
};

export default memo(Title);
