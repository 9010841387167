import { FC, memo, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';

import type { TAction } from '.';

import styles from './StepActions.module.scss';

const cn = classnames.bind(styles);

const ActionButton: FC<{ actionData: TAction }> = ({ actionData }) => {
  const [action, setAction] = useState(actionData);

  useEffect(() => {
    setAction({ ...actionData });
  }, [actionData]);

  return (
    <Button
      key={action.label}
      color={action.colorBtn ?? 'yellow'}
      isFitContent
      className={cn('step-actions__button', { actions__button_disabled: !!action.disabled }, action.className)}
      onClick={() => action.onClick(setAction)}
      disabled={!!action.disabled}
      isLoading={!!action.isLoading}
    >
      {action.label}
    </Button>
  );
};

export default memo(ActionButton);
