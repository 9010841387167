import { SVGProps } from 'react';

const ReloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="17" viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M15.562 8.562a7 7 0 1 0-2.711 5.533" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="m15.03 11.888-2.218-2.534a.5.5 0 0 1 .376-.83h4.796a.5.5 0 0 1 .377.83l-2.218 2.534a.74.74 0 0 1-1.114 0z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ReloadIcon;
