import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getExportingImageSize } from '@/components/charts/DownloadChartButton/helpers';
import { prepareMatrixLayout } from '@/components/charts/DownloadChartButton/prepareLayouts';

import { experimentSelectors } from '@/store/slices/experiment';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';

import { downloadMatrixViewAsSVG } from './helpers/svgImage';
import { downloadMatrixViewAsPNG } from './helpers/pngImage';

import { plotsPadding } from './constants';
import { getFullFileNameWithTime } from './helpers';

const { imageWidth } = getExportingImageSize();

type TUseMatrixScreenShot = {
  labelRef: MutableRefObject<Nullable<HTMLSpanElement>>;
};

const useMatrixScreenShot = ({ labelRef }: TUseMatrixScreenShot) => {
  const { Plotly } = window;
  const columnsCountLocalStorage = Number(localStorage.getItem('columnsCount')) ?? 1;

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);

  const graphInfoArr = useSelector(scatterplotsSelectors.selectMatrixPlotInfoSortedArr);
  const graphInfoObj = useSelector(scatterplotsSelectors.selectMatrixPlotInfoObj);

  const plotSize = useRef({ width: 0, height: 0 });

  const [isLoading, setIsLoading] = useState(false);

  const columnsCount = useMemo(() => {
    if (graphInfoArr.length <= columnsCountLocalStorage) {
      return graphInfoArr.length;
    }

    return columnsCountLocalStorage;
  }, [columnsCountLocalStorage, graphInfoArr.length]);

  const width = useMemo(() => Math.floor(imageWidth / columnsCount), [columnsCount]);
  const height = useMemo(() => Math.floor(width / 1.5), [width]);
  const imageHeight = useMemo(
    () => Math.round(graphInfoArr.length / columnsCount) * height,
    [graphInfoArr, columnsCount, height]
  );

  useEffect(() => {
    plotSize.current.width = width - plotsPadding * columnsCount;
    plotSize.current.height = height - plotsPadding * Math.floor(graphInfoArr.length / columnsCount);
  }, [graphInfoArr.length, columnsCount, width, height]);

  const handleDownload = useCallback(
    async (type: 'svg' | 'png') => {
      setIsLoading(true);
      const graphBlocks = graphInfoArr.reduce((acc: IPlotlyHTMLDivElement[], graph) => {
        const plotDiv = document.querySelector<IPlotlyHTMLDivElement>(`#${graph.plotId}`);
        if (plotDiv) {
          acc.push(plotDiv);
        }
        return acc;
      }, []);

      if (!graphBlocks.length) {
        setIsLoading(false);

        return;
      }

      const { width: plotWidth, height: plotHeight } = plotSize.current;

      const plotlyImages = await Promise.all(
        graphBlocks.map((graphBlock) => {
          const { xAxisLabel, yAxisLabel } = graphInfoObj[graphBlock.id];
          const { layout } = prepareMatrixLayout(graphBlock, xAxisLabel, yAxisLabel);
          return Plotly.toImage(
            {
              data: graphBlock.data ?? [],
              layout,
            },
            {
              format: type,
              width: plotWidth,
              height: plotHeight,
            }
          );
        })
      );

      const fileName = experimentName ?? `Matrix View`;

      const fullFileName = getFullFileNameWithTime(fileName);

      if (type === 'svg') {
        downloadMatrixViewAsSVG({
          plotlyImages,
          imageWidth,
          imageHeight,
          columnsCount,
          plotWidth,
          plotHeight,
          graphBlocks,
          graphInfoObj,
          labelElement: labelRef.current,
          plotSize,
          fullFileName,
        });

        setIsLoading(false);
        return;
      }

      downloadMatrixViewAsPNG({
        plotlyImages,
        imageWidth,
        imageHeight,
        columnsCount,
        plotWidth,
        plotHeight,
        graphBlocks,
        graphInfoObj,
        labelElement: labelRef.current,
        plotSize,
        fullFileName,
      });

      setIsLoading(false);
    },
    [graphInfoArr, graphInfoObj, columnsCount]
  );

  return useMemo(() => ({ handleDownload, isLoading }), [handleDownload, isLoading]);
};

export default useMatrixScreenShot;
