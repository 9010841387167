/**
 * See: https://github.com/corygibbons/react-file-icon/blob/master/src/glyphs.js
 */

export default {
  compressed: (
    <path
      d="M.25 0A.25.25 0 0 0 0 .25v1.5c0 .138.112.25.25.25h1.5A.25.25 0 0 0 2 1.75V.25A.25.25 0 0 0 1.75 0H.25zM1 17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H1zm0 4v3h2v-3H1zM2 2.25A.25.25 0 0 1 2.25 2h1.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 3.75v-1.5zM.25 4a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h1.5A.25.25 0 0 0 2 5.75v-1.5A.25.25 0 0 0 1.75 4H.25zM2 6.25A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 7.75v-1.5zM.25 8a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h1.5A.25.25 0 0 0 2 9.75v-1.5A.25.25 0 0 0 1.75 8H.25zM2 10.25a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5zM.25 12a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.25-.25H.25zM2 14.25a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5z"
      transform="translate(15 1)"
      fillRule="evenodd"
    />
  ),
  spreadsheet: (
    <path
      d="M0 8h6V5H0v3zm0 5h6v-3H0v3zM0 3h6V0H0v3zm8 5h12V5H8v3zm0 5h12v-3H8v3zM8 0v3h12V0H8z"
      transform="translate(14 14)"
      fillRule="evenodd"
    />
  ),
};
