import { OptionProps } from 'react-select';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';

import ConsumableComponent from '~/src/components/runDesign/ConsumableComponent';

import styles from './CustomComponents.mosule.scss';

const cn = classnames.bind(styles);

type TWavesOption = OptionProps<TBasicOption> & { consumableClassName?: string };

const WavesOption = ({ data, innerProps, innerRef, isSelected, consumableClassName }: TWavesOption) => (
  <article ref={innerRef} {...innerProps} className={cn('reagent-option__wrapper')}>
    <div className={cn('reagent-option__check-mark')}>{isSelected && <icons.CheckMarkIcon width={8} />}</div>
    <div className={cn('reagent-option__type')}>{data?.customData?.type ?? ''}</div>{' '}
    <ConsumableComponent
      className={cn('reagent-option__item', consumableClassName)}
      wavesClassName={cn('reagent-option__waves')}
      consumable={data.customData?.reagent as any}
    />
  </article>
);

export default WavesOption;
