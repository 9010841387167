/* eslint-disable @typescript-eslint/no-unused-vars */

import vShader from '../shaders/vertex.vert';
import fShader from '../shaders/fragment.frag';
import basicProgramInit from './basicProgramInit';
import projectSpecifiedInit from './projectSpecifiedInit';
import { changeTextures } from './changeTextures';
import changeLut from './changeLut';
import { render } from './render';

let isWebGlContextLost = false;
const createCanvas = () => {
  const webglId = 'webgl_renderer';
  const existingCanvas = document.getElementById(webglId) as HTMLCanvasElement;
  if (existingCanvas) {
    return existingCanvas;
  }

  const canvas = document.createElement('canvas');
  canvas.id = webglId;
  canvas.addEventListener('webglcontextlost', () => {
    isWebGlContextLost = true;
  });
  return canvas;
};

const showCanvas = (canvas: HTMLCanvasElement) => {
  document.querySelector('#root')?.appendChild(canvas);
  canvas.style.position = 'fixed';
  canvas.style.left = '0';
  canvas.style.top = '0';
  canvas.style.zIndex = '2000';
  canvas.style.pointerEvents = 'none';
};

export default class WebglHelper {
  public canvas = createCanvas();

  public gl = this.canvas.getContext('webgl') as WebGLRenderingContext;

  private textures: Nullable<WebGLTexture>[] = [];

  private lutTextureList: Record<string, Nullable<WebGLTexture>> = {};

  private uniforms?: Record<string, Nullable<WebGLUniformLocation>>;

  constructor() {
    this.init();
  }

  async init() {
    const tileWidth = 1000;
    const tileHeight = 1000;

    this.canvas.width = tileWidth;
    this.canvas.height = tileHeight;
    // uncomment for display
    // showCanvas(this.canvas);

    if (isWebGlContextLost) {
      this.restoreContext();
    }
    const { gl } = this;
    if (!gl) {
      return;
    }

    const program = basicProgramInit(gl, vShader, fShader);

    if (!program) {
      return;
    }

    const { uniforms, lutTextureList } = await projectSpecifiedInit(gl, program);

    this.uniforms = uniforms;
    if (lutTextureList) {
      this.lutTextureList = lutTextureList;
    }

    return this;
  }

  resize(width: number, height: number) {
    const { gl } = this;
    if (!gl) {
      return;
    }

    gl.viewport(0, gl.canvas.height - height, width, height);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    return this;
  }

  changeLut(lutIdList: string[]) {
    const { gl, lutTextureList } = this;
    if (!gl || !lutTextureList) {
      return;
    }

    changeLut(gl, lutIdList, lutTextureList);
  }

  changeTextures(imageDataList: ImageData[] | HTMLImageElement[] | HTMLCanvasElement[]) {
    const { gl, textures } = this;
    if (!gl || !textures) {
      return;
    }

    changeTextures(gl, textures, imageDataList);
  }

  render(settings: TRenderOptions) {
    const { gl, uniforms, lutTextureList } = this;
    if (!gl || !uniforms || !settings.min?.length || !lutTextureList) {
      return;
    }

    render(gl, uniforms, settings, lutTextureList);
    return this;
  }

  restoreContext() {
    this.canvas.remove();
    this.canvas = createCanvas();

    // Create a new context because the current context is lost.
    this.gl = this.canvas.getContext('webgl') as WebGLRenderingContext;

    // Clear all textures because they do not belong to the new context.
    this.textures = [];

    isWebGlContextLost = false;
  }
}
