import type { RootState } from '@/store';
import { EPreprocessingAssayType } from '@/helpers';

const TARGET_AND_AFFECTED_CELLS_SHAPES_AMOUNT = 4;

export const isStepInitCompleted = (state: RootState): boolean => {
  const { beadsCount, beadTypes, datasets, preprocessingAssayId, beadChannel } = state.preprocessing;
  const beadsCond = beadsCount && beadTypes.length === beadsCount;

  const isStepCompletedBySelectedId = {
    [EPreprocessingAssayType.cytokineSecretion]: Boolean(
      beadsCond &&
        datasets.length &&
        beadChannel &&
        preprocessingAssayId &&
        preprocessingAssayId === EPreprocessingAssayType.cytokineSecretion
    ),
    [EPreprocessingAssayType.cellKilling]: Boolean(
      datasets.length && preprocessingAssayId && preprocessingAssayId === EPreprocessingAssayType.cellKilling
    ),
    default: false,
  };

  return isStepCompletedBySelectedId[preprocessingAssayId ?? 'default'];
};

export const isStepCytokineDefineBeadsCompleted = (state: RootState): boolean => {
  const stepInitCompleted = isStepInitCompleted(state);
  const { beadsCount, generalBeadShapes } = state.preprocessing;
  const shapeLength = Object.keys(generalBeadShapes).length;
  const stepCytokineDefineBeadsCompleted = beadsCount === shapeLength;
  return stepInitCompleted && stepCytokineDefineBeadsCompleted;
};

export const isStepCytokineReviewBeadsCompleted = (state: RootState): boolean => {
  const stepInitCompleted = isStepInitCompleted(state);
  const stepCytokineDefineBeadsCompleted = isStepCytokineDefineBeadsCompleted(state);
  return stepInitCompleted && stepCytokineDefineBeadsCompleted;
};

export const isStepCellKillingDefineCellsCompleted = (state: RootState): boolean => {
  const stepInitCompleted = isStepInitCompleted(state);
  const { generalCellShapes, cellTypes } = state.preprocessing;
  const shapeLength = Object.keys(generalCellShapes).length;
  const stepStepCellKillingDefineCellsCompleted = cellTypes.length === shapeLength;

  return stepInitCompleted && stepStepCellKillingDefineCellsCompleted;
};

export const isStepCellKillingReviewCellsCompleted = (state: RootState): boolean => {
  const stepInitCompleted = isStepInitCompleted(state);
  const stepStepCellKillingDefineCellsCompleted = isStepCellKillingDefineCellsCompleted(state);
  return stepInitCompleted && stepStepCellKillingDefineCellsCompleted;
};

export const isStepCellKillingDefineCellsTargetCompleted = (state: RootState): boolean => {
  const { generalCellShapes } = state.preprocessing;
  const generalShape = Object.values(generalCellShapes)?.[0] ?? null;
  const targetAndAffectedGates = generalShape ? generalShape?.shapeNodes : {};
  const targetAndAffectedGateList = Object.values(targetAndAffectedGates);

  return targetAndAffectedGateList.length === TARGET_AND_AFFECTED_CELLS_SHAPES_AMOUNT;
};

export const isStepCellKillingReviewCellsTargetCompleted = (state: RootState): boolean => {
  const stepInitCompleted = isStepInitCompleted(state);
  const stepCellKillingDefineCellsTargetCompleted = isStepCellKillingDefineCellsTargetCompleted(state);

  return stepInitCompleted && stepCellKillingDefineCellsTargetCompleted;
};
