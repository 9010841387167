import { SVGProps } from 'react';

const ProjectCardArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M17.5 0C27.165 0 35 7.835 35 17.5S27.165 35 17.5 35 0 27.165 0 17.5 7.835 0 17.5 0z" fill="#56E5F1" />
      <g stroke="currentColor" strokeLinecap="round" strokeWidth="1.5">
        <path d="M23.035 17.5h-11M23.035 17.5 19.5 13.964M23.035 17.5 19.5 21.035" />
      </g>
    </g>
  </svg>
);

export default ProjectCardArrowIcon;
