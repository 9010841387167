import {
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { toast } from 'react-toastify';

import { arrToMapByKeys, isSomeObjValueDiff, LANE_LETTER_NAME_LIST } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { CSSProperty } from '@/helpers/interfaces';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignLaneCagingSettings,
} from '@/store/slices/experimentRunDesign';

import { RunDesignContext } from '@/pages/experiment-run-design/context';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import { CCEType } from '@/graphql/API';

import styles from './EditSettings.module.scss';
import EditFields from './components/EditFields';

import { TLaneCagingSettings } from './types';
import { validateCagingSettings } from './helpers';
import BaseSettingSections from '../BaseSettingSections';
import { getHeaderSectionsForEditMode } from '../../helpers';

const cn = classNames.bind(styles);

type TEditSettings = {
  className?: string;
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  isViewMoreActive: boolean;
  openSettingsInfoModal: (isEditMode?: boolean) => void;
};

const EditSettings: FC<TEditSettings> = ({ className, cardRef, isViewMoreActive, openSettingsInfoModal }) => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const isAdvancedMode = useSelector(experimentRunDesignSelectors.selectIsAdvancedMode);
  const laneLetterMap = useSelector(experimentRunDesignSelectors.selectCurrentLaneLetterMap);
  const lanesCagingSettings = useSelector(experimentRunDesignSelectors.selectLanesCagingSettings);
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);

  const [tableData, setTableData] = useState<Nullable<TLaneCagingSettings>[]>([]);
  const isSomeErrorRef = useRef(false);
  const isSomeSubtractiveCCE = useMemo(
    () => tableData.some((row) => row?.cceType === CCEType.SUBTRACTIVE_CCE),
    [tableData]
  );

  const settingSections = useMemo(
    () => getHeaderSectionsForEditMode(isSomeSubtractiveCCE, isViewMoreActive),
    [isSomeSubtractiveCCE, isViewMoreActive]
  );

  const handleTitleClick = useCallback(() => openSettingsInfoModal(false), [openSettingsInfoModal]);

  useEffect(() => {
    if (!globalCagingSettings) {
      return;
    }

    const settingsByLaneMap = arrToMapByKeys(lanesCagingSettings ?? [], 'laneId');
    const settingsByLaneList = LANE_LETTER_NAME_LIST.map((letter) => {
      if (!laneLetterMap[letter]) {
        return null;
      }
      const result: TLaneCagingSettings = {
        ...settingsByLaneMap[letter],
        overrideSettings: {
          ...globalCagingSettings,
          ...settingsByLaneMap[letter]?.overrideSettings,
        },
        laneId: letter,
        errors: {},
      };
      return result;
    });

    setTableData(settingsByLaneList);
  }, [laneLetterMap, globalCagingSettings, lanesCagingSettings]);

  const handleChangeTableData = useCallback(
    (setStateData: SetStateAction<Nullable<TLaneCagingSettings>[]>) => {
      let isSomeError = false;
      const newData = Array.isArray(setStateData)
        ? structuredClone(setStateData)
        : structuredClone(setStateData(tableData));

      newData.forEach((lane) => {
        if (!lane) return;

        const { isValid, errors } = validateCagingSettings(lane);
        lane.errors = { ...errors };

        if (!isValid) {
          isSomeError = true;
        }
      });

      setTableData(newData);
      isSomeErrorRef.current = isSomeError;
    },
    [tableData]
  );

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      saveAndContinue: {
        clickHandler: () => {
          const clonedTableData = structuredClone(tableData);

          const preparedLanesData = clonedTableData.reduce((acc: TRunDesignLaneCagingSettings[], laneInfo) => {
            if (!laneInfo) {
              return acc;
            }

            laneInfo.errors = {};

            const { isValid, errors } = validateCagingSettings(laneInfo);

            if (!isValid) {
              isSomeErrorRef.current = true;
              laneInfo.errors = errors;
            }

            const { laneId, overrideSettings, cellToCage, magnification, cellToSubtract, cceType } = laneInfo;

            acc.push({
              laneId,
              magnification,
              cceType,
              cellToCage: cellToCage ?? null,
              cellToSubtract: cellToSubtract ?? null,
              overrideSettings: overrideSettings ? { ...overrideSettings } : null,
              __typename: 'LaneCagingSettings',
            });

            return acc;
          }, []);

          if (isSomeErrorRef.current) {
            setTableData(clonedTableData);
            toast.info('The caging settings are not correct. Please review and try again');

            return;
          }

          appDispatch(experimentRunDesignActions.updateLanesCagingSettings(preparedLanesData));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
  }, [tableData, overrideCustomFooterConfig, globalCagingSettings]);

  useEffect(
    () => () => {
      appDispatch(experimentRunDesignActions.setIsAdvancedMode(false));
    },
    []
  );

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('edit-settings__table', className)}
      headerClassName={cn('edit-settings__header')}
      style={
        {
          '--settings-count': settingSections.length,
        } as CSSProperty
      }
      header={
        <RunDesignTable.Row className={cn('edit-settings__header-row')}>
          <BaseSettingSections sectionsData={settingSections} cardRef={cardRef} onTitleClick={handleTitleClick} />
        </RunDesignTable.Row>
      }
    >
      {tableData.map((laneCagingSettings, laneIndex) => {
        const key = laneIndex;
        const isDiff = isSomeObjValueDiff(laneCagingSettings?.overrideSettings ?? {}, globalCagingSettings ?? {});

        return laneCagingSettings ? (
          <EditFields
            key={key}
            isAdvancedMode={isAdvancedMode}
            isViewMoreActive={isViewMoreActive}
            laneCagingSettings={laneCagingSettings}
            laneIndex={laneIndex}
            setTableData={handleChangeTableData}
            isChanged={isDiff}
            isSomeSubtractiveCCE={isSomeSubtractiveCCE}
          />
        ) : (
          <RunDesignTable.Row key={key} />
        );
      })}
    </RunDesignTable>
  );
};

export default EditSettings;
