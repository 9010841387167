import { SVGProps } from 'react';

const GateOnParentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M4.5 14H2a2 2 0 0 1-2-2V9.5h0M9.5 14H12a2 2 0 0 0 2-2V9.5h0M4.5 0H2a2 2 0 0 0-2 2v2.5h0M9.5 0H12a2 2 0 0 1 2 2v2.5h0M6.95 10V3.5M4 6.369l2.828-2.828M9.85 6.38 7.024 3.552" />
    </g>
  </svg>
);

export default GateOnParentIcon;
