import { SVGProps } from 'react';

const ArrowStartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="m7.028 1 5.977 5.987M7.028 13l5.971-5.916M8.954 7.084 1 7.049" />
    </g>
  </svg>
);

export default ArrowStartIcon;
