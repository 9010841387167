import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { HomeCardLayout } from './types';

import { useGetLastJobRunDate } from './hooks/useGetLastJobRunDate';
import { useGetLastExperimentDate } from './hooks/useGetLastExperimentDate';

import HomeCard from './components/HomeCard';
import HomeHeader from './components/HomeHeader';

import runsImageUrl from './images/runs.png';
import processingImageUrl from './images/processing.png';
import designImageUrl from './images/design.png';

import styles from './Home.module.scss';

const cn = classNames.bind(styles);

const Home: FC = () => {
  const navigate = useNavigate();

  const lastExperimentDate = useGetLastExperimentDate();
  const lastJobRunDate = useGetLastJobRunDate();

  const runsData = useMemo(
    () => ({
      total: 108,
      status: 'Executed',
      date: lastExperimentDate,
      current: {
        count: 2,
        isInProcess: true,
        comment: 'In progress',
      },
      ctaText: 'Go to Runs',
    }),
    [lastExperimentDate]
  );

  const handleRunsClick = () => {
    navigate('/');
  };

  const processingData = useMemo(
    () => ({
      total: 48,
      status: 'Processed',
      date: lastJobRunDate,
      current: {
        count: 5,
        isInProcess: true,
        comment: 'In queue (processing)',
      },
      ctaText: 'Go to Processing',
    }),
    [lastJobRunDate]
  );

  const handleProcessingClick = () => {
    navigate('/jobs-dashboard');
  };

  const designData = {
    counterList: [
      {
        count: 18,
        comment: 'Run templates',
      },
      {
        count: 12,
        comment: 'Ready to run',
      },
    ],
    ctaText: 'Go to Design',
  };

  const handleDesignClick = () => {
    navigate('/design-experiment');
  };

  return (
    <div className={cn('home')}>
      <div className={cn('home__wrap')}>
        <HomeHeader />
        <HomeCard title="Runs" image={{ url: runsImageUrl, alt: 'cells' }} data={runsData} onClick={handleRunsClick} />
        <HomeCard
          title="Processing"
          image={{ url: processingImageUrl, alt: 'mechanism' }}
          data={processingData}
          onClick={handleProcessingClick}
        />
        <HomeCard
          title={
            <>
              Experiment
              <br />
              design
            </>
          }
          image={{ url: designImageUrl, alt: 'tweezers hold small plate' }}
          layout={HomeCardLayout.union}
          data={designData}
          onClick={handleDesignClick}
        />
      </div>
    </div>
  );
};

export default Home;
