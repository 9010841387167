import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import { defaultColorList } from '@/data/colors';
import { lutImageURIList } from '@/hooks/useWebgl/lut';

import { FC } from 'react';
import styles from './ColorPicker.module.scss';

const cn = classnames.bind(styles);

type TColorButtonProps = {
  onClick: (_newSelectedColor: string) => void;
  color: string;
  label?: string;
  isSelectedColor: boolean;
  disabled?: boolean;
};

const ColorButton: FC<TColorButtonProps> = ({ onClick, color, label, isSelectedColor, disabled }) => (
  <button
    className={cn('color-button', { 'color-button_active': isSelectedColor })}
    type="button"
    onClick={() => onClick(color)}
    style={{ backgroundColor: color }}
    disabled={disabled}
    {...addTooltip(label)}
    aria-label={`${label} color`}
  />
);

type TLutButtonProps = {
  type: string;
  onClick: (_type: string) => void;
  image: string;
  isSelected: boolean;
  disabled?: boolean;
};

const LutButton: FC<TLutButtonProps> = ({ type, image, onClick, isSelected, disabled = false }) => (
  <button
    className={cn('lut-button', { 'lut-button_active': isSelected })}
    onClick={() => onClick(type)}
    disabled={disabled}
    aria-label="select lut coloring"
    {...addTooltip(type.replace(/^[a-zA-Z]/, (match) => match.toUpperCase()))}
  >
    <img src={image} alt="" className={cn('lut-button__image')} />
  </button>
);

type TColorPickerProps = {
  value: string;
  className?: string;
  onChange: (_color: string) => void;
  onLutChange: (_type: string) => void;
  isLutSelected: boolean;
  lutType: string;
  disabled?: boolean;
};

const ColorPicker: FC<TColorPickerProps> = ({
  value,
  onChange,
  onLutChange,
  disabled = false,
  className,
  isLutSelected,
  lutType,
}) => {
  const changeColorHandler = (newColor: string) => {
    onChange(newColor);
  };

  return (
    <div className={cn('color-picker', className)}>
      {defaultColorList.map((color) => (
        <ColorButton
          key={color.value}
          onClick={changeColorHandler}
          color={color.value}
          label={color.label}
          isSelectedColor={!isLutSelected && value === color.value}
          disabled={!!disabled}
        />
      ))}
      {Object.entries(lutImageURIList).map(([type, lutImage]) => (
        <LutButton
          key={type}
          onClick={onLutChange}
          type={type}
          image={lutImage as string}
          isSelected={isLutSelected && type === lutType}
          disabled={!!disabled}
        />
      ))}
    </div>
  );
};

export default ColorPicker;
