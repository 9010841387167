import { SVGProps } from 'react';

const ExportStatisticsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="1.5">
      <path d="M17 12.3v2.44c0 .89-.09 1.22-.27 1.54-.17.33-.43.58-.75.76-.33.17-.65.26-1.54.26H3.56c-.89 0-1.21-.09-1.54-.26a1.82 1.82 0 0 1-.75-.76c-.18-.33-.27-.65-.27-1.54V12.3M9 1.6v9.5m0-10L4.5 5.6M9 1.1l4.5 4.5" />
    </g>
  </svg>
);

export default ExportStatisticsIcon;
