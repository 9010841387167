import { SVGProps } from 'react';

const HamburgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M14.971 1h-14M14.971 6h-14M14.971 11h-14" />
    </g>
  </svg>
);

export default HamburgerIcon;
