import { SVGProps } from 'react';

const TimelineArrowNext = (props: SVGProps<SVGSVGElement>) => (
  <svg width="5" height="10" viewBox="-1 -1 7 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M0 10 5 5M0 0l5 5" />
    </g>
  </svg>
);

export default TimelineArrowNext;
