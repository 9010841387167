import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ECdnObjectType } from '@/types/cdnData';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { findLaneInScanList } from '@/helpers/scans';
import { getCdnTableDataFileName } from '@/helpers/cdnData/fetch';

import { getEntitiesDataByGates, UNGATED_ID } from '@/hooks/useExperimentContext/helpers';

import { cdnAPI } from '@/store/services/cdnData';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { datasetsSelectors } from '@/store/slices/datasets';
import { viewerSelectors } from '@/store/slices/viewer';

export function useCageEntitiesByLanesAndGates(
  experimentId: string,
  currentAppDatasetList: Array<TDataset>,
  currentAppDataset: Nullable<TDataset>,
  gateList?: Array<TGate>
) {
  const chartId = usePlotChartIdContext();

  const useOptimizedData = useSelector(viewerSelectors.selectUseOptimizedData);
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));
  const laneDatasetDetailsList = useSelector(datasetsSelectors.selectDatasetDetailsList(experimentId));

  const [fetchCageEntityList] = cdnAPI.useLazyFetchCageEntityListQuery();

  const [cageEntitiesByLanesAndGates, setCageEntitiesByLanesAndGates] = useState<TEntitiesByLanesAndGates>({});
  const [cageEntitiesLoadingLaneList, setCageEntitiesLoadingLaneList] = useState<string[]>([]);
  const [cageEntitiesLoadedLaneList, setCageEntitiesLoadedLaneList] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (!gateList) {
      return;
    }
    // recalculate gates percentages for all cage data
    const newCageEntitiesByLanesAndGates: TEntitiesByLanesAndGates = {};
    Object.keys(cageEntitiesByLanesAndGates).forEach((lanePath) => {
      const cagesData = cageEntitiesByLanesAndGates[lanePath];
      const dataset = laneDatasetDetailsList.find((lane) => lane.dataset.path === lanePath);

      newCageEntitiesByLanesAndGates[lanePath] = getEntitiesDataByGates({
        entityList: cagesData[UNGATED_ID].fullCageList,
        gateList,
        objectType,
        scanId: dataset?.scanId,
        laneId: dataset?.laneId,
      });
    });
    setCageEntitiesByLanesAndGates(newCageEntitiesByLanesAndGates);
  }, [gateList]);

  useEffect(() => {
    if (!gateList) {
      return;
    }
    // recalculate gates percentages for just loaded cage data
    const newCageEntitiesByLanesAndGates: TEntitiesByLanesAndGates = {};
    cageEntitiesLoadedLaneList.forEach((lanePath) => {
      const cagesData = cageEntitiesByLanesAndGates[lanePath];

      if (!cagesData) return;

      const cagesDataKeys = Object.keys(cagesData);
      if (cagesDataKeys.length === 1 && cagesDataKeys[0] === UNGATED_ID) {
        const dataset = laneDatasetDetailsList.find((lane) => lane.dataset.path === lanePath);

        newCageEntitiesByLanesAndGates[lanePath] = getEntitiesDataByGates({
          entityList: cagesData[UNGATED_ID].fullCageList,
          gateList,
          objectType,
          scanId: dataset?.scanId,
          laneId: dataset?.laneId,
        });
      } else {
        newCageEntitiesByLanesAndGates[lanePath] = cagesData;
      }
    });
    setCageEntitiesByLanesAndGates(newCageEntitiesByLanesAndGates);
  }, [cageEntitiesLoadedLaneList]);

  useEffect(() => {
    if (!gateList) {
      return;
    }
    const newCageEntitiesByLanesAndGates: TEntitiesByLanesAndGates = {};
    const cageEntitiesByLanesAndGatesKeys = Object.keys(cageEntitiesByLanesAndGates);

    if (!cageEntitiesByLanesAndGatesKeys.length) return;

    cageEntitiesByLanesAndGatesKeys.forEach((lanePath) => {
      const cagesData = cageEntitiesByLanesAndGates[lanePath];
      const dataset = laneDatasetDetailsList.find((lane) => lane.dataset.path === lanePath);

      if (!cagesData) return;

      const entitiesDataByGates = getEntitiesDataByGates({
        entityList: cagesData[UNGATED_ID].fullCageList,
        gateList,
        objectType,
        scanId: dataset?.scanId,
        laneId: dataset?.laneId,
      });
      newCageEntitiesByLanesAndGates[lanePath] = entitiesDataByGates;
    });

    setCageEntitiesByLanesAndGates(newCageEntitiesByLanesAndGates);
  }, [objectType]);

  useEffect(() => {
    setIsError(false);

    const datasetList = [...currentAppDatasetList];
    if (currentAppDataset && !datasetList.find((dataset) => dataset.path === currentAppDataset.path)) {
      datasetList.push(currentAppDataset);
    }
    datasetList.forEach((dataset) => {
      const lane = findLaneInScanList(scanList, dataset.scanId, dataset.laneId);
      if (lane) {
        if (!cageEntitiesLoadingLaneList.includes(lane.path)) {
          setCageEntitiesLoadingLaneList((prev) => [...prev, lane.path]);
          fetchCageEntityList(lane, true)
            .unwrap()
            .then((cageList) => {
              const entitiesDataByGates = getEntitiesDataByGates({
                entityList: cageList,
                gateList: [],
                objectType,
                scanId: dataset.scanId,
                laneId: dataset.laneId,
              });
              setCageEntitiesByLanesAndGates((prev) => ({ ...prev, [lane.path]: entitiesDataByGates }));
            })
            .catch((error) => {
              setIsError(true);
              const { fileName } = getCdnTableDataFileName({
                lane,
                type: ECdnObjectType.cageEntity,
                useOptimizedData,
              });
              if (!fileName) {
                return;
              }
              showErrorToast(getErrorMessage(error));
            })
            .finally(() => {
              setCageEntitiesLoadedLaneList((prev) => [...prev, lane.path]);
            });
        }
      }
    });

    return () => {
      setIsError(false);
    };
  }, [currentAppDataset, currentAppDatasetList, objectType]);

  return { cageEntitiesByLanesAndGates, cageEntitiesLoadedLaneList, isError };
}
