import { Viewport as OsdViewport, Point as OsdPoint } from 'openseadragon';

import { clamp, MAX_NAVIGATOR_ZOOM, MIN_NAVIGATOR_ZOOM } from '@/helpers';

const getNavigatorZoomFactor = (viewport: OsdViewport) => {
  const minLinearViewportZoom = Math.log(viewport.getMinZoom());
  const maxLinearViewportZoom = Math.log(viewport.getMaxZoom());
  return (MAX_NAVIGATOR_ZOOM - MIN_NAVIGATOR_ZOOM) / (maxLinearViewportZoom - minLinearViewportZoom);
};

export const getViewportZoomFromNavigatorZoom = (viewport: OsdViewport, navigatorZoom: number) => {
  const navigatorZoomFactor = getNavigatorZoomFactor(viewport);
  const linearViewportZoom = (navigatorZoom - 1) / navigatorZoomFactor;
  const viewportZoom = Math.exp(linearViewportZoom);
  return clamp(viewport.getMinZoom(), viewportZoom, viewport.getMaxZoom());
};

export const getNavigatorZoomFromViewportZoom = (viewport: OsdViewport, viewportZoom = viewport.getZoom(true)) => {
  const linearViewportZoom = Math.log(viewportZoom);
  const navigatorZoomFactor = getNavigatorZoomFactor(viewport);
  const navigatorZoom = navigatorZoomFactor * linearViewportZoom + 1;
  return clamp(MIN_NAVIGATOR_ZOOM, navigatorZoom, MAX_NAVIGATOR_ZOOM);
};

export const getPositionFromPoint = (viewport: OsdViewport, point: OsdPoint) => {
  const coords = viewport.viewportToImageCoordinates(point.x, point.y);
  return { x: coords.x, y: coords.y };
};

export const getPointFromPosition = (viewport: OsdViewport, position: TLanePosition) => {
  const bounds = viewport.getBounds();
  const point = viewport.imageToViewportCoordinates(position.x, position.y);
  point.x = clamp(bounds.width / 2, point.x, 1 - bounds.width / 2);
  return point;
};
