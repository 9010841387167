import { EPageWithChartType, EChartType } from '@/types/charts';

export const getAvailableChartTypeList = (pageType: EPageWithChartType) => {
  switch (pageType) {
    case EPageWithChartType.multiHistogram:
      return [EChartType.histogram, EChartType.lineHistogram];
    case EPageWithChartType.heatmap:
      return [];
    default:
      return [
        EChartType.dot,
        EChartType.dotDensity,
        EChartType.histogram2d,
        EChartType.histogram2dcontour,
        EChartType.histogram,
        EChartType.lineHistogram,
      ];
  }
};
