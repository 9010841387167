import { CELSIUS, FAHRENHEIT } from '@/helpers';

import { TemperatureUnit } from '@/graphql/API';

export const getGridTemplate = (count: number, width = 215) =>
  Array.from({ length: count }, () => `${width}px`).join(' ');

export const getDurationText = (durationSec: number) => {
  const secondsInHour = 60 * 60;
  const secondsInMinute = 60;
  if (durationSec < secondsInMinute) {
    return `${durationSec}s`;
  }

  const hours = Math.floor(durationSec / secondsInHour);
  const minutes = Math.floor((durationSec - hours * secondsInHour) / secondsInMinute);
  const durationParts = [];
  if (hours > 0) {
    durationParts.push(`${hours}h`);
  }
  if (minutes > 0) {
    durationParts.push(`${minutes}m`);
  }
  return durationParts.join(' ');
};

export const getTemperatureText = (temperature: number, temperatureUnit: TemperatureUnit) => {
  const temperatureUnitMap = { [TemperatureUnit.C]: CELSIUS, [TemperatureUnit.F]: FAHRENHEIT };
  return `${temperature}${temperatureUnitMap[temperatureUnit]}`;
};
