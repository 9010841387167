import { API as awsAPI } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';

import { OnExperimentEventSubscription } from '@/graphql/API';
import * as subscriptions from '@/graphql/subscriptions';
import { v4 as uuidv4 } from 'uuid';

export enum EOnExperimentEventType {
  associated = 'experiment.defineClasses.dataset.associated',
  generated = 'experiment.cage.summary.generated',
  zipped = 'experiment.dataset.zipped',
  primaryAnalysisGatesCreated = 'experiment.primaryAnalysisGates.dataset.created',
}

type TCallBackData = {
  eventType: EOnExperimentEventType;
  success: (() => void) | ((eventData: Record<string, string>) => void);
  experimentChange?: () => void;
  error?: (error: unknown) => void;
};

class GraphqlEvents {
  private experimentId: string;

  private organizationId: string;

  private eventsSubscription: Nullable<GraphQLSubscription<OnExperimentEventSubscription>>;

  private callBackDataList: Record<string, TCallBackData>;

  constructor() {
    this.experimentId = '';
    this.organizationId = '';
    this.eventsSubscription = null;
    this.callBackDataList = {};
  }

  private onExperimentEvent(eventData: Record<string, string>) {
    Object.values(this.callBackDataList).forEach((callBackData) => {
      if (callBackData.eventType === eventData.type && typeof callBackData.success === 'function') {
        callBackData.success(eventData);
      }
    });
  }

  private onExperimentChange() {
    Object.values(this.callBackDataList).forEach((callBackData) => {
      if (typeof callBackData.experimentChange === 'function') {
        callBackData.experimentChange();
      }
    });
  }

  private onExperimentError(error: unknown) {
    Object.values(this.callBackDataList).forEach((callBackData) => {
      if (typeof callBackData.error === 'function') {
        callBackData.error(error);
      }
    });
  }

  async subscribe(experimentId: string, organizationId: string, callBackData: TCallBackData) {
    if (!experimentId || !organizationId) {
      return;
    }

    const callBackUuid = uuidv4();
    if (experimentId === this.experimentId && organizationId === this.organizationId) {
      this.callBackDataList[callBackUuid] = callBackData;
      return callBackUuid;
    }

    this.experimentId = experimentId;
    this.organizationId = organizationId;
    if (this.eventsSubscription) {
      // @ts-ignore
      this.eventsSubscription.unsubscribe();
      this.onExperimentChange();
    }

    this.callBackDataList = { [callBackUuid]: callBackData };
    this.eventsSubscription = await awsAPI
      .graphql<GraphQLSubscription<OnExperimentEventSubscription>>({
        query: subscriptions.onExperimentEvent,
        variables: {
          experimentId,
          organizationId,
        },
      })
      // @ts-ignore
      .subscribe({
        next: ({
          provider: _,
          value,
        }: {
          provider: unknown;
          value: { data: { onExperimentEvent: Record<string, string> } };
        }) => {
          this.onExperimentEvent(value.data.onExperimentEvent);
        },
        error: ({ provider: _, error }: { provider: unknown; error: unknown }) => {
          this.onExperimentError(error);
        },
      });
    return callBackUuid;
  }

  unsubscribe(callBackUuid: string) {
    delete this.callBackDataList[callBackUuid];
  }
}

const graphqlEvents = new GraphqlEvents();

export default graphqlEvents;
