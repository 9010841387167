import { FC, useMemo, CSSProperties } from 'react';
import classNames from 'classnames/bind';
import { iconList } from '@/helpers/runDesigns/constants';
import IncubationBlock from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/components/IncubationBlock';
import WashBlock from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/components/WashBlock';
import { CellKilling, Placement, TemperatureUnit } from '@/graphql/API';
import { ETimeInputUnit, TTimeInputUnit } from '@/components/common/TimeInput';
import { clamp } from '@/helpers';
import getTimeUnits from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getTimeUnits';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import {
  isCellKillingComponent,
  isComponentWithIncubation,
  isComponentWithScan,
  isComponentWithWash,
  isCytokineSecretionComponent,
  isIncubationComponent,
  isScanComponent,
  isSurfaceReceptorComponent,
  isMRNAComponent,
  isCellCagingComponent,
} from '@/helpers/runDesigns/typeGuards';

import { EScanTotal, TScanTotal } from './types';
import SingleScanContent from './components/SingleScanContent';
import MultiScanContent from './components/MultiScanContent';
import SimultaneousScanContent from './components/SimultaneousScanContent';
import styles from './MainBlock.module.scss';
import CellKillingStainContent from './components/CellKillingStainContent';
import AddAntibodyBlock from './components/AddAntibodyBlock';
import AddMediaBlock from './components/AddMediaBlock';
import { totalScansByType } from './constants';
import NoScanContent from './components/NoScanContent';

const cn = classNames.bind(styles);

type TMainBlockProps = {
  component: Nullable<TRunDesignComponent>;
  componentList: Nullable<TRunDesignComponent[]>;
  incubationDuration: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  incubationTemperature: {
    value: number;
    onChange: (value: number) => void;
  };
  incubationTemperatureUnit: {
    value: TemperatureUnit;
    onChange: (value: TemperatureUnit) => void;
  };
  incubationLocationOnDevice: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
  washDuration: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  washTemperature: {
    value: number;
    onChange: (value: number) => void;
  };
  washTemperatureUnit: {
    value: TemperatureUnit;
    onChange: (value: TemperatureUnit) => void;
  };
  washNumberOfWashes: {
    value: number;
    onChange: (value: number) => void;
  };
  scanOrder: number;
  scanNumberOfScans: {
    value: number;
    onChange: (value: number) => void;
  };
  scanInterval: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  scanFirstScanTime: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  cellKillingStainPlacement: {
    relativeTo: {
      list: TOption[];
      value: Nullable<string>;
      onChange: (value: string, isChecked: boolean) => void;
    };
  };
  handleUpdateIncubationComponentHasTreatment: ({ value }: { value: boolean }) => void;
};

const MainBlock: FC<TMainBlockProps> = ({
  component,
  componentList,
  incubationDuration,
  incubationTemperature,
  incubationTemperatureUnit,
  incubationLocationOnDevice,
  washDuration,
  washTemperature,
  washTemperatureUnit,
  washNumberOfWashes,
  scanOrder,
  scanNumberOfScans,
  scanInterval,
  scanFirstScanTime,
  cellKillingStainPlacement,
  handleUpdateIncubationComponentHasTreatment,
}) => {
  const hasIncubation = useMemo(() => isComponentWithIncubation(component), [component]);

  const hasMedia = useMemo(() => isIncubationComponent(component), [component]);

  const hasWash = useMemo(() => isComponentWithWash(component), [component]);

  const hasScanOptions = useMemo(() => isComponentWithScan(component), [component]);

  const hasAntibody = useMemo(
    () => isSurfaceReceptorComponent(component) || isCytokineSecretionComponent(component),
    [component]
  );

  const incubationBlockProps = {
    durationHour: {
      value: getTimeUnits(incubationDuration.value).hours,
      onChange: (value: number) =>
        incubationDuration.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    durationMinute: {
      value: getTimeUnits(incubationDuration.value).minutes,
      onChange: (value: number) =>
        incubationDuration.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    temperature: incubationTemperature,
    temperatureUnit: incubationTemperatureUnit,
    location: incubationLocationOnDevice,
  };

  const washBlockProps = {
    durationHour: {
      value: getTimeUnits(washDuration.value).hours,
      onChange: (value: number) => washDuration.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    durationMinute: {
      value: getTimeUnits(washDuration.value).minutes,
      onChange: (value: number) => washDuration.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    temperature: washTemperature,
    temperatureUnit: washTemperatureUnit,
    washesTotal: washNumberOfWashes,
  };

  const noScanProps = {
    scanTotal: {
      value: EScanTotal.noScan,
      onChange: (value: TScanTotal) => {
        scanNumberOfScans.onChange(totalScansByType[value]);
      },
    },
  };

  const singleScanProps = {
    scanOrder,
    scanTotal: {
      value: EScanTotal.single,
      onChange: (value: TScanTotal) => {
        scanNumberOfScans.onChange(totalScansByType[value]);
      },
    },
  };

  const multiScanProps = {
    scanOrder,
    scanTotal: {
      value: EScanTotal.multi,
      onChange: (value: TScanTotal) => {
        scanNumberOfScans.onChange(totalScansByType[value]);
      },
    },
    scanFirstScanTimeHour: {
      value: getTimeUnits(scanFirstScanTime.value).hours,
      onChange: (value: number) =>
        scanFirstScanTime.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    scanFirstScanTimeMinute: {
      value: getTimeUnits(scanFirstScanTime.value).minutes,
      onChange: (value: number) =>
        scanFirstScanTime.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    scanIntervalHour: {
      value: getTimeUnits(scanInterval.value).hours,
      onChange: (value: number) => scanInterval.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    scanIntervalMinute: {
      value: getTimeUnits(scanInterval.value).minutes,
      onChange: (value: number) => scanInterval.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    scanNumberOfScans: {
      value: scanNumberOfScans.value,
      onChange: (value: number) => scanNumberOfScans.onChange(clamp(2, value, 999)),
    },
  };

  const simultaneousScanProps = {
    componentList,
  };

  const cellKillingComponent = useMemo(
    () => (componentList?.find((item) => isCellKillingComponent(item)) as CellKilling) ?? null,
    [componentList]
  );

  const cellKillingStainProps = useMemo(
    () => ({
      componentList:
        componentList?.filter(
          (componentData) =>
            !isCellKillingComponent(componentData) &&
            !isScanComponent(componentData) &&
            componentData.timing.placement !== Placement.SIMULTANEOUS
        ) ?? [],
      cellKillingStainPlacement,
      deliveryStainsAt: cellKillingComponent?.deliveryStainsAt ?? [],
    }),
    [componentList, cellKillingStainPlacement, cellKillingComponent]
  );

  const isCellKilling = useMemo(() => isCellKillingComponent(component), [component]);

  const isMRNA = useMemo(() => isMRNAComponent(component), [component]);

  const isCellCaging = useMemo(() => isCellCagingComponent(component), [component]);

  const addMediaProps = useMemo(
    () => ({
      onChange: handleUpdateIncubationComponentHasTreatment,
      scanNumberOfScans: scanNumberOfScans?.value ?? '',
      checkedByDefault: !!isIncubationComponent(component) && !!component.hasTreatment,
    }),
    [handleUpdateIncubationComponentHasTreatment, scanNumberOfScans.value, component]
  );

  return (
    <div className={cn('main-block')}>
      <div className={cn('main-block__column')}>
        {isMRNA && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')} style={{ '--sub-column-count': 6 } as CSSProperties}>
              <li className={cn('main-block__header-elem')}>
                Cell lysis
                {iconList.iconCellLysis}
              </li>
              <li className={cn('main-block__header-elem')}>
                cDNA Synthesis
                {iconList.iconMRna}
              </li>
              <li className={cn('main-block__header-elem')}>
                Elution
                {iconList.iconElution}
              </li>
            </ul>

            <div className={cn('main-block__content')}>
              <div
                className={cn(
                  'main-block__block',
                  'main-block__block_center-content',
                  'main-block__text-info',
                  'text-center'
                )}
              >
                Settings for mRNA Expression step <br /> cannot be changed
              </div>
              <div className={cn('main-block__block')} />
            </div>
          </div>
        )}
        {isCellCaging && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Scan + Segment
                <div>
                  {iconList.iconScanning}
                  {iconList.iconSegment}
                </div>
              </li>
              <li className={cn('main-block__header-elem')}>
                Form CCE
                {iconList.iconCaging}
              </li>
              <li className={cn('main-block__header-elem')}>
                Wash
                {iconList.iconWash}
              </li>
            </ul>

            <div className={cn('main-block__content')}>
              <div
                className={cn(
                  'main-block__block',
                  'main-block__block_center-content',
                  'main-block__text-info',
                  'text-center'
                )}
              >
                You will define ‘Segmentation’ and ’Caging’ parameters in Step 5 <br />
                of the experiment setup workflow.
              </div>
              <div className={cn('main-block__block')} />
            </div>
          </div>
        )}
        {isCellKilling && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Add cell killing stain
                {iconList.cellKillingStain}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <CellKillingStainContent {...cellKillingStainProps} />
            </div>
          </div>
        )}
        {hasAntibody && (
          <div className={cn('main-block__inner-column', 'main-block__inner-column_max-limited')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Add antibody
                {iconList.iconAddAntibody}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <AddAntibodyBlock componentName={component?.name ?? ''} />
            </div>
          </div>
        )}
        {hasMedia && (
          <div className={cn('main-block__inner-column', 'main-block__inner-column_max-limited')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Add media
                {iconList.iconAddMedia}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <AddMediaBlock {...addMediaProps} />
            </div>
          </div>
        )}
        {hasIncubation && !isCellKilling && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Incubate
                {incubationLocationOnDevice.value && iconList.iconIncubation}
                {!incubationLocationOnDevice.value && iconList.iconIncubationOffDevice}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <IncubationBlock {...incubationBlockProps} />
            </div>
          </div>
        )}
        {hasWash && !isCellKilling && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Wash
                {iconList.iconWash}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <WashBlock {...washBlockProps} />
            </div>
          </div>
        )}
      </div>
      {hasScanOptions && (
        <div className={cn('main-block__column')}>
          <div className={cn('main-block__inner-column', 'main-block__inner-column_scan')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Scan
                {scanNumberOfScans.value === 1 && iconList.iconScanning}
                {component?.timing.placement === Placement.SIMULTANEOUS ||
                  (scanNumberOfScans.value > 1 && iconList.iconScanningStacked)}
              </li>
            </ul>

            {component?.timing.placement !== Placement.SIMULTANEOUS && scanNumberOfScans.value === 1 && (
              <SingleScanContent {...singleScanProps} />
            )}
            {component?.timing.placement !== Placement.SIMULTANEOUS && scanNumberOfScans.value > 1 && (
              <MultiScanContent {...multiScanProps} />
            )}
            {component?.timing.placement !== Placement.SIMULTANEOUS && scanNumberOfScans.value === 0 && (
              <NoScanContent {...noScanProps} />
            )}
            {component?.timing.placement === Placement.SIMULTANEOUS && (
              <SimultaneousScanContent {...simultaneousScanProps} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainBlock;
