import { SVGProps } from 'react';

const UsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <mask id="path-2-inside-1_12052_5815">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3823 20.2941H19.8823C19.8823 17.5652 17.6701 15.3529 14.9412 15.3529C12.2122 15.3529 10 17.5652 10 20.2941C10 20.2941 10 20.2941 10 20.2941H11.5C11.5 20.2941 11.5 20.2941 11.5 20.2941C11.5 18.3936 13.0407 16.8529 14.9412 16.8529C16.8417 16.8529 18.3823 18.3936 18.3823 20.2941Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3823 20.2941H19.8823C19.8823 17.5652 17.6701 15.3529 14.9412 15.3529C12.2122 15.3529 10 17.5652 10 20.2941C10 20.2941 10 20.2941 10 20.2941H11.5C11.5 20.2941 11.5 20.2941 11.5 20.2941C11.5 18.3936 13.0407 16.8529 14.9412 16.8529C16.8417 16.8529 18.3823 18.3936 18.3823 20.2941Z"
      fill="currentColor"
    />
    <path
      d="M18.3823 20.2941H16.8823V21.7941H18.3823V20.2941ZM19.8823 20.2941V21.7941H21.3823V20.2941H19.8823ZM10 20.2941H8.5V21.7941H10V20.2941ZM11.5 20.2941V21.7941H13V20.2941H11.5ZM18.3823 21.7941H19.8823V18.7941H18.3823V21.7941ZM21.3823 20.2941C21.3823 16.7367 18.4985 13.8529 14.9412 13.8529V16.8529C16.8417 16.8529 18.3823 18.3936 18.3823 20.2941H21.3823ZM14.9412 13.8529C11.3838 13.8529 8.5 16.7367 8.5 20.2941H11.5C11.5 18.3936 13.0407 16.8529 14.9412 16.8529V13.8529ZM8.5 20.2941V20.2941H11.5V20.2941H8.5ZM10 21.7941H11.5V18.7941H10V21.7941ZM10 20.2941C10 20.2941 10 20.2941 10 20.2941V20.2941C10 20.2941 10 20.2941 10 20.2941V20.2941V20.2941V20.2941V20.2941C10 20.2941 10 20.2941 10 20.2941V20.2941C10 20.2941 10 20.2941 10 20.2941H13C13 20.2941 13 20.2941 13 20.2941V20.2941C13 20.2941 13 20.2941 13 20.2941V20.2941V20.2941V20.2941V20.2941C13 20.2941 13 20.2941 13 20.2941V20.2941C13 20.2941 13 20.2941 13 20.2941H10ZM14.9412 15.3529C12.2122 15.3529 10 17.5652 10 20.2941H13C13 19.222 13.8691 18.3529 14.9412 18.3529V15.3529ZM19.8823 20.2941C19.8823 17.5652 17.6701 15.3529 14.9412 15.3529V18.3529C16.0133 18.3529 16.8823 19.222 16.8823 20.2941H19.8823Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_12052_5815)"
    />
    <path
      d="M16.6618 12.4706C16.6618 13.4208 15.8914 14.1912 14.9412 14.1912C13.9909 14.1912 13.2206 13.4208 13.2206 12.4706C13.2206 11.5203 13.9909 10.75 14.9412 10.75C15.8914 10.75 16.6618 11.5203 16.6618 12.4706Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <mask id="path-5-inside-2_12052_5815">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 20.2941H31C31 17.5652 28.7878 15.3529 26.0588 15.3529C23.3299 15.3529 21.1176 17.5652 21.1176 20.2941C21.1176 20.2941 21.1176 20.2941 21.1176 20.2941H22.6176C22.6176 20.2941 22.6176 20.2941 22.6176 20.2941C22.6176 18.3936 24.1583 16.8529 26.0588 16.8529C27.9593 16.8529 29.5 18.3936 29.5 20.2941Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5 20.2941H31C31 17.5652 28.7878 15.3529 26.0588 15.3529C23.3299 15.3529 21.1176 17.5652 21.1176 20.2941C21.1176 20.2941 21.1176 20.2941 21.1176 20.2941H22.6176C22.6176 20.2941 22.6176 20.2941 22.6176 20.2941C22.6176 18.3936 24.1583 16.8529 26.0588 16.8529C27.9593 16.8529 29.5 18.3936 29.5 20.2941Z"
      fill="currentColor"
    />
    <path
      d="M29.5 20.2941H28V21.7941H29.5V20.2941ZM31 20.2941V21.7941H32.5V20.2941H31ZM21.1176 20.2941H19.6176V21.7941H21.1176V20.2941ZM22.6176 20.2941V21.7941H24.1176V20.2941H22.6176ZM29.5 21.7941H31V18.7941H29.5V21.7941ZM32.5 20.2941C32.5 16.7367 29.6162 13.8529 26.0588 13.8529V16.8529C27.9593 16.8529 29.5 18.3936 29.5 20.2941H32.5ZM26.0588 13.8529C22.5015 13.8529 19.6176 16.7367 19.6176 20.2941H22.6176C22.6176 18.3936 24.1583 16.8529 26.0588 16.8529V13.8529ZM19.6176 20.2941V20.2941H22.6176V20.2941H19.6176ZM21.1176 21.7941H22.6176V18.7941H21.1176V21.7941ZM21.1176 20.2941C21.1176 20.2941 21.1176 20.2941 21.1176 20.2941V20.2941C21.1176 20.2941 21.1176 20.2941 21.1176 20.2941V20.2941V20.2941V20.2941V20.2941C21.1176 20.2941 21.1176 20.2941 21.1176 20.2941V20.2941C21.1176 20.2941 21.1176 20.2941 21.1176 20.2941H24.1176C24.1176 20.2941 24.1176 20.2941 24.1176 20.2941V20.2941C24.1176 20.2941 24.1176 20.2941 24.1176 20.2941V20.2941V20.2941V20.2941V20.2941C24.1176 20.2941 24.1176 20.2941 24.1176 20.2941V20.2941C24.1176 20.2941 24.1176 20.2941 24.1176 20.2941H21.1176ZM26.0588 15.3529C23.3299 15.3529 21.1176 17.5652 21.1176 20.2941H24.1176C24.1176 19.222 24.9867 18.3529 26.0588 18.3529V15.3529ZM31 20.2941C31 17.5652 28.7878 15.3529 26.0588 15.3529V18.3529C27.1309 18.3529 28 19.222 28 20.2941H31Z"
      fill="currentColor"
      mask="url(#path-5-inside-2_12052_5815)"
    />
    <path
      d="M27.7794 12.4706C27.7794 13.4208 27.0091 14.1912 26.0588 14.1912C25.1086 14.1912 24.3383 13.4208 24.3383 12.4706C24.3383 11.5203 25.1086 10.75 26.0588 10.75C27.0091 10.75 27.7794 11.5203 27.7794 12.4706Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <mask id="path-8-inside-3_12052_5815">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3823 31C29.3823 29.0995 27.8417 27.5588 25.9412 27.5588C24.0407 27.5588 22.5 29.0995 22.5 31H21C21 28.2711 23.2122 26.0588 25.9412 26.0588C28.6701 26.0588 30.8823 28.2711 30.8823 31H29.3823Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3823 31C29.3823 29.0995 27.8417 27.5588 25.9412 27.5588C24.0407 27.5588 22.5 29.0995 22.5 31H21C21 28.2711 23.2122 26.0588 25.9412 26.0588C28.6701 26.0588 30.8823 28.2711 30.8823 31H29.3823Z"
      fill="currentColor"
    />
    <path
      d="M29.3823 31L27.8823 31L27.8824 32.5H29.3823V31ZM22.5 31V32.5H24L24 31L22.5 31ZM21 31L19.5 31L19.5 32.5H21V31ZM30.8823 31V32.5H32.3824L32.3823 31L30.8823 31ZM30.8823 31C30.8823 28.2711 28.6701 26.0588 25.9412 26.0588V29.0588C27.0133 29.0588 27.8823 29.9279 27.8823 31L30.8823 31ZM25.9412 26.0588C23.2122 26.0588 21 28.2711 21 31L24 31C24 29.9279 24.8691 29.0588 25.9412 29.0588V26.0588ZM21 32.5H22.5V29.5H21V32.5ZM25.9412 24.5588C22.3838 24.5588 19.5 27.4426 19.5 31L22.5 31C22.5 29.0995 24.0407 27.5588 25.9412 27.5588V24.5588ZM32.3823 31C32.3823 27.4426 29.4985 24.5588 25.9412 24.5588V27.5588C27.8417 27.5588 29.3823 29.0995 29.3823 31L32.3823 31ZM29.3823 32.5H30.8823V29.5H29.3823V32.5Z"
      fill="currentColor"
      mask="url(#path-8-inside-3_12052_5815)"
    />
    <path
      d="M27.6618 23.1765C27.6618 24.1267 26.8915 24.8971 25.9412 24.8971C24.9909 24.8971 24.2206 24.1267 24.2206 23.1765C24.2206 22.2262 24.9909 21.4559 25.9412 21.4559C26.8915 21.4559 27.6618 22.2262 27.6618 23.1765Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <mask id="path-11-inside-4_12052_5815">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3823 31C18.3823 29.0995 16.8417 27.5588 14.9412 27.5588C13.0407 27.5588 11.5 29.0995 11.5 31H10C10 28.2711 12.2122 26.0588 14.9412 26.0588C17.6701 26.0588 19.8823 28.2711 19.8823 31H18.3823Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3823 31C18.3823 29.0995 16.8417 27.5588 14.9412 27.5588C13.0407 27.5588 11.5 29.0995 11.5 31H10C10 28.2711 12.2122 26.0588 14.9412 26.0588C17.6701 26.0588 19.8823 28.2711 19.8823 31H18.3823Z"
      fill="currentColor"
    />
    <path
      d="M18.3823 31L16.8823 31L16.8824 32.5H18.3823V31ZM11.5 31V32.5H13L13 31L11.5 31ZM10 31L8.5 31L8.5 32.5H10V31ZM19.8823 31V32.5H21.3824L21.3823 31L19.8823 31ZM19.8823 31C19.8823 28.2711 17.6701 26.0588 14.9412 26.0588V29.0588C16.0133 29.0588 16.8823 29.9279 16.8823 31L19.8823 31ZM14.9412 26.0588C12.2122 26.0588 10 28.2711 10 31L13 31C13 29.9279 13.8691 29.0588 14.9412 29.0588V26.0588ZM10 32.5H11.5V29.5H10V32.5ZM14.9412 24.5588C11.3838 24.5588 8.50001 27.4426 8.5 31L11.5 31C11.5 29.0995 13.0407 27.5588 14.9412 27.5588V24.5588ZM21.3823 31C21.3823 27.4426 18.4985 24.5588 14.9412 24.5588V27.5588C16.8417 27.5588 18.3823 29.0995 18.3823 31L21.3823 31ZM18.3823 32.5H19.8823V29.5H18.3823V32.5Z"
      fill="currentColor"
      mask="url(#path-11-inside-4_12052_5815)"
    />
    <path
      d="M16.6618 23.1765C16.6618 24.1267 15.8915 24.8971 14.9412 24.8971C13.9909 24.8971 13.2206 24.1267 13.2206 23.1765C13.2206 22.2262 13.9909 21.4559 14.9412 21.4559C15.8915 21.4559 16.6618 22.2262 16.6618 23.1765Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default UsersIcon;
