import { FC, useState } from 'react';

import ChartModebarButton from '@/components/charts/ChartModebarButton';
import icons from '@/components/common/icons';

import ManageDatasetListModal from './ManageDatasetListModal';

const ManageDatasetListButton: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <ChartModebarButton
        IconComponent={icons.GridPlus}
        onClick={openModal}
        active={isOpen}
        tooltip="Manage dataset list"
      />
      <ManageDatasetListModal isOpen={isOpen} onClose={closeModal} />
    </>
  );
};

export default ManageDatasetListButton;
