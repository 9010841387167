import { SVGProps } from 'react';

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="17" viewBox="14 15 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd">
      <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" d="M14.5 18.683h8" />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M26.5 18.683h4M26.5 21.683v-6M18.5 29.683v-6M22.5 26.684h8M14.5 26.684h4"
      />
    </g>
  </svg>
);

export default SettingsIcon;
