import { SVGProps } from 'react';

const DisableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="18.5" cy="18.5" r="11.5" transform="rotate(45 18.5 18.5)" stroke="currentColor" strokeWidth="2" />
    <line x1="26.9853" y1="10.7218" x2="10.7218" y2="26.9853" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default DisableIcon;
