import { SVGProps } from 'react';

const RetentionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" viewBox="0 0 47 47" fill="none" {...props}>
    <circle
      cx="23.5"
      cy="23.5"
      transform="rotate(-90 23.5 23.5)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
      r="7.5"
    />
    <g fill="currentColor">
      <circle cx="23.5" cy="2.5" r="2.5" transform="rotate(90 23.5 2.5)" />
      <circle cx="23.5" cy="44.5" r="2.5" transform="rotate(90 23.5 44.5)" />
      <circle cx="31.521" cy="4.092" r="2.5" transform="rotate(112.455 31.521 4.092)" />
      <circle cx="15.479" cy="42.908" r="2.5" transform="rotate(112.455 15.479 42.908)" />
      <circle cx="38.349" cy="8.651" r="2.5" transform="rotate(135 38.35 8.65)" />
      <circle cx="8.651" cy="38.349" r="2.5" transform="rotate(135 8.65 38.35)" />
      <circle cx="42.895" cy="15.448" r="2.5" transform="rotate(157.455 42.895 15.448)" />
      <circle cx="4.105" cy="31.552" r="2.5" transform="rotate(157.455 4.105 31.552)" />
      <circle cx="44.5" cy="23.5" r="2.5" transform="rotate(-180 44.5 23.5)" />
      <circle cx="2.5" cy="23.5" r="2.5" transform="rotate(-180 2.5 23.5)" />
      <circle cx="42.908" cy="31.521" r="2.5" transform="rotate(-157.545 42.908 31.521)" />
      <circle cx="4.092" cy="15.479" r="2.5" transform="rotate(-157.545 4.092 15.479)" />
      <circle cx="38.349" cy="38.349" r="2.5" transform="rotate(-135 38.35 38.35)" />
      <circle cx="8.651" cy="8.651" r="2.5" transform="rotate(-135 8.65 8.65)" />
      <circle cx="31.552" cy="42.895" r="2.5" transform="rotate(-112.545 31.552 42.895)" />
      <circle cx="15.448" cy="4.105" r="2.5" transform="rotate(-112.545 15.448 4.105)" />
    </g>
  </svg>
);

export default RetentionIcon;
