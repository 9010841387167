import {
  gateSchema,
  gateListSchema,
  circleGateModelSchema,
  gatePointsSchema,
  gatePolygonsSchema,
  rangeGateModelSchema,
  polarSectorFromServerSchema,
  baseGateFromServerSchema,
  basePolarAreaFromServerSchema,
} from '@/validationSchemas';
import { isPolarAreaTypeGate, isPolarTypeGate } from '@/helpers/typeGuards';
import { parseJSON } from '@/helpers';

// backwards compatibility with gates that were previously saved
// further all gates will be saved with dimensions in the snake case
const getCorrectDimension = (dimension: string) => {
  switch (dimension) {
    case 'isCell':
      return 'is_cell';
    case 'cageCenterDist':
      return 'cage_center_dist';
    case 'objectAreaPx':
      return 'object_area_px';
    case 'perimeterAreaRatio':
      return 'perimeter_area_ratio';
    case 'objectCount':
      return 'object_count';
    default:
      return dimension;
  }
};

const transformGateModel = (gate: TGateFromServer) => {
  const { model, type } = gate.shape;
  const typeValidators = {
    circle: circleGateModelSchema,
    polygon: gatePointsSchema,
    range: rangeGateModelSchema,
    polar: gatePolygonsSchema,
    'polar-sector': polarSectorFromServerSchema,
  };

  const modelCheckResult = typeValidators[type].safeParse({ ...model });

  if (!modelCheckResult.success) {
    console.error(modelCheckResult?.error);
    return null;
  }

  return model;
};

export const transformGatePropertiesToObject = (gateFromServer: TGateFromServer): TGateProperties => {
  let gateProperties: TGateProperties = {};

  if (typeof gateFromServer?.properties === 'string') {
    const parsedProperties = parseJSON(gateFromServer?.properties ?? '') ?? {};
    gateProperties = parsedProperties;
  } else if (typeof gateFromServer?.properties === 'object') {
    gateProperties = gateFromServer?.properties;
  }

  return gateProperties;
};

export const transformPolarArea = (
  polarSectorGate: Omit<TGateFromServer, 'properties' | 'xDimension' | 'yDimension' | 'scanId' | 'laneId'>,
  polarGate: TGateFromServer,
  level = 0
): Nullable<TGate> => {
  if (!polarSectorGate?.shape) return null;

  if (!isPolarAreaTypeGate(polarSectorGate.shape) || !isPolarTypeGate(polarGate.shape)) return null;

  const polarGateProperties = transformGatePropertiesToObject(polarGate);

  const gate = {
    ...polarSectorGate,
    name: polarSectorGate?.name ?? '',
    level,
    parentId: polarGate.id ?? '',
    properties: polarGateProperties,
    shape: {
      type: polarSectorGate?.shape?.type ?? 'polar-sector',
      model: {
        sectorAngle: polarSectorGate.shape.model.sectorAngle,
        offsetAngle: polarSectorGate.shape.model.offsetAngle,
        x: polarGate?.shape?.model?.x,
        y: polarGate?.shape?.model?.y,
      },
    },
    xDimension: polarGate?.xDimension ? getCorrectDimension(polarGate.xDimension) : '',
    yDimension: polarGate?.yDimension ? getCorrectDimension(polarGate.yDimension) : '',
    scanId: polarGate.scanId ?? '',
    laneId: polarGate.laneId ?? '',
  };

  return { ...gate, gateNodes: transformGateListResponse(polarSectorGate.gateNodes ?? [], gate ?? null, level + 1) };
};

export const transformGateResponse = (gateFromServer: TGateFromServer, parentId = '', level = 0): Nullable<TGate> => {
  try {
    const model = transformGateModel(gateFromServer);

    if (!model) return null;

    // Do not increase level for the Polar Gate children, as we don't show the parent gate in the tree
    const gateNodesLevel = isPolarTypeGate(gateFromServer.shape) ? level : level + 1;

    const gate: TGate = {
      id: gateFromServer.id ?? '',
      parentId,
      name: gateFromServer.name?.trim() ?? '',
      scanId: gateFromServer.scanId ?? '',
      laneId: gateFromServer.laneId ?? '',
      shape: {
        type: gateFromServer.shape.type ?? 'polygon',
        model,
      } as TGateShape,
      overrides: Array.isArray(gateFromServer?.overrides) ? gateFromServer.overrides : [],
      xDimension: gateFromServer.xDimension ? getCorrectDimension(gateFromServer.xDimension) : '',
      yDimension: gateFromServer.yDimension ? getCorrectDimension(gateFromServer.yDimension) : '',
      gateNodes: transformGateListResponse(gateFromServer?.gateNodes ?? [], gateFromServer ?? null, gateNodesLevel),
      properties: transformGatePropertiesToObject(gateFromServer),
      level,
    };

    const gateCheckResult = gateSchema.safeParse({ ...gate });

    if (!gateCheckResult.success) {
      console.error(gateCheckResult?.error);

      return null;
    }

    return gate;
  } catch (error) {
    return null;
  }
};

export const transformGateListResponse = (
  gatesFromServer: TGateFromServer[],
  parentGateFromServer: Nullable<TGateFromServer>,
  level = 0
): TGate[] => {
  const parsedGateList: TGate[] = [];

  gatesFromServer.forEach((gate) => {
    const { type } = gate.shape;
    const typeValidators = {
      circle: baseGateFromServerSchema,
      polygon: baseGateFromServerSchema,
      range: baseGateFromServerSchema,
      polar: baseGateFromServerSchema,
      'polar-sector': basePolarAreaFromServerSchema,
    };

    const gateFromServerCheckResult = typeValidators[type].safeParse(gate);
    if (!gateFromServerCheckResult.success) {
      console.error(gateFromServerCheckResult?.error, gate);
    }

    const parsedGate =
      type === 'polar-sector' && parentGateFromServer
        ? transformPolarArea(gate, parentGateFromServer, level)
        : transformGateResponse(gate, parentGateFromServer?.id ?? '', level);

    if (!parsedGate) return;
    parsedGateList.push(parsedGate);
  });

  const gateListCheckResult = gateListSchema.safeParse(parsedGateList);

  if (!gateListCheckResult.success) {
    console.error(gateListCheckResult?.error);
  }

  return parsedGateList.sort((current, next) => current.name.localeCompare(next.name));
};
