import { SVGProps } from 'react';

const VisibilityOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="12" viewBox="-1 -1 21 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <path
        d="M7.245 12.717c.701.152 1.432.238 2.184.238 5.207 0 9.428-4.126 9.428-6 0-.743-.664-1.84-1.79-2.894"
        strokeLinecap="round"
      />
      <path d="M14.493 2.248C13.03 1.484 11.293.955 9.429.955 4.22.955 0 5.081 0 6.955c0 1.184 1.684 3.266 4.236 4.639" />
      <path strokeLinecap="round" d="M15.994.476 1.907 13.226" />
      <path d="M11.782 4.639a3.429 3.429 0 0 0-5.018 4.67" />
      <path d="M9.629 10.304a3.43 3.43 0 0 0 3.074-2.802" strokeLinecap="round" />
    </g>
  </svg>
);

export default VisibilityOffIcon;
