import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { MIN_LANES_COUNT } from '@/helpers';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import CounterModule from './CounterModule';

import styles from '../RunDetails.module.scss';

const cn = classnames.bind(styles);

type TIncubationsTotalProps = {
  isChangeAllowed: boolean;
};

const IncubationsTotal: FC<TIncubationsTotalProps> = ({ isChangeAllowed }) => {
  const appDispatch = useAppDispatch();

  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const incubationComponentList = useSelector(experimentRunDesignSelectors.selectIncubationComponentList);
  const fullComponentList = useSelector(experimentRunDesignSelectors.selectComponentList);

  const prevComponentId = useMemo(() => {
    if (incubationComponentList.length) {
      return incubationComponentList[incubationComponentList.length - 1].id;
    }
    if (fullComponentList.length) {
      return fullComponentList[fullComponentList.length - 1].id;
    }
    return '';
  }, [incubationComponentList.length, fullComponentList.length]);

  const incubationsCount = useMemo(() => incubationComponentList?.length ?? 0, [incubationComponentList]);

  const handleIncubationCountIncrease = () => {
    appDispatch(
      experimentRunDesignActions.addIncubationComponent({
        id: prevComponentId,
      })
    );
  };

  const handleIncubationCountDecrease = () => {
    appDispatch(experimentRunDesignActions.removeIncubationComponent());
  };

  return (
    <CounterModule
      className={cn('run-details__module')}
      count={incubationsCount}
      onIncrease={handleIncubationCountIncrease}
      onDecrease={handleIncubationCountDecrease}
      showControls={isChangeAllowed}
      description="Incubations"
      isLocked={someRunDesignCardIsEdit}
      minCount={MIN_LANES_COUNT}
    />
  );
};

export default memo(IncubationsTotal);
