import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import { CellIndex } from '@/graphql/API';
import AddToTableButton from '../AddToTableButton';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TPreSelectedCellTypeName = {
  className?: string;
  cellIndex?: Nullable<CellIndex>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onPlusClick?: MouseEventHandler<HTMLDivElement>;
};

const PreSelectedCellTypeName: FC<TPreSelectedCellTypeName> = ({ cellIndex, className, onClick, onPlusClick }) => {
  const handlePlusClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onPlusClick?.(e);
    },
    [onPlusClick]
  );
  return (
    <AddToTableButton className={cn('consumable__btn', 'preselected-consumable', className)} onClick={onClick}>
      <div className={cn('consumable', 'consumable_preselected')}>
        <div className={cn('preselected-consumable__name')}>
          <span className={cn('consumable__name')}>{cellIndex?.name}</span>
        </div>

        <div className={cn('preselected-consumable__plus-wrapper')}>
          <div
            role="presentation"
            className={cn('preselected-consumable__plus-btn')}
            aria-label="Add consumable"
            onClick={handlePlusClick}
          >
            <icons.PlusIcon />
          </div>
        </div>
      </div>
    </AddToTableButton>
  );
};

export default PreSelectedCellTypeName;
