import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { navigatorSelectors } from '@/store/slices/navigator';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

type TOpenNavigatorButtonProps = {
  className?: string;
};

const OpenNavigatorButton: FC<TOpenNavigatorButtonProps> = ({ className }) => {
  const { openNavigator } = useExperimentModalsContext();

  const navigatorLanesSettings = useSelector(navigatorSelectors.selectLanesSettings);
  const isLanesDataExist = useMemo(() => !!Object.values(navigatorLanesSettings).length, [navigatorLanesSettings]);

  const handleButtonClick = () => {
    openNavigator();
  };

  return (
    <Button color="yellow" className={className} onClick={handleButtonClick} disabled={!isLanesDataExist}>
      <icons.NavigatorIcon />
      <span>Navigator</span>
    </Button>
  );
};

export default OpenNavigatorButton;
