import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useUserRole } from '@/hooks';

import { headerActions } from '@/store/slices/header';

import JobRunList from '@/components/jobs/JobRunList';

const JobsDashboard: FC = () => {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPermissionsRead, isJobRunReadAllowed } = useUserRole();

  useEffect(() => {
    if (!isPermissionsRead) {
      return;
    }

    if (!isJobRunReadAllowed) {
      navigate('/');
    } else {
      appDispatch(headerActions.removeAllLinks());
    }
  }, [isPermissionsRead, isJobRunReadAllowed]);

  return <JobRunList />;
};

export default JobsDashboard;
