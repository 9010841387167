import { SVGProps } from 'react';

const Loading = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.062 2.5a7 7 0 1 0 3.28 4.321"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default Loading;
