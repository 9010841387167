import { CSSProperties, FC } from 'react';
import classnames from 'classnames/bind';

import styles from './NoDataFound.module.scss';

const cn = classnames.bind(styles);

type TNoDataFound = {
  className?: string;
  alignment?: 'left' | 'right' | 'center';
  size?: 'small' | 'normal' | 'big';
  textData?: React.ReactNode;
};

const fontSizeByType = {
  small: '17px',
  normal: '20px',
  big: '24px',
};

const NoDataFound: FC<TNoDataFound> = ({
  className,
  alignment = 'center',
  size = 'small',
  textData = 'No data found',
}) => (
  <div
    className={cn('content', className)}
    style={
      {
        '--text-alignment': alignment,
        '--font-size': fontSizeByType[size],
      } as CSSProperties
    }
  >
    {textData}
  </div>
);

export default NoDataFound;
