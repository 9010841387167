import { TSelectionDivElement } from '@/hooks/gates/types';

export type TGateVariant = 'rectangle' | 'ellipse' | 'polar' | 'polygon' | 'range' | 'split-gate';

export type TGateLabel = {
  position: {
    x: number;
    y: number;
  };
  text: string;
  rtl?: boolean;
};

type TRectangleGateLayout = {
  type: 'rectangle';
  label: Nullable<TGateLabel>;
  coordinates: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  styles: Record<string, string>;
};

type TEllipseGateLayout = {
  type: 'ellipse';
  label: Nullable<TGateLabel>;
  coordinates: {
    cx: number;
    cy: number;
    rx: number;
    ry: number;
  };
  styles: Record<string, string>;
};

type TPolygonGateLayout = {
  type: 'polygon';
  label: Nullable<TGateLabel>;
  coordinates: {
    path: [number, number][];
  };
  styles: Record<string, string>;
};

type TPolarGateLayout = {
  type: 'polar';
  labelList: Nullable<TGateLabel>[];
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }[];
  styles: Record<string, string>;
};

type TRangeGateLayout = {
  type: 'range';
  label: Nullable<TGateLabel>;
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }[];
  styles: Record<string, string>;
};

type TSplitGateLayout = {
  type: 'split-gate';
  labelList: Nullable<TGateLabel>[];
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }[];
  styles: Record<string, string>;
};

export type TGateLayout =
  | TRectangleGateLayout
  | TEllipseGateLayout
  | TPolygonGateLayout
  | TPolarGateLayout
  | TRangeGateLayout
  | TSplitGateLayout;

export const isGateVariant = (text: string): text is TGateVariant =>
  ['rectangle', 'ellipse', 'polar', 'polygon', 'range', 'split-gate'].includes(text);

export type TDownloadChartHandlerPayload = {
  plotlyImage: string;
  imageWidth: number;
  imageHeight: number;
  renderPlotHeight: number;
  renderPlotWidth: number;
  fileName: string;
  layout: TPlotLayout;
  overflowContainer?: TSelectionDivElement;
  labelElement: Nullable<HTMLSpanElement>;
};
