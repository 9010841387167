import { SVGProps } from 'react';

const NavigatorCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M1 5a4 4 0 0 1 4-4h9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.436V8.564c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267h4.873c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v2.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H7.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756c-.174-.326-.267-.65-.267-1.54z"
        fill="currentColor"
      />
      <path
        d="M1 15a4 4 0 0 0 4 4h9M19 5a4 4 0 0 0-4-4h-1 0M19 15a4 4 0 0 1-4 4h-1 0"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default NavigatorCircleIcon;
