import { FC, ReactElement } from 'react';
import classNames from 'classnames/bind';
import styles from './Table.module.scss';

const cn = classNames.bind(styles);

type TTableProps = {
  heading: (string | ReactElement)[];
  content: (string | ReactElement)[][];
};

const Table: FC<TTableProps> = ({ heading, content }) => (
  <table className={cn('table')}>
    <thead>
      <tr className={cn('table__row', 'table__header-row')}>
        {heading.map((header, index) => (
          <th key={index} className={cn('table__cell', 'table__header')}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {content.map((row, rowindex) => (
        <tr key={rowindex} className={cn('table__row')}>
          {row.map((cell, cellIndex) => (
            <td key={`${rowindex}_${cellIndex}`} className={cn('table__cell')}>
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;
