export enum ECdnObjectType {
  objectEntity = 'objectEntity',
  cageEntity = 'cageEntity',
  cageContour = 'cageContour',
  cellContour = 'cellContour',
  barcodeCenters = 'barcodeCenters',
  cagingSegmentationCenters = 'cagingSegmentationCenters',
}

export type TCdnObjectType = `${ECdnObjectType}`;
