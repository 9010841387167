import turboColorscale from '@/assets/images/turbo.png';
import infernoColorscale from '@/assets/images/inferno.png';
import cividisColorscale from '@/assets/images/cividis.png';
import ylOrRdColorscale from '@/assets/images/YlOrRd.png';
import ylGnBuColorscale from '@/assets/images/YlGnBu.png';
import rdBuColorscale from '@/assets/images/RdBu.png';
import rdYlBuColorscale from '@/assets/images/RdYlBu.png';
import purplesColorscale from '@/assets/images/Purples.png';
import ylGnColorscale from '@/assets/images/YlGn.png';
import schemeSet3Colorscale from '@/assets/images/Set3.png';
import schemeTableau10Colorscale from '@/assets/images/schemeTableau10.png';
import schemeSet2Colorscale from '@/assets/images/schemeSet2.png';
import schemePairedColorscale from '@/assets/images/schemePaired.png';
import schemeCategory10Colorscale from '@/assets/images/schemeCategory10.png';
import schemeSet1Colorscale from '@/assets/images/schemeSet1.png';
import plasmaColorscale from '@/assets/images/plasma.png';
import orangesColorscale from '@/assets/images/oranges.png';

import { EHistogramDataGroupTypes, EChartType, TColorscale, TColorscaleScheme } from '@/types/charts';
import {
  CONTINUOUS_COLORSCALES,
  CATEGORICAL_D3_COLORSCALES,
  DEFAULT_D3_COLORSCALES,
  D3_COLORSCALES_FOR_SCATTER_CHART,
} from '@/helpers/charts/colorscales';

export const colorscaleByTypes: Record<TColorscale, string> = {
  inferno: infernoColorscale,
  turbo: turboColorscale,
  cividis: cividisColorscale,
  ylOrRd: ylOrRdColorscale,
  ylGnBu: ylGnBuColorscale,
  rdBu: rdBuColorscale,
  rdYlBu: rdYlBuColorscale,
  purples: purplesColorscale,
  ylGn: ylGnColorscale,
  schemeSet3: schemeSet3Colorscale,
  schemeTableau10: schemeTableau10Colorscale,
  schemeSet2: schemeSet2Colorscale,
  schemePaired: schemePairedColorscale,
  schemeCategory10: schemeCategory10Colorscale,
  schemeSet1: schemeSet1Colorscale,
  plasma: plasmaColorscale,
  oranges: orangesColorscale,
};

export const colorscaleByChartType: Record<string, TColorscaleScheme> = {
  [EChartType.heatmap]: CONTINUOUS_COLORSCALES,
  [EChartType.histogram]: CATEGORICAL_D3_COLORSCALES,
  [EChartType.lineHistogram]: CATEGORICAL_D3_COLORSCALES,
  [EChartType.dotDensity]: DEFAULT_D3_COLORSCALES,
  [EChartType.dot]: D3_COLORSCALES_FOR_SCATTER_CHART,
  [EChartType.histogram2d]: DEFAULT_D3_COLORSCALES,
  [EChartType.histogram2dcontour]: DEFAULT_D3_COLORSCALES,
};

const histogramGroupingOptions = [
  {
    value: EHistogramDataGroupTypes.thresholdFreedmanDiaconis,
    label: 'Freedman–Diaconis rule',
  },
  {
    value: EHistogramDataGroupTypes.thresholdScott,
    label: 'Scott`s normal reference rule',
  },
  {
    value: EHistogramDataGroupTypes.thresholdSturges,
    label: 'Sturges` formula',
  },
  {
    value: EHistogramDataGroupTypes.custom,
    label: 'Custom',
  },
];

const lineHistogramGroupingOptions = [
  {
    value: EHistogramDataGroupTypes.kernelEstimation,
    label: 'Kernel Estimation',
  },
  ...histogramGroupingOptions,
];

export const groupOptionsByHistogramType: Record<string, TBasicOption[]> = {
  [EChartType.histogram]: histogramGroupingOptions,
  [EChartType.lineHistogram]: lineHistogramGroupingOptions,
};

export const linksToRuleDetails: Record<string, string> = {
  [EHistogramDataGroupTypes.thresholdFreedmanDiaconis]:
    "https://en.wikipedia.org/wiki/Histogram#Freedman%E2%80%93Diaconis'_choice",
  [EHistogramDataGroupTypes.thresholdScott]: "https://en.wikipedia.org/wiki/Histogram#Scott's_normal_reference_rule",
  [EHistogramDataGroupTypes.thresholdSturges]: "https://en.wikipedia.org/wiki/Histogram#Sturges'_formula",
  [EHistogramDataGroupTypes.custom]: 'https://en.wikipedia.org/wiki/Histogram#Number_of_bins_and_width',
  [EHistogramDataGroupTypes.kernelEstimation]: 'https://en.wikipedia.org/wiki/Kernel_density_estimation',
};

export const MIN_POSITIVE_KERNEL_BANDWIDTH_COEFFICIENT = 0.001;

export const DEFAULT_KERNEL_BINS_AMOUNT = 2;

export const CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT = 3;

export const CONFIRMATION_MODAL_TEXT = 'Applying matrix-level changes will reset individual graph settings';
