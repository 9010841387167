import { FC, memo, MutableRefObject } from 'react';

type CanvasProps = {
  canvasRef: MutableRefObject<Nullable<HTMLCanvasElement>>;
};

const Canvas: FC<CanvasProps> = ({ canvasRef, ...props }) => (
  <canvas
    ref={canvasRef}
    style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 1,
      pointerEvents: 'none',
    }}
    {...props}
  />
);

export default memo(Canvas);
