import { useState, useMemo, FC, useCallback, useEffect, useRef, memo } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useDebounce } from '@/hooks';

import { appAPI } from '@/store/services/app';
import { ESequencingDataRequestDataType } from '@/store/services/app/types';

import Select from '@/components/common/Select';
import { getNoOptionsMessage, isCompoundOption, isOption } from '@/components/common/Select/helpers';

import styles from '../../SequencingData.module.scss';

const cn = classnames.bind(styles);

type TGuideSeqSelectProps = {
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  placeholder: string;
  className?: string;
};

const GuideSeqSelect: FC<TGuideSeqSelectProps> = ({ selectedValue, setSelectedValue, placeholder, className = '' }) => {
  const selectRef = useRef<Nullable<HTMLDivElement>>(null);

  const [inputValue, setInputValue] = useState(selectedValue);
  const debouncedSequencingData = useDebounce(inputValue, 250);

  const {
    data: sequencingDataV2,
    isFetching: isSequencingDataV2Fetching,
    isError: isSequencingDataV2Error,
    error: sequencingDataV2Error,
  } = appAPI.useFetchSequencingDataListV2Query({
    searchString: debouncedSequencingData,
    dataType: ESequencingDataRequestDataType.guideseq,
  });

  const sequencingDataList = useMemo(() => sequencingDataV2?.directoryList ?? [], [sequencingDataV2]);

  const isSelectOptionsLoading = useMemo(
    () => isSequencingDataV2Fetching && !sequencingDataList?.includes(debouncedSequencingData),
    [isSequencingDataV2Fetching, sequencingDataList, debouncedSequencingData]
  );

  const sequencingDataOptionList = useMemo<TOption[]>(
    () => sequencingDataList.map((dataValue: string) => ({ label: dataValue, value: dataValue })),
    [sequencingDataList]
  );

  const noOptionsMessage = (obj: { inputValue: string }) => <>{getNoOptionsMessage(obj)}</>;

  const handleGuideSeqChange = useCallback((option: TOption) => {
    if (isOption(option) && isCompoundOption(option)) {
      return;
    }
    const newSelectedValue = isOption(option) ? String(option?.value) : '';
    setSelectedValue(newSelectedValue);
    setInputValue(newSelectedValue);
  }, []);

  useEffect(() => {
    if (isSequencingDataV2Error) {
      showErrorToast(getErrorMessage(sequencingDataV2Error));
    }
  }, [isSequencingDataV2Error, sequencingDataV2Error]);

  useEffect(() => {
    setInputValue(selectedValue);
  }, [selectedValue]);

  return (
    <Select
      placeholder={placeholder}
      isLoading={isSelectOptionsLoading}
      innerRef={selectRef}
      value={selectedValue}
      isEditable
      isMultiLine
      isClearable
      inputValue={inputValue}
      setInputValue={setInputValue}
      className={cn('sequencing-data__selector', className)}
      menuListClassName={cn('sequencing-data__selector-menu-list')}
      controlClassName={cn('sequencing-data__selector-control')}
      theme={themeOptions.light}
      onChange={handleGuideSeqChange}
      options={sequencingDataOptionList}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default memo(GuideSeqSelect);
