import { TAnnotationsEndpointBuilder } from '@/store/services/app/types';
import {
  transformAnnotationResponse,
  transformAnnotationsEmResponse,
  transformAnnotationsExResponse,
  transformAnnotationsResponse,
  transformAnnotationsSuggestsResponse,
  transformAnnotationsVendorsResponse,
} from '../dataProvider/annotation';
import { TAnnotationType } from './types';
import { TReturnTransformedTypes } from '../dataProvider/types';

export const annotationEndpoints = (build: TAnnotationsEndpointBuilder) => ({
  fetchAnnotationsSuggests: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['name'],
      },
    }),
    transformResponse: transformAnnotationsSuggestsResponse,
  }),

  fetchAnnotationsVendors: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['vendor'],
      },
    }),
    transformResponse: transformAnnotationsVendorsResponse,
  }),

  fetchAnnotationsEx: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['excitationWavelength'],
      },
    }),
    transformResponse: transformAnnotationsExResponse,
  }),

  fetchAnnotationsEm: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['detectionWavelength'],
      },
    }),
    transformResponse: transformAnnotationsEmResponse,
  }),

  fetchAnnotations: build.query<
    TReturnTransformedTypes[],
    { type: TAnnotationType | TAnnotationType[]; search: string; filterList?: Record<string, string> }
  >({
    query: ({ type, search, filterList = {} }) => {
      const filters: Record<string, any> = {};
      if (filterList.vendor) {
        filters.vendor = [filterList.vendor];
      }
      if (filterList.catalogIndex) {
        filters.catalogNumbers = [filterList.catalogIndex];
      }
      if (filterList.ex) {
        filters.excitationWavelength = [filterList.ex];
      }
      if (filterList.em) {
        filters.detectionWavelength = [filterList.em];
      }

      Object.entries(filterList).forEach(([key, value]) => {
        if (
          ['disease', 'tissue', 'gender', 'growthProperties', 'reporter', 'geneModification'].includes(key) &&
          !!value
        ) {
          filters[key] = value;
        }
      });

      return {
        url: '/annotations',
        method: 'GET',
        params: {
          ...filters,
          type,
          name: search,
          limit: 100,
        },
      };
    },
    transformResponse: transformAnnotationsResponse,
  }),

  fetchBeadById: build.query<TReturnTransformedTypes, string>({
    query: (id = '') => ({
      url: `/annotations/${id}`,
    }),
    transformResponse: transformAnnotationResponse,
  }),
});
