import { OptionProps } from 'react-select';
import classnames from 'classnames/bind';
import RadioButton from '@/components/runDesign/RadioButton';
import styles from './MediaSettingOption.module.scss';

const cn = classnames.bind(styles);

const MediaSettingOption = ({ data, innerProps, innerRef, isSelected }: OptionProps<TOption>) => (
  <div ref={innerRef} {...innerProps} className={cn('media-option')}>
    <RadioButton isChecked={isSelected}>
      <div className={cn('media-option__content')}>{data.label}</div>
    </RadioButton>
  </div>
);

export default MediaSettingOption;
