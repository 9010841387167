import { ChangeEventHandler, FC, KeyboardEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import TextInput from '@/components/common/TextInput';
import { useDebounce } from '@/hooks';
import styles from './FilterBlock.module.scss';

const cn = classNames.bind(styles);

type TFilterInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const FilterInput: FC<TFilterInputProps> = ({
  value: outerValue = '',
  placeholder = '',
  onChange,
  disabled = false,
}) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    setValue(outerValue);
  }, [outerValue]);

  useEffect(() => {
    onChange?.(debouncedValue);
  }, [debouncedValue]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setValue(ev.target.value);
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Enter') {
      onChange?.(value);
    }
  };

  return (
    <TextInput
      className={cn('filter-input')}
      inputClassName={cn('filter-input__input')}
      value={value}
      placeholder={placeholder}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      disabled={disabled}
    />
  );
};

export default FilterInput;
