import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { inviteUserToTeamSchema } from '@/validationSchemas';
import { themeOptions } from '@/types/theme';
import { TMemberRole, MemberRole } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { useFetchNextToken } from '@/hooks';

import { authAPI } from '@/store/services/auth';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';

import styles from './AdminModals.module.scss';

import { TAdminModalProps, TErrorsMapGeneric } from './types';

const cn = classnames.bind(styles);

type TInviteUserToTeamModalProps = TAdminModalProps & {
  team: TTeam;
};

const validationFields = {
  username: 'username',
} as const;

type TErrorsMap = TErrorsMapGeneric<typeof validationFields>;

const InviteUserToTeamModal: FC<TInviteUserToTeamModalProps> = ({ closeModal, isModalOpen, team }) => {
  const [inviteUserToTeam, { isLoading }] = authAPI.useAddUserToTeamMutation();

  const [username, setUsername] = useState('');
  const [selectedUser, setSelectedUser] = useState<Nullable<TUser>>(null);
  const [role, setRole] = useState<TMemberRole>(MemberRole.viewer);
  const [errors, setErrors] = useState<TErrorsMap>({});

  const { list: userList } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
  });

  const isInviteInProgress = useMemo(() => isLoading, [isLoading]);

  const userOptions = useMemo<TOption[]>(
    () =>
      userList.map((user) => ({
        label: user.displayName,
        value: user.username,
      })),
    [userList]
  );

  const roleOptions = useMemo<TOption[]>(() => {
    const result: TOption[] = [];
    result.push({
      label: 'Admin',
      value: MemberRole.admin,
    });
    result.push({
      label: 'Maintainer',
      value: MemberRole.maintainer,
    });
    result.push({
      label: 'Viewer',
      value: MemberRole.viewer,
    });
    return result;
  }, []);

  const handleSelectUser = useCallback(
    (option: TBasicOption) => {
      const userToSelect = userList.find((user) => user.username === option.value);
      if (!userToSelect) {
        setSelectedUser(null);
        setUsername('');
        return;
      }
      setSelectedUser(userToSelect);
      setUsername(userToSelect.displayName);
      setErrors((prev) => {
        delete prev.username;
        return prev;
      });
    },
    [userList]
  );

  const handleChangeRole = useCallback((newRole: TMemberRole) => {
    setRole(newRole);
  }, []);

  const handleFormSubmit = () => {
    if (!team) {
      toast.error('Invalid team');
      return;
    }

    const validate = inviteUserToTeamSchema.safeParse({ username: selectedUser?.username, teamId: team.id });
    if (!validate.success) {
      setErrors(
        validate.error.issues.reduce((acc: TErrorsMap, { message, path }) => {
          acc[path[0].toString() as keyof typeof validationFields] = message;
          return acc;
        }, {})
      );
      return;
    }
    if (!selectedUser) {
      return;
    }

    inviteUserToTeam({
      teamId: team.id,
      username: selectedUser.username,
      role,
    })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  useEffect(() => {
    setUsername('');
    setSelectedUser(null);
    setErrors({});
  }, [isModalOpen]);

  useEffect(() => {
    Object.values(errors).forEach((message) => {
      toast.error(message);
    });
  }, [errors]);

  if (!team) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick className={cn('modal')}>
      <Modal.Header onRequestClose={closeModal}>
        <h2>Invite user to team {team?.name}</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__row')}>
          <span className={cn('select__label')}>
            Select user<span className={cn('required')}>*</span>
          </span>
          <Select
            value={selectedUser?.username}
            options={userOptions}
            inputValue={username}
            className={cn('modal__select', 'select__control-container', {
              'select__control-container_error': errors.username,
            })}
            theme={themeOptions.light}
            onChange={handleSelectUser}
            isEditable
            isClearable
            setInputValue={setUsername}
          />
        </div>

        <div className={cn('modal__row')}>
          <span className={cn('modal__input-label', 'select__label')}>Select role</span>
          <Select
            className={cn('modal__select', 'select__control-container')}
            value={role}
            options={roleOptions}
            theme={themeOptions.light}
            onChange={handleChangeRole}
            id="invite-user-to-team__role-select"
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="invite-user-to-team__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="invite-user-to-team__begin"
          onClick={handleFormSubmit}
          color="yellow"
          className={cn('modal__button')}
          isLoading={isInviteInProgress}
        >
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(InviteUserToTeamModal);
