import getCurrentGatesLayout from '@/pages/Dataset/components/DatasetChart/helpers/getCurrentGatesLayout';
import { TDownloadChartHandlerPayload, TGateLayout } from '@/pages/Dataset/components/DatasetChart/types';
import { drawGateOnSvg } from '@/helpers/screenShotUtils';

export const downloadSvg = async (svg: SVGSVGElement, fileName: string) => {
  const link = document.createElement('a');
  const svgString = new XMLSerializer().serializeToString(svg);
  // create a Blob from the SVG string
  const blob = new Blob([svgString], { type: 'image/svg+xml' });

  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.svg`;
  link.click();
};

export const preparePlotlySVGSVGElement = ({
  plotlyImage,
}: {
  plotlyImage: string;
  imageWidth: number;
  imageHeight: number;
}): Nullable<SVGSVGElement> => {
  const svgString = plotlyImage.replace('data:image/svg+xml,', '');

  // decode the URI
  const decodedData = decodeURIComponent(svgString);

  // create a new DOMParser
  const parser = new DOMParser();

  // parse the decoded data as an SVG document
  const svgDoc = parser.parseFromString(decodedData, 'image/svg+xml');

  // extract the SVG element
  const svg = svgDoc.documentElement;

  if (svg instanceof SVGSVGElement) {
    return svg;
  }

  return null;
};

export const handleDownloadSvgImage = ({
  plotlyImage,
  imageWidth,
  imageHeight,
  renderPlotHeight,
  renderPlotWidth,
  fileName,
  layout,
  overflowContainer,
  labelElement,
}: TDownloadChartHandlerPayload) => {
  const svg = preparePlotlySVGSVGElement({
    plotlyImage,
    imageWidth,
    imageHeight,
  });

  if (!svg) return;

  if (!overflowContainer) {
    downloadSvg(svg, fileName);
    return;
  }

  const gatesLayout = getCurrentGatesLayout(overflowContainer);

  if (!gatesLayout) {
    downloadSvg(svg, fileName);
    return;
  }

  const offset = {
    sx: layout.margin.l,
    sy: layout.margin.t,
  };

  gatesLayout.forEach((gate: Nullable<TGateLayout>) => {
    drawGateOnSvg({
      svg,
      gate,
      imageHeight: renderPlotHeight,
      imageWidth: renderPlotWidth,
      offset,
      labelElement,
    });
  });

  downloadSvg(svg, fileName);
};
