import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import styles from './Loader.module.scss';

const cn = classnames.bind(styles);

type TLoader = {
  textData?: string | string[];
  isLoading?: boolean;
};

const Loader: FC<TLoader> = ({ textData = 'Processing', isLoading }) => {
  const textList = useMemo(() => (Array.isArray(textData) ? textData : [textData]), [textData]);
  return isLoading ? (
    <div className={cn('loader')}>
      <div className={cn('loader__spinner-wrapper')}>
        <div className={cn('lds-spinner')}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className={cn('loader__text-wrapper')}>
          {textList.map((text) => (
            <span key={text}>{text}</span>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default Loader;
