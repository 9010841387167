import type { RootState } from '../..';

export const selectDisplaySegmentedObjectsLayer = (state: RootState) => state.viewer.displaySegmentedObjectsLayer;
export const selectDisplayBarcodeCagesLayer = (state: RootState) => state.viewer.displayBarcodeCagesLayer;
export const selectShouldFillInBarcodes = (state: RootState) => state.viewer.shouldFillInBarcodes;
export const selectDisplayCagingSegmentationCentersLayer = (state: RootState) =>
  state.viewer.displayCagingSegmentationCentersLayer;
export const selectShouldFillInCagingSegmentationCenters = (state: RootState) =>
  state.viewer.shouldFillInCagingSegmentationCenters;
export const selectDisplayCageLocationsLayer = (state: RootState) => state.viewer.displayCageLocationsLayer;
export const selectDisplayDebugLayer = (state: RootState) => state.viewer.displayDebugLayer;
export const selectUseOptimizedData = (state: RootState) => state.viewer.useOptimizedData;
export const selectDisableUseOptimizedData = (state: RootState) => state.viewer.disableUseOptimizedData;

export const selectAllowDrawObjectsOnImageCrop = (state: RootState) => state.viewer.allowDrawObjectsOnImageCrop;
export const selectDisplayCurrentObjOnImageCrop = (state: RootState) => state.viewer.displayCurrentObjOnImageCrop;
export const selectDisplayAllObjectsOnImageCrop = (state: RootState) => state.viewer.displayAllObjectsOnImageCrop;

export const selectHighlightedContourList = (state: RootState) => state.viewer.highlightedContourList;
export const selectHighlightedCage = (state: RootState) => state.viewer.highlightedCage;
