import { EPreprocessingAssayType } from '@/helpers';
import { CELL_TYPES_FOR_AFFECTED_CELLS } from '@/components/experiment/Preprocessing/CellKilling/constants';

const toSnakeCase = (str: string) => str.replace(/\s/g, '_');
export const getPrimeAxisList = (
  channelName: string,
  markerList: TClass[] | undefined,
  withChannelIndependentAxes = true
) => {
  const orderedPrimaryAxisList: string[] = [];

  if (markerList) {
    // cytokine secretion
    markerList.forEach(({ name, processType }) => {
      if (processType === EPreprocessingAssayType.cytokineSecretion) {
        orderedPrimaryAxisList.push(`${name}_${channelName}_mean`);
      }
    });

    // cell killing
    orderedPrimaryAxisList.push(...CELL_TYPES_FOR_AFFECTED_CELLS.map(({ name }) => `${toSnakeCase(name)}_obj_count`));
  }

  orderedPrimaryAxisList.push(`${channelName}_mean`);

  if (withChannelIndependentAxes) {
    orderedPrimaryAxisList.push(`object_count`);
    orderedPrimaryAxisList.push(`all_cell_count`);
    orderedPrimaryAxisList.push(`all_bead_count`);
    orderedPrimaryAxisList.push(`cell_object_area_px_mean`);
    orderedPrimaryAxisList.push(`cell_eccentricity_mean`);
  }

  return orderedPrimaryAxisList;
};
