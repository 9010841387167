export enum EAxesGroupName {
  general = 'general',
  singleChart = 'singleChart',
  multiHistogram = 'multiHistogram',
  heatmap = 'heatmap',
  cytokineSecretion = 'cytokineSecretion',
  cellKillingRealCells = 'cellKillingRealCells',
  cellKillingTargetEffectorCells = 'cellKillingTargetEffectorCells',
}

export type TAxes = Partial<{ x: string; y: string; isObjectEntityEnabled: boolean }>;
export type TAxesState = Partial<Record<EAxesGroupName, TAxes | Record<string, TAxes>>>;

export type TDensityBandWidth = {
  custom: Record<'x' | 'y', number>;
  default: Record<'x' | 'y', number>;
};

export enum EActiveEntityUuidLastEdit {
  plot = 'plot',
  images = 'images',
  popover = 'popover',
}

export type THeaderInfo = {
  sampleName: string;
  datasetName: string;
  scanTime: string;
};

export type TMatrixPlotInfo = {
  plotId: string;
  index: number;
  xAxisLabel: string;
  yAxisLabel: string;
  headerInfo: THeaderInfo;
};

export type TBaseScatterPlotsSettings = {
  highlightDotsBy: string;
};

export type SpecificScatterPlotsSetting = Partial<TBaseScatterPlotsSettings>;

export type TInitialScatterPlotsState = {
  currentAxesGroupName: EAxesGroupName;
  axes: TAxesState;

  activeEntityUuid: {
    uuid: string;
    lastEdit: Nullable<EActiveEntityUuidLastEdit>;
  };
  densityBandWidth: TDensityBandWidth;
  matrixPlotsInfo: Record<string, TMatrixPlotInfo>;
  highlightDotsBy: string;
  specificDatasetOptionMap: Record<string, SpecificScatterPlotsSetting>;
};
