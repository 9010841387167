import { ChangeEvent, FC } from 'react';
import classnames from 'classnames/bind';

import ControlPanel from '@/components/Layout/ControlPanel';
import SearchInput from '@/components/common/SearchInput';

import styles from '../UserList.module.scss';

const cn = classnames.bind(styles);

type TUsersControlPanelProps = {
  isLoading: boolean;
  isSearchDisabled: boolean;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
};

const UsersControlPanel: FC<TUsersControlPanelProps> = ({
  isLoading,
  isSearchDisabled,
  searchQuery,
  setSearchQuery,
}) => {
  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  return (
    <ControlPanel isLoading={isLoading}>
      <ControlPanel.RightActions>
        <SearchInput
          onChange={handleSearchInputChange}
          onReset={handleResetSearchClick}
          value={searchQuery}
          disabled={isSearchDisabled}
          className={cn('content__search')}
        />
      </ControlPanel.RightActions>
    </ControlPanel>
  );
};

export default UsersControlPanel;
