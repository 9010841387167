import { FC } from 'react';
import classNames from 'classnames/bind';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import styles from './SearchResults.module.scss';

const cn = classNames.bind(styles);

type TReagentSearchResultsRowProps = {
  result: TReturnTransformedTypes;
};

export const IncubationReagentSearchResultsHeading = () => (
  <div className={cn('search-results__table-cell')}>Reagent name</div>
);

export const IncubationReagentSearchResultsRow: FC<TReagentSearchResultsRowProps> = ({ result }) => (
  <div className={cn('search-results__table-cell')}>
    <div className={cn('search-results__text')}>{result.name}</div>
  </div>
);

export const incubationReagentSearchResultsGridLayout = '1fr';
