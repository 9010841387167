import { useCallback, useRef, useState } from 'react';
import { select as d3Select } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';

import { getGateById } from '@/helpers/gates';
import { drawControlElement, drawGateLabel, updateLabelPosition } from './helpers/common';
import { HANDLERS_OFFSET } from './constants';
import { getEllipseDataInChartScale } from './helpers/ellipseGates';
import {
  TSelectionEllipseElement,
  TD3DragEvent,
  TSelectionRectElement,
  TSelectionGroupElement,
  EGateDragType,
  TUseGatesOnPlotPayload,
} from './types';

type TEllipseData = {
  ellipse: TSelectionEllipseElement;
  gateContainer: TSelectionGroupElement;
  rx: number;
  ry: number;
  x: number;
  y: number;
};

type TControlData = {
  x: number;
  y: number;
  position: string;
};

const MIN_RADIUS = 5;

export function useEllipseGates({
  chartContainerParameters,
  plotId,
  plotRange,
  isStatic,
  openGateCreationModal,
  displayType,
  entityLevelGateList,
  updateGate,
  handleGateDrag,
  isGateMenuHidden,
}: TUseGatesOnPlotPayload) {
  const [newEllipseData, setNewEllipseData] = useState<Nullable<TEllipseData>>(null);
  const [isGateDrawing, setIsGateDrawing] = useState<boolean>(false);
  const isChangedRef = useRef(false);

  const clearEllipseData = () => setNewEllipseData(null);

  const updateEllipseGate = (currentGateData: TEllipseData) => {
    const gate = currentGateData.ellipse;
    gate.attr('rx', currentGateData.rx).attr('ry', currentGateData.ry);
  };

  const updateControls = (currentGateData: TEllipseData) => {
    const gate = currentGateData.ellipse;
    const gateId = gate.attr('gate-id').split('gate_')[1];
    const gateGroupElement = gate.node()?.parentNode;

    if (!gateGroupElement) return;

    const controls = d3Select(gateGroupElement as Element)
      .selectAll(`[gate-control-id = 'gate-control_${gateId}']`)
      .nodes() as Element[];

    controls.forEach((control) => {
      const element = d3Select(control);
      const controlType = element.attr('control-position');

      const offsetByType: Record<string, number> = {
        'y-top': currentGateData.y + currentGateData.ry * -1,
        'y-bottom': currentGateData.y + currentGateData.ry,
        'x-left': currentGateData.x + currentGateData.rx * -1,
        'x-right': currentGateData.x + currentGateData.rx,
      };

      const controlUpdateHandlersMap: Record<string, () => void> = {
        'y-top': () => element.attr('y', offsetByType[controlType] - HANDLERS_OFFSET),
        'y-bottom': () => element.attr('y', offsetByType[controlType] - HANDLERS_OFFSET),
        'x-left': () => element.attr('x', offsetByType[controlType] - HANDLERS_OFFSET),
        'x-right': () => element.attr('x', offsetByType[controlType] - HANDLERS_OFFSET),
      };

      controlUpdateHandlersMap[controlType]();
    });

    gate.attr('rx', currentGateData.rx).attr('ry', currentGateData.ry);
  };

  const onEllipseDragStart = (element: TSelectionEllipseElement) => {
    const gateId = element.attr('gate-id').split('gate_')[1];
    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onEllipseGateDrag = (element: TSelectionEllipseElement, event: TD3DragEvent) => {
    const gateIdAttr = element.attr('gate-id');
    const gateGroupElement = element.node()?.parentNode;

    if (!gateGroupElement) return;

    const gateId = gateIdAttr.split('gate_')[1];
    const controls = d3Select(gateGroupElement as Element)
      .selectAll(`[gate-control-id = 'gate-control_${gateId}']`)
      .nodes();

    updateLabelPosition(gateId, [event.dx, event.dy], isGateMenuHidden, plotId);

    handleGateDrag(gateId, EGateDragType.drag);

    controls.forEach((control) => {
      const controlEl = d3Select(control);
      const cx = parseFloat(controlEl.attr('x'));
      const cy = parseFloat(controlEl.attr('y'));

      controlEl.attr('x', cx + event.dx).attr('y', cy + event.dy);
    });

    element.attr('cx', parseFloat(element.attr('cx')) + event.dx).attr('cy', parseFloat(element.attr('cy')) + event.dy);
  };

  const onEllipseShapeDragEnd = useCallback(
    (element: TSelectionEllipseElement) => {
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
      const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn

      if (!shapesContainerParameters || !chartContainerParameters || !plotRange) return;

      const gateId = element.attr('gate-id').split('gate_')[1];

      const ellipseData = {
        x: parseFloat(element.attr('cx')),
        y: parseFloat(element.attr('cy')),
        rx: parseFloat(element.attr('rx')),
        ry: parseFloat(element.attr('ry')),
      };

      const gate = getGateById(gateId, entityLevelGateList);
      const model = getEllipseDataInChartScale({
        shapesContainerParameters,
        plotRange,
        newEllipseData: ellipseData,
      });

      if (!model || !gate) return;

      handleGateDrag(gateId, EGateDragType.dragEnd, {
        ...gate,
        shape: {
          type: 'circle',
          model,
        },
      });

      if (!isChangedRef.current) {
        return;
      }

      updateGate(gate, {
        shape: {
          type: 'circle',
          model,
        },
      });
      isChangedRef.current = false;
    },
    [chartContainerParameters, plotRange, entityLevelGateList]
  );

  const handleEllipseDrag = d3Drag<SVGEllipseElement, unknown>()
    .on('start', function handleDragStart() {
      const element = d3Select(this);
      onEllipseDragStart(element);
    })
    .on('drag', function handleDrag(event) {
      const element = d3Select(this);
      onEllipseGateDrag(element, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const element = d3Select(this);

      onEllipseShapeDragEnd(element);
    });

  const onEllipseControlDragStart = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onEllipseControlDrag = (control: TSelectionEllipseElement | TSelectionRectElement, event: TD3DragEvent) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    const gateContainer: TSelectionGroupElement = d3Select(`[gate-group-id = 'group_${gateId}']`);

    if (!gateContainer?.node()) return;

    const gate: TSelectionEllipseElement = gateContainer.select(`[gate-id = 'gate_${gateId}']`);
    const gateX = parseFloat(gate.attr('cx'));
    const gateY = parseFloat(gate.attr('cy'));
    const gateRx = parseFloat(gate.attr('rx'));
    const gateRy = parseFloat(gate.attr('ry'));

    handleGateDrag(gateId, EGateDragType.drag);

    const controlType = control.attr('control-position');

    const updatedRadiusByTypeMap: Record<string, Record<'ry' | 'rx', number>> = {
      'y-top': {
        ry: gateRy - event.dy,
        rx: gateRx + event.dx,
      },
      'y-bottom': {
        ry: gateRy + event.dy,
        rx: gateRx + event.dx,
      },
      'x-left': {
        ry: gateRy + event.dy,
        rx: gateRx - event.dx,
      },
      'x-right': {
        ry: gateRy + event.dy,
        rx: gateRx + event.dx,
      },
    };

    const newRx = updatedRadiusByTypeMap[controlType].rx;
    const newRy = updatedRadiusByTypeMap[controlType].ry;

    const ellipseData: TEllipseData = {
      ellipse: gate,
      gateContainer,
      rx: newRx < MIN_RADIUS ? MIN_RADIUS : newRx,
      ry: newRy < MIN_RADIUS ? MIN_RADIUS : newRy,
      x: gateX,
      y: gateY,
    };

    updateEllipseGate(ellipseData);
    updateControls(ellipseData);

    return ellipseData;
  };

  const onEllipseControlDragEnd = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    const gateGroupElement = control.node()?.parentNode;

    if (!gateGroupElement) return;

    const element: TSelectionEllipseElement = d3Select(gateGroupElement as Element).select(
      `[gate-id = 'gate_${gateId}']`
    );

    onEllipseShapeDragEnd(element);
  };

  const controlDragHandler = d3Drag<SVGRectElement, unknown>()
    .on('start', function handleDragStart() {
      const control = d3Select(this);
      onEllipseControlDragStart(control);
    })
    .on('drag', function handleDrag(event) {
      const control = d3Select(this);
      onEllipseControlDrag(control, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const control = d3Select(this);
      onEllipseControlDragEnd(control);
    });

  const drawControls = (data: TControlData[], gateContainer: TSelectionGroupElement, gateId: string) => {
    const controls = data.map((item: TControlData) =>
      drawControlElement({
        parentElement: gateContainer,
        x: item.x,
        y: item.y,
        attributes: [
          { attr: 'gate-control-id', value: `gate-control_${gateId}` },
          { attr: 'control-position', value: item.position },
        ],
      })
    );

    return controls;
  };

  const drawEllipse = ({
    gateId,
    ellipseData,
    label,
    groupClassName = 'gate-group',
    isDrawing = false,
  }: {
    gateId: string;
    ellipseData: TCircleGateModel;
    label: string;
    groupClassName?: string;
    isDrawing?: boolean;
  }) => {
    const svg = d3Select(`#${plotId} .gates-container`);
    const group = svg
      .append('g')
      .attr('gate-group-id', `group_${gateId}`)
      .attr('class', groupClassName)
      .attr('gate-type', 'ellipse');
    const gateIdAttr = `gate_${gateId}`;
    const { x, y, rx, ry } = ellipseData;
    const topY = y - ry;

    const ellipse = group
      .append('ellipse')
      .attr('class', 'gate-element')
      .attr('cx', x)
      .attr('cy', y)
      .attr('rx', rx)
      .attr('ry', ry)
      .attr('gate-id', gateIdAttr);

    const controlsData = [
      {
        x,
        y: y + ry,
        position: 'y-bottom',
      },
      {
        x,
        y: y - ry,
        position: 'y-top',
      },
      {
        x: x + rx,
        y,
        position: 'x-right',
      },
      {
        x: x - rx,
        y,
        position: 'x-left',
      },
    ];

    const controls = drawControls(controlsData, group, gateId);

    if (isDrawing) {
      controls.forEach((control: TSelectionRectElement) => {
        control.style('opacity', 1);
      });
    }

    drawGateLabel({
      gateContainer: group,
      bgX: x,
      bgY: topY,
      textX: x,
      textY: topY,
      gateIdAttr,
      label,
      displayType,
      isGateMenuHidden,
    });

    if (!isStatic) {
      ellipse.call(handleEllipseDrag);

      controls.forEach((control: TSelectionRectElement) => {
        control.call(controlDragHandler);
      });
    }

    return { ellipse, gateContainer: group };
  };

  const handleEllipseGateMouseDown = useCallback(
    (eventData: number[]) => {
      if (newEllipseData) return setIsGateDrawing(false);

      const id = Date.now();
      const ellipseData = {
        x: eventData[0],
        y: eventData[1],
        rx: MIN_RADIUS,
        ry: MIN_RADIUS,
      };

      const { ellipse, gateContainer } = drawEllipse({
        gateId: `${id}`,
        ellipseData,
        label: '',
        isDrawing: true,
      });

      setNewEllipseData({ ...ellipseData, ellipse, gateContainer });
      setIsGateDrawing(true);
    },
    [newEllipseData]
  );

  const handleEllipseGateMouseMove = useCallback(
    (eventData: number[]) => {
      if (!newEllipseData || !isGateDrawing) return;

      setNewEllipseData((prevState) => {
        if (!prevState) return null;
        const newRx = Math.abs(prevState.x - eventData[0]);
        const newRy = Math.abs(prevState.y - eventData[1]);

        const ellipseData = {
          ...prevState,
          rx: newRx < MIN_RADIUS ? MIN_RADIUS : newRx,
          ry: newRy < MIN_RADIUS ? MIN_RADIUS : newRy,
        };

        updateEllipseGate(ellipseData);
        updateControls(ellipseData);
        return ellipseData;
      });
    },
    [newEllipseData, isGateDrawing]
  );

  const handleEllipseGateMouseUp = useCallback(() => {
    const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

    const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn
    if (!newEllipseData || !shapesContainerParameters || !chartContainerParameters || !plotRange)
      return clearEllipseData();

    if (newEllipseData.rx <= MIN_RADIUS || newEllipseData.ry <= MIN_RADIUS) {
      setIsGateDrawing(false);
      newEllipseData.gateContainer.remove();
      clearEllipseData();
      return;
    }

    const model = getEllipseDataInChartScale({
      shapesContainerParameters,
      plotRange,
      newEllipseData,
    });

    if (!model) return clearEllipseData();

    openGateCreationModal({
      type: 'circle',
      model,
    });
    setIsGateDrawing(false);
  }, [plotId, plotRange, chartContainerParameters, newEllipseData]);

  return {
    drawEllipse,
    handleEllipseGateMouseDown,
    handleEllipseGateMouseMove,
    handleEllipseGateMouseUp,
    clearEllipseData,
  };
}

export default useEllipseGates;
