import { memo } from 'react';

import Modal, { TModal } from '@/components/common/Modal';

import KneePlot, { TKneePlotProps } from './KneePlot';

export type TKneePlotModalProps = TKneePlotProps & Pick<TModal, 'onRequestClose' | 'isOpen'>;

const KneePlotModal = ({ isOpen, onRequestClose, laneId }: TKneePlotModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick isFullScreen>
    <Modal.Header>
      <h2>{`Knee Plot (${laneId})`}</h2>
    </Modal.Header>
    <Modal.Content>
      <KneePlot laneId={laneId} />
    </Modal.Content>
  </Modal>
);

export default memo(KneePlotModal);
