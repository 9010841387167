import { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './InvalidBrowser.module.scss';
import chrome from './chrome.png';
import edge from './edge.png';
import firefox from './firefox.png';
import opera from './opera.png';

const cn = classNames.bind(styles);

type InvalidBrowserProps = {
  handleForceContinue: () => void;
};

const InvalidBrowser: FC<InvalidBrowserProps> = ({ handleForceContinue }) => {
  const browsersData = [
    {
      title: 'Chrome',
      image: chrome,
      alt: 'Chrome browser logo',
      href: 'https://www.google.com/chrome/',
    },
    {
      title: 'Firefox',
      image: firefox,
      alt: 'Firefox browser logo',
      href: 'https://www.mozilla.org/en-US/firefox/new/',
    },
    {
      title: 'Edge',
      image: edge,
      alt: 'Edge browser logo',
      href: 'https://www.microsoft.com/en-Us/edge/download',
    },
    {
      title: 'Opera',
      image: opera,
      alt: 'Opera browser logo',
      href: 'https://www.opera.com/',
    },
  ];

  return (
    <div className={cn('invalid-browser')}>
      <h1 className={cn('invalid-browser__title')}>
        Your browser
        <br />
        is not supported or outdated!
      </h1>
      <p className={cn('invalid-browser__text')}>
        To work properly with the site,
        <br />
        please download one of the browsers below or update it:
      </p>
      <ul className={cn('invalid-browser__list')}>
        {browsersData.map(({ title, image, alt, href }) => (
          <li className={cn('invalid-browser__elem')} key={title}>
            <a href={href} className={cn('invalid-browser__link')} target="_blank" rel="noreferrer">
              <img src={image} alt={alt} className={cn('invalid-browser__image')} />
              <div className={cn('invalid-browser__label')}>{title}</div>
            </a>
          </li>
        ))}
      </ul>

      <button className={cn('invalid-browser__force-continue')} onClick={handleForceContinue}>
        Continue anyway
      </button>
      <p className={cn('invalid-browser__comment')}>The site might not support all intended features.</p>
    </div>
  );
};

export default InvalidBrowser;
