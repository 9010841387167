import { FC, useCallback } from 'react';
import classNames from 'classnames/bind';

import { Reagent } from '@/graphql/API';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import AddToTableButton from '@/components/runDesign/AddToTableButton';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';
import { TRunDesignCellType } from '@/store/slices/experimentRunDesign';
import consumableStyles from '@/components/runDesign/ConsumableComponent/Consumable.module.scss';

import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context';
import useReagentSuggestions from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useReagentSuggestions';
import useReagentSearch from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useReagentSearch';
import useReagentFilter from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/useReagentFilter';
import ReagentFilterBlock from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock';
import {
  reagentSearchResultInformationList,
  reagentSearchResultsGridLayout,
  ReagentSearchResultsHeading,
  ReagentSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/ReagentSearchResults';
import PreSelectedConsumable from '@/components/runDesign/ConsumableComponent/PreSelectedConsumable';
import { isDefined } from '@/helpers/typeGuards';
import styles from '../../EditCellType.module.scss';

const cn = classNames.bind({ ...styles, ...consumableStyles });

type TEditCellTypePreLabelingProps = {
  reagent?: Nullable<Reagent>;
  preSelectedReagent: Nullable<Reagent>;
  cellType?: Nullable<TRunDesignCellType>;
  laneIndex: number;
  laneId: string;
  sampleTitle: string;
  onChangeReagent: (runDesignCardIndex: number, reagentData: Nullable<Reagent>) => void;
};

const EditCellTypePreLabeling: FC<TEditCellTypePreLabelingProps> = ({
  reagent,
  preSelectedReagent,
  laneIndex,
  onChangeReagent,
  cellType,
  laneId,
  sampleTitle,
}) => {
  const { openModal, setConfig } = useReagentModalContext();
  const { getSuggestions } = useReagentSuggestions();
  const { getData } = useReagentSearch();

  const handleAddNewClick = () => {
    setConfig({
      title: `Lane ${laneId} / Pre-Labeling`,
      description: `Search for ${sampleTitle} reagent. Use filters to narrow down your search.`,
      search: {
        placeholder: 'Reagent name',
        getSuggestions,
        getData,
        result: {
          headingRenderer: ReagentSearchResultsHeading,
          rowRenderer: ReagentSearchResultsRow,
          cssGridLayout: reagentSearchResultsGridLayout,
          informationList: reagentSearchResultInformationList,
        },
      },
      filter: {
        hook: useReagentFilter,
        renderer: ReagentFilterBlock,
      },
      submitButtonText: 'Add reagent',
      onSelect: (selectedReagent: Nullable<Reagent>) => {
        onChangeReagent(laneIndex, selectedReagent);
      },
    });

    openModal();
  };

  const handleEditClick = () => {
    setConfig({
      title: `Lane ${laneId} / Pre-Labeling`,
      description: `Search for ${sampleTitle} reagent. Use filters to narrow down your search.`,
      current: reagent,
      search: {
        placeholder: 'Reagent name',
        getSuggestions,
        getData,
        result: {
          headingRenderer: ReagentSearchResultsHeading,
          rowRenderer: ReagentSearchResultsRow,
          cssGridLayout: reagentSearchResultsGridLayout,
          informationList: reagentSearchResultInformationList,
        },
      },
      filter: {
        hook: useReagentFilter,
        renderer: ReagentFilterBlock,
      },
      submitButtonText: 'Add reagent',
      onSelect: (selectedReagent: Nullable<Reagent>) => {
        onChangeReagent(laneIndex, selectedReagent);
      },
    });

    openModal();
  };

  const handleDeleteClick = () => {
    onChangeReagent(laneIndex, null);
  };

  const handlePreselectedClick = useCallback(() => {
    if (!isDefined(preSelectedReagent)) {
      return;
    }

    onChangeReagent(laneIndex, preSelectedReagent);
  }, [preSelectedReagent, laneIndex]);

  if (!cellType) {
    return null;
  }

  return (
    <div
      className={cn('cell-content', 'pre-labeling__btn', SELECTABLE_CLASS_NAME)}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 3, dataType: 'preLabeling' })}
    >
      {!reagent && preSelectedReagent && (
        <PreSelectedConsumable
          onClick={handlePreselectedClick}
          consumable={preSelectedReagent}
          onPlusClick={handleAddNewClick}
        />
      )}

      {!reagent && !preSelectedReagent && <AddToTableButton onClick={handleAddNewClick} text="Add reagent" />}

      {!!reagent && (
        <ConsumableComponent onClick={handleEditClick} consumable={reagent} deleteReagent={handleDeleteClick} />
      )}
    </div>
  );
};

export default EditCellTypePreLabeling;
