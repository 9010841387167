import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadStatusType, TViolinState } from './types';
import { cdnAPI } from '../../services/cdnData';

const initialState: TViolinState = {
  violinMap: {},
  laneIdList: [],
};

const violinSlice = createSlice({
  name: 'violin',
  initialState,
  reducers: {
    deleteViolin: (state, action: PayloadAction<string>) => {
      delete state.violinMap[action.payload];
    },
    clearViolin: (state) => {
      state.violinMap = {};
    },
    setLaneIds: (state, action: PayloadAction<string[]>) => {
      state.laneIdList = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(cdnAPI.endpoints.fetchGeneCounts.matchPending, (state, action) => {
        const { id, experimentCloudPath, geneName, laneId } = action.meta.arg.originalArgs;
        const violin = state.violinMap[id];

        if (violin) {
          violin.loadStatus = LoadStatusType.loading;
          violin.geneName = geneName;
          violin.laneId = laneId;
          violin.experimentCloudPath = experimentCloudPath;
        } else {
          state.violinMap[id] = {
            experimentCloudPath,
            geneName,
            id,
            laneId,
            data: [],
            loadStatus: LoadStatusType.loading,
          };
        }
      })
      .addMatcher(cdnAPI.endpoints.fetchGeneCounts.matchFulfilled, (state, { payload }) => {
        const { gensData, id, loadStatus } = payload;

        const violin = state.violinMap[id];
        if (violin) {
          violin.data = gensData;
          violin.loadStatus = loadStatus;
        }
      });
  },
});

export default violinSlice;
