import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';
import ConsumableHeader from '@/components/runDesign/ConsumableComponent/ConsumableHeader';

import styles from './MinimizedCellType.module.scss';

const cn = classnames.bind(styles);

type TMinimizedCellType = {
  runDesignCardIndex: number;
  isExpandMode?: boolean;
};

const MinimizedCellType: FC<TMinimizedCellType> = ({ runDesignCardIndex, isExpandMode }) => {
  const cellTypes = useSelector(experimentRunDesignSelectors.selectCellTypeListByIndex(runDesignCardIndex));
  const isPreLabelingEX = useSelector(experimentRunDesignSelectors.selectIsPreLabelingEX(runDesignCardIndex));
  const cellTypesCount = useSelector(experimentRunDesignSelectors.selectCountOfCellTypes);
  const showFullLabels = useMemo(() => isExpandMode || cellTypesCount === 1, [isExpandMode, cellTypesCount]);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('minimized-cell-type__table')}
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column className={cn('minimized-cell-type__header-title')}>Cell name</RunDesignTable.Column>
          {isPreLabelingEX && (
            <RunDesignTable.Column>
              <ConsumableHeader
                className={cn({
                  'minimized-cell-type__header-title_no-uppercase': !showFullLabels,
                })}
                withWaves={isPreLabelingEX}
              >
                {showFullLabels ? 'Pre-Labeling' : 'pLBL'}
              </ConsumableHeader>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {cellTypes?.map((cellType, index) => {
        const key = index;
        if (!cellType) {
          return (
            <RunDesignTable.Row key={key}>
              <RunDesignTable.Column className={cn('cell')} />
            </RunDesignTable.Row>
          );
        }

        return (
          <RunDesignTable.Row key={key}>
            <RunDesignTable.Column className={cn('cell')}>
              <div className={cn('cell__content', 'cell__content_name')}>
                <div className={cn('cell__text')}>{cellType?.cellIndex?.name}</div>
              </div>
            </RunDesignTable.Column>

            <RunDesignTable.Column className={cn('cell')}>
              {cellType?.preLabeling?.consumable && isReagentConsumable(cellType?.preLabeling?.consumable) && (
                <ConsumableComponent className={cn('cell__content')} consumable={cellType?.preLabeling?.consumable} />
              )}
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default MinimizedCellType;
