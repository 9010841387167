import { FC, useState, ChangeEvent, useEffect, useMemo, memo } from 'react';
import classnames from 'classnames/bind';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import useItemList from '@/hooks/useItemList';

import { appAPI } from '@/store/services/app';

import Modal from '@/components/common/Modal';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import styles from './AdminModals.module.scss';
import type { TAdminModalProps } from './types';

const cn = classnames.bind(styles);

const CreateProjectModal: FC<TAdminModalProps> = ({ closeModal, isModalOpen }) => {
  const [createProject, { isLoading: isCreateProjectInProgress }] = appAPI.useCreateProjectMutation();

  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [collaboratorValueList, setCollaboratorValueList] = useState<string[]>([]);
  const {
    list: collaboratorList,
    addEmptyItem: addCollaborator,
    changeItem: changeCollaborator,
    deleteItem: deleteCollaborator,
    clearAll: clearCollaboratorList,
  } = useItemList<string>(collaboratorValueList, setCollaboratorValueList, '', (value) => !!value.trim());

  const isSaveDisabled = useMemo(() => projectName.trim() === '', [projectName]);

  const handleChangeProjectName = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleChangeProjectDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectDescription(event.target.value);
  };

  const handleCollaboratorChange = (event: ChangeEvent<HTMLInputElement>, collaboratorUuid: string) => {
    changeCollaborator(collaboratorUuid, event.target.value);
  };

  const handleFormSubmit = () => {
    if (isSaveDisabled) {
      return;
    }
    createProject({
      name: projectName.trim(),
      description: projectDescription,
      collaborators: collaboratorValueList,
    })
      .unwrap()
      .then(closeModal)
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  useEffect(() => {
    setProjectName('');
    setProjectDescription('');
    clearCollaboratorList();
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      onFormSubmit={handleFormSubmit}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>New project</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={projectName}
            label="Project name"
            labelClassName={cn('modal__input-label')}
            onChange={handleChangeProjectName}
            isBgLight
            id="create-project__name-input"
          />
        </div>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={projectDescription}
            label="Project description"
            labelClassName={cn('modal__input-label')}
            onChange={handleChangeProjectDescription}
            isBgLight
            id="create-project__description-input"
          />
        </div>
        <div className={cn('modal__row')}>
          {collaboratorList.map((collaborator, index) => (
            <div key={collaborator.uuid} className={cn('modal__collaborator-row')}>
              <TextInput
                className={cn('modal__input')}
                value={collaborator.value}
                label={index === 0 ? 'Collaborators' : ''}
                labelClassName={cn('modal__input-label')}
                onChange={(event) => handleCollaboratorChange(event, collaborator.uuid)}
                isBgLight
                id={`create-project__collaborator-input_${index}`}
              />
              <Button onClick={addCollaborator} className={cn('modal__collaborator-button')}>
                <icons.PlusIcon />
              </Button>
              <Button
                onClick={() => {
                  deleteCollaborator(collaborator.uuid);
                }}
                className={cn('modal__collaborator-button')}
              >
                <icons.DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="create-project__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="create-project__begin"
          color="yellow"
          className={cn('modal__button')}
          disabled={isSaveDisabled}
          isLoading={isCreateProjectInProgress}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CreateProjectModal);
