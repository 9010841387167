import { transformObjectToDeepLevel, mergeDeep } from './common';

const methodFlagsMap = {
  update: false,
  react: false,
  relayout: false,
  restyle: false,
  purge: false,
} as const;

type TPlotlyMethod = keyof typeof methodFlagsMap;

type TPlotInfo = {
  id: string;
  timer?: NodeJS.Timeout;
  debounceTime: number;
  data?: TPlotData;
  layout?: Nullable<TPlotRelayoutEvent>;
  config?: any;
  restyleUpdate?: TPlotData;
  relayoutUpdate?: Nullable<TPlotRelayoutEvent>;
  traces?: Nullable<number[]>;
  callbacks: (((plot: IPlotlyHTMLDivElement) => void) | undefined)[];
  flags: Record<TPlotlyMethod, boolean>;
  graphDiv?: IPlotlyHTMLDivElement;
};

export class PlotlyProxy {
  private plotInfo: TPlotInfo;

  constructor(id: string, customDebounceTime = 300) {
    this.plotInfo = {
      id,
      debounceTime: customDebounceTime,
      flags: { ...methodFlagsMap },
      callbacks: [],
    };
  }

  public get id(): string {
    return this.plotInfo.id;
  }

  public get debounceTime(): number {
    return this.plotInfo.debounceTime;
  }

  public set debounceTime(newDebounceTime: number) {
    this.plotInfo.debounceTime = newDebounceTime;
  }

  public react(
    data: TPlotData,
    layout: Nullable<TPlotRelayoutEvent>,
    config?: any,
    callback?: (plot: IPlotlyHTMLDivElement) => void
  ) {
    this.plotInfo.data = data;
    this.plotInfo.layout = layout;
    this.plotInfo.config = config;
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.react = true;
    this.debounceDraw();
  }

  public forceReact(
    data: TPlotData,
    layout: Nullable<TPlotRelayoutEvent>,
    config?: any,
    callback?: (plot: IPlotlyHTMLDivElement) => void
  ) {
    this.plotInfo.data = data;
    this.plotInfo.layout = layout;
    this.plotInfo.config = config;
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.react = true;
    this.draw();
  }

  public update(
    restyleUpdate?: TPlotData,
    layout?: Nullable<TPlotRelayoutEvent>,
    traces?: Nullable<number[]>,
    callback?: (plot: IPlotlyHTMLDivElement) => void
  ) {
    this.plotInfo.restyleUpdate = restyleUpdate;
    this.plotInfo.layout = { ...mergeDeep(this.plotInfo.layout, transformObjectToDeepLevel(layout ?? {})) };
    this.plotInfo.traces = traces ?? null;
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.update = true;
    this.debounceDraw();
  }

  public forceUpdate(
    restyleUpdate?: TPlotData,
    layout?: Nullable<TPlotRelayoutEvent>,
    traces?: Nullable<number[]>,
    callback?: (plot: IPlotlyHTMLDivElement) => void
  ) {
    this.plotInfo.restyleUpdate = restyleUpdate;
    this.plotInfo.layout = { ...mergeDeep(this.plotInfo.layout, transformObjectToDeepLevel(layout ?? {})) };
    this.plotInfo.traces = traces ?? null;
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.update = true;
    this.draw();
  }

  public restyle(restyleUpdate: TPlotData, traces?: number[], callback?: (plot: IPlotlyHTMLDivElement) => void) {
    this.plotInfo.restyleUpdate = restyleUpdate;
    this.plotInfo.traces = traces ?? null;
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.restyle = true;
    this.debounceDraw();
  }

  public forceRestyle(restyleUpdate: TPlotData, traces?: number[], callback?: (plot: IPlotlyHTMLDivElement) => void) {
    this.plotInfo.restyleUpdate = restyleUpdate;
    this.plotInfo.traces = traces ?? null;
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.restyle = true;
    this.draw();
  }

  public relayout(layout: Nullable<TPlotRelayoutEvent>, callback?: (plot: IPlotlyHTMLDivElement) => void) {
    this.plotInfo.layout = { ...mergeDeep(this.plotInfo.layout, transformObjectToDeepLevel(layout ?? {})) };
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.relayout = true;
    this.debounceDraw();
  }

  public forceRelayout(layout: Nullable<TPlotRelayoutEvent>, callback?: (plot: IPlotlyHTMLDivElement) => void) {
    this.plotInfo.layout = { ...mergeDeep(this.plotInfo.layout, transformObjectToDeepLevel(layout ?? {})) };
    this.plotInfo.callbacks.push(callback);
    this.plotInfo.flags.relayout = true;
    this.draw();
  }

  public purge(graphDiv?: IPlotlyHTMLDivElement) {
    this.plotInfo.flags.purge = true;
    if (graphDiv) {
      this.plotInfo.graphDiv = graphDiv;
    }

    this.draw();
  }

  private debounceDraw() {
    clearTimeout(this.plotInfo.timer);
    this.plotInfo.timer = setTimeout(() => {
      this.draw();
    }, this.plotInfo.debounceTime);
  }

  private runCallbacks(plot: IPlotlyHTMLDivElement) {
    this.plotInfo.callbacks.forEach((callback) => {
      if (!callback) {
        return;
      }

      callback(plot);
    });
    if (this.plotInfo) {
      this.plotInfo.data = null;
      this.plotInfo.config = null;
      this.plotInfo.callbacks = [];
      this.plotInfo.restyleUpdate = {};
      this.plotInfo.traces = null;
      this.plotInfo.flags = { ...methodFlagsMap };
    }
  }

  private draw() {
    try {
      const { Plotly } = window;

      const plotId = this.plotInfo.id;

      switch (true) {
        case this.plotInfo.flags.react:
          Plotly.react(plotId, this.plotInfo.data, this.plotInfo.layout, this.plotInfo.config).then(
            this.runCallbacks.bind(this)
          );
          break;
        case this.plotInfo.flags.update || (this.plotInfo.flags.relayout && this.plotInfo.flags.restyle):
          Plotly.update(
            plotId,
            this.plotInfo.restyleUpdate,
            this.plotInfo.layout,
            this.plotInfo.traces ?? undefined
          ).then(this.runCallbacks.bind(this));
          break;
        case this.plotInfo.flags.relayout:
          Plotly.relayout(plotId, this.plotInfo.layout).then(this.runCallbacks.bind(this));
          break;
        case this.plotInfo.flags.restyle:
          Plotly.restyle(plotId, this.plotInfo.restyleUpdate, this.plotInfo.traces ?? undefined).then(
            this.runCallbacks.bind(this)
          );
          break;

        case this.plotInfo.flags.purge:
          Plotly.purge(this.plotInfo.graphDiv ?? plotId);
          break;

        default:
          break;
      }

      this.plotInfo.flags = { ...methodFlagsMap };
    } catch {
      /* empty */
    }
  }
}
