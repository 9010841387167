import { FC } from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';
import NoDataFound from '@/components/common/NoDataFound';

import ReagentModalContextProvider from '@/pages/experiment-run-design/ReagentsForAssays/context';
import ReagentsForAssaysBlock from '../ReagentsForAssays/components/ReagentsForAssaysBlock';

const IncubationReagents: FC = () => {
  const incubationComponentList = useSelector(experimentRunDesignSelectors.selectIncubationComponentList);
  return (
    <ReagentModalContextProvider>
      <RunDesignBlockWithLanes title="Select treatment for incubation">
        {incubationComponentList.length === 0 ? (
          <NoDataFound
            size="big"
            textData="There are no components with the type Incubation"
            className="center-block"
          />
        ) : (
          <ReagentsForAssaysBlock componentList={incubationComponentList} withWaves={false}  />
        )}
      </RunDesignBlockWithLanes>
    </ReagentModalContextProvider>
  );
};

export default IncubationReagents;
