import { SVGProps } from 'react';

const TouchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.2638 12.94L15.0238 11.32C16.3138 10.32 17.1438 8.76 17.1438 7C17.1438 3.97 14.6738 1.5 11.6438 1.5C8.61379 1.5 6.14379 3.97 6.14379 7C6.14379 9.13 7.36379 10.98 9.14379 11.89V15.15C6.99379 14.69 7.12379 14.71 6.88379 14.71C6.35379 14.71 5.85379 14.92 5.47379 15.3L4.07379 16.72L9.16379 21.81C9.59379 22.25 10.1938 22.5 10.8138 22.5H17.1138C18.0938 22.5 18.9238 21.8 19.0838 20.83L19.8838 16.12C20.1038 14.82 19.4538 13.54 18.2638 12.94ZM17.9138 15.79L17.1138 20.5H10.8138C10.7238 20.5 10.6438 20.46 10.5738 20.4L6.89379 16.72L11.1438 17.61V7C11.1438 6.72 11.3638 6.5 11.6438 6.5C11.9238 6.5 12.1438 6.72 12.1438 7V13H13.9038L17.3638 14.73C17.7638 14.93 17.9838 15.36 17.9138 15.79ZM8.14379 7C8.14379 5.07 9.71379 3.5 11.6438 3.5C13.5738 3.5 15.1438 5.07 15.1438 7C15.1438 7.95 14.7638 8.81 14.1438 9.44V7C14.1438 5.62 13.0238 4.5 11.6438 4.5C10.2638 4.5 9.14379 5.62 9.14379 7V9.44C8.52379 8.81 8.14379 7.95 8.14379 7Z"
      fill="currentColor"
    />
  </svg>
);

export default TouchIcon;
