import { SVGProps } from 'react';

const ShowAxisIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="13" height="12.5" viewBox="0 0 13 12.5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="currentColor" x="4" width="9" height="9" rx="4.5" />
      <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" d="M1 12h11M1 12V1" />
    </g>
  </svg>
);

export default ShowAxisIcon;
