import { SVGProps } from 'react';

const AssayCiteSeq = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" version="1.1" viewBox="0 0 130 130" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10">
      <path d="M58.2 92.6c18 13.5 25.9-12.2 42.8.6" />
      <path d="m65.7 54 19.7-11.2M47.7 54 28 42.8M83 37 62 49.3c-1.2.7-2 2-2 3.5V84M31 37l21 12.3c1.2.7 2 2 2 3.5V84" />
    </g>
  </svg>
);

export default AssayCiteSeq;
