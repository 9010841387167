import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';

import { changeGateColor } from '@/hooks/gates/helpers/common';
import { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';
import ColorButton from '@/components/common/ColorButton';
import { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import { isPolarAreaTypeGate } from '@/helpers/typeGuards';
import { findParentGate } from '@/helpers/gates';
import ColorScheme from '@/components/common/UpdateGateChangeColorModal/ColorScheme';
import styles from './UpdateGateChangeColorModal.module.scss';

const cn = classnames.bind(styles);

type TUpdateGateNameModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  gate: Nullable<TGate>;
  entityLevelGateList: TGeneralChartProps['entityLevelGateList'];
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
};

const DEFAULT_COLOR = '#000000';

const UpdateGateChangeColorModal: FC<TUpdateGateNameModalProps> = ({
  isModalOpen,
  closeModal,
  updateGate,
  gate,
  entityLevelGateList,
}) => {
  const currentGateProperties = useMemo(() => gate?.properties ?? {}, [gate]);
  const [currentColor, setCurrentColor] = useState<string>(currentGateProperties?.color ?? DEFAULT_COLOR);

  const colorPickerRef = useRef<Nullable<HTMLInputElement>>(null);

  const handleColorPicker = () => {
    colorPickerRef.current?.click();
  };

  const handleSaveClick = () => {
    closeModal();
  };

  const handleSubmit = () => {
    if (!gate || !entityLevelGateList) {
      return;
    }

    const isPolarSector = isPolarAreaTypeGate(gate.shape);
    const polarSectorParent = isPolarSector ? findParentGate(entityLevelGateList, gate.parentId) : null;
    const gateToColor = isPolarSector && polarSectorParent ? polarSectorParent : gate;

    const properties = {
      ...gateToColor.properties,
      color: currentColor,
    };

    updateGate(gateToColor, { properties });

    changeGateColor(gateToColor.id, currentColor);
  };

  const handleColorChange = (e: string | ChangeEvent<HTMLInputElement>) => {
    if (typeof e === 'string') {
      setCurrentColor(e);
    } else {
      setCurrentColor(e.target.value);
    }
  };

  useEffect(() => {
    if (currentGateProperties?.color) setCurrentColor(currentGateProperties.color);
  }, [currentGateProperties]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('update-gate-change-color-modal')}
      onFormSubmit={handleSaveClick}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>Change gate color</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('update-gate-change-color-modal__text-wrapper')}>
          Please, choose new color for gate{' '}
          <b className={cn('update-gate-change-color-modal__gate-name')}>{gate?.name}</b>
        </div>
        <div>
          <div className={cn('update-gate-change-color-modal__color-picker')}>
            <ColorScheme color={currentColor} onClick={handleColorChange} />
          </div>

          <div className={cn('update-gate-change-color-modal__color-block')}>
            <div>
              Choose different color <ColorButton onClick={handleColorPicker} color={currentColor} />
            </div>
            <input
              ref={colorPickerRef}
              type="color"
              value={currentColor}
              style={{ opacity: 0, width: '100px', position: 'absolute' }}
              onChange={(e) => handleColorChange(e)}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('update-gate-change-color-modal__footer')}>
        <Button
          id="modal-btn__cancel"
          onClick={closeModal}
          color="white"
          className={cn('update-gate-change-color-modal__button')}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          id="modal__confirm"
          color="yellow"
          disabled={!gate}
          className={cn('update-gate-change-color-modal__button')}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateGateChangeColorModal;
