import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { PagePanelContext } from '@/components/common/PagePanel/context';
import styles from './CollapsableHeader.module.scss';

const cn = classNames.bind(styles);

type TCollapsableHeaderProps = {
  className?: string;
  collapsedClassName?: string;
  children?: ReactNode;
};

const CollapsableHeader: FC<TCollapsableHeaderProps> = ({ className, children, collapsedClassName }) => {
  const anchorRef = useRef<Nullable<HTMLDivElement>>(null);
  const [isIntersecting, setIsIntersecting] = useState(true);
  const scrollRef = useContext(PagePanelContext);

  useEffect(() => {
    if (!scrollRef?.current || !anchorRef.current) {
      return;
    }
    const options = {
      root: scrollRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setIsIntersecting(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(callback, options);

    observer.observe(anchorRef.current);
  }, [scrollRef?.current, anchorRef.current]);

  return (
    <>
      <div className={cn('collapsable-header__trigger')} ref={anchorRef} />

      <div
        className={cn(
          'collapsable-header',
          {
            _collapsed: !isIntersecting,
          },
          collapsedClassName && { [collapsedClassName]: !isIntersecting },
          className
        )}
      >
        <div className={cn('collapsable-header__content')}>{children}</div>
      </div>
    </>
  );
};

export default CollapsableHeader;
