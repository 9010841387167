import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import classnames from 'classnames/bind';

import { experimentSelectors } from '@/store/slices/experiment';
import { appAPI } from '@/store/services/app';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from '@/pages/Experiment/Experiment.module.scss';
import ProgressToast from '@/helpers/progressToast';
import { TGateData } from '@/webWorkers/getCagesStatisticsWorker';

const cn = classnames.bind(styles);

const progressToast = new ProgressToast(
  (
    <span>
      Preparing cage statistics is in progress.
      <br />
      We will notify you when download starts.
    </span>
  ),
  'Cage statistics download started.',
  'Preparing cage statistics has failed.'
);

const DownloadStatistics: FC = () => {
  const experimentId = useSelector(experimentSelectors.selectCurrentExperimentId);
  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const laneList = useSelector(experimentSelectors.selectExperimentLaneList);

  const csvFileName = useMemo(
    () => (experimentName ? `${experimentName} - Statistics.csv` : 'Statistics.csv'),
    [experimentName]
  );

  const btnRef = useRef<Nullable<HTMLSpanElement>>(null);

  const [csvData, setCsvData] = useState<Nullable<TGateData[]>>(null);
  const [isCsvDataInProgress, setIsCsvDataInProgress] = useState(false);

  const { data: fullGateList, isLoading: isGateListLoading } = appAPI.useFetchExperimentGatesQuery(experimentId);

  const handleButtonClick = () => {
    progressToast.showLoadingMessage();
    setIsCsvDataInProgress(true);
    const getCageStatisticsWorker = new Worker(new URL('@/webWorkers/getCagesStatisticsWorker', import.meta.url));
    getCageStatisticsWorker.onmessage = (event) => {
      if (event.data.csvData) {
        progressToast.showSuccessMessage();
        setCsvData(event.data.csvData);
      }
      if (event.data.error) {
        progressToast.showErrorMessage(event.data.error);
      }
      setIsCsvDataInProgress(false);
      getCageStatisticsWorker.terminate();
    };
    getCageStatisticsWorker.onerror = (error) => {
      progressToast.showErrorMessage(error);
      setIsCsvDataInProgress(false);
      getCageStatisticsWorker.terminate();
    };
    getCageStatisticsWorker.postMessage({ laneList, gateList: fullGateList });
  };

  useEffect(() => {
    if (!btnRef.current || !csvData) {
      return;
    }
    btnRef.current.click();
    setTimeout(() => {
      setCsvData(null);
    });
  }, [btnRef.current, csvData]);

  return (
    <>
      {!isGateListLoading && (
        <Button
          tooltip="Export cage statistics"
          color="white"
          className={cn('controls__button')}
          onClick={handleButtonClick}
          isLoading={isCsvDataInProgress}
        >
          <icons.ExportStatisticsIcon />
        </Button>
      )}
      {csvData && (
        <CSVLink data={csvData} filename={csvFileName} target="_self">
          <span ref={btnRef} />
        </CSVLink>
      )}
    </>
  );
};

export default memo(DownloadStatistics);
