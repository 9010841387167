import { FC, ReactNode, useState } from 'react';
import classnames from 'classnames/bind';
import { addTooltip } from '@/helpers';

import styles from './PopoverButton.module.scss';
import Popover, { TPopoverOption } from '../Popover';

const cn = classnames.bind(styles);

type TPopoverButton = {
  options: TPopoverOption[];
  tooltip: string;
  icon?: ReactNode;
};

const PopoverButton: FC<TPopoverButton> = ({ options, tooltip, icon }) => {
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const handlePopoverClick = () => {
    setIsPopoverOpened((prevState) => !prevState);
  };

  return (
    <Popover
      onClick={handlePopoverClick}
      isOpen={isPopoverOpened}
      options={options}
      setIsOpen={setIsPopoverOpened}
      styles={{
        top: '0',
        zIndex: '20',
      }}
      align="start"
    >
      <button
        className={cn('popover-button', { 'popover-button_active': isPopoverOpened })}
        {...addTooltip(tooltip)}
        aria-label={tooltip}
      >
        {icon}
      </button>
    </Popover>
  );
};

export default PopoverButton;
