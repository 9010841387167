import { assaySchema } from '@/validationSchemas/assays';

export const transformAssayListResponse = (assayList: TAssay[]) =>
  assayList.filter((assay) => {
    const assayCheckResult = assaySchema.safeParse(assay);
    if (!assayCheckResult.success) {
      console.error(assayCheckResult.error);
      return false;
    }
    return true;
  });
