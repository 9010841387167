import { SVGProps } from 'react';

const FullScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.20698 9.10168V9.10168C1.20698 6.25479 1.20698 4.83134 1.75195 3.76179C2.23132 2.82098 2.99622 2.05607 3.93703 1.57671C5.00659 1.03174 6.40672 1.03174 9.20698 1.03174H13.1436C15.9439 1.03174 17.344 1.03174 18.4136 1.57671C19.3544 2.05607 20.1193 2.82098 20.5987 3.76179C21.1436 4.83134 21.1436 6.23148 21.1436 9.03174V13.0317C21.1436 15.832 21.1436 17.2321 20.5987 18.3017C20.1193 19.2425 19.3544 20.0074 18.4136 20.4868C17.344 21.0317 15.9431 21.0317 13.1411 21.0317V21.0317"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <g>
      <path d="M8.19922 14L1.17742 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M1.23828 14H8.1968" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M8.19626 14V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </g>
  </svg>
);

export default FullScreenIcon;
