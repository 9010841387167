import { v4 as uuidv4 } from 'uuid';

import { CellFunction, CellType } from '@/graphql/API';
import icons from '@/components/common/icons';
import { TCagingSettingsData } from './types';

export const CAGE_SETTINGS_MAIN_SECTIONS = [
  {
    key: 'magnigication',
    sections: [
      {
        title: 'Magnification',
        key: 'magnification',
      },
    ],
  },
  {
    key: 'cellsToTarget',
    sections: [
      {
        title: 'CCE type',
        key: 'cceType',
      },
      {
        title: 'Cells to target',
        key: 'cellsToCageCellName',
      },
      {
        title: '',
        key: 'cellsToCageExEm',
        waves: true,
      },
      {
        title: 'Cells To Subtract',
        key: 'cellsToSubtractCellName',
      },
      {
        title: 'Subtraction',
        key: 'cellsToSubtractExEm',
        waves: true,
      },
    ],
  },
  {
    key: 'advanced',
    sections: [
      {
        title: 'Selected advanced settings',
        key: 'advanced',
      },
    ],
  },
];

export const RESTRICTED_ACCESS_SETTINGS = ['cageWallThickness', 'maxCageAreaFraction'];

export const DOMAIN_TO_VALIDATE_ACCESS = '@cellanome.com';

export const ADVANCED_SETTINGS: TCagingSettingsData[] = [
  {
    title: 'CCE Radius',
    key: 'cageRadius',
    withWiki: true,
    icon: <icons.CageRadiusIcon />,
    valueType: 'um',
  },
  {
    title: 'CCE-to-CCE distance',
    key: 'cageToCageDistance',
    withWiki: true,
    icon: <icons.CageToCageDistanceIcon />,
    valueType: 'um',
  },
  {
    title: 'Cell-to-CCE wall min distance',
    key: 'cellToCageWallMinDistance',
    withWiki: true,
    icon: <icons.CellToCageWallMinDistanceIcon />,
    customdata: '(inner keep away zone)',
    valueType: 'um',
  },
  {
    title: 'Min Cell Size',
    key: 'minCellSize',
    withWiki: true,
    icon: <icons.MinCellSizeIcon />,
    valueType: 'um',
  },
  {
    title: 'Max Cell Size',
    key: 'maxCellSize',
    withWiki: true,
    icon: <icons.MaxCellSizeIcon />,
    valueType: 'um',
  },
  {
    title: 'Max number of Control CCEs',
    key: 'maxNumberOfControlCages',
    icon: <icons.MaxNumberOfControlCagesIcon />,
  },
  {
    title: 'CCE wall to other objects min distance',
    key: 'cageWallToOtherObjsMinDistance',
    withWiki: true,
    icon: <icons.CageWallToOtherObjectsMinDistanceIcon />,
    valueType: 'um',
  },
  {
    title: 'CCE wall thickness',
    key: 'cageWallThickness',
    withWiki: true,
    icon: <icons.CageWallThicknessIcon />,
    valueType: 'um',
  },
  {
    title: 'Max CCE Area Fraction',
    key: 'maxCageAreaFraction',
  },
  {
    title: 'DMD exposure',
    key: 'dmdExposure',
    customdata: '(caging projection duration)',
  },
  {
    title: 'DMD Intensity',
    key: 'dmdIntensity',
    customdata: '(varies from instrument to instrument)',
    valueType: 'sec',
  },
  {
    title: 'Auto-focus current',
    key: 'autoFocusCurrent',
    valueType: '%',
  },
  {
    title: 'Auto-focus exp. time',
    key: 'autoFocusExposureTime',
    valueType: 'ms',
  },
  {
    title: 'Z offset',
    key: 'zOffset',
  },
];

export const DEFAULT_MAGNIFICATION = 4;

const uuid = uuidv4();

export const CAGING_SETTINGS_WAVES_DEFAULT_REAGENT = {
  label: 'Bright Field',
  value: uuid,
  customData: {
    type: 'Default',
    reagent: {
      __typename: 'Reagent',
      id: uuid,
      name: 'Bright Field',
      type: 'Reagent',
      annotations: null,
      detectionWavelength: 0,
      excitationWavelength: 0,
      marker: '',
      phenotype: null,
    },
  },
};

export const DEFAULT_CELL_TYPE: CellType = {
  __typename: 'CellType',
  name: '',
  function: CellFunction.EFFECTOR,
  otherFunction: null,
  preTreatment: null,
  preLabeling: null,
  cellIndex: null,
};

export const DEFAULT_GLOBAL_SETTINGS = {
  cageWallThickness: 7,
  cageWallToOtherObjsMinDistance: 15,
  maxNumberOfControlCages: 1,
  cageRadius: 45,
  cageToCageDistance: 15,
  cellToCageWallMinDistance: 15,
  minCellSize: 5,
  maxCellSize: 1,
  maxCageAreaFraction: 0.37,
  dmdExposure: 5,
  dmdIntensity: 91,
  autoFocusCurrent: 0,
  autoFocusExposureTime: 10,
  zOffset: 0,
};
