import { SVGProps } from 'react';

const ArrowBackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="27" height="12" viewBox="0 0 27 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M1 5.912h25M1 5.912 5.987 1M1 6l4.987 4.912" />
    </g>
  </svg>
);

export default ArrowBackIcon;
