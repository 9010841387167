import { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { appAPI } from '@/store/services/app';
import { useUserRole } from '@/hooks';
import { useOpenModal } from '@/hooks/useOpenModal';
import { themeOptions } from '@/types/theme';

import PageHeader from '@/components/Layout/Page/PageHeader';
import ControlPanel from '@/components/Layout/ControlPanel';
import Select from '@/components/common/Select';
import SearchInput from '@/components/common/SearchInput';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import CreateProjectModal from '@/components/admin/modals/CreateProjectModal';

import { collaboratorFilterProjects, ownerFilterProjects } from '@/helpers/projects/filterProjects';
import { timeFilterOptionList } from '@/helpers/tempData/timeFilter';
import { searchProjects } from '@/helpers/projects/searchProjects';
import NoDataFound from '@/components/common/NoDataFound';
import { headerActions } from '@/store/slices/header';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { ProjectListContextProvider } from '@/hooks/useProjectListContext';
import ProjectListItem from './ProjectListItem';
import ProjectCard from './ProjectCard';

import styles from './ProjectList.module.scss';

const cn = classnames.bind(styles);

type TRequestParamList = {
  collaborators?: string[];
  owner?: string;
  time?: string;
};

const ProjectList: FC = () => {
  const appDispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const { isProjectCreateAllowed } = useUserRole();

  const [requestParamList, setRequestParamList] = useState<TRequestParamList>({});
  const [cardsType, setCardsType] = useState('card');
  const [searchQuery, setSearchQuery] = useState('');
  const [collaboratorFilterOptionList, setCollaboratorFilterOptionList] = useState<TOption[]>([
    { value: '', label: 'All collaborators' },
  ]);
  const [ownerFilterOptionList, setOwnerFilterOptionList] = useState<TOption[]>([{ value: '', label: 'Owner' }]);

  const {
    data: projectList = [],
    isLoading: isProjectListLoading,
    isError: isProjectListError,
  } = appAPI.useFetchProjectListQuery({ ...requestParamList });

  const allProjects = useMemo(() => searchProjects(searchQuery, [...projectList]), [projectList, searchQuery]);

  const {
    isOpen: isProjectModalOpen,
    setIsOpen: setIsProjectModalOpen,
    onClose: handleCloseCreateProjectModal,
  } = useOpenModal();

  const setSearchParamItem = (searchParamValue: string, searchParamName: string) => {
    if (searchParamValue) {
      searchParams.set(searchParamName, searchParamValue);
    } else {
      searchParams.delete(searchParamName);
    }
    setSearchParams(searchParams);
  };

  const handleView = () => {
    if (cardsType === 'list') {
      setCardsType('card');
    } else {
      setCardsType('list');
    }
  };

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  const handleTimeInputChange = (value: string) => {
    setRequestParamList({ ...requestParamList, time: value });
    setSearchParamItem(value, 'time');
  };

  const handleOwnerSelect = (value: string) => {
    setRequestParamList({ ...requestParamList, owner: value });
    setSearchParamItem(value, 'owner');
  };

  const handleCollaboratorsSelect = (value: string) => {
    setRequestParamList({ ...requestParamList, collaborators: [value] });
    setSearchParamItem(value, 'collaborators');
  };

  const handleNewProjectClick = () => {
    setIsProjectModalOpen(true);
  };

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());
  }, []);

  useEffect(() => {
    if (!projectList?.length) {
      return;
    }

    const { collaborators } = collaboratorFilterProjects(projectList, collaboratorFilterOptionList);
    setCollaboratorFilterOptionList(() => [...collaborators]);

    const { owners } = ownerFilterProjects(projectList, ownerFilterOptionList);
    setOwnerFilterOptionList(() => [...owners]);
  }, [projectList]);

  useEffect(() => {
    const newRequestParamList: TRequestParamList = {};
    if (searchParams.has('time')) {
      newRequestParamList.time = searchParams.get('time') ?? '';
    }

    if (searchParams.has('owner')) {
      newRequestParamList.owner = searchParams.get('owner') ?? '';
    }

    if (searchParams.has('collaborators')) {
      newRequestParamList.collaborators = [searchParams.get('collaborators') ?? ''];
    }

    setRequestParamList(newRequestParamList);
  }, [searchParams]);

  return (
    <div className={cn('project-list')}>
      <PageHeader className={cn('project-list-header')}>
        <PageHeader.Section>
          <PageHeader.Title as="h1">
            Projects <span className={cn('project-list-header__title-count')}>{projectList.length}</span>
          </PageHeader.Title>
        </PageHeader.Section>

        {isProjectCreateAllowed && (
          <PageHeader.Section className={cn('project-list-header__actions')}>
            <Button className={cn('project-list-header__new-project-button')} onClick={handleNewProjectClick}>
              Create project
            </Button>
          </PageHeader.Section>
        )}
      </PageHeader>

      <ControlPanel isLoading={isProjectListLoading}>
        <ControlPanel.StickyContent>
          <ControlPanel.FilterList>
            <Select
              placeholder="Time"
              placeholderClassName={cn('project-list__select-item')}
              theme={themeOptions.light}
              value={requestParamList.time ?? ''}
              onChange={handleTimeInputChange}
              options={timeFilterOptionList}
            />

            <Select
              placeholder="Owner"
              placeholderClassName={cn('project-list__select-item')}
              theme={themeOptions.light}
              value={requestParamList.owner ?? ''}
              onChange={handleOwnerSelect}
              options={ownerFilterOptionList}
            />

            <Select
              placeholder="All collaborators"
              placeholderClassName={cn('project-list__select-item')}
              theme={themeOptions.light}
              value={requestParamList.collaborators ?? ''}
              onChange={handleCollaboratorsSelect}
              options={collaboratorFilterOptionList}
            />
          </ControlPanel.FilterList>
        </ControlPanel.StickyContent>
        <ControlPanel.RightActions>
          <SearchInput
            onChange={handleSearchInputChange}
            onReset={handleResetSearchClick}
            value={searchQuery}
            disabled={!projectList?.length}
            className={cn('project-list__search')}
          />
          <Button
            tooltip={cardsType === 'list' ? 'Cards view' : 'Panel view'}
            color="white"
            className={cn('project-list__button')}
            onClick={handleView}
          >
            {cardsType === 'list' ? <icons.CardsViewIcon /> : <icons.ChangeViewIcon />}
          </Button>
        </ControlPanel.RightActions>
      </ControlPanel>

      <ProjectListContextProvider allProjects={allProjects} searchQuery={searchQuery}>
        {(isProjectListError || !allProjects?.length) && !isProjectListLoading && (
          <div className={cn('project-list__error')}>
            <NoDataFound size="normal" alignment="center" />
          </div>
        )}
        {!!allProjects?.length && !isProjectListLoading && !isProjectListError && cardsType === 'card' && (
          <div className={cn('project-list__cards-list')}>
            {allProjects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        )}
        {!!allProjects?.length && !isProjectListLoading && !isProjectListError && cardsType === 'list' && (
          <div className={cn('project-list__list')}>
            <div className={cn('project-list__list-heading')}>
              <div className={cn('project-list__list-title')}>Project name</div>
              <div className={cn('project-list__list-title')}>Exp.</div>
              <div className={cn('project-list__list-title')}>Owner</div>
              <div className={cn('project-list__list-title')}>Team</div>
              <div className={cn('project-list__list-title')}>Created</div>
              <div className={cn('project-list__list-title')}>Modified</div>
            </div>
            {allProjects.map((project) => (
              <ProjectListItem key={project.id} project={project} />
            ))}
          </div>
        )}
      </ProjectListContextProvider>
      <CreateProjectModal isModalOpen={isProjectModalOpen} closeModal={handleCloseCreateProjectModal} />
    </div>
  );
};

export default ProjectList;
