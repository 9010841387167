import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useUserRole } from '@/hooks';

import { headerActions } from '@/store/slices/header';

import UserList from './UserList';

const UsersDashboard: FC = () => {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPermissionsRead, isUserReadAllowed } = useUserRole();

  useEffect(() => {
    if (!isPermissionsRead) {
      return;
    }

    if (!isUserReadAllowed) {
      navigate('/');
    } else {
      appDispatch(headerActions.removeAllLinks());
    }
  }, [isPermissionsRead, isUserReadAllowed]);

  return <UserList />;
};

export default UsersDashboard;
