import type { ChangeEvent } from 'react';
import { ZodType, ZodTypeDef } from 'zod';

export function isEqualPosition(
  pos1: { x: string | number; y: string | number; z: string | number },
  pos2: { x: string | number; y: string | number; z: string | number }
): boolean {
  return pos1.x === pos2.x && pos1.y === pos2.y && pos1.z === pos2.z;
}

export function numberFromEventValidate(e: ChangeEvent<HTMLInputElement>, customRegex?: RegExp) {
  let newValue = e.target.value;

  if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
    newValue = newValue.slice(1);
  }
  const numberValidationRegex = customRegex ?? /^(-)?(\d{0,8})?(\.)?(\d{0,3})?$/;
  if (!numberValidationRegex.test(newValue)) {
    return;
  }
  return newValue;
}

export const DecimalRegex = /(\d{0,8})$/;

export const PhoneNumberInputRegex = /^[+]?([0-9]{0,3})?\W?[(]?[0-9]{0,3}[)]?[-\s.]?[0-9]{0,3}[-\s.]?[0-9]{0,6}$/im;

export const PhoneNumberResultRegex = /^[+]?([0-9]{0,3})?\W?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const TOTPResultRegex = /^\d{6}$/;
export const TOTPInputRegex = /^\d{0,6}$/;

export function getErrorsByFieldsFromSchema<T>(object: Record<string, unknown>, schema: ZodType<T, ZodTypeDef, T>) {
  const checkResult = schema.safeParse(object);
  if (!checkResult.success) {
    return checkResult.error.issues.reduce((acc: Record<string, string>, { message, path }) => {
      acc[path[0].toString()] = message;
      return acc;
    }, {});
  }
  return {};
}
