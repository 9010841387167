import { SVGProps } from 'react';

const CollapseV2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 6L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15 6L20 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1 11L6 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M20 11L15 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default CollapseV2Icon;
