export const SELECTABLE_CLASS_NAME = 'react-selectable';
export const SELECTED_CLASS_NAME = 'react-selectable__selected';
export const COPIED_CLASS_NAME = 'react-selectable__copied';
export const CLIPBOARD_CLASS_NAME = 'react-selectable__clipboard';
export const SELECTABLE_ATTRIBUTE = 'data-selectable-info';
export const SELECT_BY_CLICK_CLASS_NAME = 'react-selectable__by-click';

export type TSelectableData = {
  rowIndex: number;
  columnIndex: number;
  dataType?: string;
};

export const addSelectableAttribute = (data: TSelectableData) => ({
  [SELECTABLE_ATTRIBUTE]: JSON.stringify(data),
});
