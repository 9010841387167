import { FC, memo, useCallback, useState } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { LoadStatusType, TAddViolinData } from '@/store/slices/violin';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import VirtualSelect from '@/components/common/VirtualSelect';

import styles from './ViolinPopover.module.scss';

const cn = classnames.bind(styles);

type TNewViolin = {
  onCreate: (data: TAddViolinData) => void;
  laneOptions: TBasicOption[];
  genOptions: TBasicOption[];
  experimentPath: string;
};

const NewViolin: FC<TNewViolin> = ({ onCreate, laneOptions, genOptions, experimentPath }) => {
  const [gene, setGen] = useState<string>('');
  const [lane, setLane] = useState<string>('');

  const onChangeGen = useCallback((genOption: TBasicOption) => {
    setGen(genOption.value.toString());
  }, []);

  const onChangeLane = useCallback((newLaneId: string) => {
    setLane(newLaneId);
  }, []);

  const handleCreate = useCallback(() => {
    if (!gene || !lane || !experimentPath) {
      return;
    }

    const violinData: TAddViolinData = {
      geneName: gene,
      laneId: lane,
      experimentCloudPath: experimentPath,
      loadStatus: LoadStatusType.loading,
    };
    onCreate(violinData);
    setGen('');
    setLane('');
  }, [onCreate, experimentPath, gene, lane]);

  return (
    <div className={cn('violin-row')}>
      <VirtualSelect
        options={genOptions}
        onSelect={onChangeGen}
        value={gene}
        inputClassName={cn('select__virtual')}
        placeholder="Select gene"
      />
      <Select
        options={laneOptions}
        onChange={onChangeLane}
        theme={themeOptions.light}
        className={cn('select')}
        value={lane}
        placeholder="Select lane"
      />
      <div className={cn('violin-row__actions')}>
        <Button isFitContent color="white" onClick={handleCreate} tooltip="Add violin">
          <icons.SaveIcon className={cn('icon')} />
        </Button>
      </div>
    </div>
  );
};

export default memo(NewViolin);
