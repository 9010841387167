import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { removeDuplicates } from '@/helpers';
import { isDatasetDetailsNeedPreprocessing } from '@/helpers/preprocessing';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { useParamsExperimentId } from '@/hooks';
import { datasetsSelectors } from '@/store/slices/datasets';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import NoDataFound from '@/components/common/NoDataFound';

import DatasetDetailsRow from './DatasetDetailsRow';
import styles from './DatasetDetailsTable.module.scss';

const cn = classnames.bind(styles);

type TDatasetDetailsTableProps = {
  laneId: string;
};

const DatasetDetailsTable = ({ laneId }: TDatasetDetailsTableProps) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const laneDatasetDetailsList = useSelector(datasetsSelectors.selectDatasetDetailsList(experimentId, laneId));
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const preprocessingAssayId = useSelector(preprocessingSelectors.selectPreprocessingAssayId);

  const filteredLaneDatasetDetailsList = useMemo(() => {
    if (!isPreprocessingView) {
      return laneDatasetDetailsList;
    }

    return laneDatasetDetailsList.filter((laneDataset) =>
      isDatasetDetailsNeedPreprocessing(laneDataset, preprocessingAssayId)
    );
  }, [preprocessingAssayId, laneId, isPreprocessingView]);

  useEffect(() => {
    if (!isPreprocessingView) {
      return;
    }
    appDispatch(
      preprocessingActions.addDatasets({
        laneId,
        data: removeDuplicates(filteredLaneDatasetDetailsList, 'scanId', 'laneId'),
      })
    );
  }, [filteredLaneDatasetDetailsList]);

  return (
    <div className={cn('table-wrapper')}>
      <div className={cn('table-scroll')}>
        <table className={cn('table')}>
          <thead className={cn('table__head')}>
            <tr className={cn('table__row')}>
              <th className={cn('table__head-item')}>Dataset name</th>
              <th className={cn('table__head-item', 'table__head-item_center')}>Time point</th>
              <th className={cn('table__head-item')}>Assay type</th>
              <th className={cn('table__head-item')}>Markers</th>
              <td aria-label="empty" />
            </tr>
          </thead>
          <tbody className={cn('table__body')}>
            {filteredLaneDatasetDetailsList.length === 0 && (
              <tr>
                <td aria-label="empty" colSpan={5}>
                  <NoDataFound className={cn('table__no-data')} size="normal" alignment="center" />
                </td>
              </tr>
            )}

            {filteredLaneDatasetDetailsList.map((datasetDetails) => (
              <DatasetDetailsRow key={datasetDetails.id} datasetDetails={datasetDetails} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DatasetDetailsTable;
