import { SVGProps } from 'react';

const ArrowDropDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="11" height="7" viewBox="0 0 11 7" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="m1 1 4.5 4.5M5.5 5.5 10 1" />
    </g>
  </svg>
);

export default ArrowDropDownIcon;
