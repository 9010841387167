import { SVGProps } from 'react';

const RangeGateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.08789 0V18" stroke="currentColor" strokeWidth="1.5" />
    <path d="M20.6631 0V18" stroke="currentColor" strokeWidth="1.5" />
    <path d="M19.3125 9L1.7625 9.00001" stroke="currentColor" strokeWidth="1.5" strokeDasharray="1.8 1.8" />
  </svg>
);

export default RangeGateIcon;
