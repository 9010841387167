import { createContext, useContext, ReactNode, FC, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ECdnObjectType } from '@/types/cdnData';

import { findLaneInScanList } from '@/helpers/scans';

import { TUseCdnTablesData, useCdnTablesData, useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentSelectors } from '@/store/slices/experiment';
import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';

type TContoursContext = {
  lane: Nullable<TLane>;
  cdnData: {
    [ECdnObjectType.cageContour]: TUseCdnTablesData & { objectList: Array<TCageContour> };
    [ECdnObjectType.cellContour]: TUseCdnTablesData & { objectList: Array<TCellContour> };
    [ECdnObjectType.barcodeCenters]: TUseCdnTablesData & { objectList: Array<TBarcodeCenter> };
    [ECdnObjectType.cagingSegmentationCenters]: TUseCdnTablesData & { objectList: Array<TCagingSegmentationCenter> };
  };
};

const ContoursContext = createContext<TContoursContext>({} as TContoursContext);

export const useContoursContext = (): TContoursContext => useContext(ContoursContext);

type TContoursContextProviderProps = {
  children: ReactNode;
};

export const ContoursContextProvider: FC<TContoursContextProviderProps> = ({ children }) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentScan = useSelector(experimentSelectors.selectScan(currentScanId));
  const currentLane = useMemo(
    () => findLaneInScanList(scanList, currentScanId, currentLaneId),
    [scanList, currentScanId, currentLaneId]
  );
  const lane = useMemo(() => currentLane ?? currentScan?.lanes[0] ?? null, [currentScan, currentLane]);

  const cdnData = {
    [ECdnObjectType.cageContour]: useCdnTablesData(lane, ECdnObjectType.cageContour),
    [ECdnObjectType.cellContour]: useCdnTablesData(lane, ECdnObjectType.cellContour),
    [ECdnObjectType.barcodeCenters]: useCdnTablesData(lane, ECdnObjectType.barcodeCenters),
    [ECdnObjectType.cagingSegmentationCenters]: useCdnTablesData(lane, ECdnObjectType.cagingSegmentationCenters),
  };

  useEffect(() => {
    if (!currentLane && lane) {
      appDispatch(navigatorActions.setCurrentLaneId({ experimentId, laneId: lane.id }));
    }
  }, [lane, currentLane]);

  const contoursContextData = useMemo(() => {
    const cageContourData = cdnData[ECdnObjectType.cageContour];
    const cageContourList = cageContourData.objectList as TCageContour[];
    const cellContourData = cdnData[ECdnObjectType.cellContour];
    const cellContourList = cellContourData.objectList as TCellContour[];
    const barcodeCentersData = cdnData[ECdnObjectType.barcodeCenters];
    const barcodeCentersList = barcodeCentersData.objectList as TBarcodeCenter[];
    const cagingSegmentationCentersData = cdnData[ECdnObjectType.cagingSegmentationCenters];
    const cagingSegmentationCentersList = cagingSegmentationCentersData.objectList as TCagingSegmentationCenter[];
    return {
      lane,
      cdnData: {
        [ECdnObjectType.cageContour]: { ...cageContourData, objectList: cageContourList },
        [ECdnObjectType.cellContour]: { ...cellContourData, objectList: cellContourList },
        [ECdnObjectType.barcodeCenters]: { ...barcodeCentersData, objectList: barcodeCentersList },
        [ECdnObjectType.cagingSegmentationCenters]: {
          ...cagingSegmentationCentersData,
          objectList: cagingSegmentationCentersList,
        },
      },
    };
  }, [lane, cdnData]);

  return <ContoursContext.Provider value={contoursContextData}>{children}</ContoursContext.Provider>;
};
