import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API as awsAPI } from 'aws-amplify';

import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import {
  CreateRunDesignFromTemplateInput,
  RunDesign,
  RunDesignTemplate,
  RunDesignTemplateVersion,
  UpdateRunDesignInput,
} from '@/graphql/API';

export const graphqlAPI = createApi({
  reducerPath: 'graphqlAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['RunDesign'],
  endpoints: (build) => ({
    fetchTemplate: build.query<RunDesignTemplate, string | undefined>({
      // @ts-ignore
      queryFn: async (templateId) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesignTemplate,
            variables: {
              id: templateId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          return { data: response?.data.runDesignTemplate };
        } catch (error) {
          return { error };
        }
      },
    }),

    fetchTemplateVersion: build.query<RunDesignTemplateVersion, string | undefined>({
      // @ts-ignore
      queryFn: async (templateId) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesignTemplateVersion,
            variables: {
              id: templateId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          return { data: response?.data.runDesignTemplateVersion };
        } catch (error) {
          return { error };
        }
      },
    }),

    fetchRunDesign: build.query<RunDesign, string | undefined>({
      // @ts-ignore
      queryFn: async (runDesignId) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesign,
            variables: {
              id: runDesignId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          return { data: response?.data.runDesign };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: 'RunDesign', id }],
    }),

    createRunDesign: build.mutation<string, CreateRunDesignFromTemplateInput>({
      // @ts-ignore
      queryFn: async (input) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.createRunDesignFromTemplate,
            variables: { input },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const createdRunDesignId = response?.data?.createRunDesignFromTemplate.id;
          if (createdRunDesignId) {
            return { data: createdRunDesignId };
          }
          return { error: 'Error creating run design' };
        } catch (error) {
          return { error };
        }
      },
    }),

    updateRunDesign: build.mutation<RunDesign, { id: string; input: UpdateRunDesignInput }>({
      // @ts-ignore
      queryFn: async ({ id, input }) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.updateRunDesign,
            variables: { id, input },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const { updateRunDesign } = response?.data ?? {};
          if (updateRunDesign) {
            return { data: updateRunDesign };
          }
          return { error: 'Error updating run design' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'RunDesign', id }],
    }),

    publishRunDesign: build.mutation<RunDesign, string>({
      // @ts-ignore
      queryFn: async (id) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.publishRunDesign,
            variables: { id },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const { publishRunDesign } = response?.data ?? {};
          if (publishRunDesign) {
            return { data: publishRunDesign };
          }
          return { error: 'Error publishing run design' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'RunDesign', id }],
    }),
  }),
});
