import zod from 'zod';

const originalConsoleError = console.error;
console.error = (...args) => {
  if (args.length && args[0] instanceof zod.ZodError) {
    if (process.env.REACT_APP_SHOW_ZOD_ERROR_IN_CONSOLE === 'false') {
      console.error('There is Zod error. Additional info is hidden by .env value.');
      return;
    }
  }

  originalConsoleError(...args);
};
