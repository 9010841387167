import { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import { isDefined } from '@/helpers/typeGuards';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import styles from './InformationList.module.scss';

const cn = classNames.bind(styles);

type TInformationListProps = {
  reagent: TReturnTransformedTypes;
};

const InformationList: FC<TInformationListProps> = ({ reagent }) => {
  const { config } = useReagentModalContext();

  const includedFields = config.search.result?.informationList ?? [];

  const listData = useMemo(
    () =>
      includedFields.reduce<{ label: string; value: string }[]>((acc, fieldName) => {
        if (isDefined(reagent) && fieldName in reagent) {
          acc.push({
            label: fieldName
              .replace(/[A-Z]/g, (symbol) => ` ${symbol}`)
              .toLowerCase()
              .replace(/^./, (symbol) => symbol.toUpperCase()),
            value: reagent[fieldName as keyof typeof reagent],
          });
        }

        return acc;
      }, []),
    [reagent, includedFields]
  );

  return (
    <div className={cn('information-list')}>
      {listData.map(({ label, value }) => (
        <div className={cn('information-list__row')} key={value}>
          <div className={cn('information-list__label')}>{label}</div>
          <div className={cn('information-list__value')}>{value}</div>
        </div>
      ))}
    </div>
  );
};

export default InformationList;
