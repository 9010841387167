import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { select as d3Select } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';

import { getGateById } from '@/helpers/gates';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { drawControlElement, drawGateLabel, getPointsFromChartScale, updateLabelPosition } from './helpers/common';
import { HANDLERS_OFFSET } from './constants';
import { generateLine, intersects } from './helpers/polygonGates';
import {
  TSelectionPathElement,
  TSelectionGroupElement,
  TD3DragEvent,
  TSelectionRectElement,
  EGateDragType,
  TUseGatesOnPlotPayload,
} from './types';

type TPolygonData = {
  gateGroup: Nullable<TSelectionGroupElement>;
  path: Nullable<TSelectionPathElement>;
  pathArray: TGatePolygons;
  isClicking: boolean;
  firstClick: boolean;
  count: number;
  startPoint: number[];
  endPoint: number[];
};

const INITIAL_POLYGON_DATA: TPolygonData = {
  gateGroup: null,
  path: null,
  pathArray: [],
  isClicking: false,
  firstClick: true,
  count: 0,
  startPoint: [],
  endPoint: [],
};

export function usePolygonGates({
  chartContainerParameters,
  plotId,
  plotRange,
  isStatic,
  openGateCreationModal,
  displayType,
  updateGate,
  entityLevelGateList,
  handleGateDrag,
  origDataRange,
  isGateMenuHidden,
}: TUseGatesOnPlotPayload) {
  const chartId = usePlotChartIdContext();

  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );

  const [newPolygonData, setNewPolygonData] = useState<TPolygonData>({ ...INITIAL_POLYGON_DATA });
  const isChangedRef = useRef(false);

  const clearPolygonData = () => {
    setNewPolygonData({ ...INITIAL_POLYGON_DATA });
  };

  const updatePolygonPath = useCallback(
    (polygonData?: Pick<TPolygonData, 'path' | 'pathArray'>) => {
      const data = polygonData ?? newPolygonData;
      if (!data?.path || !data?.pathArray) return;

      const { path } = data;
      path.attr('d', generateLine(data.pathArray)).attr('class', 'gate-element');
    },
    [newPolygonData]
  );

  const updateControlsPosition = (
    path: TSelectionPathElement,
    eventData: TD3DragEvent,
    controlForUpdate?: TSelectionRectElement
  ) => {
    const gateGroupElement = path.node()?.parentNode;
    if (!gateGroupElement) return;

    if (controlForUpdate?.node()) {
      const cx = parseFloat(controlForUpdate.attr('x'));
      const cy = parseFloat(controlForUpdate.attr('y'));

      controlForUpdate.attr('x', cx + eventData.dx).attr('y', cy + eventData.dy);
      return;
    }

    const gateId = path.attr('gate-id').split('gate_')[1];
    const controls = d3Select(gateGroupElement as Element)
      .selectAll(`[gate-control-id = 'gate-control_${gateId}']`)
      .nodes();

    controls.forEach((control) => {
      const controlElement = d3Select(control);
      const cx = parseFloat(controlElement.attr('x'));
      const cy = parseFloat(controlElement.attr('y'));

      controlElement.attr('x', cx + eventData.dx).attr('y', cy + eventData.dy);
    });
  };

  const onPolygonControlDragStart = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const onPolygonControlDrag = (control: TSelectionRectElement, event: TD3DragEvent) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];

    const gateGroupElement = control.node()?.parentNode ?? null;

    if (!gateGroupElement) return;

    handleGateDrag(gateId, EGateDragType.drag);
    const path: TSelectionPathElement = d3Select(gateGroupElement as Element).select(`[gate-id = 'gate_${gateId}']`);
    const d = path.attr('d').replace(/[a-z]/gi, ' ');

    const polygons: TGatePolygons = getPolygonsFromPath(d);
    const newPoly = [...polygons];
    const controlXPolygon = parseFloat(control.attr('x')) + HANDLERS_OFFSET;
    const controlYPolygon = parseFloat(control.attr('y')) + HANDLERS_OFFSET;
    const roundedX = Math.round(controlXPolygon * 1000) / 1000;
    const roundedY = Math.round(controlYPolygon * 1000) / 1000;

    const updatedPolygonIndex = polygons.findIndex(
      (polygon: TGatePolygon) => polygon.x === roundedX && polygon.y === roundedY
    );

    if (updatedPolygonIndex === -1) return;

    const updatedPolygon = { x: roundedX + event.dx, y: roundedY + event.dy };
    newPoly[updatedPolygonIndex] = updatedPolygon;

    if (updatedPolygonIndex === 0 || updatedPolygonIndex === polygons.length - 1) {
      newPoly[polygons.length - 1] = updatedPolygon;
    }

    updatePolygonPath({
      path,
      pathArray: newPoly,
    });

    updateControlsPosition(path, event, control);
  };

  const onPolygonControlDragEnd = (control: TSelectionRectElement) => {
    const controlGateIdAttr = control.attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    const gateGroupElement = control.node()?.parentNode ?? null;

    if (!gateGroupElement) return;
    const path: TSelectionPathElement = d3Select(gateGroupElement as Element).select(`[gate-id = 'gate_${gateId}']`);
    onPolygonDragEnd(path);
  };

  const controlDragHandler = d3Drag<SVGRectElement, unknown>()
    .on('start', function handleDragStart() {
      const control = d3Select(this);
      onPolygonControlDragStart(control);
    })
    .on('drag', function handleDrag(event) {
      const control = d3Select(this);
      onPolygonControlDrag(control, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const control = d3Select(this);
      onPolygonControlDragEnd(control);
    });

  const onPolygonDragStart = (polygon: TSelectionPathElement) => {
    const gateId = polygon.attr('gate-id').split('gate_')[1];

    handleGateDrag(gateId, EGateDragType.dragStart);
  };

  const getPolygonsFromPath = (path: string) => {
    const pathPoints = path.trim().split(' ');
    const polygons: TGatePolygons = pathPoints.map((pointStr: string) => {
      const pointArr = pointStr.split(',');
      return { x: parseFloat(pointArr[0]), y: parseFloat(pointArr[1]) };
    });

    return polygons;
  };

  const onPolygonDrag = (path: TSelectionPathElement, event: TD3DragEvent) => {
    const d = path.attr('d').replace(/[a-z]/gi, ' ');
    const pathIdAttr = path.attr('gate-id');
    const gateId = pathIdAttr.split('gate_')[1];

    updateLabelPosition(gateId, [event.dx, event.dy], isGateMenuHidden, plotId);

    handleGateDrag(gateId, EGateDragType.drag);

    const currentPolygons: TGatePolygons = getPolygonsFromPath(d);
    const updatedPolygons = currentPolygons.map((item) => ({
      x: item.x + event.dx,
      y: item.y + event.dy,
    }));

    updatePolygonPath({
      path,
      pathArray: updatedPolygons,
    });
    updateControlsPosition(path, event);
  };

  const onPolygonDragEnd = useCallback(
    (path: TSelectionPathElement) => {
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
      const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn

      if (!shapesContainerParameters || !chartContainerParameters || !plotRange) return;

      const d = path.attr('d').replace(/[a-z]/gi, ' ');
      const pathIdAttr = path.attr('gate-id');
      const gateId = pathIdAttr.split('gate_')[1];
      const currentPolygons = getPolygonsFromPath(d);
      const updatedPolygons = getPointsFromChartScale({
        polygons: currentPolygons,
        shapesContainerParameters,
        range: plotRange,
      });
      const gate = getGateById(gateId, entityLevelGateList);

      if (!gate) return;

      handleGateDrag(gateId, EGateDragType.dragEnd, {
        ...gate,
        shape: {
          type: 'polygon',
          model: {
            points: updatedPolygons,
          },
        },
      });

      if (!isChangedRef.current) {
        return;
      }

      const points = axisScaleHelper.getRealGatePoints({
        xAxisScaleType,
        yAxisScaleType,
        gatePoints: updatedPolygons,
        plotLinearRange: plotRange,
        origDataRange,
      });

      updateGate(gate, {
        shape: {
          type: 'polygon',
          model: {
            points,
          },
        },
      });

      isChangedRef.current = false;
    },
    [chartContainerParameters, plotRange, entityLevelGateList]
  );

  const handlePolygonDrag = d3Drag<SVGPathElement, unknown>()
    .on('start', function handleDragStart() {
      const polygon = d3Select(this);
      onPolygonDragStart(polygon);
    })
    .on('drag', function handleDrag(event) {
      const path = d3Select(this);
      onPolygonDrag(path, event);
      isChangedRef.current = true;
    })
    .on('end', function handleDragEnd() {
      const path = d3Select(this);

      onPolygonDragEnd(path);
    });

  const handlePolygonCreationEnded = (path: Nullable<TSelectionPathElement>, dataPt: TGatePolygon) => {
    setNewPolygonData((prevData) => {
      if (!prevData) return prevData;
      const newPathArray = [...prevData.pathArray];
      newPathArray[newPathArray.length - 1] = dataPt;

      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
      const shapesContainerParameters = shapesContainer ? shapesContainer.getBoundingClientRect() : null; // canvas svg container in which gates are drawn

      if (!shapesContainerParameters || !chartContainerParameters || !plotRange) return prevData;

      const polygons = getPointsFromChartScale({
        polygons: newPathArray,
        shapesContainerParameters,
        range: plotRange,
      });

      const realPolygons = axisScaleHelper.getRealGatePoints({
        xAxisScaleType,
        yAxisScaleType,
        gatePoints: polygons,
        plotLinearRange: plotRange,
        origDataRange,
      });
      openGateCreationModal({ type: 'polygon', model: { points: realPolygons } });

      return {
        ...prevData,
        pathArray: newPathArray,
        isClicking: false,
        firstClick: true,
        path,
      };
    });
  };

  const handlePolygonMouseDown = useCallback(
    (eventData: number[]) => {
      let { isClicking } = newPolygonData;

      for (let i = 0; i < newPolygonData.pathArray.length - 1; i++) {
        const dataPt = newPolygonData.pathArray[i];
        if (intersects(eventData[0], eventData[1], dataPt.x, dataPt.y, 10)) {
          const { path } = newPolygonData;

          handlePolygonCreationEnded(path, dataPt);

          isClicking = false;
          break;
        }
      }

      if (newPolygonData.firstClick) {
        const id = Date.now();
        const svg = d3Select(`#${plotId} .gates-container`);
        const group = svg.append('g').attr('gate-group-id', `group_${id}`).attr('class', 'gate-group');
        const path = group.append('path').attr('fill', 'none').style('cursor', 'initial');

        setNewPolygonData((prevData) => {
          if (!prevData) return INITIAL_POLYGON_DATA;

          return {
            ...prevData,
            isClicking: true,
            startPoint: eventData,
            pathArray: [
              { x: eventData[0], y: eventData[1] },
              { x: eventData[0], y: eventData[1] },
            ],
            firstClick: false,
            gateGroup: group,
            path,
          };
        });

        drawControlElement({
          parentElement: group,
          x: eventData[0],
          y: eventData[1],
          classes: 'gate-element__control',
          attributes: [{ attr: 'gate-control-id', value: `gate-control_${id}` }],
        }).style('opacity', 1);

        updatePolygonPath();
      } else if (isClicking) {
        setNewPolygonData((prevData) => {
          if (!newPolygonData) return INITIAL_POLYGON_DATA;
          const newPathArr = [...prevData.pathArray];
          newPathArr[newPathArr.length] = { x: eventData[0], y: eventData[1] };

          const updatedData = {
            ...prevData,
            isClicking: true,
            pathArray: newPathArr,
            endPoint: eventData,
          };
          updatePolygonPath(updatedData);

          return updatedData;
        });

        if (!newPolygonData?.gateGroup) return;

        const gateId = newPolygonData.gateGroup.attr('gate-group-id').split('group_')[1];
        drawControlElement({
          parentElement: newPolygonData.gateGroup,
          x: eventData[0],
          y: eventData[1],
          classes: 'gate-element__control ',
          attributes: [{ attr: 'gate-control-id', value: `gate-control_${gateId}` }],
        }).style('opacity', 1);
      }
    },
    [newPolygonData, chartContainerParameters, plotRange]
  );

  const handlePolygonMouseMove = useCallback(
    (eventData: [number, number]) => {
      if (!newPolygonData.isClicking) return;
      setNewPolygonData((prevData) => {
        const newPathArr = [...prevData.pathArray];
        newPathArr[newPathArr.length - 1] = { x: eventData[0], y: eventData[1] };
        const updatedData = {
          ...prevData,
          isClicking: true,
          pathArray: newPathArr,
        };

        updatePolygonPath(updatedData);

        return updatedData;
      });
    },
    [newPolygonData.isClicking]
  );

  const drawPolygon = ({
    gateId,
    gatePolygons,
    label,
    groupClassName = 'gate-group',
  }: {
    gateId: string;
    gatePolygons: TGatePolygons;
    label: string;
    groupClassName?: string;
  }) => {
    const svg = d3Select(`#${plotId} .gates-container`);
    const group = svg
      .append('g')
      .attr('gate-group-id', `group_${gateId}`)
      .attr('class', groupClassName)
      .attr('gate-type', 'polygon');

    const topPolygon = gatePolygons.reduce((prev, cur) => (cur?.y < prev.y ? cur : prev), { y: Infinity, x: 0 });
    const gateIdAttr = `gate_${gateId}`;

    if (label) {
      drawGateLabel({
        gateContainer: group,
        bgX: topPolygon.x,
        bgY: topPolygon.y,
        textX: topPolygon.x,
        textY: topPolygon.y,
        gateIdAttr,
        label,
        displayType,
        isGateMenuHidden,
      });
    }

    const path = group
      .append('path')
      .attr('d', generateLine(gatePolygons))
      .attr('class', 'gate-element')
      .attr('gate-id', gateIdAttr);

    const controlList: Element[] = [];

    gatePolygons.forEach((polygon, index) => {
      if (index === gatePolygons.length - 1) return;
      const newControlData = {
        parentElement: group,
        classes: '',
      };
      const control = drawControlElement({
        ...newControlData,
        x: polygon.x,
        y: polygon.y,
        classes: 'gate-element__control',
        attributes: [{ attr: 'gate-control-id', value: `gate-control_${gateId}` }],
      });

      controlList.push(control.node() as Element);
    });

    if (!isStatic) {
      path.call(handlePolygonDrag);

      controlList.forEach((control) => {
        const controlSelection = d3Select(control);
        controlSelection.call(controlDragHandler as (selection: unknown) => void);
      });
    }
  };

  return {
    drawPolygon,
    handlePolygonMouseDown,
    handlePolygonMouseMove,
    newPolygonData,
    clearPolygonData,
  };
}

export default usePolygonGates;
