import { useEffect, useState } from 'react';
import { API as awsAPI } from 'aws-amplify';

import * as queries from '@/graphql/queries';
import { ListJobRunsConnection } from '@/graphql/API';

export function useGetLastJobRunDate() {
  const [lastJobRunDate, setLastJobRunDate] = useState<string>();

  const requestLastJobRun = async () => {
    const response = (await awsAPI.graphql({
      query: queries.jobRuns,
      variables: {
        input: {
          limit: 1,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as {
      data: Record<string, ListJobRunsConnection>;
    };
    setLastJobRunDate(response?.data.jobRuns.edges[0]?.node.createdAt);
  };

  useEffect(() => {
    requestLastJobRun();
  }, []);

  return lastJobRunDate;
}
