import { FC, ReactNode } from 'react';

import classnames from 'classnames/bind';

import styles from '../PageHeader.module.scss';

const cn = classnames.bind(styles);

type TPageHeaderSlotProps = {
  className?: string;
  children?: ReactNode;
};

const PageHeaderSection: FC<TPageHeaderSlotProps> = ({ className, children }) => (
  <div className={cn('page-header__section', className)}>{children}</div>
);

export default PageHeaderSection;
