import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';
import { TChartEntityListData } from '@/store/slices/chartData/types';

export const selectMultiChartEntityListData = (state: RootState) => state.chartData.chartEntityListData;

export const selectChartEntityListData = (datasetPath?: string) =>
  createSelector(
    [selectMultiChartEntityListData],
    (entityListData) =>
      ((datasetPath && entityListData[datasetPath]) ?? {
        isLoading: true,
        isError: false,
      }) as TChartEntityListData
  );

export const selectAllOrigDataRanges = (state: RootState) => state.chartData.origDataRange;

export const selectCurrentChartData = (state: RootState) => state.chartData.currentChartData;
