import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import Datasets from '@/pages/Experiment/components/Data/Datasets';

import mainStyles from '../Preprocessing.module.scss';

const cnMain = classnames.bind(mainStyles);

type TPreprocessingDatasetsProps = {
  className?: string;
};

const PreprocessingDatasets: FC<TPreprocessingDatasetsProps> = ({ className }) => (
  <div className={cnMain('question-block', 'datasets-table', className)}>
    <h1 className={cnMain('question-block__title', 'datasets-table__title')}>Datasets in your preprocessing batch</h1>
    <Datasets className={cnMain('datasets-table__table')} />
  </div>
);

export default memo(PreprocessingDatasets);
