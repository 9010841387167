import { isNumber } from '@/helpers';

export const getContourList = (objList: TEntity[]) => {
  const outlineList: Pick<TCellContour, 'canvasPoints'>[] = [];
  objList.forEach((object) => {
    const xMin = object.objBboxInCageXtl;
    const xMax = object.objBboxInCageXbr;
    const yMin = object.objBboxInCageYtl;
    const yMax = object.objBboxInCageYbr;
    if (isNumber(xMin) && isNumber(xMax) && isNumber(yMin) && isNumber(yMax)) {
      outlineList.push({
        canvasPoints: [
          [xMin, yMin],
          [xMax, yMin],
          [xMax, yMax],
          [xMin, yMax],
          [xMin, yMin],
        ],
      });
    }
  });
  return outlineList;
};
