import { FC, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { EChartType } from '@/types/charts';

import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { gatesActions } from '@/store/slices/gates';

import SwitchInput from '@/components/common/SwitchInput';

const forcedCageLvlChartTypeList = new Map([
  [EChartType.violin, true],
  [EChartType.knee, true],
  [EChartType.heatmap, true],
]);

const EntityLevelSwitch: FC = () => {
  const appDispatch = useAppDispatch();
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));
  const plotType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const onChange = useCallback(() => {
    appDispatch(chartSettingsActions.setIsObjectEntityEnabled(!isObjectEntityEnabled));
  }, [isObjectEntityEnabled]);

  useEffect(() => {
    if (!forcedCageLvlChartTypeList.has(plotType)) {
      appDispatch(chartSettingsActions.setIsCageLvlForced(false));
      return;
    }
    appDispatch(chartSettingsActions.setIsCageLvlForced(true));
    return () => {
      if (isObjectEntityEnabled) {
        appDispatch(gatesActions.setSelectedGate(null));
      }
      appDispatch(chartSettingsActions.setIsCageLvlForced(false));
    };
  }, [plotType]);

  return (
    <SwitchInput
      checked={isCageLvlForced ? false : isObjectEntityEnabled}
      onChange={onChange}
      offLabel="Cage"
      onLabel="Object"
      disabled={isCageLvlForced || !!fullScreenChartData} // TODO:   disabled swith on matrix full screen
      key={`${isCageLvlForced}`} // key is passed to prevent animation when changing the props of the component
    />
  );
};

export default memo(EntityLevelSwitch);
