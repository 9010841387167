import { sortData } from '@/helpers';

export const getFormattedInstruments = (instrumentFilterOptionList: Array<TInstrumentFromServer>) => {
  const formattedInstruments: Array<TOption> = [];
  const firstInstrument = { value: '', label: 'All instruments' };

  [...instrumentFilterOptionList]
    .sort((current, next) => sortData(current.name, next.name))
    .forEach((instrument) => {
      const label = instrument?.friendlyName ?? instrument.name;
      formattedInstruments.push({ value: instrument.id, label });
    });

  formattedInstruments.unshift(firstInstrument);

  return formattedInstruments;
};

export const getFormattedOperators = (operatorList: string[]) => {
  const formattedOperators: Array<TOption> = [];
  const firstOperator = { value: '', label: 'All operators' };

  operatorList.forEach((operator) => {
    if (operator) {
      formattedOperators.push({ value: operator, label: operator });
    }
  });

  formattedOperators.sort((current, next) => sortData(current.label, next.label));

  formattedOperators.unshift(firstOperator);

  return formattedOperators;
};
