import { D3DragEvent, SubjectPosition } from 'd3-drag';
import { Selection, BaseType } from 'd3-selection';

import { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import { EAxesScaleType } from '@/types/charts';
import { EGateLabelType } from '@/types/gateSettings';
import { EAxesGroupName } from '@/store/slices/scatterplots';

import { TUseUpdateGateCallbacksType } from './useUpdateGates';

export type TSelectionRectElement = Selection<SVGRectElement, unknown, BaseType, unknown>;

export type TSelectionEllipseElement = Selection<SVGEllipseElement, unknown, BaseType, unknown>;

export type TSelectionPathElement = Selection<SVGPathElement, unknown, BaseType, unknown>;

export type TSelectionLineElement = Selection<SVGLineElement, unknown, BaseType, unknown>;

export type TSelectionGroupElement = Selection<SVGGElement, unknown, BaseType, unknown>;

export type TSelectionSVGSVGElement = Selection<SVGSVGElement, unknown, HTMLElement, unknown>;

export type TSelectionDivElement = Selection<HTMLDivElement, unknown, HTMLElement, unknown>;

export type TBaseElementDatum = {
  dx: number;
  dy: number;
} & Record<string, number>;

export type TD3DragEvent = D3DragEvent<SVGSVGElement, TBaseElementDatum, SubjectPosition>;

export enum EGateDragType {
  drag = 'drag',
  dragStart = 'dragStart',
  dragEnd = 'dragEnd',
}

export type TUseGatesOnPlotPayload = {
  chartContainerParameters: Nullable<DOMRect>;
  plotId: string;
  plotRange: Nullable<TPopulationRange>;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  isStatic: boolean;
  openGateCreationModal: (newGateData: TNewGateModelData) => void;
  displayType: EGateLabelType;
  createGate: TUseUpdateGateCallbacksType['createGate'];
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
  entityLevelGateList: TGeneralChartProps['entityLevelGateList'];
  handleGateDrag: (gateId: string, type: EGateDragType, gate?: TGate) => void;
  scanId: string;
  laneId: string;
  origDataRange?: TPlotAxisRange;
  isObjectEntityEnabled?: boolean;
  isGateMenuHidden: boolean;
  specificAxesGroupName?: EAxesGroupName;
};
