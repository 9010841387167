import { SVGProps } from 'react';

const AssayCellKilling = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" version="1.1" viewBox="0 0 130 130" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round">
      <path d="M23 47.2c3.2-2 7-3.2 11.1-3.2C45.6 44 55 53.4 55 65s-9.4 21-20.9 21c-4.1 0-7.8-1.2-11.1-3.2M105 46.3c-2.9-1.5-6.2-2.3-9.7-2.3C83.5 44 74 53.4 74 65s9.5 21 21.3 21c3.5 0 6.8-.8 9.7-2.3M95 43v-7M95 94v-7M74.4 86.6l6.1-6M66 65h7M75 45l4.7 4.8M64 60c-2.2.5-4 2.3-4 5s1.8 4.5 4 5M39 95c-.5-2.2-2.3-4-5-4s-4.5 1.8-5 4M29 35c.5 2.2 2.3 4 5 4s4.5-1.8 5-4M51.7 40.8c-.9 1.8-.8 4 .9 5.8 1.7 1.7 4 1.8 5.8.9M58.4 82.5c-1.8-.9-4-.8-5.8.9-1.8 1.8-1.8 4.3-.8 6.1" />
    </g>
  </svg>
);

export default AssayCellKilling;
