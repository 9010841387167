import { useEffect, useRef, useState, useMemo, memo, useCallback, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import classnames from 'classnames/bind';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ECanvasWebglContextType, EPageWithChartType } from '@/types/charts';

import { DRAGGABLE_TYPES, addTooltip, isTouchScreen } from '@/helpers';
import { isEqualAxes } from '@/helpers/channels';
import { isHistogramChartType } from '@/helpers/charts/chartsType';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotGateAndAxesUpdate, usePlotSettings, useScatterglPlotsSettings } from '@/hooks';
import { useGatesOnPlot } from '@/hooks/gates/useGatesOnPlot';
import { useLinks } from '@/hooks/useLinks';
import { useHistogramPlotsSettings } from '@/hooks/plotSettings/useHistogramPlotsSettings';
import { useChartSideEffects } from '@/hooks/useChartSideEffects';
import usePlotProxy from '@/hooks/usePlotProxy';

import { experimentSelectors } from '@/store/slices/experiment';
import { chartDataSelectors } from '@/store/slices/chartData';
import { scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { datasetsSelectors } from '@/store/slices/datasets';

import '@/styles/gates.scss';

import Button from '@/components/common/Button';
import { getOption, isBasicOption } from '@/components/common/Select/helpers';
import NoDataFound from '@/components/common/NoDataFound';
import icons from '@/components/common/icons';
import type { TChartCallBacksData, TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import { getHistogramsYAxisLabel } from '@/helpers/charts/lineHistogram';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { withChartData } from '@/hoc/withChartData';
import ChartModals from '@/pages/Dataset/components/DatasetChart/ChartModals';

import { EGateLabelType } from '@/types/gateSettings';
import { usePlotAxesOptionList } from '@/hooks/charts/usePlotAxesOptionList';
import styles from '../DataScatterplotChart/DataScatterplotChart.module.scss';
import { getAxesTitlesUpdatedLayout, getShapesUpdatedLayout } from '../DataScatterplotChart/helpers';

const cn = classnames.bind(styles);

type TDataScatterplotChart = {
  chartData: TDatasetDetails;
  chartColumns: number;
  isTransitionEnd: boolean;
  isSomePlotLoading?: boolean;
  disableFullScreen?: boolean;
  withoutGateLabel?: boolean;
  onPlotClickCustom?: (chartId: string) => void;
  hideGates?: boolean;
  cardClassName?: string;
  handleIsEmptyData?: (data: boolean) => void;
  customFlipBlockClassName?: string;
  cardHeaderClassName?: string;
  customOfTopContent?: ReactNode;
  onAxisChange?: (xAxis: string, yAxis: string, plotId: string) => void;
  isBlockAxesChanging?: boolean;
  isHideAxesInfo?: boolean;
  handleChangeScan?: (data: string) => void;
  onSave?: (isForAll: boolean) => void;
  plotIndex: number;
  moveChart?: (dragIndex: number, hoverIndex: number) => void;
  datasetIdsList: { scanId: string; laneId: string }[];
  canvasWebglType?: ECanvasWebglContextType;
  handleIsLoaded?: (path: string, isLoading: boolean) => void;
  scatterPlotObjectAxesOptions: Nullable<TOption[]>;
  scatterPlotCageAxesOptions: Nullable<TOption[]>;
  isUpdateGatesInProgress?: boolean;
  isCreateGatesInProgress?: boolean;
  openFullScreenModal?: (chartData: TDatasetDetails) => void;
  closeFullScreenModal?: () => void;
} & Pick<
  TGeneralChartProps,
  | 'selectedChartData'
  | 'entitiesByLanesAndGates'
  | 'entityLevelGateList'
  | 'fullGateList'
  | 'plotId'
  | 'noDataContent'
  | 'plotRangeName'
  | 'scanList'
> &
  TChartCallBacksData;

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const LIMIT_BY_COLUMN = 3;
const STATIC_CHART_SELECTOR = '.bg';

const isExpandMode = false;
const isFullScreenTransitionEnd = true;
/* TODO: FULL-SCREEN DATASET In this component, the logic from useFlipBlock was removed.
   Implementing the full - screen dataset was temporarily converted into a modal window.
   After the component with the chart has been refactored and the current chart components have been reduced to one reusable, it is necessary to return the full - screen concept via useFlipBlock
*/

const DataScatterplotChart = ({
  chartData,
  chartColumns,
  isTransitionEnd,
  selectedChartData,
  entitiesByLanesAndGates,
  entityLevelGateList,
  fullGateList = [],
  updateGate,
  createGate,
  disableFullScreen = false,
  withoutGateLabel,
  onPlotClickCustom,
  plotId,
  hideGates = false,
  noDataContent,
  cardClassName,
  handleIsEmptyData,
  customFlipBlockClassName,
  customOfTopContent,
  cardHeaderClassName,
  plotRangeName,
  isHideAxesInfo,
  onAxisChange = () => null,
  plotIndex,
  moveChart,
  datasetIdsList,
  isSomePlotLoading,
  handleIsLoaded = () => null,
  canvasWebglType = ECanvasWebglContextType.defaultWebgl,
  isUpdateGatesInProgress = false,
  isCreateGatesInProgress = false,
  openFullScreenModal = () => null,
}: TDataScatterplotChart) => {
  const { Plotly } = window;
  const { generateChartLink } = useLinks();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const contextChartId = usePlotChartIdContext();
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(contextChartId));

  const chartId = useMemo(() => `chart_${plotId ?? chartData.id}`, [chartData, plotId]);
  const chartRef = useRef<HTMLDivElement>(null);
  const scan = useSelector(experimentSelectors.selectScan(chartData.scanId));
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(contextChartId));

  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');

  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(contextChartId)(plotRangeName));
  const isTickLabelsVisible = useSelector(chartSettingsSelectors.selectIsTickLabelsVisible(contextChartId));

  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const singleChartLink = useMemo(() => generateChartLink(chartData, true), [chartData]);

  const chartLane = useSelector(experimentSelectors.selectLane(chartData.scanId, chartData.laneId));

  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(contextChartId)
  );

  const plotAxesOptionList = usePlotAxesOptionList(chartData);

  const xAxisStored = useSelector(scatterplotsSelectors.selectXAxisByChartData(chartData.id));
  const [xAxis, setXAxis] = useState(xAxisStored);
  const xAxisLabel = useMemo(() => getOption(plotAxesOptionList, xAxis)?.label ?? '', [plotAxesOptionList, xAxis]);

  const yAxisStored = useSelector(scatterplotsSelectors.selectYAxisByChartData(chartData.id));
  const [yAxis, setYAxis] = useState(yAxisStored);
  const yAxisLabel = useMemo(() => getOption(plotAxesOptionList, yAxis)?.label ?? '', [plotAxesOptionList, yAxis]);

  const [isGateUpdateModalOpened, setIsGateUpdateModalOpened] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePlotlyResize = () => {
    Plotly.Plots.resize(chartId).then(() => defineGatesLayoutParameters());
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAGGABLE_TYPES.matrixCard,
      canDrag: true,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: {
        id: plotId,
        index: plotIndex,
      },
    }),
    [plotIndex, plotId, isExpandMode, isFullScreenTransitionEnd]
  );

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: unknown }>(
    () => ({
      accept: DRAGGABLE_TYPES.matrixCard,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem) {
        if (!chartRef.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = plotIndex;

        if (dragIndex === hoverIndex) {
          return;
        }

        item.index = hoverIndex;
        moveChart?.(dragIndex, hoverIndex);
      },
    }),
    [plotIndex]
  );

  const isHistogram = useMemo(() => isHistogramChartType(currentChartType), [currentChartType]);

  const isChangeAxesAllowed = useMemo(() => isExpandMode && !isHideAxesInfo, [isExpandMode, isHideAxesInfo]);

  const {
    data: chartEntityList,
    isError: isChartEntityListError,
    error: chartEntityListError,
  } = useSelector(chartDataSelectors.selectChartEntityListData(chartLane?.dataset.path));

  const getAxisTitleOnPlotly = useCallback(
    (axisLabel: string, isYAxis = false) => {
      if (isYAxis && isHistogram) {
        return getHistogramsYAxisLabel(currentHistogramDataGroupType);
      }
      if (!isChangeAxesAllowed && axisLabel) {
        return axisLabel;
      }
      return null;
    },
    [isChangeAxesAllowed, isHistogram, currentHistogramDataGroupType]
  );

  const { isPlotLoaded, isAllPlotDataLoaded, isEmptyData, isShowLoader } = useChartSideEffects({
    graphRef,
    currentAppLane: chartLane,
    entitiesByLanesAndGates,
    setOpenedAxisSettingsName: () => null,
    selectedTool: null,
    setSelectedTool: () => null,
    isTransitionEnd,
    entityLevelGateList,
    plotRangeName,
    customXAxis: xAxis,
    customYAxis: yAxis,
    isStatic: !isExpandMode,
    xAxisLabel: getAxisTitleOnPlotly(xAxisLabel) ?? '',
    yAxisLabel: getAxisTitleOnPlotly(yAxisLabel, true) ?? '',
    pageType: EPageWithChartType.matrixView,
    handleIsLoaded,
  });

  usePlotSettings({
    graphRef,
    isPlotLoaded,
    customXAxis: xAxis,
    customYAxis: yAxis,
    plotRangeName,
    pageType: EPageWithChartType.matrixView,
  });

  useScatterglPlotsSettings({ graphRef, isPlotLoaded });

  const isAllDataLoaded = useMemo(
    () => isPlotLoaded && isAllPlotDataLoaded && isFullScreenTransitionEnd && isTransitionEnd,
    [isPlotLoaded, isAllPlotDataLoaded, isFullScreenTransitionEnd, isTransitionEnd]
  );

  const isMinimized = useMemo(() => chartColumns >= LIMIT_BY_COLUMN, [chartColumns]);

  useHistogramPlotsSettings({
    graphRef,
    defaultChartConfigs: { isTickLabelsVisible },
    isPlotLoaded,
    customXAxis: xAxis,
    datasetName: chartData?.dataset.name,
    pageType: EPageWithChartType.matrixView,
  });

  useEffect(() => {
    // This is triggered when yAxisStored is changed (the setting applies to all datasets)
    const opt = getOption(plotAxesOptionList, xAxisStored, isEqualAxes);

    const xAxisInOptionList = getOption(plotAxesOptionList, xAxis, isEqualAxes);

    if (isExpandMode && xAxisInOptionList && isBasicOption(xAxisInOptionList)) return;

    if (opt && isBasicOption(opt)) {
      setXAxis(opt.value as string);
      return;
    }

    appDispatch(
      scatterplotsActions.setAxes({
        chartDataId: chartData.id,
        newAxes: { x: xAxis },
      })
    );
  }, [xAxisStored, plotAxesOptionList]);

  useEffect(() => {
    // This is triggered when yAxisStored is changed (the setting applies to all datasets)
    const opt = getOption(plotAxesOptionList, yAxisStored, isEqualAxes);

    const yAxisInOptionList = getOption(plotAxesOptionList, yAxis, isEqualAxes);

    if (isExpandMode && yAxisInOptionList && isBasicOption(yAxisInOptionList)) return;

    if (opt && isBasicOption(opt)) {
      setYAxis(yAxisStored);
      return;
    }

    appDispatch(
      scatterplotsActions.setAxes({
        chartDataId: chartData.id,
        newAxes: { y: yAxis },
      })
    );
  }, [yAxisStored, plotAxesOptionList]);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
      appDispatch(scatterplotsActions.removeMatrixPlotId(chartId));
    };
  }, []);

  useEffect(() => {
    appDispatch(
      scatterplotsActions.addMatrixPlotId({
        index: plotIndex,
        plotId: chartId,
        xAxisLabel,
        yAxisLabel: getAxisTitleOnPlotly(yAxisLabel, true) ?? '',
        headerInfo: {
          datasetName: chartData.friendlyName,
          sampleName: chartLane?.sampleFriendlyName ?? '',
          scanTime: scan?.time ?? '',
        },
      })
    );
  }, [
    plotIndex,
    chartId,
    xAxisLabel,
    yAxisLabel,
    scan?.name,
    scan?.time,
    chartLane?.sampleFriendlyName,
    chartData.friendlyName,
    isHistogram,
  ]);

  const entitiesDataByGates = useMemo(
    () => chartLane && entitiesByLanesAndGates[chartLane.path],
    [chartLane, entitiesByLanesAndGates]
  );

  const filteredEntityLevelGateList = useMemo(
    () =>
      entityLevelGateList?.filter((gate: TGate) =>
        gate.properties?.processType
          ? gate?.laneId === selectedChartData?.laneId && gate?.scanId === selectedChartData?.scanId
          : true
      ) ?? [],
    [entityLevelGateList, selectedChartData]
  );

  const handlePlotlySelected = () => {
    setIsModalOpen(true);
  };

  const { newGateModel, clearNewGateData, mouseUpEventRef, mouseDownEventRef, defineGatesLayoutParameters } =
    useGatesOnPlot({
      graphRef,
      scanId: chartData.scanId,
      laneId: chartData.laneId,
      selectedTool: null,
      entitiesDataByGates,
      plotId: chartId,
      isStatic: !isExpandMode,
      chartClassName: STATIC_CHART_SELECTOR,
      isAllPlotDataLoaded: isAllDataLoaded,
      displayType: isMinimized ? EGateLabelType.minimized : EGateLabelType.default,
      entityLevelGateList: filteredEntityLevelGateList,
      isObjectEntityEnabled,
      gates: fullGateList,
      updateGate,
      createGate,
      handlePlotlySelected,
      chartXAxis: xAxis,
      chartYAxis: yAxis,
      withoutGateLabel,
      hideGates,
      isEmptyData,
      defaultPlotRange: plotRange,
      isHoverInfoOpen: false,
    });

  const handleCloseModal = useCallback(() => {
    setIsGateUpdateModalOpened(false);
    setIsModalOpen(false);
    clearNewGateData();
  }, [appDispatch, newGateModel]);

  const handleCardClick = () => {
    if ((!isAllDataLoaded && !isChartEntityListError) || isShowLoader) return;

    if (onPlotClickCustom) {
      onPlotClickCustom(chartData.id);
    } else if (!isExpandMode && !disableFullScreen) {
      openFullScreenModal(chartData);
    }
  };

  const cageDataList = useMemo(() => [{ cageList: chartEntityList ?? [] }], [chartEntityList]);

  usePlotGateAndAxesUpdate({
    cageDataList,
    datasetIdsList,
    graphRef,
    plotRangeName,
    isAllPlotDataLoaded,
    entityLevelGateList,
    customXAxis: xAxis,
    customYAxis: yAxis,
    specificDatasetId: chartData.id,
    pageType: EPageWithChartType.matrixView,
    chartDataList: [chartData],
  });

  useEffect(() => {
    if (!graphRef.current?.layout) {
      return;
    }
    const updatedLayout = getShapesUpdatedLayout(graphRef.current.layout, isMinimized);
    const updatedLayoutConfig = getAxesTitlesUpdatedLayout(
      getAxisTitleOnPlotly(xAxisLabel),
      getAxisTitleOnPlotly(yAxisLabel, true)
    );
    plotlyProxy.forceRelayout({ ...updatedLayoutConfig, ...updatedLayout }, () => {
      defineGatesLayoutParameters();
    });
  }, [getAxisTitleOnPlotly, xAxisLabel, yAxisLabel, isAllDataLoaded, isTransitionEnd, isShowLoader]);

  useEffect(() => {
    if (!handleIsEmptyData) {
      return;
    }
    handleIsEmptyData(isEmptyData);
  }, [isEmptyData]);

  useEffect(() => {
    appDispatch(chartSettingsActions.setCustomRangeName(''));
    onAxisChange(xAxis, yAxis, chartData.id);
  }, [xAxis, yAxis]);

  const opacityOnDrag = useMemo<number>(() => {
    if (isDragging) {
      return isTouchScreen() ? 0.5 : 0;
    }
    return 1;
  }, [isDragging, isTouchScreen()]);

  drag(drop(chartRef));

  const onViewSingleChartClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(singleChartLink);
  };

  return (
    <div onClick={handleCardClick} className={cn('card-wrapper')} role="presentation" data-webgl-type={canvasWebglType}>
      <ChartModals
        newGateModel={newGateModel}
        handleCloseModal={handleCloseModal}
        updateGate={updateGate}
        createGate={createGate}
        isGateUpdateModalOpened={isGateUpdateModalOpened}
        isModalOpen={isModalOpen}
        isUpdateGatesInProgress={isUpdateGatesInProgress}
        isCreateGatesInProgress={isCreateGatesInProgress}
        mouseUpEventRef={mouseUpEventRef}
        mouseDownEventRef={mouseDownEventRef}
      />
      <div
        ref={isExpandMode ? null : chartRef}
        style={{ opacity: opacityOnDrag }}
        data-handler-id={handlerId}
        className={cn(
          'card',
          {
            card_selected: selectedChartData?.id === chartData.id,
            card_minimized: isMinimized,
          },
          cardClassName
        )}
      >
        <div className={cn('card__flip-block', customFlipBlockClassName)}>
          {customOfTopContent && <div className={cn('card__custom-oftop-content')}>{customOfTopContent}</div>}
          <div className={cn('card__header', cardHeaderClassName)}>
            <h2 className={cn('header__title')} {...addTooltip(chartLane?.sampleFriendlyName ?? '')}>
              {chartLane?.sampleFriendlyName ?? ''}
            </h2>
            <span {...addTooltip(chartData.friendlyName)}>{chartData.friendlyName}</span>
            <span {...addTooltip(scan?.time)}>{scan?.timeNode}</span>
            {!isPreprocessingView && (
              <Button
                color="light"
                className={cn('card__small-button')}
                tooltip="View chart"
                onClick={onViewSingleChartClick}
              >
                <icons.ChartViewIcon />
              </Button>
            )}
          </div>
          <div className={cn('card__content')}>
            {isAllDataLoaded && isEmptyData && (
              <NoDataFound className={cn('card__no-data')} alignment="center" size="small" textData={noDataContent} />
            )}
            {isChartEntityListError && (
              <NoDataFound
                className={cn('card__no-data')}
                alignment="center"
                size="small"
                textData={chartEntityListError}
              />
            )}
            {((!isAllDataLoaded && !isChartEntityListError) || isShowLoader || isSomePlotLoading) &&
              !isChartEntityListError && <Skeleton className={cn('card__skeleton')} />}
            <div ref={graphRef} className={cn('card__chart')} id={chartId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(withChartData(DataScatterplotChart));
