import { MutableRefObject } from 'react';
import { select as d3Select } from 'd3-selection';

import { downloadCanvas } from '@/components/charts/DownloadChartButton/helpers/pngImage';
import { prepareMatrixLayout } from '@/components/charts/DownloadChartButton/prepareLayouts';

import { fittingString } from '@/helpers';
import { drawGateOnCanvas } from '@/helpers/screenShotUtils';
import { TOffset } from '@/helpers/screenShotUtils/types';

import getCurrentGatesLayout from '@/pages/Dataset/components/DatasetChart/helpers/getCurrentGatesLayout';
import { TGateLayout } from '@/pages/Dataset/components/DatasetChart/types';

import { THeaderInfo } from '@/store/slices/scatterplots';
import { TSelectionDivElement } from '../../gates/types';
import { headerPadding, sampleFont, noDataText, baseFont, plotsPadding } from '../constants';
import { TDownloadMatrixViewHandlerPayload } from './types';

const headerMaxWidthFn = (plotWidth: number) => plotWidth / 3 - headerPadding;

export const drawLabelsOnPNG = (
  ctx: CanvasRenderingContext2D,
  options: {
    sx: number;
    sy: number;
    isEmpty?: boolean;
    headerInfo: THeaderInfo;
    layoutMargin: {
      t: number;
    };
    plotSize: MutableRefObject<{ width: number; height: number }>;
  }
) => {
  const { sx, sy, isEmpty, headerInfo, layoutMargin, plotSize } = options;
  const { width: plotWidth, height: plotHeight } = plotSize.current;
  const headerMaxWidth = headerMaxWidthFn(plotWidth);

  if (isEmpty) {
    ctx.save();
    ctx.fillStyle = 'white';
    ctx.fillRect(sx, sy, plotWidth, plotHeight);
    ctx.restore();

    ctx.save();
    ctx.fillStyle = 'black';
    ctx.textBaseline = 'middle';
    ctx.font = sampleFont;

    const { width: textWidth } = ctx.measureText(noDataText);
    const measureText = ctx.measureText(noDataText);

    ctx.fillText(
      noDataText,
      sx + plotWidth / 2 - textWidth / 2,
      sy + plotHeight / 2 - measureText.hangingBaseline,
      headerMaxWidth
    );

    ctx.restore();
  }

  ctx.save();
  ctx.fillStyle = 'white';
  ctx.fillRect(sx, sy, plotWidth, layoutMargin.t * 0.8);
  ctx.restore();

  ctx.save();
  ctx.fillStyle = 'black';
  ctx.textBaseline = 'middle';
  ctx.font = sampleFont;

  const sampleName = fittingString(ctx, headerInfo.sampleName, headerMaxWidth);
  ctx.fillText(sampleName, sx + headerPadding, sy + layoutMargin.t / 2, headerMaxWidth);

  ctx.font = baseFont;

  const datasetName = fittingString(ctx, headerInfo.datasetName, headerMaxWidth);
  ctx.fillText(datasetName, sx + headerPadding * 2 + headerMaxWidth, sy + layoutMargin.t / 2, headerMaxWidth);

  const scanInfo = fittingString(ctx, headerInfo.scanTime, headerMaxWidth);
  ctx.fillText(scanInfo, sx + headerPadding * 3 + headerMaxWidth * 2, sy + layoutMargin.t / 2, headerMaxWidth);

  ctx.restore();
};

export const downloadMatrixViewAsPNG = async ({
  plotlyImages,
  imageWidth,
  imageHeight,
  columnsCount,
  plotWidth,
  plotHeight,
  graphBlocks,
  graphInfoObj,
  labelElement,
  plotSize,
  fullFileName,
}: TDownloadMatrixViewHandlerPayload) => {
  const canvas = document.createElement('canvas');
  canvas.width = imageWidth;
  canvas.height = imageHeight;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  await Promise.all(
    plotlyImages.map(async (plotlyImage, i) => {
      const onLoadPromise = new Promise((res, rej) => {
        const image = new Image();
        image.onload = () => res(image);
        image.onerror = rej;
        image.src = plotlyImage;
      });

      const paddingX = plotsPadding * Number(i % columnsCount);
      const paddingY = plotsPadding * Number(i / columnsCount >= 1);

      const rowIndex = Math.floor(i / columnsCount);

      const dx = plotWidth * (i % columnsCount) + paddingX;
      const dy = Math.floor(i / columnsCount) * plotHeight + paddingY * rowIndex;

      const imagePromise = await onLoadPromise;
      ctx.clearRect(dx, dy, plotWidth, plotHeight);
      ctx.drawImage(imagePromise as HTMLImageElement, dx, dy, plotWidth, plotHeight);
    })
  );

  graphBlocks.forEach((graphBlock, i) => {
    const { xAxisLabel, yAxisLabel, headerInfo } = graphInfoObj[graphBlock.id];
    const { layout } = prepareMatrixLayout(graphBlock, xAxisLabel, yAxisLabel);
    const overflowContainer: TSelectionDivElement = d3Select(`#${graphBlock.id} .overflow-container`);
    const paddingX = plotsPadding * Number(i % columnsCount);
    const paddingY = plotsPadding * Number(i / columnsCount >= 1);

    const sx = plotWidth * (i % columnsCount) + paddingX;
    const sy = Math.floor(i / columnsCount) * plotHeight + paddingY;

    const gatesLayout = getCurrentGatesLayout(overflowContainer);
    if (!gatesLayout) {
      drawLabelsOnPNG(ctx, { headerInfo, layoutMargin: layout.margin, sx, sy, isEmpty: true, plotSize });
      return;
    }

    const leftBlock = ctx.getImageData(sx, sy, layout.margin.l, plotHeight);
    const bottomBlock = ctx.getImageData(sx, sy + plotHeight - layout.margin.b, plotWidth, layout.margin.b);

    const renderPlotHeight = plotHeight - (layout.margin.t + layout.margin.b);
    const renderPlotWidth = plotWidth - layout.margin.l;

    const offset: TOffset = {
      sx: sx + layout.margin.l,
      sy: sy + layout.margin.t,
    };

    gatesLayout.forEach((gate: Nullable<TGateLayout>) => {
      drawGateOnCanvas({
        ctx,
        gate,
        imageHeight: renderPlotHeight,
        imageWidth: renderPlotWidth,
        offset,
        labelElement,
      });

      ctx.putImageData(leftBlock, sx, sy);
      ctx.putImageData(bottomBlock, sx, sy + plotHeight - layout.margin.b);
    });

    drawLabelsOnPNG(ctx, { headerInfo, layoutMargin: layout.margin, sx, sy, plotSize });
  });

  downloadCanvas(ctx.canvas, fullFileName);
};
