import { includes } from '@/helpers/enum';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import copyState from './helpers/copyState';
import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import orderReducer from './orderReducer';
import incubationReducer from './incubationReducer';
import washReducer from './washReducer';
import scanReducer from './scanReducer';
import {
  EIncubationActionList,
  EMediaActionList,
  EOrderActionList,
  EScanActionList,
  EWashActionList,
  TEditReducerAction,
  TIncubationReducerAction,
  TMediaReducerAction,
  TOrderReducerAction,
  TScanReducerAction,
  TWashReducerAction,
} from './types';
import mediaReducer from './mediaReducer';

const isOrderAction = (action: TEditReducerAction): action is TOrderReducerAction =>
  includes(Object.values(EOrderActionList), action.type);

const isIncubationAction = (action: TEditReducerAction): action is TIncubationReducerAction =>
  includes(Object.values(EIncubationActionList), action.type);

const isWashAction = (action: TEditReducerAction): action is TWashReducerAction =>
  includes(Object.values(EWashActionList), action.type);

const isScanAction = (action: TEditReducerAction): action is TScanReducerAction =>
  includes(Object.values(EScanActionList), action.type);

const isMediaAction = (action: TMediaReducerAction): action is TMediaReducerAction =>
  includes(Object.values(EMediaActionList), action.type);

export const editReducer = (
  state: Nullable<TRunDesignComponent[]>,
  action: TEditReducerAction
): TRunDesignComponent[] => {
  if (action.type === 'changeComponentList') {
    return action.payload;
  }

  // next actions need existing state
  if (!state) {
    throw new Error('Trying edit non existing state.');
  }

  if (action.type === 'updatePauseAfterCompletion') {
    const currentComponentIndex = getCurrentComponentIndex(state, action.payload.id);
    const newState = copyState(state);
    newState[currentComponentIndex].pauseAfterCompletion = action.payload.value;

    return newState;
  }

  if (isWashAction(action)) {
    return washReducer(state, action);
  }

  if (isIncubationAction(action)) {
    return incubationReducer(state, action);
  }

  if (isScanAction(action)) {
    return scanReducer(state, action);
  }

  if (isOrderAction(action)) {
    return orderReducer(state, action);
  }

  if (isMediaAction(action)) {
    return mediaReducer(state, action);
  }

  throw new Error('Unknown edit action.');
};
