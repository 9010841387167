import { FC, useLayoutEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useUserRole } from '@/hooks';
import { EExperimentTabId, useLinks } from '@/hooks/useLinks';

import JobRunList from '@/components/jobs/JobRunList';

import styles from './JobsTab.module.scss';

const cn = classnames.bind(styles);

const JobsTab: FC = () => {
  const navigate = useNavigate();

  const { isPermissionsRead, isJobRunReadAllowed } = useUserRole();
  const { generateExperimentLink } = useLinks();

  useLayoutEffect(() => {
    if (!isPermissionsRead) {
      return;
    }

    if (!isJobRunReadAllowed) {
      navigate(generateExperimentLink(EExperimentTabId.data));
    }
  }, [isPermissionsRead, isJobRunReadAllowed]);

  return <JobRunList hideDashboardHeader className={cn('jobs-dashboard')} />;
};

export default memo(JobsTab);
