import { isComponentWithIncubation } from '@/helpers/runDesigns/typeGuards';

import { TemperatureUnit } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import copyState from './helpers/copyState';
import getInitialIncubation from './helpers/getInitialIncubation';
import updateTimeValue from './helpers/updateTimeValue';
import { EIncubationActionList, TIncubationReducerAction, TTimeChangePayload } from './types';

const updateTime = (state: TRunDesignComponent[], placementIndex: number, payload: TTimeChangePayload) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithIncubation(currentComponent)) {
    return newState;
  }

  currentComponent.incubation ??= getInitialIncubation();
  const { incubation } = currentComponent;

  if (!incubation) {
    return state;
  }

  currentComponent.incubation = {
    ...incubation,
    duration: updateTimeValue(incubation.duration, payload),
  };

  return newState;
};

const updateTemperature = (state: TRunDesignComponent[], placementIndex: number, temperature: number) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithIncubation(currentComponent)) {
    return newState;
  }

  currentComponent.incubation ??= getInitialIncubation();
  const { incubation } = currentComponent;

  if (!incubation) {
    return state;
  }

  currentComponent.incubation = {
    ...incubation,
    temperature,
  };

  return newState;
};

const updateTemperatureUnit = (
  state: TRunDesignComponent[],
  placementIndex: number,
  temperatureUnit: TemperatureUnit
) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithIncubation(currentComponent)) {
    return newState;
  }

  currentComponent.incubation ??= getInitialIncubation();
  const { incubation } = currentComponent;

  if (!incubation) {
    return state;
  }

  currentComponent.incubation = {
    ...incubation,
    temperatureUnit,
  };

  return newState;
};

const updateLocationOnDevice = (state: TRunDesignComponent[], placementIndex: number, onDevice: boolean) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithIncubation(currentComponent)) {
    return newState;
  }

  currentComponent.incubation ??= getInitialIncubation();
  const { incubation } = currentComponent;

  if (!incubation) {
    return state;
  }

  currentComponent.incubation = {
    ...incubation,
    onDevice,
  };

  return newState;
};

const incubationReducer = (state: TRunDesignComponent[], action: TIncubationReducerAction) => {
  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EIncubationActionList.updateTime) {
    return updateTime(state, placementIndex, action.payload);
  }

  if (action.type === EIncubationActionList.updateTemperature) {
    return updateTemperature(state, placementIndex, action.payload.value);
  }

  if (action.type === EIncubationActionList.updateTemperatureUnit) {
    return updateTemperatureUnit(state, placementIndex, action.payload.value);
  }

  if (action.type === EIncubationActionList.updateLocationOnDevice) {
    return updateLocationOnDevice(state, placementIndex, action.payload.value);
  }

  throw new Error('Unknown edit incubation action.');
};

export default incubationReducer;
