import { SVGProps } from 'react';

const CellKillingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" viewBox="0 0 48 48" fill="none" {...props}>
    <path
      d="M23.5 3C11.6259 3 2 12.6259 2 24.5C2 36.3741 11.6259 46 23.5 46C35.3741 46 45 36.3741 45 24.5"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <ellipse
      cx="19"
      cy="28"
      rx="8"
      ry="8"
      transform="rotate(-90 19 28)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path d="M46 2L30 18" stroke="currentColor" strokeWidth="4" />
    <path d="M30 2L46 18" stroke="currentColor" strokeWidth="4" />
  </svg>
);

export default CellKillingIcon;
