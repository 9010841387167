import { FC, useState, ChangeEvent, useEffect, memo } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { createTeamSchema } from '@/validationSchemas';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { authAPI } from '@/store/services/auth';

import Modal from '@/components/common/Modal';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import Textarea from '@/components/common/Textarea';

import styles from './AdminModals.module.scss';
import type { TAdminModalProps, TErrorsMapGeneric } from './types';

const cn = classnames.bind(styles);

const validationFields = {
  teamName: 'email',
} as const;

type TErrorsMap = TErrorsMapGeneric<typeof validationFields>;

const CreateTeamModal: FC<TAdminModalProps> = ({ closeModal, isModalOpen }) => {
  const [createTeam, { isLoading: isCreateTeamInProgress }] = authAPI.useCreateTeamMutation();

  const [teamName, setTeamName] = useState('');
  const [teamDescription, setTeamDescription] = useState('');
  const [errors, setErrors] = useState<TErrorsMap>({});

  const handleChangeTeamName = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamName(event.target.value);
    setErrors((prev) => {
      delete prev.teamName;
      return prev;
    });
  };

  const handleChangeTeamDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTeamDescription(event.target.value);
  };

  const handleFormSubmit = () => {
    const validate = createTeamSchema.safeParse({ teamName });
    if (!validate.success) {
      setErrors(
        validate.error.issues.reduce((acc: TErrorsMap, { message, path }) => {
          acc[path[0].toString() as keyof typeof validationFields] = message;
          return acc;
        }, {})
      );
      return;
    }

    createTeam({
      name: teamName.trim(),
      description: teamDescription,
    })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  useEffect(() => {
    setTeamName('');
    setTeamDescription('');
    setErrors({});
  }, [isModalOpen]);

  useEffect(() => {
    Object.values(errors).forEach((message) => {
      toast.error(message);
    });
  }, [errors]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      onFormSubmit={handleFormSubmit}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>New team</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={teamName}
            label={
              <>
                <span>Name</span>
                <span className={cn('required')}>*</span>
              </>
            }
            labelClassName={cn('modal__input-label', { 'modal__input-label_error': errors.teamName })}
            onChange={handleChangeTeamName}
            isBgLight
            id="create-team__name-input"
            errorMessage={errors.teamName}
          />
        </div>
        <div className={cn('modal__row')}>
          <span className={cn('modal__input-label', 'select__label')}>Description</span>
          <Textarea
            className={cn('modal__text-area')}
            value={teamDescription}
            onChange={handleChangeTeamDescription}
            id="create-team__description-input"
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="create-team__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="create-team__begin"
          color="yellow"
          className={cn('modal__button')}
          isLoading={isCreateTeamInProgress}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CreateTeamModal);
