import { ChangeEvent, FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useUserRole, useFetchNextToken } from '@/hooks';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { authAPI } from '@/store/services/auth';
import { headerActions } from '@/store/slices/header';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import TeamUsersModal from '@/components/admin/modals/TeamUsersModal';
import CreateTeamModal from '@/components/admin/modals/CreateTeamModal';
import AddProjectToTeamModal from '@/components/admin/modals/AddProjectToTeamModal';
import InviteUserToTeamModal from '@/components/admin/modals/InviteUserToTeamModal';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import PageHeader from '@/components/Layout/Page/PageHeader';
import ControlPanel from '@/components/Layout/ControlPanel';
import SearchInput from '@/components/common/SearchInput';
import { TAction, TStandardField } from '@/components/common/StandardTable/types';
import StandardTable from '@/components/common/StandardTable';
import Button from '@/components/common/Button';
import { useOpenModal } from '@/hooks/useOpenModal';
import icons from '@/components/common/icons';

import styles from './TeamsDashboard.module.scss';

const cn = classnames.bind(styles);

const TEAM_TABLE_FIELD_LIST = [
  { name: 'name', label: 'Name', isSearchable: true, isSortable: true },
  { name: 'description', label: 'Description', isSearchable: true, isSortable: true },
] as TStandardField<TTeam>[];

const TeamsDashboard: FC = () => {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirmationModal = useConfirmationModalContext();
  const { isPermissionsRead, isTeamReadAllowed, isTeamCreateAllowed, isTeamDeleteAllowed, isTeamUpdateAllowed } =
    useUserRole();

  useEffect(() => {
    if (!isPermissionsRead) {
      return;
    }

    if (!isTeamReadAllowed) {
      navigate('/');
    } else {
      appDispatch(headerActions.removeAllLinks());
    }
  }, [isPermissionsRead, isTeamReadAllowed]);

  const [deleteTeam] = authAPI.useDeleteTeamMutation();

  const queryData = useMemo(() => ({}), []);

  const {
    count: teamCount,
    error,
    isError,
    isListLoading: isLoading,
    isListLoadingDebounced: isTeamListLoadingDebounced,
    list: teamList,
  } = useFetchNextToken<TTeam>({
    useFetch: authAPI.useFetchTeamListQuery,
    select: authAPI.endpoints.fetchTeamList.select,
    queryData,
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [teamToInvite, setTeamToInvite] = useState<Nullable<TTeam>>(null);
  const [teamToShow, setTeamToShow] = useState<Nullable<TTeam>>(null);
  const [teamForAddProject, setTeamForAddProject] = useState<Nullable<TTeam>>(null);

  const {
    isOpen: isCreateTeamModalOpen,
    setIsOpen: setIsCreateTeamModalOpen,
    onClose: handleCloseCreateTeamModal,
  } = useOpenModal();

  const {
    isOpen: isInviteToTeamModalOpen,
    setIsOpen: setIsInviteToTeamModalOpen,
    onClose: handleCloseInviteToTeamModal,
  } = useOpenModal();

  const {
    isOpen: isAddProjectToTeamModalOpen,
    setIsOpen: setIsAddProjectToTeamModalOpen,
    onClose: handleCloseAddProjectToTeamModal,
  } = useOpenModal();

  const {
    isOpen: isTeamUsersModalOpen,
    setIsOpen: setIsTeamUsersModalOpen,
    onClose: handleCloseTeamUsersModal,
  } = useOpenModal();

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  const handleCreateTeamClick = () => {
    setIsCreateTeamModalOpen(true);
  };

  const teamActionList = useMemo<TAction<TTeam>[]>(() => {
    const actions: TAction<TTeam>[] = [];

    if (isTeamUpdateAllowed) {
      actions.push({
        handler: (teamData) => {
          setTeamToInvite(teamData);
          setIsInviteToTeamModalOpen(true);
        },
        label: () => 'Add user to team',
        icon: <icons.PlusIcon />,
      });
      actions.push({
        handler: (teamData) => {
          setTeamForAddProject(teamData);
          setIsAddProjectToTeamModalOpen(true);
        },
        label: () => 'Add project to team',
        icon: <icons.PlusIcon />,
      });
    }
    if (isTeamDeleteAllowed) {
      actions.push({
        handler: async (teamData) => {
          const isConfirmed = await confirmationModal.onOpen({
            confirmationText: `Are you sure you want to delete the team "${teamData.name}"?`,
            approveButtonText: 'Yes',
            cancelButtonText: 'Cancel',
          });
          if (!isConfirmed) {
            return;
          }
          deleteTeam({ id: teamData.id })
            .unwrap()
            .catch((err) => showErrorToast(getErrorMessage(err)));
        },
        label: () => 'Remove this team',
        icon: <icons.DeleteIcon />,
      });
    }

    return actions;
  }, [isTeamUpdateAllowed]);

  const handleRowClick = useCallback((teamData: TTeam) => {
    setTeamToShow(teamData);
    setIsTeamUsersModalOpen(true);
  }, []);

  const onCloseTeamUsersModal = useCallback(() => {
    handleCloseTeamUsersModal();
    setTeamToShow(null);
  }, [handleCloseTeamUsersModal]);

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content')}>
        <PageHeader>
          <PageHeader.Section>
            <PageHeader.Title as="h1">Teams Dashboard</PageHeader.Title>
          </PageHeader.Section>
          <PageHeader.Section>
            <PageHeader.Title isStatistics>{teamCount}</PageHeader.Title>
            <PageHeader.Subtitle>{teamCount === 1 ? 'Team' : 'Teams'}</PageHeader.Subtitle>
          </PageHeader.Section>
          {isTeamCreateAllowed && (
            <PageHeader.Section className={cn('header-actions')}>
              <Button className={cn('header-action')} onClick={handleCreateTeamClick}>
                Create new team
              </Button>
            </PageHeader.Section>
          )}
        </PageHeader>

        <ControlPanel isLoading={isTeamListLoadingDebounced}>
          <ControlPanel.RightActions>
            <SearchInput
              onChange={handleSearchInputChange}
              onReset={handleResetSearchClick}
              value={searchQuery}
              disabled={teamCount === 0}
            />
          </ControlPanel.RightActions>
        </ControlPanel>

        <StandardTable<TTeam>
          dataList={teamList}
          fieldList={TEAM_TABLE_FIELD_LIST}
          keyFieldName="id"
          searchQuery={searchQuery}
          isLoading={isLoading}
          errorMessage={isError ? getErrorMessage(error) : undefined}
          actions={teamActionList}
          onRowClick={handleRowClick}
        />
      </div>
      <CreateTeamModal closeModal={handleCloseCreateTeamModal} isModalOpen={isCreateTeamModalOpen} />
      {teamToInvite && (
        <InviteUserToTeamModal
          closeModal={handleCloseInviteToTeamModal}
          isModalOpen={isInviteToTeamModalOpen}
          team={teamToInvite}
        />
      )}
      {teamForAddProject && (
        <AddProjectToTeamModal
          closeModal={handleCloseAddProjectToTeamModal}
          isModalOpen={isAddProjectToTeamModalOpen}
          team={teamForAddProject}
        />
      )}
      {teamToShow && (
        <TeamUsersModal closeModal={onCloseTeamUsersModal} isModalOpen={isTeamUsersModalOpen} team={teamToShow} />
      )}
    </div>
  );
};

export default TeamsDashboard;
