import { searchExperiments } from '@/helpers/experiments/searchExperiments';

export const getCount = (searchQuery: string, experimentList: TExperimentFromServer[] | undefined) => {
  const foundExperiments = searchExperiments(searchQuery, experimentList);
  const instruments: string | Record<string, number> = {};

  foundExperiments?.forEach((elem: TExperiment) => {
    instruments[elem.instrument.friendlyName] = instruments[elem.instrument.friendlyName]
      ? instruments[elem.instrument.friendlyName] + 1
      : 1;
  });

  return { instrumentsCount: Object.keys(instruments).length, experimentsCount: foundExperiments?.length ?? 0 };
};
