import { scaleLog } from 'd3-scale';

import { getPositiveMinMax } from '@/helpers/arrays';

import { getAxisGap } from './helpers';

export const MIN_LOG_SCALE_RANGE_POINT_VALUE = 0.0001;

const getScaleLog10 = (minValue: number, maxValue: number) => {
  const domain = [minValue, maxValue];
  const range = [minValue, maxValue];
  return scaleLog(domain, range);
};

export default {
  getPlotLayoutTypePart() {
    return {
      type: 'log',
      exponentformat: 'power',
    };
  },

  getPlotLayoutTicksPart(_plotAxisRange: number[], _axisOrigDataRange?: number[]) {
    return {};
  },

  preparePlotData(data: number[], _axisOrigDataRange?: number[]) {
    return data;
  },

  getPlotAxisRange(data: number[], withGap: boolean) {
    const { min: minVal, max: maxVal } = getPositiveMinMax(data);
    const minLogVal = Math.log10(minVal > 0 ? minVal : MIN_LOG_SCALE_RANGE_POINT_VALUE);
    const maxLogVal = Math.log10(maxVal > 0 ? maxVal : MIN_LOG_SCALE_RANGE_POINT_VALUE);
    const gap = withGap ? getAxisGap(maxLogVal - minLogVal) : 0;
    return [minLogVal - gap, maxLogVal + gap];
  },

  // TODO: fix the position of the gate when the current zoom goes beyond the drawn gate
  convertToLinearPlotRange(logRange: Nullable<[number, number]>) {
    if (logRange) {
      let minValue = 10 ** logRange[0];
      let maxValue = 10 ** logRange[1];

      minValue = minValue || Math.sign(maxValue) * MIN_LOG_SCALE_RANGE_POINT_VALUE;
      maxValue = maxValue || Math.sign(minValue) * MIN_LOG_SCALE_RANGE_POINT_VALUE;

      return [minValue, maxValue];
    }
    return null;
  },

  getScaledGatePoint({
    point,
    axisLinearRange,
    axisOrigDataRange: _,
  }: {
    point: number;
    axisLinearRange: Nullable<[number, number]>;
    axisOrigDataRange?: number[];
  }) {
    if (!axisLinearRange) {
      return point;
    }
    const scaleLog10 = getScaleLog10(axisLinearRange[0], axisLinearRange[1]);
    return point > 0 ? scaleLog10(point) : MIN_LOG_SCALE_RANGE_POINT_VALUE;
  },

  getRealGatePoint({
    point,
    axisLinearRange,
    axisOrigDataRange: _,
  }: {
    point: number;
    axisLinearRange: Nullable<[number, number]>;
    axisOrigDataRange?: number[];
  }) {
    if (!axisLinearRange) {
      return point;
    }
    const scaleLog10 = getScaleLog10(axisLinearRange[0], axisLinearRange[1]);
    return scaleLog10.invert(point);
  },

  convertPlotValueToReal(axisValue: number, _axisOrigDataRange?: number[]) {
    return axisValue;
  },

  // todo: allow to draw circle gates in log scale
  isCircleGateAllowed() {
    return false;
  },

  isNegativeValuesSupported() {
    return false;
  },
};
