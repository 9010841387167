export function fittingString(c: CanvasRenderingContext2D, str: string, maxWidth: number) {
  let resultStr = str;
  let { width } = c.measureText(resultStr);
  const ellipsis = '…';
  const ellipsisWidth = c.measureText(ellipsis).width;
  if (width <= maxWidth || width <= ellipsisWidth) {
    return resultStr;
  }

  let len = resultStr.length;
  while (width >= maxWidth - ellipsisWidth && len-- > 0) {
    resultStr = resultStr.substring(0, len);
    width = c.measureText(resultStr).width;
  }

  return resultStr + ellipsis;
}
