import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import AnnotationSwitch from '@/components/runDesign/AnnotationSwitch';
import LanesTable from '@/components/runDesign/LanesTable';

import styles from './LanesBlock.module.scss';
import SampleNamesSwitch from '../SampleNamesSwitch';

const cn = classnames.bind(styles);

type TLanesBlock = {
  isEditAllowed?: boolean;
  hideAnnotationSwitch?: boolean;
  hideCellTypes?: boolean;
  hideSampleNameGenerateSwitch?: boolean;
  isActivationAllowed?: boolean;
  className?: string;
};

const LanesBlock: FC<TLanesBlock> = ({
  isEditAllowed = false,
  hideAnnotationSwitch = false,
  hideCellTypes = false,
  isActivationAllowed = false,
  hideSampleNameGenerateSwitch,
  className,
}) => {
  const someRunDesignCardIsExpand = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsExpand);
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  return (
    <div className={cn('lanes-block', className)}>
      <div className={cn('switch-container')}>
        {!hideAnnotationSwitch && !someRunDesignCardIsExpand && (
          <AnnotationSwitch className={cn('switch-container__item')} />
        )}
        {someRunDesignCardIsExpand ||
          (!hideSampleNameGenerateSwitch && <SampleNamesSwitch className={cn('switch-container__item')} />)}
      </div>
      <LanesTable
        isEditAllowed={isEditAllowed}
        isLocked={someRunDesignCardIsEdit}
        hideCellTypes={hideCellTypes || someRunDesignCardIsExpand}
        hideSampleName={someRunDesignCardIsExpand}
        isActivationAllowed={isActivationAllowed}
      />
    </div>
  );
};

export default LanesBlock;
