import { FC } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import awsconfig from '@/aws-exports';

import { generateJwtToken } from '@/helpers';

import InstrumentsDashboard from './InstrumentsDashboard';

const appSyncConfig = {
  url: process.env.REACT_APP_APOLLO_GRAPHOS_API_URL ?? '',
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS | AUTH_TYPE.OPENID_CONNECT,
    jwtToken: generateJwtToken,
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([createAuthLink(appSyncConfig), createSubscriptionHandshakeLink(appSyncConfig)]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

const InstrumentsDashboardWrapper: FC = () => (
  <ApolloProvider client={client}>
    <InstrumentsDashboard />
  </ApolloProvider>
);

export default InstrumentsDashboardWrapper;
