import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TBaseGateSettings, TInitialGatesState } from '@/store/slices/gates/types';
import { EGatesLabelFormat } from '@/types/gateSettings';
import { chartSettingsActions } from '../chartSettings';
import { getStateObject } from './helpers';

const initialState: TInitialGatesState = {
  isUngatedSelected: true,
  isGatesDrawn: true,
  activeGate: null,
  selectedGate: null,
  isCustomGateRangeActive: false,
  isBlockDraw: false,
  gateLabelFormat: EGatesLabelFormat.nameAndPercent,
  specificDatasetOptionMap: {},
  onlyGateToDisplay: null,
};

const gatesSlice = createSlice({
  name: 'gates',
  initialState,
  reducers: {
    toggleIsUngatedSelected: (state) => {
      const newValue = !state.isUngatedSelected;
      if (newValue) {
        state.selectedGate = null;
        state.isGatesDrawn = newValue;
      } else if (!state.selectedGate) {
        state.isGatesDrawn = !state.isGatesDrawn;
      }

      state.isUngatedSelected = newValue;
    },
    setIsGateDrawn: (state, action) => {
      state.isGatesDrawn = action.payload;
    },
    setSelectedGate: (state, action) => {
      state.selectedGate = action.payload;
      state.isUngatedSelected = action.payload === null;
      state.isCustomGateRangeActive = false;
    },
    setActiveGate: (state, action) => {
      state.activeGate = action.payload;
    },
    setIsCustomGateRangeActive: (state, action) => {
      state.isCustomGateRangeActive = action.payload;
    },
    setIsBlockDraw: (state, action: PayloadAction<boolean>) => {
      state.isBlockDraw = action.payload;
    },
    setGateLabelFormat: (
      sliceState,
      action: PayloadAction<{ format: EGatesLabelFormat; fullScreenChartDataId?: string }>
    ) => {
      const { format, fullScreenChartDataId } = action.payload;
      const state = getStateObject(sliceState, fullScreenChartDataId);

      state.gateLabelFormat = format;
    },
    setSpecificChartSettings: (
      state,
      action: PayloadAction<{ chartDataId: string; settings: Partial<TBaseGateSettings> }>
    ) => {
      const { chartDataId, settings } = action.payload;
      if (!state.specificDatasetOptionMap[chartDataId]) return;

      state.specificDatasetOptionMap[chartDataId] = {
        ...state.specificDatasetOptionMap[chartDataId],
        ...settings,
      };
    },
    setOnlyGateToDisplay: (state, action: PayloadAction<Nullable<TGate>>) => {
      state.onlyGateToDisplay = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(chartSettingsActions.setIsObjectEntityEnabled, (state) => {
        state.activeGate = null;
        state.selectedGate = null;
      })
      .addCase(
        chartSettingsActions.setFullScreenChartData,
        (state, action: PayloadAction<Nullable<TDatasetDetails>>) => {
          if (action.payload && !state.specificDatasetOptionMap[action.payload.id]) {
            state.specificDatasetOptionMap[action.payload.id] = {
              gateLabelFormat: state.gateLabelFormat,
            };
          }
        }
      )
      .addCase(
        chartSettingsActions.clearSpecificDatasetOptionMap,
        (state, action: PayloadAction<{ chartDataId?: string }>) => {
          const { chartDataId } = action.payload;

          if (chartDataId && state.specificDatasetOptionMap[chartDataId]) {
            delete state.specificDatasetOptionMap[chartDataId];
          }
        }
      )
      .addCase(chartSettingsActions.clearAllSpecificChartSettings, (state) => {
        state.specificDatasetOptionMap = {};
      })
      .addCase(
        chartSettingsActions.setSpecificChartSettingsToAllDatasets,
        (state, action: PayloadAction<{ fullScreenChartDataId: string }>) => {
          const { fullScreenChartDataId } = action.payload;

          const specificSettings = state.specificDatasetOptionMap?.[fullScreenChartDataId] ?? {};

          if (!Object.keys(specificSettings)) return;
          state.gateLabelFormat = specificSettings.gateLabelFormat ?? state.gateLabelFormat;
        }
      );
  },
});

export default gatesSlice;
