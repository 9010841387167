import { useEffect, useMemo } from 'react';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { filterGateListByEntityLevel } from '@/helpers/gates';

import { appAPI } from '@/store/services/app';

const POLLING_INTERVAL_MIN = 2;

export function useGateList(experimentId: string) {
  const [fetchExperimentGates, { data: fullGateList, isError: isGateListError, error: gateListError }] =
    appAPI.useLazyFetchExperimentGatesQuery({
      pollingInterval: POLLING_INTERVAL_MIN * 60 * 1000, // Allows a query to automatically refetch on a provided interval, specified in milliseconds. Defaults to 0 (off)
      refetchOnReconnect: true, // Allows forcing the query to refetch when regaining a network connection. Defaults to false
    });

  const objectLevelGateList = useMemo(
    () => fullGateList && filterGateListByEntityLevel(fullGateList, true),
    [fullGateList]
  );

  const cageLevelGateList = useMemo(
    () => fullGateList && filterGateListByEntityLevel(fullGateList, false),
    [fullGateList]
  );

  const fetchExperimentGateList = () => {
    fetchExperimentGates(experimentId, true)
      .unwrap()
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  useEffect(() => {
    if (isGateListError) {
      showErrorToast(getErrorMessage(gateListError));
    }
  }, [isGateListError, gateListError]);

  return { fetchExperimentGateList, objectLevelGateList, cageLevelGateList };
}
