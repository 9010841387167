import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash.isequal';

const useItemList = <T>(
  valueList: T[],
  setValueList: (valueList: T[]) => void,
  emptyValue: T,
  isValueEmpty: (value: T) => boolean
) => {
  const getDefaultEmptyList = () => [{ uuid: uuidv4(), value: emptyValue }];

  const [list, setList] = useState<Array<{ uuid: string; value: T }>>(getDefaultEmptyList());

  const addEmptyItem = useCallback(() => {
    setList((prev) => [...prev, { uuid: uuidv4(), value: emptyValue }]);
  }, [setList]);

  const changeItem = useCallback(
    (uuid: string, newValue: T) => {
      setList((prev) => prev.map((item) => (item.uuid === uuid ? { uuid: item.uuid, value: newValue } : item)));
    },
    [setList]
  );

  const deleteItem = useCallback(
    (uuid: string) => {
      setList((prev) => {
        if (prev.length === 1) {
          return getDefaultEmptyList();
        }
        return prev.filter((item) => item.uuid !== uuid);
      });
    },
    [setList]
  );

  const clearAll = useCallback(() => {
    setList(getDefaultEmptyList());
  }, [setList]);

  const getValueList = useCallback(() => list.map((item) => item.value).filter((value) => isValueEmpty(value)), [list]);

  useEffect(() => {
    setValueList(getValueList());
  }, [getValueList]);

  useEffect(() => {
    if (isEqual(valueList, getValueList())) {
      return;
    }

    if (valueList.length) {
      setList(valueList.map((value) => ({ uuid: uuidv4(), value })));
    } else {
      setList(getDefaultEmptyList());
    }
  }, [valueList]);

  return useMemo(
    () => ({ list, addEmptyItem, changeItem, deleteItem, clearAll }),
    [list, addEmptyItem, changeItem, deleteItem, clearAll]
  );
};

export default useItemList;
