import { FC, ReactNode } from 'react';

import classnames from 'classnames/bind';
import styles from './Metadata.module.scss';

import MetadataItem from './MetadataItem';

const cn = classnames.bind(styles);

type TMetadataProps = {
  className?: string;
  children: ReactNode;
};

type TMetadataExtensions = {
  Item: typeof MetadataItem;
};

const Metadata: FC<TMetadataProps> & TMetadataExtensions = ({ className, children }) => (
  <dl className={cn('metadata', className)}>{children}</dl>
);

Metadata.Item = MetadataItem;

export default Metadata;
