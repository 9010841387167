import { EPlotlyChartType } from '@/types/charts';

export type TKneePointData = {
  rank: number;
  nUMIunique1: number;
  cbName: string;
};

export const CELLS_TRACE_NAME = 'Genomics-called cells';
export const BACKGROUND_TRACE_NAME = 'Background';

export const prepareData = (dataArr: TKneePointData[]) =>
  dataArr.reduce(
    (acc: { x: number[]; y: number[]; customdata: Record<string, string>[] }, { cbName, nUMIunique1, rank }) => {
      acc.x.push(rank);
      acc.y.push(nUMIunique1);
      acc.customdata.push({ cbName });
      return acc;
    },
    { x: [], y: [], customdata: [] }
  );

export const defaultTraceSettings = (name: string, color: string) => ({
  type: EPlotlyChartType.scattergl,
  mode: 'lines+markers',
  hoverinfo: 'none',
  name,
  line: {
    shape: 'spline',
    smoothing: 0.35,
    width: 2,
  },
  marker: { color },
});
