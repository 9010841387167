import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/react-hooks';

import * as queries from '@/graphql/queries';
import { addTooltip, MDASH } from '@/helpers';
import { TUseOpenModal } from '@/hooks/useOpenModal';

import Modal from '@/components/common/Modal';
import LoaderProgress from '@/components/common/LoaderProgress';
import NoDataFound from '@/components/common/NoDataFound';
import StatusBadge from '@/components/experiment/StatusesInfo/StatusBadge';
import { getInstrumentCurrentStatusBadgeData } from '@/components/experiment/StatusesInfo/helpers';
import { formatDateTime } from '@/pages/Home/helpers';

import styles from './TelemetryModal.module.scss';

const cn = classnames.bind(styles);

type TTelemetryModalProps = Pick<TUseOpenModal, 'isOpen' | 'onClose'> & {
  instrument: TInstrumentFromServer;
};

const TelemetryModal: FC<TTelemetryModalProps> = ({ isOpen, onClose, instrument }) => {
  const {
    data,
    loading: isInstrumentTelemetryLoading,
    error: isInstrumentTelemetryError,
  } = useQuery(queries.telemetryByInstrument, {
    variables: {
      instrumentId: instrument.id,
    },
  });

  const isEmptyData = useMemo(
    () => !isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !data?.telemetryByInstrument?.length,
    [isInstrumentTelemetryLoading, isInstrumentTelemetryError, data?.telemetryByInstrument]
  );

  const badgeData = useMemo(
    () => (instrument?.currentStatus ? getInstrumentCurrentStatusBadgeData(instrument.currentStatus) : null),
    [instrument?.currentStatus]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick className={cn('modal')} isFullScreen>
      <Modal.Header onRequestClose={onClose} />
      <Modal.Content className={cn({ modal__content: !isInstrumentTelemetryLoading })}>
        {isInstrumentTelemetryLoading && (
          <div className={cn('loader')}>
            <LoaderProgress isLoading={isInstrumentTelemetryLoading} theme="light" />
          </div>
        )}

        {!isInstrumentTelemetryLoading && (
          <>
            <div className={cn('instrument', 'content-block')}>
              <h2 className={cn('instrument__name')}>{instrument.name}</h2>
              <div className={cn('instrument__details')}>
                <div className={cn('instrument__details-block')}>
                  <div className={cn('instrument__row')}>
                    <span>Operator</span>
                    <span>{instrument.currentStatus?.operator ?? MDASH}</span>
                  </div>
                  <div className={cn('instrument__row')}>
                    <span>SW Version</span>
                    <span>{instrument.currentStatus?.version ?? MDASH}</span>
                  </div>
                </div>
                <div className={cn('instrument__details-block')}>
                  <div className={cn('instrument__row')}>
                    <span>Status</span>
                    {badgeData ? (
                      <StatusBadge className={cn('instrument__status')} {...badgeData} />
                    ) : (
                      <span>{MDASH}</span>
                    )}
                  </div>
                  <div className={cn('instrument__row')}>
                    <span>Run start</span>
                    <span>{formatDateTime(instrument.currentStatus?.timestamp, 'hh:mm:ss, MMM d yyyy') ?? MDASH}</span>
                  </div>
                </div>
                {instrument.currentStatus?.experiment?.name && (
                  <div className={cn('instrument__details-block')}>
                    <div className={cn('instrument__row')}>
                      <span>Run name</span>
                      <span>{instrument.currentStatus?.experiment?.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={cn('sensors', 'content-block')}>
              <h2 className={cn('sensors__name')}>Sensor reading</h2>
              <div className={cn('sensors__list-container')}>
                {isEmptyData || isInstrumentTelemetryError ? (
                  <NoDataFound
                    alignment="center"
                    size="big"
                    textData={isInstrumentTelemetryError ? 'Something went wrong' : 'No data found'}
                    className={cn('no-data')}
                  />
                ) : (
                  <div className={cn('sensors__list')}>
                    {data?.telemetryByInstrument?.map((telemetry: TTelemetryByInstrument) => (
                      <div
                        key={telemetry.name}
                        className={cn('sensors__list-item')}
                        {...addTooltip(telemetry.description, 'top-start')}
                      >
                        <span className={cn('sensors__telemetry-name')}>{telemetry.label}</span>
                        <span>{`${telemetry.value} ${telemetry.postfix}`}</span>
                        <span className={cn('sensors__telemetry-time')}>
                          {formatDateTime(telemetry.time, 'hh:mm:ss, MMM d yyyy')}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default memo(TelemetryModal);
