import ExperimentList from '@/components/common/ExperimentList';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useEffect } from 'react';
import { headerActions } from '@/store/slices/header';

const Project = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());
    appDispatch(headerActions.setNewLink({ title: 'projects', link: '/projects' }));
  }, []);

  return <ExperimentList />;
};

export default Project;
