import type { RootState } from '@/store';
import {
  isStepCellKillingReviewCellsCompleted,
  isStepCytokineDefineBeadsCompleted,
  isStepCytokineReviewBeadsCompleted,
  isStepInitCompleted,
  isStepCellKillingDefineCellsCompleted,
  isStepCellKillingDefineCellsTargetCompleted,
  isStepCellKillingReviewCellsTargetCompleted,
} from './helpers';
import { EStepName } from './types';

export const selectBeadsCount = (state: RootState) => state.preprocessing.beadsCount;

export const selectPreprocessingAssayId = (state: RootState) => state.preprocessing.preprocessingAssayId;

export const selectDatasets = (state: RootState) => state.preprocessing.datasets;

export const selectCurrentDatasetIndex = (state: RootState) => state.preprocessing.currentDatasetIndex;

export const selectCurrentDataset = (state: RootState) => {
  const { datasets, currentDatasetIndex } = state.preprocessing;
  return datasets[currentDatasetIndex ?? 0];
};

export const selectBeadTypes = (state: RootState) => state.preprocessing.beadTypes;

export const selectCellTypes = (state: RootState) => state.preprocessing.cellTypes;

export const selectCurrentStep = (state: RootState) => state.preprocessing.currentStep;

export const selectIsStepInitCompleted = (state: RootState): boolean => isStepInitCompleted(state);

export const selectIsStepCytokineReviewBeadsCompleted = (state: RootState): boolean =>
  isStepCytokineReviewBeadsCompleted(state);

export const selectIsStepCellKillingReviewCellsCompleted = (state: RootState): boolean =>
  isStepCellKillingReviewCellsCompleted(state);

export const selectIsStepCellKillingDefineCellsTargetCompleted = (state: RootState): boolean =>
  isStepCellKillingDefineCellsTargetCompleted(state);

export const selectIsStepCellKillingReviewCellsTargetCompleted = (state: RootState): boolean =>
  isStepCellKillingReviewCellsTargetCompleted(state);

export const selectNextStepEnabled = (state: RootState): boolean => {
  const { currentStep } = state.preprocessing;
  const conditionsObject: Partial<Record<EStepName, boolean>> = {
    stepInit: isStepInitCompleted(state),
    stepCytokineDefineBeads: isStepCytokineDefineBeadsCompleted(state),
    stepCytokineReviewBeads: isStepCytokineReviewBeadsCompleted(state),
    stepCellKillingDefineCells: isStepCellKillingDefineCellsCompleted(state),
    stepCellKillingReviewCells: isStepCellKillingReviewCellsCompleted(state),
    stepCellKillingDefineCellsTarget: isStepCellKillingDefineCellsTargetCompleted(state),
    stepCellKillingReviewCellsTarget: isStepCellKillingReviewCellsTargetCompleted(state),
  };

  return !!conditionsObject[currentStep];
};

export const selectBeadForGate = (state: RootState) => state.preprocessing.beadForGate;

export const selectGeneralBeadShapes = (state: RootState) => state.preprocessing.generalBeadShapes;

export const selectGeneralBeadShapesLength = (state: RootState) =>
  Object.keys(state.preprocessing.generalBeadShapes).length;

export const selectGeneralCellShapes = (state: RootState) => {
  const { selectedGate } = state.gates;
  if (!selectedGate) {
    return state.preprocessing.generalCellShapes;
  }
  return state.preprocessing.generalCellShapes[selectedGate.id].shapeNodes;
};

export const selectFullCellShapes = (state: RootState) => state.preprocessing.generalCellShapes;

export const selectIsProcessing = (state: RootState) => state.preprocessing.isProcessing;

export const selectBeadChannel = (state: RootState) => state.preprocessing.beadChannel;

export const selectApplyForAllDatasets = (state: RootState) => state.preprocessing.applyForAllDatasets;

export const selectPreprocessingType = (state: RootState) => state.preprocessing.preprocessingType;

export const selectCellXChannel = (state: RootState) => state.preprocessing.cellXChannel;

export const selectCellYChannel = (state: RootState) => state.preprocessing.cellYChannel;

export const selectCellForGate = (state: RootState) => state.preprocessing.cellForGate;

export const selectAxesOptionList = (state: RootState) => state.preprocessing.axesOptionList;

export const selectAxesOptionListLength = (state: RootState) => state.preprocessing.axesOptionList.length;

export const selectAxesOptionMap = (state: RootState) => state.preprocessing.axesOptionMap;

export const selectChannelOptionMap = (state: RootState) => state.preprocessing.channelOptionMap;
