import { FC, Children, cloneElement, useMemo, SyntheticEvent, ReactElement } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames/bind';

import { useDebounce } from '@/hooks/useDebounce';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { uiActions } from '@/store/slices/ui';

import { TModalColor } from '@/helpers/interfaces';
import styles from './Modal.module.scss';
import ModalHead from './ModalHead';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';

import './popup.css';

const cn = classnames.bind(styles);

export type TModal = ReactModal.Props & {
  className?: string;
  overlayClassName?: string;
  children: ReactElement | ReactElement[];
  color?: TModalColor;
  sidebar?: 'left' | 'right';
  title?: string;
  animationType?: 'splash-animation' | 'slide-animation' | 'none';
  isOverlay?: boolean;
  isFullScreen?: boolean;
  onFormSubmit?: () => void;
};

type TModalExtensions = {
  Header: typeof ModalHead;
  Content: typeof ModalContent;
  Footer: typeof ModalFooter;
};

ReactModal.setAppElement('#root');

const Modal: FC<TModal> & TModalExtensions = ({
  children,
  className,
  overlayClassName,
  portalClassName,
  shouldCloseOnOverlayClick = true,
  color = 'default',
  sidebar,
  onRequestClose,
  id,
  isOverlay = true,
  isFullScreen = false,
  onFormSubmit,
  animationType = 'splash-animation',
  shouldReturnFocusAfterClose = false,
  ...props
}: TModal) => {
  const appDispatch = useAppDispatch();

  const modalId = useMemo(() => `modal__${id}`, [id]);
  const debouncedOpen = useDebounce(props.isOpen, 300);

  const onAfterOpen = () => {
    appDispatch(uiActions.setIsMainScrollEnabled(false));
  };

  const onAfterClose = () => {
    // Check if the other Modal is open in current moment
    requestAnimationFrame(() => {
      if (!document.querySelector('.ReactModal__Overlay')) {
        appDispatch(uiActions.setIsMainScrollEnabled(true));
      }
    });
  };

  const handleFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (onFormSubmit) {
      onFormSubmit();
    }
  };

  if (!debouncedOpen) {
    return null;
  }

  return (
    <ReactModal
      closeTimeoutMS={300}
      className={cn(
        'modal',
        {
          [color]: true,
          modal_sidebar: sidebar,
          modal_fullscreen: isFullScreen,
          [`modal_sidebar_${sidebar}`]: sidebar,
          [`modal_overlay_${animationType}-close`]: !props.isOpen,
          [`modal_overlay_${animationType}`]: props.isOpen,
        },

        className
      )}
      overlayClassName={cn(
        'modal_overlay',
        {
          modal_open: props.isOpen,
          modal_overlay_sidebar: sidebar,
          'modal_no-overlay': !isOverlay,
        },
        overlayClassName
      )}
      portalClassName={cn(
        'modal_portal',
        {
          modal_portal_sidebar: sidebar,
          modal_open: props.isOpen,
          modal_closed: !props.isOpen,
        },
        portalClassName
      )}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      {...props}
    >
      {onFormSubmit && (
        <form onSubmit={handleFormSubmit} className={cn('modal__form')}>
          {Children.map(children, (child) =>
            cloneElement(child, { onRequestClose, modalColor: color, sidebar, id: modalId })
          )}
        </form>
      )}
      {!onFormSubmit &&
        Children.map(children, (child) =>
          cloneElement(child, { onRequestClose, modalColor: color, sidebar, id: modalId })
        )}
    </ReactModal>
  );
};

Modal.Header = ModalHead;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
