import { TSelectionDivElement } from '@/hooks/gates/types';
import { select as d3Select, Selection, BaseType } from 'd3-selection';
import { isGateVariant, TGateLayout, TGateVariant } from '@/pages/Dataset/components/DatasetChart/types';

const normalize = (value: number | string, maxValue: number | string) => Number(value) / Number(maxValue);

const getLabelData = (
  d3Label: Selection<BaseType, unknown, null, undefined>,
  maxXPosition: number | string,
  maxYPosition: number | string
) => {
  if (d3Label.empty()) {
    return null;
  }

  return {
    position: {
      x: normalize(d3Label.attr('x'), maxXPosition),
      y: normalize(d3Label.attr('y'), maxYPosition),
    },
    text: d3Label.text(),
    rtl: d3Label.attr('text-anchor') === 'end',
  };
};

const getCurrentGatesLayout = (overflowContainer: Nullable<TSelectionDivElement>) => {
  if (!overflowContainer) {
    return;
  }
  const svg = overflowContainer.select('.gates-container');
  if (!svg || svg.empty()) {
    return;
  }

  const width = svg.attr('width');
  const height = svg.attr('height');

  const normalizeH = (value: number | string) => normalize(value, width);
  const normalizeV = (value: number | string) => normalize(value, height);

  const gateList = svg.selectAll('.gate-group').nodes();
  const layout: TGateLayout[] = [];
  gateList.forEach((gate) => {
    const d3Gate = d3Select(gate);
    if (d3Gate.attr('gate-hidden') === 'true') {
      return;
    }
    const type = d3Gate.attr('gate-type');
    if (!isGateVariant(type)) {
      return;
    }
    const gateElement = d3Gate.select('.gate-element');

    const getLayoutByType = (inType: TGateVariant) => {
      const byType: Record<TGateVariant, () => TGateLayout> = {
        rectangle: () => {
          const d3Label = d3Gate.select('.gate-label__text');
          const label = getLabelData(d3Label, width, height);
          const stroke = gateElement.style('stroke');

          return {
            type: 'rectangle',
            label,
            coordinates: {
              x: normalizeH(gateElement.attr('x')),
              y: normalizeV(gateElement.attr('y')),
              width: normalizeH(gateElement.attr('width')),
              height: normalizeV(gateElement.attr('height')),
            },
            styles: {
              stroke,
            },
          };
        },
        ellipse: () => {
          const d3Label = d3Gate.select('.gate-label__text');
          const label = getLabelData(d3Label, width, height);
          const stroke = gateElement.style('stroke');

          return {
            type: 'ellipse',
            label,
            coordinates: {
              cx: normalizeH(gateElement.attr('cx')),
              cy: normalizeV(gateElement.attr('cy')),
              rx: normalizeH(gateElement.attr('rx')),
              ry: normalizeV(gateElement.attr('ry')),
            },
            styles: {
              stroke,
            },
          };
        },
        polar: () => {
          const labelElementList = d3Gate.selectAll('.gate-label__text').nodes();
          const gateElementList = d3Gate.selectAll('.gate-element').nodes();
          const stroke = d3Gate.select('.gate-element').style('stroke');

          const labelList = labelElementList.map((label) => {
            const d3Label = d3Select(label);
            return getLabelData(d3Label, width, height);
          });
          const coordinates = gateElementList.map((line) => {
            const d3Line = d3Select(line);

            return {
              x1: normalizeH(d3Line.attr('x1')),
              y1: normalizeV(d3Line.attr('y1')),
              x2: normalizeH(d3Line.attr('x2')),
              y2: normalizeV(d3Line.attr('y2')),
            };
          }, []);

          return {
            type: 'polar',
            labelList,
            coordinates,
            styles: {
              stroke,
            },
          };
        },
        'split-gate': () => {
          const labelElementList = d3Gate.selectAll('.gate-label__text').nodes();
          const gateElementList = d3Gate.selectAll('.gate-element').nodes();
          const stroke = d3Gate.select('.gate-element').style('stroke');

          const labelList = labelElementList.map((label) => {
            const d3Label = d3Select(label);
            return getLabelData(d3Label, width, height);
          });
          const coordinates = gateElementList.map((line) => {
            const d3Line = d3Select(line);

            return {
              x1: normalizeH(d3Line.attr('x1')),
              y1: normalizeV(d3Line.attr('y1')),
              x2: normalizeH(d3Line.attr('x2')),
              y2: normalizeV(d3Line.attr('y2')),
            };
          }, []);

          return {
            type: 'split-gate',
            labelList,
            coordinates,
            styles: {
              stroke,
            },
          };
        },
        polygon: () => {
          const pathString = gateElement.attr('d');
          const path = pathString.split(/[ML]/).slice(1);
          const splitPath = path.reduce((acc, coordinate) => {
            const [x, y] = coordinate.split(',');
            acc.push([normalizeH(x), normalizeV(y)]);

            return acc;
          }, [] as [number, number][]);
          const d3Label = d3Gate.select('.gate-label__text');
          const label = getLabelData(d3Label, width, height);
          const stroke = gateElement.style('stroke');

          return {
            type: 'polygon',
            label,
            coordinates: {
              path: splitPath,
            },
            styles: {
              stroke,
            },
          };
        },
        range: () => {
          const d3Label = d3Gate.select('.gate-label__text');
          const label = getLabelData(d3Label, width, height);
          const stroke = gateElement.style('stroke');

          const gateElementList = d3Gate.selectAll('.gate-element').nodes();

          const coordinates = gateElementList.map((line) => {
            const d3Line = d3Select(line);

            return {
              x1: normalizeH(d3Line.attr('x1')),
              y1: normalizeV(d3Line.attr('y1')),
              x2: normalizeH(d3Line.attr('x2')),
              y2: normalizeV(d3Line.attr('y2')),
            };
          }, []);

          return {
            type: 'range',
            label,
            coordinates,
            styles: {
              stroke,
            },
          };
        },
      };

      return byType[inType]();
    };

    const layoutByType = getLayoutByType(type);

    if (layoutByType) {
      layout.push(layoutByType);
    }
  });

  return layout;
};

export default getCurrentGatesLayout;
