import { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path
        d="M17 12.304v2.436c0 .892-.093 1.215-.267 1.541-.174.326-.43.582-.756.756-.326.175-.65.267-1.54.267H3.563c-.892 0-1.215-.092-1.54-.267a1.817 1.817 0 0 1-.757-.756c-.174-.326-.267-.649-.267-1.54v-2.437h0"
        fill="none"
      />
      <path d="M9 10.5V1M9 11l4.5-4.5M9 11 4.5 6.5" />
    </g>
  </svg>
);

export default DownloadIcon;
