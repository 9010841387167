import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentSelectors } from '@/store/slices/experiment';
import { navigatorActions, TChannelListOption } from '@/store/slices/navigator';
import { selectUniqueChannelIdListFromScanList } from '@/store/services/app/selectors';

import useParamsExperimentId from '../useParamsExperimentId';

const useInitNavigatorData = () => {
  const experimentId = useParamsExperimentId();
  const appDispatch = useAppDispatch();
  const channels: TChannelListOption = {};
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const defaultScan = useSelector(experimentSelectors.selectDefaultScan);
  const uniqueChannelIdList = useMemo(() => selectUniqueChannelIdListFromScanList(scanList), [scanList]);

  useEffect(() => {
    // set initial render options on page load
    if (!scanList) {
      return;
    }

    scanList.forEach((scan) => {
      channels[scan.id] = {};
      scan.lanes.forEach((lane) => {
        channels[scan.id][lane.id] = lane.channels;
      });
    });

    const laneIdList = [...new Set(scanList.map(({ lanes }) => lanes.map(({ id }) => id)).flat())];
    const viewportSettings = laneIdList.map((uniqueLaneId) => {
      const currentScanData = scanList.find(({ lanes }) => lanes.find(({ id }) => id === uniqueLaneId));
      const res = {
        width: 0,
        height: 0,
      };
      if (!currentScanData) {
        return res;
      }

      const currentLaneData = currentScanData.lanes.find(({ id }) => id === uniqueLaneId);
      if (currentLaneData) {
        res.width = Number(currentLaneData.totalImgWidth);
        res.height = Number(currentLaneData.totalImgHeight);
      }

      return res;
    });
    appDispatch(
      navigatorActions.setInitialChannelOptions({
        experimentId,
        laneIdList,
        channelIdList: uniqueChannelIdList,
        channels,
        viewportSettings,
      })
    );
  }, [scanList]);

  useEffect(() => {
    if (!defaultScan) {
      return;
    }
    appDispatch(
      navigatorActions.setCurrentScanId({
        experimentId,
        scanId: defaultScan.id,
      })
    );
  }, [defaultScan]);

  return null;
};

export default useInitNavigatorData;
