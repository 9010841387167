import { SVGProps } from 'react';

const ArrowPointerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 6.00391L1 6.0039" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M17 0L23.0104 6.01041L17 12.0208V6.01041V0Z" fill="currentColor" />
  </svg>
);

export default ArrowPointerIcon;
