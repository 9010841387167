import classNames from 'classnames/bind';
import { FC } from 'react';
import styles from './RadialLoader.module.scss';

const cn = classNames.bind(styles);

type TRadialLoaderProps = {
  className?: string;
};
const RadialLoader: FC<TRadialLoaderProps> = ({ className }) => <div className={cn('loader', className)} />;

export default RadialLoader;
