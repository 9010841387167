import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { graphqlAPI } from '@/store/services/graphql';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

export function useEditDesignData() {
  const appDispatch = useAppDispatch();

  const { sourceType, sourceId } = useParams();

  const source = useSelector(experimentRunDesignSelectors.selectSource);

  const [fetchTemplate] = graphqlAPI.useLazyFetchTemplateQuery();
  const [fetchTemplateVersion] = graphqlAPI.useLazyFetchTemplateVersionQuery();
  const [fetchRunDesign] = graphqlAPI.useLazyFetchRunDesignQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleError = (error: unknown) => {
    showErrorToast(getErrorMessage(error));
    setIsError(true);
  };

  const requestRunDesign = async () => {
    const runDesignResponse = await fetchRunDesign(sourceId, true);
    if (runDesignResponse.isError || !runDesignResponse.data) {
      handleError(runDesignResponse.error || 'No run design found');
      return;
    }

    const versionResponse = await fetchTemplateVersion(runDesignResponse.data.templateVersionId, true);
    if (versionResponse.isError || !versionResponse.data) {
      handleError(versionResponse.error || 'No template version found');
      appDispatch(experimentRunDesignActions.setData({ runDesign: runDesignResponse.data }));
      return;
    }

    const templateResponse = await fetchTemplate(versionResponse.data.templateId, true);
    if (templateResponse.isError || !templateResponse.data) {
      handleError(templateResponse.error || 'No template found');
      appDispatch(experimentRunDesignActions.setData({ runDesign: runDesignResponse.data }));
      return;
    }

    if (
      runDesignResponse.data?.id &&
      sourceType === ERunDesignSourceType.draft &&
      runDesignResponse.data.id === sourceId
    ) {
      appDispatch(experimentRunDesignActions.setEditFieldsFromRunDesign(runDesignResponse.data));
    }

    appDispatch(
      experimentRunDesignActions.setData({ template: templateResponse.data, runDesign: runDesignResponse.data })
    );
  };

  const requestTemplate = async () => {
    const templateResponse = await fetchTemplate(sourceId, true);
    if (templateResponse.isError || !templateResponse.data) {
      handleError(templateResponse.error || 'No template found');
      return;
    }

    appDispatch(experimentRunDesignActions.setData({ template: templateResponse.data }));
    appDispatch(experimentRunDesignActions.setEditFieldsFromTemplate(templateResponse.data));
  };

  const requestData = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (sourceType === ERunDesignSourceType.draft) {
      await requestRunDesign();
    } else {
      await requestTemplate();
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    if (source.type !== sourceType || source.id !== sourceId) {
      appDispatch(experimentRunDesignActions.setSource({ sourceType, sourceId }));
      requestData();
    }
  }, [source, sourceType, sourceId, requestData]);

  return useMemo(
    () => ({
      isLoading,
      isError,
    }),
    [isLoading, isError]
  );
}
