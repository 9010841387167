import { PREPROCESSING_ASSAY_TYPE_LIST, titleCase } from '@/helpers';

export const getEntityFromServerFieldsForAxes = (entityFromServer?: Record<string, string>) => {
  if (!entityFromServer) {
    return [];
  }

  const fieldsToExclude = [
    'cage_id',
    'file_name',
    'segmented_fov_file_name',
    'cage_crop_file_name',
    'snapshot_id',
    'global_cage_id_matched',
    'y_stage',
    'x_stage',
    'object_bbox_xtl',
    'object_bbox_ytl',
    'object_bbox_xbr',
    'object_bbox_ybr',
    'obj_bbox_in_cage_xtl',
    'obj_bbox_in_cage_xbr',
    'obj_bbox_in_cage_ytl',
    'obj_bbox_in_cage_ybr',
    'cage_bbox_xtl',
    'cage_bbox_ytl',
    'cage_bbox_xbr',
    'cage_bbox_ybr',
  ];
  return Object.keys(entityFromServer).filter((fieldName) => !fieldsToExclude.includes(fieldName));
};

export const getEntityLevelAxesOptions = (entity?: TEntity, lane?: TLane) => {
  if (!entity) {
    return [];
  }

  const laneClasses = lane?.classes ?? [];
  const classesNamesMap = new Map();

  laneClasses.forEach((laneClass: TClass) => {
    if (PREPROCESSING_ASSAY_TYPE_LIST.includes(laneClass.processType)) {
      classesNamesMap.set(`${laneClass.name.replace(',', '')}`, laneClass.marker.replace(',', ''));
    }
  });

  const fieldsToExclude = [
    'cageId',
    'cageCropFileName',
    'snapshotId',
    'globalCageIdMatched',
    'yStage',
    'xStage',
    'cropsPaths',
    'cropsThumbnailPaths',
    'uuid',
  ];

  const fieldsForDimensions = Object.keys(entity).filter((fieldName) => !fieldsToExclude.includes(fieldName));
  const classesNamesList = Array.from(classesNamesMap);

  return fieldsForDimensions.map((fieldName) => {
    const classData = classesNamesList.find(([name]) => fieldName.includes(name));

    let label = fieldName;

    if (classData) {
      const [name, marker] = classData;
      const reg = new RegExp(name, 'g');

      label = fieldName.replace(reg, marker);
    }

    return {
      value: fieldName,
      label: titleCase(label),
    };
  });
};
