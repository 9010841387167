import { useEffect, ComponentType } from 'react';
import { Hub } from 'aws-amplify';

import { cdnAPI } from '@/store/services/cdnData';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { generateJwtToken, createChangeJwtTokenListener } from '@/helpers';

const setApiCookies = async () => {
  try {
    const token = await generateJwtToken();
    await fetch(`/api/set-cookie`, {
      headers: { authorization: `Bearer ${token}` },
      cache: 'no-store',
    });
  } catch (_) {
    // Do nothing
  }
};

export function withJwtTokenListener(WrappedComponent: ComponentType) {
  return function ComponentWithExperimentData() {
    const appDispatch = useAppDispatch();

    useEffect(() => {
      const authListener = createChangeJwtTokenListener(() => {
        appDispatch(cdnAPI.util.invalidateTags([{ type: 'Cookies' }]));
        setApiCookies();
      });
      const removeAuthListener = Hub.listen('auth', authListener);

      // Check session every second. It will trigger the tokenRefresh event when the session is expired.
      // The tokenRefresh event will trigger refetching of cookies for CDN
      const checkSessionIntervalId = setInterval(generateJwtToken, 1000);

      setApiCookies();

      return () => {
        removeAuthListener();
        clearInterval(checkSessionIntervalId);
      };
    }, []);

    return <WrappedComponent />;
  };
}
