import { SVGProps } from 'react';

const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" {...props}>
    <g stroke="#B3B3B3" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <circle cx="8.5" cy="8.5" r="7.75" />
      <path strokeLinecap="round" d="M8.5 8.5v-4M8.5 8.5h4" />
    </g>
  </svg>
);

export default ClockIcon;
