import { FC, MouseEvent, ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import ToggleableBlockMain from './ToggleableBlockMain';
import ToggleableBlockSidebar from './ToggleableBlockSidebar';
import icons from '../icons';
import styles from './ToggleableBlock.module.scss';

const cn = classNames.bind(styles);

type ToggleableBlockProps = {
  isOpen?: boolean;
  showOnlyMain?: boolean;
  onIsOpenChange?: (_isOPen: boolean) => void;
  children: [ReactElement, ReactElement];
  className?: string;
};

type TToggleableBlockExtensions = {
  Main: typeof ToggleableBlockMain;
  Sidebar: typeof ToggleableBlockSidebar;
};

const ToggleableBlock: FC<ToggleableBlockProps> & TToggleableBlockExtensions = ({
  isOpen: outerIsOpen,
  onIsOpenChange,
  children,
  showOnlyMain = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (outerIsOpen !== undefined) {
      setIsOpen(outerIsOpen);
    }
  }, [outerIsOpen]);

  const handleOpenToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onIsOpenChange) {
      onIsOpenChange(!isOpen);
      return;
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={cn('toggleable-block', { 'toggleable-block_open': isOpen || showOnlyMain }, className)}>
      <div className={cn('toggleable-block__wrap')}>
        {children[0]}
        {!showOnlyMain && (
          <div className={cn('toggleable-block__sidebar')}>
            <button
              className={cn('toggleable-block__trigger-track')}
              onClick={handleOpenToggle}
              aria-label="toggle block"
            >
              <div className={cn('toggleable-block__trigger')}>
                <icons.ArrowTimeLeftIcon className={cn('toggleable-block__trigger-icon')} />
              </div>
            </button>
            <div className={cn('toggleable-block__sidebar-content')}>{children[1]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

ToggleableBlock.Main = ToggleableBlockMain;
ToggleableBlock.Sidebar = ToggleableBlockSidebar;
export default ToggleableBlock;
