import { ReactNode } from 'react';

import { EDesignStep, TDesignStep } from '@/pages/experiment-run-design/types';

import icons from '@/components/common/icons';

export const urlPostfixList: Record<TDesignStep, string> = {
  [EDesignStep.details]: '',
  [EDesignStep.timeline]: 'timeline',
  [EDesignStep.sample]: 'sample',
  [EDesignStep.reagents]: 'reagents',
  [EDesignStep.incubation]: 'incubation',
  [EDesignStep.cagingSettings]: 'cagingSettings',
  [EDesignStep.opticalSettings]: 'opticalSettings',
};

export const runDesignNavigation = [
  { step: EDesignStep.timeline, label: 'Run timeline' },
  { step: EDesignStep.sample, label: 'Sample information' },
  { step: EDesignStep.reagents, label: 'Assays: Reagents' },
  { step: EDesignStep.incubation, label: 'Incubation treatment' },
  { step: EDesignStep.cagingSettings, label: 'Caging settings' },
  { step: EDesignStep.opticalSettings, label: 'Optical settings' },
];

export const fullRunDesignNavigationSteps = [{ step: EDesignStep.details, label: 'Details' }, ...runDesignNavigation];

export const runDesignStepOrder: TDesignStep[] = [
  EDesignStep.details,
  EDesignStep.timeline,
  EDesignStep.sample,
  EDesignStep.reagents,
  EDesignStep.incubation,
  EDesignStep.cagingSettings,
  EDesignStep.opticalSettings,
];

export const lastRunDesignStepOrder = runDesignStepOrder.length - 1;

export const iconList = {
  iconCaging: <icons.CagingIcon />,
  iconScanning: <icons.ScanningIcon />,
  iconIncubationOffDevice: <icons.IncubationOffDeviceIcon />,
  iconReceptor: <icons.ReceptorIcon />,
  iconSecretion: <icons.SecretionIcon />,
  iconRetention: <icons.RetentionIcon />,
  iconIncubation: <icons.IncubationIcon />,
  iconKilling: <icons.TimelineCellKillingIcon />,
  iconScanningStacked: <icons.ScanningStackedIcon />,
  iconWash: <icons.WashIcon />,
  iconMRna: <icons.MRnaIcon />,
  cellKillingStain: <icons.CellKillingStainIcon />,
  iconAddAntibody: <icons.AddAntibodyIcon />,
  iconAddMedia: <icons.AddMediaIcon />,
  iconCellLysis: <icons.CellLysisIcon />,
  iconElution: <icons.ElutionIcon />,
  iconSegment: <icons.SegmentIcon />,
};

/* all non-default exports exist to clean code and remove hardcode id from data file */
export type TIconId = keyof typeof iconList;

type TIconIdList = { [P in TIconId]: P };
export const iconIdList = Object.keys(iconList).reduce<TIconIdList>(
  (acc, iconId) => ({
    ...acc,
    [iconId]: iconId,
  }),
  {} as TIconIdList
);

// Matches assay icons with components by component type
// Other components are not assays
export const assayIconList: Record<string, ReactNode> = {
  SurfaceReceptor: <icons.ReceptorIcon />,
  SurfaceReceptorCytokineSecretion: <icons.ReceptorIcon />,
  CytokineSecretion: <icons.SecretionIcon />,
  CellKilling: <icons.CellKillingIcon />,
  mRNA: <icons.MRnaIcon />,
};
