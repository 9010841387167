import { FC } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import { useSelector } from 'react-redux';
import { headerSelectors } from '@/store/slices/header';
import { NavLink } from 'react-router-dom';
import styles from './Nav.module.scss';

const cn = classnames.bind(styles);

const Nav: FC = () => {
  const currentLinks = useSelector(headerSelectors.selectHeadersLinks);

  return (
    <nav className={cn('nav')}>
      {currentLinks.map((link) => (
        <NavLink key={link.title} to={link.link} className={cn('nav__link')}>
          <icons.SlashIcon className={cn('nav__slash-icon')} />
          <span className={cn('nav__label')}>{link.title}</span>
        </NavLink>
      ))}
    </nav>
  );
};

export default Nav;
