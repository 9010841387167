import { SVGProps } from 'react';

const ScanningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" {...props}>
    <path
      d="M31 2h4a7 7 0 0 1 7 7v4M13 2H9a7 7 0 0 0-7 7v4M31 42h4a7 7 0 0 0 7-7v-4M13 42H9a7 7 0 0 1-7-7v-4"
      stroke="currentColor"
      strokeWidth="4"
    />
    <circle
      cx="22"
      cy="22"
      r="10"
      transform="rotate(-90 22 22)"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
  </svg>
);

export default ScanningIcon;
