import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TAnnotationsEndpointBuilder } from '@/store/services/app/types';
import { annotationEndpoints } from '@/store/services/annotation/endpoints';
import { generateJwtToken } from '@/helpers';

const prepareEndpoints = (build: TAnnotationsEndpointBuilder) => ({
  ...annotationEndpoints(build),
});

export const antnAPI = createApi({
  reducerPath: 'antnAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/antsvc`,
    prepareHeaders: (headers) =>
      new Promise((resolve) => {
        generateJwtToken().then(
          (token) => {
            headers.set('authorization', `Bearer ${token}`);
            resolve(headers);
          },
          () => {
            resolve(headers);
          }
        );
      }),
  }),
  endpoints: prepareEndpoints,
});
