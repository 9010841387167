import { SVGProps } from 'react';

const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" color="currentColor" viewBox="0 0 21 17" {...props}>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path
        d="M5.128 0h8.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v4.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512C.186 12.302 0 11.655 0 9.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 0 1 2.046.534C2.698.186 3.345 0 5.128 0z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <circle stroke="currentColor" strokeWidth="1.5" cx="9.5" cy="7.5" r="3.5" />
      <circle fill="currentColor" cx="15" cy="4" r="1" />
    </g>
  </svg>
);

export default CameraIcon;
