import { EPreprocessingAssayType } from '@/helpers';

export enum EStepName {
  stepInit = 'stepInit',
  stepCytokineDefineBeads = 'stepCytokineDefineBeads',
  stepCytokineReviewBeads = 'stepCytokineReviewBeads',
  stepCytokineSummary = 'stepCytokineSummary',
  stepCellKillingDefineCells = 'stepCellKillingDefineCells',
  stepCellKillingReviewCells = 'stepCellKillingReviewCells',
  stepCellKillingDefineCellsTarget = 'stepCellKillingDefineCellsTarget',
  stepCellKillingReviewCellsTarget = 'stepCellKillingReviewCellsTarget',
  stepCellKillingSummary = 'stepCellKillingSummary',
}

export type TStepsData = {
  [k in EStepName]: {
    next: Nullable<EStepName>;
    previous: Nullable<EStepName>;
    name: EStepName;
    number: number;
  };
};

export type TGeneralBeadShape = Pick<
  TGate,
  'shape' | 'gateNodes' | 'level' | 'properties' | 'parentId' | 'xDimension' | 'yDimension'
> & {
  id: string;
  beadId?: string;
  name: string;
  marker: string;
  noEditable?: boolean;
  specificDatasets: Record<string, Omit<TGeneralBeadShape, 'beadId' | 'specificDatasets' | 'name' | 'marker'>>;
};

export type TGeneralCellShape = Pick<
  TGate,
  'shape' | 'gateNodes' | 'level' | 'properties' | 'name' | 'parentId' | 'xDimension' | 'yDimension'
> & {
  id: string;
  cellId?: string;
  noEditable?: boolean;
  specificDatasets: Record<
    string,
    Omit<TGeneralBeadShape, 'beadId' | 'specificDatasets' | 'name' | 'shapeNodes' | 'marker'>
  >;
  shapeNodes: Record<string, TGeneralCellShape>;
};

export type TDefaultPreprocessingShape = Omit<TGeneralBeadShape, 'beadId' | 'specificDatasets' | 'name' | 'marker'>;

export enum EPreprocessingObjectType {
  'beads' = 'beads',
  'cells' = 'cells',
}

export type TInitialPreprocessingState = {
  isProcessing: boolean;
  datasets: TDatasetDetails[];
  beadsCount: number;
  preprocessingAssayId?: EPreprocessingAssayType;
  beadTypes: TBead[];
  cellTypes: TCell[];
  currentStep: EStepName;
  currentDatasetIndex: Nullable<number>;
  beadForGate?: TBead;
  cellForGate?: TCell;
  generalBeadShapes: Record<TGeneralBeadShape['id'], TGeneralBeadShape>;
  generalCellShapes: Record<TGeneralCellShape['id'], TGeneralCellShape>;
  targetAndAffectedCellShapes: Record<TGeneralCellShape['id'], TGeneralCellShape>;
  beadChannel: string;
  applyForAllDatasets: boolean;
  preprocessingType: EPreprocessingObjectType;
  cellXChannel: string;
  cellYChannel: string;
  axesOptionList: TOption[];
  channelOptionMap: Record<string, Record<string, string>>;
  axesOptionMap: Record<string, Record<string, string>>;
};
