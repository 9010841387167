import { TProcessingResultPayload } from '@/store/slices/gatesAutoPreprocessing';

export const preprocessingResult = {
  resultPayloadList: new Map<string, Nullable<TProcessingResultPayload>>(),

  clearPayloads(experimentId: string, datasetIdList: { scanId: string; laneId: string }[]) {
    this.resultPayloadList.clear();
    datasetIdList.forEach((datasetId) => {
      this.resultPayloadList.set(JSON.stringify({ experimentId, ...datasetId }), null);
    });
  },

  addPayload(experimentId: string, payload: TProcessingResultPayload) {
    this.resultPayloadList.set(
      JSON.stringify({ experimentId, scanId: payload.scanId, laneId: payload.laneId }),
      payload
    );
  },

  getResultPayloadList() {
    const values = Array.from(this.resultPayloadList.values());
    return values.filter((payload) => payload !== null) as TProcessingResultPayload[];
  },

  isAllReceived() {
    const values = Array.from(this.resultPayloadList.values());
    return values.every((payload: Nullable<TProcessingResultPayload>) => payload !== null);
  },
};
