import { SVGProps } from 'react';

const WashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="39" viewBox="0 0 44 39" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 30V35.2175C1.77031 37.2218 4.38615 38.5 7.33328 38.5C10.2804 38.5 12.8963 37.2218 14.6666 35.2175V35.2175C16.4369 37.2218 19.0527 38.5 21.9999 38.5C24.947 38.5 27.5629 37.2218 29.3332 35.2175V30H28.6922C27.3417 32.4157 24.8112 34 21.9999 34C19.1885 34 16.658 32.4157 15.3075 30H14.0257C12.6751 32.4157 10.1446 34 7.33328 34C4.52194 34 1.99145 32.4157 0.640897 30H0ZM29.3334 30V35.2175C31.1037 37.2218 33.7195 38.5 36.6667 38.5C39.6138 38.5 42.2297 37.2218 44 35.2175V30H43.3591C42.0085 32.4157 39.478 34 36.6667 34C33.8553 34 31.3248 32.4157 29.9743 30H29.3334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V5.21751C1.77031 7.22183 4.38615 8.5 7.33328 8.5C10.2804 8.5 12.8963 7.22182 14.6666 5.2175V5.21751C16.4369 7.22183 19.0527 8.5 21.9999 8.5C24.947 8.5 27.5629 7.2218 29.3332 5.21745V0H28.6922C27.3417 2.41565 24.8112 4 21.9999 4C19.1885 4 16.658 2.41565 15.3075 0H14.0257C12.6751 2.41565 10.1446 4 7.33328 4C4.52194 4 1.99145 2.41565 0.640897 0H0ZM29.3334 0V5.21751C31.1037 7.22183 33.7195 8.5 36.6667 8.5C39.6138 8.5 42.2297 7.2218 44 5.21745V0H43.3591C42.0085 2.41565 39.478 4 36.6667 4C33.8553 4 31.3248 2.41565 29.9743 0H29.3334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15V20.2175C1.77031 22.2218 4.38615 23.5 7.33328 23.5C10.2804 23.5 12.8963 22.2218 14.6666 20.2175V20.2175C16.4369 22.2218 19.0527 23.5 21.9999 23.5C24.947 23.5 27.5629 22.2218 29.3332 20.2175V15H28.6922C27.3417 17.4157 24.8112 19 21.9999 19C19.1885 19 16.658 17.4157 15.3075 15H14.0257C12.6751 17.4157 10.1446 19 7.33328 19C4.52194 19 1.99145 17.4157 0.640897 15H0ZM29.3334 15V20.2175C31.1037 22.2218 33.7195 23.5 36.6667 23.5C39.6138 23.5 42.2297 22.2218 44 20.2175V15H43.3591C42.0085 17.4157 39.478 19 36.6667 19C33.8553 19 31.3248 17.4157 29.9743 15H29.3334Z"
      fill="currentColor"
    />
  </svg>
);

export default WashIcon;
