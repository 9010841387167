import { FC, memo, MouseEvent, useCallback } from 'react';
import classnames from 'classnames/bind';

import styles from './ContextMenu.module.scss';

import { TContextMenuAction } from '../types';

const cn = classnames.bind(styles);

type TContextMenu = {
  className?: string;
  actions: TContextMenuAction[];
};

const ContextMenu: FC<TContextMenu> = ({ actions, className }) => {
  const handleClick = useCallback(
    (action: () => void) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      action();
    },
    []
  );

  return (
    <div className={cn('context-menu', className)}>
      {actions.map((action) => {
        const key = action.name;
        return (
          <button key={key} onClick={handleClick(action.onClick)} className={cn('action', action.className)}>
            {action.component}
          </button>
        );
      })}
    </div>
  );
};

export default memo(ContextMenu);
