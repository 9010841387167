import { formatDate, titleCase } from '@/helpers';
import icons from '@/components/common/icons';

const getTooltip = (message?: Nullable<string>, timestamp?: string, addInfo?: string[]) => {
  const parts = [];
  if (timestamp) {
    parts.push(`${formatDate(timestamp, 'MMMM d, yyyy, p')}`);
  }
  if (message) {
    parts.push(`<b>${message}</b>`);
  }
  if (addInfo) {
    parts.push(...addInfo);
  }
  return parts.join('<br/>');
};

export const getCurrentStatusBadgeData = (currentStatus: TCurrentStatus) => {
  const classNameMap: Record<TCurrentStatus['status'], string> = {
    CREATED: 'blue',
    STARTED: 'blue',
    ACTION_PROMPT: 'yellow',
    ACTION_CONFIRMED: 'green',
    ACTION_SKIPPED: 'yellow',
    PAUSED: 'yellow',
    RUNNING: 'blue',
    SUSPENDED: 'yellow',
    RESUMED: 'green',
    COMPLETED: 'green',
    CANCELLED: 'red',
    ERROR: 'red',
    WARNING: 'yellow',
  };

  return {
    status: titleCase(currentStatus.status),
    colorModifier: classNameMap[currentStatus.status] ?? 'blue',
    tooltip: getTooltip(currentStatus.message, currentStatus.timestamp),
    icon: <icons.ExperimentStatus />,
  };
};

export const getPipelineCurrentStatusBadgeData = (pipelineCurrentStatus: TPipelineCurrentStatus) => {
  const classNameMap: Record<TPipelineCurrentStatus['status'], string> = {
    QUEUED: 'yellow',
    STARTED: 'blue',
    COMPLETED: 'green',
    ERROR: 'red',
  };

  return {
    status: titleCase(pipelineCurrentStatus.status),
    colorModifier: classNameMap[pipelineCurrentStatus.status] ?? 'blue',
    tooltip: getTooltip(pipelineCurrentStatus.message, pipelineCurrentStatus.timestamp, [
      `Step: ${titleCase(pipelineCurrentStatus.step)}`,
    ]),
    icon: <icons.ExperimentPipelineStatus />,
  };
};

export const getInstrumentCurrentStatusBadgeData = (currentStatus: TInstrumentStatus) => {
  const classNameMap: Record<TInstrumentStatus['status'], string> = {
    ONLINE: 'green',
    INITIALIZING: 'blue',
    ERROR: 'red',
    READY: 'green',
    RUNNING: 'yellow',
    PAUSED: 'yellow',
    OFFLINE: 'black',
  };

  return {
    status: titleCase(currentStatus.status),
    colorModifier: classNameMap[currentStatus.status] ?? 'blue',
    tooltip: getTooltip(currentStatus.message, currentStatus.timestamp),
  };
};
