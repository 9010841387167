import { EChartType } from '@/types/charts';

export const isEmptyAxesRange = (range: Nullable<Record<'x' | 'y', number[]>>) => {
  if (!range) {
    return false;
  }
  return !Object.values(range).some((el) => el?.some((subEl) => subEl));
};

export const formatRangeObj = (
  data:
    | TPlotAxisRange
    | Nullable<{
        xMin: number;
        xMax: number;
        yMin: number;
        yMax: number;
      }>
): TPopulationRange => {
  if (!data) {
    return {
      xMin: 0,
      xMax: 0,
      yMax: 0,
      yMin: 0,
    };
  }
  if (data && 'xMin' in data && 'yMin' in data && data && 'xMax' in data && 'yMax' in data) {
    return data;
  }

  return {
    xMin: data.x[0] ?? 0,
    xMax: data.x[1] ?? 0,
    yMax: data.y[1] ?? 0,
    yMin: data.y[0] ?? 0,
  };
};

export const formatRange = (
  data:
    | TPlotAxisRange
    | Nullable<{
        xMin: number;
        xMax: number;
        yMin: number;
        yMax: number;
      }>
): TPlotAxisRange => {
  if (!data) {
    return {
      x: [0, 0],
      y: [0, 0],
    };
  }
  if (data && 'x' in data && 'y' in data) {
    return data;
  }

  return {
    x: [data.xMin, data.xMax],
    y: [data.yMin, data.yMax],
  };
};

export const isSameRanges = (...ranges: TPlotAxisRange[]): boolean => {
  const xMin = ranges[0].x[0];
  const xMax = ranges[0].x[1];
  const yMin = ranges[1].y[0];
  const yMax = ranges[1].y[1];
  return ranges.every(
    (range) => range.x[0] === xMin && range.x[1] === xMax && range.y[0] === yMin && range.y[1] === yMax
  );
};

export const defineAutoCalculatedRangForHistograms = (
  plot: IPlotlyHTMLDivElement,
  currentChartType: EChartType
): Nullable<TPopulationRange> => {
  if (currentChartType !== EChartType.lineHistogram) return null;

  const xAxisRangeFromLayout = plot?.layout?.xaxis?.range ?? null;
  const yAxisRangeFromLayout = plot?.layout?.yaxis?.range ?? null;

  if (!xAxisRangeFromLayout && !yAxisRangeFromLayout) return null;

  const rangeCalculatedForHistograms = {
    xMin: xAxisRangeFromLayout[0],
    xMax: xAxisRangeFromLayout[1],
    yMin: yAxisRangeFromLayout[0],
    yMax: yAxisRangeFromLayout[1],
  };

  return rangeCalculatedForHistograms;
};
