import classnames from 'classnames/bind';
import ConsumableWaves from '@/components/runDesign/ConsumableComponent/ConsumableWaves';

import styles from '../../EditFields.module.scss';

const cn = classnames.bind(styles);

const ReagentSelectedValue = ({ selectedValue }: { selectedValue: TBasicOption }) => (
  <ConsumableWaves
    className={cn('field', 'edit-setting__waves')}
    opticalDisplay={selectedValue?.customData?.reagent?.opticalDisplay ?? null}
  />
);

export default ReagentSelectedValue;
