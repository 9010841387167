import { FC } from 'react';
import { MDASH } from '@/helpers';
import NoDataFound from '@/components/common/NoDataFound';

type TSettingValueString = {
  value: TSettingOptionVariableValue;
};

const SettingValueString: FC<TSettingValueString> = ({ value }) => {
  if (typeof value !== 'string' || !value) {
    return <NoDataFound textData={MDASH} alignment="left" />;
  }

  return <span>{value}</span>;
};

export default SettingValueString;
