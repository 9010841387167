import { SVGProps } from 'react';

const CollapseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M1.5 4.5h11M16.5 4.5h4M16.5 7.5v-6M5.5 16.5v-6M9.5 13.5h11M1.5 13.5h4" />
    </g>
  </svg>
);

export default CollapseIcon;
