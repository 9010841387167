import { FC } from 'react';

import { formatDate } from '@/helpers';

import useNowDate from '@/hooks/useNowDate';

const Datetime: FC = () => {
  const now = useNowDate();

  return <div>{formatDate(now, 'E, MMM d  h:mm a')}</div>;
};

export default Datetime;
