import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCorrectNameOfAxes } from '@/helpers/preprocessing';

import { appAPI } from '@/store/services/app';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { TGeneralBeadShape } from '@/store/slices/preprocessing/types';

import useParamsExperimentId from '../useParamsExperimentId';
import { useAppDispatch } from '../useAppDispatch';

const usePredefineCytokineGates = (dataset: TDatasetDetails) => {
  const experimentId = useParamsExperimentId();
  const appDispatch = useAppDispatch();

  const beadTypes = useSelector(preprocessingSelectors.selectBeadTypes);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const axesOptionMap = useSelector(preprocessingSelectors.selectAxesOptionMap);
  const generalBeadShapesLength = useSelector(preprocessingSelectors.selectGeneralBeadShapesLength);

  const [fetchExperimentGates, { data: gatesFromServer, isLoading, isError }] =
    appAPI.useLazyFetchExperimentGatesQuery();

  useEffect(() => {
    fetchExperimentGates(experimentId, true);
  }, [experimentId]);

  const getCorrectAxesBetweenDatasetAndGate = useCallback(
    (datasetData: TDatasetDetails, gate: TGate) => {
      const { correctXAxis, correctYAxis, xAxisFromMap, yAxisFromMap } = getCorrectNameOfAxes(
        axesOptionMap,
        datasetData.name,
        xAxis,
        yAxis
      );

      const { xAxisFromMap: gateXAxis, yAxisFromMap: gateYAxis } = getCorrectNameOfAxes(
        axesOptionMap,
        datasetData.name,
        gate.xDimension,
        gate.yDimension
      );

      const isSameAxes = xAxisFromMap === gateXAxis && yAxisFromMap === gateYAxis;
      const isSameReversedAxes = xAxisFromMap === gateYAxis && yAxisFromMap === gateXAxis;

      const xDimension = isSameAxes ? correctXAxis : correctYAxis;
      const yDimension = isSameAxes ? correctYAxis : correctXAxis;

      return {
        xDimension,
        yDimension,
        isGateOfDataset: isSameAxes || isSameReversedAxes,
      };
    },
    [axesOptionMap, xAxis, yAxis]
  );

  useEffect(() => {
    if (isError || isLoading || !gatesFromServer || generalBeadShapesLength) {
      return;
    }

    const generalBeadShapes = beadTypes.reduce((generalBeadsAcc, beadType) => {
      const gate = gatesFromServer.find(
        (gateFromServer) =>
          gateFromServer.name === beadType.name &&
          dataset.laneId === gateFromServer.laneId &&
          dataset.scanId === gateFromServer.scanId &&
          gateFromServer.properties?.classId
      );

      if (!gate) {
        return generalBeadsAcc;
      }

      const {
        isGateOfDataset: isGeneralGateOfDataset,
        xDimension: generalXAxis,
        yDimension: generalYAxis,
      } = getCorrectAxesBetweenDatasetAndGate(dataset, gate);

      if (!isGeneralGateOfDataset) {
        return generalBeadsAcc;
      }

      const specificDatasets = datasets.reduce((acc, el) => {
        if (el.id === dataset.id) {
          return acc;
        }
        const specificGate = gatesFromServer.find(
          (gateFromServer) =>
            gateFromServer.name === beadType.name &&
            el.laneId === gateFromServer.laneId &&
            el.scanId === gateFromServer.scanId
        );

        if (!specificGate) {
          return acc;
        }

        const { isGateOfDataset, xDimension, yDimension } = getCorrectAxesBetweenDatasetAndGate(el, specificGate);
        if (!isGateOfDataset) {
          return acc;
        }

        acc[el.id] = {
          ...specificGate,
          xDimension,
          yDimension,
          id: beadType.uuid,
        };
        return acc;
      }, {} as TGeneralBeadShape['specificDatasets']);

      generalBeadsAcc[beadType.uuid] = {
        ...gate,
        beadId: gate.properties.classId,
        id: beadType.uuid,
        xDimension: generalXAxis,
        yDimension: generalYAxis,
        marker: beadType?.marker ?? '',
        specificDatasets: specificDatasets ?? {},
      };

      return generalBeadsAcc;
    }, {} as Record<string, TGeneralBeadShape>);

    appDispatch(preprocessingActions.setGeneralBeadShapes(generalBeadShapes));
  }, [gatesFromServer, isLoading, isError, beadTypes, dataset, generalBeadShapesLength]);

  return { isLoading };
};

export default usePredefineCytokineGates;
