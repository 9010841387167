import { TGateLabel } from '@/pages/Dataset/components/DatasetChart/types';

import { TRenderOnCanvasInput } from './types';
import { fittingString } from '../canvas';

const labelPadding = 8;

export const drawLabel = (
  ctx: CanvasRenderingContext2D,
  label: Nullable<TGateLabel>,
  labelRenderingElement: Nullable<HTMLSpanElement>
) => {
  if (!label || !labelRenderingElement) {
    return;
  }

  labelRenderingElement.innerHTML = label.text;
  ctx.save();

  ctx.font = '400 17px "Inter Display", Arial, sans-serif';
  ctx.textBaseline = 'middle';

  const { width } = ctx.measureText(label.text);
  const { height } = labelRenderingElement.getBoundingClientRect();

  const left = label.rtl ? label.position.x - width : label.position.x;

  ctx.fillStyle = '#f5f5f5';
  ctx.beginPath();
  ctx.roundRect(
    Math.round(left - labelPadding),
    Math.round(label.position.y - height / 2),
    Math.round(width + 2 * labelPadding),
    Math.round(height),
    15
  );
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = 'black';

  ctx.fillText(fittingString(ctx, label.text, width), Math.round(left), Math.round(label.position.y));
  ctx.restore();
};
export const renderEllipseGate = ({
  gate,
  offset,
  ctx,
  imageHeight,
  imageWidth,
  labelElement,
}: TRenderOnCanvasInput) => {
  if (!gate || gate.type !== 'ellipse') {
    return;
  }

  const {
    label,
    coordinates: { cx, cy, rx, ry },
  } = gate;

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabel(ctx, label, labelElement);
  }
  ctx.save();

  ctx.beginPath();
  ctx.rect(sx, sy, imageWidth, imageHeight);
  ctx.clip();

  ctx.beginPath();
  ctx.ellipse(
    Math.round(sx + imageWidth * cx),
    Math.round(sy + imageHeight * cy),
    Math.round(imageWidth * rx),
    Math.round(imageHeight * ry),
    0,
    0,
    2 * Math.PI
  );
  ctx.strokeStyle = gate.styles.stroke ?? 'black';
  ctx.lineWidth = 2;
  ctx.setLineDash([2, 2]);
  ctx.stroke();
  ctx.restore();
};

export const renderPolarGate = ({ gate, offset, ctx, imageHeight, imageWidth, labelElement }: TRenderOnCanvasInput) => {
  if (!gate || (gate.type !== 'polar' && gate.type !== 'split-gate')) {
    return;
  }

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const { labelList, coordinates } = gate;
  if (labelList.length) {
    labelList?.forEach((label) => {
      if (!label) {
        return;
      }
      label.position = {
        x: sx + imageWidth * label.position.x,
        y: sy + imageHeight * label.position.y,
      };

      drawLabel(ctx, label, labelElement);
    });
  }

  ctx.save();

  ctx.beginPath();
  ctx.rect(sx, sy, imageWidth, imageHeight);
  ctx.clip();

  ctx.beginPath();
  coordinates.forEach((line) => {
    ctx.moveTo(Math.round(sx + imageWidth * line.x1), Math.round(sy + imageHeight * line.y1));
    ctx.lineTo(Math.round(sx + imageWidth * line.x2), Math.round(sy + imageHeight * line.y2));
  });

  ctx.strokeStyle = gate.styles.stroke ?? 'black';
  ctx.lineWidth = 2;
  ctx.setLineDash([2, 2]);
  ctx.stroke();
  ctx.restore();
};

export const renderPolygonGate = ({
  gate,
  offset,
  ctx,
  imageHeight,
  imageWidth,
  labelElement,
}: TRenderOnCanvasInput) => {
  if (!gate || gate.type !== 'polygon') {
    return;
  }

  const {
    label,
    coordinates: { path },
  } = gate;

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabel(ctx, label, labelElement);
  }

  const [startPoint, ...nextPointList] = path as number[][];

  ctx.save();

  ctx.beginPath();
  ctx.rect(sx, sy, imageWidth, imageHeight);
  ctx.clip();

  ctx.beginPath();
  ctx.moveTo(Math.round(sx + imageWidth * startPoint[0]), Math.round(sy + imageHeight * startPoint[1]));
  nextPointList.forEach((point) => {
    ctx.lineTo(Math.round(sx + imageWidth * point[0]), Math.round(sy + imageHeight * point[1]));
  });

  ctx.strokeStyle = gate.styles.stroke ?? 'black';
  ctx.lineWidth = 2;
  ctx.setLineDash([2, 2]);
  ctx.stroke();
  ctx.restore();
};

export const renderRangeGate = ({ gate, offset, ctx, imageHeight, imageWidth, labelElement }: TRenderOnCanvasInput) => {
  if (!gate || gate.type !== 'range') {
    return;
  }

  const { label, coordinates } = gate;

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabel(ctx, label, labelElement);
  }

  ctx.save();

  ctx.beginPath();
  ctx.rect(sx, sy, imageWidth, imageHeight);
  ctx.clip();

  ctx.beginPath();
  coordinates.forEach((line) => {
    ctx.moveTo(Math.round(sx + imageWidth * line.x1), Math.round(sy + imageHeight * line.y1));
    ctx.lineTo(Math.round(sx + imageWidth * line.x2), Math.round(sy + imageHeight * line.y2));
  });

  ctx.strokeStyle = gate.styles.stroke ?? 'black';
  ctx.lineWidth = 2;
  ctx.setLineDash([2, 2]);
  ctx.stroke();
  ctx.restore();
};

export const renderRectangleGate = ({
  gate,
  offset,
  ctx,
  imageHeight,
  imageWidth,
  labelElement,
}: TRenderOnCanvasInput) => {
  if (!gate || gate.type !== 'rectangle') {
    return;
  }

  const sx = offset.sx ?? 0;
  const sy = offset.sy ?? 0;

  const {
    label,
    coordinates: { x, y, width, height },
  } = gate;

  if (label) {
    label.position = {
      x: sx + imageWidth * label.position.x,
      y: sy + imageHeight * label.position.y,
    };

    drawLabel(ctx, label, labelElement);
  }

  ctx.save();

  ctx.beginPath();
  ctx.rect(sx, sy, imageWidth, imageHeight);
  ctx.clip();

  ctx.strokeStyle = gate.styles.stroke ?? 'black';
  ctx.lineWidth = 2;
  ctx.setLineDash([2, 2]);
  ctx.beginPath();
  ctx.rect(
    Math.round(sx + imageWidth * x),
    Math.round(sy + imageHeight * y),
    Math.round(imageWidth * width),
    Math.round(imageHeight * height)
  );
  ctx.stroke();
  ctx.restore();
};
