import { useEffect, ComponentType } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { refreshSession } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useParamsExperimentId } from '@/hooks';
import { useLinks } from '@/hooks/useLinks';

import { appAPI } from '@/store/services/app';

import { TExperimentDataProps } from '@/hoc/withExperimentData';

import LoaderProgress from '@/components/common/LoaderProgress';

import styles from './withExperimentToken.module.scss';

const cn = classnames.bind(styles);

export function withExperimentToken<P>(WrappedComponent: ComponentType<P & TExperimentDataProps>) {
  return function ComponentWithExperimentToken(props: P) {
    const { generateExperimentLink } = useLinks();
    const experimentId = useParamsExperimentId();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');

    const [acceptInvitationLink, { isError: isAcceptInvitationLinkError, error: errorAcceptInvitationLink }] =
      appAPI.useAcceptInvitationLinkMutation();

    useEffect(() => {
      if (!token) {
        return;
      }

      acceptInvitationLink({
        experimentId,
        token,
      })
        .unwrap()
        .then(() => {
          refreshSession(() => {
            navigate(generateExperimentLink());
          });
        })
        .catch((error) => {
          showErrorToast(getErrorMessage(error));
        });
    }, []);

    if (!token) {
      return <WrappedComponent experimentId={experimentId} {...props} />;
    }

    if (isAcceptInvitationLinkError) {
      return <div className={cn('experiment-token__error')}>{getErrorMessage(errorAcceptInvitationLink)}</div>;
    }

    return <LoaderProgress isLoading theme="light" wrapperClassName={cn('experiment-token__content-loader')} />;
  };
}
