import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames/bind';

import { EDesignStep } from '@/pages/experiment-run-design/types';

import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';

import Title from '@/components/runDesign/Title';
import LanesBlock from '@/components/runDesign/LanesBlock';

import styles from './RunDesignBlockWithLanes.module.scss';

const cn = classNames.bind(styles);

type TRunDesignBlockProps = {
  title: ReactNode;
  children?: ReactNode;
};

const RunDesignBlockWithLanes: FC<TRunDesignBlockProps> = ({ title, children }) => {
  const { currentStep } = useRunDesignLocation();

  const isSampleStep = useMemo(() => currentStep === EDesignStep.sample, [currentStep]);
  const isReagentAssayStep = useMemo(() => currentStep === EDesignStep.reagents, [currentStep]);

  return (
    <div className={cn('run-design-block')}>
      <Title>{title}</Title>
      <LanesBlock
        isEditAllowed
        isActivationAllowed={isSampleStep}
        hideAnnotationSwitch={!isReagentAssayStep}
        hideCellTypes={!isSampleStep}
        hideSampleNameGenerateSwitch={!isSampleStep}
      />
      {children}
    </div>
  );
};

export default RunDesignBlockWithLanes;
