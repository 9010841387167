import { createSelector } from '@reduxjs/toolkit';

import { DISPLAY_CONTOURS_NAVIGATOR_ZOOM } from '@/helpers';

import { selectCurrentExperimentId } from '@/store/slices/experiment/selectors';

import { TExperimentOption } from './types';

export const selectExperimentOptions = createSelector(
  [(state) => state, selectCurrentExperimentId],
  (state, currentExperimentId) => state.navigator.experiments[currentExperimentId] ?? {}
);

export const selectLanesSettings = createSelector(
  [(state) => state, selectExperimentOptions],
  (state, currentExperimentOptions) => currentExperimentOptions.lanes ?? {}
);

export const selectCurrentLaneId = createSelector(
  [(state) => state, selectExperimentOptions],
  (state, currentExperimentOptions) => currentExperimentOptions.currentLaneId ?? ''
);

export const selectCurrentScanId = createSelector(
  [(state) => state, selectExperimentOptions],
  (state, currentExperimentOptions) => currentExperimentOptions.currentScanId ?? ''
);

const getLaneZoom = (experimentOptions: TExperimentOption, laneId: string) => {
  if (!experimentOptions.lanes) {
    return {
      zoom: 0,
      lastEdit: '',
    };
  }

  return (
    experimentOptions.lanes[laneId]?.zoom ?? {
      zoom: 0,
      lastEdit: '',
    }
  );
};

export const selectZoom = (laneId: string) =>
  createSelector([selectExperimentOptions], (experimentOptions) => getLaneZoom(experimentOptions, laneId));

export const selectIsAllowedObjectsOnCanvas = (laneId: string) =>
  createSelector([selectExperimentOptions], (experimentOptions) => {
    const { zoom } = getLaneZoom(experimentOptions, laneId);
    return Number(zoom.toFixed(2)) >= DISPLAY_CONTOURS_NAVIGATOR_ZOOM;
  });

export const selectPosition = (laneId: string) =>
  createSelector([selectExperimentOptions], (experimentOptions) => {
    if (!experimentOptions.lanes) {
      return { x: 0, y: 0, lastEdit: '' };
    }

    return experimentOptions.lanes[laneId]?.position ?? { x: 0, y: 0, lastEdit: '' };
  });

export const selectViewport = (laneId: string) =>
  createSelector([selectExperimentOptions], (experimentOptions) => {
    if (!experimentOptions.lanes) {
      return { height: 0, width: 0 };
    }

    return experimentOptions.lanes[laneId]?.viewport ?? { height: 0, width: 0 };
  });

export const selectRenderOptions = createSelector(
  [(state) => state, selectExperimentOptions],
  (state, currentExperimentOptions) => currentExperimentOptions.render ?? {}
);

export const selectChannelList = (scanId: string, laneId: string) =>
  createSelector(
    [selectExperimentOptions],
    (currentExperimentOptions) => (currentExperimentOptions.channels?.[scanId]?.[laneId] ?? []) as string[]
  );
