import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { MDASH } from '@/helpers';
import { getErrorMessage } from '@/helpers/errors';
import { includes } from '@/helpers/enum';

import { useFetchNextToken } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';

import * as queries from '@/graphql/queries';
import { ListRunDesignsInput, ListRunDesignTemplatesInput, RunDesignEdge, RunDesignTemplateEdge } from '@/graphql/API';

import { authAPI } from '@/store/services/auth';
import { experimentRunDesignActions } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';
import PagePanel from '@/components/common/PagePanel';
import NoDataFound from '@/components/common/NoDataFound';
import type { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';
import { DEFAULT_WIZARD_STEP } from '@/store/slices/experimentRunDesign/slice';
import { lastRunDesignStepOrder } from '@/helpers/runDesigns/constants';

import {
  DesignCardRenderer,
  ScreenVariantList,
  TDraftDesignCardData,
  TReadyDesignCardData,
  TScreenVariantList,
  TTemplateDesignCardData,
} from './types';
import { getDraftHref, getRunDesignAssayList, getRunDesignMeta, getTemplateMeta } from './helpers';

import DesignCard from './components/DesignCard';

import styles from './DesignSelector.module.scss';

const cn = classnames.bind(styles);

const DesignSelector = () => {
  const appDispatch = useAppDispatch();

  const { list: userList } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
  });

  const {
    edgeList: templateEdgeList,
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
    error: templatesError,
  } = useGraphqlQueryFullList<ListRunDesignTemplatesInput, RunDesignTemplateEdge>({
    query: queries.runDesignTemplates,
    dataName: 'runDesignTemplates',
  });

  const {
    edgeList: runDesignEdgeList,
    isLoading: isRunDesignsLoading,
    isError: isRunDesignsError,
    error: runDesignsError,
  } = useGraphqlQueryFullList<ListRunDesignsInput, RunDesignEdge>({
    query: queries.runDesigns,
    dataName: 'runDesigns',
  });

  const templateCardDataList = useMemo(
    () =>
      templateEdgeList.reduce<TTemplateDesignCardData[]>((list, edge) => {
        if (edge.node.activeVersionId) {
          list.push({
            id: edge.node.id,
            title: edge.node.name,
            meta: getTemplateMeta(edge.node, userList),
            href: `/design-experiment/${ERunDesignSourceType.template}/${edge.node.id}`,
            ...(edge.node.activeVersion?.schema && {
              assayList: getRunDesignAssayList(edge.node.activeVersion.schema),
            }),
          });
        }
        return list;
      }, []),
    [templateEdgeList, userList]
  );

  const draftEdgeList = useMemo(() => runDesignEdgeList.filter((edge) => !edge.node.publishedAt), [runDesignEdgeList]);
  const readyEdgeList = useMemo(() => runDesignEdgeList.filter((edge) => !!edge.node.publishedAt), [runDesignEdgeList]);

  const draftCardDataList = useMemo(
    () =>
      draftEdgeList.map<TDraftDesignCardData>((edge) => {
        const steps = {
          total: lastRunDesignStepOrder,
          current: edge.node?.wizardStep ?? DEFAULT_WIZARD_STEP,
        };
        return {
          id: edge.node.id,
          title: edge.node.name,
          href: getDraftHref(edge.node.id, steps.current),
          meta: getRunDesignMeta(edge.node, userList),
          assayList: getRunDesignAssayList(edge.node.schema),
          steps,
        };
      }),
    [draftEdgeList, userList]
  );

  const readyCardDataList = useMemo(
    () =>
      readyEdgeList.map<TReadyDesignCardData>((edge) => ({
        id: edge.node.id,
        title: edge.node.name,
        href: `/design-experiment/${ERunDesignSourceType.draft}/${edge.node.id}`,
        meta: getRunDesignMeta(edge.node, userList),
        assayList: getRunDesignAssayList(edge.node.schema),
        laneCount: edge.node.schema.lanes?.length ?? MDASH,
        controls: [
          {
            label: 'Edit',
            icon: <icons.SettingsIcon />,
            clickHandler: () => {
              navigate(`/design-experiment/${ERunDesignSourceType.draft}/${edge.node.id}`);
            },
          },
          {
            label: 'Manifest',
            icon: <icons.ManifestIcon />,
            clickHandler: () => {
              window.open('/Experiment-Manifest.pdf', '_blank');
            },
          },
        ],
      })),
    [readyEdgeList, userList]
  );

  const isLoading = useMemo(() => isTemplatesLoading || isRunDesignsLoading, [isTemplatesLoading, isRunDesignsLoading]);
  const isError = useMemo(() => isTemplatesError || isRunDesignsError, [isTemplatesError, isRunDesignsError]);
  const errorMessage = useMemo(
    () => getErrorMessage(templatesError || runDesignsError),
    [templatesError, runDesignsError]
  );
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = useMemo(() => {
    const tab = searchParams.get('tab');

    if (!tab) {
      return ScreenVariantList.templates;
    }

    return tab;
  }, [searchParams]);
  const handleToggleViewClickFactory = (newValue: TScreenVariantList) => () => {
    if (includes([ScreenVariantList.templates], newValue)) {
      // hide default tab value
      setSearchParams((params) => {
        params.delete('tab');
        return params;
      });
      return;
    }

    setSearchParams({ tab: newValue });
  };

  const pageControlsConfig: TDefaultPageControlsConfig = {
    back: {
      clickHandler: () => {
        navigate('/home');
      },
    },
  };

  useEffect(() => {
    appDispatch(experimentRunDesignActions.clear());
  }, []);

  return (
    <PagePanel
      title="Experiment design"
      isLoading={isLoading}
      className={cn('design-experiment')}
      config={pageControlsConfig}
    >
      {!isLoading && isError && (
        <div className={cn('design-experiment__error')}>
          <NoDataFound size="big" textData={errorMessage} />
        </div>
      )}

      {!isLoading && !isError && (
        <>
          <PagePanel.CollapsableHeader
            className={cn('design-experiment__header-wrap')}
            collapsedClassName={cn('_collapsed')}
          >
            <div className={cn('design-experiment__view-toggle')}>
              <button
                className={cn('design-experiment__templates', { _active: currentTab === ScreenVariantList.templates })}
                onClick={handleToggleViewClickFactory(ScreenVariantList.templates)}
              >
                Templates
                <div className={cn('design-experiment__counter')}>{templateCardDataList.length}</div>
              </button>
              <button
                className={cn('design-experiment__drafts', { _active: currentTab === ScreenVariantList.drafts })}
                onClick={handleToggleViewClickFactory(ScreenVariantList.drafts)}
              >
                Drafts
                <div className={cn('design-experiment__counter')}>{draftCardDataList.length}</div>
              </button>
              <div className={cn('design-experiment__view-toggle-spacer')} />
              <button
                className={cn('design-experiment__ready', { _active: currentTab === ScreenVariantList.ready })}
                onClick={handleToggleViewClickFactory(ScreenVariantList.ready)}
              >
                Ready
                <div className={cn('design-experiment__counter')}>{readyCardDataList.length}</div>
              </button>
            </div>

            <div className={cn('design-experiment__header')}>
              Select a template
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                className={cn('design-experiment__header-slash')}
              >
                <path d="M1 37L37 1" stroke="black" strokeWidth="1.5" />
              </svg>
              finish the draft
            </div>
          </PagePanel.CollapsableHeader>

          <div className={cn('dashboard__items_cards', 'design-experiment__content')}>
            {currentTab === ScreenVariantList.templates && (
              <>
                {templateCardDataList.map((data) => (
                  <DesignCard data={data} key={data.id} />
                ))}
                {templateCardDataList.length === 0 && (
                  <NoDataFound textData="No templates found" className={cn('design-experiment__no-data')} />
                )}
              </>
            )}
            {currentTab === ScreenVariantList.drafts && (
              <>
                {draftCardDataList.map((data) => (
                  <DesignCard render={DesignCardRenderer.draft} data={data} key={data.id} />
                ))}
                {draftCardDataList.length === 0 && (
                  <NoDataFound textData="No drafts found" className={cn('design-experiment__no-data')} />
                )}
              </>
            )}
            {currentTab === ScreenVariantList.ready && (
              <>
                {readyCardDataList.map((data) => (
                  <DesignCard render={DesignCardRenderer.ready} data={data} key={data.id} />
                ))}
                {readyCardDataList.length === 0 && (
                  <NoDataFound
                    textData="No ready run designs found"
                    size="big"
                    className={cn('design-experiment__no-data')}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </PagePanel>
  );
};

export default DesignSelector;
