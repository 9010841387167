import { CSSProperties, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useParamsExperimentId } from '@/hooks';
import { lutImageURIList } from '@/hooks/useWebgl/lut';
import useSavedChannelOptions from '@/hooks/navigator/useSavedChannelOptions';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { TRenderOption, navigatorActions, navigatorSelectors } from '@/store/slices/navigator';
import { viewerActions, viewerSelectors } from '@/store/slices/viewer';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import Modal from '@/components/common/Modal';
import Range from '@/components/common/Range';
import icons from '@/components/common/icons';
import ColorPicker from '@/components/common/ColorPicker';
import CheckboxInput from '@/components/common/CheckboxInput';

import styles from './FiltersModal.module.scss';

const cn = classnames.bind(styles);

type TFiltersModalProps = {
  isOpen: boolean;
  handleCloseModal: () => void;
};

const FiltersModal: FC<TFiltersModalProps> = ({ isOpen, handleCloseModal }) => {
  const appDispatch = useAppDispatch();

  const chartId = usePlotChartIdContext();
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));

  const channelsData = useSelector(navigatorSelectors.selectRenderOptions);
  const allowDrawObjectsOnImageCrop = useSelector(viewerSelectors.selectAllowDrawObjectsOnImageCrop);
  const displayCurrentObjOnImageCrop = useSelector(viewerSelectors.selectDisplayCurrentObjOnImageCrop);
  const displayAllObjectsOnImageCrop = useSelector(viewerSelectors.selectDisplayAllObjectsOnImageCrop);

  const channelsKeysArr = useMemo(() => Object.keys(channelsData), [channelsData]);

  const toggleDisplayCurrentObjOnImageCrop = () => {
    appDispatch(viewerActions.toggleDisplayCurrentObjOnImageCrop());
  };

  const toggleDisplayAllObjectsOnImageCrop = () => {
    appDispatch(viewerActions.toggleDisplayAllObjectsOnImageCrop());
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      animationType="slide-animation"
      className={cn('filters-modal')}
      sidebar="right"
      onRequestClose={handleCloseModal}
      isOverlay={false}
    >
      <Modal.Header withDivide={false} className={cn('filters-modal__header')}>
        Filters
      </Modal.Header>
      <Modal.Content className={cn('filters-modal__content')}>
        {allowDrawObjectsOnImageCrop && (
          <div className={cn('section', 'section_highlight-objects')}>
            {isObjectEntityEnabled && (
              <CheckboxInput
                checked={displayCurrentObjOnImageCrop}
                onChange={toggleDisplayCurrentObjOnImageCrop}
                label="Highlight current object"
                theme="light"
              />
            )}
            <CheckboxInput
              checked={displayAllObjectsOnImageCrop}
              onChange={toggleDisplayAllObjectsOnImageCrop}
              label="Highlight all objects"
              theme="light"
            />
          </div>
        )}
        {channelsKeysArr?.map((item) => (
          <SingleModalChannel key={item} channelName={item} channelsData={channelsData} />
        ))}
      </Modal.Content>
    </Modal>
  );
};

const SingleModalChannel = ({
  channelsData,
  channelName,
}: {
  channelsData: Record<string, TRenderOption>;
  channelName: string;
}) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const channel = { ...channelsData[channelName], name: channelName };
  const { isResetOptionsDisabled, onResetOptionsClick, throttledSaveRenderOptionsInLS } = useSavedChannelOptions({
    channelId: channelName,
  });

  const [minValue, maxValue] = useMemo(() => {
    if (!channel.range) {
      return [0, 0];
    }

    return channel.range;
  }, [channel]);

  const handleVisibilityIconClick = () => {
    const payload = {
      experimentId,
      channelId: channelName,

      options: {
        ...channel,
        isActive: !channel.isActive,
      },
    };
    appDispatch(navigatorActions.setRenderOptions(payload));
    throttledSaveRenderOptionsInLS(payload);
  };

  const onRangeChange = (newRangeValue: number | number[]) => {
    const payload = {
      experimentId,
      channelId: channel.name,

      options: {
        ...channel,
        range: newRangeValue as [number, number],
      },
    };
    appDispatch(navigatorActions.setRenderOptions(payload));
    throttledSaveRenderOptionsInLS(payload);
  };

  const colorPickerProps = {
    channel,
    disabled: !channel.isActive,
    className: cn('channel__controls-color-picker'),
    value: channel.color,
    onChange: (newColor: string) => {
      const payload = {
        experimentId,
        channelId: channel.name,
        options: {
          color: newColor,
          isUseLut: false,
        },
      };
      appDispatch(navigatorActions.setRenderOptions(payload));
      throttledSaveRenderOptionsInLS(payload);
    },
    onLutChange: (type: string) => {
      const payload = {
        experimentId,
        channelId: channel.name,

        options: {
          isUseLut: true,
          lutType: type,
        },
      };
      appDispatch(navigatorActions.setRenderOptions(payload));
      throttledSaveRenderOptionsInLS(payload);
    },
    isLutSelected: channel.isUseLut,
    lutType: channel.lutType,
  };

  return (
    <div className={cn('section', { channel_disabled: !channel.isActive })}>
      <div className={cn('channel__header')}>
        <div className={cn('channel__header-title')}>
          {!channel.isUseLut && (
            <div
              style={
                {
                  '--color': channel.color,
                } as CSSProperties
              }
              className={cn('channel__header-title-color')}
            />
          )}
          {channel.isUseLut && (
            <div className={cn('channel__header-title-lut', { 'image-modal__lut_disabled': !channel.isActive })}>
              <img src={lutImageURIList[channel.lutType]} alt="" className={cn('channel__header-title-lut-image')} />
            </div>
          )}
          {channel.label}
        </div>
        <div className={cn('channel__controls-container')}>
          {!isResetOptionsDisabled && (
            <button onClick={onResetOptionsClick} aria-label="reset-options" className={cn('channel__button')}>
              <icons.ReloadIcon />
            </button>
          )}
          <button onClick={handleVisibilityIconClick} className={cn('channel__button')}>
            {channel.isActive ? <icons.VisibilityOnIcon /> : <icons.VisibilityOffIcon />}
          </button>
        </div>
      </div>
      <div className={cn('channel__controls')}>
        <Range
          type="range"
          value={[minValue, maxValue]}
          min={0}
          max={255}
          step={1}
          label="Min-Max"
          onChange={onRangeChange}
          labelBlockClassName={cn('channel__controls-range')}
          infoBlockClassName={cn('channel__controls-range-info')}
          labelClassName={cn('channel__controls-range-label')}
          inputClassName={cn('channel__controls-input')}
          className={cn('channel__controls-range-input')}
          fixedValue={0}
          rangeHeight={2}
          disabled={!channel.isActive}
        />
        <ColorPicker {...colorPickerProps} />
      </div>
    </div>
  );
};

export default FiltersModal;
