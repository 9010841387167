import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// uncomment when backend could support custom beads
// import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames/bind';

import { EChannelName } from '@/helpers/channels';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import { themeOptions } from '@/types/theme';

import Select from '@/components/common/Select';

import mainStyles from '../Preprocessing.module.scss';
import styles from './StepInit.module.scss';
import SelectChannel from '../Cytokine/SelectChannel';
import BeadSelect from './BeadSelect';

const cnMain = classnames.bind(mainStyles);
const cn = classnames.bind(styles);

const COUNT_BEADS = 12;

const beadsDefault: TOption[] = [];
const customBeads: TBead[] = [];

// uncomment when backend could support custom beads
Array.from({ length: COUNT_BEADS }).forEach((_, i) => {
  const beadOption: TOption = { label: `${i + 1}`, value: i + 1 };
  // const customBead = { name: `Custom ${i + 1}`, uuid: uuidv4(), isEditable: true };
  beadsDefault.push(beadOption);
  // customBeads.push(customBead);
});

const Beads: FC = () => {
  const dispatch = useDispatch();
  const beadsCount = useSelector(preprocessingSelectors.selectBeadsCount);
  const beadChannel = useSelector(preprocessingSelectors.selectBeadChannel);
  const selectedBeadTypes = useSelector(preprocessingSelectors.selectBeadTypes);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const prevBeadIdList = useMemo(
    () => [...new Set(datasets.map((dataset) => dataset.markerList?.map((marker) => marker.id) ?? []).flat()).values()],
    [datasets]
  );

  useEffect(() => {
    onBeadCountChange(prevBeadIdList.length);
  }, [prevBeadIdList]);

  const onSelectBeadType = useCallback(
    (bead: TBead, index: number) => {
      if (selectedBeadTypes[index]) {
        dispatch(preprocessingActions.changeBeadTypeByIndex({ index, data: bead }));
        return;
      }

      dispatch(preprocessingActions.addBeadType(bead));
    },
    [selectedBeadTypes]
  );

  const onBeadCountChange = useCallback(
    (value: number) => {
      dispatch(preprocessingActions.setBeadsCount(value));
      if (value < selectedBeadTypes.length) {
        const updated = [...selectedBeadTypes];
        updated.length = value;
        dispatch(preprocessingActions.setBeadTypes(updated));
      }
    },
    [selectedBeadTypes, prevBeadIdList]
  );

  const onBeadChannelChange = useCallback((channelName: string) => {
    dispatch(preprocessingActions.setBeadChannel(channelName));
  }, []);

  return (
    <div className={cnMain('question-block')}>
      <h1 className={cnMain('question-block__title')}>2. Specify how many bead types were used in the experiment</h1>
      <div className={cnMain('question-block__content')}>
        <div className={cn('beads-settings')}>
          <div className={cn('beads-setting', 'beads-number')}>
            <span className={cn('beads-setting__title')}>Number of bead types:</span>
            <Select
              theme={themeOptions.light}
              className={cn('beads-settings-select')}
              options={beadsDefault}
              onChange={onBeadCountChange}
              value={beadsCount}
              placeholder="Select"
              controlClassName={cn('beads-settings-select__control')}
              dropDownClassName={cn('beads-settings-select__dropdown')}
              valueContainerClassName={cn('beads-settings-select__value-container')}
              placeholderClassName={cn('beads-settings-select__placeholder')}
            />
          </div>
          <SelectChannel
            onChange={onBeadChannelChange}
            value={beadChannel}
            datasets={datasets}
            autoSelectedChannel={EChannelName.red}
          />
        </div>
        {!!beadsCount && (
          <div className={cn('beads-types')}>
            <span className={cn('beads-types__title')}>Name bead types: (optional)</span>
            <div className={cn('beads-types__selects')}>
              {Array.from({ length: beadsCount }).map((_, i) => {
                const key = i;
                return (
                  <BeadSelect key={key} customBeads={customBeads} selectIndex={i} onSelectBeadType={onSelectBeadType} />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Beads);
