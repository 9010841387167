import { select as d3Select } from 'd3-selection';

import { EAxesScaleType } from '@/types/charts';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { getMinMax } from '@/helpers/arrays';

import { TLineData } from './polarGate';
import { getXPointOnChart, getYPointOnChart } from './common';

const RANGE_GATE_MIDDLE_CONNECTOR_TOP_GAP_PCT = 0.2;
const POLYGONS_LIST_LENGTH_WITH_MIDDLE_LINE = 6;

export const DEFAULT_GAP_BETWEEN_LANES = 10;
export const MIN_GAP_BETWEEN_LINES = 1;

export type TRangeLineData = Omit<TLineData, 'offsetAngle'> & {
  isMiddleLine?: boolean;
};

const getLineData = (
  startPolygon: TGatePolygon,
  endPolygon: TGatePolygon,
  shapesContainerParameters: DOMRect
): TRangeLineData => {
  const lineData = {
    x1: startPolygon.x,
    x2: endPolygon.x,
    y1: startPolygon.y === Infinity ? 0 : shapesContainerParameters.height, // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
    y2: endPolygon.y === Infinity ? 0 : shapesContainerParameters.height, // the y values are inverted because the coordinates are counted not from the lower left corner, but from the upper
  };

  return lineData;
};

export const prepareLinesDataList = ({
  shapesContainerParameters,
  gatePolygons,
  yPoint,
}: {
  shapesContainerParameters: DOMRect;
  gatePolygons: TGatePolygons;
  yPoint?: number;
}) => {
  const lanesData: TRangeLineData[] = [];

  const isPolygonsListIncludesMiddleLineData = gatePolygons.length === POLYGONS_LIST_LENGTH_WITH_MIDDLE_LINE;

  for (let i = 0; i < gatePolygons.length; i++) {
    if (i % 2 === 0) {
      const { y } = gatePolygons[i];
      const nextPoints = gatePolygons[i + 1] ?? null;
      const lastPolygonsPairIndex = POLYGONS_LIST_LENGTH_WITH_MIDDLE_LINE - 2;
      const isMiddleLinePolygons = i >= lastPolygonsPairIndex;

      if (!nextPoints) break;

      const lineData = getLineData(gatePolygons[i], nextPoints, shapesContainerParameters);

      if (isMiddleLinePolygons) {
        lineData.y1 = y;
        lineData.y2 = y;
        lineData.isMiddleLine = true;
      }

      lanesData.push(lineData);
    }
  }

  // prepare data for the middle line if the server does not send y coordinate for it
  if (!isPolygonsListIncludesMiddleLineData) {
    const defaultMiddleLineYPosition = shapesContainerParameters.height * RANGE_GATE_MIDDLE_CONNECTOR_TOP_GAP_PCT;

    const middleLanePoints = {
      x1: gatePolygons[0].x,
      x2: gatePolygons[3].x,
      y1: yPoint ?? defaultMiddleLineYPosition,
      y2: yPoint ?? defaultMiddleLineYPosition,
      isMiddleLine: true,
    };

    lanesData.push(middleLanePoints);
  }

  return lanesData;
};

export const getRangeGateLinesData = (gateId: string, plotId: string): Nullable<TRangeLineData[]> => {
  const lines = d3Select(`#${plotId}`).selectAll(`[gate-id = 'gate_${gateId}']`).nodes() as Element[];
  const linesData: TRangeLineData[] = [];

  lines.forEach((line) => {
    if (!line) return;
    const x1 = parseFloat(d3Select(line).attr('x1'));
    const x2 = parseFloat(d3Select(line).attr('x2'));
    const y1 = parseFloat(d3Select(line).attr('y1'));
    const y2 = parseFloat(d3Select(line).attr('y2'));
    const isMiddleLine = d3Select(line).attr('middle-line');

    const lineData = { x1, x2, y1, y2, ...(isMiddleLine && { isMiddleLine: true }) };

    linesData.push(lineData);
  });

  if (!linesData?.length) return null;
  return linesData;
};

export const getPolygonsForRangeGate = ({
  linesData,
  plotId,
  chartContainerParameters,
  plotRange,
  xAxisScaleType,
  yAxisScaleType,
  origDataRange,
}: {
  linesData: TRangeLineData[];
  plotId: string;
  chartContainerParameters: DOMRect;
  plotRange: TPopulationRange;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  origDataRange?: TPlotAxisRange;
}): Nullable<{ x1: number; x2: number; y: number }> => {
  const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

  if (!shapesContainer) return null;
  const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn

  if (!shapesContainerParameters || !chartContainerParameters) return null;

  const middleLineData = linesData[linesData.length - 1];
  const linesDataWithoutMiddleConnector = linesData.slice(0, 2); // get all lines except the middle one
  const xPoints = linesDataWithoutMiddleConnector.map((item) => [item.x1, item.x2]).flat();
  const { min: xMin, max: xMax } = getMinMax(xPoints);

  const xPayload = {
    point: xMin,
    containerSize: shapesContainerParameters.width,
    chartMinValue: plotRange?.xMin,
    chartMaxValue: plotRange?.xMax,
  };

  const yPayload = {
    point: middleLineData.y1,
    containerSize: shapesContainerParameters.height,
    chartMinValue: plotRange?.yMin,
    chartMaxValue: plotRange?.yMax,
  };

  const xMinOnChart = getXPointOnChart(xPayload);
  const xMaxOnChart = getXPointOnChart({ ...xPayload, point: xMax });
  const yPointOnChart = getYPointOnChart({
    ...yPayload,
  });

  const polygons = [
    { x: xMinOnChart, y: 0 },
    { x: xMaxOnChart, y: 0 },
  ];

  const convertedPoints = axisScaleHelper.getRealGatePoints({
    xAxisScaleType,
    yAxisScaleType,
    gatePoints: polygons,
    plotLinearRange: plotRange,
    origDataRange,
  });

  return { x1: convertedPoints[0].x, x2: convertedPoints[1].x, y: yPointOnChart };
};

export const getUpdatedLinesData = (
  [xPoint, yPoint]: [number, number],
  linesData: TRangeLineData[],
  changedXValue?: number
) => {
  const startX = linesData[0].x1;
  const isBothXAreEqual = xPoint !== changedXValue && xPoint === startX;
  const newX = isBothXAreEqual ? startX + MIN_GAP_BETWEEN_LINES : xPoint;
  const yValues = linesData.map(({ y1, y2 }) => [y1, y2]).flat();

  const { min: minY, max: maxY } = getMinMax(yValues);

  const updatedLinesData = linesData.map((item: TRangeLineData) => {
    const newX1 = item.x1 === changedXValue ? newX : item.x1;
    const newX2 = item.x2 === changedXValue ? newX : item.x2;
    const newPoins = {
      ...item,
      x1: newX1,
      x2: newX2,
    };

    if (item?.isMiddleLine) {
      const isOutTheRange = yPoint > maxY || yPoint < minY;
      const yForMiddleLine = isOutTheRange ? item.y1 : yPoint;

      newPoins.y1 = yForMiddleLine;
      newPoins.y2 = yForMiddleLine;
    }

    return newPoins;
  });

  return updatedLinesData;
};
