import { FC } from 'react';
import classNames from 'classnames/bind';
import Select from '@/components/common/Select';
import { TScanTotal } from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/types';
import { themeOptions } from '@/types/theme';
import TimeInput, { ETimeInputUnit } from '@/components/common/TimeInput';
import NumberInput from '@/components/common/NumberInput';
import Table from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/components/Table';
import styles from '../MainBlock.module.scss';
import { scanSelectorOptions } from '../constants';

const cn = classNames.bind(styles);

type TMultiScanContentProps = {
  scanOrder: number;
  scanTotal: {
    value: string;
    onChange: (value: TScanTotal) => void;
  };
  scanFirstScanTimeHour: {
    value: number;
    onChange: (value: number) => void;
  };
  scanFirstScanTimeMinute: {
    value: number;
    onChange: (value: number) => void;
  };
  scanIntervalHour: {
    value: number;
    onChange: (value: number) => void;
  };
  scanIntervalMinute: {
    value: number;
    onChange: (value: number) => void;
  };
  scanNumberOfScans: {
    value: number;
    onChange: (value: number) => void;
  };
};

const MultiScanContent: FC<TMultiScanContentProps> = ({
  scanOrder,
  scanTotal,
  scanFirstScanTimeHour,
  scanFirstScanTimeMinute,
  scanIntervalHour,
  scanIntervalMinute,
  scanNumberOfScans,
}) => (
  <div className={cn('main-block__content')}>
    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title')}>scans total</div>
          <Select
            options={scanSelectorOptions}
            value={scanTotal.value}
            onChange={scanTotal.onChange}
            theme={themeOptions.light}
            placeholder="Select scan total..."
            className={cn('main-block__select')}
            controlClassName={cn('main-block__select-control')}
            menuClassName={cn('main-block__select-menu')}
            menuPosition="absolute"
            isPortalable={false}
          />
        </div>
      </div>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group', 'main-block__group_time')}>
          <div className={cn('main-block__block-title')}>scan interval</div>
          <TimeInput
            theme={themeOptions.light}
            onChange={scanIntervalHour.onChange}
            value={scanIntervalHour.value}
            unit={ETimeInputUnit.hour}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
          <TimeInput
            theme={themeOptions.light}
            onChange={scanIntervalMinute.onChange}
            value={scanIntervalMinute.value}
            unit={ETimeInputUnit.minute}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
        </div>
        <div className={cn('main-block__group', 'main-block__group_scan-count')}>
          <div className={cn('main-block__block-title')}>scans num.</div>
          <NumberInput
            theme={themeOptions.light}
            onChange={scanNumberOfScans.onChange}
            value={scanNumberOfScans.value}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
        </div>
      </div>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group', 'main-block__group_time')}>
          <div className={cn('main-block__block-title')}>first scan start</div>
          <TimeInput
            theme={themeOptions.light}
            onChange={scanFirstScanTimeHour.onChange}
            value={scanFirstScanTimeHour.value}
            unit={ETimeInputUnit.hour}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
          <TimeInput
            theme={themeOptions.light}
            onChange={scanFirstScanTimeMinute.onChange}
            value={scanFirstScanTimeMinute.value}
            unit={ETimeInputUnit.minute}
            className={cn('main-block__input-wrap')}
            inputClassName={cn('main-block__input')}
          />
        </div>
      </div>
    </div>

    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title')}>suggested interval (based on lanes number)</div>
          <span className={cn('main-block__block-text')}>4-6 hours</span>
        </div>
      </div>
      <Table
        heading={['scan number', 'scan type']}
        content={[
          [
            <span className={cn('main-block__block-text')} key={6}>
              {scanOrder}
            </span>,
            <span className={cn('main-block__scan-type')} key={61}>
              After step completion
            </span>,
          ],
          [
            <span className={cn('main-block__block-text')} key={7}>
              {scanNumberOfScans.value === 2 && scanOrder + 1}
              {scanNumberOfScans.value !== 2 && `${scanOrder + 1}-${scanOrder + scanNumberOfScans.value - 1}`}
            </span>,
            <span className={cn('main-block__scan-type', 'main-block__scan-type_secondary')} key={71}>
              {scanNumberOfScans.value === 2 && 'Interval scan'}
              {scanNumberOfScans.value !== 2 && 'Interval scans'}
            </span>,
          ],
        ]}
      />
    </div>
  </div>
);

export default MultiScanContent;
