import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { getDatasetChannelNameByChannelName } from '@/helpers/channels';
import usePredefineCytokineGates from '@/hooks/preprocessing/usePredefineCytokineGates';

import { preprocessingSelectors, preprocessingActions } from '@/store/slices/preprocessing';
import { gatesActions } from '@/store/slices/gates';
import { EStepName } from '@/store/slices/preprocessing/types';
import { EEntityObjectType, chartSettingsActions } from '@/store/slices/chartSettings';

import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';

import PreprocessingDatasetChart from '../../components/PreprocessingDatasetChart';
import StepActions, { TAction, TActionGroup } from '../../components/StepActions';

import SelectChannel from '../SelectChannel';

import styles from '../../PreprocessingSteps.module.scss';
import PreprocessingHeader from '../../components/PreprocessingHeader';
import PreprocessingStepHeader from '../../components/PreprocessingStepHeader';
import CreateGatePopover from '../CreateGatePopover';

import Gates from '../Gates';

const cn = classnames.bind(styles);

type TStepCytokineDefineBeadsProps = {
  className?: string;
};

const StepCytokineDefineBeads: FC<TStepCytokineDefineBeadsProps> = ({ className }) => {
  const appDispatch = useAppDispatch();

  const beadsCount = useSelector(preprocessingSelectors.selectBeadsCount);
  const beadChannel = useSelector(preprocessingSelectors.selectBeadChannel);
  const generalBeadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);
  const isStepCytokineReviewBeadsCompleted = useSelector(
    preprocessingSelectors.selectIsStepCytokineReviewBeadsCompleted
  );
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const defaultDataset = useSelector(preprocessingSelectors.selectCurrentDataset);

  const datasetDetails = useMemo(() => {
    const dataset = datasets.find((el) => !!getDatasetChannelNameByChannelName(el.channelList, beadChannel));
    return dataset;
  }, [beadChannel, datasets]);

  const { isLoading } = usePredefineCytokineGates(datasetDetails ?? defaultDataset);

  const isPluralSymbol = useMemo(() => (beadsCount > 1 ? 's' : ''), [beadsCount]);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepInit));
        },
      },
      {
        label: 'Next',
        disabled: !isStepCytokineReviewBeadsCompleted,
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCytokineReviewBeads));
        },
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isStepCytokineReviewBeadsCompleted]);

  const createGate: TGeneralChartProps['createGate'] = useCallback(
    (gate) => {
      if (!datasetDetails) {
        return;
      }

      const { gateNodes, level, shape, parentId, properties, xDimension, yDimension } = gate.newGate;
      appDispatch(
        preprocessingActions.addGeneralBeadShape({
          gateNodes,
          level,
          shape,
          parentId,
          properties,
          xDimension,
          yDimension,
        })
      );
    },
    [appDispatch]
  );

  const updateGate: TGeneralChartProps['updateGate'] = useCallback(
    (data) => {
      const { gateId, updatedGateList } = data;
      const updatedGate = updatedGateList.find((el) => el.id === gateId);
      if (!updatedGate) {
        return;
      }

      const { gateNodes, level, parentId, shape, properties, name, xDimension, yDimension } = updatedGate;

      appDispatch(
        preprocessingActions.updateGeneralBeadShape({
          beadShapeId: gateId,
          data: { gateNodes, level, parentId, shape, properties, name, xDimension, yDimension },
        })
      );
    },
    [appDispatch]
  );

  const deleteGate: TGeneralChartProps['deleteGate'] = useCallback(
    (data) => {
      const { gateId } = data;
      appDispatch(preprocessingActions.deleteGeneralBeadShape(gateId));
    },
    [appDispatch]
  );

  const deleteGateChildren: TGeneralChartProps['deleteGateChildren'] = useCallback(
    (data) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    [appDispatch]
  );

  useEffect(() => {
    const isBlockDraw = beadsCount === Object.keys(generalBeadShapes).length;
    appDispatch(gatesActions.setIsBlockDraw(isBlockDraw));
  }, [beadsCount, generalBeadShapes]);

  useEffect(() => {
    appDispatch(chartSettingsActions.setObjectType(EEntityObjectType.isBead));
  }, []);

  const title = useMemo(
    () => `STEP 1. Define gates for ${beadsCount} bead-type population${isPluralSymbol}.`,
    [beadsCount]
  );

  const instructions = useMemo(
    () => [
      `Use click-and-drag functionality on the graph to define ${beadsCount} bead-type population ${isPluralSymbol} and create ${beadsCount} bead-type population${isPluralSymbol} gate${isPluralSymbol}`,
      `These gate${isPluralSymbol} will be applied to all datasets in this processing batch`,
    ],
    [beadsCount]
  );

  return (
    <div className={cn('preprocessing-step', className)}>
      <div className={cn('preprocessing-step__header')}>
        <PreprocessingHeader />
        <PreprocessingStepHeader title={title} instructions={instructions} />
      </div>
      <div className={cn('preprocessing-step__content')}>
        <PreprocessingDatasetChart
          createGate={createGate}
          deleteGate={deleteGate}
          updateGate={updateGate}
          deleteGateChildren={deleteGateChildren}
          onlyShowAxisValue
          disabled={isLoading}
          datasetDetails={datasetDetails ?? defaultDataset}
          headingChildren={<SelectChannel value={beadChannel} disabled />}
          GatesComponent={Gates}
          CustomCreateGateComponent={CreateGatePopover}
        />
        <StepActions actionGroups={actionGroups} />
      </div>
    </div>
  );
};

export default memo(StepCytokineDefineBeads);
