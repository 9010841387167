import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { getEntityObjectTypeOptionList } from '@/helpers/cages';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { chartSettingsSelectors, chartSettingsActions, EEntityObjectType } from '@/store/slices/chartSettings';

import EntityLevelSwitch from '@/components/experiment/EntityLevelSwitch';
import GateAutoProcessing from '@/components/experiment/GateAutoProcessing';
import OpenNavigatorButton from '@/components/navigator/OpenNavigatorButton';
import DownloadStatistics from '@/components/experiment/DownloadStatistics';
import GeneratePrimaryAnalysisGatesButton from '@/components/experiment/GeneratePrimaryAnalysisGatesButton';

import Select from '../Select';

import styles from './ExperimentPageHeader.module.scss';

const cn = classnames.bind(styles);

type TExperimentPageDefaultHeaderControls = {
  buttonClassName?: string;
  selectClassName?: string;
};

const ExperimentPageDefaultHeaderControls: FC<TExperimentPageDefaultHeaderControls> = ({
  buttonClassName,
  selectClassName,
}) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));

  const entityObjectTypeOptions = useMemo<TOption[]>(() => getEntityObjectTypeOptionList(), []);

  const handleEntityObjectTypeChange = useCallback(
    (newObjectType: EEntityObjectType) => {
      appDispatch(chartSettingsActions.setObjectType(newObjectType));
    },
    [objectType, appDispatch]
  );

  return (
    <>
      <OpenNavigatorButton className={cn('button', buttonClassName)} />
      {isObjectEntityEnabled && !isCageLvlForced && <GateAutoProcessing />}
      <GeneratePrimaryAnalysisGatesButton />
      <DownloadStatistics />
      <EntityLevelSwitch />
      {isObjectEntityEnabled && !isCageLvlForced && (
        <Select
          className={cn('select', selectClassName)}
          theme={themeOptions.light}
          options={entityObjectTypeOptions}
          defaultValue={objectType}
          onChange={handleEntityObjectTypeChange}
          value={objectType}
        />
      )}
    </>
  );
};

export default memo(ExperimentPageDefaultHeaderControls);
