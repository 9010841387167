import { FC, MouseEvent, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';

import { isCompoundConsumable, isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';

import Removable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/Removable';
import { TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import styles from './Consumable.module.scss';
import ReagentWaves from './ConsumableWaves';

const cn = classNames.bind(styles);

type TConsumableComponent = {
  consumable: TRunDesignConsumable;
  className?: string;
  wavesClassName?: string;
  onClick?: Nullable<(consumable: TRunDesignConsumable, e: MouseEvent<HTMLButtonElement>) => void>;
  withWaves?: boolean;
  deleteReagent?: () => void;
};

const ConsumableComponent: FC<TConsumableComponent> = ({
  consumable,
  onClick,
  className,
  wavesClassName,
  withWaves = true,
  deleteReagent,
}) => {
  const opticalDisplay = useMemo(
    () => (isReagentConsumable(consumable) || isStainConsumable(consumable) ? consumable?.opticalDisplay : null),
    [consumable]
  );

  const removeReagent = () => {
    deleteReagent?.();
  };

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(consumable, e);
    },
    [onClick, consumable]
  );

  return (
    <>
      {onClick && (
        <button onClick={handleClick} className={cn('consumable-wrap', className)}>
          <Removable onRemove={removeReagent} className={cn('consumable')}>
            <span className={cn('consumable__name')}>{consumable?.name}</span>
            {withWaves && consumable?.name && !isCompoundConsumable(consumable) && (
              <ReagentWaves className={cn('consumable__waves', wavesClassName)} opticalDisplay={opticalDisplay} />
            )}
          </Removable>
        </button>
      )}
      {!onClick && (
        <div className={cn('consumable-wrap', className)}>
          <div className={cn('consumable')}>
            <span className={cn('consumable__name')}>{consumable?.name}</span>
            {withWaves && consumable?.name && !isCompoundConsumable(consumable) && (
              <ReagentWaves className={cn('consumable__waves', wavesClassName)} opticalDisplay={opticalDisplay} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ConsumableComponent;
