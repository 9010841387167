import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { navigatorSelectors } from '@/store/slices/navigator';
import { experimentSelectors } from '@/store/slices/experiment';

import LaneController, { TLaneControllerProps } from './LaneController';
import styles from './Lanes.module.scss';

const cn = classnames.bind(styles);

const Lanes: FC = () => {
  const navigatorLanesSettings = useSelector(navigatorSelectors.selectLanesSettings);
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const currentScan = useSelector(experimentSelectors.selectScan(currentScanId));

  const currentScanLanesProps = useMemo(
    () =>
      (currentScan?.lanes
        .map((lane) => {
          const laneSettings = navigatorLanesSettings[lane.id];
          if (!laneSettings) {
            return;
          }
          const { position, boundary, viewport } = laneSettings;
          return {
            laneId: lane.id,
            label: lane.letterName,
            viewportWidth: viewport.width,
            xNormal: position.x / viewport.width || 0,
            isActive: lane.id === currentLaneId,
            widthNormal: boundary.right - boundary.left,
          };
        })
        .filter((laneSettings) => laneSettings !== undefined) as TLaneControllerProps[]) ?? [],
    [currentScan, navigatorLanesSettings]
  );

  return (
    <div className={cn('sidebar__lanes-container')}>
      {currentScanLanesProps.map((laneProps) => (
        <LaneController {...laneProps} key={laneProps.laneId} />
      ))}
    </div>
  );
};

export default memo(Lanes);
