import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

import PageHeader from '@/components/Layout/Page/PageHeader';
import SearchInput from '@/components/common/SearchInput';
import ControlPanel from '@/components/Layout/ControlPanel';
import NoDataFound from '@/components/common/NoDataFound';

import { searchInstruments } from '@/helpers/instruments/searchInstruments';
import { useOpenModal } from '@/hooks/useOpenModal';

import * as queries from '@/graphql/queries';

import styles from './InstrumentsDashboard.module.scss';
import InstrumentCard from './components/InstrumentCard';
import TelemetryModal from './components/TelemetryModal';

const cn = classnames.bind(styles);

const InstrumentsDashboard: FC = () => {
  const [currentInstrumentData, setCurrentInstrumentData] = useState<Nullable<TInstrumentFromServer>>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { isOpen, setIsOpen, onClose } = useOpenModal();

  const openTelemetryModal = (instrument: TInstrumentFromServer) => {
    setCurrentInstrumentData(instrument);
    setIsOpen(true);
  };

  const closeTelemetryModal = () => {
    setCurrentInstrumentData(null);
    onClose();
  };

  const {
    data,
    loading: isInstrumentListLoading,
    error: isInstrumentListError,
  } = useQuery(queries.instruments, {
    variables: {
      limit: 50,
    },
  });

  const foundInstruments = useMemo(
    () => searchInstruments(searchQuery, data?.instruments ?? []),
    [searchQuery, data?.instruments]
  );

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };
  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    setCurrentInstrumentData(null);
    onClose();
  }, []);

  const isEmptyData = useMemo(
    () => !isInstrumentListLoading && !isInstrumentListError && !foundInstruments?.length,
    [isInstrumentListLoading, isInstrumentListError, foundInstruments?.length]
  );

  const getInstrumentsCards = () => {
    if (isEmptyData || isInstrumentListError) {
      return (
        <NoDataFound
          size="normal"
          alignment="center"
          textData={isInstrumentListError ? 'Something went wrong' : 'No data found'}
        />
      );
    }

    return (
      <div className={cn('dashboard__items_cards')}>
        {foundInstruments?.map((instrument) => (
          <InstrumentCard
            key={instrument.id}
            instrument={instrument}
            searchQuery={searchQuery}
            onClick={openTelemetryModal}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content')}>
        <PageHeader>
          <PageHeader.Section>
            <PageHeader.Title as="h1">Instruments Dashboard</PageHeader.Title>
          </PageHeader.Section>
          <PageHeader.Section>
            <PageHeader.Title isStatistics>{foundInstruments?.length}</PageHeader.Title>
            <PageHeader.Subtitle>{foundInstruments?.length === 1 ? 'Instrument' : 'Instruments'}</PageHeader.Subtitle>
          </PageHeader.Section>
        </PageHeader>
        <ControlPanel isLoading={isInstrumentListLoading} className={cn('control-panel')}>
          <ControlPanel.RightActions>
            <SearchInput
              onChange={handleSearchInputChange}
              onReset={handleResetSearchClick}
              value={searchQuery}
              disabled={isInstrumentListLoading}
              className={cn('control-panel__search')}
            />
          </ControlPanel.RightActions>
        </ControlPanel>
        {getInstrumentsCards()}
        {currentInstrumentData && (
          <TelemetryModal isOpen={isOpen} onClose={closeTelemetryModal} instrument={currentInstrumentData} />
        )}
      </div>
    </div>
  );
};

export default InstrumentsDashboard;
