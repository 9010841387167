import { SVGProps } from 'react';

const InstrumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="60" height="42" viewBox="0 0 60 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="0.5"
      y="-0.5"
      width="59"
      height="40.25"
      rx="4"
      transform="matrix(1 0 0 -1 0 40.25)"
      fill="white"
      stroke="#CCCCCC"
    />
    <rect
      x="0.5"
      y="-0.5"
      width="33"
      height="40.25"
      rx="4"
      transform="matrix(1 0 0 -1 0 40.25)"
      fill="white"
      stroke="#CCCCCC"
    />
    <rect
      x="0.5"
      y="-0.5"
      width="27"
      height="14"
      transform="matrix(1 0 0 -1 3 24.125)"
      fill="currentColor"
      stroke="#CCCCCC"
    />
  </svg>
);

export default InstrumentIcon;
