import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { API as awsAPI } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';
import classnames from 'classnames/bind';

import { EJobRunType } from '@/types/jobRuns';

import { isJobRunInProgress } from '@/helpers';
import ProgressToast from '@/helpers/progressToast';

import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';
import useFindSucceededJobRuns from '@/hooks/jobRuns/useFindSucceededJobRuns';

import * as queries from '@/graphql/queries';
import * as subscriptions from '@/graphql/subscriptions';
import {
  JobRunEdge,
  JobRunStatus,
  JobRunStatusChangedSubscription,
  JobRunStatusChangedEventInput,
  ListJobRunsInput,
} from '@/graphql/API';

import { appAPI } from '@/store/services/app';
import { experimentSelectors } from '@/store/slices/experiment';

import Button from '@/components/common/Button';

import styles from '../../SequencingData.module.scss';

const cn = classnames.bind(styles);

type TSequencingDataProps = {
  laneDetails: TLaneDetails;
  sequencingDataGenome: string;
  isSequencingDataSaved: boolean;
};

const SeuratGenerationProcessButton: FC<TSequencingDataProps> = ({
  laneDetails,
  sequencingDataGenome,
  isSequencingDataSaved,
}) => {
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const seuratProgressToast = useMemo(
    () =>
      new ProgressToast(
        (
          <span>
            Seurat job run generation for the lane {laneDetails.letterName} started.
            <br />
            We will notify you when it&apos;s done.
          </span>
        ),
        `Seurat job run for the lane ${laneDetails.letterName} is generated.`,
        `Seurat job run generation for the lane ${laneDetails.letterName} has failed.`
      ),
    [laneDetails.letterName]
  );

  const starSoloProgressToast = useMemo(
    () =>
      new ProgressToast(
        (
          <span>
            STARsolo job run for the lane {laneDetails.letterName} started.
            <br />
            We will notify you when it&apos;s done.
          </span>
        ),
        `STARsolo job run for the lane ${laneDetails.letterName} finished.`,
        `STARsolo job run for the lane ${laneDetails.letterName} has failed.`
      ),
    [laneDetails.letterName]
  );

  const jobRunStatusSubscription = useRef<Nullable<GraphQLSubscription<JobRunStatusChangedSubscription>>>(null);

  const [isSeuratGenerationInProgress, setIsSeuratGenerationInProgress] = useState(false);

  const [generateSeuratJob] = appAPI.useGenerateSeuratJobMutation();

  const {
    hasSucceededJobRun: hasSucceededStarSoloJobRun,
    hasJobRunInProgress: hasStarSoloJobRunInProgress,
    restartSucceededJobRunsSearch,
  } = useFindSucceededJobRuns(
    EJobRunType.starSolo,
    experimentData?.id ?? '',
    experimentData?.organizationId,
    laneDetails.id
  );

  const { edgeList: seuratJobRunEdgeList, isLoading: isSeuratJobRunListLoading } = useGraphqlQueryFullList<
    ListJobRunsInput,
    JobRunEdge
  >({
    query: queries.jobRuns,
    dataName: 'jobRuns',
    input: {
      tags: [
        { key: 'job_type', value: EJobRunType.seuratGeneration },
        { key: 'experiment_id', value: experimentData?.id ?? '' },
        { key: 'lane_id', value: laneDetails.id },
      ],
    },
  });

  const isSeuratGenerationDisabled = useMemo(
    () =>
      !isSequencingDataSaved ||
      !hasSucceededStarSoloJobRun ||
      isSeuratJobRunListLoading ||
      isSeuratGenerationInProgress,
    [isSequencingDataSaved, hasSucceededStarSoloJobRun, isSeuratJobRunListLoading, isSeuratGenerationInProgress]
  );

  const unsubscribeJobRunStatus = () => {
    if (!jobRunStatusSubscription.current) {
      return;
    }
    // @ts-ignore
    jobRunStatusSubscription.current.unsubscribe();
    jobRunStatusSubscription.current = null;
  };

  const subscribeJobRunStatus = async (jobRunId: string) => {
    unsubscribeJobRunStatus();
    jobRunStatusSubscription.current = await awsAPI
      .graphql<GraphQLSubscription<JobRunStatusChangedSubscription>>({
        query: subscriptions.jobRunStatusChanged,
        variables: { organizationId: experimentData?.organizationId, jobRunId },
      })
      // @ts-ignore
      .subscribe({
        next: ({
          provider: _,
          value,
        }: {
          provider: unknown;
          value: { data: { jobRunStatusChanged: JobRunStatusChangedEventInput } };
        }) => {
          if (value.data.jobRunStatusChanged) {
            const { status } = value.data.jobRunStatusChanged.payload;
            if (!isJobRunInProgress(status)) {
              setIsSeuratGenerationInProgress(false);
              if (status === JobRunStatus.SUCCEEDED) {
                seuratProgressToast.showSuccessMessage();
              } else {
                seuratProgressToast.showErrorMessage();
              }
            }
          }
        },
        error: ({ provider: _, error }: { provider: unknown; error: unknown }) => {
          setIsSeuratGenerationInProgress(false);
          seuratProgressToast.showErrorMessage(error);
        },
      });
  };

  const handleSeuratGenerationProcessClick = async () => {
    setIsSeuratGenerationInProgress(true);
    seuratProgressToast.showLoadingMessage();
    generateSeuratJob({
      experimentId: experimentData?.id,
      laneId: laneDetails.id,
      sampleId: laneDetails.sampleName,
      genome: sequencingDataGenome,
    })
      .unwrap()
      .then((result) => {
        const { id } = result as { id: string };
        subscribeJobRunStatus(id ?? '');
      })
      .catch((error) => {
        setIsSeuratGenerationInProgress(false);
        seuratProgressToast.showErrorMessage(error);
      });
  };

  useEffect(() => {
    if (hasStarSoloJobRunInProgress) {
      starSoloProgressToast.showLoadingMessage();
    } else if (hasSucceededStarSoloJobRun) {
      starSoloProgressToast.showSuccessMessage();
    }
    return () => {
      starSoloProgressToast.hideMessage();
    };
  }, [hasStarSoloJobRunInProgress, hasSucceededStarSoloJobRun]);

  useEffect(() => {
    if (isSequencingDataSaved) {
      restartSucceededJobRunsSearch();
    }
  }, [isSequencingDataSaved]);

  useEffect(() => {
    if (!isSeuratGenerationInProgress) {
      unsubscribeJobRunStatus();
    }
  }, [isSeuratGenerationInProgress]);

  useEffect(() => {
    const seuratJobRun = seuratJobRunEdgeList.find((jobRun) => isJobRunInProgress(jobRun.node.status));
    if (seuratJobRun && isJobRunInProgress(seuratJobRun.node.status)) {
      setIsSeuratGenerationInProgress(true);
      seuratProgressToast.showLoadingMessage();
      subscribeJobRunStatus(seuratJobRun.node.id);
    }
  }, [seuratJobRunEdgeList]);

  useEffect(
    () => () => {
      seuratProgressToast.hideMessage();
    },
    []
  );

  if (isSeuratGenerationDisabled) {
    return null;
  }

  return (
    <Button
      color="white"
      className={cn('sequencing-data__process-button')}
      onClick={handleSeuratGenerationProcessClick}
    >
      Update Seurat Data
    </Button>
  );
};

export default memo(SeuratGenerationProcessButton);
