import { FC, useMemo } from 'react';
import Modal from '@/components/common/Modal';
import ApiInfoTable from '@/components/Layout/Footer/ApiInfoModal/components/ApiInfoTable';
import { intAPI } from '@/store/services/intsvc';
import classnames from 'classnames/bind';
import NoDataFound from '@/components/common/NoDataFound';
import { getErrorMessage } from '@/helpers/errors';
import styles from './ApiInfoModal.module.scss';

const cn = classnames.bind(styles);

type TApiInfoModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  apiInfoData?: TInfo;
};

const ApiInfoModal: FC<TApiInfoModalProps> = ({ isOpen, onRequestClose, apiInfoData }) => {
  const { data: statusData, isError, error } = intAPI.useFetchStatusQuery();

  const errorMessage = useMemo(() => (isError ? getErrorMessage(error) : undefined), [isError, error]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={cn('api-info-modal')}>
      <Modal.Header>System Current Information</Modal.Header>
      <Modal.Content className={cn('api-info-modal__content')}>
        {!apiInfoData || !statusData ? (
          <NoDataFound textData={errorMessage} />
        ) : (
          <ApiInfoTable apiInfoData={apiInfoData} statusData={statusData} onRequestClose={onRequestClose} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ApiInfoModal;
