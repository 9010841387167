import { findLaneInScanList } from '@/helpers/scans';
import {
  forceWhiteChannelFirst,
  getScatterPlotCageLevelAxesOptions,
  getScatterPlotObjectLevelAxesOptions,
  sortChannelIdList,
} from '@/helpers/channels';

import { prepareAxesPresetOptionList } from './selectHelpers';

export const selectUniqueChannelIdListFromScanList = (scanList: TScan[], applyFullSort = false) => {
  const channelIdList: string[] = [];
  scanList.forEach((scan) => {
    scan.lanes.forEach((lane) => {
      channelIdList.push(...lane.channels);
    });
  });
  const uniqueChannelIdList = [...new Set(channelIdList)];
  if (applyFullSort) {
    sortChannelIdList(uniqueChannelIdList);
  } else {
    forceWhiteChannelFirst(uniqueChannelIdList);
  }
  return uniqueChannelIdList;
};

export const selectAxesOptionListFromScanList = (
  scans: TScan[] | undefined,
  scanId: Nullable<string>,
  laneId: Nullable<string>,
  isObjectEntityEnabled: boolean,
  channelList?: string[]
) => {
  const lane = findLaneInScanList(scans, scanId, laneId);
  if (lane) {
    return isObjectEntityEnabled
      ? getScatterPlotObjectLevelAxesOptions(channelList ?? lane.channels)
      : getScatterPlotCageLevelAxesOptions(channelList ?? lane.channels);
  }
  return [];
};

export const selectAxesOptionListForMultiLanes = (
  datasetDetailsList: TDatasetDetails[],
  isObjectEntityEnabled: boolean
): TBasicOption[] => {
  const channelIdList = datasetDetailsList.map((datasetDetails) => datasetDetails.channelId);
  const uniqueChannelIdList = [...new Set(channelIdList)];
  let axesChannelIdList: string[] = [];

  if ((uniqueChannelIdList.length === 1 && uniqueChannelIdList[0]) || uniqueChannelIdList.length) {
    axesChannelIdList = uniqueChannelIdList as string[];
  }

  return isObjectEntityEnabled
    ? getScatterPlotObjectLevelAxesOptions(axesChannelIdList)
    : getScatterPlotCageLevelAxesOptions(axesChannelIdList);
};

export const selectAxesPresetOptionListFromScanList = (
  scans: TScan[] | undefined,
  scanId: Nullable<string>,
  laneId: Nullable<string>,
  isObjectEntityEnabled: boolean
) => {
  const lane = findLaneInScanList(scans, scanId, laneId);
  return prepareAxesPresetOptionList(lane?.channels ?? [], isObjectEntityEnabled);
};
