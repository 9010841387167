import { SVGProps } from 'react';

const AssayMRnaExpression = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" version="1.1" viewBox="0 0 130 130" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10">
      <path d="M42 37c0 38.4 46 22.8 46 59M61 57h8M61 78h8M54 48h22M54 86h22M52 39h27M52 94h26" />
    </g>
  </svg>
);

export default AssayMRnaExpression;
