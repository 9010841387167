import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { isNumber } from '@/helpers';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import useGeneralGates from '@/hooks/preprocessing/useGeneralGates';
import {
  EPreprocessingObjectType,
  EStepName,
  TGeneralBeadShape,
  TGeneralCellShape,
} from '@/store/slices/preprocessing/types';

import type { TGateList } from '@/components/charts/SingleChartWithGates/types';
import CheckboxInput from '@/components/common/CheckboxInput';

import styles from './Gates.module.scss';
import BeadCard from '../BeadPopover/BeadCard';

const cn = classnames.bind(styles);

type TBeadsProps = {
  type: EPreprocessingObjectType.beads;
  items: TBead[];
  shapes: Record<string, TGeneralBeadShape>;
};

type TCellsProps = {
  type: EPreprocessingObjectType.cells;
  items: TCell[];
  shapes: Record<string, TGeneralCellShape>;
};

export type TGatesProps = Partial<TGateList> &
  (TBeadsProps | TCellsProps) & {
    headerClassName?: string;
    className?: string;
    count: number;
    withApplyForAllDatasets: boolean;
  };

const stepsWithoutRemovaGateHandler = [
  EStepName.stepCytokineReviewBeads,
  EStepName.stepCellKillingReviewCells,
  EStepName.stepCellKillingReviewCellsTarget,
];

const Gates: FC<TGatesProps> = ({
  isExpandMode,
  headerClassName,
  className,
  entitiesByLanesAndGates = {},
  currentAppLane,
  type,
  items,
  shapes,
  count,
  withApplyForAllDatasets,
}) => {
  const dispatch = useDispatch();
  const datasetDetails = useSelector(preprocessingSelectors.selectCurrentDataset);
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const stepCytokineReviewBeadsCompleted = useSelector(preprocessingSelectors.selectIsStepCytokineReviewBeadsCompleted);
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const applyForAllDatasets = useSelector(preprocessingSelectors.selectApplyForAllDatasets);
  const activeBeadGate = useSelector(gatesSelectors.selectActiveGate);
  const gates = useGeneralGates(datasetDetails.id);
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);

  const busyBeads = useMemo<Record<string, boolean>>(
    () =>
      Object.values(shapes).reduce((acc, el) => {
        acc[el.id] = true;
        return acc;
      }, {} as Record<string, boolean>),
    [shapes]
  );

  const handleClickBead = useCallback(
    (id: string) => {
      const shape = Object.values(shapes).find((el) => el.id === id);
      const gate = gates.find((el) => el.id === shape?.id);
      if (!gate) {
        return;
      }

      if (gate.id === activeBeadGate?.id) {
        dispatch(gatesActions.setActiveGate(null));
        return;
      }

      dispatch(gatesActions.setActiveGate(gate));
    },
    [shapes, gates, activeBeadGate]
  );

  const handleRemoveGateByBeadId = (id: string) => {
    const shape = Object.values(shapes).find((el) => el.id === id);
    if (!shape) {
      return;
    }

    if (type === EPreprocessingObjectType.beads) {
      dispatch(preprocessingActions.deleteGeneralBeadShape(shape.id));
    } else {
      dispatch(
        preprocessingActions.deleteGeneralCellShape({
          id: shape.id,
          parentId: selectedGate ? selectedGate.id : '',
        })
      );
    }
  };

  // TODO: rename handlers to something more generic
  const handleRenameGateByBeadId = (id: string, newName: string) => {
    if (type === EPreprocessingObjectType.cells) return;

    dispatch(
      preprocessingActions.updateGeneralBeadShape({
        beadShapeId: id,
        data: {
          name: newName,
        },
      })
    );
  };

  const handleChangeApplyForAllDatasets = () => {
    dispatch(preprocessingActions.setApplyForAllDatasets(!applyForAllDatasets));
  };

  const memoHandleRemoveGate = useMemo(() => {
    const isHandlerAnUndefined =
      stepCytokineReviewBeadsCompleted && stepsWithoutRemovaGateHandler.includes(currentStep);
    return isHandlerAnUndefined ? undefined : handleRemoveGateByBeadId;
  }, [currentDatasetIndex, handleRemoveGateByBeadId]);

  const memoHandleRenameGate = useMemo(
    () =>
      stepCytokineReviewBeadsCompleted && currentStep !== EStepName.stepCytokineDefineBeads
        ? undefined
        : handleRenameGateByBeadId,
    [currentDatasetIndex, handleRenameGateByBeadId]
  );

  return (
    <div className={cn('gates', { gates_expand: isExpandMode }, className)}>
      <div className={cn('gates__header', headerClassName)}>
        <h3 className={cn('gates__title')}>Gates for {type}</h3>
        <div className={cn('gates__count-wrapper')}>
          <span className={cn('gates__count')}>{count}</span>
        </div>
      </div>
      <div className={cn('gates__items', { gates__items_border: isNumber(currentDatasetIndex) })}>
        {items.map((itemData) => {
          const shape = Object.values(shapes).find((el) => el.id === itemData.uuid);
          const gate = gates.find((el) => el.id === shape?.id);

          const data = {
            ...itemData,
            name: gate?.name ?? itemData?.name,
          };

          const isDisabled = !busyBeads[itemData.uuid];

          return (
            <BeadCard
              item={data}
              type={type}
              key={itemData.uuid}
              gate={gate}
              handleClick={handleClickBead}
              disabled={isDisabled}
              handleRemoveGateByBeadId={memoHandleRemoveGate}
              handleRenameGateByBeadId={(!itemData?.beadSize && memoHandleRenameGate) || undefined}
              selected={activeBeadGate?.id === itemData.uuid && busyBeads[itemData.uuid]}
              entitiesByLanesAndGates={entitiesByLanesAndGates}
              currentAppLane={currentAppLane}
            />
          );
        })}
      </div>
      {withApplyForAllDatasets && (
        <CheckboxInput
          theme="light"
          checked={applyForAllDatasets}
          label="Apply changes to all datasets"
          className={cn('gates__all-datasets', { 'gates__all-datasets_checked': applyForAllDatasets })}
          onChange={handleChangeApplyForAllDatasets}
        />
      )}
    </div>
  );
};

export default memo(Gates);
