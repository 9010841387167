import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import { isNumber } from '@/helpers';
import {
  EStepName,
  TDefaultPreprocessingShape,
  TGeneralBeadShape,
  TGeneralCellShape,
} from '@/store/slices/preprocessing/types';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { gatesActions } from '@/store/slices/gates';

import SelectedDataset from '../../components/SelectedDataset';
import GeneralView from '../../components/GeneralView';

import styles from '../../PreprocessingSteps.module.scss';
import PreprocessingHeader from '../../components/PreprocessingHeader';
import PreprocessingStepHeader from '../../components/PreprocessingStepHeader';
import Gates from '../Gates';
import { TAction, TActionGroup } from '../../components/StepActions';
import { CELL_TYPES_FOR_PUPULATION } from '../constants';

const cn = classnames.bind(styles);

const title = 'STEP 2. Review real cell population gate across all datasets in this processing batch';
const instructions = [
  'Modify gating for specific datasets by clicking on the dataset graph below and adjusting gates on the expanded individual graphs.',
];

const StepCellKillingReviewCells: FC = () => {
  const appDispatch = useAppDispatch();
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const generalCellShapes = useSelector(preprocessingSelectors.selectGeneralCellShapes);
  const isNextStepEnabled = useSelector(preprocessingSelectors.selectNextStepEnabled);

  const updatedDatasetsRef = useRef<Record<string, boolean>>({});

  const [showSelectedDataset, setShowSelectedDataset] = useState(false);
  const [updatedDatasets, setUpdatedDatasets] = useState<Record<string, boolean>>({});

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCellKillingDefineCells));
        },
      },
      {
        label: 'Next',
        disabled: !isNextStepEnabled,
        onClick: () => {
          const gate = Object.values(generalCellShapes)[0];
          appDispatch(gatesActions.setSelectedGate(gate));
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCellKillingDefineCellsTarget));
        },
      },
    ];

    res.push(actionGroup);

    return res;
  }, [generalCellShapes]);

  const handleAddSpecificDatasetShape = useCallback((shapesData: TDefaultPreprocessingShape[]) => {
    appDispatch(preprocessingActions.addSpecificDatasetForCellShapeAllDatasets(shapesData));
  }, []);

  const handleRemoveSpecificDatasetShape = useCallback(
    (datasetDetailsId: string) =>
      appDispatch(preprocessingActions.removeSpecificCellDatasetShape({ datasetId: datasetDetailsId })),
    []
  );
  const handleCompleteShapeChanges = useCallback(
    (shapeData: Record<string, TGeneralBeadShape | TGeneralCellShape>) =>
      appDispatch(preprocessingActions.setGeneralCellShapes(shapeData as Record<string, TGeneralCellShape>)),
    []
  );

  const handleUpdatedDataset = useCallback((datasetId: string, isUpdated: boolean) => {
    updatedDatasetsRef.current[datasetId] = isUpdated;
  }, []);

  useEffect(() => {
    setShowSelectedDataset(isNumber(currentDatasetIndex));
  }, [currentDatasetIndex]);

  useEffect(() => {
    appDispatch(gatesActions.setSelectedGate(null));
    appDispatch(preprocessingActions.setCellTypes(CELL_TYPES_FOR_PUPULATION));
  }, []);

  useEffect(() => {
    appDispatch(gatesActions.setActiveGate(null));
    if (!showSelectedDataset) {
      setUpdatedDatasets({ ...updatedDatasetsRef.current });
    } else {
      setUpdatedDatasets({});
    }
    updatedDatasetsRef.current = {};
  }, [showSelectedDataset]);

  return (
    <>
      <PreprocessingHeader />
      <PreprocessingStepHeader title={title} instructions={instructions} withoutBorder />
      <div className={cn('preprocessing-step__content')}>
        <GeneralView
          className={cn({ 'preprocessing-step_hidden': showSelectedDataset })}
          hideGates={showSelectedDataset}
          GatesComponent={Gates}
          actionGroups={actionGroups}
          label="Real cells gating"
          updatedDatasets={updatedDatasets}
        />
        {/* TODO: temporary solution to prevent chart height from jumping on marker hover */}
        {isNumber(currentDatasetIndex) && (
          <SelectedDataset
            className={cn({ 'preprocessing-step_hidden': !showSelectedDataset })}
            needResetRange={showSelectedDataset}
            isShown={showSelectedDataset}
            GatesComponent={Gates}
            shapes={generalCellShapes}
            handleAddSpecificDatasetShape={handleAddSpecificDatasetShape}
            handleRemoveSpecificDatasetShape={handleRemoveSpecificDatasetShape}
            handleCompleteShapeChanges={handleCompleteShapeChanges}
            handleUpdatedDataset={handleUpdatedDataset}
          />
        )}
      </div>
    </>
  );
};

export default memo(StepCellKillingReviewCells);
