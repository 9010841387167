import OpenSeadragon, { Viewer, CanvasClickEvent } from 'openseadragon';

window.OpenSeadragon = OpenSeadragon;

export default OpenSeadragon;

const tileSourceOfChannel = (lane: TLane, channelName: string) => {
  const getTileUrl = (level: number, x: number, y: number) =>
    `${process.env.REACT_APP_CDN_URL}/${lane.pyramidPath}/${channelName}/${level}/${x}_${y}.${lane.tileFileFormat}`;
  // @ts-ignore
  return new OpenSeadragon.DziTileSource({
    getTileUrl,
    width: lane.totalImgWidth,
    height: lane.totalImgHeight,
    tileSize: lane.tileSize,
    tileOverlap: 0,
    minLevel: lane.minLevel,
    maxLevel: lane.maxLevel,
    fileFormat: lane.tileFileFormat,
  });
};

export const initOpenSeaDragonWithChannels = (
  parent: HTMLDivElement,
  lane: TLane,
  channelNameList: string[],
  isDebug = false
) =>
  channelNameList.map((channelName, index) => {
    const OpenSeaDragonProps = {
      element: parent,
      prefixUrl: '../images/openseadragon/',
      tileSources: tileSourceOfChannel(lane, channelName),
      crossOriginPolicy: 'use-credentials',
      debugMode: isDebug && index === 2,
      debugGridColor: ['#ff0000'],
      minZoomImageRatio: 1,
      gestureSettingsMouse: {
        clickToZoom: false,
        scrollToZoom: true,
      },
      gestureSettingsTouch: {
        clickToZoom: false,
        scrollToZoom: true,
      },
      showNavigationControl: false,
      imageLoaderLimit: 5,
      clickTimeThreshold: 1500,
      clickDistThreshold: 50,
    };

    // @ts-ignore
    return OpenSeadragon(OpenSeaDragonProps);
  });

export type { Viewer, CanvasClickEvent };
