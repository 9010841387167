import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { select as d3Select, selectAll as d3SelectAll } from 'd3-selection';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { gatesSelectors } from '@/store/slices/gates';
import { EGatesLabelFormat } from '@/types/gateSettings';

import { LABEL_MENU_R, TITLE_OFFSET_BY_TYPE } from './constants';
import { updateMenuPosition } from './helpers/common';

type TGatesLabelSettings = {
  entitiesDataByGates?: Nullable<TEntitiesByGates>;
  plotId?: string;
};

export function useGatesLabelSettings({ entitiesDataByGates, plotId }: TGatesLabelSettings) {
  const chartId = usePlotChartIdContext();

  const gateLabelFormat = useSelector(gatesSelectors.selectGateLabelFormat(chartId));

  const showLabelsRegardingTheSelectedType = (type: EGatesLabelFormat) => {
    if (!entitiesDataByGates) return;

    const labels = d3SelectAll('.gate-label').nodes() as Element[];

    labels.forEach((label) => {
      if (!label) return;
      const classes = type === EGatesLabelFormat.none ? 'gate-label gate-label_hidden' : 'gate-label';
      const labelSelection = d3Select(label);
      const gateId = labelSelection.attr('label-gate-id').split('gate-label_')[1];
      const labelTextElement = labelSelection.select('text');
      const labelAligment = labelTextElement.attr('text-anchor') ?? 'start';
      const labelBgElement = labelSelection.select('rect');

      const gateStatistic = entitiesDataByGates[gateId];

      const lableByFormat = {
        [EGatesLabelFormat.none]: '',
        [EGatesLabelFormat.nameAndPercent]: `${gateStatistic.name} | ${gateStatistic?.cagesPercent ?? ''}`,
        [EGatesLabelFormat.name]: gateStatistic.name,
        [EGatesLabelFormat.percent]: gateStatistic?.cagesPercent ?? '',
      };

      labelSelection.attr('class', classes);
      labelTextElement.text(lableByFormat[type]);

      const updatedTextElement = labelTextElement?.node() as Element;
      const labelParameter = updatedTextElement?.getBoundingClientRect();
      const bgWidth = (labelParameter?.width ?? 0) + TITLE_OFFSET_BY_TYPE.default * 2;
      const bgTransformXByTitleAnchor: Record<string, string> = {
        start: 'unset',
        end: `translateX(-${labelParameter?.width ?? 0}px)`,
      };

      labelBgElement.attr('width', bgWidth).style('transform', bgTransformXByTitleAnchor[labelAligment]);
      const labelBgX = parseFloat(labelBgElement.attr('x'));
      const labelBgY = parseFloat(labelBgElement.attr('y'));
      const bgHeight = parseFloat(labelBgElement.attr('height'));

      if (!plotId) return;

      const menuCX = labelBgX + bgWidth + LABEL_MENU_R;
      const menuCY = labelBgY + bgHeight / 2;

      updateMenuPosition({ gateId, newPosition: [menuCX, menuCY], plotId });
    });
  };

  useEffect(() => {
    showLabelsRegardingTheSelectedType(gateLabelFormat);
  }, [gateLabelFormat]);
}
