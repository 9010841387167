import { SVGProps } from 'react';

const LoadingCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="m1 8 4 5M5 13l8-12" />
    </g>
  </svg>
);

export default LoadingCheckmark;
