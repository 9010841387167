import { createSlice } from '@reduxjs/toolkit';
import { TUi } from '@/store/slices/ui/types';

const initialState: TUi = {
  isMainScrollEnabled: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsMainScrollEnabled: (state, action) => {
      state.isMainScrollEnabled = action.payload;
    },
  },
});

export default uiSlice;
