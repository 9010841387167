import { SVGProps } from 'react';

const ArrowTimeLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="m1.98 8.87 3.89-3.889M5.867 4.911l-3.89-3.889" />
    </g>
  </svg>
);

export default ArrowTimeLeftIcon;
