import { CellKilling, Placement } from '@/graphql/API';
import { getComponentListWithNewIncubation } from '@/store/slices/experimentRunDesign/helpers';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import { isNumber } from '@/helpers';
import { isCellKillingComponent } from '@/helpers/runDesigns/typeGuards';

import fixPlacement from './helpers/fixPlacement';
import copyState from './helpers/copyState';
import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import { EOrderActionList, TOrderReducerAction } from './types';

const updateCellKillingStainPlacement = (
  state: TRunDesignComponent[],
  placementIndex: number,
  relativeTo: string,
  isChecked: boolean
) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));

  const component = newState[placementIndex] as CellKilling;
  let deliveryStainsAt: string[] = component?.deliveryStainsAt ?? [];

  let prevRelativeToIndex = 0;
  let changedRelativeToIndex = null;
  let newRelativeToId = component.timing.relativeTo;

  newState.forEach(({ id }, index: number) => {
    if (component.timing.relativeTo === id) {
      prevRelativeToIndex = index;
    }
    if (relativeTo === id) {
      changedRelativeToIndex = index;
    }
  });

  if (!isNumber(changedRelativeToIndex)) {
    return newState;
  }

  if (
    isChecked &&
    ((changedRelativeToIndex < prevRelativeToIndex &&
      !deliveryStainsAt.includes(newState[changedRelativeToIndex].id)) ||
      !deliveryStainsAt.includes(newState[prevRelativeToIndex].id))
  ) {
    newRelativeToId = newState[changedRelativeToIndex].id;
  }

  if (isChecked && !deliveryStainsAt.includes(relativeTo)) {
    deliveryStainsAt = [...deliveryStainsAt, relativeTo];
  } else {
    deliveryStainsAt = deliveryStainsAt.filter((id) => id !== relativeTo);
  }

  if (!isChecked && changedRelativeToIndex === prevRelativeToIndex) {
    const lastStainComponent = newState.find((componentItem) => deliveryStainsAt.includes(componentItem.id));
    newRelativeToId = lastStainComponent?.id ?? newState[0].id;
  }

  component.timing.relativeTo = newRelativeToId;
  component.deliveryStainsAt = deliveryStainsAt;
  newState = newState.toSpliced(placementIndex, 1);

  newState = [...newState, component];

  return fixPlacement(newState);
};

const updateCellKillingPosition = (state: TRunDesignComponent[], component: TRunDesignComponent) => {
  if (isCellKillingComponent(component)) return state;

  const cellKillingComponentIndex = state.findIndex((componentItem) => isCellKillingComponent(componentItem));

  if (cellKillingComponentIndex < 0) return state;

  const cellKillingComponent = state[cellKillingComponentIndex] as CellKilling;

  if (
    !cellKillingComponent?.deliveryStainsAt?.includes(component.id) &&
    cellKillingComponent?.timing.relativeTo !== component.id
  )
    return state;

  return updateCellKillingStainPlacement(state, cellKillingComponentIndex, component.id, false);
};

const updateTimelinePlacement = (
  state: TRunDesignComponent[],
  placementIndex: number,
  placement: Placement
): TRunDesignComponent[] => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const component = newState[placementIndex];
  // Update deliveryStainsAt and timing of  cell killing component in the case moved component had  cell killing  stain
  newState = updateCellKillingPosition(newState, component);

  component.timing.placement = placement;

  newState = newState.toSpliced(placementIndex, 1);
  let relativeToIndex = newState.findIndex(({ id }) => component.timing.relativeTo === id);

  if (relativeToIndex < 0) {
    relativeToIndex = 0;
    component.timing.relativeTo = newState[0]?.id;
  }

  const reorderActions = {
    [Placement.START]: () => {
      newState = [component, ...newState];
    },
    [Placement.END]: () => {
      newState = [...newState, component];
    },
    [Placement.BEFORE]: () => {
      newState = newState.toSpliced(relativeToIndex, 0, component);
    },
    [Placement.AFTER]: () => {
      newState = newState.toSpliced(relativeToIndex + 1, 0, component);
    },
    [Placement.SIMULTANEOUS]: () => {
      newState = [...newState, component];
    },
  };

  if (placement in reorderActions) {
    reorderActions[placement as keyof typeof reorderActions]();
    newState = fixPlacement(newState);
  }

  return newState;
};

const updateTimelineRelativeTo = (state: TRunDesignComponent[], placementIndex: number, relativeTo: string) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const component = newState[placementIndex];
  component.timing.relativeTo = relativeTo;

  newState = newState.toSpliced(placementIndex, 1);
  const relativeToIndex = newState.findIndex(({ id }) => component.timing.relativeTo === id);

  const reorderActions = {
    [Placement.BEFORE]: () => {
      newState = newState.toSpliced(relativeToIndex, 0, component);
    },
    [Placement.AFTER]: () => {
      newState = newState.toSpliced(relativeToIndex + 1, 0, component);
    },
    [Placement.SIMULTANEOUS]: () => {
      newState = [...newState, component];
    },
  };

  const { placement } = component.timing;

  if (placement in reorderActions) {
    reorderActions[placement as keyof typeof reorderActions]();
    newState = fixPlacement(newState);
  }

  return newState;
};

const addIncubation = (state: TRunDesignComponent[], placementIndex: number, id: string) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  newState = getComponentListWithNewIncubation(newState, id, placementIndex);
  fixPlacement(newState);

  return newState;
};

const removeComponent = (state: TRunDesignComponent[], placementIndex: number) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));

  newState = newState.toSpliced(placementIndex, 1);
  fixPlacement(newState);

  return newState;
};

const orderReducer = (state: TRunDesignComponent[], action: TOrderReducerAction) => {
  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EOrderActionList.updateTimelinePlacement) {
    return updateTimelinePlacement(state, placementIndex, action.payload.value);
  }

  if (action.type === EOrderActionList.updateTimelineRelativeTo) {
    return updateTimelineRelativeTo(state, placementIndex, action.payload.value);
  }

  if (action.type === EOrderActionList.updateCellKillingStainPlacement) {
    return updateCellKillingStainPlacement(state, placementIndex, action.payload.value, action.payload.isChecked);
  }

  if (action.type === EOrderActionList.addIncubation) {
    return addIncubation(state, placementIndex, action.payload.id);
  }

  if (action.type === EOrderActionList.removeIncubation) {
    return removeComponent(state, placementIndex);
  }

  throw new Error('Unknown edit order action.');
};

export default orderReducer;
