import { FC, useEffect, useState, useCallback, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import type { TCustomCreateGateComponentProps } from '@/components/charts/SingleChartWithGates/types';
import { EPreprocessingObjectType } from '@/store/slices/preprocessing/types';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import BeadPopover from '../../components/BeadPopover';

const CreateGatePopover: FC<TCustomCreateGateComponentProps> = ({
  mouseUpEventRef,
  mouseDownEventRef,
  closeModal,
  createGate,
  newGateModel,
  xDimension,
  yDimension,
  selectedGate,
  isModalOpen,
}) => {
  const dispatch = useDispatch();
  const chartId = usePlotChartIdContext();

  const items = useSelector(preprocessingSelectors.selectBeadTypes);
  const beadsCount = useSelector(preprocessingSelectors.selectBeadsCount);
  const beadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);
  const beadForGate = useSelector(preprocessingSelectors.selectBeadForGate);
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const [isOpen, setIsOpen] = useState(false);

  const filteredBeads = useMemo(() => {
    const busyBeads = Object.values(beadShapes).reduce((acc, shape) => {
      acc[shape.id] = true;
      return acc;
    }, {} as Record<string, boolean>);

    return items.filter((bead) => !busyBeads[bead.uuid]);
  }, [items, beadShapes]);

  const handleClose = useCallback(() => {
    dispatch(preprocessingActions.setBeadForGate(undefined));
    setIsOpen(false);
    closeModal();
  }, []);

  const handleSave = useCallback(() => {
    if (!beadForGate) {
      return;
    }

    const gateName = `${beadForGate.name}_gate`;
    createGate({
      gateName,
      newGateModel,
      xDimension,
      yDimension,
      successCallback: closeModal,
      parentGate: selectedGate,
      isCageLevel: false,
      currentChartType,
    });
  }, [newGateModel, xDimension, yDimension, closeModal, selectedGate, beadForGate]);

  const handleSelectBead = useCallback(
    (beadId: string) => {
      dispatch(preprocessingActions.setBeadForGate(beadId));
    },
    [dispatch]
  );

  useEffect(() => {
    const shapesLength = Object.keys(beadShapes).length;
    const cond = shapesLength >= beadsCount;
    if (cond) {
      handleClose();
    } else {
      setIsOpen(isModalOpen);
    }
  }, [beadShapes, beadsCount, handleClose, isModalOpen]);

  return (
    <BeadPopover
      mouseDownEventRef={mouseDownEventRef}
      mouseUpEventRef={mouseUpEventRef}
      items={filteredBeads}
      type={EPreprocessingObjectType.beads}
      handleClose={handleClose}
      handleSave={handleSave}
      handleSelectBead={handleSelectBead}
      isOpen={isOpen}
      possibleSave={!!beadForGate}
      selectedBeadId={beadForGate?.uuid ?? ''}
      title="Save gate for bead"
    />
  );
};

export default memo(CreateGatePopover);
