import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { gatesActions } from '@/store/slices/gates';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { isNumber } from '@/helpers';

import SelectedDataset from './SelectedDataset';
import GeneralView from './GeneralView';

import styles from './StepCytokineReviewBeads.module.scss';
import preprocessingStepStyles from '../../PreprocessingSteps.module.scss';
import PreprocessingHeader from '../../components/PreprocessingHeader';
import PreprocessingStepHeader from '../../components/PreprocessingStepHeader';

const cn = classnames.bind(styles);
const cnPreprocessing = classnames.bind(preprocessingStepStyles);

const title = 'STEP 2. Review gating settings across all datasets in this processing batch.';
const instructions = [
  'Modify gating for specific dataset by clicking on the dataset graph below and adjusting gates on the expanded individual graphs',
];

const StepCytokineReviewBeads: FC = () => {
  const appDispatch = useAppDispatch();
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);

  const [showSelectedDataset, setShowSelectedDataset] = useState(false);
  const [updatedDatasets, setUpdatedDatasets] = useState<Record<string, boolean>>({});

  const updatedDatasetsRef = useRef<Record<string, boolean>>({});

  const handleUpdatedDataset = useCallback((datasetId: string, isUpdated: boolean) => {
    updatedDatasetsRef.current[datasetId] = isUpdated;
  }, []);

  useEffect(() => {
    setShowSelectedDataset(isNumber(currentDatasetIndex));
  }, [currentDatasetIndex]);

  useEffect(() => {
    appDispatch(gatesActions.setActiveGate(null));
    if (!showSelectedDataset) {
      setUpdatedDatasets({ ...updatedDatasetsRef.current });
    } else {
      setUpdatedDatasets({});
    }
    updatedDatasetsRef.current = {};
  }, [showSelectedDataset]);

  return (
    <>
      <PreprocessingHeader />
      <PreprocessingStepHeader title={title} instructions={instructions} withoutBorder />
      <div className={cnPreprocessing('preprocessing-step__content')}>
        <GeneralView
          className={cn({ 'step-cytokine-review-beads__hidden': showSelectedDataset })}
          hideGates={showSelectedDataset}
          updatedDatasets={updatedDatasets}
        />
        {/* TODO: temporary solution to prevent chart height from jumping on marker hover */}
        {isNumber(currentDatasetIndex) && (
          <SelectedDataset
            key={currentDatasetIndex}
            className={cn({ 'step-cytokine-review-beads__hidden': !showSelectedDataset })}
            needResetRange={showSelectedDataset}
            isShown={showSelectedDataset}
            handleUpdatedDataset={handleUpdatedDataset}
          />
        )}
      </div>
    </>
  );
};

export default memo(StepCytokineReviewBeads);
