import { SVGProps } from 'react';

const AttentionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M62.1543 10.625C64.7524 6.12501 71.2476 6.125 73.8457 10.625L120.611 91.625C123.209 96.125 119.962 101.75 114.765 101.75H21.2346C16.0385 101.75 12.7909 96.125 15.389 91.625L62.1543 10.625Z"
      fill="#D9D9D9"
    />
    <path
      d="M64.783 72.8676L64.1502 42.4926H72.2502L71.649 72.8676H64.783ZM68.2002 88.4981C66.9346 88.4981 65.8482 88.0656 64.9412 87.2008C64.0553 86.336 63.6123 85.2813 63.6123 84.0367C63.6123 82.7711 64.0553 81.7164 64.9412 80.8727C65.8482 80.0078 66.9346 79.5754 68.2002 79.5754C69.4869 79.5754 70.5732 80.0078 71.4592 80.8727C72.3451 81.7164 72.7881 82.7711 72.7881 84.0367C72.7881 85.2813 72.3451 86.336 71.4592 87.2008C70.5732 88.0656 69.4869 88.4981 68.2002 88.4981Z"
      fill="black"
    />
  </svg>
);

export default AttentionIcon;
