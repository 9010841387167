import { SVGProps } from 'react';

const Timeline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(1 1)" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
      <circle cx="17" cy="4" r="4" />
      <circle cx="4" cy="4" r="4" />
      <circle cx="4" cy="17" r="4" />
      <circle cx="17" cy="17" r="4" />
    </g>
  </svg>
);

export default Timeline;
