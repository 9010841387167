import JSZip from 'jszip';

import { ECdnObjectType, TCdnObjectType } from '@/types/cdnData';

import { getErrorFromHtml } from '@/store/services/cdnData/helpers';
import {
  transformBarcodes,
  transformCageContours,
  transformCageSummaryTable,
  transformCellContours,
  transformSummaryTable,
  transformCagingSegmentationCenters,
} from '@/store/services/cdnData/dataProvider';

type TFetchDataWorkerEventData = {
  lane: TLane;
  type: TCdnObjectType;
  useOptimizedData: boolean;
};

const getCdnTableDataTransformHandler = (type: TCdnObjectType) => {
  const transformDataHandlerList = {
    [ECdnObjectType.objectEntity]: transformSummaryTable,
    [ECdnObjectType.cageEntity]: transformCageSummaryTable,
    [ECdnObjectType.cageContour]: transformCageContours,
    [ECdnObjectType.cellContour]: transformCellContours,
    [ECdnObjectType.barcodeCenters]: transformBarcodes,
    [ECdnObjectType.cagingSegmentationCenters]: transformCagingSegmentationCenters,
  };
  return transformDataHandlerList[type];
};

export const getCdnTableDataFileName = ({ lane, type, useOptimizedData }: TFetchDataWorkerEventData) => {
  const dataNameList = {
    [ECdnObjectType.objectEntity]: 'primaryAnalysis',
    [ECdnObjectType.cageEntity]: 'cageSummaryTable',
    [ECdnObjectType.cageContour]: 'cageContours',
    [ECdnObjectType.cellContour]: 'cellContours',
    [ECdnObjectType.barcodeCenters]: 'barcodeCenters',
    [ECdnObjectType.cagingSegmentationCenters]: 'cagingSegmentationCenters',
  };
  const dataName = dataNameList[type] as
    | 'primaryAnalysis'
    | 'cageSummaryTable'
    | 'cageContours'
    | 'cellContours'
    | 'barcodeCenters'
    | 'cagingSegmentationCenters';

  const { original, zip, optimized, optimizedZip } = lane.datasetFiles[dataName] ?? {};

  if (useOptimizedData) {
    if (optimizedZip) {
      return { fileName: optimizedZip, isArchive: true };
    }
    if (optimized) {
      return { fileName: optimized, isArchive: false };
    }
  }

  if (zip) {
    return { fileName: zip, isArchive: true };
  }
  return { fileName: original ?? '', isArchive: false };
};

export const fetchData = ({ lane, type, useOptimizedData }: TFetchDataWorkerEventData) =>
  new Promise<Array<TCdnObject>>((resolve, reject) => {
    const transformData = getCdnTableDataTransformHandler(type);
    const { fileName, isArchive } = getCdnTableDataFileName({ lane, type, useOptimizedData });
    if (!fileName) {
      reject(new Error(`no filename specified for ${type}`));
      return;
    }
    if (isArchive) {
      fetch(`${process.env.REACT_APP_CDN_URL}/${lane.path}/${fileName}`, {
        credentials: 'include',
      })
        .then((response) =>
          response.ok ? response.blob() : response.text().then((text) => Promise.reject(getErrorFromHtml(text)))
        )
        .then((blob) => {
          JSZip.loadAsync(blob)
            .then(async (zipped) => {
              const fileData = Object.values(zipped.files)[0];
              if (!fileData) {
                reject(new Error(`invalid ZIP file for ${type}`));
              }
              fileData.async('string').then((data) => {
                resolve(transformData(data, lane));
              });
            })
            .catch(reject);
        })
        .catch(() => {
          reject(new Error(`File ${fileName} not available. Please contact support.`));
        });
    } else {
      fetch(`${process.env.REACT_APP_CDN_URL}/${lane.path}/${fileName}`, {
        credentials: 'include',
      })
        .then((response) =>
          response.ok ? response.text() : response.text().then((text) => Promise.reject(getErrorFromHtml(text)))
        )
        .then((text) => {
          resolve(transformData(text, lane));
        })
        .catch(() => {
          reject(new Error(`File ${fileName} not available. Please contact support.`));
        });
    }
  });
