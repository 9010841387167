const TITLE_FONT_COLOR = '#989898';
export const GRAPHIC_HEIGHT = 600;
export const GRAPHIC_XAXES_TITLE = 'Flowcell Lane';
export const GRAPHIC_YAXES_TITLE = 'Number of cells';

export const STATISTICS_CHART_CONFIG = {
  responsive: true,
  displayModeBar: false,
};

export const STATISTICS_CHART_LAYOUT = {
  autosize: true,
  height: GRAPHIC_HEIGHT,
  xaxis: {
    autorange: true,
    automargin: true,
    title: GRAPHIC_XAXES_TITLE,
    titlefont: {
      color: TITLE_FONT_COLOR,
      size: 17,
      family: 'Inter Display, Arial, sans-serif',
    },
    rangemode: 'tozero',
  },
  yaxis: {
    autorange: true,
    automargin: true,
    title: GRAPHIC_YAXES_TITLE,
    titlefont: {
      color: TITLE_FONT_COLOR,
      size: 17,
      family: 'Inter Display, Arial, sans-serif',
    },
    rangemode: 'tozero',
  },
};
