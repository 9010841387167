import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useParamsExperimentId } from '@/hooks';

import { experimentSelectors } from '@/store/slices/experiment';
import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';

import icons from '@/components/common/icons';

import styles from './Timeline.module.scss';

const cn = classnames.bind(styles);

type TTimelineProps = {
  className?: string;
};

const Timeline: FC<TTimelineProps> = ({ className }) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);

  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);

  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const scansCount = scanList.length;
  const currentScanIndex = scanList.findIndex(({ id }) => id === currentScanId) ?? -1;
  const prevIndex = currentScanIndex > 0 ? currentScanIndex - 1 : -1;
  const nextIndex = currentScanIndex < scansCount - 1 ? currentScanIndex + 1 : -1;

  const handleTimeButtonClickFactory = (scanId: string) => () => {
    appDispatch(
      navigatorActions.setCurrentScanId({
        experimentId,
        scanId,
      })
    );
  };

  const handlePrevScanClick = () => {
    if (!scanList) {
      return;
    }

    appDispatch(
      navigatorActions.setCurrentScanId({
        experimentId,
        scanId: scanList[prevIndex].id,
      })
    );
  };

  const handleNextScanClick = () => {
    if (!scanList) {
      return;
    }

    appDispatch(
      navigatorActions.setCurrentScanId({
        experimentId,
        scanId: scanList[nextIndex].id,
      })
    );
  };

  return (
    <div className={cn('timeline', className)}>
      <div className={cn('timeline__controls')}>
        <button
          className={cn('timeline__order-button', 'timeline__order-button_prev')}
          disabled={prevIndex === -1}
          onClick={handlePrevScanClick}
          aria-label="prev"
        >
          <icons.TimelineArrowPrev />
        </button>
        <button
          className={cn('timeline__order-button', 'timeline__order-button_next')}
          disabled={nextIndex === -1}
          onClick={handleNextScanClick}
          aria-label="next"
        >
          <icons.TimelineArrowNext />
        </button>
      </div>
      <div className={cn('timeline__list')}>
        {scanList.map(({ timeNode, id, lanes }) => (
          <button
            key={id}
            className={cn('timeline__time', {
              _active: id === currentScanId,
              '_has-current-lane': lanes.some((lane) => lane.id === currentLaneId),
            })}
            onClick={handleTimeButtonClickFactory(id)}
          >
            {timeNode}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
