import { SVGProps } from 'react';

const TimelineCellKillingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse
      cx="24"
      cy="24"
      rx="22"
      ry="22"
      transform="rotate(-90 24 24)"
      stroke="currentcolor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path d="M11 24C15.2958 24 17.7042 24 22 24" stroke="currentcolor" strokeWidth="4" />
    <path d="M24 37C24 32.7042 24 30.2958 24 26" stroke="currentcolor" strokeWidth="4" />
    <path d="M26 24H37" stroke="currentcolor" strokeWidth="4" />
    <path d="M24 22L24 11" stroke="currentcolor" strokeWidth="4" />
  </svg>
);

export default TimelineCellKillingIcon;
