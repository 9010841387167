import { useCallback, useMemo } from 'react';

import { gateFromServerSchema, gateListSchema, stringSchema } from '@/validationSchemas';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { isCircleTypeGate, isPolarTypeGate, isPolygonTypeGate, isRangeTypeGate } from '@/helpers/typeGuards';
import { GATE_API_VALIDATION_ERROR } from '@/helpers';
import {
  prepareNewGateData,
  getGatesAfterDelete,
  getGatesAfterChildrenDelete,
  prepareUpdatedGateList,
} from '@/helpers/gates';
import { EChartType } from '@/types/charts';

export type TCreateExperimentGatePayload = {
  gateName: string;
  xDimension: string;
  yDimension: string;
  successCallback?: () => void;
  parentGate: Nullable<TGate>;
  isCageLevel?: boolean;
  newGateModel: TNewGateModelData;
  uuid?: string;
  gateTypeByTool?: TGatePropertyType;
  currentChartType: EChartType;
};

export const gateType: Record<TGatePropertyType, TGateType> = {
  circle: 'circle',
  polygon: 'polygon',
  rectangle: 'polygon',
  polar: 'polar',
  'split-gate': 'polar',
  range: 'range',
};

export type TUseUpdateGatesProps = {
  experimentId: string;
  scanId: string;
  laneId: string;
  fullGateList: TGate[];
  updateGate?: (data: TUpdateGateRequest) => unknown;
  createGate?: (data: TCreateGateRequest) => unknown;
  deleteGate?: (data: TDeleteGateRequest) => unknown;
  deleteGateChildren?: (data: TDeleteGateRequest) => unknown;
};

export type TUseUpdateGateCallbacksType = {
  createGate: (payload: TCreateExperimentGatePayload) => unknown;
  updateGate: (gate: TGate, updatedGateData: Partial<TGate>, successCallback?: () => null | void) => unknown;
  deleteGate: (gateId: string) => unknown;
  deleteGateChildren: (gateId: string) => unknown;
};

export function useUpdateExperimentGate(data: TUseUpdateGatesProps) {
  const { updateGate, createGate, deleteGateChildren, deleteGate, experimentId, fullGateList, laneId, scanId } = data;

  const createExperimentGate = useCallback<TUseUpdateGateCallbacksType['createGate']>(
    async ({
      gateName,
      xDimension,
      yDimension,
      newGateModel,
      parentGate,
      isCageLevel,
      gateTypeByTool,
      currentChartType,
      successCallback = () => null,
    }) => {
      try {
        const shapeData = {
          type: gateType[newGateModel.type],
          model: { ...newGateModel.model },
        };

        if (
          !isCircleTypeGate(shapeData) &&
          !isPolygonTypeGate(shapeData) &&
          !isPolarTypeGate(shapeData) &&
          !isRangeTypeGate(shapeData)
        )
          return;

        const newGate = prepareNewGateData({
          gateName,
          xDimension,
          yDimension,
          parentGate,
          isCageLevel,
          newGateModel,
          scanId,
          laneId,
          gateTypeByTool,
          currentChartType,
        });

        const { success: isReqBodyValid } = gateFromServerSchema.safeParse({ ...newGate });

        if (!isReqBodyValid) {
          showErrorToast(GATE_API_VALIDATION_ERROR.invalidGate);
        }
        await createGate?.({ experimentId, body: newGate, newGate });
        successCallback();
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    },
    [experimentId, scanId, laneId, fullGateList]
  );

  const deleteExperimentGate = useCallback<TUseUpdateGateCallbacksType['deleteGate']>(
    async (gateId) => {
      try {
        const updatedGateList = getGatesAfterDelete(gateId, fullGateList);
        const { success: isGateIdValid } = stringSchema.safeParse(gateId);
        const { success: isGateListValid } = gateListSchema.safeParse(updatedGateList);

        if (!(isGateListValid && isGateIdValid)) {
          const message = isGateIdValid
            ? GATE_API_VALIDATION_ERROR.invalidId
            : GATE_API_VALIDATION_ERROR.invalidGateList;
          showErrorToast(message);
        }

        await deleteGate?.({
          experimentId,
          gateId,
          updatedGateList,
        });
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    },
    [experimentId, fullGateList]
  );

  const deleteExperimentGateChildren = useCallback<TUseUpdateGateCallbacksType['deleteGateChildren']>(
    async (gateId) => {
      try {
        const updatedGateList = getGatesAfterChildrenDelete(gateId, fullGateList);
        const { success: isGateIdValid } = stringSchema.safeParse(gateId);
        const { success: isGateListValid } = gateListSchema.safeParse(updatedGateList);

        if (!(isGateListValid && isGateIdValid)) {
          const message = isGateIdValid
            ? GATE_API_VALIDATION_ERROR.invalidId
            : GATE_API_VALIDATION_ERROR.invalidGateList;
          showErrorToast(message);
        }

        await deleteGateChildren?.({
          experimentId,
          gateId,
          updatedGateList,
        });
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    },
    [experimentId, fullGateList]
  );

  const updateExperimentGate = useCallback<TUseUpdateGateCallbacksType['updateGate']>(
    async (gate: TGate, updatedGateData: Partial<TGate>, successCallback = () => null) => {
      try {
        const updatedGateList = prepareUpdatedGateList({
          gates: fullGateList,
          updatedGate: { ...updatedGateData, id: gate.id },
        });

        const { shape: { model = null, type = 'polygon' } = {} } = updatedGateData;

        const body = {
          ...updatedGateData,
          ...(model && {
            shape: {
              type,
              model,
            },
          }),
        } as TUpdatedGatePayload;

        const { success: isGateIdValid } = stringSchema.safeParse(gate.id);
        const { success: isGateListValid } = gateListSchema.safeParse(updatedGateList);

        if (!(isGateListValid && isGateIdValid)) {
          const message = isGateIdValid
            ? GATE_API_VALIDATION_ERROR.invalidId
            : GATE_API_VALIDATION_ERROR.invalidGateList;
          return showErrorToast(message);
        }

        await updateGate?.({
          experimentId,
          gateId: gate.id,
          body,
          updatedGateList,
        });
        successCallback();
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    },
    [experimentId, fullGateList]
  );

  const hookReturn = useMemo(
    () => ({
      createExperimentGate,
      deleteExperimentGate,
      updateExperimentGate,
      deleteExperimentGateChildren,
    }),
    [createExperimentGate, deleteExperimentGate, updateExperimentGate, deleteExperimentGateChildren]
  );

  return hookReturn;
}
