import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import styles from './CellTypes.module.scss';

const cn = classnames.bind(styles);

type TCellTypes = {
  className?: string;
  laneLetter: string;
};

const CellTypes: FC<TCellTypes> = ({ laneLetter, className }) => {
  const lane = useSelector(experimentRunDesignSelectors.selectCurrentLaneById(laneLetter));

  return (
    <div className={cn('cell-types', className)}>
      {lane?.cellTypes?.map((cellType, runDesignCardIndex) => {
        const key = runDesignCardIndex;
        return <span key={key} className={cn('cell-type', { 'cell-type_active': !!cellType?.cellIndex?.name })} />;
      })}
    </div>
  );
};

export default CellTypes;
