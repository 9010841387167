import { Point as OsdPoint, Viewport as OsdViewport } from 'openseadragon';
import pointInPolygon from 'point-in-polygon';

import { CELL_CONTOUR_DRAW_SETTINGS } from './helpers';

export const contoursOnCanvas = {
  getObjectBbox<T extends TCageContour | TCellContour>(objectContour: T) {
    let xMin = Infinity;
    let xMax = -Infinity;
    let yMin = Infinity;
    let yMax = -Infinity;
    let i = objectContour.canvasPoints.length;
    while (i--) {
      const point = objectContour.canvasPoints[i];
      xMin = point[0] < xMin ? point[0] : xMin;
      xMax = point[0] > xMax ? point[0] : xMax;
      yMin = point[1] < yMin ? point[1] : yMin;
      yMax = point[1] > yMax ? point[1] : yMax;
    }
    return { xMin, xMax, yMin, yMax };
  },

  filterByBbox<T extends TCageContour | TCellContour>(bbox: TBoundaryBox, objectContourList: T[]) {
    const { xMin, xMax, yMin, yMax } = bbox;
    return objectContourList.filter((objectContour) => {
      const firstPoint = objectContour.canvasPoints[0];
      if (!firstPoint) {
        return false;
      }
      return firstPoint && firstPoint[0] > xMin && firstPoint[0] < xMax && firstPoint[1] > yMin && firstPoint[1] < yMax;
    });
  },

  findObject<T extends TCageContour | TCellContour>(
    viewport: OsdViewport,
    position: OsdPoint,
    objectContourList: T[]
  ): Nullable<T> {
    const imageCoords = viewport.viewerElementToImageCoordinates(position);
    return (
      objectContourList.find((cageContourItem) =>
        pointInPolygon([imageCoords.x, imageCoords.y], cageContourItem.canvasPoints)
      ) ?? null
    );
  },

  draw<T extends TCageContour | TCellContour | Pick<TCellContour, 'canvasPoints'>>(
    ctx: Nullable<CanvasRenderingContext2D>,
    objectContourList: T[],
    drawSettings: typeof CELL_CONTOUR_DRAW_SETTINGS,
    xShift = 0,
    yShift = 0
  ) {
    if (!ctx) {
      return;
    }
    ctx.strokeStyle = drawSettings.lineColor;
    ctx.lineWidth = drawSettings.lineWidth;
    objectContourList.forEach((objectContour) => {
      const poly = objectContour.canvasPoints;
      ctx.beginPath();
      ctx.moveTo(poly[0][0] - xShift, poly[0][1] - yShift);
      for (let index = 1; index < poly.length - 1; index += 1) {
        ctx.lineTo(poly[index][0] - xShift, poly[index][1] - yShift);
      }
      ctx.closePath();
      ctx.stroke();
    });
  },
};
