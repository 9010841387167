import { drawGateOnCanvas } from '@/helpers/screenShotUtils';
import getCurrentGatesLayout from '@/pages/Dataset/components/DatasetChart/helpers/getCurrentGatesLayout';
import { TDownloadChartHandlerPayload, TGateLayout } from '@/pages/Dataset/components/DatasetChart/types';

export const downloadCanvas = (canvas: HTMLCanvasElement, fileName: string) => {
  const link = document.createElement('a');
  link.download = `${fileName}.png`;
  link.href = canvas.toDataURL();
  link.click();
};

export const preparePlotlyCanvasContext = async ({
  plotlyImage,
  imageWidth,
  imageHeight,
}: {
  plotlyImage: string;
  imageWidth: number;
  imageHeight: number;
}) => {
  const canvas = document.createElement('canvas');
  canvas.width = imageWidth;
  canvas.height = imageHeight;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const onLoadPromise = new Promise((res, rej) => {
    const image = new Image();
    image.onload = () => res(image);
    image.onerror = rej;
    image.src = plotlyImage;
  });

  const test = await onLoadPromise;
  ctx.clearRect(0, 0, imageWidth, imageHeight);
  ctx.drawImage(test as HTMLImageElement, 0, 0);

  return ctx;
};

export const handleDownloadPngImage = async ({
  plotlyImage,
  imageWidth,
  imageHeight,
  renderPlotHeight,
  renderPlotWidth,
  fileName,
  layout,
  overflowContainer,
  labelElement,
}: TDownloadChartHandlerPayload) => {
  const ctx = await preparePlotlyCanvasContext({
    plotlyImage,
    imageWidth,
    imageHeight,
  });

  if (!ctx) return;

  if (!overflowContainer) {
    downloadCanvas(ctx.canvas, fileName);
    return;
  }

  const gatesLayout = getCurrentGatesLayout(overflowContainer);

  if (!gatesLayout) {
    downloadCanvas(ctx.canvas, fileName);
    return;
  }

  // save axis images, to redraw later over images
  const leftBlock = ctx.getImageData(0, 0, layout.margin.l, imageHeight);
  const bottomBlock = ctx.getImageData(0, imageHeight - layout.margin.b, imageWidth, layout.margin.b);

  const offset = {
    sx: layout.margin.l,
    sy: layout.margin.t,
  };

  gatesLayout.forEach((gate: Nullable<TGateLayout>) => {
    drawGateOnCanvas({
      ctx,
      gate,
      imageHeight: renderPlotHeight,
      imageWidth: renderPlotWidth,
      labelElement,
      offset,
    });
    ctx.putImageData(leftBlock, 0, 0);
    ctx.putImageData(bottomBlock, 0, imageHeight - layout.margin.b);
  });

  downloadCanvas(ctx.canvas, fileName);
};
