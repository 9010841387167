import { useMemo } from 'react';

import { useSequencingDataFiles } from '@/hooks/sequencingData/useSequencingDataFiles';

import { cdnAPI } from '@/store/services/cdnData';

import { BACKGROUND_TRACE_NAME, CELLS_TRACE_NAME, defaultTraceSettings, prepareData, TKneePointData } from './helpers';

const useKneePlotData = (laneId: string) => {
  const { cellReadsPath, summaryPath } = useSequencingDataFiles(laneId);

  const {
    data: cellReadsList,
    isLoading,
    isFetching,
    isError,
  } = cdnAPI.useFetchSequencingDataCellReadsStatsQuery(cellReadsPath);

  const { data: sequencingDataSummaryInfo } = cdnAPI.useFetchSequencingDataSummaryInfoQuery(summaryPath);

  const plotData = useMemo(() => {
    const numberOfCells = +(sequencingDataSummaryInfo?.['Estimated Number of Cells'] ?? 0);
    const filteredData = (cellReadsList ?? []).filter((d) => d.CB !== 'CBnotInPasslist');

    filteredData.sort((a, b) => b.nUMIunique - a.nUMIunique);
    const [cellList, backgroundList] = filteredData.reduce(
      (acc: TKneePointData[][], el, i) => {
        const listIndex = i + 1 <= numberOfCells ? 0 : 1;
        acc[listIndex].push({
          rank: i + 1,
          nUMIunique1: el.nUMIunique + 1,
          cbName: el.CB,
        });
        return acc;
      },
      [[], []]
    );

    const lastCellEl = cellList.at(-1);
    if (lastCellEl) {
      backgroundList.unshift(lastCellEl);
    }

    const traceCells = {
      ...prepareData(cellList),
      ...defaultTraceSettings(CELLS_TRACE_NAME, 'blue'),
    };

    const traceBackground = {
      ...prepareData(backgroundList),
      ...defaultTraceSettings(BACKGROUND_TRACE_NAME, 'gray'),
    };

    return [traceCells, traceBackground];
  }, [cellReadsList, sequencingDataSummaryInfo]);

  return useMemo(
    () => ({ plotData, summaryInfo: sequencingDataSummaryInfo, isLoading: isLoading || isFetching, isError }),
    [plotData, sequencingDataSummaryInfo, isLoading, isFetching, isError]
  );
};

export default useKneePlotData;
