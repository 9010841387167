import { FC, useRef } from 'react';
import classnames from 'classnames/bind';

import useMatrixScreenShot from '@/hooks/useMatrixScreenShot';

import icons from '@/components/common/icons';
import PopoverButton from '@/components/common/PopoverButton';

import styles from '../DownloadChartButton.module.scss';

const cn = classnames.bind(styles);

const DownloadMatrixButton: FC = () => {
  const labelRef = useRef<Nullable<HTMLSpanElement>>(null);
  const { handleDownload, isLoading } = useMatrixScreenShot({ labelRef });

  const options = [
    {
      id: 'png',
      title: 'PNG',
      onClick: () => handleDownload('png'),
    },
    {
      id: 'svg',
      title: 'SVG',
      onClick: () => handleDownload('svg'),
    },
  ];

  return (
    <>
      <PopoverButton
        options={options}
        icon={<icons.DownloadIcon className={cn('download-icon', { 'download-icon_downloading': isLoading })} />}
        tooltip="Download datasets as an image"
      />
      <span ref={labelRef} className={cn('label')} />
    </>
  );
};

export default DownloadMatrixButton;
