import { line } from 'd3-shape';

export const intersects = (x: number, y: number, cx: number, cy: number, r: number) => {
  const dx = x - cx;
  const dy = y - cy;

  return dx * dx + dy * dy <= r * r;
};

export const generateLine = line<TGatePolygon>()
  .x((d: TGatePolygon) => d.x)
  .y((d: TGatePolygon) => d.y);
