export const lexSort = (a: string | number, b: string | number) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};

export const invertedSort: <T>(callback: (a: T, b: T) => number) => (a: T, b: T) => number = (callback) => (a, b) =>
  callback(b, a);

/**
 * Do not use Math.min(...arr) and Math.max(...arr) with big arrays because it could cause "RangeError: Maximum call stack size exceeded".
 * The reason of getting that error is that a function is called with arr.length arguments.
 * Arguments are passed on the system stack which could be not big enough.
 * https://github.com/nodejs/node/issues/43043
 * @param arr
 */
export const getMinMax = (arr: number[]) => {
  let i = arr.length;
  let max = -Infinity;
  let min = Infinity;

  while (i--) {
    max = arr[i] > max ? arr[i] : max;
    min = arr[i] < min ? arr[i] : min;
  }
  return { max, min };
};

export const getPositiveMinMax = (arr: number[]) => {
  let i = arr.length;
  let max = -Infinity;
  let min = Infinity;

  while (i--) {
    if (arr[i] > 0) {
      max = arr[i] > max ? arr[i] : max;
      min = arr[i] < min ? arr[i] : min;
    }
  }
  return { max, min };
};

export const getMin = (arr: number[]) => {
  let i = arr.length;
  let min = Infinity;

  while (i--) {
    min = arr[i] < min ? arr[i] : min;
  }
  return min;
};

export const getMax = (arr: number[]) => {
  let i = arr.length;
  let max = -Infinity;

  while (i--) {
    max = arr[i] > max ? arr[i] : max;
  }
  return max;
};
