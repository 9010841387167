import { CSSProperties, FC } from 'react';
import classnames from 'classnames/bind';

import { MDASH } from '@/helpers';

import Portal from '@/components/common/Portal';

import styles from './PointHoverTemplate.module.scss';

const cn = classnames.bind(styles);

export type TPointHoverTemplateData = Nullable<Record<string, { label: string; value: string | number }>>;

type TPointHoverTemplateRowProps = {
  label: string;
  value?: number | string;
};

const PointHoverTemplateRow: FC<TPointHoverTemplateRowProps> = ({ label, value }) => (
  <div>
    <b>{label}: </b>
    {value ?? MDASH}
  </div>
);

type TPointHoverTemplateProps = {
  isOpen: boolean;
  data: TPointHoverTemplateData;
  templatePosition: Nullable<[number, number]>;
};

const PADDING_FROM_CURSOR = 20;

const PointHoverTemplate: FC<TPointHoverTemplateProps> = ({ isOpen, data, templatePosition }) => {
  if (!isOpen || !data || !templatePosition) {
    return null;
  }

  return (
    <Portal>
      <div
        className={cn('hovertemplate')}
        style={
          {
            '--offset-left': `${templatePosition[0] + PADDING_FROM_CURSOR}px`,
            '--offset-top': `${templatePosition[1] - PADDING_FROM_CURSOR}px`,
          } as CSSProperties
        }
      >
        {data &&
          Object.entries(data).map(([key, value]) => (
            <PointHoverTemplateRow key={key} label={value.label} value={value.value} />
          ))}
      </div>
    </Portal>
  );
};

export default PointHoverTemplate;
