import { useMemo, useEffect, useState, MutableRefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { select as d3Select } from 'd3-selection';

import { getOrigDataRange } from '@/helpers/origDataRange';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { EModebarTools, EPageWithChartType, EChartType } from '@/types/charts';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { isHistogramChartType } from '@/helpers/charts/chartsType';
import { useDebounce } from '@/hooks';

import { EAxesGroupName, scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { gatesSelectors } from '@/store/slices/gates';
import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { EStepName } from '@/store/slices/preprocessing/types';
import { chartDataActions, chartDataSelectors, TChartEntityListData } from '@/store/slices/chartData';

import { CONFIG, DRAGMODES } from '@/pages/Dataset/constants';
import { getDataConfigList, getCoordinatesByAxesAndGate, prepareDataConfig } from '@/helpers/charts/chartsData';
import { getLineHistogramCoordinates, isHistogramsChartType } from '@/helpers/charts/lineHistogram';
import { getMax } from '@/helpers/arrays';

import { getChartsLayoutConfig } from './datasetAnalysis/helpers';
import { histogramSettingsSelectors } from '../store/slices/histogramSettings';
import useSelectedGateForSpecificDataset from './preprocessing/useSelectedGateForSpecificDataset';
import { useGatesLabelSettings } from './gates/useGatesLabelSettings';
import { getAxesTitlesUpdatedLayout } from '../pages/Analytics/components/Scatterplot/components/DataScatterplotChart/helpers';
import usePlotProxy from './usePlotProxy';
import { usePlotChartIdContext } from '../contexts/PlotChartIdContext';

const gatesMaxNestingLevel = Number(process.env.REACT_APP_GATES_MAX_NESTING_LEVEL) || 4;

export type TUseChartSideEffectsProps = {
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  currentAppLane: Nullable<TLane>;
  entitiesByLanesAndGates: TEntitiesByLanesAndGates;
  selectedTool: Nullable<EModebarTools>;
  isTransitionEnd: boolean;
  isResetDisabled?: boolean;
  entityLevelGateList: TGate[];
  setOpenedAxisSettingsName: (data: Nullable<'x' | 'y'>) => void;
  setSelectedTool: (value: Nullable<EModebarTools>) => void;
  plotRangeName?: string;
  customXAxis?: string;
  customYAxis?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  isStatic?: boolean;
  handleIsLoaded?: (path: string, isLoading: boolean) => void;
  pageType?: EPageWithChartType;
  specificAxesGroupName?: EAxesGroupName;
};

export const useChartSideEffects = ({
  graphRef,
  currentAppLane,
  entitiesByLanesAndGates,
  selectedTool,
  isTransitionEnd,
  setOpenedAxisSettingsName,
  setSelectedTool,
  entityLevelGateList,
  plotRangeName,
  customXAxis,
  customYAxis,
  isStatic = false,
  xAxisLabel = '',
  yAxisLabel = '',
  handleIsLoaded = () => null,
  pageType,
  specificAxesGroupName,
}: TUseChartSideEffectsProps) => {
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const multiChartEntityListData = useSelector(chartDataSelectors.selectMultiChartEntityListData);
  const chartData = useMemo(
    () =>
      (currentAppLane?.dataset.path && multiChartEntityListData[currentAppLane?.dataset.path]) as
        | TChartEntityListData
        | undefined,
    [multiChartEntityListData, currentAppLane?.dataset.path]
  );
  const chartEntityList = useMemo(() => chartData?.data ?? [], [chartData]);
  const isChartEntityListLoading = useMemo(() => chartData?.isLoading ?? false, [chartData]);
  const chartEntityListError = useMemo(() => chartData?.error ?? false, [chartData]);
  const isChartEntityListError = useMemo(() => chartData?.isError ?? false, [chartData]);

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const selectedGate = useSelectedGateForSpecificDataset();
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)(plotRangeName));
  const highlightDotsBy = useSelector(scatterplotsSelectors.selectHighlightDotsBy(chartId));
  const storedXAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const xAxis = useMemo(() => customXAxis ?? storedXAxis, [customXAxis, storedXAxis]);
  const storedYAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const yAxis = useMemo(() => {
    if (isHistogramChartType(currentChartType)) {
      return xAxis; // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis
    }
    return customYAxis ?? storedYAxis;
  }, [currentChartType, xAxis, customYAxis, storedYAxis]);
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const isBlockDraw = useSelector(gatesSelectors.selectIsBlockDraw);

  const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(chartId));
  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const isStackedChartsChecked = useSelector(histogramSettingsSelectors.selectIsStackedChartsChecked);
  const customHistogramBinsAmount = useSelector(histogramSettingsSelectors.selectCustomHistogramBinsAmount(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const kernelBinsAmountMultiplier = useSelector(histogramSettingsSelectors.selectKernelBinsAmountMultiplier(chartId));
  const kernelBandwidthCoefficient = useSelector(histogramSettingsSelectors.selectKernelBandwidthCoefficient(chartId));

  const allOrigDataRanges = useSelector(chartDataSelectors.selectAllOrigDataRanges);
  const origDataRange = useMemo(
    () =>
      getOrigDataRange({
        allOrigDataRanges,
        scanId: currentAppLane?.dataset.scanId,
        laneId: currentAppLane?.dataset.laneId,
        xAxis,
        yAxis,
      }),
    [allOrigDataRanges, currentAppLane, xAxis, yAxis]
  );

  const [isPlotLoaded, setIsPlotLoaded] = useState<boolean>(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(false);

  const debouncedTransitionEnd = useDebounce(isTransitionEnd, 100);

  const isAllPlotDataLoaded = useMemo(
    () =>
      Boolean(
        (isPlotLoaded || isEmptyData) &&
          graphRef.current &&
          isTransitionEnd &&
          debouncedTransitionEnd &&
          !isChartEntityListLoading
      ),
    [isPlotLoaded, isTransitionEnd, debouncedTransitionEnd, isChartEntityListLoading, isEmptyData]
  );

  const entitiesDataByGates = useMemo(
    () => currentAppLane && entitiesByLanesAndGates[currentAppLane.path],
    [currentAppLane, entitiesByLanesAndGates]
  );

  const isModebarControlsDisabled = useMemo(
    () => Boolean((selectedGate && selectedGate.level >= gatesMaxNestingLevel) || isBlockDraw),
    [selectedGate, gatesMaxNestingLevel, isBlockDraw]
  );

  useGatesLabelSettings({ entitiesDataByGates, plotId: graphRef.current?.id });

  const stepsWithSelectedGateByDefault = [
    EStepName.stepCellKillingDefineCellsTarget,
    EStepName.stepCellKillingReviewCellsTarget,
  ];
  const memoData = useMemo(
    () => ({
      isChartEntityListLoading,
      xAxis,
      yAxis,
      chartEntityList,
      plotRangeName,
      plotRange,
      currentChartType,
      currentHistogramDataGroupType,
      customHistogramBinsAmount,
      selectedTool,
      xAxisScaleType,
      yAxisScaleType,
      currentColorScale,
      isObjectEntityEnabled,
    }),
    [
      isChartEntityListLoading,
      xAxis,
      yAxis,
      chartEntityList,
      plotRangeName,
      plotRange,
      currentChartType,
      currentHistogramDataGroupType,
      customHistogramBinsAmount,
      selectedTool,
      xAxisScaleType,
      yAxisScaleType,
      currentColorScale,
      isObjectEntityEnabled,
    ]
  );

  const debounceData = useDebounce(memoData, 100);

  useEffect(() => {
    if (isChartEntityListLoading) {
      return;
    }

    // Take the selected gate into account when calculating the plot range for histograms and the corresponding pre-processing steps
    const gate =
      currentChartType === EChartType.histogram || stepsWithSelectedGateByDefault.includes(currentStep)
        ? selectedGate
        : null;
    const { coordinates } = getCoordinatesByAxesAndGate({
      entityList: chartEntityList,
      xAxis,
      yAxis,
      gate,
      entityLevelGateList,
      scanId: currentAppLane?.dataset.scanId,
      laneId: currentAppLane?.dataset.laneId,
    });

    let yData = coordinates.y;
    let xData = coordinates.x;
    setIsEmptyData(xData.length === 0 || yData.length === 0);

    if (isHistogramsChartType(currentChartType)) {
      const newCoordinates = getLineHistogramCoordinates({
        coords: coordinates,
        customBinsAmount: customHistogramBinsAmount,
        currentHistogramDataGroupType,
        xAxisScaleType,
        kernelBandwidthCoefficient,
        kernelBinsAmountMultiplier,
      });

      let yMax = getMax(newCoordinates.y);
      yMax += yMax * 0.05;
      yData = [0, yMax];
      xData = newCoordinates.x;
    }

    if (!gate && currentAppLane && xData.length && yData.length) {
      const newOrigDataRange = axisScaleHelper.getLinearRangeWithoutGaps(xData, yData);
      const rangeDataList = [{ axis: xAxis, range: [newOrigDataRange.xMin, newOrigDataRange.xMax] }];
      if (!isHistogramsChartType(currentChartType)) {
        rangeDataList.push({ axis: yAxis, range: [newOrigDataRange.yMin, newOrigDataRange.yMax] });
      }
      appDispatch(
        chartDataActions.setOrigDataRange({
          scanId: currentAppLane.dataset.scanId,
          laneId: currentAppLane.dataset.laneId,
          rangeDataList,
        })
      );
    }

    if (plotRangeName || pageType !== EPageWithChartType.matrixView) {
      const range = axisScaleHelper.getPlotRange({
        xAxisScaleType,
        yAxisScaleType,
        x: xData,
        y: yData,
        withGap: !isHistogramsChartType(currentChartType),
      });

      appDispatch(chartSettingsActions.setPlotRange({ range, rangeName: plotRangeName }));
    }
  }, [
    debounceData.isChartEntityListLoading,
    selectedGate,
    debounceData.xAxis,
    debounceData.yAxis,
    debounceData.chartEntityList,
    debounceData.plotRangeName,
    debounceData.currentChartType,
    debounceData.currentHistogramDataGroupType,
    debounceData.customHistogramBinsAmount,
    debounceData.xAxisScaleType,
    debounceData.yAxisScaleType,
    debounceData.isObjectEntityEnabled,
    kernelBandwidthCoefficient,
    kernelBinsAmountMultiplier,
  ]);

  const [isReadyForFirstDraw, setIsReadyForFirstDraw] = useState(false);

  useEffect(() => {
    setIsShowLoader(true);
    const timeout = setTimeout(() => {
      setIsShowLoader(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    memoData.xAxisScaleType,
    memoData.yAxisScaleType,
    memoData.xAxis,
    memoData.yAxis,
    memoData.currentChartType,
    memoData.currentHistogramDataGroupType,
    memoData.isChartEntityListLoading,
    memoData.chartEntityList,
  ]);

  useEffect(() => {
    if (
      debounceData.plotRange &&
      !!currentAppLane &&
      !debounceData.isChartEntityListLoading &&
      debounceData.chartEntityList.length > 0 &&
      !!debounceData.xAxis &&
      !!debounceData.yAxis
    ) {
      handleIsLoaded(currentAppLane.dataset.path, true);
      setIsReadyForFirstDraw(true);
    } else {
      setIsReadyForFirstDraw(false);
    }
  }, [
    currentAppLane,
    debounceData.isChartEntityListLoading,
    debounceData.chartEntityList,
    debounceData.xAxis,
    debounceData.yAxis,
    debounceData.plotRange,
  ]);

  // create plot when all data received. Do not add other dependencies to this hook. This can cause Plotly configurations to overlap and the chart to display incorrectly.
  useEffect(() => {
    if (!isReadyForFirstDraw || !plotRange || graphRef.current?.layout) return;

    const { coordinates, cagesDataByCoordinates } = getCoordinatesByAxesAndGate({
      entityList: chartEntityList,
      xAxis,
      yAxis,
      gate: selectedGate,
      entityLevelGateList,
      scanId: currentAppLane?.dataset.scanId,
      laneId: currentAppLane?.dataset.laneId,
    });

    const { dataConfig, densityBandWidth: newBandWidth } = prepareDataConfig({
      coordinates,
      xAxisScaleType,
      yAxisScaleType,
      currentChartType,
      currentColorScale,
      customdata: cagesDataByCoordinates,
      isChartFillEnabled,
      isStackedAndFilledEnabled,
      customHistogramBinsAmount,
      currentHistogramDataGroupType,
      kernelBinsAmountMultiplier,
      kernelBandwidthCoefficient,
      pageType,
      selectedGate,
      origDataRange,
      highlightDotsBy,
      chartEntityList,
    });

    const bandWidthToUpdate = {
      custom: { ...newBandWidth },
      default: { ...newBandWidth },
    };

    if (pageType !== EPageWithChartType.matrixView) {
      appDispatch(scatterplotsActions.setDensityBandWidth(bandWidthToUpdate));
    }

    const layoutConfig = getChartsLayoutConfig({
      dataCount: 1,
      currentChartType,
      xAxisScaleType,
      yAxisScaleType,
      isTickLabelsVisible: true,
      dragmode: DRAGMODES[selectedTool as EModebarTools],
      range: plotRange,
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      pageType,
    });

    plotlyProxy.react(
      [dataConfig],
      layoutConfig,
      {
        ...CONFIG(),
        scrollZoom: EModebarTools.zoom === selectedTool,
        staticPlot: isStatic,
      },
      () => {
        addAxesClickListeners();
        setIsPlotLoaded(true);
      }
    );

    setIsReadyForFirstDraw(false);
  }, [isReadyForFirstDraw, plotRange, isStatic]);

  useEffect(() => {
    const isChartUpdateDisabled =
      currentStep === EStepName.stepCellKillingDefineCellsTarget ||
      !graphRef.current ||
      isChartEntityListLoading ||
      !isAllPlotDataLoaded;

    if (isChartUpdateDisabled) return;

    const { coordinates, cagesDataByCoordinates } = getCoordinatesByAxesAndGate({
      entityList: chartEntityList,
      xAxis,
      yAxis,
      gate: selectedGate,
      entityLevelGateList,
      scanId: currentAppLane?.dataset.scanId,
      laneId: currentAppLane?.dataset.laneId,
    });

    const { dataConfig, densityBandWidth } = prepareDataConfig({
      coordinates,
      xAxisScaleType,
      yAxisScaleType,
      currentChartType,
      currentColorScale,
      customdata: cagesDataByCoordinates,
      isChartFillEnabled,
      isStackedAndFilledEnabled,
      customHistogramBinsAmount,
      currentHistogramDataGroupType,
      kernelBandwidthCoefficient,
      kernelBinsAmountMultiplier,
      pageType,
      selectedGate,
      origDataRange,
      highlightDotsBy,
      chartEntityList,
    });

    const densityBandWidthToUpdate = {
      custom: { ...densityBandWidth },
      default: { ...densityBandWidth },
    };

    const update = {
      x: [[...dataConfig.x]],
      y: [[...dataConfig.y]],
      marker: dataConfig?.marker,
      customdata: [[...dataConfig.customdata]],
    };

    if (pageType !== EPageWithChartType.matrixView) {
      appDispatch(scatterplotsActions.setDensityBandWidth(densityBandWidthToUpdate));
    }

    plotlyProxy.forceRestyle(update);
  }, [debounceData.chartEntityList]);

  useEffect(() => {
    if (!graphRef?.current?.data?.[0] || !graphRef?.current?.layout) return;

    const dataConfig = graphRef.current.data[0];
    const dataLayout = graphRef.current.layout;

    plotlyProxy.react(
      [dataConfig],
      { ...dataLayout, dragmode: DRAGMODES[selectedTool as EModebarTools] },
      {
        ...CONFIG(),
        scrollZoom: EModebarTools.zoom === selectedTool,
        staticPlot: isStatic,
      }
    );
  }, [debounceData.selectedTool, isStatic]);

  useEffect(() => {
    if (!isModebarControlsDisabled || !graphRef?.current) return;

    setSelectedTool(EModebarTools.zoom);
  }, [isModebarControlsDisabled, graphRef?.current]);

  const addAxesClickListeners = () => {
    document.removeEventListener('contextmenu', addAxesClickListeners);

    const yAxisEl = d3Select('.nsdrag.drag.cursor-ns-resize').node() as Element;
    const xAxisEl = d3Select('.ewdrag.drag.cursor-ew-resize').node() as Element;

    d3Select(yAxisEl).on('contextmenu', () => {
      setOpenedAxisSettingsName('y');
    });

    d3Select(xAxisEl).on('contextmenu', () => {
      setOpenedAxisSettingsName('x');
    });
  };

  const saveNewDensityBandwidth = useCallback(
    (bandwidth: { x: number; y: number }) => {
      if (currentChartType === EChartType.dotDensity && bandwidth) {
        const bandWidthToUpdate = {
          custom: { ...bandwidth },
          default: { ...bandwidth },
        };
        appDispatch(scatterplotsActions.setDensityBandWidth(bandWidthToUpdate));
      }
    },
    [currentChartType]
  );

  useEffect(() => {
    if (
      !isPlotLoaded ||
      !graphRef.current?.data ||
      graphRef.current.data.length === 0 ||
      !graphRef.current.layout ||
      currentChartType === EChartType.heatmap
    ) {
      return;
    }

    const dataConfigList = getDataConfigList({
      cageDataList: [{ cageList: chartEntityList }],
      xAxis,
      yAxis,
      xAxisScaleType,
      yAxisScaleType,
      currentChartType,
      currentColorScale,
      isChartFillEnabled,
      isStackedChartsChecked,
      isStackedAndFilledEnabled: isStackedAndFilledEnabled && isStackedChartsChecked,
      currentHistogramDataGroupType,
      customHistogramBinsAmount,
      kernelBinsAmountMultiplier,
      pageType,
      selectedGate,
      entityLevelGateList,
      origDataRange,
      saveNewDensityBandwidth,
      highlightDotsBy,
    });

    const layoutConfig = getChartsLayoutConfig({
      dataCount: dataConfigList.length,
      currentChartType,
      xAxisScaleType,
      yAxisScaleType,
      isTickLabelsVisible: true,
      dragmode: true,
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      range: plotRange,
      pageType,
    });

    const axesTitleLayoutConfig = getAxesTitlesUpdatedLayout(xAxisLabel, yAxisLabel);
    if (axesTitleLayoutConfig['xaxis.title'] && axesTitleLayoutConfig['yaxis.title']) {
      layoutConfig.xaxis.title = {
        ...layoutConfig.xaxis.title,
        ...axesTitleLayoutConfig['xaxis.title'],
      };

      layoutConfig.yaxis.title = {
        ...layoutConfig.yaxis.title,
        ...axesTitleLayoutConfig['yaxis.title'],
      };
    }

    plotlyProxy.react(dataConfigList, layoutConfig, {
      ...CONFIG(),
      scrollZoom: EModebarTools.zoom === selectedTool,
      staticPlot: isStatic,
    });
  }, [debounceData.currentChartType, debounceData.xAxisScaleType, debounceData.yAxisScaleType, isStatic]);

  const hookResult = useMemo(
    () => ({
      entitiesDataByGates,
      chartEntityList,
      isPlotLoaded,
      isChartEntityListLoading,
      chartEntityListError,
      isChartEntityListError,
      isModebarControlsDisabled,
      isAllPlotDataLoaded,
      isEmptyData,
      isShowLoader,
    }),
    [
      entitiesDataByGates,
      chartEntityList,
      isPlotLoaded,
      isChartEntityListLoading,
      chartEntityListError,
      isChartEntityListError,
      isModebarControlsDisabled,
      isAllPlotDataLoaded,
      isEmptyData,
      isShowLoader,
    ]
  );

  return hookResult;
};
