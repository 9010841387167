import { FC, memo, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import Flip from 'gsap/Flip';

import { useNavigatorCurrentDatasetName } from '@/hooks';
import { ContoursContextProvider } from '@/hooks/useContoursContext';

import { experimentSelectors } from '@/store/slices/experiment';

import Modal from '@/components/common/Modal';
import DisplaySettings from '@/components/navigator/DisplaySettings';

import NavigatorSettings from './NavigatorSettings';
import Lanes from './NavigatorSettings/Lanes';
import Zoom from './NavigatorSettings/Zoom';
import ChannelList from './NavigatorSettings/ChannelList';
import EntitiesSearchBlock from './NavigatorSettings/EntitiesSearchBlock';
import Viewer from './Viewer';
import Timeline from './Timeline';

import styles from './Navigator.module.scss';

const cn = classnames.bind(styles);

type TNavigatorProps = {
  dataset: TDataset;
  openCageInspector: (props: TCageInspectorModalProps) => void;
};

const ANIMATION_DURATION_S = 0.3;
const ANIMATION_FLIP_CLASS = 'navigator-flip-block';

const Navigator: FC<TNavigatorProps> = ({ dataset, openCageInspector }) => {
  const viewerRef = useRef<Nullable<HTMLDivElement>>(null);

  const [screenStateModifiers, setScreenStateModifiers] = useState({
    // fullscreen: false,
    expand: false,
    square: false,
  });
  const isInTransitionRef = useRef(false); // this state is for optimising resize animations

  const onStartTransition = () => {
    isInTransitionRef.current = true;

    setTimeout(() => {
      isInTransitionRef.current = false;
    }, ANIMATION_DURATION_S * 1000);
  };

  const toggleFullScreen = useCallback(() => {
    if (!viewerRef.current) return;

    if (!document.fullscreenElement) {
      viewerRef.current.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);

  const toggleExpandMode = useCallback(() => {
    onStartTransition();

    const state = Flip.getState(`.${ANIMATION_FLIP_CLASS}`);

    setScreenStateModifiers((prev) => ({
      ...prev,
      expand: !prev.expand,
    }));

    requestAnimationFrame(() => {
      Flip.from(state, { absolute: true, duration: ANIMATION_DURATION_S });
    });
  }, []);

  const toggleScreenRatio = useCallback(() => {
    onStartTransition();

    const state = Flip.getState(`.${ANIMATION_FLIP_CLASS}`);

    setScreenStateModifiers((prev) => ({
      ...prev,
      square: !prev.square,
    }));

    requestAnimationFrame(() => {
      Flip.from(state, { absolute: true, duration: ANIMATION_DURATION_S });
    });
  }, []);

  return (
    <div
      className={cn('navigator', {
        navigator_expand: screenStateModifiers.expand,
      })}
    >
      <div ref={viewerRef} className={cn('navigator__viewer', ANIMATION_FLIP_CLASS)}>
        <Viewer
          scanId={dataset.scanId}
          laneId={dataset.laneId}
          screenState={{
            changeFullScreen: toggleFullScreen,
            changeExpandMode: toggleExpandMode,
            changeScreenRatio: toggleScreenRatio,
            screenStateModifiers,
          }}
          isInTransitionRef={isInTransitionRef}
          className={cn(ANIMATION_FLIP_CLASS)}
          openCageInspector={openCageInspector}
        />
      </div>

      <NavigatorSettings className={cn('navigator__settings', ANIMATION_FLIP_CLASS)}>
        <NavigatorSettings.Row>Lanes</NavigatorSettings.Row>
        <NavigatorSettings.Row>
          <Lanes />
        </NavigatorSettings.Row>
        <NavigatorSettings.Row>
          <Zoom laneId={dataset.laneId} />
        </NavigatorSettings.Row>
        <NavigatorSettings.Row>
          <DisplaySettings />
        </NavigatorSettings.Row>
        <NavigatorSettings.Row>
          <ChannelList />
        </NavigatorSettings.Row>
        <NavigatorSettings.Row>
          <EntitiesSearchBlock openCageInspector={openCageInspector} />
        </NavigatorSettings.Row>
      </NavigatorSettings>

      <Timeline className={cn('navigator__timeline', ANIMATION_FLIP_CLASS)} />
    </div>
  );
};

const MemoNavigator = memo(Navigator);

type TNavigatorModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  dataset: TDataset;
  openCageInspector: (props: TCageInspectorModalProps) => void;
};

const NavigatorModal: FC<TNavigatorModalProps> = ({ isOpen = false, closeModal, dataset, openCageInspector }) => {
  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const datasetName = useNavigatorCurrentDatasetName(dataset.name);

  const navigatorProps = {
    dataset,
    openCageInspector,
  };

  return (
    // todo: pass close method via provider
    <Modal isOpen={isOpen} onRequestClose={closeModal} isFullScreen className={cn('navigator-modal')}>
      <Modal.Header onRequestClose={closeModal} className={cn('navigator-modal__header')} withDivide={false}>
        <div className={cn('navigator-modal__header-wrap')}>
          <div className={cn('navigator-modal__header-block')}>{experimentName}</div>
          <div className={cn('navigator-modal__header-block')}>{datasetName}</div>
        </div>
      </Modal.Header>
      <Modal.Content className={cn('navigator-modal__content')}>
        {isOpen && (
          <ContoursContextProvider>
            <MemoNavigator {...navigatorProps} />
          </ContoursContextProvider>
        )}
      </Modal.Content>
    </Modal>
  );
};
export default memo(NavigatorModal);
