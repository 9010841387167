import { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import { ETooltipTypeMap } from '@/helpers/tooltip';

import icons from '@/components/common/icons';
import CheckboxInput from '@/components/common/CheckboxInput';
import { useDebounce } from '@/hooks';

import styles from '../MainBlock.module.scss';

const cn = classnames.bind(styles);

type TAddMediaBlockProps = {
  scanNumberOfScans: number;
  onChange: ({ value }: { value: boolean }) => void;
  checkedByDefault: boolean;
};

const AddMediaBlock: FC<TAddMediaBlockProps> = ({ scanNumberOfScans, onChange, checkedByDefault }) => {
  const debouncedDefaultData = useDebounce(checkedByDefault);
  const [isChecked, setIsChecked] = useState(debouncedDefaultData);

  const onSwitchChange = () => {
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    setIsChecked(checkedByDefault);
  }, [checkedByDefault]);

  useEffect(() => {
    onChange({ value: isChecked });
  }, [isChecked]);

  return (
    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title', 'main-block__block-title_with-wrap')}>
            Media refresh (based on scans number)
          </div>
          <span className={cn('main-block__block-text')}>{`${scanNumberOfScans} times`} </span>
        </div>
      </div>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title', 'main-block__block-title_with-wrap')}>Treatment</div>
          <div className={cn('main-block__toggle-box', 'main-block__toggle-box_bigger-gap')}>
            <CheckboxInput
              checked={isChecked}
              isSwitch
              theme="run-design"
              onChange={onSwitchChange}
              label="Add treatment"
              labelClassName={cn('main-block__checkbox-label')}
            />
            <div className={cn('main-block__info')} {...addTooltip('Add treatment', 'top', ETooltipTypeMap.dialog)}>
              <icons.InfoIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMediaBlock;
