import { FC, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { useFetchNextToken, useUserRole } from '@/hooks';
import { useOpenModal } from '@/hooks/useOpenModal';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { USER_TABLE_FIELD_LIST } from '@/helpers/user/tableFieldList';

import { authAPI } from '@/store/services/auth';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import icons from '@/components/common/icons';
import PageHeader from '@/components/Layout/Page/PageHeader';
import { TAction } from '@/components/common/StandardTable/types';
import StandardTable from '@/components/common/StandardTable';
import Button from '@/components/common/Button';
import InviteUserModal from '@/components/admin/modals/InviteUserModal';
import UserPermissionsModal from '@/components/admin/modals/UserPermissionsModal';

import UsersControlPanel from './components/UsersControlPanel';

import styles from './UserList.module.scss';

const cn = classnames.bind(styles);

const UserList: FC = () => {
  const confirmationModal = useConfirmationModalContext();
  const { isUserCreateAllowed, isUserUpdateAllowed, isUserDeleteAllowed } = useUserRole();

  const [currentUsername, setCurrentUsername] = useState('');

  const queryData = useMemo(() => ({}), []);
  const {
    count: userCount,
    error,
    isError,
    isListLoading: isLoading,
    isListLoadingDebounced: isUserListLoadingDebounced,
    list: userList,
  } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
    queryData,
  });

  const [removeUser] = authAPI.useRemoveUserFromOrganizationMutation();
  const [changeMFASettings] = authAPI.useChangeMFAMutation();

  const {
    isOpen: isInviteModalOpen,
    setIsOpen: setIsInviteModalOpen,
    onClose: handleCloseInviteModal,
  } = useOpenModal();

  const {
    isOpen: isUserPermissonsModalOpen,
    setIsOpen: setUserPermissonsModalOpen,
    onClose: handleCloseUserPermissonsModal,
  } = useOpenModal();

  const [searchQuery, setSearchQuery] = useState('');

  const handleInviteClick = useCallback(() => {
    setIsInviteModalOpen(true);
  }, []);

  const closeUserPermissionsModal = () => {
    handleCloseUserPermissonsModal();
    setCurrentUsername('');
  };

  const userActions = useMemo<TAction<TUser>[]>(() => {
    const actions: TAction<TUser>[] = [
      {
        label: () => 'See user permissions',
        icon: <icons.LockIcon />,
        handler: (user) => {
          setCurrentUsername(user.username);
          setUserPermissonsModalOpen(true);
        },
      },
    ];

    if (isUserDeleteAllowed) {
      actions.push({
        label: () => 'Remove user',
        icon: <icons.DeleteIcon />,
        className: cn('remove-btn'),
        handler: async (user) => {
          const isConfirmed = await confirmationModal.onOpen({
            confirmationText: (
              <>
                Are you sure you want to remove user <br />
                <strong>{user.displayName}</strong>?
              </>
            ),
            approveButtonText: 'Remove',
          });
          if (!isConfirmed) {
            return;
          }
          removeUser(user.username);
        },
      });
    }
    if (isUserUpdateAllowed) {
      actions.push({
        label: () => 'Reset MFA',
        icon: <icons.DisableIcon />,
        handler: async (user) => {
          const isConfirmed = await confirmationModal.onOpen({
            confirmationText: (
              <>
                Are you sure you want to reset MFA for user <br />
                <strong>{user.displayName}</strong>?
              </>
            ),
            approveButtonText: 'Reset',
          });
          if (!isConfirmed) {
            return;
          }
          changeMFASettings({ status: 'DISABLED', username: user.username })
            .then((result) => {
              if ('error' in result) {
                showErrorToast(getErrorMessage(result.error));
              } else {
                toast.success(`MFA was reset successfully for ${user.displayName}`);
              }
            })
            .catch((err) => {
              showErrorToast(getErrorMessage(err));
            });
        },
      });
    }
    return actions;
  }, [isUserDeleteAllowed, isUserUpdateAllowed]);

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content')}>
        <PageHeader>
          <PageHeader.Section>
            <PageHeader.Title as="h1">Users Dashboard</PageHeader.Title>
          </PageHeader.Section>
          <PageHeader.Section>
            <PageHeader.Title isStatistics>{userCount}</PageHeader.Title>
            <PageHeader.Subtitle>{userCount === 1 ? 'User' : 'Users'}</PageHeader.Subtitle>
          </PageHeader.Section>
          {isUserCreateAllowed && isUserUpdateAllowed && (
            <PageHeader.Section className={cn('header-actions')}>
              <Button className={cn('header-action')} onClick={handleInviteClick}>
                Invite user
              </Button>
            </PageHeader.Section>
          )}
        </PageHeader>

        <UsersControlPanel
          isLoading={isUserListLoadingDebounced}
          isSearchDisabled={userCount === 0}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        <StandardTable<TUser>
          dataList={userList}
          fieldList={USER_TABLE_FIELD_LIST}
          keyFieldName="username"
          searchQuery={searchQuery}
          isLoading={isLoading}
          errorMessage={isError ? getErrorMessage(error) : undefined}
          actions={userActions}
          actionsFormat="popover"
        />
      </div>
      <InviteUserModal closeModal={handleCloseInviteModal} isModalOpen={isInviteModalOpen} />
      {currentUsername && (
        <UserPermissionsModal
          isModalOpen={isUserPermissonsModalOpen}
          username={currentUsername}
          closeModal={closeUserPermissionsModal}
        />
      )}
    </div>
  );
};

export default UserList;
