import { iconIdList, TIconId } from '@/helpers/runDesigns/constants';
import { isIncubationComponent } from '@/helpers/runDesigns/typeGuards';
import getTimeUnits from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getTimeUnits';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

export const getComponentIcon = (component: Nullable<TRunDesignComponent>): TIconId[] => {
  if (!component) {
    return [];
  }

  const iconsMap: Record<string, TIconId> = {
    CellCaging: iconIdList.iconCaging,
    CellKilling: iconIdList.iconKilling,
    CytokineSecretion: iconIdList.iconSecretion,
    Incubation: iconIdList.iconIncubation,
    mRNA: iconIdList.iconMRna,
    SelectiveRetention: iconIdList.iconRetention,
    SurfaceReceptor: iconIdList.iconReceptor,
    SurfaceReceptorCytokineSecretion: iconIdList.iconReceptor,
  };

  let icon = iconsMap[component.type];
  if (isIncubationComponent(component) && !component.incubation?.onDevice) {
    icon = iconIdList.iconIncubationOffDevice;
  }

  return icon ? [icon] : [];
};

export const formatDurationText = ({ duration, comment = '' }: { duration: number; comment?: string }) => {
  const { hours, minutes, seconds } = getTimeUnits(duration);

  const durationHourPart = `${hours}h`;
  const durationMinutePart = minutes ? ` ${minutes}m` : '';
  const durationSecondPart = seconds ? ` ${seconds}s` : '';
  const commentPart = comment ? ` (${comment})` : '';

  return `${durationHourPart}${durationMinutePart}${durationSecondPart}${commentPart}`;
};

export const getTimeText = (timeInSeconds: number) => {
  const { hours, minutes } = getTimeUnits(timeInSeconds);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
