export enum EChannelName {
  white = 'White',
  blue = 'Blue',
  green = 'Green',
  red = 'Red',
  violet = 'Violet',
}

export type TChannelData = {
  name: EChannelName;
  channelNameList: string[];
  shaderColor: string;
  uiColor: string;
};

const WHITE_UPPER_NAME_LIST = ['WHITE.CAGING', 'WHITE', 'UBG', 'BF', 'BRIGHTFIELD'];
const BLUE_UPPER_NAME_LIST = ['BLUE', 'CYAN', 'CY7'];
const GREEN_UPPER_NAME_LIST = ['GREEN', 'CY3'];
const RED_UPPER_NAME_LIST = ['RED', 'CY5'];
const VIOLET_UPPER_NAME_LIST = ['UV (DMD)', 'UV', 'VIOLET'];

export const channelDataList: TChannelData[] = [
  {
    name: EChannelName.white,
    channelNameList: WHITE_UPPER_NAME_LIST,
    shaderColor: '#eeeeee',
    uiColor: '#ffffff',
  },
  {
    name: EChannelName.blue,
    channelNameList: BLUE_UPPER_NAME_LIST,
    shaderColor: '#0000ff',
    uiColor: '#d5f2ff',
  },
  { name: EChannelName.green, channelNameList: GREEN_UPPER_NAME_LIST, shaderColor: '#00ff00', uiColor: '#defddf' },
  { name: EChannelName.red, channelNameList: RED_UPPER_NAME_LIST, shaderColor: '#ff0000', uiColor: '#fde4e4' },
  { name: EChannelName.violet, channelNameList: VIOLET_UPPER_NAME_LIST, shaderColor: '#8e24aa', uiColor: '#f4e6ff' },
];

export const forceWhiteChannelFirst = (channelIdList: string[]) => {
  channelIdList.sort((a: string) => (WHITE_UPPER_NAME_LIST.includes(a.toUpperCase()) ? -1 : 0));
};

export const isChannelIdInChannelData = (channelId: string, channelData: TChannelData) => {
  const channelIdUpper = channelId.toUpperCase();
  return channelData.channelNameList.some((channelIdPartUpper) => channelIdUpper.startsWith(channelIdPartUpper));
};

export const sortChannelIdList = (channelIdList: string[]) => {
  channelIdList.sort((a, b) => {
    const aIndex = channelDataList.findIndex((channelData) => isChannelIdInChannelData(a, channelData));
    const bIndex = channelDataList.findIndex((channelData) => isChannelIdInChannelData(b, channelData));
    if (aIndex === -1 || bIndex === -1) {
      return 0;
    }
    if (aIndex > bIndex) {
      return 1;
    }
    return -1;
  });
};

export const getChannelShaderColor = (channelId: string) => {
  const channelData = channelDataList.find((channelDataItem) => isChannelIdInChannelData(channelId, channelDataItem));
  return channelData ? channelData.shaderColor : '#eeeeee';
};

export const getChannelName = (channelId: string, shouldReturnComplexName = true) => {
  const channelData = channelDataList.find((channelDataItem) => isChannelIdInChannelData(channelId, channelDataItem));
  if (channelData) {
    if (shouldReturnComplexName) {
      const channelUpperName = channelData.channelNameList.find((upperName: string) =>
        channelId.toUpperCase().startsWith(upperName)
      );
      if (channelUpperName) {
        return channelData.name + channelId.substring(channelUpperName.length);
      }
    } else {
      return channelData.name;
    }
  }
  return channelId;
};

export const getChannelUiColor = (channelName = '') => {
  const channelData = channelDataList.find((channelDataItem) => channelName.includes(channelDataItem.name));
  return channelData ? channelData.uiColor : '';
};

export const getUniqueChannelNameList = (channelIdList: string[]) => {
  const channelNameList: string[] = [];
  channelDataList.forEach((channelData) => {
    channelIdList.forEach((channelId) => {
      const channelName = getChannelName(channelId);
      if (isChannelIdInChannelData(channelId, channelData) && !channelNameList.includes(channelName)) {
        channelNameList.push(channelName);
      }
    });
  });
  return channelNameList;
};

export const getChannelFieldNameList = (channelIdList: string[]) =>
  channelIdList.reduce((acc, channelId) => {
    acc.push(
      `${channelId}_tot`, // both levels
      `${channelId}_var`, // object level
      `${channelId}_mean`, // both levels
      `${channelId}_bg_mean`, // both levels
      `${channelId}_mean_sub` // object level
      // `${channelId}_weighted_mean`, // cage level
      // `${channelId}_bg_tot`, // cage level
      // `${channelId}_weighted_bg_mean`, // cage level
    );
    return acc;
  }, [] as string[]);

export const getScatterPlotObjectLevelAxesOptions = (channelIdList: string[]): TBasicOption[] => {
  const scatterPlotObjectLevelAxesOptions = [
    { value: 'object_area_px', label: 'Object Area PX' },
    { value: 'eccentricity', label: 'Eccentricity' },
    { value: 'perimeter_area_ratio', label: 'Perimeter Area Ratio' },
    { value: 'cage_center_dist', label: 'Cage Center Dist' },
    { value: 'is_cell', label: 'Is Cell' },
  ];

  channelIdList.forEach((channel) => {
    scatterPlotObjectLevelAxesOptions.push(
      { value: `${channel}_tot`, label: `${channel} Tot` },
      { value: `${channel}_var`, label: `${channel} Var` },
      { value: `${channel}_mean`, label: `${channel} Mean` },
      { value: `${channel}_bg_mean`, label: `${channel} BG Mean` },
      { value: `${channel}_mean_sub`, label: `${channel} Mean Sub` }
    );
  });

  return scatterPlotObjectLevelAxesOptions;
};

export const getScatterPlotCageLevelAxesOptions = (channelIdList: string[], isHeatmap = false): TBasicOption[] => {
  const scatterPlotCageLevelAxesOptions: Array<TBasicOption> = isHeatmap
    ? []
    : [
        { value: 'object_count', label: 'Object Count' },
        // { value: 'is_cell', label: 'Is Cell' },
        { value: 'cell_object_area_px_mean', label: 'Cell Object Area Px Mean' },
        // { value: 'object_area_px', label: 'Object Area PX' },
        { value: 'cell_eccentricity_mean', label: 'Cell Eccentricity Mean' },
        // { value: 'eccentricity', label: 'Eccentricity' },
        // { value: 'perimeter_area_ratio', label: 'Perimeter Area Ratio' },
        // { value: 'cage_center_dist', label: 'Cage Center Dist' },
        { value: 'cell_count', label: 'Cell Count' },
        { value: 'bead_count', label: 'Bead Count' },
      ];

  channelIdList.forEach((channel) => {
    scatterPlotCageLevelAxesOptions.push(
      // { value: `${channel}_tot`, label: `${channel} Tot` },
      { value: `${channel}_mean`, label: `${channel} Mean` }
      // { value: `${channel}_weighted_mean`, label: `${channel} Weighted Mean` },
      // { value: `${channel}_bg_tot`, label: `${channel} BG Tot` },
      // { value: `${channel}_bg_mean`, label: `${channel} BG Mean` }
      // { value: `${channel}_weighted_bg_mean`, label: `${channel} Weighted BG Mean` }
    );
  });

  return scatterPlotCageLevelAxesOptions;
};

export const getDatasetChannelNameByChannelName = (
  datasetChannels: string[],
  channelName: string
): string | undefined => {
  const channelData = channelDataList.find((el) => isChannelIdInChannelData(channelName, el));
  if (!channelData) {
    return;
  }
  return datasetChannels.find((channelId) => isChannelIdInChannelData(channelId, channelData));
};

export const isEqualAxes = (channelIdA: unknown, channelIdB: unknown) => {
  const regExps = [
    new RegExp(`(${WHITE_UPPER_NAME_LIST.join('|')})`, 'gi'),
    new RegExp(`(${BLUE_UPPER_NAME_LIST.join('|')})`, 'gi'),
    new RegExp(`(${GREEN_UPPER_NAME_LIST.join('|')})`, 'gi'),
    new RegExp(`(${RED_UPPER_NAME_LIST.join('|')})`, 'gi'),
    new RegExp(`(${VIOLET_UPPER_NAME_LIST.join('|')})`, 'gi'),
  ];
  if (typeof channelIdA === 'string' && typeof channelIdB === 'string') {
    return regExps.some((re) => channelIdA.replace(re, '') === channelIdB.replace(re, '')) || channelIdA === channelIdB;
  }

  return false;
};
