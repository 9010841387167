import { SVGProps } from 'react';

const ProjectsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <mask id="path-2-inside-1_12052_5813" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C10.8954 12 10 12.8954 10 14V17.2105V19V26.4737C10 27.5783 10.8954 28.4737 12 28.4737H29C30.1046 28.4737 31 27.5783 31 26.4737V17.2105C31 16.106 30.1046 15.2105 29 15.2105H21V14C21 12.8954 20.1046 12 19 12H12Z"
      />
    </mask>
    <path
      d="M21 15.2105H19.5V16.7105H21V15.2105ZM11.5 14C11.5 13.7239 11.7239 13.5 12 13.5V10.5C10.067 10.5 8.5 12.067 8.5 14H11.5ZM11.5 17.2105V14H8.5V17.2105H11.5ZM11.5 19V17.2105H8.5V19H11.5ZM11.5 26.4737V19H8.5V26.4737H11.5ZM12 26.9737C11.7239 26.9737 11.5 26.7498 11.5 26.4737H8.5C8.5 28.4067 10.067 29.9737 12 29.9737V26.9737ZM29 26.9737H12V29.9737H29V26.9737ZM29.5 26.4737C29.5 26.7498 29.2761 26.9737 29 26.9737V29.9737C30.933 29.9737 32.5 28.4067 32.5 26.4737H29.5ZM29.5 17.2105V26.4737H32.5V17.2105H29.5ZM29 16.7105C29.2761 16.7105 29.5 16.9344 29.5 17.2105H32.5C32.5 15.2775 30.933 13.7105 29 13.7105V16.7105ZM21 16.7105H29V13.7105H21V16.7105ZM19.5 14V15.2105H22.5V14H19.5ZM19 13.5C19.2761 13.5 19.5 13.7239 19.5 14H22.5C22.5 12.067 20.933 10.5 19 10.5V13.5ZM12 13.5H19V10.5H12V13.5Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_12052_5813)"
    />
    <path d="M23 13H29.1765" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M13.9412 15.5H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ProjectsIcon;
