import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './SortedFieldHeading.module.scss';

const cn = classnames.bind(styles);

type TSortedFieldHeadingProps = {
  id: string;
  fieldName: string;
  sortingOrder: string;
  sortingField: string;
  onChangeSorting: (fieldName: string) => void;
  children: ReactNode;
  className?: string;
  arrowPositionMode?: 'relative' | 'absolute';
};

const SortedFieldHeading: FC<TSortedFieldHeadingProps> = ({
  id,
  fieldName,
  sortingOrder,
  sortingField,
  onChangeSorting,
  children,
  className,
  arrowPositionMode,
}) => {
  const handleArrowClick = () => {
    onChangeSorting(fieldName);
  };

  return (
    <div className={cn('sorted-field-heading', className)}>
      <label htmlFor={id} className={cn('sorted-field-heading__label')}>
        {children}
      </label>
      <button
        className={cn('sorted-field-heading__arrow', `sorted-field-heading__arrow_${arrowPositionMode}`, {
          'sorted-field-heading__arrow_current': sortingField === fieldName,
          _asc: sortingOrder === 'asc',
          _dec: sortingOrder === 'dec',
        })}
        onClick={handleArrowClick}
        id={id}
        aria-label="Sort by"
      />
    </div>
  );
};

export default SortedFieldHeading;
