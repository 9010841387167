import { useCallback } from 'react';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { antnAPI } from '@/store/services/annotation';

const useCellNameSearch = () => {
  const [fetchAnnotations] = antnAPI.useLazyFetchAnnotationsQuery();

  const getData = useCallback(async (inputValue: string, filterList: Record<string, string>) => {
    if (inputValue.length <= 2) {
      return [];
    }

    try {
      return (
        (await fetchAnnotations({ type: EAnnotationType.cellIndex, search: inputValue, filterList }, true).unwrap()) ??
        []
      );
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  }, []);

  return {
    getData,
  };
};

export default useCellNameSearch;
