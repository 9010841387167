import { createSlice } from '@reduxjs/toolkit';

import { TGatesAutoPreprocessingState } from './types';

const initialState: TGatesAutoPreprocessingState = {
  isPreprocessingInProgress: false,
  preprocessingResultMap: {},
};

const gatesAutoPreprocessingSlice = createSlice({
  name: 'gatesAutoPreprocessing',
  initialState,
  reducers: {
    setIsPreprocessingInProgress: (state, action) => {
      state.isPreprocessingInProgress = action.payload;
    },
    setCalculatedCageLevelSummaryStats: (state, action) => {
      const { experimentId, preprocessingResult } = action.payload;
      state.preprocessingResultMap[experimentId] = preprocessingResult;
    },
  },
});

export default gatesAutoPreprocessingSlice;
