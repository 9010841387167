import { FC, memo, MouseEventHandler } from 'react';
import classNames from 'classnames/bind';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import { TDefaultPageControlsConfig } from './types';

import styles from './PageControls.module.scss';

const cn = classNames.bind(styles);

type TPageControlsProps = {
  config: TDefaultPageControlsConfig;
};

const PageControls: FC<TPageControlsProps> = ({ config }) => {
  const handleBackClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    config.back?.clickHandler?.(ev);
  };

  const handleSaveClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    config.save?.clickHandler?.(ev);
  };

  const handleEditClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    config.edit?.clickHandler?.(ev);
  };

  const handleNextClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    config.next?.clickHandler?.(ev);
  };

  return (
    <div className={cn('page-controls')}>
      <div className={cn('page-controls__group', 'page-controls__group_left')}>
        {config.back && (
          <button
            className={cn('page-controls__button', 'page-controls__back')}
            onClick={handleBackClick}
            aria-label="back"
            disabled={config.back.disabled}
          >
            <icons.BackArrowIcon />
          </button>
        )}
        {config.save && (
          <Button
            color="light"
            isOutlined
            className={cn('page-controls__button', 'page-controls__save')}
            onClick={handleSaveClick}
            disabled={config.save.disabled}
            isLoading={config.save.isLoading}
          >
            Save & Exit
          </Button>
        )}
      </div>

      {config.navigation && (
        <div className={cn('page-controls__group', 'page-controls__group_center')}>
          {config.navigation.map(({ step, label, isCurrent, clickHandler, disabled }, index) => (
            <button
              key={step}
              className={cn('page-controls__step-selector', { 'page-controls__step-selector_current': isCurrent })}
              onClick={clickHandler}
              disabled={disabled}
            >
              <div className={cn('page-controls__step-selector-number')}>{index + 1}</div>
              <div className={cn('page-controls__step-selector-text')}>{label}</div>
            </button>
          ))}
        </div>
      )}

      <div className={cn('page-controls__group', 'page-controls__group_right')}>
        {config.edit && (
          <Button
            color="light"
            className={cn('page-controls__button', 'page-controls__edit')}
            isOutlined
            onClick={handleEditClick}
            disabled={config.edit.disabled}
          >
            <icons.SettingsIcon />
            {config.edit.customTitle ?? 'Edit'}
          </Button>
        )}
        {config.next && (
          <Button
            color="yellow"
            className={cn('page-controls__button', 'page-controls__next')}
            onClick={handleNextClick}
            disabled={config.next.disabled}
            isLoading={config.next.isLoading}
          >
            {config.next.customTitle ?? 'Next'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(PageControls);
