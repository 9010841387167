/** TODO: Refactor - use one component for the inputs. See: TextInput */

import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './Input.module.scss';

const cn = classnames.bind(styles);

type TInputProps = {
  handleSave?: (currentValue: string) => void;
  initialValue: string;
  maxLength?: number;
};

const Input: FC<TInputProps> = ({ handleSave, initialValue, maxLength = 150 }) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (handleSave && value !== initialValue) {
      handleSave(value);
    }
  };

  const onEsc = () => {
    setValue(initialValue);
  };

  const onEnter = () => {
    inputRef.current?.blur();
  };

  const onInputKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {
    let { key } = event;
    const keyList: Record<string, () => void> = {
      Enter: onEnter,
      Escape: onEsc,
      default: () => null,
    };

    if (!Object.keys(keyList).includes(key)) {
      key = 'default';
    }

    keyList[key]();
  };

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <input
      ref={inputRef}
      value={value}
      onChange={handleOnChange}
      onBlur={onBlur}
      onKeyUp={onInputKeyUp}
      className={cn('input')}
      maxLength={maxLength}
    />
  );
};
export default Input;
