import {
  ReactNode,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  ReactEventHandler,
  KeyboardEventHandler,
} from 'react';
import classNames from 'classnames/bind';

import { ETooltipTypeMap } from '@/helpers/tooltip';

import { Tooltip } from 'react-tooltip';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import styles from './DialogModal.module.scss';

const cn = classNames.bind(styles);

type TDialogModalProps = {
  children?: ReactNode;
  className?: string;
  open?: boolean;
  onClose?: () => void;
  title?: string;
};

const DialogModal: ForwardRefRenderFunction<HTMLDialogElement, TDialogModalProps> = (
  { children, className, open = false, onClose, title },
  outerRef
) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!outerRef || typeof outerRef !== 'object') {
      return;
    }

    outerRef.current = dialogRef.current;
  }, [dialogRef.current]);

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }

    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (open) {
      dialogRef.current.showModal();
    } else {
      // animation on dialog close
      setIsClosing(true);

      timeoutId = setTimeout(() => {
        setIsClosing(false);
        dialogRef.current?.close();
      }, 500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [open]);

  // handle backdrop click
  const handleDialogClick: MouseEventHandler<HTMLDialogElement> = (ev) => {
    if (ev.target === dialogRef.current) {
      onClose?.();
    }
  };

  const handleDialogClose: ReactEventHandler<HTMLDialogElement> = (ev) => {
    ev.stopPropagation();
    onClose?.();
  };

  const handleDialogKeyDown: KeyboardEventHandler<HTMLDialogElement> = (ev) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      onClose?.();
    }
  };

  return (
    <dialog
      className={cn('dialog-modal', { _closing: isClosing }, className)}
      ref={dialogRef}
      role="presentation"
      onClick={handleDialogClick}
      onClose={handleDialogClose}
      onKeyDown={handleDialogKeyDown}
    >
      <div className={cn('dialog-modal__header')}>
        <div className={cn('dialog-modal__title')}>{title}</div>
        <Button color="light" className={cn('dialog-modal__close')} onClick={onClose}>
          <icons.CloseIcon />
        </Button>
      </div>
      <div className={cn('dialog-modal__content')}>
        {children}

        <Tooltip id={ETooltipTypeMap.dialog} opacity={1} clickable openEvents={{ mouseenter: true }} />
      </div>
    </dialog>
  );
};

export default forwardRef<HTMLDialogElement, TDialogModalProps>(DialogModal);
