const useLocalStorage = (initialKey?: string) => {
  function getLocalValue(): string;
  function getLocalValue(key: string): string;
  function getLocalValue(key?: string) {
    if (key === undefined) {
      if (initialKey) {
        return localStorage.getItem(initialKey);
      }

      throw new Error(
        `Can't get local value, because key "${key}" and initial key "${initialKey}" are not valid strings.`
      );
    }

    return localStorage.getItem(key);
  }

  function setLocalValue(value: string): void;
  function setLocalValue(key: string, value: string): void;
  function setLocalValue(keyOrValue: string, value?: string) {
    if (value === undefined) {
      if (initialKey) {
        localStorage.setItem(initialKey, keyOrValue);
        return;
      }

      throw new Error(
        `Can't set local value, because key "${keyOrValue}" and initial key "${initialKey}" are not valid strings.`
      );
    }

    localStorage.setItem(keyOrValue, value);
  }
  return {
    getLocalValue,
    setLocalValue,
  };
};

export default useLocalStorage;
