import { CSSProperties, useMemo } from 'react';
import Slider, { SliderProps } from 'rc-slider';
import classnames from 'classnames/bind';

import 'rc-slider/assets/index.css';
import style from './Range.module.scss';
import TextInput from '../TextInput';

const cn = classnames.bind(style);

export type TRangeProps = {
  label?: string;
  type?: 'slider' | 'range';
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  value: number | [number, number];
  sliderValue?: number;

  onChange?: (_num: number | number[]) => void;
  className?: string;
  labelBlockClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  fixedValue?: number;
  isControlInput?: boolean;
  infoBlockClassName?: string;
  rangeHeight?: number;
  sliderValueClassName?: string;
};

const rangeHandleStyle: { slider: CSSProperties[]; range: CSSProperties[] } = {
  slider: [
    {
      position: 'relative',
      width: '18px',
      height: '18px',
      borderRadius: '10px',
      marginTop: '-7px',
      background: 'var(--handle-bg-color)',
      border: '2px solid var(--handle-border-color)',
      outline: '4px solid var(--handle-bg-color)',
      opacity: '1',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  ],
  range: [
    {
      width: '18px',
      height: '18px',
      borderRadius: '50%',
      marginLeft: '-9px',
      background: 'var(--color-white)',
      border: '2px solid var(--handle-border-color, --color-white-10)',
      opacity: '1',
      boxShadow: 'none',
      marginTop: '-8px',
    },
    {
      width: '18px',
      height: '18px',
      borderRadius: '50%',
      marginLeft: '9px',
      background: 'var(--color-white)',
      border: '2px solid var(--handle-border-color, --color-white-10)',
      opacity: '1',
      boxShadow: 'none',
      marginTop: '-8px',
    },
  ],
};

const Range = ({
  label = '',
  type = 'slider',
  disabled = false,
  min = -1,
  max = 1,
  step = 0.1,
  value,
  sliderValue,
  onChange,
  className,
  labelBlockClassName,
  labelClassName,
  fixedValue = 2,
  isControlInput = false,
  inputClassName,
  infoBlockClassName,
  rangeHeight = 2,
  sliderValueClassName,
}: TRangeProps) => {
  const RangeSliderProps: SliderProps = {
    range: type === 'range',
    min,
    max,
    step,
    allowCross: false,
    defaultValue: [0, 5000],
    value,
    handleStyle: rangeHandleStyle[type].map((qwe) => ({ ...qwe, cursor: disabled ? 'not-allowed' : 'pointer' })),
    railStyle: {
      height: `${rangeHeight}px`,
      background: 'var(--rail-color)',
    },
    trackStyle: {
      height: `${rangeHeight}px`,
      background: 'var(--track-color, --color-yellow-10, #ffffff)',
    },
    dotStyle: {
      marginTop: '-10px',
    },
    onChange,
    disabled,
    className,
  };

  const handleChangeMaxValue = (maxValue: string) => {
    if (!Array.isArray(value)) {
      return;
    }
    let [currentMin] = value;
    let newMaxValue = parseFloat(maxValue) || 0;
    newMaxValue = newMaxValue > max ? max : newMaxValue;
    newMaxValue = newMaxValue < min ? min : newMaxValue;
    currentMin = newMaxValue < currentMin ? newMaxValue : currentMin;
    if (onChange) {
      onChange([currentMin, newMaxValue]);
    }
  };

  const handleChangeMinValue = (minValue: string) => {
    if (!Array.isArray(value)) {
      return;
    }

    let [, currentMax] = value;
    let newMinValue = parseFloat(minValue) || 0;
    newMinValue = newMinValue < min ? min : newMinValue;
    newMinValue = newMinValue > max ? max : newMinValue;
    currentMax = newMinValue > currentMax ? newMinValue : currentMax;
    if (onChange) {
      onChange([newMinValue, currentMax]);
    }
  };

  const handleChangeValue = (changedValue: string) => {
    const str = changedValue.replace('.', '.0');
    let newValue = parseFloat(str) || 0;
    newValue = newValue > max ? max : newValue;
    newValue = newValue < min ? min : newValue;

    if (onChange) {
      onChange(newValue);
    }
  };

  const sliderValueToDisplay = useMemo(() => {
    if (sliderValue) {
      return sliderValue.toFixed(fixedValue);
    }

    if (Array.isArray(value)) return '';

    return value.toFixed(fixedValue);
  }, [sliderValue, value, fixedValue]);

  return (
    <label
      className={cn(
        'slider',
        {
          slider_disabled: disabled,
          slider__range: type === 'range',
        },
        labelBlockClassName
      )}
    >
      <div className={cn('slider__label', { 'slider__label-range': type === 'range' }, infoBlockClassName)}>
        <span className={cn('slider__top-label', labelClassName)}>{label}</span>
        {Array.isArray(value) ? (
          <div className={cn('slider__range-values')}>
            <TextInput
              disabled={disabled}
              className={cn('slider__range-value', inputClassName, { 'slider__range-zero-value': value[0] === 0 })}
              onChange={(e) => handleChangeMinValue(e.target.value)}
              value={value[0].toFixed(fixedValue)}
            />
            <TextInput
              disabled={disabled}
              className={cn('slider__range-value', inputClassName)}
              onChange={(e) => handleChangeMaxValue(e.target.value)}
              value={value[1].toFixed(fixedValue)}
            />
          </div>
        ) : (
          <>
            {!isControlInput && (
              <span className={cn('slider__value', sliderValueClassName)}>{sliderValueToDisplay}</span>
            )}
            {isControlInput && (
              <TextInput
                disabled={disabled}
                className={cn('slider__range-value', inputClassName)}
                onChange={(e) => handleChangeValue(e.target.value)}
                value={value.toFixed(fixedValue)}
              />
            )}
          </>
        )}
      </div>
      <Slider {...RangeSliderProps} />
    </label>
  );
};

export default Range;
