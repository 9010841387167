import type { TGateVariant } from '@/pages/Dataset/components/DatasetChart/types';
import {
  renderEllipseGate,
  renderPolarGate,
  renderPolygonGate,
  renderRangeGate,
  renderRectangleGate,
} from './renderer';
import { TRenderOnCanvasInput, TRenderOnSVGInput } from './types';
import {
  renderEllipseGateOnSVG,
  renderPolarGateOnSVG,
  renderPolygonGateOnSVG,
  renderRangeGateOnSVG,
  renderRectangleGateOnSVG,
} from './rendererOnSVG';

export const drawGateOnCanvas = (renderInput: TRenderOnCanvasInput) => {
  const { gate } = renderInput;

  if (!gate) {
    return;
  }

  const { type } = gate;

  const renderer: Record<TGateVariant, () => void> = {
    rectangle: () => {
      renderRectangleGate(renderInput);
    },
    polygon: () => {
      renderPolygonGate(renderInput);
    },
    ellipse: () => {
      renderEllipseGate(renderInput);
    },
    polar: () => {
      renderPolarGate(renderInput);
    },
    'split-gate': () => {
      // split-gate is the sub-type of the polar gate but with two sectors
      renderPolarGate(renderInput);
    },
    range: () => {
      renderRangeGate(renderInput);
    },
  };

  renderer[type]();
};

export const drawGateOnSvg = (renderInput: TRenderOnSVGInput) => {
  const { gate } = renderInput;

  if (!gate) {
    return;
  }

  const { type } = gate;

  const renderer: Record<TGateVariant, () => void> = {
    rectangle: () => {
      renderRectangleGateOnSVG(renderInput);
    },
    polygon: () => {
      renderPolygonGateOnSVG(renderInput);
    },
    ellipse: () => {
      renderEllipseGateOnSVG(renderInput);
    },
    polar: () => {
      renderPolarGateOnSVG(renderInput);
    },
    'split-gate': () => {
      // split-gate is the sub-type of the polar gate but with two sectors
      renderPolarGateOnSVG(renderInput);
    },
    range: () => {
      renderRangeGateOnSVG(renderInput);
    },
  };

  renderer[type]();
};
