import { FC, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames/bind';
import { syncResize } from '@/helpers';
import styles from './LayoutEmpty.module.scss';
import globalStyles from './LayoutEmptyGlobal.scss';

const cn = classNames.bind({
  ...styles,
  ...globalStyles,
});

const DESIGN_HEIGHT = 1080;
const MAX_ZOOM = 1.25;

const LayoutEmpty: FC = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onResize = () => {
      const currentHeight = window.innerHeight;
      const ratio = currentHeight / DESIGN_HEIGHT;
      const zoom = Math.min(MAX_ZOOM, ratio);

      layoutRef.current?.style.setProperty('--zoom', zoom.toFixed(2));
    };

    onResize();
    syncResize.subscribe(onResize);

    return () => {
      syncResize.unsubscribe(onResize);
    };
  }, []);

  return (
    <div ref={layoutRef} className={cn('layout', 'empty-layout')} id="empty-layout">
      <Outlet />
    </div>
  );
};

export default LayoutEmpty;
