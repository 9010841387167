import { SVGProps } from 'react';

const CageWallToOtherObjectsMinDistanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="50.5078"
      y="50.5"
      width="47"
      height="47"
      rx="23.5"
      transform="rotate(180 50.5078 50.5)"
      stroke="#EBEBEA"
      strokeWidth="7"
    />
    <path
      d="M26.2228 22.5782L25.2483 26.1011L28.2066 28.2479L24.8448 29.6825L25.0109 33.3339L21.7931 31.6L19.0419 34.0064L18.3913 30.4096L14.7945 29.7591L17.201 27.0078L15.4671 23.7901L19.1185 23.9562L20.5531 20.5943L22.6998 23.5527L26.2228 22.5782Z"
      fill="#57D4FB"
    />
    <rect width="10" height="10" rx="5" transform="matrix(-1 0 0 1 37.0078 15)" fill="#F9EF7D" />
    <rect width="10" height="10" rx="5" transform="matrix(-1 0 0 1 37.0078 29)" fill="#F9EF7D" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 14.0078 26)" fill="black" />
    <rect width="2" height="2" transform="matrix(-1 0 0 1 10.0078 26)" fill="black" />
  </svg>
);

export default CageWallToOtherObjectsMinDistanceIcon;
