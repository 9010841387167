import { SVGProps } from 'react';

const QRCodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="10.2941" height="10.2941" rx="2.5" stroke="currentColor" />
    <rect x="2.35297" y="2.35297" width="6.58824" height="6.58824" rx="3" fill="currentColor" />
    <rect x="13.2059" y="0.5" width="10.2941" height="10.2941" rx="2.5" stroke="currentColor" />
    <rect x="15.0588" y="2.35297" width="6.58824" height="6.58824" rx="3" fill="currentColor" />
    <rect x="0.5" y="13.2059" width="10.2941" height="10.2941" rx="2.5" stroke="currentColor" />
    <rect x="2.35297" y="15.0588" width="6.58824" height="6.58824" rx="3" fill="currentColor" />
    <rect x="13.2059" y="13.2059" width="10.2941" height="10.2941" rx="1.5" stroke="currentColor" />
    <rect
      x="14.1177"
      y="15.0588"
      width="0.941176"
      height="8.47059"
      rx="0.470588"
      transform="rotate(-90 14.1177 15.0588)"
      fill="currentColor"
    />
    <path
      d="M21.5294 14.6471C21.5294 14.3547 21.7664 14.1177 22.0588 14.1177V14.1177C22.3512 14.1177 22.5882 14.3547 22.5882 14.6471V17.353C22.5882 17.6454 22.3512 17.8824 22.0588 17.8824V17.8824C21.7664 17.8824 21.5294 17.6454 21.5294 17.353V14.6471Z"
      fill="currentColor"
    />
    <path
      d="M14.1177 19.353C14.1177 19.0606 14.3547 18.8235 14.6471 18.8235V18.8235C14.9395 18.8235 15.1765 19.0606 15.1765 19.353V22.0588C15.1765 22.3512 14.9395 22.5883 14.6471 22.5883V22.5883C14.3547 22.5883 14.1177 22.3512 14.1177 22.0588V19.353Z"
      fill="currentColor"
    />
    <rect
      x="14.1177"
      y="22.5883"
      width="0.941176"
      height="8.47059"
      rx="0.470588"
      transform="rotate(-90 14.1177 22.5883)"
      fill="currentColor"
    />
    <rect x="16" y="16" width="1.88235" height="1.88235" rx="0.941176" fill="currentColor" />
    <rect x="18.8235" y="18.8235" width="1.88235" height="1.88235" rx="0.941176" fill="currentColor" />
    <rect x="16" y="18.8235" width="1.88235" height="1.88235" rx="0.941176" fill="currentColor" />
    <rect x="18.8235" y="16" width="1.88235" height="1.88235" rx="0.941176" fill="currentColor" />
  </svg>
);

export default QRCodeIcon;
