import { sortByKey } from '@/helpers';

import { ScaleLinear } from 'd3-scale';
import { getScaleLinearArcSinh } from './helpers';

const getNearestIntPower10 = (value: number) => Math.floor(Math.log10(Math.abs(value)));

const roundUpWithPrecision = (value: number, precision: number) => Math.ceil(value / precision) * precision;

const roundUpWithSuitablePrecision = (value: number, power: number) => {
  const interimValue = value / 10 ** power;
  const data = [10, 5, 2].map((precision) => {
    const roundedValue = roundUpWithPrecision(interimValue, precision) * 10 ** power;
    return { roundedValue, diff: roundedValue - value };
  });
  sortByKey(data, 'diff');
  // take rounded value with min difference to value
  return data[0].roundedValue;
};

const SUP_NUMBER_MAP: Record<string, string> = {
  '0': '⁰',
  '1': '¹',
  '2': '²',
  '3': '³',
  '4': '⁴',
  '5': '⁵',
  '6': '⁶',
  '7': '⁷',
  '8': '⁸',
  '9': '⁹',
};

const getSupNumberText = (value: number) => {
  const strValue = Math.abs(value).toFixed();
  return strValue
    .split('')
    .map((strNumber) => SUP_NUMBER_MAP[strNumber])
    .join('');
};

const INTERVAL_COUNT = 10;

type TTickData = {
  linVal: number;
  asinhVal: number;
  text: string;
};

export default class ArcSinhTicksCreator {
  private tickDataList: TTickData[];

  private readonly scaleLinearArcSinh: ScaleLinear<number, number, unknown> | null;

  private readonly minLinVal: number = 0;

  private readonly maxLinVal: number = 0;

  private readonly linStep: number = 0;

  private readonly minAsinhVal: number = 0;

  private readonly maxAsinhVal: number = 0;

  private readonly asinhStep: number = 0;

  constructor(plotAxisRange: number[], axisOrigDataRange?: number[]) {
    this.tickDataList = [];

    const [minVal, maxVal] = plotAxisRange;
    this.minLinVal = minVal;
    this.maxLinVal = maxVal;
    this.linStep = Math.abs(this.maxLinVal - this.minLinVal) / INTERVAL_COUNT;

    if (!axisOrigDataRange || axisOrigDataRange.length < 2) {
      this.scaleLinearArcSinh = null;
    } else {
      const [minOrigVal, maxOrigVal] = axisOrigDataRange;
      this.scaleLinearArcSinh = getScaleLinearArcSinh(minOrigVal, maxOrigVal);
    }

    this.minAsinhVal = this.getAsinhVal(this.minLinVal);
    this.maxAsinhVal = this.getAsinhVal(this.maxLinVal);
    this.asinhStep = Math.abs(this.maxAsinhVal - this.minAsinhVal) / INTERVAL_COUNT;
  }

  private getAsinhVal(linVal: number) {
    if (!this.scaleLinearArcSinh) {
      return linVal;
    }
    return this.scaleLinearArcSinh(Math.asinh(linVal)) as number;
  }

  private getLinVal(asinhVal: number) {
    if (!this.scaleLinearArcSinh) {
      return asinhVal;
    }
    return Math.sinh(this.scaleLinearArcSinh.invert(asinhVal));
  }

  private isAllowedToAdd(asinhVal: number) {
    const isInInterval = asinhVal >= this.minAsinhVal && asinhVal <= this.maxAsinhVal;
    if (isInInterval) {
      const hasNearAsinhVal = this.tickDataList.find((data) => Math.abs(asinhVal - data.asinhVal) < this.asinhStep / 5);
      return !hasNearAsinhVal;
    }
    return false;
  }

  private addZeroTickData() {
    this.tickDataList.push({
      linVal: 0,
      asinhVal: this.getAsinhVal(0),
      text: '0',
    });
  }

  private addPower10TickData(power: number, isNegative: boolean) {
    const linVal = isNegative ? -(10 ** power) : 10 ** power;
    const asinhVal = this.getAsinhVal(linVal);
    const negativePrefix = isNegative ? '-' : '';
    const powerNegativePrefix = power < 0 ? '⁻' : '';
    const text =
      power === 0 ? `${negativePrefix}1` : `${negativePrefix}10${powerNegativePrefix}${getSupNumberText(power)}`;
    if (this.isAllowedToAdd(asinhVal)) {
      this.tickDataList.push({
        linVal,
        asinhVal,
        text,
      });
    }
  }

  private addSubTickData(subTickVal: number, power: number) {
    if (subTickVal === 0 || subTickVal === 10) {
      return;
    }
    const correctedLinVal = subTickVal * 10 ** power;
    const correctedAsinhVal = this.getAsinhVal(correctedLinVal);
    if (this.isAllowedToAdd(correctedAsinhVal)) {
      const isNegative = subTickVal < 0;
      const negativePrefix = isNegative ? '⁻' : '';
      this.tickDataList.push({
        linVal: correctedLinVal,
        asinhVal: correctedAsinhVal,
        text: `${negativePrefix}${getSupNumberText(subTickVal)}`,
      });
    }
  }

  private shouldAddStandardTicks() {
    const hasNoBigTensDifference = (
      minVal: number,
      maxVal: number,
      minValNearestIntPower: number,
      maxValNearestIntPower: number
    ) => {
      const correctedMinValPower = minValNearestIntPower < 1 ? 1 : minValNearestIntPower;
      const correctedMaxValPower = maxValNearestIntPower < 1 ? 1 : maxValNearestIntPower;
      return correctedMinValPower === correctedMaxValPower && (minVal >= 0 || maxVal <= 0);
    };

    const minValPower = getNearestIntPower10(this.minLinVal);
    const maxValPower = getNearestIntPower10(this.maxLinVal);
    if (hasNoBigTensDifference(this.minLinVal, this.maxLinVal, minValPower, maxValPower)) {
      return true;
    }

    const correctedMin = this.minLinVal + this.linStep;
    if (correctedMin <= 0 && this.maxLinVal >= 0) {
      return false;
    }
    if (hasNoBigTensDifference(correctedMin, this.maxLinVal, getNearestIntPower10(correctedMin), maxValPower)) {
      return true;
    }

    const correctedMax = this.maxLinVal - this.linStep;
    if (correctedMax >= 0 && this.minLinVal <= 0) {
      return false;
    }
    if (hasNoBigTensDifference(this.minLinVal, correctedMax, minValPower, getNearestIntPower10(correctedMax))) {
      return true;
    }

    return false;
  }

  private addStandardTicks() {
    const stepPower = getNearestIntPower10(this.linStep);
    const newStep = roundUpWithSuitablePrecision(this.linStep, stepPower);
    const newMin = roundUpWithPrecision(this.minLinVal, newStep);
    for (let linVal = newMin; linVal <= this.maxLinVal; linVal += newStep) {
      const asinhVal = this.getAsinhVal(linVal);
      const hasNoDecimals = linVal.toFixed() === linVal.toString();
      this.tickDataList.push({
        linVal,
        asinhVal,
        text: hasNoDecimals ? linVal.toString() : linVal.toFixed(stepPower >= 0 ? 0 : -stepPower),
      });
    }
  }

  private addPowerTicks() {
    if (this.minAsinhVal <= 0 && this.maxAsinhVal >= 0) {
      this.addZeroTickData();
    }

    for (let asinhVal = this.minAsinhVal; asinhVal <= this.maxAsinhVal; asinhVal += this.asinhStep) {
      const linVal = this.getLinVal(asinhVal);
      const isNegative = linVal < 0;
      const power = getNearestIntPower10(linVal);
      this.addPower10TickData(power, isNegative);
    }

    for (let asinhVal = this.minAsinhVal; asinhVal <= this.maxAsinhVal; asinhVal += this.asinhStep) {
      const linVal = this.getLinVal(asinhVal);
      const power = getNearestIntPower10(linVal);
      this.addSubTickData(Math.floor(linVal / 10 ** power), power);
      this.addSubTickData(Math.ceil(linVal / 10 ** power), power);
    }
  }

  public getPlotLayoutTicksPart() {
    if (this.scaleLinearArcSinh === null) {
      return { tickvals: [], ticktext: [] };
    }

    if (this.shouldAddStandardTicks()) {
      this.addStandardTicks();
    } else {
      this.addPowerTicks();
      if (this.tickDataList.length < 5) {
        this.tickDataList = [];
        this.addStandardTicks();
      }
    }

    sortByKey(this.tickDataList, 'asinhVal');

    return {
      tickvals: this.tickDataList.map((data) => data.asinhVal),
      ticktext: this.tickDataList.map((data) => data.text),
    };
  }
}
