import { FC } from 'react';
import classNames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { appAPI } from '@/store/services/app';

import Select from '@/components/common/Select';
import icons from '@/components/common/icons';

import styles from '../DesignDetails.module.scss';

const cn = classNames.bind(styles);

type TOrganismItemProps = {
  value: string;
  uuid: string;
  onChange: (uuid: string, value: string) => void;
  isDeleteAllowed: boolean;
  onDelete: (uuid: string) => void;
  inputId?: string;
};

const OrganismItem: FC<TOrganismItemProps> = ({ value, uuid, onChange, isDeleteAllowed, onDelete, inputId }) => {
  const { data: genomeOptionList = [] } = appAPI.useFetchGenomeOptionListQuery();

  const handleDeleteClick = () => {
    onDelete(uuid);
  };

  const handleValueChange = (newValue: string) => {
    onChange(uuid, newValue);
  };

  return (
    <div
      className={cn('details__organism', {
        'details__multi-value_item': isDeleteAllowed,
      })}
    >
      <Select
        value={value}
        options={genomeOptionList}
        onChange={handleValueChange}
        theme={themeOptions.light}
        placeholder="Select organism..."
        id={inputId}
        className={cn('details__select')}
        controlClassName={cn('details__select-control')}
        menuClassName={cn('details__select-menu')}
      />
      {isDeleteAllowed && (
        <button
          className={cn('details__delete')}
          type="button"
          onClick={handleDeleteClick}
          aria-label="Delete organism"
        >
          <icons.DeleteIcon className={cn('details__delete-icon')} />
        </button>
      )}
    </div>
  );
};

export default OrganismItem;
