import { useCallback } from 'react';
import { useMatch } from 'react-router-dom';

import useSearchParamsDatasets from '@/hooks/useSearchParamsDatasets';
import useParamsExperimentId from '@/hooks/useParamsExperimentId';
import useParamsProjectId from '@/hooks/useParamsProjectId';
import { b64EncodeUnicode } from '@/helpers';
import { getChannelName } from '@/helpers/channels';

export enum EExperimentTabId {
  summary = 'summary',
  settings = 'settings',
  data = 'data',
  downloads = 'downloads',
  jobs = 'jobs',
  activity = 'activity',
}

const getExperimentLinkSearchParams = (tabId = '') => {
  if (tabId) {
    const params = new URLSearchParams({ tab: tabId });
    params.set('tab', tabId);
    return `?${params.toString()}`;
  }
  return '';
};

const getChartLinkSearchParams = (
  dataset: TDatasetDetails | TDatasetDetailsIdData,
  withAnalytics = false,
  datasets = ''
) => {
  const params = new URLSearchParams({ scanId: dataset.scanId, laneId: dataset.laneId });
  if ('channelId' in dataset) {
    params.set('channelName', getChannelName(dataset.channelId ?? ''));
  }
  if (withAnalytics) {
    params.set('withAnalytics', 'true');
  }
  if ('marker' in dataset && dataset?.marker?.name) {
    // TDatasetDetails
    params.set('markerName', dataset.marker.name);
  }
  if (datasets) {
    params.set('datasets', datasets);
  }
  return `?${params.toString()}`;
};

export function useLinks() {
  const projectId = useParamsProjectId();
  const experimentId = useParamsExperimentId();
  const datasetsStr = useSearchParamsDatasets();

  const matchPattern = projectId
    ? '/project/:projectId/experiment/:experimentId/data/analytics/dataset'
    : '/experiment/:experimentId/data/analytics/dataset';
  const isDatasetPage = !!useMatch(matchPattern);

  const generateExperimentLink = useCallback(
    (tabId = '') => {
      const experimentLink = `/experiment/${experimentId}${getExperimentLinkSearchParams(tabId)}`;
      return projectId ? `/project/${projectId}${experimentLink}` : experimentLink;
    },
    [projectId, experimentId]
  );

  const generateChartLink = useCallback(
    (dataset: TDataset | TDatasetDetailsIdData, withAnalytics = false) => {
      const chartLink = `/experiment/${experimentId}/data/analytics/dataset${getChartLinkSearchParams(
        dataset,
        withAnalytics,
        datasetsStr
      )}`;
      return projectId ? `/project/${projectId}${chartLink}` : chartLink;
    },
    [projectId, experimentId, datasetsStr]
  );

  const generateAnalyticsLink = useCallback(
    (detailsList: TDatasetDetailsIdData[] = []) => {
      if (detailsList.length === 1) {
        return generateChartLink(detailsList[0]);
      }
      const encodedStr = detailsList.length ? b64EncodeUnicode(JSON.stringify(detailsList)) : datasetsStr;
      const analyticsLink = `/experiment/${experimentId}/data/analytics?datasets=${encodedStr}`;
      return projectId ? `/project/${projectId}${analyticsLink}` : analyticsLink;
    },
    [projectId, experimentId, datasetsStr]
  );

  return { isDatasetPage, generateExperimentLink, generateChartLink, generateAnalyticsLink };
}
