import { SVGProps } from 'react';

const ExperimentPipelineStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 9.5h19M9.5 0v19" stroke="currentColor" />
    <circle cx="14.5" cy="4.5" r="2" stroke="currentColor" />
    <circle cx="14.5" cy="14.5" r="2" stroke="currentColor" />
    <circle cx="4.5" cy="4.5" r="2" stroke="currentColor" />
    <circle cx="4.5" cy="14.5" r="2" stroke="currentColor" />
  </svg>
);

export default ExperimentPipelineStatus;
