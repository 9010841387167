import { FC, useCallback, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';

import Popover, { TPopoverOption } from '@/components/common/Popover';
import icons from '@/components/common/icons';
import styles from './Actions.module.scss';

const cn = classnames.bind(styles);

const Actions: FC = () => {
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const handleSignOutClick = useCallback(() => {
    Auth.signOut();
  }, []);

  const options = useMemo<TPopoverOption[]>(
    () => [
      {
        id: 'sign-out',
        title: 'Sign out',
        onClick: handleSignOutClick,
      },
    ],
    [handleSignOutClick]
  );

  const handlePopoverClick = useCallback(() => {
    setIsPopoverOpened((prev) => !prev);
  }, []);

  return (
    <Popover onClick={handlePopoverClick} setIsOpen={setIsPopoverOpened} isOpen={isPopoverOpened} options={options}>
      <Button color="white" className={cn('actions__button')} isActive={isPopoverOpened}>
        <icons.PersonIcon />
      </Button>
    </Popover>
  );
};

export default Actions;
