import {
  number,
  object,
  string,
  ZodType,
  lazy,
  array,
  discriminatedUnion,
  literal,
  union,
  boolean,
  null as null_,
} from 'zod';

export const populationRangeSchema = object({
  xMin: number(),
  xMax: number(),
  yMin: number(),
  yMax: number(),
});

export const gatePolygonsSchema = object({
  x: number(),
  y: number(),
});

export const circleGateModelSchema = object({
  x: number(),
  y: number(),
  rx: number(),
  ry: number(),
});

export const rangeGateModelSchema = object({
  x1: number(),
  x2: number(),
  y: number().optional(),
});

export const gatePointsSchema = object({
  points: gatePolygonsSchema.array(),
});

export const polarSectorSchema = object({
  sectorAngle: union([literal(270), literal(180), literal(90), literal(0)]),
  offsetAngle: union([literal(270), literal(180), literal(90), literal(0)]),
  x: number(),
  y: number(),
});

export const polarSectorFromServerSchema = object({
  sectorAngle: number(),
  offsetAngle: number(),
});

export const polarGateModelSchema = object({
  sectors: polarSectorFromServerSchema.array(),
  x: number(),
  y: number(),
});

export const gateRangePropertiesSchema = object({
  x: number().array().length(2),
  y: number().array().length(2),
});

// type of gate according to the tool used to create it
export const gateTypeByToolSchema = literal('circle')
  .or(literal('polygon'))
  .or(literal('rectangle'))
  .or(literal('polar'))
  .or(literal('split-gate'))
  .or(literal('range'));

// type of gate according to the API
export const gateTypeSchema = literal('circle')
  .or(literal('polygon'))
  .or(literal('polar'))
  .or(literal('polar-sector'))
  .or(literal('range'));

export const gatePropertiesSchema = object({
  range: gateRangePropertiesSchema,
  type: gateTypeByToolSchema,
  color: string(),
  isVisible: boolean(),
  processType: string(),
  entityLevel: literal('object').or(literal('cage')),
  marker: string(),
  beadId: string(),
  classId: string(),
  plotType: union([
    literal('dot'),
    literal('dotDensity'),
    literal('histogram2d'),
    literal('histogram2dcontour'),
    literal('histogram'),
    literal('lineHistogram'),
    literal('heatmap'),
    literal('violin'),
    literal('knee'),
  ]),
}).partial();

export const rangeGateShapeSchema = object({ type: literal('range'), model: rangeGateModelSchema });

export const circleGateShapeSchema = object({ type: literal('circle'), model: circleGateModelSchema });
// polygon schema used for gate with property.type === 'rectangle' and 'polygon';
export const polygonGateShapeSchema = object({ type: literal('polygon'), model: gatePointsSchema });

// polar schema used for gate with property.type === 'polar' and 'split-gate';
export const polarGateShapeSchema = object({ type: literal('polar'), model: polarGateModelSchema });
export const polarSectorGateShapeSchema = object({ type: literal('polar-sector'), model: polarSectorSchema });

export const polarSectorGateShapeFromServerSchema = object({
  type: literal('polar-sector'),
  model: polarSectorFromServerSchema,
});

export const shapeSchema = discriminatedUnion('type', [
  circleGateShapeSchema,
  polygonGateShapeSchema,
  polarGateShapeSchema,
  polarSectorGateShapeSchema,
  rangeGateShapeSchema,
]);

export const shapeSchemaFromServer = discriminatedUnion('type', [
  circleGateShapeSchema,
  polygonGateShapeSchema,
  polarGateShapeSchema,
  polarSectorGateShapeFromServerSchema,
  rangeGateShapeSchema,
]);

export const gatesShapeOverridesSchema = object({
  scanId: string(),
  laneId: string(),
  shape: shapeSchema.or(shapeSchemaFromServer),
});

export const baseGateFromServerSchema = object({
  id: string(),
  name: string().nullable(),
  scanId: string(),
  laneId: string(),
  xDimension: string(),
  yDimension: string(),
  properties: string().optional().or(gatePropertiesSchema),
  shape: shapeSchemaFromServer,
  overrides: gatesShapeOverridesSchema.array().or(null_()).optional(),
});

export const gateFromServerSchema: ZodType<TGateFromServer> = baseGateFromServerSchema.extend({
  gateNodes: lazy(() => gateFromServerSchema.array()),
});

export const basePolarAreaFromServerSchema = object({
  id: string(),
  name: string(),
  shape: polarSectorGateShapeFromServerSchema,
  gateNodes: gateFromServerSchema.array(),
});

export const gateListFromServerSchema = array(gateFromServerSchema);

export const baseGateSchema = baseGateFromServerSchema.extend({
  name: string(),
  parentId: string(),
  level: number(),
  shape: shapeSchema,
  properties: gatePropertiesSchema,
  overrides: gatesShapeOverridesSchema.array().or(null_()).optional(),
});

export const gateSchema: ZodType<TGate> = baseGateSchema.extend({
  gateNodes: lazy(() => gateSchema.array()),
});

export const gateListSchema = array(gateSchema);

export const gateStatistics = object({
  cagesCount: number(),
  cagesPercent: string(),
  cagesIds: string().array(),
});
