import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useMemo,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors, TMatrixSettingItem } from '@/store/slices/experimentRunDesign';
import {
  DEFAULT_EXPOSURE,
  DEFAULT_INTENSITY,
  DEFAULT_ZOFFSET,
} from '@/pages/experiment-run-design/OpticalSettings/constants';

type TMatrixOpticalSettingsContext = {
  lanesMatrixSettings: TMatrixSettingItem[];
  setLanesMatrixSettings: Dispatch<SetStateAction<TMatrixSettingItem[]>>;
  resetMatrixSettingsToDefault: () => void;
};

const MatrixOpticalSettingsContext = createContext<TMatrixOpticalSettingsContext>({} as TMatrixOpticalSettingsContext);

export const useMatrixOpticalSettingsContext = (): TMatrixOpticalSettingsContext =>
  useContext(MatrixOpticalSettingsContext);

type TMatrixOpticalSettingsContextProviderProps = {
  children: ReactNode;
};

export const MatrixOpticalSettingsContextProvider: FC<TMatrixOpticalSettingsContextProviderProps> = ({ children }) => {
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);

  const [lanesMatrixSettings, setLanesMatrixSettings] = useState<TMatrixSettingItem[]>([]);

  const resetMatrixSettingsToDefault = useCallback(() => {
    setLanesMatrixSettings(
      lanesMatrixSettings.map((setting) => ({
        ...setting,
        intensity: DEFAULT_INTENSITY,
        exposure: DEFAULT_EXPOSURE,
        zOffset: DEFAULT_ZOFFSET,
      }))
    );
  }, [lanesMatrixSettings]);

  useEffect(() => {
    setLanesMatrixSettings(opticalMatrixSettings.filter((el) => el.scanConfig));
  }, [opticalMatrixSettings]);

  const MatrixOpticalSettingsContextData = useMemo(
    () => ({
      lanesMatrixSettings,
      setLanesMatrixSettings,
      resetMatrixSettingsToDefault,
    }),
    [lanesMatrixSettings, setLanesMatrixSettings, resetMatrixSettingsToDefault]
  );

  return (
    <MatrixOpticalSettingsContext.Provider value={MatrixOpticalSettingsContextData}>
      {children}
    </MatrixOpticalSettingsContext.Provider>
  );
};
