import { FC, memo, useEffect } from 'react';
import classnames from 'classnames/bind';

import { authAPI } from '@/store/services/auth';

import { capitalizeFirstLetter } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';

import styles from './AdminModals.module.scss';
import { TAdminModalProps } from './types';
import NoDataFound from '../../common/NoDataFound';
import LineLoader from '../../common/LineLoader';

type TUserPermissionsModal = TAdminModalProps & {
  username: string;
};

const cn = classnames.bind(styles);

const UserPermissionsModal: FC<TUserPermissionsModal> = ({ username, closeModal, isModalOpen }) => {
  const { data, error, isError, isLoading } = authAPI.useFetchUserRolesQuery({ username });

  useEffect(() => {
    if (!isError) return;

    showErrorToast(capitalizeFirstLetter(getErrorMessage(error)));
  }, [error]);

  if (!username) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick className={cn('modal')}>
      <Modal.Header onRequestClose={closeModal} className={cn('modal__header', 'modal__header_no-border')}>
        <h2>{`User's Permissions`}</h2>
      </Modal.Header>
      <LineLoader isLoading={isLoading} />
      <Modal.Content className={cn('modal__content_no-gap')}>
        {isError && <NoDataFound alignment="center" textData={capitalizeFirstLetter(getErrorMessage(error))} />}
        {!isLoading && !isError && (
          <div className={cn('table-container')}>
            <table className={cn('table')}>
              <thead className={cn('table__head')}>
                <tr className={cn('table__head-row')}>
                  <th className={cn('table__head-item')}>Group</th>
                  <th className={cn('table__head-item')}>Access level</th>
                  <th className={cn('table__head-item')}>Id</th>
                </tr>
              </thead>
              <tbody>
                {data?.permissions?.map((permissionData) => {
                  const permissionDataArr = permissionData.split(':');
                  const [type, accessLevel, _, customData = ''] = permissionDataArr;

                  return (
                    <tr key={permissionData} className={cn('table__row')}>
                      <td className={cn('table__column')}>{type}</td>
                      <td className={cn('table__column')}>{accessLevel}</td>
                      <td className={cn('table__column')}>{customData}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="add-project-to-team__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(UserPermissionsModal);
