import { FC, MutableRefObject, memo, useRef } from 'react';
import icons from '@/components/common/icons';
import { getErrorMessage } from '@/helpers/errors';
import useNavigatorCurrentDatasetName from '@/hooks/navigator/useNavigatorCurrentDatasetName';
import Button from '@/components/common/Button';

type TSnapshotProps = {
  className?: string;
  canvasRef: MutableRefObject<Nullable<HTMLCanvasElement>>;
  rerenderWebglCanvas: () => void;
  coordinatesForImageName: string;
};

type TSnapshotHandleDownload = (blob: Blob, imageData: { name: string; extension: string }) => Promise<void>;

const Snapshot: FC<TSnapshotProps> = ({ canvasRef, className, rerenderWebglCanvas, coordinatesForImageName }) => {
  const imgBlobRef = useRef<Nullable<Blob>>(null);
  const datasetName = useNavigatorCurrentDatasetName('');

  const handleDownloadScreenshot = async () => {
    try {
      if (!imgBlobRef.current) {
        return;
      }

      const name = `${datasetName}_${coordinatesForImageName}`;
      const extension = imgBlobRef.current.type.split('/')[1];

      await handleSnapshotBrowser(imgBlobRef.current, { name, extension });
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  };

  const resize = (canvas: HTMLCanvasElement) => {
    const displayWidth = canvas.clientWidth;
    const displayHeight = canvas.clientHeight;

    if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
      canvas.width = displayWidth;
      canvas.height = displayHeight;
    }
  };

  const handleSnapshotClick = async () => {
    if (!canvasRef.current) {
      return;
    }

    const gl = canvasRef.current.getContext('webgl');
    if (!gl) {
      return;
    }

    resize(gl.canvas as HTMLCanvasElement);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    rerenderWebglCanvas();

    canvasRef.current.toBlob((blob) => {
      imgBlobRef.current = blob;
      handleDownloadScreenshot();
    });
  };

  const handleSnapshotBrowser: TSnapshotHandleDownload = async (blob, { extension, name }) => {
    try {
      // @ts-ignore
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: `${name}.${extension}`,
        types: [
          {
            description: 'Image',
            accept: {
              'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.bmp'],
            },
          },
        ],
      });

      const writableFileStream = await fileHandle.createWritable();
      await writableFileStream.write(blob);
      await writableFileStream.close();
    } catch (error) {
      const userAbortText = 'The user aborted a request.';
      if (getErrorMessage(error) !== userAbortText) {
        try {
          if (!imgBlobRef.current) {
            return;
          }

          const url = URL.createObjectURL(imgBlobRef.current) ?? '';

          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', `${name}.${extension}`);

          link.click();
          link.remove();
        } catch (e) {
          throw new Error(getErrorMessage(e));
        }
      }
    }
  };

  return (
    <Button
      tooltip="Snapshot"
      id="snapshot"
      color="gray"
      onClick={handleSnapshotClick}
      className={className}
      disabled={false}
    >
      <icons.SnapshotIcon />
    </Button>
  );
};

export default memo(Snapshot);
