import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useOpenModal } from '@/hooks/useOpenModal';
import { RunDesignContext } from '@/pages/experiment-run-design/context';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';

import SettingsCard from './components/SettingsCard';
import CustomPageHeader from './components/CustomPageHeader';
import CageSettingsInfoModal from './components/CageSettingsInfoModal';

const CagingSettings: FC = () => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig, clearCustomFooterConfig, addCustomHeaderContent, clearCustomHeaderContent } =
    useContext(RunDesignContext);

  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);

  const cardRef = useRef(null);
  const [isEditGlobalSettingsMode, setIsEditGlobalSettingsMode] = useState(false);

  const { isOpen: isModalOpen, setIsOpen: setIsModalOpen, onClose: handleModalClose } = useOpenModal();

  const handleModalOpen = useCallback((isEditMode = false) => {
    setIsModalOpen(true);
    setIsEditGlobalSettingsMode(isEditMode);
  }, []);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: cardRef,
      isEditMode: runDesignCardIndexEdit >= 0,
      cancel: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [overrideCustomFooterConfig, clearCustomFooterConfig, runDesignCardIndexEdit]);

  useEffect(() => {
    if (!addCustomHeaderContent || !clearCustomHeaderContent) {
      return;
    }

    if (runDesignCardIndexEdit >= 0) {
      addCustomHeaderContent(<CustomPageHeader onClick={() => handleModalOpen(true)} />);
    } else {
      clearCustomHeaderContent();
    }

    return () => {
      clearCustomHeaderContent();
    };
  }, [addCustomHeaderContent, clearCustomHeaderContent, runDesignCardIndexEdit]);

  return (
    <>
      <CageSettingsInfoModal onClose={handleModalClose} isOpen={isModalOpen} isEditMode={isEditGlobalSettingsMode} />
      <RunDesignBlockWithLanes
        title={
          <span>
            Review CellCage™ Enclosure <br /> (CCE) settings
          </span>
        }
      >
        <SettingsCard innerRef={cardRef} openSettingsInfoModal={handleModalOpen} />
      </RunDesignBlockWithLanes>
    </>
  );
};

export default CagingSettings;
