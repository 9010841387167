import { FC, memo, MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { Viewer as OsdViewer } from 'openseadragon';

import {
  getNavigatorZoomFromViewportZoom,
  getPointFromPosition,
  getPositionFromPoint,
  getViewportZoomFromNavigatorZoom,
} from '@/helpers/osd';
import { LAST_EDIT, MAX_NAVIGATOR_ZOOM, syncResize } from '@/helpers';
import { CSSProperty } from '@/helpers/interfaces';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useParamsExperimentId } from '@/hooks';
import { changeTextures } from '@/hooks/useWebgl/helpers/changeTextures';
import { render } from '@/hooks/useWebgl/helpers/render';

import { experimentSelectors } from '@/store/slices/experiment';
import { navigatorActions, navigatorSelectors, TRenderOption } from '@/store/slices/navigator';
import { viewerSelectors } from '@/store/slices/viewer';

import LoaderProgress from '@/components/common/LoaderProgress';

import { initShaders } from './webgl/initShaders';
import { initOpenSeaDragonWithChannels } from './OpenSeadragon';

import ResizeControl from './Addons/ResizeControl';
import Coords from './Addons/Coords';
import ArrowControl from './Addons/ArrowControl';
import Maps from './Addons/Maps';
import Canvas from './Canvas';
import ObjectsOnCanvas from './ObjectsOnCanvas';

import styles from './Viewer.module.scss';

const cn = classnames.bind(styles);

type TViewerProps = {
  scanId: string;
  laneId: string;
  screenState: {
    changeFullScreen: () => void;
    changeExpandMode: () => void;
    changeScreenRatio: () => void;
    screenStateModifiers: Record<string, boolean>;
  };
  isInTransitionRef: MutableRefObject<boolean>;
  className?: string;
  openCageInspector: (props: TCageInspectorModalProps) => void;
};

const Viewer: FC<TViewerProps> = ({
  scanId = '',
  laneId = '',
  screenState,
  isInTransitionRef,
  className,
  openCageInspector,
}) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const displayCageLocations = useSelector(viewerSelectors.selectDisplayCageLocationsLayer);
  const displaySegmentedObjects = useSelector(viewerSelectors.selectDisplaySegmentedObjectsLayer);
  const displayDebugLayer = useSelector(viewerSelectors.selectDisplayDebugLayer);
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const experimentRenderOptions = useSelector(navigatorSelectors.selectRenderOptions);
  const channelNameList = useSelector(navigatorSelectors.selectChannelList(currentScanId, currentLaneId));

  const osdRef = useRef<OsdViewer[]>();
  const viewerWrapRef = useRef<Nullable<HTMLDivElement>>(null);
  const resultCanvasRef = useRef<Nullable<HTMLCanvasElement>>(null);
  const texturesRef = useRef<Nullable<WebGLTexture>[]>([]);
  const lutTextureListRef = useRef<Record<string, Nullable<WebGLTexture>>>({});
  const uniformsRef = useRef<Record<string, Nullable<WebGLUniformLocation>>>();
  const viewerRootRef = useRef<Nullable<HTMLDivElement>>(null);
  const viewerRootDataRef = useRef<Nullable<DOMRect>>(null);
  const isComponentMounted = useRef(false);
  const allChannelsRef = useRef<Record<string, TRenderOption>>({});

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentScan = useSelector(experimentSelectors.selectScan(currentScanId));

  const position = useSelector(navigatorSelectors.selectPosition(currentLaneId !== '' ? currentLaneId : laneId));
  const zoom = useSelector(navigatorSelectors.selectZoom(currentLaneId !== '' ? currentLaneId : laneId));
  const currentLane = useSelector(experimentSelectors.selectLane(currentScanId, currentLaneId));

  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    appDispatch(
      navigatorActions.setCurrentScanId({
        experimentId,
        scanId,
      })
    );
  }, [scanId]);

  useEffect(() => {
    appDispatch(
      navigatorActions.setCurrentLaneId({
        experimentId,
        laneId,
      })
    );
  }, [laneId]);

  useEffect(() => {
    const osd = osdRef.current;
    if (osd) {
      osd.at(-1)?.setDebugMode(displayDebugLayer);
    }
  }, [displayDebugLayer, osdRef.current]);

  useEffect(() => {
    allChannelsRef.current = channelNameList.reduce((acc, channelId) => {
      acc[channelId] = experimentRenderOptions[channelId];

      return acc;
    }, {} as Record<string, TRenderOption>);
  }, [experimentRenderOptions, channelNameList]);

  useEffect(() => {
    if (!viewerWrapRef.current || !scanList) {
      return;
    }

    if (!currentLaneId || !currentScanId) {
      return;
    }

    if (!currentLane) {
      return;
    }

    if (osdRef.current) {
      osdRef.current.forEach((osdViewer) => {
        osdViewer.destroy();
      });
    }

    osdRef.current = initOpenSeaDragonWithChannels(
      viewerWrapRef.current,
      currentLane,
      [channelNameList[0], ...channelNameList], // duplicate for controller layer
      displayDebugLayer
    );

    const osd = osdRef.current;
    const [controllerOsd, ...guidedOsd] = osd;
    const bounds = controllerOsd.viewport.getBounds();
    appDispatch(
      navigatorActions.setBoundary({
        experimentId,
        laneId: currentLaneId,
        boundary: {
          top: bounds.y,
          left: bounds.x,
          right: bounds.x + bounds.width,
          bottom: bounds.y + bounds.height,
        },
      })
    );

    controllerOsd.addHandler('pan', (ev) => {
      if (!isComponentMounted.current || isInTransitionRef.current) {
        return;
      }

      requestAnimationFrame(() => {
        guidedOsd.forEach((osdInstance) => {
          osdInstance.viewport.panTo(ev.center, ev.immediately);
        });

        const { x, y } = getPositionFromPoint(controllerOsd.viewport, ev.center);

        appDispatch(
          navigatorActions.setPosition({
            experimentId,
            laneId: currentLaneId,
            options: {
              x, // : Number.isNaN(x) ? x : 0,
              y, // : Number.isNaN(y) ? y : 0,
              lastEdit: LAST_EDIT.VIEWER,
            },
          })
        );
      });
    });
    controllerOsd.addHandler('zoom', (ev) => {
      if (!isComponentMounted.current || isInTransitionRef.current) {
        return;
      }

      requestAnimationFrame(() => {
        const newBounds = ev.eventSource?.viewport?.getBounds();
        const center = osd[0].viewport.getCenter();
        guidedOsd.forEach((osdInstance) => {
          osdInstance.viewport.zoomTo(ev.zoom, undefined, ev.immediately);
          osdInstance.viewport.panTo(center, ev.immediately);
        });

        appDispatch(
          navigatorActions.setLanesOptions({
            experimentId,
            laneId: currentLaneId,
            options: {
              position: {
                ...getPositionFromPoint(controllerOsd.viewport, center),
                lastEdit: LAST_EDIT.VIEWER,
              },
              zoom: {
                zoom: getNavigatorZoomFromViewportZoom(controllerOsd.viewport, ev.zoom),
                lastEdit: LAST_EDIT.VIEWER,
              },
              boundary: {
                top: newBounds.y,
                left: newBounds.x,
                right: newBounds.x + newBounds.width,
                bottom: newBounds.y + newBounds.height,
              },
            },
          })
        );
      });
    });

    controllerOsd.addHandler('open', () => {
      const { viewport } = controllerOsd;
      // An empty lastEdit means that no values were saved to store, therefore openseadragon should set default values.
      if (zoom.lastEdit) {
        const viewportZoom = getViewportZoomFromNavigatorZoom(viewport, zoom.zoom);
        viewport.zoomTo(viewportZoom);
      }
      if (position.lastEdit) {
        const viewportPosition = getPointFromPosition(viewport, position);
        viewport.panTo(viewportPosition);
      }
    });
  }, [scanList, channelNameList, currentScanId, currentLaneId]);

  const getDataAndRender = () => {
    const webglCanvas = resultCanvasRef.current;
    if (!webglCanvas) {
      return;
    }

    const gl = webglCanvas.getContext('webgl');
    if (!gl || !uniformsRef.current) {
      return;
    }

    if (!osdRef.current || Object.keys(allChannelsRef.current).length === 0) {
      return;
    }

    const channelsSettings: TRenderOption[] = Object.values(allChannelsRef.current);
    const currentRenderSettings = {
      min: channelsSettings.map(({ range: [min, _] }) => min),
      max: channelsSettings.map(({ range: [_, max] }) => max),
      color: channelsSettings.map(({ color }) => color),
      isActive: channelsSettings.map(({ isActive }) => Number(isActive)),
      isUseLut: channelsSettings.map(({ isUseLut }) => Number(isUseLut)),
      lutTypeList: channelsSettings
        .map(({ lutType }) => lutType)
        .concat(Array.from({ length: 8 - channelsSettings.length }, () => '')),
    };

    const canvasToRender = osdRef.current
      ?.slice(1)
      .map((osdInstance) => osdInstance.drawer.canvas as HTMLCanvasElement);

    texturesRef.current = changeTextures(gl, texturesRef.current, canvasToRender);

    render(gl, uniformsRef.current, currentRenderSettings, lutTextureListRef.current);
  };

  useEffect(() => {
    let requestAnimationFrameId = 0;

    const init = async () => {
      const webglCanvas = resultCanvasRef.current;

      if (!webglCanvas) {
        return;
      }
      const gl = webglCanvas.getContext('webgl');
      const size = webglCanvas.getBoundingClientRect();
      webglCanvas.width = size.width;
      webglCanvas.height = size.height;

      const shaderArtifacts = await initShaders(gl);
      if (!shaderArtifacts || !shaderArtifacts.lutTextureList) {
        return;
      }

      uniformsRef.current = shaderArtifacts.uniforms;
      lutTextureListRef.current = shaderArtifacts.lutTextureList;

      function loop() {
        getDataAndRender();
        requestAnimationFrameId = requestAnimationFrame(loop);
      }

      if (!osdRef.current?.[0]) {
        return;
      }

      if (osdRef.current[0].isOpen()) {
        cancelAnimationFrame(requestAnimationFrameId);
        loop();
      } else {
        osdRef.current[0].addOnceHandler('open', () => {
          if (!osdRef.current) {
            return;
          }
          cancelAnimationFrame(requestAnimationFrameId);
          loop();
        });
      }
    };

    init();

    return () => {
      cancelAnimationFrame(requestAnimationFrameId);
    };
  }, [resultCanvasRef.current, currentScanId, currentLaneId]);

  // actualisation of zoom in viewer
  useEffect(() => {
    if (zoom.lastEdit === LAST_EDIT.VIEWER) {
      return;
    }
    if (!osdRef.current) {
      return;
    }
    const { viewport } = osdRef.current[0];
    const viewportZoom = getViewportZoomFromNavigatorZoom(viewport, zoom.zoom);
    viewport.zoomTo(viewportZoom);
  }, [zoom]);

  // actualisation of position in viewer
  useEffect(() => {
    if (position.lastEdit === LAST_EDIT.VIEWER) {
      return;
    }
    if (!osdRef.current) {
      return;
    }
    const { viewport } = osdRef.current[0];
    viewport.panTo(getPointFromPosition(viewport, position));
  }, [position]);

  const onArrowControlClickFactory = useCallback(
    (direction: 'left' | 'right' | 'top' | 'bottom') => () => {
      let xMultiplier = 0;
      let yMultiplier = 0;
      const offset = 200 * Math.floor(MAX_NAVIGATOR_ZOOM - zoom.zoom) + 100;

      const directionHandlerList = {
        left: () => {
          xMultiplier = -1;
        },
        right: () => {
          xMultiplier = 1;
        },
        top: () => {
          yMultiplier = -1;
        },
        bottom: () => {
          yMultiplier = 1;
        },
      };

      directionHandlerList[direction]();

      appDispatch(
        navigatorActions.setPosition({
          experimentId,
          laneId: currentLaneId,
          options: {
            x: position.x + xMultiplier * offset,
            y: position.y + yMultiplier * offset,
            lastEdit: LAST_EDIT.ARROW_CONTROL,
          },
        })
      );
    },
    [experimentId, currentLaneId, position, zoom]
  );

  useLayoutEffect(() => {
    function onResize() {
      if (!viewerRootRef.current) {
        return;
      }

      viewerRootDataRef.current = viewerRootRef.current?.getBoundingClientRect();
    }

    onResize();
    syncResize.subscribe(onResize);

    return () => {
      syncResize.unsubscribe(onResize);
    };
  }, [viewerRootRef.current]);

  return (
    <div
      className={cn(
        'viewer',
        {
          'viewer__with-cage-locations': displayCageLocations,
          'viewer__with-segmented-objects': displaySegmentedObjects,
          viewer__loaded: true,
          viewer_square: screenState.screenStateModifiers.square,
        },
        className
      )}
      ref={viewerRootRef}
      style={
        {
          '--viewer-height': Math.min(viewerRootDataRef.current?.height ?? 0, viewerRootDataRef.current?.width ?? 0),
          '--viewer-bonus-height': screenState.screenStateModifiers.fullscreen ? 85 : 0,
        } as CSSProperty
      }
    >
      <Canvas canvasRef={resultCanvasRef} />
      <div className={cn('viewer__osd-wrap')} ref={viewerWrapRef} />
      {currentScan && currentLane && (
        <ObjectsOnCanvas osdRef={osdRef} currentLane={currentLane} openCageInspector={openCageInspector} />
      )}
      <div className={cn('viewer__loader')}>
        <LoaderProgress theme="dark" />
      </div>
      <div className={cn('viewer__addon', 'viewer__addon_left', 'viewer__addon_top')}>
        <Coords laneId={currentLaneId ?? laneId} />
      </div>
      <div className={cn('viewer__addon', 'viewer__addon_left', 'viewer__addon_bottom')}>
        {currentLane && <Maps laneLetterName={currentLane.letterName} />}
      </div>
      <div className={cn('viewer__addon', 'viewer__addon_right', 'viewer__addon_top')}>
        <ResizeControl
          canvasRef={resultCanvasRef}
          screenState={{
            ...screenState,
            rerenderWebglCanvas: getDataAndRender,
            coordinatesForImageName: `${Math.floor(position.x)}_${Math.floor(position.y)}_${Math.floor(zoom.zoom)}`,
          }}
        />
      </div>
      <div className={cn('viewer__addon', 'viewer__addon_right', 'viewer__addon_bottom')}>
        <ArrowControl onClickFactory={onArrowControlClickFactory} />
      </div>
    </div>
  );
};

export default memo(Viewer);
