import { FC, MouseEventHandler, ReactNode, useId, useMemo } from 'react';
import { PlacesType } from 'react-tooltip';
import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import { TButtonColor } from '@/helpers/interfaces';
import styles from './Button.module.scss';

type TButton = {
  type?: 'button' | 'submit';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  loadingClassName?: string;
  color?: TButtonColor;
  isOutlined?: boolean;
  isFitContent?: boolean;
  isEmptyStyles?: boolean;
  isSwitch?: boolean;
  isBgLight?: boolean;
  isActive?: boolean;
  isActiveSwitch?: boolean;
  tabIndex?: number;
  isLoading?: boolean;
  id?: string;
  tooltip?: string;
  tooltipPlace?: PlacesType;
};

const cn = classnames.bind(styles);

const Button: FC<TButton> = ({
  type = 'button',
  onClick,
  onMouseUp,
  onMouseDown,
  children,
  disabled = false,
  className = '',
  loadingClassName = '',
  color = 'dark',
  isOutlined = false,
  isFitContent = false,
  isEmptyStyles = false,
  isBgLight = false,
  isActive = false,
  isSwitch = false,
  isActiveSwitch = false,
  isLoading = false,
  tabIndex = 0,
  id,
  tooltip,
  tooltipPlace,
}) => {
  const currentId = id || useId();
  const buttonId = useMemo(() => `button__${currentId}`, [currentId]);
  const dataTestId = useMemo(() => `button__${currentId}`, [currentId]);

  return (
    <button
      className={cn(
        'button',
        {
          button_empty: isEmptyStyles,
          [color]: true,
          outline: isOutlined,
          fit_content: isFitContent,
          button_active: isActive,
          'bg-light': isBgLight,
          'switch-btn': isSwitch,
          'switch-btn_active': isActiveSwitch,
        },
        className
      )}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      tabIndex={tabIndex}
      id={buttonId}
      data-test-id={dataTestId}
      aria-label={tooltip}
      {...addTooltip(tooltip, tooltipPlace)}
    >
      {isSwitch && (
        <span className={cn('switch-btn__circle', { 'switch-btn__circle_active': isActiveSwitch }, loadingClassName)} />
      )}

      {isLoading ? <span className={cn({ button__loading: isLoading })} /> : children}
    </button>
  );
};

export default Button;
