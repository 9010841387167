import { useMemo } from 'react';

import { appAPI } from '@/store/services/app';

export function useExperimentAssayList(experiment: TExperiment) {
  const { data: assayList = [] } = appAPI.useFetchAssayListQuery();

  const assayListWithActive = useMemo(() => {
    const uniqueAssayIdList = [...new Set(experiment.assays.map((assay: TAssay) => assay.id))];
    return assayList.map((assay) => {
      const assayProperties = assay.properties ?? {};
      return {
        ...assay,
        properties: {
          ...assayProperties,
          active: uniqueAssayIdList.includes(assay.id),
        },
      };
    });
  }, [experiment.assays, assayList]);

  return { assayListWithActive };
}
