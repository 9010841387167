import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(1 1)" stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <circle cx="6.5" cy="6.5" r="6.5" />
      <path strokeLinecap="round" d="m11.5 11.5 3 3" />
    </g>
  </svg>
);

export default SearchIcon;
