import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ArrowControl.module.scss';

const cn = classnames.bind(styles);

type TArrowControlProps = {
  onClickFactory: (_direction: 'left' | 'right' | 'top' | 'bottom') => () => void;
};

const ArrowControl: FC<TArrowControlProps> = ({ onClickFactory }) => {
  const handleClickFactory = (direction: 'left' | 'right' | 'top' | 'bottom') => () => {
    if (!onClickFactory) {
      return;
    }

    onClickFactory(direction)();
  };

  return (
    <div className={cn('arrow-control')}>
      <div className={cn('arrow-control__wrap')}>
        <button className={cn('arrow-control__button')} aria-label="Move to top" onClick={handleClickFactory('top')} />
        <button
          className={cn('arrow-control__button')}
          aria-label="Move to right"
          onClick={handleClickFactory('right')}
        />
        <button
          className={cn('arrow-control__button')}
          aria-label="Move to left"
          onClick={handleClickFactory('left')}
        />
        <button
          className={cn('arrow-control__button')}
          aria-label="Move to bottom"
          onClick={handleClickFactory('bottom')}
        />
      </div>
    </div>
  );
};

export default ArrowControl;
