import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';

import ImageButton from '@/components/common/ImagesScrollArea/components/ImageButton';

import styles from '../../ImagesScrollArea.module.scss';

const cn = classnames.bind(styles);

type TCageListRowProps = {
  rowIndex: number;
  openModalFactory: (sample: TEntity) => () => void;
  currentRenderSettings: TRenderOptions;
  renderEntityImage: (canvas: HTMLCanvasElement, entity: TEntity, settings: TRenderOptions) => Promise<void>;
  isHoverActionEnabled: boolean;
  entitiesToDisplay: Nullable<TEntity[]>;
  cagesInRowCount: number;
};
const CageListRow: FC<TCageListRowProps> = ({
  rowIndex,
  openModalFactory,
  currentRenderSettings,
  renderEntityImage,
  isHoverActionEnabled,
  entitiesToDisplay,
  cagesInRowCount,
}) => {
  const rowCages = useMemo(() => {
    const cageIndex = rowIndex * cagesInRowCount;

    if (!entitiesToDisplay) {
      return [];
    }

    return entitiesToDisplay.slice(cageIndex, cageIndex + cagesInRowCount);
  }, [entitiesToDisplay, rowIndex, cagesInRowCount]);

  return (
    <div className={cn('images-scroll-area__image-row')}>
      {rowCages?.map((sample: TEntity) => {
        if (!sample?.uuid) {
          return null;
        }

        return (
          <ImageButton
            key={sample.uuid}
            cropEntity={sample}
            onClick={openModalFactory(sample)}
            currentRenderSettings={currentRenderSettings}
            renderEntityImage={renderEntityImage}
            isHoverActionEnabled={isHoverActionEnabled}
            className={cn('images-scroll-area__image-item')}
          />
        );
      })}
    </div>
  );
};

export default memo(CageListRow);
