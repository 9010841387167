import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { experimentActions } from '@/store/slices/experiment';

import { TInitialChartDataState } from './types';

const initialState: TInitialChartDataState = {
  chartEntityListData: {},
  currentChartData: null,
  origDataRange: {},
};

const chartDataSlice = createSlice({
  name: 'chartData',
  initialState,
  reducers: {
    resetEntityListData: (state) => {
      state.chartEntityListData = {};
    },
    setLoadingEntityListData: (state, action: PayloadAction<{ datasetPath: string }>) => {
      const { datasetPath } = action.payload;
      if (!state.chartEntityListData[datasetPath]) {
        state.chartEntityListData[datasetPath] = {
          isLoading: true,
          isError: false,
        };
      }
    },
    updateEntityListData: (state, action: PayloadAction<{ datasetPath: string; data?: TEntity[]; error?: string }>) => {
      const { datasetPath, data, error } = action.payload;
      state.chartEntityListData[datasetPath] = {
        isLoading: false,
        isError: !!error,
        data,
        error,
      };
    },
    setCurrentChartData: (state, action: PayloadAction<Nullable<TDatasetDetails>>) => {
      state.currentChartData = action.payload;
    },
    setOrigDataRange: (
      state,
      action: PayloadAction<{ scanId: string; laneId: string; rangeDataList: { axis: string; range: number[] }[] }>
    ) => {
      const { scanId, laneId, rangeDataList } = action.payload;
      rangeDataList.forEach(({ axis, range }) => {
        const key = JSON.stringify({
          scanId,
          laneId,
          axis,
        });
        state.origDataRange[key] = range;
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(experimentActions.setCurrentExperimentId, (state) => {
      state.origDataRange = {};
    });
  },
});

export default chartDataSlice;
