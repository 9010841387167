import { ChangeEventHandler, FC, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames/bind';
import icons from '@/components/common/icons';
import styles from './SearchResults.module.scss';

const cn = classNames.bind(styles);

type TSearchResultsRowProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  isSelected: boolean;
  onInfoClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  isSelectVisible: boolean;
};

const SearchResultsRow: FC<TSearchResultsRowProps> = ({
  onChange,
  isSelected,
  onInfoClick,
  children,
  isSelectVisible,
}) => {
  const handleLabelClick: MouseEventHandler<HTMLLabelElement> = (ev) => {
    if (!isSelectVisible) {
      ev.preventDefault();
    }
  };

  return (
    <label className={cn('search-results__table-row')} onClick={handleLabelClick} role="presentation">
      {isSelectVisible ? (
        <div className={cn('search-results__table-cell', 'search-results__table-cell_select')}>
          <div className={cn('search-results__radio')}>
            <input
              type="radio"
              className={cn('search-results__radio-input')}
              onChange={onChange}
              checked={isSelected}
            />
          </div>
        </div>
      ) : (
        <div />
      )}
      {children}
      <div className={cn('search-results__table-cell')}>
        <button className={cn('search-results__info')} aria-label="info" onClick={onInfoClick}>
          <icons.InfoRectangularMiniIcon />
        </button>
      </div>
    </label>
  );
};

export default SearchResultsRow;
