import { FC, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';
import { Popover as TinyPopover } from 'react-tiny-popover';

import styles from './InfoPopover.module.scss';

const cn = classnames.bind({ ...styles });

type TInfoPopover = {
  title: string;
  range: string;
};

const InfoPopover: FC<TInfoPopover> = ({ title, range }) => {
  const [isInputPopoverOpen, setIsInputPopoverOpen] = useState(false);
  const popoverButtonRef = useRef<HTMLDivElement>(null);
  return (
    <TinyPopover
      isOpen={isInputPopoverOpen}
      clickOutsideCapture
      positions={['bottom']}
      align="start"
      ref={popoverButtonRef}
      padding={5}
      content={
        <div className={cn('popover__content')}>
          <span className={cn('popover__title')}>{title}</span>
          <span>{range}</span>
        </div>
      }
    >
      <button
        onMouseEnter={() => setIsInputPopoverOpen(true)}
        onMouseLeave={() => setIsInputPopoverOpen(false)}
        aria-label="info"
        className={cn('info-btn')}
      >
        <icons.InfoIcon className={cn('info-btn__icon')} />
      </button>
    </TinyPopover>
  );
};

export default InfoPopover;
