import { GroupBase } from 'react-select';
import { MIN_SYMBOLS_TO_SEARCH_COUNT } from './constants';

export function isOptionList(optionList: unknown): optionList is TOption[] {
  return Array.isArray(optionList) && optionList.every(isOption);
}

export const isOption = (option: unknown): option is TOption => {
  if (!option) {
    return false;
  }
  if (typeof option === 'object' && 'label' in option && typeof option.label === 'string') {
    if ('value' in option && ['string', 'number'].includes(typeof option.value)) {
      return true;
    }

    if ('options' in option && isOptionList(option.options)) {
      return true;
    }
  }

  return false;
};

export const isBasicOption = (option: TOption): option is TBasicOption => {
  if (!('value' in option)) {
    return false;
  }
  if (!['string', 'number'].includes(typeof option.value)) {
    return false;
  }
  if ('options' in option) {
    return false;
  }

  return option.value !== undefined;
};

export const isCompoundOption = (option: TOption | GroupBase<TOption>): option is TCompoundOption => {
  if ('value' in option) {
    return false;
  }
  if (!('options' in option)) {
    return false;
  }

  return isOptionList(option.options);
};

export const flatOptionList = (optionList: TOption[]): TBasicOption[] =>
  optionList.reduce((acc, option) => {
    if (isBasicOption(option)) {
      acc.push(option);
    } else {
      acc.push(...flatOptionList(option.options));
    }

    return acc;
  }, [] as TBasicOption[]);

export const getOption = (
  optionList: TOption[],
  value: TBasicOption['value'],
  compareCallback = (valueA: string | number, valueB: string | number) => valueA === valueB
): TOption | undefined => {
  const flattenOptionList = flatOptionList(optionList);

  return flattenOptionList.find((option) => compareCallback(option.value, value));
};

export const getNoOptionsMessage = (obj: { inputValue: string }) => {
  const { inputValue } = obj;
  if (inputValue.length === 0) {
    return 'Start typing';
  }
  if (inputValue.length < MIN_SYMBOLS_TO_SEARCH_COUNT) {
    return 'Continue typing';
  }
  return 'No options';
};
