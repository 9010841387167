import { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { isDefined } from '@/helpers/typeGuards';
import InputWithSuggestions from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/InputWithSuggestions';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import styles from './SearchBlock.module.scss';

const cn = classNames.bind(styles);

type TSearchBlockProps = {
  searchValue: string;
  className?: string;
  setIsSearchFinished: Dispatch<SetStateAction<boolean>>;
  setReagentList: Dispatch<SetStateAction<TReturnTransformedTypes[]>>;
  filterList: Record<string, string>;
  onCancel: () => void;
  runFromOutsideSearchTrigger: boolean;
};

const SearchBlock: FC<TSearchBlockProps> = ({
  className,
  onCancel,
  searchValue: outerSearchValue,
  setIsSearchFinished: setOuterIsSearchFinished,
  filterList,
  setReagentList,
  runFromOutsideSearchTrigger,
}) => {
  const { config } = useReagentModalContext();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [suggestionList, setSuggestionList] = useState<string[]>([]);
  const [isSearchFinished, setIsSearchFinished] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const isSearchCompleted = useMemo(
    () => !!searchValue.length && isSearchFinished && !isSearchLoading,
    [searchValue, isSearchFinished, isSearchLoading]
  );

  // sync when load existing reagent
  useEffect(() => {
    setSearchValue(outerSearchValue);
    if (!outerSearchValue) {
      return;
    }
    setIsSearchFinished(true);
    setIsSearchDisabled(true);
  }, [outerSearchValue]);

  // search from filter apply
  useEffect(() => {
    if (!searchValue) {
      return;
    }

    loadSearchResults(searchValue);
    // searchValue is intentionally not in dependencies to not trigger load every input
  }, [runFromOutsideSearchTrigger]);

  const loadSearchResults = useCallback(
    async (value: string) => {
      setIsSearchLoading(true);
      const newReagentList = await config.search.getData(value, filterList);
      setIsSearchLoading(false);
      setOuterIsSearchFinished(true);
      setIsSearchFinished(true);
      if (newReagentList) {
        setReagentList(newReagentList);
      }
    },
    [config, filterList]
  );

  const handleSearchInputChange = async (value: string) => {
    setSearchValue(value);
    setSuggestionList([]);

    if (outerSearchValue) {
      return;
    }

    if (!value || value.length <= 2) {
      return;
    }
    setIsFetching(true);
    const suggests = await config.search.getSuggestions(value);
    setIsFetching(false);
    if (suggests) {
      setSuggestionList(suggests);
    }
    setOuterIsSearchFinished(false);
    setIsSearchFinished(false);
  };

  const handleSearchButtonClick = () => {
    if (!isDefined(searchValue)) {
      return;
    }

    if (isSearchCompleted) {
      setIsSearchDisabled(false);
      setOuterIsSearchFinished(false);
      setIsSearchFinished(false);
      setSearchValue('');
      onCancel();
      return;
    }

    loadSearchResults(searchValue);
  };

  return (
    <div className={cn('search-block', className)}>
      <InputWithSuggestions
        value={searchValue}
        isFetching={isFetching}
        placeholder={config.search.placeholder}
        onChange={handleSearchInputChange}
        onSubmit={loadSearchResults}
        disabled={isSearchDisabled}
        suggestionData={suggestionList}
      />
      <Button
        className={cn('search-block__search-trigger', { 'search-block__search-trigger_cleanup': isSearchCompleted })}
        onClick={handleSearchButtonClick}
        aria-label="search"
        isLoading={isSearchLoading}
        disabled={isSearchLoading}
      >
        <icons.SearchIcon className={cn('search-block__search-icon')} />
      </Button>
    </div>
  );
};
export default memo(SearchBlock);
