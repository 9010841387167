import { FC, TouchEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import { isEqualCircle, limitMinMax } from '@/helpers';
import Panel from '@/components/common/Panel';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import Portal from '@/components/common/Portal';

import { getErrorMessage } from '@/helpers/errors';
import styles from './GridMinimap.module.scss';
import GridCircles from './GridCircles';

const cn = classnames.bind(styles);

type TGridMinimap = {
  activeCircle: {
    row: number;
    column: number;
  };
  handleClick: (_: { rowIndex: number; columnIndex: number }) => void;
  handleClose: () => void;
  label: string;
  disabled?: boolean;
  columns?: number;
  rows?: number;
  isMini?: boolean;
  startRow?: number;
  startColumn?: number;
};

const magnifierSize = 5;
const circleWidth = 13;
const circleHeight = 16;

const GridMinimap: FC<TGridMinimap> = ({
  activeCircle,
  handleClick,
  handleClose,
  label,
  disabled = false,
  columns = 75,
  rows = 5,
  startRow = 0,
  startColumn = 0,
  isMini = false,
}) => {
  const magnifierRef = useRef<Nullable<HTMLDivElement>>(null);
  const gridCirclesRef = useRef<Nullable<HTMLDivElement>>(null);
  const [hoverCircleData, setHoverCircleData] = useState<{ row: number; column: number }>({ row: 0, column: 0 });
  const [showMoveTo, setShowMoveTo] = useState(false);
  const [showMagnifier, setShowMagnifier] = useState(false);

  useEffect(() => {
    if (!gridCirclesRef.current) {
      return;
    }

    if (showMagnifier) {
      gridCirclesRef.current.style.setProperty('overflow-x', 'hidden');
    } else {
      gridCirclesRef.current.style.setProperty('overflow-x', 'auto');
    }
  }, [showMagnifier]);

  useEffect(() => {
    setHoverCircleData({ row: activeCircle.row, column: activeCircle.column });
  }, [activeCircle.row, activeCircle.column]);

  const handleCloseGrid = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleCircleClick = useCallback(
    (rowIndex: number, columnIndex: number) => {
      if (isEqualCircle(hoverCircleData, activeCircle)) {
        return;
      }
      handleClick({ rowIndex, columnIndex });
    },
    [handleClick, activeCircle.column, activeCircle.row, hoverCircleData.column, hoverCircleData.row]
  );

  const handleMouseEnter = useCallback(
    (rowIndex: number, columnIndex: number) => {
      setShowMoveTo(true);
      setHoverCircleData({ column: columnIndex + startColumn, row: rowIndex + startRow });
    },
    [startRow, startColumn]
  );

  const handleTouch = useCallback(
    (_rowIndex: number, _columnIndex: number, e: TouchEvent<HTMLSpanElement>) => {
      try {
        if (!magnifierRef.current || !gridCirclesRef.current) {
          return;
        }

        const { clientX, clientY } = e.changedTouches[0];

        const { scrollLeft } = gridCirclesRef.current;
        const { x: diffX, left, right, bottom, top, y: diffY } = gridCirclesRef.current.getBoundingClientRect();

        if (clientY < top || clientY > bottom || clientX < left || clientX > right) {
          setHoverCircleData({ column: activeCircle.column, row: activeCircle.row });
          setShowMagnifier(false);
          return;
        }
        setShowMagnifier(true);

        const changedX = limitMinMax({ value: clientX - diffX, minValue: 0, maxValue: right - left });
        const changedY = limitMinMax({ value: clientY - diffY, minValue: 0, maxValue: bottom - top });

        const newColumn = Math.floor((scrollLeft + changedX) / circleWidth);
        const newRow = Math.floor(changedY / circleHeight);

        magnifierRef.current.style.setProperty('--left', `${clientX + 70}px`);
        magnifierRef.current.style.setProperty('--top', `${clientY - 50}px`);

        setHoverCircleData({ column: newColumn + startColumn, row: newRow + startRow });
      } catch (err) {
        console.error(getErrorMessage(err));
      }
    },
    [gridCirclesRef.current, magnifierRef.current, activeCircle.column, activeCircle.row, startColumn, startRow]
  );

  const handleCircleTouchEnd = useCallback(() => {
    setShowMagnifier(false);
    if (isEqualCircle(hoverCircleData, activeCircle)) {
      return;
    }
    handleClick({ columnIndex: hoverCircleData.column - startColumn, rowIndex: hoverCircleData.row - startRow });
  }, [
    handleClick,
    hoverCircleData.row,
    hoverCircleData.column,
    activeCircle.column,
    activeCircle.row,
    startColumn,
    startRow,
  ]);

  const handleLeaveGrid = useCallback(() => {
    setShowMoveTo(false);
    setHoverCircleData({ row: activeCircle.row, column: activeCircle.column });
  }, [activeCircle.row, activeCircle.column]);

  const isEqualCircels = useMemo<boolean>(
    () => activeCircle.row === hoverCircleData.row && activeCircle.column === hoverCircleData.column,
    [activeCircle.row, activeCircle.column, hoverCircleData.row, hoverCircleData.column]
  );

  return (
    <div data-testid="grid-minimap" className={cn('grid-minimap')}>
      <Panel className={cn('grid-minimap__panel')}>
        <Panel.Header
          onClick={handleCloseGrid}
          wrapClassName={cn('grid-minimap__header-wrapper', { 'grid-minimap__mini-header': isMini })}
          className={cn('grid-minimap__header')}
          withDivide={!isMini}
        >
          <div className={cn('grid-minimap__lane-info', { 'grid-minimap__mini-lane-info': isMini })}>
            <span data-testid="grid-minimap__title" className={cn('grid-minimap__lane-title')}>
              Lane {label}
            </span>
            <div className={cn('grid-minimap__from-to-wrapper')}>
              <div
                className={cn('grid-minimap__from-to-block', {
                  'grid-minimap__from-to-block-show': !isMini || (showMoveTo && !isEqualCircels),
                })}
              >
                <span className={cn('grid-minimap__from')}>
                  {activeCircle.row + 1}-{activeCircle.column + 1}
                </span>

                <span
                  className={cn('grid-minimap__to-block', {
                    'grid-minimap__to-block-transparent': isEqualCircels,
                  })}
                >
                  <icons.ArrowWhiteIcon />
                  <span className={cn('grid-minimap__to')}>
                    {hoverCircleData.row + 1}-{hoverCircleData.column + 1}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {!isMini && (
            <Button
              data-testid="grid-minimap__close"
              onClick={handleCloseGrid}
              isEmptyStyles
              isFitContent
              className={cn('grid-minimap__close')}
            >
              <icons.CloseIcon />
            </Button>
          )}
        </Panel.Header>
        <Panel.Content className={cn('grid-minimap__grid-content')}>
          <GridCircles
            withRowNumber
            onCircleClick={handleCircleClick}
            onCircleMouseEnter={handleMouseEnter}
            onLeaveGrid={handleLeaveGrid}
            onCircleTouch={handleTouch}
            onCircleTouchEnd={handleCircleTouchEnd}
            startColumn={startColumn}
            startRow={startRow}
            hoverCircleData={hoverCircleData}
            activeCircleData={activeCircle}
            columns={columns}
            rows={rows}
            disabled={disabled}
            innerRef={gridCirclesRef}
          />
        </Panel.Content>
      </Panel>
      <Portal>
        <GridCircles
          innerRef={magnifierRef}
          startColumn={hoverCircleData.column - 2}
          startRow={hoverCircleData.row - 2}
          startGridColumn={startColumn}
          startGridRow={startRow}
          endGridColumn={startColumn + columns - 1}
          endGridRow={startRow + rows - 1}
          hoverCircleData={hoverCircleData}
          activeCircleData={activeCircle}
          columns={magnifierSize}
          rows={magnifierSize}
          className={cn('grid-minimap__magnifier', { 'grid-minimap__transparent': !showMagnifier })}
        />
      </Portal>
    </div>
  );
};

export default memo(GridMinimap);
