import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import Highlighter from 'react-highlight-words';

import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';
import { MDASH } from '@/helpers';

import Panel from '@/components/common/Panel';
import StatusBadge from '@/components/experiment/StatusesInfo/StatusBadge';
import icons from '@/components/common/icons';
import { getInstrumentCurrentStatusBadgeData } from '@/components/experiment/StatusesInfo/helpers';

import { themeOptions } from '@/types/theme';

import styles from './InstrumentCard.module.scss';
import { getInstrumentMeta } from '../helpers';

const cn = classnames.bind(styles);

export type TInstrumentCardProps = {
  instrument: TInstrumentFromServer;
  searchQuery?: string;
  onClick: (instrumnet: TInstrumentFromServer) => void;
};

const InstrumentCard: FC<TInstrumentCardProps> = ({ instrument, searchQuery = '', onClick }) => {
  const { name, currentStatus } = instrument;
  const onCardClick = () => {
    onClick(instrument);
  };

  const badgeData = useMemo(
    () => (currentStatus ? getInstrumentCurrentStatusBadgeData(currentStatus) : null),
    [currentStatus]
  );

  return (
    <div onClick={onCardClick} role="presentation" className={cn('card', 'in-view')}>
      <Panel theme={themeOptions.light} className={cn('card__panel')}>
        <Panel.Content className={cn('card__group_content', 'render-content-animation')}>
          <div className={cn('card__content')}>
            <div className={cn('card__content-main')}>
              <div className={cn('card__title-wrap')}>
                <div className={cn('card__title')} title={name}>
                  <Highlighter textToHighlight={name ?? ''} searchWords={formatHighlightSearchQuery(searchQuery)} />
                </div>
              </div>
              <div className={cn('card__content-row')}>
                <span>Status</span>
                {badgeData ? <StatusBadge className={cn('card__status')} {...badgeData} /> : <span>{MDASH}</span>}
              </div>
              {currentStatus?.experiment?.name && (
                <div className={cn('card__content-row')}>
                  <span>Run name</span>
                  <span className={cn('card__run-name')}>{currentStatus?.experiment?.name}</span>
                </div>
              )}
            </div>
            <div className={cn('card__content-aside', 'card__content-divider')}>
              <icons.InstrumentIcon
                className={cn('card__status-icon', `card__status-icon_${badgeData?.colorModifier}`)}
              />
            </div>
          </div>
          <div className={cn('card__meta')}>
            {getInstrumentMeta(instrument).map((meta) => (
              <div className={cn('card__meta-row')} key={meta.key}>
                <span>{meta.key}</span>
                <span>{meta.value}</span>
              </div>
            ))}
          </div>
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default InstrumentCard;
