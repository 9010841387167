import { useEffect, useState, ComponentType } from 'react';
import Bowser from 'bowser';
import InvalidBrowser from '@/hoc/withValidBrowser/InvalidBrowser';
import { useLocalStorage } from '@/hooks';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  chrome: '>=110',
  firefox: '>115',
  edge: '>=110',
  opera: '>=96',
});

export function withValidBrowser(WrappedComponent: ComponentType) {
  return function ComponentWithValidBrowser() {
    const localStorageKey = 'is-valid-browser';
    const [isForcedContinue, setIsForcedContinue] = useState(true);
    const { getLocalValue, setLocalValue } = useLocalStorage(localStorageKey);

    useEffect(() => {
      const loadedIsForcedContinue = !!getLocalValue();
      setIsForcedContinue(loadedIsForcedContinue);
    }, []);

    const handleForceContinue = () => {
      setIsForcedContinue(true);
      setLocalValue('true');
    };

    if (isForcedContinue || isValidBrowser) {
      return <WrappedComponent />;
    }

    return <InvalidBrowser handleForceContinue={handleForceContinue} />;
  };
}
