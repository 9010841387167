import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';

import { getAwsClientMetadata } from '@/helpers';

import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { datadogConfig } from './datadog-exports';

import awsconfig from './aws-exports';

import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import './conditionalGlobalMethodsOverride';

Amplify.configure({
  ...awsconfig,
  Auth: {
    ...awsconfig.Auth,
    clientMetadata: getAwsClientMetadata(),
  },
});

if (process.env.REACT_APP_DATADOG_APPLICATION_ID) {
  datadogRum.init({
    ...datadogConfig,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
