import { SVGProps } from 'react';

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M6.63636 11.5L17.1364 1L20 3.86364L9.5 14.3636L6 15L6.63636 11.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <line x1="14.2725" y1="3.54584" x2="17.4543" y2="6.72766" stroke="currentColor" strokeWidth="1.5" />
    <path d="M1 19H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default EditIcon;
