import { parseJSON } from '@/helpers/common';

import { JobRunEdge, JobRunStatus } from '@/graphql/API';

export const isJobRunInProgress = (status: JobRunStatus) =>
  (
    [
      JobRunStatus.PENDING,
      JobRunStatus.RUNNING,
      JobRunStatus.ENQUEUED,
      JobRunStatus.CANCELLING,
      JobRunStatus.TERMINATING,
    ] as JobRunStatus[]
  ).includes(status);

export const isJobRunSucceeded = (status: JobRunStatus) =>
  ([JobRunStatus.SUCCEEDED] as JobRunStatus[]).includes(status);

export const getJobRunCommandInfo = (jobRun: JobRunEdge) => {
  const command = parseJSON(jobRun.node.containerOverrides?.command ?? '');
  let laneSeq = command?.['lane-seq'];
  if (typeof laneSeq !== 'object') {
    laneSeq = {};
  }
  const laneId = Object.keys(laneSeq)[0];
  return { laneId, experimentId: command?.experiment };
};
