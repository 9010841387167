import { EScanTotal } from './types';

export const scanSelectorOptions = [
  { value: EScanTotal.noScan, label: 'No scan' },
  { value: EScanTotal.single, label: 'Single after completion' },
  { value: EScanTotal.multi, label: 'Multiple scans' },
];

export const totalScansByType = {
  [EScanTotal.noScan]: 0,
  [EScanTotal.single]: 1,
  [EScanTotal.multi]: 2,
};
