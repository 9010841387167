import { SVGProps } from 'react';

const AssaySurfaceReceptor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" version="1.1" viewBox="0 0 130 130" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round">
      <circle cx="65.5" cy="70.5" r="18.5" />
      <path d="M69.1 26.5c-1.6 1.4-5.1 4.4-6.9 6-.7.6-1.7 1-2.7 1l-7.4-.1M59.2 29.4h-7.1M67.1 38v6.9M71.5 29.3c-1.5 1.3-4.8 4.2-6.7 5.8-.9.8-1.4 1.8-1.4 3V45M22 67.4c1.4 1.5 4.4 4.9 6 6.7.7.7 1 1.7 1 2.7v7.1M25.2 76.9v7M33.7 69l6.6-.1M24.7 65c1.3 1.5 4.2 4.7 5.8 6.5.8.9 1.9 1.3 3 1.3l6.8-.1M96.4 101.1v-9c0-1.2.5-2.2 1.4-3l5.3-4.7M100.1 91.9l5.4-4.6M88.9 92l-5.4-4.6M92.7 101.2v-9c0-1.2-.5-2.2-1.4-3L86 84.6" />
    </g>
  </svg>
);

export default AssaySurfaceReceptor;
