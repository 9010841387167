import { SVGProps } from 'react';

const VisibilityOnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="12" viewBox="-1 -1 21 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <path d="M9.429 12c5.207 0 9.428-4.126 9.428-6 0-1.874-4.221-6-9.428-6C4.22 0 0 4.126 0 6c0 1.874 4.221 6 9.429 6z" />
      <circle cx="9.429" cy="6" r="3.429" />
    </g>
  </svg>
);

export default VisibilityOnIcon;
