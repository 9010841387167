import { FC, MouseEvent, MouseEventHandler, ReactNode, useCallback } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './AddToTableButton.module.scss';

const cn = classnames.bind(styles);

type TAddToTableButton = {
  className?: string;
  children?: ReactNode;
  text?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const AddToTableButton: FC<TAddToTableButton> = ({ children, className, onClick, text }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
    },
    [onClick]
  );

  return (
    <Button className={cn('add-button', className)} onClick={handleClick}>
      {children}
      {!children && (
        <>
          <icons.PlusIcon className={cn('add-button__plus-icon')} />
          <span>{text}</span>
        </>
      )}
    </Button>
  );
};

export default AddToTableButton;
