import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { isDefined } from '@/helpers/typeGuards';
import { lastRunDesignStepOrder, runDesignStepOrder, urlPostfixList } from '@/helpers/runDesigns/constants';

import { EDesignStep, TDesignStep } from '@/pages/experiment-run-design/types';

// regex to handle url /template/id/timeline, /draft/id/samples etc
const pathNameRegExp = new RegExp(`((?:${Object.values(ERunDesignSourceType).join('|')})/\\w+)/(\\w*)$`);
export const useRunDesignLocation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const rootPath = useMemo(
    () =>
      // crop ending part, if exist /template/id/timeline -> /template/id
      location.pathname.replace(pathNameRegExp, '$1'),
    [location]
  );

  const currentStep = useMemo(() => {
    // crop leading part /template/id/timeline -> /timeline
    const currentStepPostfix = location.pathname.match(pathNameRegExp);

    return (
      Object.entries(urlPostfixList).find(([_, postfix]) => currentStepPostfix?.[2] === postfix)?.[0] ??
      EDesignStep.details
    );
  }, [location]);

  const getStepOrder = useCallback((designStep?: Nullable<TDesignStep>) => {
    if (!isDefined(designStep)) return null;

    const stepIndex = runDesignStepOrder.findIndex((step) => step === designStep);
    if (stepIndex < 0) return null;
    return stepIndex;
  }, []);

  const getNextStep = useCallback(() => {
    const currentStepOrder = runDesignStepOrder.findIndex((step) => step === currentStep);
    const nextStepOrder = currentStepOrder < lastRunDesignStepOrder ? currentStepOrder + 1 : undefined;

    if (!isDefined(nextStepOrder)) {
      return null;
    }

    return runDesignStepOrder[nextStepOrder];
  }, [currentStep]);

  const goToNextStep = useCallback(() => {
    const nextStep = getNextStep();

    if (!isDefined(nextStep)) {
      return;
    }

    navigate(`${rootPath}/${urlPostfixList[nextStep]}`);
  }, [currentStep, location.pathname]);

  const goToPrevStep = useCallback(() => {
    const currentStepOrder = runDesignStepOrder.findIndex((step) => step === currentStep);
    const prevStepOrder = currentStepOrder > 0 ? currentStepOrder - 1 : undefined;

    if (!isDefined(prevStepOrder)) {
      return;
    }

    const prevStep = runDesignStepOrder[prevStepOrder];
    navigate(`${rootPath}/${urlPostfixList[prevStep]}`);
  }, [currentStep, location.pathname]);

  const goToStep = useCallback(
    (step: TDesignStep) => {
      navigate(`${rootPath}/${urlPostfixList[step]}`);
    },
    [currentStep]
  );

  return {
    goToNextStep,
    goToPrevStep,
    goToStep,
    currentStep,
    getNextStep,
    getStepOrder,
  };
};
