import { EAxesScaleType } from '@/types/charts';

import { toFixed } from '@/helpers/common';
import { getMinMax } from '@/helpers/arrays';

import { CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT } from '@/components/charts/ChartSettingsButton/SettingsPopover/constants';

import arcSinhScaleStrategy from './arcSinhScaleStrategy';
import linearScaleStrategy from './linearScaleStrategy';
import logScaleStrategy from './logScaleStrategy';

const getStrategy = (axesScaleType: EAxesScaleType) => {
  switch (axesScaleType) {
    case EAxesScaleType.arcSinh:
      return arcSinhScaleStrategy;
    case EAxesScaleType.log:
      return logScaleStrategy;
    case EAxesScaleType.linear:
    default:
      return linearScaleStrategy;
  }
};

export default {
  getPlotLayoutTypePart(scaleType: EAxesScaleType): { type: string; exponentformat?: string } {
    return getStrategy(scaleType).getPlotLayoutTypePart();
  },

  getPlotUpdateLayoutTicksPart(
    scaleType: EAxesScaleType,
    axisName: string,
    plotAxisRange: number[],
    axisOrigDataRange?: number[]
  ) {
    const data = getStrategy(scaleType).getPlotLayoutTicksPart(plotAxisRange, axisOrigDataRange);
    const result: Record<string, number[] | string[]> = {};
    if ('tickvals' in data && 'ticktext' in data) {
      result[`${axisName}.tickvals`] = data.tickvals as number[];
      result[`${axisName}.ticktext`] = data.ticktext as string[];
    }
    return result;
  },

  preparePlotData(scaleType: EAxesScaleType, data: number[], axisOrigDataRange?: number[]) {
    return getStrategy(scaleType).preparePlotData(data, axisOrigDataRange);
  },

  getLinearRangeWithoutGaps: (x: number[], y: number[]): TPopulationRange => {
    const { min: xMin, max: xMax } = getMinMax(x);
    const { min: yMin, max: yMax } = getMinMax(y);
    return { xMin, xMax, yMin, yMax };
  },

  getPlotRange: ({
    xAxisScaleType,
    yAxisScaleType,
    x,
    y,
    withGap = true,
  }: {
    xAxisScaleType: EAxesScaleType;
    yAxisScaleType: EAxesScaleType;
    x?: number[];
    y?: number[];
    withGap?: boolean;
  }) => {
    if (!Array.isArray(x) || x.length === 0 || !Array.isArray(y) || y.length === 0) {
      return null;
    }

    const yRange = getStrategy(yAxisScaleType).getPlotAxisRange(y, withGap);
    const xRange = getStrategy(xAxisScaleType).getPlotAxisRange(x, withGap);

    // Rounding depends on numberValidationRegex
    return {
      xMin: toFixed(xRange[0], CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT),
      xMax: toFixed(xRange[1], CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT),
      yMin: toFixed(yRange[0], CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT),
      yMax: toFixed(yRange[1], CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT),
    };
  },

  convertToLinearPlotRange: ({
    xAxisScaleType,
    yAxisScaleType,
    plotRange,
  }: {
    xAxisScaleType: EAxesScaleType;
    yAxisScaleType: EAxesScaleType;
    plotRange: Nullable<TPopulationRange>;
  }) => {
    const xRange = plotRange
      ? getStrategy(xAxisScaleType).convertToLinearPlotRange([plotRange.xMin, plotRange.xMax])
      : null;
    const yRange = plotRange
      ? getStrategy(yAxisScaleType).convertToLinearPlotRange([plotRange.yMin, plotRange.yMax])
      : null;

    if (!xRange || !yRange) return null;

    return {
      xMin: xRange[0],
      xMax: xRange[1],
      yMin: yRange[0],
      yMax: yRange[1],
    };
  },

  getScaledGatePoints: ({
    xAxisScaleType,
    yAxisScaleType,
    gatePoints,
    plotLinearRange,
    origDataRange,
  }: {
    xAxisScaleType: EAxesScaleType;
    yAxisScaleType: EAxesScaleType;
    gatePoints: TGatePolygons;
    plotLinearRange: Nullable<TPopulationRange>;
    origDataRange?: TPlotAxisRange;
  }) =>
    gatePoints.map((point: { x: number; y: number }) => {
      const scaledX = getStrategy(xAxisScaleType).getScaledGatePoint({
        point: point.x,
        axisLinearRange: plotLinearRange ? [plotLinearRange.xMin, plotLinearRange.xMax] : null,
        axisOrigDataRange: origDataRange?.x,
      });
      const scaledY = getStrategy(yAxisScaleType).getScaledGatePoint({
        point: point.y,
        axisLinearRange: plotLinearRange ? [plotLinearRange.yMin, plotLinearRange.yMax] : null,
        axisOrigDataRange: origDataRange?.y,
      });

      return {
        x: scaledX,
        y: scaledY,
      };
    }),

  getRealGatePoints: ({
    xAxisScaleType,
    yAxisScaleType,
    gatePoints,
    plotLinearRange,
    origDataRange,
  }: {
    xAxisScaleType: EAxesScaleType;
    yAxisScaleType: EAxesScaleType;
    gatePoints: TGatePolygons;
    plotLinearRange: Nullable<TPopulationRange>;
    origDataRange?: TPlotAxisRange;
  }) =>
    gatePoints.map((point: { x: number; y: number }) => ({
      x: getStrategy(xAxisScaleType).getRealGatePoint({
        point: point.x,
        axisLinearRange: plotLinearRange ? [plotLinearRange.xMin, plotLinearRange.xMax] : null,
        axisOrigDataRange: origDataRange?.x,
      }),
      y: getStrategy(yAxisScaleType).getRealGatePoint({
        point: point.y,
        axisLinearRange: plotLinearRange ? [plotLinearRange.yMin, plotLinearRange.yMax] : null,
        axisOrigDataRange: origDataRange?.y,
      }),
    })),

  convertPlotValueToReal(scaleType: EAxesScaleType, axisValue: number, axisOrigDataRange?: number[]) {
    return getStrategy(scaleType).convertPlotValueToReal(axisValue, axisOrigDataRange);
  },

  isCircleGateAllowed(scaleType: EAxesScaleType) {
    return getStrategy(scaleType).isCircleGateAllowed();
  },

  isNegativeValuesSupported(scaleType: EAxesScaleType) {
    return getStrategy(scaleType).isNegativeValuesSupported();
  },
};
