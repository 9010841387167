import { ChangeEvent, FC, LegacyRef, MouseEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import { useDebounce } from '@/hooks';

import icons from '@/components/common/icons';

import styles from './VirtualSelect.module.scss';

const cn = classnames.bind(styles);

type TSelectInput = {
  handleOpened: (status: boolean) => void;
  isOpened: boolean;
  onChange: (value: string) => void;
  blockRef: LegacyRef<HTMLDivElement>;
  defaultValue?: Nullable<string>;
  value?: Nullable<string>;
  className?: string;
  placeholder?: string;
};

const SelectInput: FC<TSelectInput> = ({
  onChange,
  handleOpened,
  blockRef,
  defaultValue,
  value,
  className,
  isOpened,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue);
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  useEffect(() => {
    onChange(debouncedInputValue);
  }, [debouncedInputValue]);

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    setInputValue(str);
  }, []);

  const open = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    inputRef.current?.focus();
    handleOpened(true);
  }, []);

  const toggle = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      inputRef.current?.focus();
      handleOpened(!isOpened);
    },
    [handleOpened, isOpened]
  );

  const isContainsValue = useMemo(() => !!inputValue, [inputValue]);

  const onClear = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setInputValue('');
    handleOpened(true);
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!isOpened && defaultValue && inputRef.current) {
      setInputValue(defaultValue);
    }
  }, [isOpened, defaultValue]);

  useEffect(() => {
    if (inputRef.current) {
      setInputValue(value ?? '');
    }
  }, [value]);

  return (
    <div className={cn('select-input', className)} onClick={open} role="presentation" ref={blockRef}>
      <input
        onChange={onChangeInput}
        ref={inputRef}
        className={cn('select-input__input')}
        value={inputValue}
        placeholder={placeholder}
      />
      {isContainsValue && (
        <button aria-label="clear" className={cn('select-input__action', 'select-input__clear')} onClick={onClear}>
          <icons.CloseIcon />
        </button>
      )}
      <button aria-label="collapse" className={cn('select-input__action', 'select-input__collapse')} onClick={toggle}>
        <icons.ArrowDropdownIcon />
      </button>
    </div>
  );
};

export default memo(SelectInput);
