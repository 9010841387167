import { FC } from 'react';
import classNames from 'classnames/bind';
import ConsumableWaves from '@/components/runDesign/ConsumableComponent/ConsumableWaves';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import styles from './SearchResults.module.scss';

const cn = classNames.bind(styles);

type TReagentSearchResultsRowProps = {
  result: TReturnTransformedTypes;
};

export const ReagentSearchResultsHeading = () => (
  <>
    <div className={cn('search-results__table-cell')}>Reagent name</div>
    <div className={cn('search-results__table-cell')}>Vendor</div>
    <div className={cn('search-results__table-cell')}>Catalog #</div>
    <div className={cn('search-results__table-cell', 'search-results__table-cell_waves-heading')}>
      <span>Ex</span>
      <span>Em</span>
    </div>
  </>
);

export const ReagentSearchResultsRow: FC<TReagentSearchResultsRowProps> = ({ result }) => (
  <>
    <div className={cn('search-results__table-cell')}>
      <div className={cn('search-results__text')}>{result.name}</div>
    </div>
    <div className={cn('search-results__table-cell')}>
      {'vendor' in result && <div className={cn('search-results__text')}>{result.vendor}</div>}
    </div>
    <div className={cn('search-results__table-cell')}>
      {'catalogNumbers' in result && (
        <div className={cn('search-results__text')}>{result.catalogNumbers?.join(', ')}</div>
      )}
    </div>
    {'detectionWavelength' in result &&
      typeof result.detectionWavelength === 'number' &&
      typeof result.excitationWavelength === 'number' && (
        <ConsumableWaves
          className={cn('search-results__table-cell', 'search-results__table-cell_waves')}
          opticalDisplay={result?.opticalDisplay ?? null}
        />
      )}
  </>
);

export const reagentSearchResultsGridLayout = '1fr 180px 150px 100px';
export const reagentSearchResultInformationList = [
  'description',
  'immunogen',
  'manufacturerName',
  'marker',
  'type',
  'url',
];
