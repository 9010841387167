import {SVGProps} from 'react';

const ReagentSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="33.5" cy="33.5" r="30" transform="matrix(-1 0 0 1 67 0)" stroke="currentColor" strokeWidth="7"/>
    <path d="M56 56L71 71" stroke="currentColor" strokeWidth="7" strokeLinecap="round"/>
    <path d="M27.74 33.5C27.74 36.6812 30.3188 39.26 33.5 39.26C36.6812 39.26 39.26 36.6812 39.26 33.5C39.26 30.3188 36.6812 27.74 33.5 27.74C30.3188 27.74 27.74 30.3188 27.74 33.5Z" fill="black" stroke="currentColor" strokeWidth="4.48"/>
  </svg>
);

export default ReagentSearchIcon;
