import { SVGProps } from 'react';

const ManifestIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.5 1.68359H16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1.5 6.68359H16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1.5 11.6836H9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ManifestIcon;
