import { FC, MouseEventHandler } from 'react';
import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import icons from '@/components/common/icons';

import styles from './DetailsButton.module.scss';

const cn = classnames.bind(styles);

type TDetailsButtonProps = {
  isOpen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
};

const DetailsButton: FC<TDetailsButtonProps> = ({ isOpen, onClick, tooltip }) => (
  <button
    onClick={onClick}
    className={cn('details-button', {
      'details-button_open': isOpen,
    })}
    aria-label="expand"
    {...addTooltip(tooltip)}
  >
    <icons.ExpandMore />
  </button>
);

export default DetailsButton;
