const LANE_YELLOW_COLOR = '#fff575';
const LANE_BLUE_COLOR = '#56e5f1';

export const getPlotlyData = (laneList: TLane[], currentLaneId: string) => {
  const currentColor = laneList.map((lane) => (lane.id === currentLaneId ? LANE_YELLOW_COLOR : LANE_BLUE_COLOR));

  return {
    x: laneList.map((lane) => lane.letterName),
    y: laneList.map((lane) => lane.summary.numberOfCellsCaged),
    name: 'lane statistics',
    type: 'bar',
    marker: {
      color: currentColor,
      line: {
        width: 2,
        color: currentColor,
      },
    },
    width: Array.from({ length: laneList.length }, () => Math.log10(laneList.length + (1 - laneList.length / 8))),
  };
};
