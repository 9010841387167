import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EPreprocessingAssayType } from '@/helpers';
import {
  EStepName,
  TGeneralCellShape,
  TGeneralBeadShape,
  TInitialPreprocessingState,
  EPreprocessingObjectType,
} from './types';

const initialState: TInitialPreprocessingState = {
  datasets: [],
  beadsCount: 0,
  beadTypes: [],
  cellTypes: [],
  currentStep: EStepName.stepInit,
  currentDatasetIndex: null,
  generalBeadShapes: {},
  generalCellShapes: {},
  targetAndAffectedCellShapes: {},
  isProcessing: false,
  beadChannel: '',
  applyForAllDatasets: false,
  preprocessingType: EPreprocessingObjectType.beads,
  cellXChannel: '',
  cellYChannel: '',
  axesOptionList: [],
  channelOptionMap: {},
  axesOptionMap: {},
};

const preprocessingSlice = createSlice({
  name: 'preprocessing',
  initialState,
  reducers: {
    setBeadsCount: (state, action: PayloadAction<number>) => {
      state.beadsCount = action.payload;
    },
    setDatasets: (state, action: PayloadAction<TDatasetDetails[]>) => {
      state.datasets = action.payload;
    },
    changeDatasetById: (state, action: PayloadAction<{ id: string; data: Partial<TDatasetDetails> }>) => {
      const { id, data } = action.payload;
      state.datasets.forEach((dataset, i) => {
        if (dataset.id === id) {
          state.datasets[i] = {
            ...dataset,
            ...data,
          };
        }
      });
    },
    setPreprocessingAssayId: (state, action: PayloadAction<TInitialPreprocessingState['preprocessingAssayId']>) => {
      state.preprocessingAssayId = action.payload;
      if (action.payload !== EPreprocessingAssayType.cytokineSecretion) {
        state.beadTypes = [];
        state.beadsCount = 0;
        state.preprocessingType = EPreprocessingObjectType.cells;
      } else {
        state.preprocessingType = EPreprocessingObjectType.beads;
      }
    },
    setBeadTypes: (state, action: PayloadAction<TBead[]>) => {
      state.beadTypes = action.payload;
    },
    addBeadType: (state, action: PayloadAction<TBead>) => {
      if (state.beadTypes.some((el) => el.id === action.payload.id)) {
        return;
      }
      state.beadTypes.push(action.payload);
    },
    addDatasets: (state, action: PayloadAction<{ laneId: string; data: TDatasetDetails[] }>) => {
      const { data, laneId } = action.payload;

      const datasetsNotInLane = state.datasets.filter((el) => el.laneId !== laneId);
      state.datasets = [...datasetsNotInLane, ...data];
    },
    changeBeadTypeByIndex: (state, action: PayloadAction<{ index: number; data: TBead }>) => {
      const { index, data } = action.payload;
      if (!state.beadTypes[index]) {
        return;
      }
      state.beadTypes[index] = data;
    },
    setCurrentStep: (state, action: PayloadAction<EStepName>) => {
      state.currentStep = action.payload;
    },
    setCurrentDatasetIndex: (state, action: PayloadAction<Nullable<number>>) => {
      state.currentDatasetIndex = action.payload;
    },
    setGeneralBeadShapes: (state, action: PayloadAction<Record<TGeneralBeadShape['id'], TGeneralBeadShape>>) => {
      state.generalBeadShapes = action.payload;
    },
    addGeneralBeadShape: (
      state,
      action: PayloadAction<
        Omit<TGeneralBeadShape, 'id' | 'beadId' | 'specificDatasets' | 'name' | 'marker'> & {
          specificDatasets?: TGeneralBeadShape['specificDatasets'];
        }
      >
    ) => {
      const { beadForGate } = state;
      if (!beadForGate) {
        return state;
      }
      const data = action.payload;

      const generalBeadShape: TGeneralBeadShape = {
        ...data,
        beadId: beadForGate.id,
        id: beadForGate.uuid,
        specificDatasets: data.specificDatasets ?? {},
        name: beadForGate.name ?? 'Custom name',
        noEditable: !!beadForGate.beadSize,
        marker: beadForGate.marker ?? '',
      };
      state.generalBeadShapes[generalBeadShape.id] = generalBeadShape;
      state.beadForGate = undefined;
    },
    updateGeneralBeadShape: (
      state,
      action: PayloadAction<{ beadShapeId: TGeneralBeadShape['id']; data: Partial<TGeneralBeadShape> }>
    ) => {
      const { beadShapeId, data } = action.payload;
      state.generalBeadShapes[beadShapeId] = {
        ...state.generalBeadShapes[beadShapeId],
        ...data,
        specificDatasets: {},
      };
      state.beadForGate = undefined;
    },
    deleteGeneralBeadShape: (state, action: PayloadAction<TGeneralBeadShape['id']>) => {
      delete state.generalBeadShapes[action.payload];
      state.beadForGate = undefined;
    },
    setBeadForGate: (state, action: PayloadAction<TBead['uuid'] | undefined>) => {
      state.beadForGate = state.beadTypes.find((bead) => bead.uuid === action.payload);
    },
    addSpecificDatasetForBeadShape: (
      state,
      action: PayloadAction<{
        datasetId: string;
        shapeId: TGeneralBeadShape['id'];
        data: Omit<TGeneralBeadShape, 'beadId' | 'specificDatasets' | 'marker'>;
      }>
    ) => {
      const { shapeId, data, datasetId } = action.payload;
      const beadShape = state.generalBeadShapes[shapeId];
      beadShape.specificDatasets[datasetId] = data;
    },
    addSpecificDatasetForBeadShapeAllDatasets: (
      state,
      action: PayloadAction<Omit<TGeneralBeadShape, 'name' | 'beadId' | 'specificDatasets' | 'marker'>[]>
    ) => {
      const data = action.payload;
      data.forEach((beadShape) => {
        state.datasets.forEach((dataset) => {
          state.generalBeadShapes[beadShape.id].specificDatasets[dataset.id] = beadShape;
        });
      });
    },
    setApplyForAllDatasets: (state, action: PayloadAction<boolean>) => {
      state.applyForAllDatasets = action.payload;
    },
    removeSpecificDatasetShape: (state, action: PayloadAction<string>) => {
      Object.values(state.generalBeadShapes).forEach((beadShape) => {
        delete beadShape.specificDatasets[action.payload];
      });
    },
    setIsProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setBeadChannel: (state, action: PayloadAction<string>) => {
      state.beadChannel = action.payload;
    },
    resetState: () => initialState,

    // cell killing actions
    setCellTypes: (state, action: PayloadAction<TCell[]>) => {
      state.cellTypes = [...action.payload];
    },
    setCellForGate: (state, action: PayloadAction<TCell['uuid'] | undefined>) => {
      state.cellForGate = state.cellTypes.find((item) => item.uuid === action.payload);
    },
    //
    addGeneralCellShape: (
      state,
      action: PayloadAction<Omit<TGeneralCellShape, 'specificDatasets' | 'shapeNodes' | 'id' | 'name'>>
    ) => {
      const { cellForGate } = state;
      const data = action.payload;
      if (!cellForGate) {
        return state;
      }

      const parentShape = data.parentId ? state.generalCellShapes[data.parentId] : null;
      const properties = data?.properties ?? {};

      if (cellForGate.color) {
        properties.color = cellForGate.color;
      }

      if (data.parentId && !parentShape) return state;

      const shape: TGeneralCellShape = {
        ...data,
        cellId: cellForGate.id,
        id: cellForGate.uuid,
        specificDatasets: {},
        name: cellForGate.name ?? 'REAL CELLS GATE',
        noEditable: true,
        shapeNodes: {},
        properties,
      };

      if (data.parentId) {
        state.generalCellShapes[data.parentId].shapeNodes[shape.id] = shape;
      } else {
        state.generalCellShapes[shape.id] = shape;
      }

      state.cellForGate = undefined;
    },
    deleteGeneralCellShape: (
      state,
      action: PayloadAction<{ id: TGeneralCellShape['id']; parentId?: TGeneralCellShape['parentId'] }>
    ) => {
      const { parentId, id } = action.payload;

      if (parentId) {
        delete state.generalCellShapes[parentId].shapeNodes[id];
      } else {
        delete state.generalCellShapes[id];
      }
    },
    updateGeneralCellShape: (
      state,
      action: PayloadAction<{
        cellShapeId: TGeneralCellShape['id'];
        data: Partial<TGeneralCellShape>;
        parentId?: TGeneralCellShape['parentId'];
      }>
    ) => {
      const { cellShapeId, data, parentId } = action.payload;

      const parentShape = parentId ? state.generalCellShapes[parentId] : null;

      if (data.parentId && !parentShape) return state;

      const dataToUpdate = {
        ...data,
        specificDatasets: {},
      };

      if (parentId) {
        state.generalCellShapes[parentId].shapeNodes[cellShapeId] = {
          ...state.generalCellShapes[parentId].shapeNodes[cellShapeId],
          ...dataToUpdate,
        };
      } else {
        state.generalCellShapes[cellShapeId] = {
          ...state.generalCellShapes[cellShapeId],
          ...dataToUpdate,
        };
      }
    },
    clearGeneralCellShapeNodes: (state, action: PayloadAction<TGeneralCellShape['id']>) => {
      state.generalCellShapes[action.payload].shapeNodes = {};
    },
    clearGeneralCellShapes: (state) => {
      state.generalCellShapes = initialState.generalCellShapes;
    },
    addSpecificDatasetForCellShapeAllDatasets: (
      state,
      action: PayloadAction<Omit<TGeneralCellShape, 'name' | 'specificDatasets' | 'shapeNodes'>[]>
    ) => {
      const data = action.payload;

      data.forEach((shape) => {
        state.datasets.forEach((dataset) => {
          if (shape.parentId) {
            state.generalCellShapes[shape.parentId].shapeNodes[shape.id].specificDatasets[dataset.id] = shape;
          } else {
            state.generalCellShapes[shape.id].specificDatasets[dataset.id] = shape;
          }
        });
      });
    },
    removeSpecificCellDatasetShape: (
      state,
      action: PayloadAction<{ datasetId: TGeneralCellShape['id']; parentId?: TGeneralCellShape['parentId'] }>
    ) => {
      const { datasetId, parentId } = action.payload;

      if (parentId) {
        Object.values(state.generalCellShapes[parentId].shapeNodes).forEach((shape) => {
          delete shape.specificDatasets[datasetId];
        });
      } else {
        Object.values(state.generalCellShapes).forEach((shape) => {
          delete shape.specificDatasets[datasetId];
        });
      }
    },
    setGeneralCellShapes: (state, action: PayloadAction<Record<TGeneralCellShape['id'], TGeneralCellShape>>) => {
      state.generalCellShapes = action.payload;
    },
    //
    addSpecificDatasetForCellShape: (
      state,
      action: PayloadAction<{
        datasetId: string;
        shapeId: TGeneralBeadShape['id'];
        data: Omit<TGeneralBeadShape, 'beadId' | 'specificDatasets' | 'marker'>;
      }>
    ) => {
      const { shapeId, data, datasetId } = action.payload;
      let shape = null;

      if (data.parentId) {
        shape = state.generalCellShapes[data.parentId].shapeNodes[shapeId];
      } else {
        shape = state.generalCellShapes?.[shapeId];
      }

      if (shape?.specificDatasets) {
        shape.specificDatasets[datasetId] = data;
      }
    },
    setCellXChannel: (state, action: PayloadAction<string>) => {
      state.cellXChannel = action.payload;
    },
    setCellYChannel: (state, action: PayloadAction<string>) => {
      state.cellYChannel = action.payload;
    },
    setAxesOptionList: (state, action: PayloadAction<TInitialPreprocessingState['axesOptionList']>) => {
      state.axesOptionList = action.payload;
    },
    setAxesOptionMap: (state, action: PayloadAction<TInitialPreprocessingState['axesOptionMap']>) => {
      state.axesOptionMap = action.payload;
    },
    setChannelOptionMap: (state, action: PayloadAction<TInitialPreprocessingState['channelOptionMap']>) => {
      state.channelOptionMap = action.payload;
    },
  },
});

export default preprocessingSlice;
