import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { addTooltip, MDASH } from '@/helpers';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import Metadata from '@/components/common/Metadata';

import styles from '../RunDetails.module.scss';

const cn = classnames.bind(styles);

const RunDetailsMetadata: FC = () => {
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);
  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const assaysCount = useSelector(experimentRunDesignSelectors.selectAssayComponentsCount);

  return (
    <Metadata className={cn('run-details__meta')}>
      <Metadata.Item
        title="Template"
        description={<span {...addTooltip(designData.templateName ?? '')}>{designData.templateName ?? MDASH}</span>}
        titleClassName={cn('run-details__meta-title')}
        descriptionClassName={cn('run-details__meta-description')}
      />
      <Metadata.Item
        title="Run"
        description={<span {...addTooltip(currentEditFields.name)}>{currentEditFields.name}</span>}
        titleClassName={cn('run-details__meta-title')}
        descriptionClassName={cn('run-details__meta-description')}
      />
      <Metadata.Item
        title={<span className={cn('run-details__action')}>Assays</span>}
        description={<span>{assaysCount}</span>}
        titleClassName={cn('run-details__meta-title')}
        descriptionClassName={cn('run-details__meta-description')}
      />
    </Metadata>
  );
};

export default memo(RunDetailsMetadata);
