import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { arrToMapByKeys } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignConsumableToUse,
} from '@/store/slices/experimentRunDesign';

import { RunDesignContext } from '@/pages/experiment-run-design/context';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ConsumableHeader from '@/components/runDesign/ConsumableComponent/ConsumableHeader';
import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';
import { EDesignStep } from '@/pages/experiment-run-design/types';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import selectStyles from '@/components/common/Select/Select.module.scss';
import { LaneConsumablesWithMedia } from '@/graphql/API';

import ReagentsRow from './ReagentsRow';
import MediaSettingOption from './MediaSettingOption';

import type { TReagentListByLaneList } from '../types';
import styles from '../ReagentsCard.module.scss';

const cn = classnames.bind({ ...selectStyles, ...styles });

type TEditReagents = {
  isEdit?: boolean;
  withWaves?: boolean;
  isIncubation?: boolean;
  currentReagentListByLaneList: TReagentListByLaneList;
  setCurrentReagentListByLaneList: Dispatch<SetStateAction<TReagentListByLaneList>>;
  mediaSettingsByLaneMap: Record<string, LaneConsumablesWithMedia>;
  setMediaSettingsByLaneMap: Dispatch<SetStateAction<Record<string, LaneConsumablesWithMedia>>>;
};

const MEDIA_OPTIONS = [
  { value: 'refreshMedia', label: 'Refresh media' },
  { value: 'noRefresh', label: 'No Refresh' },
];

const EditReagents: FC<TEditReagents> = ({
  isEdit,
  withWaves,
  isIncubation = false,
  currentReagentListByLaneList,
  setCurrentReagentListByLaneList,
  mediaSettingsByLaneMap,
  setMediaSettingsByLaneMap,
}) => {
  const appDispatch = useAppDispatch();
  const currentLanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);
  const { currentStep } = useRunDesignLocation();

  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const [tableHeaders, setTableHeaders] = useState<ReactNode[]>([]);

  const lanesMap = useMemo(() => arrToMapByKeys(currentLanes ?? [], 'id'), [currentLanes]);

  const formattedTableData = useMemo(
    () => currentReagentListByLaneList.map((el) => el.reagents),
    [currentReagentListByLaneList]
  );

  const updateTableData = useCallback((newData: any[]) => {
    setCurrentReagentListByLaneList((prev) => {
      const copyPrev = structuredClone(prev);
      copyPrev.forEach((laneData, laneIndex) => {
        laneData.reagents = newData[laneIndex];
      });

      return copyPrev;
    });
  }, []);

  const updateMediaSetting = useCallback((newMedia: string, laneKey: string) => {
    setMediaSettingsByLaneMap((prev) => {
      const copyPrev = structuredClone(prev);
      const newValue = MEDIA_OPTIONS.find((option) => option.value === newMedia)?.value;

      if (!copyPrev?.[laneKey] || !newValue) return copyPrev;
      copyPrev[laneKey] = {
        ...copyPrev?.[laneKey],
        media: newValue,
      };
      return copyPrev;
    });
  }, []);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      saveAndContinue: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.updateComponentsLanesReagents(currentReagentListByLaneList));
          if (isIncubation) {
            appDispatch(experimentRunDesignActions.updateMediaSettingsByLanes(mediaSettingsByLaneMap));
          }
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
  }, [overrideCustomFooterConfig, currentReagentListByLaneList, mediaSettingsByLaneMap]);

  useEffect(() => {
    const countOfRepeated = Math.max(
      ...Object.values(currentReagentListByLaneList).map(
        (reagentListData) =>
          (isEdit ? 1 : 0) + 1 + reagentListData.reagents.findLastIndex((reagent) => !!reagent?.consumable.id)
      )
    );
    setTableHeaders(
      Array.from({ length: countOfRepeated }, (_, index) => {
        const key = index;
        const showWavesInHeader =
          currentStep !== EDesignStep.incubation &&
          currentReagentListByLaneList.some((laneData) => !!laneData.reagents?.[index]?.consumable);
        return (
          <ConsumableHeader key={key} withWaves={showWavesInHeader}>
            {isIncubation ? 'Treatment' : 'Reagent'} {index + 1}
          </ConsumableHeader>
        );
      })
    );
  }, [currentReagentListByLaneList, isEdit, currentStep]);

  return (
    <RunDesignTable<Array<TRunDesignConsumableToUse | null>[]>
      className={cn('reagents-table')}
      tableData={formattedTableData}
      setTableData={updateTableData}
      isEdit={isEdit}
      header={
        <RunDesignTable.Row>
          {isIncubation && (
            <RunDesignTable.Column>
              <span className={cn('reagents-table__header-item')}>MEDIA SETTINGS</span>
            </RunDesignTable.Column>
          )}
          {tableHeaders.map((header, headerIndex) => {
            const key = headerIndex;
            return <RunDesignTable.Column key={key}>{header}</RunDesignTable.Column>;
          })}
        </RunDesignTable.Row>
      }
    >
      {currentReagentListByLaneList.map((reagentListByLane, rowIndex) => {
        const laneKey = reagentListByLane.laneLetter;

        if (!lanesMap[laneKey]) {
          return <RunDesignTable.Row key={laneKey} />;
        }

        return (
          <ReagentsRow
            updateCurrentReagentList={setCurrentReagentListByLaneList}
            reagentListByLane={reagentListByLane}
            isEdit={isEdit}
            allReagentsData={currentReagentListByLaneList}
            key={laneKey}
            rowIndex={rowIndex}
            withWaves={withWaves}
          >
            {isIncubation && (
              <RunDesignTable.Column>
                {isEdit ? (
                  <Select
                    value={mediaSettingsByLaneMap?.[laneKey]?.media ?? MEDIA_OPTIONS[0].value}
                    options={MEDIA_OPTIONS}
                    theme={themeOptions.light}
                    onChange={(value: string) => updateMediaSetting(value, laneKey)}
                    className={cn('select')}
                    controlClassName={cn('select__control')}
                    menuClassName={cn('select__menu')}
                    optionClassName={cn('select__option')}
                    customComponents={{ Option: MediaSettingOption as any }}
                  />
                ) : (
                  <div className={cn('media-setting')}>
                    {MEDIA_OPTIONS.find((option) => option.value === mediaSettingsByLaneMap?.[laneKey]?.media)?.label ??
                      MEDIA_OPTIONS[0].label}
                  </div>
                )}
              </RunDesignTable.Column>
            )}
          </ReagentsRow>
        );
      })}
    </RunDesignTable>
  );
};

export default EditReagents;
