import { FC, memo, MutableRefObject, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Viewer as OsdViewer, CanvasEvent, CanvasClickEvent } from 'openseadragon';
import { scaleLinear } from 'd3-scale';
import classnames from 'classnames/bind';

import { ECdnObjectType } from '@/types/cdnData';

import { isNumber, MDASH, throttle } from '@/helpers';
import { showErrorToast } from '@/helpers/errors';
import {
  BARCODE_DRAW_SETTINGS,
  CAGE_CONTOUR_DRAW_SETTINGS,
  CAGING_SEGMENTATION_DRAW_SETTINGS,
  CELL_CONTOUR_DRAW_SETTINGS,
  circlesOnCanvas,
  contoursOnCanvas,
  getBboxWithGap,
  getOsdViewportBbox,
  CURRENT_CAGE_DRAW_SETTINGS,
} from '@/helpers/objectsOnCanvas';
import { getMinMax } from '@/helpers/arrays';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useContoursContext } from '@/hooks/useContoursContext';
import { useSequencingDataFiles } from '@/hooks/sequencingData/useSequencingDataFiles';

import { cdnAPI } from '@/store/services/cdnData';
import { viewerSelectors } from '@/store/slices/viewer';
import { navigatorSelectors } from '@/store/slices/navigator';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import Portal from '@/components/common/Portal';

import { getOsdCanvasOverlay } from './osdCanvasOverlay';

import styles from './ObjectsOnCanvas.module.scss';

const cn = classnames.bind(styles);

type TObjectsOnCanvasProps = {
  osdRef: MutableRefObject<OsdViewer[] | undefined>;
  currentLane: TLane;
  openCageInspector: (props: TCageInspectorModalProps) => void;
};

const getOpacityGenerator = (values: number[]) => {
  const { min, max } = getMinMax(values);
  return scaleLinear().domain([min, max]).range([0, 0.8]);
};

const ObjectsOnCanvas: FC<TObjectsOnCanvasProps> = ({ osdRef, currentLane, openCageInspector }) => {
  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const displayCageLocations = useSelector(viewerSelectors.selectDisplayCageLocationsLayer);
  const displaySegmentedObjects = useSelector(viewerSelectors.selectDisplaySegmentedObjectsLayer);
  const displayBarcodeCagesLayer = useSelector(viewerSelectors.selectDisplayBarcodeCagesLayer);
  const shouldFillInBarcodes = useSelector(viewerSelectors.selectShouldFillInBarcodes);
  const displayCagingSegmentationCentersLayer = useSelector(
    viewerSelectors.selectDisplayCagingSegmentationCentersLayer
  );
  const shouldFillInCagingSegmentationCenters = useSelector(
    viewerSelectors.selectShouldFillInCagingSegmentationCenters
  );
  const isAllowedObjectsOnCanvas = useSelector(navigatorSelectors.selectIsAllowedObjectsOnCanvas(currentLane.id));
  const highlightedContourList = useSelector(viewerSelectors.selectHighlightedContourList);

  const {
    cdnData: {
      [ECdnObjectType.cageContour]: { objectList: cageContourList },
      [ECdnObjectType.cellContour]: { objectList: cellContourList },
      [ECdnObjectType.barcodeCenters]: { objectList: barcodeCenterList },
      [ECdnObjectType.cagingSegmentationCenters]: { objectList: cagingSegmentationList },
    },
  } = useContoursContext();
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const { cellReadsPath } = useSequencingDataFiles(currentLaneId);
  const { data: cellReadsList } = cdnAPI.useFetchSequencingDataCellReadsStatsQuery(cellReadsPath);
  const hasCellReads = useMemo(() => !!cellReadsList, [cellReadsList]);
  const barcodeDataList = useMemo(() => {
    if (!hasCellReads) {
      return barcodeCenterList as TBarcodeCenter[];
    }
    const data = (barcodeCenterList as TBarcodeCenter[]).map((barcodeCenter) => {
      const cellReadsData = cellReadsList?.find((cellRead) => cellRead.CB === barcodeCenter.barcodeSequence);
      return {
        ...barcodeCenter,
        cbMatch: cellReadsData?.cbMatch,
        nUMIunique: cellReadsData?.nUMIunique,
      };
    });
    const generateOpacity = getOpacityGenerator(data.map((barcode) => barcode.nUMIunique ?? -1));
    data.forEach((d) => {
      d.opacity = generateOpacity(d.nUMIunique ?? -1);
    });
    return data;
  }, [hasCellReads, barcodeCenterList, cellReadsList]);

  const useFetchEntityListQuery = isObjectEntityEnabled
    ? cdnAPI.useFetchObjectEntityListQuery
    : cdnAPI.useFetchCageEntityListQuery;
  const { data: entityList = [] } = useFetchEntityListQuery(currentLane);

  const controllerOsd = useMemo(
    () => (osdRef.current && osdRef.current.length > 0 ? osdRef.current[0] : null),
    [osdRef.current]
  );

  const [hoveredObject, setHoveredObject] =
    useState<Nullable<TCageContour | TCellContour | TBarcodeCenter | TCagingSegmentationCenter>>(null);
  const [hoveredPosition, setHoveredPosition] = useState({ x: 0, y: 0 });

  const handleCanvasRedraw = () => {
    const overlay = controllerOsd && isAllowedObjectsOnCanvas ? getOsdCanvasOverlay(controllerOsd) : null;
    const ctx = overlay?.context2d();
    if (!controllerOsd || !ctx) {
      return;
    }

    const bbox = getBboxWithGap(getOsdViewportBbox(controllerOsd.viewport));
    if (displayCageLocations && cageContourList) {
      const filteredCageContourList = contoursOnCanvas.filterByBbox(bbox, cageContourList);
      contoursOnCanvas.draw(ctx, filteredCageContourList, CAGE_CONTOUR_DRAW_SETTINGS);
    }

    if (displaySegmentedObjects && cellContourList) {
      const filteredCellContourList = contoursOnCanvas.filterByBbox(bbox, cellContourList);
      contoursOnCanvas.draw(ctx, filteredCellContourList, CELL_CONTOUR_DRAW_SETTINGS);
    }

    if (displayBarcodeCagesLayer && barcodeCenterList) {
      const filteredBarcodeCenterList = circlesOnCanvas.filterByBbox(bbox, barcodeDataList);
      circlesOnCanvas.draw(
        ctx,
        filteredBarcodeCenterList,
        BARCODE_DRAW_SETTINGS,
        shouldFillInBarcodes,
        hasCellReads ? (item) => !isNumber(item.cbMatch) : undefined
      );
    }

    if (displayCagingSegmentationCentersLayer && cagingSegmentationList) {
      const filteredCagingSegmentationList = circlesOnCanvas.filterByBbox(bbox, cagingSegmentationList);
      circlesOnCanvas.draw(
        ctx,
        filteredCagingSegmentationList,
        CAGING_SEGMENTATION_DRAW_SETTINGS,
        shouldFillInCagingSegmentationCenters
      );
    }

    if (highlightedContourList.length > 0) {
      contoursOnCanvas.draw(ctx, highlightedContourList, CURRENT_CAGE_DRAW_SETTINGS);
    }
  };

  const handleCanvasClick = (event: CanvasClickEvent) => {
    if (!event.quick || !controllerOsd) {
      return;
    }
    const cageContourAtPosition = contoursOnCanvas.findObject(
      controllerOsd.viewport,
      event.position,
      cageContourList ?? []
    );
    const cage =
      cageContourAtPosition &&
      entityList.find((entity: TEntity) => entity.globalCageIdMatched === cageContourAtPosition.globalCageIdMatched);
    if (cage) {
      openCageInspector({ entity: cage, lane: currentLane });
      setHoveredObject(null);
    } else if (cageContourAtPosition) {
      showErrorToast('Cage not found');
    }
  };

  const handleCanvasMove = (event: CanvasEvent) => {
    if (!controllerOsd || !isAllowedObjectsOnCanvas) {
      return;
    }

    let cageContourAtPosition = null;

    if (displayBarcodeCagesLayer) {
      cageContourAtPosition = circlesOnCanvas.findObject(controllerOsd.viewport, event.position, barcodeDataList ?? []);
    }

    if (!cageContourAtPosition) {
      cageContourAtPosition =
        contoursOnCanvas.findObject(controllerOsd.viewport, event.position, cellContourList ?? []) ??
        contoursOnCanvas.findObject(controllerOsd.viewport, event.position, cageContourList ?? []);
    }

    throttledSetHoveredObject(cageContourAtPosition);
    setHoveredPosition(event.position);
  };

  const throttledSetHoveredObject = throttle(
    (contour: Nullable<TCageContour | TCellContour | TBarcodeCenter | TCagingSegmentationCenter>) => {
      setHoveredObject(contour);
    },
    250
  );

  useEffect(() => {
    const overlay = controllerOsd ? getOsdCanvasOverlay(controllerOsd) : null;
    if (!overlay) {
      return;
    }
    overlay.setHandlers(handleCanvasRedraw, handleCanvasClick, handleCanvasMove);
    overlay.updateCanvas();
  }, [
    osdRef.current,
    cageContourList,
    cellContourList,
    barcodeCenterList,
    displayCageLocations,
    displaySegmentedObjects,
    displayBarcodeCagesLayer,
    shouldFillInBarcodes,
    displayCagingSegmentationCentersLayer,
    shouldFillInCagingSegmentationCenters,
    isAllowedObjectsOnCanvas,
    highlightedContourList,
  ]);

  useEffect(
    throttle(() => {
      if (!isAllowedObjectsOnCanvas) {
        setTimeout(() => {
          setHoveredObject(null);
        }, 250);
      }
    }),
    [isAllowedObjectsOnCanvas]
  );

  if (!hoveredObject) {
    return null;
  }

  return (
    <Portal>
      <div
        className={cn('objects-on-canvas__tooltip')}
        style={{
          left: hoveredPosition.x,
          top: hoveredPosition.y,
        }}
      >
        {'objectId' in hoveredObject && <div>Object ID: {hoveredObject.objectId}</div>}
        {'globalCageIdMatched' in hoveredObject && (
          <div>Global cage ID: {hoveredObject?.globalCageIdMatched ?? MDASH}</div>
        )}
        {'snapshotId' in hoveredObject && <div>Snapshot ID: {hoveredObject.snapshotId}</div>}
        {'imageId' in hoveredObject && <div>Image ID: {hoveredObject.imageId}</div>}
        {'barcodeName' in hoveredObject && (
          <>
            <div>Barcode name: {hoveredObject.barcodeName}</div>
            <div>Barcode sequence: {hoveredObject.barcodeSequence}</div>
            {hasCellReads && (
              <>
                <div>Reads: {hoveredObject.cbMatch ?? MDASH}</div>
                <div>MBCs: {hoveredObject.nUMIunique ?? MDASH}</div>
              </>
            )}
          </>
        )}
      </div>
    </Portal>
  );
};

export default memo(ObjectsOnCanvas);
