import { v4 as uuidv4 } from 'uuid';
import { Incubation, Placement } from '@/graphql/API';
import getInitialScanConfig from './getInitialScanConfig';
import getInitialIncubation from './getInitialIncubation';

const getInitialIncubationComponent = (counterInName: number, relativeTo?: string): Incubation => ({
  __typename: 'Incubation',
  id: uuidv4(),
  name: counterInName > 1 ? `Incubation ${counterInName}` : 'Incubation',
  type: 'Incubation',
  performedOnLanes: [],
  performedOnLanesWithMedia: [],
  scanConfig: {
    ...getInitialScanConfig(),
    numberOfScans: 1,
    lagTime: 5400, // 90 minutes
  },
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
});

export default getInitialIncubationComponent;
