import { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import styles from './Label.module.scss';

const cn = classnames.bind(styles);

type TLabel = {
  laneLetter: string;
  isEditAllowed?: boolean;
  isLocked?: boolean;
  className?: string;
  isActivationAllowed?: boolean;
};

const Label: FC<TLabel> = ({ laneLetter, isEditAllowed, isLocked, className, isActivationAllowed }) => {
  const appDispatch = useAppDispatch();

  const lane = useSelector(experimentRunDesignSelectors.selectCurrentLaneById(laneLetter));

  const isNotClickable = useMemo(
    () => isLocked || !isEditAllowed || (!lane && !isActivationAllowed),
    [isLocked, isEditAllowed, lane, isActivationAllowed]
  );

  const handleActive = useCallback(() => {
    if (!isEditAllowed || !isActivationAllowed) {
      return;
    }

    if (lane) {
      appDispatch(experimentRunDesignActions.removeLane(laneLetter));
      return;
    }
    appDispatch(experimentRunDesignActions.addNewLane(laneLetter));
  }, [lane, laneLetter, isActivationAllowed]);

  return (
    <>
      {isNotClickable && (
        <div className={cn('label', 'label_non-editable', className, { 'label_non-existent': !lane })}>
          {laneLetter}
        </div>
      )}
      {!isNotClickable && (
        <button onClick={handleActive}>
          <div className={cn('label', className, { 'label_non-existent': !lane })}>{laneLetter}</div>
        </button>
      )}
    </>
  );
};

export default Label;
