import { SVGProps } from 'react';

const ArrowToIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 8.14358L1 1.08008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 8.14453L1 15.081" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ArrowToIcon;
