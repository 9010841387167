import vShader from '@/hooks/useWebgl/shaders/vertex.vert';
import fShader from '@/hooks/useWebgl/shaders/fragment.frag';
import basicProgramInit from '@/hooks/useWebgl/helpers/basicProgramInit';
import projectSpecifiedInit from '@/hooks/useWebgl/helpers/projectSpecifiedInit';

export async function initShaders(gl: Nullable<WebGLRenderingContext>) {
  if (!gl) {
    return;
  }

  const program = basicProgramInit(gl, vShader, fShader);

  if (!program) {
    return;
  }

  const { uniforms, lutTextureList } = await projectSpecifiedInit(gl, program);

  return {
    uniforms,
    lutTextureList,
  };
}
