import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { TRenderEntityImage } from '@/hooks/useWebgl';

import { downloadByUrl } from '@/helpers';

import { experimentSelectors } from '@/store/slices/experiment';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import useDrawObjectsOnImageCrop from '../hooks/useDrawObjectsOnImageCrop';

import styles from './PreviewImage.module.scss';

const cn = classnames.bind(styles);

export type TPreviewImageModalContentProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cropEntityList: (TEntity | undefined)[];
  renderSettingsList: TRenderOptions[];
  renderEntityImage: TRenderEntityImage;
  currentEntityUuid?: string;
  scanIndex?: number;
  laneId?: string;
};

const PreviewImageModalContent: FC<TPreviewImageModalContentProps> = ({
  isOpen,
  setIsOpen,
  cropEntityList,
  renderSettingsList,
  renderEntityImage,
  currentEntityUuid,
  scanIndex,
  laneId,
}) => {
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);

  const [selectedScanIndex, setSelectedScanIndex] = useState(scanIndex);

  const previewCanvasRef = useRef<Nullable<HTMLCanvasElement>>(null);

  const drawObjects = useDrawObjectsOnImageCrop(
    previewCanvasRef,
    selectedScanIndex !== undefined ? scanList[selectedScanIndex].id : undefined,
    laneId
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleScanTimeClick = (index: number) => {
    setSelectedScanIndex(index);
  };

  const handleDownloadButtonClick = () => {
    if (!isOpen || !previewCanvasRef.current) {
      return;
    }
    const entity = selectedScanIndex !== undefined && cropEntityList[selectedScanIndex];
    if (entity) {
      const url = previewCanvasRef.current.toDataURL('image/png');
      downloadByUrl(url, entity.cageCropFileName);
    }
  };

  useEffect(() => {
    const entity = selectedScanIndex !== undefined && cropEntityList[selectedScanIndex];
    const renderSettings = selectedScanIndex !== undefined && renderSettingsList[selectedScanIndex];
    if (!previewCanvasRef.current || !entity || !renderSettings) {
      return;
    }
    renderEntityImage(previewCanvasRef.current, entity, renderSettings, () => {
      drawObjects(entity, currentEntityUuid === entity.uuid);
    });
  }, [
    previewCanvasRef.current,
    cropEntityList,
    renderSettingsList,
    selectedScanIndex,
    renderEntityImage,
    drawObjects,
    currentEntityUuid,
  ]);

  return (
    <>
      <canvas ref={previewCanvasRef} className={cn('canvas')} />
      <div className={cn('preview-image__container')}>
        {scanList && (
          <div className={cn('preview-image__time-container')}>
            {scanList.map(
              ({ id, timeNode }, index) =>
                cropEntityList[index] && (
                  <div
                    key={id}
                    role="presentation"
                    onClick={() => handleScanTimeClick(index)}
                    className={cn('preview-image__time', {
                      'preview-image__time_selected': selectedScanIndex === index,
                    })}
                  >
                    {timeNode}
                  </div>
                )
            )}
          </div>
        )}
        <Button color="light" onClick={handleDownloadButtonClick} className={cn('preview-image__download-button')}>
          <icons.DownloadIcon />
        </Button>
      </div>

      <Button color="light" type="button" className={cn('preview-image__close-button')} onClick={closeModal}>
        <icons.CloseIcon />
      </Button>
    </>
  );
};

export default PreviewImageModalContent;
