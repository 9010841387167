import { FC, ReactElement } from 'react';

import classnames from 'classnames/bind';

import styles from '../Metadata.module.scss';

const cn = classnames.bind(styles);

type TMetadataSlotProps = {
  className?: string;
  title: string | ReactElement;
  description: string | number | ReactElement;
  titleClassName?: string;
  descriptionClassName?: string;
};

const MetadataItem: FC<TMetadataSlotProps> = ({
  className,
  title,
  description,
  titleClassName,
  descriptionClassName,
}) => (
  <div className={cn('metadata__item', className)}>
    <dt className={titleClassName}>{title}</dt>
    <dd className={descriptionClassName}>{description}</dd>
  </div>
);

export default MetadataItem;
