import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Flip from 'gsap/Flip';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { uiActions } from '@/store/slices/ui';

import styles from './FlipBlock.module.scss';

const cn = classnames.bind(styles);

export default function useFlipBlock(resizeAfterAnimation: () => void, flipBlockFullScreenClass = '') {
  const appDispatch = useAppDispatch();

  const [isExpandMode, setIsExpandMode] = useState(false);
  const [isTransitionEnd, setIsTransitionEnd] = useState(true);
  const flipBlockRef = useRef<Nullable<HTMLDivElement>>(null);

  const flipBlockClassName = cn('flip-block', { 'flip-block_full-screen': isExpandMode });
  const flipBlockBackgroundClassName = cn('flip-block__background', {
    'flip-block__background_visible': isExpandMode || !isTransitionEnd,
  });

  const toggleFullScreen = () => {
    setIsTransitionEnd(false);
    appDispatch(uiActions.setIsMainScrollEnabled(false));
  };

  const onAnimationComplete = () => {
    resizeAfterAnimation();
    setIsExpandMode((prevState) => !prevState);
    setIsTransitionEnd(true);
  };

  useLayoutEffect(() => {
    if (!isTransitionEnd) {
      const state = Flip.getState(flipBlockRef.current);
      if (flipBlockFullScreenClass) {
        flipBlockRef.current?.classList.toggle(flipBlockFullScreenClass);
      }
      flipBlockRef.current?.classList.toggle(cn('flip-block_full-screen'));
      Flip.from(state, {
        duration: 0.5,
        ease: 'power1.inOut',
        absolute: true,
        onComplete: onAnimationComplete,
      });
    }
  }, [isTransitionEnd]);

  useEffect(() => {
    appDispatch(uiActions.setIsMainScrollEnabled(!isExpandMode));
  }, [isExpandMode]);

  useEffect(
    () => () => {
      appDispatch(uiActions.setIsMainScrollEnabled(true));
    },
    []
  );

  return {
    flipBlockRef,
    flipBlockClassName,
    flipBlockBackgroundClassName,
    toggleFullScreen,
    isExpandMode,
    isTransitionEnd,
  };
}
