import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useGatesPreprocessing } from '@/hooks/preprocessing/useGatesPreprocessing';

import { experimentSelectors } from '@/store/slices/experiment';
import {
  gatesAutoPreprocessingActions,
  gatesAutoPreprocessingSelectors,
  TProcessingResultPayload,
} from '@/store/slices/gatesAutoPreprocessing';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import Popover, { TPopoverOption } from '@/components/common/Popover';

import styles from '@/pages/Experiment/Experiment.module.scss';

import { getProcessingResultOptionList } from './helpers';

const cn = classnames.bind(styles);

const GateAutoProcessing: FC = () => {
  const appDispatch = useAppDispatch();

  const experimentId = useSelector(experimentSelectors.selectCurrentExperimentId);
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const isPreprocessingInProgress = useSelector(gatesAutoPreprocessingSelectors.selectIsPreprocessingInProgress);
  const preprocessingResult = useSelector(gatesAutoPreprocessingSelectors.selectPreprocessingResult);

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const setIsPreprocessingInProgress = useCallback((newIsPreprocessingInProgress: boolean) => {
    appDispatch(gatesAutoPreprocessingActions.setIsPreprocessingInProgress(newIsPreprocessingInProgress));
  }, []);

  const savePreprocessingResult = useCallback((newPreprocessingResult: TProcessingResultPayload[]) => {
    appDispatch(
      gatesAutoPreprocessingActions.setCalculatedCageLevelSummaryStats({
        experimentId,
        preprocessingResult: newPreprocessingResult,
      })
    );
  }, []);

  const { startGatesPreprocessing } = useGatesPreprocessing(setIsPreprocessingInProgress, savePreprocessingResult);

  const optionList = useMemo<TPopoverOption[]>(
    () => getProcessingResultOptionList(preprocessingResult, scanList),
    [preprocessingResult]
  );

  const calculateCageLevelSummaryStats = () => {
    startGatesPreprocessing();
  };

  const handlePopoverClick = () => {
    setIsPopoverOpened((prev) => !prev);
  };

  return (
    <>
      <Button
        tooltip="Calculate cage-level summary stats"
        onClick={calculateCageLevelSummaryStats}
        color="white"
        className={cn('controls__button')}
        isLoading={isPreprocessingInProgress}
      >
        <icons.LaneChoosedIcon />
      </Button>
      {!isPreprocessingInProgress && optionList.length > 0 && (
        <Popover
          onClick={handlePopoverClick}
          isOpen={isPopoverOpened}
          options={optionList}
          setIsOpen={setIsPopoverOpened}
          align="start"
        >
          <Button
            tooltip="Download calculated cage-level summary stats"
            color="white"
            className={cn('controls__button')}
          >
            <icons.DownloadIcon />
          </Button>
        </Popover>
      )}
    </>
  );
};

export default memo(GateAutoProcessing);
