import { SVGProps } from 'react';

const BigPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <line x1="36.25" y1="3.27834e-08" x2="36.25" y2="70" stroke="currentColor" strokeWidth="1.5" />
    <line x1="70" y1="35.25" x2="-6.55671e-08" y2="35.25" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default BigPlus;
