import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import { FC } from 'react';

export type TConfigReducerState = {
  title: string;
  description: string;

  current?: Nullable<{ id: string; name: string }>;
  search: {
    placeholder: string;
    getSuggestions: (value: string) => Promise<void | string[]>;
    getData: (value: string, filters: Record<string, string>) => Promise<void | TReturnTransformedTypes[]>;
    result?: {
      headingRenderer?: FC;
      rowRenderer?: FC<{
        result: TReturnTransformedTypes;
      }>;
      cssGridLayout: string;
      informationList: string[];
    };
  };
  filter: Nullable<{
    hook: () => {
      filterState: Record<string, string>;
      updateFactory: (field: string) => (value: string) => void;
      cleanFilters: () => void;
      cssGridLayout: string;
    };
    renderer?: FC<{
      filterState: Record<string, string>;
      updateFactory: (field: string) => (value: string) => void;
      data?: TReturnTransformedTypes[];
    }>;
  }>;

  submitButtonText: string;
  onSelect: (...args: any[]) => void;
};

export const EConfigActionList = {
  setConfig: 'config/set',
  cleanCurrent: 'config/clean-current',
} as const;

type TSetConfigAction = {
  type: typeof EConfigActionList.setConfig;
  payload: TConfigReducerState;
};

type TCleanCurrentAction = {
  type: typeof EConfigActionList.cleanCurrent;
};

export type TConfigReducerActionList = TSetConfigAction | TCleanCurrentAction;

export const configReducer = (state: TConfigReducerState, action: TConfigReducerActionList) => {
  if (action.type === EConfigActionList.setConfig) {
    return action.payload;
  }

  if (action.type === EConfigActionList.cleanCurrent) {
    return { ...state, current: null };
  }

  return state;
};

export const initialConfig = {
  title: '',
  description: '',
  current: null,
  search: {
    placeholder: '',
    getSuggestions: () => Promise.resolve([]),
    getData: () => Promise.resolve([]),
  },
  filter: {
    hook: () => ({
      filterState: {},
      updateFactory: () => () => null,
      cleanFilters: () => null,
      cssGridLayout: '',
    }),
  },
  submitButtonText: '',
  onSelect: () => null,
};
