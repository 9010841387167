import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { appAPI } from '@/store/services/app';
import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { TGeneralCellShape } from '@/store/slices/preprocessing/types';

import { CELL_TYPES_FOR_PUPULATION } from '@/components/experiment/Preprocessing/CellKilling/constants';

import useParamsExperimentId from '../useParamsExperimentId';

const usePredefineCellKillingGates = (dataset: TDatasetDetails) => {
  const experimentId = useParamsExperimentId();

  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const cellTypes = useSelector(preprocessingSelectors.selectCellTypes);

  const [fetchExperimentGates, { data: gatesFromServer, isLoading, isError }] =
    appAPI.useLazyFetchExperimentGatesQuery();

  useEffect(() => {
    fetchExperimentGates(experimentId, true);
  }, [experimentId]);

  useEffect(() => {
    if (isError || isLoading || !gatesFromServer) {
      return;
    }

    CELL_TYPES_FOR_PUPULATION.reduce((cellTypesAcc, cellType) => {
      const gate = gatesFromServer.find(
        (gateFromServer) =>
          gateFromServer.name === cellType.name &&
          dataset.laneId === gateFromServer.laneId &&
          dataset.scanId === gateFromServer.scanId
      );
      if (!gate) {
        return cellTypesAcc;
      }

      return cellTypesAcc;
    }, {} as Record<string, TGeneralCellShape>);
  }, [gatesFromServer, isLoading, isError, dataset, datasets, cellTypes]);
};

export default usePredefineCellKillingGates;
