import { FC, ReactElement, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatNumber } from '@/helpers';

import PageHeader from '@/components/Layout/Page/PageHeader';

import classnames from 'classnames/bind';
import styles from './DashboardHeader.module.scss';

const cn = classnames.bind(styles);

type TDashboardHeaderProps = {
  title: string;
  dataList: Array<{ countData?: { label: string; value: number }; customData?: ReactElement }>;
};

const DashboardHeader: FC<TDashboardHeaderProps> = ({ title, dataList }) => {
  const slotDataList = useMemo(() => dataList.map((slotData) => ({ ...slotData, uuid: uuidv4() })), [dataList]);

  return (
    <PageHeader className={cn('dashboard-header')}>
      <PageHeader.Section>
        <PageHeader.Title as="h1">{title}</PageHeader.Title>
      </PageHeader.Section>

      {slotDataList.map((data) => (
        <PageHeader.Section key={data.uuid}>
          {!!data.countData && (
            <>
              <PageHeader.Title isStatistics>{formatNumber(data.countData.value)}</PageHeader.Title>
              <PageHeader.Subtitle>{data.countData.label}</PageHeader.Subtitle>
            </>
          )}
          {data.customData}
        </PageHeader.Section>
      ))}
    </PageHeader>
  );
};

export default DashboardHeader;
