import classnames from 'classnames/bind';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useExperimentContext } from '@/hooks';

import { experimentSelectors } from '@/store/slices/experiment';

import NoDataFound from '@/components/common/NoDataFound';

import styles from '../../Summary.module.scss';

const cn = classnames.bind(styles);

const Flowcell: FC = () => {
  const { changeCurrentAppLane, isAllLanesDataShow } = useExperimentContext();
  const allLaneList = useSelector(experimentSelectors.selectAllCagingLaneList);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const isLanesSelectingAvailable = useMemo(() => allLaneList.length > 1, [allLaneList]);

  const handleCurrentAppLane = (lane: TLane) => {
    if (!isLanesSelectingAvailable) {
      return;
    }
    changeCurrentAppLane(lane);
  };

  if (allLaneList.length === 0) {
    return <NoDataFound alignment="left" />;
  }

  return (
    <div>
      {allLaneList.map((lane) => (
        <div
          key={lane.id}
          onClick={() => handleCurrentAppLane(lane)}
          role="presentation"
          className={cn('flow-data__flowcell_lane', {
            'flow-data__flowcell_lane_selected': isAllLanesDataShow ? false : lane.id === currentAppLane?.id,
            'flow-data__flowcell_lane_disabled': !isLanesSelectingAvailable,
          })}
        >
          <div className={cn('flow-data__flowcell_lane_letter')}>{lane.letterName}</div>
          <div className={cn('flow-data__flowcell_progressbar')}>
            <div className={cn('flow-data__flowcell_lane_progress')} style={{ width: `${lane.progress ?? 100}%` }} />
          </div>
          <div className={cn('flow-data__flowcell_lane_percentage')}>{`${lane.progress ?? 100}%`}</div>
        </div>
      ))}
    </div>
  );
};

export default Flowcell;
