import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { EPreprocessingAssayType, PREPROCESSING_ASSAYS } from '@/helpers';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';

import mainStyles from '../Preprocessing.module.scss';

const cnMain = classnames.bind(mainStyles);

const ChooseAssay: FC = () => {
  const dispatch = useDispatch();

  const selectedPreprocessingAssayId = useSelector(preprocessingSelectors.selectPreprocessingAssayId);

  const handleSelectPreprocessingAssayId = useCallback((preprocessingAssayId: EPreprocessingAssayType) => {
    dispatch(preprocessingActions.setPreprocessingAssayId(preprocessingAssayId));
  }, []);

  return (
    <div className={cnMain('question-block')}>
      <h1 className={cnMain('question-block__title')}>1. What type of data preprocessing would you like to do?</h1>
      <div className={cnMain('question-block__content')}>
        {PREPROCESSING_ASSAYS.map((preprocessingAssayId) => (
          <label key={preprocessingAssayId.idByProcessType} className={cnMain('radio-button')}>
            <input
              type="radio"
              checked={preprocessingAssayId.idByProcessType === selectedPreprocessingAssayId}
              onChange={() => handleSelectPreprocessingAssayId(preprocessingAssayId.idByProcessType)}
            />
            {preprocessingAssayId.name}
          </label>
        ))}
      </div>
    </div>
  );
};

export default memo(ChooseAssay);
