import { FC } from 'react';
import { MDASH } from '@/helpers';
import NoDataFound from '@/components/common/NoDataFound';

type TSettingValueBoolean = {
  value: TSettingOptionVariableValue;
};

const SettingValueBoolean: FC<TSettingValueBoolean> = ({ value }) => {
  if (typeof value !== 'boolean') {
    return <NoDataFound textData={MDASH} alignment="left" />;
  }

  return <span>{value ? 'Yes' : 'No'}</span>;
};

export default SettingValueBoolean;
