import { isPointInGate } from '@/helpers/gates';
import { isNumber } from '@/helpers';

import { filterEntityListByObjectType } from '@/hooks/charts/useChartEntityList';
import { EEntityObjectType } from '@/store/slices/chartSettings';

export const UNGATED_ID = 'ungated';

type TPrepareGatesData = {
  gateList: TGate[];
  fullParentEntityList: TEntity[];
  entitiesDataByGates: TEntitiesByGates;
  objectType: EEntityObjectType;
  scanId?: string;
  laneId?: string;
  parentGate?: TGate;
};

type TGetEntitiesDataByGatesPayload = {
  entityList: TEntity[];
  gateList: TGate[];
  scanId?: string;
  laneId?: string;
  objectType: EEntityObjectType;
};

const prepareGatesData = ({
  gateList,
  fullParentEntityList,
  entitiesDataByGates,
  objectType = EEntityObjectType.all,
  scanId,
  laneId,
  parentGate,
}: TPrepareGatesData) => {
  gateList.forEach((gate) => {
    const parentEntityList = fullParentEntityList.filter(
      (entity) => isNumber(entity[gate.xDimension]) && isNumber(entity[gate.yDimension])
    );

    const gateEntityList =
      gate.shape.type === 'polar'
        ? parentEntityList
        : parentEntityList.filter((entity: TEntity) =>
            isPointInGate({
              x: entity[gate.xDimension],
              y: entity[gate.yDimension],
              gate,
              parentGate,
              scanId,
              laneId,
            })
          );

    const filteredCageList = filterEntityListByObjectType(gateEntityList, objectType);
    const entitiesCount = filteredCageList.length;
    const entitiesPercent = parentEntityList.length > 0 ? (filteredCageList.length / parentEntityList.length) * 100 : 0;

    entitiesDataByGates[gate.id] = {
      cageList: filteredCageList,
      fullCageList: gateEntityList,
      cagesCount: entitiesCount,
      cagesPercent: `${entitiesPercent.toFixed(2)}%`,
      name: gate?.name ?? '',
    };

    if (gate.gateNodes.length) {
      prepareGatesData({
        gateList: gate.gateNodes,
        parentGate: gate,
        fullParentEntityList: gateEntityList,
        entitiesDataByGates,
        objectType,
        scanId,
        laneId,
      });
    }
  });
};

export const getEntitiesDataByGates = ({
  entityList,
  gateList,
  objectType = EEntityObjectType.all,
  scanId,
  laneId,
}: TGetEntitiesDataByGatesPayload) => {
  const filteredCageList = filterEntityListByObjectType(entityList, objectType);

  const entitiesDataByGates: TEntitiesByGates = {
    [UNGATED_ID]: {
      cageList: filteredCageList,
      fullCageList: entityList,
      cagesCount: filteredCageList.length,
      cagesPercent: '100%',
      name: '',
    },
  };

  prepareGatesData({
    gateList,
    fullParentEntityList: filteredCageList,
    entitiesDataByGates,
    objectType,
    scanId,
    laneId,
  });
  return entitiesDataByGates;
};

export const getEntitiesData = (
  entitiesByLanesAndGates: TEntitiesByLanesAndGates,
  lanePath?: string,
  gate?: Nullable<TGate>
) => {
  if (!lanePath || !entitiesByLanesAndGates[lanePath]) {
    return null;
  }
  if (gate && gate.id) {
    return entitiesByLanesAndGates[lanePath][gate.id] ?? null;
  }

  return entitiesByLanesAndGates[lanePath][UNGATED_ID];
};

export const filterEntitiesByCage = (entityList: TEntity[]) => {
  const entitiesMap: Record<string, TEntity> = {};
  entityList.forEach((entity) => {
    const key = JSON.stringify({
      cageId: entity.cageId,
      xStage: entity.xStage,
      yStage: entity.yStage,
    });
    if (!entitiesMap[key]) {
      entitiesMap[key] = entity;
    }
  });
  return Object.values(entitiesMap);
};
