export const getFullFileNameWithTime = (fileName: string, withTime = true) =>
  withTime
    ? `${fileName} ${new Date().toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
      })}`
    : fileName;
