export const searchInstruments = (searchQuery: string, instrumentList: TInstrumentFromServer[] | undefined) => {
  if (!searchQuery || !instrumentList) {
    return instrumentList;
  }

  const searchKeys = ['name', 'friendlyName'] as const;

  return instrumentList.filter((item: TInstrumentFromServer) => {
    const preparedData = {
      name: item.name,
      friendlyName: item.friendlyName,
    };

    return searchKeys.some((key) => preparedData[key]?.toLowerCase().includes(searchQuery.toLowerCase()));
  });
};
