import { useSelector } from 'react-redux';
import VirtualScroll from 'react-dynamic-virtual-scroll';
import { FC, useCallback, useRef, useState, useMemo, memo, useEffect, useLayoutEffect } from 'react';
import classnames from 'classnames/bind';

import { formatNumber } from '@/helpers';

import useWebgl from '@/hooks/useWebgl';
import { useExperimentContext } from '@/hooks';

import { navigatorSelectors, TRenderOption } from '@/store/slices/navigator';
import { experimentSelectors } from '@/store/slices/experiment';

import LoaderProgress from '@/components/common/LoaderProgress';
import CageListRow from '@/components/common/ImagesScrollArea/components/CageListRow';
import InfoButton from '@/components/common/InfoButton';

import NoDataFound from '../NoDataFound';
import styles from './ImagesScrollArea.module.scss';

const cn = classnames.bind(styles);

type TImagesScrollArea = {
  openModalFactory: (sample: TEntity) => () => void;
  minItemWidth?: number;
  entitiesToDisplay: TEntity[];
  showTitle?: boolean;
  imagesScrollAreaContainerClassName?: string;
  isHoverActionEnabled?: boolean;
};

const ITEMS_TO_RENDER_BY_SCROLL = 10;
const IMAGES_GAP = 5;

const ImagesScrollArea: FC<TImagesScrollArea> = ({
  openModalFactory,
  minItemWidth = 60,
  entitiesToDisplay,
  showTitle = true,
  imagesScrollAreaContainerClassName = '',
  isHoverActionEnabled = false,
}) => {
  const experimentContext = useExperimentContext();
  const currentAppScan = useSelector(experimentSelectors.selectCurrentScan);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const entitiesLoadedLaneList = useMemo(
    () => experimentContext.entitiesLoadedLaneList,
    [experimentContext.entitiesLoadedLaneList]
  );

  const [isCageListLoading, setIsCageListLoading] = useState(true);

  useLayoutEffect(() => {
    const loading = !!currentAppLane && !entitiesLoadedLaneList.includes(currentAppLane.path);
    // setTimeout is used to avoid blinking "no data" text
    setTimeout(() => setIsCageListLoading(loading));
  }, [currentAppLane, entitiesLoadedLaneList]);

  const { renderEntityImage } = useWebgl();

  const imagesRef = useRef<Nullable<HTMLDivElement>>(null);
  const [rowCountData, setRowCountData] = useState({
    rowsCount: 0,
    cagesInRowCount: 0,
  });

  useEffect(() => {
    if (!imagesRef.current) {
      return;
    }

    const count = imagesRef.current
      ? Math.floor((imagesRef.current?.offsetWidth ?? 0) / (minItemWidth + IMAGES_GAP))
      : 0;
    if (entitiesToDisplay.length && count) {
      setRowCountData({
        rowsCount: Math.ceil(entitiesToDisplay.length / count),
        cagesInRowCount: count,
      });
    }
  }, [entitiesToDisplay?.length, imagesRef.current, isCageListLoading]);

  const renderOptionsFromRedux = useSelector(navigatorSelectors.selectRenderOptions);
  const channelNameList = useSelector(
    navigatorSelectors.selectChannelList(currentAppScan?.id ?? '', currentAppLane?.id ?? '')
  );

  const currentRenderSettings = useMemo(() => {
    const channelSettingsList = channelNameList.reduce((acc, channelId) => {
      acc.push(renderOptionsFromRedux[channelId]);

      return acc;
    }, [] as TRenderOption[]);

    return {
      min: channelSettingsList.map(({ range: [min, _] }) => min),
      max: channelSettingsList.map(({ range: [_, max] }) => max),
      color: channelSettingsList.map(({ color }) => color),
      isActive: channelSettingsList.map(({ isActive }) => Number(isActive)),
      isUseLut: channelSettingsList.map(({ isUseLut }) => Number(isUseLut)),
      lutTypeList: channelSettingsList
        .map(({ lutType }) => lutType)
        .concat(Array.from({ length: 8 - channelSettingsList.length }, () => '')),
      isThumbnail: true,
    };
  }, [channelNameList, renderOptionsFromRedux]);

  const renderCagesRow = useCallback(
    (rowIndex: number) => (
      <div>
        <CageListRow
          entitiesToDisplay={currentAppLane && entitiesToDisplay}
          cagesInRowCount={rowCountData.cagesInRowCount}
          rowIndex={rowIndex}
          openModalFactory={openModalFactory}
          currentRenderSettings={currentRenderSettings}
          renderEntityImage={renderEntityImage}
          isHoverActionEnabled={!!isHoverActionEnabled}
        />
      </div>
    ),
    [currentRenderSettings, entitiesToDisplay, currentAppLane, rowCountData.cagesInRowCount]
  );

  if (isCageListLoading) {
    return (
      <div className={cn('loader')}>
        <LoaderProgress isLoading={entitiesToDisplay.length === 0} theme="light" />
      </div>
    );
  }

  if (!entitiesToDisplay.length) {
    return (
      <div className={cn('loader')}>
        <NoDataFound alignment="center" className={cn('loader__no-data')} />
      </div>
    );
  }

  return (
    <div className={cn('images-scroll-area')} id="images-scroll-area">
      {showTitle && (
        <div className={cn('images-scroll-area__header', imagesScrollAreaContainerClassName)}>
          <h3 className={cn('images-scroll-area__title')}>Images</h3>
          <div className={cn('images-scroll-area__label')}>{formatNumber(entitiesToDisplay?.length)}</div>
          <InfoButton
            className={cn('section__button')}
            tooltip={`<div>Number of images is coming from <b>lane ${currentAppLane?.letterName}</b> from <b>the latest scan ${currentAppScan?.time}</b></div>`}
          />
        </div>
      )}
      <div className={cn('images-scroll-area__images-wrapper')} id="images-wrapper">
        <div className={cn('images-scroll-area__images-container')} id="images-container" ref={imagesRef}>
          <VirtualScroll
            className={cn('images-scroll-area__images')}
            minItemHeight={minItemWidth}
            totalLength={rowCountData.rowsCount}
            length={ITEMS_TO_RENDER_BY_SCROLL}
            renderItem={renderCagesRow}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ImagesScrollArea);
