import { getEnum, TEnumValue } from '@/helpers/enum';

const HomeCardLayoutVariants = ['separated', 'union'] as const;
export const HomeCardLayout = getEnum(HomeCardLayoutVariants);

export type THomeCardLayout = TEnumValue<typeof HomeCardLayout>;

type TCounterData = {
  count: number;
  comment?: string;
  isInProcess?: boolean;
};

export type TSeparatedCardData = {
  total: number;
  status: string;
  date?: string;
  current: TCounterData;
  ctaText?: string;
};

export type TUnionCardData = {
  counterList: TCounterData[];
  ctaText?: string;
};

export const isSeparatedCardData = (cardData: Record<string, unknown>): cardData is TSeparatedCardData =>
  !!cardData.total;
