import { FC, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useLinks } from '@/hooks/useLinks';
import { useSelectedDatasets } from '@/hooks/datasetList/useSelectedDatasets';

import { datasetsSelectors, datasetsActions } from '@/store/slices/datasets';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import NoDataFound from '@/components/common/NoDataFound';

import { getNotifyMessage } from './helpers';

import DatasetsOptions from './DatasetsOptions';
import Datasets from './Datasets';
import Assays from './Assays';

import styles from './Data.module.scss';

const cn = classnames.bind(styles);

const Data: FC = () => {
  const dispatch = useDispatch();
  const { generateAnalyticsLink } = useLinks();

  const isDatasetsView = useSelector(datasetsSelectors.selectIsDatasetsView);
  const laneDetailsList = useSelector(datasetsSelectors.selectLaneDetailsList);

  const { selectedDetailsDataList, selectedGroupsCount } = useSelectedDatasets();

  const selectedDatasetsInfo = useMemo(
    () => ({
      groupsCount: selectedGroupsCount,
      datasetsCount: selectedDetailsDataList.length,
    }),
    [laneDetailsList, selectedDetailsDataList, selectedGroupsCount]
  );

  const isSomeDatasetsSelected = useMemo(() => selectedDetailsDataList.length > 0, [selectedDetailsDataList]);

  const notifyMessage = useMemo(() => getNotifyMessage(selectedDatasetsInfo), [selectedDatasetsInfo]);

  const handleClearAllButtonClick = useCallback(() => {
    dispatch(datasetsActions.setAllChannelDetailsUnchecked());
  }, []);

  useEffect(
    () => () => {
      dispatch(datasetsActions.setAllChannelDetailsUnchecked());
    },
    []
  );

  return (
    <div className={cn('data')}>
      {laneDetailsList.length ? (
        <>
          {isDatasetsView && (
            <div className={cn('data__popup', 'popup', { popup_hidden: selectedDetailsDataList.length === 0 })}>
              <div className={cn('popup__container')}>
                <div className={cn('popup__text')}>{notifyMessage}</div>
                {isSomeDatasetsSelected && (
                  <Button color="light" onClick={handleClearAllButtonClick}>
                    <div className={cn('popup__close-icon')}>
                      <icons.CloseIcon />
                    </div>
                  </Button>
                )}
              </div>
              <div className={cn('popup__actions')}>
                <NavLink
                  className={cn('popup__link', {
                    popup__link_disabled: !isSomeDatasetsSelected,
                  })}
                  to={generateAnalyticsLink(selectedDetailsDataList)}
                >
                  Analyze data
                </NavLink>
              </div>
            </div>
          )}
          <DatasetsOptions />
          {isDatasetsView ? <Datasets /> : <Assays />}
        </>
      ) : (
        <NoDataFound size="normal" alignment="center" />
      )}
    </div>
  );
};

export default Data;
