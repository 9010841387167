import { FC, KeyboardEventHandler, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames/bind';
import icons from '@/components/common/icons';
import styles from './Removable.module.scss';

const cn = classNames.bind(styles);

type TRemovableProps = {
  className?: string;
  children: ReactNode;
  onRemove: () => void;
};

const Removable: FC<TRemovableProps> = ({ className, children, onRemove }) => {
  const handleDeleteClick: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    onRemove();
  };

  const handleDeleteKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
  };

  return (
    <div className={cn('removable', className)}>
      <div className={cn('removable__backdrop')}>
        <div
          className={cn('removable__delete')}
          aria-label="delete cell name"
          role="button"
          tabIndex={0}
          onClick={handleDeleteClick}
          onKeyDown={handleDeleteKeyDown}
        >
          <icons.DeleteIcon className={cn('removable__icon')} />
        </div>
      </div>
      {children}
    </div>
  );
};

export default Removable;
