import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import { CSSProperty } from '@/helpers/interfaces';
import { MDASH } from '@/helpers';

import styles from './MemberList.module.scss';

const cn = classnames.bind(styles);

const DEFAULT_MEMBER_ITEM_SIZE = 35;

type TSingleMemberProps = {
  name: string;
  nameList?: never;
  size?: number;
};

type TMultipleMemberProps = {
  name?: never;
  nameList: string[];
  isReverseOrder?: boolean;
  size?: number;
};

type TMemberListProps = TSingleMemberProps | TMultipleMemberProps;

type TMemberProps = {
  initials: string;
};
function isSingleMember(props: TMemberListProps): props is TSingleMemberProps {
  return 'name' in props;
}

const Member: FC<TMemberProps> = ({ initials }) => (
  <div className={cn('member')} data-initials={initials}>
    {initials}
  </div>
);

const MemberList: FC<TMemberListProps> = (props) => {
  const getInitials = (name: string) => {
    const separator = /\s/;
    return name
      .split(separator)
      .slice(0, 2)
      .map((text) => text.at(0))
      .join('')
      .toUpperCase();
  };

  if (isSingleMember(props)) {
    const { name, size } = props;
    const initials = useMemo(() => getInitials(name) || MDASH, [name]);

    return (
      <div
        className={cn('member-list', 'member-list_single')}
        style={
          {
            '--member-size': `${size ?? DEFAULT_MEMBER_ITEM_SIZE}px`,
          } as CSSProperty
        }
      >
        <div className={cn('member-list__member-wrap', 'member-list__member-wrap_single')}>
          <Member initials={initials} />
        </div>
      </div>
    );
  }

  const { nameList, size } = props;
  let { isReverseOrder } = props;
  isReverseOrder = isReverseOrder ?? true;
  const initialsList = useMemo(() => nameList.map(getInitials), [nameList]);

  return (
    <div
      className={cn('member-list', { 'member-list_reverse': isReverseOrder })}
      style={
        {
          '--member-size': `${size ?? DEFAULT_MEMBER_ITEM_SIZE}px`,
        } as CSSProperty
      }
    >
      {initialsList.map((initials, index) => (
        <div key={index} className={cn('member-list__member-wrap')} title={nameList[index]}>
          <Member initials={initials} />
        </div>
      ))}
    </div>
  );
};

export default MemberList;
