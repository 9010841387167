import { toast } from 'react-toastify';

const getMissingPlaceText = (missingLanes: string[], lanesCount: number) => {
  if (missingLanes.length === lanesCount) {
    return 'for this experiment';
  }
  return `for the following lanes in this experiment: ${missingLanes.join(', ')}`;
};

const getNoDatasetFilesLanes = (scansFromServer: TScanFromServer[]) => {
  const noDatasetFilesLanes: Record<string, string[]> = {};
  const expectedFileNames = ['primaryAnalysis', 'summaryTable', 'cageSummaryTable'];
  let lanesCount = 0;
  scansFromServer.forEach((scanFromServer) => {
    scanFromServer.lanes.forEach((laneFromServer) => {
      const { datasetFiles } = laneFromServer.dataset;
      if (!datasetFiles) {
        if (!noDatasetFilesLanes.datasetFiles) {
          noDatasetFilesLanes.datasetFiles = [];
        }
        noDatasetFilesLanes.datasetFiles.push(`${scanFromServer.name}/${laneFromServer.id}`);
      } else {
        expectedFileNames.forEach((fileName) => {
          if (!datasetFiles[fileName as keyof TDatasetFiles]) {
            if (!noDatasetFilesLanes[fileName]) {
              noDatasetFilesLanes[fileName] = [];
            }
            noDatasetFilesLanes[fileName].push(`${scanFromServer.name}/${laneFromServer.id}`);
          }
        });
      }
      lanesCount++;
    });
  });
  return { lanesCount, noDatasetFilesLanes };
};

export const showDatasetFilesErrors = (scansFromServer: TScanFromServer[]) => {
  const { lanesCount, noDatasetFilesLanes } = getNoDatasetFilesLanes(scansFromServer);

  if (noDatasetFilesLanes.datasetFiles) {
    toast.warning(
      <span>
        Features <b>Data analytics</b>, <b>Images</b>, <b>Matching cage locations with Images</b> and{' '}
        <b>Search by global cage ID</b> at the Object and Cage levels not available{' '}
        {getMissingPlaceText(noDatasetFilesLanes.datasetFiles, lanesCount)}.
      </span>
    );
    return;
  }

  if (noDatasetFilesLanes.primaryAnalysis && noDatasetFilesLanes.summaryTable) {
    toast.warning(
      <span>
        Features <b>Data analytics</b>, <b>Images</b>, <b>Matching cage locations with Images</b> and{' '}
        <b>Search by global cage ID</b> at the Object level not available{' '}
        {getMissingPlaceText(noDatasetFilesLanes.primaryAnalysis, lanesCount)}.
      </span>
    );
  }

  if (noDatasetFilesLanes.cageSummaryTable) {
    toast.warning(
      <span>
        Features <b>Data analytics</b>, <b>Images</b>, <b>Matching cage locations with Images</b> and{' '}
        <b>Search by global cage ID</b> at the Cage level not available{' '}
        {getMissingPlaceText(noDatasetFilesLanes.cageSummaryTable, lanesCount)}.
      </span>
    );
  }
};
