import { FC, useEffect, memo } from 'react';

import { getErrorMessage } from '@/helpers/errors';

import { useParamsExperimentId } from '@/hooks';

import { appAPI } from '@/store/services/app';

import { TStandardField } from '@/components/common/StandardTable/types';
import StandardTable from '@/components/common/StandardTable';

import DownloadButton from './DownloadButton';
import FileType from './FileType';

type TDownloadsTabProps = {
  searchQuery: string;
  setIsLoading: (value: boolean) => void;
};

const DOWNLOAD_FILE_TABLE_FIELD_LIST = [
  { name: 'name', label: 'Name', isSortable: true, isSearchable: true },
  { name: 'description', label: 'Description', isSortable: true, isSearchable: true },
  {
    name: 'fileSize',
    label: 'File Size',
    isSortable: true,
    className: 'text-right',
    isBytes: true,
    isFitContent: true,
    arrowPositionMode: 'absolute',
  },
  { name: 'lastModified', label: 'Last Modified', isSortable: true, isFitContent: true, isDateDistance: true },
  {
    name: 'fileType',
    label: 'Type',
    isSortable: true,
    isFitContent: true,
    arrowPositionMode: 'absolute',
    dataComponent: FileType,
  },
  { name: 'download', label: '', isFitContent: true, dataComponent: DownloadButton },
] as TStandardField<TDownloadFile>[];

const DownloadsTab: FC<TDownloadsTabProps> = ({ searchQuery, setIsLoading }) => {
  const experimentId = useParamsExperimentId();

  const {
    isLoading,
    isError,
    error,
    data: downloadDataList = [],
  } = appAPI.useFetchExperimentDownloadsQuery(experimentId);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(
    () => () => {
      setIsLoading(false);
    },
    []
  );

  return (
    <StandardTable<TDownloadFile>
      dataList={downloadDataList}
      fieldList={DOWNLOAD_FILE_TABLE_FIELD_LIST}
      keyFieldName="uuid"
      searchQuery={searchQuery}
      isLoading={isLoading}
      errorMessage={isError ? getErrorMessage(error) : undefined}
    />
  );
};

export default memo(DownloadsTab);
