import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { formatNumber, isNumber, removeDuplicates } from '@/helpers';
import { getDatasetFriendlyName } from '@/helpers/datasetDetails';
import { getUngatedName } from '@/helpers/gates';

import { filterEntitiesByCage, getEntitiesData } from '@/hooks/useExperimentContext/helpers';
import { useLinks } from '@/hooks/useLinks';
import { useGateLabelForCurrentCageImage } from '@/hooks/gates/useGateLabelForCurrentCageImage';

import { gatesSelectors } from '@/store/slices/gates';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import ImagesScrollArea from '@/components/common/ImagesScrollArea';
import Select from '@/components/common/Select';
import LoaderProgress from '@/components/common/LoaderProgress';
import type { TGateList } from '@/components/charts/SingleChartWithGates/types';
import NoDataFound from '@/components/common/NoDataFound';

import GatesAccordionWrapper from '../GatesAccordionWrapper';

import styles from './GateList.module.scss';

const cn = classnames.bind(styles);

type TGateListProps = TGateList;

const GateList: FC<TGateListProps> = ({
  showDatasetsSelect = false,
  isHoverActionEnabled = false,
  scatterPlotAxesOptions,
  currentAppLane,
  entitiesByLanesAndGates,
  entityLevelGateList,
  chartDataList,
  changeSelectedChartData = () => null,
  selectedChartData,
  isGateListLoading,
  deleteGate,
  deleteGateChildren,
  updateGate,
  isUpdateGatesInProgress,
  pageType,
  className,
  specificAxesGroupName,
}) => {
  const { isNavigatorOpen, openCageInspector } = useExperimentModalsContext();
  const { isDatasetPage } = useLinks();
  const chartId = usePlotChartIdContext();

  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const activeGate = useSelector(gatesSelectors.selectActiveGate);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));

  const { currentGateLabelForCageImage } = useGateLabelForCurrentCageImage({
    isObjectEntityEnabled,
    isCageLvlForced,
    selectedGate,
    activeGate,
  });
  const gateCageList = useMemo(() => {
    const onlyGateToDisplay = activeGate ?? selectedGate;
    if (onlyGateToDisplay) {
      // cannot use only cageEntitiesByLanesAndGates here because it's impossible to filter them by object-level gates
      return filterEntitiesByCage(
        getEntitiesData(entitiesByLanesAndGates, currentAppLane?.path, onlyGateToDisplay)?.cageList ?? []
      );
    }

    return filterEntitiesByCage(
      getEntitiesData(entitiesByLanesAndGates, currentAppLane?.path, onlyGateToDisplay)?.cageList ?? []
    );
  }, [currentAppLane, selectedGate, activeGate, entitiesByLanesAndGates]);

  const getGatesByPreprocessing = useCallback(
    (gateList: TGate[] = [], isPreprocessing = false) => {
      const filteredGateList = gateList.filter((gate) => {
        if (!isPreprocessing) {
          return !gate?.properties?.processType;
        }

        const currentScanId = selectedChartData?.scanId ?? currentAppLane?.dataset?.scanId;
        const currentLaneId = selectedChartData?.laneId ?? currentAppLane?.dataset?.laneId;

        const isSameScanAndLane = gate.scanId === currentScanId && gate.laneId === currentLaneId;
        const isGateHasProcessType = !!gate.properties?.processType;

        return isSameScanAndLane && isGateHasProcessType;
      });

      return removeDuplicates<TGate>(filteredGateList, 'shape', 'name', 'xDimension', 'yDimension');
    },
    [currentAppLane, selectedChartData]
  );

  const ungated: Nullable<TGate> = useMemo(() => {
    if (!entityLevelGateList) {
      return null;
    }
    return {
      id: '',
      parentId: '',
      name: getUngatedName(isObjectEntityEnabled && !isCageLvlForced),
      scanId: '',
      laneId: '',
      shape: {
        type: 'polygon',
        model: {
          points: [{ x: 0, y: 0 }],
        },
      },
      xDimension: '',
      yDimension: '',
      level: 0,
      gateNodes: getGatesByPreprocessing(entityLevelGateList),
      properties: {},
    };
  }, [entityLevelGateList, isObjectEntityEnabled, isCageLvlForced, getGatesByPreprocessing]);

  const preprocessed: Nullable<TGate> = useMemo(() => {
    if (!entityLevelGateList) {
      return null;
    }

    return {
      id: '',
      parentId: '',
      name: 'Preprocessing-level',
      scanId: '',
      laneId: '',
      shape: {
        type: 'polygon',
        model: {
          points: [{ x: 0, y: 0 }],
        },
      },
      xDimension: '',
      yDimension: '',
      level: 0,
      gateNodes: getGatesByPreprocessing(entityLevelGateList, true),
      properties: {},
    };
  }, [entityLevelGateList, currentAppLane, getGatesByPreprocessing]);

  const chartDataListOptions = useMemo<TOption[]>(
    () =>
      chartDataList.map((chartData) => ({
        label: getDatasetFriendlyName(chartData),
        value: chartData.id,
      })),
    [chartDataList]
  );

  const openModalFactory = useCallback(
    (entity: TEntity) => () => {
      openCageInspector({ entity, gateName: currentGateLabelForCageImage, entityList: gateCageList });
    },
    [currentGateLabelForCageImage, gateCageList, openCageInspector]
  );

  const handleDatasetSelectChange = (newChartDataId: string) => {
    const newChartData = chartDataList.find((chartData: TDatasetDetails) => chartData.id === newChartDataId);
    if (newChartData) {
      changeSelectedChartData(newChartData);
    }
  };

  const gatesCount = useMemo(() => {
    const preprocessedGatesCount = isNumber(preprocessed?.gateNodes?.length) ? preprocessed.gateNodes.length : 0;
    const defaultGatesCount = isNumber(ungated?.gateNodes.length) ? ungated?.gateNodes.length : 0;

    return preprocessedGatesCount + defaultGatesCount;
  }, [ungated, preprocessed]);

  return (
    <div className={cn('datasets-list', className)} id="datasets-list">
      <Tabs selectedTabClassName={cn('header-item_selected')} className={cn('header__tabs')}>
        <TabList className={cn('datasets-list__header')}>
          <Tab className={cn('header-item')}>
            <div className={cn('header-item__title', 'header-item__title_gates')}>Gates</div>
            {entityLevelGateList && <span className={cn('header-item__number')}>{formatNumber(gatesCount)}</span>}
          </Tab>

          <Tab className={cn('header-item')}>
            <div className={cn('header-item__title', 'header-item__title_images')}>Images</div>
            <span className={cn('header-item__number')}>{formatNumber(gateCageList?.length)}</span>
          </Tab>
        </TabList>
        {showDatasetsSelect && (
          <Select
            theme={themeOptions.light}
            value={selectedChartData?.id}
            defaultValue={selectedChartData?.id}
            onChange={handleDatasetSelectChange}
            containerClassName={cn('select')}
            menuListWrapperClassName={cn('select__option')}
            options={chartDataListOptions}
          />
        )}
        <TabPanel
          className={cn('datasets-list__gates', {
            'datasets-list__gates-loading': isGateListLoading,
            'datasets-list__gates-with-datasets': showDatasetsSelect,
          })}
        >
          {isGateListLoading ? (
            <div className={cn('loader')}>
              <LoaderProgress
                isLoading
                theme="light"
                className={cn({ loader__progress: !isDatasetPage })}
                wrapperClassName={cn({
                  'loader__analysis-wrapper': !isDatasetPage,
                  'loader__dataset-wrapper': isDatasetPage,
                })}
              />
            </div>
          ) : (
            !!entityLevelGateList && (
              <div className={cn('datasets-list__gates-items')}>
                {!!preprocessed?.gateNodes?.length && (
                  <GatesAccordionWrapper
                    gate={preprocessed}
                    scatterPlotAxesOptions={scatterPlotAxesOptions}
                    currentAppLane={currentAppLane}
                    deleteGate={deleteGate}
                    deleteGateChildren={deleteGateChildren}
                    entitiesByLanesAndGates={entitiesByLanesAndGates}
                    entityLevelGateList={entityLevelGateList}
                    isUpdateGatesInProgress={isUpdateGatesInProgress}
                    updateGate={updateGate}
                    pageType={pageType}
                    specificAxesGroupName={specificAxesGroupName}
                  />
                )}
                {!!ungated?.gateNodes?.length && (
                  <GatesAccordionWrapper
                    gate={ungated}
                    scatterPlotAxesOptions={scatterPlotAxesOptions}
                    currentAppLane={currentAppLane}
                    deleteGate={deleteGate}
                    deleteGateChildren={deleteGateChildren}
                    entitiesByLanesAndGates={entitiesByLanesAndGates}
                    entityLevelGateList={entityLevelGateList}
                    isUpdateGatesInProgress={isUpdateGatesInProgress}
                    updateGate={updateGate}
                    pageType={pageType}
                    specificAxesGroupName={specificAxesGroupName}
                  />
                )}
                {!ungated?.gateNodes?.length && !preprocessed?.gateNodes?.length && (
                  <NoDataFound textData="No gates had been created yet" className={cn('datasets-list__text')} />
                )}
              </div>
            )
          )}
        </TabPanel>
        <TabPanel className={cn('images-list')}>
          {isNavigatorOpen && <Skeleton className={cn('skeleton')} />}
          {!isNavigatorOpen && (
            <>
              <div className={cn('selected-gate')}>{currentGateLabelForCageImage}</div>
              <ImagesScrollArea
                openModalFactory={openModalFactory}
                entitiesToDisplay={gateCageList}
                showTitle={false}
                imagesScrollAreaContainerClassName={cn('header-item__images-container')}
                isHoverActionEnabled={isHoverActionEnabled}
              />
            </>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default GateList;
