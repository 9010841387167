import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ECdnObjectType } from '@/types/cdnData';

import { TSequencingSummarySchema, genFromServerSchema } from '@/validationSchemas';

import { generateJwtToken, repeatKeys } from '@/helpers';
import { parseCdnTable } from '@/helpers/tables-parsers';
import { getCdnTableDataFileName } from '@/helpers/cdnData/fetch';

import type { RootState } from '@/store';

import { fetchData, fetchDataFromArchive, getErrorFromHtml } from './helpers';
import { transformCellReadsStatsResponse, transformSequencingDataSummaryResponse } from './dataProvider';

import type { TLoadStatusType } from '../../slices/violin';

export const cdnAPI = createApi({
  reducerPath: 'cdnAPI',
  keepUnusedDataFor: 1800, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CDN_URL,
    credentials: 'include',
    responseHandler: 'text',
  }),
  tagTypes: ['Cookies', ECdnObjectType.objectEntity, ECdnObjectType.cageEntity],
  endpoints: (build) => ({
    fetchExperimentCookies: build.query<unknown, string>({
      queryFn: (experimentId, _queryApi, _extraOptions, _fetchWithBQ) =>
        new Promise((resolve) => {
          if (!experimentId) {
            resolve({
              error: { error: 'An experimentId is required to fetch experiment cookies.', status: 'CUSTOM_ERROR' },
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorHandler = (error: any) => {
            resolve({ error });
          };
          generateJwtToken().then((token) => {
            fetch(`${process.env.REACT_APP_CDN_URL}/api/experiments/${experimentId}`, {
              credentials: 'include',
              headers: { Authorization: `Bearer ${token}` },
            }).then((result) => {
              if (result.ok) {
                result.text().then((text) => {
                  resolve({ data: text });
                }, errorHandler);
              } else {
                result.json().then((json) => {
                  resolve({ error: { status: result.status, data: json } });
                }, errorHandler);
              }
            }, errorHandler);
          }, errorHandler);
        }),
      providesTags: ['Cookies'],
    }),
    fetchObjectEntityList: build.query<TObjectEntity[], Nullable<TLane>>({
      queryFn(lane, { getState }) {
        return new Promise((resolve) => {
          if (!lane?.path) {
            resolve({ error: { error: 'A lane path is required to fetch objects.', status: 'CUSTOM_ERROR' } });
            return;
          }
          const { useOptimizedData } = (getState() as RootState).viewer;
          const { fileName, isArchive } = getCdnTableDataFileName({
            lane,
            type: ECdnObjectType.objectEntity,
            useOptimizedData,
          });
          if (!fileName) {
            resolve({
              error: { error: 'File primaryAnalysis not available. Please contact support.', status: 'CUSTOM_ERROR' },
            });
            return;
          }
          if (isArchive) {
            fetchDataFromArchive(lane, ECdnObjectType.objectEntity, fileName)
              .then((dataFromArchive) => {
                resolve(dataFromArchive);
              })
              .catch(() => {
                resolve(fetchData(lane, ECdnObjectType.objectEntity, lane.datasetFiles.primaryAnalysis.original));
              });
          } else {
            resolve(fetchData(lane, ECdnObjectType.objectEntity, fileName));
          }
        });
      },
      serializeQueryArgs: ({ queryArgs }) => `fetchObjectEntityList("${queryArgs?.path ?? ''}")`,
      providesTags: [ECdnObjectType.objectEntity],
    }),
    fetchCageEntityList: build.query<TObjectEntity[], Nullable<TLane>>({
      queryFn(lane, { getState }) {
        return new Promise((resolve) => {
          if (!lane?.path) {
            resolve({ error: { error: 'A lane path is required to fetch cages.', status: 'CUSTOM_ERROR' } });
            return;
          }
          const { useOptimizedData } = (getState() as RootState).viewer;
          const { fileName, isArchive } = getCdnTableDataFileName({
            lane,
            type: ECdnObjectType.cageEntity,
            useOptimizedData,
          });
          if (!fileName) {
            resolve({
              error: {
                error: 'File cageSummaryTable not available. Please contact support.',
                status: 'CUSTOM_ERROR',
              },
            });
            return;
          }
          if (isArchive) {
            fetchDataFromArchive(lane, ECdnObjectType.cageEntity, fileName, false)
              .then((dataFromArchive) => {
                resolve(dataFromArchive);
              })
              .catch(() => {
                resolve(fetchData(lane, ECdnObjectType.cageEntity, lane.datasetFiles.cageSummaryTable.original, false));
              });
          } else {
            resolve(fetchData(lane, ECdnObjectType.cageEntity, fileName, false));
          }
        });
      },
      serializeQueryArgs: ({ queryArgs }) => `fetchCageEntityList("${queryArgs?.path ?? ''}")`,
      providesTags: (result, error, lane) => [{ type: ECdnObjectType.cageEntity, id: lane?.path }],
    }),
    fetchGeneCounts: build.query<
      TGensCount & { id: string; loadStatus: TLoadStatusType },
      { laneId: string; geneName: string; experimentCloudPath: string; id: string }
    >({
      queryFn: async (input) => {
        const { experimentCloudPath, geneName, laneId, id } = input;
        const urlString = `${process.env.REACT_APP_CDN_URL}/${experimentCloudPath}/gene_counts/${laneId}/${geneName}.csv`;
        const firstHeader = 'global.cage.id.matched';
        const countHeader = 'count';

        const gensDataPromise = new Promise<Record<string, number>>((resolve, reject) => {
          fetch(`${urlString}`, {
            credentials: 'include',
            cache: 'no-store',
          })
            .then((response) =>
              response.ok ? response.text() : response.text().then((text) => Promise.reject(getErrorFromHtml(text)))
            )
            .then((text) => {
              const parsedData = parseCdnTable({ text, schema: genFromServerSchema });
              const resData = parsedData.reduce((acc: Record<string, number>, el) => {
                if (el[firstHeader] && countHeader in el) {
                  acc[el[firstHeader]] = Number(el[countHeader] || 0);
                }

                return acc;
              }, {});
              resolve(resData);
            })
            .catch(reject);
        });

        try {
          const gensData = repeatKeys(await gensDataPromise);
          const loadStatus: TLoadStatusType = gensData.length ? 'loaded' : 'empty';
          return {
            data: {
              laneId,
              geneName,
              experimentCloudPath,
              gensData,
              id,
              loadStatus,
            },
          };
        } catch {
          return {
            data: {
              laneId,
              geneName,
              experimentCloudPath,
              gensData: [],
              id,
              loadStatus: 'error',
            },
          };
        }
      },
    }),

    fetchSequencingDataCellReadsStats: build.query<TCellReadStats[], string>({
      query: (path) => ({
        url: `${process.env.REACT_APP_CDN_URL}/${path}`,
      }),
      transformResponse: transformCellReadsStatsResponse,
    }),

    fetchSequencingDataSummaryInfo: build.query<TSequencingSummarySchema | undefined, string>({
      query: (path) => ({
        url: `${process.env.REACT_APP_CDN_URL}/${path}`,
      }),
      transformResponse: transformSequencingDataSummaryResponse,
    }),
  }),
});
