import { ChangeEvent, FC, FormEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuthenticator, Button as AwsButton, TextField, Text, Flex, Loader } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import classnames from 'classnames/bind';

import { TOTPInputRegex, TOTPResultRegex } from '@/helpers/validator';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import Modal from '@/components/common/Modal';
import { QRCodeCanvas } from 'qrcode.react';

import styles from './WithTOTP.module.scss';

const cn = classnames.bind(styles);

export type TSetupTOTPModalProps = {
  isModalOpen: boolean;
  closeModal?: () => void;
  onSkip?: () => void;
  onComplete?: () => Promise<void> | void;
  isRequired?: boolean;
  isSkipped?: boolean;
};

const SetupTOTPModal: FC<TSetupTOTPModalProps> = ({
  closeModal,
  isModalOpen,
  onComplete,
  isRequired,
  onSkip,
  isSkipped,
}) => {
  const { user } = useAuthenticator();
  const [otp, setOTP] = useState('');
  const [qrCodeStr, setQRCodeStr] = useState('');
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const otpRef = useRef<HTMLInputElement>(null);

  const generateQRCode = useCallback(async () => {
    try {
      const secretCode = await Auth.setupTOTP(user);
      const qrString = `otpauth://totp/AWSCognito:${
        user.attributes?.name ?? user.attributes?.email
      }?secret=${secretCode}&issuer=${process.env.REACT_APP_AMPLIFY_APP_NAME}`;
      setQRCodeStr(qrString);
    } catch {
      /* empty */
    }
  }, [user]);

  const verifyTOTP = useCallback(async () => {
    setIsConfirmLoading(true);
    try {
      const result = await Auth.verifyTotpToken(user, otp);

      // @ts-ignore wrong type in node-modules
      if (result.Status === 'SUCCESS') {
        if (onComplete) {
          await onComplete();
          return;
        }
        await Auth.setPreferredMFA(user, 'TOTP');
        toast.success('MFA with TOTP was successfully added');
        Auth.signOut();
      }
    } catch (err) {
      showErrorToast(getErrorMessage(err));
    } finally {
      setIsConfirmLoading(false);
    }
  }, [otp, user, onComplete]);

  const backToSignIn = useCallback(() => {
    Auth.signOut();
  }, []);

  const skipMFA = useCallback(() => {
    onSkip?.();
  }, [onSkip]);

  const handleChangeOTP = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (TOTPInputRegex.test(newValue)) {
      setOTP(newValue);
    }
  }, []);

  const isConfirmDisabled = useMemo(() => !TOTPResultRegex.test(otp), [otp]);

  useEffect(() => {
    if (isModalOpen) {
      generateQRCode();
    }
  }, [isModalOpen]);

  useEffect(() => {
    otpRef.current?.focus();
  }, [qrCodeStr]);

  const onFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      verifyTOTP();
    },
    [verifyTOTP]
  );

  return (
    <Modal isOpen={isModalOpen} onRequestClose={isRequired ? undefined : closeModal} className={cn('totp-modal')}>
      <Modal.Header className={cn('totp-modal__header')} withDivide={false} />
      <Modal.Content className={cn('totp-modal__content')}>
        {qrCodeStr ? (
          <form className={cn('totp-modal__form')} onSubmit={onFormSubmit}>
            <Text>Please setup MFA</Text>
            <Text>Scan this QR code and enter code below</Text>
            <QRCodeCanvas value={qrCodeStr} size={200} />
            <TextField
              ref={otpRef}
              type="text"
              variation="quiet"
              textAlign="center"
              label=""
              onChange={handleChangeOTP}
              value={otp}
            />
            <AwsButton variation="primary" type="submit" isFullWidth isDisabled={isConfirmDisabled || isConfirmLoading}>
              <Flex columnGap={10} alignItems="center">
                {isConfirmLoading && <Loader />}
                {isConfirmLoading ? 'Confirming' : 'Confirm'}
              </Flex>
            </AwsButton>
          </form>
        ) : (
          <Flex height="100%" alignItems="center" justifyContent="center">
            <Loader size="large" />
          </Flex>
        )}
      </Modal.Content>
      <Modal.Footer className={cn('totp-modal__footer')}>
        {isRequired && (
          <Flex width="100%">
            <AwsButton size="small" variation="link" isFullWidth onClick={backToSignIn}>
              Back to Sign In
            </AwsButton>
            {isSkipped && (
              <AwsButton size="small" variation="link" isFullWidth onClick={skipMFA}>
                Skip
              </AwsButton>
            )}
          </Flex>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default memo(SetupTOTPModal);
