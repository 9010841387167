import { FC, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import Popover, { TPopoverOption } from '@/components/common/Popover';

import styles from './ContextMenuPopoverWrapper.module.scss';

const cn = classnames.bind(styles);

const POPOVER_WIDTH = 220;

type TContextMenuPopoverWrapperProps = {
  options: TPopoverOption[];
  activeElementId: string;
  children: ReactNode;
};

const ContextMenuPopoverWrapper: FC<TContextMenuPopoverWrapperProps> = ({ options, activeElementId, children }) => {
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [popoverCoords, setPopoverCoords] = useState({ x: 0, y: 0 });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (options.length > 0) {
      setIsPopoverOpened((prevState) => !prevState);
    }
  };

  const handlePopoverClick = () => {
    setIsPopoverOpened((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation();
      const target = event.target as Node;
      const activeShape = document.getElementById(activeElementId);

      const SECONDARY_BUTTON_TYPE = 2;
      if (event.button === SECONDARY_BUTTON_TYPE && activeShape?.contains(target)) {
        setPopoverCoords({
          x:
            event.clientX -
            activeShape.getBoundingClientRect().left -
            activeShape.getBoundingClientRect().width +
            POPOVER_WIDTH,
          y: event.clientY - activeShape.getBoundingClientRect().top,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [options.length]);

  return (
    <div onContextMenu={handleContextMenu}>
      <Popover
        onClick={handlePopoverClick}
        isOpen={isPopoverOpened}
        options={options}
        setIsOpen={setIsPopoverOpened}
        contentClassName={cn('popover')}
        contentId="popover"
        styles={{ top: `${popoverCoords.y}px`, left: `${popoverCoords.x}px` }}
      />
      {children}
    </div>
  );
};

export default ContextMenuPopoverWrapper;
