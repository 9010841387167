import { SVGProps } from 'react';

const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 6L6 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M20 6L15 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 11L1 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15 11L20 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ExpandIcon;
