export const isReadsEmpty = (
  reads: {
    read1: string;
    read2: string;
  }[]
) => {
  if (!reads.length) return true;

  return reads.some((item) => !(item.read1.trim() && item.read2.trim()));
};

export const isReadsHasChanges = (
  selectedReads: {
    read1: string;
    read2: string;
  }[],
  readsFromServer: {
    read1: string;
    read2: string;
  }[]
) => {
  const isSelectedReadsEmpty = isReadsEmpty(selectedReads);
  const isReadsFromServerEmpty = isReadsEmpty(readsFromServer);

  if (isSelectedReadsEmpty && isReadsFromServerEmpty) return false;

  return selectedReads.some(
    (item, index) => item.read1 !== readsFromServer?.[index]?.read1 || item.read2 !== readsFromServer?.[index]?.read2
  );
};
