import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Highlighter from 'react-highlight-words';
import { API as awsAPI } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { useUserRole } from '@/hooks';

import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import {
  JobDefinition,
  JobRunStatus,
  JobRunStatusChangedEventInput,
  JobRunStatusChangedSubscription,
} from '@/graphql/API';
import * as mutations from '@/graphql/mutations';
import * as subscriptions from '@/graphql/subscriptions';

import Panel from '@/components/common/Panel';
import icons from '@/components/common/icons';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import DateDistance from '@/components/common/DateDistance';
import DetailsButton from '@/components/common/DetailsButton';
import Popover from '@/components/common/Popover';

import JobRunLogs from './JobRunLogs';
import JobRunStatusBadge from './JobRunStatusBadge';

import styles from '../JobRunList.module.scss';

const cn = classnames.bind(styles);

export type TJobRunDetails = {
  organizationId: string;
  experimentId?: string;
  laneId?: string;
  id: string;
  name: string;
  status: JobRunStatus;
  createdAt: string;
  jobDefinition?: JobDefinition;
  logs: { text: string; isOpen: boolean };
  containerOverrides?: object | null;
};

type TJobListItemProps = {
  jobRunDetails: TJobRunDetails;
  searchQuery: string;
  refetchData?: () => void;
  toggleJobRunLogsOpen: (jobRunId: string) => void;
  setJobRunLogsText: (jobRunId: string, text: string) => void;
  setJobRunStatus: (jobRunId: string, status: JobRunStatus) => void;
};

const JobRunListItem: FC<TJobListItemProps> = ({
  jobRunDetails,
  searchQuery,
  refetchData = () => null,
  toggleJobRunLogsOpen,
  setJobRunLogsText,
  setJobRunStatus,
}) => {
  const confirmationModal = useConfirmationModalContext();

  const jobRunElementId = useMemo(() => `panel__job_run_${jobRunDetails.id}`, [jobRunDetails]);
  const panelRef = useRef<HTMLDivElement>(null);

  const { isJobRunCreateAllowed, isJobRunDeleteAllowed } = useUserRole();

  const isJobRunCancelAllowed = useMemo(
    () =>
      isJobRunDeleteAllowed &&
      ([JobRunStatus.PENDING, JobRunStatus.RUNNING, JobRunStatus.ENQUEUED] as JobRunStatus[]).includes(
        jobRunDetails.status
      ),
    [isJobRunDeleteAllowed, jobRunDetails.status]
  );

  const isJobRunRestartAllowed = useMemo(
    () =>
      isJobRunCreateAllowed &&
      ([JobRunStatus.SUCCEEDED, JobRunStatus.FAILED, JobRunStatus.CANCELLED] as JobRunStatus[]).includes(
        jobRunDetails.status
      ),
    [isJobRunCreateAllowed, jobRunDetails.status]
  );

  const isSubscriptionRequired = useMemo(
    () =>
      (
        [
          JobRunStatus.PENDING,
          JobRunStatus.RUNNING,
          JobRunStatus.ENQUEUED,
          JobRunStatus.CANCELLING,
          JobRunStatus.TERMINATING,
        ] as JobRunStatus[]
      ).includes(jobRunDetails.status),
    [jobRunDetails.status]
  );

  const handleCancelJobRunClick = async () => {
    const isCancelConfirmed = await confirmationModal.onOpen({
      confirmationText: `Are you sure you want to cancel the "${jobRunDetails.name}" job run?`,
      approveButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (isCancelConfirmed) {
      try {
        const response = await awsAPI.graphql({
          query: mutations.cancelJobRun,
          variables: { id: jobRunDetails.id, reason: 'Requested by user through Cloud Platform' },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        // @ts-ignore
        if (response?.data?.cancelJobRun) {
          refetchData();
          toast.success(`The "${jobRunDetails.name}" job run has been canceled`);
        }
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    }
  };

  const handleRestartJobRunClick = async () => {
    try {
      const response = await awsAPI.graphql({
        query: mutations.rerunJobRun,
        variables: {
          id: jobRunDetails.id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      // @ts-ignore
      if (response?.data?.rerunJobRun) {
        refetchData();
        toast.success(`The "${jobRunDetails.name}" job run has been enqueued`);
      }
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  };

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const contextMenuOptionList = useMemo(() => {
    const list = [];
    if (isJobRunCancelAllowed) {
      list.push({
        id: 'cancel-job-run',
        title: 'Cancel job run',
        icon: <icons.DetachIcon />,
        onClick: handleCancelJobRunClick,
      });
    }
    if (isJobRunRestartAllowed) {
      list.push({
        id: 'restart-job-run',
        title: 'Restart job run',
        icon: <icons.PlayIcon />,
        onClick: handleRestartJobRunClick,
      });
    }
    return list;
  }, [handleCancelJobRunClick]);

  const eventsSubscription = useRef<Nullable<GraphQLSubscription<JobRunStatusChangedSubscription>>>(null);

  const subscribe = async () => {
    eventsSubscription.current = await awsAPI
      .graphql<GraphQLSubscription<JobRunStatusChangedSubscription>>({
        query: subscriptions.jobRunStatusChanged,
        variables: { organizationId: jobRunDetails.organizationId, jobRunId: jobRunDetails.id },
      })
      // @ts-ignore
      .subscribe({
        next: ({
          provider: _,
          value,
        }: {
          provider: unknown;
          value: { data: { jobRunStatusChanged: JobRunStatusChangedEventInput } };
        }) => {
          if (value.data.jobRunStatusChanged) {
            setJobRunStatus(value.data.jobRunStatusChanged.jobRunId, value.data.jobRunStatusChanged.payload.status);
          }
        },
        error: ({ provider: _, error }: { provider: unknown; error: unknown }) => {
          showErrorToast(getErrorMessage(error));
        },
      });
  };

  const handleDetailsButtonClick = () => {
    toggleJobRunLogsOpen(jobRunDetails.id);
  };

  const handlePopoverClick = () => {
    setIsPopoverOpened((prev) => !prev);
  };

  useEffect(() => {
    if (isSubscriptionRequired && !eventsSubscription.current) {
      subscribe();
    }
    return () => {
      if (eventsSubscription.current) {
        // @ts-ignore
        eventsSubscription.current.unsubscribe();
        eventsSubscription.current = null;
      }
    };
  }, [isSubscriptionRequired]);

  return (
    <Panel key={jobRunDetails.id} theme={themeOptions.light} className={cn('job-item')} id={jobRunElementId}>
      <Panel.Content className={cn('job-item__content', 'render-content-animation')}>
        <div className={cn('job-item__group')}>
          <h2 className={cn('job-item__name')}>
            <Highlighter textToHighlight={jobRunDetails.name} searchWords={formatHighlightSearchQuery(searchQuery)} />
          </h2>
          <div className={cn('job-item__id')}>
            <Highlighter textToHighlight={jobRunDetails.id} searchWords={formatHighlightSearchQuery(searchQuery)} />
          </div>
        </div>
        <div className={cn('job-item__group')}>
          <Highlighter
            textToHighlight={jobRunDetails.jobDefinition?.name ?? ''}
            searchWords={formatHighlightSearchQuery(searchQuery)}
          />
        </div>
        <div className={cn('job-item__group')}>
          <div className={cn('job-item__group-data')}>
            <div className={cn('job-item__group-data-label')}>Status</div>
            <JobRunStatusBadge status={jobRunDetails.status} />
          </div>
          <div className={cn('job-item__group-data')}>
            <div className={cn('job-item__group-data-label')}>Created at</div>
            <div>
              <DateDistance date={jobRunDetails.createdAt} />
            </div>
          </div>
        </div>
        <div className={cn('job-item__group', 'job-item__group_controls')} ref={panelRef}>
          {contextMenuOptionList.length > 0 && (
            <Popover
              onClick={handlePopoverClick}
              isOpen={isPopoverOpened}
              options={contextMenuOptionList}
              setIsOpen={setIsPopoverOpened}
              padding={-32}
              parentElement={panelRef.current ?? undefined}
              useBackdrop={false}
            >
              <button className={cn('job-item__button')} aria-label="expand">
                <icons.DotIcon />
              </button>
            </Popover>
          )}
          <DetailsButton
            isOpen={jobRunDetails.logs.isOpen}
            onClick={handleDetailsButtonClick}
            tooltip={jobRunDetails.logs.isOpen ? 'Hide logs' : 'Show logs'}
          />
        </div>
      </Panel.Content>
      <Panel.Description className={cn('job-item__logs')} withDivide={false}>
        {jobRunDetails.logs.isOpen && (
          <JobRunLogs
            jobRunId={jobRunDetails.id}
            logsText={jobRunDetails.logs.text}
            setJobRunLogsText={setJobRunLogsText}
          />
        )}
      </Panel.Description>
    </Panel>
  );
};

export default JobRunListItem;
