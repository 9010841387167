import { TAppEndpointBuilder, TFetchProjectsQuery } from '@/store/services/app/types';
import { transformProjectListResponse } from '@/store/services/app/dataProvider';
import { getDateBefore } from '@/helpers';

export const projectEndpoints = (build: TAppEndpointBuilder) => ({
  fetchProjectList: build.query<TProject[], TFetchProjectsQuery>({
    query: ({ collaborators, owner, time = '' }) => ({
      url: `/projects`,
      method: 'GET',
      params: {
        collaborators,
        owner,
        projectDateFrom: getDateBefore(time),
      },
    }),
    transformResponse: (response: TProject[]) => transformProjectListResponse(response),
    providesTags: ['Project'],
  }),
  fetchProject: build.query<TProject, string>({
    query: (id) => ({
      url: `/projects/${id}`,
      method: 'GET',
    }),
    providesTags: (result, error, id) => [{ type: 'Project', id }],
  }),
  createProject: build.mutation<TProject, Partial<TProject>>({
    query: (projectData) => ({
      url: `/projects`,
      method: 'POST',
      body: projectData,
    }),
    invalidatesTags: ['Project'],
  }),
  getExperimentsByProjectId: build.query<TExperiment[], string>({
    query: (id) => ({
      url: `/projects/${id}/experiments`,
      method: 'GET',
    }),
    providesTags: ['Project'],
  }),
  attachExperimentToProject: build.mutation<unknown, { id: string; ids: string[] }>({
    query: ({ id, ids }) => ({
      url: `/projects/${id}/experiments/attach`,
      method: 'POST',
      body: ids,
    }),
    invalidatesTags: ['Project'],
  }),
  detachExperimentToProject: build.mutation<unknown, { id: string; ids: string[] }>({
    query: ({ id, ids }) => ({
      url: `/projects/${id}/experiments/detach`,
      method: 'POST',
      body: ids,
    }),
    invalidatesTags: ['Project'],
  }),
});
