import { createContext, FC, memo, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import Modal from '@/components/common/Modal';
import classnames from 'classnames/bind';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { useOpenModal } from '@/hooks/useOpenModal';
import styles from './ConfirmationModal.module.scss';

const cn = classnames.bind(styles);

type TContent = {
  confirmationText: ReactNode;
  approveButtonText: string;
  cancelButtonText?: string;
  modalChildren?: ReactNode;
};

type TConfirmationModalContext = {
  onOpen: ({ confirmationText, approveButtonText, cancelButtonText, modalChildren }: TContent) => Promise<boolean>;
};

type TConfirmationModalProviderProps = {
  children: ReactNode;
};

const ConfirmationModalContext = createContext<TConfirmationModalContext>({} as TConfirmationModalContext);

const ConfirmationModalProvider: FC<TConfirmationModalProviderProps> = ({ children }) => {
  const { isOpen, setIsOpen, onClose } = useOpenModal();
  const [content, setContent] = useState<Nullable<TContent>>();

  const resolver = useRef<(_: boolean) => void>();

  const handleOpenModal = ({
    confirmationText,
    approveButtonText,
    cancelButtonText,
    modalChildren,
  }: TContent): Promise<boolean> => {
    setContent({
      confirmationText,
      approveButtonText,
      cancelButtonText,
      modalChildren,
    });

    setIsOpen(true);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const modalContext: TConfirmationModalContext = useMemo(
    () => ({
      onOpen: handleOpenModal,
    }),
    [handleOpenModal]
  );

  const handleApprove = () => {
    if (resolver.current) {
      resolver.current(true);
    }

    onClose();
  };

  const handleCancel = () => {
    if (resolver.current) {
      resolver.current(false);
    }

    onClose();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {children}
      <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick className={cn('confirmation-modal')}>
        <Modal.Header onRequestClose={onClose} withDivide={false} className={cn('confirmation-modal__header')}>
          <div />
        </Modal.Header>
        <Modal.Content className={cn('confirmation-modal__content')}>
          <div className={cn('confirmation-modal__attention')}>
            <icons.AttentionIcon className={cn('confirmation-modal__attention-icon')} />
            <span className={cn('confirmation-modal__content-text')}>{content?.confirmationText}</span>
          </div>
          {content?.modalChildren}
        </Modal.Content>
        <Modal.Footer className={cn('confirmation-modal__footer')} withDivide>
          <Button onClick={handleCancel} color="white" className={cn('confirmation-modal__button')} isFitContent>
            {content?.cancelButtonText ?? 'Cancel'}
          </Button>
          <Button onClick={handleApprove} color="yellow" className={cn('confirmation-modal__button')} isFitContent>
            {content?.approveButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};
const useConfirmationModalContext = (): TConfirmationModalContext => useContext(ConfirmationModalContext);

export { useConfirmationModalContext };
export default memo(ConfirmationModalProvider);
