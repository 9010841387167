import { FC } from 'react';
import classNames from 'classnames/bind';
import Button from '@/components/common/Button';
import styles from './FooterBlock.module.scss';

const cn = classNames.bind(styles);

type TFooterBlockProps = {
  onSave: () => void;
  onCancel: () => void;
};

const FooterBlock: FC<TFooterBlockProps> = ({ onSave, onCancel }) => (
  <div className={cn('footer-block')}>
    <div className={cn('footer-block__controls')}>
      <Button
        color="light"
        className={cn('footer-block__button', 'footer-block__button_close')}
        onClick={onCancel}
        isOutlined
      >
        Close
      </Button>
      <Button color="yellow" className={cn('footer-block__button', 'footer-block__button_save')} onClick={onSave}>
        Save
      </Button>
    </div>
  </div>
);

export default FooterBlock;
