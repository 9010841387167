import { SVGProps } from 'react';

const AssayCellProliferation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="21" viewBox="0 0 27 21" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path
        d="m23.616 1.285-6.942 4.628a3 3 0 0 0-1.336 2.496v10.876h0M3.381 1.285l6.942 4.628A3 3 0 0 1 11.66 8.41v10.876h0"
        strokeLinejoin="round"
      />
      <path d="m1.53 4.555 6.437 4.157M25.442 4.555l-6.436 4.157" />
    </g>
  </svg>
);

export default AssayCellProliferation;
