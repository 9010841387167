import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ApiEndpointQuery } from '@reduxjs/toolkit/query';

import { useDebounce } from '../useDebounce';

import { TFetchTokenResult } from './types';

type TUseTeamUserList = {
  queryData?: Record<string, string | number | undefined>;
  select: ApiEndpointQuery<any, any>['select'];
  useFetch: (arg: any) => Record<string, any>;
};

const useFetchNextToken = <T>({ queryData = {}, select, useFetch }: TUseTeamUserList): TFetchTokenResult<T> => {
  const [requestParamList, setRequestParamList] = useState<Record<string, string | number | undefined>>(queryData);
  const deps = useMemo(() => Object.values(queryData), [queryData]);

  const selector = useMemo(() => select(requestParamList), [requestParamList]);

  const { data } = useSelector(selector) as { data: { list: T[]; nextToken?: string } };

  const { isFetching, isLoading, isError, error } = useFetch(requestParamList);

  const nextToken = useMemo(() => data?.nextToken, [data]);
  const list = useMemo(() => data?.list ?? [], [data]);
  const count = useMemo(() => list.length, [list]);
  const isListLoading = useMemo(() => isLoading || isFetching, [isLoading, isFetching]);
  const isListLoadingDebounced = useDebounce(isListLoading);

  useEffect(() => {
    const newRequestParamList = { ...requestParamList, nextToken, ...queryData };
    if (!shallowEqual(requestParamList, newRequestParamList)) {
      setRequestParamList((prev) => ({ ...prev, ...queryData, nextToken }));
    }
  }, [...deps, nextToken, queryData]);

  return useMemo<TFetchTokenResult<T>>(
    () => ({
      count,
      isListLoading,
      isListLoadingDebounced,
      list,
      error,
      isError,
    }),
    [count, isListLoading, isListLoadingDebounced, list, error, isError]
  );
};

export default useFetchNextToken;
