import { FC, MutableRefObject } from 'react';
import classnames from 'classnames/bind';
import FullScreen from './Fullscreen';
import ExpandToggle from './ExpandToggle';
import RatioToggle from './RatioToggle';
import styles from './ResizeControl.module.scss';
import Snapshot from './Snapshot';

const cn = classnames.bind(styles);

type TResizeControlProps = {
  canvasRef: MutableRefObject<Nullable<HTMLCanvasElement>>;
  screenState: {
    changeFullScreen: () => void;
    changeExpandMode: () => void;
    changeScreenRatio: () => void;
    screenStateModifiers: Record<string, boolean>;
    rerenderWebglCanvas: () => void;
    coordinatesForImageName: string;
  };
};

const ResizeControl: FC<TResizeControlProps> = ({
  canvasRef,
  screenState: {
    changeFullScreen,
    changeExpandMode,
    changeScreenRatio,
    screenStateModifiers,
    coordinatesForImageName,
    rerenderWebglCanvas,
  },
}) => (
  <div className={cn('resize-control')}>
    <ul className={cn('resize-control__controls-wrap')}>
      <li className={cn('resize-control__controls-elem')}>
        <FullScreen
          changeFullScreen={changeFullScreen}
          isFullScreen={screenStateModifiers.fullscreen}
          className={cn('resize-control__square-btn')}
        />
      </li>
      <li className={cn('resize-control__controls-elem')}>
        <ExpandToggle
          changeFullScreen={changeExpandMode}
          isExpandView={screenStateModifiers.expand}
          className={cn('resize-control__square-btn')}
        />
      </li>
      <li className={cn('resize-control__controls-elem')}>
        <RatioToggle
          isSquareScreen={screenStateModifiers.square}
          changeRatio={changeScreenRatio}
          className={cn('resize-control__square-btn')}
        />
      </li>
      <li className={cn('resize-control__controls-elem')}>
        <Snapshot
          canvasRef={canvasRef}
          rerenderWebglCanvas={rerenderWebglCanvas}
          coordinatesForImageName={coordinatesForImageName}
          className={cn('resize-control__square-btn')}
        />
      </li>
    </ul>
  </div>
);

export default ResizeControl;
