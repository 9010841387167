import { findLaneInScanList } from '@/helpers/scans';
import { downloadByUrl } from '@/helpers';

import { TProcessingResultPayload } from '@/store/slices/gatesAutoPreprocessing';

import { TPopoverOption } from '@/components/common/Popover';

export const getProcessingResultOptionList = (preprocessingResult: TProcessingResultPayload[], scanList: TScan[]) =>
  preprocessingResult
    .map((payload) => {
      const lane = findLaneInScanList(scanList, payload.scanId, payload.laneId);
      if (lane) {
        const fileName = payload.s3FileName;
        const url = `${process.env.REACT_APP_CDN_URL}/${lane?.path}/${fileName}.csv?v=${new Date().getTime()}`;
        return {
          id: lane.dataset.name,
          title: `${fileName} (${lane.dataset.friendlyName})`,
          onClick: () => {
            downloadByUrl(url, `${fileName} (${lane.dataset.friendlyName}).csv`);
          },
        };
      }
      return null;
    })
    .filter((option) => option !== null) as TPopoverOption[];
