import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import styles from './PreprocessingStepHeader.module.scss';

const cn = classnames.bind(styles);

type TPreprocessingStepHeaderProps = {
  title: string;
  className?: string;
  instructions: string[] | React.ReactNode[];
  withoutBorder?: boolean;
};

const PreprocessingStepHeader: FC<TPreprocessingStepHeaderProps> = ({
  title,
  instructions,
  className,
  withoutBorder = false,
}) => (
  <div className={cn('header', { 'header_no-border': withoutBorder }, className)}>
    <span className={cn('header__title')}>{title}</span>
    <div className={cn('instructions')}>
      {instructions.map((item, index) => {
        const key = index;

        return (
          <div className={cn('instructions__item')} key={key}>
            {item}{' '}
          </div>
        );
      })}
    </div>
  </div>
);

export default memo(PreprocessingStepHeader);
