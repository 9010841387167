import { SVGProps } from 'react';

const PreprocessingLargeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.5527 3.63232V23.4749" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3.63281 13.5552L23.4733 13.5552" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8.59473 4.96069L18.515 22.1449" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path d="M4.96484 18.5154L22.1473 8.59409" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path d="M4.95996 8.5979L22.1424 18.5192" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <path d="M8.59277 22.1506L18.513 4.96646" stroke="currentColor" strokeWidth="1.36125" strokeLinecap="round" />
    <circle cx="13.5" cy="13.5" r="6.75" fill="#66F0FB" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="13.75" cy="13.75" r="3" fill="#66F0FB" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default PreprocessingLargeIcon;
