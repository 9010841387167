import { EEntityObjectType } from '@/store/slices/chartSettings';

export const getCoordinatesByAxes = ({
  entityList,
  xAxis,
  yAxis,
}: {
  entityList: TEntity[];
  xAxis: string;
  yAxis: string;
}) => {
  const entitiesDataByCoordinates: TEntity[] = [];
  const x: number[] = [];
  const y: number[] = [];

  entityList.forEach((entity) => {
    const xValue = entity[xAxis];
    const yValue = entity[yAxis];

    if (typeof xValue === 'number' && typeof yValue === 'number') {
      entitiesDataByCoordinates.push(entity);

      x.push(xValue);
      y.push(yValue);
    }
  });

  return {
    coordinates: { x, y },
    cagesDataByCoordinates: entitiesDataByCoordinates,
  };
};

export const getEntityObjectTypeOptionList = (): TOption[] => {
  const options: { label: string; value: EEntityObjectType }[] = [
    { label: 'All', value: EEntityObjectType.all },
    { label: 'Cells', value: EEntityObjectType.isCell },
    { label: 'Beads', value: EEntityObjectType.isBead },
  ];

  return options;
};
