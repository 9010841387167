import { SVGProps } from 'react';

const PolarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M6.158.474v17.052M.474 11.842h17.052" />
    </g>
  </svg>
);

export default PolarIcon;
