import { FC, ReactNode, ChangeEventHandler } from 'react';
import classnames from 'classnames/bind';

import styles from './RadioButton.module.scss';

const cn = classnames.bind(styles);

export type TRadioButton = {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  id: string;
  label?: ReactNode;
  theme?: 'dark' | 'light';
};

const RadioButton: FC<TRadioButton> = ({
  checked = false,
  onChange,
  disabled = false,
  readOnly = false,
  className = '',
  label,
  id,
  theme = 'dark',
}) => (
  <label
    htmlFor={id}
    className={cn(
      'radio__label',
      `radio_${theme}`,
      {
        radio_disabled: disabled,
        radio_checked: checked,
      },
      className
    )}
  >
    <input
      type="radio"
      className={cn('radio__input')}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      readOnly={readOnly}
      id={id}
    />
    {label}
  </label>
);

export default RadioButton;
