import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import styles from '../UserList.module.scss';

const cn = classnames.bind(styles);

type TUserRoleBadgeProps = {
  data: TUser;
};

const UserRoleBadge: FC<TUserRoleBadgeProps> = ({ data: user }) => {
  const badgeClassName = useMemo<string>(() => {
    const classNameMap: Record<TUser['role'], string> = {
      admin: 'badge_blue',
      maintainer: 'badge_yellow',
      viewer: 'badge_gray',
      '–': '',
      '—': '',
    };

    return classNameMap[user.role ?? 'viewer'];
  }, [user.role]);
  return <div className={cn('badge', badgeClassName)}>{user.role.toUpperCase()}</div>;
};

export default UserRoleBadge;
