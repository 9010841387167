import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select as d3Select } from 'd3-selection';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { getEntityObjectTypeOptionList } from '@/helpers/cages';
import { isNumber } from '@/helpers';

import { useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { gatesSelectors, gatesActions } from '@/store/slices/gates';
import { EPreprocessingObjectType, EStepName } from '@/store/slices/preprocessing/types';
import { experimentSelectors } from '@/store/slices/experiment';
import { EEntityObjectType, chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';

import Select from '@/components/common/Select';

import Gates from '../../components/Gates';

import DatasetScatterPlot from './DatasetScatterPlot';
import StepActions, { TAction, TActionGroup } from '../../components/StepActions';

import styles from '../../PreprocessingSteps.module.scss';

const cn = classnames.bind(styles);

export type TGeneralView = {
  className?: string;
  hideGates?: boolean;
  updatedDatasets?: Record<string, boolean>;
};

const GeneralView: FC<TGeneralView> = ({ className, hideGates, updatedDatasets = {} }) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const beads = useSelector(preprocessingSelectors.selectBeadTypes);
  const beadsCount = useSelector(preprocessingSelectors.selectBeadsCount);

  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const generalBeadShapes = useSelector(preprocessingSelectors.selectGeneralBeadShapes);
  const isStepCytokineReviewBeadsCompleted = useSelector(
    preprocessingSelectors.selectIsStepCytokineReviewBeadsCompleted
  );
  const objectType = useSelector(chartSettingsSelectors.selectObjectType());
  const activeGate = useSelector(gatesSelectors.selectActiveGate);

  const generalViewId = useMemo(() => 'preprocessing-general-view', []);

  const withApplyForAllDatasets = useMemo(
    () =>
      isStepCytokineReviewBeadsCompleted &&
      currentStep === EStepName.stepCytokineReviewBeads &&
      isNumber(currentDatasetIndex),
    [isStepCytokineReviewBeadsCompleted, currentStep, currentDatasetIndex]
  );
  const [scansCount, isPluralSymbolScans] = useMemo(() => {
    const scansObj = datasets.reduce((acc, el) => {
      acc[el.scanId] = true;
      return acc;
    }, {} as Record<string, boolean>);
    const { length } = Object.keys(scansObj);
    return [length, length > 1 ? 's' : ''];
  }, [datasets]);

  const [datasetsCount, isPluralSymbolDatasets] = useMemo(() => {
    const { length } = datasets;
    return [length, length > 1 ? 's' : ''];
  }, [datasets]);

  const entityObjectTypeOptions = useMemo<TOption[]>(() => getEntityObjectTypeOptionList(), []);

  const actionGroups = useMemo(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Back',
        colorBtn: 'white',
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCytokineDefineBeads));
        },
      },
      {
        label: 'Next',
        disabled: !isStepCytokineReviewBeadsCompleted,
        onClick: () => {
          appDispatch(preprocessingActions.setCurrentStep(EStepName.stepCytokineSummary));
        },
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isStepCytokineReviewBeadsCompleted, generalBeadShapes]);

  const onPlotClick = useCallback(
    (chartId: string) => {
      const index = datasets.findIndex((el) => el.id === chartId);
      if (index < 0 || index >= datasets.length) {
        return;
      }
      appDispatch(preprocessingActions.setCurrentDatasetIndex(index));
    },
    [appDispatch]
  );

  const handleEntityObjectTypeChange = useCallback(
    (newObjectType: EEntityObjectType) => {
      appDispatch(chartSettingsActions.setObjectType(newObjectType));
    },
    [objectType, appDispatch]
  );

  useEffect(() => {
    appDispatch(gatesActions.setActiveGate(null));
  }, []);

  useEffect(() => {
    const block = d3Select(`#${generalViewId}`);
    const gateElements = block.selectAll('.gate-element');
    gateElements.attr('class', 'gate-element gate-element_no-events');
  }, [activeGate, currentDatasetIndex]);

  return (
    <div className={cn('general-view', className)}>
      <div className={cn('preprocessing-step', 'preprocessing-step_matrix')} id={generalViewId}>
        <div className={cn('preprocessing-step__charts-wrapper')}>
          <div className={cn('preprocessing-step__charts-block', 'charts-block')}>
            <div className={cn('charts-block__header')}>
              <span>
                Gating for {datasetsCount} dataset{isPluralSymbolDatasets} in {scansCount} sample{isPluralSymbolScans}
              </span>
              <Select
                className={cn('select')}
                theme={themeOptions.light}
                options={entityObjectTypeOptions}
                defaultValue={objectType}
                onChange={handleEntityObjectTypeChange}
                value={objectType}
              />
            </div>
            <div
              className={cn('charts-block__charts')}
              style={{
                gridTemplateColumns: 'repeat(3, 1fr)',
              }}
            >
              {datasets?.map((datasetData) => (
                <DatasetScatterPlot
                  key={datasetData.id}
                  experimentId={experimentId}
                  datasetDetails={datasetData}
                  onPlotClick={onPlotClick}
                  scanList={scanList}
                  hideGates={hideGates}
                  isUpdated={updatedDatasets[datasetData.id]}
                />
              ))}
            </div>
          </div>
          <Gates
            headerClassName={cn('general-view__gates-header')}
            className={cn('general-view__gates')}
            type={EPreprocessingObjectType.beads}
            shapes={generalBeadShapes}
            count={beadsCount}
            items={beads}
            withApplyForAllDatasets={withApplyForAllDatasets}
          />
        </div>
      </div>
      <StepActions actionGroups={actionGroups} />
    </div>
  );
};

export default memo(GeneralView);
