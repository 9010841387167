import { useMemo } from 'react';

import { EAxesScaleType, EChartType } from '@/types/charts';

import type { TPresetSettings } from './types';

export const useViolinChartSettingsPreset = (_chartDataList: TDatasetDetails[]): Nullable<Partial<TPresetSettings>> =>
  useMemo(
    () => ({
      plotType: EChartType.violin,
      axesScaleTypes: {
        x: EAxesScaleType.linear,
        y: EAxesScaleType.linear,
      },
      isReady: true,
    }),
    []
  );
