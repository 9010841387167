import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { isCircleTypeGate, isPolygonTypeGate } from '@/helpers/typeGuards';
import { getMinMax } from '@/helpers/arrays';

import { gatesSelectors } from '@/store/slices/gates';

// To calculate the ranges for a matrix view, it's important to get data about the area that includes all the specific gates for preprocessing datasets.
// This ensures that points from specific shapes are taken into account and the ranges are calculated accurately.
const useSelectedGateForUnitedSpecificShapes = () => {
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);

  const gateWithSpecificShape = useMemo<Nullable<TGate>>(() => {
    if (!selectedGate || !selectedGate?.specificDatasets) return selectedGate;
    const specificShapes = Object.values(selectedGate.specificDatasets);

    let xPoints: number[] = [];
    let yPoints: number[] = [];

    const pointsHandlersByGateType: Record<TGateType, (shape: TGateShape) => void> = {
      polar: () => null,
      'polar-sector': () => null,
      range: () => null,
      circle: (shape) => {
        if (!isCircleTypeGate(shape)) return;
        const { model } = shape;
        const xMin = model.x - model.rx;
        const xMax = model.x + model.rx;
        const yMin = model.y - model.ry;
        const yMax = model.y + model.ry;

        xPoints = [...xPoints, xMin, xMax];
        yPoints = [...yPoints, yMin, yMax];
      },
      polygon: (shape) => {
        if (!isPolygonTypeGate(shape)) return;
        const { model } = shape;
        model.points.forEach((pointData) => {
          xPoints.push(pointData.x);
          yPoints.push(pointData.y);
        });
      },
    };

    specificShapes.forEach(({ shape }) => pointsHandlersByGateType[shape.type](shape));
    pointsHandlersByGateType[selectedGate.shape.type](selectedGate.shape);

    const { min: xMin, max: xMax } = getMinMax(xPoints);
    const { min: yMin, max: yMax } = getMinMax(yPoints);

    const polygonsByMaxMin = [
      { x: xMin, y: yMin },
      { x: xMin, y: yMax },
      { x: xMax, y: yMax },
      { x: xMax, y: yMin },
    ];

    const gate: TGate = {
      ...selectedGate,
      shape: {
        type: 'polygon',
        model: { points: polygonsByMaxMin },
      },
      properties: {
        ...selectedGate.properties,
        type: 'polygon',
      },
    };
    return gate;
  }, [selectedGate]);

  return gateWithSpecificShape;
};

export default useSelectedGateForUnitedSpecificShapes;
