import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <circle cx="7" cy="7" r="5" />
    </g>
  </svg>
);

export default SearchIcon;
