import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { withDefaultChartSettings } from '@/hoc/withDefaultChartSettings';

import { EPageWithChartType, EChartType } from '@/types/charts';
import { clearGenes, getGenes } from '@/helpers';
import { formatRangeObj } from '@/helpers/charts/ranges';
import { findLaneInScanList } from '@/helpers/scans';

import { chartSettingsActions } from '@/store/slices/chartSettings';
import { experimentSelectors } from '@/store/slices/experiment';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import usePlotProxy from '@/hooks/usePlotProxy';
import { useChartRangeHandling } from '@/hooks';
import useHighlightMarker from '@/hooks/charts/useHighlightMarker';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import RangeResetButton from '@/components/charts/RangeResetButton';
import NoDataFound from '@/components/common/NoDataFound';
import type { TDatasetChart } from '@/components/charts/SingleChartWithGates/types';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import PointHoverTemplate, { TPointHoverTemplateData } from '@/components/charts/PointHoverTemplate';

import { CONFIG } from '@/pages/Dataset/constants';

import styles from './ViolinGraph.module.scss';
import ViolinDataPopover from './components/ViolinDataPopover';
import useCageMap from './hooks/useCageMap';
import useViolinPlotData from './hooks/useViolinPlotData';

const cn = classnames.bind(styles);

type TViolinGraphProps = TDatasetChart;

const ViolinGraph: FC<TViolinGraphProps> = ({ isTransitionEnd, chartDataList, scanList, entitiesByLanesAndGates }) => {
  const appDispatch = useAppDispatch();
  const { openCageInspector } = useExperimentModalsContext();

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);

  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');

  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isLoadingGens, setIsLoadingGens] = useState(true);
  const [isPlotLoaded, setIsPlotLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isHoverInfoOpen, setIsHoverInfoOpen] = useState(false);
  const [hoverInfoPosition, setHoverInfoPosition] = useState<[number, number]>([0, 0]);
  const [hoverData, setHoverData] = useState<TPointHoverTemplateData>(null);

  const { highlightMarker, removeMarkerHighlight } = useHighlightMarker(plotlyProxy);
  const { resetRangeToDefault, isCustomRangeUsed, handlePlotZoomed } = useChartRangeHandling({
    openedAxisSettingsName: null,
    selectedTool: null,
    graphRef,
    customChartType: EChartType.violin,
  });

  const cageMap = useCageMap(chartDataList);
  const plotsData = useViolinPlotData(entitiesByLanesAndGates);

  const downloadFileName = useMemo(() => `Violin ${experimentName}`, [experimentName]);

  const loadGens = useCallback(async () => {
    await getGenes();
    setIsLoadingGens(false);
  }, []);

  const handleHover = useCallback(
    (data: TPlotMouseEvent) => {
      const point = data.points[0];
      if (!point?.customdata?.globalCageId) {
        return;
      }
      setIsHoverInfoOpen(true);

      setHoverInfoPosition([data.event.x, data.event.y]);

      highlightMarker(point.pointIndex, point.customdata.plotIndex);

      const cageInfo = cageMap[point.customdata.globalCageId];

      const newHoverData: TPointHoverTemplateData = {
        globalCageIdMatched: {
          label: 'Global cage ID',
          value: point.customdata.globalCageId,
        },
        x: {
          label: 'Violin',
          value: point.x,
        },
      };
      if (!cageInfo) {
        newHoverData.noData = {
          label: 'No cage',
          value: point.customdata.globalCageId,
        };
        newHoverData.y = {
          label: 'Cage ID',
          value: point.y,
        };
        setHoverData(newHoverData);
        return;
      }

      newHoverData.snapshotId = {
        label: 'Snapshot ID',
        value: cageInfo.snapshotId,
      };
      newHoverData.cageId = {
        label: 'Cage ID',
        value: cageInfo.cageId,
      };
      setHoverData(newHoverData);
    },
    [cageMap, highlightMarker]
  );

  const handleUnHover = useCallback(() => {
    setHoverData(null);
    setIsHoverInfoOpen(false);
    removeMarkerHighlight();
  }, [removeMarkerHighlight]);

  const handlePointClick = useCallback(
    (data: TPlotMouseEvent) => {
      const point = data.points[0];
      if (!point.customdata?.globalCageId) {
        toast.error('Cage images does not exist');
        return;
      }

      const selectedCage = cageMap[point.customdata.globalCageId];
      if (!selectedCage) {
        toast.error('Cage images does not exist');
        return;
      }
      handleUnHover();
      const lane = findLaneInScanList(scanList, selectedCage.scanId, selectedCage.laneId);
      openCageInspector({ entity: selectedCage, lane });
    },
    [scanList, handleUnHover, openCageInspector]
  );

  const handleRangeResetClick = () => {
    resetRangeToDefault();
  };

  useEffect(() => {
    loadGens();

    return () => {
      clearGenes();
    };
  }, []);

  useEffect(() => {
    const layout = {
      yaxis: {
        zeroline: false,
      },
      margin: {
        pad: 0,
        l: 50,
        r: 0,
        t: 0,
        b: 20,
      },
    };

    setIsEmptyData(!plotsData.length);
    if (!plotsData.length) {
      return;
    }

    plotlyProxy.forceReact(plotsData, layout, { ...CONFIG(), scrollZoom: true }, (plot) => {
      const { xaxis, yaxis } = plot.layout;
      if ('range' in xaxis && 'range' in yaxis) {
        appDispatch(
          chartSettingsActions.setPlotRange({
            range: formatRangeObj({
              x: xaxis.range,
              y: yaxis.range,
            }),
          })
        );
      }
      setIsPlotLoaded(true);
    });
  }, [plotlyProxy.id, plotsData]);

  useEffect(() => {
    const graphDiv = graphRef.current;

    if (!isPlotLoaded || !graphDiv?.removeAllListeners || !graphDiv?.on) {
      return;
    }

    graphDiv.on('plotly_click', handlePointClick);
    graphDiv.on('plotly_hover', handleHover);
    graphDiv.on('plotly_unhover', handleUnHover);
    graphDiv.on('plotly_relayout', (eventData: TPlotRelayoutEvent) => handlePlotZoomed(eventData, 'plotly_relayout')); // event for zoom via selection

    return () => {
      graphDiv.removeAllListeners?.('plotly_click');
      graphDiv.removeAllListeners?.('plotly_relayout');
      graphDiv.removeAllListeners?.('plotly_hover');
      graphDiv.removeAllListeners?.('plotly_unhover');
    };
  }, [isPlotLoaded, handlePointClick, handleHover, handleUnHover]);

  useEffect(() => {
    if (!graphRef?.current) {
      return;
    }

    graphRef?.current?.removeAllListeners?.('plotly_doubleclick');
    graphRef.current?.on?.('plotly_doubleclick', () => {
      handleRangeResetClick();
    });

    return () => {
      graphRef?.current?.removeAllListeners?.('plotly_doubleclick');
    };
  }, [plotsData, handleRangeResetClick]);

  useEffect(() => {
    setIsReady(false);

    const timeout = setTimeout(() => {
      setIsReady(true);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [isTransitionEnd]);

  return (
    <div
      className={cn('chart')}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {(!isTransitionEnd || !isReady) && <Skeleton className={cn('skeleton')} />}
      <div className={cn('chart__controls')}>
        <div className={cn('chart__modebar', 'modebar')}>
          <ViolinDataPopover disabled={isLoadingGens} />
          {!isEmptyData && <DownloadChartButton fileName={downloadFileName} graphRef={graphRef} withTime />}
          <RangeResetButton onClick={handleRangeResetClick} disabled={!isCustomRangeUsed} />
        </div>
      </div>
      <div className={cn('plot__container')}>
        <PointHoverTemplate isOpen={isHoverInfoOpen} data={hoverData} templatePosition={hoverInfoPosition} />
        <div ref={graphRef} id="violin-plot-chart" className={cn('plot-chart')}>
          {isEmptyData && isTransitionEnd && (
            <NoDataFound
              className={cn('chart__no-data')}
              alignment="center"
              size="small"
              textData="No data for plots"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(withDefaultChartSettings(ViolinGraph, EPageWithChartType.violin));
