import { useEffect, useMemo, ComponentType, useState } from 'react';
import { useSelector } from 'react-redux';

import { EPageWithChartType, EChartType } from '@/types/charts';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useExperimentContext } from '@/hooks';

import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsActions, histogramSettingsSelectors } from '@/store/slices/histogramSettings';

import { useHeatmapSettingsPreset } from './hooks/useChartSettingsPreset/useHeatmapSettingsPreset';
import { useMatrixSettingsPreset } from './hooks/useChartSettingsPreset/useMatrixSettingsPreset';
import { useMultiHistogramSettingsPreset } from './hooks/useChartSettingsPreset/useMultiHistogramSettingsPreset';
import { usePreprocessingSettingsPreset } from './hooks/useChartSettingsPreset/usePreprocessingSettingsPreset';
import { useSingleChartSettingsPreset } from './hooks/useChartSettingsPreset/useSingleChartSettingsPreset';
import { useViolinChartSettingsPreset } from './hooks/useChartSettingsPreset/useViolinChartSettingsPreset';
import { useKneePlotSettingsPreset } from './hooks/useChartSettingsPreset/useKneePlotSettingsPreset';
import { TPresetSettings } from './hooks/useChartSettingsPreset/types';

const getUseChartSettingsPreset = (
  pageWithChartType?: EPageWithChartType
): ((chartDataList: TDatasetDetails[]) => Nullable<Partial<TPresetSettings>>) => {
  switch (pageWithChartType) {
    case EPageWithChartType.heatmap:
      return useHeatmapSettingsPreset;
    case EPageWithChartType.matrixView:
      return useMatrixSettingsPreset;
    case EPageWithChartType.multiHistogram:
      return useMultiHistogramSettingsPreset;
    case EPageWithChartType.preprocessing:
      return usePreprocessingSettingsPreset;
    case EPageWithChartType.singleChart:
      return useSingleChartSettingsPreset;
    case EPageWithChartType.violin:
      return useViolinChartSettingsPreset;
    case EPageWithChartType.knee:
      return useKneePlotSettingsPreset;
    default:
      return () => null;
  }
};

const GENERAL_PRESET_SETTINGS = {
  isReady: false,
  plotType: EChartType.dotDensity,
  colorScale: 'turbo',
  histogramPreset: {
    isChartFillEnabled: true,
  },
};

export function withDefaultChartSettings<P extends object>(
  WrappedComponent: ComponentType<P>,
  pageWithChartType?: EPageWithChartType
) {
  return function ComponentWithDefaultSettings(props: P) {
    const appDispatch = useAppDispatch();
    const chartId = usePlotChartIdContext();

    const useChartSettingsPreset = getUseChartSettingsPreset(pageWithChartType);
    const { chartDataList } = useExperimentContext();
    const pagePresetSettings = useChartSettingsPreset(chartDataList);

    const presetSettings: TPresetSettings = useMemo(
      () => ({ ...GENERAL_PRESET_SETTINGS, ...pagePresetSettings }),
      [pagePresetSettings]
    );

    const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
    const axesScaleTypes = useSelector(chartSettingsSelectors.selectAxesScaleTypes(chartId));
    const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
    const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(chartId));
    const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

    const currentSettings = useMemo(
      () => ({
        plotType: currentChartType,
        axesScaleTypes,
        colorScale: currentColorScale,
        histogramPreset: { isChartFillEnabled },
      }),
      [currentChartType, axesScaleTypes, currentColorScale, isChartFillEnabled]
    );

    const [isSettingsPresetDone, setSettingsPresetDone] = useState(false);

    useEffect(() => {
      if (!isSettingsPresetDone && presetSettings.isReady) {
        if (presetSettings.plotType !== currentSettings.plotType) {
          appDispatch(chartSettingsActions.setCurrentChartType(presetSettings.plotType));
        }
        if (
          presetSettings?.axesScaleTypes &&
          (presetSettings.axesScaleTypes?.x !== currentSettings.axesScaleTypes.x ||
            presetSettings.axesScaleTypes?.y !== currentSettings.axesScaleTypes.y)
        ) {
          appDispatch(chartSettingsActions.setAxesScaleTypes(presetSettings.axesScaleTypes));
        }
        if (presetSettings.colorScale !== currentSettings.colorScale) {
          appDispatch(chartSettingsActions.setCurrentColorScale(presetSettings.colorScale));
        }
        if (presetSettings.histogramPreset.isChartFillEnabled !== currentSettings.histogramPreset.isChartFillEnabled) {
          appDispatch(
            histogramSettingsActions.setIsChartFillEnabled({
              isChecked: presetSettings.histogramPreset.isChartFillEnabled,
              fullScreenChartDataId: fullScreenChartData?.id,
            })
          );
        }
      }
    }, [presetSettings, isSettingsPresetDone]);

    useEffect(() => {
      const isAxesScalePresetDone =
        !presetSettings?.axesScaleTypes ||
        (presetSettings?.axesScaleTypes?.x === currentSettings.axesScaleTypes.x &&
          presetSettings?.axesScaleTypes?.y === currentSettings.axesScaleTypes.y);

      if (
        presetSettings.isReady &&
        presetSettings.plotType === currentSettings.plotType &&
        isAxesScalePresetDone &&
        presetSettings.colorScale === currentSettings.colorScale &&
        presetSettings.histogramPreset.isChartFillEnabled === currentSettings.histogramPreset.isChartFillEnabled
      ) {
        // This must be set exactly once. Any further changes to the settings should not cause them to be reset by this component.
        setSettingsPresetDone(true);
        if (pageWithChartType) {
          appDispatch(
            chartSettingsActions.setChartPresetSettingsСompletionStatusMap({
              key: pageWithChartType,
              isCompleted: true,
            })
          );
        }
      }
    }, [presetSettings, currentSettings]);

    return <WrappedComponent {...props} />;
  };
}
