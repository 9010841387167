import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import InfoButton from '@/components/common/InfoButton';

import styles from './StatusBadge.module.scss';

const cn = classnames.bind(styles);

type TStatusBadgeProps = {
  colorModifier: string;
  status: string;
  tooltip: string;
  icon?: ReactNode;
  shouldAlignIcons?: boolean;
  className?: string;
};

const StatusBadge: FC<TStatusBadgeProps> = ({
  colorModifier,
  status,
  tooltip,
  icon,
  shouldAlignIcons = false,
  className,
}) => (
  <div className={cn('status', className)}>
    <div className={cn('status__badge', `status__badge_${colorModifier}`)}>
      {status}
      {tooltip && <InfoButton className={cn('status__info')} tooltip={tooltip} />}
    </div>
    {!!icon && <div className={cn('status__icon', { status__icon_aligned: shouldAlignIcons })}>{icon}</div>}
  </div>
);

export default StatusBadge;
