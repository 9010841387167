import { CSSProperties, FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TConsumableCell = {
  children: ReactNode;
  color: string;
  isEx?: boolean;
};

const ConsumableCell: FC<TConsumableCell> = ({ children, color, isEx }) => (
  <span className={cn('wave', isEx ? 'wave__ex' : 'wave__em')} style={{ '--bg-color': color } as CSSProperties}>
    {children}
  </span>
);

export default ConsumableCell;
