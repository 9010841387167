import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useParamsExperimentId from '@/hooks/useParamsExperimentId';

import { datasetsSelectors } from '@/store/slices/datasets';

export function useSelectedDatasets() {
  const experimentId = useParamsExperimentId();
  const channelDetailsList = useSelector(datasetsSelectors.selectChannelDetailsList(experimentId));
  const laneDetailsList = useSelector(datasetsSelectors.selectLaneDetailsList);
  const openLaneIdList = useMemo(
    () => laneDetailsList.filter((laneDetails) => laneDetails.isOpen).map((laneDetails) => laneDetails.id),
    [laneDetailsList]
  );

  const selectedDetailsList = useMemo(
    () =>
      channelDetailsList.filter(
        (channelDetails) => openLaneIdList.includes(channelDetails.laneId) && channelDetails.checked
      ),
    [channelDetailsList, openLaneIdList]
  );

  const { selectedDetailsDataList, selectedGroupsCount } = useMemo(() => {
    const selectedGroups = new Set();

    const list = selectedDetailsList.map((details) => {
      const detailsIdData = {
        scanId: details.scanId,
        laneId: details.laneId,
      } as TDatasetDetailsIdData;

      if (details.channelId) {
        detailsIdData.channelId = details.channelId;
      }

      if (details.marker) {
        detailsIdData.marker = details.marker;
      }

      selectedGroups.add(details.laneId);

      return detailsIdData;
    });

    return {
      selectedDetailsDataList: list,
      selectedGroupsCount: selectedGroups.size,
    };
  }, [selectedDetailsList]);

  return { selectedDetailsDataList, selectedGroupsCount };
}
