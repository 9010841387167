import { EventEmitter } from '../../EventEmitter';

/**
 * Lets you to the window resize event end get its data
 */
class SyncResize {
  constructor() {
    if (typeof window === 'undefined') return;

    this.eventEmitter = new EventEmitter();
    this.width = 0;
    this.height = 0;
    this.onResize = this.onResize.bind(this);
    this.get = this.get.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);

    window.addEventListener('resize', this.onResize, { passive: true });
    window.addEventListener('orientationchange', this.onResize, {
      passive: true,
    });
    window.addEventListener('load', this.onResize, { passive: true });

    this.onResize();
  }

  onResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.eventEmitter.dispatch({
      type: 'resize',
    });
  }

  get() {
    return {
      width: this.width,
      height: this.height,
    };
  }

  subscribe(handler) {
    this.eventEmitter.subscribe(handler);
  }

  unsubscribe(handler) {
    this.eventEmitter.unsubscribe(handler);
  }
}

const syncResize = new SyncResize();

export default syncResize;
