import { SVGProps } from 'react';

const ExclamationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="30px"
    viewBox="0 -960 960 960"
    width="30px"
    fill="currentColor"
    {...props}
  >
    <path d="M427.67-374.33v-411.34h104.66v411.34H427.67Zm0 200V-279h104.66v104.67H427.67Z" />
  </svg>
);

export default ExclamationIcon;
