import { SVGProps } from 'react';

const CageRadiusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="53" viewBox="-2 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="19.8984" y="27" width="2" height="2" fill="black" />
    <rect x="23.8984" y="27" width="2" height="2" fill="black" />
    <rect x="27.8984" y="27" width="2" height="2" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0078125 45.7406V45.9999L2.64748 52.2282C12.7987 48.3471 20.0078 38.5154 20.0078 26.9999C20.0078 15.4843 12.7987 5.65256 2.64747 1.77148L0.0078125 7.99985V8.25907C7.60115 11.0965 13.0078 18.4168 13.0078 26.9999C13.0078 35.5829 7.60115 42.9032 0.0078125 45.7406Z"
      fill="#EBEBEA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.8984 45.7408V46.0002L47.1858 52.2004C37.0729 48.3002 29.8983 38.488 29.8983 27C29.8983 15.5121 37.0728 5.69988 47.1856 1.79966L49.8984 8.00022V8.25918C42.305 11.0966 36.8983 18.4169 36.8983 27C36.8983 35.5831 42.305 42.9034 49.8984 45.7408ZM56.7888 0.000217438C56.8253 7.25246e-05 56.8618 0 56.8983 0C56.9348 0 56.9713 7.25246e-05 57.0078 0.000217438H56.7888Z"
      fill="#EBEBEA"
    />
  </svg>
);

export default CageRadiusIcon;
