import { FC, MutableRefObject, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Popover as TinyPopover } from 'react-tiny-popover';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './BeadPopover.module.scss';
import BeadCard from './BeadCard';
import { TCardBeadsProps, TCardCellsProps } from './types';

const cn = classnames.bind(styles);

const POPOVER_SPACE = 10;

// TODO: rename variables to more generic
type TBeadPopoverProps = (TCardBeadsProps | TCardCellsProps) & {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: () => void;
  handleSelectBead: (beadId: string) => void;
  selectedBeadId: string;
  possibleSave: boolean;
  mouseUpEventRef?: MutableRefObject<Nullable<MouseEvent>>;
  mouseDownEventRef?: MutableRefObject<Nullable<MouseEvent>>;
  title: string;
};

const BeadPopover: FC<TBeadPopoverProps> = ({
  isOpen,
  handleClose,
  handleSelectBead,
  items,
  selectedBeadId,
  handleSave,
  possibleSave,
  mouseUpEventRef,
  mouseDownEventRef,
  type,
  title,
}) => {
  const [clientX, setClientX] = useState<number>(0);
  const [clientY, setClientY] = useState<number>(0);

  const popoverRef = useRef<HTMLDivElement>(null);

  const calculateX = useCallback(() => {
    let x = 0;

    const popoverWidth = popoverRef.current?.clientWidth ?? 0;
    const upClientX = mouseUpEventRef?.current?.clientX ?? 0;
    const downClientX = mouseDownEventRef?.current?.clientX ?? 0;

    const rightSide = Math.max(upClientX, downClientX) + popoverWidth < window.innerWidth;
    const leftSide = Math.min(upClientX, downClientX) - popoverWidth > POPOVER_SPACE;

    if (!rightSide && !leftSide) {
      x = upClientX + POPOVER_SPACE;
      const toLeft = upClientX + popoverWidth > window.innerWidth;
      x -= Number(toLeft) * (popoverWidth + 2 * POPOVER_SPACE);
    } else {
      x = rightSide
        ? Math.max(upClientX, downClientX) + POPOVER_SPACE
        : Math.min(upClientX, downClientX) - popoverWidth - POPOVER_SPACE;
    }

    setClientX(x);
  }, []);

  const calculateY = useCallback(() => {
    let y = 0;
    const popoverHeight = popoverRef.current?.clientHeight ?? 0;

    const upClientY = mouseUpEventRef?.current?.clientY ?? 0;
    const downClientY = mouseDownEventRef?.current?.clientY ?? 0;

    const botSide = Math.min(upClientY, downClientY) + popoverHeight < window.innerHeight;
    const topSide = Math.min(upClientY, downClientY) - popoverHeight > POPOVER_SPACE;

    if (!topSide && !botSide) {
      y = window.innerHeight - POPOVER_SPACE - popoverHeight;
    } else {
      y = botSide ? Math.min(upClientY, downClientY) : window.innerHeight - popoverHeight - POPOVER_SPACE;
    }

    setClientY(y);
  }, []);

  useEffect(() => {
    calculateX();
    calculateY();
  }, [isOpen]);

  return (
    <TinyPopover
      isOpen={isOpen}
      contentLocation={{ left: clientX, top: clientY }}
      clickOutsideCapture
      content={
        <>
          <div
            className={cn('bead-gate-popover__backdrop')}
            id="bead-gate-popover__backdrop"
            role="presentation"
            onClick={handleClose}
          />
          <div className={cn('bead-gate-popover')} ref={popoverRef}>
            <div className={cn('bead-gate-popover__header')}>
              <span>{title}</span>
              <Button className={cn('close-button')} onClick={handleClose} isFitContent color="light">
                <icons.CloseIcon />
              </Button>
            </div>
            <div className={cn('bead-gate-popover__beads')}>
              {items.map((bead) => (
                <BeadCard
                  key={bead.uuid}
                  withoutPlaceholder
                  item={bead}
                  type={type}
                  handleClick={handleSelectBead}
                  selected={bead.uuid === selectedBeadId}
                />
              ))}
            </div>
            <div className={cn('bead-gate-popover__actions')}>
              <Button color="white" className={cn('action_cancel')} onClick={handleClose}>
                Cancel
              </Button>
              <Button color="yellow" disabled={!possibleSave} className={cn('action_submit')} onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>
        </>
      }
    >
      <div />
    </TinyPopover>
  );
};

export default memo(BeadPopover);
