import { RefObject, useEffect } from 'react';

import { COPIED_CLASS_NAME, SELECT_BY_CLICK_CLASS_NAME, SELECTED_CLASS_NAME } from '@/helpers/reactSelectable';

import { checkIfCursorOnEl, handleAllItemAction } from '../helpers';

type TUseEventListeners<T> = {
  tableRef: RefObject<HTMLDivElement>;
  tableData: T;
  onCopyAction: () => void;
  onPasteAction: () => void;
};

function useEventListeners<T>({ tableRef, onCopyAction, onPasteAction, tableData }: TUseEventListeners<T>) {
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        handleAllItemAction(tableRef.current, (el) => el.classList.add(SELECT_BY_CLICK_CLASS_NAME));
      }

      if ((event.ctrlKey || event.metaKey) && event.code === 'KeyC') {
        onCopyAction();
      }

      if ((event.ctrlKey || event.metaKey) && event.code === 'KeyV') {
        onPasteAction();
      }
    };

    const keyupListener = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        return;
      }
      handleAllItemAction(tableRef.current, (el) => el.classList.remove(SELECT_BY_CLICK_CLASS_NAME));
    };

    const tableClickListener = (e: MouseEvent) => {
      if (e.button === 2) {
        e.preventDefault();
      }
    };

    const ctrlClickListener = (e: MouseEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        return;
      }

      handleAllItemAction(tableRef.current, (el) => {
        el.classList.add(SELECT_BY_CLICK_CLASS_NAME);
        const isCursorOnEl = checkIfCursorOnEl(e.clientX, e.clientY, el.getBoundingClientRect());
        if (isCursorOnEl) {
          el.classList.add(SELECTED_CLASS_NAME);
        }
      });
    };

    const clickListener = (e: MouseEvent) => {
      let isCursorOnEl = false;
      handleAllItemAction(tableRef.current, (el) => {
        if (isCursorOnEl) {
          return;
        }
        isCursorOnEl = checkIfCursorOnEl(e.clientX, e.clientY, el.getBoundingClientRect());
      });

      if (!isCursorOnEl) {
        handleAllItemAction(tableRef.current, (el) => {
          el.classList.remove(SELECTED_CLASS_NAME);
          el.classList.remove(COPIED_CLASS_NAME);
        });
      }
    };

    tableRef.current?.addEventListener('click', tableClickListener);
    tableRef.current?.addEventListener('contextmenu', tableClickListener);

    document.addEventListener('keydown', keydownListener);
    document.addEventListener('keyup', keyupListener);
    document.addEventListener('click', ctrlClickListener);
    document.addEventListener('click', clickListener);

    return () => {
      tableRef.current?.removeEventListener('click', tableClickListener);
      tableRef.current?.removeEventListener('contextmenu', tableClickListener);

      document.removeEventListener('keydown', keydownListener);
      document.removeEventListener('keyup', keyupListener);
      document.removeEventListener('click', ctrlClickListener);
      document.removeEventListener('click', clickListener);
    };
  }, [tableData]);
}

export default useEventListeners;
