import { getEnum, TEnumValue } from '@/helpers/enum';

const designStepVariants = [
  'details',
  'timeline',
  'sample',
  'reagents',
  'incubation',
  'cagingSettings',
  'opticalSettings',
] as const;

export const EDesignStep = getEnum(designStepVariants);

export type TDesignStep = TEnumValue<typeof EDesignStep>;
