import { Compound, Reagent, Stain } from '@/graphql/API';
import { TReturnTransformedTypes } from './types';

const checkIsReagent = (reagent: unknown): reagent is Reagent => {
  const data = reagent as Reagent;
  return 'type' in data && data.type === 'reagent';
};

const checkIsCompound = (compound: unknown): compound is Compound => {
  const data = compound as Compound;
  return 'type' in data && data.type === 'compound';
};

const checkIsStain = (compound: unknown): compound is Stain => {
  const data = compound as Compound;
  return 'type' in data && data.type === 'stain';
};

const transformWaveLength = (wavelengthFromServer?: number | string | number[] | string[] | null) => {
  if (Array.isArray(wavelengthFromServer) && wavelengthFromServer.length > 0) {
    return +wavelengthFromServer[0];
  }
  if (typeof wavelengthFromServer === 'string' || typeof wavelengthFromServer === 'number') {
    return +wavelengthFromServer;
  }
  return null;
};

const transformReagent = (data: TGeneralAnnotationFields): Reagent => {
  const resultData: Reagent = {
    __typename: 'Reagent',
    type: 'Reagent',
    id: data.id,
    name: data.name,
  };

  if (checkIsReagent(data)) {
    resultData.annotations = data.annotations ?? null;
    resultData.detectionWavelength = transformWaveLength(data.detectionWavelength);
    resultData.excitationWavelength = transformWaveLength(data.excitationWavelength);
    resultData.marker = data.marker ?? null;
    resultData.phenotype = data.phenotype ?? null;
    resultData.opticalDisplay = data.opticalDisplay ?? null;

    // @ts-ignore
    resultData.catalogNumbers = data.catalogNumbers ?? [];
    // @ts-ignore
    resultData.manufacturerName = data.manufacturerName ?? null;
    // @ts-ignore
    resultData.description = data.description ?? null;
    // @ts-ignore
    resultData.url = data.url ?? null;
    // @ts-ignore
    resultData.immunogen = data.immunogen ?? null;
    // @ts-ignore
    resultData.vendor = data.vendor ?? null;
  }
  return resultData;
};

const transformCompound = (data: TGeneralAnnotationFields): Compound => {
  const resultData: Compound = {
    __typename: 'Compound',
    type: 'Compound',
    id: data.id,
    name: data.name,
  };

  if (checkIsCompound(data)) {
    resultData.annotations = data.annotations ?? null;
  }

  return resultData;
};

const transformStain = (data: TGeneralAnnotationFields): Stain => {
  const resultData: Stain = {
    __typename: 'Stain',
    type: 'Stain',
    id: data.id,
    name: data.name,
  };

  if (checkIsStain(data)) {
    resultData.annotations = data.annotations ?? null;
    resultData.detectionWavelength = transformWaveLength(data.detectionWavelength);
    resultData.excitationWavelength = transformWaveLength(data.excitationWavelength);
    resultData.marker = data.marker ?? null;
    resultData.phenotype = data.phenotype ?? null;
    resultData.opticalDisplay = data.opticalDisplay ?? null;

    // @ts-ignore
    resultData.catalogNumbers = data.catalogNumbers ?? [];
    // @ts-ignore
    resultData.manufacturerName = data.manufacturerName ?? null;
    // @ts-ignore
    resultData.description = data.description ?? null;
    // @ts-ignore
    resultData.url = data.url ?? null;
    // @ts-ignore
    resultData.immunogen = data.immunogen ?? null;
    // @ts-ignore
    resultData.vendor = data.vendor ?? null;
  }

  return resultData;
};

export const transformMap: Record<string, (annotation: TGeneralAnnotationFields) => TReturnTransformedTypes> = {
  reagent: transformReagent,
  compound: transformCompound,
  stain: transformStain,
};
