import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Flip from 'gsap/Flip';
import classnames from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';
import update from 'immutability-helper';
import isEqual from 'lodash.isequal';

import { ECanvasWebglContextType, EPageWithChartType } from '@/types/charts';

import { PlotChartIdContext, usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { uniqueKeyForDuplicates } from '@/helpers';
import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';
import useMatrixRanges from '@/hooks/charts/useMatrixRanges';
import { useLinks } from '@/hooks/useLinks';
import { useDebounce } from '@/hooks';

import { chartSettingsActions, chartSettingsSelectors, TBaseChartSettings } from '@/store/slices/chartSettings';
import {
  scatterplotsSelectors,
  EAxesGroupName,
  TAxes,
  scatterplotsActions,
  TBaseScatterPlotsSettings,
} from '@/store/slices/scatterplots';
import { prepareAxesPresetOptionList } from '@/store/services/app/selectHelpers';
import {
  histogramSettingsActions,
  histogramSettingsSelectors,
  TBaseHistogramSettings,
} from '@/store/slices/histogramSettings';
import { gatesActions, gatesSelectors, TBaseGateSettings } from '@/store/slices/gates';

import { withDefaultChartSettings } from '@/hoc/withDefaultChartSettings';
import { getBasicAxes } from '@/hoc/withDefaultChartSettings/hooks/useChartSettingsPreset/helpers';
import { getPlotAxesOptionList } from '@/hoc/withDefaultChartSettings/helpers';

import type { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';
import ToggleableBlock from '@/components/common/ToggleableBlock';
import { getChartDataListFromIdList } from '@/helpers/datasetDetails';
import { withChartData } from '@/hoc/withChartData';

import GateList from './components/GateList';
import DataScatterplotChart from './components/MatrixScatterplotChart';
import styles from './Scatterplot.module.scss';
import DatasetFullScreen from './DatasetFullScreen';
import { checkIsGlobalSettingsChanged } from './helpers';

const cn = classnames.bind(styles);

const DEFAULT_BANDWIDTH = {
  custom: {
    x: 0,
    y: 0,
  },
  default: {
    x: 0,
    y: 0,
  },
};

type TScatterplot = {
  chartColumns: number;
} & Omit<TGeneralChartProps, 'createGate' | 'updateGate' | 'deleteGate' | 'deleteGateChildren' | 'experimentId'> &
  TUseUpdateGateCallbacksType;

/* TODO: FULL-SCREEN DATASET Temp solution. 
   Implementing the full - screen dataset was temporarily converted into a modal window.
   After the component with the chart has been refactored and the current chart components have been reduced to one reusable, it is necessary to return the full - screen concept via useFlipBlock
*/
const Scatterplot = ({
  chartColumns,
  fullGateList = [],
  isUpdateGatesInProgress = false,
  chartDataList,
  cageEntitiesByLanesAndGates,
  changeSelectedChartData,
  currentAppLane,
  entitiesByLanesAndGates,
  isGateListLoading = false,
  selectedChartData,
  entityLevelGateList,
  createGate: createExperimentGate,
  updateGate: updateExperimentGate,
  deleteGate: deleteExperimentGate,
  deleteGateChildren: deleteExperimentGateChildren,
  channelName,
  scanList,
}: TScatterplot) => {
  const appDispatch = useAppDispatch();
  const contextChartId = usePlotChartIdContext();

  const [isColumnsChangeEnd, setIsColumnsChangeEnd] = useState(true);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [isFullWidthAnimationEnd, setIsFullWidthAnimationEnd] = useState(true);

  // ?? uniqueKeyForDuplicates usage for correct DND logic, when we get same dataset when change scan
  // ?? and possible get duplicate datasets
  const [chartsInfo, setChartsInfo] = useState(uniqueKeyForDuplicates(chartDataList));
  const initialChartColumns = useRef(chartColumns);
  const toScanIdRef = useRef('');
  const { generateAnalyticsLink } = useLinks();
  const navigate = useNavigate();
  const [loadedChartMap, setLoadedChartMap] = useState<Record<string, boolean>>({});
  const generalAxes = useSelector(scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.general)) as Record<
    string,
    TAxes
  >;
  const allGlobalChartSettings = useSelector(chartSettingsSelectors.selectAllGlobalChartSettings);
  const allGlobalScatterPlotSettings = useSelector(scatterplotsSelectors.selectAllGlobalScatterPlotSettingsSettings);
  const allGlobalGatesSettings = useSelector(gatesSelectors.selectAllGlobalGatesSettings);
  const allGlobalHistogramSettings = useSelector(histogramSettingsSelectors.selectAllGlobalHistogramSettings);

  const fullScreenDatasetAxes = useSelector(
    scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.singleChart)
  ) as Record<string, TAxes>;
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const [isDatasetFullScreenOpen, setIsDatasetFullScreenOpen] = useState(false);
  const [shouldApplyChangesToAllDatasets, setShouldApplyChangesToAllDatasets] = useState(false);
  const prevSpecificSettings = useRef<
    Nullable<{
      chartSettings: Partial<TBaseChartSettings>;
      histogramSettings: Partial<TBaseHistogramSettings>;
      scatterplotSettings: Partial<TBaseScatterPlotsSettings>;
      gateSettings: Partial<TBaseGateSettings>;
    }>
  >(null);

  const toggleShouldApplyChangesToAllDatasets = () => {
    setShouldApplyChangesToAllDatasets((prev) => !prev);
  };

  const axesOfDatasetEditedOnFullScreen = useMemo(
    () => generalAxes?.[fullScreenChartData?.name ?? ''] ?? null,
    [fullScreenChartData, generalAxes]
  );

  const isSomeGlobalScatterplotSettingsChanged = useMemo(
    () =>
      checkIsGlobalSettingsChanged({
        fullScreenChartDataId: fullScreenChartData?.id ?? '',
        globalSettings: allGlobalScatterPlotSettings,
        specificSettings: allGlobalScatterPlotSettings.specificDatasetOptionMap,
      }),
    [allGlobalScatterPlotSettings, fullScreenChartData]
  );

  const isSomeGlobalGatesSettingsChanged = useMemo(
    () =>
      checkIsGlobalSettingsChanged({
        fullScreenChartDataId: fullScreenChartData?.id ?? '',
        globalSettings: allGlobalGatesSettings,
        specificSettings: allGlobalGatesSettings.specificDatasetOptionMap,
      }),
    [allGlobalGatesSettings, fullScreenChartData]
  );

  const isSomeGlobalChartSettingsChanged = useMemo(() => {
    const { specificDatasetOptionMap, customRangesMapName } = allGlobalChartSettings;
    const currentSpecificChartSettings = specificDatasetOptionMap?.[fullScreenChartData?.id ?? ''] ?? {};
    const { customRangesMapName: specificCustomRangeName, customRangesMap: specificCustomRangesMap } =
      currentSpecificChartSettings;

    const isMainSettingsChanged = checkIsGlobalSettingsChanged({
      fullScreenChartDataId: fullScreenChartData?.id ?? '',
      globalSettings: allGlobalChartSettings,
      specificSettings: specificDatasetOptionMap,
      specificSettingKeysToExlude: ['plotRangesMap', 'lastPlotRangeName', 'customRangesMap'],
    });

    const isCustomRangeChanged =
      customRangesMapName === specificCustomRangeName
        ? !isEqual(
            allGlobalChartSettings.customRangesMap?.[customRangesMapName]?.range,
            specificCustomRangesMap?.[specificCustomRangeName]?.range
          )
        : false;

    return isCustomRangeChanged || isMainSettingsChanged;
  }, [allGlobalChartSettings, fullScreenChartData]);

  const isSomeAxesChanged = useMemo(
    () =>
      axesOfDatasetEditedOnFullScreen?.x !== fullScreenDatasetAxes?.x ||
      axesOfDatasetEditedOnFullScreen?.y !== fullScreenDatasetAxes?.y,
    [
      axesOfDatasetEditedOnFullScreen?.x,
      fullScreenDatasetAxes?.x,
      axesOfDatasetEditedOnFullScreen?.y,
      fullScreenDatasetAxes?.y,
    ]
  );

  const isSomeGlobalHistogramSettingsChanged = useMemo(
    () =>
      checkIsGlobalSettingsChanged({
        fullScreenChartDataId: fullScreenChartData?.id ?? '',
        globalSettings: allGlobalHistogramSettings,
        specificSettings: allGlobalHistogramSettings.specificDatasetOptionMap,
      }),
    [allGlobalHistogramSettings, fullScreenChartData]
  );

  const isSomeChartSettingsChanged = useMemo(
    () =>
      isSomeGlobalScatterplotSettingsChanged ||
      isSomeGlobalGatesSettingsChanged ||
      isSomeGlobalChartSettingsChanged ||
      isSomeGlobalHistogramSettingsChanged,
    [
      isSomeGlobalScatterplotSettingsChanged,
      isSomeGlobalGatesSettingsChanged,
      isSomeGlobalChartSettingsChanged,
      isSomeGlobalHistogramSettingsChanged,
    ]
  );

  const isShouldApplyChangesToAllDatasetsVisible = useMemo(
    () => isSomeChartSettingsChanged || isSomeAxesChanged,
    [isSomeChartSettingsChanged, isSomeAxesChanged]
  );

  const debouncedIsDatasetFullScreenOpen = useDebounce(isDatasetFullScreenOpen);

  const openFullScreenModal = (chartData: TDatasetDetails) => {
    if (allGlobalChartSettings.specificDatasetOptionMap?.[chartData.id]) {
      prevSpecificSettings.current = {
        chartSettings: allGlobalChartSettings.specificDatasetOptionMap?.[chartData.id],
        histogramSettings: allGlobalHistogramSettings.specificDatasetOptionMap?.[chartData.id],
        scatterplotSettings: allGlobalScatterPlotSettings.specificDatasetOptionMap?.[chartData.id],
        gateSettings: allGlobalGatesSettings.specificDatasetOptionMap?.[chartData.id],
      };
    }

    setIsDatasetFullScreenOpen(true);
    appDispatch(chartSettingsActions.setFullScreenChartData(chartData));

    if (!generalAxes?.[chartData?.id]) return;

    appDispatch(
      scatterplotsActions.setAxes({
        newAxes: generalAxes[chartData.id],
        axesGroupName: EAxesGroupName.singleChart,
        withoutUpdatingGroupName: true,
      })
    );
  };

  const closeFullScreenModal = () => {
    setIsDatasetFullScreenOpen(false);

    if (prevSpecificSettings.current?.chartSettings && fullScreenChartData) {
      appDispatch(
        chartSettingsActions.setSpecificChartSettings({
          chartDataId: fullScreenChartData.id,
          settings: prevSpecificSettings.current?.chartSettings,
        })
      );
      appDispatch(
        histogramSettingsActions.setSpecificChartSettings({
          chartDataId: fullScreenChartData.id,
          settings: prevSpecificSettings.current?.histogramSettings,
        })
      );
      appDispatch(
        gatesActions.setSpecificChartSettings({
          chartDataId: fullScreenChartData.id,
          settings: prevSpecificSettings.current?.gateSettings,
        })
      );
      appDispatch(
        scatterplotsActions.setSpecificChartSettings({
          chartDataId: fullScreenChartData.id,
          settings: prevSpecificSettings.current?.scatterplotSettings,
        })
      );
      prevSpecificSettings.current = null;
    } else {
      appDispatch(chartSettingsActions.clearSpecificDatasetOptionMap({ chartDataId: fullScreenChartData?.id }));
    }
    appDispatch(chartSettingsActions.clearFullScreenChartData());
    appDispatch(scatterplotsActions.setDensityBandWidth(DEFAULT_BANDWIDTH));
    appDispatch(gatesActions.setActiveGate(null));
    setShouldApplyChangesToAllDatasets(false);
  };

  const isSomePlotLoading = useMemo(() => {
    const values = Object.values(loadedChartMap);
    if (!values.length) {
      return false;
    }
    return values.some((value) => !value);
  }, [loadedChartMap]);

  useEffect(() => {
    setLoadedChartMap((prev) => ({
      ...prev,
      ...chartsInfo.reduce((acc: Record<string, boolean>, el) => {
        if (prev[el.data.dataset.path]) {
          return acc;
        }
        acc[el.data.dataset.path] = false;
        return acc;
      }, {}),
    }));
  }, [chartsInfo]);

  const handleIsLoaded = useCallback((path: string, isLoaded: boolean) => {
    setLoadedChartMap((prev) => ({ ...prev, [path]: isLoaded }));
  }, []);

  const chartsContainerRef = useRef<Nullable<HTMLDivElement>>(null);

  const { handleAxisChange, rangesDataMap } = useMatrixRanges(chartDataList);
  const fullScreenDatasetRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(fullScreenChartData?.id)());

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(contextChartId));
  const { optionList: cageLevelMultiLanesAxesOptionList } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(chartDataList, isObjectEntityEnabled)
  );

  const cageLevelAxesOptionListByLanes = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionListByLanes);

  const plotAxesOptionList = useMemo(
    () =>
      fullScreenChartData
        ? getPlotAxesOptionList(fullScreenChartData, isObjectEntityEnabled, cageLevelAxesOptionListByLanes)
        : [],
    [fullScreenChartData, isObjectEntityEnabled, cageLevelAxesOptionListByLanes]
  );

  const datasetIdsList = useMemo(
    () => chartDataList.map((chartData) => ({ scanId: chartData.scanId, laneId: chartData.laneId })),
    [chartDataList]
  );

  const scatterPlotObjectAxesOptions = useMemo(
    () => chartDataList.flatMap((chartData) => getScatterPlotObjectLevelAxesOptions(chartData.channelList)),
    [chartDataList]
  );
  const scatterPlotCageAxesOptions = useMemo(
    () => cageLevelMultiLanesAxesOptionList,
    [cageLevelMultiLanesAxesOptionList]
  );

  useEffect(() => {
    setChartsInfo((prev) =>
      chartDataList.map((el, index) => {
        const chartInfoId = prev[index]?.data?.id === el.id && prev[index]?.id ? prev[index].id : uuidv4();
        return { data: el, id: chartInfoId ?? uuidv4() };
      })
    );
  }, [chartDataList]);

  useEffect(() => {
    if (!isColumnsChangeEnd && chartsContainerRef.current) {
      const currentState = Flip.getState(chartsContainerRef.current.children);
      chartsContainerRef.current.style.gridTemplateColumns = `repeat(${chartColumns}, 1fr)`;
      Flip.from(currentState, {
        duration: 0.8,
        ease: 'power1.inOut',
        absolute: true,
        onComplete: () => {
          setIsColumnsChangeEnd(true);
        },
      });
    }
  }, [chartColumns, isColumnsChangeEnd]);

  useEffect(() => {
    setIsColumnsChangeEnd(false);
  }, [chartColumns]);

  useEffect(() => {
    setIsFullWidthAnimationEnd(false);
    const timeout = setTimeout(() => {
      setIsFullWidthAnimationEnd(true);
    }, 800);
    return () => {
      clearTimeout(timeout);
    };
  }, [isFullWidth]);

  const handleIsOpenChange = useCallback((newIsOpen: boolean) => {
    setIsFullWidth(newIsOpen);
  }, []);

  const handleChangeScan = (chartDataId: string) => (scanId?: string) => {
    if (!scanId) return;

    toScanIdRef.current = scanId;
    let newChartDataIds: Nullable<TDatasetDetailsIdData> = null;
    const newChartDataIdsList = chartDataList.map<TDatasetDetailsIdData>((el) => {
      const newIds = {
        laneId: el.laneId,
        scanId: el.id === chartDataId ? scanId : el.scanId,
        channelId: el.channelId,
      };

      if (el.id === chartDataId) {
        newChartDataIds = { ...newIds };
      }

      return { ...newIds };
    });

    const newLink = generateAnalyticsLink(newChartDataIdsList);
    navigate(newLink);

    if (!newChartDataIds) return;

    const [newFullScreenChartData] = getChartDataListFromIdList(scanList, [newChartDataIds]);

    if (newFullScreenChartData) {
      appDispatch(
        chartSettingsActions.setFullScreenChartData({
          ...newFullScreenChartData,
        })
      );
    }
  };

  const handleChangeChannel = (chartDataId: string) => (newChannelName?: string) => {
    if (!newChannelName) return;

    let newChartDataIds: Nullable<TDatasetDetailsIdData> = null;
    const newChartDataIdsList = chartDataList.map<TDatasetDetailsIdData>((el) => {
      const newIds = {
        laneId: el.laneId,
        scanId: el.scanId,
        channelName: el.id === chartDataId ? newChannelName : el.channelName,
      };

      if (el.id === chartDataId) {
        newChartDataIds = { ...newIds };
      }

      return { ...newIds };
    });
    const newLink = generateAnalyticsLink(newChartDataIdsList);
    navigate(newLink);

    if (!newChartDataIds) return;

    const [newFullScreenChartData] = getChartDataListFromIdList(scanList, [newChartDataIds]);

    if (!newFullScreenChartData) return;
    appDispatch(
      chartSettingsActions.setFullScreenChartData({
        ...newFullScreenChartData,
      })
    );

    const axesPresetOptionList = prepareAxesPresetOptionList(
      newFullScreenChartData?.channelList,
      isObjectEntityEnabled
    );
    const newAxes = getBasicAxes(axesPresetOptionList, plotAxesOptionList, isObjectEntityEnabled, newChannelName);

    appDispatch(
      scatterplotsActions.setAxes({
        newAxes: { ...newAxes, isObjectEntityEnabled },
        axesGroupName: EAxesGroupName.singleChart,
        withoutUpdatingGroupName: true,
      })
    );
  };

  const handleSaveClick = () => {
    if (!fullScreenChartData) {
      closeFullScreenModal();
      return;
    }

    if (!isSomeChartSettingsChanged) {
      appDispatch(chartSettingsActions.clearSpecificDatasetOptionMap({ chartDataId: fullScreenChartData?.id }));
      closeFullScreenModal();
    }

    if (shouldApplyChangesToAllDatasets) {
      appDispatch(
        chartSettingsActions.setSpecificChartSettingsToAllDatasets({
          fullScreenChartDataId: fullScreenChartData?.id ?? '',
        })
      );

      if (isSomeAxesChanged) {
        appDispatch(
          scatterplotsActions.setAxes({
            newAxes: fullScreenDatasetAxes,
          })
        );
      }
      appDispatch(chartSettingsActions.clearFullScreenChartData());
      appDispatch(chartSettingsActions.clearAllSpecificChartSettings());
    } else {
      appDispatch(
        scatterplotsActions.setAxes({
          chartDataId: fullScreenChartData.id,
          newAxes: fullScreenDatasetAxes,
        })
      );

      // update range for dataset on matrix view
      if (fullScreenDatasetRange) {
        appDispatch(
          chartSettingsActions.setPlotRange({
            range: fullScreenDatasetRange,
            rangeName: rangesDataMap[fullScreenChartData.id]?.name,
          })
        );
      }
    }

    appDispatch(scatterplotsActions.setDensityBandWidth(DEFAULT_BANDWIDTH));
    setIsDatasetFullScreenOpen(false);
    appDispatch(chartSettingsActions.setFullScreenChartData(null));
    setShouldApplyChangesToAllDatasets(false);
  };

  const handleSave = (isForAll: boolean) => {
    if (!isForAll || !toScanIdRef.current) {
      return;
    }
    const newDatasets = chartDataList.map<TDatasetDetailsIdData>((el) => ({
      laneId: el.laneId,
      scanId: toScanIdRef.current,
      channelId: el.channelId,
    }));
    const newLink = generateAnalyticsLink(newDatasets);
    navigate(newLink);
  };

  const dndChart = useCallback((dragIndex: number, hoverIndex: number) => {
    setChartsInfo((prevData) =>
      update(prevData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevData[dragIndex]],
        ],
      })
    );
  }, []);

  const gatesProps = useMemo(
    () => ({
      createGate: createExperimentGate,
      entityLevelGateList,
      fullGateList,
      updateGate: updateExperimentGate,
      deleteGateChildren: deleteExperimentGateChildren,
      deleteGate: deleteExperimentGate,
      isUpdateGatesInProgress,
    }),
    [
      createExperimentGate,
      entityLevelGateList,
      fullGateList,
      updateExperimentGate,
      deleteExperimentGateChildren,
      deleteExperimentGate,
      isUpdateGatesInProgress,
    ]
  );

  const datasetDataProps = useMemo(
    () => ({
      selectedChartData,
      datasetIdsList,
      scanList,
      entitiesByLanesAndGates,
      onSave: handleSave,
      scatterPlotObjectAxesOptions,
      scatterPlotCageAxesOptions,
      onAxisChange: handleAxisChange,
    }),
    [
      selectedChartData,
      datasetIdsList,
      scanList,
      entitiesByLanesAndGates,
      handleSave,
      scatterPlotObjectAxesOptions,
      scatterPlotCageAxesOptions,
      handleAxisChange,
    ]
  );

  const uiProps = useMemo(
    () => ({
      isTransitionEnd: isColumnsChangeEnd && isFullWidthAnimationEnd && !isSomePlotLoading,
      handleIsLoaded,
      moveChart: dndChart,
      chartColumns,
      openFullScreenModal,
      closeFullScreenModal,
    }),
    [
      isColumnsChangeEnd,
      isFullWidthAnimationEnd,
      isSomePlotLoading,
      handleIsLoaded,
      dndChart,
      chartColumns,
      openFullScreenModal,
      closeFullScreenModal,
    ]
  );

  useEffect(
    () => () => {
      appDispatch(chartSettingsActions.setFullScreenChartData(null));
      appDispatch(chartSettingsActions.clearSpecificDatasetOptionMap({ chartDataId: fullScreenChartData?.id }));
      appDispatch(chartSettingsActions.clearFullScreenChartData());
      prevSpecificSettings.current = null;
    },
    []
  );

  return (
    <div className={cn('scatterplot')}>
      <PlotChartIdContext.Provider value={fullScreenChartData?.id ?? null}>
        <DatasetFullScreen
          isOpen={isDatasetFullScreenOpen}
          closeModal={closeFullScreenModal}
          handleSaveClick={handleSaveClick}
          handleChangeScan={handleChangeScan}
          handleChangeChannel={handleChangeChannel}
          toggleShouldApplyChangesToAllDatasets={toggleShouldApplyChangesToAllDatasets}
          shouldApplyChangesToAllDatasets={shouldApplyChangesToAllDatasets}
          isShouldApplyChangesToAllDatasetsVisible={isShouldApplyChangesToAllDatasetsVisible}
        />
      </PlotChartIdContext.Provider>

      <ToggleableBlock isOpen={isFullWidth} onIsOpenChange={handleIsOpenChange}>
        <ToggleableBlock.Main>
          <div
            ref={chartsContainerRef}
            className={cn('scatterplot__charts', { scatterplot__charts_hidden: debouncedIsDatasetFullScreenOpen })}
            style={{
              gridTemplateColumns: `repeat(${initialChartColumns.current}, 1fr)`,
            }}
          >
            {chartsInfo?.map((datasetInfo, plotIndex) => {
              const key = datasetInfo.id;
              const { data: chartData } = datasetInfo;

              return (
                <PlotChartIdContext.Provider value={chartData.id} key={key}>
                  <DataScatterplotChart
                    key={key}
                    plotIndex={plotIndex}
                    canvasWebglType={ECanvasWebglContextType.virtualWebgl}
                    {...gatesProps}
                    {...datasetDataProps}
                    chartData={chartData}
                    plotId={key}
                    handleChangeScan={handleChangeScan(chartData.id)}
                    plotRangeName={rangesDataMap[chartData.id]?.name}
                    {...uiProps}
                  />
                </PlotChartIdContext.Provider>
              );
            })}
          </div>
        </ToggleableBlock.Main>
        <ToggleableBlock.Sidebar>
          <aside className={cn('scatterplot__gates')} id="gate-list">
            <GateList
              channelName={channelName}
              showDatasetsSelect
              cageEntitiesByLanesAndGates={cageEntitiesByLanesAndGates}
              changeSelectedChartData={changeSelectedChartData}
              chartDataList={chartDataList}
              currentAppLane={currentAppLane}
              entitiesByLanesAndGates={entitiesByLanesAndGates}
              entityLevelGateList={entityLevelGateList ?? []}
              isGateListLoading={isGateListLoading}
              isUpdateGatesInProgress={isUpdateGatesInProgress}
              selectedChartData={selectedChartData}
              updateGate={updateExperimentGate}
              deleteGate={deleteExperimentGate}
              deleteGateChildren={deleteExperimentGateChildren}
              scatterPlotAxesOptions={scatterPlotObjectAxesOptions}
              pageType={EPageWithChartType.matrixView}
            />
          </aside>
        </ToggleableBlock.Sidebar>
      </ToggleableBlock>
    </div>
  );
};

export default withChartData(
  withDefaultChartSettings(memo(Scatterplot), EPageWithChartType.matrixView),
  EPageWithChartType.matrixView
);
