import { v4 as uuidv4 } from 'uuid';

export const CELL_TYPES_FOR_PUPULATION = [
  {
    uuid: uuidv4(),
    id: '1',
    name: 'REAL CELLS gate',
    emptyLabel: 'Real cell population gate not defined',
    color: '#000',
    bgColor: '#ebebeb',
  },
];

export const CELL_TYPES_FOR_AFFECTED_CELLS: TCell[] = [
  {
    uuid: uuidv4(),
    id: '1',
    name: 'TARGET CELLS LIVE gate',
    label: 'Number of live target cells',
    emptyLabel: 'Target cells live gate not defined',
    axisChannel: 'cellYChannel',
    color: '',
    bgColor: '',
  },
  {
    uuid: uuidv4(),
    id: '2',
    name: 'TARGET CELLS DEAD gate',
    label: 'Number of dead target cells',
    emptyLabel: 'Target cells dead gate not defined',
    axisChannel: 'cellYChannel',
    color: '',
    bgColor: '',
  },
  {
    uuid: uuidv4(),
    id: '3',
    name: 'EFFECTOR CELLS LIVE gate',
    label: 'Number of live effector cells',
    emptyLabel: 'Effector cells live gate not defined',
    axisChannel: 'cellXChannel',
    color: '',
    bgColor: '',
  },
  {
    uuid: uuidv4(),
    id: '4',
    name: 'EFFECTOR CELLS DEAD gate',
    label: 'Number of dead effector cells',
    emptyLabel: 'Effector cells dead gate not defined',
    axisChannel: 'cellXChannel',
    color: '',
    bgColor: '',
  },
];
