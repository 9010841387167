import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { EPageWithChartType, EChartType } from '@/types/charts';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { TInitialHistogramSettingsState, histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import {
  getDataConfigList,
  getCoordinatesByAxesAndGate,
  getCorrectDimension,
  defineMinHistogramsNbinsX,
} from '@/helpers/charts/chartsData';
import { isHistogramsChartType, getLineHistogramCoordinates } from '@/helpers/charts/lineHistogram';
import { gatesSelectors } from '@/store/slices/gates';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { useDebounce } from '../useDebounce';
import { getChartsLayoutConfig } from '../datasetAnalysis/helpers';
import usePlotProxy from '../usePlotProxy';

type TUseHeatmapPlotSettingsResponse = {
  getDataConfigToUpdate: (currentDataList: TPlotData[]) => { x: number[][]; y: number[][]; nbinsx: number[] };
};

type TDefaultChartCurrentSettings = {
  isTickLabelsVisible: boolean;
};

type TUseHistogramPlotsSettings = {
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  defaultChartConfigs: TDefaultChartCurrentSettings;
  isPlotLoaded?: boolean;
  settingsPreset?: TInitialHistogramSettingsState;
  customXAxis?: string;
  datasetName?: string;
  pageType?: EPageWithChartType;
  plotRangeName?: string;
  origDataRange?: TPlotAxisRange;
  dimensionsMapping?: Record<string, Record<string, string>>;
};

export function useHistogramPlotsSettings({
  graphRef,
  defaultChartConfigs,
  customXAxis,
  isPlotLoaded = true,
  datasetName = '',
  pageType,
  plotRangeName,
  origDataRange,
  dimensionsMapping,
}: TUseHistogramPlotsSettings): TUseHeatmapPlotSettingsResponse {
  const { isTickLabelsVisible } = defaultChartConfigs;
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const storedXAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const xAxis = useMemo(() => customXAxis ?? storedXAxis, [customXAxis, storedXAxis]);
  const yAxis = useMemo(() => xAxis, [xAxis]); // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis

  const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(chartId));
  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const isStackedChartsChecked = useSelector(histogramSettingsSelectors.selectIsStackedChartsChecked);
  const customHistogramBinsAmount = useSelector(histogramSettingsSelectors.selectCustomHistogramBinsAmount(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const kernelBinsAmountMultiplier = useSelector(histogramSettingsSelectors.selectKernelBinsAmountMultiplier(chartId));
  const kernelBandwidthCoefficient = useSelector(histogramSettingsSelectors.selectKernelBandwidthCoefficient(chartId));

  const debouncedCustomHistogramBinsAmount = useDebounce(customHistogramBinsAmount, 150);
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)(plotRangeName));

  const getNewFillConfig = useCallback(() => {
    if (!graphRef.current?.data || !graphRef.current.data.length) {
      return {};
    }

    return {
      fill: isChartFillEnabled ? 'tozeroy' : null,
      fillcolor: isChartFillEnabled ? 'default' : 'rgba(0,0,0,0)',
    };
  }, [graphRef.current?.data, isChartFillEnabled]);

  const getDataConfigToUpdate = useCallback(
    (currentDataList: TPlotData[]) => {
      const updatedData: { x: number[][]; y: number[][]; nbinsx: number[] } = {
        x: [],
        y: [],
        nbinsx: [],
      };

      currentDataList.forEach((currentDataConfig) => {
        const entityList = currentDataConfig.customdata;
        const { coordinates } = getCoordinatesByAxesAndGate({
          entityList,
          xAxis: getCorrectDimension(xAxis, currentDataConfig.legendgrouptitle, dimensionsMapping),
          yAxis: getCorrectDimension(yAxis, currentDataConfig.legendgrouptitle, dimensionsMapping),
        });

        const newCoordinates = getLineHistogramCoordinates({
          coords: { x: [...coordinates.x], y: [...coordinates.y] },
          customBinsAmount: debouncedCustomHistogramBinsAmount,
          currentHistogramDataGroupType,
          xAxisScaleType,
          kernelBinsAmountMultiplier,
          kernelBandwidthCoefficient,
        });

        updatedData.x.push(newCoordinates.x);
        updatedData.y.push(newCoordinates.y);
        // length of x points in line histogram === number of nbinsx for histogram
        updatedData.nbinsx.push(defineMinHistogramsNbinsX(newCoordinates.x.length));
      });

      return updatedData;
    },
    [
      xAxis,
      yAxis,
      currentHistogramDataGroupType,
      debouncedCustomHistogramBinsAmount,
      kernelBandwidthCoefficient,
      kernelBinsAmountMultiplier,
      datasetName,
      dimensionsMapping,
    ]
  );

  useEffect(() => {
    if (!isPlotLoaded || !graphRef.current?.data) return;
    const update = getNewFillConfig();
    plotlyProxy.restyle({ ...update });
  }, [isChartFillEnabled]);

  useEffect(() => {
    if (!isPlotLoaded || !graphRef.current?.data) return;
    const updatedData = getDataConfigToUpdate(graphRef.current.data);

    let dataToUpdate = null;

    if (currentChartType === EChartType.lineHistogram) {
      dataToUpdate = {
        x: updatedData.x,
        y: updatedData.y,
      };
    } else if (currentChartType === EChartType.histogram) {
      dataToUpdate = {
        nbinsx: updatedData.nbinsx,
      };
    }

    if (!dataToUpdate) return;

    plotlyProxy.forceRestyle(dataToUpdate);
  }, [currentHistogramDataGroupType, debouncedCustomHistogramBinsAmount]);

  useEffect(() => {
    if (!isPlotLoaded || !graphRef.current?.data || currentChartType !== EChartType.lineHistogram) return;

    const updatedData = getDataConfigToUpdate(graphRef.current.data);

    const dataToUpdate = {
      x: updatedData.x.map((x) => axisScaleHelper.preparePlotData(xAxisScaleType, x, origDataRange?.x)),
      y: updatedData.y.map((y) => axisScaleHelper.preparePlotData(yAxisScaleType, y, origDataRange?.y)),
    };

    plotlyProxy.forceRestyle(dataToUpdate);
  }, [kernelBandwidthCoefficient, kernelBinsAmountMultiplier]);

  useEffect(() => {
    if (
      !isPlotLoaded ||
      !graphRef.current?.data ||
      graphRef.current.data.length === 0 ||
      !graphRef.current.layout ||
      !isHistogramsChartType(currentChartType)
    ) {
      return;
    }

    const cageDataList = graphRef.current.data.map((currentDataConfig) => ({
      cageList: currentDataConfig.customdata,
      dataName: currentDataConfig.name as string | undefined,
      datasetName: currentDataConfig.legendgrouptitle,
    }));

    const dataConfigList = getDataConfigList({
      cageDataList,
      xAxis,
      yAxis,
      xAxisScaleType,
      yAxisScaleType,
      currentChartType,
      currentColorScale,
      isChartFillEnabled,
      isStackedChartsChecked,
      isStackedAndFilledEnabled: isStackedAndFilledEnabled && isStackedChartsChecked,
      currentHistogramDataGroupType,
      customHistogramBinsAmount,
      kernelBinsAmountMultiplier,
      pageType,
      kernelBandwidthCoefficient,
      selectedGate,
      origDataRange,
      dimensionsMapping,
    });

    const layoutConfig = getChartsLayoutConfig({
      dataCount: dataConfigList.length,
      currentChartType,
      xAxisScaleType,
      yAxisScaleType,
      isTickLabelsVisible,
      dragmode: true,
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      pageType,
      range: plotRange,
    });

    plotlyProxy.react(dataConfigList, layoutConfig);
  }, [isStackedChartsChecked, isStackedAndFilledEnabled]);

  return {
    getDataConfigToUpdate,
  };
}
