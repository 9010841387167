import { FC, useEffect, useMemo, useState, useCallback, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames/bind';

import { showErrorToast } from '@/helpers/errors';

import { useOpenModal } from '@/hooks/useOpenModal';
import { usePageControlsConfig } from '@/hooks/runDesign/usePageControlsConfig';

import PagePanel from '@/components/common/PagePanel';
import type { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';
import DesignCompleteModal from '@/components/runDesign/DesignCompleteModal';

import { EDesignStep } from '../types';

import { useRunDesignLocation } from '../hooks/useRunDesignLocation';
import { useEditDesignData } from '../hooks/useEditDesignData';

import { RunDesignContext } from '../context';

import LanesTotal from './components/LanesTotal';
import CellTypeMax from './components/CellTypeMax';
import RunDetailsMetadata from './components/RunDetailsMetadata';

import styles from './RunDetails.module.scss';
import IncubationsTotal from './components/IncubationsTotal';

const cn = classNames.bind(styles);

const showValidationErrors = (validationErrors: Record<string, string>) => {
  showErrorToast(`The following fields have incorrect values: ${Object.values(validationErrors).join(', ')}`);
};

const RunDetails: FC = () => {
  const { isLoading, isError } = useEditDesignData();
  const { goToNextStep, goToPrevStep, currentStep } = useRunDesignLocation();
  const { isOpen: isCompleteModalOpen, open: openCompleteModal, onClose: closeCompleteModal } = useOpenModal();

  const [footerConfig, setFooterConfig] = useState({});
  const [title, setTitle] = useState<ReactNode>('');
  const [customFooterContent, setCustomFooterContent] = useState<ReactNode>(null);
  const [saveAndContinueMethod, setSaveAndContinueMethod] = useState<() => void>(() => null);
  const [customFooterConfig, setCustomFooterConfig] = useState<Partial<TDefaultPageControlsConfig>>({});
  const [customHeaderContent, setCustomHeaderContent] = useState<ReactNode>(null);

  const { pageControlsConfig, handleUpdateRunDesign } = usePageControlsConfig(showValidationErrors, openCompleteModal);

  useEffect(() => {
    setFooterConfig({ ...pageControlsConfig, ...customFooterConfig });
  }, [pageControlsConfig, customFooterConfig]);

  const overrideCustomFooterConfig = useCallback(
    (overrideValue: Partial<TDefaultPageControlsConfig>) => {
      setCustomFooterConfig((prev) => ({ ...prev, ...overrideValue }));
    },
    [goToNextStep, goToPrevStep]
  );

  const clearCustomFooterConfig = useCallback(() => {
    setCustomFooterConfig({});
  }, []);

  const addCustomControls = useCallback(setCustomFooterContent, []);
  const removeCustomControls = useCallback(() => setCustomFooterContent(null), []);

  const addCustomHeaderContent = useCallback((node: ReactNode) => {
    setCustomHeaderContent(node);
  }, []);

  const clearCustomHeaderContent = useCallback(() => {
    setCustomHeaderContent(null);
  }, []);

  const runDesignContextValue = useMemo(
    () => ({
      footerConfig,
      overrideCustomFooterConfig,
      addCustomControls,
      removeCustomControls,
      setTitle,
      saveAndContinueMethod,
      setSaveAndContinueMethod,
      clearCustomFooterConfig,
      addCustomHeaderContent,
      clearCustomHeaderContent,
      handleUpdateRunDesign,
    }),
    [
      footerConfig,
      overrideCustomFooterConfig,
      setTitle,
      saveAndContinueMethod,
      setSaveAndContinueMethod,
      clearCustomFooterConfig,
      clearCustomHeaderContent,
      addCustomHeaderContent,
      handleUpdateRunDesign,
    ]
  );

  const conditionalProps = useMemo(() => {
    if (customFooterContent) {
      return {
        customControls: customFooterContent,
      };
    }

    return {
      config: footerConfig,
    };
  }, [footerConfig, customFooterContent]);

  return (
    <RunDesignContext.Provider value={runDesignContextValue}>
      <PagePanel title="Run details" className={cn('run-details')} isLoading={isLoading} {...conditionalProps}>
        <div className={cn('run-details__header')}>
          {customHeaderContent ?? (
            <>
              <h3 className={cn('run-details__title')}>{title}</h3>
              {!isLoading && !isError && (
                <div className={cn('run-details__header-data')}>
                  <LanesTotal isChangeAllowed={currentStep === EDesignStep.sample} />
                  {currentStep === EDesignStep.sample && <CellTypeMax isChangeAllowed />}
                  {currentStep === EDesignStep.incubation && <IncubationsTotal isChangeAllowed />}
                  <RunDetailsMetadata />
                </div>
              )}
            </>
          )}
        </div>
        {!isLoading && !isError && <Outlet />}
        {isCompleteModalOpen && <DesignCompleteModal isOpen={isCompleteModalOpen} closeModal={closeCompleteModal} />}
      </PagePanel>
    </RunDesignContext.Provider>
  );
};

export default RunDetails;
