import { createSlice } from '@reduxjs/toolkit';
import { THeader } from '@/store/slices/header/types';

const initialState: THeader = {
  links: [],
  currentLink: '',
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setNewLink: (state, action) => {
      let isInArray = false;
      state.links.forEach((elem) => {
        if (elem.link === action.payload.link && elem.title === action.payload.title) {
          isInArray = true;
        }
      });

      if (!isInArray) {
        state.links.push(action.payload);
      }
    },

    removeLink: (state) => {
      state.links.pop();
    },

    removeAllLinks: (state) => {
      state.links.splice(0, state.links.length);
    },
  },
});

export default headerSlice;
