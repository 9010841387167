import { FC, useCallback } from 'react';
import classNames from 'classnames/bind';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import { CellIndex, Reagent } from '@/graphql/API';

import AddToTableButton from '@/components/runDesign/AddToTableButton';

import Removable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/Removable';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context';
import useCellNameSuggestions from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useCellNameSuggestions';
import useCellNameSearch from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useCellNameSearch';

import useCellNameFilter from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/CellnameFilterBlock/useCellNameFilter';
import CellNameFilterBlock from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/CellnameFilterBlock';
import {
  cellNameSearchResultInformationList,
  cellNameSearchResultsGridLayout,
  CellNameSearchResultsHeading,
  CellNameSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/CellNameSearchResults';
import { isDefined } from '@/helpers/typeGuards';

import PreSelectedCellTypeName from '@/components/runDesign/ConsumableComponent/PreSelectedCellTypeName';
import styles from '../../EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeNameProps = {
  cellIndex?: Nullable<CellIndex>;
  preSelectedCellIndex?: Nullable<CellIndex>;
  laneIndex: number;
  laneId: string;
  onNameChange: (runDesignCardIndex: number, data: Nullable<{ name: string; id: string }>) => void;
  sampleTitle: string;
};

const EditCellTypeName: FC<TEditCellTypeNameProps> = ({
  cellIndex,
  preSelectedCellIndex,
  onNameChange,
  laneIndex,
  laneId,
  sampleTitle,
}) => {
  const handleChange = useCallback(
    (data: Nullable<CellIndex>) => {
      if (!data) {
        onNameChange(laneIndex, null);
        return;
      }
      onNameChange(laneIndex, {
        name: data.name,
        id: data.id,
      });
    },
    [laneIndex, onNameChange]
  );

  const { openModal, setConfig } = useReagentModalContext();
  const { getSuggestions } = useCellNameSuggestions();
  const { getData } = useCellNameSearch();

  const handleAddNewClick = () => {
    setConfig({
      title: `Lane ${laneId} / ${sampleTitle}`,
      description: `Search for ${sampleTitle}. Use filters to narrow down your search.`,
      search: {
        placeholder: 'Cell line name',
        getSuggestions,
        getData,
        result: {
          headingRenderer: CellNameSearchResultsHeading,
          rowRenderer: CellNameSearchResultsRow,
          cssGridLayout: cellNameSearchResultsGridLayout,
          informationList: cellNameSearchResultInformationList,
        },
      },
      filter: {
        hook: useCellNameFilter,
        renderer: CellNameFilterBlock,
      },
      submitButtonText: 'Add cell',
      onSelect: (selectedReagent: Nullable<Reagent>) => {
        onNameChange(laneIndex, selectedReagent);
      },
    });

    openModal();
  };

  const handleEditClick = () => {
    setConfig({
      title: `Lane ${laneId} / ${sampleTitle}`,
      description: `Search for ${sampleTitle}. Use filters to narrow down your search.`,
      current: cellIndex,
      search: {
        placeholder: 'Cell line name',
        getSuggestions,
        getData,
        result: {
          headingRenderer: CellNameSearchResultsHeading,
          rowRenderer: CellNameSearchResultsRow,
          cssGridLayout: cellNameSearchResultsGridLayout,
          informationList: cellNameSearchResultInformationList,
        },
      },
      filter: {
        hook: useCellNameFilter,
        renderer: CellNameFilterBlock,
      },
      submitButtonText: 'Add cell',
      onSelect: (selectedReagent: Nullable<Reagent>) => {
        onNameChange(laneIndex, selectedReagent);
      },
    });

    openModal();
  };

  const removeCellName = () => {
    handleChange(null);
  };

  const handlePreselectedClick = useCallback(() => {
    if (!isDefined(preSelectedCellIndex)) {
      return;
    }

    handleChange(preSelectedCellIndex);
  }, [preSelectedCellIndex]);

  return (
    <div
      className={cn('cell-content', 'cell-content_name', SELECTABLE_CLASS_NAME)}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 0, dataType: 'cellIndex' })}
    >
      {!cellIndex?.name && !!preSelectedCellIndex && (
        <PreSelectedCellTypeName
          onClick={handlePreselectedClick}
          cellIndex={preSelectedCellIndex}
          onPlusClick={handleAddNewClick}
        />
      )}

      {!cellIndex?.name && !preSelectedCellIndex && <AddToTableButton onClick={handleAddNewClick} text="Add cell" />}

      {!!cellIndex?.name && (
        <Removable onRemove={removeCellName}>
          <button className={cn('cell-content__name-block')} onClick={handleEditClick}>
            {cellIndex?.name}
          </button>
        </Removable>
      )}
    </div>
  );
};

export default EditCellTypeName;
