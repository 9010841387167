export enum EGatesLabelFormat {
  none = 'none',
  nameAndPercent = 'nameAndPercent',
  name = 'name',
  percent = 'percent',
}

export enum EGateLabelType {
  default = 'default',
  minimized = 'minimized',
}
