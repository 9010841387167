import { FC, Children, cloneElement, useMemo, useId, ReactElement, MouseEventHandler } from 'react';
import classnames from 'classnames/bind';
import { CSSProperty, TPanelColor } from '@/helpers/interfaces';
import { themeOptions } from '@/types/theme';
import styles from './Panel.module.scss';
import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';
import PanelDescription from './PanelDescription';

const cn = classnames.bind(styles);

type TPanelProps = {
  children: ReactElement | ReactElement[];
  className?: string;
  id?: string;
  type?: TPanelColor;
  style?: CSSProperty;
  rootRef?: null;
  onClick?: MouseEventHandler<HTMLDivElement>;
  theme?: themeOptions;
};

type TPanelExtensions = {
  Content: typeof PanelContent;
  Header: typeof PanelHeader;
  Description: typeof PanelDescription;
};

const Panel: FC<TPanelProps> & TPanelExtensions = ({
  children,
  className,
  type = 'default',
  style,
  rootRef,
  onClick = () => null,
  id,
  theme = 'dark',
}) => {
  const renderedId = useId();
  const panelId = useMemo(() => id ?? `panel__${renderedId}`, [renderedId]);
  return (
    <div
      className={cn('panel', `panel_${type}`, `panel_${theme}`, className)}
      data-panel="true"
      style={style}
      ref={rootRef}
      onClick={onClick}
      role="presentation"
      id={panelId}
      data-test-id={panelId}
    >
      {children && Children.map(children, (child) => child && cloneElement(child, { type }))}
    </div>
  );
};

Panel.Content = PanelContent;
Panel.Header = PanelHeader;
Panel.Description = PanelDescription;

export default Panel;
