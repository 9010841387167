import { Id, toast } from 'react-toastify';

import { getErrorMessage } from '@/helpers/errors';
import { TOAST_DELAY } from '@/helpers';

const defaultToastOptions = {
  isLoading: false,
  autoClose: TOAST_DELAY,
  closeButton: true,
  closeOnClick: true,
};

export const endGeneratedEventWithError = ({ error, toastId }: { error?: unknown; toastId: Nullable<Id> }) => {
  if (toastId) {
    toast.update(toastId, {
      render: getErrorMessage(error, 'Preprocessing failed'),
      type: 'error',
      ...defaultToastOptions,
    });
    return;
  }

  toast.error(getErrorMessage(error, 'Preprocessing failed'), {
    ...defaultToastOptions,
  });
};

export const endGeneratedEventWithSuccess = ({ toastId }: { toastId: Nullable<Id> }) => {
  if (toastId) {
    toast.update(toastId, {
      render: 'Data preprocessing successfully completed.',
      type: 'success',
      ...defaultToastOptions,
    });
    return;
  }

  toast.success('Data preprocessing successfully completed.', {
    ...defaultToastOptions,
  });
};

export const getDatasetsToPreprocess = (
  experimentId: string,
  datasetDetails: TDatasetDetails[]
): Map<string, boolean> => {
  const list = new Map();

  datasetDetails.forEach((datasetDetailsItem) => {
    const key = `${experimentId}-${datasetDetailsItem.scanId}-${datasetDetailsItem.laneId}`;

    list.set(key, false);
  });

  return list;
};

export const defineIsAllDatasetsProcessed = (datasetsToProcessMap: Map<string, boolean>) => {
  const values = Array.from(datasetsToProcessMap.values());

  return values.every((isProcessed) => isProcessed);
};
