import { SVGProps } from 'react';

const AlignmentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" {...props}>
    <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M16 1H1M11 5H1M16 9H1M11 13H1" />
    </g>
  </svg>
);

export default AlignmentIcon;
