import { FC } from 'react';
import classnames from 'classnames/bind';
import { MDASH } from '@/helpers';
import NoDataFound from '@/components/common/NoDataFound';
import styles from './SettingValueTable.module.scss';

const cn = classnames.bind(styles);

type TSettingValueTable = {
  value: TSettingOptionVariableValue;
};

const SettingValueTable: FC<TSettingValueTable> = ({ value }) => {
  if (!Array.isArray(value) || !value.length || Array.isArray(value[0]) || !Object.keys(value[0]).length) {
    return <NoDataFound textData={MDASH} alignment="left" />;
  }

  const formatString = (str: string) => str.replaceAll(/[^A-Za-z0-9]/g, ' ').trim();

  return (
    <table className={cn('table')}>
      <thead className={cn('table__head')}>
        <tr className={cn('table__row', 'table__row_no-underline')}>
          {Object.keys(value[0]).map((settingHeading) => (
            <th key={settingHeading} className={cn('table__heading')}>
              {formatString(String(settingHeading))}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={cn('table__body')}>
        {value.map((tableLine, lineIndex) => (
          <tr key={lineIndex} className={cn('table__row')}>
            {Object.values(tableLine).map((settingData, dataIndex) => (
              <td key={dataIndex} className={cn('table__data-element')}>
                {String(settingData)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SettingValueTable;
