import { z } from 'zod';
import { PhoneNumberResultRegex } from '../helpers/validator';

export const inviteUserSchema = z.object({
  email: z.string().email('Invalid email'),
  phoneNumber: z.string().regex(PhoneNumberResultRegex, 'Invalid phone number').or(z.string().max(0)),
});

export const inviteUserToTeamSchema = z.object({
  username: z.string().min(1, 'Invalid name'),
  teamId: z.string().min(1, 'Invalid team'),
});

export const createTeamSchema = z.object({
  teamName: z.string().min(1, 'Invalid team name'),
});

export const addProjectToTeamSchema = z.object({
  projectId: z.string().min(1, 'Invalid project'),
  teamId: z.string().min(1, 'Invalid team'),
  roles: z.string().array().min(1).max(3),
});
