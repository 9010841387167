import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './LineLoader.module.scss';

const cn = classnames.bind(styles);

type TLineLoader = {
  isLoading: boolean;
  className?: string;
};
const LineLoader: FC<TLineLoader> = ({ isLoading, className }) => (
  <div className={cn('line-loader', { 'line-loader_animate': isLoading }, className)} />
);

export default LineLoader;
