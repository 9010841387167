import { SVGProps } from 'react';

const ElutionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none" {...props}>
    <path
      d="M22 2.92578C10.9543 2.92578 2 11.8801 2 22.9258C2 33.9715 10.9543 42.9258 22 42.9258C33.0457 42.9258 42 33.9715 42 22.9258"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path
      d="M22 12.9258C16.4772 12.9258 12 17.4029 12 22.9258C12 28.4486 16.4772 32.9258 22 32.9258C27.5228 32.9258 32 28.4486 32 22.9258"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path d="M30.832 4.78906L41.9948 4.78906" stroke="currentColor" strokeWidth="4" />
    <path d="M40.1328 14.0938L40.1328 2.93096" stroke="currentColor" strokeWidth="4" />
    <path
      d="M36.7782 11.3653C36.0596 12.3212 35.2658 13.2099 34.3968 14.0313C33.5381 14.8391 32.6672 15.6165 31.7844 16.3634C30.8926 17.1036 30.0379 17.8503 29.2202 18.6034C28.4127 19.3429 27.6857 20.1428 27.0389 21.0031C26.1355 22.2048 25.4509 23.3572 24.9849 24.4603C24.5203 25.543 24.2719 26.5425 24.2398 27.4587L20.8519 24.9119C20.9389 24.0155 21.1924 23.0092 21.6124 21.8929C22.0337 20.7563 22.7166 19.5598 23.661 18.3035C24.3899 17.334 25.1837 16.4453 26.0425 15.6375C26.9012 14.8297 27.7765 14.0557 28.6683 13.3155C29.5512 12.5686 30.4014 11.8186 31.2191 11.0654C32.0368 10.3123 32.7639 9.51235 33.4004 8.6657C34.3038 7.46401 34.9877 6.32181 35.4524 5.23911C35.9183 4.13604 36.1674 3.12636 36.1995 2.21009L39.5874 4.75696C39.5004 5.65333 39.2513 6.66301 38.8403 7.78598C38.4203 8.90224 37.7329 10.0954 36.7782 11.3653Z"
      fill="currentColor"
    />
  </svg>
);

export default ElutionIcon;
