import { SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <line y1="9.6499" x2="20" y2="9.6499" stroke="currentColor" strokeWidth="1.5" />
    <line x1="10.3496" y1="3.27835e-08" x2="10.3496" y2="20" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default PlusIcon;
