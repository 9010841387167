import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pointer, select as d3Select } from 'd3-selection';

import { EAxesScaleType, EModebarTools, EChartType } from '@/types/charts';
import { EGateLabelType, EGatesLabelFormat } from '@/types/gateSettings';

import { defineIsGateShouldBeDrawn, getGateById, isGateDimensionsReverted, revertPoint } from '@/helpers/gates';
import { isCircleTypeGate, isRangeTypeGate } from '@/helpers/typeGuards';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { isEmptyAxesRange } from '@/helpers/charts/ranges';
import { isHistogramsChartType } from '@/helpers/charts/lineHistogram';
import { getOrigDataRange } from '@/helpers/origDataRange';
import { isNumber } from '@/helpers';
import { MIN_LOG_SCALE_RANGE_POINT_VALUE } from '@/helpers/axisScaleHelper/logScaleStrategy';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { EAxesGroupName, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { datasetsSelectors } from '@/store/slices/datasets';
import { chartDataSelectors } from '@/store/slices/chartData';
import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { EStepName } from '@/store/slices/preprocessing/types';

import {
  changeGateColor,
  clearHighlightedGate,
  defineGatePointsByType,
  detectClickedGate,
  getGateXPositionOnSVG,
  getGateYPositionOnSVG,
  getPointsFromChartScale,
  hideGate,
  highlightActiveGate,
  setOnContextMenuHandlers,
} from './helpers/common';
import { usePolygonGates } from './usePolygonGates';
import { useRectangleGates } from './useRectangleGates';
import { useEllipseGates } from './useEllipseGates';
import { HARDCODED_TOP_Y_VALUE, toolsForDrawingGates, toolsWithMouseUpHandler } from './constants';
import { TDrawPolarPayload, usePolarGates } from './usePolarGates';
import { EGateDragType, TSelectionDivElement, TSelectionSVGSVGElement } from './types';
import type { TUseUpdateGateCallbacksType } from './useUpdateGates';
import { useRangeGate } from './useRangeGate';

type TUseGatesOnPlotResponse = {
  defineGatesLayoutParameters: () => void;
  newGateModel: Nullable<TNewGateModelData>;
  clearNewGateData: () => void;
  mouseUpEventRef: MutableRefObject<Nullable<MouseEvent>>;
  mouseDownEventRef: MutableRefObject<Nullable<MouseEvent>>;
  overflowContainer: TSelectionDivElement;
};

type TPayload = {
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  scanId: string;
  laneId: string;
  selectedTool: Nullable<EModebarTools>;
  entitiesDataByGates?: Nullable<TEntitiesByGates>;
  handlePlotlySelected?: (newGateData: Nullable<TNewGateModelData>) => void;
  isStatic?: boolean;
  plotId: string;
  chartClassName: string;
  onContextMenu?: (menuPosition: Record<'x' | 'y', number>) => void;
  isAllPlotDataLoaded: boolean;
  axesRange?: Nullable<Record<'x' | 'y', number[]>>;
  displayType?: EGateLabelType;
  isHoverInfoOpen?: boolean;
  entityLevelGateList?: TGate[];
  isObjectEntityEnabled?: boolean;
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
  createGate: TUseUpdateGateCallbacksType['createGate'];
  gates: TGate[];
  chartXAxis?: string;
  chartYAxis?: string;
  withoutGateLabel?: boolean;
  hideGates?: boolean;
  isEmptyData?: boolean;
  defaultPlotRange: Nullable<TPopulationRange>;
  specificAxesGroupName?: EAxesGroupName;
};

type TDrawGateByTypePayload = {
  gate: TGate;
  groupClassName: string;
  gatePolygons: TGatePolygonList;
  cagesData: TEntitiesByGates;
};

export function useGatesOnPlot({
  graphRef,
  scanId,
  laneId,
  selectedTool,
  entitiesDataByGates,
  handlePlotlySelected = () => null,
  isStatic = false,
  plotId,
  chartClassName,
  onContextMenu = () => null,
  isAllPlotDataLoaded,
  axesRange = null,
  displayType = EGateLabelType.default,
  isHoverInfoOpen,
  entityLevelGateList,
  isObjectEntityEnabled,
  updateGate,
  createGate,
  gates,
  chartXAxis = '',
  chartYAxis = '',
  withoutGateLabel = false,
  hideGates,
  isEmptyData,
  defaultPlotRange,
  specificAxesGroupName,
}: TPayload): TUseGatesOnPlotResponse {
  const appDispatch = useAppDispatch();
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const chartId = usePlotChartIdContext();
  const mouseUpEventRef = useRef<Nullable<MouseEvent>>(null);
  const mouseDownEventRef = useRef<Nullable<MouseEvent>>(null);

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const activeGate = useSelector(gatesSelectors.selectActiveGate);
  const isBlockDraw = useSelector(gatesSelectors.selectIsBlockDraw);
  const gateLabelFormat = useSelector(gatesSelectors.selectGateLabelFormat(chartId));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const preprocessingDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const currentPreprocessingStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const onlyGateToDisplay = useSelector(gatesSelectors.selectOnlyGateToDisplay);

  const activeGateRef = useRef(activeGate);
  const isMovingRef = useRef(false);
  const prevActiveGateRef = useRef<Nullable<TGate>>(null);

  const isGateMenuHidden = useMemo(
    () =>
      isStatic ||
      displayType === EGateLabelType.minimized ||
      (isNumber(preprocessingDatasetIndex) && currentPreprocessingStep !== EStepName.stepCellKillingDefineCellsTarget),
    [preprocessingDatasetIndex, displayType, isStatic, currentPreprocessingStep]
  );

  const isGateLabelsHidden = useMemo(
    () => withoutGateLabel || gateLabelFormat === EGatesLabelFormat.none,
    [withoutGateLabel, gateLabelFormat]
  );

  const handleGateDrag = useCallback(
    (gateId: string, type: EGateDragType, gate?: TGate) => {
      const currentGate = gate ?? getGateById(gateId, entityLevelGateList);

      const isDragStart = type === EGateDragType.dragStart;
      const isDrag = type === EGateDragType.drag;
      const isDragEnd = type === EGateDragType.dragEnd;
      const needClear = isDragEnd && !isMovingRef.current && prevActiveGateRef.current?.id === currentGate?.id;

      switch (true) {
        case isDragStart:
          prevActiveGateRef.current = activeGateRef.current;
          highlightActiveGate(gateId);
          appDispatch(gatesActions.setActiveGate(currentGate));
          break;
        case needClear:
          clearHighlightedGate();
          appDispatch(gatesActions.setActiveGate(null));
          break;
        default:
          isMovingRef.current = isDrag;
      }

      isMovingRef.current = isDrag;
    },
    [activeGate, entityLevelGateList]
  );

  useEffect(() => {
    if (hideGates) {
      return;
    }
    activeGateRef.current = activeGate;
    if (activeGate) {
      highlightActiveGate(activeGate.id);
    } else {
      clearHighlightedGate();
    }
  }, [activeGate]);

  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);

  const xAxisFromStore = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const yAxisFromStore = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const isGatesDrawn = useSelector(gatesSelectors.selectIsGatesDrawn);
  const xAxis = useMemo(() => chartXAxis || xAxisFromStore, [chartXAxis, xAxisFromStore]);
  const yAxis = useMemo(() => chartYAxis || yAxisFromStore, [chartYAxis, yAxisFromStore]);
  const [newGateModel, setNewGateModel] = useState<Nullable<TNewGateModelData>>(null);
  const allOrigDataRanges = useSelector(chartDataSelectors.selectAllOrigDataRanges);
  const origDataRange = useMemo(
    () =>
      getOrigDataRange({
        allOrigDataRanges,
        scanId,
        laneId,
        xAxis,
        yAxis: isHistogramsChartType(currentChartType) ? xAxis : yAxis,
      }),
    [allOrigDataRanges, scanId, laneId, xAxis, yAxis]
  );

  // Gates are drawn in linear coordinates
  const plotLinearRange = useMemo(() => {
    const currentRange =
      axesRange && !isEmptyAxesRange(axesRange)
        ? {
            xMin: axesRange.x[0],
            xMax: axesRange.x[1],
            yMin: axesRange.y[0],
            yMax: axesRange.y[1],
          }
        : defaultPlotRange;
    // For log scale log range is transformed to linear
    return axisScaleHelper.convertToLinearPlotRange({
      xAxisScaleType,
      yAxisScaleType,
      plotRange: currentRange,
    });
  }, [axesRange, defaultPlotRange]);

  const isOutOfThePositiveLogRange = useMemo(() => {
    const isInvalidX =
      xAxisScaleType === EAxesScaleType.log &&
      graphRef?.current?.layout?.xaxis?.range?.[0] < 0 &&
      plotLinearRange?.xMin === MIN_LOG_SCALE_RANGE_POINT_VALUE;
    const isInvalidY =
      yAxisScaleType === EAxesScaleType.log &&
      graphRef?.current?.layout?.yaxis?.range?.[0] < 0 &&
      plotLinearRange?.yMin === MIN_LOG_SCALE_RANGE_POINT_VALUE;

    return isInvalidX || isInvalidY;
  }, [
    xAxisScaleType,
    yAxisScaleType,
    graphRef?.current?.layout?.xaxis?.range,
    graphRef?.current?.layout?.yaxis?.range,
    plotLinearRange,
  ]);

  const chartContainerSelector = useMemo(() => `#${plotId} ${chartClassName}`, [chartClassName, plotId]);
  const [chartContainerParameters, setChartContainerParameters] = useState<Nullable<DOMRect>>(null);
  const [plotContainerParameters, setPlotContainerParameters] = useState<Nullable<DOMRect>>(null);
  const offsetLeft = useMemo(
    () =>
      chartContainerParameters && plotContainerParameters
        ? chartContainerParameters.left - plotContainerParameters.left
        : 0,
    [chartContainerParameters, plotContainerParameters]
  );
  const offsetTop = useMemo(
    () =>
      chartContainerParameters && plotContainerParameters
        ? chartContainerParameters.top - plotContainerParameters.top
        : 0,
    [chartContainerParameters, plotContainerParameters]
  );

  const xWidth = useMemo(() => (plotLinearRange ? plotLinearRange.xMax - plotLinearRange.xMin : 0), [plotLinearRange]); // x range
  const yWidth = useMemo(() => (plotLinearRange ? plotLinearRange.yMax - plotLinearRange.yMin : 0), [plotLinearRange]); // y range

  const onGateContextMenu = useCallback(
    (gateId: string, event: MouseEvent) => {
      const gate = getGateById(gateId, entityLevelGateList);

      if (!gate) return;

      appDispatch(gatesActions.setActiveGate(gate));
      highlightActiveGate(gateId);
      onContextMenu({ x: event.offsetX, y: event.offsetY });
    },
    [entityLevelGateList]
  );

  const openGateCreationModal = (newGateData: Nullable<TNewGateModelData>) => {
    setNewGateModel(newGateData);
    handlePlotlySelected(newGateData);
  };

  const onGateUpdateSuccess = (gate: TGate) => appDispatch(gatesActions.setActiveGate(gate));

  const handleGateUpdate = (gate: TGate, updatedGateData: Partial<TGate>) => {
    const currentGateOverrides: TGatesShapeOverrides[] =
      Array.isArray(gate?.overrides) && gate?.overrides.length ? gate.overrides : [];

    const isAlreadyHasOverridesForDataset = currentGateOverrides?.find(
      (data: TGatesShapeOverrides) => data.scanId === scanId && data.laneId === laneId
    );
    let overridesToUpdate: TGatesShapeOverrides[] = [...currentGateOverrides];

    if (isPreprocessingView) {
      updateGate(gate, updatedGateData, () => {
        onGateUpdateSuccess({ ...gate, ...updatedGateData });
      });

      return;
    }

    if (isAlreadyHasOverridesForDataset) {
      overridesToUpdate = currentGateOverrides.map((overrideData: TGatesShapeOverrides) => {
        if (overrideData.scanId === scanId && overrideData.laneId === laneId) {
          return {
            ...overrideData,
            shape: updatedGateData.shape as TGateShape,
          };
        }

        return overrideData;
      });
    } else {
      const newOverrideItem: TGatesShapeOverrides[] = [
        {
          scanId,
          laneId,
          shape: updatedGateData.shape as TGateShape,
        },
      ];

      overridesToUpdate = [...overridesToUpdate, ...newOverrideItem];
    }

    const updateGatePayload: Partial<TGate> = {
      overrides: [...overridesToUpdate],
    };

    updateGate(gate, updateGatePayload, () => {
      onGateUpdateSuccess({ ...gate, ...updateGatePayload });
    });
  };

  const hooksPayload = useMemo(
    () => ({
      chartContainerParameters,
      plotId,
      plotRange: plotLinearRange,
      xAxisScaleType,
      yAxisScaleType,
      isStatic,
      openGateCreationModal,
      displayType,
      entityLevelGateList: entityLevelGateList ?? [],
      isObjectEntityEnabled,
      updateGate: handleGateUpdate,
      createGate,
      gates,
      handleGateDrag,
      origDataRange,
      scanId,
      laneId,
      isGateMenuHidden,
      specificAxesGroupName,
    }),
    [
      chartContainerParameters,
      plotId,
      plotLinearRange,
      xAxisScaleType,
      yAxisScaleType,
      isStatic,
      openGateCreationModal,
      displayType,
      entityLevelGateList,
      isObjectEntityEnabled,
      updateGate,
      createGate,
      gates,
      handleGateDrag,
      origDataRange,
      scanId,
      laneId,
      isGateMenuHidden,
      specificAxesGroupName,
    ]
  );

  const {
    setNewRectangleData,
    drawRectangle,
    handleRectGateMouseDown,
    handleRectGateMouseMove,
    handleRectGateMouseUp,
  } = useRectangleGates({ ...hooksPayload });

  const { drawPolygon, handlePolygonMouseDown, handlePolygonMouseMove, newPolygonData, clearPolygonData } =
    usePolygonGates({ ...hooksPayload });

  const {
    handleEllipseGateMouseDown,
    handleEllipseGateMouseMove,
    handleEllipseGateMouseUp,
    clearEllipseData,
    drawEllipse,
  } = useEllipseGates({ ...hooksPayload });

  const { drawRangeGate, handleRangeMouseDown, handleRangeMouseMove, handleRangeMouseUp } = useRangeGate({
    ...hooksPayload,
  });

  const { handlePolarMouseDown, handlePolarMouseMove, handlePolarMouseUp, drawPolar } = usePolarGates({
    ...hooksPayload,
  });

  const defineGatesLayoutParameters = () => {
    if (!isAllPlotDataLoaded) return;
    const chartContainer = d3Select(chartContainerSelector).node() as HTMLElement; // plotly container with chart axes

    if (!chartContainer) return;

    const chartParameters = chartContainer.getBoundingClientRect();
    setChartContainerParameters(chartParameters);

    const plotContainer = d3Select(`#${plotId}`).node() as HTMLElement; // plot-chart - id of the main plot container

    if (!plotContainer) return;

    const plotParameters = plotContainer.getBoundingClientRect();
    setPlotContainerParameters(plotParameters);
  };

  const prepareLayoutForGates = useCallback(() => {
    if (!chartContainerParameters) return;
    // create an SVG layout for the shapes and place it exactly in the chart container (with the same width and height)
    const plotContainer = d3Select(`#${plotId}`);
    let shapesContainer: TSelectionSVGSVGElement = d3Select(`#${plotId} .gates-container`);
    let wrapper: TSelectionDivElement = d3Select(`#${plotId} .overflow-container`);

    if (!wrapper.node()) {
      wrapper = plotContainer.append('div').attr('class', 'overflow-container');
    }

    if (!shapesContainer.node()) {
      shapesContainer = wrapper.append('svg').attr('class', 'gates-container');
    }

    shapesContainer
      .attr('width', chartContainerParameters.width)
      .attr('height', chartContainerParameters.height)
      .style('top', `${offsetTop}px`)
      .style('left', `${offsetLeft}px`);
  }, [chartContainerParameters, offsetTop, offsetLeft]);

  const prepareGateToDraw = useCallback(
    ({ points, hardcodedValue = null }: { points: TGatePolygons; hardcodedValue: Nullable<number> }) => {
      if (!plotLinearRange || !points || !chartContainerParameters) return;
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;

      if (!shapesContainer) return;

      const shapesContainerParameters = shapesContainer.getBoundingClientRect(); // canvas svg container in which gates are drawn
      const res = points.map((item) => {
        const xPayload = {
          point: item.x,
          containerSize: shapesContainerParameters.width,
          chartMinValue: plotLinearRange?.xMin,
          chartMaxValue: plotLinearRange?.xMax,
        };
        const yPayload = {
          point: item.y,
          containerSize: shapesContainerParameters.height,
          chartMinValue: plotLinearRange?.yMin,
          chartMaxValue: plotLinearRange?.yMax,
        };
        const isYShouldNotBeParsed = hardcodedValue && hardcodedValue === item.y;

        return {
          x: getGateXPositionOnSVG(xPayload),
          y: isYShouldNotBeParsed ? item.y : getGateYPositionOnSVG(yPayload),
        };
      });

      return res;
    },
    [plotLinearRange, chartContainerParameters, xWidth, yWidth, plotId]
  );

  const getGateRadius = useCallback(
    (gate: TGate) => {
      if (!plotLinearRange || !chartContainerParameters || !isCircleTypeGate(gate.shape)) return;
      const shapesContainer = d3Select(`#${plotId} .gates-container`).node() as Element;
      const { model } = gate.shape;

      if (!shapesContainer) return;

      let radiusY = model.ry;
      let radiusX = model.rx;

      if (xAxis === gate.yDimension && yAxis === gate.xDimension && yAxis !== xAxis) {
        radiusY = model.rx;
        radiusX = model.ry;
      }

      const shapesContainerParameters = shapesContainer?.getBoundingClientRect(); // canvas svg container in which gates are drawn
      const yCenterOnChart = model.y;
      const yTopOnChart = radiusY + model.y;

      const xCenterOnChart = model.x;
      const xTopOnChart = radiusX + model.x;

      const xPayload = {
        containerSize: shapesContainerParameters.width,
        chartMinValue: plotLinearRange?.xMin,
        chartMaxValue: plotLinearRange?.xMax,
      };

      const yPayload = {
        containerSize: shapesContainerParameters.height,
        chartMinValue: plotLinearRange?.yMin,
        chartMaxValue: plotLinearRange?.yMax,
      };

      const yTopPointInSVG = getGateYPositionOnSVG({ ...yPayload, point: yTopOnChart });
      const yCenterPointOnSVG = getGateYPositionOnSVG({ ...yPayload, point: yCenterOnChart });
      const xTopPOointInSVG = getGateXPositionOnSVG({ ...xPayload, point: xTopOnChart });
      const xCenterPointOnSVG = getGateXPositionOnSVG({ ...xPayload, point: xCenterOnChart });

      const ry = Math.floor(yCenterPointOnSVG - yTopPointInSVG);
      const rx = Math.floor(xCenterPointOnSVG - xTopPOointInSVG);

      return {
        ry: Math.abs(ry),
        rx: Math.abs(rx),
      };
    },
    [plotLinearRange, chartContainerParameters, xWidth, yWidth, xAxis, yAxis]
  );

  const drawGateByType = ({ gate, groupClassName, gatePolygons, cagesData }: TDrawGateByTypePayload) => {
    const { properties } = gate;
    const type: TGatePropertyType = properties?.type || 'polygon';
    const gateStatistics = cagesData?.[gate.id];
    const cagesPercentWithDividerStr = gateStatistics?.cagesPercent ? `| ${gateStatistics?.cagesPercent}` : '';

    const lableByFormat = {
      [EGatesLabelFormat.none]: '',
      [EGatesLabelFormat.nameAndPercent]: `${gate.name} ${cagesPercentWithDividerStr}`,
      [EGatesLabelFormat.name]: gate.name,
      [EGatesLabelFormat.percent]: gateStatistics?.cagesPercent ?? '',
    };

    const gateLabel = isGateLabelsHidden ? '' : lableByFormat[gateLabelFormat];

    const payload = { gateId: gate.id, gatePolygons, label: gateLabel, groupClassName };
    const handlerByType: Record<TGatePropertyType, () => void> = {
      polygon: () => drawPolygon(payload),
      circle: () => {
        const radiusData = getGateRadius(gate);
        if (!radiusData) return;

        const ellipseData = {
          x: gatePolygons[0].x,
          y: gatePolygons[0].y,
          rx: radiusData.rx,
          ry: radiusData.ry,
        };
        drawEllipse({ ...payload, ellipseData });
      },
      rectangle: () => drawRectangle(payload),
      polar: () => {
        const polarData = {
          ...payload,
          center: [gatePolygons[0].x, gatePolygons[0].y],
          cagesData,
          gate,
        };
        drawPolar(polarData);
      },
      'split-gate': () => {
        const polarData: TDrawPolarPayload = {
          ...payload,
          center: [gatePolygons[0].x, gatePolygons[0].y],
          cagesData,
          gate,
          polarGateSubType: 'split-gate',
        };
        drawPolar(polarData);
      },
      range: () => drawRangeGate(payload),
    };

    handlerByType[type]();
  };

  const drawGates = ({
    allGates,
    gatesToDraw,
    cagesData,
    activeGateId = '',
    plotType,
    datasetScanId,
    datasetLaneId,
    parentGate,
  }: {
    allGates: TGate[];
    gatesToDraw: TGate[];
    cagesData: TEntitiesByGates;
    activeGateId?: string;
    plotType: EChartType;
    datasetScanId: string;
    datasetLaneId: string;
    parentGate?: TGate;
  }) => {
    gatesToDraw?.forEach((gate: TGate) => {
      const points = defineGatePointsByType(gate, datasetScanId, datasetLaneId, parentGate);

      if (gate.gateNodes.length && selectedGate) {
        drawGates({
          allGates,
          gatesToDraw: gate.gateNodes,
          cagesData,
          activeGateId,
          plotType,
          datasetScanId,
          datasetLaneId,
          parentGate,
        });
      }

      if (onlyGateToDisplay && onlyGateToDisplay.id !== gate.id) {
        return;
      }

      if (
        gate.shape.type === 'circle' &&
        (!axisScaleHelper.isCircleGateAllowed(xAxisScaleType) || !axisScaleHelper.isCircleGateAllowed(yAxisScaleType))
      ) {
        return;
      }

      const isGateShouldBeDrawn = defineIsGateShouldBeDrawn({ gate, selectedGate, xAxis, yAxis, plotType });

      if (!isGateShouldBeDrawn || !points.length) return;

      const isDimensionsReverted = isGateDimensionsReverted(gate, xAxis, yAxis);
      let parsedPoints = isDimensionsReverted ? revertPoint(points) : points;
      const {
        properties: { color = '', isVisible = true },
      } = gate;

      parsedPoints = axisScaleHelper.getScaledGatePoints({
        xAxisScaleType,
        yAxisScaleType,
        gatePoints: parsedPoints,
        plotLinearRange,
        origDataRange,
      });

      const isInvalidPoints = parsedPoints.some(
        ({ x, y }) => !(isNumber(x) && !Number.isNaN(x) && isNumber(y) && !Number.isNaN(y))
      );

      if (isOutOfThePositiveLogRange || isInvalidPoints) return;

      const hardcodedPoint = isRangeTypeGate(gate.shape) ? HARDCODED_TOP_Y_VALUE : null;
      const gatePolygons = prepareGateToDraw({ points: parsedPoints, hardcodedValue: hardcodedPoint });
      const groupClassName = gate.id === activeGateId ? 'gate-group gate-group_active' : 'gate-group';

      if (!gatePolygons) return;

      drawGateByType({ gate, groupClassName, gatePolygons, cagesData });

      if (gate.id !== activeGateId) {
        changeGateColor(gate.id, color);
      }
      hideGate(gate.id, isVisible);
    });
  };

  useEffect(() => {
    if (!plotContainerParameters || !chartContainerParameters || hideGates) return;

    prepareLayoutForGates();
  }, [plotContainerParameters, chartContainerParameters]);

  useEffect(() => {
    if (!graphRef?.current?.layout || !isAllPlotDataLoaded || hideGates) return;
    defineGatesLayoutParameters();
  }, [isAllPlotDataLoaded, graphRef.current?.id, !!graphRef?.current?.layout, hideGates, selectedTool]);

  // display gates
  useEffect(() => {
    if (
      !entitiesDataByGates ||
      !entityLevelGateList ||
      !plotLinearRange ||
      !chartContainerParameters ||
      hideGates ||
      isEmptyData
    )
      return;

    const svg = d3Select(`#${plotId} .gates-container`);

    svg.selectAll('*').remove();
    clearPolygonData();
    removeGatesListeners();

    if (isStatic) {
      svg.attr('class', 'gates-container gates-container_disabled');
    }

    if (isGatesDrawn && isAllPlotDataLoaded) {
      drawGates({
        gatesToDraw: entityLevelGateList,
        allGates: entityLevelGateList,
        cagesData: entitiesDataByGates,
        activeGateId: activeGate?.id ?? '',
        plotType: currentChartType,
        datasetScanId: scanId,
        datasetLaneId: laneId,
      });

      setOnContextMenuHandlers(onGateContextMenu, plotId);
    }
  }, [
    entityLevelGateList,
    entitiesDataByGates,
    selectedGate,
    xAxis,
    yAxis,
    plotLinearRange,
    isGatesDrawn,
    chartContainerParameters,
    isStatic,
    plotId,
    isAllPlotDataLoaded,
    currentChartType,
    hideGates,
    scanId,
    laneId,
    fullScreenChartData,
    onlyGateToDisplay,
  ]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const eventData = pointer(event);
      if (!selectedTool || (selectedTool && !toolsForDrawingGates.includes(selectedTool))) return;
      const handlers: Record<string, (eventData: [number, number]) => void> = {
        [EModebarTools.rectangle]: handleRectGateMouseMove,
        [EModebarTools.ellipse]: handleEllipseGateMouseMove,
        [EModebarTools.polygon]: handlePolygonMouseMove,
        [EModebarTools.polar]: handlePolarMouseMove,
        [EModebarTools.splitGate]: handlePolarMouseMove,
        [EModebarTools.range]: handleRangeMouseMove,
      };
      handlers[selectedTool](eventData);
    },

    [
      selectedTool,
      handleRectGateMouseMove,
      handlePolygonMouseMove,
      handleEllipseGateMouseMove,
      handlePolarMouseMove,
      handleRangeMouseMove,
    ]
  );

  const removeGatesContainerListeners = () => {
    const svg = d3Select(`#${plotId} .gates-container`);
    const body = d3Select('body');
    body.on('mouseup', null);
    if (!svg) return;
    svg.on('mousedown', null);
    svg.on('mousemove', null);
  };

  const clearNewGateData = () => {
    setNewRectangleData(null);
    clearPolygonData();
    clearEllipseData();
    defineGatesLayoutParameters();
  };

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      mouseDownEventRef.current = event;
      const target = event.target as Element;
      const targetClassList = target?.classList ?? [];

      if (
        !entityLevelGateList ||
        event.button !== 0 ||
        (!targetClassList.contains('gates-container') && !newPolygonData.isClicking)
      )
        return;

      const eventData = pointer(event);

      if (isBlockDraw || !selectedTool || (selectedTool && !toolsForDrawingGates.includes(selectedTool))) return;

      const handlers: Record<string, (eventData: [number, number]) => void> = {
        [EModebarTools.rectangle]: handleRectGateMouseDown,
        [EModebarTools.ellipse]: handleEllipseGateMouseDown,
        [EModebarTools.polygon]: handlePolygonMouseDown,
        [EModebarTools.polar]: (polarEventData: [number, number]) => handlePolarMouseDown(polarEventData, 'polar'),
        [EModebarTools.splitGate]: (polarEventData: [number, number]) =>
          handlePolarMouseDown(polarEventData, 'split-gate'),
        [EModebarTools.range]: handleRangeMouseDown,
      };

      handlers[selectedTool](eventData);
    },
    [
      entityLevelGateList,
      selectedTool,
      isBlockDraw,
      handlePolygonMouseDown,
      handleRectGateMouseDown,
      handleEllipseGateMouseDown,
      handlePolarMouseDown,
      handleRangeMouseDown,
    ]
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      mouseUpEventRef.current = event;
      if (event.button !== 0 || !selectedTool || (selectedTool && !toolsWithMouseUpHandler.includes(selectedTool)))
        return;
      const handlers: Record<string, () => void> = {
        [EModebarTools.rectangle]: handleRectGateMouseUp,
        [EModebarTools.ellipse]: handleEllipseGateMouseUp,
        [EModebarTools.polar]: () => handlePolarMouseUp('polar'),
        [EModebarTools.splitGate]: () => handlePolarMouseUp('split-gate'),
        [EModebarTools.range]: handleRangeMouseUp,
      };

      handlers[selectedTool]();
    },
    [selectedTool, handleRectGateMouseUp, handleEllipseGateMouseUp, handlePolarMouseUp, handleRangeMouseUp]
  );

  useEffect(() => {
    if (
      !isAllPlotDataLoaded ||
      !isGatesDrawn ||
      !chartContainerParameters ||
      !entityLevelGateList?.length ||
      !plotLinearRange ||
      hideGates
    )
      return;

    const chartLayout = d3Select(chartContainerSelector);
    const chartLayoutNode = chartLayout.node() as Element;
    if (!chartLayoutNode) return;

    const gapX = parseFloat(chartLayout.attr('x'));
    const gapY = parseFloat(chartLayout.attr('y'));
    chartLayout.on('click', null);
    chartLayout.on('click', (event: MouseEvent) => {
      if (isHoverInfoOpen) return;

      const drawnGateInfo = {
        selectedGate,
        xAxis,
        yAxis,
        isGatesDrawn,
      };

      const [pointsOnChart] = getPointsFromChartScale({
        polygons: [{ x: event.offsetX - gapX, y: event.offsetY - gapY }],
        shapesContainerParameters: chartLayoutNode.getBoundingClientRect(),
        range: plotLinearRange,
      });

      const clickedGate = detectClickedGate({
        points: pointsOnChart,
        drawnGateInfo,
        gateList: entityLevelGateList,
        scanId,
        laneId,
      });

      if (!clickedGate) return;

      highlightActiveGate(clickedGate.id);
      appDispatch(gatesActions.setActiveGate(clickedGate));
    });

    return () => {
      chartLayout.on('click', null);
    };
  }, [
    isAllPlotDataLoaded,
    chartContainerParameters,
    isHoverInfoOpen,
    entityLevelGateList,
    selectedGate,
    isGatesDrawn,
    plotLinearRange,
  ]);

  useEffect(() => {
    if (!chartContainerParameters || isStatic || !entityLevelGateList || hideGates) return;
    const svg = d3Select(`#${plotId} .gates-container`);
    const body = d3Select('body');
    if (svg && selectedTool && toolsForDrawingGates.includes(selectedTool)) {
      removeGatesContainerListeners();
      svg.on('mousedown', handleMouseDown);
      svg.on('mousemove', handleMouseMove);
      body.on('mouseup', handleMouseUp);
    }

    return () => {
      removeGatesContainerListeners();
    };
  }, [handleMouseMove, chartContainerParameters, isStatic, handleMouseDown, handleMouseUp]);

  const removeGatesListeners = () => {
    const svg = d3Select(`#${plotId} .gates-container`);

    const gateElements = svg.selectAll('.gate-element').nodes();
    const controlElements = svg.selectAll('.gate-element__control').nodes();
    const gateLabels = svg.selectAll('.gate-label').nodes();
    const gateMenuElements = svg.selectAll('.gate-menu').nodes();

    gateElements.forEach((gate) => {
      d3Select(gate).on('.drag', null);
      d3Select(gate).on('click', null);
      d3Select(gate).on('contextmenu', null);
    });

    controlElements.forEach((control) => {
      d3Select(control).on('.drag', null);
    });

    gateLabels.forEach((gate) => {
      d3Select(gate).on('contextmenu', null);
    });

    gateMenuElements.forEach((gate) => {
      d3Select(gate).on('click', null);
    });

    removeGatesContainerListeners();
  };

  useEffect(
    () => () => {
      removeGatesListeners();
      removeGatesContainerListeners();
    },
    [isStatic]
  );

  useEffect(() => {
    if (!isAllPlotDataLoaded || !plotContainerParameters || !chartContainerParameters || hideGates) return;

    const activeGateClass = 'gates-container gates-container_active';
    const disabledClass = 'gates-container gates-container_gates-disabled';
    const fullActionsClass = 'gates-container gates-container_full-actions';
    const gatesContainer = d3Select(`#${plotId} .gates-container`);
    const staticClass = 'gates-container gates-container_gates-static';

    let gatesContainerClass = activeGateClass;

    if (!selectedTool || isStatic) {
      gatesContainerClass = staticClass;
    } else {
      const classByType: Record<EModebarTools | string, string> = {
        [EModebarTools.zoom]: isPreprocessingView ? fullActionsClass : disabledClass,
        [EModebarTools.gateInteractions]: isPreprocessingView ? fullActionsClass : disabledClass,
        default: activeGateClass,
      };

      gatesContainerClass = classByType?.[selectedTool] ?? classByType.default;
    }

    gatesContainer.attr('class', gatesContainerClass);
  }, [
    selectedTool,
    isAllPlotDataLoaded,
    plotContainerParameters,
    chartContainerParameters,
    isPreprocessingView,
    hideGates,
    isStatic,
    fullScreenChartData,
  ]);

  const updateGatesOnResize = () => {
    const { Plotly } = window;

    const plotBlock = d3Select(`#${plotId}`);
    if (!plotBlock?.nodes()?.length) {
      return;
    }

    Plotly.Plots.resize(plotId).then(() => defineGatesLayoutParameters());
  };

  useEffect(() => {
    window.addEventListener('resize', updateGatesOnResize);

    return () => window.removeEventListener('resize', updateGatesOnResize);
  }, [isAllPlotDataLoaded]);

  return {
    defineGatesLayoutParameters,
    newGateModel,
    clearNewGateData,
    mouseUpEventRef,
    mouseDownEventRef,
    overflowContainer: d3Select(`#${plotId} .overflow-container`),
  };
}

export default useGatesOnPlot;
