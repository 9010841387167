import { sortByKey } from '@/helpers';

export const getSearchMatches = (entityList: TEntity[], search: string, isOnlyGlobalCageId = false) => {
  const exactList: TEntity[] = [];
  const approximateList: TEntity[] = [];
  entityList.forEach((entity) => {
    const globalCageIdMatched = entity.globalCageIdMatched?.toString() ?? '';
    const objectId = entity.objectId?.toString() ?? '';
    if (globalCageIdMatched === search || (!isOnlyGlobalCageId && objectId === search)) {
      exactList.push(entity);
    } else if (globalCageIdMatched.includes(search) || (!isOnlyGlobalCageId && objectId.includes(search))) {
      approximateList.push(entity);
    }
  });
  sortByKey(exactList, 'globalCageIdMatched');
  sortByKey(approximateList, 'globalCageIdMatched');
  return { exactList, approximateList };
};
