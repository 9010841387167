import Button from '@/components/common/Button';
import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './InfoButton.module.scss';

const cn = classnames.bind(styles);

type TInfoButton = {
  handleClick?: () => void;
  tooltip: string;
  className?: string;
};

const InfoButton: FC<TInfoButton> = ({ handleClick, tooltip, className }) => (
  <Button onClick={handleClick} className={cn('info-button', className)} tooltip={tooltip} color="white">
    i
  </Button>
);

export default InfoButton;
