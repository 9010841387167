import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { useDebounce } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { formatRangeObj } from '@/helpers/charts/ranges';

import { TCustomRange, chartSettingsActions } from '@/store/slices/chartSettings';

import NumberInput from '@/components/common/NumberInput';
import Button from '@/components/common/Button';

import styles from './CustomRanges.module.scss';

type TSingleCustomRange = {
  rangeData: TCustomRange;
  isSelected?: boolean;
  isXLogScale?: boolean;
  isYLogScale?: boolean;
  generalPlotRange: Nullable<TPopulationRange>;
};

const cn = classnames.bind(styles);

const SingleCustomRange: FC<TSingleCustomRange> = ({
  rangeData,
  isSelected,
  isXLogScale,
  isYLogScale,
  generalPlotRange,
}) => {
  const appDispatch = useAppDispatch();
  const [changedRangeData, setChangedRangeData] = useState(rangeData);

  const isChanged = useMemo(() => changedRangeData !== rangeData, [changedRangeData, rangeData]);
  const debounceIsChanged = useDebounce(isChanged, 100);

  const handleChangeRange = useCallback((val: number, axisName: keyof TPopulationRange) => {
    setChangedRangeData((prev) => {
      const axesRange = formatRangeObj(prev.range);
      return { ...prev, range: { ...axesRange, [axisName]: val } };
    });
  }, []);

  const handleUpdateRange = useCallback(() => {
    if (!isChanged) {
      return;
    }

    const customRangeToUpdate = {
      ...rangeData,
      ...changedRangeData,
    };
    appDispatch(chartSettingsActions.addCustomPlotRange({ customRange: { ...customRangeToUpdate } }));
  }, [changedRangeData, rangeData, isChanged]);

  const { isSettingsChangingConfirmed } = useSettingsNotification();

  const handleSetRange = useCallback(async () => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    handleUpdateRange();
    appDispatch(chartSettingsActions.setCustomRangeName(rangeData.rangeName));
  }, [rangeData, handleUpdateRange]);

  useEffect(() => {
    setChangedRangeData(rangeData);
  }, [rangeData]);

  useEffect(() => {
    if (!isSelected && generalPlotRange) {
      setChangedRangeData({ ...rangeData, range: generalPlotRange });
    }
  }, [isSelected]);

  return (
    <div className={cn('single-custom-range')}>
      {changedRangeData.isEditable && changedRangeData.range && (
        <>
          <div className={cn('custom-range__axis')}>
            <div className={cn('custom-range__input-wrap')}>
              <fieldset className={cn('fieldset', { log: isXLogScale })}>
                <legend className={cn('legend')}>min X</legend>
                <NumberInput
                  value={changedRangeData.range.xMin}
                  inputClassName={cn('axis-input')}
                  className={cn('axis-input-wrapper')}
                  onChange={(val) => handleChangeRange(val, 'xMin')}
                />
              </fieldset>
              <fieldset className={cn('fieldset', { log: isXLogScale })}>
                <legend className={cn('legend')}>max X</legend>
                <NumberInput
                  value={changedRangeData.range.xMax}
                  inputClassName={cn('axis-input')}
                  className={cn('axis-input-wrapper')}
                  onChange={(val) => handleChangeRange(val, 'xMax')}
                />
              </fieldset>
            </div>
          </div>
          <div className={cn('custom-range__axis')}>
            <div className={cn('custom-range__input-wrap')}>
              <fieldset className={cn('fieldset', { log: isYLogScale })}>
                <legend className={cn('legend')}>min Y</legend>
                <NumberInput
                  value={changedRangeData.range.yMin}
                  inputClassName={cn('axis-input')}
                  className={cn('axis-input-wrapper')}
                  onChange={(val) => handleChangeRange(val, 'yMin')}
                />
              </fieldset>
              <fieldset className={cn('fieldset', { log: isYLogScale })}>
                <legend className={cn('legend')}>max Y</legend>
                <NumberInput
                  value={changedRangeData.range.yMax}
                  inputClassName={cn('axis-input')}
                  className={cn('axis-input-wrapper')}
                  onChange={(val) => handleChangeRange(val, 'yMax')}
                />
              </fieldset>
            </div>
          </div>
          <Button color={isSelected ? 'yellow' : 'white'} onClick={handleSetRange}>
            {debounceIsChanged ? 'Apply changes' : rangeData.label}
          </Button>
        </>
      )}
    </div>
  );
};

export default memo(SingleCustomRange);
