import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { cdnAPI } from '@/store/services/cdnData';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

const useGetEntitiesByLaneList = () => {
  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));

  const [fetchObjectEntityList] = cdnAPI.useLazyFetchObjectEntityListQuery();
  const [fetchCageEntityList] = cdnAPI.useLazyFetchCageEntityListQuery();

  const getEntitiesByLaneList = useCallback(
    async (laneList: (TLane | undefined)[]): Promise<(TEntity[] | undefined)[]> =>
      isObjectEntityEnabled
        ? Promise.all(laneList.map((lane) => lane && fetchObjectEntityList(lane, true).unwrap()))
        : Promise.all(laneList.map((lane) => lane && fetchCageEntityList(lane, true).unwrap())),
    [isObjectEntityEnabled]
  );

  return useMemo(
    () => ({
      getEntitiesByLaneList,
    }),
    [getEntitiesByLaneList]
  );
};

export default useGetEntitiesByLaneList;
