import { v4 as uuidv4 } from 'uuid';

import { removeDuplicates, sortByKey, toSnakeCase } from '@/helpers/common';
import { channelDataList, getChannelName, TChannelData } from '@/helpers/channels';
import { getAssayNameByPreprocessingType } from '@/helpers/preprocessing';
import { EPreprocessingAssayType } from '@/helpers/constants';

const getMarkerList = (assayList: TAssay[], laneClasses: TClass[]) => [
  ...new Set(
    assayList.flatMap((assay) =>
      (laneClasses ?? []).filter(
        (classItem) =>
          classItem.processType === EPreprocessingAssayType.cytokineSecretion &&
          assay?.name === getAssayNameByPreprocessingType(classItem.processType as EPreprocessingAssayType)
      )
    )
  ),
];

export const getChartDataName = (datasetName: string, channelName: string) => {
  const channelNameParts = channelName.split('-');
  channelNameParts[0] = channelNameParts[0].substring(0, 1);
  return `${datasetName}-${channelNameParts.join('-')}`;
};

const getBasicChartData = (scan: TScan, lane: TLane, channelId: string, channelName: string) => {
  const channelProperties = lane.channelsProperties ? lane.channelsProperties[channelId] : null;
  const timePoint = scan.time;
  const scanId = scan.id;
  const laneId = lane.id;
  const assay = channelProperties?.assay ?? null;
  return {
    id: uuidv4(),
    scanId,
    laneId,
    channelId,
    name: getChartDataName(lane.dataset.name, channelName),
    friendlyName: getChartDataName(lane.dataset.friendlyName, channelName),
    laneLetterName: lane.letterName,
    sampleFriendlyName: lane.sampleFriendlyName,
    timePoint,
    channelName,
    checked: false,
    assayList: [],
    dataset: lane.dataset,
    assay,
    reagents: channelProperties?.reagents ?? [],
    channelList: lane.channels,
  };
};

const getLaneChartDataList = (scan: TScan, lane: TLane, channelData: TChannelData) =>
  lane.channels.reduce((laneLetterDataList, channelId) => {
    const channelName = getChannelName(channelId);
    if (channelName.includes(channelData.name)) {
      const chartDataIdWithChannel = `${scan.id}-${lane.id}-${toSnakeCase(channelId)}`;

      const chartData = getBasicChartData(scan, lane, channelId, channelName);
      const markerList = chartData.assay ? getMarkerList([chartData.assay], lane.classes ?? []) : [];
      if (markerList.length > 0) {
        return markerList.reduce((chartDataList, marker) => {
          const fullChartDataId = `${scan.id}-${lane.id}-${toSnakeCase(channelId)}-${marker.id}`;

          return [
            ...chartDataList,
            {
              ...chartData,
              id: fullChartDataId,
              marker,
              markerList,
            },
          ];
        }, laneLetterDataList);
      }
      return [...laneLetterDataList, { ...chartData, id: chartDataIdWithChannel }];
    }
    return laneLetterDataList;
  }, [] as TDatasetDetails[]);

const getChartDataList = (scanList: TScan[]) => {
  const chartDataList: TDatasetDetails[] = [];
  channelDataList.forEach((channelData) => {
    scanList.forEach((scan) => {
      scan.lanes.forEach((lane) => {
        chartDataList.push(...getLaneChartDataList(scan, lane, channelData));
      });
    });
  });
  sortByKey(chartDataList, 'laneId');
  return chartDataList;
};

const getDatasetDetails = (lane: TLane, timePoint: string) => {
  const assayList: TAssay[] = [];
  Object.values(lane.channelsProperties ?? {}).forEach((channelProperties) => {
    if (!channelProperties.assay) {
      return;
    }

    assayList.push(channelProperties.assay);
  });

  return {
    id: uuidv4(),
    scanId: lane.dataset.scanId,
    laneId: lane.dataset.laneId,
    name: lane.dataset.name,
    friendlyName: lane.dataset.friendlyName,
    laneLetterName: lane.letterName,
    sampleFriendlyName: lane.sampleFriendlyName,
    timePoint,
    checked: false,
    assayList: removeDuplicates<TAssay>(Object.values(assayList), 'id'),
    dataset: lane.dataset,
    channelList: lane.channels,
    markerList: getMarkerList(assayList, lane.classes ?? []),
  };
};

export const getDetailsLists = (scanList: TScan[]) => {
  const laneDetailsByLanes: Record<string, TLaneDetails> = {};
  const datasetDetailsList: TDatasetDetails[] = [];
  scanList.forEach(({ lanes, time: timePoint }: TScan) => {
    lanes.forEach((laneItem: TLane) => {
      const { id, sampleName, letterName, sampleFriendlyName } = laneItem;
      laneDetailsByLanes[id] = {
        id,
        sampleName,
        sampleFriendlyName: laneDetailsByLanes[id]?.sampleFriendlyName ?? sampleFriendlyName,
        letterName,
        isOpen: true,
      };
      datasetDetailsList.push(getDatasetDetails(laneItem, timePoint));
    });
  });

  return {
    laneDetailsList: Object.values(laneDetailsByLanes),
    datasetDetailsList,
    channelDetailsList: getChartDataList(scanList),
  };
};

const isSameChannelFactory = (chartDataIds: TDatasetDetailsIdData) => {
  if ('channelId' in chartDataIds) {
    return (chartData: TDatasetDetails) => chartData.channelId === chartDataIds.channelId;
  }
  if ('channelName' in chartDataIds) {
    return (chartData: TDatasetDetails) => chartData.channelName?.startsWith(chartDataIds.channelName ?? '');
  }
  return (_chartData: TDatasetDetails) => true;
};

export const getChartDataListFromIdList = (scanList: TScan[], chartDataIdsList: TDatasetDetailsIdData[]) => {
  if (!Array.isArray(chartDataIdsList) || !chartDataIdsList.length) {
    return [];
  }

  const chartDataList = getDetailsLists(scanList).channelDetailsList;

  return chartDataIdsList
    .map((chartDataIds) => {
      const isSameChannel = isSameChannelFactory(chartDataIds);
      return chartDataList.find(
        (chartData) =>
          chartData.scanId === chartDataIds.scanId &&
          chartData.laneId === chartDataIds.laneId &&
          isSameChannel(chartData) &&
          (!chartData.marker?.name ||
            !chartDataIds.marker?.name ||
            chartData.marker?.name === chartDataIds.marker?.name)
      );
    })
    .filter((chartData) => !!chartData) as TDatasetDetails[];
};

export const getDatasetFriendlyName = (data: TDatasetDetails | TLane) => {
  if (!data) {
    return '';
  }
  if ('friendlyName' in data) {
    // TDatasetDetails
    return `${data.sampleFriendlyName} ${data.friendlyName}`;
  }
  // TLane
  return `${data.sampleFriendlyName} ${data.dataset.friendlyName}`;
};
