import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { EPageWithChartType, EChartType, TColorscale } from '@/types/charts';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { DEFAULT_D3_COLORSCALES } from '@/helpers/charts/colorscales';

import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { EUnselectedOption } from '@/types/settings';

import Accordion from '@/components/common/Accordion/Accordion';
import RadioButton from '@/components/common/RadioButton.tsx';

import { colorscaleByChartType, colorscaleByTypes } from '../constants';

import styles from '../SettingsPopover.module.scss';

const cn = classnames.bind(styles);

type TColorScaleSettingProps = {
  pageType: EPageWithChartType;
};

const ColorScaleSetting: FC<TColorScaleSettingProps> = ({ pageType }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
  const highlightDotsBy = useSelector(scatterplotsSelectors.selectHighlightDotsBy(chartId));

  const isColorScaleSettingVisible = useMemo(() => {
    if (pageType === EPageWithChartType.multiHistogram) {
      return true;
    }

    const isColorscaleVisibleInScatterChart =
      currentChartType === EChartType.dot && highlightDotsBy !== EUnselectedOption.value;

    const allowedChartTypeList = [
      EChartType.dotDensity,
      EChartType.histogram2d,
      EChartType.histogram2dcontour,
      EChartType.heatmap,
    ];

    return allowedChartTypeList.includes(currentChartType) || isColorscaleVisibleInScatterChart;
  }, [pageType, currentChartType, highlightDotsBy]);

  const currentColorScaleList = useMemo(
    () => colorscaleByChartType?.[currentChartType] ?? DEFAULT_D3_COLORSCALES,
    [currentChartType]
  );

  const { isSettingsChangingConfirmed } = useSettingsNotification();

  const handleCurrentColorScaleChange = async (value: string) => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(chartSettingsActions.setCurrentColorScale(value));
  };

  if (!isColorScaleSettingVisible) {
    return null;
  }

  return (
    <div className={cn('section')}>
      <Accordion
        className={cn('accordion')}
        theme="light"
        heading={
          <div className={cn('accordion__header', 'section__head', 'section__head_no-gap')}>
            <div className={cn('accordion__title')}>
              <span>Color Scale</span>
            </div>
            <img className={cn('colorscale', 'current-colorscale')} src={colorscaleByTypes[currentColorScale]} alt="" />
          </div>
        }
      >
        <div className={cn('accordion__content')}>
          {Object.keys(currentColorScaleList)?.map((key) => (
            <RadioButton
              className={cn('radio-btn')}
              checked={key === currentColorScale}
              onChange={() => handleCurrentColorScaleChange(key as TColorscale)}
              id={`radio-btn-${key}`}
              theme="light"
              key={key}
              label={<img className={cn('colorscale')} src={colorscaleByTypes[key as TColorscale]} alt="" />}
            />
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default ColorScaleSetting;
