import { useCallback } from 'react';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { antnAPI } from '@/store/services/annotation';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';

const useCellNameSuggestions = () => {
  const [fetchAnnotationsSuggests] = antnAPI.useLazyFetchAnnotationsSuggestsQuery();

  const getSuggestions = useCallback(async (searchText: string) => {
    try {
      const res = await fetchAnnotationsSuggests(
        {
          type: EAnnotationType.cellIndex,
          search: searchText,
        },
        true
      ).unwrap();
      return res ?? [];
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  }, []);

  return {
    getSuggestions,
  };
};

export default useCellNameSuggestions;
