import { CSSProperties, FC, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';
import { Popover as TinyPopover } from 'react-tiny-popover';

import { addTooltip } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import { TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';

import { TSettingsValidationConfig } from '@/pages/experiment-run-design/CagingSettings/components/EditSettings/types';
import NumberInput from '@/components/common/NumberInput';
import { CagingSettingsItem } from '@/graphql/API';

import styles from './NumberField.module.scss';

const cn = classnames.bind({ ...styles });

type TNumberField = {
  onChangeNumberField: (field: TCagingSettingNumberField) => (n: number) => void;
  field: TCagingSettingNumberField;
  defaultValue?: number;
  disabled?: boolean;
  validationConfig?: Nullable<Record<TCagingSettingNumberField, TSettingsValidationConfig>>;
  errors: Partial<Record<string | number, string>>;
  settings?: Nullable<CagingSettingsItem>;
  className?: string;
};

const NumberField: FC<TNumberField> = ({
  field,
  onChangeNumberField,
  disabled,
  defaultValue,
  validationConfig,
  errors,
  settings,
  className,
}) => {
  const [isInputPopoverOpen, setIsInputPopoverOpen] = useState(false);
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  const inputValidationInfo = useMemo(() => {
    if (validationConfig?.[field]?.validationPlaceholder) return validationConfig?.[field].validationPlaceholder;

    return validationConfig?.[field].min && validationConfig?.[field].max
      ? `${validationConfig?.[field].min} - ${validationConfig?.[field].max}`
      : '';
  }, [validationConfig]);

  const toggleInputPopover = useCallback(() => {
    setIsInputPopoverOpen((prev) => !prev);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setIsInputPopoverOpen(false);
  }, []);

  return (
    <TinyPopover
      isOpen={isInputPopoverOpen}
      onClickOutside={handlePopoverClose}
      clickOutsideCapture
      positions={['bottom']}
      align="start"
      ref={popoverButtonRef}
      padding={5}
      content={
        <div
          className={cn('number-field__input-container')}
          style={
            {
              '--container-width': `${Math.ceil(popoverButtonRef?.current?.getBoundingClientRect()?.width ?? 145)}px`,
            } as CSSProperties
          }
        >
          <div className={cn('number-field__description')}>
            {validationConfig?.[field].placeholder ? `${validationConfig?.[field].placeholder}:` : ''}
          </div>
          <span>{inputValidationInfo}</span>
          <span> {validationConfig?.[field].valueType}</span>
          <div {...addTooltip(errors[field])}>
            <NumberInput
              onChange={onChangeNumberField(field)}
              value={settings?.[field] ?? 0}
              className={cn('number-field__input', {
                error: errors[field],
              })}
              min={-1000}
              max={10000}
              disabled={disabled}
            />
          </div>
        </div>
      }
    >
      <div
        onClick={toggleInputPopover}
        role="presentation"
        className={cn(
          'number-field',
          {
            'number-field_wide': field === 'cageWallToOtherObjsMinDistance',
            'number-field_changed': isDefined(defaultValue) && defaultValue !== settings?.[field],
            error: errors[field],
          },
          className
        )}
      >
        <div className={cn('number-field__value')} {...addTooltip(errors[field])}>
          {settings?.[field] ?? 0}
        </div>
        <icons.ArrowDropdownIcon
          className={cn('number-field__dropdown-arrow', { 'data-field__dropdown-arrow_opened': isInputPopoverOpen })}
        />
      </div>
    </TinyPopover>
  );
};

export default NumberField;
