import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../MainBlock.module.scss';

const cn = classnames.bind(styles);

type TAddAntibodyBlock = {
  componentName: string;
};

const AddAntibodyBlock: FC<TAddAntibodyBlock> = ({ componentName }) => (
  <div className={cn('main-block__sub-column')}>
    <div className={cn('main-block__block')}>
      <div className={cn('main-block__group')}>
        <div className={cn('main-block__block-title')}>Settings</div>
        <span className={cn('main-block__text-info')}>
          You will be able to define antibodies for {componentName.toLocaleLowerCase()} in Step 3 of experiment design.{' '}
        </span>
      </div>
    </div>
  </div>
);

export default AddAntibodyBlock;
