import { FC, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { MatrixOpticalSettingsContextProvider } from '~/src/hooks/runDesign/useMatrixOpticalSettingsContext';

import Title from '@/components/runDesign/Title';

import { RunDesignContext } from '../context';
import SettingsCard from './components/SettingsCard';
import SubHeader from './components/SubHeader';

const OpticalSettings: FC = () => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig, clearCustomFooterConfig } = useContext(RunDesignContext);

  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);

  const cardRef = useRef(null);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: cardRef,
      isEditMode: runDesignCardIndexEdit >= 0,
      cancel: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [overrideCustomFooterConfig, clearCustomFooterConfig, runDesignCardIndexEdit]);

  return (
    <>
      <Title>Optical settings</Title>
      <MatrixOpticalSettingsContextProvider>
        <SubHeader isEditMode={runDesignCardIndexEdit >= 0} />
        <SettingsCard innerRef={cardRef} />
      </MatrixOpticalSettingsContextProvider>
    </>
  );
};

export default OpticalSettings;
