import { SVGProps } from 'react';

const PauseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="11" height="16" viewBox="0 0 11 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M1.5 1.389v13.222M9.5 1.389v13.222" />
    </g>
  </svg>
);

export default PauseIcon;
