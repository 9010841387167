import { FC } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';
import Range from '@/components/common/Range';
import { LAST_EDIT, MIN_NAVIGATOR_ZOOM, MAX_NAVIGATOR_ZOOM, NAVIGATOR_ZOOM_STEP } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useParamsExperimentId } from '@/hooks';
import styles from './Zoom.module.scss';

const cn = classnames.bind(styles);

type TZoomProps = {
  laneId: string;
};

const Zoom: FC<TZoomProps> = ({ laneId }) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const { zoom } = useSelector(navigatorSelectors.selectZoom(currentLaneId !== '' ? currentLaneId : laneId));

  const handleZoomChange = (newZoom: number | number[]) => {
    appDispatch(
      navigatorActions.setZoom({
        experimentId,
        laneId: currentLaneId,
        options: {
          zoom: newZoom as number,
          lastEdit: LAST_EDIT.INPUT,
        },
      })
    );
  };
  return (
    <div className={cn('zoom')}>
      <div className={cn('zoom__title')}>Zoom</div>
      <Range
        onChange={handleZoomChange}
        min={MIN_NAVIGATOR_ZOOM}
        max={MAX_NAVIGATOR_ZOOM}
        step={NAVIGATOR_ZOOM_STEP}
        isControlInput
        infoBlockClassName={cn('zoom__slider-label')}
        inputClassName={cn('zoom__slider-input')}
        className={cn('zoom__range-slider')}
        value={zoom}
        sliderValueClassName={cn('zoom__slider-value')}
        labelBlockClassName={cn('zoom__range')}
        rangeHeight={2}
      />
    </div>
  );
};

export default Zoom;
