import { ChangeEvent, FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { JobRunStatus } from '@/graphql/API';

import ControlPanel from '@/components/Layout/ControlPanel';
import SearchInput from '@/components/common/SearchInput';
import Select from '@/components/common/Select';

import JobRunStatusBadge from './JobRunStatusBadge';

import styles from '../JobRunList.module.scss';

const cn = classnames.bind(styles);

type TJobRunsControlPanelProps = {
  isLoading: boolean;
  isSearchDisabled: boolean;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  selectedJobStatusList: JobRunStatus[];
  setSelectedJobStatusList: (value: JobRunStatus[]) => void;
  jobRunsCount?: number;
};

const JobRunsControlPanel: FC<TJobRunsControlPanelProps> = ({
  isLoading,
  isSearchDisabled,
  searchQuery,
  setSearchQuery,
  selectedJobStatusList,
  setSelectedJobStatusList,
  jobRunsCount,
}) => {
  const statusOptionList = useMemo(
    () =>
      Object.keys(JobRunStatus).map((statusValue) => ({
        label: <JobRunStatusBadge status={statusValue as JobRunStatus} />,
        value: statusValue,
      })),
    [JobRunStatus]
  );

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  return (
    <ControlPanel isLoading={isLoading}>
      <ControlPanel.StickyContent>
        <ControlPanel.FilterList>
          {jobRunsCount !== undefined && (
            <div className={cn('content__jobs-count')}>
              <h2>{jobRunsCount}</h2>
              <span>Job runs</span>
            </div>
          )}
          <Select
            placeholder="Job status"
            placeholderClassName={cn('content__select-item')}
            menuClassName={cn('status-selector__menu')}
            menuListClassName={cn('status-selector__menu-list')}
            controlClassName={cn('status-selector__control')}
            theme={themeOptions.light}
            value={selectedJobStatusList}
            onChange={setSelectedJobStatusList}
            options={statusOptionList}
            multiple
            isRightAlign={false}
          />
        </ControlPanel.FilterList>
      </ControlPanel.StickyContent>

      <ControlPanel.RightActions>
        <SearchInput
          onChange={handleSearchInputChange}
          onReset={handleResetSearchClick}
          value={searchQuery}
          disabled={isSearchDisabled}
          className={cn('content__search')}
        />
      </ControlPanel.RightActions>
    </ControlPanel>
  );
};

export default JobRunsControlPanel;
