import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';
import { useSelector } from 'react-redux';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { useCallback, useMemo } from 'react';
import { EDesignStep } from '@/pages/experiment-run-design/types';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { antnAPI } from '@/store/services/annotation';

const useReagentSearch = (componentId = '') => {
  const [fetchAnnotations] = antnAPI.useLazyFetchAnnotationsQuery();
  const { currentStep } = useRunDesignLocation();
  const component = useSelector(experimentRunDesignSelectors.selectComponentById(componentId));

  const annotationType = useMemo(() => {
    if (currentStep === EDesignStep.incubation) {
      return EAnnotationType.compound;
    }

    return component?.__typename === 'CellKilling' ? EAnnotationType.stain : EAnnotationType.reagent;
  }, [currentStep, component]);

  const getData = useCallback(
    async (inputValue: string, filterList: Record<string, string>) => {
      if (inputValue.length <= 2) {
        return [];
      }

      try {
        return (await fetchAnnotations({ type: annotationType, search: inputValue, filterList }, true).unwrap()) ?? [];
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    },
    [annotationType]
  );

  return {
    getData,
  };
};

export default useReagentSearch;
