import { useState, FC, memo, useCallback } from 'react';
import { Popover as TinyPopover } from 'react-tiny-popover';

import { withChartSettingsContext } from '@/hoc/withChartSettingsContext';
import { useChartSettingsContext } from '@/hooks/useChartSettingsContext';

import icons from '@/components/common/icons';
import ChartModebarButton from '@/components/charts/ChartModebarButton';

import SettingsPopover from './SettingsPopover';
import { TSettingsPopover } from './SettingsPopover/types';

type TChartSettingsButtonProps = {
  disabled?: boolean;
  align?: 'start' | 'center' | 'end';
} & TSettingsPopover;

const ChartSettingsButton: FC<TChartSettingsButtonProps> = ({ disabled, align = 'start', ...props }) => {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const { hidePopoverOnOutsideClick } = useChartSettingsContext();

  const toggleSettingsPopover = useCallback(() => setIsSettingsOpened(!isSettingsOpened), [isSettingsOpened]);

  const closeSettingsPopover = useCallback(
    () => hidePopoverOnOutsideClick && setIsSettingsOpened(false),
    [hidePopoverOnOutsideClick]
  );

  const onClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target.tagName !== 'BODY') {
      requestAnimationFrame(closeSettingsPopover);
    }
  };

  return (
    <TinyPopover
      onClickOutside={onClickOutside}
      isOpen={isSettingsOpened}
      align={align}
      padding={5}
      positions={['right', 'left']}
      content={<SettingsPopover {...props} />}
      containerStyle={{ marginTop: '20px' }}
      boundaryInset={40}
      reposition
    >
      {/* without div here popover is drawn far from button and JS error fails:
       Warning: Function components cannot be given refs. Attempts to access this ref will fail */}
      <div>
        <ChartModebarButton
          IconComponent={icons.SettingsIcon}
          onClick={toggleSettingsPopover}
          active={isSettingsOpened}
          disabled={disabled}
          tooltip={isSettingsOpened ? 'Close chart settings' : 'Open chart settings'}
        />
      </div>
    </TinyPopover>
  );
};

export default memo(withChartSettingsContext(ChartSettingsButton));
