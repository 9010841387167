import { FC, RefObject, useMemo } from 'react';
import { ReactMouseSelect } from 'react-mouse-select';
import { Popover as TinyPopover } from 'react-tiny-popover';
import classnames from 'classnames/bind';

import { SELECTABLE_CLASS_NAME, SELECTED_CLASS_NAME } from '@/helpers/reactSelectable';

import icons from '@/components/common/icons';

import type { TContextMenuAction } from './types';
import ContextMenu from './ContextMenu';
import useContextMenu from './hooks/useContextMenu';
import useCopyPaste from './hooks/useCopyPaste';
import useEventListeners from './hooks/useEventListeners';

import styles from './ReactSelectable.module.scss';

const cn = classnames.bind(styles);

const CopyAction: FC = () => (
  <div className={cn('action')}>
    <span className={cn('action__title')}>Copy</span>
    <span className={cn('action__icon')}>
      <icons.CopyIcon />
    </span>
  </div>
);

const PasteAction: FC = () => (
  <div className={cn('action')}>
    <span className={cn('action__title')}>Paste</span>
    <span className={cn('action__icon')}>
      <icons.PasteIcon />
    </span>
  </div>
);

type TReactSelectable<T> = {
  tableRef: RefObject<HTMLDivElement>;
  tableData: T;
  setTableData?: (data: T) => void;
};

function ReactSelectable<T>({ setTableData, tableData, tableRef }: TReactSelectable<T>) {
  const { isOpenedPopover, popoverPosition, closeContextMenu, cursorPosition } = useContextMenu<T>({
    tableData,
    tableRef,
  });

  const { onCopyAction, onPasteAction } = useCopyPaste<T>({
    tableData,
    tableRef,
    setTableData,
  });

  useEventListeners<T>({ onCopyAction, onPasteAction, tableData, tableRef });

  const contextMenuActions = useMemo<TContextMenuAction[]>(() => {
    const actions: TContextMenuAction[] = [];

    actions.push({
      name: 'Copy',
      component: <CopyAction />,
      onClick: () => {
        onCopyAction();
        closeContextMenu();
      },
    });
    actions.push({
      name: 'Paste',
      component: <PasteAction />,
      onClick: () => {
        onPasteAction();
        closeContextMenu();
      },
    });

    return actions;
  }, [cursorPosition, onPasteAction, onCopyAction, closeContextMenu]);

  return (
    <>
      <ReactMouseSelect
        containerRef={tableRef}
        itemClassName={SELECTABLE_CLASS_NAME}
        selectedItemClassName={SELECTED_CLASS_NAME}
        saveSelectAfterFinish
        onClickPreventDefault
      />
      <TinyPopover
        content={<ContextMenu actions={contextMenuActions} />}
        isOpen={isOpenedPopover}
        contentLocation={popoverPosition}
        clickOutsideCapture
        onClickOutside={closeContextMenu}
      >
        {/* for correct work of popover, because popover need has any child element */}
        <div style={{ position: 'absolute' }} />
      </TinyPopover>
    </>
  );
}

export default ReactSelectable;
