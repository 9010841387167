import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import styles from './Row.module.scss';

const cn = classnames.bind(styles);

type TRow = {
  className?: string;
  children?: ReactNode;
  tooltipMessage?: string;
};

const Row: FC<TRow> = ({ children, className, tooltipMessage }) => (
  <tr className={cn('row', className)} {...addTooltip(tooltipMessage)}>
    {children}
  </tr>
);

export default Row;
