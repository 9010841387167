import { memo } from 'react';

import Modal from '@/components/common/Modal';
import Markdown from 'react-markdown';

type TDetailsModalProps = {
  title: string;
  details: string;
  isOpen: boolean;
  handleCloseModal: () => void;
};

const DetailsModal = ({ title, details, isOpen, handleCloseModal }: TDetailsModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={handleCloseModal} shouldCloseOnOverlayClick isFullScreen>
    <Modal.Header>
      <h2>{title}</h2>
    </Modal.Header>
    <Modal.Content>
      <Markdown className="markdown-body">{details}</Markdown>
    </Modal.Content>
  </Modal>
);

export default memo(DetailsModal);
