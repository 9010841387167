import { useState, useMemo, FC, useCallback, useRef, memo, MouseEvent, useEffect } from 'react';
import classnames from 'classnames/bind';
import AsyncSelect from 'react-select/async';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { addTooltip } from '@/helpers';

import { appAPI } from '@/store/services/app';

import { getNoOptionsMessage, isBasicOption } from '@/components/common/Select/helpers';

import selectStyles from '@/components/common/Select/Select.module.scss';
import { MIN_SYMBOLS_TO_SEARCH_COUNT } from '@/components/common/Select/constants';

import styles from './ChannelReagent.module.scss';

const cn = classnames.bind(styles);
const cnSelect = classnames.bind(selectStyles);

type TChannelReagentProps = {
  isEditMode: boolean;
  experimentId: string;
  datasetDetails: TDatasetDetails;
};

const ChannelReagent: FC<TChannelReagentProps> = ({ isEditMode, experimentId, datasetDetails }) => {
  const [fetchReagentsAutocomplete] = appAPI.useLazyFetchReagentsAutocompleteQuery();
  const [updateReagentList] = appAPI.useUpdateReagentListMutation();
  const [deleteReagent] = appAPI.useDeleteReagentMutation();

  const [selectedOption, setSelectedOption] = useState<Nullable<TBasicOption>>(null);
  const selectedOptionLabel = useRef('');

  const channelReagent = useMemo(() => {
    if (datasetDetails.reagents?.length) {
      return datasetDetails.reagents[0];
    }
    return null;
  }, [datasetDetails.reagents]);

  const loadOptions = (inputValue: string, callback: (options: TBasicOption[]) => void) => {
    const searchValue = inputValue.length === 0 ? selectedOptionLabel.current : inputValue;
    if (searchValue.length < MIN_SYMBOLS_TO_SEARCH_COUNT) {
      const optionList = [];
      if (selectedOptionLabel.current) {
        optionList.unshift({ label: 'Unselect', value: '' });
      }
      callback(optionList);
      return;
    }

    fetchReagentsAutocomplete(searchValue, true)
      .unwrap()
      .then((autocompleteReagentList) => {
        const optionList = autocompleteReagentList.map((reagent) => ({ label: reagent.name, value: reagent.id }));
        if (selectedOptionLabel.current) {
          optionList.unshift({ label: 'Unselect', value: '' });
        }
        callback(optionList);
      })
      .catch((error) => {
        const optionList = [];
        if (selectedOptionLabel.current) {
          optionList.unshift({ label: 'Unselect', value: '' });
        }
        callback(optionList);
        showErrorToast(getErrorMessage(error));
      });
  };

  const noOptionsMessage = (obj: { inputValue: string }) => <>{getNoOptionsMessage(obj)}</>;

  const deleteSelectedReagent = (successCallback?: () => void) => {
    deleteReagent({
      experimentId,
      scanId: datasetDetails.scanId,
      laneId: datasetDetails.laneId,
      channelId: datasetDetails.channelId,
      idReagentToDelete: selectedOption?.value,
    })
      .then(successCallback)
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  const addSelectedReagent = (option: TOption) => {
    if (!isBasicOption(option)) {
      return;
    }
    updateReagentList({
      experimentId,
      scanId: datasetDetails.scanId,
      laneId: datasetDetails.laneId,
      channelId: datasetDetails.channelId,
      reagentList: [{ name: option.label, id: option.value as string }],
    }).catch((error) => {
      showErrorToast(getErrorMessage(error));
    });
  };

  const handleSelectedOptionChange = useCallback(
    (option: Nullable<TBasicOption>) => {
      if (!option) {
        return;
      }

      // Unselect option
      if (isBasicOption(option) && option.value === '') {
        if (selectedOption) {
          setSelectedOption(null);
          deleteSelectedReagent();
        }
        return;
      }

      setSelectedOption(option);
      if (selectedOption) {
        // If option is already selected it needs to be deleted first
        deleteSelectedReagent(() => {
          addSelectedReagent(option);
        });
      } else {
        addSelectedReagent(option);
      }
    },
    [selectedOption, datasetDetails]
  );

  const handleVoidClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (selectedOption) {
      return;
    }
    if (channelReagent) {
      setSelectedOption({ label: channelReagent.name, value: channelReagent.id });
    } else {
      setSelectedOption(null);
    }
  }, [channelReagent]);

  useEffect(() => {
    selectedOptionLabel.current = selectedOption?.label ?? '';
  }, [selectedOption]);

  return (
    <>
      {!isEditMode && !channelReagent && <div>&ndash;</div>}
      {!isEditMode && channelReagent && (
        <div className={cn('reagents-autocomplete__data-text')} {...addTooltip(channelReagent.name)}>
          {channelReagent.name}
        </div>
      )}
      {isEditMode && (
        <div aria-hidden className={cn('reagents-autocomplete')} onClick={handleVoidClick}>
          <AsyncSelect
            placeholder="Type at least 3 symbols..."
            classNames={{
              valueContainer: () => cn('reagents-autocomplete__selector'),
              container: ({ isDisabled }) =>
                cnSelect('select', 'select_light', {
                  select_disabled: isDisabled,
                }),
              control: () => cnSelect('select__control'),
              dropdownIndicator: () => cnSelect('select__dropdown-indicator'),
              indicatorSeparator: () => cnSelect('select__indicator-separator'),
              menu: () => cnSelect('select__menu', 'select__menu_light'),
              menuPortal: () => cnSelect('select__menu-portal'),
              menuList: () => cnSelect('select__menu-list', 'select__menu-list_light'),
              option: ({ isFocused, isSelected, isMulti, isDisabled }) =>
                cnSelect('select__option', {
                  select__option_multiple: isMulti,
                  select__option_focused: isFocused,
                  select__option_selected: isSelected,
                  select__option_disabled: isDisabled,
                  'select__option_light-focus': isFocused,
                }),
              clearIndicator: () => cnSelect('select__clear-indicator'),
              placeholder: () => cnSelect('select__placeholder'),
              singleValue: () => cnSelect('select__single-value'),
            }}
            value={selectedOption}
            onChange={handleSelectedOptionChange}
            loadOptions={loadOptions}
            defaultOptions
            noOptionsMessage={noOptionsMessage}
            key={selectedOptionLabel.current}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        </div>
      )}
    </>
  );
};

export default memo(ChannelReagent);
