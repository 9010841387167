import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { getJobRunCommandInfo } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';
import { JobRunStatus, JobRunEdge, JobDefinitionEdge, ListJobRunsInput, ListJobDefinitionsInput } from '@/graphql/API';
import * as queries from '@/graphql/queries';

import { experimentSelectors } from '@/store/slices/experiment';

import PageHeader from '@/components/Layout/Page/PageHeader';
import NoDataFound from '@/components/common/NoDataFound';
import VisibleInView from '@/components/common/VisibleInView';

import JobRunsControlPanel from './components/JobRunsControlPanel';
import JobRunListItem, { TJobRunDetails } from './components/JobRunListItem';

import styles from './JobRunList.module.scss';

const cn = classnames.bind(styles);

type TJobRunListProps = {
  hideDashboardHeader?: boolean;
  className?: string;
};

const JobRunList: FC<TJobRunListProps> = ({ hideDashboardHeader = false, className }) => {
  const experimentId = useSelector(experimentSelectors.selectCurrentExperimentId);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedJobStatusList, setSelectedJobStatusList] = useState<JobRunStatus[]>([
    JobRunStatus.PENDING,
    JobRunStatus.RUNNING,
    JobRunStatus.ENQUEUED,
  ]);

  const jobRunsInput = useMemo(
    () =>
      experimentId
        ? ({
            tags: [{ key: 'experiment_id', value: experimentId }],
          } as ListJobRunsInput)
        : undefined,
    [experimentId]
  );

  const {
    edgeList: jobRunEdgeList,
    isLoading: isJobRunsLoading,
    isError: isJobRunsError,
    error: jobRunsError,
    refetch: refetchJobRuns,
  } = useGraphqlQueryFullList<ListJobRunsInput, JobRunEdge>({
    query: queries.jobRuns,
    dataName: 'jobRuns',
    input: jobRunsInput,
  });

  const {
    edgeList: jobDefinitionEdgeList,
    isError: isJobDefinitionsError,
    error: jobDefinitionsError,
  } = useGraphqlQueryFullList<ListJobDefinitionsInput, JobDefinitionEdge>({
    query: queries.jobDefinitions,
    dataName: 'jobDefinitions',
  });

  const [jobRunDetailsList, setJobRunDetailsList] = useState<TJobRunDetails[]>([]);

  const filteredJobRunDetailsList = useMemo(
    () =>
      jobRunDetailsList.filter(
        (jobRunDetails) =>
          (selectedJobStatusList.length === 0 || selectedJobStatusList.includes(jobRunDetails.status)) &&
          (jobRunDetails.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            jobRunDetails.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
            jobRunDetails.jobDefinition?.name.toLowerCase().includes(searchQuery.toLowerCase()))
      ),
    [jobRunDetailsList, searchQuery, selectedJobStatusList]
  );

  const toggleJobRunLogsOpen = (jobRunId: string) => {
    setJobRunDetailsList((prev) =>
      prev.map((jobRun) => {
        if (jobRun.id === jobRunId) {
          return { ...jobRun, logs: { ...jobRun.logs, isOpen: !jobRun.logs.isOpen } };
        }
        return jobRun;
      })
    );
  };

  const setJobRunLogsText = (jobRunId: string, text: string) => {
    setJobRunDetailsList((prevList) =>
      prevList.map((jobRun) => {
        if (jobRun.id === jobRunId) {
          return { ...jobRun, logs: { ...jobRun.logs, text } };
        }
        return jobRun;
      })
    );
  };

  const setJobRunStatus = (jobRunId: string, status: JobRunStatus) => {
    setJobRunDetailsList((prevList) =>
      prevList.map((jobRun) => {
        if (jobRun.id === jobRunId) {
          return { ...jobRun, status };
        }
        return jobRun;
      })
    );
  };

  useEffect(() => {
    const newJobRunDetailsList = jobRunEdgeList.map((jobRunEdge) => {
      const {
        node: { containerOverrides, organizationId, id, name, status, createdAt, jobDefinition },
      } = jobRunEdge;
      const jobRunDefinition = jobDefinitionEdgeList.find(
        ({ node: { id: jobDefinitionId } }) => jobDefinitionId === jobDefinition.id
      );
      const { laneId, experimentId: jobRunExperimentId } = getJobRunCommandInfo(jobRunEdge);

      return {
        organizationId,
        experimentId: jobRunExperimentId,
        laneId,
        id,
        name,
        status,
        createdAt,
        jobDefinition: jobRunDefinition?.node,
        logs: { text: 'Loading...', isOpen: false },
        containerOverrides: containerOverrides ?? null,
      };
    });
    setJobRunDetailsList(newJobRunDetailsList);
  }, [jobRunEdgeList, jobDefinitionEdgeList]);

  useEffect(() => {
    if (isJobRunsError) {
      showErrorToast(getErrorMessage(jobRunsError));
    }
  }, [isJobRunsError, jobRunsError]);

  useEffect(() => {
    if (isJobDefinitionsError) {
      showErrorToast(getErrorMessage(jobDefinitionsError));
    }
  }, [isJobDefinitionsError, jobDefinitionsError]);

  return (
    <div className={cn('dashboard', className)}>
      <div className={cn('dashboard__content', 'content')}>
        {!hideDashboardHeader && (
          <PageHeader>
            <PageHeader.Section>
              <PageHeader.Title as="h1">Jobs Dashboard</PageHeader.Title>
            </PageHeader.Section>
            <PageHeader.Section>
              <PageHeader.Title isStatistics>{filteredJobRunDetailsList.length}</PageHeader.Title>
              <PageHeader.Subtitle>Job runs</PageHeader.Subtitle>
            </PageHeader.Section>
          </PageHeader>
        )}
        <JobRunsControlPanel
          isLoading={isJobRunsLoading}
          isSearchDisabled={jobRunEdgeList.length === 0}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedJobStatusList={selectedJobStatusList}
          setSelectedJobStatusList={setSelectedJobStatusList}
          jobRunsCount={hideDashboardHeader ? filteredJobRunDetailsList.length : undefined}
        />

        <div className={cn('dashboard__items_list')}>
          {filteredJobRunDetailsList.map((jobRunDetails) => (
            <VisibleInView key={jobRunDetails.id} placeholderClassName={cn('job-placeholder')}>
              <JobRunListItem
                jobRunDetails={jobRunDetails}
                searchQuery={searchQuery}
                refetchData={refetchJobRuns}
                toggleJobRunLogsOpen={toggleJobRunLogsOpen}
                setJobRunLogsText={setJobRunLogsText}
                setJobRunStatus={setJobRunStatus}
              />
            </VisibleInView>
          ))}
        </div>

        {!isJobRunsLoading && filteredJobRunDetailsList.length === 0 && (
          <NoDataFound size="normal" alignment="center" />
        )}
      </div>
    </div>
  );
};

export default JobRunList;
