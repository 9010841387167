import { SVGProps } from 'react';

const UsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.015 27.4846C18.936 24.3732 16.3889 21.8751 13.2584 21.8751C10.1279 21.8751 7.58086 24.3732 7.50189 27.4846H6.0015C6.08078 23.5447 9.29946 20.3751 13.2584 20.3751C17.2174 20.3751 20.4361 23.5447 20.5154 27.4846H19.015ZM6.00001 27.6462V27.6209C6 27.6251 6 27.6293 6 27.6335C6 27.6377 6 27.6419 6.00001 27.6462Z"
      fill="currentColor"
    />
    <path
      d="M16.4169 15.9084C16.4169 17.6527 15.0028 19.0668 13.2585 19.0668C11.5142 19.0668 10.1001 17.6527 10.1001 15.9084C10.1001 14.1641 11.5142 12.75 13.2585 12.75C15.0028 12.75 16.4169 14.1641 16.4169 15.9084Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.4996 27.4846C34.4207 24.3732 31.8736 21.8751 28.7431 21.8751C25.6126 21.8751 23.0655 24.3732 22.9866 27.4846H21.4862C21.5655 23.5447 24.7841 20.3751 28.7431 20.3751C32.7021 20.3751 35.9208 23.5447 36 27.4846H34.4996ZM21.4847 27.6564V27.6107C21.4847 27.6183 21.4847 27.6259 21.4847 27.6335C21.4847 27.6412 21.4847 27.6488 21.4847 27.6564Z"
      fill="currentColor"
    />
    <path
      d="M31.9015 15.9084C31.9015 17.6527 30.4875 19.0668 28.7432 19.0668C26.9988 19.0668 25.5848 17.6527 25.5848 15.9084C25.5848 14.1641 26.9988 12.75 28.7432 12.75C30.4875 12.75 31.9015 14.1641 31.9015 15.9084Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default UsersIcon;
