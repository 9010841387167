import { polygonContains } from 'd3-polygon';
import { v4 as uuidv4 } from 'uuid';

import { isNumber } from './common';
import {
  isCircleTypeGate,
  isPolygonTypeGate,
  isPolarAreaTypeGate,
  isPolarTypeGate,
  isRangeTypeGate,
} from './typeGuards';
import { transformPolarArea } from '../store/services/app/dataProvider';
import { EChartType } from '../types/charts';
import { isHistogramsChartType } from './charts/lineHistogram';

const gateType: Record<TGatePropertyType, TGateType> = {
  circle: 'circle',
  polygon: 'polygon',
  rectangle: 'polygon',
  polar: 'polar',
  range: 'range',
  'split-gate': 'polar',
};

type TUpdatedGateListData = {
  gates?: TGate[];
  parentGate?: TGate;
  updatedGate: Partial<TGate>;
};

type TDefineIsGateShouldBeDrawn = {
  gate: TGate;
  selectedGate: Nullable<TGate>;
  xAxis: string;
  yAxis: string;
  plotType: EChartType;
};

type TIsPointInGate = {
  x?: number;
  y?: number;
  gate?: TGate;
  parentGate?: Nullable<TGate>;
  scanId?: string;
  laneId?: string;
};

type TGetGateShape = {
  gate: TGate;
  parentGate?: Nullable<TGate>;
  scanId?: string;
  laneId?: string;
};

export const getGateShape = ({ gate, parentGate, scanId, laneId }: TGetGateShape): TGateShape => {
  const overrides: TGatesShapeOverrides[] =
    Array.isArray(gate?.overrides) && gate?.overrides.length ? gate.overrides : [];

  let shapeForCurrentDataset = overrides.find(
    (data: TGatesShapeOverrides) => data.scanId === scanId && data.laneId === laneId
  )?.shape;

  if (!scanId || !laneId) return gate.shape;

  if (isPolarAreaTypeGate(gate.shape) && parentGate) {
    const parentGateOverrides: TGatesShapeOverrides[] =
      Array.isArray(parentGate?.overrides) && parentGate?.overrides.length ? parentGate.overrides : [];

    shapeForCurrentDataset = parentGateOverrides.find(
      (data: TGatesShapeOverrides) => data.scanId === scanId && data.laneId === laneId
    )?.shape;
  }

  if (!shapeForCurrentDataset) {
    return gate.shape;
  }

  if (isPolarTypeGate(shapeForCurrentDataset) && isPolarAreaTypeGate(gate.shape)) {
    return {
      ...gate.shape,
      model: {
        ...gate.shape.model,
        x: shapeForCurrentDataset?.model?.x,
        y: shapeForCurrentDataset?.model?.y,
      },
    };
  }

  return shapeForCurrentDataset as TGateShape;
};

export const getSplitPolarGateSectors = (xAxis: string) => [
  {
    name: `${xAxis}-`,
    sectorAngle: 180,
    offsetAngle: 270,
  },
  {
    name: `${xAxis}+`,
    sectorAngle: 180,
    offsetAngle: 90,
  },
];

export const POLAR_SECTORS_ANGLES = [
  {
    name: 'Q3',
    sectorAngle: 90,
    offsetAngle: 0,
  },
  {
    name: 'Q2',
    sectorAngle: 90,
    offsetAngle: 90,
  },
  {
    name: 'Q1',
    sectorAngle: 90,
    offsetAngle: 180,
  },
  {
    name: 'Q4',
    sectorAngle: 90,
    offsetAngle: 270,
  },
];

const isPointInsideCircle = ({ cx, cy, x, y, rx, ry }: Record<string, number>) => {
  const result = (x - cx) ** 2 / rx ** 2 + (y - cy) ** 2 / ry ** 2;
  return result <= 1;
};

export const isPointInGate = ({ x, y, gate, parentGate, scanId, laneId }: TIsPointInGate) => {
  if (!isNumber(x) || !isNumber(y) || !gate) return false;
  const shape = getGateShape({ gate, parentGate, scanId, laneId });
  const { type } = shape;

  const isPointInGateMethodMap: Record<TGateType, () => boolean> = {
    circle: () => {
      if (isCircleTypeGate(shape)) {
        const { model } = shape;
        const isPointInCircleGate = isPointInsideCircle({
          cx: model.x,
          cy: model.y,
          x,
          y,
          rx: Math.abs(model.rx),
          ry: Math.abs(model.ry),
        });

        return isPointInCircleGate;
      }
      return false;
    },
    polygon: () => {
      if (isPolygonTypeGate(shape)) {
        const { model } = shape;
        const { points = [] } = model;
        if (!points.length) return false;

        const polygons: [number, number][] = points.map((point) => [point.x, point.y]);

        return polygonContains(polygons, [x, y]);
      }
      return false;
    },
    polar: () => false,
    'polar-sector': () => {
      if (isPolarAreaTypeGate(shape)) {
        const { model } = shape;
        const polarGateSubType = gate.properties?.type ?? 'polar';

        const checkByX: Record<TPolarOffsetAngles, boolean> = {
          270: x <= model.x,
          180: x <= model.x,
          90: x > model.x,
          0: x >= model.x,
        };

        const checkByY: Record<TPolarOffsetAngles, boolean> = {
          270: y <= model.y,
          180: y > model.y,
          90: y > model.y,
          0: y <= model.y,
        };

        return polarGateSubType === 'polar'
          ? checkByX[model.offsetAngle] && checkByY[model.offsetAngle]
          : checkByX[model.offsetAngle];
      }
      return false;
    },
    range: () => {
      if (isRangeTypeGate(shape)) {
        const { model } = shape;

        const xMin = Math.min(model.x1, model.x2);
        const xMax = Math.max(model.x1, model.x2);

        return x >= xMin && x <= xMax;
      }
      return false;
    },
  };

  return isPointInGateMethodMap[type]();
};

export const defineIsGateShouldBeDrawn = ({
  gate,
  selectedGate,
  xAxis,
  yAxis,
  plotType,
}: TDefineIsGateShouldBeDrawn) => {
  const isVisibleInCurrentPlot = selectedGate ? gate.parentId === selectedGate.id : true;
  const isDimensionsMatched = isGateDimensionsMatch(gate, xAxis, yAxis);
  const { properties } = gate;

  // determine whether the current gate should be displayed on the current chart type
  let isChartTypeMatchTheGateType = properties?.type !== 'range' && properties?.type !== 'split-gate';

  if (isHistogramsChartType(plotType)) {
    isChartTypeMatchTheGateType = properties?.type === 'range' || properties?.type === 'split-gate';
  }

  return isVisibleInCurrentPlot && isDimensionsMatched && isChartTypeMatchTheGateType;
};

export const revertPoint = (points: TGatePolygons) => points.map((point) => ({ x: point.y, y: point.x }));

export const isGateDimensionsMatch = (gate: TGate, xAxis: string, yAxis: string) => {
  const gateTypeByProperty = gate.properties.type;

  if (gateTypeByProperty && ['range', 'split-gate'].includes(gateTypeByProperty)) {
    return gate.xDimension === xAxis;
  }

  return (
    (gate.xDimension === xAxis && gate.yDimension === yAxis) || (gate.xDimension === yAxis && gate.yDimension === xAxis)
  );
};

export const isGateDimensionsReverted = (gate: TGate, xAxis: string, yAxis: string) => {
  if (gate.xDimension === gate.yDimension) return false;

  return gate.xDimension === yAxis && gate.yDimension === xAxis;
};

export function findParentGate(gateList: TGate[], parentId: string): Nullable<TGate> {
  for (let i = 0; i < gateList.length; i++) {
    const gate = gateList[i];

    if (gate?.id === parentId) {
      return gate;
    }

    if (gate?.gateNodes) {
      const parentObject = findParentGate(gate?.gateNodes, parentId);
      if (parentObject) {
        return parentObject;
      }
    }
  }

  return null;
}

export function getGateById(id: Nullable<string>, gateList?: TGate[]): Nullable<TGate> {
  if (!gateList || !id) {
    return null;
  }

  for (let i = 0; i < gateList.length; i++) {
    const gate = gateList[i];

    if (gate?.id === id) {
      return gate;
    }

    if (gate?.gateNodes) {
      const parentObject = getGateById(id, gate?.gateNodes);
      if (parentObject) {
        return parentObject;
      }
    }
  }

  return null;
}

export const prepareUpdatedGateList = (payload: TUpdatedGateListData): TGate[] => {
  const { gates, updatedGate } = payload;

  const updatedGateList: TGate[] = gates
    ? gates.map((gate: TGate) => {
        if (gate.id === updatedGate.id) {
          const { model: currentGateModel } = gate.shape;
          const { shape: { model: updatedGateModel = null } = {}, gateNodes = null } = updatedGate;
          const newProperties = updatedGate?.properties ?? gate.properties;
          const updatedGateNodes = gateNodes ?? prepareUpdatedGateList({ ...payload, gates: gate.gateNodes });

          return {
            id: gate.id,
            parentId: gate.parentId,
            name: updatedGate?.name ?? gate.name,
            scanId: gate.scanId,
            laneId: gate.laneId,
            level: gate.level,
            shape: {
              type: gate.shape.type,
              model: updatedGateModel ?? currentGateModel,
            } as TGateShape,
            overrides: updatedGate?.overrides ?? gate.overrides,
            xDimension: updatedGate?.xDimension ?? gate.xDimension,
            yDimension: updatedGate?.yDimension ?? gate.yDimension,
            gateNodes: updatedGateNodes,
            properties: newProperties,
          };
        }

        return {
          ...gate,
          gateNodes: prepareUpdatedGateList({ ...payload, gates: gate.gateNodes }),
        };
      })
    : [];

  updatedGateList.sort((current, next) => current.name.localeCompare(next.name));

  return updatedGateList;
};

export const prepareNewGateList = (newGate: TGate, gates?: TGate[]): TGate[] => {
  const newGateList = gates
    ? gates.map((gate: TGate) => {
        if (newGate.parentId) {
          const updatedGateNodes = [...gate.gateNodes, newGate].sort((current, next) =>
            current.name.localeCompare(next.name)
          );
          const gateNodes =
            newGate.parentId === gate.id ? updatedGateNodes : prepareNewGateList(newGate, gate.gateNodes);

          return { ...gate, gateNodes };
        }
        return gate;
      })
    : [];

  if (!newGate.parentId) {
    newGateList.push(newGate);
  }

  newGateList.sort((current, next) => current.name.localeCompare(next.name));

  return newGateList;
};

export const getGatesAfterDelete = (gateId: string, gates?: TGate[]): TGate[] => {
  const newGateList: TGate[] = [];
  gates?.forEach((gate: TGate) => {
    if (gate.id !== gateId) {
      if (gate.gateNodes.length) {
        const gateNodes = getGatesAfterDelete(gateId, gate.gateNodes);
        const updatedGate = { ...gate, gateNodes };
        newGateList.push(updatedGate);
      } else {
        newGateList.push(gate);
      }
    }
  });

  newGateList.sort((current, next) => current.name.localeCompare(next.name));

  return newGateList;
};

export const getGatesAfterChildrenDelete = (gateId: string, gates?: TGate[]): TGate[] => {
  const newGateList: TGate[] = [];
  gates?.forEach((gate: TGate) => {
    if (gate.id !== gateId) {
      if (gate.gateNodes.length) {
        const gateNodes = getGatesAfterChildrenDelete(gateId, gate.gateNodes);
        const updatedGate = { ...gate, gateNodes };
        newGateList.push(updatedGate);
      } else {
        newGateList.push(gate);
      }
    } else {
      newGateList.push({ ...gate, gateNodes: [] });
    }
  });

  return newGateList;
};

export const prepareNewGateData = ({
  gateName = '',
  xDimension,
  yDimension,
  parentGate,
  isCageLevel,
  newGateModel,
  scanId,
  laneId,
  gateTypeByTool,
  currentChartType,
}: {
  gateName?: string;
  xDimension: string;
  yDimension: string;
  parentGate?: Nullable<TGate>;
  isCageLevel?: boolean;
  newGateModel: TNewGateModelData;
  scanId: string;
  laneId: string;
  gateTypeByTool?: TGatePropertyType;
  currentChartType: EChartType;
}) => {
  const newGateLevel = parentGate ? parentGate.level + 1 : 0;
  const shapeData = {
    type: gateType[newGateModel.type],
    model: { ...newGateModel.model },
  };

  const gatePropertyType = gateTypeByTool ?? newGateModel.type;

  const gateId = uuidv4();
  const gateProperties: TGateProperties = {
    entityLevel: isCageLevel ? 'cage' : 'object',
    type: gatePropertyType,
    plotType: currentChartType,
  };

  const baseData = {
    id: gateId,
    parentId: parentGate?.id ?? '',
    level: newGateLevel,
    name: gateName,
    scanId,
    laneId,
    shape: shapeData,
    xDimension,
    yDimension,
    gateNodes: [],
    properties: gateProperties,
  };

  const data: TGate = {
    ...baseData,
    shape: {
      ...shapeData,
      model: {
        ...shapeData.model,
      },
    } as TGateShape,
  };

  return data;
};

export const prepareUpdatedGateData = (gate: TGate, updatedGateData: Partial<TGate>) => {
  const isPolarGate = isPolarTypeGate(gate.shape);
  let dataToUpdate = {
    gateId: gate.id,
    dataToUpdate: updatedGateData,
  };

  if (isPolarGate) {
    const updatedGateNodes = gate?.gateNodes.map(
      (polarSector) => transformPolarArea(polarSector, { ...gate, ...updatedGateData }, gate.level) ?? polarSector
    );

    dataToUpdate = {
      gateId: gate.id,
      dataToUpdate: { ...updatedGateData, gateNodes: updatedGateNodes },
    };
  }

  return dataToUpdate;
};

export const defineGateDeletionConfirmationMessage = (gate: TGate, gates?: TGate[]) => {
  if (!gates) return '';
  const defaultConfirmation = `Are you sure you want to delete gate ${gate.name}?`;
  const isPolarSector = isPolarAreaTypeGate(gate.shape);
  const isPolar = isPolarTypeGate(gate.shape);

  if (!isPolarSector && !isPolar) return defaultConfirmation;

  const polarSectorParent = isPolarSector ? findParentGate(gates, gate.parentId) : gate;

  if (polarSectorParent) {
    const title = polarSectorParent.gateNodes.reduce((str, item, index) => {
      const isTheLastChild = index === polarSectorParent.gateNodes.length - 1;
      const itemStr = isTheLastChild ? ` and ${item.name}` : `${item.name},`;
      return `${str} ${itemStr}`;
    }, '');

    return `Are you sure you want to delete Polar gate? It will delete ${title}.`;
  }

  return defaultConfirmation;
};

const GATES_FOR_SCATTER_CHARTS = ['circle', 'polygon', 'rectangle', 'polar'];
const GATES_FOR_HISTOGRAM_CHARTS = ['split-gate', 'range'];

export const isGateForCurrentChartType = (gateTypeByProperty: TGatePropertyType, plotType: EChartType) => {
  if (plotType === EChartType.heatmap) return false;

  if (isHistogramsChartType(plotType)) {
    return GATES_FOR_HISTOGRAM_CHARTS.includes(gateTypeByProperty);
  }
  return GATES_FOR_SCATTER_CHARTS.includes(gateTypeByProperty);
};

export const getUngatedName = (isObjectLvl: boolean) => (isObjectLvl ? 'Object-level' : 'Cage-level');

export const filterGateListByEntityLevel = (gateList: TGate[], isObjectLevel: boolean) => {
  if (isObjectLevel) {
    return gateList.filter(({ properties }) => properties?.entityLevel === 'object' || !properties?.entityLevel);
  }
  return gateList.filter((gate) => gate.properties?.entityLevel === 'cage');
};
