import { CSSProperties, FC, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './ListWithActiveTab.module.scss';
import Item from './Item';

const cn = classnames.bind(styles);

type TList = {
  activeCardIndex?: number;
  cardContentList: ReactNode[];
  customCardWidth?: number;
  customListRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

const ListWithActiveTab: FC<TList> = ({ activeCardIndex = -1, cardContentList, customCardWidth, customListRef }) => {
  const [leftPos, setLeftPos] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);

  const listRef: MutableRefObject<Nullable<HTMLDivElement>> = useRef(null);

  const handleActiveTab = useCallback(
    (newIndex: number, offsetLeft?: number) => {
      if (newIndex === activeIndex) {
        setActiveIndex(-1);
        return;
      }
      const scrollLeft = listRef.current?.scrollLeft;

      setLeftPos((scrollLeft ?? 0) - (offsetLeft ?? 0));
      setActiveIndex(newIndex);
    },
    [activeIndex]
  );

  useEffect(() => {
    if (activeCardIndex === -1) {
      setActiveIndex(-1);
    }
  }, [activeCardIndex]);

  return (
    <div
      ref={(el) => {
        listRef.current = el;
        if (customListRef) {
          customListRef.current = el;
        }
      }}
      className={cn('list')}
      style={{ '--left': leftPos, ...(customCardWidth ? { '--card-width': customCardWidth } : {}) } as CSSProperties}
    >
      {cardContentList.map((content, contentIndex) => {
        const key = contentIndex;
        return (
          <Item
            key={key}
            index={contentIndex}
            isOpen={contentIndex === activeIndex || activeCardIndex === contentIndex}
            onChangeActive={handleActiveTab}
            listRef={listRef}
          >
            {content}
          </Item>
        );
      })}
    </div>
  );
};

export default ListWithActiveTab;
