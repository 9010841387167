import { FC, ReactNode, RefObject } from 'react';
import classnames from 'classnames/bind';

import styles from './RunDesignCard.module.scss';

import RunDesignCardHeader from './Header';
import RunDesignCardContent from './Content';

const cn = classnames.bind(styles);

type TExtension = {
  Header: typeof RunDesignCardHeader;
  Content: typeof RunDesignCardContent;
};

type TRunDesignCard = {
  children?: ReactNode;
  className?: string;
  cardRef?: RefObject<HTMLDivElement>;
  withoutHeader?: boolean;
};

const RunDesignCard: FC<TRunDesignCard> & TExtension = ({ children, className, cardRef, withoutHeader }) => (
  <div ref={cardRef} className={cn('card', className, { 'card_one-row': withoutHeader })}>
    {children}
  </div>
);

RunDesignCard.Header = RunDesignCardHeader;
RunDesignCard.Content = RunDesignCardContent;

export default RunDesignCard;
