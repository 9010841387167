import { FC, MouseEventHandler, SVGProps } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';

import styles from './ChartModebarButton.module.scss';

const cn = classnames.bind(styles);

type TChartModebarButtonProps = {
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
  disabled?: boolean;
  iconClassName?: string;
  tooltip?: string;
};

const ChartModebarButton: FC<TChartModebarButtonProps> = ({
  IconComponent,
  onClick,
  active,
  disabled,
  iconClassName,
  tooltip,
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    color="white"
    className={cn('chart-modebar-button', { 'chart-modebar-button_active': active })}
    tooltip={tooltip}
  >
    <IconComponent className={cn('chart-modebar-button__icon', iconClassName)} />
  </Button>
);

export default ChartModebarButton;
