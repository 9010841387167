import addLut from '@/hooks/useWebgl/helpers/addLut';

const maxChannels = 8;
const projectSpecifiedInit = async (gl: WebGLRenderingContext, program: WebGLProgram) => {
  const imageLocation = Array.from({ length: maxChannels }).map((_, index) =>
    gl.getUniformLocation(program, `u_image${index}`)
  );

  const lutLocation = Array.from({ length: maxChannels }).map((_, index) =>
    gl.getUniformLocation(program, `u_lut${index}`)
  );

  // lookup uniforms
  const uniforms = {
    rgb: gl.getUniformLocation(program, 'u_rgb'),
    min: gl.getUniformLocation(program, 'u_min'),
    max: gl.getUniformLocation(program, 'u_max'),
    isActive: gl.getUniformLocation(program, 'u_isActive'),
    isUseLut: gl.getUniformLocation(program, 'u_isUseLut'),
  };

  const resolutionLocation = gl.getUniformLocation(program, 'u_resolution');

  // set the resolution
  gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);

  imageLocation.forEach((location, index) => gl.uniform1i(location, index + 1));

  lutLocation.forEach((location, index) => gl.uniform1i(location, index + 10));

  const lutTextureList = await addLut(gl);

  return {
    uniforms,
    lutTextureList,
  };
};

export default projectSpecifiedInit;
