import { Dispatch, FC, SetStateAction, memo } from 'react';
import classnames from 'classnames/bind';

import type { TButtonColor } from '@/helpers/interfaces';

import ActionButton from './ActionButton';

import styles from './StepActions.module.scss';

const cn = classnames.bind(styles);

export type TAction = {
  label: string;
  disabled?: boolean;
  onClick: (changeAction: Dispatch<SetStateAction<TAction>>) => void;
  className?: string;
  colorBtn?: TButtonColor;
  isLoading?: boolean;
};

export type TActionGroup = TAction[];

export type TStepActionsProps = {
  actionGroups: TActionGroup[];
  className?: string;
};

const StepActions: FC<TStepActionsProps> = ({ actionGroups, className }) => (
  <div className={cn('step-actions', className)}>
    {actionGroups.toReversed().map((actionGroup, groupIndex) => (
      <div className={cn('step-actions__group')} key={groupIndex}>
        {actionGroup.map((action) => (
          <ActionButton key={action.label} actionData={action} />
        ))}
      </div>
    ))}
  </div>
);

export default memo(StepActions);
