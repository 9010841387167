import { useCallback, useMemo } from 'react';

import { PlotlyProxy, isNumber } from '@/helpers';

const useHighlightMarker = (plotlyProxy: PlotlyProxy) => {
  const highlightMarker = useCallback(
    (markerIndex: number, plotIndex?: number) => {
      let selectedpoints = [[markerIndex]];

      if (isNumber(plotIndex)) {
        selectedpoints = Array.from<number[]>({ length: Math.max(2, plotIndex + 1) }).fill([]);
        selectedpoints[plotIndex] = [markerIndex];
      }
      const update = { selectedpoints };

      plotlyProxy.forceRestyle(update);
    },
    [plotlyProxy]
  );

  const removeMarkerHighlight = useCallback(() => {
    const update = {
      selectedpoints: [null],
    };
    plotlyProxy.forceRestyle(update);
  }, [plotlyProxy]);

  return useMemo(() => ({ highlightMarker, removeMarkerHighlight }), [highlightMarker, removeMarkerHighlight]);
};

export default useHighlightMarker;
