import { FC, memo, useState, useMemo, CSSProperties } from 'react';
import classnames from 'classnames/bind';

import { getEntitiesData } from '@/hooks/useExperimentContext/helpers';

import icons from '@/components/common/icons';
import Popover, { TPopoverOption } from '@/components/common/Popover';
import UpdateGateNameModal from '@/components/common/UpdateGateNameModal';
import { EPreprocessingObjectType } from '@/store/slices/preprocessing/types';

import styles from './BeadCard.module.scss';
import { TBeadsProps, TCellsProps } from '../types';

const cn = classnames.bind(styles);

type TBeadCardProps = (TBeadsProps | TCellsProps) & {
  gate?: TGate;
  handleClick: (uuid: string) => void;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  handleRemoveGateByBeadId?: (uuid: string) => void;
  handleRenameGateByBeadId?: (uuid: string, newName: string) => void;
  entitiesByLanesAndGates?: TEntitiesByLanesAndGates;
  currentAppLane?: Nullable<TLane>;
  withoutPlaceholder?: boolean;
};

// TODO: change component name to more generic
const BeadCard: FC<TBeadCardProps> = ({
  gate,
  className,
  selected = false,
  disabled = false,
  handleClick,
  handleRemoveGateByBeadId,
  handleRenameGateByBeadId,
  entitiesByLanesAndGates = {},
  currentAppLane,
  type,
  item,
  withoutPlaceholder = false,
}) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);

  const gateCagesData = useMemo(
    () => getEntitiesData(entitiesByLanesAndGates, currentAppLane?.path, gate),
    [entitiesByLanesAndGates, currentAppLane, gate]
  );

  const popoverOptions = useMemo<TPopoverOption[]>(() => {
    const options: TPopoverOption[] = [];
    const typeStr = type.slice(0, -1);

    if (handleRenameGateByBeadId) {
      options.push({
        id: '1',
        title: `Rename ${typeStr} gate`,
        onClick: () => setIsOpenRenameModal(true),
        icon: <icons.PencilIcon />,
      });
    }
    if (handleRemoveGateByBeadId) {
      options.push({
        id: '2',
        title: `Delete ${typeStr} gate`,
        onClick: () => handleRemoveGateByBeadId(item.uuid),
        icon: <icons.DeleteIcon />,
      });
    }
    return options;
  }, [handleRemoveGateByBeadId, handleRenameGateByBeadId]);

  const handleOpenPopover = () => {
    setIsOpenPopover((prev) => !prev);
  };

  const handleBeadClick = () => {
    if (isOpenPopover) {
      return;
    }
    handleClick(item.uuid);
  };

  const handleRenameBeadGate = (gateData: TGate, updatedGateData: Partial<TGate>) => {
    if (!handleRenameGateByBeadId || !updatedGateData.name) {
      return;
    }
    handleRenameGateByBeadId(gateData.id, updatedGateData.name);
  };

  const cagesCount = useMemo<string>(() => {
    if (!gateCagesData) {
      return '';
    }

    const thousands = Math.floor(gateCagesData.cagesCount / 1000);
    const units = gateCagesData.cagesCount % 1000;

    let result = units.toString();
    if (thousands) {
      result = `${thousands},${units.toString().padStart(3, '0')}`;
    }

    return result;
  }, [gateCagesData]);

  const title = useMemo(() => {
    if (type === EPreprocessingObjectType.beads) {
      return item.name;
    }
    return gate || withoutPlaceholder ? item.name : item?.emptyLabel;
  }, [gate, item, type, withoutPlaceholder]);

  const cardColor = useMemo(
    () => (type === EPreprocessingObjectType.cells && item?.bgColor ? item.bgColor : '#dbf1fe'),
    [item, type]
  );

  const markerColor = useMemo(
    () =>
      type === EPreprocessingObjectType.cells && item?.color && (withoutPlaceholder || gate) ? item.color : 'none',
    [item, type]
  );

  return (
    <>
      <div
        role="none"
        className={cn(
          'bead-card',
          {
            'bead-card_disabled': disabled,
            'bead-card_selected': selected,
            'bead-card_big-gap': markerColor !== 'none',
          },
          className
        )}
        onClick={() => handleBeadClick()}
        style={
          {
            '--selected-card-background-color': cardColor,
            '--marker-card-color': markerColor,
          } as CSSProperties
        }
      >
        <div
          className={cn('bead-card__content-wrapper', {
            'bead-card__content-wrapper_inline': type === EPreprocessingObjectType.cells,
          })}
        >
          <span className={cn('bead-card__title')}>{title}</span>
          {type === 'beads' && item.beadSize && (
            <div className={cn('bead-card__description')}>
              <span>Size = {item.beadSize}</span>
              <span>Intensity = {item.beadIntensity}</span>
            </div>
          )}
          {gateCagesData?.name && (
            <div className={cn('bead-card__cages-data', 'cages')}>
              <span className={cn('cages__count')}>{cagesCount}</span>
              <span className={cn('cages__percent')}>({gateCagesData.cagesPercent})</span>
            </div>
          )}
        </div>
        {!disabled && !!popoverOptions.length && (
          <div className={cn('bead-card__popover-wrapper')}>
            <Popover
              isOpen={isOpenPopover}
              options={popoverOptions}
              setIsOpen={setIsOpenPopover}
              onClick={handleOpenPopover}
            >
              <div className={cn('bead-card__dots')}>
                <icons.DotIcon />
              </div>
            </Popover>
          </div>
        )}
      </div>
      {gate && (
        <UpdateGateNameModal
          isUpdateGatesInProgress={false}
          updateGate={handleRenameBeadGate}
          isModalOpen={isOpenRenameModal}
          closeModal={() => setIsOpenRenameModal(false)}
          gate={gate}
        />
      )}
    </>
  );
};

export default memo(BeadCard);
