import { SVGProps } from 'react';

const ReagentsListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <rect width="15" height="1.5" rx=".75" />
      <rect y="4" width="15" height="1.5" rx=".75" />
      <rect y="8" width="15" height="1.5" rx=".75" />
      <rect y="12" width="15" height="1.5" rx=".75" />
      <rect y="12" width="15" height="1.5" rx=".75" />
    </g>
  </svg>
);

export default ReagentsListIcon;
