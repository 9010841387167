import { SVGProps } from 'react';

const ApplyGateToIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor">
      <path d="M5.5 14.8845H3C1.89543 14.8845 1 13.9891 1 12.8845L1 10.3845" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M5.5 1L3 1C1.89543 1 1 1.89543 1 3L1 5.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M10.3846 1L12.8846 1C13.9892 1 14.8846 1.89543 14.8846 3V5.5" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M9 12.177L11.6346 15.5695L16.1338 9.38452"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ApplyGateToIcon;
