import { FC, useCallback, useLayoutEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { formatDate, NDASH } from '@/helpers';
import icons from '@/components/common/icons';
import { NavLink } from 'react-router-dom';
import { getUiServiceData } from '@/helpers/changelog';
import styles from './ApiInfoTable.module.scss';

const cn = classnames.bind(styles);

type TApiInfoTableProps = {
  apiInfoData?: TInfo;
  statusData?: TStatus;
  onRequestClose: () => void;
};
const apiInfoTableData = [
  { id: 'name', heading: 'name' },
  { id: 'open-api-version', heading: 'open API version' },
  { id: 'app-version', heading: 'app version' },
  { id: 'release-date', heading: 'release date' },
  { id: 'documentation-URL', heading: 'documentation URL' },
  { id: 'changelog-URL', heading: 'changelog URL' },
  { id: 'status', heading: 'status' },
];

const urls = ['documentationURL', 'changelogURL'] as const;

const serviceKeys = ['name', 'openApiVersion', 'appVersion', 'releaseDate', ...urls] as const;

type TServiceKeys = (typeof serviceKeys)[number];

const ApiInfoTable: FC<TApiInfoTableProps> = ({ apiInfoData, statusData, onRequestClose }) => {
  const [uiServiceData, setUiServiceData] = useState<TInfoService>();
  const handleTableItem = useCallback(
    (itemValue?: string, key?: TServiceKeys) =>
      key === 'releaseDate' && itemValue ? formatDate(itemValue, 'MMM d, hh:mm aaa') : itemValue ?? NDASH,
    []
  );

  const isNextPageLink = (link: string) => !link.endsWith('.md');

  const generateLinkComponent = (
    URLKey: 'documentationURL' | 'changelogURL' | undefined,
    service: TInfoService,
    key: TServiceKeys,
    documentationURLName?: string
  ) => {
    if (!URLKey) return handleTableItem(service?.[key], key);
    const link = service?.[key] ?? '';

    if (!link) {
      return <div className={cn('table__no-data')}>{documentationURLName ?? NDASH}</div>;
    }

    if (isNextPageLink(link)) {
      return (
        <a className={cn('table__link')} href={link} target="_blank" rel="noreferrer">
          <span className={cn('table__link-text')}>{documentationURLName ?? formatURLText(key)}</span>
          <icons.LinkIcon />
        </a>
      );
    }

    const serviceId = service?.name?.replace(' ', '-').toLowerCase();

    return (
      <NavLink to={`/changelog/${serviceId}`} className={cn('table__link')} onClick={onRequestClose}>
        {documentationURLName ?? formatURLText(key)}
      </NavLink>
    );
  };

  const formatURLText = useCallback((key: string) => key.replace('URL', ''), []);

  useLayoutEffect(() => {
    getUiServiceData().then((data) => setUiServiceData(data as TInfoService));
  }, []);

  return (
    <table className={cn('table')}>
      <thead className={cn('table__head')}>
        <tr className={cn('table__row', 'table__row_no-underline')}>
          {apiInfoTableData.map(({ id, heading }) => (
            <th key={id} className={cn('table__heading')}>
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {serviceKeys.map((key) => {
            const isURL = urls.find((elem) => elem === key);

            return (
              <td
                key={key}
                className={cn(
                  'table__data-element',
                  { 'table__data-element_center': key !== 'name' && !isURL },
                  { 'table__data-element_url': isURL }
                )}
              >
                {generateLinkComponent(
                  isURL,
                  uiServiceData as TInfoService,
                  key,
                  !uiServiceData?.documentationURL && key === 'documentationURL' ? 'TBD' : undefined
                )}
              </td>
            );
          })}
          <td className={cn('table__data-element', 'table__data-element_status', 'table__data-element_ok')}>OK</td>
        </tr>

        {apiInfoData?.services?.map((service, i) => (
          <tr key={service.name} className={cn('table__row')}>
            {serviceKeys.map((key) => {
              const isURL = urls.find((elem) => elem === key);

              return (
                <td
                  key={key}
                  className={cn(
                    'table__data-element',
                    { 'table__data-element_center': key !== 'name' && !isURL },
                    { 'table__data-element_url': isURL }
                  )}
                >
                  {generateLinkComponent(isURL, service, key)}
                </td>
              );
            })}
            <td
              className={cn('table__data-element', 'table__data-element_status', {
                [`table__data-element_${statusData?.services?.[i]?.status?.toLowerCase()}`]:
                  statusData?.services?.[i]?.status,
              })}
            >
              {statusData?.services?.[i]?.status}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ApiInfoTable;
