import type { TBaseGateSettings, TInitialGatesState } from './types';

export function getStateObject(state: TInitialGatesState, fullScreenChartDataId?: string) {
  return state.specificDatasetOptionMap?.[fullScreenChartDataId ?? ''] ?? state;
}

export function getStateValue<T extends TInitialGatesState, K extends keyof TBaseGateSettings>(
  state: T,
  key: K,
  fullScreenChartDataId?: Nullable<string>
): TBaseGateSettings[K] {
  const currentState = fullScreenChartDataId ? state.specificDatasetOptionMap?.[fullScreenChartDataId] : state;
  return currentState?.[key] ?? state?.[key];
}
