import { FC, ReactNode, Children } from 'react';

import classnames from 'classnames/bind';

import { CSSProperty } from '@/helpers/interfaces';

import styles from './PageHeader.module.scss';

import PageHeaderSection from './PageHeaderSection';
import PageHeaderTitle from './PageHeaderTitle';
import PageHeaderSubtitle from './PageHeaderSubtitle';

const cn = classnames.bind(styles);

type TPageHeaderProps = {
  className?: string;
  children: ReactNode;
};

type TPageHeaderExtensions = {
  Section: typeof PageHeaderSection;
  Title: typeof PageHeaderTitle;
  Subtitle: typeof PageHeaderSubtitle;
};

const PageHeader: FC<TPageHeaderProps> & TPageHeaderExtensions = ({ className, children }) => {
  const templateColumns = Children.count(children) - 1;
  return (
    <div className={cn('page-header', className)} style={{ '--template-columns': templateColumns } as CSSProperty}>
      {children}
    </div>
  );
};

PageHeader.Section = PageHeaderSection;
PageHeader.Title = PageHeaderTitle;
PageHeader.Subtitle = PageHeaderSubtitle;

export default PageHeader;
