/**
 * Basic EventEmitter. You can subscribe a callback, unsubscribe it or dispatch an event, calling all the subscribed handlers
 */
export class EventEmitter {
  constructor() {
    this.handlers = [];
    this.pendingHandlers = [];
    this.frozen = 0;
  }

  dispatch(event) {
    Object.freeze(Event);
    this.frozen += 1;

    this.handlers.forEach((handler) => handler(event));

    this.frozen -= 1;

    if (!this.frozen) {
      this.pendingHandlers.forEach((pendingHandler) => {
        if (pendingHandler.pending === 'add') {
          this.subscribe(pendingHandler.handler);
        } else if (pendingHandler.pending === 'remove') {
          this.unsubscribe(pendingHandler.handler);
        }
      });

      this.pendingHandlers = [];
    }
  }

  subscribe(handler) {
    if (this.frozen) {
      this.pendingHandlers.push({
        pending: 'add',
        handler,
      });
      return;
    }

    if (this.handlers.indexOf(handler) === -1) {
      this.handlers.push(handler);
    } else {
      throw new Error('handler was already subscribed');
    }
  }

  unsubscribe(handler) {
    if (this.frozen) {
      this.pendingHandlers.push({
        pending: 'remove',
        handler,
      });
      return;
    }

    if (this.handlers.indexOf(handler) === -1) {
      throw new Error('handler was never subscribed');
    }
    this.handlers = this.handlers.filter((filteredHandler) => filteredHandler !== handler);
  }

  unsubscribeAll() {
    this.handlers.forEach((handler) => this.unsubscribe(handler));
  }
}
