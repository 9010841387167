import type { RootState } from '@/store';
import { getStateValue } from './helpers';

export const selectIsUngatedSelected = (state: RootState) => state.gates.isUngatedSelected;

export const selectActiveGate = (state: RootState) => state.gates.activeGate;

export const selectSelectedGate = (state: RootState) => state.gates.selectedGate;

export const selectIsGatesDrawn = (state: RootState) => state.gates.isGatesDrawn;

export const selectIsCustomGateRangeActive = (state: RootState) => state.gates.isCustomGateRangeActive;

export const selectIsBlockDraw = (state: RootState) => state.gates.isBlockDraw;

export const selectGateLabelFormat = (datasetId?: Nullable<string>) => (storeState: RootState) =>
  getStateValue(storeState.gates, 'gateLabelFormat', datasetId);

export const selectSpecificDatasetOptionMap = (storeState: RootState) => storeState.gates.specificDatasetOptionMap;

export const selectAllGlobalGatesSettings = (storeState: RootState) => storeState.gates;

export const selectOnlyGateToDisplay = (storeState: RootState) => storeState.gates.onlyGateToDisplay;
