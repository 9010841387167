import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';
import classnames from 'classnames/bind';

import { Compound, Reagent, Stain } from '@/graphql/API';

import icons from '@/components/common/icons';

import AddToTableButton from '../AddToTableButton';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TPreSelectedConsumable = {
  className?: string;
  consumable: Reagent | Stain | Compound;
  onClick?: (consumable: Reagent | Stain | Compound, e: MouseEvent<HTMLButtonElement>) => void;
  onPlusClick?: MouseEventHandler<HTMLDivElement>;
};

const PreSelectedConsumable: FC<TPreSelectedConsumable> = ({ consumable, className, onClick, onPlusClick }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(consumable, e);
    },
    [consumable, onClick]
  );

  const handlePlusClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onPlusClick?.(e);
    },
    [onPlusClick]
  );
  return (
    <AddToTableButton className={cn('consumable__btn', 'preselected-consumable', className)} onClick={handleClick}>
      <div className={cn('consumable', 'consumable_preselected')}>
        <div className={cn('preselected-consumable__name')}>
          <span className={cn('consumable__name')}>{consumable.name}</span>
        </div>

        <div className={cn('preselected-consumable__plus-wrapper')}>
          <div
            role="presentation"
            className={cn('preselected-consumable__plus-btn')}
            aria-label="Add consumable"
            onClick={handlePlusClick}
          >
            <icons.PlusIcon />
          </div>
        </div>
      </div>
    </AddToTableButton>
  );
};

export default PreSelectedConsumable;
