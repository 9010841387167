import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { appAPI } from '@/store/services/app';
import { experimentSelectors } from '@/store/slices/experiment';

export function useSequencingDataFiles(laneId: string) {
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const { data: sequencingLaneData } = appAPI.useFetchSequencingDataByLanesQuery({
    experimentId: experimentData?.id ?? '',
    laneId,
  });

  return useMemo(() => {
    const cellReadsPath =
      sequencingLaneData?.sequencingData?.[0]?.files?.cellReads ??
      `${experimentData?.cloudPath ?? ''}/sequencing-data/${laneId}/CellReads.stats.tsv`;
    const summaryPath =
      sequencingLaneData?.sequencingData?.[0]?.files?.summary ??
      `${experimentData?.cloudPath ?? ''}/sequencing-data/${laneId}/Summary.csv`;
    return { cellReadsPath, summaryPath };
  }, [sequencingLaneData, experimentData, laneId]);
}
