import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { API as awsAPI } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';

import { EJobRunType } from '@/types/jobRuns';

import { isJobRunSucceeded, isJobRunInProgress, getJobRunCommandInfo } from '@/helpers';

import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';

import * as queries from '@/graphql/queries';
import * as subscriptions from '@/graphql/subscriptions';
import type {
  JobRunEdge,
  JobRunStatusChangedEventInput,
  JobRunStatusChangedSubscription,
  ListJobRunsInput,
} from '@/graphql/API';

const useFindSucceededJobRuns = (
  jobType: EJobRunType,
  experimentId: string,
  organizationId?: string,
  laneId?: string
) => {
  const [succeededLaneIdList, setSucceededLaneIdList] = useState<string[]>([]);
  const [laneInProgressIdList, setLaneInProgressIdList] = useState<string[]>([]);
  const jobSubscriptionListRef = useRef<Record<string, GraphQLSubscription<JobRunStatusChangedSubscription>>>({});

  const tags = useMemo<ListJobRunsInput['tags']>(() => {
    const tagList: ListJobRunsInput['tags'] = [
      { key: 'job_type', value: jobType },
      { key: 'experiment_id', value: experimentId },
    ];

    if (laneId) {
      tagList.push({ key: 'lane_id', value: laneId });
    }

    return tagList;
  }, [jobType, experimentId, laneId]);

  const { edgeList, refetch } = useGraphqlQueryFullList<ListJobRunsInput, JobRunEdge>({
    query: queries.jobRuns,
    dataName: 'jobRuns',
    input: {
      tags,
    },
  });

  const unsubscribe = useCallback((jobRunId: string) => {
    // @ts-ignore
    jobSubscriptionListRef.current[jobRunId]?.unsubscribe();
    delete jobSubscriptionListRef.current[jobRunId];
  }, []);

  const subscribe = useCallback(
    async (jobRunId: string, jobLaneId: string) => {
      const variables: Record<string, string> = { jobRunId };
      if (organizationId) {
        variables.organizationId = organizationId;
      }
      unsubscribe(jobRunId);
      jobSubscriptionListRef.current[jobRunId] = await awsAPI
        .graphql<GraphQLSubscription<JobRunStatusChangedSubscription>>({
          query: subscriptions.jobRunStatusChanged,
          variables,
        })
        // @ts-ignore
        .subscribe({
          next: ({
            provider: _,
            value,
          }: {
            provider: unknown;
            value: { data: { jobRunStatusChanged: JobRunStatusChangedEventInput } };
          }) => {
            if (value.data.jobRunStatusChanged) {
              const { status } = value.data.jobRunStatusChanged.payload;
              if (isJobRunSucceeded(status)) {
                setSucceededLaneIdList((prev) => [...prev, jobLaneId]);
              }
              if (!isJobRunInProgress(status)) {
                setLaneInProgressIdList((prev) => prev.filter((laneInProgressId) => laneInProgressId !== jobLaneId));
                unsubscribe(jobRunId);
              }
            }
          },
        });
    },
    [unsubscribe, organizationId]
  );

  useEffect(() => {
    if (!edgeList.length) {
      return;
    }

    edgeList.forEach((jobRun) => {
      let jobLaneId = laneId;
      if (!jobLaneId) {
        const commandInfo = getJobRunCommandInfo(jobRun);
        jobLaneId = commandInfo.laneId;
      }

      if (!jobLaneId) {
        return;
      }

      if (isJobRunSucceeded(jobRun.node.status)) {
        setSucceededLaneIdList((prev) => [...prev, jobLaneId]);
        return;
      }

      if (isJobRunInProgress(jobRun.node.status)) {
        setLaneInProgressIdList((prev) => [...prev, jobLaneId]);
        subscribe(jobRun.node.id, jobLaneId);
      }
    });
  }, [edgeList]);

  useEffect(
    () => () => {
      Object.keys(jobSubscriptionListRef.current).forEach((el) => unsubscribe(el));
    },
    []
  );

  return useMemo(
    () => ({
      laneIdList: succeededLaneIdList,
      hasSucceededJobRun: succeededLaneIdList.length > 0,
      hasJobRunInProgress: laneInProgressIdList.length > 0,
      restartSucceededJobRunsSearch: refetch,
    }),
    [succeededLaneIdList, laneInProgressIdList, refetch]
  );
};

export default useFindSucceededJobRuns;
