import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TInitialAWSState } from './types';

const initialState: TInitialAWSState = {
  isNeedCheckMFA: false,
  isSkippedMFA: false,
};

const awsSlice = createSlice({
  name: 'chartData',
  initialState,
  reducers: {
    setCheckMFA: (state, action: PayloadAction<boolean>) => {
      state.isNeedCheckMFA = action.payload;
    },
    setSkippedMFA: (state, action: PayloadAction<boolean>) => {
      state.isSkippedMFA = action.payload;
    },
  },
});

export default awsSlice;
