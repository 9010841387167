import { FC } from 'react';
import { MDASH } from '@/helpers';
import NoDataFound from '@/components/common/NoDataFound';

type TSettingValueArray = {
  value: TSettingOptionVariableValue;
};

const SettingValueArray: FC<TSettingValueArray> = ({ value }) => {
  if (!Array.isArray(value) || !value.length) {
    return <NoDataFound textData={MDASH} alignment="left" />;
  }

  return <span>{value.join(', ')}</span>;
};

export default SettingValueArray;
