import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';

export const getPlotAxesOptionList = (
  chartData: TDatasetDetails,
  isObjectEntityEnabled: boolean,
  cageLevelAxesOptionListByLanes: Record<string, TOption[]>
) => {
  if (isObjectEntityEnabled) {
    return getScatterPlotObjectLevelAxesOptions(chartData.channelList ?? []) as TBasicOption[];
  }
  return (cageLevelAxesOptionListByLanes[chartData.dataset.name] ?? []) as TBasicOption[];
};
