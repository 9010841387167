import { SVGProps } from 'react';

const BackArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17" fill="none" {...props}>
    <g stroke="currentColor" strokeWidth="2" strokeLinecap="round">
      <path d="M1 8.14358L8 1.08008" />
      <path d="M1 8.14365L8 15.0801" />
    </g>
  </svg>
);

export default BackArrowIcon;
