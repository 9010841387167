import { createElement, FC, SVGProps } from 'react';
import iconList from './iconList';

export type TIconNames = keyof typeof iconList;

type TIconCollection = {
  [key in TIconNames]: FC<SVGProps<SVGSVGElement>>;
};

const imageProcessor =
  (Component: FC<SVGProps<SVGSVGElement>>) =>
  // eslint-disable-next-line react/display-name
  ({ width, height, color, ...props }: SVGProps<SVGSVGElement>) => {
    const style: Record<string, string> = {};

    if (color) {
      style.color = color;
    }

    if (width) {
      style.width = `${width}px`;
    }

    if (height) {
      style.height = `${height}px`;
    }

    const iconProps = {
      ...props,
      style,
    };
    return createElement(Component, iconProps);
  };

/**
 * Add icons to @/components/icons/iconList and export it from index.ts file in same directory
 */
const icons = Object.entries(iconList).reduce((acc, [name, Component]) => {
  acc[name as TIconNames] = imageProcessor(Component);

  return acc;
}, {} as TIconCollection);

export default icons;
