import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import { channelDataList } from '@/helpers/channels';
import { CSSProperty } from '@/helpers/interfaces';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { datasetsActions } from '@/store/slices/datasets';

import styles from './DatasetsFilters.module.scss';
import CheckboxInput from '../CheckboxInput';

const cn = classnames.bind(styles);

type TDatasetsFiltersProps = {
  channelNameList: string[];
  timePointOptionList: { time: string; timeNode: JSX.Element; disabled: boolean }[];
  includedChannels: string[];
  includedTimePoints: string[];
  toggleTimePoint: (_: string) => void;
  className?: string;
};

const DatasetsFilters: FC<TDatasetsFiltersProps> = ({
  channelNameList,
  timePointOptionList,
  includedChannels,
  includedTimePoints,
  toggleTimePoint,
  className,
}) => {
  const appDispatch = useAppDispatch();

  const channelNameListObj = useMemo(
    () =>
      channelNameList.reduce((acc, el) => {
        acc[el] = true;
        return acc;
      }, {} as Record<string, boolean>),
    [channelNameList]
  );

  const handleCheckBoxClick = (channelName: string) => {
    appDispatch(datasetsActions.toggleIncludedChannel(channelName));
  };

  return (
    <div className={cn('datasets-filters', 'datasets-filters__panel', 'datasets-filters__panel_detailed', className)}>
      <span className={cn('datasets-filters__title')}>Filter by:</span>
      <div className={cn('datasets-filters__groups-wrapper')}>
        <div className={cn('filter-group')}>
          <span className={cn('filter-group__title')}>Time points:</span>
          <div className={cn('filter-group__content', 'filter-group__time-points')}>
            {timePointOptionList.map(({ time, timeNode, disabled }) => (
              <span key={time}>
                {disabled ? (
                  <span className={cn('filter-group__time-point', 'filter-group__time-point_disabled')}>
                    {timeNode}
                  </span>
                ) : (
                  <CheckboxInput
                    checked={includedTimePoints.includes(time)}
                    onChange={() => toggleTimePoint(time)}
                    theme="light"
                    label={timeNode}
                    className={cn('filter-group__time-point')}
                  />
                )}
              </span>
            ))}
          </div>
        </div>
        <div className={cn('filter-group')}>
          <span className={cn('filter-group__title')}>Color channels:</span>
          <div className={cn('filter-group__content')}>
            {channelDataList.map((channelData) => {
              const disabled = !Object.keys(channelNameListObj).some((key) => key.includes(channelData.name));
              return (
                <span key={channelData.name}>
                  {disabled ? (
                    <span
                      style={
                        {
                          '--bg-color': channelData.uiColor,
                        } as CSSProperty
                      }
                      className={cn('channel-disabled')}
                    >
                      {channelData.name}
                    </span>
                  ) : (
                    <CheckboxInput
                      checked={!!includedChannels.find((channelName) => channelName.includes(channelData.name))}
                      onChange={() => handleCheckBoxClick(channelData.name)}
                      theme="light"
                      label={channelData.name}
                      style={{
                        '--bg-color': channelData.uiColor,
                      }}
                      className={cn('channel-check')}
                    />
                  )}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatasetsFilters;
