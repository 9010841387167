import { SVGProps } from 'react';

const PolygonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m10 .986 8.482 6.104a.25.25 0 0 1 .09.28l-3.183 9.797a.25.25 0 0 1-.238.173H4.85a.25.25 0 0 1-.238-.173L1.427 7.37a.25.25 0 0 1 .091-.28l8.335-6.055A.25.25 0 0 1 10 .986z"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default PolygonIcon;
