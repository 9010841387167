import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import TextInput from '@/components/common/TextInput';
import icons from '@/components/common/icons';

import styles from './Name.module.scss';

const cn = classnames.bind(styles);

type TName = {
  className?: string;
  laneLetter: string;
  isEditAllowed?: boolean;
  isActivationAllowed?: boolean;
  isLocked?: boolean;
};

const Name: FC<TName> = ({ laneLetter, className, isEditAllowed, isLocked, isActivationAllowed }) => {
  const appDispatch = useAppDispatch();

  const lane = useSelector(experimentRunDesignSelectors.selectCurrentLaneById(laneLetter));
  const isNotClickable = useMemo(
    () => isLocked || !isEditAllowed || (!lane && !isActivationAllowed),
    [isLocked, isEditAllowed, lane, isActivationAllowed]
  );

  const handleChangeLaneName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isEditAllowed || !lane) {
        return;
      }
      appDispatch(
        experimentRunDesignActions.updateLane({ laneId: lane?.id, updatedData: { sample: event.target.value } })
      );
    },
    [lane]
  );

  const handleActive = useCallback(() => {
    if (lane || !isActivationAllowed) {
      return;
    }

    appDispatch(experimentRunDesignActions.addNewLane(laneLetter));
  }, [lane, laneLetter, isActivationAllowed]);

  const placeHolderString = useMemo<string>(() => {
    if (isLocked || !isEditAllowed || (!lane && !isActivationAllowed)) {
      return '';
    }

    return 'Enter sample name...';
  }, [isLocked, isEditAllowed, lane, isActivationAllowed]);

  return (
    <TextInput
      type="text"
      value={lane?.sample ?? ''}
      onChange={handleChangeLaneName}
      onFocus={handleActive}
      disabled={isNotClickable}
      className={cn(className)}
      placeholder={placeHolderString}
      inputClassName={cn('input', { input_disabled: !lane, input_locked: !lane && !isActivationAllowed })}
    >
      {isLocked && (
        <div className={cn('locked-icon')}>
          <icons.LockIcon />
        </div>
      )}
    </TextInput>
  );
};

export default Name;
