import { TAppEndpointBuilder } from '@/store/services/app/types';
import { transformGenomeOptionListResponse } from '@/store/services/app/dataProvider/annotations';

export const annotationsEndpoints = (build: TAppEndpointBuilder) => ({
  fetchGenomeOptionList: build.query<TBasicOption[], void>({
    query: () => ({
      url: '/annotations/genome',
      method: 'GET',
    }),
    transformResponse: transformGenomeOptionListResponse,
  }),
});
