import { EChartType } from '@/types/charts';
import { channelDataList, isEqualAxes } from '@/helpers/channels';
import { isCompoundOption } from '@/components/common/Select/helpers';
import type { TBaseChartSettings, TInitialChartSettingsState } from './types';

export const defineAllOptionsData = (datasetOptionObj: Record<string, TOption[]>) => {
  const generalChannelOptionMap: Record<string, Record<string, string>> = {};

  const allOptionList: TOption[] = [];
  const allOptionMapping: Record<string, Record<string, string>> = {};
  Object.entries(datasetOptionObj).forEach(([datasetName, axesList]) => {
    const axes = Object.keys(allOptionMapping);
    axesList.forEach((datasetOption) => {
      if (isCompoundOption(datasetOption)) {
        return;
      }
      const axisForUpdate = axes.find((axis) => isEqualAxes(String(datasetOption.value), axis));
      const channelData = channelDataList.find((channel) =>
        isEqualAxes(String(datasetOption.value), `${channel.name}_mean`)
      );
      if (channelData) {
        if (!generalChannelOptionMap[channelData.name]) {
          generalChannelOptionMap[channelData.name] = {};
        }

        generalChannelOptionMap[channelData.name][datasetName] = String(datasetOption.value);
      }
      if (axisForUpdate) {
        allOptionMapping[axisForUpdate][datasetName] = String(datasetOption.value);
        return;
      }

      allOptionMapping[String(datasetOption.value)] = {
        [datasetName]: String(datasetOption.value),
      };

      allOptionList.push({
        label: datasetOption.label,
        value: datasetOption.value,
      });
    });
  }, {} as Record<string, Record<string, TOption & { datasetName: string }>>);

  return { generalChannelOptionMap, allOptionList, allOptionMapping };
};

// Log scale does not work properly for histograms. Issue: https://github.com/plotly/plotly.js/issues/1844
export const isLogScaleTypeAllowedForYAxis = (currentChartType: EChartType) =>
  [EChartType.dot, EChartType.dotDensity].includes(currentChartType);

export const isLogScaleTypeAllowedForXAxis = (currentChartType: EChartType) =>
  [EChartType.dot, EChartType.dotDensity, EChartType.lineHistogram].includes(currentChartType);

export function getStateObject(state: TInitialChartSettingsState) {
  return state.specificDatasetOptionMap?.[state.fullScreenChartData?.id ?? ''] ?? state;
}

export function getStateValue<T extends TInitialChartSettingsState, K extends keyof TBaseChartSettings>(
  state: T,
  key: K,
  fullScreenChartDataId?: Nullable<string>
): TBaseChartSettings[K] {
  const currentState = fullScreenChartDataId ? state.specificDatasetOptionMap?.[fullScreenChartDataId] : state;
  return currentState?.[key] ?? state?.[key];
}
