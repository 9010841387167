import InputWithSuggestions from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/InputWithSuggestions';
import { FC, useMemo } from 'react';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import { isDefined } from '@/helpers/typeGuards';

type TFilterInputWithSuggestionFromDataProps = {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  data?: TReturnTransformedTypes[];
  field: string;
};

const FilterInputWithSuggestionFromData: FC<TFilterInputWithSuggestionFromDataProps> = ({
  value,
  placeholder,
  onChange,
  data,
  disabled,
  field,
}) => {
  const uniqueValues = useMemo(() => {
    const fieldUnUniqueValues =
      data?.map((annotation) => (field in annotation ? annotation[field as keyof TReturnTransformedTypes] : null)) ??
      [];
    const fieldUniqueValues = [...new Set(fieldUnUniqueValues)];
    const meanFullValues = fieldUniqueValues.filter((str): str is string => isDefined(str) && str !== '');
    return value ? meanFullValues.filter((str) => str.toLowerCase().includes(value.toLowerCase())) : [];
  }, [data, field, value]);

  return (
    <InputWithSuggestions
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onSubmit={onChange}
      disabled={disabled}
      suggestionData={uniqueValues}
    />
  );
};

export default FilterInputWithSuggestionFromData;
