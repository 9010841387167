import { FC, MouseEvent, memo, useCallback, useState } from 'react';
import { Popover as TinyPopover } from 'react-tiny-popover';

import icons from '@/components/common/icons';
import ChartModebarButton from '@/components/charts/ChartModebarButton';

import PopoverContent from './PopoverContent';

type ViolinPopover = {
  disabled?: boolean;
};

const ViolinPopover: FC<ViolinPopover> = ({ disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <TinyPopover
      isOpen={isOpen}
      content={<PopoverContent togglePopover={togglePopover} />}
      positions={['right', 'top', 'left', 'bottom']}
    >
      <div>
        <ChartModebarButton IconComponent={icons.DatasetIcon} onClick={togglePopover} disabled={disabled} />
      </div>
    </TinyPopover>
  );
};

export default memo(ViolinPopover);
