import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { themeOptions } from '@/types/theme';

import { appAPI } from '@/store/services/app';

import Select, { TSelectProps } from '@/components/common/Select';

type TProjectSelectProps = TSelectProps & {
  selectedProjectId: Nullable<string>;
  setSelectedProjectId: (value: string) => void;
};

const ProjectSelect: FC<TProjectSelectProps> = ({ selectedProjectId, setSelectedProjectId, ...selectProps }) => {
  const { data: projectList } = appAPI.useFetchProjectListQuery({});

  const [projectName, setProjectName] = useState('');

  const projectOptionList = useMemo<TOption[]>(
    () =>
      projectList?.map((project) => ({
        label: `${project.name} (last modified - ${project.dateModified})`,
        value: project.id,
      })) ?? [],
    [projectList]
  );

  const handleSelectProject = useCallback(
    (option: TBasicOption) => {
      setSelectedProjectId(projectList?.find((project: TProject) => project.id === option.value)?.id ?? '');
    },
    [projectList]
  );

  useEffect(() => {
    const selectedProject = projectList?.find((project) => project.id === selectedProjectId);
    if (selectedProject) {
      setProjectName(`${selectedProject.name} (last modified - ${selectedProject.dateModified})`);
    } else {
      setProjectName('');
    }
  }, [projectList, selectedProjectId]);

  return (
    <Select
      value={selectedProjectId}
      options={projectOptionList}
      inputValue={projectName}
      theme={themeOptions.light}
      onChange={handleSelectProject}
      isEditable
      isClearable
      setInputValue={setProjectName}
      {...selectProps}
    />
  );
};

export default memo(ProjectSelect);
