import { intervalToDuration } from 'date-fns';

import { laneSchema } from '@/validationSchemas/scans';

import { clamp, formatScanDuration, removeTrailingSlash, sortData } from '@/helpers';
import { forceWhiteChannelFirst, getChannelFieldNameList } from '@/helpers/channels';

import { prepareDataset } from '@/store/slices/experiment/dataProvider';

import { SCAN_NUMBER_REGEX, getScanNumberByName } from '@/helpers/scans';

import { showDatasetFilesErrors } from './helpers';

const getNewExperimentPyramidInfo = (pyramidInfo: TPyramidInfo) => ({
  totalImgWidth: pyramidInfo.total_img_width,
  totalImgHeight: pyramidInfo.total_img_height,
  tileSize: pyramidInfo.tile_size,
  tileFileFormat: pyramidInfo.tile_file_format,
  minLevel: pyramidInfo.min_level || 1,
  maxLevel: pyramidInfo.max_level,
});

const transformLanePyramid = (laneDataFromServer: TLaneFromServer, channelsCount: number) => {
  let isNewExperiment =
    laneDataFromServer && 'pyramid' in laneDataFromServer && 'original_fov_width' in laneDataFromServer.pyramid;
  if (isNewExperiment) {
    return getNewExperimentPyramidInfo(laneDataFromServer.pyramid);
  }
  const { pyramid } = laneDataFromServer;
  const scans = pyramid ? Object.values(pyramid) : [];
  const lanes = scans[0] ? Object.values((scans[0] as unknown as { lanes: Array<TLaneFromServer> }).lanes) : [];
  const lane = lanes[0];
  isNewExperiment = lane && 'channels' in lane;
  if (isNewExperiment) {
    return getNewExperimentPyramidInfo(lane.pyramid_info);
  }
  let snapshotsCount = 41;
  if (lane && lane.num_images && channelsCount > 0) {
    snapshotsCount = lane.num_images / channelsCount;
  } else {
    console.warn('The following lane has unknown format and cannot be shown in navigator', laneDataFromServer);
  }
  return {
    totalImgWidth: 1991.5 * snapshotsCount,
    totalImgHeight: 1600,
    tileSize: 400,
    tileFileFormat: 'png',
    minLevel: 1,
    maxLevel: 5,
  };
};

const transformLaneClasses = (laneClasses: TClass[]) =>
  laneClasses.map((laneClass) => ({
    ...laneClass,
    marker: laneClass?.marker?.trim() ? laneClass.marker : laneClass.name,
  }));

const generateLaneLetterName = (sampleName: string, laneIndex: number): string => {
  const found = /^.*(\d+)/.exec(sampleName);
  const index = clamp(1, found?.[1] ? +found[1] : laneIndex + 1, 8) - 1;
  const letterNameMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  return letterNameMap[index];
};

const transformDatasetFiles = (lane: TLaneFromServer) => {
  const datasetFilesFromServer = lane.dataset.datasetFiles;

  const datasetFiles = { ...datasetFilesFromServer };
  if (!datasetFiles.primaryAnalysis && datasetFiles.summaryTable) {
    datasetFiles.primaryAnalysis = datasetFiles.summaryTable;
  }

  return datasetFiles;
};

const transformLane = (lane: TLaneFromServer, laneIndex: number, scan: TScanFromServer) => {
  const laneCheckResult = laneSchema.safeParse(lane);
  if (!laneCheckResult.success) {
    console.error(laneCheckResult.error);
  }

  const channels = Array.isArray(lane.channels) ? [...lane.channels] : [];
  forceWhiteChannelFirst(channels);
  return {
    letterName: generateLaneLetterName(lane.sampleName, laneIndex),
    ...lane,
    ...transformLanePyramid(lane, channels.length),
    classes: transformLaneClasses(lane?.classes ?? []),
    sampleFriendlyName: lane.sampleFriendlyName ?? lane.sampleName,
    channels,
    cageCropsPath: removeTrailingSlash(lane.cageCropsPath),
    cageCropsThumbnailsPath: removeTrailingSlash(lane.dataset.datasetFolders?.cageCropsThumbnails),
    dataset: prepareDataset(lane.dataset, scan.id, lane.id),
    datasetFiles: transformDatasetFiles(lane),
    path: removeTrailingSlash(lane.dataset.path),
    pyramidPath: removeTrailingSlash(lane.pyramidPath),
    channelsFieldsNames: getChannelFieldNameList(channels),
  };
};

const transformScanDuration = (
  scanFromServer: TScanFromServer,
  scanIndex: number,
  durationFromFirstScan: Nullable<Duration>
): string => {
  if (durationFromFirstScan) {
    return formatScanDuration(durationFromFirstScan);
  }
  const found = SCAN_NUMBER_REGEX.exec(scanFromServer.name);
  const hours = found ? +found[0] : scanIndex + 1;
  return formatScanDuration({ days: 0, hours, minutes: 0 });
};

const transformScan = (
  scanFromServer: TScanFromServer,
  scanIndex: number,
  durationFromFirstScan: Nullable<Duration>
) => {
  const time = transformScanDuration(scanFromServer, scanIndex, durationFromFirstScan);

  const lanesFromServer = Array.isArray(scanFromServer.lanes) ? scanFromServer.lanes : [];
  lanesFromServer.sort((current, next) => sortData(current.sampleName, next.sampleName));
  const lanes = lanesFromServer.map((lane: TLaneFromServer, laneIndex: number) =>
    transformLane(lane, laneIndex, scanFromServer)
  );

  return {
    ...scanFromServer,
    time: `${time} (${scanFromServer.name})`,
    timeNode: (
      <span>
        {time} <small>({scanFromServer.name})</small>
      </span>
    ),
    lanes,
  };
};

export const transformScansResponse = (scansFromServer: TScanFromServer[]): TScan[] => {
  if (scansFromServer.length === 0) {
    return [];
  }

  showDatasetFilesErrors(scansFromServer);

  const firstScanFromServer = scansFromServer[0];
  const lastScanFromServer = scansFromServer[scansFromServer.length - 1];

  const durationBetweenScansInMinutes =
    intervalToDuration({
      start: new Date(firstScanFromServer.scanDate),
      end: new Date(lastScanFromServer.scanDate),
    })?.minutes ?? 0;

  const isPipelineV5 = durationBetweenScansInMinutes > 0;

  scansFromServer.sort((current, next) => sortData(getScanNumberByName(current.name), getScanNumberByName(next.name)));

  const firstScanDate = scansFromServer[0]?.scanDate ?? '';
  return scansFromServer.map((scanFromServer: TScanFromServer, scanIndex: number) => {
    const durationFromFirstScan = isPipelineV5
      ? intervalToDuration({
          start: new Date(firstScanDate),
          end: new Date(scanFromServer.scanDate),
        })
      : null;
    return transformScan(scanFromServer, scanIndex, durationFromFirstScan);
  });
};

export const parseSequencingDataReads = (readsFromServer: [string, string][]): { read1: string; read2: string }[] => {
  let parsedReads: { read1: string; read2: string }[] = [];

  const isReadsSendAsArray = readsFromServer.every((readsItem) => Array.isArray(readsItem) && readsItem.length === 2);

  // Temporary solution. The 'reads' is expected to be as follows: { read1: string, read2: string }[] = []
  if (isReadsSendAsArray) {
    parsedReads = readsFromServer.map(([read1, read2]) => ({
      read1: read1?.substring(read1.lastIndexOf('/') + 1),
      read2: read2?.substring(read2.lastIndexOf('/') + 1),
    }));
  }

  return [...parsedReads];
};

export const transformSequencingDataResponse = (
  sequencingDataFromServer: TSequencingDataFromServer
): TParsedSequencingData =>
  sequencingDataFromServer && {
    ...sequencingDataFromServer,
    sequencingData: sequencingDataFromServer.sequencingData.map((data) => ({
      ...data,
      mRNA: {
        ...data.mRNA,
        reads: parseSequencingDataReads(data.mRNA.reads),
      },
      guideSeq: {
        ...data?.guideSeq,
        reads: parseSequencingDataReads(data?.guideSeq?.reads ?? []),
      },
    })),
  };
