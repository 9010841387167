import { scaleLinear } from 'd3-scale';
import { select as d3Select, selectAll as d3SelectAll } from 'd3-selection';

import { getGateShape, isGateDimensionsMatch, isPointInGate } from '@/helpers/gates';
import { isCircleTypeGate, isPolygonTypeGate, isPolarTypeGate, isRangeTypeGate } from '@/helpers/typeGuards';
import { isNumber } from '@/helpers';
import { EGateLabelType } from '@/types/gateSettings';

import {
  HANDLERS_SIZE,
  HANDLERS_OFFSET,
  TITLE_OFFSET_BY_TYPE,
  TITLE_HEIGHT_BY_TYPE,
  TITLE_GAP_BY_TYPE,
  TITLE_SIZE_BY_TYPE,
  LABEL_MENU_R,
} from '../constants';
import { TSelectionGroupElement } from '../types';

type TGetXPositionOnSVGPayload = {
  point: number;
  containerSize: number;
  chartMinValue: number;
  chartMaxValue: number;
};

type TDrawControlElementPayload = {
  parentElement: TSelectionGroupElement;
  x: number;
  y: number;
  classes?: string;
  attributes?: Record<'attr' | 'value', string>[];
};

type TDrawLabelPayload = {
  gateContainer: TSelectionGroupElement;
  bgX: number;
  bgY: number;
  textX: number;
  textY: number;
  gateIdAttr: string;
  label: string;
  displayType: EGateLabelType;
  aligment?: 'start' | 'end';
  isGateMenuHidden: boolean;
};

type TDefineIstGateDrawnOnScatterPlot = {
  selectedGate: Nullable<TGate>;
  gate: TGate;
  xAxis: string;
  yAxis: string;
  isGatesDrawn: boolean;
};

type TGetPointsFromChartScale = {
  polygons: TGatePolygons;
  shapesContainerParameters: DOMRect;
  range: TPopulationRange;
  hardcodedValue?: Nullable<number>;
};

const MENU_MARKER_LEFT_OFFSET = 5;
const MENU_MARKER_GAP = 2;
const MENU_MARKER_R = 1.5;
const MENU_MARKER_D = MENU_MARKER_R * 2;
const MENU_BG_RADIUS = 15;
const MENU_GAP = 10;

// get coordinates relative to SVG shapes layout
export const getGateXPositionOnSVG = ({
  point,
  containerSize,
  chartMinValue,
  chartMaxValue,
}: TGetXPositionOnSVGPayload) => {
  const x = scaleLinear([chartMinValue, chartMaxValue], [0, containerSize]);
  const xOnSVG = x(point);

  return xOnSVG;
};

export const getGateYPositionOnSVG = ({
  point,
  containerSize,
  chartMinValue,
  chartMaxValue,
}: TGetXPositionOnSVGPayload) => {
  const y = scaleLinear([chartMinValue, chartMaxValue], [0, containerSize]);
  const yOnSvg = (y(point) - containerSize) * -1;

  return yOnSvg;
};

// determining the coordinate of a point along the X-axis of the graph, including the difference in scale and indentation
export const getXPointOnChart = ({ point, containerSize, chartMinValue, chartMaxValue }: TGetXPositionOnSVGPayload) => {
  const x = scaleLinear([0, containerSize], [chartMinValue, chartMaxValue]);
  return x(point);
};

// determining the coordinate of a point along the Y-axis of the graph, including the difference in scale and indentation
export const getYPointOnChart = ({ point, containerSize, chartMinValue, chartMaxValue }: TGetXPositionOnSVGPayload) => {
  const y = scaleLinear([0, containerSize], [chartMinValue, chartMaxValue]);
  return y(containerSize - point);
};

export const drawControlElement = ({
  parentElement,
  x,
  y,
  classes = 'gate-element__control',
  attributes = [],
}: TDrawControlElementPayload) => {
  const element = parentElement
    .append('rect')
    .attr('class', classes)
    .attr('width', HANDLERS_SIZE)
    .attr('height', HANDLERS_SIZE)
    .attr('x', x - HANDLERS_OFFSET)
    .attr('y', y - HANDLERS_OFFSET);

  attributes.forEach((item) => {
    element.attr(`${item.attr}`, `${item.value}`);
  });

  return element;
};

export const getPointsFromChartScale = ({
  polygons,
  shapesContainerParameters,
  range,
  hardcodedValue = null,
}: TGetPointsFromChartScale) => {
  const xPayload = {
    containerSize: shapesContainerParameters.width,
    chartMinValue: range?.xMin,
    chartMaxValue: range?.xMax,
  };

  const yPayload = {
    containerSize: shapesContainerParameters.height,
    chartMinValue: range?.yMin,
    chartMaxValue: range?.yMax,
  };

  const pointsOnChart = polygons.map(({ x, y }) => {
    const isYShouldNotBeParsed = hardcodedValue && hardcodedValue === y;

    return {
      x: getXPointOnChart({ ...xPayload, point: x }),
      y: isYShouldNotBeParsed ? y : getYPointOnChart({ ...yPayload, point: y }),
    };
  });

  return pointsOnChart;
};

const drawMenuGroup = ({
  gateContainer,
  menuCX,
  menuCY,
  gateId,
}: {
  gateContainer: TSelectionGroupElement;
  menuCX: number;
  menuCY: number;
  gateId: string;
}) => {
  const menuGroup = gateContainer.append('g').attr('class', `gate-menu`).attr('gate-menu-id', `gate_${gateId}`);

  menuGroup
    .append('ellipse')
    .attr('cx', menuCX + LABEL_MENU_R)
    .attr('cy', menuCY)
    .attr('rx', MENU_BG_RADIUS)
    .attr('ry', MENU_BG_RADIUS)
    .attr('class', `gate-menu__bg`);

  for (let i = 1; i <= 3; i++) {
    const gapForCurrentMarker = menuCX + MENU_MARKER_LEFT_OFFSET + (MENU_MARKER_GAP + MENU_MARKER_D) * i;

    menuGroup
      .append('ellipse')
      .attr('cx', gapForCurrentMarker)
      .attr('cy', menuCY)
      .attr('rx', MENU_MARKER_R)
      .attr('ry', MENU_MARKER_R)
      .attr('class', `gate-menu__marker`);
  }
};

export const updateMenuPosition = ({
  gateId,
  newOffset,
  newPosition,
  plotId,
}: {
  gateId: string;
  newOffset?: [number, number];
  newPosition?: [number, number];
  plotId: string;
}) => {
  const menuGroup = d3Select(`#${plotId}`).select(`[gate-menu-id = 'gate_${gateId}'`);

  const menuBg = menuGroup.select('.gate-menu__bg');

  let newBgCx = null;
  let newBgCy = null;

  if (newOffset) {
    newBgCx = parseFloat(menuBg.attr('cx')) + newOffset[0];
    newBgCy = parseFloat(menuBg.attr('cy')) + newOffset[1];
  } else if (newPosition) {
    const [cx, cy] = newPosition;
    newBgCx = cx;
    newBgCy = cy;
  }

  menuBg.attr('cx', newBgCx);
  menuBg.attr('cy', newBgCy);

  const menuMarkers = menuGroup.selectAll('.gate-menu__marker').nodes();

  menuMarkers.forEach((marker, i) => {
    if (!marker) return;

    const ellipseSelection = d3Select(marker);
    let newCx = null;
    let newCy = null;

    if (newOffset) {
      newCx = parseFloat(ellipseSelection.attr('cx')) + newOffset[0];
      newCy = parseFloat(ellipseSelection.attr('cy')) + newOffset[1];
    } else if (newPosition) {
      const [cx, cy] = newPosition;
      const gapForCurrentMarker = MENU_MARKER_LEFT_OFFSET + (MENU_MARKER_GAP + MENU_MARKER_D) * (i + 1);
      newCx = cx + gapForCurrentMarker - MENU_BG_RADIUS;
      newCy = cy;
    }

    if (!isNumber(newCx) || !isNumber(newCy)) return;

    ellipseSelection.attr('cx', newCx);
    ellipseSelection.attr('cy', newCy);
  });
};

export const drawGateLabel = ({
  gateContainer,
  bgX,
  bgY,
  textX,
  textY,
  gateIdAttr,
  label,
  displayType,
  aligment = 'start',
  isGateMenuHidden,
}: TDrawLabelPayload) => {
  const gateId = gateIdAttr.split('gate_')[1];
  const classes = label ? 'gate-label' : 'gate-label gate-label_hidden';

  const labelBgX = bgX - TITLE_OFFSET_BY_TYPE[displayType];
  const labelBgY =
    bgY - TITLE_HEIGHT_BY_TYPE[displayType] - TITLE_OFFSET_BY_TYPE[displayType] - TITLE_GAP_BY_TYPE[displayType];
  const labelTextX = textX;
  const labelTextY = textY - TITLE_HEIGHT_BY_TYPE[displayType] / 2 - TITLE_GAP_BY_TYPE[displayType];

  const labelContainer = gateContainer.append('g').attr('class', classes).attr('label-gate-id', `gate-label_${gateId}`);

  const labelBg = labelContainer
    .append('rect')
    .attr('class', `gate-label__label-bg`)
    .attr('x', labelBgX)
    .attr('y', labelBgY)
    .attr('rx', 15)
    .attr('label-gate-id', `label-bg_${gateId}`);

  const labelText = labelContainer
    .append('text')
    .text(label)
    .attr('class', `gate-label__text`)
    .attr('x', labelTextX)
    .attr('y', labelTextY)
    .attr('font-size', TITLE_SIZE_BY_TYPE[displayType])
    .attr('text-anchor', aligment)
    .attr('label-gate-id', `label-text_${gateId}`);

  const labelParameter = labelText?.node()?.getBoundingClientRect();
  const bgTransformXByTitleAnchor = {
    start: 'unset',
    end: `translateX(-${labelParameter?.width ?? 0}px)`,
  };

  const bgWidth = (labelParameter?.width ?? 0) + TITLE_OFFSET_BY_TYPE[displayType] * 2;
  const bgHeight = TITLE_HEIGHT_BY_TYPE[displayType] + TITLE_OFFSET_BY_TYPE[displayType];
  labelBg.attr('width', bgWidth).attr('height', bgHeight).style('transform', bgTransformXByTitleAnchor[aligment]);

  if (!label || isGateMenuHidden) return;

  let menuCX = labelBgX + bgWidth;
  let menuCY = labelBgY + bgHeight / 2;

  if (aligment === 'end') {
    menuCX = labelBgX - bgWidth - MENU_GAP;
    menuCY = labelBgY + bgHeight / 2;
  }

  drawMenuGroup({
    gateContainer,
    menuCX,
    menuCY,
    gateId,
  });
};

export const defineGatePointsByType = (
  gate: TGate,
  scanId: string,
  laneId: string,
  parentGate?: Nullable<TGate>
): TGatePolygons => {
  const shape = getGateShape({ gate, parentGate, scanId, laneId });
  const { type } = shape;

  const defineGatePointsMethodMap: Record<TGateType, () => TGatePolygons> = {
    circle: () => {
      if (isCircleTypeGate(shape)) {
        const { model } = shape;
        return [{ x: model.x, y: model.y }];
      }
      return [];
    },
    polygon: () => {
      if (isPolygonTypeGate(shape)) {
        const { model } = shape;
        return model.points;
      }

      return [];
    },
    polar: () => {
      if (isPolarTypeGate(shape)) {
        const { model } = shape;
        return [{ x: model.x, y: model.y }];
      }
      return [];
    },
    'polar-sector': () => [],
    range: () => {
      if (isRangeTypeGate(shape)) {
        const { model } = shape;
        const defaultPoints = [
          { x: model.x1, y: 0 },
          { x: model.x1, y: Infinity },
          { x: model.x2, y: Infinity },
          { x: model.x2, y: 0 },
        ];

        if (isNumber(model?.y)) {
          const middleLinePolygons = [
            { x: model.x1, y: model.y },
            { x: model.x2, y: model.y },
          ];

          return [...defaultPoints, ...middleLinePolygons];
        }

        return defaultPoints;
      }
      return [];
    },
  };

  return defineGatePointsMethodMap[type]();
};

export const updateLabelPosition = (
  gateId: string,
  [dx, dy]: [number, number],
  isGateMenuHidden: boolean,
  plotId: string
) => {
  if (dx === 0 && dy === 0) return;

  const labelBg = d3Select(`#${plotId}`)
    .select('.gates-container:not(.gates-container_disabled)')
    .select(`[label-gate-id = 'label-bg_${gateId}'`);
  const labelText = d3Select(`#${plotId}`)
    .select('.gates-container:not(.gates-container_disabled)')
    .select(`[label-gate-id = 'label-text_${gateId}'`);

  if (!labelBg.node() || !labelText.node()) return;

  const labelBgX = parseFloat(labelBg.attr('x')) + dx;
  const labelBgY = parseFloat(labelBg.attr('y')) + dy;
  const labelTextX = parseFloat(labelText.attr('x')) + dx;
  const labelTextY = parseFloat(labelText.attr('y')) + dy;

  labelBg.attr('x', labelBgX).attr('y', labelBgY);
  labelText.attr('x', labelTextX).attr('y', labelTextY);

  if (isGateMenuHidden) return;

  updateMenuPosition({ gateId, newOffset: [dx, dy], plotId });
};

export const clearHighlightedGate = () => {
  d3SelectAll('.gate-group_active').attr('class', 'gate-group');
};

export const highlightActiveGate = (gateId: string, clearPrevActiveGate = true) => {
  const gateGroupId = `group_${gateId}`;

  if (clearPrevActiveGate) {
    clearHighlightedGate();
  }

  const gateGroups = d3SelectAll(`[gate-group-id = '${gateGroupId}']`);

  gateGroups.attr('class', 'gate-group gate-group_active');
};

export const changeGateColor = (gateId: string, color: string) => {
  if (!color) {
    return;
  }

  const gateElementId = `gate_${gateId}`;

  const gateFrame = d3SelectAll(`[gate-id = '${gateElementId}']`);
  const gateSquareGroup = d3SelectAll(`[gate-control-id = 'gate-control_${gateId}']`);

  gateFrame.style('stroke', color);
  gateSquareGroup.style('fill', color);
};

export const hideGate = (gateId: string, isVisible?: boolean) => {
  if (typeof isVisible === 'undefined') return;

  const gateGroupId = `group_${gateId}`;
  const gateGroup = d3SelectAll(`[gate-group-id = '${gateGroupId}']`);

  gateGroup.style('display', isVisible ? 'initial' : 'none');
  gateGroup.attr('gate-hidden', isVisible ? 'false' : 'true');
};

export const defineIstGateDrawnOnScatterPlot = ({
  selectedGate,
  gate,
  xAxis,
  yAxis,
  isGatesDrawn,
}: TDefineIstGateDrawnOnScatterPlot) => {
  if (selectedGate) {
    return selectedGate?.id === gate.parentId && isGateDimensionsMatch(gate, xAxis, yAxis);
  }
  return isGatesDrawn && !gate.parentId && isGateDimensionsMatch(gate, xAxis, yAxis);
};

export const detectClickedGate = (payload: {
  points: TGatePolygon;
  drawnGateInfo: Omit<TDefineIstGateDrawnOnScatterPlot, 'gate'>;
  gateList: TGate[];
  scanId: string;
  laneId: string;
}): Nullable<TGate> => {
  const { points, drawnGateInfo, gateList, scanId, laneId } = payload;

  for (let i = 0; i < gateList.length; i++) {
    const gate = gateList[i];
    const isGateDrawn = defineIstGateDrawnOnScatterPlot({ ...drawnGateInfo, gate });
    const isGateWasClicked = isPointInGate({
      x: points.x,
      y: points.y,
      gate,
      scanId,
      laneId,
    });

    if (isGateDrawn && isGateWasClicked) {
      return gate;
    }

    if (gate?.gateNodes) {
      const clickedGate = detectClickedGate({ ...payload, gateList: gate?.gateNodes, scanId, laneId });
      if (clickedGate) {
        return clickedGate;
      }
    }
  }

  return null;
};

export const setOnContextMenuHandlers = (handler: (gateId: string, event: MouseEvent) => void, plotId: string) => {
  const gateElements = d3Select(`#${plotId}`).selectAll('.gate-element').nodes();
  const gateLabels = d3Select(`#${plotId}`).selectAll('.gate-label').nodes();
  const controlElements = d3Select(`#${plotId}`).selectAll('.gate-element__control').nodes();

  gateElements.forEach((gate) => {
    const gateId = d3Select(gate).attr('gate-id').split('gate_')[1];

    d3Select(gate).on('contextmenu', (event: MouseEvent) => {
      handler(gateId, event);
    });
  });

  controlElements.forEach((control) => {
    const controlGateIdAttr = d3Select(control).attr('gate-control-id');
    const gateId = controlGateIdAttr.split('gate-control_')[1];
    d3Select(control).on('contextmenu', (event: MouseEvent) => {
      handler(gateId, event);
    });
  });

  gateLabels.forEach((gate) => {
    const gateId = d3Select(gate).attr('label-gate-id').split('gate-label_')[1];

    d3Select(gate).on('contextmenu', (event: MouseEvent) => {
      handler(gateId, event);
    });
  });

  const gateMenuElements = d3Select(`#${plotId}`).selectAll('.gate-menu').nodes();

  gateMenuElements.forEach((gate) => {
    const gateId = d3Select(gate).attr('gate-menu-id').split('gate_')[1];

    d3Select(gate).on('click', (event: MouseEvent) => {
      handler(gateId, event);
    });
  });
};
