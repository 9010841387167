import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { experimentSelectors } from '@/store/slices/experiment';

import Panel from '@/components/common/Panel';
import NoDataFound from '@/components/common/NoDataFound';

import SettingBlock from './components/SettingBlock';

import styles from './Settings.module.scss';

const cn = classnames.bind(styles);

const Settings: FC = () => {
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  if (!experimentData?.settings?.length) {
    return <NoDataFound size="normal" alignment="center" />;
  }

  return (
    <div className={cn('settings')}>
      <Panel theme={themeOptions.light} className={cn('settings__panel')}>
        <Panel.Content className={cn('settings__content')}>
          {experimentData.settings.map((elem: TInstrumentSettings, blockIndex) => (
            <SettingBlock key={blockIndex} settingBlock={elem} />
          ))}
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default Settings;
