import { Point as OsdPoint, Viewport as OsdViewport } from 'openseadragon';

import { isNumber } from '@/helpers';

import { BARCODE_DRAW_SETTINGS } from './helpers';

export const circlesOnCanvas = {
  filterByBbox<T extends TBarcodeCenter | TCagingSegmentationCenter>(bbox: TBoundaryBox, objectCircleList: T[]) {
    const { xMin, xMax, yMin, yMax } = bbox;
    return objectCircleList.filter(
      (objectCircle) => objectCircle.x > xMin && objectCircle.x < xMax && objectCircle.y > yMin && objectCircle.y < yMax
    );
  },

  findObject<T extends TBarcodeCenter | TCagingSegmentationCenter>(
    viewport: OsdViewport,
    position: OsdPoint,
    objectContourList: T[]
  ): Nullable<T> {
    const imageCoords = viewport.viewerElementToImageCoordinates(position);
    return (
      objectContourList.find(
        (circleItem) => (imageCoords.x - circleItem.x) ** 2 + (imageCoords.y - circleItem.y) ** 2 <= circleItem.r ** 2
      ) ?? null
    );
  },

  draw<T extends TBarcodeCenter | TCagingSegmentationCenter>(
    ctx: Nullable<CanvasRenderingContext2D>,
    objectCircleList: T[],
    drawSettings: typeof BARCODE_DRAW_SETTINGS,
    shouldFillIn = false,
    emptyDataCondition: (item: T) => boolean = (_item: T) => false
  ) {
    if (!ctx) {
      return;
    }
    ctx.lineWidth = drawSettings.lineWidth;
    ctx.strokeStyle = drawSettings.lineColor;
    objectCircleList.forEach((objectCircle) => {
      ctx.setLineDash(emptyDataCondition(objectCircle) ? [30, 10] : []);
      ctx.beginPath();
      ctx.arc(objectCircle.x, objectCircle.y, objectCircle.r, 0, 2 * Math.PI, false);
      if (shouldFillIn) {
        ctx.fillStyle =
          'opacity' in objectCircle && isNumber(objectCircle.opacity)
            ? drawSettings.fillColor(objectCircle.opacity)
            : drawSettings.fillColor(0.5);
        ctx.fill();
      }
      ctx.closePath();
      ctx.stroke();
    });
  },
};
