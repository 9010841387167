import { FC } from 'react';
import classnames from 'classnames/bind';
import TimeInput, { ETimeInputUnit } from '@/components/common/TimeInput';
import { TemperatureUnit } from '@/graphql/API';
import { themeOptions } from '@/types/theme';
import Select from '@/components/common/Select';
import NumberInput from '@/components/common/NumberInput';
import { CELSIUS, FAHRENHEIT } from '@/helpers';
import styles from '../MainBlock.module.scss';

const cn = classnames.bind(styles);

type TIncubationBlockProps = {
  durationHour: {
    value: number;
    onChange: (value: number) => void;
  };
  durationMinute: {
    value: number;
    onChange: (value: number) => void;
  };
  temperature: {
    value: number;
    onChange: (value: number) => void;
  };
  temperatureUnit: {
    value: TemperatureUnit;
    onChange: (value: TemperatureUnit) => void;
  };
  washesTotal: {
    value: number;
    onChange: (value: number) => void;
  };
};

const WashBlock: FC<TIncubationBlockProps> = ({
  durationHour,
  durationMinute,
  temperature,
  temperatureUnit,
  washesTotal,
}) => (
  <div className={cn('main-block__sub-column')}>
    <div className={cn('main-block__block')}>
      <div className={cn('main-block__group')}>
        <div className={cn('main-block__block-title')}>Washes Total</div>
        <NumberInput
          theme={themeOptions.light}
          onChange={washesTotal.onChange}
          value={washesTotal.value}
          className={cn('main-block__input-wrap')}
          inputClassName={cn('main-block__input')}
        />
      </div>
    </div>

    <div className={cn('main-block__block')}>
      <div className={cn('main-block__group', 'main-block__group_time')}>
        <div className={cn('main-block__block-title')}>Duration</div>
        <TimeInput
          theme={themeOptions.light}
          onChange={durationHour.onChange}
          value={durationHour.value}
          unit={ETimeInputUnit.hour}
          className={cn('main-block__input-wrap')}
          inputClassName={cn('main-block__input')}
        />
        <TimeInput
          theme={themeOptions.light}
          onChange={durationMinute.onChange}
          value={durationMinute.value}
          unit={ETimeInputUnit.minute}
          className={cn('main-block__input-wrap')}
          inputClassName={cn('main-block__input')}
        />
      </div>
    </div>

    <div className={cn('main-block__block')}>
      <div className={cn('main-block__group', 'main-block__group_time')}>
        <div className={cn('main-block__block-title')}>Temperature</div>
        <Select
          options={Array.from({ length: 100 }, (_, index) => ({
            value: index,
            label: String(index),
          }))}
          value={temperature.value}
          onChange={temperature.onChange}
          theme={themeOptions.light}
          className={cn('main-block__select', 'main-block__select_narrow')}
          controlClassName={cn('main-block__select-control')}
          menuClassName={cn('main-block__select-menu')}
          menuPosition="absolute"
          menuPlacement="top"
          isPortalable={false}
        />
        <Select
          options={[
            { value: TemperatureUnit.C, label: CELSIUS },
            { value: TemperatureUnit.F, label: FAHRENHEIT },
          ]}
          value={temperatureUnit.value}
          onChange={temperatureUnit.onChange}
          theme={themeOptions.light}
          className={cn('main-block__select', 'main-block__select_narrow')}
          controlClassName={cn('main-block__select-control')}
          menuClassName={cn('main-block__select-menu')}
          menuPosition="absolute"
          menuPlacement="top"
          isPortalable={false}
        />
      </div>
    </div>
  </div>
);

export default WashBlock;
