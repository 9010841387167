import { FC, useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions } from '@/store/slices/experimentRunDesign';

import CustomHeaderWithActions, { TCustomHeaderAction } from '@/components/runDesign/CustomHeaderWithActions';

type TCustomPageHeaderProps = {
  onClick: () => void;
};

const CustomPageHeader: FC<TCustomPageHeaderProps> = ({ onClick }) => {
  const appDispatch = useAppDispatch();

  const handleBasicClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setIsAdvancedMode(false));
  }, []);

  const handleAdvancedClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setIsAdvancedMode(true));
  }, []);

  const actions = useMemo<TCustomHeaderAction[]>(() => {
    const result: TCustomHeaderAction[] = [
      {
        onClick,
        title: 'Advanced - All Lanes',
        isActive: false,
      },
    ];

    return result;
  }, [handleBasicClick, handleAdvancedClick]);

  return (
    <CustomHeaderWithActions actions={actions}>
      Edit CellCage Encapsulation <br /> (CCE) settings
    </CustomHeaderWithActions>
  );
};

export default CustomPageHeader;
