import { ChangeEventHandler, CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames/bind';
import { isDefined } from '~/src/helpers/typeGuards';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import SearchResultsRow from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/SearchResultsRow';
import styles from './SearchResults.module.scss';

const cn = classNames.bind(styles);

type TSearchResultsProps = {
  results?: TReturnTransformedTypes[];
  isSearchFinished: boolean;
  isSelectVisible: boolean;
  selectedReagentId?: string;
  onSelectChange: Dispatch<SetStateAction<string>>;
  onInfoClickFactory: (reagentId: string) => () => void;
};

const SearchResults: FC<TSearchResultsProps> = ({
  results,
  isSearchFinished,
  isSelectVisible,
  selectedReagentId,
  onSelectChange,
  onInfoClickFactory,
}) => {
  const { config } = useReagentModalContext();
  const ResultsHeader = config.search.result?.headingRenderer;
  const ResultsRow = config.search.result?.rowRenderer;

  const handleResultChangeFactory: (newValue: string) => ChangeEventHandler<HTMLInputElement> = (newValue) => () => {
    onSelectChange(newValue);
  };

  if (isSearchFinished && (!isDefined(results) || !results.length)) {
    return (
      <div className={cn('search-results')}>
        <div className={cn('search-results__no-data')}>No data matches the selected filters.</div>
      </div>
    );
  }

  if (!isDefined(results) || !results.length) {
    return null;
  }

  return (
    <div
      className={cn('search-results')}
      style={{ '--css-grid-layout': config.search.result?.cssGridLayout } as CSSProperties}
    >
      {isDefined(ResultsHeader) && (
        <div className={cn('search-results__table-row', 'search-results__table-heading')}>
          {isSelectVisible ? (
            <div className={cn('search-results__table-cell', 'search-results__table-cell_select')}>Select</div>
          ) : (
            <div />
          )}
          <ResultsHeader />
          <div className={cn('search-results__table-cell')}>Info</div>
        </div>
      )}
      <div className={cn('search-results__table')}>
        {isDefined(results) &&
          isDefined(ResultsRow) &&
          results.map((result) => (
            <SearchResultsRow
              key={result.id}
              onChange={handleResultChangeFactory(String(result.id))}
              isSelected={selectedReagentId === result.id}
              onInfoClick={onInfoClickFactory(result.id)}
              isSelectVisible={isSelectVisible}
            >
              <ResultsRow result={result} />
            </SearchResultsRow>
          ))}
      </div>
    </div>
  );
};

export default SearchResults;
