import { FC, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';
import { removeDuplicates } from '@/helpers';
import { CCEType, CellIndex } from '@/graphql/API';

import { experimentRunDesignSelectors, TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';

import Select from '@/components/common/Select';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import Button from '@/components/common/Button';
import { ADVANCED_SETTINGS, DEFAULT_CELL_TYPE } from '@/pages/experiment-run-design/CagingSettings/constants';
import { cceTypeOptions } from '@/pages/experiment-run-design/CagingSettings/helpers';

import styles from './EditFields.module.scss';
import { TLaneCagingSettings } from '../../types';
import AdvancedSettings from './components/AdvancedSettings';
import MagnificationSwitch from '../MagnificationSwitch/MagnificationSwitch';
import ReagentSelector from './components/ReagentSelector/ReagentSelector';

const cn = classnames.bind(styles);

type TEditFields = {
  laneCagingSettings: TLaneCagingSettings;
  laneIndex: number;
  isAdvancedMode?: boolean;
  isViewMoreActive: boolean;
  setTableData: (data: SetStateAction<Nullable<TLaneCagingSettings>[]>) => void;
  isChanged?: boolean;
  isSomeSubtractiveCCE?: boolean;
};

const EditFields: FC<TEditFields> = ({
  laneCagingSettings,
  laneIndex,
  setTableData,
  isViewMoreActive,
  isChanged,
  isSomeSubtractiveCCE,
}) => {
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const currentLaneData = useSelector(experimentRunDesignSelectors.selectCurrentLaneById(laneCagingSettings.laneId));

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      setTableData((prev) => {
        const newSettings = structuredClone(prev);
        const laneData = newSettings[laneIndex];
        if (!laneData || !laneData?.overrideSettings) {
          return prev;
        }
        laneData.overrideSettings[field] = value;
        return newSettings;
      });
    },
    [laneIndex, setTableData]
  );

  const handleResetSettings = useCallback(() => {
    setTableData((prev) => {
      const newSettings = structuredClone(prev);
      const laneData = newSettings[laneIndex];
      if (!laneData || !laneData?.overrideSettings || !globalCagingSettings) {
        return prev;
      }
      laneData.overrideSettings = { ...globalCagingSettings };

      return newSettings;
    });
  }, [laneIndex, setTableData]);

  const handleChangeMagnification = useCallback(
    (input: { label: string; value: number }) => {
      setTableData((prev) => {
        const newSettings = structuredClone(prev);
        const laneData = newSettings[laneIndex];
        if (!laneData) {
          return prev;
        }
        laneData.magnification = input.value;
        return newSettings;
      });
    },
    [laneIndex, setTableData]
  );

  const cellNameOptions = useMemo<TBasicOption[]>(() => {
    const namesCollection: TBasicOption[] = [];

    currentLaneData?.cellTypes?.forEach((cellType) => {
      if (cellType?.cellIndex?.name) {
        namesCollection.push({
          label: cellType.cellIndex.name,
          value: cellType.cellIndex.id,
          customData: cellType.cellIndex,
        });
      }
    });

    return removeDuplicates(namesCollection, 'value');
  }, [currentLaneData, laneCagingSettings.cellToCage?.cellIndex?.name]);

  const handleChangeCellName = useCallback(
    (value: string, type: 'cellToCage' | 'cellToSubtract') => {
      setTableData((prev) => {
        const newSettings = structuredClone(prev);
        const currentOption = cellNameOptions.find((option) => value === option.value);
        const laneData = newSettings[laneIndex];
        if (!laneData || !currentOption?.customData) {
          return prev;
        }

        const dataSource = type === 'cellToCage' ? laneData?.cellToCage : laneData?.cellToSubtract;

        if (!dataSource?.cellIndex) {
          laneData[type] = {
            ...DEFAULT_CELL_TYPE,
            cellIndex: currentOption.customData as CellIndex,
          };
        } else {
          dataSource.cellIndex = currentOption.customData as CellIndex;
        }

        return newSettings;
      });
    },
    [laneIndex, setTableData]
  );

  const handleChangeCCEType = useCallback(
    (value: CCEType) => {
      setTableData((prev) => {
        const newSettings = structuredClone(prev);
        const laneData = newSettings[laneIndex];
        if (!laneData) {
          return prev;
        }
        laneData.cceType = value;
        return newSettings;
      });
    },
    [laneIndex, setTableData]
  );

  const handleChangeCellToCageCellName = (value: string) => handleChangeCellName(value, 'cellToCage');
  const handleChangeCellToSubtractCellName = (value: string) => handleChangeCellName(value, 'cellToSubtract');
  const isLaneWithSubtractiveCCE = laneCagingSettings?.cceType === CCEType.SUBTRACTIVE_CCE;

  return (
    <RunDesignTable.Row key={laneCagingSettings.laneId}>
      <RunDesignTable.Column
        className={cn('selected-field', SELECTABLE_CLASS_NAME, 'magnification')}
        {...addSelectableAttribute({ columnIndex: 0, rowIndex: laneIndex, dataType: 'magnification' })}
      >
        <MagnificationSwitch
          firstValue={{ label: '4X', value: 4 }}
          secondValue={{ label: '10X', value: 10 }}
          defaultValue="first"
          onChangeValue={handleChangeMagnification}
          selectedValue={laneCagingSettings.magnification === 10 ? 'second' : 'first'}
        />
      </RunDesignTable.Column>
      <RunDesignTable.Column
        className={cn(SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ columnIndex: 1, rowIndex: laneIndex, dataType: 'cellToCage' })}
      >
        <Select
          value={laneCagingSettings.cceType}
          options={cceTypeOptions}
          theme={themeOptions.light}
          onChange={handleChangeCCEType}
          className={cn('select')}
          controlClassName={cn('select__control')}
          menuClassName={cn('select__menu')}
          optionClassName={cn('select__option')}
        />
      </RunDesignTable.Column>

      <RunDesignTable.Column
        className={cn(SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ columnIndex: 1, rowIndex: laneIndex, dataType: 'cellToCage' })}
      >
        <Select
          value={laneCagingSettings?.cellToCage?.cellIndex?.id}
          options={cellNameOptions}
          theme={themeOptions.light}
          onChange={handleChangeCellToCageCellName}
          className={cn('select', 'cell-name-select')}
          controlClassName={cn('select__control')}
          menuClassName={cn('select__menu')}
          optionClassName={cn('select__option')}
        />
      </RunDesignTable.Column>
      <RunDesignTable.Column
        className={cn(SELECTABLE_CLASS_NAME, {
          'selected-field': !isSomeSubtractiveCCE,
        })}
        {...addSelectableAttribute({ columnIndex: 3, rowIndex: laneIndex, dataType: 'cellToCage' })}
      >
        <ReagentSelector
          laneCagingSettings={laneCagingSettings}
          laneIndex={laneIndex}
          type="cellToCage"
          setTableData={setTableData}
          className={cn('selected-field__waves-block')}
        />
      </RunDesignTable.Column>

      {isSomeSubtractiveCCE && (
        <>
          <RunDesignTable.Column
            className={cn(SELECTABLE_CLASS_NAME)}
            {...addSelectableAttribute({ columnIndex: 4, rowIndex: laneIndex, dataType: 'cellToCage' })}
          >
            {isLaneWithSubtractiveCCE && (
              <Select
                value={laneCagingSettings?.cellToSubtract?.cellIndex?.id}
                options={cellNameOptions}
                theme={themeOptions.light}
                onChange={handleChangeCellToSubtractCellName}
                className={cn('select', 'cell-name-select')}
                controlClassName={cn('select__control')}
                menuClassName={cn('select__menu')}
                optionClassName={cn('select__option')}
              />
            )}
          </RunDesignTable.Column>
          <RunDesignTable.Column
            className={cn(
              {
                'selected-field': isLaneWithSubtractiveCCE,
              },
              SELECTABLE_CLASS_NAME
            )}
            {...addSelectableAttribute({ columnIndex: 6, rowIndex: laneIndex, dataType: 'cellToSubtract' })}
          >
            {isLaneWithSubtractiveCCE && (
              <ReagentSelector
                laneCagingSettings={laneCagingSettings}
                laneIndex={laneIndex}
                type="cellToSubtract"
                setTableData={setTableData}
                className={cn('selected-field__waves-block')}
              />
            )}
          </RunDesignTable.Column>
        </>
      )}

      {isViewMoreActive && (
        <AdvancedSettings
          laneCagingSettings={laneCagingSettings}
          onChangeNumberField={handleChangeNumberField}
          globalCagingSettings={globalCagingSettings}
          advancedSettingsList={ADVANCED_SETTINGS}
          {...addSelectableAttribute({ columnIndex: 7, rowIndex: laneIndex, dataType: 'advancedSettings' })}
        />
      )}
      <RunDesignTable.Column>
        <div
          className={cn('selected-field', 'selected-field_no-padding', 'selected-field_from-start')}
          {...addSelectableAttribute({ columnIndex: 0, rowIndex: laneIndex, dataType: 'resetToDefault' })}
        >
          <Button
            onClick={handleResetSettings}
            type="button"
            color="light"
            disabled={!isChanged}
            className={cn('selected-field__reset-btn')}
          >
            Reset
          </Button>
        </div>
      </RunDesignTable.Column>
    </RunDesignTable.Row>
  );
};

export default EditFields;
