import { isBasicOption } from '@/components/common/Select/helpers';

const formatAxisOptionList = (scatterPlotAxesOptions: TOption[], primaryOptionList: (string | number)[]) => {
  const list = scatterPlotAxesOptions.reduce(
    (acc, option) => {
      if (isBasicOption(option)) {
        if (primaryOptionList.includes(option.value)) {
          acc.primary.push(option);
        } else {
          acc.secondary.push(option);
        }
      }

      return acc;
    },
    {
      primary: [],
      secondary: [],
    } as Record<string, TBasicOption[]>
  );

  // sorting
  list.primary = primaryOptionList.reduce((acc, optionValue) => {
    const foundOption = list.primary.find((option) => option.value === optionValue);
    if (foundOption) {
      acc.push(foundOption);
    }

    return acc;
  }, [] as TBasicOption[]);

  if (!list.secondary.length) {
    return list.primary;
  }

  return [
    ...list.primary,
    {
      label: 'See more data',
      options: list.secondary,
    },
  ];
};

export default formatAxisOptionList;
