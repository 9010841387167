import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { arrToMapByKeys } from '@/helpers';

import { chartDataSelectors } from '@/store/slices/chartData';

type TEntityWithLaneAndScan = TEntity & { laneId: string; scanId: string };

const useCageMap = (chartDataList: TDatasetDetails[]) => {
  const multiChartEntityListData = useSelector(chartDataSelectors.selectMultiChartEntityListData);
  const cageMap = useMemo(() => {
    const result = chartDataList.reduce((acc: Record<string, TEntityWithLaneAndScan>, el) => {
      const entityList = multiChartEntityListData[el.dataset.path]?.data;

      if (!entityList) {
        return acc;
      }
      Object.assign(
        acc,
        arrToMapByKeys(
          entityList.map((entity) => ({ ...entity, laneId: el.dataset.laneId, scanId: el.dataset.scanId })),
          'globalCageIdMatched'
        )
      );
      return acc;
    }, {});

    return result;
  }, [chartDataList, multiChartEntityListData]);

  return cageMap;
};

export default useCageMap;
