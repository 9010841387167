import type { TStandardField } from '@/components/common/StandardTable/types';

import UserRoleBadge from '@/pages/UsersDashboard/UserList/components/UserRoleBadge';
import UserStatusBadge from '@/pages/UsersDashboard/UserList/components/UserStatusBadge';
import UserMFARequired from '@/pages/UsersDashboard/UserList/components/UserMFARequired';
import UserEnabled from '@/pages/UsersDashboard/UserList/components/UserEnabled';
import UserMFAStatus from '@/pages/UsersDashboard/UserList/components/UsetMFAStatus';

export const USER_TABLE_FIELD_LIST = [
  { name: 'name', label: 'Name', isSearchable: true, isSortable: true },
  { name: 'email', label: 'Email', isSearchable: true, isSortable: true },
  {
    name: 'role',
    label: 'Role',
    isSearchable: true,
    isSortable: true,
    className: 'text-center',
    arrowPositionMode: 'absolute',
    dataComponent: UserRoleBadge,
  },
  {
    name: 'mfa.required',
    label: 'MFA required',
    isFitContent: true,
    arrowPositionMode: 'absolute',
    dataComponent: UserMFARequired,
  },
  {
    name: 'mfa.status',
    label: 'MFA status',
    isFitContent: true,
    arrowPositionMode: 'absolute',
    dataComponent: UserMFAStatus,
  },
  {
    name: 'status',
    label: 'Status',
    isSortable: true,
    isFitContent: true,
    className: 'text-center',
    arrowPositionMode: 'absolute',
    dataComponent: UserStatusBadge,
  },
  {
    name: 'enabled',
    label: 'Enabled',
    isSortable: true,
    isFitContent: true,
    className: 'text-center',
    arrowPositionMode: 'absolute',
    dataComponent: UserEnabled,
  },
] as TStandardField<TUser>[];
