import { SVGProps } from 'react';

const InfoRectangularIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="29" viewBox="0 0 16 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.465 0.684999C8.42167 0.684999 9.20333 0.988332 9.81 1.595C10.4167 2.17833 10.72 2.91333 10.72 3.8C10.72 4.71 10.4167 5.45667 9.81 6.04C9.20333 6.62333 8.42167 6.915 7.465 6.915C6.50833 6.915 5.715 6.62333 5.085 6.04C4.47833 5.43333 4.175 4.68667 4.175 3.8C4.175 2.91333 4.47833 2.17833 5.085 1.595C5.715 0.988332 6.50833 0.684999 7.465 0.684999ZM10.79 25.22H15.55V29H0.0100001V25.22H5.26V14.195H0.185V10.415H10.79V25.22Z"
      fill="currentColor"
    />
    <path
      d="M9.81 1.595L9.45638 1.94862L9.46345 1.95541L9.81 1.595ZM9.81 6.04L10.1566 6.40042V6.40042L9.81 6.04ZM5.085 6.04L4.73145 6.39355L4.73824 6.40035L4.7453 6.40688L5.085 6.04ZM5.085 1.595L5.43155 1.95541L5.43182 1.95516L5.085 1.595ZM10.79 25.22H10.29V25.72H10.79V25.22ZM15.55 25.22H16.05V24.72H15.55V25.22ZM15.55 29V29.5H16.05V29H15.55ZM0.00999999 29H-0.49V29.5H0.00999999V29ZM0.00999999 25.22V24.72H-0.49V25.22H0.00999999ZM5.26 25.22V25.72H5.76V25.22H5.26ZM5.26 14.195H5.76V13.695H5.26V14.195ZM0.185 14.195H-0.315V14.695H0.185V14.195ZM0.185 10.415V9.915H-0.315V10.415H0.185ZM10.79 10.415H11.29V9.915H10.79V10.415ZM7.465 1.185C8.30576 1.185 8.95473 1.44684 9.45645 1.94855L10.1636 1.24144C9.45194 0.52983 8.53758 0.184999 7.465 0.184999V1.185ZM9.46345 1.95541C9.96635 2.43898 10.22 3.0412 10.22 3.8H11.22C11.22 2.78547 10.867 1.91768 10.1566 1.23458L9.46345 1.95541ZM10.22 3.8C10.22 4.58627 9.96356 5.1987 9.46345 5.67958L10.1566 6.40042C10.8698 5.71463 11.22 4.83373 11.22 3.8H10.22ZM9.46345 5.67958C8.96284 6.16093 8.31156 6.415 7.465 6.415V7.415C8.53178 7.415 9.44383 7.08573 10.1566 6.40042L9.46345 5.67958ZM7.465 6.415C6.61873 6.415 5.95146 6.16085 5.4247 5.67312L4.7453 6.40688C5.47854 7.08581 6.39794 7.415 7.465 7.415V6.415ZM5.43855 5.68645C4.9291 5.177 4.675 4.55902 4.675 3.8H3.675C3.675 4.81431 4.02756 5.68967 4.73145 6.39355L5.43855 5.68645ZM4.675 3.8C4.675 3.0412 4.92865 2.43898 5.43155 1.95541L4.73845 1.23458C4.02802 1.91768 3.675 2.78547 3.675 3.8H4.675ZM5.43182 1.95516C5.95951 1.44701 6.6244 1.185 7.465 1.185V0.184999C6.39227 0.184999 5.47049 0.52965 4.73818 1.23484L5.43182 1.95516ZM10.79 25.72H15.55V24.72H10.79V25.72ZM15.05 25.22V29H16.05V25.22H15.05ZM15.55 28.5H0.00999999V29.5H15.55V28.5ZM0.51 29V25.22H-0.49V29H0.51ZM0.00999999 25.72H5.26V24.72H0.00999999V25.72ZM5.76 25.22V14.195H4.76V25.22H5.76ZM5.26 13.695H0.185V14.695H5.26V13.695ZM0.685 14.195V10.415H-0.315V14.195H0.685ZM0.185 10.915H10.79V9.915H0.185V10.915ZM10.29 10.415V25.22H11.29V10.415H10.29Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoRectangularIcon;
