import { Auth } from 'aws-amplify';
import { NDASH } from '@/helpers/constants';

export const getUserDisplayName = (user: TUserFromServer) => `${user.name ?? NDASH} (${user.email})`;

export const getUserRole = (user: TUserFromServer) => user.role ?? NDASH;

export const getUserEmail = async () => {
  try {
    return (await Auth.currentAuthenticatedUser()).attributes.email;
  } catch (_error) {
    return '';
  }
};
