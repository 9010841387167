import { FC } from 'react';
import classnames from 'classnames/bind';

import { downloadByUrl } from '@/helpers';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './DownloadsTab.module.scss';

const cn = classnames.bind(styles);

type TDownloadButtonProps = {
  data: TDownloadFile;
};
const DownloadButton: FC<TDownloadButtonProps> = ({ data }) => {
  const handleButtonClick = () => {
    downloadByUrl(data.cdnUri, data.name);
  };

  return (
    <Button tooltip="Download file" color="light" className={cn('downloads__button')} onClick={handleButtonClick}>
      <icons.DownloadIcon />
    </Button>
  );
};
export default DownloadButton;
