import { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './ContextPopover.module.scss';

const cn = classnames.bind(styles);

type TContextMenuPopover = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
  title?: string;
};

const ContextMenuPopover: FC<TContextMenuPopover> = ({
  isOpen,
  onClose = () => null,
  className = '',
  children,
  title,
}) => (
  <>
    {isOpen && <div onClick={onClose} aria-hidden className={cn('backdrop')} />}
    <div
      className={cn('popover', className, {
        popover_opened: isOpen,
        popover_closed: !isOpen,
      })}
    >
      {title && <div className={cn('popover__title')}>{title}</div>}
      <div className={cn('popover__content', { popover__content_gaped: title })}>{children}</div>
    </div>
  </>
);

export default ContextMenuPopover;
