import { ETimeInputUnit } from '@/components/common/TimeInput';
import getTimeUnits from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getTimeUnits';
import { TTimeChangePayload } from '../types';

const updateTimeValue = (currentDuration: number, { unit, value }: TTimeChangePayload) => {
  const { hours, minutes, seconds } = getTimeUnits(currentDuration);
  let newDuration = seconds;

  if (unit === ETimeInputUnit.hour) {
    newDuration += value * 60 * 60 + minutes * 60;
  } else {
    newDuration += hours * 60 * 60 + value * 60;
  }

  return newDuration;
};

export default updateTimeValue;
