import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { EPageWithChartType } from '@/types/charts';

import { EPreprocessingAssayType } from '@/helpers';

import useGeneralRange from '@/hooks/preprocessing/useGeneralRange';
import { useParamsExperimentId } from '@/hooks';

import { ReactComponent as Logo } from '@/icons/Logo.svg';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { appAPI } from '@/store/services/app';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { datasetsActions } from '@/store/slices/datasets';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { EStepName } from '@/store/slices/preprocessing/types';
import { chartSettingsActions, chartSettingsSelectors, EEntityObjectType } from '@/store/slices/chartSettings';
import { antnAPI } from '@/store/services/annotation';

import { withDefaultChartSettings } from '@/hoc/withDefaultChartSettings';
import { withChartData } from '@/hoc/withChartData';

import StepInit from './StepInit';
import StepCytokineDefineBeads from './Cytokine/StepCytokineDefineBeads';
import StepCytokineReviewBeads from './Cytokine/StepCytokineReviewBeads';
import StepCytokineSummary from './Cytokine/StepCytokineSummary';

import StepCellKillingDefineCells from './CellKilling/StepCellKillingDefineCells';

import styles from './Preprocessing.module.scss';

import PreprocessingDatasets from './StepInit/PreprocessingDatasets';
import Loader from './components/Loader';
import StepCellKillingReviewCells from './CellKilling/StepCellKillingReviewCells';
import StepCellKillingDefineCellsTarget from './CellKilling/StepCellKillingDefineCellsTarget';
import StepCellKillingReviewCellsTarget from './CellKilling/StepCellKillingReviewCellsTarget';
import StepCellKillingSummary from './CellKilling/StepCellKillingSummary';

const cn = classnames.bind(styles);

type TPreprocessingProps = {
  className?: string;
};

const Preprocessing: FC<TPreprocessingProps> = () => {
  const dispatch = useDispatch();
  const chartId = usePlotChartIdContext();

  const experimentId = useParamsExperimentId();
  const confirmationModal = useConfirmationModalContext();

  const [fetchBeadById] = antnAPI.useLazyFetchBeadByIdQuery();
  const [fetchExperimentGates, { isLoading: isGatesLoading, isFetching: isGatesFetching }] =
    appAPI.useLazyFetchExperimentGatesQuery();

  const [isPrevBeadsLoading, setIsPrevBeadsLoading] = useState(true);

  const currentStep = useSelector(preprocessingSelectors.selectCurrentStep);
  const isProcessing = useSelector(preprocessingSelectors.selectIsProcessing);
  const preprocessingType = useSelector(preprocessingSelectors.selectPreprocessingType);
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isBlockDraw = useSelector(gatesSelectors.selectIsBlockDraw);
  const selectedPreprocessingAssayId = useSelector(preprocessingSelectors.selectPreprocessingAssayId);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);

  const prevBeadIdList = useMemo(
    () => [...new Set(datasets.map((dataset) => dataset.markerList?.map((marker) => marker.id) ?? []).flat()).values()],
    [datasets]
  );

  const fetchPrevBeadsData = useCallback(
    async () => Promise.all(prevBeadIdList.map((beadId) => fetchBeadById(beadId, true).unwrap())),
    [prevBeadIdList]
  );

  const objectTypeRef = useRef(objectType);
  const isBlockDrawRef = useRef(isBlockDraw);
  const isObjectEntityEnabledRef = useRef(isObjectEntityEnabled);

  const datasetList = useMemo(() => datasets.map((el) => el.dataset), [datasets]);

  useGeneralRange(experimentId, datasetList, preprocessingType);

  useEffect(() => {
    const isPreprocessedBefore = datasets.some((dataset) =>
      dataset.markerList?.some(
        (marker) =>
          marker.processType === EPreprocessingAssayType.cytokineSecretion ||
          marker.processType === EPreprocessingAssayType.cellKilling
      )
    );
    if (isPreprocessedBefore) {
      fetchExperimentGates(experimentId, true);
    }
  }, [datasets]);

  useEffect(() => {
    const documentElementClassList = document.documentElement.classList;

    dispatch(datasetsActions.toggleIsPreprocessingView(true));
    dispatch(chartSettingsActions.setIsObjectEntityEnabled(true));
    documentElementClassList.add('is-preprocessing');

    return () => {
      dispatch(datasetsActions.toggleIsPreprocessingView(false));
      dispatch(gatesActions.setIsBlockDraw(isBlockDrawRef.current));

      dispatch(chartSettingsActions.setIsObjectEntityEnabled(isObjectEntityEnabledRef.current));
      dispatch(chartSettingsActions.clearRanges());
      dispatch(gatesActions.setSelectedGate(null));
      dispatch(preprocessingActions.setCellYChannel(''));
      dispatch(preprocessingActions.setCellXChannel(''));

      documentElementClassList.remove('is-preprocessing');
    };
  }, []);

  useEffect(() => {
    const newObjectType =
      selectedPreprocessingAssayId === EPreprocessingAssayType.cellKilling
        ? EEntityObjectType.isCell
        : EEntityObjectType.isBead;
    dispatch(chartSettingsActions.setObjectType(newObjectType));
    return () => {
      dispatch(chartSettingsActions.setObjectType(objectTypeRef.current));
    };
  }, [selectedPreprocessingAssayId]);

  const onClose = useCallback(async () => {
    const result = await confirmationModal.onOpen({
      confirmationText: 'Are you sure you want to exit the data preprocessing flow?',
      approveButtonText: 'Yes, exit',
    });
    if (!result) {
      return;
    }
    dispatch(datasetsActions.toggleIsPreprocessingView(false));
  }, [dispatch]);

  const StepComponent = useMemo(() => {
    const componentsObj: Partial<Record<EStepName, FC>> = {
      stepInit: StepInit,
      stepCytokineDefineBeads: StepCytokineDefineBeads,
      stepCytokineReviewBeads: StepCytokineReviewBeads,
      stepCytokineSummary: StepCytokineSummary,

      stepCellKillingDefineCells: StepCellKillingDefineCells,
      stepCellKillingReviewCells: StepCellKillingReviewCells,
      stepCellKillingDefineCellsTarget: StepCellKillingDefineCellsTarget,
      stepCellKillingReviewCellsTarget: StepCellKillingReviewCellsTarget,
      stepCellKillingSummary: StepCellKillingSummary,
    };

    return componentsObj[currentStep];
  }, [currentStep]);

  useEffect(() => {
    if (!prevBeadIdList.length) {
      setIsPrevBeadsLoading(false);
      return;
    }

    fetchPrevBeadsData()
      .then((preselectedBeads) => {
        dispatch(preprocessingActions.setBeadTypes(preselectedBeads as TBead[]));
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      })
      .finally(() => {
        setIsPrevBeadsLoading(false);
      });
  }, [prevBeadIdList]);

  const loaderText = useMemo(() => {
    const textList: string[] = [];
    if (isGatesLoading || isGatesFetching || isPrevBeadsLoading) {
      textList.push('Getting data about last preprocessing');
    }

    return textList;
  }, [isGatesLoading, isGatesFetching, isPrevBeadsLoading]);

  const isShowLoader = useMemo(
    () => isProcessing || isGatesLoading || isGatesFetching || isPrevBeadsLoading,
    [isGatesLoading, isGatesFetching, isPrevBeadsLoading, isProcessing]
  );

  return (
    <>
      <div className={cn('preprocessing')}>
        <Button className={cn('close-button')} onClick={onClose} isFitContent color="light">
          <icons.CloseIcon />
        </Button>
        <Logo className={cn('preprocessing__logo')} />
        <div className={cn('preprocessing__block', { 'preprocessing__block_all-height': currentStep !== 'stepInit' })}>
          {StepComponent && <StepComponent />}
        </div>

        {currentStep === 'stepInit' && (
          <div className={cn('preprocessing__block')}>
            <PreprocessingDatasets />
          </div>
        )}
      </div>
      <Loader isLoading={isShowLoader} textData={loaderText} />
    </>
  );
};

export default withDefaultChartSettings(withChartData(Preprocessing), EPageWithChartType.preprocessing);
