import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { CellFunction, CellIndex, CellType, Consumable, Lane, Reagent } from '@/graphql/API';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignCellType,
  TRunDesignConsumable,
  TRunDesignLane,
} from '@/store/slices/experimentRunDesign';

import { LANE_LETTER_NAME_LIST } from '@/helpers';
import { RunDesignContext } from '@/pages/experiment-run-design/context';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ConsumableHeader from '@/components/runDesign/ConsumableComponent/ConsumableHeader';

import EditCellTypeName from './components/EditCellTypeName';
import EditCellTypeFunction from './components/EditCellTypeFunction';
import EditCellTypePreLabeling from './components/EditCellTypePreLabeling';

import styles from './EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeProps = {
  runDesignCardIndex: number;
  showFunctionBlock?: boolean;
  sampleTitle: string;
};

const EditCellType: FC<TEditCellTypeProps> = ({ runDesignCardIndex, showFunctionBlock, sampleTitle }) => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);
  const isPreLabelingEX = useSelector(experimentRunDesignSelectors.selectIsPreLabelingEX(runDesignCardIndex));

  const [editableLaneList, setEditableLaneList] = useState<Partial<TRunDesignLane>[]>([]);

  const isSomeCellNameDefined = useMemo(
    () =>
      editableLaneList?.some(
        (lane) =>
          !!lane.cellTypes?.find((cellType, index) => index === runDesignCardIndex && !!cellType?.cellIndex?.name)
      ) ?? false,
    [editableLaneList]
  );

  const changeCellType = (laneIndex: number, newCellTypePart: Nullable<DeepPartial<CellType>>) => {
    setEditableLaneList((prev) => {
      const newLaneList = structuredClone(prev);
      const lane = newLaneList[laneIndex];

      if (lane) {
        if (!lane.cellTypes || newCellTypePart === null) {
          lane.cellTypes = [];
        }
        const cellType = lane.cellTypes[runDesignCardIndex];
        const currentCellIndex = cellType?.cellIndex ?? null;

        const updatedCellIndex = newCellTypePart?.cellIndex
          ? {
              ...(currentCellIndex ?? {}),
              ...newCellTypePart.cellIndex,
            }
          : currentCellIndex;

        lane.cellTypes[runDesignCardIndex] = cellType
          ? { ...cellType, ...newCellTypePart, cellIndex: updatedCellIndex }
          : { __typename: 'CellType', name: '', function: CellFunction.EFFECTOR, ...newCellTypePart };
        newLaneList[laneIndex] = lane;
      }
      return newLaneList;
    });
  };

  const tableData = useMemo(
    () => editableLaneList.map((lane) => lane.cellTypes?.[runDesignCardIndex] ?? null),
    [runDesignCardIndex, editableLaneList]
  );

  const updateFromTableData = useCallback(
    (newTableData: (TRunDesignCellType | null)[]) => {
      setEditableLaneList((prev) => {
        const newLaneList = structuredClone(prev);
        newLaneList.forEach((lane, laneIndex) => {
          if (!newTableData[laneIndex]) {
            return lane;
          }
          if (!lane.cellTypes) {
            lane.cellTypes = [];
          }
          lane.cellTypes[runDesignCardIndex] = newTableData[laneIndex];
        });
        return newLaneList;
      });
    },
    [runDesignCardIndex, editableLaneList]
  );

  const handleNameChange = (laneIndex: number, data: Nullable<{ name: string; id: string }>) => {
    if (!data) {
      setEditableLaneList((prev) => {
        const newLaneList = structuredClone(prev);
        const lane = newLaneList[laneIndex];

        if (lane) {
          lane.cellTypes = [null];

          newLaneList[laneIndex] = lane;
        }
        return newLaneList;
      });

      return;
    }

    changeCellType(laneIndex, { cellIndex: { __typename: 'CellIndex', id: data.id, name: data.name } });
  };

  const handleFunctionChange = (laneIndex: number, newFunction: CellFunction) => {
    changeCellType(laneIndex, { function: newFunction });
  };

  const handlePreLabelingChange = (laneIndex: number, newConsumable: Nullable<TRunDesignConsumable>) => {
    if (!newConsumable || !isReagentConsumable(newConsumable)) {
      changeCellType(laneIndex, { preLabeling: null });
      return;
    }

    changeCellType(laneIndex, {
      preLabeling: { __typename: 'ConsumableToUse', consumable: newConsumable as unknown as Consumable },
    });
  };

  useEffect(() => {
    const laneList = currentEditFields.schema?.lanes ?? [];
    const initLaneList = LANE_LETTER_NAME_LIST.map((letter) => {
      const letterLane = laneList.find((lane) => lane.id === letter);
      if (letterLane) {
        return structuredClone(letterLane);
      }
      return { id: letter };
    });
    setEditableLaneList(initLaneList);
  }, [currentEditFields.schema?.lanes]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      isEditMode: true,
      saveAndContinue: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.updateCellTypes(editableLaneList as Lane[]));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
  }, [editableLaneList, overrideCustomFooterConfig]);

  const preSelectedValues = useMemo(
    () =>
      editableLaneList.reduce<{ cellIndex: Nullable<CellIndex>; preLabeling: Nullable<Reagent> }>(
        (acc, lane) => {
          const cellType = lane?.cellTypes?.[runDesignCardIndex];

          if (!acc.cellIndex && cellType?.cellIndex) {
            acc.cellIndex = cellType.cellIndex;
          }

          if (!acc.preLabeling && cellType?.preLabeling && isReagentConsumable(cellType?.preLabeling?.consumable)) {
            acc.preLabeling = cellType.preLabeling.consumable;
          }

          return acc;
        },
        {
          cellIndex: null,
          preLabeling: null,
        }
      ),
    [editableLaneList, runDesignCardIndex]
  );

  return (
    <RunDesignTable<(TRunDesignCellType | null)[]>
      className={cn('edit-cell-type__table')}
      tableData={tableData}
      setTableData={updateFromTableData}
      isEdit
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column>
            <span className={cn('edit-cell-type__header-title')}>Cell name</span>
          </RunDesignTable.Column>
          {isSomeCellNameDefined && showFunctionBlock && (
            <RunDesignTable.Column>
              <span className={cn('edit-cell-type__header-title')}>Function</span>
            </RunDesignTable.Column>
          )}
          {isSomeCellNameDefined && (
            <RunDesignTable.Column>
              <ConsumableHeader className={cn('edit-cell-type__header-title')} withWaves={isPreLabelingEX}>
                Pre-Labeling
              </ConsumableHeader>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {editableLaneList.map((lane, laneIndex) => {
        if (!('sample' in lane)) {
          return <RunDesignTable.Row key={lane.id} />;
        }

        const cellType = lane?.cellTypes?.[runDesignCardIndex];
        const preLabelingReagentData = isReagentConsumable(cellType?.preLabeling?.consumable)
          ? cellType?.preLabeling?.consumable ?? null
          : null;

        return (
          <RunDesignTable.Row key={lane.id}>
            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypeName
                cellIndex={lane.cellTypes?.[runDesignCardIndex]?.cellIndex}
                preSelectedCellIndex={preSelectedValues.cellIndex}
                laneIndex={laneIndex}
                onNameChange={handleNameChange}
                laneId={lane.id ?? ''}
                sampleTitle={sampleTitle}
              />
            </RunDesignTable.Column>
            {showFunctionBlock && (
              <RunDesignTable.Column className={cn('edit-cell-type__column')}>
                <EditCellTypeFunction
                  cellType={lane.cellTypes?.[runDesignCardIndex]}
                  laneIndex={laneIndex}
                  onChangeFunction={handleFunctionChange}
                />
              </RunDesignTable.Column>
            )}

            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypePreLabeling
                key={lane.id}
                cellType={lane.cellTypes?.[runDesignCardIndex]}
                reagent={preLabelingReagentData as Nullable<Reagent>}
                preSelectedReagent={preSelectedValues.preLabeling}
                laneIndex={laneIndex}
                laneId={lane.id ?? ''}
                sampleTitle={sampleTitle}
                onChangeReagent={handlePreLabelingChange}
              />
              {/* <ReagentCell */}
              {/*  componentId={reagentListByLane.componentId} */}
              {/*  laneId={lane.id ?? ''} */}
              {/*  onAddNew={onAddNew} */}
              {/*  onPreselectedClick={handlePreselectedClick} */}
              {/*  preselectedUsedReagent={preselectedReagent} */}
              {/*  usedReagentIndex={cellIndex} */}
              {/*  usedReagent={preLabelingReagentData as Nullable<Reagent>} */}
              {/*  isEdit */}
              {/*  withWaves */}
              {/* /> */}
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default EditCellType;
