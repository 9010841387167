import Highlighter from 'react-highlight-words';

import { formatBytesToHumanReadable, NDASH } from '@/helpers';

import DateDistance from '@/components/common/DateDistance';

import { TStandardField, TStandardValue } from './types';

type TStandardTableCellProps<T> = {
  data: T;
  field: TStandardField<T>;
  searchWords: string[];
};

const StandardTableCell = <T extends object>({ data, field, searchWords }: TStandardTableCellProps<T>) => {
  if (field.dataComponent) {
    return <field.dataComponent data={data} />;
  }
  if (!data[field.name]) {
    return NDASH;
  }
  if (field.isBytes) {
    return formatBytesToHumanReadable(data[field.name] as number);
  }
  if (field.isDateDistance) {
    return <DateDistance date={data[field.name] as string} />;
  }
  if (field.isSearchable) {
    return <Highlighter textToHighlight={(data[field.name] as TStandardValue).toString()} searchWords={searchWords} />;
  }
  return data[field.name] as TStandardValue;
};

export default StandardTableCell;
