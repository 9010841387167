import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { isKeyOf, LANE_LETTER_NAME_LIST } from '@/helpers';

import { experimentRunDesignSelectors, TMatrixSettingItem } from '@/store/slices/experimentRunDesign';

import NoDataFound from '@/components/common/NoDataFound';

import styles from './MatrixView.module.scss';
import MatrixViewTable from '../MatrixViewTable';

const cn = classnames.bind(styles);

type TMatrixView = {
  lanesMatrixSettings: TMatrixSettingItem[];
  setLanesMatrixSettings: Dispatch<SetStateAction<TMatrixSettingItem[]>>;
};

const MatrixView: FC<TMatrixView> = ({ lanesMatrixSettings, setLanesMatrixSettings }) => {
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);

  const [activeLaneId, setActiveLaneId] = useState<Nullable<string>>(null);

  const onActiveLaneChangeFactory = useCallback(
    (id: string) => () => {
      const newActiveLane = activeLaneId === id ? null : id;
      setActiveLaneId(newActiveLane);
    },
    [activeLaneId]
  );

  const isEmptyDataForCurrentLane = useMemo(
    () => activeLaneId && !lanesMatrixSettings.filter((data) => data.lane.laneId === activeLaneId).length,
    [activeLaneId, lanesMatrixSettings]
  );

  const groupedMatrixSettingsByLanes = useMemo(
    () =>
      lanesMatrixSettings.reduce<Record<string, TMatrixSettingItem[]>>((acc, setting) => {
        const key = `${setting.lane.laneId}`;

        if (isKeyOf(key, acc)) {
          acc[key].push(setting);
        } else {
          acc[key] = [setting];
        }

        return acc;
      }, {}),
    [lanesMatrixSettings]
  );

  const updateSettings = ({
    value,
    key,
    laneId,
    scanId,
    consumableId,
  }: {
    value: number;
    key?: string;
    laneId: string;
    scanId?: string;
    consumableId: string;
  }) => {
    if (!key || !scanId) return;
    const updatedData = lanesMatrixSettings.map((setting) => {
      if (
        setting.lane.laneId !== laneId ||
        setting.scanConfig?.scanIds?.[0] !== scanId ||
        consumableId !== setting.consumable.id
      )
        return setting;

      const data = {
        ...setting,
        [key]: value,
      };

      return data;
    });
    setLanesMatrixSettings(updatedData);
  };

  if (!opticalMatrixSettings.some((el) => el.scanConfig)) {
    return (
      <NoDataFound
        size="big"
        textData="There are no optic settings for matrix view or no components with scan"
        className="center-block"
      />
    );
  }

  return (
    <div className={cn('matrix-view__content')}>
      <div className={cn('matrix-view__lanes')}>
        {LANE_LETTER_NAME_LIST.map((id) => (
          <button
            key={id}
            className={cn('lane', { lane_selected: activeLaneId === id })}
            onClick={onActiveLaneChangeFactory(id)}
          >
            {id}
          </button>
        ))}
      </div>
      <div className={cn('matrix-view__table-content')}>
        <div className={cn('matrix-view__table-wrapper')}>
          {isEmptyDataForCurrentLane && (
            <NoDataFound
              size="big"
              textData={`There are no optic settings for lane ${activeLaneId ?? ''}`}
              className={cn('center-block', 'no-data')}
            />
          )}
          {!isEmptyDataForCurrentLane &&
            (activeLaneId ? (
              <MatrixViewTable
                lanesMatrixSettings={groupedMatrixSettingsByLanes?.[activeLaneId] ?? []}
                activeLaneId={activeLaneId}
                updateSettings={updateSettings}
              />
            ) : (
              Object.keys(groupedMatrixSettingsByLanes).map((laneId) => (
                <MatrixViewTable
                  key={laneId}
                  lanesMatrixSettings={groupedMatrixSettingsByLanes?.[laneId] ?? []}
                  activeLaneId={laneId}
                  updateSettings={updateSettings}
                />
              ))
            ))}
        </div>
      </div>
    </div>
  );
};

export default MatrixView;
