import { FC, useCallback, useEffect, useRef, useState, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { EChartType } from '@/types/charts';

import { handlePlotlyResize } from '@/helpers/plotly';
import { CSSProperty } from '@/helpers/interfaces';

import usePlotProxy from '@/hooks/usePlotProxy';
import { useChartRangeHandling, useExperimentContext } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { experimentSelectors } from '@/store/slices/experiment';
import { cdnAPI } from '@/store/services/cdnData';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { datasetsSelectors } from '@/store/slices/datasets';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import ImagesScrollArea from '@/components/common/ImagesScrollArea';
import NoDataFound from '@/components/common/NoDataFound';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import RangeResetButton from '@/components/charts/RangeResetButton';

import {
  STATISTICS_CHART_LAYOUT,
  STATISTICS_CHART_CONFIG,
  GRAPHIC_HEIGHT,
  GRAPHIC_XAXES_TITLE,
  GRAPHIC_YAXES_TITLE,
} from './constants';
import { getPlotlyData } from './helpers';
import StatisticsSummary from './components/StatisticsSummary';
import Flowcell from './components/Flowcell';
import styles from './Summary.module.scss';
import AllLanesSwitch from './components/AllLanesSwitch';

const cn = classnames.bind(styles);

const Summary: FC = () => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const { openCageInspector } = useExperimentModalsContext();

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const laneList = useSelector(experimentSelectors.selectAllCagingLaneList);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const isObjectEntityEnabledRef = useRef(isObjectEntityEnabled);

  const { isAllLanesDataShow, changeCurrentAppLane } = useExperimentContext();

  const { data: cageList = [] } = cdnAPI.useFetchCageEntityListQuery(currentAppLane);

  const [defaultRange, setDefaultRange] = useState({
    x: [0, 0],
    y: [0, 0],
  });

  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const graphXRange = graphRef.current?.layout?.xaxis?.range;
  const graphYRange = graphRef.current?.layout?.yaxis?.range;

  const { resetRangeToDefault, handlePlotZoomed } = useChartRangeHandling({
    openedAxisSettingsName: null,
    selectedTool: null,
    graphRef,
    customChartType: EChartType.histogram,
    withAutorange: true,
  });

  const openModalFactory = useCallback(
    (entity: TEntity) => () => {
      openCageInspector({ entity, entityList: cageList });
    },
    [cageList, openCageInspector]
  );

  const downloadChartFileName = useMemo(() => `${experimentName}_lane-statistics`, [experimentName]);

  const handleRangeResetClick = () => {
    resetRangeToDefault();
  };

  const isArraysEqual = (array1: number[], array2: number[]) =>
    array1?.every((element, index) => element === array2?.[index]);

  const isDefaultAxesActive =
    (defaultRange.x[0] === 0 && defaultRange.x[1] === 0 && defaultRange.y[0] === 0 && defaultRange.y[1] === 0) ||
    (isArraysEqual(defaultRange.x, graphXRange) && isArraysEqual(defaultRange.y, graphYRange));

  useEffect(() => {
    if (isPreprocessingView) {
      return;
    }
    if (isObjectEntityEnabled !== false) {
      isObjectEntityEnabledRef.current = isObjectEntityEnabled;
      appDispatch(chartSettingsActions.setIsObjectEntityEnabled(false));
    }
    return () => {
      if (isObjectEntityEnabled !== isObjectEntityEnabledRef.current) {
        appDispatch(chartSettingsActions.setIsObjectEntityEnabled(isObjectEntityEnabledRef.current));
      }
    };
  }, [isObjectEntityEnabled, isPreprocessingView]);

  useEffect(() => {
    if (!(graphRef.current && laneList.length)) return;

    graphRef.current.fn = `${experimentName}_lane-statistics`;

    const plotlyData = getPlotlyData(laneList, isAllLanesDataShow ? '' : currentAppLane?.id ?? '');

    plotlyProxy.react([plotlyData], STATISTICS_CHART_LAYOUT, STATISTICS_CHART_CONFIG);

    graphRef.current?.removeAllListeners?.('plotly_relayout');

    graphRef.current?.on?.('plotly_relayout', (eventData: TPlotRelayoutEvent) => {
      handlePlotZoomed(eventData, 'plotly_relayout');
    }); // event for zoom via scroll

    if (laneList?.length > 1) {
      graphRef.current?.on?.('plotly_click', (data: TPlotRelayoutEvent) => {
        const currentLane: TLane | undefined = laneList?.find((lane) => lane?.letterName === data?.points?.[0]?.label);
        if (currentLane) {
          changeCurrentAppLane(currentLane);
        }
      });
    }

    return () => {
      graphRef.current?.removeAllListeners?.('plotly_click');
    };
  }, [plotlyProxy.id, graphRef.current?.layout, currentAppLane, isAllLanesDataShow, laneList]);

  useEffect(() => {
    const isDefaultRangeSet =
      defaultRange.x?.filter((rangeValue) => rangeValue === 0)?.length === 2 &&
      defaultRange.y?.filter((rangeValue) => rangeValue === 0)?.length === 2;

    if (isDefaultRangeSet && graphXRange && graphYRange) {
      setDefaultRange({ x: [...graphXRange], y: [...graphYRange] });
    }
  }, [graphXRange, graphYRange]);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
    };
  }, []);

  return (
    <div className={cn('summary')}>
      <div className={cn('summary__content')}>
        <div className={cn('flow-data')}>
          <div className={cn('flow-data__flowcell')}>
            <div className={cn('statistics-headline', 'headline')}>
              <h3 className={cn('headline__title')}>Flowcell</h3>
              <AllLanesSwitch />
            </div>
            <Flowcell />
          </div>
          <div className={cn('flow-data__summary')}>
            <StatisticsSummary />
          </div>
        </div>
        <div className={cn('graphic')}>
          <div className={cn('graphic__heading')}>
            <h3 className={cn('graphic__title')}>Lane statistics</h3>
            <div className={cn('graphic__control-panel')}>
              <DownloadChartButton
                graphRef={graphRef}
                fileName={downloadChartFileName}
                xAxisLabel={GRAPHIC_XAXES_TITLE}
                yAxisLabel={GRAPHIC_YAXES_TITLE}
              />
              <RangeResetButton onClick={handleRangeResetClick} disabled={isDefaultAxesActive} />
            </div>
          </div>
          {!laneList.length && <NoDataFound className={cn('chart__no-data-found')} />}
          <div
            ref={graphRef}
            id="lane-statistic-chart"
            style={
              {
                '--graphic-height': `${GRAPHIC_HEIGHT}px`,
              } as CSSProperty
            }
            className={cn('graphic__chart', { 'graphic__chart_no-data': !laneList.length })}
          />
        </div>
      </div>
      <ImagesScrollArea openModalFactory={openModalFactory} entitiesToDisplay={cageList} />
    </div>
  );
};

export default memo(Summary);
