import { ZodType, ZodTypeDef } from 'zod';

export function correctHeadersRow(headersRow: string, firstFieldInHeadersRow: string) {
  const trimmedHeadersRow = headersRow.trim();
  const fieldIndex = trimmedHeadersRow.indexOf(firstFieldInHeadersRow);
  if (fieldIndex > 0) {
    return trimmedHeadersRow.substring(fieldIndex);
  }
  return trimmedHeadersRow;
}

type TParseCdnTableOptions<T> = {
  text: string;
  rowSeparator?: RegExp | string;
  columnSeparator?: RegExp | string;
  isTranspose?: boolean;
  headers?: string[];
  schema?: ZodType<T, ZodTypeDef, T>;
  firstFieldInHeadersRow?: string;
  typeGuard?: (value: unknown) => boolean;
};

export function parseCdnTable<T>({
  text,
  rowSeparator = /\r\n|\n/,
  columnSeparator = ',',
  isTranspose = false,
  headers,
  schema,
  firstFieldInHeadersRow,
  typeGuard,
}: TParseCdnTableOptions<T>): T[] {
  // Split the input text into rows
  const rows = text.split(rowSeparator).map((row, rowIndex) => {
    if (rowIndex === 0 && !isTranspose && firstFieldInHeadersRow) {
      const correctedRow = correctHeadersRow(row, firstFieldInHeadersRow);
      return correctedRow.split(columnSeparator);
    }
    return row.split(columnSeparator);
  });

  // Transpose the data if required
  if (isTranspose) {
    const transposedRows: string[][] = [];
    for (let i = 0; i < rows[0].length; i++) {
      transposedRows.push(rows.map((row) => row[i]));
    }
    rows.splice(0, rows.length, ...transposedRows);
  }

  // Use provided headers or default to the first row
  const dataHeaders = (headers ?? rows[0]).map((header) => header.replaceAll('"', ''));
  const startRow = headers ? 0 : 1;
  const dataRows = rows.slice(startRow);

  // Map data rows to objects
  const data: T[] = dataRows.reduce((acc: T[], row) => {
    const obj: Record<string, string> = {};
    row.forEach((value, index) => {
      if (typeof value === 'undefined') {
        return;
      }
      obj[dataHeaders[index]] = value.trim();
    });

    if (schema) {
      const zodParsed = schema.safeParse(obj);
      if (!zodParsed.success) {
        return acc;
      }
    }

    if (typeGuard) {
      const guarded = typeGuard(obj);
      if (!guarded) {
        return acc;
      }
    }

    acc.push(obj as T);
    return acc;
  }, []);

  return data;
}

export const parseContourXY = (contourXY: string) => {
  const contourXYPoints = contourXY.split(',');
  const canvasPoints = [];
  let i = 0;
  while (i < contourXYPoints.length) {
    canvasPoints.push([+contourXYPoints[i], +contourXYPoints[i + 1]]);
    i += 2;
  }
  return canvasPoints;
};

export function getSnapshotId(imageId: string) {
  const parts = imageId.split('_');
  if (parts.length > 4) {
    return parseInt(parts[3], 10);
  }
  return 0;
}
