import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import AsyncSelect from 'react-select/async';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { antnAPI } from '@/store/services/annotation';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { preprocessingSelectors } from '@/store/slices/preprocessing';

import { getNoOptionsMessage } from '@/components/common/Select/helpers';
import selectStyles from '@/components/common/Select/Select.module.scss';
import { MIN_SYMBOLS_TO_SEARCH_COUNT } from '@/components/common/Select/constants';

import styles from './StepInit.module.scss';

const cn = classnames.bind(styles);
const cnSelect = classnames.bind(selectStyles);

type TBeadSelect = {
  selectIndex: number;
  onSelectBeadType: (bead: TBead, index: number) => void;
  customBeads: TBead[];
};

const BeadSelect: FC<TBeadSelect> = ({ selectIndex, onSelectBeadType, customBeads }) => {
  const selectedBeadTypes = useSelector(preprocessingSelectors.selectBeadTypes);
  const [fetchAnnotations] = antnAPI.useLazyFetchAnnotationsQuery();

  const selectedOptionLabel = useRef('');
  const currentBeadsSearchResult = useRef<TBead[]>([]);

  const [selectedBead, setSelectedBead] = useState<Nullable<TBead>>(null);

  const selectedBeadTypesIds = useMemo(() => selectedBeadTypes.map((beadType) => beadType.id), [selectedBeadTypes]);
  const [selectedOption, setSelectedOption] = useState<Nullable<TBasicOption>>(null);

  const onBlur = () => {
    if (!selectedBead?.name && !selectedBead?.id) return;

    selectedOptionLabel.current = selectedBead.name;

    setSelectedOption({ label: selectedBead.name, value: selectedBead.id });
  };

  const handleSelectedOptionChange = useCallback(
    (option: Nullable<TBasicOption>) => {
      if (!option) {
        setSelectedOption(null);
        return;
      }

      const bead = [...currentBeadsSearchResult.current, ...customBeads].find((el) => el.id === option.value);

      if (!bead) {
        return;
      }

      setSelectedBead(bead);
      onSelectBeadType(bead, selectIndex);
      setSelectedOption({ label: bead.name, value: bead.id });
      selectedOptionLabel.current = option?.label ?? '';
    },
    [selectedOption, selectedOptionLabel]
  );

  const loadOptions = (inputValue: string, callback: (options: TBasicOption[]) => void) => {
    const searchValue = inputValue.length === 0 ? selectedOptionLabel.current : inputValue;
    if (searchValue?.length < MIN_SYMBOLS_TO_SEARCH_COUNT) {
      callback([]);
      return;
    }

    fetchAnnotations({ type: EAnnotationType.bead, search: searchValue }, true)
      .unwrap()
      .then((beadList) => {
        currentBeadsSearchResult.current = beadList as TBead[];
        const optionList = beadList.map((beadItem): TBasicOption => ({ label: beadItem.name, value: beadItem.id }));
        const optionListWithoutAlreadySelectedBeads = optionList.filter(
          ({ value }) => !selectedBeadTypesIds.includes(`${value}`)
        );
        callback(optionListWithoutAlreadySelectedBeads);
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
        callback([]);
      });
  };

  useEffect(() => {
    if (!selectedBeadTypes?.[selectIndex]?.name && !selectedBeadTypes?.[selectIndex]?.id) return;
    setSelectedBead(selectedBeadTypes[selectIndex]);
    setSelectedOption({ label: selectedBeadTypes[selectIndex].name, value: selectedBeadTypes[selectIndex].id });

    selectedOptionLabel.current = selectedBeadTypes[selectIndex]?.name ?? '';
  }, [selectedBeadTypes]);

  const noOptionsMessage = (obj: { inputValue: string }) => <>{getNoOptionsMessage(obj)}</>;

  return (
    <div className={cn('beads-types__select-wrapper')}>
      <AsyncSelect
        placeholder="Type at least 3 symbols..."
        className={cn('beads-types-select')}
        classNames={{
          valueContainer: () => cn('beads-types-select__value-container'),
          container: ({ isDisabled }) =>
            cnSelect('select', 'select_light', {
              select_disabled: isDisabled,
            }),
          control: () => `${cnSelect('select__control')} ${cn('beads-types-select__control')}`,
          dropdownIndicator: () => cnSelect('select__dropdown-indicator'),
          indicatorSeparator: () => cnSelect('select__indicator-separator'),
          menu: () => `${cnSelect('select__menu', 'select__menu_light')} ${cn('beads-types-select__menu-list')}`,
          menuPortal: () => cnSelect('select__menu-portal'),
          menuList: () => cnSelect('select__menu-list', 'select__menu-list_light'),
          option: ({ isFocused, isSelected, isMulti, isDisabled }) =>
            cnSelect('select__option', {
              select__option_multiple: isMulti,
              select__option_focused: isFocused,
              select__option_selected: isSelected,
              select__option_disabled: isDisabled,
              'select__option_light-focus': isFocused,
            }),
          clearIndicator: () => cnSelect('select__clear-indicator'),
          placeholder: () => cnSelect('select__placeholder'),
          singleValue: () => cnSelect('select__single-value'),
        }}
        value={selectedOption}
        onChange={handleSelectedOptionChange}
        loadOptions={loadOptions}
        defaultOptions
        noOptionsMessage={noOptionsMessage}
        key={selectedOptionLabel.current}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        isClearable
        onBlur={onBlur}
      />
      {selectedBead?.beadSize && (
        <span>
          Size = {selectedBead.beadSize}, Intensity = {selectedBead.beadIntensity}
        </span>
      )}
    </div>
  );
};

export default memo(BeadSelect);
