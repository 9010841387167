import { isComponentWithWash } from '@/helpers/runDesigns/typeGuards';

import { TemperatureUnit } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import copyState from './helpers/copyState';
import getInitialWash from './helpers/getInitialWash';
import updateTimeValue from './helpers/updateTimeValue';
import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import { EWashActionList, TTimeChangePayload, TWashReducerAction } from './types';

const updateTime = (state: TRunDesignComponent[], placementIndex: number, payload: TTimeChangePayload) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithWash(currentComponent)) {
    return newState;
  }

  currentComponent.wash ??= getInitialWash();
  const { wash } = currentComponent;
  if (!wash) {
    return state;
  }

  currentComponent.wash = {
    ...wash,
    duration: updateTimeValue(wash.duration, payload),
  };

  return newState;
};
const updateTemperature = (state: TRunDesignComponent[], placementIndex: number, temperature: number) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithWash(currentComponent)) {
    return newState;
  }

  currentComponent.wash ??= getInitialWash();
  const { wash } = currentComponent;

  if (!wash) {
    return state;
  }

  currentComponent.wash = {
    ...wash,
    temperature,
  };

  return newState;
};
const updateTemperatureUnit = (
  state: TRunDesignComponent[],
  placementIndex: number,
  temperatureUnit: TemperatureUnit
) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithWash(currentComponent)) {
    return newState;
  }

  currentComponent.wash ??= getInitialWash();
  const { wash } = currentComponent;

  if (!wash) {
    return state;
  }

  currentComponent.wash = {
    ...wash,
    temperatureUnit,
  };

  return newState;
};
const updateNumberOfWashes = (state: TRunDesignComponent[], placementIndex: number, numberOfWashes: number) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithWash(currentComponent)) {
    return newState;
  }

  currentComponent.wash ??= getInitialWash();
  const { wash } = currentComponent;

  if (!wash) {
    return state;
  }

  currentComponent.wash = {
    ...wash,
    numberOfWashes,
  };

  return newState;
};

const washReducer = (state: TRunDesignComponent[], action: TWashReducerAction) => {
  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EWashActionList.updateTime) {
    return updateTime(state, placementIndex, action.payload);
  }

  if (action.type === EWashActionList.updateTemperature) {
    return updateTemperature(state, placementIndex, action.payload.value);
  }

  if (action.type === EWashActionList.updateTemperatureUnit) {
    return updateTemperatureUnit(state, placementIndex, action.payload.value);
  }

  if (action.type === EWashActionList.updateNumberOfWashes) {
    return updateNumberOfWashes(state, placementIndex, action.payload.value);
  }

  throw new Error('Unknown edit wash action.');
};

export default washReducer;
