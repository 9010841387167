import { CSSProperties, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import {
  EChannelName,
  channelDataList,
  getChannelUiColor,
  isChannelIdInChannelData,
  getChannelName,
} from '@/helpers/channels';

import Select from '@/components/common/Select';

import styles from './SelectChannel.module.scss';

const cn = classnames.bind(styles);

type TSelectChannel = {
  onChange?: (channelName: string) => void;
  value: string;
  className?: string;
  disabled?: boolean;
  datasets?: TDatasetDetails[];
  withTitle?: boolean;
  autoSelectedChannel?: EChannelName;
};

const SelectChannel: FC<TSelectChannel> = ({
  onChange,
  value,
  className,
  disabled,
  datasets = [],
  withTitle = true,
  autoSelectedChannel,
}) => {
  const [color, setColor] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = useCallback(
    (channelName: string) => {
      if (!onChange) {
        return;
      }
      onChange(channelName);
    },
    [onChange]
  );

  const channelsOptions = useMemo<TBasicOption[]>(() => {
    if (disabled) {
      return [{ label: value, value }];
    }

    const channelsOptionsMap = new Map<string, TBasicOption>();

    datasets.forEach((dataset) => {
      dataset.channelList.forEach((channelId) => {
        const correctChannelName = channelDataList.find((channelData) =>
          isChannelIdInChannelData(channelId, channelData)
        );
        if (!correctChannelName) {
          return;
        }
        channelsOptionsMap.set(correctChannelName.name, {
          label: correctChannelName.name,
          value: getChannelName(channelId),
        });
      });
    });
    return [...channelsOptionsMap.values()];
  }, [datasets]);

  useEffect(() => {
    const newColor = getChannelUiColor(value);
    setColor(newColor);
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (!onChange) {
      return;
    }

    if (autoSelectedChannel && !value && channelsOptions.some((option) => option.value === autoSelectedChannel)) {
      setSelectedValue(autoSelectedChannel);
      const newColor = getChannelUiColor(autoSelectedChannel);
      setColor(newColor);

      onChange(autoSelectedChannel);
    } else {
      onChange('');
      setSelectedValue('');
      setColor('');
    }
  }, [channelsOptions, autoSelectedChannel]);

  return (
    <div
      className={cn('select-channel', className)}
      style={
        {
          '--theme-channel-select-bg-color': color,
        } as CSSProperties
      }
    >
      {withTitle && <span className={cn('select-channel__title')}>Beads channel:</span>}
      <Select
        disabled={disabled}
        theme={themeOptions.light}
        options={channelsOptions}
        onChange={handleChange}
        value={selectedValue}
        placeholder="Select"
        controlClassName={cn('select-channel__control', 'control')}
        dropDownClassName={cn('select-channel__dropdown')}
        valueContainerClassName={cn('select-channel__value-container', {
          'select-channel__value-container_enabled': !disabled,
        })}
        placeholderClassName={cn('select-channel__placeholder')}
      />
    </div>
  );
};

export default memo(SelectChannel);
