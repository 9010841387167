import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { LAST_EDIT, limitMinMax, limitRangeMinMax } from '@/helpers';
import {
  GRID_MINIMAP_COLUMNS,
  GRID_MINIMAP_ROWS,
  MINI_GRID_MINIMAP_COLUMNS,
  MINI_GRID_MINIMAP_ROWS,
} from '@/helpers/constants';

import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';
import { useParamsExperimentId } from '@/hooks';

import GridMinimap from './GridMinimap';

type GridProps = {
  row: number;
  column: number;
};

type TMapsProps = {
  laneLetterName: string;
};

const Maps: FC<TMapsProps> = ({ laneLetterName }) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const [rows, setRows] = useState(MINI_GRID_MINIMAP_ROWS);
  const [columns, setColumns] = useState(MINI_GRID_MINIMAP_COLUMNS);

  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const position = useSelector(navigatorSelectors.selectPosition(currentLaneId));
  const viewport = useSelector(navigatorSelectors.selectViewport(currentLaneId));

  const yPerCeil = useMemo(() => Math.floor(viewport.height / GRID_MINIMAP_ROWS), [viewport, GRID_MINIMAP_ROWS]);
  const xPerCeil = useMemo(() => Math.floor(viewport.width / GRID_MINIMAP_COLUMNS), [viewport, GRID_MINIMAP_COLUMNS]);

  const [isMinimapExtended, setIsMinimapExtended] = useState(false);

  const updateActiveCircleData = (): GridProps => {
    let newCol = Math.round(position.x / (xPerCeil || 1));
    let newRow = Math.round(position.y / (yPerCeil || 1));

    newCol = limitMinMax({
      value: newCol,
      minValue: 0,
      maxValue: GRID_MINIMAP_COLUMNS - 1,
    });

    newRow = limitMinMax({
      value: newRow,
      minValue: 0,
      maxValue: GRID_MINIMAP_ROWS - 1,
    });
    return { row: newRow, column: newCol };
  };

  const updateStartData = (activeRow: number, activeColumn: number): GridProps => {
    const row = limitRangeMinMax({
      startValue: activeRow - 1,
      endValue: activeRow + 1,
      minValue: 0,
      maxValue: GRID_MINIMAP_ROWS - 1,
    });
    const column = limitRangeMinMax({
      startValue: activeColumn - Math.floor(MINI_GRID_MINIMAP_COLUMNS / 2),
      endValue: activeColumn + Math.ceil(MINI_GRID_MINIMAP_COLUMNS / 2),
      minValue: 0,
      maxValue: GRID_MINIMAP_COLUMNS - 1,
    });

    return {
      row,
      column,
    };
  };

  const [activeCircleData, setActiveCircleData] = useState<GridProps>(updateActiveCircleData());
  const [startData, setStartData] = useState<GridProps>({ row: 0, column: 0 });

  useEffect(() => {
    setRows(isMinimapExtended ? GRID_MINIMAP_ROWS : MINI_GRID_MINIMAP_ROWS);
    setColumns(isMinimapExtended ? GRID_MINIMAP_COLUMNS : MINI_GRID_MINIMAP_COLUMNS);
  }, [isMinimapExtended]);

  useEffect(() => {
    if (position.lastEdit === LAST_EDIT.MINIMAP) {
      return;
    }

    const newActiveCircleData = updateActiveCircleData();
    setActiveCircleData(newActiveCircleData);
  }, [position.x, position.y, yPerCeil, xPerCeil]);

  useEffect(() => {
    if (isMinimapExtended) {
      setStartData({ row: 0, column: 0 });
      return;
    }

    const { row: activeRow, column: activeColumn } = activeCircleData;
    const { row: startRow, column: startColumn } = startData;

    const rowVal = limitMinMax({
      value: activeRow,
      maxValue: startRow + MINI_GRID_MINIMAP_ROWS - 2,
      minValue: startRow,
    });
    const colVal = limitMinMax({
      value: activeColumn,
      maxValue: startColumn + MINI_GRID_MINIMAP_COLUMNS - 1,
      minValue: startColumn,
    });

    if (rowVal !== activeRow || colVal !== activeColumn) {
      const newStartData = updateStartData(activeRow, activeColumn);
      setStartData(newStartData);
    }
  }, [isMinimapExtended, activeCircleData.row, activeCircleData.column]);

  const handleClickGridCircle = (input: { rowIndex: number; columnIndex: number }) => {
    const { columnIndex, rowIndex } = input;
    appDispatch(
      navigatorActions.setPosition({
        experimentId,
        laneId: currentLaneId,
        options: {
          x: xPerCeil * (columnIndex + startData.column),
          y: yPerCeil * (rowIndex + startData.row),
          lastEdit: LAST_EDIT.GRID_MINIMAP,
        },
      })
    );
  };

  const handleCloseGrid = () => {
    setIsMinimapExtended((prev) => !prev);
  };

  return (
    <GridMinimap
      activeCircle={activeCircleData}
      handleClick={handleClickGridCircle}
      label={laneLetterName}
      handleClose={handleCloseGrid}
      isMini={!isMinimapExtended}
      columns={columns}
      rows={rows}
      startColumn={startData.column}
      startRow={startData.row}
    />
  );
};

export default Maps;
