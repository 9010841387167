import { TAppEndpointBuilder } from '@/store/services/app/types';
import { transformAssayListResponse } from '@/store/services/app/dataProvider';

export const assayEndpoints = (build: TAppEndpointBuilder) => ({
  fetchAssayList: build.query<TAssay[], void>({
    query: () => ({
      url: `/assays`,
      method: 'GET',
    }),
    transformResponse: transformAssayListResponse,
  }),
});
