import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { arrToMapByKeys, b64DecodeUnicode, getGenes, parseJSON, removeDuplicates } from '@/helpers';

import { cdnAPI } from '@/store/services/cdnData';
import { experimentSelectors } from '@/store/slices/experiment';
import { TAddViolinData, TUpdateViolinData, TViolinData, violinActions, violinSelectors } from '@/store/slices/violin';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './ViolinPopover.module.scss';
import NewViolin from './NewViolin';
import ViolinRow from './ViolinRow';

const cn = classnames.bind(styles);

type TPopoverContent = {
  togglePopover: () => void;
};

const PopoverContent: FC<TPopoverContent> = ({ togglePopover }) => {
  const appDispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const datasetsData = searchParams.get('datasets') ?? '';

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const experiment = useSelector(experimentSelectors.selectCurrentExperiment);
  const violins = useSelector(violinSelectors.selectViolinsArr);
  const laneIdList = useSelector(violinSelectors.selectLaneIdList);

  const [getData] = cdnAPI.useLazyFetchGeneCountsQuery();

  const [genOptions, setGenOptions] = useState<TBasicOption[]>([]);
  const [laneOptions, setLaneOptions] = useState<TBasicOption[]>([]);

  const initGens = useCallback(async () => {
    const options = await getGenes();
    setGenOptions(options);
  }, []);

  useEffect(() => {
    initGens();
    const chartDataIdsList = parseJSON(b64DecodeUnicode(datasetsData)) as TDatasetDetailsIdData[];
    if (!chartDataIdsList) {
      return;
    }

    const lanes: TLane[] = [];

    const scanMap = arrToMapByKeys(scanList, 'id');

    chartDataIdsList.forEach((dataIds) => {
      const scan = scanMap[dataIds.scanId];
      if (!scan) {
        return;
      }
      const lane = scan.lanes.find((el) => el.id === dataIds.laneId);
      if (lane) {
        lanes.push(lane);
      }
    });

    setLaneOptions(
      removeDuplicates(lanes, 'id')
        .filter((lane) => laneIdList.includes(lane.id))
        .map((lane) => ({ label: lane.name ?? `Lane ${lane.letterName}`, value: lane.id }))
    );
  }, []);

  const onDelete = useCallback((id: string) => {
    appDispatch(violinActions.deleteViolin(id));
  }, []);

  const onUpdate = useCallback((data: TUpdateViolinData, violin: TViolinData) => {
    getData({ ...violin, ...data.updated, id: violin.id });
  }, []);

  const onCreate = useCallback(
    (data: TAddViolinData) => {
      const id = uuidv4();
      getData({ ...data, id });
    },
    [getData]
  );

  return (
    <div className={cn('violin-popover')}>
      <div className={cn('violin-popover__header')}>
        <span className={cn('violin-popover__title')}>
          <span>Violins data</span>
        </span>
        <Button color="light" type="button" className={cn('close-button')} onClick={togglePopover}>
          <icons.CloseIcon />
        </Button>
      </div>
      <div className={cn('violin-list', { 'violin-list_empty': !violins.length })}>
        {violins.map((violin) => {
          const key = violin.id;
          return (
            <ViolinRow
              key={key}
              genOptions={genOptions}
              laneOptions={laneOptions}
              onDelete={onDelete}
              onUpdate={onUpdate}
              violin={violin}
            />
          );
        })}
      </div>

      <div className={cn('new-violin')}>
        <NewViolin
          genOptions={genOptions}
          laneOptions={laneOptions}
          experimentPath={experiment?.cloudPath ?? ''}
          onCreate={onCreate}
        />
      </div>
    </div>
  );
};

export default memo(PopoverContent);
