import { FC } from 'react';
import classnames from 'classnames/bind';

import GateItem from '@/pages/Analytics/components/Scatterplot/components/GateItem';
import { TGeneralChartProps } from '@/components/charts/SingleChartWithGates/types';

import type { TUseUpdateGateCallbacksType } from '@/hooks/gates/useUpdateGates';
import { EPageWithChartType } from '@/types/charts';
import { EAxesGroupName } from '@/store/slices/scatterplots';

import styles from './GatesAccordionWrapper.module.scss';

const cn = classnames.bind(styles);

type TGatesAccordionWrapperProps = {
  gate: TGate;
  defaultActive?: boolean;
  scatterPlotAxesOptions?: Nullable<TOption[]>;
  currentAppLane: TGeneralChartProps['currentAppLane'];
  entityLevelGateList: TGeneralChartProps['entityLevelGateList'];
  entitiesByLanesAndGates: TGeneralChartProps['entitiesByLanesAndGates'];
  isUpdateGatesInProgress: TGeneralChartProps['isUpdateGatesInProgress'];
  pageType: EPageWithChartType;
  deleteGate: TUseUpdateGateCallbacksType['deleteGate'];
  updateGate: TUseUpdateGateCallbacksType['updateGate'];
  deleteGateChildren: TUseUpdateGateCallbacksType['deleteGateChildren'];
  specificAxesGroupName?: EAxesGroupName;
};

const GatesAccordionWrapper: FC<TGatesAccordionWrapperProps> = ({
  gate,
  scatterPlotAxesOptions = [],
  currentAppLane,
  entityLevelGateList,
  entitiesByLanesAndGates,
  deleteGate,
  updateGate,
  isUpdateGatesInProgress,
  deleteGateChildren,
  defaultActive = true,
  pageType,
  specificAxesGroupName,
}) => (
  <div className={cn('gates-accordion-wrapper')}>
    {gate.gateNodes.length > 0 && (
      <GateItem
        key={gate.id}
        gate={gate}
        currentAppLane={currentAppLane}
        entityLevelGateList={entityLevelGateList}
        deleteGate={deleteGate}
        updateGate={updateGate}
        isUpdateGatesInProgress={isUpdateGatesInProgress}
        deleteGateChildren={deleteGateChildren}
        entitiesByLanesAndGates={entitiesByLanesAndGates}
        headingClassName={cn('gates-accordion-wrapper__heading')}
        childrenClassName={cn('gates-accordion-wrapper__children')}
        defaultActive={defaultActive}
        scatterPlotAxesOptions={scatterPlotAxesOptions}
        pageType={pageType}
        specificAxesGroupName={specificAxesGroupName}
      />
    )}
  </div>
);

export default GatesAccordionWrapper;
