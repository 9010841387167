import { CCEType } from '@/graphql/API';
import { capitalizeFirstLetter } from '@/helpers';
import { ADVANCED_SETTINGS, CAGE_SETTINGS_MAIN_SECTIONS } from './constants';

export const formatCCETypeLabel = (cceType: CCEType) => {
  const parsedStr = cceType.replaceAll('_', ' ').toLowerCase();
  return capitalizeFirstLetter(parsedStr).replaceAll('cce', 'CCE');
};

export const getDefaultHeaderSections = (isSomeSubtractiveCCE: boolean) => {
  if (isSomeSubtractiveCCE) return CAGE_SETTINGS_MAIN_SECTIONS;

  return CAGE_SETTINGS_MAIN_SECTIONS.map((section) => {
    if (section.key === 'cellsToTarget') {
      const spliced = section.sections.toSpliced(3, 2);
      return {
        key: section.key,
        sections: spliced,
      };
    }
    return section;
  });
};

export const getHeaderSectionsForEditMode = (isSomeSubtractiveCCE: boolean, isViewMoreActive?: boolean) => {
  const defaultSections = getDefaultHeaderSections(isSomeSubtractiveCCE);
  const sections = defaultSections.toSpliced(defaultSections.length - 1, 1);

  if (isViewMoreActive) {
    sections.push({
      key: 'fullAdvanced',
      sections: [...ADVANCED_SETTINGS],
    });
  }

  sections.push({
    key: 'resetToDefault',
    sections: [
      {
        title: 'Reset to default',
        key: 'resetToDefault',
      },
    ],
  });

  return sections;
};

export const cceTypeOptions = Object.values(CCEType).map((value) => ({
  label: formatCCETypeLabel(value),
  value,
}));
