import { SVGProps } from 'react';

const CageSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="17.5" cy="17.5" r="14" stroke="black" strokeWidth="7" />
    <circle cx="57.5" cy="17.5" r="14" stroke="black" strokeWidth="7" />
    <circle cx="57.5" cy="57.5" r="14" stroke="black" strokeWidth="7" />
    <circle cx="17.5" cy="57.5" r="14" stroke="black" strokeWidth="7" />
  </svg>
);

export default CageSettingsIcon;
