import { useCallback, useEffect } from 'react';
import WebglHelper from './helpers/WebglHelper';

// todo add singleton and replace to ref
const webglHelper = new WebglHelper();

export type TRenderEntityImage = (
  _canvas: HTMLCanvasElement,
  _entity: TEntity,
  _settings: TRenderOptions,
  _callBack?: () => void
) => Promise<void>;

const imagesCache: Record<string, unknown> = {};
const errorImageUrl = '/image-placeholder.png';
const errorImg = new Image();
errorImg.crossOrigin = 'use-credentials';
errorImg.src = errorImageUrl;

window.imagesCache = imagesCache;

const loadImageElements = async (entity: TEntity, settings: TRenderOptions) => {
  const urls =
    settings.isThumbnail && entity.cropsThumbnailPaths.length ? entity.cropsThumbnailPaths : entity.cropsPaths;
  return Promise.all(
    urls.map((url, urlIndex) => {
      if (imagesCache[url]) {
        return imagesCache[url];
      }

      return new Promise((resolve, reject) => {
        const imageElement = new Image();
        imageElement.crossOrigin = 'use-credentials';
        imageElement.onload = () => {
          imagesCache[url] = imageElement;
          resolve(imageElement);
        };
        imageElement.onerror = (error) => {
          if (
            settings.isThumbnail &&
            imageElement.src === entity.cropsThumbnailPaths[urlIndex] &&
            entity.cropsPaths[urlIndex] !== entity.cropsThumbnailPaths[urlIndex]
          ) {
            // fallback for thumbnails
            imageElement.src = entity.cropsPaths[urlIndex];
          } else {
            reject(error);
          }
        };
        imageElement.src = url;
      });
    })
  );
};

const drawImageElements = (
  imageElements: HTMLImageElement[] | HTMLCanvasElement[],
  canvas: HTMLCanvasElement,
  settings: TRenderOptions
) => {
  webglHelper.changeTextures(imageElements);
  const { width, height } = imageElements[0];
  canvas.width = width;
  canvas.height = height;
  webglHelper.resize(width, height);
  const renderSettings = {
    ...settings,
    isActive: settings.isActive.concat(Array.from({ length: 8 - settings.isActive.length }, () => 0)),
  };
  webglHelper.render(renderSettings);
  canvas.getContext('2d')?.drawImage(webglHelper.canvas, 0, 0);
  // @ts-ignore
  canvas.renderSettings = renderSettings;
};

const drawErrorImage = (canvas: HTMLCanvasElement) => {
  const { width, height } = canvas.getBoundingClientRect();
  const mutableCanvas = canvas;
  mutableCanvas.width = width;
  mutableCanvas.height = height;
  canvas.getContext('2d')?.drawImage(errorImg, 0, 0, 400, 400, 0, 0, width, height);
};

const useWebgl = () => {
  useEffect(() => {
    webglHelper.init();
  }, []);

  const renderEntityImage = useCallback(
    async (canvas: HTMLCanvasElement, entity: TEntity, settings: TRenderOptions, callBack: () => void = () => null) => {
      try {
        // For add skeleton loading mask
        const parent = canvas.parentElement;
        if (parent) {
          parent.style.setProperty('--loading-display', 'block');
        }
        const imageElements = (await loadImageElements(entity, settings)) as HTMLImageElement[];
        if (imageElements.length) {
          drawImageElements(imageElements, canvas, settings);
        } else {
          drawErrorImage(canvas);
        }
      } catch (error) {
        drawErrorImage(canvas);
      } finally {
        // For remove skeleton loading mask in extra cases
        const parent = canvas.parentElement;
        if (parent) {
          parent.style.setProperty('--loading-display', 'none');
        }
        callBack();
      }
    },
    []
  );

  return {
    renderEntityImage,
    render: (
      imageElements: HTMLImageElement[] | HTMLCanvasElement[],
      canvas: HTMLCanvasElement,
      settings: TRenderOptions
    ) => {
      webglHelper.changeTextures(imageElements);
      webglHelper.render(settings);

      const { width, height } = canvas;

      canvas.getContext('2d')?.clearRect(0, 0, canvas.width, canvas.height);
      canvas.getContext('2d')?.drawImage(webglHelper.canvas, 0, 1000 - height / 2, width, height, 0, 0, width, height);
    },
  };
};

export default useWebgl;
