import { SVGProps } from 'react';

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
    <rect y="7" width="14" height="9" rx="1" fill="currentColor" />
    <rect x="4" y="1" width="6" height="11" rx="3" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default LockIcon;
