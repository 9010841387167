import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import styles from './NotFoundPage.module.scss';

const cn = classnames.bind(styles);

const NotFoundPage = () => (
  <div className={cn('not-found')}>
    <div className={cn('not-found__column')}>
      <div className={cn('not-found__code')}>404</div>
    </div>
    <div className={cn('not-found__column')}>
      <h2 className={cn('not-found__header')}>Nothing to see here!</h2>
      <p className={cn('not-found__text')}>
        <Link to="/" className={cn('not-found__link')}>
          Go to the home page
        </Link>
      </p>
    </div>
  </div>
);

export default NotFoundPage;
