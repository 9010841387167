import { TIntEndpointBuilder } from '@/store/services/app/types';

export const infoEndpoints = (build: TIntEndpointBuilder) => ({
  fetchApiInfo: build.query<TInfo, void>({
    query: () => ({
      url: '/info',
      method: 'GET',
    }),
  }),
});
