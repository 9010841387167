import { FC } from 'react';
import classNames from 'classnames/bind';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import icons from '@/components/common/icons';
import { addTooltip } from '@/helpers';
import { ETooltipTypeMap } from '@/helpers/tooltip';

import styles from '../MainBlock.module.scss';
import StainStepsSwitch from './StainStepsSwitch';

const cn = classNames.bind(styles);

type TCellKillingStainContent = {
  componentList: Nullable<TRunDesignComponent[]>;
  deliveryStainsAt: string[];
  cellKillingStainPlacement: {
    relativeTo: {
      list: TOption[];
      value: Nullable<string>;
      onChange: (value: string, isChecked: boolean) => void;
    };
  };
};

const CellKillingStainContent: FC<TCellKillingStainContent> = ({
  componentList,
  cellKillingStainPlacement,
  deliveryStainsAt,
}) => (
  <div className={cn('main-block__content')}>
    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__title-group')}>
            <div className={cn('main-block__block-title', 'main-block__block-title_no-gap')}>
              Select steps / washes to deliver cell killing Stain
            </div>
            <div
              className={cn('main-block__info')}
              {...addTooltip('Select steps / washes to deliver cell killing Stain', 'right', ETooltipTypeMap.dialog)}
            >
              <icons.InfoIcon />
            </div>
          </div>
          <div>
            {componentList?.map((component) => (
              <StainStepsSwitch
                key={component.id}
                component={component}
                checkedByDefault={deliveryStainsAt.includes(component.id)}
                onChange={cellKillingStainPlacement.relativeTo.onChange}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CellKillingStainContent;
