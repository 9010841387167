import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import { JobRunStatus } from '@/graphql/API';

import styles from '../JobRunList.module.scss';

const cn = classnames.bind(styles);

type TJobRunsControlPanelProps = {
  status: JobRunStatus;
};

const JobRunStatusBadge: FC<TJobRunsControlPanelProps> = ({ status }) => (
  <div
    className={cn('job-item__group-data-status', {
      'job-item__group-data-status_yellow': ([JobRunStatus.PENDING, JobRunStatus.RUNNING] as JobRunStatus[]).includes(
        status
      ),
      'job-item__group-data-status_blue': status === JobRunStatus.ENQUEUED,
      'job-item__group-data-status_red': (
        [
          JobRunStatus.FAILED,
          JobRunStatus.CANCELLING,
          JobRunStatus.TERMINATING,
          JobRunStatus.CANCELLED,
        ] as JobRunStatus[]
      ).includes(status),
      'job-item__group-data-status_green': status === JobRunStatus.SUCCEEDED,
    })}
  >
    {status}
  </div>
);

export default memo(JobRunStatusBadge);
