import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import styles from './ToggleableBlock.module.scss';

const cn = classnames.bind(styles);

type TToggleableBlockMainProps = {
  children: ReactNode;
  className?: string;
};

const ToggleableBlockMain: FC<TToggleableBlockMainProps> = ({ children, className }) => (
  <div className={cn('toggleable-block__main', className)}>{children}</div>
);

export default ToggleableBlockMain;
