import { SVGProps } from 'react';

const PrintIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#262626" stroke="currentColor" strokeWidth="1.5" fillRule="evenodd">
      <path d="M7.612 1h3.776c.892 0 1.215.093 1.541.267.326.174.582.43.756.756.175.326.267.65.267 1.54v2.264c0 .892-.092 1.215-.267 1.541-.174.326-.43.582-.756.756-.326.174-.649.267-1.54.267H7.611c-.892 0-1.215-.093-1.541-.267a1.817 1.817 0 01-.756-.756c-.175-.326-.267-.65-.267-1.54V3.563c0-.892.092-1.215.267-1.54a1.81 1.81 0 01.756-.757C6.397 1.093 6.72 1 7.61 1z" />
      <path d="M3.564 4.696h11.872c.892 0 1.215.092 1.54.267.327.174.583.43.757.756.174.326.267.649.267 1.54v4.481c0 .892-.093 1.215-.267 1.541-.174.326-.43.582-.756.756-.326.175-.65.267-1.54.267H3.563c-.892 0-1.215-.092-1.54-.267a1.817 1.817 0 01-.757-.756c-.174-.326-.267-.649-.267-1.54V7.26c0-.892.093-1.215.267-1.541.174-.326.43-.582.756-.756.326-.175.65-.267 1.54-.267z" />
      <path d="M7.612 9h3.776c.892 0 1.215.093 1.541.267.326.174.582.43.756.756.175.326.267.65.267 1.54v3.873c0 .892-.092 1.215-.267 1.54a1.81 1.81 0 01-.756.757c-.326.174-.649.267-1.54.267H7.611c-.892 0-1.215-.093-1.541-.267a1.817 1.817 0 01-.756-.756c-.175-.326-.267-.65-.267-1.54v-3.873c0-.892.092-1.215.267-1.54a1.81 1.81 0 01.756-.757C6.397 9.093 6.72 9 7.61 9z" />
    </g>
  </svg>
);

export default PrintIcon;
