import { ChangeEventHandler, Dispatch, FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import DialogModal from '@/components/common/DialogModal';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignComponent,
} from '@/store/slices/experimentRunDesign';
import { Placement, TemperatureUnit } from '@/graphql/API';
import { includes } from '@/helpers/enum';
import { isDefined } from '@/helpers/typeGuards';
import {
  isComponentWithIncubation,
  isComponentWithScan,
  isComponentWithWash,
  isScanComponent,
} from '@/helpers/runDesigns/typeGuards';
import { TTimeInputUnit } from '@/components/common/TimeInput';
import { TEditReducerAction } from '@/pages/experiment-run-design/DesignTimeline/reducer/types';
import { getScanOrdersMap } from '@/helpers/runDesigns/timeline';
import { isNumber } from '@/helpers';
import TitleBlock from './components/TitleBlock';
import MainBlock from './components/MainBlock';
import FooterBlock from './components/FooterBlock';

import ComponentSelector from './components/ComponentSelector';
import { isIncubationComponent } from './types';
import styles from './SettingsModal.module.scss';

const cn = classNames.bind(styles);

type TSettingsModalProps = {
  open?: boolean;
  onClose?: () => void;
  currentComponentId: Nullable<string>;
  setCurrentComponentId: (id: string) => void;
  componentList: Nullable<TRunDesignComponent[]>;
  dispatchComponentListAction: Dispatch<TEditReducerAction>;
};

const SettingsModal: FC<TSettingsModalProps> = ({
  open,
  onClose,
  currentComponentId,
  setCurrentComponentId,
  componentList,
  dispatchComponentListAction,
}) => {
  const appDispatch = useAppDispatch();

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const components: TRunDesignComponent[] = useMemo(
    () => currentEditFields.schema?.components ?? [],
    [currentEditFields.schema?.components]
  );

  useEffect(() => {
    dispatchComponentListAction({ type: 'changeComponentList', payload: components });
  }, [components]);

  const currentComponent = useMemo(
    () => componentList?.find(({ id }) => id === currentComponentId) ?? null,
    [componentList, currentComponentId]
  );

  // select if no component selected on modal open
  useEffect(() => {
    if (components?.length) {
      setCurrentComponentId(components[0].id);
    }
  }, [components]);

  // get list of all not-current component's
  const timingRelativeToList = useMemo(
    () =>
      componentList
        ?.filter((component) => component.id !== currentComponentId && !isScanComponent(component))
        ?.filter(({ timing: { placement } }) => placement !== Placement.SIMULTANEOUS)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })) ?? [],
    [componentList, currentComponentId]
  );
  const handleStepClickFactory = (step: string) => () => {
    setCurrentComponentId(step);
  };

  const handleTimingPlacementChange = (value: Placement) => {
    if (!currentComponent?.timing || !currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/updateTimelinePlacement',
      payload: {
        id: currentComponentId,
        value,
      },
    });

    if (includes([Placement.START, Placement.END], value)) {
      return;
    }

    if (currentComponent.timing.relativeTo) {
      return;
    }

    // get first non-current id
    const relativeTo = componentList?.find(({ id }) => id !== currentComponentId)?.id;

    if (!relativeTo) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/updateTimelineRelativeTo',
      payload: {
        id: currentComponentId,
        value: relativeTo,
      },
    });
  };

  const handleTimingRelativeToChange = (value: string) => {
    if (!currentComponent?.timing || !currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/updateTimelineRelativeTo',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleCellKillingStainPlacementToChange = (relativeToId: string, isChecked: boolean) => {
    if (!currentComponent?.timing || !currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/updateCellKillingStainPlacement',
      payload: {
        id: currentComponentId,
        value: relativeToId,
        isChecked,
      },
    });
  };

  const handlePauseAfterCompletionChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget: { checked } }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'updatePauseAfterCompletion',
      payload: {
        id: currentComponentId,
        value: checked,
      },
    });
  };

  const handleIncubationDurationChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateTime',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleIncubationTemperatureChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateTemperature',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleIncubationTemperatureUnitChange = (value: TemperatureUnit) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateTemperatureUnit',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleIncubationLocationOnDeviceChange = (value: boolean) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateLocationOnDevice',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleWashDurationChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateTime',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleWashTemperatureChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateTemperature',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleWashTemperatureUnitChange = (value: TemperatureUnit) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateTemperatureUnit',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleWashNumberOfWashesChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateNumberOfWashes',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleScanNumberOfScansChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'scan/updateNumberOfScans',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleScanIntervalChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'scan/updateInterval',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleScanFirstScanStartChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'scan/updateFirstScanStart',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleRemoveIncubation = () => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/removeIncubation',
      payload: {
        id: currentComponentId,
      },
    });
  };

  const handleUpdateIncubationComponentHasTreatment = ({ value }: { value: boolean }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'media/updateIncubationHasTreatment',
      payload: {
        id: currentComponentId,
        hasTreatment: value,
      },
    });
  };

  const titleBlockProps = {
    name: currentComponent?.name ?? '',
    typename: currentComponent?.__typename,
    afterCompletion: {
      checked: currentComponent?.pauseAfterCompletion ?? false,
      onChange: handlePauseAfterCompletionChange,
    },
    removing: {
      isRemovable: isDefined(currentComponent) && isIncubationComponent(currentComponent),
      onRemove: handleRemoveIncubation,
    },
    timing: {
      placement: {
        value: currentComponent?.timing.placement ?? null,
        onChange: handleTimingPlacementChange,
      },
      relativeTo: {
        list: timingRelativeToList,
        value: currentComponent?.timing.relativeTo ?? null,
        onChange: handleTimingRelativeToChange,
      },
    },
  };

  const scanOrdersMap = getScanOrdersMap(componentList ?? []);

  const mainBlockProps = {
    component: currentComponent,
    componentList,
    incubationDuration: {
      value: (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.duration) || 0,
      onChange: handleIncubationDurationChange,
    },
    incubationTemperature: {
      value: (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.temperature) || 38,
      onChange: handleIncubationTemperatureChange,
    },
    incubationTemperatureUnit: {
      value:
        (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.temperatureUnit) ||
        TemperatureUnit.C,
      onChange: handleIncubationTemperatureUnitChange,
    },
    incubationLocationOnDevice: {
      value: (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.onDevice) ?? false,
      onChange: handleIncubationLocationOnDeviceChange,
    },
    washDuration: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.duration) || 0,
      onChange: handleWashDurationChange,
    },
    washTemperature: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.temperature) || 0,
      onChange: handleWashTemperatureChange,
    },
    washTemperatureUnit: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.temperatureUnit) || TemperatureUnit.C,
      onChange: handleWashTemperatureUnitChange,
    },
    washNumberOfWashes: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.numberOfWashes) || 0,
      onChange: handleWashNumberOfWashesChange,
    },
    scanOrder: currentComponentId ? scanOrdersMap[currentComponentId] : 0,
    scanNumberOfScans: {
      value:
        isComponentWithScan(currentComponent) && isNumber(currentComponent?.scanConfig?.numberOfScans)
          ? currentComponent?.scanConfig?.numberOfScans
          : 0,
      onChange: handleScanNumberOfScansChange,
    },
    scanInterval: {
      value: (isComponentWithScan(currentComponent) && currentComponent?.scanConfig?.scanEvery) || 0,
      onChange: handleScanIntervalChange,
    },
    scanFirstScanTime: {
      value: (isComponentWithScan(currentComponent) && currentComponent?.scanConfig?.lagTime) || 0,
      onChange: handleScanFirstScanStartChange,
    },
    cellKillingStainPlacement: {
      relativeTo: {
        list: timingRelativeToList,
        value: currentComponent?.timing.relativeTo ?? null,
        onChange: handleCellKillingStainPlacementToChange,
      },
    },
    handleUpdateIncubationComponentHasTreatment,
  };

  const handleAddIncubationClick = () => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/addIncubation',
      payload: {
        id: currentComponentId,
      },
    });
  };

  const footerBlockProps = {
    onCancel: () => {
      dispatchComponentListAction({ type: 'changeComponentList', payload: components });
      onClose?.();
    },
    onSave: () => {
      appDispatch(experimentRunDesignActions.updateComponents(componentList as TRunDesignComponent[]));
      onClose?.();
    },
  };

  return (
    <DialogModal open={open} title="Settings" onClose={onClose}>
      <div className={cn('settings-modal')}>
        {componentList && isDefined(currentComponentId) && (
          <ComponentSelector
            componentList={componentList}
            currentComponentId={currentComponentId}
            onComponentClickFactory={handleStepClickFactory}
            onAddIncubationClick={handleAddIncubationClick}
          />
        )}
        {!!currentComponent && (
          <>
            <TitleBlock {...titleBlockProps} />
            <MainBlock {...mainBlockProps} />
            <FooterBlock {...footerBlockProps} />
          </>
        )}
      </div>
    </DialogModal>
  );
};

export default SettingsModal;
