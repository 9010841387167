export const getSelectionAreaIndices = (
  datasets: TDatasetDetails[],
  startDatasetId: string,
  endDatasetId: Nullable<string>
) => {
  const indices: number[] = [];

  datasets.forEach((element, index: number) => {
    if (startDatasetId && element.id === startDatasetId) {
      indices[0] = index;
    } else if (endDatasetId && element.id === endDatasetId) {
      indices[1] = index;
    }
  });

  return indices;
};
