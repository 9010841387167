const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_REGION ?? '',
    userPoolId: process.env.REACT_APP_USER_POOL ?? '',
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT ?? '',
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
    usernameAttributes: 'email',
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_API_URL ?? '',
  aws_appsync_region: process.env.REACT_APP_REGION ?? '',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsconfig;
