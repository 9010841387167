import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { experimentSelectors } from '@/store/slices/experiment';

import CheckboxInput from '@/components/common/CheckboxInput';
import { useExperimentContext } from '@/hooks';

const AllLanesSwitch: FC = () => {
  const { isAllLanesDataShow, toggleAllLanesDataShow } = useExperimentContext();
  const allCagingLaneList = useSelector(experimentSelectors.selectAllCagingLaneList);
  const isLaneSwitchAvailable = useMemo(() => allCagingLaneList.length > 1, [allCagingLaneList]);

  if (!isLaneSwitchAvailable) {
    return null;
  }

  return (
    <CheckboxInput
      checked={isAllLanesDataShow}
      onChange={toggleAllLanesDataShow}
      theme="light"
      label="All lanes"
      isSwitch
    />
  );
};

export default AllLanesSwitch;
