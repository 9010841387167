import { FC, useEffect } from 'react';
import classnames from 'classnames/bind';

import LoaderProgress from '@/components/common/LoaderProgress';

import styles from './PageLoader.module.scss';

const cn = classnames.bind(styles);

type TPageLoader = {
  firstRender?: { current: boolean };
};

const PageLoader: FC<TPageLoader> = ({ firstRender }) => {
  useEffect(() => {
    if (firstRender) {
      firstRender.current = false;
    }
  }, []);

  return (
    <div>
      <div className={cn('page-loader')}>
        <LoaderProgress
          theme="light"
          loaderContent={
            firstRender && firstRender.current && <h1 className={cn('page-loader__title')}>Welcome to Cellanome</h1>
          }
        />
      </div>
    </div>
  );
};

export default PageLoader;
