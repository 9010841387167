import { SVGProps } from 'react';

const StainIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.0196 17.2217C19.0196 22.0698 15.2535 26 10.6078 26C5.96212 26 2.19604 22.0698 2.19604 17.2217C2.19604 12.3735 6.60221 8.71429 10.6078 4C14.6134 8.71429 19.0196 12.3735 19.0196 17.2217Z"
      stroke="currentColor"
      strokeWidth="4"
    />
  </svg>
);

export default StainIcon;
