import { bin as d3Bin, thresholdFreedmanDiaconis, thresholdScott, thresholdSturges } from 'd3-array';
import { density1d } from 'fast-kde';

import { EAxesScaleType, EHistogramDataGroupTypes, EPageWithChartType, EChartType } from '@/types/charts';

import axisScaleHelper from '@/helpers/axisScaleHelper';
import { getMax, getMinMax } from '@/helpers/arrays';

import { DEFAULT_KERNEL_BINS_AMOUNT } from '@/components/charts/ChartSettingsButton/SettingsPopover/constants';

import { isNumber } from '../common';

type TGetLineHistogramCoordinates = {
  coords: Record<'x' | 'y', number[]>;
  customBinsAmount: number;
  currentHistogramDataGroupType: EHistogramDataGroupTypes;
  kernelBandwidthCoefficient?: number;
  kernelBinsAmountMultiplier: number;
  xAxisScaleType: EAxesScaleType;
  pageType?: EPageWithChartType;
};

type TD3BinsMethod = (values: ArrayLike<number | undefined>, min: number, max: number) => number;

const gaussianKernelEstimationGroupTypes = [EHistogramDataGroupTypes.kernelEstimation];

const DEFAULT_PADDING_FOR_KERNEL_ESTIMATION = 3;

const thresholdsGeneratorByType: Record<string, (customBinsAmount: number) => number | TD3BinsMethod> = {
  [EHistogramDataGroupTypes.thresholdFreedmanDiaconis]: () => thresholdFreedmanDiaconis,
  [EHistogramDataGroupTypes.thresholdScott]: () => thresholdScott,
  [EHistogramDataGroupTypes.thresholdSturges]: () => thresholdSturges,
  [EHistogramDataGroupTypes.custom]: (customBinsAmount: number) => customBinsAmount,
};

const histogramChartTypes = [EChartType.histogram, EChartType.lineHistogram];

const normalizeArray = (arr: number[]) => {
  const max = getMax(arr);

  return arr.map((val) => {
    const result = val / max;

    if (Number.isFinite(result)) {
      return result;
    }

    return 0;
  });
};

// https://observablehq.com/@uwdata/fast-kde
export const getLineHistogramCoordinatesByKernelEstimation = ({
  coords,
  kernelBandwidthCoefficient,
  xAxisScaleType,
  kernelBinsAmountMultiplier,
}: Omit<TGetLineHistogramCoordinates, 'currentHistogramDataGroupType' | 'customBinsAmount'> & {
  xAxisScaleType: EAxesScaleType;
}) => {
  const resultX: number[] = [];
  const resultY: number[] = [];

  const densityEstimator = density1d(coords.x, {
    pad: DEFAULT_PADDING_FOR_KERNEL_ESTIMATION,
    ...(isNumber(kernelBandwidthCoefficient) && {
      adjust: kernelBandwidthCoefficient,
    }),
    ...(kernelBinsAmountMultiplier && {
      bins: DEFAULT_KERNEL_BINS_AMOUNT ** kernelBinsAmountMultiplier,
    }),
  });

  const points = Array.from(densityEstimator);

  points.forEach(({ x, y }) => {
    if (x > 0 || axisScaleHelper.isNegativeValuesSupported(xAxisScaleType)) {
      resultX.push(x);
      resultY.push(y);
    }
  });

  const yPoints = normalizeArray(resultY);

  return { x: resultX, y: yPoints };
};

export const groupDataForHistogramByD3 = (
  data: number[],
  currentHistogramDataGroupType: EHistogramDataGroupTypes,
  customBinsAmount: number
) => {
  const { min: minVal, max: maxVal } = getMinMax(data);
  const thresholds = thresholdsGeneratorByType[currentHistogramDataGroupType](customBinsAmount);
  const bin = d3Bin().domain([minVal, maxVal]).thresholds(thresholds);
  const buckets = bin(data);

  return buckets;
};

export const getLineHistogramCoordinatesByD3 = ({
  coords,
  customBinsAmount,
  currentHistogramDataGroupType,
}: Omit<
  TGetLineHistogramCoordinates,
  'xAxisScaleType' | 'yAxisScaleType' | 'kernelBandwidthCoefficient' | 'kernelBinsAmountMultiplier'
>) => {
  const groupedData = groupDataForHistogramByD3(coords.x, currentHistogramDataGroupType, customBinsAmount);
  const xValues: number[] = [];
  const yValues: number[] = [];

  groupedData.forEach((item) => {
    const xMin = item?.x0 ?? null;
    const xMax = item?.x1 ?? null;

    const avgXPoint = isNumber(xMin) && isNumber(xMax) ? (xMin + xMax) / 2 : 0;
    const yPoint = item.length;

    xValues.push(avgXPoint);
    yValues.push(yPoint);
  });

  return { x: xValues, y: yValues };
};

export const getLineHistogramCoordinates = ({
  coords,
  customBinsAmount,
  currentHistogramDataGroupType,
  kernelBandwidthCoefficient,
  xAxisScaleType,
  kernelBinsAmountMultiplier,
}: TGetLineHistogramCoordinates): TScatterPlotCoordinates => {
  if (gaussianKernelEstimationGroupTypes.includes(currentHistogramDataGroupType)) {
    return getLineHistogramCoordinatesByKernelEstimation({
      coords,
      kernelBandwidthCoefficient,
      xAxisScaleType,
      kernelBinsAmountMultiplier,
    });
  }

  return getLineHistogramCoordinatesByD3({
    coords,
    customBinsAmount,
    currentHistogramDataGroupType,
  });
};

export const isHistogramsChartType = (plotType: EChartType) => histogramChartTypes.includes(plotType);

export const getHistogramsYAxisLabel = (currentHistogramDataGroupType: EHistogramDataGroupTypes) => {
  const titleByGroupType =
    currentHistogramDataGroupType === EHistogramDataGroupTypes.kernelEstimation ? 'Distribution' : 'Count';
  return titleByGroupType;
};
