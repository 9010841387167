import { FC } from 'react';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

type TFullScreen = {
  isFullScreen?: boolean;
  className?: string;
  changeFullScreen: () => void;
};

const FullScreen: FC<TFullScreen> = ({ className, isFullScreen, changeFullScreen }) => {
  const handleChangeFullScreen = () => {
    changeFullScreen();
  };

  return (
    <Button
      tooltip={isFullScreen ? 'Exit full screen' : 'Full screen'}
      id="full-screen"
      color="gray"
      className={className}
      onClick={handleChangeFullScreen}
    >
      <icons.FullScreenIcon />
    </Button>
  );
};

export default FullScreen;
