import { useEffect, useReducer, useRef } from 'react';
import { API as awsAPI } from 'aws-amplify';

import { getErrorMessage } from '@/helpers/errors';

import { PageInfoCursorBased } from '@/graphql/API';

const QUERY_LIMIT = 100;

type TUseGraphqlQueryProps<I> = {
  query: string;
  dataName: string;
  skip?: boolean;
  input?: Partial<I>;
};

type TSetEdgeListAction<E> = {
  type: 'setEdgeList';
  payload: E[];
};

type TSetErrorAction = {
  type: 'setError';
  payload: string;
};

type TSetLoadingAction = {
  type: 'setLoading';
};

const queryStateReducer = <E>(
  state: {
    edgeList: E[];
    isLoading: boolean;
    isError: boolean;
    error: string;
  },
  action: TSetEdgeListAction<E> | TSetErrorAction | TSetLoadingAction
) => {
  if (action.type === 'setEdgeList') {
    return {
      edgeList: action.payload,
      isLoading: false,
      isError: false,
      error: '',
    };
  }

  if (action.type === 'setError') {
    return {
      edgeList: [],
      isLoading: false,
      isError: true,
      error: action.payload,
    };
  }

  if (action.type === 'setLoading') {
    return {
      edgeList: [],
      isLoading: true,
      isError: false,
      error: '',
    };
  }

  throw new Error('Unknown filtering action.');
};

const useGraphqlQueryFullList = <I, E>({ query, dataName, skip = false, input = {} }: TUseGraphqlQueryProps<I>) => {
  const [queryState, dispatchQueryState] = useReducer(queryStateReducer<E>, {
    edgeList: [],
    isLoading: true,
    isError: false,
    error: '',
  });

  const partlyEdgeList = useRef<E[]>([]);
  const isQueryStartedRef = useRef(skip);
  const requestPromise = useRef<any>(null);

  const requestPage = async (after?: string) => {
    try {
      if (requestPromise.current) {
        awsAPI.cancel(requestPromise.current);
      }
      requestPromise.current = awsAPI.graphql({
        query,
        variables: {
          input: {
            ...input,
            after,
            limit: QUERY_LIMIT,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      const response = (await requestPromise.current) as {
        data: Record<
          string,
          {
            edges: E[];
            pageInfo: PageInfoCursorBased;
          }
        >;
      };
      requestPromise.current = null;
      const data = response.data[dataName];
      partlyEdgeList.current = after ? [...partlyEdgeList.current, ...data.edges] : data.edges;
      if (data.pageInfo.hasNextPage && data.pageInfo.endCursor) {
        requestPage(data.pageInfo.endCursor);
      } else {
        dispatchQueryState({ type: 'setEdgeList', payload: partlyEdgeList.current });
      }
    } catch (err: unknown) {
      if (!err?.constructor?.name.toLowerCase().includes('cancel')) {
        dispatchQueryState({ type: 'setError', payload: getErrorMessage(err) });
      }
    }
  };

  const refetch = () => {
    isQueryStartedRef.current = true;
    dispatchQueryState({ type: 'setLoading' });
    requestPage();
  };

  useEffect(() => {
    if (!skip) {
      refetch();
    }
  }, [skip]);

  useEffect(() => {
    if (!isQueryStartedRef.current) {
      refetch();
    }
  }, [isQueryStartedRef.current]);

  return { ...queryState, refetch };
};

export default useGraphqlQueryFullList;
