import { FC, ReactNode } from 'react';

import classnames from 'classnames/bind';

import InfoButton from '@/components/common/InfoButton';

import styles from '../PageHeader.module.scss';

const cn = classnames.bind(styles);

type TPageHeaderSlotProps = {
  className?: string;
  children: ReactNode;
  infoTooltip?: string;
};

const PageHeaderSubtitle: FC<TPageHeaderSlotProps> = ({ className, children, infoTooltip }) => (
  <div className={cn('page-header__subtitle', className)}>
    {children} {infoTooltip && <InfoButton tooltip={infoTooltip} />}
  </div>
);

export default PageHeaderSubtitle;
