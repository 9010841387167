import { CSSProperties, FC, ReactNode, useMemo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './Header.module.scss';

const cn = classnames.bind(styles);

type TAction = {
  name: string;
  component: ReactNode;
  onClick: () => void;
  className?: string;
};

type TRunDesignCardHeader = {
  className?: string;
  contentClassName?: string;
  actionsClassName?: string;
  onSettingClick?: () => void;
  onExpandClick?: () => void;
  isExpandMode?: boolean;
  children?: ReactNode;
  customActions?: TAction[];
  style?: CSSProperties;
  isEditMode?: boolean;
  hasDetails?: boolean;
};

const RunDesignCardHeader: FC<TRunDesignCardHeader> = ({
  className,
  contentClassName,
  actionsClassName,
  onSettingClick,
  onExpandClick,
  children,
  customActions,
  isExpandMode,
  style,
  isEditMode,
  hasDetails = false,
}) => {
  const showActions = useMemo(
    () => hasDetails && (onSettingClick || onExpandClick),
    [hasDetails, onSettingClick, onExpandClick]
  );

  return (
    <div className={cn('header', className)} style={style}>
      <div className={cn('header__content', contentClassName)}>{children}</div>
      {showActions && (
        <div className={cn('actions', actionsClassName)}>
          {onSettingClick && !isEditMode && (
            <Button
              onClick={onSettingClick}
              isFitContent
              isBgLight
              type="button"
              color="dark"
              className={cn('action-btn')}
            >
              <icons.CollapseIcon />
            </Button>
          )}
          {onExpandClick && (
            <Button
              onClick={onExpandClick}
              isFitContent
              isBgLight
              type="button"
              color="dark"
              className={cn('action-btn')}
            >
              {isExpandMode ? <icons.CollapseV2Icon /> : <icons.ExpandIcon />}
            </Button>
          )}
          {customActions?.map((customAction) => (
            <Button
              key={customAction.name}
              onClick={customAction.onClick}
              isFitContent
              isBgLight
              type="button"
              color="dark"
              className={cn('action-btn', customAction.className)}
            >
              {customAction.component}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RunDesignCardHeader;
