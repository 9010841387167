import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { LANE_LETTER_NAME_LIST } from '@/helpers';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import styles from './LanesTable.module.scss';
import Label from './Label';
import Name from './Name';
import CellTypes from './CellTypes';

const cn = classnames.bind(styles);

type TLanesTable = {
  className?: string;
  rowClassName?: string;
  isEditAllowed?: boolean;
  hideSampleName?: boolean;
  hideCellTypes?: boolean;
  isLocked?: boolean;
  isActivationAllowed?: boolean;
};

const LanesTable: FC<TLanesTable> = ({
  className,
  hideCellTypes,
  hideSampleName,
  rowClassName,
  isLocked,
  isEditAllowed,
  isActivationAllowed,
}) => {
  const lanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('lanes-table', className)}
      header={
        <RunDesignTable.Row className={cn('lanes-table__row', 'lanes-table__header-row')}>
          <RunDesignTable.Column>
            <span className={cn('lanes-table__cell', 'lanes-table__header-item')}>Lane</span>
          </RunDesignTable.Column>
          {!hideSampleName && (
            <RunDesignTable.Column>
              <span className={cn('lanes-table__header-item')}>Sample name</span>
            </RunDesignTable.Column>
          )}
          {!hideCellTypes && (
            <RunDesignTable.Column>
              <span className={cn('lanes-table__header-item')}>Cell types</span>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {LANE_LETTER_NAME_LIST.map((laneLetter) => {
        const lane = lanes?.find((el) => laneLetter === el.id);

        return (
          <RunDesignTable.Row
            key={laneLetter}
            className={cn('lanes-table__row', rowClassName)}
            tooltipMessage={!lane && !isActivationAllowed ? 'Activate lane on Step 2' : ''}
          >
            <RunDesignTable.Column>
              <Label
                className={cn('lanes-table__cell', { 'lanes-table__cell_label': !hideSampleName })}
                laneLetter={laneLetter}
                isEditAllowed={isEditAllowed}
                isLocked={isLocked}
                isActivationAllowed={isActivationAllowed}
              />
            </RunDesignTable.Column>
            {!hideSampleName && (
              <RunDesignTable.Column>
                <Name
                  className={cn('lanes-table__cell')}
                  laneLetter={laneLetter}
                  isEditAllowed={isEditAllowed}
                  isLocked={isLocked}
                  isActivationAllowed={isActivationAllowed}
                />
              </RunDesignTable.Column>
            )}

            {!hideCellTypes && (
              <RunDesignTable.Column>
                <CellTypes className={cn('lanes-table__cell')} laneLetter={laneLetter} />
              </RunDesignTable.Column>
            )}
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default LanesTable;
