import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { scatterplotsSelectors } from '@/store/slices/scatterplots';

import { preprocessingActions } from '@/store/slices/preprocessing';

import { useAppDispatch } from '../useAppDispatch';

const useGatesHandlingOnAxesChange = () => {
  const appDispatch = useAppDispatch();
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const ref = useRef<{ firstRender: boolean; xAxis: string; yAxis: string }>({
    firstRender: true,
    xAxis,
    yAxis,
  });

  useEffect(() => {
    if (ref.current.firstRender) {
      ref.current.firstRender = true;
      ref.current.xAxis = xAxis;
      ref.current.yAxis = yAxis;
      return;
    }

    const isTheSame = xAxis === ref.current.xAxis && yAxis === ref.current.yAxis;

    if (isTheSame) return;

    const isReverted = xAxis === ref.current.yAxis && yAxis === ref.current.xAxis;

    // clear created gate if one of the axes has been changed (not just swaped)
    if (!isReverted) {
      appDispatch(preprocessingActions.clearGeneralCellShapes());
    }

    ref.current.xAxis = xAxis;
    ref.current.yAxis = yAxis;
  }, [xAxis, yAxis]);

  useEffect(
    () => () => {
      ref.current.firstRender = false;
    },
    []
  );
};

export default useGatesHandlingOnAxesChange;
