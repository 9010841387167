import { FC, memo, useCallback, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { TUpdateViolinData, TViolinData } from '@/store/slices/violin';

import icons from '@/components/common/icons';
import Select from '@/components/common/Select';
import Button from '@/components/common/Button';
import VirtualSelect from '@/components/common/VirtualSelect';

import styles from './ViolinPopover.module.scss';
import LoadStatus from './LoadStatus';

const cn = classnames.bind(styles);

type TViolinRow = {
  onDelete: (id: string) => void;
  onUpdate: (data: TUpdateViolinData, violin: TViolinData) => void;
  violin: TViolinData;
  laneOptions: TBasicOption[];
  genOptions: TBasicOption[];
};

const ViolinRow: FC<TViolinRow> = ({ onDelete, onUpdate, violin, laneOptions, genOptions }) => {
  const [isEdit, setIsEdit] = useState(false);
  const unsavedDataRef = useRef<TUpdateViolinData>({
    id: violin.id,
    updated: { geneName: violin.geneName, laneId: violin.laneId },
  });

  const onChangeGen = useCallback((genOption: TBasicOption) => {
    unsavedDataRef.current.updated.geneName = genOption.value.toString();
  }, []);

  const onChangeLane = useCallback((newLaneId: string) => {
    unsavedDataRef.current.updated.laneId = newLaneId;
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(violin.id);
  }, [violin, onDelete]);

  const handleUpdate = useCallback(() => {
    onUpdate(unsavedDataRef.current, violin);
  }, [violin, onDelete]);

  const toggleEdit = useCallback(() => {
    const { geneName: updatedGenName, laneId: updatedLaneId } = unsavedDataRef.current.updated;
    const { geneName, laneId } = violin;
    const isEdited = geneName !== updatedGenName || updatedLaneId !== laneId;
    setIsEdit((prev) => {
      if (prev && isEdited) {
        handleUpdate();
      }
      return !prev;
    });
  }, [handleUpdate, violin]);

  return (
    <div className={cn('violin-row')}>
      <div className={cn('violin-row__data-wrapper')}>
        {isEdit ? (
          <VirtualSelect
            options={genOptions}
            onSelect={onChangeGen}
            defaultValue={violin.geneName}
            value={violin.geneName ?? ''}
            inputClassName={cn('select__virtual')}
          />
        ) : (
          <span className={cn('violin-row__data', 'gene-label', 'label')}>{violin.geneName}</span>
        )}
        {isEdit ? (
          <Select
            options={laneOptions}
            onChange={onChangeLane}
            value={violin.laneId}
            theme={themeOptions.light}
            className={cn('select')}
          />
        ) : (
          <span className={cn('violin-row__data', 'lane-label', 'label')}>
            {laneOptions.find((lane) => lane.value === violin.laneId)?.label ?? ''}
          </span>
        )}
      </div>
      <div className={cn('violin-row__actions')}>
        <Button isFitContent color="white" onClick={toggleEdit} tooltip={`${isEdit ? 'Save' : 'Edit'} violin`}>
          {isEdit ? <icons.SaveIcon className={cn('icon')} /> : <icons.EditIcon className={cn('icon')} />}
        </Button>
        <Button isFitContent color="gray" onClick={handleDelete} tooltip="Delete violin">
          <icons.DeleteIcon className={cn('icon')} />
        </Button>
      </div>
      <LoadStatus status={violin.loadStatus} />
    </div>
  );
};

export default memo(ViolinRow);
