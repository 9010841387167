import packageJson from '~/package.json';
import uiChangelogPath from '~/CHANGELOG.md';

let uiChangelogData: unknown;

export const fetchUIChangelog = async () => {
  if (uiChangelogData) return uiChangelogData;

  uiChangelogData = new Promise((resolve, reject) => {
    fetch(uiChangelogPath)
      .then((response) => response.text())
      .then((value) => {
        resolve(value);
      })
      .catch((err: unknown) => {
        console.error(err);
        reject(err);
      });
  });

  return uiChangelogData;
};

export const getReleaseDate = async () => {
  const uiChangelogMarkup = (await fetchUIChangelog()) as string;
  const date = uiChangelogMarkup?.split('\n')?.[0]?.split(' ')?.at(-1) as string;

  if (!/^\(\d{4}-\d{2}-\d{2}\)$/.test(date)) {
    return;
  }

  return date.replace(/[()]/g, '');
};

export const getUiServiceData = async () => {
  const releaseDate = await getReleaseDate();

  return {
    name: 'UI Service',
    openApiVersion: 'N/A',
    documentationURL: process.env.REACT_APP_GITHUB_URL ? `${process.env.REACT_APP_GITHUB_URL}/wiki` : '',
    appVersion: packageJson.version,
    releaseDate,
    changelogURL: 'CHANGELOG.md',
  };
};
