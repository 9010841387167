import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { API as awsAPI } from 'aws-amplify';
import { Id, toast } from 'react-toastify';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import graphqlEvents, { EOnExperimentEventType } from '@/helpers/graphqlEvents';
import { EPreprocessingAssayType, parseJSON } from '@/helpers';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { ProcessCageLevelAnalysisStatus } from '@/graphql/API';
import * as mutations from '@/graphql/mutations';

import { experimentSelectors } from '@/store/slices/experiment';
import { appAPI } from '@/store/services/app';
import { datasetsActions } from '@/store/slices/datasets';
import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { TGeneralCellShape } from '@/store/slices/preprocessing/types';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';

import DatasetsToConfirm from './DatasetsToConfirm';
import {
  endGeneratedEventWithSuccess,
  endGeneratedEventWithError,
  getDatasetsToPreprocess,
  defineIsAllDatasetsProcessed,
} from './helpers';

export function useCellKillingPreprocessing(experimentId: string, datasetDetailsList: TDatasetDetails[]) {
  const appDispatch = useAppDispatch();
  const confirmationModal = useConfirmationModalContext();

  const fullCellShapes = useSelector(preprocessingSelectors.selectFullCellShapes);
  const objectType = useSelector(chartSettingsSelectors.selectObjectType());
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const toastId = useRef<Nullable<Id>>(null);
  const preprocessedDatasetsAmount = useRef<Nullable<Map<string, boolean>>>(null);
  const associatedEventsSubscription = useRef<string | undefined>(undefined);
  const generatedEventsSubscription = useRef<string | undefined>(undefined);

  const unsubscribeAssociatedEvents = () => {
    if (associatedEventsSubscription.current) {
      graphqlEvents.unsubscribe(associatedEventsSubscription.current);
    }
  };

  const unsubscribeGeneratedEvents = () => {
    if (generatedEventsSubscription.current) {
      graphqlEvents.unsubscribe(generatedEventsSubscription.current);
    }
  };

  const unsubscribeEvents = () => {
    unsubscribeAssociatedEvents();
    unsubscribeGeneratedEvents();
  };

  const subscribeEvents = () => {
    unsubscribeEvents();
    graphqlEvents
      .subscribe(experimentId, experimentData?.organizationId ?? '', {
        eventType: EOnExperimentEventType.associated,
        success: endDataPreprocessingWithSuccess,
        error: endDataPreprocessingWithError,
      })
      .then((associatedCallBackUuid) => {
        associatedEventsSubscription.current = associatedCallBackUuid;
      });

    graphqlEvents
      .subscribe(experimentId, experimentData?.organizationId ?? '', {
        eventType: EOnExperimentEventType.generated,
        success: (eventData: Record<string, string>) => {
          if (!preprocessedDatasetsAmount.current) return;

          const payload = parseJSON(eventData.payload);
          const key = `${experimentId}-${payload.scanId}-${payload.laneId}`;

          preprocessedDatasetsAmount.current.set(key, true);
          const isAllProcessed = defineIsAllDatasetsProcessed(preprocessedDatasetsAmount.current);

          if (isAllProcessed) {
            endGeneratedEventWithSuccess({
              toastId: toastId.current,
            });
            unsubscribeGeneratedEvents();
          }
        },
        error: (error: unknown) => {
          endGeneratedEventWithError({ error, toastId: toastId.current });
          unsubscribeGeneratedEvents();
        },
      })
      .then((generatedCallBackUuid) => {
        generatedEventsSubscription.current = generatedCallBackUuid;
      });
  };

  const prepareShapeData = (datasetDetails: TDatasetDetails, shape: TGeneralCellShape) => {
    const { id: datasetId, laneId, scanId } = datasetDetails;
    const { specificDatasets, name } = shape;

    const gateData = specificDatasets[datasetId] ?? shape;
    const gateNodes: unknown[] = [];

    if (shape.shapeNodes) {
      Object.values(shape.shapeNodes).forEach((shapeItem) => {
        const preparedGate = prepareShapeData(datasetDetails, shapeItem);
        gateNodes.push(preparedGate);
      });
    }

    const properties = {
      ...gateData.properties,
      className: name,
      processType: EPreprocessingAssayType.cellKilling,
    };

    return {
      laneId,
      scanId,
      name,
      xDimension: shape.xDimension,
      yDimension: shape.yDimension,
      shape: {
        ...gateData.shape,
        model: JSON.stringify(gateData.shape.model),
      },
      gateNodes,
      properties: JSON.stringify(properties), // preprocessing request does not support the properties as object
    };
  };

  const getCellKillingGateVariable = () =>
    datasetDetailsList.map(
      (datasetDetails) => Object.values(fullCellShapes).map((shape) => prepareShapeData(datasetDetails, shape))[0]
    );

  useEffect(() => {
    toast.onChange((changeData) => {
      if (changeData.id === toastId.current && changeData.status === 'removed') {
        toastId.current = null;
      }
    });
  }, []);

  const endDataPreprocessingWithPending = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
    toastId.current = toast.loading(
      <span>
        You successfully sent the preprocessing data.
        <br />
        We will notify you when the data is updated.
      </span>,
      {
        className: 'toast_max-content',
        closeOnClick: true,
        closeButton: true,
      }
    );
    subscribeEvents();

    appDispatch(datasetsActions.toggleIsPreprocessingView(false));
    appDispatch(preprocessingActions.setIsProcessing(false));
  };

  const endDataPreprocessingWithSuccess = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
    toastId.current = toast.loading(
      <span>
        Data preprocessing started.
        <br />
        We will notify you when data is updated.
      </span>,
      {
        className: 'toast_max-content',
      }
    );

    appDispatch(appAPI.util.invalidateTags([{ type: 'Scan' }]));
    appDispatch(appAPI.util.invalidateTags([{ type: 'Gates' }]));
    appDispatch(datasetsActions.toggleIsPreprocessingView(false));
    appDispatch(preprocessingActions.setIsProcessing(false));
    unsubscribeAssociatedEvents();
  };

  const endDataPreprocessingWithError = (error?: unknown) => {
    showErrorToast(getErrorMessage(error, 'Preprocessing failed'));
    appDispatch(preprocessingActions.setIsProcessing(false));
    unsubscribeEvents();
  };

  const startCellKillingPreprocessing = async () => {
    try {
      const datasetsToRewrite = datasetDetailsList.filter((datasetDetails) =>
        datasetDetails.markerList?.some((marker) => marker.processType === EPreprocessingAssayType.cellKilling)
      );

      if (datasetsToRewrite.length) {
        const result = await confirmationModal.onOpen({
          confirmationText: 'This action will overwrite all existing Cell Killing gates for the selected datasets',
          approveButtonText: 'Yes, I understand',
          modalChildren: <DatasetsToConfirm datasetDetailsList={datasetsToRewrite} />,
        });
        if (!result) {
          return;
        }
      }
      appDispatch(preprocessingActions.setIsProcessing(true));
      const response = await awsAPI.graphql({
        query: mutations.processCageLevelAnalysis,
        variables: {
          experimentId,
          gate: getCellKillingGateVariable(),
          objectType,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      // @ts-ignore
      const status = response?.data?.processCageLevelAnalysis?.status;
      if (status === ProcessCageLevelAnalysisStatus.READY) {
        endDataPreprocessingWithSuccess();
      } else if (status === ProcessCageLevelAnalysisStatus.PENDING) {
        endDataPreprocessingWithPending();
      } else {
        endDataPreprocessingWithError();
      }
    } catch (error) {
      endDataPreprocessingWithError(error);
    }
  };

  useEffect(() => {
    const datasetsPreprocessingStatus = getDatasetsToPreprocess(experimentId, datasetDetailsList);
    preprocessedDatasetsAmount.current = datasetsPreprocessingStatus;
  }, [datasetDetailsList]);

  return {
    startCellKillingPreprocessing,
  };
}
