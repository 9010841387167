import { MutableRefObject, useEffect, useMemo, useState } from 'react';

import { useDebounce } from '@/hooks';

const DEBOUNCE_TIME = 150;

type TUseScatterPlotDebouncedData = {
  graphRef: MutableRefObject<Nullable<HTMLDivElement>>;
  filteredCoordinates: Nullable<TScatterPlotCoordinates>;
  plotRange: Nullable<TPopulationRange>;
  isTransitionEnd: boolean;
  isFullScreenTransitionEnd: boolean;
};

const useScatterPlotDebouncedData = ({
  graphRef,
  filteredCoordinates,
  plotRange,
  isFullScreenTransitionEnd,
  isTransitionEnd,
}: TUseScatterPlotDebouncedData) => {
  const [isChartDataAndRangeChanged, setIsChartDataAndRangeChanged] = useState(true);

  const memoChartDataAndRange = useMemo(() => [filteredCoordinates, plotRange], [filteredCoordinates, plotRange]);
  const [filteredCoordinatesDebounce, plotRangeDebounce] = useDebounce(memoChartDataAndRange, DEBOUNCE_TIME);

  useEffect(() => {
    setIsChartDataAndRangeChanged(false);

    const timeout = setTimeout(() => {
      setIsChartDataAndRangeChanged(true);
    }, DEBOUNCE_TIME);
    return () => {
      clearTimeout(timeout);
    };
  }, [filteredCoordinates, plotRange]);

  const isAllDataLoadedMemo = useMemo(
    () =>
      [
        isTransitionEnd,
        isFullScreenTransitionEnd,
        !!graphRef.current,
        !!filteredCoordinatesDebounce,
        isChartDataAndRangeChanged,
      ].every((el) => el),
    [
      isTransitionEnd,
      isFullScreenTransitionEnd,
      !!graphRef.current,
      !!filteredCoordinatesDebounce,
      isChartDataAndRangeChanged,
    ]
  );

  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  const isAllDataLoadedDebounced = useDebounce(isAllDataLoadedMemo, DEBOUNCE_TIME);

  useEffect(() => {
    setIsAllDataLoaded(isAllDataLoadedDebounced && isAllDataLoadedMemo);
  }, [isAllDataLoadedDebounced, isAllDataLoadedMemo]);

  return useMemo(
    () => ({
      plotRangeDebounce,
      filteredCoordinatesDebounce,
      isAllDataLoaded,
    }),
    [plotRangeDebounce, filteredCoordinatesDebounce, isAllDataLoaded]
  );
};

export default useScatterPlotDebouncedData;
