import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';

import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

export function usePlotAxesOptionList(chartData: TDatasetDetails) {
  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const chartLane = useSelector(experimentSelectors.selectLane(chartData.scanId, chartData.laneId));
  const cageLevelAxesOptionList = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionList(chartLane));

  const objectLevelAxesOptionList = useMemo(
    () => getScatterPlotObjectLevelAxesOptions(chartData.channelList ?? []),
    [chartData]
  );

  return useMemo(
    () =>
      isObjectEntityEnabled
        ? (objectLevelAxesOptionList as TBasicOption[])
        : (cageLevelAxesOptionList as TBasicOption[]),
    [cageLevelAxesOptionList, objectLevelAxesOptionList, isObjectEntityEnabled]
  );
}
