import { SVGProps } from 'react';

const PasteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.75" y="2.75" width="13.5" height="16.5" rx="2.25" stroke="currentColor" strokeWidth="1.5" />
    <path d="M5 10L10 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 14L10 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <rect x="4.75" y="0.75" width="5.5" height="4.5" rx="2.25" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default PasteIcon;
