import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

import { getUserDisplayName, getUserRole } from '@/helpers/user';

export const transformUserListResponse = (
  userListFromServer: TUserFromServer[],
  meta: FetchBaseQueryMeta | undefined
): TFetchUserListResult => ({
  nextToken: meta?.response?.headers.get('X-Pagination-Next-Token') ?? undefined,
  list: userListFromServer.map((user) => ({
    ...user,
    displayName: getUserDisplayName(user),
    role: getUserRole(user),
  })),
});
