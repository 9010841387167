export const SCAN_NUMBER_REGEX = /\d+/;

export const findScanInScanList = (scanList: TScan[], scanId: string) =>
  scanList.find((scan) => scan.id === scanId) ?? null;

export const findDefaultScanInScanList = (scanList: TScan[]) => {
  if (!scanList?.length) {
    return null;
  }
  return scanList[scanList.length - 1];
};

export const findLaneInScanList = (
  scanList: TScan[] | undefined,
  scanId: Nullable<string>,
  laneId: Nullable<string>
) => {
  if (!scanList || !scanId || !laneId) {
    return null;
  }
  return findScanInScanList(scanList, scanId)?.lanes?.find((lane) => lane.id === laneId) ?? null;
};

export const getScanNumberByName = (name: string) => {
  const found = name?.match(SCAN_NUMBER_REGEX)?.[0];
  return found ? Number(found) : -1;
};
