import { getMinMax } from '@/helpers/arrays';

import { getAxisGap } from './helpers';

export default {
  getPlotLayoutTypePart() {
    return {
      type: 'linear',
    };
  },

  getPlotLayoutTicksPart(_plotAxisRange: number[], _axisOrigDataRange?: number[]) {
    return {};
  },

  preparePlotData(data: number[], _axisOrigDataRange?: number[]) {
    return data;
  },

  getPlotAxisRange(data: number[], withGap: boolean) {
    const { min: minVal, max: maxVal } = getMinMax(data);
    const valDiff = maxVal - minVal;
    const gap = withGap ? getAxisGap(valDiff) : 0;
    return [minVal - gap, maxVal + gap];
  },

  convertToLinearPlotRange(linearRange: Nullable<[number, number]>) {
    return linearRange;
  },

  getScaledGatePoint({
    point,
    axisLinearRange: _axisLinearRange,
    axisOrigDataRange: _axisOrigDataRange,
  }: {
    point: number;
    axisLinearRange: Nullable<[number, number]>;
    axisOrigDataRange?: number[];
  }) {
    return point;
  },

  getRealGatePoint({
    point,
    axisLinearRange: _axisLinearRange,
    axisOrigDataRange: _axisOrigDataRange,
  }: {
    point: number;
    axisLinearRange: Nullable<[number, number]>;
    axisOrigDataRange?: number[];
  }) {
    return point;
  },

  getRealGatePoints(gatePoints: TGatePolygons, _plotLinearRange: Nullable<TPopulationRange>) {
    return gatePoints;
  },

  convertPlotValueToReal(axisValue: number, _axisOrigDataRange?: number[]) {
    return axisValue;
  },

  isCircleGateAllowed() {
    return true;
  },

  isNegativeValuesSupported() {
    return true;
  },
};
