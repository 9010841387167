import { FC, MouseEvent, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Content.module.scss';

const cn = classnames.bind(styles);

type TRunDesignCardContent = {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent) => void;
};

const RunDesignCardContent: FC<TRunDesignCardContent> = ({ children, className, onClick }) => (
  <div onClick={onClick} role="presentation" className={cn('content', { content_clickable: !!onClick }, className)}>
    {children}
  </div>
);

export default RunDesignCardContent;
